import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { Survey } from '../types'

export const useUpdateSurveyVisibility = (
  surveyId: number,
  onSuccess: (result: Survey) => void,
): [(active: boolean) => Promise<Survey>, Status<Survey>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Survey>()

  const updateSurvey = useCallback(
    (active: boolean) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/survey/${surveyId}/visible`,
        config: {
          body: {
            anonymous: !active,
          },
        },
        onSuccess: res => {
          onSuccess && onSuccess(res)
        },
      })
    },
    [onSuccess, organization.id, request, surveyId],
  )

  return [updateSurvey, status]
}

export default useUpdateSurveyVisibility
