import { connect } from 'react-redux'
import LegacyEditEventScene from '../../scenes/edit-event'
import { changeStep, clearEventForm, setEventDataFromApi, setEventField, setNewData } from '../reducers/EditEvent'
import { isFeatureEnabled } from 'core/feature/selectors'
import EditEventScene from '../../scenes/EditEventScene'
import { useFeatureEnabled } from '../../../core/feature/hooks'
import React from 'react'

export function MSTPCreateEvent(state) {
  return {
    editEventForm: state.EditEvent,
    isQuestionnaireAsSurveyEnabled: isFeatureEnabled(state, 'QuestionnaireAsSurvey'),
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    changeStep: step => dispatch(changeStep(step)),
    setEventField: (key, val) => dispatch(setEventField(key, val)),
    clearEventForm: () => dispatch(clearEventForm()),
    setNewData: data => dispatch(setNewData(data)),
    setEventDataFromApi: data => dispatch(setEventDataFromApi(data)),
  }
}

const EditEventContainer = props => {
  const NewEventEditConfirmationDialogEnabled = useFeatureEnabled('NewEventEditConfirmationDialog')
  if (NewEventEditConfirmationDialogEnabled) {
    return <EditEventScene {...props} />
  } else {
    return <LegacyEditEventScene {...props} />
  }
}

export default connect(MSTPCreateEvent, mapDispatchToProps)(EditEventContainer)
