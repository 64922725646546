import pickBy from 'lodash/pickBy'
import isNil from 'lodash/isNil'
import { useCallback, useEffect, useMemo } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'
import { useCurrentOrg } from 'auth/hooks'

export const useGetAutomatedMessages = filters => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching messages summary')
  const organization = useCurrentOrg()
  const getMessages = useCallback(async () => {
    const { startDate, endDate } = filters || {}

    const queryStringParameters = pickBy(
      {
        startDate: format.dateForUrl(startDate),
        endDate: format.dateForUrl(endDate),
        forGrid: true,
      },
      value => !isNil(value),
    )

    return request({
      method: 'get',
      url: `/organizations/${organization.id}/automated-messages`,
      config: {
        queryStringParameters,
      },
    })
  }, [filters, organization.id, request])

  useEffect(() => {
    getMessages()
  }, [getMessages])

  return useMemo(
    () => ({ result: result || [], loading: loading || !called, called, error }),
    [result, loading, called, error],
  )
}

export default useGetAutomatedMessages
