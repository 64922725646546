import React from 'react'
import './index.scss'

interface Props {
  type: string
}

function LabelType(props: Props) {
  return (
    <div className="label_type">
      {props.type === 'meeting' && <div className="label_type_meeting">Meeting</div>}
      {props.type === 'moment' && <div className="label_type_moment">Moment</div>}
    </div>
  )
}

export default LabelType
