import { CredentialFull } from 'civic-champs-shared/question-sets/types'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import format from 'civic-champs-shared/utils/format'

const useStyles = makeStyles({
  table: {
    '& > thead > tr > td': {
      padding: '8px 12px',
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 700,
      fontSize: '11px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#000000',
    },
    '& > tbody > tr > td': {
      borderTop: '1px solid #EEF0FA',
      padding: '18px 12px',
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
      '&:first-child': {
        color: '#0F5DB5',
      },
      '&.invalidCell': {
        fontWeight: '700 !important',
        color: '#BA1B1B !important',
      },
    },
  },
})

export const ProfileWaivers = ({ credentials }: { credentials: CredentialFull[] }) => {
  const classes = useStyles()
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <td>Name</td>
          <td>Status</td>
          <td>Signed</td>
          <td>Expired</td>
        </tr>
      </thead>
      <tbody>
        {credentials
          .filter(({ userCredential }) => !!userCredential)
          .map(credential => {
            const invalid =
              !credential.signedAt || (credential.expiredAt && new Date(credential.expiredAt) < new Date())
            return (
              <tr>
                <td>{credential.version.title}</td>
                <td className={cn({ invalidCell: invalid })}>{credential.userCredential?.status}</td>
                <td>{format.date(credential.signedAt)}</td>
                <td>{format.date(credential.expiredAt)}</td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
