import { Activity, Volunteer } from 'champion/utils/interface'
import { Address } from 'civic-champs-shared/core/location/utils'
import {
  CredentialFull,
  OrgNamedRef,
  QuestionSetPreReq,
  VolunteerPreferredContactMethod,
} from 'civic-champs-shared/question-sets/types'
import { PersonContactType } from 'people/hooks/useAddPersonContact'
import { SendMethod } from 'civic-champs-shared/question-sets/types/invitation'
import { Tag } from '../tags/types'
import { Group } from '../Event/components/opportunity/GroupPicker/types'

export interface Person {
  id: number
  givenName: string
  familyName: string
  email?: string
  phoneNumber?: string
  emailVerified?: boolean
  homePhoneNumber?: string
  workPhoneNumber?: string
  phoneNumberVerified?: boolean
  cognitoSub?: string
  stripeCustomerId?: string
  hasNoContact?: boolean
  birthDate?: Date
  emergencyContactId?: number
  emergencyContactRelationship?: string
  createdAt?: Date
  deletedAt?: Date
  preferredContactMethod?: VolunteerPreferredContactMethod
}

export enum ContactType {
  EMAIL = 'email',
  PHONE = 'phone',
}

export interface Contact {
  contact: string
  contactType: ContactType
  id: number
  createdAt: Date
  deletedAt?: Date
}

export enum PersonContactStatus {
  UNCONFIRMED = 'unconfirmed',
  CONFIRMED = 'confirmed',
  INVALID = 'invalid',
  VERIFIED = 'verified',
  VERIFIED_BY_OTHER = 'verified-by-other',
}

export interface PersonRefWithoutContacts {
  id: number
  givenName?: string
  familyName?: string
  hasNoContact?: boolean
  cognitoSub?: string
}

export interface PersonContact {
  id: number
  contactType: PersonContactType
  private?: boolean
  isPrimary?: boolean
  status: PersonContactStatus
  contact: Contact
  person: PersonRefWithoutContacts
  deletedBy?: PersonRefWithoutContacts
  invalidatedBy?: PersonRefWithoutContacts
  organization?: OrgNamedRef
  enteredBy?: PersonRefWithoutContacts
  createdAt: Date
  deletedAt?: Date
}

export interface PersonWithContacts extends Person {
  contacts?: PersonContact[]
}

export interface UserAvatar {
  id: number
  personId: number
  createdAt: Date
  uploadedAt?: Date
  url: string
  fileId: number
}

export interface PreferredContactMethod {
  sendMethod: SendMethod
  reasonWithheld?: string
  contact?: string
}
export interface PersonProfile extends Omit<Person, 'emergencyContactId'> {
  avatar: UserAvatar
  emergencyContact?: PersonWithContacts
  address?: Address
  preferredContact: PreferredContactMethod
}

export interface ChampionProfile {
  volunteer: Omit<Volunteer, 'user'>
  user: PersonProfile
  activities: Omit<Activity, 'user' | 'organization'>[]
  credentials: CredentialFull[]
  questionSets?: QuestionSetPreReq[]
  tags?: Tag[]
  groups?: Group[]
}

export const AVATAR_MAX_ZOOM = 300
export const AVATAR_DEFAULT_ZOOM = 250
