import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { useFormikWizardContext } from '../../formik/FormikWizard'
import { useFormikContext } from 'formik'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonDraft: {
    color: '#5F8FE8',
    borderColor: '#5F8FE8',
    width: '120px',
    height: '50px',
  },
  buttonStep: {
    width: '190px',
    height: '60px',
    backgroundImage: "url('/assets/icons/arrowButton.svg')",
    marginRight: '40px',
  },
  buttonInactive: {
    width: '190px',
    height: '60px',
    backgroundImage: "url('/assets/icons/inactiveButton.svg')",
    marginRight: '40px',
  },
  buttonPublish: {
    width: '150px',
    height: '49px',
    background: '#5F8FE8',
    marginRight: '40px',
  },
  nextText: {
    color: 'white',
    zIndex: 1000,
    fontSize: '15px',
  },
  cancelText: {
    color: '#FF6F3E',
  },
}))

export default function StepNavigation() {
  const { stepIndex, furthestStep, steps, published, gotoStep, saveDraft, onSaveDraft, publish, publishButtonLabel } =
    useFormikWizardContext()
  const { isValidating, isValid: isAllValid, isSubmitting } = useFormikContext()

  const { isValid: isStepValid = false } = steps[stepIndex] || {}
  const isLastStep = stepIndex >= steps.length - 1

  const disableNext = !isStepValid && stepIndex === furthestStep
  const disableSave = isValidating || isSubmitting || !isAllValid

  const classes = useStyles()

  const handlePublish = () => (!published ? publish() : saveDraft())

  //TODO should make some kind of check for nextStep?
  return (
    <div className={classes.root}>
      <div className={classes.leftContainer}>
        {!isLastStep && onSaveDraft && (
          <Button className={classes.buttonDraft} variant="outlined" disabled={disableSave} onClick={saveDraft}>
            <div>
              <b>Save{!published ? ' Draft' : ''}</b>
            </div>
          </Button>
        )}
      </div>
      <div>
        {stepIndex > 0 && (
          <Button style={{ color: '#5F8FE8' }} onClick={() => gotoStep(stepIndex - 1)}>
            Back
          </Button>
        )}
        {isLastStep ? (
          <Button className={classes.buttonPublish} disabled={disableSave} onClick={handlePublish}>
            <div className={classes.nextText}>
              <b>{publishButtonLabel ? publishButtonLabel : !published ? 'Publish' : 'Save'}</b>
            </div>
          </Button>
        ) : (
          <Button
            className={disableNext ? classes.buttonInactive : classes.buttonStep}
            disabled={disableNext}
            onClick={() => gotoStep(stepIndex + 1)}
          >
            <div className={classes.nextText}>Next</div>
          </Button>
        )}
      </div>
    </div>
  )
}
