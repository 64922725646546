import { useRemoteCollection } from '../../civic-champs-shared/api/hooks'
import { useDateRangeFilter } from '../../filtering/hooks'
import { useCallback, useEffect, useState } from 'react'
import { ProgramMatch, Tag } from '../types'
import useFetchMatches from './useFetchMatches'
import filter from 'lodash/filter'
import { useWithinRange } from '../../utils/useWithinRange'
import { CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import MatchFlowEditor, { CreateMatchForm } from '../components/MatchFlowEditor'
import useAddMatch from './useAddMatch'
import useTagManagement from './useTagsManagement'
import differenceBy from 'lodash/differenceBy'
import useEditMatch from './useEditMatch'
import useFetchPrograms from './useFetchPrograms'

export const useMentorshipMatchesCollection = () => {
  const [matches, operations, events] = useRemoteCollection<ProgramMatch>()
  const [dateRange] = useDateRangeFilter()
  const { syncCollection, eagerAdd, eagerReplace } = operations
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const [fetchMatches, { loading }] = useFetchMatches()
  const [withinRange] = useWithinRange(dateRange)
  const { addMatch } = useAddMatch()
  const { editMatch } = useEditMatch()
  const { linkTagsToMatch, unlinkTagsFromMatch } = useTagManagement()
  const [fetchPrograms, { result: programs }] = useFetchPrograms()
  useEffect(() => {
    fetchPrograms()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const showAddMatch = useShowPrompt(MatchFlowEditor)
  const addNewMatch = useCallback(
    () =>
      showAddMatch({
        label: 'Add a New Match',
        submit: ({ tags, ...rest }: Partial<ProgramMatch> & { tags: Tag[] }) =>
          addMatch(rest).then(async (match: ProgramMatch) => {
            if (tags.length) {
              await linkTagsToMatch(
                tags.map(({ id }) => id),
                match.id,
              )
            }
            eagerAdd({ ...match, tags, program: programs.find(({ id }) => id === match.programId) })
          }),
      }),
    [addMatch, eagerAdd, linkTagsToMatch, programs, showAddMatch],
  )
  const editExistingMatch = useCallback(
    (props: { id: number; initialValues: CreateMatchForm; editable: boolean }) =>
      showAddMatch({
        ...props,
        label: 'Edit Match',
        submit: ({ tags, ...rest }: Partial<ProgramMatch> & { tags: Tag[] }) =>
          editMatch(props.id, rest).then(async (match: ProgramMatch) => {
            const addTags = differenceBy(tags, match?.tags || [], 'id')
            const deleteTags = differenceBy(match?.tags || [], tags, 'id')
            if (addTags.length) {
              await linkTagsToMatch(
                addTags.map(({ id }) => id),
                match.id,
              )
            }
            if (deleteTags.length) {
              await unlinkTagsFromMatch(
                deleteTags.map(({ id }) => id),
                match.id,
              )
            }
            eagerReplace({ ...match, tags, program: programs.find(({ id }) => id === match.programId) })
          }),
      }),
    [eagerReplace, editMatch, linkTagsToMatch, programs, showAddMatch, unlinkTagsFromMatch],
  )

  useEffect(() => {
    fetchMatches().then(result => {
      syncCollection(filter(result as ProgramMatch[], ({ startedAt, stoppedAt }) => withinRange(startedAt, stoppedAt)))
      setInitiallyLoaded(true)
    })
  }, [dateRange, fetchMatches, syncCollection, withinRange])

  return [{ matches, loading, initiallyLoaded }, { addMatch: addNewMatch, editMatch: editExistingMatch }, events] as [
    { matches: ProgramMatch[]; loading: boolean; initiallyLoaded: boolean },
    {
      addMatch: () => Promise<void>
      editMatch: (props: { id: number; initialValues: CreateMatchForm; editable: boolean }) => Promise<void>
    },
    CollectionEventListeners<ProgramMatch>,
  ]
}
