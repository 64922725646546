import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const useCreateTask = () => {
  const organization = useCurrentOrg()
  const [request, { loading }] = useFetch()

  const createTask = useCallback(
    task => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/tasks`,
        config: {
          body: task,
        },
      })
    },
    [request, organization.id],
  )

  return { createTask, loading }
}

export default useCreateTask
