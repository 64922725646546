import React, { useState } from 'react'
import './index.scss'
import { Button, Modal, TextField, Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Popover from 'core/popover/Popover'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '30%',
    flexDirection: 'column',
    zIndex: '1000',
    padding: '20px 20px',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const NamePopOver = () => {
  return (
    <Popover>
      <div>Changing Name saves a new version of this Questionnaire.</div>
      <div>UPDATE changes the existing Questionnaire</div>
    </Popover>
  )
}

const ReusablePopOver = () => {
  return (
    <Popover>
      <div>This will add this questionnaire to your pool of</div>
      <div>questionnaires that you can use again.</div>
    </Popover>
  )
}

export const NameModal = (props) => {
  const { showing, questionSet, onSave, cancel, isExisting, showAnonymousCheckbox } = props
  const [name, setName] = useState(questionSet.name)
  const [anonymous, setAnonymous] = useState(!questionSet.anonymous)
  const classes = useStyles()
  return (
    <Modal open={showing} className={classes.modal}>
      <Grid container direction="column" className={classes.modalBody}>
        <Grid item>
          <Typography variant="h5">Save Questionnaires</Typography>
        </Grid>
        <Grid item container direction="row" alignItems="center">
          <Grid item>
            <span>Name</span>
          </Grid>
          {isExisting && (
            <Grid item>
              <NamePopOver />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <TextField
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '100%' }}
          />
        </Grid>
        {showAnonymousCheckbox && (
          <Grid item container direction="row" justify="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={!anonymous}
                  onChange={() => setAnonymous(!anonymous)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Save as a reusable questionnaire"
            />
            <ReusablePopOver />
          </Grid>
        )}
        <Grid item container direction="row" justify="flex-end" style={{ paddingTop: '24px' }}>
          <Grid item style={{ marginRight: '24px' }}>
            <Button onClick={cancel} variant="text">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => onSave({ name, anonymous })} variant="contained" color="primary" disabled={!name}>
              {!isExisting || name !== questionSet.name ? 'Save' : 'Update'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
