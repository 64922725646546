import React from 'react'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import { Field } from 'formik'
import TaskRequesterField from '../formik/TaskRequesterField'

export default function TaskRequester(props) {
    return (
        <div>
            <div>
                {
                    <Field
                        name="requester"
                        component={TaskRequesterField}
                    />
                }
                <StepNavigation />
            </div>
        </div>
    )
}

TaskRequester.SCHEMA_FIELDS = ['requester']