import moment from 'moment'

export const isCampaignActive = campaign => {
  const { publishedAt, startsAt, endsAt } = campaign

  if(!publishedAt) return false

  const now = moment()
  return moment(startsAt).isSameOrBefore(now) && moment(endsAt).isSameOrAfter(now)
}

//TODO move all this into a "useCampaignAudienceLabels" hook 
//     that produces a labels object from values
const LABELS = {
  TARGET_AUDIENCE: {
    'everyone': 'everyone',
    'current_donor': 'current donors',
    'never_donated': 'never donated'
  },
  DETAILED_TARGET_AUDIENCE: {
    'everyone': "Everyone (This will ask every volunteer)",
    'current_donor': "Current Donors (Volunteers who have previously donated)",
    'never_donated': "Never donated (Volunteers who have never donated)"
  },
  FREQUENCY: {
    'every_shift': 'Always',
    'once_a_week': 'Frequently',
    'once_a_month': 'Sometimes',
    'once_every_three_month': 'Rarely'
  }
}

export const labelAudience = value => LABELS.TARGET_AUDIENCE[value] || 'unknown'
export const labelAudienceDetailed = value => LABELS.DETAILED_TARGET_AUDIENCE[value] || 'unknown'
export const labelFrequency = value => LABELS.FREQUENCY[value] || 'unknown'
export const labelViewsPerUser = value => {
  const intValue = parseInt(value)
  if(intValue === 0) return "every time"
  else if(intValue === 1) return "only ask once"
  else return `stop asking after ${value} times`
}