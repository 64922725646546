import * as React from 'react'
import classNames from 'classnames'
import { CreateEventFields, EditEventFields } from '../../../../interfaces/interfaceCreateEditEvent'
import SummaryBasicInfo from './summary-basic-info/index'
import SummaryLocationInfo from './summary-location-info/index'
import SummaryShiftAndRoleInfo from './summary-shifts-info/index'

import './index.scss'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import { FORM_STEPS } from 'Event/constants/EVENT_FORM_STEPS'
import { getVisibilityLabel } from 'Event/interfaces'

export interface SummaryInfoProps {
  event: CreateEventFields | EditEventFields
  editMode?: boolean
  currentStep: number
}

const SummaryInfo = (props: SummaryInfoProps) => {
  const { event, currentStep, editMode = false } = props
  const sidebarClassNames = classNames('sidebar-title', { opacity: currentStep === 1 })

  return (
    <div className="summary-info-sidebar">
      <h3 className={sidebarClassNames}>Summary</h3>
      {currentStep === 1 && <span className="sidebar-placeholder-image" />}
      {currentStep >= FORM_STEPS.PARTICIPANTS && (
        <SummaryBasicInfo
          editMode={editMode}
          selectedRecurringType={event.recurring_type}
          startsAt={event.startsAt}
          endsAt={event.endsAt}
          name={event.name}
          isTest={event.isTest}
        />
      )}
      {currentStep >= FORM_STEPS.LOCATION && (
        <span className="visibility-info">Visibility: {getVisibilityLabel(event.visibility)}</span>
      )}
      {currentStep >= FORM_STEPS.SHIFTS && (
        <SummaryLocationInfo
          zip={event.zip}
          displayAddress={getDisplayAddressFromEvent(event)}
          coordinates={event.geofencing.location.coordinates}
        />
      )}
      {currentStep >= FORM_STEPS.QUESTIONS && (
        <SummaryShiftAndRoleInfo timeshifts={event.timeshifts} />
      )}
    </div>
  )
}

export default SummaryInfo
