import React from 'react'
import { Grid, Tabs, Tab, makeStyles, Typography } from '@material-ui/core'
import RequestersTab from './RequestersTab'
import VolunteersTab from './VolunteersTab'
import RequestsTab from './RequestsTab'
import CompletedTasksTab from './CompletedTasksTab'
import AddIcon from '@material-ui/icons/AddCircle'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { mdiNote } from '@mdi/js'
import { useHistory } from 'react-router'
import ActionMenu from 'components/menu/ActionMenu'
import ActionMenuItem from 'components/menu/ActionMenuItem'
import { withStyles } from '@material-ui/styles'
import useTaskRecipients from '../hooks/useTaskRecipients'
import useTaskVolunteers from '../hooks/useTaskVolunteers'
import useTaskRequesters from '../hooks/useTaskRequesters'
import RecipientsTab from './RecipientsTab'
import { Icon } from '@mdi/react'
import HelpingHandsQuestionSetModal from './HelpingHandsQuestionSetModal'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  actionMenu: {
    margin: '16px 0',
  },
  tabs: {
    backgroundColor: 'inherit !important',
    border: 'none',
    // get rid of underline
    '& > div > span': {
      display: 'none',
    },
    '& button:last-child': {
      width: '100%',
      maxWidth: 'calc(100% - 640px)',
      alignItems: 'flex-start',
    },
  },
  panel: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderTop: 'none',
    minHeight: 860,
    padding: theme.spacing(2),
  },
}))

const StyledTab = withStyles({
  root: {
    backgroundColor: '#EEF3FD',
    borderRadius: '4px 4px 0 0',
    border: '1px solid #5C8DE8',
    fontSize: '1.2em',
    textTransform: 'none',
    color: '#3d3d3d',
  },
  wrapper: {
    alignItems: 'flex-start',
  },
  selected: {
    background: 'white',
    borderBottom: 0,
    color: 'inherit !important',
  },
})(Tab)

const DropDownOption = ({ onClick, icon, label }) => React.useMemo(() => (
  <ActionMenuItem onClick={onClick}>
    <Grid container direction="row" alignItems="center">
      <Grid xs={2} alignItems="center">
        {icon}
      </Grid>
      <Grid xs={10} item container alignItems="center">
        <Typography style={{ marginLeft: 10 }}>{label}</Typography>
      </Grid>
    </Grid>
  </ActionMenuItem>
), [onClick, icon, label])

export default function HelpingHandsTabs(props) {
  const { query } = props
  const [currentTab, setCurrentTab] = React.useState(0)
  const [questionSetModalOpen, setQuestionSetModalOpen] = React.useState(false)
  const classes = useStyles()
  const { recipients, loading: recipientsLoading, onOpenAddRecipientPrompt } = useTaskRecipients()
  const {
    volunteers,
    loading: volunteersLoading,
    refresh: refreshVolunteers,
    onOpenAddVolunteerPrompt,
  } = useTaskVolunteers()
  const { requesters, loading: requestersLoading, onOpenAddRequesterPrompt } = useTaskRequesters()

  const handleTabChange = (event, tabIndex) => setCurrentTab(tabIndex)

  const history = useHistory()
  const onAddTask = () => {
    history.push('/tasks/new')
  }

  return (
    <>
      <HelpingHandsQuestionSetModal showing={questionSetModalOpen} close={() => setQuestionSetModalOpen(false)}/>
      <Grid container direction="column">
        <Grid item className={classes.actionMenu}>
          <ActionMenu>
            <DropDownOption label="Add Task" onClick={onAddTask} icon={<AddIcon />} />
            <DropDownOption label="Add Recipient" onClick={onOpenAddRecipientPrompt} icon={<PersonAdd />} />
            <DropDownOption label="Add Requester" onClick={onOpenAddRequesterPrompt} icon={<PersonAdd />} />
            <DropDownOption label="Add Volunteer" onClick={onOpenAddVolunteerPrompt} icon={<PersonAdd />} />
            {/* we need both HelpingHandsQuestionnaires and Groups, as the modal just link to a Groups page */}
            <FeatureEnabled code="HelpingHandsQuestionnaires">
              <FeatureEnabled code="Groups">
                <DropDownOption label="Questionnaires" onClick={() => setQuestionSetModalOpen(true)} icon={<Icon path={mdiNote} />} />
              </FeatureEnabled>
            </FeatureEnabled>
          </ActionMenu>
        </Grid>
        <Grid item>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <StyledTab label="Tasks" />
            <StyledTab label="Recipients" />
            <StyledTab label="Requesters" />
            <StyledTab label="Volunteers" />
            <StyledTab label="Closed Tasks" />
          </Tabs>

          <TabPanel className={classes.panel} value={currentTab} index={0}>
            <RequestsTab query={query} />
          </TabPanel>

          <TabPanel className={classes.panel} value={currentTab} index={1}>
            <RecipientsTab recipients={recipients} loading={recipientsLoading} query={query} />
          </TabPanel>

          <TabPanel className={classes.panel} value={currentTab} index={2}>
            <RequestersTab requesters={requesters} loading={requestersLoading} query={query} />
          </TabPanel>

          <TabPanel className={classes.panel} value={currentTab} index={3}>
            <VolunteersTab
              volunteers={volunteers}
              loading={volunteersLoading}
              query={query}
              refresh={refreshVolunteers}
            />
          </TabPanel>

          <TabPanel className={classes.panel} value={currentTab} index={4}>
            <CompletedTasksTab query={query} />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  )
}
