import React, { useState } from 'react'
import SalsaMigrationIssue from './SalsaMigrationIssue'
import { useFetchSalsaMigrationIssues } from '../hooks/useFetchSalsaMigrationIssues'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core'
import { set, unset, get } from 'lodash'

import Loading from '../../components/Loading'
import { MigrationResolutionMap, PopulatedMigrationIssue } from '../interfaces'

export default function SalsaMigrationIssuesModal(props: any) {
  const { open, onClose, classes, onSubmit } = props
  const { loading, migrationIssues } = useFetchSalsaMigrationIssues(open)
  const [migrationResolutionMap, setMigrationResolutionMap] = useState<MigrationResolutionMap>({})
  const [submitting, setSubmitting] = useState<boolean>(false)
  const onSelect = (salsaMigrationIssueId: number, selectedId: string | number | null) => {
    let updatedMigrationResolutionMap = { ...migrationResolutionMap }
    if (selectedId) {
      set(updatedMigrationResolutionMap, salsaMigrationIssueId, selectedId)
    } else {
      unset(updatedMigrationResolutionMap, salsaMigrationIssueId)
    }
    setMigrationResolutionMap(updatedMigrationResolutionMap)
  }

  const submit = async () => {
    try {
      setSubmitting(true)
      await onSubmit(migrationResolutionMap)
      setMigrationResolutionMap({})
      onClose()
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center', paddingBottom: '6px' }}>
        <b>Connecting Volunteer Issues</b>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            <p className={classes.orangeText} style={{ margin: '0 auto' }}>
              {migrationIssues.length} issue{migrationIssues.length > 1 ? 's' : ''} to resolve
            </p>
            <p style={{ margin: '0 auto' }}>Please help us match any volunteers below you know should be linked.</p>
          </div>
        )}
        {migrationIssues.map((migrationIssue: PopulatedMigrationIssue, index: number) => (
          <div key={index}>
            <SalsaMigrationIssue
              classes={classes}
              onSelect={onSelect}
              migrationIssue={migrationIssue}
              currentResolutionSelection={get(migrationResolutionMap, migrationIssue.salsaMigrationIssueId)}
            />
            <Divider />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelButton} onClick={onClose}>
          Cancel
        </Button>
        <Button className={classes.submitButton} disabled={loading || submitting} onClick={submit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
