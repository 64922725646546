import React from 'react'
import { Card, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import useFormatToOrgTime from 'Event/scenes/events/hooks/useFormatToOrgTime'
import moment from 'moment'
import ReactGoogleMap from 'components/ReactGoogleMap'
import cn from 'classnames'
import ExpandingText from 'components/ExpandingText'
import { PersonInfo } from 'volunteering/components/PersonInfo'

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(2),
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  locationRow: {
    height: '300px',
  },
  text: {
    lineHeight: '2rem',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}))

export default function TaskReview(props) {
  const { values } = props
  const {
    location: { address, geofencing },
  } = values
  const classes = useStyles()
  const formatDate = useFormatToOrgTime()
  return (
    <div>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.row}>
        <Grid item>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.header}>Status</Typography>
          <Typography className={classes.text}>Pending Creation</Typography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container item direction="row" spacing={2} className={classes.row}>
        <Grid item container xs={4}>
          <Grid item>
            <Typography className={classes.header}>Service Type</Typography>
            <Typography className={classes.text}>{values.volunteeringRole.name}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.header}>Description</Typography>
          <ExpandingText text={values.description} />
        </Grid>
      </Grid>

      <Divider />

      <Grid container item direction="row" spacing={2} className={classes.row}>
        <Grid item xs={4}>
          <Typography className={classes.header}>Needed by</Typography>
          <Typography className={classes.text}>
            {formatDate(moment.max(values.timeslots.map(ts => ts.endTime)), 'MM/DD/YYYY hh:mma')}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.header}>Available scheduling timeslots</Typography>
          {values.timeslots.map(ts => (
            <Typography className={classes.text} key={ts.startTime}>
              {moment(ts.startTime).format('MM/DD/YYYY hh:mma')} - {moment(ts.endTime).format('hh:mma')}
            </Typography>
          ))}
        </Grid>
      </Grid>

      <Divider />

      <Grid container item direction="row" spacing={4} className={classes.row}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <PersonInfo person={values.recipient} title="Recipient" />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <PersonInfo person={values.requester} title="Requester" />
          </Card>
        </Grid>
      </Grid>

      <Divider />

      <Grid container item direction="row" spacing={2} className={cn(classes.locationRow, classes.row)}>
        <Grid item xs={4}>
          <Typography className={classes.header}>Location Address</Typography>
          <Typography className={classes.text}>{address.line1}</Typography>
          <Typography className={classes.text}>
            {address.city}, {address.state} {address.zipCode}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <ReactGoogleMap coordinates={geofencing.position.coordinates} />
        </Grid>
      </Grid>

      <div>
        <StepNavigation />
      </div>
    </div>
  )
}
