import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MultiStepNavButton from '../navigation-button-area'
import './index.scss'
import { QuestionSetPicker } from 'question-sets/components/QuestionSetPicker'
import { WaiverPicker } from 'question-sets/components/WaiverPicker'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import { GroupMembership } from './GroupMembership'
import { useLocation } from 'react-router-dom'

const CustomQuestionModule = props => {
  const {
    onNextClick,
    onPrevClick,
    onSaveDraftClick,
    isSendingDraftRequest,
    isQuestionnaireAsSurveyEnabled,
    event,
    onBeforeAddEdit,
  } = props
  const location = useLocation()
  const [questionSetsValid, setQuestionSetsValid] = useState(true)
  const [waiversValid, setWaiversValid] = useState(true)
  const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'

  const { published, requiredCredentials, visibility } = event

  const prevClick = () => {
    if (questionSetsValid && waiversValid) {
      onPrevClick()
    }
  }

  const nextClick = () => {
    if (questionSetsValid && waiversValid) {
      onNextClick()
    }
  }

  const selectQuestionSets = formikProps => {
    const { values, isValid } = formikProps
    setQuestionSetsValid(isValid)
    // TODO: fix because returning isValid: true even when it's just questionSetId: '', name: '', required: ''
    if (isValid && values.questionSets.every(questionSet => !!questionSet[key])) {
      props.setEventField(
        'questionSets',
        values.questionSets.map(questionSet => ({ ...questionSet, required: !questionSet.optional })),
      )
    }
  }

  const selectWaivers = formikProps => {
    const { values, isValid } = formikProps
    setWaiversValid(isValid)
    if (isValid) {
      props.setEventField(
        'requiredCredentials',
        values.credentials.map(credential => ({
          id: credential.credential.id,
          title: credential.version.title,
          requiresReview: false,
        })),
      )
    }
  }

  return (
    <div className="custom-question-module">
      <FeatureEnabled code="Groups">
        <h2 className="custom-question-module__title">Group Membership</h2>
        <GroupMembership
          visibility={visibility}
          onChange={val => props.setEventField('onboardingGroups', val)}
          onboardingGroups={event.onboardingGroups}
        />
      </FeatureEnabled>
      <div className="custom-question-module__waiver-container">
        <WaiverPicker
          title={<h2 className="custom-question-module__list-title">Waivers</h2>}
          value={requiredCredentials.map(credential => credential.id)}
          onChange={selectWaivers}
        />
      </div>
      <div className="custom-question-module__container">
        <QuestionSetPicker
          loading={false}
          showRequired
          title={<h2 className="custom-question-module__list-title">Questionnaire</h2>}
          value={event.questionSets.map(opportunityOccurrenceQuestionSet => ({
            ...opportunityOccurrenceQuestionSet,
            optional: !opportunityOccurrenceQuestionSet.required,
          }))}
          onChange={selectQuestionSets}
          onBeforeAddEdit={onBeforeAddEdit}
          backLink={location.pathname}
          showAnonymousCheckbox
        />
      </div>
      <MultiStepNavButton
        isShowDraftButton={!published}
        draftButtonText="Update Draft"
        onNextClick={nextClick}
        onPrevClick={prevClick}
        onSaveDraftClick={onSaveDraftClick}
        isSendingRequest={isSendingDraftRequest}
      />
    </div>
  )
}

CustomQuestionModule.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  isSendingDraftRequest: PropTypes.bool,
  isQuestionnaireAsSurveyEnabled: PropTypes.bool,
  event: PropTypes.object,
  onBeforeAddEdit: PropTypes.func,
}

export default CustomQuestionModule
