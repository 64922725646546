import React, { useState, useCallback } from 'react';
import { Typography, Stepper, StepLabel, StepContent, Step } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel'
import useStyles from '../hooks/useMyUserStyles';
import { useFetch } from 'civic-champs-shared/api/hooks'
import CheckExistingUsersStep from './CheckExistingUsersStep';
import SelectUserStep from './SelectUserStep';
import CreateNewUserStep from './CreateNewUserStep';
import AddUserStep from './AddUserStep';
import { useCurrentOrg } from '../../auth/hooks'

export const EXISTING_USER = 'EXISTING_USER'
export const NEW_USER = 'NEW_USER'

export default function AddUserFlow(props) {
    const classes = useStyles();

    const { userLabel="User", onSubmit, submitting, error: submitError, onCancel, onDone } = props;

    const [activeStep, setActiveStep] = useState(0)
    const [skippedCreateUser, setSkippedCreatedUser] = useState(false)

    const [filter, setFilter] = useState({ email: '', phone: '' });
    const onFilterChanged = useCallback( change => {
        setFilter( prev => ({ ...prev, ...change }) )
    }, [setFilter])

    const [provided, setProvided] = useState({})
    const [request, { loading, result: foundUsers, error: searchError }] = useFetch();
    const currentOrg = useCurrentOrg()
    const searchForUsers = useCallback( (params) => {
        request({
            method: 'get',
            url: `/organizations/${currentOrg.id}/people`,
            config: {
                queryStringParameters: params
            }
        })

        setProvided(params)
        setActiveStep(1)
    }, [request]);

    const handleBack = useCallback(() => {
        if(activeStep === 3) {
            if(skippedCreateUser) {
                setSkippedCreatedUser(false)
                setActiveStep(1)
            } else {
                setUser(null)
                setActiveStep(2)
            }
        } else if(activeStep > 0) {
            setActiveStep( activeStep -1 )
        }
    }, [activeStep, skippedCreateUser])

    const [user, setUser] = useState(null)

    const onSelectExisting = useCallback( (user, sendInvite) => {
        onSubmit({ type: EXISTING_USER, userId: user.id, sendInvite })
        setUser(user)
        setActiveStep(3)
        setSkippedCreatedUser(true)
    }, [setActiveStep, onSubmit])

    const onSelectNew = useCallback( () => {
        setUser(prev => prev != null ? prev : {
            givenName: '',
            familyName: '',
            email: provided.email || '',
            phone: provided.phone || '',
            sendInvite: true
        })
        setActiveStep(2)
    }, [provided.email, provided.phone])

    const onCreate = useCallback( userData => {
        setUser(userData)
        onSubmit({ type: NEW_USER, ...userData })
        setActiveStep(3)
    }, [onSubmit])

    return (
        <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
                <StepLabel>
                  <Typography className={(classes.sectionLabel)}>Check Existing Users</Typography>
                </StepLabel>
                <StepContent>
                    <CheckExistingUsersStep
                        userLabel={userLabel}
                        filter={filter}
                        onFilterChanged={onFilterChanged}
                        onNext={searchForUsers}
                    />
                </StepContent>
            </Step>
            <Step>
                {(activeStep < 2 || skippedCreateUser)
                    ? <StepLabel>Select User</StepLabel>
                    : <StepLabel icon={<CancelIcon color="primary"/>}>Select User</StepLabel>}
                <StepContent>
                    <SelectUserStep
                        userLabel={userLabel}
                        users={foundUsers}
                        loading={loading}
                        error={searchError}
                        providedContact={filter}
                        onBack={handleBack}
                        onSelectExisting={onSelectExisting}
                        onSelectNew={onSelectNew}
                    />
                </StepContent>
            </Step>
            <Step>
                {!skippedCreateUser
                    ? (<StepLabel
                            optional={
                                activeStep < 2 &&
                                <Typography variant="caption">If Needed</Typography>
                            }
                       >
                            Create New User
                        </StepLabel>
                    ) : (<StepLabel icon={<CancelIcon color="primary" />}>
                            Create New User
                        </StepLabel>)}
                <StepContent>
                    <CreateNewUserStep
                        userLabel={userLabel}
                        initialValues={user}
                        onBack={handleBack}
                        provided={provided}
                        onCreate={onCreate}
                    />
                </StepContent>
            </Step>
            <Step>
                <StepLabel>Add {userLabel}</StepLabel>
                <StepContent>
                    <AddUserStep
                        userLabel={userLabel}
                        user={user}
                        submitting={submitting}
                        error={submitError}
                        onBack={handleBack}
                        onCancel={onCancel}
                        onDone={onDone}
                    />
                </StepContent>
            </Step>
        </Stepper>
    )
}
