import { get } from 'lodash'
import React, { useState } from 'react'
import Label from 'Event/components/label'
import TabSwitcher from 'core/tab-switcher'
import './style.scss'
import ProgressBar from 'Event/components/progress-bar'
import Loader from 'Event/components/loader'
import EventDetailsHeader from 'Event/modules/events/details/header'
import StaticGoogleMap from 'Event/components/static-google-map'
import Button from 'components/button'
import OpportunityVisibility from './private-token/OpportunityVisibility'
import format from 'civic-champs-shared/utils/format'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import { MIN_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import { useDateStrings } from '../hooks/useDateStrings'
import { RECURRING_TYPE } from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { EditEndDateModal } from 'Event/modules/events/create-edit/basic-info/EditEndDateModal'
import { useFeatureEnabled } from 'core/feature/hooks'
import ReactGoogleMap from 'civic-champs-shared/core/location/ReactGoogleMap'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'
import { useGetEvent } from 'Event/hooks/useGetEvent'
import { createMarkup } from 'civic-champs-shared/core/utils/helpers'

function EventDetails(props: any) {
  const { orgId, encodedOccurrence } = props.match.params
  const [showDynamicMap, setShowDynamicMap] = useState(false)
  const [eventsPage] = useEventsPageState()

  const { event, loading } = useGetEvent(encodedOccurrence) as any
  const { recurrenceString, opportunityTimeDisplay } = useDateStrings({ event, loading })
  const showEditEndDateModal = useShowPrompt(EditEndDateModal)
  const changeEndDateEnabled = useFeatureEnabled('ChangeEndDate')
  if (loading) {
    return <Loader />
  }
  const endDateEditable =
    changeEndDateEnabled && ![RECURRING_TYPE.NEVER, RECURRING_TYPE.CUSTOM_EVENTS].includes(event.recurring_type)
  const signupsSummaryTotal = get(event, 'timeshifts').reduce(
    (acc: number, item: any) => acc + item.roles.reduce((roleAcc: any, role: any) => roleAcc + role.all, 0),
    0,
  )

  const signupsSummaryCurrent =
    event.timeshifts &&
    signupsSummaryTotal -
      event.timeshifts.reduce(
        (acc: number, item: any) => acc + item.roles.reduce((roleAcc: any, role: any) => roleAcc + role.available, 0),
        0,
      )

  const volunteers =
    event.timeshifts &&
    event.timeshifts
      .map((shift: any) =>
        shift.roles.map((role: any) => {
          return { name: role.name, available: role.all - role.available, all: role.all }
        }),
      )
      .flat()

  const { coordinates } = event.geofencing.location
  const geofenceRadius = (event.geofencing && event.geofencing.radius) || MIN_RADIUS_IN_METER
  const handleShowDynamicMap = () => setShowDynamicMap(true)

  const renderGoogleMaps = () =>
    showDynamicMap ? (
      <ReactGoogleMap zoom={16} geofenceRadius={geofenceRadius} coordinates={coordinates} />
    ) : (
      <>
        <StaticGoogleMap zoom={17} coordinates={coordinates} />
        <div className="left_side_map--overlay">
          <Button onClick={handleShowDynamicMap} size="small">
            Explore More
          </Button>
        </div>
      </>
    )

  const tabDetails = [
    { to: `/events/${encodedOccurrence}/${orgId}`, text: 'Event Summary' },
    { to: `/events/${encodedOccurrence}/${orgId}/volunteers`, text: 'Volunteers' },
  ]

  return (
    <article className="section-scene">
      <div className="detail_event_block">
        <div
          className="detail_event_block_back_arrow"
          onClick={() => props.history.push(eventsPage === EventPages.Calendar ? '/events' : '/list-of-events')}
        >
          <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
          {get(props.history.location.state, 'goBackTitle', 'Events')}
        </div>
        <div>
          <EventDetailsHeader event={event} encodedOccurrence={encodedOccurrence} isDraft={!!event.timeshifts.length} />

          <TabSwitcher linkDetails={tabDetails} />
          <div className="event_summary_container">
            <div className="left_side">
              <div className="left_side_label">
                <Label
                  title="WHEN"
                  icon={<img src="/assets/icons/place.svg" alt="place" style={{ marginRight: 9 }} />}
                  color="#5c8de8"
                  className="left_side_label_item"
                />
                <div className="left_side_label_text">{opportunityTimeDisplay}</div>
                <div className="left_side_label_text">
                  <i>{recurrenceString}</i>
                </div>
                <div>
                  {endDateEditable && (
                    <div className="base-info-form-row multi-date-row">
                      <Button
                        onClick={() =>
                          showEditEndDateModal({ recurrenceInfo: event.recurrenceInfo, encodedOccurrence })
                        }
                      >
                        Edit End Date
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="left_side_label">
                <Label
                  title="WHERE"
                  icon={<img src="/assets/icons/place.svg" alt="place" style={{ marginRight: 9 }} />}
                  color="#5c8de8"
                  className="left_side_label_item"
                />
                <div className="left_side_label_text">{getDisplayAddressFromEvent(event)}</div>
              </div>
              <div className="left_side_map left_side_map--hover">{renderGoogleMaps()}</div>
              <div className="left_side_progress_bar">
                <div className="left_side_progress_bar_title">Signups Summary:</div>
                <ProgressBar total={signupsSummaryTotal} current={signupsSummaryCurrent} />
              </div>
              <div className="left_side_volunteers">
                <div className="left_side_volunteers_title">Volunteers:</div>
                {volunteers &&
                  volunteers.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        {item.name}: {item.available} / {item.all}
                      </div>
                    )
                  })}
              </div>
            </div>
            <address className="right_side">
              <label className="right_side_title">Description:</label>
              <div dangerouslySetInnerHTML={createMarkup(event.description)} />
              <label className="right_side_title gap">Day-of Event Instructions for Volunteers:</label>
              <div dangerouslySetInnerHTML={createMarkup(event.instructions)} />
              <label className="right_side_title gap">Point Of Contact</label>
              {(event.contact_name || event.contact_email || event.contact_phone_number) && (
                <div>
                  {event.contact_name && <span>{event.contact_name}</span>}
                  {event.contact_email && (
                    <span>
                      {' '}
                      -&nbsp;<b>{event.contact_email}</b>
                    </span>
                  )}
                  {event.contact_phone_number && <span> -&nbsp;{format.phoneNumber(event.contact_phone_number)}</span>}
                </div>
              )}
              <div className="visibility_container">
                <OpportunityVisibility opportunity={event} encodedOccurrence={encodedOccurrence} />
              </div>
              {event.isTest && <label className="right_side_title">Test Event</label>}
            </address>
          </div>
        </div>
      </div>
    </article>
  )
}

export default React.memo(EventDetails)
