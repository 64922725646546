import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import Loader from '../../Event/components/loader'
import {BUTTON_COLORS} from './BUTTON_COLORS'

import './index.scss'

interface Props {
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  inline?: boolean
  style?: object
  onClick?: () => void
  customTextColor?: string
  customBackgroundColor?: string
  customBorderColor?: string
  size?: 'small' | 'big'
  isLoading?: boolean
  withIcon?: boolean
  children?: any
  color?: 'primary' | 'secondary'
  type?: 'text' | 'outlined' | 'contained'
}

const StyledButton = styled<any>('button')`
  ${props => props.normal}
  ${props => props.boxShadow}
  :hover {
    text-decoration: ${props => (props.buttonType === 'text' ? 'underline' : 'none')};
    ${props => props.hover}
  }
  :focus {
    text-decoration: ${props => (props.buttonType === 'text' ? 'underline' : 'none')};
    ${props => props.focus}
  }
`

const Button = (props: Props) => {
  const {
    className,
    disabled,
    fullWidth,
    inline,
    style,
    onClick,
    customTextColor,
    customBackgroundColor,
    customBorderColor,
    size,
    isLoading,
    withIcon,
    children,
    color = 'primary',
    type = 'contained',
  } = props

  const classes = classNames('custom-button-component', {
    [`${className}`]: className,
    disabled: disabled || isLoading,
    'full-width': fullWidth,
    inline: inline,
    'size-small': size === 'small',
    'size-big': size === 'big',
  })

  const mainButtonColors = BUTTON_COLORS[color][type]

  const GenerateColorStyle = (buttonState: any) => ({
    textColor: customTextColor ? customTextColor : mainButtonColors[buttonState]['textColor'],
    bgColor: customBackgroundColor ? customBackgroundColor : mainButtonColors[buttonState]['bgColor'],
    borderColor: customBorderColor ? customBorderColor : mainButtonColors[buttonState]['borderColor'],
  })

  const GenerateButtonStyle = (buttonState: any) => `
    color: ${GenerateColorStyle(buttonState)['textColor']};
    background-color: ${GenerateColorStyle(buttonState)['bgColor']};
    border: 1px solid ${GenerateColorStyle(buttonState)['borderColor']};
  `

  const setBoxShadow =
    size === 'big' &&
    color === 'primary' &&
    type === 'contained' &&
    `
    -webkit-box-shadow: 3px 3px 0px 0px rgba(220,177,0,1);
       -moz-box-shadow: 3px 3px 0px 0px rgba(220,177,0,1);
            box-shadow: 3px 3px 0px 0px rgba(220,177,0,1);
  `
  return (
    <StyledButton
      normal={GenerateButtonStyle('normal')}
      hover={GenerateButtonStyle('hover')}
      focus={GenerateButtonStyle('focus')}
      buttonType={type}
      boxShadow={setBoxShadow}
      onClick={onClick}
      disabled={disabled || isLoading}
      style={style}
      className={classes + ` ${color + '-' + type}`}
      type="button"
    >
      {withIcon && !isLoading && <span className="button-plus-icon" />}
      {isLoading && <Loader width={18} height={18} color={GenerateColorStyle('normal')['textColor']} />}
      {children}
    </StyledButton>
  )
}

export default Button
