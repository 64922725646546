import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback, useEffect, useState } from 'react'
import TaskStatus from '../TaskStatus'
import * as _ from 'lodash'

const COMPLETED_STATUSES = [TaskStatus.Completed, TaskStatus.ClaimedCompleted]
const CLOSED_STATUSES = [...COMPLETED_STATUSES, TaskStatus.Canceled, TaskStatus.NotAccepted]

const isPersonOnTask = (task, personId) =>
  Number(_.get(task, 'volunteer.id')) === Number(personId) ||
  Number(_.get(task, 'recipient.id')) === Number(personId) ||
  Number(_.get(task, 'requester.id')) === Number(personId)

const useTasks = ({ personId } = {}) => {
  const organization = useCurrentOrg()
  const [request, { result, loading }] = useFetch()
  const [tasks, setTasks] = useState([])
  const [completedTasks, setCompletedTasks] = useState([])
  const [closedTasks, setClosedTasks] = useState([])

  const getTasks = useCallback(async () => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/tasks`,
    })
  }, [organization.id, request])

  useEffect(() => {
    getTasks()
  }, [getTasks])

  useEffect(() => {
    if (result) {
      const filteredResults = personId ? result.filter(task => isPersonOnTask(task, personId)) : result
      setTasks(filteredResults.filter(r => !CLOSED_STATUSES.includes(r.taskStatus)))
      setClosedTasks(filteredResults.filter(r => CLOSED_STATUSES.includes(r.taskStatus)))
      setCompletedTasks(filteredResults.filter(r => COMPLETED_STATUSES.includes(r.taskStatus)))
    }
  }, [result, personId])

  //TODO errors!
  return {
    tasks,
    closedTasks,
    completedTasks,
    loading,
    refresh: getTasks,
  }
}

export default useTasks
