import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useRemoveVolunteer from './useRemoveVolunteer'
import { useCallback } from 'react'
import { RemoveVolunteersConfirmDialog } from 'volunteering/volunteers/components/RemoveVolunteersConfirmDialog'
import pickBy from 'lodash/pickBy'

const useRemoveVolunteerPrompt = (eagerRemoveMany: any) => {
  const showPrompt = useShowPrompt(RemoveVolunteersConfirmDialog)
  const removeVolunteer = useRemoveVolunteer(eagerRemoveMany)

  return useCallback(
    async volunteers => {
      const include = await showPrompt({ volunteers })
      await removeVolunteer(volunteers, Object.keys(pickBy(include)))
    },
    [showPrompt, removeVolunteer],
  )
}

export default useRemoveVolunteerPrompt
