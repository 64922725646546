import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const usePublishCampaign = () => {
  const [request, status] = useFetch()
  const publishCampaign = useCallback(
    campaign => {
      const { id, organization, createdAt, publishedAt, ...body } = campaign
      return request({
        method: 'post',
        url: `/campaigns/${campaign.id}/publish`,
        config: { body },
      })
    },
    [request],
  )

  return [publishCampaign, status]
}

export default usePublishCampaign
