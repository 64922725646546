import React from 'react';
import {makeStyles, Tab, Tabs} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import {ProcessMemberAnswerSetContainer} from './ProcessMemberAnswerSetContainer';
import {ProcessMemberWaiverContainer} from "./ProcessMemberWaiverContainer";

const useStyles = makeStyles(theme => ({
    actionMenu: {
        margin: '16px 0',
    },
    tabs: {
        backgroundColor: 'inherit !important',
        border: 'none',
        // get rid of underline
        '& > div > span': {
            display: 'none',
        },
        '& button:first-child': {
            width: '100%',
            maxWidth: 'calc(100% - 600px)',
            alignItems: 'flex-start',
        },
    },
    panel: {
        border: '1px solid',
        borderColor: theme.palette.secondary.main,
        borderTop: 'none',
        padding: theme.spacing(2),
    },
    fullHeight: {
        height: "93%"
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{height: props.fullHeight ? "100%" : "auto"}}
            {...other}
        >
            {value === index && <div style={{height: props.fullHeight ? "100%" : "auto"}}>{children}</div>}
        </div>
    )
}

const StyledTab = withStyles({
    root: {
        backgroundColor: '#EEF3FD',
        borderRadius: '4px 4px 0 0',
        border: '1px solid #5C8DE8',
        fontSize: '1.2em',
        textTransform: 'none',
        color: '#3d3d3d',
    },
    wrapper: {
        alignItems: 'flex-start',
    },
    selected: {
        background: 'white',
        borderBottom: 0,
        color: 'inherit !important',
    },
})(Tab)

const ProcessMemberTabs = (props) => {
    const [currentTab, setCurrentTab] = React.useState(0)
    const handleTabChange = (event, tabIndex) => setCurrentTab(tabIndex)
    const classes = useStyles()

    return (
        <div className={props.fullHeight ? classes.fullHeight : ""}>
            <Tabs
                value={currentTab}
                indicatorColor="primary"
                textColor="primary"
                className={classes.tabs}
                onChange={handleTabChange}
                variant="fullWidth"
            >
                <StyledTab label="Questionnaires"/>
                <StyledTab label="Waivers"/>
            </Tabs>

            <TabPanel className={classes.panel} value={currentTab} index={0} fullHeight={props.fullHeight} >
                <ProcessMemberAnswerSetContainer filters={props.filters} fullHeight={props.fullHeight}/>
            </TabPanel>

            <TabPanel className={classes.panel} value={currentTab} index={1} fullHeight={props.fullHeight}>
                <ProcessMemberWaiverContainer filters={props.filters} fullHeight={props.fullHeight}/>
            </TabPanel>

        </div>
    );
};

export default ProcessMemberTabs;
