import React from 'react'
import { DialogTitle as MuiDialogTitle, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface DialogTitleProps {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    // @ts-ignore
    color: theme.palette.neutral.black,
  },
  dialogCloseIcon: {
    width: '1.5em',
    height: '1.5em',
  },
}))

export const DialogTitle = ((props: DialogTitleProps) => {
  const { onClose, ...other } = props
  const classes = useStyles()

  return (
    <MuiDialogTitle disableTypography {...other} className={classes.dialogTitle}>
      {onClose ? (
        <IconButton className={classes.dialogCloseButton} onClick={onClose}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

