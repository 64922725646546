import { Field } from 'formik'
import React from 'react'
import TaskVolunteerField from '../formik/TaskVolunteerField'
import { FormikWizard, Step } from 'civic-champs-shared/formik/FormikWizard'
import BackLink from 'donation/campaigns/components/BackLink'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import { Typography, makeStyles } from '@material-ui/core'
import { useAssignVolunteerTask } from '../hooks'
import { useHistory } from 'react-router'
import useTaskTimeslots from '../hooks/useTaskTimeslots'
import moment from 'moment'
import cn from 'classnames'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import * as _ from 'lodash'
import { addVolunteerSchema } from '../task.schema'

const useStyles = makeStyles(theme => ({
  timeSlot: {
      border: '1px solid',
      borderColor: '#707070',
      borderRadius: '2px',
      padding: 8,
      margin: 8,
      cursor: 'pointer',
  },
  selected: {
      background: theme.palette.secondary.main,
      color: 'white'
  },
  text: {
    lineHeight: '2rem'
  },
  header: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
  },
  error: {
    color: theme.palette.danger.main
  }
}))


const VolunteerStep = (props) => {
  return (
    <>
      <Field
        name='volunteer'
        component={TaskVolunteerField}
      />
      <StepNavigation />
    </>
  )
}

const TimeSlotStep = (props) => {
  const { timeslots, setFieldValue, values: { timeslot }, errors } = props
  const classes = useStyles()
  const org = useCurrentOrg()
  const tz = _.get(org, 'timeZone', 'America/New_York')

  return (
    <>
      <Typography variant="body1">Select a time to perform the task</Typography>
      {timeslots.map(ts => (
        <div
          className={cn(classes.timeSlot, {
            [classes.selected]: timeslot && timeslot.id === ts.id,
          })}
          onClick={() => setFieldValue('timeslot', ts)}
        >
          {moment(ts.startTime).tz(tz).format('MM/DD/YYYY hh:mma')} - {moment(ts.endTime).tz(tz).format('hh:mma')}
        </div>
      ))}
      {errors.timeslot && <p className={classes.error}>{errors.timeslot}</p>}
      <StepNavigation />
    </>
  )
}

const ReviewStep = (props) => {
  const { values: { volunteer, timeslot } } = props
  const classes = useStyles()
  const org = useCurrentOrg()
  const tz = _.get(org, 'timeZone', 'America/New_York')

  return (
    <>
      <Typography variant='h4'>Review</Typography>
      <Typography className={classes.header}>Volunteer</Typography>
      <Typography className={classes.text}>{volunteer.givenName} {volunteer.familyName}</Typography>
      <Typography className={classes.header}>Time</Typography>
      <Typography className={classes.text}>
        {moment(timeslot.startTime).tz(tz).format('MM/YY/YYYY hh:mma')} -  {moment(timeslot.endTime).tz(tz).format('hh:mma')}
      </Typography>
      <StepNavigation />
    </>
  )
}

export default function TaskAddVolunteer(props) {
  const { id: taskId } = props.match.params
  const history = useHistory()
  const { timeslots } = useTaskTimeslots(taskId)
  const [ assignVolunteer ] = useAssignVolunteerTask()
  const showError = useErrorNotification()

  const handleSubmit = async (form) => {
    try {
      await assignVolunteer({ id: taskId }, {
        startTime: form.timeslot.startTime,
        endTime: form.timeslot.endTime,
        volunteerId: form.volunteer.id,
      })

      history.goBack()
    } catch (error) {
      showError('There was a problem assigning the volunteer', error)
    }
  }

  return (
    <div>
      <BackLink to="/helping-hands" text="Back to Helping Hands Overview" />
      <FormikWizard
        initialValues={{}}
        onPublish={handleSubmit}
        publishButtonLabel="Assign Volunteer"
        validationSchema={addVolunteerSchema}
      >
        <Step name="Volunteer" component={VolunteerStep} schemaFields={SCHEMA_FIELDS.volunteer} />
        <Step name="Timeslot" schemaFields={SCHEMA_FIELDS.timeSlot}>
          {stepProps => <TimeSlotStep timeslots={timeslots} {...stepProps} />}
        </Step>
        <Step name="Review" component={ReviewStep} />
      </FormikWizard>
    </div>
  )
}

const SCHEMA_FIELDS = {
  volunteer: ['volunteer'],
  timeSlot: ['timeslot'],
}
