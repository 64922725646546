import { get } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Paper } from '@material-ui/core'

import { startKiosk } from '../actions'
import { useTableSearch } from 'components/table/hooks'
import useStartKioskColumns from 'kiosk/hooks/useStartKioskColumns'
import Loading from 'components/Loading'
import DataTable from 'components/DataTable'
import AddressCard from 'components/AddressCard'
import ReactGoogleMap from 'components/ReactGoogleMap'
import useKioskOpportunities from 'kiosk/hooks/useKioskOpportunities'
import Search from 'core/table/components/Search'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { mapEventGeofencingToGeofencing } from 'utils/event'

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true,
  },
}

export default function StartKiosk(props) {
  const [opportunity, setOpportunity] = useState(null)

  const dispatch = useDispatch()

  const handleStartKiosk = useCallback(() => dispatch(startKiosk(opportunity)), [opportunity, dispatch])

  const { opportunities, loading } = useKioskOpportunities()
  const { setQuery, results: filteredOpportunities } = useTableSearch(opportunities, searchConfig)

  const columns = useStartKioskColumns()
  const geofencing = get(opportunity, 'geofencing')
  const {
    location: { coordinates },
  } = geofencing ? mapEventGeofencingToGeofencing(geofencing) : DEFAULT_GEOFENCING

  return (
    <div>
      <Grid container direction="column">
        <Grid container xs={12} item alignItems="center" justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Typography variant="h4" gutterBottom>
              Kiosk Mode
            </Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={6}>
              <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="subtitle2" gutterBottom>
          Please choose the opportunity below to start your Kiosk Mode Session.
        </Typography>
      </Grid>
      {!loading ? (
        <Grid container spacing={2} direction="row">
          <Grid item xs>
            <Paper>
              <DataTable columns={columns} data={filteredOpportunities} onSelectedChanged={setOpportunity} />
            </Paper>
          </Grid>
          <Grid item xs>
            <AddressCard
              opportunity={opportunity}
              openDialogWindow={handleStartKiosk}
              isRowSelected={opportunity != null}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div style={{ height: '400px' }}>
                    <ReactGoogleMap coordinates={coordinates} />
                  </div>
                </Grid>
              </Grid>
            </AddressCard>
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}
    </div>
  )
}
