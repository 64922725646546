import { Chat, ChatApiResponse } from 'chats/interfaces'
import { DeleteChatsResult } from 'chats/hooks'
import reduce from 'lodash/reduce'
import { FilePayload, MessagePayload } from '@pubnub/react-chat-components'

import format from 'civic-champs-shared/utils/format'

export const mapChat = ({ createdAt, lastActivityAt, ...rest }: ChatApiResponse): Chat => ({
  ...rest,
  createdAt: new Date(createdAt),
  lastActivityAt: new Date(lastActivityAt),
})

export const isAllResponsesSuccessful = (result: DeleteChatsResult) =>
  reduce(Object.values(result), (acc, val) => acc && val, true)

export const getNameInitials = (name: string): string => {
  if (!name || !name.length) return ''
  const nameSplit = name.split(' ')
  const initials = nameSplit.length === 1 ? nameSplit[0].substring(0, 2) : nameSplit[0][0] + nameSplit[1][0]
  return initials.toUpperCase()
}

export const getPredefinedColor = (uuid: string): string => {
  if (!uuid || !uuid.length) {
    return ''
  }
  const colors = ['#80deea', '#9fa7df', '#aed581', '#ce93d8', '#ef9a9a', '#ffab91', '#ffe082']
  const sum = uuid
    .split('')
    .map(c => c.charCodeAt(0))
    .reduce((a, b) => a + b)
  return colors[sum % colors.length]
}

export const getTime = (timestamp: number) => {
  const ts = String(timestamp)
  const date = new Date(parseInt(ts) / 10000)
  return format.datetime(date)
}

export function isFilePayload(message: MessagePayload | FilePayload): boolean {
  return (message as FilePayload).file !== undefined
}
