import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useFetchSalsaMigrationIssues = open => {
  const [fetchRequest, { result, loading, called }] = useFetch(
    'Error fetching sync issues.  Please refresh the page and try again',
  )
  const { id: organizationId } = useCurrentOrg()

  const fetchMigrationIssues = useCallback(async () => {
    return fetchRequest({
      method: 'get',
      url: `/salsa/organizations/${organizationId}/issues`,
    })
  }, [fetchRequest])
  useEffect(() => {
    if (open) {
      fetchMigrationIssues()
    }
  }, [open])
  return {
    migrationIssues: result || [],
    loading: loading || !called,
  }
}
