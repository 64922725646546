import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import cn from 'classnames'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progress: ({ percent }: { percent: number }) => {
    return {
      width: '100%',
      borderRadius: '12px',
      border: '1px solid #0F5DB5',
      height: '22px',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: '0',
        height: '100%',
        width: `${percent}%`,
        backgroundColor: '#0F5DB5',
      },
    }
  },
  margin: {
    marginBottom: '6px',
    marginTop: '-2px',
  },
})

export const Progress = ({ percent, className }: { percent: number; className: string }) => {
  const classes = useStyles({ percent })
  return <div className={cn(classes.progress, className)} />
}

export type Summary = Record<number, { title: string; registered: number; total: number }>

export const SummaryTable = React.memo(({ summary }: { summary: Summary }) => {
  const classes = useStyles()
  const records = Object.values(summary)
  const total = records.reduce(
    (acc, record) => ({
      registered: acc.registered + record.registered,
      total: acc.total + record.total,
    }),
    { registered: 0, total: 0 },
  )
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <span>Total</span>
        <span>
          {total.registered} / {total.total}
        </span>
      </div>
      <Progress percent={(total.registered / total.total) * 100} className={classes.margin} />
      {records.map(record => (
        <div className={classes.row} key={record.title}>
          <span>{record.title}</span>
          <span>
            {record.registered} / {record.total}
          </span>
        </div>
      ))}
    </div>
  )
})
