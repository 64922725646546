import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { DuplicateQuestionnairePrompt } from '../components/DuplicateQuestionnairePrompt'
import { useDuplicateQuestionnaire } from './useDuplicateQuestionnaire'

export const useDuplicateQuestionnairePrompt = (eagerAdd: any) => {
  const showPrompt = useShowPrompt(DuplicateQuestionnairePrompt)
  const duplicateQuestionSetPrompt = useDuplicateQuestionnaire(eagerAdd)

  return useCallback(
    async questionSet => {
      const updatedQuestionSet = await showPrompt({
        questionSet,
      })

      if (updatedQuestionSet) {
        await duplicateQuestionSetPrompt(updatedQuestionSet)
      }
    },
    [showPrompt, duplicateQuestionSetPrompt],
  )
}

export default useDuplicateQuestionnairePrompt
