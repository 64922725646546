import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { usePromptStyles } from '../../modal/hooks'
import Loading from '../../Loading'
import _ from 'lodash'
import useCropImagePrompt from '../hooks/useCropImagePrompt'
import useOrgFiles from '../hooks/useOrgFiles'
import { Metadata, UploadedFile } from '../interface'
import cn from 'classnames'

interface Props {
  showing: boolean
  close: any
  cancel: any
  complete: (file: UploadedFile) => void
  metadata?: Metadata
}

export const FileManagementPrompt = (props: Props) => {
  const { showing, close, complete, metadata } = props
  const promptClasses = usePromptStyles()
  const [, status] = useOrgFiles()
  const { loading, result } = status
  const [selectedFile, setSelectedFile] = useState<UploadedFile | null>(null)
  const { files } = result
  const [uploading, setUploading] = useState(false)
  const isSelected = (file: UploadedFile) => !!selectedFile && selectedFile.id === file.id
  const selectFile = (file: UploadedFile) => setSelectedFile(selectedFile && selectedFile.id === file.id ? null : file)
  const saveSelectedAndComplete = (file: UploadedFile | null) => {
    if (file) {
      complete(file)
    }
  }

  const cropImagePrompt = useCropImagePrompt(saveSelectedAndComplete)

  return (
    <Dialog open={showing}>
      <DialogTitle className={promptClasses.windowedTitleContainer}>Choose or upload file</DialogTitle>
      <DialogContent className={promptClasses.windowedContentContainer}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            {_.size(files) ? (
              <div>
                <Table>
                  <TableBody>
                    {files.map((r: any) => {
                      return (
                        <TableRow key={r.id}>
                          <TableCell>
                            <Checkbox checked={isSelected(r)} onChange={() => selectFile(r)} />
                          </TableCell>
                          <TableCell>
                            <a href={r.url} target="_blank" rel="noreferrer">
                              {r.filename}
                            </a>
                          </TableCell>
                          <TableCell>{r.createdAt}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div>No uploaded files</div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions
        // @ts-ignore
        className={cn(promptClasses.actionContainer, promptClasses.windowedActionContainer)}
      >
        <input
          accept="image/*"
          id="file-upload-input"
          type="file"
          style={{ display: 'none' }}
          onChange={async e => {
            setUploading(true)
            const currentFile = _.first(e.target.files)
            if (currentFile) {
              cropImagePrompt({ currentFile, metadata })
            }
            setUploading(false)
          }}
        />
        <label htmlFor="file-upload-input">
          <Button
            variant="contained"
            color="primary"
            // @ts-ignore
            className={promptClasses.actionButton}
            component="span"
            disabled={uploading}
          >
            Upload
          </Button>
        </label>
        <Button
          variant="contained"
          color="secondary"
          // @ts-ignore
          className={promptClasses.actionButton}
          disabled={uploading || !selectedFile}
          onClick={() => saveSelectedAndComplete(selectedFile)}
        >
          Add
        </Button>
        <Button
          variant="contained"
          color="secondary"
          // @ts-ignore
          className={promptClasses.actionButton}
          disabled={uploading}
          onClick={close}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
