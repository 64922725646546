import { useCallback, useEffect, useMemo } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'
import { useCurrentOrg } from 'auth/hooks'

export const useMessagesSummary = filters => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching messages summary')
  const organization = useCurrentOrg()
  const getMessagesSummary = useCallback(async () => {
    const { startDate, endDate } = filters || {}
    const params = {}

    if (startDate) {
      params.startDate = format.dateForUrl(startDate)
    }

    if (endDate) {
      params.endDate = format.dateForUrl(endDate)
    }

    return request({
      method: 'get',
      url: `/organizations/${organization.id}/notification-blasts`,
      config: {
        queryStringParameters: params,
      },
    })
  }, [filters, organization.id, request])

  useEffect(() => {
    getMessagesSummary()
  }, [getMessagesSummary])

  return useMemo(
    () => ({ result: result || [], loading: loading || !called, called, error, refresh: getMessagesSummary }),
    [result, loading, called, error, getMessagesSummary],
  )
}

export default useMessagesSummary
