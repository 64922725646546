import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( theme => ({
    retryButton: {
        color: 'white'
    }
}))

export default function RetryFetchButton({ retry, close, ...props }) {
    const classes = useStyles();
    const handleClick = () => {
        retry();
        close();
    }
    return (<Button 
        className={classes.retryButton} 
        {...props} 
        onClick={handleClick}
    >
        Retry
    </Button>)
}