import { useCallback } from "react"

import { useCurrentOrg } from "auth/hooks"
import useFetchRefactored from "civic-champs-shared/api/hooks/useFetchRefactored"
import { ExternalCustomerDatafeed } from "../types"
import { Status } from "civic-champs-shared/api/hooks/fetchRefactoredSchema"

const useCreateCustomerDatafeed = (): [() => Promise<ExternalCustomerDatafeed>, Status<ExternalCustomerDatafeed>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<ExternalCustomerDatafeed>()

  const createDatafeed = useCallback(() => {
    return request({
      method: 'post',
      url: `/organizations/${organization.id}/external_datafeeds`,
    })
  }, [organization, request])

  return [createDatafeed, status]
}

export default useCreateCustomerDatafeed