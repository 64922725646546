import _ from 'lodash';
import moment from 'moment';

const getDateFromString = dateText => dateText != null ? moment(dateText) : null

export default function processRawVolunteers(allVolunteers, activeVolunteers) {
    let activeById = new Map();
    _.each(activeVolunteers, activeVolunteer => {
        activeById.set(activeVolunteer.user.id, activeVolunteer);
    });

    return _.map(allVolunteers, volunteer => {
        let activeVolunteer = activeById.get(volunteer.user.id);
        let activeInfo = 
            activeVolunteer != null ? {
                checkedInAt: getDateFromString(activeVolunteer.checkedInAt),
                forceCheckOutAt: getDateFromString(activeVolunteer.forceCheckOutAt)
            } : {
                checkedInAt: null,
                forceCheckOutAt: null
            };
        
        return _.extend(volunteer, activeInfo);
    })

}