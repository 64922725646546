import * as React from 'react'
import {TimeshiftsBase} from '../../../../../interfaces/interfaceCreateEditEvent'

export interface SummaryShiftAndRoleInfoProps {
  timeshifts: TimeshiftsBase[]
}

const SummaryShiftAndRoleInfo = (props: SummaryShiftAndRoleInfoProps) => {
  const { timeshifts } = props
  return (
    <React.Fragment>
      <h3 className="sidebar-title">Shifts & Roles</h3>
      <div className="summary-info-sidebar__shifts">
        <ul className="summary-shift-list">
          {timeshifts.map((shift, index) => (
            <li key={index} className="summary-shift-list__item">
              <span className="time">
                {shift.time_start} - {shift.time_end}
              </span>
              {shift.roles.map((role, index) => (
                <span key={index} className="role">
                  {role.name} - {role.available}
                </span>
              ))}
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default SummaryShiftAndRoleInfo
