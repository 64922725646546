import React from 'react'
import { FormikHelpers as FormikActions, Formik, Form, Field } from 'formik'
import { CredentialContentType, CredentialExpirationOption, CreateOrUpdateCredentialParam } from 'credential/models'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import {
  Button,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core'
import useStyles from '../hooks/useStyles'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Close } from '@material-ui/icons'
import { TextField } from 'formik-material-ui'
import RichTextField from 'civic-champs-shared/formik/components/RichTextField'
import { OrgNamedRef } from 'civic-champs-shared/common/types'

interface Props {
  credential?: CreateOrUpdateCredentialParam
  handleSave: (values: CreateOrUpdateCredentialParam) => void
  handleCancel: (values: CreateOrUpdateCredentialParam) => void
  organization: OrgNamedRef
}

const defaultValues = {
  title: '',
  description: '',
  contentType: CredentialContentType.Text,
  content: '',
  url: '',
  waiverStatement: '',
  expirationOption: CredentialExpirationOption.UserEntered,
  expirationOptionLength: 1,
  expirationDate: '12/31',
}

const CredentialIdentificationForm = (props: Props) => {
  const promptClasses = usePromptStyles()
  const timeZone = props.organization.timeZone || 'America/New_York'
  const classes = useStyles()

  const handleSubmit = async (
    values: CreateOrUpdateCredentialParam,
    actions: FormikActions<CreateOrUpdateCredentialParam>,
  ) => {

    console.log("values.expirationDate")
    console.log(values.expirationDate)

    const actualDate = `${values.expirationDate}/${new Date().getFullYear()}`

    console.log(moment(actualDate).tz(timeZone).endOf('day').toISOString(),)
    await props.handleSave({
      ...values,
      expirationDate: moment(actualDate).tz(timeZone).endOf('day').toISOString(),
    })
    actions.setSubmitting(false)
  }

  const handleCancel = async (values: CreateOrUpdateCredentialParam, setSubmitting: (submitting: boolean) => void) => {
    setSubmitting(true)
    await props.handleCancel(values)
    setSubmitting(false)
  }
  const isCreate = !props.credential || _.isEmpty(props.credential)
  const initialValues = (isCreate ? defaultValues : props.credential) as CreateOrUpdateCredentialParam

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: CreateOrUpdateCredentialParam) => {

        const errors: any = {}
        if (!values.title) {
          errors.title = 'Name is required'
        }
        if (values.contentType === CredentialContentType.Text && !values.content) {
          errors.content = 'Content is required'
        }
        return errors
      }}
    >
      {({ values, isSubmitting, setSubmitting }) => {

        return (
          <Form>
            <div className={promptClasses.titleContainer} style={{ margin: '20px 0' }}>
              <Typography align="center" variant="h3">
                {!isCreate ? 'Update ID Prompt' : 'Add a New ID Prompt'}
                <IconButton onClick={() => handleCancel(values, setSubmitting)} className={promptClasses.closeButton}>
                  <Close />
                </IconButton>
              </Typography>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item sm={4}>
                  <Typography align="right" variant="h5">
                    Administrative Details
                  </Typography>
                </Grid>
                <Grid item sm={8} />
                <Grid item sm={4}>
                </Grid>
                <Grid item sm={8} />
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Name of the Identification</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="title"
                    placeholder="Name for this credential"
                  />
                </Grid>
                <Grid item sm={4} />
                <Grid item sm={4} />
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Identification Prompt</Typography>
                </Grid>
                <Grid item sm={4}>
                    <Field
                      placeholder="Identification prompt text."
                      name="content"
                      component={RichTextField}
                    />
                </Grid>
                <Grid item sm={4} />
              </Grid>
            </div>
            <div className={`${promptClasses.actionContainer} ${classes.formActions}`}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                className={promptClasses.actionButton}
                onClick={() => props.handleCancel(values)}
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                className={promptClasses.actionButton}
              >
                Save
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CredentialIdentificationForm
