import { IntegrationKey, IntegrationProvider } from 'integrations/types'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const emptyValue = { enabled: true, token: '' } as IntegrationKey

const useGetIntegrationKey = (): ((provider: IntegrationProvider) => Promise<IntegrationKey>) => {
  const [request] = useFetchRefactored<IntegrationKey>({
    emptyValue,
  })
  const org = useCurrentOrg()

  return useCallback(
    async (provider: IntegrationProvider) => {
      try {
        const result = await request({
          method: 'get',
          url: `/organizations/${org.id}/integration-keys/${provider}`,
        })
        return result
      } catch (e) {
        return emptyValue
      }
    },
    [org.id, request],
  )
}

export default useGetIntegrationKey
