import _ from 'lodash';
import { createMigrate } from 'redux-persist';
import defaultState from '../kiosk/reducer/defaultState';
import { defaultState as featuresDefaultState } from 'core/feature/reducers/development-features'

export default createMigrate({
    0: state => {
        console.log('migrating from version -1...');
        return _.omit(state, ['router', 'auth']);
    },
    2: state => {
        console.log('migrating from version 1...');
        let defaultKiosk = defaultState();
        let kiosk = _.get(state, 'kiosk', defaultKiosk);

        kiosk.getVolunteers = defaultKiosk.getVolunteers;
        kiosk.getVolunteeringRoles = defaultKiosk.getVolunteeringRoles;
        kiosk.signUpVolunteer = defaultKiosk.signUpVolunteer;
        kiosk.checkIn = defaultKiosk.checkIn;
        kiosk.checkOut = defaultKiosk.checkOut;

        return Object.assign(state, { kiosk: kiosk });
    },
    3: state => {
        console.log('migrating from version 2...')
        const type = _.chain ( _.get(state, 'features.settings', {}) ).value().first().get('type')
        if(type != 'product' || type != 'development') {
           return Object.assign(state, { 
               features: _.clone(featuresDefaultState)
            }) 
        }

        return state;
    }
}, { debug: false });