import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useDeleteNote = (): [(noteId: number) => Promise<void>, Status<void>] => {
  const [request, status] = useFetchRefactored<void>({
    successMessage: 'Note deleted',
    errorMessage: 'Failed to delete note',
  })
  const organization = useCurrentOrg()

  const getNotes = useCallback(
    (noteId: number) => {
      return request({
        method: 'del',
        url: `/organizations/${organization.id}/notes/${noteId}`,
      })
    },
    [organization.id, request],
  )

  return [getNotes, status]
}

export default useDeleteNote
