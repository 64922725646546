import {useCallback, useEffect, useReducer} from 'react';
import {filter} from "lodash";


const init = ({allMembers, chosenMemberID}) => {
  const filteredMembers = filter(allMembers, m => m)

  return {
    applicants: filteredMembers,
    index: chosenMemberID ? filteredMembers.findIndex(member => member.id === chosenMemberID): 0,
    submitting: false,
    isDirty: false
  }
}

const reducer = (state, action) => {
  const { applicants, index, submitting } = state

  switch(action) {
    case 'prev':
      return submitting ? state : {
        ...state,
        index: index > 0 ? index - 1 : index
      }

    case 'next':
      return submitting ? state : {
        ...state,
        index: index < applicants.length - 1 ? index + 1 : index
      }

    default:
      throw new Error(`Unknown identification action '${action}!`)
  }
}


export const useUserCredentialApprovalManagement = ({ allMembers, close, refresh, chosenMemberID }) => {

  const [
    { applicants, index, submitting, isDirty },
    dispatch
  ] = useReducer(reducer, {allMembers, chosenMemberID}, init)

  const exit = useCallback(() => {
    if(isDirty) refresh()
    close()
  }, [close, refresh, isDirty])

  useEffect(
    () => { if(applicants.length === 0) exit() },
    [applicants.length, exit]
  )

  return {
    submitting,
    prev: applicants[index - 1],
    current: applicants[index],
    next: applicants[index + 1],
    gotoNext: () => dispatch('next'),
    gotoPrev: () => dispatch('prev'),
    exit,
    total: applicants.length,
    currentIndex: index + 1
  }
}
