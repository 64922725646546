import { connect } from 'react-redux';

import FullscreenButton from './FullscreenButton';
import { requestSetFullscreen } from '../actions';

const mapStateToProps = state => ({
    isFullscreen: state.fullscreen
})

const mapDispatchToProps = dispatch => ({
    goFullscreen: () => dispatch( requestSetFullscreen(true) )
})

export default connect(mapStateToProps, mapDispatchToProps)(FullscreenButton);