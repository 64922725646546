import { Field, Form, Formik } from 'formik'
import { FormModalScreen } from 'components/FormModal'
import React from 'react'
import { useAddEditEventStyles } from 'Event/hooks/useAddEditEventStyles'
import * as yup from 'yup'
import { StyledAutocomplete } from 'components/StyledAutocomplete'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { AssociationType, EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import { useOptions } from 'Event/components/opportunity/GroupPicker/hooks'
import { InlineAddButton } from 'civic-champs-shared/core/InlineAddButton'
import { ReferenceItemGroup } from 'Event/components/ReferenceItemGroup'
import useOrganizationCredentials from 'credential/hooks/useOrganizationCredentials'
import { filter, map } from 'lodash'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { CredentialFull, QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import CreateOrUpdateCredentialPrompt from 'credential/components/CreateOrUpdateCredentialPrompt'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Survey } from 'surveys/types'
import useQuestionnairesCollection from 'question-sets/hooks/useQuestionnairesCollection'
import { Questionnaire, QuestionnaireStatus } from 'question-sets/types'
import { FormReferenceItem } from 'Event/helpers/add-edit-helpers'
import { useBroadcastChannel } from '../../civic-champs-shared/core/hooks/useBroadcastChannel'
import { CkEditorStyledField } from 'civic-champs-shared/formik/components/CkEditorStyledField'
import { OpportunityVisibility } from 'Event/interfaces'

export interface EventRegistrationDetailsScreenValues {
  onboardingGroups: EventGroup[]
  questionnaires: FormReferenceItem[]
  waivers: FormReferenceItem[]
  instructions: string
  visibility: OpportunityVisibility
}

export interface EventRegistrationDetailsScreenProps {
  values: EventRegistrationDetailsScreenValues
  onSubmit: (values: EventRegistrationDetailsScreenValues, asDraft?: boolean) => void
  isNewEvent: boolean
  onBack: (values: EventRegistrationDetailsScreenValues) => void
  groups: Group[]
  isSchedulable: boolean
  isDraftable: boolean
}

const eventRegistrationDetailsPayloadSchema = yup.object({
  onboardingGroups: yup.array().of(yup.object()),
  instructions: yup.string().nullable().notRequired(),
})

const eventChannel = 'event:questions:' + new Date().getTime() + Math.random()
const waiverChannel = 'event:waivers:' + new Date().getTime() + Math.random()

export const EventRegistrationDetailsScreen = ({
  values,
  onSubmit,
  isNewEvent,
  onBack,
  groups,
  isSchedulable,
  isDraftable,
}: EventRegistrationDetailsScreenProps) => {
  const groupsEnabled = useFeatureEnabled('Groups')
  const [asDraft, setAsDraft] = React.useState(false)
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const isNewAddEditWaiverUiEnabled = useFeatureEnabled('NewAddEditWaiverUi')

  const classes = useAddEditEventStyles()
  const [showAddQuestionnaire, setShowAddQuestionnaire] = React.useState(false)
  const [showAddWaiver, setShowAddWaiver] = React.useState(false)
  const { credentials } = useOrganizationCredentials()
  const [addedCredentials, setAddedCredentials] = React.useState<CredentialFull[]>([])
  const [addedQuestionnaires, setAddedQuestionnaires] = React.useState<FormReferenceItem[]>([])
  const showCreateCredentialPrompt = useShowPrompt(CreateOrUpdateCredentialPrompt)
  const { addOrReplaceMessageListener: addEventChannelListener } = useBroadcastChannel(eventChannel)
  const { addOrReplaceMessageListener: addWaiverChannelListener } = useBroadcastChannel(waiverChannel)

  const [{ questionnaires }] = useQuestionnairesCollection() as unknown as [{ questionnaires: Questionnaire[] }]
  const filteredQuestionnaires = filter(
    questionnaires,
    questionnaire => questionnaire.status === QuestionnaireStatus.ACTIVE,
  ).map(({ id, name }) => ({
    id,
    name,
  })) as FormReferenceItem[]

  const options = useOptions({ groups, associationType: AssociationType.ADD_PARTICIPANTS_TO_GROUP, value: [] })
  return (
    <Formik
      initialValues={values}
      validationSchema={eventRegistrationDetailsPayloadSchema}
      onSubmit={values => onSubmit(values, asDraft)}
      validateOnChange
      isInitialValid={!isNewEvent}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, errors }) => {
        const selectedWaiverIds = new Set(map(values.waivers, 'id'))
        const selectableWaivers: FormReferenceItem[] = [...credentials, ...addedCredentials]
          .filter(({ credential: { type, id } }) => type === 'waiver' && !selectedWaiverIds.has(id))
          .map(({ credential: { id }, version: { title } }) => ({ id, name: title }))
        const showAddCredential = async () => {
          if (isNewAddEditWaiverUiEnabled) {
            window.open(`/waivers/new#channel=${waiverChannel}`)
            addWaiverChannelListener(
              (event: MessageEvent) => {
                const waiver = event.data as CredentialFull
                setAddedCredentials(existing => [...existing, waiver])
                setFieldValue('waivers', [...values.waivers, { id: waiver.credential.id, name: waiver.version.title }])
                setShowAddWaiver(false)
              },
              { once: true },
            )
          } else {
            const credential = await showCreateCredentialPrompt()
            setAddedCredentials(values => [...values, credential])
            setFieldValue('waivers', [
              ...values.waivers,
              { id: credential.credential.id, name: credential.version.title },
            ])
            setShowAddWaiver(false)
          }
        }

        const handleAddQuestionnaire = () => {
          window.open(`/event/new-event/questions/new#channel=${eventChannel}`)
          addEventChannelListener(
            (event: MessageEvent) => {
              let newItem: FormReferenceItem
              if (isQuestionnaireAsSurveyEnabled) {
                const data = event.data as Survey
                newItem = { id: data.id, name: data.versions[0].questionSet?.name as string }
              } else {
                const data = event.data as QuestionSetResponse
                newItem = { id: data.questionSetId, name: data.name }
              }
              setAddedQuestionnaires(existing => [...existing, newItem])
              setFieldValue('questionnaires', [...values.questionnaires, newItem])
              setShowAddQuestionnaire(false)
            },
            { once: true },
          )
        }

        const selectedQuestionnaireIds = new Set(map(values.questionnaires, 'id'))
        const selectableQuestionnaires: FormReferenceItem[] = [
          ...filteredQuestionnaires,
          ...addedQuestionnaires,
        ].filter(({ id }) => !selectedQuestionnaireIds.has(id))

        return (
          <Form>
            <FormModalScreen
              onBack={() => onBack(values)}
              onNext={submitForm}
              onDraft={
                isSchedulable && isDraftable
                  ? () => {
                      setAsDraft(true)
                      submitForm()
                    }
                  : undefined
              }
              draftText={isSchedulable && isDraftable ? 'Finish Later' : undefined}
              nextText="Save"
              backText="Back"
              // For schedulable and not draftable event we don't disable buttons as pressing "Cancel"
              // in dialog asking "Edit this or all events" will result buttons being disabled forever
              disabled={isSubmitting && (!isSchedulable || isDraftable)}
            >
              {groupsEnabled && values.visibility !== OpportunityVisibility.SELECT_GROUPS_ONLY && (
                <>
                  {isSchedulable && (
                    <>
                      <div className={classes.section}>
                        <span className={classes.sectionTitle}>Questionnaires on Registration:</span>
                        <ReferenceItemGroup
                          items={values.questionnaires}
                          name="questionnaires"
                          setFieldValue={setFieldValue}
                        />
                        {showAddQuestionnaire ? (
                          <AdvancedAutocomplete<FormReferenceItem>
                            label={'Questionnaire'}
                            placeholder={'Select Questionnaire'}
                            options={selectableQuestionnaires}
                            value={null}
                            getOptionInfo={option => ({
                              left: option.name,
                              id: option.id,
                            })}
                            onChange={option => {
                              setFieldValue('questionnaires', [...values.questionnaires, option])
                              setShowAddQuestionnaire(false)
                            }}
                            onAdd={handleAddQuestionnaire}
                            addNewText={'Add a new questionnaire'}
                            addNewIcon={<AddCircleIcon className={classes.addIcon} />}
                          />
                        ) : (
                          <InlineAddButton onClick={() => setShowAddQuestionnaire(true)} text="Add Questionnaire" />
                        )}
                      </div>
                      <div className={classes.section}>
                        <span className={classes.sectionTitle}>Required Waivers</span>
                        <ReferenceItemGroup items={values.waivers} name="waivers" setFieldValue={setFieldValue} />
                        {showAddWaiver ? (
                          <AdvancedAutocomplete<FormReferenceItem>
                            label={'Waiver'}
                            placeholder={'Select Waiver'}
                            options={selectableWaivers}
                            value={null}
                            getOptionInfo={option => ({
                              left: option.name,
                              id: option.id,
                            })}
                            onChange={option => {
                              setFieldValue('waivers', [...values.waivers, option])
                              setShowAddWaiver(false)
                            }}
                            onAdd={showAddCredential}
                            addNewText={'Add a new waiver'}
                            addNewIcon={<AddCircleIcon className={classes.addIcon} />}
                          />
                        ) : (
                          <InlineAddButton onClick={() => setShowAddWaiver(true)} text="Add Waiver" />
                        )}
                      </div>
                    </>
                  )}

                  <div className={classes.section}>
                    <span className={classes.sectionTitle}>
                      Volunteers are added to these groups and sent onboarding questionnaires and waivers needed for
                      membership. This will occur during registration or when checking into this event.
                    </span>
                    <Field
                      name="onboardingGroups"
                      placeholder="Select Group (s)"
                      label="Groups"
                      component={StyledAutocomplete}
                      options={options}
                      getOptionLabel={({ name }: EventGroup) => name}
                      disabled={isSubmitting}
                      multiple
                      notched={true}
                    />
                  </div>
                </>
              )}
              {isSchedulable && (
                <div className={classes.section}>
                  <Field
                    name="instructions"
                    label="Day-of Event Instructions"
                    placeholder="Enter day-of event instructions (this will be sent to volunteers 24 hours before they are scheduled to volunteer)"
                    component={CkEditorStyledField}
                  />
                </div>
              )}
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
