import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { Moment } from 'moment'
import { Address } from 'civic-champs-shared/formik/components/AddressField'
import { mapGeofencingToEventGeofencing } from 'utils/event'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { TripOrder, TripRequestPayload } from 'volunteering/trips/types'
import { getDateTimeString } from 'volunteering/trips/hooks/useAddEditTripPrompt'
import { AdjustTripFormValues, AdjustTripModal } from 'volunteering/trips/components/AdjustTripModal'

export const useAdjustTripPrompt = (updateTripOrder: (id: number, body: TripRequestPayload) => void) => {
  const showPrompt = useShowPrompt(AdjustTripModal)

  return useCallback(
    async (trip: TripOrder): Promise<any> => {
      const formData: AdjustTripFormValues = await showPrompt({ trip })
      const { geofencing: dropOffGeofencing, ...dropOffAddress } = formData.dropOffAddress as Address
      const { geofencing: pickUpGeofencing, ...pickUpAddress } = formData.pickUpAddress as Address
      const tripData = {
        tripOrders: [
          {
            id: trip.id,
            driverId: formData.driver.id,
            droppedOffAt: getDateTimeString({
              date: formData.dropOffDate as Moment,
              time: formData.dropOffTime as Moment,
            }),
            dropOffLocation: {
              address: dropOffAddress,
              geofencing: {
                ...mapGeofencingToEventGeofencing(dropOffGeofencing),
                radius: DEFAULT_GEOFENCING.radius,
              },
            },
            pickedUpAt: getDateTimeString({ date: formData.pickUpDate as Moment, time: formData.pickUpTime as Moment }),
            pickupLocation: {
              address: pickUpAddress,
              geofencing: {
                ...mapGeofencingToEventGeofencing(pickUpGeofencing),
                radius: DEFAULT_GEOFENCING.radius,
              },
            },
            mileage: formData.mileage as any as number,
          },
        ],
      }
      try {
        updateTripOrder(trip.tripRequest?.id as number, tripData)
      } catch (e) {}
    },
    [showPrompt, updateTripOrder],
  )
}

export default useAdjustTripPrompt
