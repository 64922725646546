import React, { useCallback, useEffect, useRef } from 'react'
import {
  Hooks,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { makeStyles, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import cn from 'classnames'

import './table/table.css'

const pageSizeOptions = [10, 25, 50, 100]

const useStyles = makeStyles( theme => ({
    sortAsc: {
        boxShadow: 'inset 0 3px 0 0 rgba(0,0,0,.6)'
    },
    sortDesc: {
        boxShadow: 'inset 0 -3px 0 0 rgba(0,0,0,.6)'
    },
    selectedRow: {
        background: '#5C8DE8'
    },
    selectedCell: {
        color: 'white',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        msTextOverflow: 'ellipsis',
        OTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis',
        border: '1px solid rgba(0,0,0,0.05)',
        borderLeft: '1px solid rgba(0,0,0,0.02)',
        borderRight: '1px solid rgba(0,0,0,0.02)',
        '&:first-child': {
            'border-left': '0',
        },
        '&:last-child': {
            'border-right': '0',
            '& > a': {
                color: 'white !important',
            },
        },
    },
    header: {
        cursor: 'pointer'
    },
    headerCell : {
        paddingRight: '16px',
        backgroundColor: '#B8CDF5',
        fontWeight: 600,
    },
    cell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        msTextOverflow: 'ellipsis',
        OTextOverflow: 'ellipsis',
        textOverflow: 'ellipsis',
        border: '1px solid rgba(0,0,0,0.05)',
        borderLeft: '1px solid rgba(0,0,0,0.02)',
        borderRight: '1px solid rgba(0,0,0,0.02)',
        '&:first-child': {
            'border-left': '0',
        },
        '&:last-child': {
            'border-right': '0',
        }
    },
    resizer: {
      display: 'inline-block',
      width: '10px',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(50%)',
      zIndex: 1,
      touchAction: 'none',
    }
}))

const getClampedPageIndex = (totalItemCount: number, itemsPerPage: number, pageIndex: number) => {
    let pageCount =  Math.floor((totalItemCount + itemsPerPage - 1) / itemsPerPage)
    let minimumPageIndex = 0
    let maximumPageIndex = Math.max(0, pageCount - 1)

    return Math.min(Math.max(minimumPageIndex, pageIndex), maximumPageIndex)
}

const IndeterminateCheckbox = React.forwardRef(
   //@ts-ignore
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    //@ts-ignore
    const resolvedRef:  React.RefObject<HTMLInputElement> | null = ref || defaultRef

    useEffect(() => {
       //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

interface Props {
  checkBox?: boolean
  classes?: any
  multiSelect?: boolean
  columns: any
  data: any
  groupBy?: string[]
  filterKey?: string
  onRowAction?: Function
  onRowsChanged?: Function
  onSelectedChanged?: Function
}

export function DataTable(props: Props) {
    const { data, groupBy, filterKey, onRowAction, onSelectedChanged, checkBox, multiSelect, onRowsChanged} = props
    const tableRef = useRef(null)

  const {
      state: { pageIndex, pageSize, sortBy },
      gotoPage,
      setPageSize,
      headerGroups,
      getTableProps,
      toggleAllRowsSelected,
      page,
      pageCount,
      prepareRow,
      selectedFlatRows,
      state: { selectedRowIds },
    // @ts-ignore
    } = useTable({
        ...props,
      },
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect,
      useResizeColumns,
      useFlexLayout,
      (hooks: Hooks) => {
        if(checkBox){
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            //@ts-ignore
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox

            Cell: ({row}) => (
              <div>
                <IndeterminateCheckbox {...(row as any).getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])
      }
      }
    ) as any

    //pagination
    const handleChangePage = useCallback((_, selectedPage) => {
      gotoPage(selectedPage)
    }, [gotoPage])

    const handleChangePageSize = useCallback(event => {
      setPageSize(event.target.value)
      gotoPage(0)
    }, [gotoPage, setPageSize])

    // sorting
    const classes = useStyles()
    const getSortStyle = useCallback(column => {
        if(column.canSort && column.isSorted) {
            return column.isSortedDesc ? classes.sortDesc : classes.sortAsc
        }
        return null
    }, [classes])

    const handleToggleSortBy = useCallback(column => {
        if(!column.canSort) return null

        column.toggleSortBy(column.isSorted ? !column.isSortedDesc : false)
        gotoPage(0)
    }, [gotoPage])

    // row select
    const handleRowSelect = useCallback((row) => {
      if (onSelectedChanged && !multiSelect) {
        toggleAllRowsSelected(false)
        row.toggleRowSelected(!row.isSelected)
        onSelectedChanged(!row.isSelected ? row.original : null)
      if (onRowAction) onRowAction(row)}
    }, [toggleAllRowsSelected, onRowAction, onSelectedChanged, multiSelect])

    // Reset the page index if the data filters are changed
    useEffect(() => {
        gotoPage(0)
    }, [filterKey, gotoPage])

    useEffect(() => {
      if(multiSelect && onRowsChanged) {
        onRowsChanged(selectedFlatRows)
      }
  }, [selectedFlatRows, multiSelect, onRowsChanged])

    // Keep page index from going out of range when data changes
    useEffect(() => {
        let clampedPageIndex = getClampedPageIndex(data.length, pageSize, pageIndex)
        if (clampedPageIndex !== pageIndex) {
            gotoPage(clampedPageIndex)
        }
    }, [data.length, pageSize, pageIndex, gotoPage])

  return (<>
        <Table ref={tableRef} {...getTableProps()}>
            <TableHead>
                {headerGroups.map((headerGroup: any) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    className={classes.header}
                  >
                      {headerGroup.headers.map((column: any) => (
                        <TableCell
                          {...column.getHeaderProps()}
                          className={cn(getSortStyle(column), classes.cell, classes.headerCell, column.className)}
                        >
                          <div
                            onClick={() => handleToggleSortBy(column)}
                          >
                            {column.render('Header')}
                          </div>
                          {column.canResize &&
                          <div
                            {...column.getResizerProps()}
                            className={classes.resizer}
                          />}
                        </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {
                  page.map((row: any) => {
                    prepareRow(row)

                    return (
                      <TableRow
                        {...row.getRowProps()}
                        className={row.isSelected ? classes.selectedRow : null}
                        onClick={() => handleRowSelect(row)}
                      >
                        {row.cells.map((cell: any) => (
                          <TableCell
                            {...cell.getCellProps()}
                            className={row.isSelected ? classes.selectedCell : classes.cell}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                }
            </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={data.length}
          page={pageIndex}
          onChangePage={handleChangePage}
          rowsPerPage={pageSize}
          rowsPerPageOptions={pageSizeOptions}
          onChangeRowsPerPage={handleChangePageSize}
        />
    </>)
}

DataTable.defaultProps = {
  classes: {},
}

export default DataTable;
