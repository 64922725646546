import { useCallback, useMemo } from 'react'
import { useFetch } from "../../api/hooks";
import { useCurrentOrg } from "../../auth/hooks";

export const useDuplicateQuestionSet = (onSuccess) => {
  const [request, { loading, error, result }] = useFetch(
    'Error creating question set.  Please refresh the page to try again',
  )
  const currentOrg = useCurrentOrg()
  const duplicateQuestionSet = useCallback(
    questionSet => {
      return request({
        method: 'post',
        url: `/organizations/${currentOrg.id}/question-set`,
        config: {
          body: questionSet,
        },
        onSuccess,
      })
    },
    [request],
  )

  return useMemo(
    () => [
      duplicateQuestionSet,
      {
        result,
        loading,
        error,
      },
    ],
    [result, loading, error],
  )
}
