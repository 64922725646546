import React, { useEffect, useMemo } from 'react'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Grid } from '@material-ui/core'

import Loading from 'components/Loading'
import useGroupsCollection from '../hooks/useGroupsCollection'
import { useEnabledGroups } from 'Event/components/opportunity/GroupPicker/hooks'
import { RouteComponentProps } from 'react-router'
import PageHeader from 'App/components/PageHeader'
import { ExtendedPagedTable } from 'core/table/components'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import AddIcon from '@material-ui/icons/Add'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import useGroupOverviewColumns, { useStyles } from 'group/hooks/useGroupOverviewColumns'
import ExportGroupsButton from 'group/components/ExportGroupsButton'

const tableName = 'groups'
const getOperatorOptions = (id: string) => []
export const GroupsOverviewComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ groups, initiallyLoaded }, { addGroup }, eventListeners] = useGroupsCollection()
  const classes = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  const filteredGroups = useEnabledGroups(groups as Group[])

  const columns = useGroupOverviewColumns()
  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: DEFAULT_FILTERS,
    getOperatorOptions,
  })

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
      },
      data: filteredGroups,
      columns,
      globalFilter,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, classes.selectColumn),
  )

  const [selectedRows, selectedRowsCount] = useMemo(() => {
    return [table.selectedFlatRows, table.selectedFlatRows.length]
  }, [table.selectedFlatRows])

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Groups"
        filterSubHeader="Select volunteer attributes to filter by:"
        buttons={
          <>
            <ExportGroupsButton data={selectedRows} disabled={!selectedRowsCount} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addGroup}>
            Add Group
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const GroupsOverview = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Groups" subTitle="Create and manage volunteer groups">
      <GroupsOverviewComponent {...props} />
    </PageHeader>
  )
}
