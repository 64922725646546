import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

const useDeleteBadContacts = () => {
  const [request, status] = useFetch('Error deleting bad contacts')
  const organization = useCurrentOrg()
  const errorSnack = useErrorNotification()
  const deleteBadContacts = useCallback(
    async badContact => {
      const { email, phoneNumber } = badContact
      const deleted = await request({
        method: 'del',
        url: `/organizations/${organization.id}/bad-contacts`,
        config: {
          queryStringParameters: email ? { exactEmails: [email] } : { exactPhones: [phoneNumber] }
        }
      })
      if (!deleted) {
        errorSnack('Failed to deleted contact', new Error('No contacts were deleted'))
      }
      return deleted
    },
    [request],
  )

  return [deleteBadContacts, status]
}

export default useDeleteBadContacts
