import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useDeclineMember = () => {
  const [request, { loading }] = useFetch('Error denying membership')
  const showSuccess = useSuccessNotification()

  const declineMember = useCallback(
    (member, comment) => {
      return request({
        method: 'post',
        url: `/groups/${member.group.id}/membership_actions`,
        config: {
          body: {
            type: 'declined',
            personIds: [member.id],
            comment: comment || undefined,
          },
        },
        onSuccess: () => showSuccess('Successfully declined membership'),
      })
    },
    [request],
  )

  return { declineMember, loading }
}

export default useDeclineMember
