import { Field, Form, Formik } from 'formik'
import { AdvancedPersonAutocomplete } from 'civic-champs-shared/core/AdvancedPersonAutocomplete'
import React from 'react'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import useTripModalStyles from 'volunteering/trips/hooks/useTripModalStyles'
import { SudMh, TripOrderReason, TripOrderStatus } from 'volunteering/trips/types'
import { CheckboxWithLabel } from 'formik-material-ui'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { upperFirst } from 'lodash'
import * as yup from 'yup'
import { FormModalScreen } from 'components/FormModal'
import RadioGroupFormField from 'components/RadioGroupFormField'
import { CkEditorStyledField } from '../../../civic-champs-shared/formik/components/CkEditorStyledField'

export interface SecondScreenFormValues {
  driver: null | PersonSuggestion
  reason: null | TripOrderReason
  recovery: boolean
  cji: boolean
  sudMh: SudMh | 'null'
  description: null | string
}

export interface SecondScreenFormProps {
  values: SecondScreenFormValues
  onSubmit: (values: SecondScreenFormValues) => void
  driverGroupId: number
  close: (values: SecondScreenFormValues) => void
  isInitialValid?: boolean
  tripOrderStatus?: TripOrderStatus
}

export const validationSchema = yup.object({
  driver: yup.mixed().nullable(),
  reason: yup
    .string()
    .required('A reason is required. Please enter a reason.')
    .typeError('A reason is required. Please enter a reason.'),
  recovery: yup
    .boolean()
    .required('A recovery is required. Please enter a recovery.')
    .typeError('A recovery is required. Please enter a recovery.'),
  sudMh: yup
    .string()
    .required('A SUD / MH is required. Please enter a SUD / MH.')
    .typeError('A SUD / MH is required. Please enter a SUD / MH.'),
  description: yup.string().nullable(),
})
export const TripModalSecondScreen = ({
  values,
  onSubmit,
  close,
  driverGroupId,
  tripOrderStatus,
  isInitialValid = false,
}: SecondScreenFormProps) => {
  const classes = useTripModalStyles()
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange
      isInitialValid={isInitialValid}
    >
      {({ submitForm, isSubmitting, values, setFieldValue }) => {
        return (
          <Form>
            <FormModalScreen
              onBack={() => close(values)}
              onNext={submitForm}
              nextText="Save"
              backText="Back"
              disabled={isSubmitting}
            >
              <AdvancedAutocomplete<TripOrderReason>
                label={'Reason *'}
                placeholder={'Select a reason'}
                value={values.reason}
                disableInputFilter
                getOptionInfo={reason => ({
                  left: upperFirst(reason),
                  id: reason,
                })}
                options={Object.values(TripOrderReason)}
                onChange={reason => setFieldValue('reason', reason)}
              />
              <div className={classes.label}>
                <p>Recovery:</p>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  classes={{ colorSecondary: classes.checkbox, checked: classes.checkboxChecked }}
                  size="small"
                  name="recovery"
                  Label={{
                    label: 'This ride is recovery related',
                    classes: { root: classes.checkboxContainer, label: classes.checkboxLabel },
                  }}
                />
              </div>

              <div className={classes.label}>
                <p>SUD / MH: :</p>
                <RadioGroupFormField
                  options={[
                    {
                      value: 'null',
                      label: 'N/A',
                    },
                    {
                      value: SudMh.SUD,
                      label: 'SUD',
                    },
                    {
                      value: SudMh.MH,
                      label: 'MH',
                    },
                    {
                      value: SudMh.CO_OCCURRING,
                      label: 'Co-Occurring',
                    },
                  ]}
                  disabled={isSubmitting}
                  name="sudMh"
                />
              </div>
              <div className={classes.label}>
                <p>Criminal Justice Involvement: :</p>
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  classes={{ colorSecondary: classes.checkbox, checked: classes.checkboxChecked }}
                  size="small"
                  name="cji"
                  Label={{
                    label: 'This ride is criminal justice related',
                    classes: { root: classes.checkboxContainer, label: classes.checkboxLabel },
                  }}
                />
              </div>
              <Field
                name="description"
                label="Notes (Optional)"
                placeholder="Add additional ride notes here"
                component={CkEditorStyledField}
              />

              <AdvancedPersonAutocomplete
                label={'Driver (Optional)'}
                placeholder={'Select a person to be the trip’s driver'}
                groupId={driverGroupId}
                value={values.driver}
                excludeNonGroupMembers={true}
                disabled={
                  tripOrderStatus &&
                  ![TripOrderStatus.Assigned, TripOrderStatus.Unassigned, TripOrderStatus.Cancelled].includes(
                    tripOrderStatus,
                  )
                }
                onChange={driver => setFieldValue('driver', driver)}
              />
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
