import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Chat, ChatApiResponse } from 'chats/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import map from 'lodash/map'
import { mapChat } from 'chats/helpers'

export const useGetChats = (): [() => Promise<Chat[]>, Status<Chat[]>] => {
  const [request, status] = useFetchRefactored<Chat[], ChatApiResponse[]>({
    emptyValue: [],
    mapper: chats => map(chats, mapChat),
  })
  const org = useCurrentOrg()

  const fetchChats = useCallback(
    () =>
      request({
        method: 'get',
        url: `/organizations/${org.id}/chats`,
      }),
    [request, org],
  )

  return [fetchChats, status]
}

export default useGetChats
