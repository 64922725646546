import { Button, Typography } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import AsyncAutocomplete from 'components/AsyncAutocomplete'
import { isNull } from 'lodash'
import React from 'react'
import useTaskRecipients from '../hooks/useTaskRecipients'
import { filter } from 'lodash'
import { PersonAutocompleteOption } from 'volunteering/components/PersonAutocompleteOption'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  headerRow: {
    verticalAlign: 'middle',
    display: 'flex',
  },
  personPicker: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default function TaskRecipientPicker(props) {
  const { value, onChange } = props
  const classes = useStyles()
  const { recipients, loading, onOpenAddRecipientPrompt } = useTaskRecipients()

  const handleChange = (e, value) => (!isNull(value) ? onChange(value) : onChange(undefined))

  return (
    <div>
      <div className={classes.headerRow}>
        <Typography variant="h4" className={classes.header}>
          Recipient
        </Typography>
        <Button variant="contained" onClick={onOpenAddRecipientPrompt} color="primary">
          <AddCircleOutline />
          Add
        </Button>
      </div>
      <AsyncAutocomplete
        options={recipients}
        getOptionLabel={opt => `${filter([opt.givenName, opt.familyName]).join(' ')}`}
        renderOption={opt => <PersonAutocompleteOption option={opt} />}
        onChange={handleChange}
        value={value}
        label="Recipient"
        loading={loading}
        className={classes.personPicker}
      />
    </div>
  )
}
