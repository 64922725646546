import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { Link as RouterLink } from 'react-router-dom'

import { useMessageOverviewStyles } from 'messages/hooks/useMessageOverviewStyles'
import { PersonLinkCell } from 'core/table/cells/modern'
import { NullableTextCell, WithLinkCell } from 'core/table/cells'
import { NotificationBlastMessage, NotificationBlastMessageStatus } from 'messages/types'
import { ReactComponent as ErrorIcon } from 'images/error.svg'
import { ReactComponent as SuccessIcon } from 'images/verified.svg'
import { useHasRole } from 'civic-champs-shared/auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

interface StatusTextProps {
  id: number
  children: React.ReactNode
}

const StatusText = ({ id, children }: StatusTextProps) => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const classes = useMessageOverviewStyles()

  return isInternalAdmin ? (
    <RouterLink to={`/messages/${id}`} className={classes.link}>
      {children}
    </RouterLink>
  ) : (
    <>{children}</>
  )
}

export const MessageStatusCell = ({ cell }: CellProps<NotificationBlastMessage>) => {
  const { id, status } = cell.row.original

  const classes = useMessageOverviewStyles()

  switch (status) {
    case NotificationBlastMessageStatus.SENDING:
      return <div>Loading</div>
    case NotificationBlastMessageStatus.SENT:
      return (
        <div>
          <SuccessIcon className={classes.successIcon} />
          <StatusText id={id}> Sent</StatusText>
        </div>
      )
    case NotificationBlastMessageStatus.FAILED:
      return (
        <div>
          <ErrorIcon className={classes.errorIcon} />
          <StatusText id={id}> Failure</StatusText>
        </div>
      )
    default:
      return <></>
  }
}

export const useMessagesOverviewColumns = () => {
  const classes = useMessageOverviewStyles()
  return useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'recipient',
        Cell: PersonLinkCell,
        className: classes.nameColumn,
      },
      {
        id: 'firstName',
        Header: 'First Name',
        accessor: 'recipient.givenName',
        Cell: (props: any) =>
          WithLinkCell(
            NullableTextCell,
            (id: number) => `/champions/${id}`,
            'message.sentTo.id',
          )({ ...props, linkProps: { className: classes.link } }),
        className: classes.firstNameColumn,
      },
      {
        id: 'lastName',
        Header: 'Last Name',
        accessor: 'recipient.familyName',
        Cell: (props: any) =>
          WithLinkCell(
            NullableTextCell,
            (id: number) => `/champions/${id}`,
            'message.sentTo.id',
          )({ ...props, linkProps: { className: classes.link } }),
        className: classes.lastNameColumn,
      },
      {
        id: 'contact',
        Header: 'Email/Phone',
        accessor: 'recipientContact',
        Cell: NullableTextCell,
        className: classes.contactColumn,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        Cell: MessageStatusCell,
        className: classes.statusColumn,
      },
    ],
    [
      classes.contactColumn,
      classes.firstNameColumn,
      classes.lastNameColumn,
      classes.link,
      classes.nameColumn,
      classes.statusColumn,
    ],
  )
}
