import React from 'react'
import { Switch, FormControlLabel } from '@material-ui/core'

const ClosedLabel = ({ closed }) => (
  <label>
    <span>
      <b>{closed ? 'LIMITED GROUP' : 'OPEN GROUP'}</b>:{' '}
    </span>
    {closed ? 'Full membership requires approval.' : 'Full membership does not require approval.'}
  </label>
)

export const GroupClosedSwitchField = ({ field, readOnly }) => {
  return readOnly ? (
    <ClosedLabel closed={field.value} />
  ) : (
    <FormControlLabel
      control={<Switch name="closed" checked={field.value} onChange={field.onChange} />}
      label={<ClosedLabel closed={field.value} />}
    />
  )
}

export default ({ group, readOnly, onChange }) => {
  const formikField = {
    value: group.closed,
    onChange: (e) => onChange && onChange(e.target.checked),
  }
  return <GroupClosedSwitchField field={formikField} readOnly={readOnly} />
}
