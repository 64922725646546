import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback, useEffect } from 'react'
import { ColumnState } from 'core/table/interfaces/ColumnState'

export const useGetColumnState = (
  tableName?: string,
): [(tableName: string) => Promise<ColumnState[]>, Status<ColumnState[]>] => {
  const [request, status] = useFetchRefactored<ColumnState[]>({
    emptyValue: undefined,
  })

  const fetchColumnState = useCallback(
    tableName =>
      request({
        method: 'get',
        url: `/user/tables/${tableName}/columns`,
      }),
    [request],
  )

  useEffect(() => {
    if (tableName) {
      fetchColumnState(tableName)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [fetchColumnState, status]
}

export default useGetColumnState
