import * as React from 'react'
import StaticGoogleMap from 'Event/components/static-google-map/index'
import { GeoCoordinates } from 'civic-champs-shared/core/location/utils'

export interface SummaryLocationInfoProps {
  displayAddress: string
  radius?: number
  coordinates: GeoCoordinates
  zip: string
}

const SummaryLocationInfo = (props: SummaryLocationInfoProps) => {
  const { displayAddress, coordinates } = props
  return (
    <React.Fragment>
      <div className="summary-info-sidebar__map">
        <StaticGoogleMap zoom={17} coordinates={coordinates} />
      </div>
      <div className="summary-info-sidebar__address">{displayAddress}</div>
    </React.Fragment>
  )
}

export default SummaryLocationInfo
