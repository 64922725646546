import React, {useState} from 'react'
import {TextField} from '@material-ui/core'
import ConfirmPrompt from "../../civic-champs-shared/core/modal/ConfirmPrompt";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  topMargin: {
    marginTop: '20px',
  },
}))

const CommentPrompt = (props) => {

  const { complete, ...otherProps } = props
  const [comment, setComment] = useState('')
  const classes = useStyles()

  const commentComplete = (choice) => {
    return choice ? props.complete({choice: true, comment}) : props.complete({choice: false})
  }

  return (
    <div >
      <ConfirmPrompt
        {...props}
        complete={commentComplete}
      >
        <TextField
          className={classes.topMargin}
          value={comment}
          onChange={e => setComment(e.target.value)}
          {...otherProps}
        />
      </ConfirmPrompt>
    </div>
  )
}

export default CommentPrompt