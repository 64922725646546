
import { DateCell } from 'core/table/cells';
import { createDateValueSort } from 'components/table/sort';


const columns = [
  {
    Header: 'Name',
    accessor: 'name' // String-based value accessors!
  },
  {
    Header: 'Event Start',
    accessor: 'startsAt',
    Cell: DateCell,
    sortType: createDateValueSort('startsAt') 
  },
  {
    Header: 'Event End',
    accessor: 'endsAt',
    Cell: DateCell,
    sortType: createDateValueSort('endsAt')
  }
];

export default function useStartKioskColumns() { return columns }
