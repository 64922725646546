import * as React from 'react'
import classnames from 'classnames'
import './index.scss'
import { FieldProps } from 'formik'
import _ from 'lodash'

enum RATING_VALUE {
  LIKE = 'like',
  DISLIKE = 'dislike'
}

const RatingBlock = (props: FieldProps) => {
  const {
    form: { setFieldValue, errors },
    field: { name, value }
  } = props

  const likeClassnames = classnames(
    'like',
    {
      'selected_rating': value
    }
  )

  const dislikeClassnames = classnames(
    'dislike',
    {
      'selected_rating': value === false
    }
  )

  const strategyToSelectRating: {[key: string]: () => void} = {
    [RATING_VALUE.LIKE]: () => setFieldValue(name, true),
    [RATING_VALUE.DISLIKE]: () => setFieldValue(name, false)
  }

  const handleChange = (key: RATING_VALUE) => strategyToSelectRating[key]

  const errorString = _.get(errors, name) as string

  return (
    <div className="feedback-form-block-rating">
      <div className='feedback-form-block-rating__label'>
        Overall Experience
      </div>
      <div className="feedback-form-block-rating__value">
        <div className={likeClassnames} onClick={handleChange(RATING_VALUE.LIKE)} />
        <div className={dislikeClassnames} onClick={handleChange(RATING_VALUE.DISLIKE)} />
      </div>
      <div className='feedback-rating-error-message'>
        {errorString || ''}
      </div>
    </div>
  )
}

export default RatingBlock
