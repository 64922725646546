import cn from 'classnames'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button/Button'

const useStyles = makeStyles({
  button: {
    color: '#000000',
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    borderRadius: '4px',
    border: 0,
    padding: '6px 10px 6px 16px',
    clear: 'both',
    width: '205px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#D6E3FF',
    },
  },
  icon: {
    color: '#0F5DB5',
    fontSize: '18px !important',
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  disabled: {
    borderWidth: '0 !important',
  },
  label: {
    justifyContent: 'flex-start',
  },
  focusVisible: {
    backgroundColor: '#D6E3FF',
  },
})

export interface TablePopoverButtonProps extends Omit<ButtonProps, 'variant'> {
  iconClassName?: string
}

export const TablePopoverButton: React.FC<TablePopoverButtonProps> = ({ children, className, disabled, ...props }) => {
  const classes = useStyles()
  return (
    <Button
      variant="outlined"
      className={cn(classes.button, className)}
      disabled={disabled}
      classes={{
        ...props.classes,
        startIcon: cn(classes.icon, {
          [classes.disabledIcon]: disabled,
        }),
        endIcon: cn(classes.icon, {
          [classes.disabledIcon]: disabled,
        }),
        disabled: classes.disabled,
        label: classes.label,
        focusVisible: classes.focusVisible,
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default TablePopoverButton
