import useFetchPrograms from 'new-mentorship/hooks/useFetchPrograms'
import { useCallback, useEffect } from 'react'

export const useGetProgramFilterAutocomplete = () => {
  const [fetchPrograms, { result: programs }] = useFetchPrograms()
  useEffect(() => {
    fetchPrograms()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback(() => programs.map(({ id, name }) => ({ title: name, value: id })), [programs])
}

export default useGetProgramFilterAutocomplete
