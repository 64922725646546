import { makeStyles } from '@material-ui/core/styles'

export const useFiltersModalStyles = makeStyles(theme => ({
  helpIcon: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    position: 'relative',
    top: '2px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    // @ts-ignore
    backgroundColor: theme.palette.background.lightBlue,
    margin: 2,
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    padding: '5px 0',
    whiteSpace: 'normal',
    height: 'auto',
    border: 'none',

    '& div.icon-component': {
      marginLeft: '5px',
    },
  },
  chipLabel: {
    whiteSpace: 'normal',
  },
  chipAvatar: {
    marginLeft: '5px',
  },

  field: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#44474F',
    height: 'auto',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit !important',
      borderWidth: '1px !important',
    },
    '&::placeholder': {
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#A9ABB4',
      opacity: 1,
    },
  },
  outlined: {
    padding: '10px 11px',
  },
  table: {
    tableLayout: 'fixed',
  },
  addFilterRowButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: '-10px',
    marginBottom: '4px',
  },
  actionButton: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  row: {
    paddingBottom: '13px',
    '&:first-child': {
      'padding-top': '15px',
    },
  },
  checkboxLabel: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: theme.palette.secondary.main,
  },
  checkbox: {
    color: theme.palette.secondary.main,
    fontSize: '18px',
  },
  checkboxChecked: {
    color: theme.palette.primary.main,
    fontSize: '18px',
  },
  checkboxRoot: {
    padding: '6px 8px',
  },
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: theme.palette.secondary.main,
    margin: 0,
    marginTop: '12px',
    cursor: 'grab',
  },
  subHeader: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.main,
    margin: 0,
  },
  label: {
    top: '-14px',
    left: '11px',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#A9ABB4',
    '&.MuiInputLabel-shrink': {
      display: 'none',
    },
  },
  inputRoot: {
    padding: '1px 32px 0 4px !important',
    '&.MuiInputBase-adornedStart': {
      padding: '8px 32px 8px 11px !important',
    },
  },
  rowField: {
    width: '180px',
  },
  operatorField: {
    width: '160px',
  },
  valueField: {
    width: '240px',
  },
  tagsOption: {
    cursor: 'pointer',
    '& $chip': {
      cursor: 'pointer',
    },
  },
  tagsListbox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export default useFiltersModalStyles
