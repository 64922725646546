import { noop } from 'lodash'
import React, { FC } from 'react'
import { Button, IconButton, makeStyles, Tooltip, TooltipProps } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import { mdiContentDuplicate, mdiDragHorizontal, mdiPlusCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { Field, FieldArray } from 'formik'
import { TextField } from 'formik-material-ui'

import { Checkbox } from 'civic-champs-shared/core/new-checkbox'
import { Question, QuestionType } from 'civic-champs-shared/question-sets/types'
import SelectField from 'civic-champs-shared/formik/components/SelectField'
import EditIcon from '@material-ui/icons/Edit'
import UndoIcon from '@material-ui/icons/Undo'
import { useFeatureEnabled } from 'core/feature/hooks'

//TODO move to ./types!

export interface QuestionItemMetadata {
  itemId: any
  originalQuestion?: QuestionItem
}

//TODO working around questionId/createdAt being required in question :-/
export interface QuestionItem extends Omit<Question, 'questionId' | 'questionCreatedAt'> {
  questionId?: number
  meta: QuestionItemMetadata
}

export interface QuestionItemEditorProps {
  index: any
  question: QuestionItem
  onDuplicate: () => void
  onRemove: () => void
  onEdit: () => void
  onRevertEdit: () => void
  onRequired: () => void
}

const useTooltipStyles = makeStyles({
  tooltip: { color: 'black', backgroundColor: '#fff0b2', fontStyle: 'italic' },
  arrow: { color: '#fff0b2' },
})

const StyledTooltip: FC<TooltipProps> = props => (
  <Tooltip classes={useTooltipStyles()} placement="top-end" arrow={true} {...props} />
)

export const showOptions = (type: QuestionType): boolean =>
  [QuestionType.MULTIPLE_CHOICE, QuestionType.SINGLE_CHOICE, QuestionType.DROPDOWN].includes(type)

export const QuestionItemEditor: FC<QuestionItemEditorProps> = (props: any) => {
  const { index, question, onDuplicate, onRemove, onEdit, onRevertEdit, onRequired } = props
  const {
    questionId,
    meta: { originalQuestion },
  } = question

  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const questionTypeOptions = [
    QuestionType.SHORT_TEXT,
    QuestionType.LONG_TEXT,
    QuestionType.MULTIPLE_CHOICE,
    QuestionType.DROPDOWN,
    QuestionType.SINGLE_CHOICE,
    QuestionType.NUMBER,
    ...(isQuestionnaireAsSurveyEnabled ? [QuestionType.HOME_ADDRESS, QuestionType.EMERGENCY_CONTACT] : []),
  ].map(type => ({ label: type, value: type }))

  return (
    <div className="question-list-item">
      <div className="question-row">
        <IconButton onClick={noop} disabled>
          <Icon path={mdiDragHorizontal} size={1} style={{ fill: '#5C8DE8' }} />
        </IconButton>
        <div className="question-row__item">
          <Field
            name={`questions[${index}].questionType`}
            component={SelectField}
            options={questionTypeOptions}
            disabled={questionId && !originalQuestion}
          />
        </div>
        <div className="question-row__item">
          <Field
            name={`questions[${index}].question`}
            component={TextField}
            classes={{ root: 'custom-input-component' }}
            variant="outlined"
            disabled={questionId && !originalQuestion}
            fullWidth
          />
          <div className="answer-list">
            {showOptions(question.questionType) && (
              <FieldArray
                name={`questions[${index}].options`}
                render={arrayHelpers => {
                  const handleRemove = (index: any) => () => {
                    arrayHelpers.remove(index)
                  }
                  const handleAddNew = () => {
                    arrayHelpers.push('')
                  }

                  //TODO should have an internal draggable context?
                  return (
                    <>
                      {question.options.map((_answer: string, answerIdx: number) => (
                        <div key={answerIdx} className="answer-list-item">
                          <span className="answer-list-item__indicator" />
                          <div className="answer-input-wrap">
                            <Field
                              disabled={questionId && !originalQuestion}
                              name={`questions[${index}].options[${answerIdx}]`}
                              component={TextField}
                              label="Enter answer here"
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                          {answerIdx >= 2 && (
                            <IconButton
                              disabled={questionId && !originalQuestion}
                              onClick={handleRemove(answerIdx)}
                              className="close-icon"
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </div>
                      ))}
                      <Button
                        disabled={questionId && !originalQuestion}
                        className="add-another-question"
                        onClick={handleAddNew}
                        startIcon={<Icon path={mdiPlusCircleOutline} size={0.75} />}
                      >
                        Add another answer
                      </Button>
                    </>
                  )
                }}
              />
            )}
          </div>
        </div>
        <div className="question-row__item">
          <Checkbox value={question.isRequired} onClick={onRequired} label="Required" disabled={!!originalQuestion} />
        </div>
        <div className="question-row__item">
          <StyledTooltip title="Duplicate question">
            <IconButton onClick={onDuplicate}>
              <Icon path={mdiContentDuplicate} size={1} style={{ fill: '#5C8DE8' }} />
            </IconButton>
          </StyledTooltip>
          <StyledTooltip title="Delete question">
            <IconButton onClick={onRemove}>
              <DeleteIcon style={{ color: '#FF6F3E' }} />
            </IconButton>
          </StyledTooltip>
          {questionId && !originalQuestion && (
            <StyledTooltip title="Edit question">
              <IconButton onClick={onEdit}>
                <EditIcon style={{ color: '#5C8DE8' }} />
              </IconButton>
            </StyledTooltip>
          )}
          {!!originalQuestion && (
            <StyledTooltip title="Revert Edits">
              <IconButton onClick={onRevertEdit}>
                <UndoIcon style={{ color: '#5C8DE8' }} />
              </IconButton>
            </StyledTooltip>
          )}
        </div>
      </div>
    </div>
  )
}

export default QuestionItemEditor
