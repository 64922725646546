import { useCallback } from 'react'

import { useCurrentOrg } from 'auth/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import MigrateQuestionnairesConfirmationDialog from '../components/MigrateQuestionnairesConfirmationDialog'

export const useMigrateQuestionnairesConfirmationPrompt = onComplete => {
  const confirmationPrompt = useShowPrompt(MigrateQuestionnairesConfirmationDialog)
  const organization = useCurrentOrg()

  return useCallback(
    () => confirmationPrompt({ organization }).then(onComplete),
    [confirmationPrompt, onComplete, organization],
  )
}

export default useMigrateQuestionnairesConfirmationPrompt
