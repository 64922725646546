import { Notification, NotificationType } from 'notification/interfaces'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import format from 'civic-champs-shared/utils/format'
import { useCurrentOrg } from 'auth/hooks'
import useGetImportUrl from 'import/hooks/useGetImportUrl'

const useStyles = makeStyles((theme: any) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    width: '100%',
    padding: '5px',
    borderBottom: '1px solid #EEF0FA',
    '&:hover': {
      background: '#D6E3FF',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      borderRadius: '4px',
      '& $close': {
        display: 'block',
        marginLeft: '6px',
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#4985DF',
  },
  time: {
    display: 'flex',
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: '8px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: theme.palette.secondary.main,
    },
  },
  body: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  close: {
    fontSize: '14px',
    display: 'none',
  },
}))

export const NotificationBlock = ({
  notification,
  onDelete,
}: {
  notification: Notification
  onDelete: (id: number) => Promise<void>
}) => {
  const classes = useStyles()
  const history = useHistory()
  const org = useCurrentOrg()
  const getUrl = useGetImportUrl()
  const handleClick = useCallback(async () => {
    switch (notification.type) {
      case NotificationType.VOLUNTEER_CREATED:
        history.push(`/champion/${notification?.info?.userId}`)
        break
      case NotificationType.EVENT_REGISTRATION:
      case NotificationType.EVENT_DEREGISTRATION:
        history.push(`/events/${notification?.info?.encodedOccurence}/${org?.id}`)
        break
      case NotificationType.GROUP_APPLICATION:
        history.push(`/groups/${notification?.info?.groupId}`)
        break
      case NotificationType.IMPORT_FINISHED:
        const url = await getUrl(notification?.info?.importId)
        window.open(url)
        break
      case NotificationType.VOLUNTEER_IMPORT_FINISHED:
        history.push(`/champions`)
        break
      default:
        return
    }
  }, [getUrl, history, notification, org])
  const handleClear = useCallback(
    async e => {
      e.stopPropagation()
      await onDelete(notification.id)
    },
    [notification.id, onDelete],
  )

  return (
    <div onClick={handleClick} className={classes.main}>
      <div className={classes.header}>
        <div className={classes.title}>{notification.title}</div>
        <div className={classes.time}>
          <span>{format.timeAgo(notification.createdAt)}</span>
          <CloseIcon className={classes.close} onClick={handleClear} />
        </div>
      </div>
      <div className={classes.body}>{notification.body}</div>
    </div>
  )
}
