import { useCallback, useMemo } from 'react'
import * as _ from 'lodash'
import yup from 'civic-champs-shared/utils/yup'

export const useGetDirtyLookup = value =>
  useCallback(
    credentialIds => {
      return credentialIds.reduce((acc, credentialId, index) => {
        const currentValue = _.get(value, index)
        acc[index] = !currentValue || currentValue !== credentialId
        return acc
      }, {})
    },
    [value],
  )

export const CREATE_NEW_WAIVER = 'CREATE NEW WAIVER'

export const usePickerSchema = (min, max) =>
  useMemo(
    () =>
      yup.object({
        credentialIds: yup
          .array()
          .of(
            yup
              .number()
              .min(1, 'You must select a waiver or delete the row')
              .required('You must select a waiver or delete the row'),
          )
          .min(min, `At least ${min} waivers are required`)
          .max(max, `A maximum of ${max} waivers can be entered`)
          .unique('You cannot select the same credential set twice'),
      }),
    [min, max],
  )

export const useGetOptionsLabel = credentialById =>
  useCallback(
    option => {
      if (option === CREATE_NEW_WAIVER) {
        return CREATE_NEW_WAIVER
      }
      // if the 'option' is passed in by the 'value' prop, it will just be the id
      const optionId = _.get(option, 'credential.id', option)
      return _.get(credentialById, `${optionId}.version.title`, '')
    },
    [credentialById],
  )

export const useSubmit = ({ onSubmit, pickerSchema }) =>
  useCallback(
    values => {
      const castedValues = pickerSchema.cast(values)
      return onSubmit(castedValues.credentialIds)
    },
    [onSubmit, pickerSchema],
  )

export const useHandleNewWaiver = ({ openNewWaiverModal, setCredentials, credentials }) =>
  useCallback(
    async (arrayHelpers, index) => {
      const addedWaiver = await openNewWaiverModal()
      if (addedWaiver) {
        setCredentials([...credentials, addedWaiver])
        arrayHelpers.replace(index, addedWaiver.credential.id)
      }
    },
    [openNewWaiverModal, setCredentials, credentials],
  )

export const useOptions = credentials => useMemo(() => [CREATE_NEW_WAIVER, ...credentials], [credentials])

export const useOnEdit = ({ credentials, setCredentials, openNewWaiverModal }) =>
  useCallback(async (credentialId) => {
    const waiver = await openNewWaiverModal({ credentialId })
    if (waiver) {
      // in case the name changed, swap in the updated waiver
      const index = credentials.findIndex(x => x.credential.id === waiver.credential.id)
      const credentialsClone = _.cloneDeep(credentials)
      _.set(credentialsClone, index, waiver)
      setCredentials(credentialsClone)
    }
  }, [credentials, setCredentials, openNewWaiverModal])
