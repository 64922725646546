import React, { createContext, useCallback, useState } from 'react'
import { isFirstLoginWithToken } from 'civic-champs-shared/auth/utils/isFirstLoginWithToken'

const OverlayContext = createContext({ open: false, setOpen: (v: boolean) => {} })
export default OverlayContext
export const OverlayContextProvider = ({ children }: any) => {
  const [open, setOpen] = useState(isFirstLoginWithToken())
  const handleSetOpen = useCallback((value: boolean) => {
    setOpen(value)
    if (value) {
      localStorage.removeItem('firstLogin')
    } else {
      localStorage.setItem('firstLogin', '1')
    }
  }, [])
  return <OverlayContext.Provider value={{ open, setOpen: handleSetOpen }}>{children}</OverlayContext.Provider>
}
