import { makeStyles } from '@material-ui/core'
import { times } from 'lodash'
import moment from 'moment'
import React from 'react'

const WEEKDAYS = times(7, n => moment().weekday(n).format('dddd'))

const useStyles = makeStyles((theme: any) => ({
    calendarHeaderContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 0 16px 0',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    calendarHeaderWeekdayName: {
        width: 'calc(100% / 7)',
        fontSize: 16,
        lineHeight: '16px',
        color: theme.palette.neutral.darkGray,
        textAlign: 'center',
    }
}))

const CalendarHeader = () => {
    const classes = useStyles()
    return (
        <div className={classes.calendarHeaderContainer}>
            {WEEKDAYS.map( (day, index) => (
                <span key={index} className={classes.calendarHeaderWeekdayName}>
                    {day}
                </span>
            ))}
        </div>
    )
}

export default CalendarHeader
