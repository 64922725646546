import { useCallback } from 'react'

import { useFetchRefactored } from 'civic-champs-shared/api/hooks'

import { useCurrentOrg } from 'auth/hooks'

export const useMultiGroupAddMembers = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored()

  const addMembers = useCallback(
    async (personIds: number[], groupIds: number[]) =>
      request({
        method: 'post',
        url: `/organizations/${organization.id}/multi_group_membership_actions`,
        config: {
          body: {
            personIds,
            groupIds,
          },
        },
      }),
    [request, organization.id],
  )

  return { addMembers, loading, error }
}
