import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export const usePurgeAccount = onResultUpdate => {
  const [request, { loading, error }] = useFetch('Error merging user accounts.  Please refresh the page and try again')
  const showSuccess = useSuccessNotification()

  const purgeAccount = useCallback(
    async deletingPerson => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully deleted account')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/persons/${deletingPerson.id}/deletions`,
      })
    },
    [request],
  )

  return useMemo(() => [purgeAccount, { loading, error }], [purgeAccount, loading, error])
}
