import React from 'react'

import './index.scss'

interface Props {
  id: number
  label: string
  value: string
  checked: boolean
  onChange: (e: any) => void
  disabled?: string
  style?: object
}

const RadioButton = (props: Props) => {
  const {
    id,
    value,
    checked,
    onChange,
    label,
    disabled = false,
    style,
  } = props

  const handleChange = (e: any) => {
    onChange(e.target.value)
  }

  return (
    <label className='custom-radio-button' style={{...style}}>
      <input
        type='radio'
        id={`${id}`}
        value={value}
        checked={checked}
        onChange={handleChange}
        disabled={!!disabled}
      />
      <span className='radio'>
        {checked && <span className='radio__active'/>}
      </span>
      <div className='custom-radio-button__text'>
        {label}
      </div>
    </label>
  )
}

export default RadioButton