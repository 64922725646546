import React, { useMemo } from 'react'
import { Grid, Typography, Box, Button } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import { WithLinkCell, DateCell, NullableTextCell } from 'core/table/cells'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import useQuestionSetsManagement from '../hooks/useQuestionSetsManagement'
import { AddButton } from 'civic-champs-shared/core/add-button'

const useColumns = (duplicateQuestionSet) =>
  useMemo(
    () => [
      {
        id: 'questionSetCreatedAt',
        Header: 'Date',
        accessor: 'questionSetCreatedAt',
        Cell: DateCell,
        filter: 'dateRange',
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: WithLinkCell(NullableTextCell, (id) => `/questionnaires/${id}`, 'questionSetId'),
      },
      {
        Header: 'Created By',
        accessor: 'createdBy',
        Cell: ({
          cell: {
            row: { original: o },
          },
        }) => (o.createdBy ? `${o.createdBy.givenName} ${o.createdBy.familyName}` : '-'),
      },
      // TODO: replace plain button with an icon
      {
        Header: 'Duplicate',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Button onClick={() => duplicateQuestionSet(original)}>Duplicate</Button>,
      },
    ],
    [duplicateQuestionSet],
  )

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.name', 'original.createdBy.givenName', 'original.createdBy.familyName'],
  })

fuzzyMatchFilter.autoRemove = (value) => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const QuestionSetOverview = (props) => {
  const [{ questionSets, loading }, { duplicateQuestionSet }] = useQuestionSetsManagement()

  const [dateRange, setDateRange] = useDateRangeFilter()

  const table = useTable(
    {
      data: useMemo(() => questionSets, [questionSets]),
      columns: useColumns(duplicateQuestionSet),
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = (query) => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange)
    table.setFilter('questionSetCreatedAt', newRange)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid
          container
          xs={12}
          item
          spacing={3}
          style={{ minWidth: '1200px' }}
          justify="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid xs={4} container alignItems="center" item>
            <Grid item>
              <Typography variant="h4">Questionnaires</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <AddButton onClick={() => props.history.push('/questionnaires/new')} />
            </Grid>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>{loading ? <Loading /> : <PagedTable {...table} />}</Grid>
      </Grid>
    </div>
  )
}
