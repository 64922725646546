import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect, useState } from 'react'
import { sortTimeslots } from '../utils'

const useTaskTimeslots = taskId => {
  const [request, { loading }] = useFetch()
  const [timeslots, setTimeslots] = useState([])

  const getTimeslots = useCallback(async () => {
    const result = await request({
      method: 'get',
      url: `/task/${taskId}/timeslots`,
    })

    const orderedSlots = sortTimeslots(result)
    setTimeslots(orderedSlots)

    return orderedSlots
  }, [taskId, request])

  useEffect(() => {
    getTimeslots()
  }, [getTimeslots])

  return {
    timeslots,
    loading,
    refresh: getTimeslots,
  }
}

export default useTaskTimeslots
