import { Dispatch, SetStateAction } from 'react'

interface Props<T> {
  getCount: () => Promise<{ count: number }>
  getChunk: (props: { limit: number; offset: number }) => Promise<T[]>
  limit: number
  setLoadingProgress: Dispatch<SetStateAction<number>>
  setLoading: Dispatch<SetStateAction<boolean>>
}
export async function loadInChunks<T>({
  getCount,
  getChunk,
  limit,
  setLoadingProgress,
  setLoading,
}: Props<T>): Promise<T[]> {
  setLoadingProgress(0)
  setLoading(true)
  const data: T[] = []
  const { count } = await getCount()
  for (let offset = 0; offset < count; offset += limit) {
    data.push(...(await getChunk({ limit, offset })))
    setLoadingProgress(Math.min(100, Math.round(((offset + limit) / count) * 100)))
  }
  setLoadingProgress(100)
  setLoading(false)
  return data
}
