import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Grid } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import  CloseIcon  from '@material-ui/icons/Close';
import OpportunityFlow from './OpportunityFlow'

export default function CreateOrUpdateOpportunityPrompt(props) {
  const { showing, close, opportunity, complete } = props
  const classes = usePromptStyles()

  const isUpdate = !!opportunity

  return (
    <Dialog
      fullScreen
      className='full-screen'
      open={showing}
      onClose={close}
    >
      <DialogTitle className={classes.titleContainer}>
        <Grid container direction='row' justify='space-between'>
          <Grid>
            <Typography className={classes.title}>{ isUpdate ? 'Edit Opportunity' : 'Add Opportunity'}</Typography>        
          </Grid>
          <Grid>
            <IconButton
              onClick={close}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <OpportunityFlow
          opportunity={opportunity}
          closePrompt={close}
          complete={complete}
        />
      </DialogContent>
    </Dialog>
  )
}
