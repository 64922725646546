import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import { useCallback } from 'react'
import RemoveContactPrompt from '../components/RemoveContactPrompt'

export const useRemoveContactPrompt = (onRemoveContact) => {
  const prompt = usePrompt(RemoveContactPrompt)

  return useCallback((personContact) => {
    if (prompt.showing) return;

    if (onRemoveContact) {
      const unlistens = [
        prompt.onComplete(onRemoveContact),
        prompt.onHide(() => { unlistens.forEach(unlisten => unlisten()) })
      ]
    }

    prompt.show({ personContact })
  }, [prompt, onRemoveContact])
}
