import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export function useReferencesToBeDeleted(person, onSuccess) {
  const [request, { loading, error, result, called }] = useFetch(
    'Error fetching references to be deleted.  Please refresh the page to try again',
  )
  const fetchReferencesToBeDeleted = useCallback(
    person => {
      return request({
        method: 'get',
        url: `/persons/${person.id}/delete-preview`,
        onSuccess: result => {
          onSuccess && onSuccess(result)
        },
      })
    },
    [request, onSuccess],
  )

  useEffect(() => {
    if (person) {
      fetchReferencesToBeDeleted(person)
    }
  }, [person])

  return useMemo(
    () => ({
      loading: loading || !called,
      error,
      result,
    }),
    [loading, error, called, result],
  )
}
