import React, { useState } from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import { ConfirmDialog, RadioList } from 'civic-champs-shared/core/confirm-dialog'
import format from 'civic-champs-shared/utils/format'

import { SMS_CREDIT_CONTACT, SMS_CREDIT_PRICE_PER_SMS } from '../config'
import { NotificationBlastContact } from 'messages/hooks/useMessageOverview'

export interface Props {
  recipients: NotificationBlastContact[]
  smsCredits: number
  negativeSmsCreditsLimit?: number
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    textAlign: 'justify',
  },
  field: {
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
  container: {
    marginTop: '-20px',
    overflowY: 'scroll',
  },
  recipientsContainer: {
    maxHeight: '300px',
    marginBottom: '10px',
    overflowY: 'scroll',
    border: '1px solid #E0E3E3',
  },
  recipientsList: {
    margin: 0,
    paddingLeft: '25px',
  },
  recipient: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    padding: '5px',
  },
})

export function NotEnoughSMSCreditsModal(props: Props) {
  const { showing, complete, close, recipients = [], smsCredits, negativeSmsCreditsLimit } = props

  const [emailsOnly, setEmailsOnly] = useState(true)

  const classes = useStyles()

  const isNegativeSmsCreditsAllowed =
    negativeSmsCreditsLimit && smsCredits > 0 && recipients.length - smsCredits < negativeSmsCreditsLimit

  const messageLine1 =
    'Your recipients list contains more text recipients than you have purchased SMS Credits to accommodate.'
  const messageLine2 = isNegativeSmsCreditsAllowed
    ? 'You may choose the following options:'
    : 'You may choose to send this message as email only, and the following recipients will not receive the message:'

  const creditDeficit = recipients.length - smsCredits

  const handleSendTypeChange = (e: any) => setEmailsOnly(e.target.value === 'true')

  const sendEmailsOnly = !isNegativeSmsCreditsAllowed || (isNegativeSmsCreditsAllowed && emailsOnly)

  const handleSubmit = () => {
    complete({ sendEmailsOnly })
  }

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="You do not have enough SMS Credits to complete this action"
      text={`${messageLine1} ${messageLine2}`}
      cancelText="Cancel"
      confirmText={sendEmailsOnly ? 'Send to Email Only' : 'Agree to Charges & Send'}
    >
      <div className={classes.container}>
        {isNegativeSmsCreditsAllowed && (
          <RadioList
            onChange={handleSendTypeChange}
            value={emailsOnly}
            name="send-type"
            options={[
              {
                value: true,
                label: 'Send as Email Only',
              },
              {
                value: false,
                label: 'Send to all recipients, resulting in an SMS Credit deficit',
              },
            ]}
          />
        )}

        {isNegativeSmsCreditsAllowed && emailsOnly && (
          <Typography className={classes.text}>The following recipients will not receive the message:</Typography>
        )}

        {sendEmailsOnly && (
          <div className={classes.recipientsContainer}>
            <ul className={classes.recipientsList}>
              {recipients.map((recipient: NotificationBlastContact) => (
                <li className={classes.recipient} key={recipient.id}>
                  {recipient.firstName} {recipient.lastName} - {format.phoneNumber(recipient.phoneNumber)}
                </li>
              ))}
            </ul>
          </div>
        )}

        {sendEmailsOnly ? (
          <Typography className={classes.text}>
            To purchase more SMS Credits, please contact customer support at{' '}
            <a href={`mailto:${SMS_CREDIT_CONTACT}`}>{SMS_CREDIT_CONTACT}</a>
          </Typography>
        ) : (
          <Typography className={classes.text}>
            You will have a negative SMS Credit balance of {creditDeficit} by sending this message. By sending this
            message, you agree to settle the outstanding invoice of ${creditDeficit * SMS_CREDIT_PRICE_PER_SMS} that
            this transaction incurs.
          </Typography>
        )}
      </div>
    </ConfirmDialog>
  )
}

export default NotEnoughSMSCreditsModal
