import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect, useMemo } from 'react'
import { useCurrentOrg } from 'auth/hooks'

const STABLE_EMPTY_ARRAY = []
const useRequiredGroups = () => {
  const [request, { result, loading }] = useFetch()
  const currentOrg = useCurrentOrg()

  const getRequiredGroups = useCallback(async () => {
    return request({
      method: 'get',
      url: `/organizations/${currentOrg.id}/required-groups`,
    })
  }, [currentOrg, request])

  useEffect(() => {
    getRequiredGroups()
  }, [getRequiredGroups])

  return useMemo(
    () => ({
      requiredGroups: result || STABLE_EMPTY_ARRAY,
      loading,
      refresh: getRequiredGroups,
    }),
    [result, loading, getRequiredGroups],
  )
}

export default useRequiredGroups
