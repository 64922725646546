import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  editIcon: {
    cursor: 'pointer',
    color: '#0F5DB5',
    width: '20px',
  },
  info: {
    minWidth: '700px',
    display: 'flex',
    padding: '0px 2px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sectionHeader: {
    display: 'flex',
    gap: '10px',
    height: '35px',
    alignItems: 'center',
    width: '100%',
  },
  sectionTitle: {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.1px',
    margin: 0,
  },
  sectionRow: {
    display: 'flex',
    fontSize: '14px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    lineHeight: '20px',
    height: '34px',
    alignItems: 'center',
    letterSpacing: '0.1px',
    '& > div': {
      flexShrink: 0,
    },
    '& > div:nth-child(1)': {
      fontWeight: 700,
      width: '172px',
    },
    '& > div:nth-child(2)': {
      minWidth: '200px',
      '& > a': {
        color: '#0F5DB5',
      },
    },
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    margin: '8px 0',
    border: 0,
  },
}))
