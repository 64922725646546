import _ from 'lodash';
import getQueryKey from './getQueryKey';
import getRequest from './getRequest';

const CHARS = '01234567890abcdefghijklmnopqrstuvwxyz';
const TOKEN_LENGTH = 16;

const generateQueryKey = ({ method, url }) => {
    let token = '';
    for(let i = 0; i < TOKEN_LENGTH; i++) {
        token += _.sample(CHARS);
    }

    return `${method} ${url}|${token}`
}

const mutationMethods = ['del', 'post', 'put', 'patch'];
const isMutation = (request) => mutationMethods.includes(request.method || 'get');

export default function instantiateQueryConfig(queryDef, params={}) {
    const request = getRequest(queryDef, params);
    const queryKey = isMutation(request) ? generateQueryKey(request) : undefined;

    let configInstance = {
        ...params,
        ...queryDef,
        queryKey,
        request
    }

    configInstance.queryKey = getQueryKey(configInstance);
    
    let { update, select } = queryDef;

    configInstance.update = update ? update.bind(null, configInstance) : (state) => state
    configInstance.select = select ? (state) => select(configInstance, state.api.results) : (state) => state

    return configInstance;
}