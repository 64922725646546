import { useCallback } from 'react'
import { useFetchRefactored } from 'civic-champs-shared/api/hooks'

export const useCancelEventRegistrations = (encodedOccurrence: string) => {
  const [fetch] = useFetchRefactored<void>({
    successMessage: 'Registrations cancelled successfully',
    errorMessage: 'Failed to cancel registrations',
    useNewNotification: true,
  })

  return useCallback(
    async (ids: number[]) => {
      return fetch({
        method: 'del',
        url: `/opportunity_instances/${encodedOccurrence}/registrations`,
        config: {
          queryStringParameters: { ids },
        },
      })
    },
    [encodedOccurrence, fetch],
  )
}
