import { PostPatchProps, Put, Status } from './fetchRefactoredSchema'
import { useCallback } from 'react'
import useFetchRefactored from './useFetchRefactored'

export default function usePut<T, P>(props: PostPatchProps<T, P>): [Put<T>, Status<T>] {
  const { errorMessage, successMessage, emptyValue, configMapper } = props
  const [request, status] = useFetchRefactored({ errorMessage, successMessage, emptyValue })
  const put = useCallback(
    async (params: any) => {
      const { url, onSuccess, onError, config } = configMapper(params)
      return request({
        method: 'put',
        url,
        onError,
        onSuccess,
        config,
      })
    },
    [request, configMapper],
  )
  return [put, status]
}
