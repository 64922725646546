import React, { useState, Fragment } from 'react'
import { useRetryFailedMigration } from '../hooks/useRetryFailedMigration'
import { useFetchFailedMigrations } from '../hooks/useFetchFailedMigrations'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@material-ui/core'
import { get } from 'lodash'
import { MigrationIssue, eventDisplayNameLookup, migrationIssueTypesFriendlyLookup } from '../interfaces'
import moment from 'moment'

const getEventName = (messageBody: any) => {
  const event = get(messageBody, 'event')
  return get(eventDisplayNameLookup, event, 'Unknown event')
}

const getIssueType = (migrationIssue: MigrationIssue) => {
  const issueType = get(migrationIssue, 'issueType', '')
  return get(migrationIssueTypesFriendlyLookup, issueType, '')
}

const getNewSupporterDetails = (messageBody: any) => {
  const newSupporter = get(messageBody, 'data.newSupporter')
  if (!newSupporter) {
    return
  } else {
    const { firstName, lastName, supporterId } = newSupporter
    const emailContact = get(newSupporter, 'contacts', []).find((contact: any) => contact.type === 'EMAIL')
    const phoneContact = get(newSupporter, 'contacts', []).find((contact: any) =>
      ['CELL_PHONE', 'HOME_PHONE', 'WORK_PHONE'].includes(contact.type),
    )
    return {
      firstName,
      lastName,
      email: emailContact && emailContact.value,
      phoneNumber: phoneContact && phoneContact.value,
      supporterId,
    }
  }
}

const Update = (props: { supporterDetails: any }) => {
  const { supporterDetails } = props
  if (supporterDetails.supporterId) {
    return (
      <span>
        Please click{' '}
        <a
          href={`https://hq.salsalabs.org/#/audience/supporter/${supporterDetails.supporterId}`}
          rel="noopener"
          target="_blank"
        >
          UPDATE
        </a>{' '}
        to fix this issue before retrying.
      </span>
    )
  }
  return null
}

const detailDiv = (migrationIssue: any, classes: any) => {
  const supporterDetails = getNewSupporterDetails(migrationIssue.messageBody)
  if (supporterDetails) {
    return (
      <>
        <ul style={{ paddingLeft: '20px' }}>
          <li>Given Name: {supporterDetails.firstName || '-'}</li>
          <li>Family Name: {supporterDetails.lastName || '-'}</li> <li>Email: {supporterDetails.email || '-'}</li>
          <li>Phone Number: {supporterDetails.phoneNumber || '-'}</li>
        </ul>
        <div>
          <i>
            {migrationIssue.errorMessage}.<Update supporterDetails={supporterDetails} />
          </i>
        </div>
      </>
    )
  } else {
    return (
      <div>
        <i>{migrationIssue.errorMessage}</i>
      </div>
    )
  }
}

export default function SalsaRetryFailedMigrations(props: { classes: any; salsaIntegration: any }) {
  const { classes, salsaIntegration } = props
  const [open, setOpen] = useState<boolean>(false)
  const [failedMigrations, setFailedMigrations] = useState<MigrationIssue[]>([])
  const { loading: initialLoading } = useFetchFailedMigrations(salsaIntegration, setFailedMigrations)
  const { retryFailedMigration, loading: retryLoading } = useRetryFailedMigration(setFailedMigrations)
  const loading = initialLoading || retryLoading
  const onClose = () => setOpen(false)

  return (
    <>
      {failedMigrations.length ? <Button onClick={() => setOpen(true)}>Retry Failed Migrations</Button> : <div />}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={onClose}>
        <DialogTitle style={{ textAlign: 'center', paddingBottom: '6px' }}>
          <b>Retry Failed Migrations</b>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4} direction="row" alignItems="center">
            <Grid item xs={3}>
              <h2>Date</h2>
            </Grid>
            <Grid item xs={3}>
              <h2>Event</h2>
            </Grid>
            <Grid item xs={3}>
              <h2>Details</h2>
            </Grid>
            <Grid item xs={3}>
              <h2>Actions</h2>
            </Grid>
            {failedMigrations.map(migrationIssue => {
              return (
                <Fragment key={migrationIssue.id}>
                  <Grid item xs={3}>
                    <div>{moment(migrationIssue.createdAt).format('MM/DD/YYYY h:mm A')}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div>
                      <b>{getEventName(migrationIssue.messageBody)}</b>
                    </div>
                    <div>
                      <i>{getIssueType(migrationIssue)}</i>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    {detailDiv(migrationIssue, classes)}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      disabled={loading}
                      autoFocus
                      onClick={() => retryFailedMigration(migrationIssue.id, true)}
                    >
                      {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                      Retry
                    </Button>
                    <Button
                      type="submit"
                      className={classes.orangeButton}
                      style={{ marginLeft: '5px' }}
                      disabled={loading}
                      autoFocus
                      onClick={() => retryFailedMigration(migrationIssue.id, false)}
                    >
                      {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                      Skip
                    </Button>
                  </Grid>
                  <Divider />
                </Fragment>
              )
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
