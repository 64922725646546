import React from 'react'
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"

import './index.scss'

interface IProps {
  onClickIcon?: VoidFunction
  title: string
  value: string
}

const AccountViewItem = (props: IProps) => {
  const {
    onClickIcon,
    title,
    value
  } = props

  return(
    <div className='view_item'>
      <div className='view_item_title'>{title}</div>
      <div className='view_item_value_block'>
        <span className={onClickIcon ? 'view_item_value' : ''}>{value}</span>
        {onClickIcon ? <EditOutlinedIcon
          className='view_item_icon'
          fontSize='small'
          color='disabled'
          onClick={onClickIcon}
          /> : null
        }
      </div>
    </div>
  )
}

export default React.memo(AccountViewItem)
