import React from 'react'
import { Field } from 'formik'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import TaskRecipientField from '../formik/TaskRecipientField'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import TaskLocationField from './TaskLocationField'

export default function TaskRecipient(props) {
    const { setFieldValue, values: { recipient, recipientIsContact } } = props

    const handleChangeRecipient = (value) => {
        setFieldValue('recipient', value)
        if (recipientIsContact) {
            setFieldValue('requester', value)
        }
    }

    const handleChangeRecipientIsContact = (e) => {
        setFieldValue('recipientIsContact', e.target.checked)
        if (e.target.checked) {
            setFieldValue('requester', recipient)
        }
    }

    return (
        <div>
            <Grid container direction='column'>
                <Grid item>
                    <Field
                        name="recipient"
                        component={TaskRecipientField}
                        onChange={handleChangeRecipient}
                    />
                </Grid>
                <Grid item>
                    <Field
                        type='checkbox'
                        name='recipientIsContact'
                        Label={{ label: 'Use Recipient as Contact' }}
                        onChange={handleChangeRecipientIsContact}
                    >
                        {({ field }) => (
                            <FormControlLabel
                                label='Use Recipient as Contact'
                                control={
                                    <Checkbox
                                        checked={field.value}
                                        onChange={handleChangeRecipientIsContact}
                                        name='recipientIsContact'
                                    />
                                }
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
           
            <Field
                component={TaskLocationField}
                name='location'
                recipient={recipient}
            />

            <div>
                <StepNavigation />
            </div>
        </div>
    )
}

TaskRecipient.SCHEMA_FIELDS = ['recipient', 'location']