import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import CancelDialog from 'civic-champs-shared/core/confirm-dialog/CancelDialog'
import { BulkAction } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { Row } from 'types/react-table'
import { CancelType } from 'Event/modules/events/details/header'
import useCancelOpportunities from 'Event/hooks/useCancelOpportunities'
import { ListOfEventsRow } from 'Event/components/OngoingOpportunitiesTable'

const useCancelOpportunitiesDialog = ({
  eagerRemoveMany,
  selectedRows,
  opportunities,
}: {
  eagerRemoveMany: BulkAction<OpportunityResponse>
  selectedRows: Row<ListOfEventsRow>[]
  opportunities: OpportunityResponse[]
}) => {
  const showPrompt = useShowPrompt(CancelDialog)
  const cancelOpportunities = useCancelOpportunities()

  return useCallback(async () => {
    const confirmed = await showPrompt({
      text: `Are you sure you want to cancel ${selectedRows.length} opportunities?`,
      title: 'Confirm Action',
      confirmText: 'Yes',
      cancelText: 'No',
    })

    if (confirmed) {
      const selectedIds = selectedRows.map(({ original }) => original.id)
      const rollback = eagerRemoveMany(opportunities.filter(({ id }) => selectedIds.includes(id)))
      try {
        await cancelOpportunities(
          selectedRows.map(({ original }) => original.encodedOccurrence),
          CancelType.Single,
        )
      } catch (error) {
        // error notification is shown by `useFetch` inside `useCancelOpportunities`
        rollback()
      }
    }
  }, [showPrompt, selectedRows, eagerRemoveMany, opportunities, cancelOpportunities])
}

export default useCancelOpportunitiesDialog
