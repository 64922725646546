import { useMessageRecipientRowStyles } from 'messages/hooks/useStyles'
import cn from 'classnames'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import React from 'react'

export const AddRecipient = ({ handleClick }: { handleClick: () => void }) => {
  const classes = useMessageRecipientRowStyles()
  return (
    <div className={cn(classes.recipientRow, classes.button)} onClick={handleClick}>
      <div className={classes.recipientContainer}>
        <AddCircleIcon className={classes.rowIcon} />{' '}
        <div className={classes.recipientNameContainer}>
          <div className={classes.recipientName}>Add Recipient</div>
        </div>
      </div>
    </div>
  )
}
