import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import CreateOrUpdateCredentialPrompt from '../components/CreateOrUpdateCredentialPrompt'
import { useCallback, useMemo } from 'react'

const useCreateOrUpdateCredentialPrompt = (disableDraft?: boolean) => {
  const prompt = usePrompt(CreateOrUpdateCredentialPrompt) as any

  const createOrUpdateCredential = useCallback(
    (credentialId?: number) => {
      if (prompt.showing) return

      prompt.show({ disableDraft, credentialId })
    },
    [prompt, disableDraft],
  )

  return useMemo(() => [createOrUpdateCredential, prompt.onComplete.bind(prompt)], [createOrUpdateCredential, prompt])
}

export default useCreateOrUpdateCredentialPrompt
