import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState } from 'react'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import first from 'lodash/first'
import { findIndex } from 'lodash'
import cn from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { ThemeProvider } from '@material-ui/core/styles'
import { Fade, Popper } from '@material-ui/core'

import { muiTheme } from 'theme'
import { NotificationBlastContact, NotificationBlastPreviewOverview } from 'messages/hooks/useMessageOverview'
import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import { GetFirstLastId, useRecipientsPlaceholderText } from 'messages/hooks/useRecipientsPlaceholderText'
import { FULL_HEIGHT, HALF_HEIGHT, ROW_HEIGHT, useMessageRecipientSelectorStyles } from 'messages/hooks/useStyles'
import { MessagingContact } from '../types'
import { Post } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import PersonEmbeddedAutocomplete, { GetContent } from 'messages/components/PersonEmbeddedAutocomplete'
import { useHandleBlur } from 'civic-champs-shared/helpers/useHandleBlur'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { AddRecipient } from 'messages/components/AddRecipient'
import { RecipientRow } from 'messages/components/RecipientRow'

interface MessageRecipientSelectorProps {
  contacts: NotificationBlastContact[]
  setContacts: Dispatch<SetStateAction<NotificationBlastContact[]>>
  emailsOnly: boolean
  getSearchableContacts: () => Promise<MessagingContact[]>
  fetchMessageOverview: Post<NotificationBlastPreviewOverview>
  autocompleteSectionTitle?: string
}

const sortByFunction = ({ firstName, lastName }: MessagingContact) => `${firstName} ${lastName}`.toLowerCase()

const getFirstLastId: GetFirstLastId<NotificationBlastContact | MessagingContact> = ({ id, firstName, lastName }) => ({
  id,
  firstName,
  lastName,
})

const getContent: GetContent<NotificationBlastContact | MessagingContact> = ({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
}: NotificationBlastContact | MessagingContact) => ({
  id,
  left: `${firstName} ${lastName}`,
  right: email || phoneNumber || '',
})

const MessageRecipientSelector = ({
  contacts,
  setContacts,
  emailsOnly,
  getSearchableContacts,
  autocompleteSectionTitle,
  fetchMessageOverview,
}: MessageRecipientSelectorProps) => {
  const classes = useMessageRecipientSelectorStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [searchableContacts, setSearchableContacts] = useState<MessagingContact[] | null>(null)
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const focusEl = useRef<HTMLDivElement | null>(null)
  const { text, more } = useRecipientsPlaceholderText<NotificationBlastContact>({ contacts, getFirstLastId })
  const contactIds = useMemo(() => map(contacts, 'id'), [contacts])

  const handleBlur = useHandleBlur([anchorEl, focusEl], () => setOpen(false))

  const handleAddRecipient = useCallback(async () => {
    setAutocompleteOpen(true)
    if (isNull(searchableContacts)) {
      const searchableContactsResult = filter(await getSearchableContacts(), ({ id }) => !contactIds.includes(id))
      setSearchableContacts(sortBy(searchableContactsResult, sortByFunction))
    }
  }, [contactIds, getSearchableContacts, searchableContacts])

  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts, displayFrom } =
    useDisplayAreaInfo<NotificationBlastContact>({
      contacts,
      scrollTopPosition,
      rowHeight: ROW_HEIGHT,
      height: autocompleteOpen ? HALF_HEIGHT : FULL_HEIGHT,
    })

  const handleScroll = useHandleScroll(setScrollTopPosition)

  const handleClick = () => {
    setScrollTopPosition(0)
    setOpen(val => !val)
  }

  const removeContact = (index: number) => {
    const { id, firstName, lastName, phoneNumber, email } = first(
      contacts.slice(index, index + 1),
    ) as NotificationBlastContact
    setContacts([...contacts.slice(0, index), ...contacts.slice(index + 1)])
    if (!isNull(searchableContacts)) {
      setSearchableContacts(
        sortBy(
          [
            ...searchableContacts,
            { id, firstName, lastName, phoneNumber: phoneNumber || undefined, email: email || undefined },
          ],
          sortByFunction,
        ),
      )
    }
  }

  const handleAddContact = useCallback(
    async (id: number) => {
      const searchableContact = searchableContacts?.find(({ id: searchableId }) => searchableId === id)
      setContacts(contacts => [
        ...contacts,
        {
          ...searchableContact,
          email: null,
          phoneNumber: null,
          sendToPhone: false,
          isPhoneBounced: false,
          isEmailBounced: false,
          loading: true,
        } as NotificationBlastContact,
      ])
      if (!isNull(searchableContacts)) {
        const index = findIndex(searchableContacts, { id })
        setSearchableContacts([...searchableContacts.slice(0, index), ...searchableContacts.slice(index + 1)])
      }
      const {
        contacts: [contactInfo],
      } = await fetchMessageOverview({ recipientPersonIds: [id] })
      setContacts(contacts => contacts.map(contact => (contact.id === contactInfo.id ? contactInfo : { ...contact })))
    },
    [fetchMessageOverview, searchableContacts, setContacts],
  )

  return (
    <>
      <div
        id="message-recipient-selector"
        className={cn(classes.input, { [classes.open]: open })}
        tabIndex={-1}
        onClick={handleClick}
        ref={anchorEl}
      >
        <div className={classes.text}>
          {text}
          {more ? (
            <span>
              {'\u00A0'}+{more} more
            </span>
          ) : null}
        </div>
        {open ? (
          <ExpandLessIcon className={classes.expandCollapse} />
        ) : (
          <ExpandMoreIcon className={classes.expandCollapse} />
        )}
      </div>
      <Popper
        className={classes.popper}
        id="message-recipient-selector-popper"
        open={open}
        anchorEl={anchorEl.current}
        transition
        tabIndex={-1}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div tabIndex={-1} className={classes.popperPaper} ref={focusEl} onBlur={handleBlur}>
              <div
                className={cn(classes.recipients, { [classes.shortened]: autocompleteOpen })}
                onScroll={handleScroll}
              >
                <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />

                {visibleContacts.map((contact, index) => (
                  <RecipientRow
                    key={contact.id}
                    contact={contact}
                    emailsOnly={emailsOnly}
                    onRemove={() => removeContact(displayFrom + index)}
                  />
                ))}
                <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
              </div>
              <div style={{ borderTop: '1px rgba(0,0,0,0.1) solid', margin: '5px 9.5px' }} />
              {autocompleteOpen ? (
                <PersonEmbeddedAutocomplete<MessagingContact>
                  sectionTitle={autocompleteSectionTitle}
                  onAdd={handleAddContact}
                  selectedContactCount={contacts.length}
                  contacts={searchableContacts}
                  getFirstLastId={getFirstLastId}
                  getContent={getContent}
                />
              ) : (
                <AddRecipient handleClick={handleAddRecipient} />
              )}
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default (props: MessageRecipientSelectorProps) => (
  <ThemeProvider theme={muiTheme}>
    <MessageRecipientSelector {...props} />
  </ThemeProvider>
)
