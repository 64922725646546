import { connect } from 'react-redux'
import App from './App'

import { currentUser } from '../auth/selectors'

const mapStateToProps = state => ({
  isKioskMode: state.kiosk.status !== 'inactive',
  currentUser: currentUser(state),
})

export default connect(mapStateToProps)(App)
