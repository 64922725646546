import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import PageHeader from 'App/components/PageHeader'
import useDocumentTypesCollection, { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import Loading from 'components/Loading'
import find from 'lodash/find'
import { useFetchDocuments } from 'documents/hooks/useFetchDocuments'
import useDocumentsColumns, { useStyles } from 'documents/hooks/useDocumentColumns'
import { useColumnOrder, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import Grid from '@material-ui/core/Grid'
import { ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { ExportDocumentsButton } from 'documents/components/ExportDocumentsButton'
import { TableMenuButton } from 'civic-champs-shared'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import useDeleteDocumentsPrompt from 'documents/hooks/useDeleteDocumentsPrompt'
import map from 'lodash/map'

const tableName = 'document_file'
const getOperatorOptions = (column: string) => []

export const Documents = (props: RouteComponentProps<{ type: string }>) => {
  const [{ documentTypes, initiallyLoaded }] = useDocumentTypesCollection()
  if (!initiallyLoaded) return <Loading />
  const documentType = find(documentTypes, { id: parseInt(props.match.params.type) }) as DocumentType
  return (
    <PageHeader title={documentType?.name} subTitle="Back to Documents" backLink="/documents">
      <DocumentsComponent documentTypeName={documentType?.name} {...props} />
    </PageHeader>
  )
}

interface DocumentsComponentProps extends RouteComponentProps<{ type: string }> {
  documentTypeName: string
}

export const DocumentsComponent = ({
  location,
  history,
  match: {
    params: { type },
  },
  documentTypeName,
}: DocumentsComponentProps) => {
  const columns = useDocumentsColumns()
  const columnStyles = useStyles()
  const { fetchDocuments, loading, documents } = useFetchDocuments()
  const showDeleteDocumentsPrompt = useDeleteDocumentsPrompt()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  useEffect(() => {
    fetchDocuments(parseInt(type))
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
        // @ts-ignore
        filters,
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: documents,
      // @ts-ignore
      columns,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const handleDelete = useCallback(async () => {
    await showDeleteDocumentsPrompt(map(table.selectedFlatRows, 'original'))
    await fetchDocuments(parseInt(type))
  }, [fetchDocuments, showDeleteDocumentsPrompt, table.selectedFlatRows, type])

  if (loading || columnStateLoading) return <Loading />
  // @ts-ignore
  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Documents"
        buttons={
          <>
            <ExportDocumentsButton data={table.selectedFlatRows} documentTypeName={documentTypeName} />
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={handleDelete}
              disabled={table.selectedFlatRows.length === 0}
              rightMargin
            >
              Delete
            </TableMenuButton>
          </>
        }
        useGlobalSearch
      />
    </Grid>
  )
}
export default Documents
