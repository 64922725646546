import React, { useCallback } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import TaskStatus from '../TaskStatus'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCompleteTaskPrompt } from '../hooks'
import AcceptTaskPrompt from './AcceptTaskPrompt'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
    updateButton: {
        backgroundColor: theme.palette.accent.lightBlue.main,
        color: theme.palette.neutral.darkGrey,
        '&:hover': {
            backgroundColor: theme.palette.accent.lightBlue.dark,
        }
    },
    approveButton: {
        backgroundColor: theme.palette.accent.yellowGreen.main,
        color: theme.palette.neutral.darkGray,
        '&:hover': {
            backgroundColor: theme.palette.accent.yellowGreen.dark
        }
    },
}))

export default function StatusButton(props) {
    const { task, refreshParent, fullWidth } = props
    const history = useHistory()
    const classes = useStyles()

    const acceptTaskPrompt = useShowPrompt(AcceptTaskPrompt)
    const openAcceptTaskPrompt = useCallback(async (props) => {
        await acceptTaskPrompt({...props})
        refreshParent()
    }, [acceptTaskPrompt, refreshParent])
    const openCompleteTaskPrompt = useCompleteTaskPrompt(refreshParent)
    // TODO: these should really be a modal like the above
    const openAssignVolunteer = useCallback((taskId) => { history.push(`/tasks/${taskId}/add-volunteer`) }, [history])
    const openUpdateTask = useCallback((taskId) => { history.push(`/tasks/${taskId}/update`) }, [history])

    let buttonProps = {}
    let buttonLabel = ''
    switch (task.taskStatus) {
        case (TaskStatus.InReview):
            buttonLabel = 'Approve'
            buttonProps = {
                onClick: () => { openAcceptTaskPrompt({ task }) },
                className: classes.approveButton,
                fullWidth
            }
            break
        case (TaskStatus.Accepted):
            buttonLabel = 'Assign'
            buttonProps = {
                onClick: () => { openAssignVolunteer(task.id) },
                color: 'primary',
                fullWidth
            }
            break
        case (TaskStatus.Scheduled):
            buttonLabel = 'Update'
            buttonProps = {
                onClick: () => { openUpdateTask(task.id) },
                className: classes.updateButton,
                fullWidth
            }
            break
        case (TaskStatus.InProgress):
            buttonLabel = 'Complete'
            buttonProps = {
                onClick: () => { openCompleteTaskPrompt(task) },
                color: 'secondary',
                fullWidth
            }
            break
        default:
            return null
    }
    return(
        <Button
            variant='contained'
            {...buttonProps}
        >
            {buttonLabel}
        </Button>
    )
}
