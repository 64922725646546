import { makeStyles } from '@material-ui/core/styles'

const basicTextStyle = {
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#000000',
}

export const useMessageOverviewStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 20px 0',
    borderBottom: '1px solid #EEF0FA',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&>div': {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#000000',
      '&>span': basicTextStyle,
    },
  },
  attachments: {
    width: '100%',
    display: 'flex',
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#000000',
  },
  attachmentLink: {
    color: theme.palette.primary.main,
    marginLeft: '10px',
  },
  body: {
    ...basicTextStyle,
    paddingLeft: '28px',
    '& p, & span': basicTextStyle,
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '28px',
    color: '#000000',
    marginTop: '10px',
  },
  selectColumn: {
    '&>div': {
      width: '36px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '150px',
      '&>a': {
        textOverflow: 'ellipsis',
      },
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '100px',
      '&>a>div': {
        textOverflow: 'ellipsis',
      },
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '100px',
      '&>a>div': {
        textOverflow: 'ellipsis',
      },
    },
  },
  contactColumn: {
    '&>div': {
      width: '300px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '100px',
    },
  },
  errorIcon: {
    verticalAlign: 'middle',
  },
  successIcon: {
    color: 'orange',
    verticalAlign: 'middle',
  },
}))
