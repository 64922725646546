import { Button, Grid } from '@material-ui/core'
import { Field } from 'formik'
import * as _ from 'lodash'
import { CREATE_NEW_QUESTION_SET } from './hooks'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Icon } from '@mdi/react'
import { mdiMinusCircleOutline } from '@mdi/js'
import React from 'react'
import CheckboxField from 'civic-champs-shared/question-sets/components/CheckboxField'

export const DirtyRow = ({
  index,
  options,
  handleNewQuestionSet,
  arrayHelpers,
  showRequired,
  formikBag,
  keyField = 'questionSetId',
}) => {
  const { values, setFieldValue, errors, isSubmitting } = formikBag

  return (
    <>
      <Grid item xs={8}>
        <Field
          name={`questionSets.${index}.${keyField}`}
          options={options}
          disableClearable
          disabled={isSubmitting}
          value={_.get(values, `questionSets.${index}`)}
          getOptionLabel={option => option.name || CREATE_NEW_QUESTION_SET}
          component={Autocomplete}
          onChange={async (event, selection) => {
            if (selection === CREATE_NEW_QUESTION_SET) {
              await handleNewQuestionSet(arrayHelpers, index)
            } else {
              setFieldValue(`questionSets.${index}.name`, selection.name)
              setFieldValue(`questionSets.${index}.${keyField}`, selection[keyField])
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!_.get(errors, `questionSets.${index}.${keyField}`)}
              helperText={_.get(errors, `questionSets.${index}.${keyField}`)}
              classes={{ root: 'custom-input-component' }}
              variant="outlined"
              fullWidth
              placeholder="Select a questionnaire"
            />
          )}
        />
      </Grid>
      {showRequired && (
        <Grid item xs={2} container justify="center">
          <Field
            component={CheckboxField}
            name={`questionSets.${index}.optional`}
            label="Optional"
            disabled={isSubmitting}
            onChange={() =>
              setFieldValue(`questionSets.${index}.optional`, !_.get(values, `questionSets.${index}.optional`))
            }
            labelstyle={{ font: 'normal normal normal 18px/24px Open Sans', marginTop: '15px' }}
          />
        </Grid>
      )}
      <Grid item container xs={showRequired ? 2 : 4} justify="flex-end" direction="row">
        <Button
          variant="contained"
          style={{ backgroundColor: '#ff6f3e', color: 'white' }}
          onClick={() => arrayHelpers.remove(index)}
          disabled={isSubmitting}
        >
          <Icon path={mdiMinusCircleOutline} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
          Remove
        </Button>
      </Grid>
    </>
  )
}
