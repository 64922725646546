import React from 'react'
import classNames from 'classnames'

//components
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

//styles
import './index.scss'
import { IconButton } from '@material-ui/core'

type Props = {
  onClick: () => void
  direction?: 'left' | 'right'
  disabled: boolean
  className?: string
}

export const ChevronButton = ({ onClick, direction, disabled, className }: Props): JSX.Element => {
  const classes = classNames('chevron-button', className)
  const Chevron =
    direction === 'left'
      ? ChevronLeft
      : direction === 'right'
      ? ChevronRight
      : () => {
          throw new Error(`unknown chevron direction ${direction}`)
        }

  return (
    <IconButton onClick={onClick} className={[classes, disabled ? 'disabled' : ''].join(' ')} disabled={disabled}>
      <Chevron fontSize="large" />
    </IconButton>
  )
}

export default ChevronButton
