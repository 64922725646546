import { Credential, CredentialContentType, OrgNamedRef } from 'civic-champs-shared/question-sets/types'
import moment from 'moment-timezone'
import yup from '../civic-champs-shared/utils/yup'
import { CredentialExpirationOption } from './models'

export const getExpirationRate = (value: Credential): string => {
  switch (value.expirationOption) {
    case CredentialExpirationOption.Updated:
      return 'Never'
    case CredentialExpirationOption.Date:
      const date = moment.tz(value.expirationDate, (value.organization as OrgNamedRef).timeZone as string)
      return `Annually on ${date.format('MM/DD')}`
    case CredentialExpirationOption.Year:
      return value.expirationOptionLength === 1 ? 'Yearly' : `Every ${value.expirationOptionLength} years`
    case CredentialExpirationOption.Month:
      return value.expirationOptionLength === 1 ? 'Monthly' : `Every ${value.expirationOptionLength} months`
    case CredentialExpirationOption.Day:
      return value.expirationOptionLength === 1 ? 'Daily' : `Every ${value.expirationOptionLength} days`
    case CredentialExpirationOption.DayOf:
      return 'After Event ends'
    default:
      return ''
  }
}

export const defaultWaiverText =
  '<p>By signing below, I affirm that I have read and understood the terms of this waiver agreement, and I willingly and voluntarily agree to be bound by its provisions.</p>'

export const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string(),
  content: yup
    .string()
    .when('contentType', (contentType: CredentialContentType, schema: any) =>
      contentType === CredentialContentType.Text ? schema.required('Body is required') : schema,
    ),
  contentType: yup.string(),
  url: yup
    .string()
    .when('contentType', (contentType: CredentialContentType, schema: any) =>
      contentType === CredentialContentType.Url ? schema.required('URL is required') : schema,
    ),
  waiverStatement: yup.string(),
  expirationOption: yup.string(),
  expirationOptionLength: yup
    .number()
    .when('expirationOption', (expirationOption: CredentialExpirationOption, schema: any) =>
      [CredentialExpirationOption.Day, CredentialExpirationOption.Month, CredentialExpirationOption.Year].includes(
        expirationOption,
      )
        ? schema.required('Expiration length is required')
        : schema,
    ),
  expirationDate: yup
    .date()
    .when('expirationOption', (expirationOption: CredentialExpirationOption, schema: any) =>
      expirationOption === CredentialExpirationOption.Date ? schema.required('Expiration Date is required') : schema,
    ),
  statementType: yup.string(),
  note: yup.string(),
})

export const contentTypeOptions = [
  {
    label: 'Create a custom waiver with formatted text',
    value: CredentialContentType.Text,
  },
  {
    label: 'Direct volunteers to an external waiver with a linked URL',
    value: CredentialContentType.Url,
  },
]

export const statementTypeOptions = [
  {
    label: 'Use the Default Agreement Statement',
    value: 'default',
  },
  {
    label: 'Use a Custom Agreement Statement',
    value: 'custom',
  },
]

export const credentialExpirationOptions = [
  {
    label: 'Set number of months',
    value: CredentialExpirationOption.Month,
  },
  {
    label: 'Set number of years',
    value: CredentialExpirationOption.Year,
  },
  {
    label: 'Set number of days',
    value: CredentialExpirationOption.Day,
  },
  {
    label: 'Specific day of the year',
    value: CredentialExpirationOption.Date,
  },
  {
    label: 'After Event ends',
    value: CredentialExpirationOption.DayOf,
  },
  {
    label: 'Never unless updated',
    value: CredentialExpirationOption.Updated,
  },
]
