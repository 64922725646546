import React from 'react'
import { Typography,  Grid, makeStyles } from '@material-ui/core'
import MailIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'

import format from 'civic-champs-shared/utils/format'
import { MergeAccountReferenceList } from 'people/components/MergeAccountReferenceList';

const useStyles = makeStyles({
    contact: {
        lineHeight: '2rem',
        display: 'flex',
        alignItems: 'center'
    }
})

export interface MergeDetailsProps { 
    title: string
    person: any
    references?: any[]
 }

export const MergeDetails: React.FC<MergeDetailsProps> = (props) => { 
    const { title, person, references } = props
    const classes = useStyles()

    return (<Grid container direction="column">
        <Grid item>
            <Typography variant="h3" gutterBottom>{title}</Typography>
            <Typography>{person.givenName} {person.familyName} (id:{person.id})</Typography>
            {person.email && <Typography className={classes.contact}>
                <MailIcon />
                {person.email}
            </Typography>}
            {person.phoneNumber && <Typography className={classes.contact}>
                <PhoneIcon />
                {person.phoneNumber}
            </Typography>}
            <Typography>Added: {format.date(person.createdAt)}</Typography>
            <Typography>Account Status: {person.cognitoSub ? 'Registered' : 'None'}</Typography>
        </Grid>
        <Grid item>
            <MergeAccountReferenceList
                person={person}
                references={references}
            />
        </Grid>
    </Grid>)
}