import map from 'lodash/map'
import join from 'lodash/join'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { useCallback } from 'react'

import { useCreateApiNotification, useFetch } from 'civic-champs-shared/api/hooks'
import { volunteerSummary } from './utils'

const useRemoveVolunteer = eagerRemoveMany => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (volunteers, include = []) => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Removing ${volunteerName} from volunteers...`
        : `Removing ${numVolunteers} volunteers...`
      const undo = eagerRemoveMany(volunteers)
      const notification = createNotification(text)
      try {
        await request({
          method: 'del',
          url: `/volunteers`,
          config: {
            queryStringParameters: omitBy(
              {
                ids: join(
                  map(volunteers, item => item.id),
                  ',',
                ),
                include: include.join(','),
              },
              isEmpty,
            ),
          },
        })

        notification.onSuccess('Volunteer removed!')
      } catch (error) {
        const text = onlyOneVolunteer
          ? `Could not remove ${volunteerName} from volunteers`
          : `Could not remove ${numVolunteers} volunteers`
        notification.onError(text, error)
        undo()
      }
    },
    [createNotification, eagerRemoveMany, request],
  )
}

export default useRemoveVolunteer
