import * as React from 'react'
import { compose, withProps } from 'recompose'
import { withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps'
import { RefObject } from 'react'
import { GeoCoordinates } from 'civic-champs-shared/core/location/utils'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'

const GOOGLE_MAP_PARAMS = {
  containerElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />
}

interface ReactGoogleMapProps {
  coordinates: GeoCoordinates
  geofenceRadius?: number
  zoom?: number
}

class RenderGoogleMapComponent extends React.Component<ReactGoogleMapProps> {

  componentDidMount() {
    this.getFitBounds()
  }

  componentDidUpdate(prevProps: ReactGoogleMapProps) {
    if (this.props.geofenceRadius !== prevProps.geofenceRadius) {
      this.getFitBounds()
    }
  }

  private mapRef: RefObject<GoogleMap> = React.createRef()
  private circleRef: RefObject<Circle> = React.createRef()

  render() {
    const {
      coordinates = DEFAULT_GEOFENCING.location.coordinates,
      geofenceRadius,
      zoom = 14
    } = this.props

    const circleColor = '#ffd600'

    const circleOptions : {
      center: GeoCoordinates
      strokeColor: string
      strokeOpacity: number
      strokeWeight: number
      fillColor: string
      fillOpacity: number
    } = {
      center: coordinates,
      strokeColor: circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      fillColor: circleColor,
      fillOpacity: 0.5,
    }

    return (
      <GoogleMap
        ref={this.mapRef}
        defaultOptions={{disableDefaultUI:true}}
        zoom={zoom}
        center={coordinates}
      >
        {geofenceRadius &&
          // @ts-ignore
          <Circle
            ref={this.circleRef}
            defaultRadius={0}
            radius={this.props.geofenceRadius}
            options={circleOptions}
          />
        }
        <Marker position={coordinates} />
      </GoogleMap>
    )
  }

  private getFitBounds = () => {
    if (this.mapRef.current && this.circleRef.current) {
      const circle = this.circleRef.current
      const bounds = circle.getBounds()
      this.mapRef.current.fitBounds(bounds)
    }
  }
}

const ReactGoogleMap = compose<ReactGoogleMapProps, ReactGoogleMapProps>(
  withProps(GOOGLE_MAP_PARAMS),
  withGoogleMap
)(RenderGoogleMapComponent)

export default ReactGoogleMap
