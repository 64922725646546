import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import CancelDialog from 'civic-champs-shared/core/confirm-dialog/CancelDialog'
import useDeleteNote from 'note/hooks/useDeleteNote'

const useDeleteNoteDialog = (onDelete?: () => Promise<any>) => {
  const showPrompt = useShowPrompt(CancelDialog)
  const [removeNote] = useDeleteNote()

  return useCallback(
    async note => {
      const confirmed = await showPrompt({
        text: `Are you sure you want to delete ${note.title}? This action can not be undone. `,
        title: 'Confirm Action',
        confirmText: 'Delete',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        await removeNote(note.id)
        onDelete && (await onDelete())
      }
    },
    [showPrompt, removeNote, onDelete],
  )
}

export default useDeleteNoteDialog
