import React, { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'

const empty = []

export default function useOrganizationLongCodes() {
  const [request, { loading, error, result, called }] = useFetch()
  const { id } = useCurrentOrg()
  const getLongCode = useCallback(() => {
    return request({ method: 'get', url: `/organizations/${id}/long-codes` })
  }, [request, id])

  useEffect(() => {
    getLongCode()
  }, [getLongCode])

  return useMemo(
    () => ({
      longCodes: result || empty,
      error,
      loading: loading || !called,
      refresh: getLongCode
    }),
    [result, error, loading, called, getLongCode],
  )
}
