import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'
import { Field, Form, Formik } from 'formik'
import map from 'lodash/map'

// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import yup from 'civic-champs-shared/utils/yup'

import { muiTheme } from 'theme'

import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import CKEditorField from 'civic-champs-shared/formik/components/CkEditorField'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import Loading from 'civic-champs-shared/core/Loading'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import useMessageRecipients from 'messages/hooks/useMessageRecipients'
import FailedRecipientsList from 'components/dialog/FailedRecipientsList'
import useMessageOverview, { NotificationBlastContact } from 'messages/hooks/useMessageOverview'
import ExportFailedRecipientsButton from 'messages/components/ExportFailedRecipientsButton'
import MessageRecipientSelector from './MessageRecipientSelector'
import { useMessageRecipientsPromptStyles } from 'messages/hooks/useStyles'
import { getTextWidth } from 'utils/getTextWidth'
import { MessageSourceType, MessagingContact } from '../types'
import { AttachQuestionnaires } from './AttachQuestionnaires'
import { NotEnoughSMSCreditsModal } from './NotEnoughSMSCreditsModal'

interface MessageRecipientsPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  recipientPersonIds: number[]
  getSearchableContacts: () => Promise<MessagingContact[]>
  autocompleteSectionTitle?: string
  sourceType?: MessageSourceType
  sourceId?: number | string
}

const initialValues = {
  emailsOnly: false,
  subject: '',
  message: '',
}

const formSchema = yup.object({
  subject: yup.string().min(5).required(),
  message: yup.string().required(),
  attachments: yup
    .object({
      questionSets: yup
        .array()
        .of(
          yup.object({
            // @ts-ignore
            questionSetId: yup.number().id(),
            required: yup.boolean().default(false),
          }),
        )
        .notRequired()
        .nullable(),
    })
    .notRequired()
    .nullable(),
})

interface RecipientsGroups {
  emailPersons: NotificationBlastContact[]
  smsPersons: NotificationBlastContact[]
  failedRecipients: NotificationBlastContact[]
}

export function MessageRecipientsPrompt(props: MessageRecipientsPromptProps) {
  const {
    showing,
    close,
    complete,
    recipientPersonIds,
    getSearchableContacts,
    autocompleteSectionTitle,
    sourceType,
    sourceId,
  } = props
  const [contacts, setContacts] = useState<NotificationBlastContact[]>([])
  const [failure, setFailure] = useState(false)
  const [emailsOnly, setEmailsOnly] = useState(false)
  const [fontSize, setFontSize] = useState(12)
  const [messageOverviewLoading, setMessageOverviewLoading] = useState(true)
  const classes = useMessageRecipientsPromptStyles({ failure })
  const [
    fetchMessageOverview,
    {
      result: { smsCredits, negativeSmsCreditsLimit },
    },
  ] = useMessageOverview()
  const [messageRecipients] = useMessageRecipients()

  useEffect(
    () => {
      fetchMessageOverview({ recipientPersonIds }).then(result => {
        setContacts(result.contacts)
        setMessageOverviewLoading(false)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getRecipientGroups = useCallback(
    (contacts: NotificationBlastContact[], emailsOnly: boolean) =>
      contacts.reduce(
        (acc, contact) => {
          if (contact.doNotContact) {
            acc.failedRecipients.push(contact)
          } else {
            if (contact.sendToPhone && !emailsOnly) {
              acc.smsPersons.push(contact)
            } else if (contact.email) {
              acc.emailPersons.push(contact)
            } else {
              acc.failedRecipients.push(contact)
            }
          }
          return acc
        },
        {
          emailPersons: [],
          smsPersons: [],
          failedRecipients: [],
        } as RecipientsGroups,
      ),
    [],
  )

  const { emailPersons, smsPersons, failedRecipients } = useMemo(
    () => getRecipientGroups(contacts, emailsOnly),
    [contacts, emailsOnly, getRecipientGroups],
  )

  useEffect(() => {
    async function processFontSize() {
      let size = 12
      const width = await getTextWidth(`Text Messages: ${smsPersons.length}`, '700 12px/16px Nunito', 0.5)
      const text = `\u00A0(${smsCredits} SMS Sends Available)`
      while (width + (await getTextWidth(text, `400 ${size}px/16px Nunito`, 0.5)) > 280) {
        size--
      }
      setFontSize(size)
    }
    processFontSize()
  }, [smsCredits, smsPersons.length])

  const showNotEnoughSMSCreditsModal = useShowPrompt(NotEnoughSMSCreditsModal)

  const handleSubmit = useCallback(
    async (values: { subject: string; message: string; attachments?: { questionnaires: number[] } }) => {
      const { subject, message: body, attachments } = values

      let recipientsGroups: RecipientsGroups = { emailPersons, smsPersons, failedRecipients }

      if (
        smsPersons.length > 0 &&
        (smsCredits <= 0 || (negativeSmsCreditsLimit && smsPersons.length - smsCredits > negativeSmsCreditsLimit))
      ) {
        close()
        const { sendEmailsOnly } = await showNotEnoughSMSCreditsModal({
          recipients: smsPersons,
          smsCredits,
          negativeSmsCreditsLimit,
        })

        if (sendEmailsOnly) {
          recipientsGroups = getRecipientGroups(contacts, true)
        }
      }

      const data = await messageRecipients({
        subject,
        body,
        emailPersonIds: map(recipientsGroups.emailPersons, 'id'),
        smsPersonIds: map(recipientsGroups.smsPersons, 'id'),
        sourceType,
        sourceId,
        attachments,
      })
      if (failedRecipients.length) {
        setFailure(true)
      } else {
        complete(data)
      }
    },
    [
      close,
      complete,
      contacts,
      emailPersons,
      failedRecipients,
      getRecipientGroups,
      messageRecipients,
      negativeSmsCreditsLimit,
      showNotEnoughSMSCreditsModal,
      smsCredits,
      smsPersons,
      sourceId,
      sourceType,
    ],
  )
  if (messageOverviewLoading) {
    return <Loading />
  }

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#message-users-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="message-users-prompt">
              {failure ? 'Failures' : 'New Message'}
              <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                <CloseIcon className={classes.dialogCloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              {!failure && (
                <>
                  <div className={classes.recipients}>
                    <p>To:</p>
                    <div className={classes.recipientsCenter}>
                      <div className={classes.recipientsInner}>
                        <MessageRecipientSelector
                          emailsOnly={emailsOnly}
                          contacts={contacts}
                          setContacts={setContacts}
                          getSearchableContacts={getSearchableContacts}
                          autocompleteSectionTitle={autocompleteSectionTitle}
                          fetchMessageOverview={fetchMessageOverview}
                        />
                        <div className={classes.recipientCounters}>
                          <p>Emails: {emailPersons.length}</p>
                          <p>
                            Text Messages: {smsPersons.length}
                            <span style={{ fontSize: `${fontSize}px` }}>
                              {'\u00A0'}({smsCredits} SMS Sends Available)
                            </span>
                          </p>
                          <p>Failed Messages: {failedRecipients.length}</p>
                        </div>
                      </div>
                      <FormGroup row>
                        <FormControlLabel
                          classes={{ label: classes.sendChannel }}
                          control={
                            <Checkbox color="primary" value={emailsOnly} onChange={() => setEmailsOnly(v => !v)} />
                          }
                          label="Send to Emails Only"
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <Field
                    fullWidth
                    name="subject"
                    label="Subject"
                    className={classes.whiteBg}
                    component={StyledOutlinedTextField}
                  />
                  <div className={classes.editorContainer}>
                    <Field
                      component={CKEditorField}
                      editor={ClassicEditor}
                      config={{
                        toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Blockquote', 'Link', 'Unlink'],
                      }}
                      transformed
                      label="Message"
                      name="message"
                    />
                  </div>
                  <div className={classes.attachmentContainer}>
                    <Field
                      component={AttachQuestionnaires}
                      id="attachedQuestionnaires"
                      name="attachments.questionnaires"
                      fullWidth
                    />
                  </div>
                </>
              )}
              {failure && (
                <FailedRecipientsList
                  errorText={
                    'The following recipient(s) did not receive the message because they do not have valid contact information on file. Visit their volunteer profile to add a valid email address or phone number.'
                  }
                  title={'Failed Recipients'}
                  rows={failedRecipients.map(({ firstName, lastName }) => `${firstName} ${lastName}`)}
                  exportButton={<ExportFailedRecipientsButton data={failedRecipients} />}
                />
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              {!failure ? (
                <OutlinedButton disabled={isSubmitting || messageOverviewLoading} onClick={close}>
                  Cancel
                </OutlinedButton>
              ) : (
                <div />
              )}
              <ContainedButton
                disabled={isSubmitting || messageOverviewLoading}
                isLoading={isSubmitting}
                onClick={failure ? close : submitForm}
              >
                {failure ? 'Close' : 'Send'}
              </ContainedButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: MessageRecipientsPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <MessageRecipientsPrompt {...props} />
  </ThemeProvider>
)
