import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsPage } from 'Event/redux/selectors/EventNavigation'
import { EventPages, setEventsPage as setEventsPageAction } from 'Event/redux/actions/EventNavigation'

export const useEventsPageState = () => {
  const eventsPage = useSelector(getEventsPage)
  const dispatch = useDispatch()
  const setEventsPage = useCallback((eventsPage: EventPages) => dispatch(setEventsPageAction(eventsPage)), [dispatch])

  return [eventsPage, setEventsPage]
}
