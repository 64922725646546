import React, { useMemo } from 'react'
import { useUISettings } from 'hooks/useUISettings'
import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { CellProps } from 'react-table'
import { EditCell, EditHeader, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'
import useStyles from 'locations/hooks/useStyles'
import { OrganizationLocationReporting } from 'locations/types'
import { useFeatureEnabled } from '../../core/feature/hooks'
import RoomIcon from '@material-ui/icons/Room'
import isNull from 'lodash/isNull'

export const useLocationsColumns = ({
  removeLocation,
  editLocation,
}: {
  removeLocation: (location: OrganizationLocationReporting) => Promise<void>
  editLocation: (location: OrganizationLocationReporting) => Promise<void>
}) => {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const isOpportunityDrilldownEnabled = useFeatureEnabled('OpportunityDrilldown')

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'order',
            Header: 'Order',
            accessor: 'order',
            Cell: ({ value }: CellProps<OrganizationLocationReporting>) => value,
            disableFilters: true,
            disableSortBy: true,
            className: classes.orderColumn,
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            Cell: (props: CellProps<OrganizationLocationReporting>) => {
              if (offersEnabled && isOpportunityDrilldownEnabled) {
                return WithLinkCell(
                  NullableTextCell,
                  (id: string) => `/locations/${id}`,
                  'id',
                )({ ...props, linkProps: { className: classes.link } })
              }
              return NullableTextCell(props)
            },
            disableFilters: true,
            disableSortBy: true,
            className: classes.nameColumn,
          },
          {
            id: 'address',
            Header: 'Address',
            accessor: 'line1',
            Cell: ({ row }: CellProps<OrganizationLocationReporting>) => {
              const { line1, line2, city, county, state, zipCode, countryCode, locationDetails, locationIsAddress } =
                row.original
              return isNull(locationDetails) ? (
                <span>
                  <RoomIcon className={classes.mapIcon} />
                  {[line1, line2, city, county, state, zipCode, countryCode].filter(value => !!value).join(', ')}
                </span>
              ) : (
                locationDetails
              )
            },
            disableFilters: true,
            disableSortBy: true,
            className: classes.addressColumn,
          },
          {
            id: 'timesUsedActive',
            Header: 'Times Used (Active)',
            accessor: 'timesUsedActive',
            Cell: NumberCell,
            disableFilters: true,
            disableSortBy: true,
            className: classes.timesUsedColumn,
          },
          {
            id: 'timesUsedTotal',
            Header: 'Times Used (Total)',
            accessor: 'timesUsedTotal',
            Cell: NumberCell,
            disableFilters: true,
            disableSortBy: true,
            className: classes.timesUsedColumn,
          },
          {
            id: 'type',
            Header: 'Type',
            accessor: 'type',
            disableFilters: true,
            disableSortBy: true,
            className: classes.typeColumn,
            requires: 'offersEnabled',
          },
          {
            id: 'actions',
            Header: <EditHeader />,
            Cell: ({
              cell,
              row: {
                original: { timesUsedTotal, encodedOccurrence },
              },
            }: CellProps<OrganizationLocationReporting>) => (
              <EditCell
                cell={cell}
                onEdit={editLocation}
                onDelete={timesUsedTotal === 0 || encodedOccurrence ? removeLocation : undefined}
                deletePlaceHolder={true}
              />
            ),
            disableSortBy: true,
            disableFilters: true,
            className: classes.editColumn,
          },
        ],
        {
          showAdminItems,
          offersEnabled,
        },
      ),
    [
      classes.orderColumn,
      classes.nameColumn,
      classes.addressColumn,
      classes.timesUsedColumn,
      classes.typeColumn,
      classes.editColumn,
      classes.link,
      classes.mapIcon,
      showAdminItems,
      offersEnabled,
      isOpportunityDrilldownEnabled,
      editLocation,
      removeLocation,
    ],
  )
}
