import React from 'react'
import { PaperProps } from '@material-ui/core/Paper/Paper'
import { Paper } from '@material-ui/core'
import { useAutocompleteStyles } from './useAutocompleteStyles'

export const usePaperComponentWithOnCreate = ({
  addNewText,
  addNewIcon,
  onCreate,
  ref,
}: {
  addNewText?: string
  addNewIcon?: React.ReactElement
  onCreate?: () => void
  ref: any
}) => {
  const classes = useAutocompleteStyles({})
  return ({ children, ...props }: PaperProps) => {
    return (
      <Paper {...props}>
        {children}
        {onCreate && (
          <div ref={ref} tabIndex={-1} className={classes.addNewItem} onClick={onCreate}>
            {addNewIcon}
            {addNewText}
          </div>
        )}
      </Paper>
    )
  }
}
