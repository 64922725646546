import moment from 'moment'
import { get, set } from 'lodash'
import useCampaignEvents from './useCampaignEvents'
import useCampaignActivities from './useCampaignActivities'
import { useMemo } from 'react'

const getCombinedEventsAndActivites = result => {
  const { campaignEvents, campaignActivities } = result
  if (!campaignEvents || !campaignActivities) {
    return []
  }
  // groups all events by user/date combination
  const eventLookUp = groupEventsByUserDateCombination(campaignEvents)
  // groups all activities by user/date combination
  const activityLookUp = campaignActivities.reduce((acc, activity) => {
    const { occurredAt, hoursVolunteered, user } = activity
    const date = moment(occurredAt).format('YYYY/MM/DD')
    const hoursVolunteeredAlready = get(acc, `${user.id}.${date}.hoursVolunteered`, 0)
    const events = get(eventLookUp, `${user.id}.${date}`, {})
    set(acc, `${user.id}.${date}`, {
      ...activity,
      hoursVolunteered: hoursVolunteered + hoursVolunteeredAlready,
      events,
    })
    return acc
  }, {})
  // combines the two objects into one array, with each item as a unique user/date activity combination, with a key of 'events'
  const combineEventsAndActivites = Object.entries(activityLookUp).reduce((acc, entry) => {
    const [userId, activityInfo] = entry
    Object.entries(activityInfo).forEach(activityEntry => {
      const [date, info] = activityEntry
      acc.push({ date, ...info })
    })
    return acc
  }, [])
  return combineEventsAndActivites
}

const useCombinedActivitiesAndEvents = campaign => {
  const { campaignEvents, loading: eventsLoading } = useCampaignEvents(campaign)
  const { campaignActivities, loading: activitiesLoading } = useCampaignActivities(campaign)
  const combinedActivitiesAndEvents = useMemo(
    () => getCombinedEventsAndActivites({ campaignEvents, campaignActivities }),
    [campaignEvents, campaignActivities],
  )
  return {
    combinedActivitiesAndEvents,
    loading: eventsLoading || activitiesLoading,
  }
}

// groups all events by user/date combination
function groupEventsByUserDateCombination(campaignEvents) {
  return campaignEvents.reduce((acc, event) => {
    const { user, occurredAt, type, donation } = event
    const date = moment(occurredAt).format('YYYY/MM/DD')
    set(acc, `${user.id}.${date}.${type}`, true)
    if (donation && donation.amountInCents) {
      const donationsAlready = get(acc, `${user.id}.${date}.donations`, 0)
      set(acc, `${user.id}.${date}.donations`, donationsAlready + donation.amountInCents)
    }
    return acc
  }, {})
}

export default useCombinedActivitiesAndEvents
