import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'formik-material-ui'
import { FormControl, InputLabel, MenuItem as MenuItemComponent } from '@material-ui/core'
import cn from 'classnames'
import isUndefined from 'lodash/isUndefined'

export const useStyles = makeStyles({
  root: {
    fontFamily: 'Nunito, sans-serif',
    padding: '14px 16px',
    '& $hidden': {
      display: 'none',
    },
  },
  select: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    marginTop: '10px',
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid',
    },
    '&.marginless': {
      margin: 0,
    },
  },
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    padding: '0 16px',
    '&.MuiFormLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(8px, 3px) scale(0.75)',
      backgroundColor: 'white',
      zIndex: 1,
    },
    '&.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  item: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
  },
  shrinkHidden: {
    display: 'none',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
})

export default function StyledSelect({ label, marginless, flexValueLayout, className, shrinkLabel, ...props }: any) {
  const classes = useStyles()

  return (
    <FormControl fullWidth className={cn(classes.formControl, className)}>
      <InputLabel {...(!isUndefined(shrinkLabel) ? { shrink: shrinkLabel } : {})} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        {...props}
        classes={{ root: cn(classes.root, { [classes.flex]: flexValueLayout }) }}
        className={cn(classes.select, { marginless })}
      />
    </FormControl>
  )
}

export const MenuItem = ({ children, ...props }: any) => {
  const classes = useStyles()
  return (
    <MenuItemComponent {...props} classes={{ root: classes.item }}>
      {children}
    </MenuItemComponent>
  )
}
