import React, { useCallback, useState } from 'react'
import { FormControlLabel, Grid, makeStyles, Switch, Typography } from '@material-ui/core'
import InlineTextEditField from './InlineTextEditField'
import { GroupTypeCode } from '../../Event/components/opportunity/GroupPicker/types'
import { useUpdateGroup } from 'group/hooks/useUpdateGroup'
import { useFeatureEnabled } from 'core/feature/hooks'

const useStyles = makeStyles(() => ({
  settingsContainer: {
    padding: '0 10px',
  },
  settingsName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: 0,
  },
  settingsLabels: {
    fontSize: '18px',
    fontWeight: 600,
    marginRight: '10px',
  },
  settingsText: {
    fontSize: '16px',
  },
  editButton: {
    color: '#5C8DE8',
    marginLeft: '10px',
  },
}))

const GroupSettings = props => {
  const { group } = props
  const classes = useStyles()
  const [updateGroup] = useUpdateGroup()
  const [automaticHourLogging, setAutomaticHourLogging] = useState(group.automaticHourLogging)
  const isAutomaticEventHourLoggingEnabled = useFeatureEnabled('AutomaticEventHourLogging')

  const changeAutomaticHourLogging = useCallback(e => {
    setAutomaticHourLogging(e.target.checked)
    updateGroup({ id: group.id, automaticHourLogging: e.target.checked })
  })

  const onSubmit = patchData => props.groupUpdate(patchData)
  const readOnly = group.groupType.code !== GroupTypeCode.UserDefined

  return (
    <Grid container direction="row" className={classes.settingsContainer}>
      <Grid item xs={12} s={6}>
        <Grid container direction="column">
          <Grid item container className={classes.settingsName} alignItems="baseline">
            <Typography variant="body1" className={classes.settingsLabels}>
              Name:
            </Typography>
            <InlineTextEditField name="name" value={group.name} readOnly={readOnly} required onSubmit={onSubmit} />
          </Grid>
          <Grid item container className={classes.settingsName} alignItems="baseline">
            <Typography variant="body1" className={classes.settingsLabels}>
              Description:
            </Typography>
            <InlineTextEditField
              name="description"
              readOnly={readOnly}
              value={group.description}
              multiline
              rows={2}
              onSubmit={onSubmit}
            />
          </Grid>
          {isAutomaticEventHourLoggingEnabled && (
            <Grid item container className={classes.settingsName} alignItems="baseline">
              <Typography variant="body1" className={classes.settingsLabels}>
                Automatic Check-In:
              </Typography>
              <FormControlLabel
                control={<Switch name="closed" checked={automaticHourLogging} onChange={changeAutomaticHourLogging} />}
                label={
                  <label>
                    <b>
                      {automaticHourLogging
                        ? 'Members of this group automatically record their hours for their registered events'
                        : 'Members of this group are required to check-in for their activities'}
                    </b>
                  </label>
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupSettings
