import React, { useMemo } from 'react'
import { Field, Form, Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import StyledOutlinedTextField from '../../formik/legacy-components/StyledOutlinedTextField'
import { StyledKeyboardDatePicker } from '../../formik/components/StyledFormikDateTimePickers'
import StyledOutlinedPhoneField from '../../formik/components/StyledOutlinedPhoneField'
import { Button } from '@material-ui/core'
import { useModalStyles } from '../StepModalContainer'
import yup from '../../utils/yup'
import isNull from 'lodash/isNull'

const useValidationSchema = (emailOrPhoneRequired: boolean) => {
  const emailRule = yup.string().trim().email('Enter a valid email').nullable()
  return useMemo(
    () =>
      yup.object({
        givenName: yup.string().trim().required(),
        familyName: yup.string().trim().required(),
        email: emailOrPhoneRequired
          ? emailRule.when('phone', (phone: string, schema: any) =>
              isNull(phone) ? schema.required('Email or mobile is required') : schema,
            )
          : emailRule,
        phoneNumber: yup
          .string()
          // @ts-ignore
          .phoneNumber()
          .trim()
          .nullable(),
        homePhoneNumber: yup
          .string()
          // @ts-ignore
          .phoneNumber()
          .trim()
          .nullable(),
      }),
    [emailOrPhoneRequired, emailRule],
  )
}

export function FillPersonDataStep({ initialValues, handleSubmit, emailOrPhoneRequired, cancel }: any) {
  const classes = useModalStyles()
  const validationSchema = useValidationSchema(emailOrPhoneRequired)
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                component={StyledOutlinedTextField}
                fullWidth
                id="givenName"
                name="givenName"
                label="First Name"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={StyledOutlinedTextField}
                fullWidth
                id="familyName"
                name="familyName"
                label="Last Name"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={StyledOutlinedTextField}
                fullWidth
                name="email"
                label="email"
                required={emailOrPhoneRequired}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="birthday"
                label="Birth Date"
                component={StyledKeyboardDatePicker}
                format="MM/DD/YYYY"
                inputVariant="outlined"
                clearable={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Field component={StyledOutlinedPhoneField} fullWidth name="phoneNumber" label="Mobile Phone" />
            </Grid>
            <Grid item xs={6}>
              <Field component={StyledOutlinedPhoneField} fullWidth name="homePhoneNumber" label="Home Phone" />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            classes={{ root: classes.buttonsContainer }}
          >
            <Button onClick={cancel} variant="contained" color="secondary" className={classes.button}>
              Cancel
            </Button>
            <Button
              className={classes.button}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={submitForm}
            >
              Next
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FillPersonDataStep
