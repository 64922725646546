import moment from 'moment'

const DATE_FORMAT = 'MM/DD/YYYY'
const REGEX_TO_CHECK_DATE_FORMAT = /(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])-([12]\d{3})/

const isCorrectDateFormat = (date: string) => {
  return REGEX_TO_CHECK_DATE_FORMAT.test(date)
}

export const toSortDate = (a: string, b: string) => {
  let dateA = moment(a, DATE_FORMAT);
  let dateB = moment(b, DATE_FORMAT);
  return dateA.diff(dateB)
}