import moment from 'moment-timezone'
import { useTimeZone } from '../auth/hooks'
import { useCallback, useMemo } from 'react'

const compileDateTime = (date: moment.Moment, time: moment.Moment, zeroSeconds = true): moment.Moment =>
  date.clone().set({ hours: time.hours(), minutes: time.minutes(), seconds: zeroSeconds ? 0 : time.seconds() })

export const useDateTimeUtils = () => {
  const timeZone = useTimeZone()

  /**
   * So some basics - all UI date/time pickers use user local timeZone (used on user device, like pc or tablet)
   * So for example. Let's imagine user local time zone it UTC-1, and company time zone is UTC-5
   * Let's imagine current time is 12PM UTC/11AM user
   * Is used when we need to convert company time for UI editing.
   * What it does: it takes current time(or time in database),
   * takes a look that in company timeZone it's 7AM (12PM - 5h)
   * and returns Moment object with 7AM local time, so that user can edit it as company timezone
   * */
  const fakeAsLocalTimeForUI = useCallback(
    (dateTime: Date | moment.Moment | string): moment.Moment =>
      moment(moment.tz(dateTime, timeZone).format('YYYY-MM-DD HH:mm:ss')),
    [timeZone], // eslint-disable-line react-hooks/exhaustive-deps
  )

  /**
   * I used when we need to convert time back from UI to actual database time.
   * So If user set it to be 7AM local time it assumes it's 7AM at Company timezone, not user local,
   * and converts it to be actually 12PM UTC.
   */
  const unfakeAsLocalTimeForUI = useCallback(
    (dateTime: Date | moment.Moment): moment.Moment =>
      moment.tz(moment(dateTime).format('YYYY-MM-DD HH:mm:ss'), timeZone),
    [timeZone], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // Same as previous but also formats as ISO string
  const unfakeAsLocalTimeForUIAsString = useCallback(
    (dateTime: Date | moment.Moment): string => unfakeAsLocalTimeForUI(dateTime).toISOString(),
    [timeZone], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return useMemo(
    () => ({
      unfakeAsLocalTimeForUIAsString,
      fakeAsLocalTimeForUI,
      unfakeAsLocalTimeForUI,
      compileDateTime,
    }),
    [timeZone], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
