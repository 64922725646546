import React from 'react'
import { useClearSalsaData } from '../hooks/useClearSalsaData'
import { Button } from '@material-ui/core'
import { useFeatureEnabled } from 'core/feature/hooks'

export default function SalsaClearDemoData(props: any) {
  const showClearDataButton = useFeatureEnabled('ClearSalsaData')
  const { clearSalsaData } = useClearSalsaData()
  return showClearDataButton ? <Button onClick={clearSalsaData}>Clear Salsa Data For Demo</Button> : null
}
