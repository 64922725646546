import React from 'react'
import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import { Field, FieldInputProps, FormikProps, getIn } from 'formik'
import { TextField } from 'formik-material-ui'

import PhoneField from 'civic-champs-shared/formik/components/PhoneField'
import format from 'civic-champs-shared/utils/format'
import { isEmpty } from 'lodash'

//TODO this is copied! extract from both places into shared hook
const useStyles = makeStyles(() => ({
  avatar: {
    width: 100,
    height: 100,
  },
  cardHeaderAvatar: {
    background: '#FFCD00',
    color: '#3d3d3d',
    fontWeight: 'bold',
    '& .MuiCardHeader-title': {
      fontSize: '16px',
    },
  },
  label: {
    fontWeight: 600,
  },
  editButton: {
    color: 'white',
    backgroundColor: '#FF6F3E',
    fontSize: '12px',
  },
  cancelButton: {
    color: 'white',
    backgroundColor: '#FF6F3E',
    fontSize: '12px',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#5C8DE8',
  },
  link: {
    color: '#5C8DE8',
    fontWeight: 600,
  },
  avatarEdit: {
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: '#707070',
        color: '#707070',
      },
    },
    '& .MuiInputBase-root': {
      background: 'white',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
  verifiedIcon: {
    fontSize: '20px',
    color: '#FF6F3E',
    verticalAlign: 'middle',
  },
}))

//TODO move somewhere else!
export interface EmergencyContact {
  id?: number
  givenName: string
  familyName: string
  relationship?: string
  email?: string
  phoneNumber?: string
  workPhoneNumber?: string
  homePhoneNumber?: string
}

export interface EmergencyContactFieldProps<FormValues = any> {
  field: FieldInputProps<EmergencyContact>
  form: FormikProps<FormValues>
  label?: string
  editing?: boolean
  disabled?: boolean
}

export const EmergencyContactField: React.FC<EmergencyContactFieldProps> = props => {
  const {
    form: { setFieldValue, setFieldTouched, values },
    field: { name, value: emergencyContact },
    label = name,
    editing,
    disabled = false,
  } = props

  //TODO add a confirm modal
  const onRemoveContact = () => {
    setFieldValue(name, null)
  }

  const onAddContact = () => {
    setFieldValue(
      name,
      {
        givenName: '',
        familyName: '',
        relationship: '',
        email: '',
        phoneNumber: '',
        homePhoneNumber: '',
        workPhoneNumber: '',
      },
      false,
    )
    setFieldTouched(name, true, true)
  }

  const emergencyContactFullName = emergencyContact
    ? `${emergencyContact.givenName} ${emergencyContact.familyName}`
    : ''

  const classes = useStyles()

  return (
    <Grid container spacing={1} direction="column">
      <Grid item container direction="row" spacing={1} alignItems="center">
        <Grid item xs={5}>
          <Typography variant="body1" className={classes.label}>
            {label}:
          </Typography>
        </Grid>
        {editing ? (
          <Grid item container xs={7} justify="flex-end">
            {emergencyContact == null ? (
              <Button disabled={disabled} onClick={onAddContact}>
                Add
              </Button>
            ) : (
              <Button disabled={disabled} onClick={onRemoveContact}>
                Remove
              </Button>
            )}
          </Grid>
        ) : (
          emergencyContact == null && (
            <Grid item>
              <Typography variant="body1">N/A</Typography>
            </Grid>
          )
        )}
      </Grid>
      {emergencyContact != null && (
        <>
          {editing ? (
            <>
              <Grid item>
                <Grid container spacing={1} direction="row">
                  <Grid item className={classes.centered} xs={3}>
                    <Typography variant="body1">First Name:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled={disabled}
                      component={TextField}
                      name={`${name}.givenName`}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1} direction="row">
                  <Grid item className={classes.centered} xs={3}>
                    <Typography variant="body1">Last Name:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Field
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled={disabled}
                      component={TextField}
                      name={`${name}.familyName`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Grid container spacing={1} direction="row">
                <Grid item className={classes.centered} xs={3}>
                  <Typography variant="body1">Name:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body1">{emergencyContactFullName}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={1} direction="row">
              <Grid item className={classes.centered} xs={3}>
                <Typography variant="body1">Relationship:</Typography>
              </Grid>
              <Grid item xs={9}>
                {editing ? (
                  <Field
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disabled}
                    component={TextField}
                    name={`${name}.relationship`}
                  />
                ) : (
                  <Typography variant="body1">{emergencyContact.relationship}</Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          {[
            { key: 'phoneNumber', label: 'Mobile' },
            { key: 'workPhoneNumber', label: 'Work Phone' },
            { key: 'homePhoneNumber', label: 'Home Phone' },
            { key: 'email', label: 'Email' },
          ]
            .map(item => ({ ...item, value: getIn(values, `${name}.${item.key}`) }))
            .filter(({ value }) => (editing ? true : !isEmpty(value)))
            .map(({ key, value, label }) => (
              <Grid item key={key}>
                <Grid container spacing={1} direction="row">
                  <Grid item className={classes.centered} xs={3}>
                    <Typography variant="body1">{label}:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    {editing ? (
                      <Field
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={disabled}
                        component={key === 'email' ? TextField : PhoneField}
                        name={`${name}.${key}`}
                      />
                    ) : (
                      <Link className={classes.link}>
                        {(key === 'email' ? value : format.phoneNumber(value)) || ''}
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </>
      )}
    </Grid>
  )
}
