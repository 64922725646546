import React from 'react'
import { Grid, Button } from '@material-ui/core'
import BackLink from 'donation/campaigns/components/BackLink'
import { ReadOnlyQuestionSet } from 'civic-champs-shared/question-sets/components'

export const QuestionSetDetails = props => {
  const questionSetId = props.match.params.id
  return (
    <div>
      <BackLink to={'/questionnaires'} text="Back to Questionnaires" />
      <ReadOnlyQuestionSet questionSetId={questionSetId}/>
      <Grid container item sm={12}>
        <Button onClick={() => props.history.push(`/questionnaires/${questionSetId}/edit`)}>Edit</Button>
      </Grid>
    </div>
  )
}
