import React, { useState } from 'react'
import { makeStyles, TextField, Typography } from '@material-ui/core'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog'
import { NamedRef } from 'civic-champs-shared/common/types'

export interface Props {
  roles: NamedRef[]
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    textAlign: 'justify',
  },
  field: {
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
  container: {
    padding: '0px 18px 8px 18px',
    overflowY: 'scroll',
  },
  rolesContainer: {
    maxHeight: '300px',
    paddingBottom: '5px',
    overflowY: 'scroll',
  },
  role: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    padding: '5px',
  },
})

export function MergeRolesModal(props: Props) {
  const { showing, complete, close, roles } = props
  const [name, setName] = useState('')

  const handleSubmit = () => {
    complete({ roles, name })
  }

  const classes = useStyles()

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="Are you sure you want to permanently merge these roles?"
      text={`Merging the following roles will combine them into one role. \
        This change will take effect in all events and opportunities (past, present and future), and cannot be undone.`}
      cancelText="Do Not Merge"
      confirmText="Merge Roles"
      confirmDisabled={!name}
    >
      <div className={classes.container}>
        <Typography className={classes.text}>Roles to Merge:</Typography>
        <div className={classes.rolesContainer}>
          {roles.map((role: any, index: number) => (
            <Typography className={classes.role} key={role.id}>
              {index + 1}. {role.name}
            </Typography>
          ))}
        </div>
        <Typography className={classes.text}>All merged roles will have the name entered below:</Typography>
        <TextField
          type={'text'}
          className={classes.field}
          InputProps={{
            classes: {
              input: classes.field,
            },
          }}
          placeholder={'Enter a role name'}
          name="name"
          value={name}
          variant="outlined"
          onChange={ev => setName(ev.target.value)}
        />
      </div>
    </ConfirmDialog>
  )
}

export default MergeRolesModal
