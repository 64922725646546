import React from 'react'

import Button from "../button";

import './index.scss'

interface IProps {
  actionLeft: {
    variant: 'text'
    onClick?: VoidFunction
    disabled?: boolean
    text: string
  },
  actionRight: {
    type: 'button' | 'submit' | 'reset'
    color: 'yellow' | 'transparent' | 'blue'
    onClick?: VoidFunction
    disabled: boolean
    text: string
  }
}

function ActionBar({actionLeft, actionRight}: IProps) {
  return(
    <div className='action_bar_container'>
      <Button
        variant={actionLeft.variant}
        onClick={actionLeft.onClick}
        disabled={actionLeft.disabled}
      >
        {actionLeft.text}
      </Button>
      <Button
        className={'action_right'}
        type={actionRight.type}
        color={actionRight.color}
        onClick={actionRight.onClick}
        disabled={actionRight.disabled}
      >
        {actionRight.text}
      </Button>
    </div>
  )
}

export default ActionBar
