import React from 'react'
import { FormControl, Tooltip } from '@material-ui/core'

export default function CheckboxField(props) {
  const { form, field, labelstyle = {}, label, tooltip = '' } = props
  const { name, value } = field
  const helperText = form.errors[name]
  const error = !!helperText

  return (
    <FormControl error={error} className="checkbox-container">
      <label style={labelstyle}>
        <Tooltip title={tooltip}>
          <input
            type="checkbox"
            {...props}
            className="checkbox-input"
            checked={value}
            onChange={() => form.setFieldValue(name, !value)}
          />
        </Tooltip>
        {label}
      </label>
    </FormControl>
  )
}
