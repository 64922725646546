import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import find from 'lodash/find'
import isNull from 'lodash/isNull'
import { muiTheme } from 'theme'
import { Box, Chip, Grid, IconButton, InputAdornment, OutlinedInput, Popover } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { ThemeProvider } from '@material-ui/styles'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ClearIcon from '@material-ui/icons/Clear'
// @ts-ignore
import { ANCHOR_LEFT, ANCHOR_RIGHT } from 'react-dates/constants'
import { Column, Row, TableInstance } from 'react-table'
import * as H from 'history'
import SummaryCards, { SummaryCard } from 'civic-champs-shared/core/SummaryCards'
import NewFilters from 'core/table/components/NewFilters'
import PagedTable from 'core/table/components/PagedTable'
import { ColumnSelector } from 'core/table/components/ColumnSelector'
import { ColumnState } from 'core/table/interfaces/ColumnState'
import useExtendedTableStyles from 'core/table/table-hooks/useExtendedTableStyles'
import useSetFilterRowsHistory from 'core/table/table-hooks/useSetFiltersRowHistory'
import { BinaryFilter, GetOperatorOptionsType } from 'core/table/interfaces/Filters'
import DateRangeFilter from 'components/filter/StyledDateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import useDebounce from 'utils/useDebounce'
import { getColumnHeaderText } from 'core/table/utils'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import cn from 'classnames'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import { handleNavigateToLastAddedRow } from 'core/table/table-hooks/useNavigateToLastAddedRow'
import { EventListener } from 'civic-champs-shared/api/hooks/useRemoteCollection'

interface Summary {
  [key: string]: any
}

const getOperandTextValue = (operand: any): string => {
  if (operand?.label) {
    return operand.label
  } else if (Array.isArray(operand)) {
    return operand.map(getOperandTextValue).join(', ')
  }
  return operand as string
}

export interface ExtenedPagedTableProps<
  T extends object = {},
  I extends { id: string | number } = { id: string | number },
> {
  summary?: Summary
  getSummary?: (rows: Row<T>[]) => Summary
  cards?: SummaryCard[]
  useGlobalSearch?: boolean
  useFilters?: boolean
  useDateRange?: boolean
  history: H.History
  location?: H.Location
  table: TableInstance<any>
  columns: Column<any>[]
  getOperatorOptions?: GetOperatorOptionsType
  buttons?: JSX.Element
  addButton?: JSX.Element
  binaryFilters?: BinaryFilter[]
  columnState?: ColumnState[]
  popover?: JSX.Element
  tableName?: string
  searchPlaceholder?: string
  filterSubHeader?: string
  getCellStyle?: Function
  getHeaderCellStyle?: Function
  externalSearch?: string
  compactView?: boolean
  title?: string
  expandable?: boolean
  customDateKey?: string
  defaultDateRange?: {
    startDate: moment.Moment
    endDate: moment.Moment
  }
  collectionKey?: string
  eventListeners?: {
    onAdd: EventListener<T>
    onRemove: EventListener<T>
    onUpdate: EventListener<T>
    onSync: EventListener<T>
    onBeforeUpdate: EventListener<T>
    onBeforeAdd: EventListener<T>
    onBeforeRemove: EventListener<T>
    onBeforeSync: EventListener<T>
    onIsNewInCollectionRemoved: EventListener<T>
  }
  skipReset?: MutableRefObject<boolean | undefined>
}

export function ExtendedPagedTableComponent<
  T extends object = {},
  I extends { id: string | number } = { id: string | number },
>(props: ExtenedPagedTableProps<T, I>) {
  const {
    cards,
    useGlobalSearch,
    useFilters,
    useDateRange,
    history,
    table,
    columns,
    getOperatorOptions,
    buttons,
    popover,
    addButton,
    binaryFilters,
    columnState = null,
    tableName,
    searchPlaceholder = 'Search',
    filterSubHeader,
    getCellStyle,
    getHeaderCellStyle,
    externalSearch,
    compactView = false,
    title,
    customDateKey,
    defaultDateRange,
    expandable = true,
    skipReset,
    collectionKey,
    eventListeners: {
      onAdd,
      onUpdate,
      onBeforeAdd,
      onBeforeUpdate,
      onBeforeSync,
      onBeforeRemove,
      onIsNewInCollectionRemoved,
    } = {},
  } = props
  const classes = useExtendedTableStyles({ compactView })
  const [dateRange, setDateRange] = useDateRangeFilter({ key: customDateKey, defaultDateRange })
  const { filterRows: initialFilterRows } = useFiltersFromQuery(history.location.search, getOperatorOptions)
  const [search, setSearch] = useState<string>('')
  const [expanded, setExpanded] = useState<boolean>(true)
  const debouncedSearch = useDebounce(search, 250)
  const summary = useMemo(
    () => props.getSummary?.(table.flatRows) || props.summary,
    [props.summary, props.getSummary, table.flatRows],
  )
  const filterRows = useMemo(
    () => history?.location?.state?.filterRows || initialFilterRows,
    [history, initialFilterRows],
  )
  useEffect(() => {
    // @ts-ignore
    table.setGlobalFilter && table.setGlobalFilter(isUndefined(externalSearch) ? debouncedSearch : externalSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, table.data, externalSearch])

  useEffect(() => {
    onAdd?.(handleNavigateToLastAddedRow({ table, skipReset, key: collectionKey }))
    onUpdate?.(handleNavigateToLastAddedRow({ table, skipReset, key: collectionKey }))
    onBeforeAdd?.(() => {
      skipReset && (skipReset.current = true)
    })
    onBeforeUpdate?.(() => {
      skipReset && (skipReset.current = true)
    })
    onBeforeSync?.(() => {
      skipReset && (skipReset.current = true)
    })
    onBeforeRemove?.(() => {
      skipReset && (skipReset.current = true)
    })
    onIsNewInCollectionRemoved?.(() => {
      skipReset && (skipReset.current = false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setFilterRowsHistory = useSetFilterRowsHistory(history)
  const removeFilter = useCallback(
    (columnName: string, operator: any) => {
      const rows = filterRows.filter(
        ({ column, operator: columnOperator }: { column: string; operator: any }) =>
          column !== columnName || columnOperator !== operator,
      )
      setFilterRowsHistory(rows)
      // @ts-ignore
      table.setAllFilters(rows.map(({ column, value }) => ({ id: column, value })))
    },
    [filterRows, setFilterRowsHistory, table],
  )

  useEffect(() => {
    if (!isNull(columnState)) {
      const columnsInState = map(columnState, 'id')
      const hiddenColumnsNotInState = filter(
        table.initialState?.hiddenColumns || [],
        id => !columnsInState.includes(id),
      )
      table.setHiddenColumns([
        ...columnState.filter(({ visible }) => !visible).map(({ id }) => id),
        ...hiddenColumnsNotInState,
      ])
      // @ts-ignore
      table.setColumnOrder(columnState.map(({ id }) => id))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const DateRange = (
    <DateRangeFilter
      initialValues={dateRange}
      onFilterApplied={setDateRange}
      anchorDirection={compactView ? ANCHOR_RIGHT : ANCHOR_LEFT}
      noBorder
    />
  )

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        {cards && summary && (
          <div className={classes.summary}>
            <SummaryCards cards={cards} data={summary} />
          </div>
        )}
        {((useGlobalSearch && isUndefined(externalSearch)) || useFilters || (compactView && useDateRange) || title) && (
          <div className={classes.sectionContainer}>
            {((useGlobalSearch && isUndefined(externalSearch)) || useFilters) && (
              <div className={cn(classes.buttonsContainer, { [classes.headerCompactView]: compactView })}>
                {title && (
                  <div
                    className={cn(classes.title, { [classes.pointer]: expandable })}
                    onClick={expandable ? () => setExpanded(value => !value) : undefined}
                  >
                    {title} {expandable && (expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />)}
                  </div>
                )}
                {useGlobalSearch && isUndefined(externalSearch) && (
                  <div>
                    <OutlinedInput
                      labelWidth={0}
                      id="search"
                      placeholder={searchPlaceholder}
                      value={search}
                      onChange={(event: any) => setSearch(event.target.value)}
                      classes={{
                        root: classes.searchField,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon style={{ fontSize: '16px' }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
                {useFilters && getOperatorOptions && (
                  <div>
                    <NewFilters
                      columns={columns}
                      table={table}
                      filterRows={initialFilterRows}
                      history={history}
                      getOperatorOptions={getOperatorOptions}
                      binaryFilters={binaryFilters}
                      subHeader={filterSubHeader}
                    />
                  </div>
                )}
                {compactView && useDateRange && <Grid item>{DateRange}</Grid>}
              </div>
            )}
            {((!compactView && useDateRange) || addButton) && (
              <div className={classes.buttons}>
                {addButton && <div>{addButton}</div>}
                {!compactView && useDateRange && <div>{DateRange}</div>}
              </div>
            )}
          </div>
        )}
        {useFilters && !!filterRows.length && (
          <div className={classes.buttonsContainer}>
            {filterRows
              // @ts-ignore
              ?.map(({ column, value: { operator, operand } }, index) => {
                const columnInstance = find(table.columns, { id: column })
                operand = (columnInstance as any)?.getOperandTextValue?.(operand) || getOperandTextValue(operand)
                if (operand.length > 50) {
                  operand = operand.substr(0, 47) + '...'
                }
                const label = /^[><]=?$/.test(operator) ? `${operator} "${operand}"` : operand
                return (
                  <div key={index}>
                    <Chip
                      className={classes.filterChip}
                      label={`${getColumnHeaderText(columnInstance)}: ${label}`}
                      onDelete={() => removeFilter(column, operator)}
                      color="secondary"
                      deleteIcon={<ClearIcon className={classes.filterChipIcon} />}
                    />
                  </div>
                )
              })}
          </div>
        )}
      </div>
      <div className={classes.tableContainer}>
        <Grid
          item
          xs={12}
          // To animate with css transition, we need to set a max-height, average row height is 43px 200px is for the rest
          style={
            expandable && compactView ? { maxHeight: expanded ? (table as any).page.length * 43 + 200 + 'px' : 0 } : {}
          }
          className={classes.wrapper}
        >
          <PagedTable
            compactView={compactView}
            {...table}
            // @ts-ignore
            header={
              <Grid item container xs={12} justify="space-between" className={classes.header}>
                <Grid item>
                  {buttons}
                  {popover && (
                    <PopupState variant="popover" popupId="menu-buttons-popover">
                      {popupState => (
                        <>
                          <IconButton
                            className={classes.popoverButton}
                            aria-label="show more"
                            component="span"
                            {...bindTrigger(popupState)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <Box className={classes.popover} p={2}>
                              {popover}
                            </Box>
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  )}
                </Grid>
                <Grid item>
                  <ColumnSelector table={table} tableName={tableName} />
                </Grid>
              </Grid>
            }
            // @ts-ignore
            newStyles
            isDraggable
            getCellStyle={getCellStyle}
            getHeaderCellStyle={getHeaderCellStyle}
          />
        </Grid>
      </div>
    </div>
  )
}

export function ExtendedPagedTable<T extends object = {}, I extends { id: string | number } = { id: string | number }>(
  props: ExtenedPagedTableProps<T, I>,
) {
  return (
    <ThemeProvider theme={muiTheme}>
      <ExtendedPagedTableComponent<T> {...props} />
    </ThemeProvider>
  )
}
