import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect } from 'react'

const STABLE_EMPTY_ARRAY = []
const useTaskHistory = taskId => {
  const [request, { result: task, loading }] = useFetch()

  const getTaskHistory = useCallback(async () => {
    return request({
      method: 'get',
      url: `/tasks/${taskId}/history`,
    })
  }, [taskId, request])

  useEffect(() => {
    getTaskHistory()
  }, [getTaskHistory])

  return {
    taskHistory: task || STABLE_EMPTY_ARRAY,
    loading,
    refresh: getTaskHistory,
  }
}

export default useTaskHistory
