import {
  TableInstance,
  TableState,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseSortByInstanceProps,
} from 'react-table'
import castArray from 'lodash/castArray'
import { MutableRefObject } from 'react'

export const navigateToLastAddedRow = <I extends { [key: string]: any } = { id: string | number }>(
  table: TableInstance<any>,
  items: I | I[],
  key: string = 'id',
  skipReset?: MutableRefObject<boolean | undefined>,
) => {
  const tbl = table as TableInstance<I> &
    UsePaginationInstanceProps<I> &
    UseSortByInstanceProps<I> & {
      setSortBy: (sortBy: { id: string; desc: boolean }[]) => void
    }
  if (skipReset) {
    skipReset.current = false
  }
  const page = castArray(items).reduce((page: number | null, item: I) => {
    if (page === 0) return page
    const rowIndex = tbl.rows.findIndex(({ original }) => original[key].toString() === item[key].toString())
    if (rowIndex) {
      const pageNumber = Math.floor(rowIndex / (tbl.state as TableState<I> & UsePaginationState<I>).pageSize)
      return page === null || pageNumber < page ? pageNumber : page
    }
    return page
  }, null)
  if (page !== (tbl.state as TableState<I> & UsePaginationState<I>).pageIndex) {
    tbl.gotoPage(page)
  }
}

export const handleNavigateToLastAddedRow =
  <I extends {} = { id: string | number }>({
    table,
    refresh,
    onNewItem,
    skipReset,
    key = 'id',
  }: {
    table: TableInstance<any>
    refresh?: () => Promise<any>
    onNewItem?: (item: I) => any
    key?: string
    skipReset?: MutableRefObject<boolean | undefined>
  }) =>
  async (item: I) => {
    if (refresh) await refresh()
    onNewItem?.(item)
    window.requestAnimationFrame(() => {
      navigateToLastAddedRow(table, item, key, skipReset)
    })
  }
