import { chunk, get, groupBy } from 'lodash'
import { useMemo } from 'react'
import moment from 'moment'

import { Opportunity } from 'Event/interfaces'
import { useCurrentOrg } from 'auth/hooks'

export const useEventCalendarGrid = (date: number, month: number, year: number, events: Opportunity[], viewType: any) => {
  const organization = useCurrentOrg()
  const timeZone = get(organization, 'timeZone', 'America/New_York')

  const eventsById = useMemo(
    () => groupBy(events, event => moment.tz(event.startsAt, timeZone).format('L') ),
    [events, timeZone]
  )

  return useMemo(() => {
    const startsAt = moment.tz([year, month, date], timeZone).startOf(viewType).weekday(0)
    const endsAt =  moment.tz([year, month, date], timeZone).endOf(viewType).weekday(6)

    const cells = []
    for(let day = startsAt.clone(); day.isBefore(endsAt); day.add(1, 'day')) {
      const key = day.format('L')
      cells.push({
        date: day.clone(),
        events: eventsById[key] || []
      })
    }

    return chunk(cells, 7)
  }, [year, month, timeZone, eventsById])
}
