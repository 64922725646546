import React, { useMemo } from 'react'
import cn from 'classnames'
import { makeStyles, Grid, Box, Typography, Tooltip } from '@material-ui/core'
import { useTable } from 'react-table'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'


const useStyles = makeStyles({
    card: {
        borderTop: 'solid 1px rgb(147, 199, 242)',
        borderBottom: 'solid 1px rgb(147, 199, 242)',
        textAlign: 'center',
        padding: '25px',
        height: '100%'
    },
    dark: {
        backgroundColor: '#FEF4CB',
        border: 'solid 1px rgb(147, 199, 242)',
    },
    last: {
        borderRight: 'solid 1px rgb(147, 199, 242)'
    }
})

//TODO this should ultimately work by hooking into the filters on
//     the table itself

//TODO does it make sense to use the whole react table thing here?
//     could make a "thinner" version that re-uses the format
export default function SummaryCards(props) {
    const { cards, summary, ...remainingProps } = props

    const data = useMemo(() => [summary], [summary])
    const table = useTable({ columns: cards, data })
    const classes = useStyles()

    return (
        <Grid
            container
            spacing={0}
            {...remainingProps}
        >
        {table.rows.map(row => {
            table.prepareRow(row)
            return row.cells.map( (cell, index) => {
                const itemClasses = cn({
                    [classes.card]: true,
                    [classes.dark]: index % 2 === 0,
                    [classes.last]: index === cards.length - 1
                }) //TODO could memoize

                return (
                    <Grid item key={index}>
                        <Box
                            component="div"
                            className={itemClasses}
                        >
                            <Typography variant="h4">{cell.render('Cell')}</Typography>
                            {cell.column.render('Header')}
                            {cell.column.render('Footer')}
                            {cell.column.toolTipText &&
                            <Tooltip
                                title={cell.column.toolTipText}
                            >
                            <HelpOutlineIcon style={{ fontSize: 18, verticalAlign: 'middle', marginLeft: 5 }} />
                            </Tooltip>}
                        </Box>
                    </Grid>
                )
            })})}
        </Grid>
    )
}
