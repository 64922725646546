import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DateCell, NullableDateCell, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { createDateValueSort } from 'components/table/sort'
import { useFeatureEnabled } from 'core/feature/hooks'
import { filterByRequireRules } from '../../civic-champs-shared/utils/filterByRequireRules'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '36px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '200px',
    },
  },
  dateColumn: {
    '&>div': {
      width: '128px',
      textAlign: 'right',
      '&>span': {
        textAlign: 'right',
      },
      '&>div': {
        paddingRight: '26px',
      },
    },
  },
  locationColumn: {
    '&>div>div': {
      width: '180px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  visibilityColumn: {
    '&>div': {
      width: '132px',
    },
  },
}))

export default function useOngoingOpportunitiesColumns() {
  const classes = useStyles()
  const isOpportunityDrilldownEnabled = useFeatureEnabled('OpportunityDrilldown')
  const offersEnabled = useFeatureEnabled('StandaloneOffers')

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'name',
            Header: offersEnabled ? 'Offer Name' : 'Name',
            accessor: 'name',
            Cell: isOpportunityDrilldownEnabled
              ? (props: any) =>
                  WithLinkCell(
                    NullableTextCell,
                    (encodedOccurrence: string) => `/opportunities/${encodedOccurrence}`,
                    'encodedOccurrence',
                  )({ ...props, linkProps: { className: classes.link } })
              : NullableTextCell,
            filter: DYNAMIC_TEXT,
            className: classes.nameColumn,
            sortType: createStringSort('name'),
          },
          {
            id: 'startsAt',
            Header: 'Start Date',
            accessor: 'startsAt',
            Cell: DateCell,
            disableFilters: true,
            disableGlobalFilter: true,
            sortType: createDateValueSort('startsAt'),
            className: classes.dateColumn,
          },
          {
            id: 'endsAt',
            Header: 'End Date',
            accessor: 'endsAt',
            Cell: NullableDateCell,
            disableFilters: true,
            disableGlobalFilter: true,
            sortType: createDateValueSort('startsAt'),
            className: classes.dateColumn,
          },
          {
            id: 'location',
            Header: 'Location',
            accessor: 'location',
            Cell: NullableTextCell,
            disableFilters: true,
            disableGlobalFilter: true,
            className: classes.locationColumn,
            sortType: createStringSort('location'),
          },
          {
            id: 'signupCount',
            Header: 'Sign-Ups',
            accessor: 'signupCount',
            Cell: NumberCell,
            filter: DYNAMIC_NUMBER,
            requires: 'offersEnabled',
            className: classes.visibilityColumn,
          },
          {
            id: 'visibility',
            Header: 'Visibility',
            accessor: 'visibility',
            Cell: NullableTextCell,
            disableFilters: true,
            disableGlobalFilter: true,
            className: classes.visibilityColumn,
            sortType: createStringSort('visibility'),
          },
          {
            id: 'status',
            Header: 'Status',
            accessor: 'status',
            Cell: NullableTextCell,
            disableFilters: true,
            disableGlobalFilter: true,
            className: classes.visibilityColumn,
            sortType: createStringSort('status'),
          },
        ] as any[],
        { offersEnabled },
      ),
    [
      classes.dateColumn,
      classes.link,
      classes.locationColumn,
      classes.nameColumn,
      classes.visibilityColumn,
      isOpportunityDrilldownEnabled,
      offersEnabled,
    ],
  )
}
