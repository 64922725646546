import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import useChatPromptStyles from 'chats/hooks/useChatPromptStyles'
import MembersSelect from 'chats/components/MembersSelect'
import { Person } from 'people/interface'
import cn from 'classnames'
import { Member } from 'chats/interfaces'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

interface AddChatMembersPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  existingMembers: Member[]
}

const useStyles = makeStyles({
  dialog: { width: '380px' },
  title: { marginBottom: 0 },
})

export const AddChatMembersPrompt = (props: AddChatMembersPromptProps) => {
  const { showing, close, complete, existingMembers } = props
  const [members, setMembers] = useState<(Person | PersonSuggestion)[]>([])
  const classes = useChatPromptStyles()
  const styles = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#add-chat-prompt` }}
      aria-labelledby="add-chat-dialog-title"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, styles.dialog) }}
      disableEnforceFocus
    >
      <DialogTitle classes={{ root: cn(classes.title, styles.title) }} disableTypography={true} id="add-chat-prompt">
        Add Members
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <MembersSelect members={members} setMembers={setMembers} existingMembers={existingMembers} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <OutlinedButton onClick={close}>Cancel</OutlinedButton>
        <ContainedButton disabled={members.length === 0} onClick={() => complete(members)}>
          Add
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default (props: AddChatMembersPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddChatMembersPrompt {...props} />
  </ThemeProvider>
)
