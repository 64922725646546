import * as moment from 'moment'
import * as _ from 'lodash'

// This is used only by the `useVolunteersCollection` and we do not need to convert it to UTC
// as output format do not include timezone information
export const formatDate = date => (date ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : date)

export const volunteerSummary = volunteers => {
  const numVolunteers = _.size(volunteers)
  const onlyOneVolunteer = numVolunteers === 1
  const volunteerName = onlyOneVolunteer ? `${volunteers[0].user.givenName} ${volunteers[0].user.familyName}` : ''
  return { numVolunteers, onlyOneVolunteer, volunteerName }
}
