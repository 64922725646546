export enum MigrationIssueTypes {
  ERROR = 'error',
  MESSAGE_FAILURE = 'message_failure',
  MISSING_VOLUNTEER_INFO = 'missing_volunteer_info',
  MULTIPLE_VOLUNTEERS_MATCH_SUPPORTER = 'multiple_volunteers_match_supporter',
  MULTIPLE_SUPPORTERS_MATCH_VOLUNTEER = 'multiple_supporters_match_volunteer',
}

export const migrationIssueTypesFriendlyLookup = {
  [MigrationIssueTypes.ERROR]: 'Error',
  [MigrationIssueTypes.MESSAGE_FAILURE]: 'Message Failure',
  [MigrationIssueTypes.MISSING_VOLUNTEER_INFO]: 'Missing Supporter Information',
  [MigrationIssueTypes.MULTIPLE_VOLUNTEERS_MATCH_SUPPORTER]: 'Multiple volunteers match a single supporter',
  [MigrationIssueTypes.MULTIPLE_SUPPORTERS_MATCH_VOLUNTEER]: 'Multiple supporters match a single volunteer',
}

export interface SupporterOrVolunteer {
  // exists for both
  id: string
  givenName?: string
  familyName?: string
  email?: string
  // exists for volunteers
  homePhoneNumber?: string
  phoneNumber?: string
  // exist for supporters
  cellPhone?: string
  workPhone?: string
  homePhone?: string
}

export interface PopulatedMigrationIssue {
  salsaMigrationIssueId: number
  issueType: MigrationIssueTypes
  volunteers: Array<SupporterOrVolunteer>
  supporters: Array<SupporterOrVolunteer>
}

export interface MigrationResolutionMap {
  [key: number]: string | number | null
}

export interface MigrationIssue {
  id?: number
  salsaIntegrationId: string
  issueType: MigrationIssueTypes
  errorCode?: number
  errorMessage?: string
  messageBody?: any
  createdAt?: string
  deletedAt?: string
}

export const DEFAULT_SALSA_INTEGRATION = {
  id: '',
  organizationId: null,
  apiKey: '',
  segmentId: '',
  hasMigrationIssues: false,
}

export enum events {
  UPSERT_SALSA_SUPPORTER = 'upsertSalsaSupporter',
  SYNC_NEW_SALSA_SUPPORTERS = 'syncNewSalsaSupporters',
  EXPORT_EXISTING_VOLUNTEERS = 'exportExistingVolunteers',
  VOLUNTEER_CREATED = 'volunteerCreated',
  ENSURE_SALSA_SUPPORTER_IS_A_VOLUNTEER = 'ensureSalsaSupporterIsAVolunteer',
  SYNC_INDIVIDUAL_SALSA_SUPPORTER = 'syncIndividualSalsaSupporter',
}

export const eventDisplayNameLookup = {
  [events.UPSERT_SALSA_SUPPORTER]: 'Ensure New Sync Salsa Supporter Exists',
  [events.SYNC_NEW_SALSA_SUPPORTERS]: 'Sync New Salsa Supporters',
  [events.EXPORT_EXISTING_VOLUNTEERS]: 'Export Civic Champ Volunteers',
  [events.VOLUNTEER_CREATED]: 'Export New Civic Champs Volunteer',
  [events.ENSURE_SALSA_SUPPORTER_IS_A_VOLUNTEER]: 'Ensure Salsa Supporter Is A Volunteer',
  [events.SYNC_INDIVIDUAL_SALSA_SUPPORTER]: 'Sync Individual Salsa Supporter',
}
