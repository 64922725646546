import React from 'react'
import { Field, Form, Formik } from 'formik'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { muiTheme } from 'theme'

import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'
import { CheckboxField, CustomSwitch, StyledOutlinedTextField } from 'civic-champs-shared/formik/components'

import { RolePayload, rolePayloadSchema } from '../types'

export interface AddEditRoleModalProps {
  role?: RolePayload
  showActiveToggle?: boolean
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const useStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '415px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  content: {
    padding: '8px 0',
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
})

const AddEditRoleDialog = ({ role, showActiveToggle = true, showing, close, complete }: AddEditRoleModalProps) => {
  const handleSubmit = (values: RolePayload) =>
    complete({
      id: role?.id,
      ...values,
    })

  const classes = useStyles()
  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#role-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik
        initialValues={{
          name: role?.name || '',
          isActive: role ? role.isActive : true,
          isDefault: role ? role.isDefault : false,
        }}
        validationSchema={rolePayloadSchema}
        onSubmit={handleSubmit}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, isValid, values }) => (
          <Form>
            <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="role-prompt">
              {role ? 'Edit Role' : 'New Role'}
              <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                <CloseIcon className={classes.dialogCloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <Field
                component={StyledOutlinedTextField}
                id="name"
                name="name"
                label="Enter a Role Name"
                variant="outlined"
                fullWidth
              />
              {showActiveToggle && (
                <div className={classes.switch}>
                  <Field
                    component={CustomSwitch}
                    id="isActive"
                    name="isActive"
                    thumbColor="#FFF"
                    trackColor={values.isActive ? '#008954' : '#a2a3a6'}
                    type="checkbox"
                    tooltip="Roles set to Inactive will not be available for selection when adding new Events or Activities."
                  />
                  <span>{values.isActive ? 'Active' : 'Inactive'}</span>
                </div>
              )}
              <Field
                component={CheckboxField}
                id="isDefault"
                name="isDefault"
                label="This role can be used for Opportunities"
                tooltip="Roles marked as useable for Opportunities can be selected by volunteers when checking into all Opportunities, or logging activities outside of specific Events."
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <OutlinedButton disabled={isSubmitting} onClick={close}>
                Cancel
              </OutlinedButton>
              <ContainedButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} onClick={submitForm}>
                Save
              </ContainedButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export const AddEditRoleModal = (props: AddEditRoleModalProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddEditRoleDialog {...props} />
  </ThemeProvider>
)

export default AddEditRoleModal
