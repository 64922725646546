// this is (awkwardly) translating a data array back into one time events, managing deps manually
/* eslint-disable-file react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'civic-champs-shared/core/notifications'

import { removeNotification } from './actions';

const mapStateToProps = state => ({
    notifications: state.notifications
});

const mapDispatchToProps = dispatch => ({
    removeNotification: (key) => dispatch( removeNotification(key) )
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
