import { isNumber, filter } from 'lodash'
import React, { useState, useEffect, useCallback } from 'react'
import { Switch, Route, useHistory } from 'react-router'
import { CircularProgress } from '@material-ui/core'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useGetCampaign from '../hooks/useGetCampaign'
import useUpdateCampaign from '../hooks/useUpdateCampaign'
import CampaignEditor from '../components/CampaignEditor'
import CampaignViewer from '../components/CampaignViewer'
import PublishCampaignDialog from '../components/PublishCampaignDialog'
import { CampaignEventType } from '../interface'
import { StripeAccountWrapper } from '../../pages/StripeAccountWrapper'

const useCampaignManagement = campaignId => {
  const history = useHistory()
  const [campaign, setCampaign] = useState()
  const [getCampaign] = useGetCampaign()

  useEffect(() => {
    ;(async () => {
      if (!isNumber(campaignId)) return

      const newCampaign = await getCampaign(campaignId)
      setCampaign(newCampaign)
    })()
  }, [getCampaign, campaignId])

  const [updateCampaign] = useUpdateCampaign()
  const handleSaveDraft = useCallback(
    async (campaign, editor) => {
      const updatedCampaign = await updateCampaign({
        ...campaign,
        furthestEditorStep: editor.furthestStep,
      })
      setCampaign(updatedCampaign)
      history.push(`/campaigns/${updatedCampaign.id}`)
    },
    [updateCampaign, history],
  )

  const showPublishModal = useShowPrompt(PublishCampaignDialog)
  const handlePublish = useCallback(
    async (campaign, editor) => {
      const finalCampaign = await showPublishModal({
        campaign: {
          ...campaign,
          furthestEditorStep: editor.furthestStep,
        },
      })
      setCampaign(finalCampaign)
      history.push(`/campaigns/${finalCampaign.id}`)
    },
    [history, showPublishModal],
  )

  return {
    campaign,
    onSaveDraft: handleSaveDraft,
    onPublish: handlePublish,
  }
}

export const CampaignDetails = props => {
  const { match } = props
  const campaignId = parseInt(match.params.campaignId)
  const { campaignEventType } = match.params
  const showMoreOrLess = () => {
    const suffix = campaignEventType ? '' : `/${CampaignEventType.DONATED}`
    props.history.push(`/campaigns/${campaignId}${suffix}`)
  }
  const controller = useCampaignManagement(campaignId)

  if (!controller.campaign) return <CircularProgress size={80} />
  return (
    <StripeAccountWrapper>
        <Switch>
          <Route exact path={`/campaigns/:campaignId/edit`} render={routeProps => <CampaignEditor {...routeProps} {...controller} />} />
          <Route
            path={`/campaigns/:campaignId/:campaignEventType?`}
            render={routeProps => (
              <CampaignViewer {...routeProps} campaign={controller.campaign} campaignEventType={campaignEventType} showMoreOrLess={showMoreOrLess} />
            )}
          />
        </Switch>
    </StripeAccountWrapper>
  )
}
