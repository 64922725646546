import React, { useMemo } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import useDonations from '../hooks/useDonations'
import Loading from 'components/Loading'
import format from 'civic-champs-shared/utils/format'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import DonationsSummaryCards from 'donation/components/DonationsSummaryCards'
import { CurrencyCell, NullableTextCell, WithLinkCell, DateCell, PhoneNumberCell, ContactCell } from 'core/table/cells'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import { StripeAccountWrapper } from './StripeAccountWrapper'
import { getDonationStatusDisplayNames } from '../campaigns/interface'
import { contactAccessor } from 'core/table/utils'
import { createContactSort } from 'components/table/sort'

const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '40px',
  },
}));

//TODO don't create cells here, use existing ones if at all possible!
const columns = [
  {
    id: 'date',
    Header: 'Date',
    accessor: 'createdAt',
    Cell: WithLinkCell(DateCell, id => `/donations/${id}`),
    filter: 'dateRange',
  },
  {
    id: 'type',
    Header: 'Type',
    accessor: 'subscription',
    Cell: ({ cell }) => (
      <div style={{ textAlign: 'right', marginRight: '10px' }}>{cell.value ? 'Recurring' : 'One-time'}</div>
    ),
  },
  { id: 'firstName', Header: 'First Name', accessor: 'person.givenName' },
  { id: 'lastName', Header: 'Last Name', accessor: 'person.familyName' },
  {
    id: 'email',
    Header: 'Email',
    sortType: createContactSort('email'),
    accessor: contactAccessor({
      valuePath: 'person.email',
      verificationStatusPath: 'person.emailVerified',
      type: 'email',
    }),
    Cell: ContactCell,
  },
  {
    id: 'mobile',
    Header: 'Mobile',
    sortType: createContactSort('mobile'),
    accessor: contactAccessor({
      valuePath: 'person.phoneNumber',
      verificationStatusPath: 'person.phoneNumberVerified',
      type: 'phone',
    }),
    Cell: ContactCell,
  },
  {
    id: 'homePhoneNumber',
    Header: 'Home',
    accessor: 'person.homePhoneNumber',
    Cell: PhoneNumberCell,
  },
  {
    id: 'amount',
    Header: 'Amount',
    accessor: donation => format.fixedPoint(donation.amountInCents / 100, 2),
    Cell: CurrencyCell,
  },
  {
    id: 'fees',
    Header: 'Fees',
    accessor: donation => format.fixedPoint(donation.fees / 100, 2),
    Cell: CurrencyCell,
  },
  {
    id: 'net',
    Header: 'Net',
    accessor: donation => format.fixedPoint((donation.amountInCents - donation.fees) / 100, 2),
    Cell: CurrencyCell,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: donation => getDonationStatusDisplayNames(donation.status),
    Cell: NullableTextCell,
  },
]

const config = {
  keys: [
      item => item.values['person.givenName'],
      item => item.values['person.familyName'],
      item => item.values['person.email'],
      item => item.values['person.phoneNumber'],
      item => item.values['person.homePhoneNumber'],
      'values.Status'
    ]
}

const fuzzyMatchFilter = (rows, id, filterValue) => matchSorter(rows, filterValue, config)
fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const DonationsOverview = () => {
  const { donations, loading } = useDonations()
  const [dateRange, setDateRange] = useDateRangeFilter()
  const classes = useStyles();

  const data = useMemo(() => donations || [], [donations])

  //TODO
  const table = useTable(
    {
      data,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('date', newRange)
  }

  return (
    <StripeAccountWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid item xs={4}>
            <Typography variant="h4">Donations</Typography>
          </Grid>
          <Grid xs={8} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px'}}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <DonationsSummaryCards rows={table.rows} />
              <div className={classes.tableWrapper}>
                <PagedTable {...table} />
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </StripeAccountWrapper>
  )
}
