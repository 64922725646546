import { useCallback, useEffect } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { GetDeleteConfigMapper } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { useGet } from 'civic-champs-shared/api/hooks'
import { Survey } from 'surveys/types'

export const useFetchSurveys = () => {
  const organization = useCurrentOrg()

  //TODO: needing this separate useCallback is clunky!
  //TODO: GetDeleteConfigMapper should have better name-- ConfigMapper vs ConfigMapperWithBody?
  //      maybe just have a default never body type?
  const configMapper = useCallback<GetDeleteConfigMapper<Survey[]>>(
    () => ({
      method: 'get',
      url: `/organizations/${organization.id}/surveys`,
      config: {
        //TODO all the "useful" query information includes question,
        //     kind of an expensive fetch just to get the current name
        //
        //    maybe a way to include latest question set version?  w/o questions?
        queryStringParameters: {
          includeQuestions: true,
        },
      },
    }),
    [organization],
  )

  const [refresh, { result: surveys, loading, called, error }] = useGet<Survey[]>({ emptyValue: [], configMapper })

  //TODO: this should be a parameter in useGet()
  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    surveys,
    refresh,
    loading: loading || !called,
    error,
  }
}

export default useFetchSurveys
