import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { NotificationBlastContact } from 'messages/hooks/useMessageOverview'
import { ExportButton } from 'core/table/components'

const filename = 'failed-recipients.csv'
const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
]

const formatData = (recipients: NotificationBlastContact[]) => {
  return recipients.map(row => ({
    ...row,
    phoneNumber: format.phoneNumber(row.phoneNumber),
  }))
}

const ExportFailedRecipientsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => (
  <ExportButton data={data} formatData={formatData} headers={headers} disabled={disabled} filename={filename} />
)

export default ExportFailedRecipientsButton
