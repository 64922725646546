import React, { useState, useMemo } from 'react'
import { useTable, useFilters, usePagination, useSortBy } from 'react-table'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import useCreateCampaign from '../hooks/useCreateCampaign'
import { DateCell, CurrencyCell } from 'core/table/cells'
import useListCampaigns from '../hooks/useListCampaigns'
import Loading from 'components/Loading'
import { isCampaignActive } from '../utils'
import CampaignsSummaryCards from '../components/CampaignsSummaryCards'
import PagedTable from 'core/table/components/PagedTable'
import Search from 'core/table/components/Search'
import { StripeAccountWrapper } from '../../pages/StripeAccountWrapper'
import { AddButton } from 'civic-champs-shared/core/add-button'

const useCellStyles = makeStyles({
  active: {
    fontWeight: 'bold',
    color: '#5F8FE8',
  },
  inactive: {
    color: 'grey', //TODO fix this
  },
  draft: {
    color: '#FF6F3E',
  },
})

const DRAFT = 0
const INACTIVE = 1
const ACTIVE = 2

const getCampaignStatus = campaign => {
  if (!campaign.publishedAt) return DRAFT
  return isCampaignActive(campaign) ? ACTIVE : INACTIVE
}

const CampaignStatusCell = ({ cell }) => {
  const classes = useCellStyles()

  switch (cell.value) {
    case ACTIVE:
      return <span className={classes.active}>Active</span>
    case INACTIVE:
      return <span className={classes.inactive}>Inactive</span>
    case DRAFT:
      return <span className={classes.draft}>Draft</span>
    default:
      return '?'
  }
}

const includeInactiveFilter = (rows, id, includeInactive) => {
  return includeInactive //TODO because chrome debug is an asshole
    ? rows
    : rows.filter(row => row.values[id] !== INACTIVE)
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ cell }) => <Link to={`/campaigns/${cell.row.original.id}`}>{cell.value || 'New Campaign'}</Link>,
  },
  {
    Header: 'Start',
    accessor: 'startsAt',
    Cell: DateCell,
  },
  {
    Header: 'End',
    accessor: 'endsAt',
    Cell: DateCell,
  },
  {
    id: 'totalDonations',
    Header: 'Donations',
    accessor: campaign => campaign.totalDonations / 100,
    Cell: CurrencyCell,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: getCampaignStatus,
    Cell: CampaignStatusCell,
    filter: includeInactiveFilter,
  },
]

export const Campaigns = () => {
  //TODO maybe this should be in the hook?
  const [createCampaign, { loading: creatingCampaign }] = useCreateCampaign()
  const history = useHistory()

  const handleAdd = async () => {
    const newCampaign = await createCampaign()
    history.push(`/campaigns/${newCampaign.id}/edit`)
  }

  const { campaigns, loading } = useListCampaigns()

  const [includeInactive, setIncludeInactive] = useState(false) //TODO persist!

  const filters = useMemo(() => [{ id: 'status', value: includeInactive }], [includeInactive])

  const table = useTable(
    {
      data: campaigns,
      columns,
      initialState: { filters },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setFilter('name', query || undefined)
  }

  const handleChangeInclude = event => {
    const newValue = event.target.checked
    setIncludeInactive(newValue)
    table.setFilter('status', newValue)
  }

  if (loading) {
    return <Loading />
  }

  return (
     <StripeAccountWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" direction="row">
        <Grid xs={6} container alignItems="center" item>
          <Grid item>
            <Typography variant="h4">Campaigns</Typography>
          </Grid>
          <Grid item style={{ marginLeft: 10 }}>
            <AddButton onClick={handleAdd} disabled={creatingCampaign} />
          </Grid>
        </Grid>
        <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
          <Grid item sm={4}>
            <Search onQueryChange={handleQueryChange} fullWidth variant="outlined" placeholder="Search" />
          </Grid>
          <Grid item style={{marginLeft: '10px'}}>
            <FormControlLabel
              label="Include Inactive"
              control={<Checkbox checked={includeInactive} onChange={handleChangeInclude} color="primary" />}
            />
          </Grid>
        </Grid>
        </Grid>
        <Grid item>
          <CampaignsSummaryCards rows={table.rows} />
        </Grid>
        <Grid container item style={{ minHeight: 400 }}>
          {
            //  loading
            //   ? <div><Loading /></div>
            //   :
            <PagedTable {...table} />
          }
        </Grid>
      </Grid>
    </StripeAccountWrapper>
  )
}
