import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useUISettings } from 'hooks/useUISettings'
import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { EditCell, EditHeader, NumberCell } from 'core/table/cells'
import { CellProps } from 'react-table'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import { useHistory } from 'react-router'
import qs from 'query-string'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  orderColumn: {
    '&>div': {
      width: '100px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '150px',
      marginRight: '50px',
    },
  },
  totalColumn: {
    '&>div': {
      width: '150px',
      paddingRight: '30px',
      marginRight: '50px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '105',
      paddingRight: '50px',
      marginRight: '50px',
    },
  },
  actionsColumn: {
    '&>div': {
      width: '80px',
    },
  },
})
export const useOpportunityTemplateColumns = ({
  editOpportunityTemplate,
  deleteOpportunityTemplate,
}: {
  editOpportunityTemplate: (opportunityTemplate: OpportunityTemplate) => void
  deleteOpportunityTemplate: (opportunityTemplate: OpportunityTemplate) => void
}) => {
  const history = useHistory()
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
            className: classes.idColumn,
          },
          {
            id: 'order',
            Header: 'Order',
            Cell: ({ row }: any) => `${row.index + 1}.`,
            disableFilters: true,
            disableSortBy: true,
            className: classes.orderColumn,
          },
          {
            id: 'name',
            Header: 'Opportunity Name',
            accessor: 'name',
            Cell: ({ value }: CellProps<OpportunityTemplate, string>) => {
              const handleClick = useCallback(
                e => {
                  e.preventDefault()
                  history.push('/activities?' + qs.stringify({ 'opportunity:=': value }))
                },
                [value],
              )
              return (
                <a href="#" className={classes.link} onClick={handleClick}>
                  {value}
                </a>
              )
            },
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'usageCount',
            Header: 'Total Opportunities',
            accessor: 'usageCount',
            Cell: NumberCell,
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'isActive',
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({ value }: CellProps<OpportunityTemplate, boolean>) => (value ? 'Active' : 'Inactive'),
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'actions',
            Header: <EditHeader />,
            Cell: ({ cell }: CellProps<OpportunityTemplate>) => (
              <EditCell cell={cell} onEdit={editOpportunityTemplate} onDelete={deleteOpportunityTemplate} />
            ),
            disableSortBy: true,
            disableFilters: true,
            className: classes.actionsColumn,
          },
        ],
        { showAdminItems },
      ),
    [
      classes.actionsColumn,
      classes.idColumn,
      classes.link,
      classes.nameColumn,
      classes.orderColumn,
      deleteOpportunityTemplate,
      editOpportunityTemplate,
      history,
      showAdminItems,
    ],
  )
}
