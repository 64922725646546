import { chain, filter, sumBy, size } from 'lodash'
import { useMemo } from 'react'

const useDonationsSummary = donations =>
  useMemo(() => {
    const paidDonations = filter(donations, d => d.status === 'paid')
    const recurring = filter(paidDonations, d => d.subscription != null)
    const total = sumBy(paidDonations, 'amountInCents') / 100

    return {
      donorCount: chain(paidDonations)
        .map('person.id')
        .uniq()
        .size()
        .value(),
      total,
      average: total / size(paidDonations),
      recurringCount: size(recurring),
      recurringTotal: sumBy(recurring, 'amountInCents') / 100,
    }
  }, [donations])

export default useDonationsSummary
