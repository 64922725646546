import React, { useState } from 'react'
import { Formik, Field, Form, FormikValues, FormikHelpers as FormikActions } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import Auth from '@aws-amplify/auth'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Hint, Link } from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import parseUsername from 'auth/utils/parseUsername'
import { trim } from 'lodash'

const formSchema = yup.object({
  username: yup.string().required(),
})

const newPasswordSchema = yup.object({
  verificationCode: yup.string().required(),
  password: yup
    .string()
    .required('This field is required.')
    .min(8, 'Password must be at least 8 characters.')
    .trim(),
})

interface ResetPasswordProps {
  onSubmit: (userCredential: any) => void
  onBackToSignIn: () => void
  classes: any
  cancelButton: React.ReactNode
}

export default function ResetPassword(props: ResetPasswordProps) {
  const { onSubmit, onBackToSignIn, classes, cancelButton } = props
  const [codeSent, setCodeSent] = useState(false)
  const [username, setUsername] = useState('')

  const handleSendCode = async (values: FormikValues, formikActions: FormikActions<any>): Promise<void> => {
    const { setFieldError, setSubmitting } = formikActions
    const parsedUserName = parseUsername(trim(values.username))
    try {
      await Auth.forgotPassword(parsedUserName)
      setCodeSent(true)
      setUsername(parsedUserName)
    } catch (err) {
      setFieldError('username', err.message)
    } finally {
      setSubmitting(false)
    }
  }

  const handleResetPassword = async (values: FormikValues, formikActions: FormikActions<any>): Promise<void> => {
    const { password, verificationCode } = values
    const { setFieldError, setSubmitting } = formikActions
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode.trim(), password)
      await Auth.signIn(username, password)
      onSubmit({ username, password })
    } catch (err) {
      setFieldError('verificationCode', err.message)
    } finally {
      setSubmitting(false)
    }
  }

  const PasswordForm = () => (
    <Formik
      initialValues={{ verificationCode: '', password: '' }}
      validationSchema={newPasswordSchema}
      onSubmit={handleResetPassword}
    >
      {({ isSubmitting }) => (
        <Form>
          <DialogTitle id="alert-dialog-title">Reset your Password</DialogTitle>
          <DialogContent>
            <div>
              <Field
                component={TextField}
                name="verificationCode"
                margin="normal"
                placeholder="Code"
                fullWidth
                variant="outlined"
              />
            </div>
            <div>
              <Field
                component={TextField}
                name="password"
                margin="normal"
                placeholder="New Password"
                type="password"
                fullWidth
                variant="outlined"
              />
            </div>
            <Hint>
              <Link onClick={() => setCodeSent(false)}>Resend Code</Link>
            </Hint>
          </DialogContent>
          <DialogActions>
            <Button type="submit" className={classes.submitButton}>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
              Submit
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )

  const SendCodeForm = () => (
    <Formik initialValues={{ username }} validationSchema={formSchema} onSubmit={handleSendCode}>
      {({ isSubmitting }) => (
        <Form>
          <DialogTitle id="alert-dialog-title">Reset your Password</DialogTitle>
          <DialogContent>
            <Field
              component={TextField}
              name="username"
              margin="normal"
              placeholder="Enter your username"
              fullWidth
              variant="outlined"
            />
            <Hint>
              <Link onClick={onBackToSignIn}>Back to Sign In</Link>
            </Hint>
          </DialogContent>
          <DialogActions>
            {cancelButton}
            <Button type="submit" className={classes.submitButton} autoFocus>
              {isSubmitting && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
              Send Code
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  )

  return codeSent ? <PasswordForm /> : <SendCodeForm />
}
