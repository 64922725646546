import React, { useMemo } from 'react'
import { DateTimeCell, NullableTextCell, WithLinkCell, ViewCell, DateCell, PersonNameCell, BoldPersonNameCell } from 'core/table/cells'
import StatusButton from '../components/StatusButton'
import { createPersonSort, createTaskStatusSort } from 'components/table/sort'
import TaskStatus, { TaskStatusDisplayNames } from '../TaskStatus'
import * as _ from 'lodash'



export default function useRequestsColumns({ refresh, personId }) {
  return useMemo(() => {
    const StatusCell = ({ row }) => <StatusButton task={row.original} refreshParent={refresh} fullWidth />

    const PersonCell = (idKey) => props =>
      personId && _.get(props.cell.row.original, idKey) === personId
        ? BoldPersonNameCell(props)
        : WithLinkCell(PersonNameCell, id => `/task-persons/${id}`, idKey)(props)
    return [
      {
        Header: '',
        id: 'view',
        Cell: ViewCell(id => `/tasks/${id}`),
      },
      {
        Header: 'Request Date',
        accessor: 'createdAt',
        Cell: DateCell,
      },
      {
        Header: 'Requester',
        accessor: 'requester',
        Cell: PersonCell('requester.id'),
        sortType: createPersonSort('requester'),
      },
      {
        Header: 'Recipient',
        accessor: 'recipient',
        Cell: PersonCell('recipient.id'),
        sortType: createPersonSort('recipient'),
      },
      {
        Header: 'Request Type',
        accessor: row => row.volunteeringRole.name,
        Cell: NullableTextCell,
      },
      {
        Header: 'Needed By',
        accessor: 'overdueAt',
        Cell: DateTimeCell,
      },
      {
        Header: 'Volunteer',
        accessor: 'volunteer',
        Cell: ({ cell }) =>
          cell.row.original.taskStatus === TaskStatus.Accepted
            ? StatusCell({ row: cell.row })
            : PersonCell('volunteer.id')({ cell }),
        sortType: createPersonSort('volunteer'),
      },
      {
        id: 'taskStatus',
        Header: 'Status',
        accessor: 'taskStatus',
        sortType: createTaskStatusSort('taskStatus'),
        Cell: ({ cell: { row } }) => TaskStatusDisplayNames[row.original.taskStatus],
      },
      {
        id: 'actions',
        Header: '',
        accessor: 'taskStatus',
        Cell: ({ cell }) => (cell.row.original.taskStatus !== TaskStatus.Accepted ? StatusCell({ row: cell.row }) : ''),
      },
    ]
  }, [refresh, personId])
}
