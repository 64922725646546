import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import { CellProps } from 'react-table'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import useMessageSummaryPrompt from 'messages/hooks/useMessageSummaryPrompt'
import { DateTimeCell } from 'core/table/cells'

import { MessageSourceType, NotificationBlastSummary } from '../types'
import { MessageStatusCell } from '../components/MessageStatusCell'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  failedStatus: {
    // @ts-ignore
    color: theme.palette.danger.main,
    textDecoration: 'underline',
  },
  sendingStatus: {
    color: theme.palette.secondary.main,
  },
  centered: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
  },
  column: {
    '&>div': {
      paddingRight: '5px',
    },
  },
}))

export const MessageSourceCell = ({ cell }: CellProps<NotificationBlastSummary>) => {
  const classes = useStyles()
  const { sourceType, group, opportunity, organization } = cell.row.original
  let name,
    link = ''
  if (sourceType === MessageSourceType.Group && group) {
    name = group.name
    link = `/groups/${group.id}`
  } else if (sourceType === MessageSourceType.Event && opportunity && organization) {
    name = opportunity.name
    link = `/events/${encodeOccurrenceFromEvent({
      opportunityId: opportunity.id,
      startsAt: opportunity.date,
      organization,
    })}/${organization.id}`
  }

  return name && link ? (
    <RouterLink className={classes.link} to={link}>
      {name}
    </RouterLink>
  ) : null
}

export const MessageSourceTypeCell = ({ value }: CellProps<NotificationBlastSummary>) => {
  return <div>{capitalize(value)}</div>
}

export const RecipientsCountCell = ({ cell }: CellProps<NotificationBlastSummary>) => {
  const { emailCount, smsCount } = cell.row.original
  return emailCount + smsCount
}

export const SubjectCell = ({ cell }: CellProps<NotificationBlastSummary>) => {
  const history = useHistory()
  const isNewCustomMessagesEnabled = useFeatureEnabled('NewCustomMessages')
  const showMessageSummary = useMessageSummaryPrompt()
  const classes = useStyles()
  const message = cell.row.original
  const { subject, userEnteredSubject, uuid } = message
  const text = userEnteredSubject || subject

  return (
    <Link
      onClick={() =>
        isNewCustomMessagesEnabled ? history.push(`/custom-messages/${uuid}`) : showMessageSummary(message)
      }
      className={classes.link}
    >
      {text.length > 60 ? `${text.substring(0, 60)}...` : text}
    </Link>
  )
}

export const useMessagesColumns = () => {
  const classes = useStyles()

  return useMemo(
    () => [
      {
        id: 'subject',
        Header: 'Subject',
        accessor: 'userEnteredSubject',
        Cell: SubjectCell,
        className: classes.column,
      },
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateTimeCell,
        filter: 'dateRange',
        className: classes.column,
      },
      {
        id: 'sourceType',
        Header: 'Source',
        accessor: 'sourceType',
        Cell: MessageSourceTypeCell,
        className: classes.column,
      },
      {
        id: 'source',
        Header: 'Source Name',
        Cell: MessageSourceCell,
        className: classes.column,
      },
      {
        Header: 'Recipients',
        Cell: RecipientsCountCell,
        className: cn(classes.column, classes.centered),
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: MessageStatusCell,
      },
    ],
    [classes],
  )
}
