import React, { useCallback } from 'react'
import useFetchPersonSuggestions, { PersonSuggestion } from '../api/hooks/useFetchPersonSuggestions'
import AdvancedAutocomplete, { OnScreenRenderingConfig } from './AdvancedAutocomplete'
import { GroupedOptions } from '../helpers/useDisplayAreaInfo'
import { reduce } from 'lodash'

export interface AdvancedPersonAutocompleteProps {
  label: string
  placeholder?: string
  groupId: number
  excludeNonGroupMembers?: boolean
  groupName?: string
  nonGroupName?: string
  value: PersonSuggestion | null
  onChange: (value: PersonSuggestion[] | PersonSuggestion | null) => void
  onScreenRenderingConfig?: OnScreenRenderingConfig
  variant?: 'filter' | 'form'
  error?: string
  disabled?: boolean
}

export function AdvancedPersonAutocomplete({
  label,
  groupId,
  excludeNonGroupMembers = false,
  groupName,
  placeholder,
  nonGroupName,
  value,
  onChange,
  onScreenRenderingConfig,
  variant,
  error,
  disabled,
}: AdvancedPersonAutocompleteProps) {
  const [getSuggestion] = useFetchPersonSuggestions({ groupId, withStat: true, excludeNonGroupMembers })
  const fetchOptions = useCallback(
    async (props?: { search: string; limit: number; offset: number }) => {
      let args: any[] = []
      if (props) {
        const { search, limit, offset } = props
        args = [search, limit, offset]
      }
      const { suggestions, count, groupCount, time } = await getSuggestion(...args)
      return { rows: suggestions, count, groupCount, time }
    },
    [getSuggestion],
  )

  const getGroupHandler = useCallback(
    ({ groupName, nonGroupName = 'Other users' }: { groupName?: string; nonGroupName?: string }) =>
      groupName
        ? (options: PersonSuggestion[]): GroupedOptions<PersonSuggestion> => {
            return reduce(
              options,
              (acc, option) => {
                const group = option.groupId ? groupName : nonGroupName
                if (!acc[group]) {
                  acc[group] = []
                }
                acc[group].push(option)
                return acc
              },
              {} as GroupedOptions<PersonSuggestion>,
            )
          }
        : undefined,
    [],
  )

  return (
    <AdvancedAutocomplete<PersonSuggestion>
      label={label}
      placeholder={placeholder}
      fetchOptions={fetchOptions}
      value={value}
      variant={variant}
      getOptionInfo={person => ({
        left: `${person.givenName} ${person.familyName}`,
        id: person.id,
        right: person.contact,
      })}
      groupHandler={getGroupHandler({ groupName, nonGroupName })}
      onScreenRenderingConfig={{
        optionRowHeight: 32,
        groupTitleHeight: 24,
        pageSize: 100,
        ...(onScreenRenderingConfig || {}),
      }}
      onChange={onChange}
      error={error}
      disabled={disabled}
    />
  )
}
