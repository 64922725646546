import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

export const useRemoveRole = (eagerRemove: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()

  return useCallback(
    async role => {
      const undo = eagerRemove(role)
      const notification = createNotification('Removing roles')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/organizations/${currentOrg.id}/roles/${role.id}`,
        })

        notification.onSuccess('Role removed!')
      } catch (error) {
        notification.onError(`Could not remove ${role.name} from roles`, error)
        undo()
      }
    },
    [createNotification, currentOrg.id, eagerRemove, request],
  )
}

export default useRemoveRole
