import { get } from 'lodash'
import moment from 'moment'

import format from 'civic-champs-shared/utils/format'
import activitySources from 'tracking/enum/activitySources'
import { LOG_OTHER_HOURS } from 'tracking/enum/entryMethod'
import { createDateObjectSort } from 'components/table/sort'
import { FixedPointCell, PreFormattedCell } from 'core/table/cells'
import ActivityDetailCell from '../components/ActivityDetailsCell'
import useActivityActionCell from './useActivityActionCell'

const getEntryMethod = activity => {
  let source = activitySources[activity.occurredAtSource]
  return get(source, 'entryMethod', LOG_OTHER_HOURS)
}

const getCheckInCellValue = activity => {
  let fromLogOtherHours = getEntryMethod(activity) === LOG_OTHER_HOURS
  let occurredAt = moment(activity.occurredAt)
  if (fromLogOtherHours) {
    occurredAt.startOf('day')
  }

  let display = fromLogOtherHours ? format.date(occurredAt) : format.datetime(occurredAt)

  return { value: occurredAt, display: display, width: '10em' }
}

const useActivityColumns = (actions) => {
    const ActivityActionCell = useActivityActionCell(actions)

    return [
        { Header: 'First Name', accessor: 'user.givenName' },
        { Header: 'Last Name', accessor: 'user.familyName' },
        { Header: 'Opportunity', accessor: 'opportunity.name' },
        {
            Header: 'Activity',
            accessor: 'volunteeringRole.name',
            Cell: ActivityDetailCell,
        },
        {
            Header: 'Check-In',
            accessor: getCheckInCellValue,
            Cell: PreFormattedCell,
            sortType: createDateObjectSort('Check-In'),
        },
        {
            Header: 'Hours',
            accessor: 'hoursVolunteered',
            Cell: FixedPointCell,
            sortType: 'basic',
        },
        {
            id: 'actions',
            Cell: ActivityActionCell,
        },
    ]
}

export default useActivityColumns
