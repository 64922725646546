import { useMemo, useCallback, useEffect } from 'react';

import useLazyQuery from './useLazyQuery';

export default function useQuery(queryDef, params) {
    const [fetch, queryState] = useLazyQuery(queryDef);

    const refresh = useCallback( () => fetch(params), [fetch, params]);

    useEffect(() => { refresh() }, [refresh]);
    
    return useMemo(
        () => ({ ...queryState, refresh }),
        [queryState, refresh]
    );
}