import { size, meanBy, sumBy } from 'lodash'

export default function useActivitySummary(activities) {
  const averageHours = meanBy(activities, item => item.hoursVolunteered) || 0
  const totalHours = sumBy(activities, item => item.hoursVolunteered) || 0

  return {
    count: size(activities),
    averageHours: averageHours,
    totalHours: totalHours,
  }
}
