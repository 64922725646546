import React, { useEffect } from 'react'
import { withFormik, FormikProps, FormikValues, FormikHelpers as FormikActions } from 'formik'
import { object, string, ref } from 'yup'

import InputComponent from '../../../Mentorship/kit/components/default-input'
import ButtonComponent from '../../../Mentorship/kit/components/button'
import { getFormikFieldError } from '../../../utils/getErrorMessage'
import FormWrapper from '../../../Mentorship/kit/components/form-wrapper'

import './index.scss'

const changePasswordValidationSchema = object().shape({
  newPass: string()
    .required('This field is required')
    .min(8, 'Password must be at least 8 characters')
    .trim(),
  repeatPass: string()
    .required('This field is required')
    .oneOf([ref('newPass')], 'Passwords do not match')
    .trim(),
})

const initialFormValues = {
  currentPass: '',
  newPass: '',
  repeatPass: '',
}

interface IFormProps extends FormikProps<FormikValues> {
  passwordError?: string
  onClickCancel?: VoidFunction
}

const ChangePasswordForm = (props: IFormProps) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    isValid,
    isSubmitting,
    passwordError = '',
    onClickCancel,
  } = props

  useEffect(() => {
    setFieldError('newPass', passwordError)
    setFieldTouched('newPass', true, false)
  }, [passwordError])

  return (
    <form onSubmit={handleSubmit}>
      <div className="change_pass_new">
        <InputComponent
          type="password"
          label="New Password"
          name="newPass"
          value={values.newPass}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getFormikFieldError('newPass', errors, touched)}
          disabled={isSubmitting}
        />
      </div>
      <div className="change_pass_repeat">
        <InputComponent
          type="password"
          label="Repeat New Password"
          name="repeatPass"
          value={values.repeatPass}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMessage={getFormikFieldError('repeatPass', errors, touched)}
          disabled={isSubmitting}
        />
      </div>
      <div className="change_pass_btn">
        <div className="change_pass_btn_cancel" onClick={onClickCancel}>
          CANCEL
        </div>
        <ButtonComponent
          type="submit"
          color="yellow"
          className="change_pass_btn_save"
          disabled={!isValid || isSubmitting}
        >
          SUBMIT
        </ButtonComponent>
      </div>
    </form>
  )
}

interface IChangePasswordProps {
  changeUserPassword: (newPass: string) => {}
  closePopup: VoidFunction
  passwordError?: string
}

const ChangePassword = ({ changeUserPassword, closePopup, passwordError = '' }: IChangePasswordProps) => {
  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>): Promise<void> => {
    await changeUserPassword(values.newPass)
    actions.setSubmitting(false)
  }

  const Form: any = withFormik<IChangePasswordProps, FormikValues>({
    mapPropsToValues: () => initialFormValues,
    validationSchema: changePasswordValidationSchema,
    handleSubmit: submitFormHandler,
  })(ChangePasswordForm)

  return (
    <FormWrapper title={'Change Password'}>
      <Form onClickCancel={closePopup} passwordError={passwordError} />
    </FormWrapper>
  )
}

export default ChangePassword
