import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import { TripOrder } from 'volunteering/trips/types'

const useRemoveTripRequestDialog = (onDelete: (tripOrders: TripOrder[]) => any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)

  return useCallback(
    async (tripOrders: TripOrder[]) => {
      const confirmed = await showPrompt({
        text: `This will permanently delete all selected trip requests, and cannot be undone. Are you sure you want to delete the selected trip requests?`,
        title: 'Are you sure you want to remove the selected trip requests?',
        confirmText: 'Remove requests',
        cancelText: 'Do not remove requests',
        type: 'warning',
      })

      if (confirmed) {
        onDelete(tripOrders)
      }
    },
    [showPrompt, onDelete],
  )
}

export default useRemoveTripRequestDialog
