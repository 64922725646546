import React, { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFetch } from 'civic-champs-shared/api/hooks'
import AddUserFlow from 'users/components/AddUserFlow';
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';
import cn from 'classnames';
import { useCurrentOrg } from 'auth/hooks';
import REQUIRED_GROUP_TYPES from '../requiredGroupTypes'

const groupLabelByType = {
    [REQUIRED_GROUP_TYPES.requester]: 'Task Requester',
    [REQUIRED_GROUP_TYPES.recipient]: 'Task Recipient',
    [REQUIRED_GROUP_TYPES.volunteer]: 'Task Volunteer',
}

export default function AddRequiredGroupMemberPrompt(props) {
    const classes = usePromptStyles();
    const org = useCurrentOrg()
    const { showing, close, cancel, complete, groupType } = props;

    const [request, { loading: submitting, error, result }] = useFetch()
    const handleSubmit = useCallback( event => {
        request({
            method: 'post',
            url: `/organizations/${org.id}/required-group/${groupType}/members`,
            config: {
                body: event
            }
        })
    }, [org.id, groupType, request])

    const handleDone = () => complete(result)

    return (
      <Dialog
        fullScreen
        className={cn({ fullScreen: true })}
        open={showing}
        onClose={close}
        disableBackdropClick={true}
      >
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title}>Add {groupLabelByType[groupType] || 'Member'}</Typography>
          <IconButton disabled={submitting} onClick={close} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddUserFlow
            userLabel={groupLabelByType[groupType] || 'Member'}
            onSubmit={handleSubmit}
            submitting={submitting}
            error={error}
            onCancel={cancel}
            onDone={handleDone}
          />
        </DialogContent>
      </Dialog>
    )
}
