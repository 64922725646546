import { Grid, makeStyles, Typography } from '@material-ui/core'
import Tooltip from 'civic-champs-shared/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Scrollbars from 'civic-champs-shared/core/Scrollbars'
import map from 'lodash/map'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'left',
  },
  scroll: {
    width: '340px',
    height: '148px',
    border: '1px solid #74777F',
    borderRadius: '4px',
    position: 'relative',
    padding: 0,
    margin: '16px 0 18px 20px',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  scrollBarsWrapper: {
    height: '107px',
    padding: '0',
    borderTop: '1px solid #EEF0FA',
  },
  subTitle: {
    width: '50%',
    padding: '12px 0 12px 12px',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#44474F',
    padding: '0 4px',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: '-5px',
    left: '12px',
  },
  row: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    padding: '7px 0 7px 20px',
    '&:hover': {
      backgroundColor: '#EBF0FF',
      cursor: 'default',
    },
    '&:hover svg': {
      display: 'block !important',
    },
  },
  errorRow: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginBottom: '10px',
  },
  icon: {
    display: 'none',
    float: 'left',
    cursor: 'pointer',
    marginRight: '8px',
    marginTop: '-1px',
    fontSize: '18px',
    lineHeight: '16px',
  },
})

export const SendNotificationSelectionStep = (props: any) => {
  const { removeRow, handleSend, loading, rows } = props
  const classes = useStyles()

  return (
    <>
      <Grid item>
        <Typography className={classes.header}>
          Resend Verification Link{' '}
          <Tooltip value="Verification links can be resent to volunteers that have unverified contact information associated with their profiles, but do not yet have a Civic Champs account. Volunteers who already have any verified contact information, or volunteers without any contact information associated with their profiles, will not receive the new verification message.">
            <HelpOutlineIcon style={{ color: 'rgba(15, 93, 181, 1)' }} />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item>
        <div className={classes.scroll}>
          <Grid container>
            <Grid item className={classes.subTitle}>
              Recipients
            </Grid>
          </Grid>
          <div className={classes.scrollBarsWrapper}>
            <Scrollbars>
              {map(rows, ({ user: { id, familyName, givenName } }: any) => (
                <div key={id} className={classes.row}>
                  <CloseIcon onClick={() => removeRow(id)} className={classes.icon} />{' '}
                  <span>
                    {givenName} {familyName}
                  </span>
                </div>
              ))}
            </Scrollbars>
          </div>
        </div>
      </Grid>
      <Grid item container direction="row" justify="flex-end">
        <Grid item>
          <ContainedButton onClick={handleSend} disabled={loading}>
            Send
          </ContainedButton>
        </Grid>
      </Grid>
    </>
  )
}

export default SendNotificationSelectionStep
