import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import RemoveShiftsPrompt from './RemoveShiftsPrompt'

const useRemoveShiftsPrompt = (onEditShifts) => {
  const prompt = usePrompt(RemoveShiftsPrompt)

  return useCallback((registration) => {
    if (prompt.showing) return

    const unlistens = [
      prompt.onComplete(onEditShifts),
      prompt.onHide(() => {
        unlistens.forEach(unlisten => unlisten())
      }),
    ]

    prompt.show({ registration })
  }, [onEditShifts, prompt])
}

export default useRemoveShiftsPrompt
