import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import useSetSortOrder from 'core/table/table-hooks/useSetSortOrder'
import map from 'lodash/map'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { SyncAction } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useFetchDocumentTypes } from 'documents/hooks/useFetchDocumentTypes'

export const useReorderDocumentTypes = (eagerSync: SyncAction<DocumentType>) => {
  const [request] = useFetchRefactored<DocumentType[]>({
    emptyValue: undefined,
  })

  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()
  const [setSortOrder] = useSetSortOrder()
  const { fetchDocumentTypes } = useFetchDocumentTypes()

  return useCallback(
    async ({ rows, sortOption }) => {
      const notification = createNotification('Reordering document types')
      try {
        await setSortOrder('document_type', `"${sortOption.field}" ${sortOption.order}`)

        let documentTypes

        if (sortOption.isCustom) {
          documentTypes = await request({
            method: 'put',
            url: `/organizations/${currentOrg.id}/document_types/order`,
            config: {
              body: map(rows, 'id'),
            },
          })
        } else {
          documentTypes = await fetchDocumentTypes()
        }

        notification.onSuccess('Document types reordered!')
        eagerSync(documentTypes)
      } catch (error) {
        notification.onError(`Could not reorder document types`, error)
      }
    },
    [createNotification, currentOrg.id, eagerSync, fetchDocumentTypes, request, setSortOrder],
  )
}

export default useReorderDocumentTypes
