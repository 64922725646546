import React, { useCallback, useEffect } from 'react'
import { useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { map, size } from 'lodash'
import matchSorter from 'match-sorter'
import { Button, Grid } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/MessageOutlined'

import PagedTable from 'core/table/components/PagedTable'
import useGroupDetailColumns from '../hooks/useGroupDetailColumns'
import useProcessMemberApprovals from '../hooks/useProcessMemberApprovals'
import FeatureEnabled from '../../core/feature/components/FeatureEnabled'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import RemoveButton from '../../civic-champs-shared/core/remove-button'
import useSelectColumn from '../../core/table/table-hooks/useSelectColumn'
import ExportGroupMembersButton from './ExportGroupMembersButton'
import DEFAULT_FILTERS from 'core/table/filters'
import { MessageSourceType } from 'messages/types'

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.id',
      'original.person.givenName',
      'original.person.familyName',
      'original.status.displayName',
      'original.person.email',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

const GroupMembers = props => {
  const { group, members } = props
  const { processApprovals: handleShowApprovals } = useProcessMemberApprovals(props.refresh)
  const columns = useGroupDetailColumns(handleShowApprovals, group)

  const getSearchableContacts = useCallback(
    () =>
      Promise.resolve(
        map(members, ({ person: { id, givenName, familyName, email, phoneNumber } }) => ({
          id,
          firstName: givenName,
          lastName: familyName,
          email,
          phoneNumber,
        })),
      ),

    [members],
  )

  const table = useTable(
    {
      data: members,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn,
  )
  const selectedMembersCount = size(table.state.selectedRowIds)

  useEffect(() => {
    table.setGlobalFilter(props.query)
  }, [props.query, table])

  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()

  const filterTableMembers = () => {
    return members.filter((member, index) => {
      return table.state.selectedRowIds[index]
    })
  }

  const filterTableMembersIDs = () => {
    return members
      .filter((member, index) => {
        return table.state.selectedRowIds[index]
      })
      .map(function (member) {
        return member.person.id
      })
  }

  const onMessageMembers = () => {
    const recipientPersonIds = filterTableMembersIDs()
    showMessageRecipientsPrompt({
      getSearchableContacts,
      recipientPersonIds,
      sourceType: MessageSourceType.Group,
      sourceId: group.id,
    })
  }

  const onRemoveMember = () => {
    props.removeMember(filterTableMembers())
  }

  return (
    <Grid container>
      <Grid container item sm={12} direction="column" spacing={3}>
        <Grid container item spacing={1} alignItems="center" justify="flex-start">
          <FeatureEnabled code="BulkMessaging">
            <Grid item>
              <Button onClick={onMessageMembers} variant="contained" color="secondary" disabled={!selectedMembersCount}>
                <MessageIcon style={{ marginRight: 5 }} />
                Message
              </Button>
            </Grid>
          </FeatureEnabled>
          <Grid item>
            <ExportGroupMembersButton disabled={selectedMembersCount === 0} data={filterTableMembers()} />
          </Grid>

          <Grid item>
            <RemoveButton onClick={onRemoveMember} disabled={selectedMembersCount === 0} />
          </Grid>
        </Grid>
        <Grid>
          <PagedTable {...table} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupMembers
