import { Address } from 'civic-champs-shared/core/location/utils'
import { Person } from 'people/interface'
import { OrgNamedRef } from 'civic-champs-shared/common/types'
import { EventGeofencing } from 'utils/event'

export enum TripOrderEventType {
  DRIVER_UNASSIGNED = 'driver-unassigned',
  DISPATCHER_CANCELLED = 'dispatcher-cancelled',
  DRIVER_CANCELLED = 'driver-cancelled',
  UPDATED = 'updated',
  DRIVER_REASSIGNED = 'driver-reassigned',
  STARTED = 'started',
  DRIVER_FEEDBACK = 'driver-feedback',
  DRIVER_NO_SHOW = 'driver-no-show',
  RIDER_NO_SHOW = 'rider-no-show',
  PICKED_UP = 'picked-up',
  DRIVER_COMPLETED = 'driver-completed',
  DRIVER_CONFIRMED = 'driver-confirmed',
  DRIVER_ASSIGNED = 'driver-assigned',
  DISPATCHER_COMPLETED = 'dispatcher-completed',
  CREATED = 'created',
}

export enum TripRequestEventType {
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CREATED = 'created',
}

export interface TripRequest {
  id: number
  tripOrders: TripOrder[]
  rider: Person
  riderId: number
  tripOrderIndex: number
}

export interface TripRequestPayload {
  tripOrders?: Partial<TripOrder>[]
  rider?: Person
  riderId?: number
}

export interface TripLocation {
  address: Address
  geofencing: EventGeofencing
}

export interface TripOrderPayload {
  id?: number
  driver?: Person
  driverId?: number
  description: string
  dropOffAt: Date
  dropOffLocation: TripLocation
  pickupAt: Date
  pickupLocation: TripLocation
  validated: boolean
  createdBy: Person
  createdById?: number
  payload: any
  eventCode?: TripOrderEventType
}

export enum TripOrderReason {
  HOME = 'home',
  HEALTH = 'health',
  PURPOSE = 'purpose',
  COMMUNITY = 'community',
}

export enum SudMh {
  SUD = 'sud',
  MH = 'mh',
  CO_OCCURRING = 'co-occurring',
}

export interface TripOrder {
  id?: number
  driverId?: number | null
  driver?: Person
  rider?: Person
  description: string
  dropOffAt: Date | string
  dropOffLocation: TripLocation
  pickupAt: Date | string
  pickupLocation: TripLocation
  validated?: boolean
  createdBy?: Person
  pickedUpAt?: Date | string | null
  droppedOffAt?: Date | string | null
  reason: TripOrderReason | null
  recovery: boolean | null
  cji: boolean | null
  sudMh: SudMh | null
  tripRequest?: TripRequestRow
  mileage?: number
  tripOrderStatus?: TripOrderStatus
  tripRequestId?: number
}

export enum TripOrderStatus {
  Unassigned = 'unassigned',
  Assigned = 'assigned',
  InProgress = 'in-progress',
  Started = 'started',
  RiderPickedUp = 'rider-picked-up',
  Completed = 'completed',
  Cancelled = 'cancelled',
  DriverNoShow = 'driver-no-show',
  RiderNoShow = 'rider-no-show',
  OnPickUp = 'on-pick-up',
}

export interface TripRequestRow {
  id: number
  organization: OrgNamedRef
  tripRequestStatus: TripRequestEventType
  rider: Person
  createdBy: Person
  createdAt: Date
  deletedBy?: Person
  deletedAt?: Date
  mileage?: number
}

export interface MappedTripTableRow {
  subRows: any
  orderName: string
  pickupTime: any
  date: any
  tripOrderCount: any
  assignedDriverCount: any
  driver: null
  status: any
  actions?: string
  data: any
}
