import { meanBy, size, sumBy } from 'lodash'
import { Activity } from 'champion/utils/interface'
import { Row } from 'react-table'

export default function getActivitySummary(activities: Row<Activity>[]) {
  const averageHours = meanBy(activities, item => item.original.hoursVolunteered) || 0
  const totalHours = sumBy(activities, item => item.original.hoursVolunteered) || 0

  return {
    count: size(activities),
    averageHours: averageHours,
    totalHours: totalHours,
  }
}
