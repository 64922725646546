
export interface FileAssociation {
  organizationId?: number
  donationCampaignId?: number
  groupId?: number
  volunteerTaskId?: number
  encodedOccurrence?: number
  opportunityId?: number | string
  viewerId?: number
  userCredentialId?: number
}

export interface Metadata {
  personId: number
  association?: FileAssociation
}

export interface UploadConfig {
  expires?: number
  contentType: string
  isPrivate: boolean
  metadata?: Metadata
}

export enum VirusStatus {
  UNSCANNED = 'UNSCANNED',
  CLEAN = 'CLEAN',
  ERROR = 'ERROR',
  INFECTED = 'INFECTED',
}

export interface UploadedFilePayload {
  filename: string
  contentType: string
  tags?: string[]
  url: string
  virusStatus?: VirusStatus
  isPrivate?: boolean
}

export interface UploadedFile extends UploadedFilePayload {
  id: number
  createdAt: Date
  isPrivate: boolean
}
