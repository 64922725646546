import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'
import { OrganizationLocationPayload, OrganizationLocationReporting } from '../types'

export const useSaveLocation = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<OrganizationLocationReporting>()
  const saveLocation = useCallback(
    async ({ id, ...location }: OrganizationLocationPayload) =>
      request({
        method: id ? 'put' : 'post',
        url: `/organizations/${organization.id}/locations${id ? `/${id}` : ''}`,
        config: {
          body: location,
        },
      }),
    [request, organization.id],
  )

  return { saveLocation, loading, error }
}
