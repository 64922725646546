import React from 'react'
import { useFeatureEnabled } from 'core/feature/hooks'
import QuestionSetEditor from 'question-sets/pages/QuestionSetEditor'
import SurveyEditor from 'question-sets/pages/SurveyEditor'

export interface QuestionSetSurveyEditorProps {
  match: {
    url: string
    params: { id?: string }
  }
}

export const QuestionSetSurveyEditor = (props: QuestionSetSurveyEditorProps) => {
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  return isQuestionnaireAsSurveyEnabled ? <SurveyEditor {...props} /> : <QuestionSetEditor {...props} />
}

export default QuestionSetSurveyEditor
