import { Address, getDisplayAddress } from 'civic-champs-shared/core/location/utils'
import { Row } from 'react-table'
import { contains } from 'core/table/filters'
import isUndefined from 'lodash/isUndefined'
import { Person } from 'civic-champs-shared/common/types'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

export const ADDRESS_FILTER = 'address'
export const STRING_ARRAY_FILTER = 'string_array'
export const YES_NO_FILTER = 'yes_no'
export const PERSON_FILTER = 'person'

const includes = (rowValue: string | null, operand: (string | { value: string })[]) => {
  // @ts-ignore
  return operand.map(v => v?.value || v).includes(String(rowValue).toString())
}

const isPersonSame = (rowValue: Person | undefined, operand: PersonSuggestion) => {
  return rowValue?.id === operand.id
}

const operatorToMethodMap: { [key: string]: any } = {
  contains,
  includes,
  is: isPersonSame,
}

const addressFilter = (rows: Row[], ids: string[], filterValue: any) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = getDisplayAddress(row.values[id] as Address)
      const filterMethod = operatorToMethodMap[filterValue.operator as string]

      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

const stringArrayFilter = (rows: Row[], ids: string[], filterValue: any) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      const filterMethod = operatorToMethodMap[filterValue.operator as string]

      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

const personFilter = (rows: Row[], ids: string[], filterValue: any) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      const filterMethod = operatorToMethodMap[filterValue.operator as string]

      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

const yesNoFilter = (rows: Row[], ids: string[], filterValue: any) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]

      return (
        !!rowValue === !!(isUndefined(filterValue.operand?.value) ? filterValue.operand : filterValue.operand?.value)
      )
    })
  })
}

export const CUSTOM_FILTERS = {
  [ADDRESS_FILTER]: addressFilter,
  [STRING_ARRAY_FILTER]: stringArrayFilter,
  [YES_NO_FILTER]: yesNoFilter,
  [PERSON_FILTER]: personFilter,
}

export default CUSTOM_FILTERS
