import { get, isEmpty, map, some, pick, zipWith, omit } from 'lodash'
import { ParseResult } from 'papaparse'

import { ChampionDatafeedImportRow, ExternalCustomerDatafeed } from '../types'
import format from 'civic-champs-shared/utils/format'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

export enum IdMappingAction {
  DoNothing = 'DoNothing',
  AssignExternalID = 'AssignExternalID',
  AbortForError = 'AbortForError',
}

//TODO is this worth anything
const NOT_FOUND = Symbol()
const getAndMap = (source: any, accessor: string | string[], mapFn: (value: any) => any) => {
  const value = get(source, accessor, NOT_FOUND)
  return value !== NOT_FOUND ? mapFn(value) : undefined
}

export const transformCSVToData = (csvRow: ParseResult<any>): ChampionDatafeedImportRow => {
  const { data, errors, meta } = csvRow
  const row: ChampionDatafeedImportRow = {
    internalId: get(data, 'ID'),
    externalId: get(data, 'Customer ID'),
    firstName: get(data, 'First Name'),
    lastName: get(data, 'Last Name'),
    email: get(data, 'Email'),
    phoneNumber: getAndMap(data, 'Mobile Phone', format.phoneNumber),
    homePhoneNumber: getAndMap(data, 'Home Phone', format.phoneNumber),
    isError: false,
    isNew: false,
    errors: [],
  }

  //TODO handle parsing errors!
  for (let error of errors) {
    switch (error.code) {
      case 'MissingQuotes':
      case 'TooManyFields':
        row.errors.push(`[PARSE] ${error.message}`)
        row.isError = false
    }
  }

  //TODO handle "aborted" parsing?
  if (meta.aborted) {
    row.errors.push(`[PARSE] aborted`)
    row.isError = true
  }

  return row
}

export const validateImport = async (datafeed: ExternalCustomerDatafeed, result: ParseResult<any>[]) => {
  let rows = map(result, transformCSVToData)

  const hasErrors = some(rows, row => !isEmpty(row.errors))
  if (!hasErrors) {
    const mappings = map(rows, row => pick(row, ['internalId', 'externalId']))
    const processedMappings = await requestWithRetry({
      method: 'post',
      url: `/external_datafeeds/${datafeed.id}/id_mappings`,
      config: {
        body: mappings,
        queryStringParameters: { dryRun: 'true' },
      },
    })

    rows = zipWith(rows, processedMappings, (row, processedMapping) => {
      //@ts-ignore
      const { internalId, externalId, action, errors } = processedMapping

      return {
        internalId,
        externalId,
        ...omit(row, ['errors', 'internalId', 'externalId']),
        isNew: action === IdMappingAction.AssignExternalID,
        isError: action === IdMappingAction.AbortForError,
        errors: [...(row.errors || []), ...(errors || [])],
      }
    })
  }

  return rows
}
