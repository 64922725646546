import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  Column,
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import AddIcon from '@material-ui/icons/Add'
import { Grid } from '@material-ui/core'
import { sumBy } from 'lodash'

import ContainedButton from 'civic-champs-shared/core/ContainedButton'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks/'

import Loading from 'components/Loading'

import PageHeader from 'App/components/PageHeader'
import { useMessagesColumns } from '../hooks'
import { NotificationBlastSummary } from '../types'
import { STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'
import { useGetAllSearchableContacts } from 'messages/hooks/useGetAllSearchableContacts'
import { SMS_CREDIT_CONTACT } from '../config'
import { useMessagesCollection } from '../hooks/useMessagesCollection'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'

const csvHeaders = [
  { label: 'Subject', key: 'subject' },
  { label: 'Date', key: 'createdAt' },
  { label: 'Source', key: 'source' },
  { label: 'Source Name', key: 'sourceName' },
  { label: 'Status', key: 'status' },
]

const cards = [
  {
    header: 'Total Messages',
    accessor: 'totalMessages',
  },
  {
    header: 'Emails Sent',
    accessor: 'emailSent',
  },
  {
    header: 'SMS Sent',
    accessor: 'smsSent',
  },
  {
    header: 'SMS Sends Available',
    accessor: 'smsCredits',
    tooltip: `To purchase additional SMS Credits, please contact customer support at ${SMS_CREDIT_CONTACT} `,
  },
]

export const useGetMessagesSummary = () => {
  const organization = useCurrentOrg()
  return useCallback(
    (messages: Row<NotificationBlastSummary>[]) => {
      const emailSent = sumBy(messages, 'original.emailCount') || 0
      const smsSent = sumBy(messages, 'original.smsCount') || 0
      return {
        totalMessages: emailSent + smsSent,
        emailSent,
        smsSent,
        smsCredits: organization.smsCredits || 0,
      }
    },
    [organization],
  )
}

const tableName = 'customMessages'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'subject':
      return STRING_OPERATOR_OPTIONS
    default:
      return []
  }
}

const CustomMessagesComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()

  const [{ messages, loading, initiallyLoaded }, { addMessage }, eventListeners] = useMessagesCollection()

  const getSearchableContacts = useGetAllSearchableContacts()

  const columns = useMessagesColumns()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)

  const table = useTable(
    {
      data: messages,
      columns: columns as Column<NotificationBlastSummary>[],
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      autoResetPage: !skipReset.current,
      autoResetSortBy: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const getSummary = useGetMessagesSummary()

  const onNewMessage = () => {
    addMessage({
      recipientPersonIds: [],
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }

  if (loading || columnStateLoading || !initiallyLoaded) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows.map((row: any) => row.original)
  const disabled = !selectedRows.length

  return (
    <Grid container>
      <ExtendedPagedTable<NotificationBlastSummary>
        getSummary={getSummary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Messages"
        skipReset={skipReset}
        eventListeners={eventListeners}
        collectionKey="uuid"
        buttons={
          <>
            <ExportButton
              disabled={disabled}
              data={selectedRows}
              headers={csvHeaders}
              filename={'custom-messages.csv'}
            />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={onNewMessage}>
            New Message
          </ContainedButton>
        }
        useGlobalSearch
        useDateRange
      />
    </Grid>
  )
}

export const CustomMessages = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Custom Messages" subTitle="Compose and review messages sent to members of your organization">
      <CustomMessagesComponent {...props} />
    </PageHeader>
  )
}

export default CustomMessages
