import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Grid, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { useAddGroup } from '../hooks/useAddGroup'
import cn from 'classnames'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { GroupClosedSwitchField } from './GroupClosedSwitch'
import * as yup from 'yup'

export default function AddGroupPrompt(props) {
  const classes = usePromptStyles()
  const { showing, close, cancel, complete } = props
  const [addGroup] = useAddGroup(complete)
  const handleSubmit = (event) => addGroup(event)

  return (
    <>
      <Dialog fullScreen className={cn({ fullScreen: true })} open={showing} onClose={close}>
        <DialogTitle className={classes.titleContainer}>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row">
              <Grid>
                <Typography className={classes.title}>Add Group</Typography>
              </Grid>
            </Grid>
            <Grid>
              <IconButton onClick={close} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ name: '', description: '', closed: false }}
            validationSchema={yup.object({ name: yup.string().required() })}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting, isValid }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <Field component={TextField} id="name" name="name" label="Name" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        id="description"
                        name="description"
                        label="Description"
                        variant="outlined"
                        multiline
                        rows="2"
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Field component={GroupClosedSwitchField} name="closed" />
                    </Grid>
                    <Grid item>
                      <Button onClick={cancel}>Cancel</Button>
                      <Button type="submit" disabled={!isValid || isSubmitting}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}
