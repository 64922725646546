import React from 'react'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { useCurrentOrg } from '../../auth/hooks'
import { PeopleOverview } from '../components/PeopleOverview'

export const OrgPeopleOverview = props => {
  const currentOrg = useCurrentOrg()
  const [refresh, { loading, result: people }] = useGetOnMount({
    url: `/organizations/${currentOrg.id}/people`,
    errorMessage: 'Error fetching People',
    emptyValue: [],
  })

  return <PeopleOverview 
    title={`People in ${currentOrg.name}`}
    people={people}
    loading={loading}
    refresh={refresh}
  />
}
