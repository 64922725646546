import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import BaseService from './services/base.service'
import FeedbackScene from './scenes/FeedbackScene'

const baseService = new BaseService()

export default function Routes() {
  const is_feedback = window.location.pathname.includes('/mentorship/feedback/')
  const styles = {
    display: is_feedback ? 'block' : 'flex',
    height: '100%',
  }
  return (
    <Router>
      <div style={styles}>
        <Switch>
          <Route
            exact
            path="/mentorship/feedback/:uuid"
            render={props => <FeedbackScene {...props} {...baseService} />}
          />
        </Switch>
      </div>
    </Router>
  )
}
