import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select } from 'formik-material-ui'
import { FormControl, InputLabel } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    padding: '14px 8px',
  },
  select: {
    marginTop: '10px',
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid',
    },
  },
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  label: {
    padding: '0 8px',
    '&.MuiFormLabel-root.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&.MuiInputLabel-shrink': {
      transform: 'translate(8px, 3px) scale(0.75)',
      backgroundColor: 'white',
      zIndex: 1,
    },
    '&.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
})

export default function StyledSelect({ label, ...props }: any) {
  const classes = useStyles()

  return (
    <FormControl fullWidth className={classes.formControl}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select {...props} classes={{ root: classes.root }} className={classes.select} />
    </FormControl>
  )
}
