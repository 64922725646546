import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export const useDeleteDocuments = () => {
  const [request, { loading, error }] = useFetchRefactored<void>()
  const deleteDocuments = useCallback(
    async (documentIds: number[]) =>
      request({
        method: 'del',
        url: `/document_files`,
        config: {
          body: documentIds,
        },
      }),
    [request],
  )

  return { deleteDocuments, loading, error }
}
