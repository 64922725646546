import React from 'react'
import Popover from 'core/popover/Popover'
export default function SalsaSegmentInfoPopover() {
  // TODO: have the icon be blue
  // TODO: get anchorPosition and margins to work
  return (
    <Popover>
      <div>
        <p><b>How to find the Group ID for an existing "static" group</b></p>
        <div>
          <p>
            1. Go here:{' '}
            <span>
              <a rel="noopener" href="https://hq.salsalabs.org/#/audience/segments" target="_blank">
                https://hq.salsalabs.org/#/audience/segments
              </a>
            </span>
          </p>
          <p>
            2. Scroll down and click the <b>“Custom”</b> tab
          </p>
          <p>3. Then select the group and copy the ID from the URL</p>
        </div>
        <br />
        <div> - OR - </div>
        <br />
        <div>
          <p><b>How to create a new "static" group:{' '}</b></p>

          <p>
            1. Go here:{' '}
            <span>
            <a rel="noopener" href="https://hq.salsalabs.org/#/audience/segment/create" target="_blank">
              https://hq.salsalabs.org/#/audience/segment/create
            </a>
            </span>
          </p>
          <p>
            2. Enter a group "Name" and optional "Description"
          </p>
          <p>3. Select <b>"Static"</b> for the the "Type of Group".  The integration will not work for "dynamic" groups</p>
          <p>4. Click "Next: View Group Supporters" in the bottom right corner</p>
          <p>5. Copy the ID from the URL (see instructions above)</p>
        </div>
      </div>
    </Popover>
  )
}
