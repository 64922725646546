import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createExpireTransform from 'redux-persist-expire';
import thunk from 'redux-thunk'
import migrate from './migrate';
import reducer from '../reducers';
import root from '../sagas';
import createVolatileStateTransform from './createVolatileStateTransform';
import { defaultState as devFeaturesDefaultState } from 'core/feature/reducers/development-features';
import { defaultState as productFeaturesDefaultState } from 'core/feature/reducers/product-features';

export const history = createBrowserHistory();
const saga = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  blacklist: [
    'router',
    'auth',
    'api',
    'filter',
    'CreateEvent',
    'EditEvent',
    'Matches',
    'Match',
    'User'
  ], //temporarily blacklist 'api'
  migrate,
  transforms: [
    createVolatileStateTransform(
      { initialized: false, loading: false, error: null },
      'features'
    ),
    createExpireTransform('features', {
      persistedAtKey: 'lastUpdatedAt',
      expireSeconds: 60 * 60 * 24,
      expiredState: devFeaturesDefaultState,
      autoExpire: false
    }),
    createVolatileStateTransform(
      { initialized: false, loading: false, error: null },
      'productFeatures'
    ),
    createExpireTransform('productFeatures', {
      persistedAtKey: 'lastUpdatedAt',
      expireSeconds: 60 * 60 * 24,
      expiredState: productFeaturesDefaultState,
      autoExpire: false
    })
  ]
}

const persistedReducer = persistReducer(persistConfig, reducer(history));

// eslint-disable-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(saga),
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history))
  )
);

saga.run(root);

export const persistor = persistStore(store);
export default store;
