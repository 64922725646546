import React from 'react'
import format from 'civic-champs-shared/utils/format'

import PageHeader from 'App/components/PageHeader'
import {
  ARRAY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'
import getActivitySummary from 'tracking/activity/utils/getActivitySummary'
import * as H from 'history'
import { Opportunity } from '../../../Event/interfaces'
import { ActivityLog as ActivityLogComponent } from '../components/ActivityLog'

const cards = [
  {
    header: 'Total Activities',
    accessor: 'count',
    transform: format.number,
  },
  {
    header: 'Average Activity Length',
    accessor: 'averageHours',
    transform: format.number,
  },
  {
    header: 'Total Hours',
    accessor: 'totalHours',
    transform: format.number,
  },
]

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'firstName':
    case 'lastName':
    case 'opportunity':
    case 'name':
      return STRING_OPERATOR_OPTIONS

    case 'hours':
      return NUMBER_OPERATOR_OPTIONS

    case 'role':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'reflections':
      return [{ value: operatorTypes.CONTAINS, displayValue: 'Contains' }]

    case 'satisfaction':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'groups':
      return ARRAY_OPERATOR_OPTIONS

    default:
      return []
  }
}

const exportAllHandler = async () => {
  const blob = await requestWithRetry({
    url: '/activity_export',
    config: {
      responseType: 'blob',
    },
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', 'activity.csv')
  link.click()
}

interface Props {
  opportunity?: Opportunity
  history: H.History
  location: H.Location
}

export const ActivityLog = () => (
  <PageHeader title="Activity Log" subTitle="View, add, and edit volunteer activities">
    <ActivityLogComponent tableName="activityLog" cards={cards} getActivitySummary={getActivitySummary} />
  </PageHeader>
)
