import { useCallback } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'

const useCreateNonSchedulableOpportunity = (organizationId) => {
  const [request, status] = useFetch()
  const postOpportunity = useCallback((payload) => {
    return request({
      method: 'post',
      url: `/organizations/${organizationId}/opportunities`,
      config: { body: payload }
    })
  }, [request, organizationId])

  return [postOpportunity, status]
}

export default useCreateNonSchedulableOpportunity
