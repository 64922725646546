import _ from 'lodash'
import moment from 'moment'
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

import DurationField from './DurationField'
import LabeledText from './LabeledText'
import useDateTimeRange from 'utils/useDateTimeRange'
import { useCurrentOrg, useHasRole } from 'auth/hooks'
import { getVolunteerName, isLogOtherHoursActivity, isTimesheetActivity } from '../utils'
import OpportunitySelector from 'volunteering/opportunities/components/OpportunitySelector'
import VolunteeringRoleSelector from 'tracking/volunteeringRoles/components/VolunteeringRoleSelector'
import { useEditActivity } from '../hooks/useEditActivity'
import { useDateTimeUtils } from 'civic-champs-shared/utils/useDateTimeUtils'

const useStyles = makeStyles(() => ({
  labelStyle: {
    fontSize: '1.2em',
    fontWeight: '600',
    display: 'inline',
  },
  textStyle: {
    fontSize: '1.2em',
    display: 'inline',
    paddingLeft: '5px',
  },
  rowContent: {
    padding: '1em 0.25em',
  },
  rowContentCentered: {
    padding: '0.1em 1em',
    alignContent: 'center',
  },
  dtPicker: {},
  submitButton: {
    backgroundColor: '#5C8DE8',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const styles = () => ({
  root: {
    margin: 0,
    padding: '0',
    fontFamily: 'Open Sans',
    borderRadius: '4px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'white',
  },
  title: {
    fontSize: '2em',
    fontWeight: '500',
    backgroundColor: '#5C8DE8',
    color: 'white',
    marginTop: '0px',
    width: '100%',
    padding: '0.4em 1em',
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(() => ({
  root: {
    padding: '10px 30px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useActivityTimeRange = activity => {
  const retVal = useDateTimeRange()
  const { fakeAsLocalTimeForUI } = useDateTimeUtils()
  const [, actions] = retVal

  useEffect(() => {
    let start = fakeAsLocalTimeForUI(_.get(activity, 'occurredAt'))
    let hours = _.get(activity, 'hoursVolunteered', 0)
    let duration = moment.duration(hours, 'hours')

    actions.setStart(start)
    actions.setDuration(duration)
  }, [activity, actions]) // eslint-disable-line react-hooks/exhaustive-deps

  return retVal
}

const useActivityForm = activity => {
  const [opportunityId, setOpportunityId] = useState(null)
  const [range, rangeActions] = useActivityTimeRange(activity)
  const [volunteeringRoleId, setVolunteeringRoleId] = useState('')
  const [team, setTeam] = useState('')

  const actions = useMemo(
    () => ({
      setOpportunityId,
      setStartedAt: rangeActions.setStart,
      setEndedAt: rangeActions.setEnd,
      setDuration: rangeActions.setDuration,
      setVolunteeringRoleId,
      setTeam,
    }),
    [rangeActions],
  )

  useEffect(() => {
    let initialOpportunity = _.get(activity, 'opportunity.id')
    let initialRole = _.get(activity, 'volunteeringRole.id')
    let initialTeam = _.get(activity, 'team')

    setOpportunityId(initialOpportunity)
    setVolunteeringRoleId(initialRole)
    setTeam(initialTeam || '')
  }, [activity])

  return useMemo(() => {
    const formData = {
      opportunityId: opportunityId,
      startedAt: range.start,
      endedAt: range.end,
      duration: range.duration,
      volunteeringRoleId: volunteeringRoleId,
      team: team,
    }

    return [formData, actions]
  }, [opportunityId, range, volunteeringRoleId, team, actions])
}

export function EditActivityDialog(props) {
  const { open, complete, close, onClose, activity } = props
  const { unfakeAsLocalTimeForUIAsString } = useDateTimeUtils()
  const onSuccess = onClose || close
  const classes = useStyles()
  const organization = useCurrentOrg()
  const isSuperAdmin = useHasRole(SUPER_ADMIN)

  const [formData, actions] = useActivityForm(activity)
  const [editActivity, { loading: submitting }] = useEditActivity()
  const volunteerName = getVolunteerName(activity)

  const submit = useCallback(async () => {
    const activityData = {
      id: activity.id,
      volunteer: {
        cognitoSub: activity.user.cognitoSub,
        name: volunteerName,
      },
      occurredAt: unfakeAsLocalTimeForUIAsString(formData.startedAt),
      hoursVolunteered: formData.duration.as('hours'),
      volunteeringRoleId: formData.volunteeringRoleId,
      team: formData.team,
    }

    if (formData.opportunityId) {
      activityData.opportunityId = formData.opportunityId
    }

    const savedActivity = await editActivity(activityData)
    complete ? complete(savedActivity) : onSuccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, volunteerName, formData, editActivity, complete, onSuccess])

  const useDefaultRolesOnly = isLogOtherHoursActivity(activity)

  const formattedStartDate = useMemo(() => {
    return formData.startedAt instanceof moment ? formData.startedAt.clone().add(30, 'seconds').startOf('minute') : ''
  }, [formData.startedAt])
  const formattedEndDate = useMemo(() => {
    return formData.endedAt instanceof moment ? formData.endedAt.clone().add(30, 'seconds').startOf('minute') : ''
  }, [formData.endedAt])

  // TODO this is a bit of a hack
  const volunteeringRoleQuery = useMemo(() => {
    const opportunity = formData.opportunityId
      ? {
          opportunityId: formData.opportunityId,
          startsAt: formData.startedAt,
        }
      : null

    return {
      organization,
      opportunity,
      defaultOnly: useDefaultRolesOnly,
    }
  }, [formData.opportunityId, formData.startedAt, organization, useDefaultRolesOnly])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      TransitionComponent={Transition}
      className={classes.modalStyle}
      fullScreen={true}
    >
      <form>
        <DialogTitle>Edit Activity</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid item className={classes.rowContent}>
              <LabeledText
                label="Volunteer:"
                text={volunteerName}
                labelStyle={classes.labelStyle}
                textStyle={classes.textStyle}
              />
            </Grid>
            {formData.opportunityId &&
              (isSuperAdmin ? (
                <OpportunitySelector
                  organization={organization}
                  value={formData.opportunityId}
                  onChange={actions.setOpportunityId}
                  required={false}
                  disabled={submitting}
                  reporting={false}
                />
              ) : (
                <Grid item className={classes.rowContent}>
                  <LabeledText
                    label="Opportunity:"
                    text={activity.opportunity.name}
                    labelStyle={classes.labelStyle}
                    textStyle={classes.textStyle}
                  />
                </Grid>
              ))}
            {isTimesheetActivity(activity) ? (
              <>
                <Grid item className={classes.rowContent}>
                  <KeyboardDateTimePicker
                    name="startedAt"
                    label="Check-In"
                    value={formattedStartDate}
                    onChange={actions.setStartedAt}
                    format="MM/DD/YYYY hh:mm a"
                    inputVariant="outlined"
                    className={classes.dtPicker}
                    disabled={submitting}
                  />
                </Grid>
                <Grid item className={classes.rowContent}>
                  <KeyboardDateTimePicker
                    name="endedAt"
                    label="Check-Out"
                    minDate={formattedStartDate}
                    value={formattedEndDate}
                    onChange={actions.setEndedAt}
                    format="MM/DD/YYYY hh:mm a"
                    inputVariant="outlined"
                    className={classes.dtPicker}
                    disabled={submitting}
                  />
                </Grid>
              </>
            ) : (
              <Grid item className={classes.rowContent}>
                <KeyboardDatePicker
                  name="startedAt"
                  label="Occurred On"
                  value={formattedStartDate}
                  onChange={actions.setStartedAt}
                  format="MM/DD/YYYY"
                  inputVariant="outlined"
                  className={classes.dtPicker}
                  disabled={submitting}
                />
              </Grid>
            )}
            <Grid item className={classes.rowContentCentered}>
              <DurationField value={formData.duration} onChange={actions.setDuration} disabled={submitting} />
            </Grid>
            <Grid item className={classes.rowContent}>
              <VolunteeringRoleSelector
                value={formData.volunteeringRoleId}
                onChange={actions.setVolunteeringRoleId}
                required={false}
                disabled={submitting}
                query={volunteeringRoleQuery}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <TextField
                id="team"
                name="team"
                label="Team"
                variant="outlined"
                value={formData.team}
                onChange={e => actions.setTeam(e.target.value)}
                disabled={submitting}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose || close} disabled={submitting}>
            Cancel
          </Button>
          <Button className={classes.submitButton} disabled={submitting} onClick={submit}>
            {submitting && <CircularProgress className={classes.buttonProgress} size={24} color="primary" />}
            Save Activity
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default EditActivityDialog
