import React from 'react'
import useActivityExportConfig from '../export'
import { ExportButton } from 'core/table/components'

const ExportActivityLogButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const config = useActivityExportConfig()

  return (
    <ExportButton
      data={data}
      disabled={disabled}
      formatData={config.useFormattedData}
      headers={config.headers}
      filename={config.filename}
    />
  )
}

export default ExportActivityLogButton
