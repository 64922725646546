import React, { useState, useRef } from 'react'
import { CSVReader } from 'react-papaparse'
import { Button } from '@material-ui/core'
import { ParseResult } from 'papaparse'

//TODO promote this to a component library
//TODO replace existing CSV import functions w/ this button!

interface ImportCsvButtonProps {
  onImportParsed?: (data: ParseResult<any>[]) => void | Promise<void>
  disabled?: boolean
}

export const ImportCsvButton: React.FC<ImportCsvButtonProps> = (props) => {
  const { disabled=false, children, onImportParsed } = props

  const [importing, setImporting] = useState(false)

  const readerRef = useRef<CSVReader | null>(null)
  const handleOpen = (e: any) => {
    if(readerRef.current) {
      setImporting(true)
      readerRef.current.open(e)
    }
  }

  const handleFileLoad = async (data: ParseResult<any>[], file: any) => {
    (async () => {
      try {
        if(onImportParsed) await onImportParsed(data)
      } finally {
        setImporting(false)
      }
    })()
  }

  return (
    <CSVReader
      ref={readerRef}
      noClick
      noDrag
      isReset
      config={{
        header: true,
        skipEmptyLines: 'greedy'
      }}
      onFileLoad={handleFileLoad}
   >
    {({ file, progressBar }: { file: any, progressBar: number }) => (
      <Button 
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        disabled={disabled || importing}
      >
        {children}
      </Button>
    )}
  </CSVReader>)
}