import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Survey } from 'surveys/types'

// useSurvey returns broken `loading` state, so we added this one
const useGetSurvey = (): [(id: number) => Promise<Survey>, Status<Survey>] => {
  const [request, status] = useFetchRefactored<Survey>()

  const getSurvey = useCallback(
    (id: number) => {
      return request({
        method: 'get',
        url: `/surveys/${id}`,
      })
    },
    [request],
  )

  return [getSurvey, status]
}

export default useGetSurvey
