import { isEmpty, isString } from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ImportExport from '@material-ui/icons/ImportExport'
import { makeStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import ActionMenuItem from 'components/menu/ActionMenuItem'
import useExportStyles from 'export/useExportStyles'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  csvLink: {
    textDecoration: 'none',
    color: 'black',
  },
}))

export const useCurrentDateWithFilename = (filename, separator = '_') =>
  useMemo(() => {
    if (!isString(filename) || isEmpty(filename)) return null

    const today = moment().format('YYYYMMDD')
    const match = filename.match(/^(.*)\.csv$/)
    const prefix = match ? match[1] : filename

    return `${prefix}${separator}${today}.csv`
  }, [filename, separator])

export default function ExportCsvMenuItem({ data, config }) {
  const classes = useStyles()
  const filename = useCurrentDateWithFilename(config.filename)
  const [formattedData, setFormattedData] = useState([])
  const createFormattedData = useCallback(() => {
    setFormattedData(config.useFormattedData(data))
  }, [config, data])
  const csvClasses = useExportStyles()

  return (
    <ActionMenuItem>
      <CSVLink
        onClick={createFormattedData}
        className={cn(classes.csvLink, csvClasses.fill)}
        filename={filename}
        headers={config.headers}
        data={formattedData}
      />
      <ListItemIcon>
        <ImportExport />
      </ListItemIcon>
      Export to CSV
    </ActionMenuItem>
  )
}
