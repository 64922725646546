import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { NamedRef } from 'civic-champs-shared/question-sets/types'
import { useFeatureEnabled } from 'core/feature/hooks'

export const useDuplicateQuestionnaire = (eagerAdd: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'

  return useCallback(
    async (questionnaire: NamedRef) => {
      const { name } = questionnaire
      const notification = createNotification('Duplicating questionnaire')
      try {
        // @ts-ignore
        const duplicatedQuestionSet = await request({
          method: 'post',
          url: `/organizations/${currentOrg.id}/${
            isQuestionnaireAsSurveyEnabled ? 'survey/duplicate' : 'question-set'
          }`,
          config: {
            body: {
              [key]: questionnaire.id,
              name,
            },
          },
        })

        const { id, questionSetId, questionSetCreatedAt, createdAt, createdBy } = duplicatedQuestionSet
        eagerAdd({
          id: questionSetId || id,
          name,
          createdAt: questionSetCreatedAt || createdAt,
          createdBy,
          status: 'Draft',
        })
        notification.onSuccess('Questionnaire duplicated!')
      } catch (error) {
        notification.onError(`Could not duplicate ${name} questionnaire`, error)
      }
    },
    [createNotification, currentOrg.id, eagerAdd, isQuestionnaireAsSurveyEnabled, key, request],
  )
}

export default useDuplicateQuestionnaire
