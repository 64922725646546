import React, { useEffect } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import matchSorter from 'match-sorter'
import PagedTable from 'core/table/components/PagedTable'
import useTasks from '../hooks/useTasks'
import Loading from 'components/Loading'
import { DateCell, NullableTextCell, ViewCell, PersonNameCell } from 'core/table/cells'
import { TaskStatusDisplayNames } from '../TaskStatus'
import { createPersonSort } from 'components/table/sort'

const columns = [
  {
    Header: '',
    id: 'view',
    Cell: ViewCell(id => `/tasks/${id}`),
  },
  {
    Header: 'Request Date',
    accessor: 'createdAt',
    Cell: DateCell,
  },
  {
    Header: 'Requester', 
    accessor: 'requester',
    Cell: PersonNameCell,
    sortType: createPersonSort('requester')
  },
  {
    Header: 'Recipient',
    accessor: 'recipient',
    Cell: PersonNameCell,
    sortType: createPersonSort('recipient')
  },
  {
    Header: 'Request Type',
    id: 'requestType',
    accessor: row => row.volunteeringRole.name,
    Cell: NullableTextCell
  },
  {
    Header: 'Volunteer',
    accessor: 'volunteer',
    Cell: PersonNameCell,
    sortType: createPersonSort('volunteer')
  },
  // TODO Add to api - will be combined with status below
  // {
  //   Header: 'Completed At',
  // }
  {
    Header: 'Status',
    accessor: row => TaskStatusDisplayNames[row.taskStatus]
  }
  // TODO add to api
  // {
  //   Header: 'Rating'
  // }
]

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.givenName',
      'original.familyName',
      'original.email',
      'original.phoneNumber',
      'original.homePhoneNumber',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

export default function CompletedTasksTab(props) {
  const { query } = props
  const { closedTasks, loading } = useTasks()

  const table = useTable({
      data: closedTasks,
      columns,
      getRowId: row => row.id,
      globalFilter: fuzzyMatchFilter,
      initialState: {
        globalFilter: query,
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  useEffect(() => { table.setGlobalFilter(query) }, [table, query])

  return (
    <div>
      {
        loading ?
        <Loading />
        :
        <PagedTable {...table } />
      }
    </div>
  )
}
