import React, { useCallback, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { Add as AddIcon, AddCircle as AddCircleIcon, Close as CloseIcon } from '@material-ui/icons'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { usePaperComponentWithOnCreate } from 'civic-champs-shared/core/hooks'
import { useAutocompleteStyles } from 'civic-champs-shared/core/hooks/useAutocompleteStyles'
import { useHandleBlur } from 'civic-champs-shared/helpers/useHandleBlur'

import { StyledAutocomplete } from 'components/StyledAutocomplete'
import Tag from 'components/tag/Tag'
import { Tag as TagType } from '../types'
import { AddEditTagModal } from './AddEditTagModal'
import { useSaveTag } from '../hooks'
import { FieldProps } from 'formik'
import cn from 'classnames'

const useStyles = makeStyles({
  listbox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  addIcon: {
    padding: '3px',
  },
  tagChip: {
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000000',
    cursor: 'pointer',
    fontWeight: 400,
    margin: '2px',
    borderRadius: '16px',
    '& span': {
      fontSize: '13px',
      fontFamily: 'Open Sans',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0 12px 0 12px',
      textOverflow: 'elipsis',
    },
    '& svg': {
      margin: '0 5px 0 -6px',
      height: '22px',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
})

const filterSuggestions = createFilterOptions({ stringify: ({ name }: any) => name })

export interface TagsAutocompleteProps {
  addOption: (option: TagType) => void
  addNewText: string
  onBlur?: (...values: any) => void
  className?: string
  variant?: 'legacy' | 'form'
}

interface FormikTagsAutocompleteProps extends TagsAutocompleteProps, FieldProps {}

export const TagAutocomplete = ({
  addOption,
  addNewText = 'New Tag',
  onBlur,
  form,
  field,
  className,
  variant = 'legacy',
  ...props
}: FormikTagsAutocompleteProps) => {
  const [open, setOpen] = useState(false)
  const showAddTag = useShowPrompt(AddEditTagModal)

  const classes = useStyles()
  const baseClasses = useAutocompleteStyles({})
  const { saveTag } = useSaveTag()
  const { setFieldValue } = form
  const { name, value } = field

  const focusEl = useRef<HTMLDivElement | null>(null)
  const handleCreate = useCallback(() => {
    showAddTag().then(formData =>
      saveTag(formData).then(savedTag => {
        addOption(savedTag)
        setFieldValue(name, [...value, savedTag])
        setOpen(false)
      }),
    )
  }, [addOption, name, saveTag, setFieldValue, showAddTag, value])

  const PaperComponent = usePaperComponentWithOnCreate({
    addNewText,
    addNewIcon: <AddCircleIcon htmlColor="#0F5DB5" className={classes.addIcon} />,
    onCreate: handleCreate,
    ref: focusEl,
  })

  const handleBlur = useHandleBlur([focusEl], () => {
    setOpen(false)
    onBlur && onBlur()
  })

  return (
    <StyledAutocomplete
      open={open}
      setOpen={setOpen}
      form={form}
      field={field}
      {...props}
      classes={{
        groupLabel: baseClasses.groupLabel,
        option: baseClasses.option,
        listbox: classes.listbox,
        root: cn(classes.autoComplete, className),
      }}
      multiple
      filterSelectedOptions
      PaperComponent={PaperComponent}
      onBlur={handleBlur}
      variant={variant}
      onFocus={() => setOpen(true)}
      renderTags={(value: any, getTagProps: any) =>
        value.map((option: any, index: any) => (
          <Tag
            deleteIcon={<CloseIcon />}
            margin="2px"
            id={option.id}
            color={option.color}
            label={option.name}
            {...getTagProps({ index })}
          />
        ))
      }
      // can't use MUI Chip as tag since we should have custom "+" button
      renderOption={(props: any) => (
        <div
          role="button"
          className={classes.tagChip}
          style={{
            backgroundColor: props.color,
          }}
        >
          <span>{props.name}</span>
          <AddIcon />
        </div>
      )}
      filterOptions={filterSuggestions}
      blurOnSelect
      // not a debug code, needed to properly handle add tag click
      debug={true}
    />
  )
}
