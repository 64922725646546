import { useCallback, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export default function useUpdatePerson(onSuccess) {
  const organization = useCurrentOrg()
  const [request, { loading, error, result }] = useFetch(
    'Error updating person.  Please refresh the page to try again',
  )

  const updatePerson = useCallback(
    person => request({
      method: 'put',
      url: `/organizations/${organization.id}/champions/${person.id}`,
      config: {
        body: {
          person
        },
      },
      onSuccess
    }),
    [request, organization.id, onSuccess],
  )

  return useMemo(
    () => ({
      person: result,
      loading,
      error,
      updatePerson,
    }),
    [result, loading, error, updatePerson],
  )
}
