import { useCallback, useEffect, useState } from 'react'
import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

const useRegistrants = query => {
  const { date, personId } = query
  const [request, { loading, error }] = useFetch()
  const [registrants, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection } = operations
  const getRegistrants = useCallback(async () => {
    const updatedRegistrants = await request({
      url: `/organizations/${organization.id}/registrants`,
      config: {
        queryStringParameters: omitBy(
          {
            date: date ? date.toISOString() : undefined,
            personId,
          },
          isUndefined,
        ),
      },
    })

    syncCollection(updatedRegistrants)
    setInitiallyLoaded(true)
  }, [request, organization.id, date, personId, syncCollection])

  useEffect(() => {
    getRegistrants()
  }, [getRegistrants])

  return [
    {
      registrants,
      loading,
      error,
      initiallyLoaded,
      reload: getRegistrants,
    },
  ]
}

export default useRegistrants
