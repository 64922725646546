import { Button, Grid, makeStyles } from '@material-ui/core'
import { Field } from 'formik'
import * as _ from 'lodash'
import { CREATE_NEW_QUESTION_SET } from '../QuestionSetPicker/hooks'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Icon } from '@mdi/react'
import { mdiMinusCircleOutline } from '@mdi/js'
import React from 'react'
import CheckboxField from 'civic-champs-shared/question-sets/components/CheckboxField'

const useClasses = makeStyles({
  dropDownQuestionSet: {
    '& .Mui-error': {
      marginTop: '12px',
    },
  },
  buttonRemove: {
    backgroundColor: '#ff6f3e',
    color: 'white',
  },
  iconRemove: {
    fill: 'white',
    marginRight: '3px',
  },
})

export const SurveyDirtyRow = props => {
  const { index, options, handleNewSurvey, arrayHelpers, showRequired, formikBag, surveysOnScreen = [] } = props
  const { values, setFieldValue, errors, isSubmitting } = formikBag
  const classes = useClasses()

  // Array of survey ids, that won't be displayed on the screen
  const surveyIds = surveysOnScreen.map(({ surveyId }) => surveyId).concat('')
  const unusedOptions = options.filter(item => !surveyIds.includes(item?.surveyId)) // option that has't been used yet

  return (
    <>
      <Grid item xs={8}>
        <Field
          name={`requirements.${index}.value`}
          options={unusedOptions}
          disableClearable
          disabled={isSubmitting}
          value={_.get(values, `requirements.${index}.value`)}
          getOptionLabel={option => option.name || CREATE_NEW_QUESTION_SET}
          component={Autocomplete}
          onChange={async (_event, selection) => {
            if (selection === CREATE_NEW_QUESTION_SET) {
              await handleNewSurvey(setFieldValue, index)
            } else {
              setFieldValue(`requirements.${index}.value.name`, selection.name)
              setFieldValue(`requirements.${index}.value.surveyId`, selection.surveyId)
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!_.get(errors, `requirements[${index}].value.surveyId`)}
              helperText={_.get(errors, `requirements[${index}].value.surveyId`)}
              classes={{ root: 'custom-input-component' }}
              variant="outlined"
              fullWidth
              placeholder="Select a questionnaire"
              className={classes.dropDownQuestionSet}
            />
          )}
        />
      </Grid>
      {showRequired && (
        <Grid item xs={2} container justify="center">
          <Field
            component={CheckboxField}
            name={`requirements.${index}.value.required`}
            label="Optional"
            invertValue
            disabled={isSubmitting}
            onChange={() =>
              setFieldValue(
                `requirements[${index}].value.required`,
                !_.get(values, `requirements[${index}].value.required`),
              )
            }
            labelstyle={{ font: 'normal normal normal 18px/24px Open Sans', marginTop: '15px' }}
          />
        </Grid>
      )}
      <Grid item container xs={showRequired ? 2 : 4} justify="flex-end" direction="row">
        <Button
          variant="contained"
          className={classes.buttonRemove}
          onClick={() => arrayHelpers.remove(index)}
          disabled={isSubmitting}
        >
          <Icon path={mdiMinusCircleOutline} size={0.8} className={classes.iconRemove} />
          Remove
        </Button>
      </Grid>
    </>
  )
}
