import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useOrganization(organizationId?: number) {
  // @ts-ignore
  const [request, { loading, error, result, called }] = useFetch()

  const getOrganization = useCallback(() => {
    // @ts-ignore
    return request({ method: 'get', url: `/organizations/${organizationId}` })
  }, [request, organizationId])

  useEffect(() => {
    if (organizationId) {
      getOrganization()
    }
  }, [getOrganization])
  return {
    result,
    loading: loading || !called,
    error,
    called,
  }
}
