import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const emptyArray = []

export default function useStripeProducts() {
  const [request, { result, loading, called, error }] = useFetch('Error fetching stripe products')
  const getStripeProducts = useCallback(async () => {
    return request({
      method: 'get',
      url: '/stripe-products',
    })
  }, [request])

  useEffect(() => {
    getStripeProducts()
  }, [])

  return useMemo(
    () => ({ result: result || emptyArray, loading: loading || !called, called, error }),
    [result, loading, called, error],
  )
}
