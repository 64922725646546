import React, { useEffect, useMemo } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import matchSorter from 'match-sorter'
import PagedTable from 'core/table/components/PagedTable'
import useTasks from '../hooks/useTasks'
import Loading from 'components/Loading'
import useRequestsColumns from '../hooks/useRequestsColumns'

const generatePersonFields = person => [
  `original.${person}.givenName`,
  `original.${person}.familyName`,
  `original.${person}.email`,
  `original.${person}.phoneNumber`,
]
const keys = [
  ...generatePersonFields('volunteer'),
  ...generatePersonFields('recipient'),
  ...generatePersonFields('requester'),
  'original.volunteeringRole.name',
]
const fuzzyMatchFilter = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys })
}

fuzzyMatchFilter.autoRemove = value => !value

export default function RequestsTab(props) {
  const { query, personId, includeClosedAndCompleted = false } = props

  const { tasks, closedTasks, completedTasks, refresh, loading } = useTasks({ personId })
  const columns = useRequestsColumns({ refresh, personId })

  const data = useMemo(() => {
    return includeClosedAndCompleted ? [...tasks, ...closedTasks, ...completedTasks] : tasks
  }, [tasks, closedTasks, completedTasks, includeClosedAndCompleted])

  const table = useTable(
    {
      data,
      columns: columns,
      getRowId: row => row.id,
      globalFilter: fuzzyMatchFilter,
      initialState: {
        globalFilter: query,
        sortBy: [
          {
            id: 'overdueAt',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  useEffect(() => {
    table.setGlobalFilter(query)
  }, [table, query])

  return <div>{loading ? <Loading /> : <PagedTable {...table} />}</div>
}
