import { makeStyles } from '@material-ui/core/styles'

// @ts-ignore
export default makeStyles({
  wrapper: {
    minHeight: '100vh',
    width: '100%',
  },
  block: {
    padding: '15px 45px 15px 45px',
  },
  back: {
    fontFamily: 'Open Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '34px',
    color: '#5C8DE8',
    textDecoration: 'none',
    position: 'relative',
    paddingLeft: '30px',
    display: 'flex',
    alignItems: 'center',
  },
  arrowBack: {
    position: 'absolute',
    left: 0,
    top: '8px',
    background: 'url("/assets/mentorship/images/arrow_left.svg") no-repeat center center/cover',
    width: '15px',
    height: '15px',
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0 32px',
  },
  headerTitle: {
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '48px',
    color: '#090909',
    marginRight: '24px',
  },
  info: {
    marginBottom: '50px',
  },
  infoDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '22px',
    maxWidth: '280px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  detailsItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsTitle: {
    fontFamily: 'Open Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000',
  },
  detailsName: {
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
  },
  surveys: {
    display: 'flex',
  },
  surveyTitle: {
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '48px',
    color: '#090909',
  },
  surveyItem: {
    flex: 1,
    fontFamily: 'Open Sans, sans-serif',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000',
    marginRight: '28px',
    background: '#FFFFFF',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    padding: '19px 0 13px 23px',
    '&:last-child': {
      marginRight: 0,
    },
  },
  surveyItemBlock: {
    marginTop: '22px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  surveyItemTitle: {
    fontWeight: 'bold',
  },
  surveyItemName: {
    fontWeight: 'normal',
  },
})
