import React from 'react'
import TaskRolePicker from '../components/TaskRolePicker'

export default function TaskRoleField({ field, form, ...props }) {
    const error = form.errors[field.name]
    const handleChange = value => form.setFieldValue(field.name, value)

    return <TaskRolePicker
        {...props}
        name={field.name}
        value={field.value}
        onChange={handleChange}
        error={error}
    />
}