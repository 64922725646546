import * as moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Hub } from 'aws-amplify'
import { useSelector } from 'react-redux'

import { isFeaturesAvailable } from '../feature/selectors'
import { currentProfile, currentOrganization } from '../auth/selectors'
import { useFeatureEnabled } from '../feature/hooks/'

import format from './format'

const { REACT_APP_ENV, REACT_APP_INTERCOM_APP_ID, REACT_APP_HUBSPOT_APP_ID } = process.env

const safeIntercomCall = (method, payload) => {
  window.Intercom && window.Intercom(method, payload)
}

const safeHubSpotCall = method => {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget[method] && window.HubSpotConversations.widget[method]()
  }
}

export const useChatWidget = () => {
  const featuresAvailable = useSelector(isFeaturesAvailable)
  const hubSpotConverstionsEnabled = useFeatureEnabled('HubSpotWidget')
  const profile = useSelector(currentProfile)
  const organization = useSelector(currentOrganization)

  const { user } = profile || {}

  const [hubSpotInitialized, setHubSpotInitialized] = useState(!!window.HubSpotConversations)

  const onConversationsAPIReady = useCallback(() => {
    setHubSpotInitialized(true)
  }, [])

  // Initialization
  useEffect(() => {
    if (featuresAvailable && !window.HubSpotConversations && !window.Intercom) {
      if (hubSpotConverstionsEnabled && REACT_APP_HUBSPOT_APP_ID) {
        window.hsConversationsSettings = {
          loadImmediately: false,
        }

        window.hsConversationsOnReady = [onConversationsAPIReady]

        if (!document.querySelector('#hs-script-loader')) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.id = 'hs-script-loader'
          script.async = true
          script.defer = true
          script.src = `//js-eu1.hs-scripts.com/${REACT_APP_HUBSPOT_APP_ID}.js`

          document.body.appendChild(script)
        }
      } else {
        // See integration instruction here https://www.intercom.com/help/en/articles/168-install-intercom-in-your-product-for-logged-in-users

        window.intercomSettings = {
          api_base: 'https://api-iam.intercom.io',
          app_id: REACT_APP_INTERCOM_APP_ID,
        }

        const script = document.createElement('script')
        script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/bedere7b';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
        document.body.appendChild(script)
      }

      Hub.listen('auth', ({ payload: { event } }) => {
        switch (event) {
          case 'signOut':
            if (hubSpotConverstionsEnabled) {
              window.HubSpotConversations.widget.remove()
              window.HubSpotConversations.clear()
              window.hsConversationsSettings = {}
            } else {
              window.Intercom('shutdown')
            }

            break
          default:
        }
      })
    }
  }, [featuresAvailable, hubSpotConverstionsEnabled, onConversationsAPIReady])

  useEffect(() => {
    if (!user || !featuresAvailable) {
      return
    }

    if (hubSpotInitialized) {
      const { email, id, hubspotToken } = user

      if (email && hubspotToken) {
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: hubspotToken,
        }

        // workaround for bug with user identification
        let _hsq = (window._hsq = window._hsq || [])
        _hsq.push([
          'identify',
          {
            email,
            id,
            firstName: user.givenName,
            lastName: user.familyName,
          },
        ])

        window.HubSpotConversations.widget.load()
      }
    } else if (window.Intercom) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: REACT_APP_INTERCOM_APP_ID,
        name: format.name(user),
        user_id: `${REACT_APP_ENV}:${user.id}`,
        created_at: moment(user.createdAt).unix(),
        user_hash: user.intercomHash,
        email: user.email,
        email_verified: user.emailVerified,
        organization: organization?.name,
      })
    }
  }, [featuresAvailable, hubSpotInitialized, onConversationsAPIReady, organization, user])

  return useMemo(
    () => ({
      hideWidget: () => {
        safeHubSpotCall('remove')
        safeIntercomCall('update', {
          hide_default_launcher: true,
        })
      },
      showWidget: () => {
        safeHubSpotCall('load')
        safeIntercomCall('update', {
          hide_default_launcher: false,
        })
      },
      // we need to call this every time a URL changes,
      // see https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
      pingWidget: () => safeIntercomCall('update', { last_request_at: parseInt(new Date().getTime() / 1000) }),
    }),
    [],
  )
}

export default useChatWidget
