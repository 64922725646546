import React, { useCallback } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { StyledKeyboardDatePicker } from 'components/StyledFormikDateTimePickers'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import StyledSelect, { MenuItem } from 'civic-champs-shared/formik/components/StyledSelect'
import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer'
import useCreateProgram from 'new-mentorship/hooks/useCreateProgram'
import useUpdateProgram from 'new-mentorship/hooks/useUpdateProgram'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

const formSchema = yup.object({
  name: yup.string().label('program name').required('A Program Name is required. Please provide a Program Name.'),
  description: yup.string().nullable().notRequired(),
  startsAt: yup
    .date()
    .typeError('The date provided is not a valid date format (MM/DD/YYYY).')
    .label('Start Date')
    .required('A Starting Date is required. Please provide the Starting Date.'),
  endsAt: yup.date().typeError('The date provided is not a valid date format (MM/DD/YYYY).').notRequired().nullable(),
  // .when('startsAt', (startsAt: any, yup: any) => startsAt && yup.min(startsAt, "Program can't end before start")),
})

export default function FillProgramBasicDataStep(props: any) {
  const classes = useModalStyles()
  const { onSuccess, program, cancel } = props
  const initialValues = {
    name: program?.name || '',
    description: program?.description || '',
    startsAt: program ? moment(program.startsAt) : null,
    endsAt: program?.endsAt ? moment(program.endsAt) : null,
    status: program?.status || '',
  }
  const [createProgram] = useCreateProgram()
  const [updateProgram] = useUpdateProgram()

  const handleSubmit = useCallback(
    event => {
      event.endsAt = event.endsAt === null ? null : moment(event.endsAt).toISOString()
      event.startsAt = moment(event.startsAt).toISOString()
      return (program ? updateProgram(program.id, event) : createProgram(event)).then(onSuccess)
    },
    [createProgram, onSuccess, program, updateProgram],
  )

  const handleFormikSubmit = useCallback(
    (values, { setSubmitting }) => {
      handleSubmit(values).finally(() => setSubmitting(false))
    },
    [handleSubmit],
  )
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleFormikSubmit}>
        {({ submitForm, isSubmitting, values }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field name="name" label="Program Name *" fullWidth component={StyledOutlinedTextField} />
              </Grid>
              <Grid item xs={6}>
                <Field
                  id="status"
                  name="status"
                  label={values.status ? 'Status *' : 'Select a Status *'}
                  labelId="status-label"
                  fullWidth
                  variant="outlined"
                  component={StyledSelect}
                >
                  <MenuItem value="draft">Draft</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="startsAt"
                  fullWidth
                  label={values.startsAt ? 'Start Date' : 'Enter a Starting Date *'}
                  component={StyledKeyboardDatePicker}
                  inputVariant="outlined"
                  format="MM/DD/YYYY"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="endsAt"
                  fullWidth
                  label={values.endsAt ? 'End Date' : 'Enter an End Date'}
                  component={StyledKeyboardDatePicker}
                  inputVariant="outlined"
                  format="MM/DD/YYYY"
                  clearable={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  label={values.description ? 'Description' : 'Program Description'}
                  placeholder="Description"
                  component={StyledOutlinedTextField}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              classes={{ root: classes.buttonsContainer }}
            >
              <OutlinedButton disabled={isSubmitting} onClick={cancel}>
                Cancel
              </OutlinedButton>
              <ContainedButton disabled={isSubmitting} onClick={submitForm}>
                {program ? 'Save' : 'Create New'} Program
              </ContainedButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}
