import * as moment from 'moment'
import * as _ from 'lodash'
import { isNumber, isString } from 'lodash'

export const buildTripOrderRow = (tripOrder, drivers) => {
  const pickupAt = moment(tripOrder.pickupAt)
  return {
    orderName: tripOrder.description,
    pickupTime: pickupAt.format('h:mm a'),
    date: pickupAt.format('MM/DD/YYYY'),
    tripOrderCount: null,
    assignedDriverCount: null,
    driver: tripOrder.driver && `${tripOrder.driver.givenName} ${tripOrder.driver.familyName}`,
    status: tripOrder.tripOrderStatus,
    actions: tripOrder.driver ? '' : 'Assign Driver',
    pickupAt: pickupAt,
    data: tripOrder,
    drivers,
  }
}

export const buildTripRequestRow = tripRequest => {
  let earliestDate = moment.min(..._.map(tripRequest.tripOrders, tripOrder => moment(tripOrder.pickupAt)))
  let minDate = earliestDate.format('MM/DD/YYYY')
  let minPickupAt = earliestDate.format('h:mm a')
  let latestDate = moment.max(..._.map(tripRequest.tripOrders, tripOrder => moment(tripOrder.pickupAt)))
  let maxDate = latestDate.format('MM/DD/YYYY')
  let maxPickupAt = latestDate.format('h:mm a')
  const pickupTime = minPickupAt === maxPickupAt ? minPickupAt : `${minPickupAt} - ${maxPickupAt}`
  const date = moment(minDate).isSame(maxDate) ? minDate : `${minDate} - ${maxDate}`
  const assignedDriverCount = tripRequest.tripOrders.filter(tripOrder => tripOrder.driver).length
  const tripOrderCount = tripRequest.tripOrders.length
  return {
    orderName: `${tripRequest.rider.givenName} ${tripRequest.rider.familyName}`,
    pickupTime: pickupTime,
    date: date,
    tripOrderCount: tripOrderCount,
    assignedDriverCount: assignedDriverCount,
    driver: null,
    status: tripRequest.tripRequestStatus,
    actions: tripOrderCount === assignedDriverCount ? null : 'Assign Drivers',
    data: tripRequest,
  }
}

// Reference for this fn and handling subRow filtering https://codesandbox.io/s/react-table-expanded-global-filter-1-kibuy?file=/src/helpers.js
export const tripPlannerFilter = (rows, columnIds, filterValue, options) => {
  // Do not filter
  if (!filterValue) {
    return rows
  }
  // In case of a complex filter, the parent component should provide the filter logic
  if (typeof filterValue !== 'string') {
    return []
  }
  const textSearchValues = filterValue.trim().toLocaleLowerCase()
  const arraySearchValues = textSearchValues.split(' ') // Transform it to array of separate words
  return rows.filter(row => {
    // If the id exists in options then the parent was filtred
    // And we want to display the children as well
    if (options && Array.isArray(options.filteredIds) && options.filteredIds.some(fid => row.id.startsWith(fid))) {
      options.filteredIds.push(row.id)
      return true
    }
    const { original } = row
    // The default filter will consider only the basic data format like number and string
    // The parent component should provide the filter method if the data is complex (like Arrays and Objects)
    // Or the column structure contains customized cells. (See the cell property of react table columns)
    // The line below will use only the considered columns ids (see disableGlobalFilter in the column definition)
    // It will filter the value of the displayed row object
    // Then add all the elements to a string separated by space
    // And an array of separate lowercase words without empty strings
    const textValues = columnIds
      .map(col => typeof original[col] === 'string' && original[col].split(' ').join(''))
      .filter(v => isString(v) || isNumber(v))
      .join(' ')
      .replace(/\s+/g, ' ')
      .toLocaleLowerCase()
      .split(' ')
    // Return whether the filter values exist in the columns or not
    // This method in the line below will do the same, however repeating the same word multiple times will meet always the condition
    // const exist = arraySearchValues.every((str) => textValues.includes(str));
    const exist = textValues.filter(str => str.includes(arraySearchValues.join(''))).length > 0

    // Check if there subRows that match the filter then return the parent level
    const nestedExist =
      exist || (Array.isArray(row.subRows) && !!tripPlannerFilter(row.subRows, columnIds, filterValue, options).length)

    // If this row matchs (not the nestedExist) the filter then add its id to filtred Ids
    if (exist && options && Array.isArray(options.filteredIds)) {
      options.filteredIds.push(row.id)
    }
    return nestedExist
  })
}

export const preprocessTripRows = (trips, drivers) =>
  trips.map(tripRequest => {
    const subRows = tripRequest.tripOrders.map(tripOrder => buildTripOrderRow(tripOrder, drivers))
    return {
      ...buildTripRequestRow(tripRequest),
      subRows: subRows.sort((a, b) => +new Date(a.pickupAt) - +new Date(b.pickupAt)),
    }
  })
