import React from 'react'

import FeaturesLoadedGate from "core/feature/components/FeaturesLoadedGate"
import AuthInitializedGate from "auth/components/AuthInitializedGate"

export default function InitializationGate({ children }) {
    return (
        <FeaturesLoadedGate>
            <AuthInitializedGate>
                    {children}
            </AuthInitializedGate>
        </FeaturesLoadedGate>
    )
}