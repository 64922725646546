import { filter } from 'lodash'
import React from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, Grid, Typography, Button, Box } from '@material-ui/core'


import Loading from 'components/Loading';
import BackLink from './BackLink'
import PagedTable from 'core/table/components/PagedTable'
import { DateTimeCell, CurrencyCell } from 'core/table/cells'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import CampaignDonationSummary from './CampaignDonationSummary'
import CampaignProperties from './CampaignProperties'
import CampaignDonationsTable from './CampaignDonationsTable'
import useCampaignDonations from '../hooks/useCampaignDonations'
import CampaignStatus from './CampaignStatus';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    simpleRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    editButton: {
        backgroundColor: '#FF6F3E',
        marginLeft: '10px',
    },
    editText: {
        color: 'white',
        padding: '5px',
    },
    status: {
        textAlign: 'right',
        marginRight: '10px',
    },
}));


export default function CampaignViewer(props) {
    const { campaign, campaignEventType, showMoreOrLess } = props
    const classes = useStyles()

    return (
        <div>
            <BackLink to="/campaigns" text="Back to Campaigns" />
            <Grid container spacing={3}>
                <Grid
                    item
                    container
                    spacing={1}
                    direction="row"
                    justify="space-between"
                    className={classes.row}
                >
                    <div className={classes.simpleRow}>
                        <Grid item>
                            <Typography variant="h4">
                                {campaign.name || 'Untitled Campaign'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={`/campaigns/${campaign.id}/edit`}
                                className={classes.editButton}
                            >
                                <div className={classes.editText}><b>Edit</b></div>
                            </Button>
                        </Grid>
                    </div>
                    <Grid item className={classes.status}>
                        <div><b>Status</b></div>
                        <CampaignStatus campaign={campaign} />
                    </Grid>
                </Grid>
                <CampaignProperties item xs={9} campaign={campaign} />
                <CampaignDonationsTable campaign={campaign} campaignEventType={campaignEventType} showMoreOrLess={showMoreOrLess} />
            </Grid>
        </div>
    )
}
