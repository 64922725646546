export const timezones = [
  { value: 'America/New_York', name: 'Eastern Time' },
  { value: 'America/Chicago', name: 'Central Time' },
  { value: 'America/Denver', name: 'Mountain Time' },
  { value: 'America/Phoenix', name: 'Mountain Standard Time' },
  { value: 'America/Los_Angeles', name: 'Pacific Time' },
  { value: 'America/Anchorage', name: 'Alaska Time' },
  { value: 'Pacific/Honolulu', name: 'Hawaii Time' },
  { value: 'America/Halifax', name: 'Atlantic Time' },
  { value: 'America/St_Johns', name: 'Newfoundland Time' },
]
