import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { ExportButton } from 'core/table/components'

const filename = 'list-of-events.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Start Date', key: 'startsAt' },
  { label: 'End Date', key: 'endsAt' },
  { label: 'Location', key: 'address' },
  { label: 'Visibility', key: 'visibility' },
]

const formatData = (opportunities: any[]) => {
  return opportunities.map(({ original: { startsAt, endsAt, ...opportunity } }) => {
    return {
      ...opportunity,
      startsAt: format.datetimeForExport(startsAt),
      endsAt: format.datetimeForExport(endsAt),
    }
  })
}
const ExportListOfEventsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)

  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={headers}
      disabled={disabled}
      filename={fileNameWithDate as string}
      type="popover"
    />
  )
}

export default ExportListOfEventsButton
