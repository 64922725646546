import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'

const useMigrateQuestionnaires = () => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored({
    successMessage: 'Migration was successful',
    errorMessage: 'Error happened during migration',
  })

  const migrateQuestionnaires = useCallback(() => {
    return request({
      method: 'post',
      url: `/organizations/${organization.id}/surveys/migrate`,
    })
  }, [organization.id, request])

  return [migrateQuestionnaires, status]
}

export default useMigrateQuestionnaires
