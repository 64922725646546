import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { NotificationBulkPayload } from 'notification/interfaces'

export const useBulkOnNotifications = (): [(payload: NotificationBulkPayload) => Promise<void>, Status<void>] => {
  const [request, status] = useFetchRefactored<void>()

  const sendBulk = useCallback(
    (payload: NotificationBulkPayload) => {
      return request({
        method: 'put',
        url: `/notifications/bulk`,
        config: {
          body: payload,
        },
      })
    },
    [request],
  )

  return [sendBulk, status]
}

export default useBulkOnNotifications
