import { uniqueId, isObject } from 'lodash';
import React, { useState, useCallback, useMemo } from 'react';
import './react-dates-overrides.css';
import { DateRangePicker } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { ANCHOR_RIGHT } from 'react-dates/src/constants';
import { makeStyles } from '@material-ui/core/styles';

import DateRangePresetSelector from './DateRangePresetSelector';
import { getDateRangeFromPreset, getPresetFromDateRange } from './DateRangePresets';

const useStyles = makeStyles(theme => ({
    container: {
        flex: '1',
        textAlign: 'right'
    }
}));

function DateRangeFilter(props) {
    const classes = useStyles();
    const { initialValues, onFilterApplied } = props;
    const [currentStartMonth, setCurrentStartMonth] = useState(null);

    const [focusedInput, setFocusedInput] = useState(null);
    const [startDateId, endDateId] = useMemo(() => [uniqueId('start-date-'), uniqueId('end-date-')], []);
    
    const [appliedValues, setAppliedValues] = useState(() => ({ 
        ...initialValues, 
        preset: getPresetFromDateRange(initialValues)
    }));
    const [selectedValues, setSelectedValues] = useState(appliedValues);

    const initializeSelectedValues = useCallback(() => {
        setSelectedValues(appliedValues);
    }, [setSelectedValues, appliedValues]);

    const onSelectedValuesChanged = useCallback((dates, preset) => {
        let selectedValues = {
            startDate: dates.startDate.clone().startOf('day'), //TODO? consider a small type-- clone() operation of its own
            endDate: dates.endDate.clone().endOf('day'),
            preset: preset
        };
        setSelectedValues(selectedValues);
    }, [setSelectedValues]);

    const onSelectedDatesChanged = useCallback((dates) => {
        if ( isObject(dates) && dates.startDate && dates.endDate ) {  //TODO? consider a small type-- could have static isRange() validator
            
            let selectedPreset = getPresetFromDateRange(dates);
            onSelectedValuesChanged(dates, selectedPreset);

            let nextFocusedInput = focusedInput === START_DATE ? END_DATE : START_DATE;
            setFocusedInput(nextFocusedInput);
        }
    }, [onSelectedValuesChanged, focusedInput]);

    const onSelectedPresetChanged = useCallback((e) => {
        let selectedPreset = e.target.value;
        let dates = getDateRangeFromPreset(selectedPreset);

        onSelectedValuesChanged(dates, selectedPreset);
        
        // Update 'key' for date picker to force a refresh
        setCurrentStartMonth(dates.startDate.format('MM-YYYY'));
    }, [onSelectedValuesChanged, setCurrentStartMonth]);

    const onApplyClick = useCallback(() => {
        setAppliedValues(selectedValues);
        onFilterApplied(selectedValues);
        setFocusedInput(null);
    }, [selectedValues, onFilterApplied]);

    const PresetPanel = useCallback(() => {
        let { startDate: selectedStart, endDate: selectedEnd } = selectedValues;
        let { startDate: appliedStart, endDate: appliedEnd } = appliedValues;

        let canApply = (selectedStart && selectedEnd && appliedStart && appliedEnd) && 
            (
                !selectedStart.isSame(appliedStart, 'day') || 
                !selectedEnd.isSame(appliedEnd, 'day')
            );

        return <DateRangePresetSelector 
            selectedPreset={selectedValues.preset}
            onPresetChange={onSelectedPresetChanged}
            canApply={canApply}
            onApply={onApplyClick}
        />;
    }, [onApplyClick, onSelectedPresetChanged, selectedValues, appliedValues]);

    const isOutsideRange = () =>{
        return false;
    };

    return (
        <div className={classes.container}>
            <DateRangePicker
                key={currentStartMonth}
                startDateId={startDateId}
                startDate={selectedValues.startDate}
                endDateId={endDateId}
                endDate={selectedValues.endDate}
                focusedInput={focusedInput}
                anchorDirection={props.anchorDirection || ANCHOR_RIGHT}
                calendarInfoPosition="after"
                renderCalendarInfo={PresetPanel}
                keepOpenOnDateSelect={true}
                keepFocusOnInput={true}
                customArrowIcon={<span>-</span>}
                enableOutsideDays={true}
                isOutsideRange={isOutsideRange}
                onDatesChange={onSelectedDatesChanged}
                onFocusChange={setFocusedInput}
                onClose={initializeSelectedValues}
                displayFormat="M/D/YYYY"
                minimumNights={0}
                hideKeyboardShortcutsPanel={true}
                noBorder={props.noBorder || false}
            />
        </div>
    );
}

export default DateRangeFilter;
