import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import './index.scss'
import { OpportunityVisibility } from '../../../../interfaces'
import { Visibility } from './Visibility'
import MultiStepNavButton from '../navigation-button-area'

import { INIT_VALIDATOR } from '../../../../helpers/validation'

const ParticipantsModule = props => {
  const { onNextClick, onPrevClick, onSaveDraftClick, isSendingDraftRequest, event, setEventField } = props
  const { published, visibility } = event

  const { errorMessages, onChangeErrorMessage } = INIT_VALIDATOR({ visibilityGroups: '' })

  const prevClick = () => {
    onPrevClick()
  }

  const validateVisibilityGroup = (values) => {
    if (visibility !== OpportunityVisibility.SELECT_GROUPS_ONLY || values.length) {
      onChangeErrorMessage('visibilityGroups', '')
      return true;
    }

    onChangeErrorMessage('visibilityGroups', 'The visibility group field can\'t be empty')
    return false;
  }

  const nextClick = () => {
    if (validateVisibilityGroup(event.visibilityGroups)) {
      onNextClick()
    }
  }

  return (
    <div className="participants-module">
      <h2 className="participants-module__title">Calendar and Check In Visibility</h2>
      <Box style={{ marginBottom: '20px' }}>
        <Visibility
          visibility={visibility}
          setEventField={setEventField}
          visibilityGroups={event.visibilityGroups}
          onChange={values => validateVisibilityGroup(values)}
          hasError={!!errorMessages.visibilityGroups}
          errorMessage={errorMessages.visibilityGroups}
        />
      </Box>
      <MultiStepNavButton
        isShowDraftButton={!published}
        draftButtonText="Update Draft"
        onNextClick={nextClick}
        onPrevClick={prevClick}
        onSaveDraftClick={onSaveDraftClick}
        isSendingRequest={isSendingDraftRequest}
      />
    </div>
  )
}

ParticipantsModule.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  isSendingDraftRequest: PropTypes.bool,
  event: PropTypes.object,
}

export default ParticipantsModule
