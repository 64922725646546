import React, { useMemo } from 'react'
import { MenuItem } from '@material-ui/core'

import SelectList from 'components/select/SelectList'
import { useQuery } from 'civic-champs-shared/api/hooks'
import { GET_VOLUNTEERS } from 'volunteering/volunteers/queries'

const useVolunteers = (organization, reporting) => {
  const params = useMemo(
    () => ({
      variables: {
        filter: Object.assign(
          { organizationId: organization.id },
          typeof reporting !== 'undefined' ? { reporting } : null,
        ),
      },
    }),
    [organization, reporting],
  )
  return useQuery(GET_VOLUNTEERS, params)
}

export default function VolunteerSelector(props) {
  const { organization, value, onChange, required, disabled, reporting } = props

  const { data: volunteers, loading } = useVolunteers(organization, reporting)

  let sortedVolunteers = useMemo(() => {
    return (volunteers || [])
      .map(({ user: { cognitoSub, familyName, givenName } }) => ({ cognitoSub, name: `${familyName}, ${givenName}` }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [volunteers])

  return (
    <SelectList
      id="volunteer"
      name="volunteer"
      label="Volunteer"
      items={sortedVolunteers}
      value={value}
      getItemRenderer={i => (
        <MenuItem key={i.cognitoSub} value={i.cognitoSub}>
          {i.name}
        </MenuItem>
      )}
      onChange={value => onChange?.(sortedVolunteers.find(({ cognitoSub }) => cognitoSub === value))}
      required={required}
      disabled={disabled || loading}
    />
  )
}
