import React from 'react'
import Loading from 'components/Loading'
import QuestionSetCreation from '../components/question-set-creation'
import { useExistingQuestions, useQuestionSet, useEditQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import BackLink from '../../donation/campaigns/components/BackLink'

export const QuestionSetEdit = props => {
  const questionSetId = props.match.params.id
  const { existingQuestions, loading: existingQuestionsLoading } = useExistingQuestions()
  const { questionSet, loading: fetchLoading } = useQuestionSet(questionSetId)
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet(updatedQuestionSet =>
    props.history.push(`/questionnaires/${updatedQuestionSet.questionSetId}`),
  )
  const loading = fetchLoading || editLoading || existingQuestionsLoading

  if (loading) {
    return <Loading />
  }

  if (!questionSet) {
    return <div>No questionnaire exists with this id</div>
  }

  return (
    <>
      <BackLink to={`/questionnaires/${questionSet.questionSetId}`} text={`Back to ${questionSet.name}`} />
      <QuestionSetCreation loading={loading} onSubmit={editQuestionSet} questionSet={questionSet} existingQuestions={existingQuestions} />
    </>
  )
}
