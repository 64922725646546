import React, { useCallback, useRef, useEffect, useState } from 'react'
import { Button, Typography, Grid, Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReactGoogleMap from 'components/ReactGoogleMap'
import InputComponent from 'Event/components/input/index'
import { roundMetersTo5Feet, MIN_RADIUS_IN_METER, MAX_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import { getDisplayAddressFromEvent, parseGoogleResponse } from 'civic-champs-shared/core/location/utils'

const useStyles = makeStyles(() => ({
  locationMap: {
    width: '100%',
    height: '360px',
  },
  addressRow: {
    marginBottom: '32px',
  },
  buttonRow: {
    marginTop: '32px',
  },
}))

const LocationStep = props => {
  const { goToNextStep, goToPrevStep, formikProps } = props
  const { values, setFieldValue } = formikProps
  const { address, city, state, zip, geofencing } = values
  const disabled = !address || !city || !state
  const [displayAddress, setDisplayAddress] = useState(() => getDisplayAddressFromEvent({ address, city, state, zip }))
  const classes = useStyles()
  const changeCoordinates = useCallback(
    coordinates => {
      setFieldValue('geofencing', { ...geofencing, location: { ...geofencing.location, coordinates } })
    },
    [setFieldValue, geofencing],
  )

  const handleRadiusChange = useCallback(
    (e, radius) => {
      setFieldValue('geofencing', { ...geofencing, radius })
    },
    [setFieldValue, geofencing],
  )

  const addressRef = useRef(null)

  const handleAddressChange = address => {
    setFieldValue('address', address)
    setDisplayAddress(address)
    // this gets called as the user types or when they hit the clear button
    // either way, we want to wipe away the old city, state, zip until they have selected a location from the dropdown
    // in which case, handlePlaceSelect will get called
    setFieldValue('city', '')
    setFieldValue('state', '')
    setFieldValue('zip', '')
  }

  const handlePlaceSelect = useCallback(
    autocomplete => () => {
      const {
        displayName,
        address: { line1, city, state, zipCode },
        coordinates,
      } = parseGoogleResponse(autocomplete)
      if (address && (coordinates.lat !== 0 || coordinates.lng !== 0)) {
        setFieldValue('address', line1)
        setFieldValue('city', city)
        setFieldValue('state', state)
        setFieldValue('zip', zipCode)
        setDisplayAddress(displayName)
        changeCoordinates(coordinates)
      }
    },
    [address, setFieldValue, changeCoordinates],
  )

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
      types: ['geocode'],
    })
    autocomplete.setComponentRestrictions({
      country: ['us'],
    })

    const listener = autocomplete.addListener('place_changed', () => {
      handlePlaceSelect(autocomplete)()
    })

    return () => {
      window.google.maps.event.removeListener(listener)
      window.google.maps.event.clearInstanceListeners(autocomplete)
    }
  }, [handlePlaceSelect, addressRef])

  return (
    <Grid container direction="column">
      <Grid container direction="row" spacing={2}>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <div>
              <InputComponent
                inputRef={addressRef}
                type="search"
                value={displayAddress}
                onChange={handleAddressChange}
                label="Address*"
                placeholder="Street Address, City, State, Zip"
              />
            </div>
            <Typography id="radius-slider">Radius</Typography>
            <Slider
              value={geofencing.radius}
              onChange={handleRadiusChange}
              aria-labelledby="radius-slider"
              min={MIN_RADIUS_IN_METER}
              max={MAX_RADIUS_IN_METER}
            />
            <Typography>approx. {roundMetersTo5Feet(geofencing.radius)} feet</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.locationMap}>
            <ReactGoogleMap
              zoom={17}
              geofenceRadius={geofencing.radius}
              coordinates={geofencing.location.coordinates}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2} className={classes.buttonRow}>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={goToPrevStep}>
            Go Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" disabled={disabled} onClick={goToNextStep}>
            Next Step
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationStep
