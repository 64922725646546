import React, { useMemo } from 'react'

import { NumberCell, CurrencyCell } from 'core/table/cells'
import SummaryCards from 'core/table/components/SummaryCards'
import useDonationsSummary from 'donation/hooks/useDonationsSummary'
import { map } from 'lodash'

const cards = [
  {
    Header: 'Donors',
    accessor: 'donorCount',
    Cell: NumberCell
  },
  {
    Header: 'Total Collected',
    accessor: 'total',
    Cell: CurrencyCell
  },
  {
    Header: 'Recurring Donations',
    accessor: 'recurringCount',
    Cell: NumberCell
  }, 
  {
    Header: 'Total Recurring',
    accessor: 'recurringTotal',
    Cell: CurrencyCell
  },
  {
    Header: 'Average Donation Amount',
    accessor: 'average',
    Cell: CurrencyCell
  }
]

export default function DonationsSummaryCards(props) {
    //TODO this is getting duplicated
    const { rows } = props
    const donations = useMemo(
      () => props.donations || map(rows, r => r.original), 
      [props.donations, rows]
    )

    const summary = useDonationsSummary(donations)

    return <SummaryCards 
        cards={cards}
        summary={summary}
    />
}