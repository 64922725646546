import { makeStyles, Theme } from '@material-ui/core/styles'

export const useAddOrEditActivityPromptStyle = makeStyles<Theme, { forSignups?: boolean }>(() => ({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: ({ forSignups = false }) => (forSignups ? '400px' : '700px'),
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  labelStyle: {
    fontSize: '1.2em',
    fontWeight: 600,
    display: 'inline',
  },
  textStyle: {
    fontSize: '1.2em',
    display: 'inline',
    paddingLeft: '5px',
  },
  rowContent: {
    padding: '1em 0.25em',
    '& div[class*="-inputWrapper-"]': {
      background: '#FFFFFF',
    },
  },
  rowContentCentered: {
    padding: '0.1em 1em',
    alignContent: 'center',
  },
  input: {
    color: '#191C1D',
    background: '#FFFFFF',
  },
  submitButton: {
    backgroundColor: '#5C8DE8',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actions: {
    justifyContent: 'space-between',
  },
}))
