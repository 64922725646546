import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useSelector } from 'react-redux'
import { getEncodedOccurrence } from 'kiosk/selectors'

const useGetUnsignedWaivers = () => {
    const [request, status] = useFetch()

    const encodedOccurrence = useSelector(getEncodedOccurrence)

    const getUnsignedWaivers = useCallback( (personId) => {
        return request({
            method: 'get',
            url: `/opportunity_instances/${encodedOccurrence}/unsigned_credentials`,
            config: {
                queryStringParameters: {
                    person_id: personId
                }
            }
        })
    }, [request, encodedOccurrence])

    return [getUnsignedWaivers, status]
}

export default useGetUnsignedWaivers
