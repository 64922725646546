import React, { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { TableInstance } from 'react-table'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import ListIcon from '@material-ui/icons/List'
import { ColumnSelectorModal } from 'core/table/components/ColumnSelectorModal'
import useSetColumnState from 'core/table/table-hooks/useSetColumnState'

export const ColumnSelector = ({ table, tableName }: { table: TableInstance; tableName?: string }) => {
  const showModal = useShowPrompt(ColumnSelectorModal)
  const [setColumnState] = useSetColumnState()
  const handleClick = useCallback(async () => {
    const columns: any[] = await showModal({ table })
    if (columns) {
      table.setHiddenColumns(columns.filter(({ checked }) => !checked).map(({ id }) => id))
      // @ts-ignore
      table.setColumnOrder(columns.map(({ id }) => id))
      tableName &&
        (await setColumnState(
          tableName,
          table.allColumns.map(({ id, isVisible }) => ({
            id,
            visible: isVisible,
          })),
        ))
    }
  }, [setColumnState, showModal, table, tableName])
  return (
    <TableMenuButton endIcon={<ListIcon />} onClick={handleClick}>
      Customize
    </TableMenuButton>
  )
}
