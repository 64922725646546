import { makeStyles } from '@material-ui/core/styles'

export const useNotificationSettingsStyles = makeStyles({
  container: {
    width: '335px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    backgroundColor: '#F8FAFF',
  },
  title: {
    cursor: 'move',
    lineHeight: '36px',
    position: 'relative',
    '& > h2': {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
      margin: 0,
    },
  },
  close: {
    fontSize: '20px',
    cursor: 'pointer',
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  content: {
    padding: '15px 0 0 0',
    display: 'flex',
    flexDirection: 'column',
  },
  general: {
    display: 'flex',
    gap: '6px',
    marginBottom: '18px',
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
      '&$bold': {
        fontWeight: 700,
      },
    },
  },
  bold: {},
  selectors: {
    border: '1px solid #74777F',
    borderRadius: '4px',
    background: 'white',
    padding: '20px',
    gap: '14px',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
    },
  },
  header: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  left: {
    minWidth: '175px',
  },
  separator: {
    width: '100%',
    borderBottom: '1px solid #8E9192',
  },
  row: {
    display: 'flex',
    '& > div': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
    },
  },
  switch: {
    display: 'flex',
    gap: '6px',
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
    },
    '&$bigger > span': {
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&$bigger > span:nth-child(1)': {
      position: 'relative',
      top: '2px',
    },
  },
  bigger: {},
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '18px',
    marginTop: '18px',
  },
})

export default useNotificationSettingsStyles
