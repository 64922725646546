import format from 'civic-champs-shared/utils/format'

export const GET_OPPORTUNITIES = {
  request: ({ filter }) => {
    let url = `/organizations/${filter.organizationId}/opportunities?`

    const reporting = typeof filter.reporting !== 'undefined' ? filter.reporting : true

    url += `reporting=${reporting}`

    if (filter.startDate) {
      const encodedStartDate = encodeURIComponent(format.dateTimeForUrl(filter.startDate))

      url += `&startDate=${encodedStartDate}`
    }

    if (filter.endDate) {
      const encodedEndDate = encodeURIComponent(format.dateTimeForUrl(filter.endDate))

      url += `&endDate=${encodedEndDate}`
    }

    return {
      url: url,
    }
  },
  caching: {
    maxAge: '1 hour',
    staleAfter: '1 minute',
  },
  update: (queryConfig, state, result) => ({
    ...state,
    opportunities: result,
  }),
  select: (queryConfig, state) => state.opportunities,
}

export const GET_KIOSK_OPPORTUNITIES = {
  request: ({ filter }) => {
    const { organizationId, ...queryStringParameters } = filter

    return {
      url: `/organizations/${organizationId}/opportunities`,
      config: { queryStringParameters },
    }
  },
  caching: {
    maxAge: '1 hour',
    staleAfter: '1 minute',
  },
  update: (queryConfig, state, result) => ({
    ...state,
    opportunities: result,
  }),
  select: (queryConfig, state) => state.opportunities,
}
