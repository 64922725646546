import React from 'react'
import { CellProps } from 'react-table'
import { Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { ReactComponent as ErrorIcon } from 'images/error.svg'
import { ReactComponent as SuccessIcon } from 'images/verified.svg'

import { NotificationBlastSummary } from '../types'

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    border: '1px #E0E3E3 solid',
    padding: '8px',
    display: 'block',
  },
  tooltipHeader: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  arrow: {
    color: '#fff',
  },
  errorIcon: {
    verticalAlign: 'middle',
  },
  successIcon: {
    color: 'orange',
    verticalAlign: 'middle',
  },
  tooltipText: {
    paddingTop: '10px',
  },
})

export const MessageStatusTooltip = ({
  failedMessagesCount,
  blockedMessagesCount,
  succeededMessagesCount,
  stillSendingMessagesCount,
  children,
}: any) => {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        <div>
          <Typography className={classes.tooltipHeader}>Status Information</Typography>
          <div className={classes.tooltipText}>
            {succeededMessagesCount} Messages Sent Succesfully <SuccessIcon className={classes.successIcon} />
          </div>
          {failedMessagesCount > 0 && (
            <div className={classes.tooltipText}>
              {failedMessagesCount} Messages Failed <ErrorIcon className={classes.errorIcon} />
            </div>
          )}
          {blockedMessagesCount > 0 && (
            <div className={classes.tooltipText}>
              {blockedMessagesCount} Messages Blocked <ErrorIcon className={classes.errorIcon} />
            </div>
          )}
          {stillSendingMessagesCount > 0 && (
            <div className={classes.tooltipText}>
              {stillSendingMessagesCount} Messages still sending <ErrorIcon className={classes.errorIcon} />
            </div>
          )}
        </div>
      }
      placement="left-start"
      arrow
      interactive
    >
      {children}
    </Tooltip>
  )
}

export const MessageStatusCell = ({ cell }: CellProps<NotificationBlastSummary>) => {
  const {
    emailCount,
    smsCount,
    failedEmailEvents,
    failedSMSEvents,
    blockedEmails,
    blockedSMSs,
    emailsStillSending,
    smsStillSending,
    createdAt,
  } = cell.row.original

  const classes = useStyles()

  const stillSendingMessagesCount = emailsStillSending.length || smsStillSending.length
  if (stillSendingMessagesCount && moment.duration(moment().diff(createdAt)).asHours() <= 24) {
    return <div>Sending</div>
  }

  const failedMessagesCount = failedEmailEvents.length + failedSMSEvents.length
  const blockedMessagesCount = blockedEmails.length + blockedSMSs.length
  const unsuccessfulMessagesCount = failedMessagesCount + blockedMessagesCount + stillSendingMessagesCount
  const totalMessagesCount = emailCount + smsCount

  const succeededMessagesCount = totalMessagesCount - unsuccessfulMessagesCount

  return unsuccessfulMessagesCount === 0 ? (
    <div>
      <SuccessIcon className={classes.successIcon} /> Sent
    </div>
  ) : (
    <MessageStatusTooltip
      failedMessagesCount={failedMessagesCount}
      succeededMessagesCount={succeededMessagesCount}
      blockedMessagesCount={blockedMessagesCount}
      stillSendingMessagesCount={stillSendingMessagesCount}
    >
      <div>
        <ErrorIcon className={classes.errorIcon} />{' '}
        {succeededMessagesCount === 0
          ? 'Failed'
          : `Mixed (${unsuccessfulMessagesCount} Failure${unsuccessfulMessagesCount === 1 ? '' : 's'})`}
      </div>
    </MessageStatusTooltip>
  )
}
