import React, { useCallback, useMemo, useState } from 'react'
import yup from 'civic-champs-shared/utils/yup'
import _ from 'lodash'
import moment from 'moment'
import { Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import useCreateNonSchedulableOpportunity from '../hooks/useCreateNonSchedulableOpportunity'
import { useCurrentOrg, useIsTester } from 'auth/hooks'
import { makeStyles } from '@material-ui/core/styles'
import BasicInfoStep from './BasicInfoStep'
import LocationStep from './LocationStep'
import TimeStep from './TimeStep'
import useUpdateNonSchedulableOpportunity from '../hooks/useUpdateNonSchedulableOpportunity'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import { MIN_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import { AssociationType } from 'Event/interfaces/interfaceCreateEditEvent'
import { mapToEventGroup } from 'Event/scenes/edit-event'
import { mapGroupsPayload } from 'Event/scenes/events/helpers/mapEventPayload'
import VisibilityStep from './VisiblityStep'
import GroupMembershipStep from './GroupMembershipStep'
import Loading from 'civic-champs-shared/core/Loading'
import { OpportunityVisibility } from 'Event/interfaces'
import { useOpportunityPersonGroups } from '../hooks/useOpportunityPersonGroups'
import { useMount } from 'react-use'
import { useHistory } from 'react-router'
import { useFeatureEnabled } from 'core/feature/hooks'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { mapGeofencingToEventGeofencing } from 'utils/event'

const associatedGroupSchema = yup.object({
  groupId: yup.number().integer().positive(),
  name: yup.string(),
  associationType: yup.string().oneOf(Object.values(AssociationType)),
  approvedMembersOnly: yup.boolean(),
  closed: yup.boolean(),
})

const formSchema = (isUpdate, minRadius) =>
  yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string().nullable().notRequired(),
    // TODO: we should change this to make it required, but would require re-writing how we do validation in this component
    // needs to be broken down into multiple small forms
    address: yup.string().min(1).nullable(isUpdate).notRequired(),
    city: yup.string().min(1).nullable(isUpdate).notRequired(),
    state: yup.string().min(1).nullable(isUpdate).notRequired(),
    zip: yup.string().min(5).nullable(isUpdate).notRequired(),
    startsAt: yup.string().notRequired(),
    endsAt: yup.string().notRequired(),
    geofencing: yup
      .object({
        coordinates: yup.array(yup.number()),
        radius: yup.number().min(minRadius).required(),
      })
      .notRequired(),
    visibility: yup
      .string()
      .oneOf(Object.values(OpportunityVisibility).filter(option => option !== OpportunityVisibility.PRIVATE)),
    visibilityGroups: yup.array().of(associatedGroupSchema),
    onboardingGroups: yup.array().of(associatedGroupSchema),
    isTest: yup.boolean().nullable().notRequired(),
  })

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    fontSize: '1.5em',
  },
  errorText: {
    color: theme.palette.danger.main,
  },
}))

const initialBlankValues = timezone => ({
  name: '',
  description: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  startsAt: moment().startOf('hour').format('YYYY-MM-DDTHH:mm'),
  endsAt: moment().add(1, 'hour').startOf('hour').format('YYYY-MM-DDTHH:mm'),
  geofencing: DEFAULT_GEOFENCING,
  visibilityGroups: [],
  onboardingGroups: [],
  visibility: 'public',
  isTest: false,
})

const useOpportunityFlowValues = opportunity => {
  const encodedOccurrence = opportunity && opportunity.id
  const organization = useCurrentOrg()
  const timeZone = _.get(organization, 'timeZone', 'America/New_York')
  const [, { result: groups, loading }] = useOpportunityPersonGroups(encodedOccurrence)
  return useMemo(() => {
    const groupsByAssociationType = _.groupBy(groups, 'associationType')
    if (encodedOccurrence) {
      return {
        encodedOccurrence,
        isUpdate: true,
        timeZone,
        loading,
        organization,
        isTest: opportunity.isTest,
        isSchedulable: opportunity.schedulable,
        initialValues: {
          ...opportunity,
          startsAt: moment(opportunity.startsAt),
          endsAt: moment(opportunity.endsAt),
          geofencing: {
            ...opportunity.geofencing,
            location: {
              ...opportunity.geofencing.location,
              coordinates: opportunity.geofencing.location.coordinates,
            },
          },
          visibilityGroups: _.get(groupsByAssociationType, AssociationType.EVENT_PRIVATE_TO_MEMBERS, []).map(
            mapToEventGroup,
          ),
          onboardingGroups: _.get(groupsByAssociationType, AssociationType.ADD_PARTICIPANTS_TO_GROUP, []).map(
            mapToEventGroup,
          ),
        },
      }
    } else {
      return {
        encodedOccurrence,
        timeZone,
        organization,
        loading: false,
        isUpdate: false,
        isSchedulable: false,
        initialValues: initialBlankValues(timeZone),
      }
    }
  }, [groups, encodedOccurrence, timeZone, loading, organization, opportunity])
}

let useHandleFormikSubmit = ({ complete, isUpdate, timeZone, organization, encodedOccurrence }) => {
  const errorNotification = useErrorNotification()
  const [createOpportunity] = useCreateNonSchedulableOpportunity(organization.id)
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity()
  return useCallback(
    async (values, { setSubmitting }) => {
      try {
        const startsAt = moment(values.startsAt, 'YYYY-MM-DDTHH:mm').tz(timeZone).utc().toISOString()
        const endsAt = moment(values.endsAt, 'YYYY-MM-DDTHH:mm').tz(timeZone).utc().toISOString()

        const geofencing = {
          ...values.geofencing,
          location: {
            ...values.geofencing.location,
            coordinates: values.geofencing.location.coordinates,
          },
        }
        const payload = {
          ..._.pick(values, [
            'name',
            'description',
            'address',
            'city',
            'state',
            'zip',
            'occurrenceId',
            'visibility',
            'isTest',
          ]),
          groups: mapGroupsPayload(values),
          startsAt,
          endsAt,
          geofencing: mapGeofencingToEventGeofencing(geofencing),
          encodedOccurrence: values.id,
        }
        if (isUpdate) {
          await updateOpportunity(payload, encodedOccurrence)
        } else {
          await createOpportunity(payload)
        }
        setSubmitting(false)
        complete()
      } catch (err) {
        errorNotification('There was a problem saving the opportunity. Please try again.', err.response.data)
      }
    },
    [timeZone, isUpdate, complete, updateOpportunity, encodedOccurrence, createOpportunity, errorNotification],
  )
}

export default function OpportunityFlow(props) {
  const { opportunity, complete } = props
  const { encodedOccurrence, timeZone, organization, loading, isSchedulable, initialValues, isUpdate } =
    useOpportunityFlowValues(opportunity)
  const handleFormikSubmit = useHandleFormikSubmit({ complete, isUpdate, timeZone, organization, encodedOccurrence })
  const groupsEnabled = useFeatureEnabled('Groups')
  const [activeStep, setActiveStep] = useState(0)
  const history = useHistory()
  const classes = useStyles()
  const { isTester, loading: testerLoading } = useIsTester()

  const validationSchema = useMemo(() => {
    const minRadius = isUpdate
      ? _.min([_.get(opportunity, 'geofencing.radius', MIN_RADIUS_IN_METER), MIN_RADIUS_IN_METER])
      : MIN_RADIUS_IN_METER
    return formSchema(isUpdate, minRadius)
  }, [opportunity, isUpdate])

  useMount(() => {
    if (isSchedulable) {
      history.push(`/events/${encodedOccurrence}/edit-event/basic`)
    }
  })

  if (loading || testerLoading) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormikSubmit}
      enableReinitialize
    >
      {formikProps => {
        const { errors, values, setFieldValue, setErrors, setTouched } = formikProps
        const goToNextStep = () => {
          if (activeStep === 0) {
            setTouched({ name: true })
          }
          if (_.isEmpty(errors)) {
            setActiveStep(activeStep + 1)
          }
        }

        const goToPrevStep = () => {
          setErrors(_.omit(errors, ['api']))
          setActiveStep(activeStep - 1)
        }

        return (
          <div>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>
                  <Typography className={classes.sectionLabel}>Opportunity Info</Typography>
                </StepLabel>
                <StepContent>
                  <BasicInfoStep
                    goToNextStep={goToNextStep}
                    isTest={values.isTest}
                    setFieldValue={setFieldValue}
                    isTester={isTester}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.sectionLabel}>Location Info</Typography>
                </StepLabel>
                <StepContent>
                  <LocationStep formikProps={formikProps} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.sectionLabel}>Opportunity Time</Typography>
                </StepLabel>
                <StepContent>
                  <TimeStep goToPrevStep={goToPrevStep} goToNextStep={goToNextStep} startsAt={values.startsAt} />
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.sectionLabel}>Visibility</Typography>
                </StepLabel>
                <StepContent>
                  <VisibilityStep
                    formikProps={formikProps}
                    goToPrevStep={goToPrevStep}
                    goToNextStep={goToNextStep}
                    isUpdate={isUpdate}
                    isLastStep={!groupsEnabled}
                  />
                </StepContent>
              </Step>
              {groupsEnabled && (
                <Step>
                  <StepLabel>
                    <Typography className={classes.sectionLabel}>Associated Groups</Typography>
                  </StepLabel>
                  <StepContent>
                    <GroupMembershipStep
                      goToPrevStep={goToPrevStep}
                      formikProps={formikProps}
                      onChange={val => setFieldValue('onboardingGroups', val)}
                      isUpdate={isUpdate}
                    />
                  </StepContent>
                </Step>
              )}
            </Stepper>
            {_.keys(errors).map(key => (
              <p key={key} className={classes.errorText}>
                {JSON.stringify(errors[key])}
              </p>
            ))}
          </div>
        )
      }}
    </Formik>
  )
}
