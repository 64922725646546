import React from 'react'
import { CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default function AsyncAutocomplete(props) {
    const { label, loading, ...otherProps } = props
    return (
        <Autocomplete
            {...otherProps}
            loadingText='...Loading'
            renderInput={(params) => 
                <TextField
                    {...params}
                    label={label}
                    variant='outlined'
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress />
                                ): null}
                            </>
                        )
                    }}
                />
            }
        />
    )
}