import { fork, takeEvery, select, call, put } from 'redux-saga/effects'
import * as _ from 'lodash'
import getErrorMessage from '../../utils/getErrorMessage'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import { getOpportunity, getEncodedOccurrence } from '../selectors'
import {
  CHECK_IN,
  CHECK_OUT,
  checkInSucceeded,
  checkInFailed,
  checkOutSucceeded,
  checkOutFailed,
  GET_VOLUNTEERING_ROLES,
  KIOSK_STARTED,
  getVolunteeringRoles,
  getVolunteeringRolesSucceeded,
  getVolunteeringRolesFailed,
} from '../actions'

export default function* rootSaga() {
  yield fork(watchCheckIn)
  yield fork(watchCheckOut)
  yield fork(watchGetVolunteeringRoles)
  yield fork(getRolesOnKioskStart)
}

export function* watchCheckIn() {
  yield takeEvery(CHECK_IN, onCheckIn)
}

export function* onCheckIn(action) {
  let { volunteer } = action.payload
  let opportunity = yield select(getOpportunity)
  try {
    //TODO check for errors!
    yield postToTimesheet({ action: 'check-in', volunteer, opportunity })
    yield put(checkInSucceeded(volunteer))
  } catch (e) {
    yield put(checkInFailed(e))
  }
}

export function* watchCheckOut() {
  yield takeEvery(CHECK_OUT, onCheckOut)
}

export function* onCheckOut(action) {
  let { volunteer, activityData } = action.payload
  let opportunity = yield select(getOpportunity)
  try {
    yield postToTimesheet({
      action: 'check-out',
      volunteer,
      opportunity,
      data: activityData,
    })

    yield put(checkOutSucceeded(volunteer))
  } catch (e) {
    yield put(checkOutFailed(e))
  }
}

function* postToTimesheet({ action, volunteer, opportunity, data = {} }) {
  let config = {
    headers: {
      'X-Api-Version': '2019-06-30',
    },
    body: [
      {
        action,
        cognitoSub: volunteer.user.cognitoSub,
        opportunityId: opportunity.id,
        data,
      },
    ],
  }

  const result = yield call(requestWithRetry, { method: 'post', url: '/timesheet', config })
  const error = _.get(result, '0.error')
  if (error) {
    throw new Error(getErrorMessage(error)) //or whatever
  }
  return result
}

export function* getRolesOnKioskStart() {
  yield takeEvery(KIOSK_STARTED, function* () {
    yield put(getVolunteeringRoles())
  })
}

export function* watchGetVolunteeringRoles() {
  yield takeEvery(GET_VOLUNTEERING_ROLES, onGetVolunteeringRoles)
}

export function* onGetVolunteeringRoles(getAction) {
  let encodedOccurrence = yield select(getEncodedOccurrence) //TODO this should be passed as an argument!
  let url = `/opportunity_instances/${encodedOccurrence}/roles`
  try {
    const roles = yield call(requestWithRetry, { url })
    yield put(getVolunteeringRolesSucceeded(roles))
  } catch (error) {
    yield put(getVolunteeringRolesFailed(error))
  }
}
