import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'
import CKEditorField from './CkEditorField'
import cn from 'classnames'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FieldProps } from 'formik/dist/Field'

const useStyles = makeStyles({
  ckEditorLabel: {
    top: '32px',
    left: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '0 4px',
    fontSize: '13px',
    background: 'white',
    minHeight: '13px',
    lineHeight: '13px',
    position: 'absolute',
    fontFamily: 'Nunito, sans-serif',
    borderRadius: '24px',
    pointerEvents: 'none',
    zIndex: 2,
  },
  ckEditorContainer: {
    position: 'relative',
  },
})

interface CkEditorStyledFieldProps {
  label: string
  name: string
  placeholder?: string
  disabled?: boolean
  id?: string
  _dangerouslyRemoveDialogTabindex?: boolean
}

export const CkEditorStyledField: FC<FieldProps & CkEditorStyledFieldProps> = ({
  id,
  label,
  ...props
}: CkEditorStyledFieldProps) => {
  const classes = useStyles()
  return (
    <div className={classes.ckEditorContainer} id={id}>
      {label && <span className={classes.ckEditorLabel}>{label}</span>}
      <CKEditorField
        {...props}
        id={id}
        editor={ClassicEditor}
        transformed
        config={{
          toolbar: {
            items: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Blockquote', 'Link', 'Unlink'],
            shouldNotGroupWhenFull: true,
          },
        }}
        style={{ marginTop: 10 }}
        className={cn('fullwidth')}
      />
    </div>
  )
}
