import * as React from 'react'
import EnvService from '../../services/env.service'

import './index.scss'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { GeoCoordinates } from 'civic-champs-shared/core/location/utils'

interface StaticGoogleMapProps {
  coordinates: GeoCoordinates
  width?: number
  height?: number
  zoom?: number
}

const ENV = new EnvService()
const GOOGLE_API_KEY = ENV.getValue('GOOGLE_MAPS_KEY')

const StaticGoogleMap = (props: StaticGoogleMapProps) => {
  const { width, height, coordinates = DEFAULT_GEOFENCING.location.coordinates } = props

  const { lat, lng } = coordinates

  const { mainUrl, center, zoom, size, maptype, markers, key } = {
    mainUrl: 'https://maps.googleapis.com/maps/api/staticmap',
    center: `${lat}, ${lng}`,
    zoom: props.zoom || 16,
    size: `${width || 500}x${height || 500}`,
    maptype: 'roadmap',
    markers: `color:red%7Clabel:%7C${lat},${lng}`,
    key: GOOGLE_API_KEY
  }

  const googleMapSource
    = `${mainUrl}?center=${center}&zoom=${zoom}&size=${size}&maptype=${maptype}&markers=${markers}&key=${key}`

  return (
    <div className="static-google-map">
      <img src={googleMapSource} alt="Google Map" />
    </div>
  )
}

export default StaticGoogleMap
