import { makeStyles } from '@material-ui/core/styles'

const useTripModalStyles = makeStyles({
  driverDialog: {
    width: '456px !important',
  },
  dateTimePickers: {
    display: 'flex',
    gap: '24px',
  },
  dateTimePicker: {
    width: '208px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      margin: 0,
    },
  },
  checkboxLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  checkboxContainer: {
    marginLeft: 0,
  },
  checkbox: {},
  radio: {
    padding: '5px 9px',
  },
  checkboxChecked: {
    '&$checkbox, &$radio': {
      color: '#0F5DB5',
    },
  },
  ckEditorLabel: {
    top: '32px',
    left: '8px',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '0 4px',
    fontSize: '13px',
    background: 'white',
    minHeight: '13px',
    lineHeight: '13px',
    position: 'absolute',
    fontFamily: 'Nunito, sans-serif',
    borderRadius: '24px',
    pointerEvents: 'none',
    zIndex: 2,
  },
  ckEditorContainer: {
    position: 'relative',
  },
  tripTimeInfo: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#000000',
  },
})

export default useTripModalStyles
