import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { isNumber } from 'lodash'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '40px',
  },
  stepLink: {
    textDecoration: 'none',
    display: 'flex',
    width: '186px',
    height: '51px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: "url('/assets/icons/ProgressBarStart.svg')",
    backgroundRepeat: 'no-repeat',
    border: '0',
  },
  stepLinkActive: {
    backgroundImage: "url('/assets/icons/ProgressBarStartActive.svg')",
  },
  stepLinkPassed: {
    backgroundImage: "url('/assets/icons/ProgressBarStartPassed.svg')",
  },
  stepLinkMiddle: {
    marginLeft: '-31px',
    backgroundImage: "url('/assets/icons/ProgressBarMid.svg')",
  },
  disabled: {
    pointerEvents: 'none',
  },
  stepLinkMiddleActive: {
    backgroundImage: "url('/assets/icons/ProgressBarMidActive.svg')",
  },
  stepLinkMiddlePassed: {
    backgroundImage: "url('/assets/icons/ProgressBarMidPassed.svg')",
  },
  stepLinkEnd: {
    textDecoration: 'none',
    display: 'flex',
    width: '155px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    marginLeft: '-30px',
    backgroundImage: "url('/assets/icons/ProgressBarEnd.svg')",
  },
  stepLinkEndActive: {
    backgroundImage: "url('/assets/icons/ProgressBarEndActive.svg')",
  },
  stepLinkEndPassed: {
    backgroundImage: "url('/assets/icons/ProgressBarEndPassed.svg')",
  },
  stepLabel: {},
  title: {
    color: '#5F8FE8',
    margin: '0 auto',
    textAlign: 'center',
  },
  titleActive: {
    color: 'white',
  },
}))

export default function ProgressTracker(props) {
  const { steps, furthestStep = steps.length - 1, stepIndex = 0 } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {steps.map((step, index) => {
        const disabled = index > furthestStep
        const passed = furthestStep >= index
        const active = stepIndex === index

        const handleClick = event => {
          if (disabled) event.preventDefault()
        }

        const chooseStyle = () => {
          if (index === 0) {
            if (active) return cn([classes.stepLink, classes.stepLinkActive])
            if (passed) return cn([classes.stepLink, classes.stepLinkPassed])
            return classes.stepLink
          } else if (index !== steps.length - 1) {
            if (active) return cn([classes.stepLink, classes.stepLinkMiddle, classes.stepLinkMiddleActive])
            if (passed) return cn([classes.stepLink, classes.stepLinkMiddle, classes.stepLinkMiddlePassed])
            return cn([classes.stepLink, classes.stepLinkMiddle, classes.disabled])
          } else {
            if (active) return cn([classes.stepLinkEnd, classes.stepLinkEndActive])
            if (passed) return cn([classes.stepLinkEnd, classes.stepLinkEndPassed])
            return cn([classes.stepLinkEnd, classes.disabled])
          }
        }

        return (
          <Link key={index} to={step.path} onClick={handleClick} className={chooseStyle()}>
            <div>
              {isNumber(step.count) && (
                <p className={active ? cn([classes.title, classes.titleActive]) : classes.title}>{step.count}</p>
              )}
              <p className={active ? cn([classes.title, classes.titleActive]) : classes.title}>{step.name}</p>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
