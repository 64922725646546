import { useCallback } from 'react'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

interface TokenResponse {
  token: string
  ttl: number
}

export const useGetPubNubAccessToken = (): [() => Promise<TokenResponse>, Status<TokenResponse>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TokenResponse>()
  const fetchToken = useCallback(
    async () =>
      request({
        url: `/organizations/${organization.id}/chats/token`,
      }),
    [request, organization.id],
  )

  return [fetchToken, status]
}
