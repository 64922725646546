import { Delete, GetDeleteProps, Status } from './fetchRefactoredSchema'
import { useCallback } from 'react'
import useFetchRefactored from './useFetchRefactored'

export default function useDelete<T>(props: GetDeleteProps<T>): [Delete, Status<T>] {
  const { errorMessage, successMessage, emptyValue, configMapper } = props
  const [request, status] = useFetchRefactored({ errorMessage, successMessage, emptyValue })
  const del = useCallback(
    async (params: any) => {
      const { url, onSuccess, onError, config } = configMapper(params)
      await request({
        method: 'del',
        url,
        onError,
        onSuccess,
        config,
      })
    },
    [request, configMapper],
  )
  return [del, status]
}
