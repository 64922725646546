import { useCallback } from 'react'
import qs from 'query-string'

import { useFetchRefactored, Status } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { Person } from 'civic-champs-shared/common/types'

import { ChatMembersPayload } from 'chats/interfaces'

export const useDeleteChatMembers = (): [
  ({ chatId, uuids }: ChatMembersPayload) => Promise<Person[]>,
  Status<Person[]>,
] => {
  const [request, status] = useFetchRefactored<Person[]>({
    errorMessage: 'Cannot delete member(s) from chat',
  })
  const org = useCurrentOrg()

  const deleteChatMembers = useCallback(
    ({ chatId, uuids }: ChatMembersPayload) =>
      request({
        method: 'del',
        url: `/organizations/${org.id}/chats/${chatId}/members?${qs.stringify({ uuids }, { arrayFormat: 'bracket' })}`,
      }),

    [request, org],
  )

  return [deleteChatMembers, status]
}

export default useDeleteChatMembers
