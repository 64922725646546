import React, { useCallback, useEffect, useState } from 'react'
import { FULL_HEIGHT, HALF_HEIGHT, ROW_HEIGHT, usePersonEmbeddedAutocompleteStyles } from 'messages/hooks/useStyles'
import useDebounce from 'civic-champs-shared/utils/useDebounce'
import filter from 'lodash/filter'
import isNull from 'lodash/isNull'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import cn from 'classnames'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { GetFirstLastId } from 'messages/hooks/useRecipientsPlaceholderText'
import isUndefined from 'lodash/isUndefined'
import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'

export type GetContent<T> = (contact: T) => { left: string; right?: string; id: number }

interface PersonEmbeddedAutocompleteProps<T> {
  contacts: T[] | null
  onAdd: (id: number) => Promise<any> | any
  sectionTitle?: string
  selectedContactCount: number
  getFirstLastId: GetFirstLastId<T>
  getContent?: GetContent<T>
}

function PersonEmbeddedAutocompleteComponent<T>({
  contacts,
  onAdd,
  sectionTitle,
  selectedContactCount,
  getFirstLastId,
  getContent,
}: PersonEmbeddedAutocompleteProps<T>) {
  const classes = usePersonEmbeddedAutocompleteStyles()
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [search, setSearch] = useState<string>('')
  const [availableHeight, setAvailableHeight] = useState<number>(HALF_HEIGHT)
  const [filteredContacts, setFilteredContacts] = useState<T[]>([])
  const debouncedSearch = useDebounce(search, 250)

  const getContents = useCallback(
    (contact: T) => {
      if (isUndefined(getContent)) {
        const { firstName, lastName, id } = getFirstLastId(contact)
        return { left: `${firstName} ${lastName}`, id }
      }
      return getContent(contact)
    },
    [getContent, getFirstLastId],
  )

  useEffect(() => {
    const lowerCaseSearch = debouncedSearch.toLowerCase()
    setFilteredContacts(
      filter(contacts, contact => {
        const { left, right = '' } = getContents(contact)
        return `${left} ${right}`.toLowerCase().includes(lowerCaseSearch)
      }),
    )
  }, [contacts, debouncedSearch, getContents, getFirstLastId])

  useEffect(() => {
    let calculatedHeight = FULL_HEIGHT - selectedContactCount * ROW_HEIGHT
    if (calculatedHeight < HALF_HEIGHT) {
      calculatedHeight = HALF_HEIGHT
    }
    setAvailableHeight(calculatedHeight)
  }, [selectedContactCount])

  const handleScroll = useHandleScroll(setScrollTopPosition)

  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts } = useDisplayAreaInfo<T>({
    contacts: filteredContacts,
    scrollTopPosition,
    rowHeight: ROW_HEIGHT,
    height: availableHeight,
  })

  return (
    <div className={cn(classes.container, { [classes.loading]: isNull(contacts) })}>
      {isNull(contacts) ? (
        <CircularProgress size={24} style={{ margin: '4px' }} />
      ) : (
        <>
          <Input
            autoFocus
            classes={{ input: classes.search }}
            placeholder="Search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            disableUnderline
            endAdornment={
              <InputAdornment position="start">
                <SearchIcon className={classes.adornment} />
              </InputAdornment>
            }
          />
          <div className={classes.results} style={{ maxHeight: `${availableHeight}px` }} onScroll={handleScroll}>
            {sectionTitle ? <div className={cn(classes.row, classes.titleRow)}>{sectionTitle}</div> : null}
            <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />
            {visibleContacts.map(getContents).map(({ id, left, right }) => {
              return (
                <div key={id} onClick={() => onAdd(id)} className={classes.row}>
                  <div>{left}</div>
                  {right && <div>{right}</div>}
                </div>
              )
            })}
            <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
          </div>
        </>
      )}
    </div>
  )
}

export function PersonEmbeddedAutocomplete<T>(props: PersonEmbeddedAutocompleteProps<T>) {
  return (
    <ThemeProvider theme={muiTheme}>
      <PersonEmbeddedAutocompleteComponent {...props} />
    </ThemeProvider>
  )
}

export default PersonEmbeddedAutocomplete
