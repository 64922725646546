import { trim, isEqual } from 'lodash'
import React from 'react'
import { TextField, Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { Formik, FormikValues, getIn } from 'formik'
import * as yup from 'yup'
import InputMask from 'react-input-mask'
import SalsaSegmentInfoPopover from './SalsaSegmentInfoPopover'
import SalsaDisconnectButton from './SalsaDisconnectButton'
import SalsaPauseButton from './SalsaPauseButton'
import SalsaNotConnectedText from './SalsaNotConnectedText'

const salsaIntegrationSchema = yup.object({
  apiKey: yup.string().trim().required('API Key is a required field'),

  segmentId: yup
    .string()
    .trim()
    .required('Volunteer Segment ID is a required field')
    .matches(/^([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12}$)/),
})

interface SalsaIntegrationFormProps {
  onSubmit: (userCredential: any) => void
  disconnectSalsaIntegration: () => void
  pauseSalsaIntegration: (enabled: boolean) => void
  classes: any
  loading: boolean
  organizationId: number
  salsaIntegration: {
    id?: string
    organizationId?: number | null
    apiKey?: string
    segmentId?: string
    lastSyncStartAt?: Date
    lastSyncEndAt?: Date
    lastExportStartAt?: Date
    lastExportEndAt?: Date
    pausedAt?: Date
    createdAt?: Date
    deletedAt?: Date
    globallyDisabledAt?: Date
  }
}

export default function SalsaIntegrationForm(props: SalsaIntegrationFormProps) {
  const {
    onSubmit,
    classes,
    salsaIntegration,
    loading,
    organizationId,
    disconnectSalsaIntegration,
    pauseSalsaIntegration,
  } = props
  const { id, segmentId = '', apiKey = '', pausedAt = null, globallyDisabledAt = null } = salsaIntegration
  const paused = !!pausedAt
  const handleFormikSubmit = (values: FormikValues) => {
    return Promise.resolve(
      onSubmit({
        id,
        organizationId,
        apiKey: trim(values.apiKey),
        segmentId: trim(values.segmentId),
      }),
    )
  }
  return (
    <>
      {globallyDisabledAt && <p>FEATURE IS GLOBALLY DISABLED</p>}
      <div className={classes.explanationLabel}>
        Please enter your API key and Group Id from Salsa. This link will take you to the right page.{' '}
        <span>
          <a
            className={classes.link}
            rel="noopener"
            href="https://hq.salsalabs.org/#/settings/api-settings"
            target="_blank"
          >
            LINK
          </a>
        </span>
      </div>
      <Formik
        initialValues={{ segmentId, apiKey }}
        onSubmit={handleFormikSubmit}
        validationSchema={salsaIntegrationSchema}
      >
        {({ values, errors, submitCount, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          const hasError = (field: string) => submitCount > 0 && !!getIn(errors, field)
          const getError = (field: string) => hasError(field) && getIn(errors, field)
          const salsaIntegrationHasBeenUpdated =
            values.segmentId &&
            values.apiKey &&
            !isEqual(
              { segmentId: salsaIntegration.segmentId, apiKey: salsaIntegration.apiKey },
              { segmentId: values.segmentId, apiKey: values.apiKey },
            )
          const connected = salsaIntegration.id && !salsaIntegrationHasBeenUpdated
          const somethingInProgress = loading || isSubmitting
          const disabled = loading || isSubmitting || !!globallyDisabledAt
          const showSubmitButton = (!salsaIntegration.id || salsaIntegrationHasBeenUpdated) && !paused
          const showDisconnectButton = salsaIntegration.id && !salsaIntegrationHasBeenUpdated && !paused
          const informationMissing =
            !salsaIntegration.id && !salsaIntegrationHasBeenUpdated && (!values.segmentId || !values.apiKey)
          const canConnect = !!(!salsaIntegration.id && salsaIntegrationHasBeenUpdated)
          const showNotConnectedText = informationMissing || canConnect || paused
          const getButtonClass = () => {
            if (disabled || !salsaIntegrationHasBeenUpdated) {
              return classes.disabledButton
            } else if (connected) {
              return classes.connectedButton
            } else {
              return classes.submitButton
            }
          }
          return (
            <form onSubmit={handleSubmit} noValidate>
              {/* spacing must be set to zero or else it will make the link unclickable*/}
              <Grid container spacing={0}>
                <Grid container item xs={12} spacing={0}>
                  <Grid item className={classes.formLabelWrapper} xs={2}>
                    <Typography className={classes.formLabel}>Api Key</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="apiKey"
                      name="apiKey"
                      variant="outlined"
                      value={values.apiKey}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      classes={{ root: 'custom-input-component' }}
                      error={hasError('apiKey')}
                      helperText={getError('apiKey')}
                      disabled={disabled}
                      margin="normal"
                      required
                      multiline
                      rows={5}
                      fullWidth
                      autoCapitalize="none"
                      autoCorrect="off"
                      spellCheck="false"
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item className={classes.formLabelWrapper} xs={2}>
                    <Typography className={classes.formLabel}>Group Id</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <InputMask
                      id="segmentId"
                      name="segmentId"
                      value={values.segmentId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabled}
                      mask="********-****-****-****-************"
                      maskChar=" "
                      alwaysShowMask={false}
                    >
                      {(inputProps: any) => (
                        <TextField
                          {...inputProps}
                          error={hasError('segmentId')}
                          variant="outlined"
                          helperText={getError('segmentId')}
                          margin="normal"
                          required
                          classes={{ root: 'custom-input-component' }}
                          fullWidth
                          autoCapitalize="none"
                          autoCorrect="off"
                          spellCheck="false"
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item className={classes.formLabelWrapper} xs={2}>
                    <SalsaSegmentInfoPopover />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid container item xs={12} spacing={3}>
                  {showSubmitButton && (
                    <Grid item xs={2}>
                      <Button
                        type="submit"
                        className={getButtonClass()}
                        disabled={disabled || !salsaIntegrationHasBeenUpdated}
                      >
                        {somethingInProgress && (
                          <CircularProgress size={24} color="primary" className={classes.buttonProgress} />
                        )}
                        {connected ? 'Connected' : 'Connect'}
                      </Button>
                    </Grid>
                  )}
                  {showDisconnectButton && (
                    <Grid item xs={2}>
                      <SalsaDisconnectButton
                        onClick={disconnectSalsaIntegration}
                        classes={classes}
                        disabled={loading || !!globallyDisabledAt}
                        loading={loading}
                      />
                    </Grid>
                  )}
                  {salsaIntegration.id && (
                    <Grid item xs={2}>
                      <SalsaPauseButton
                        onClick={() => pauseSalsaIntegration(!paused)}
                        classes={classes}
                        disabled={loading || !!globallyDisabledAt}
                        loading={loading}
                        paused={paused}
                      />
                    </Grid>
                  )}
                  {showNotConnectedText && (
                    <SalsaNotConnectedText
                      classes={classes}
                      informationMissing={informationMissing}
                      canConnect={canConnect}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
