import React, { useState } from 'react'
import { useMergeAccounts } from '../hooks/useMergeAccounts'
import Loading from 'components/Loading'
import { Button, Grid } from '@material-ui/core'
import { MergeAccountReferenceList } from '../components/MergeAccountReferenceList'
import { SelectPerson } from '../components/SelectPerson'
import { usePersonReferences } from '../hooks/usePersonReferences'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'

export const MergeAccounts = (props) => {
  const [, { loading: peopleLoading, result: people }] = useGetOnMount({
    url: '/all-people',
    errorMessage: 'Error fetching People',
    emptyValue: [],
  })
  const [mergeAccounts, { loading: mergeLoading }] = useMergeAccounts(() => props.history.push('/people'))
  const [replacingPerson, setReplacingPerson] = useState('')
  const [replacedPerson, setReplacedPerson] = useState('')
  const { result: replacingPersonReferences, loading:  replacingPersonReferencesLoading } = usePersonReferences(replacingPerson)
  const { result: replacedPersonReferences, loading:  replacedPersonReferencesLoading } = usePersonReferences(replacedPerson)

  const loading = peopleLoading || mergeLoading || replacingPersonReferencesLoading || replacedPersonReferencesLoading
  const disabled = loading || !replacedPerson || !replacingPerson || replacedPerson.id === replacingPerson.id

  const onMerge = () => mergeAccounts(replacingPerson, replacedPerson)

  if (loading) {
    return <Loading/>
  }

  return (
    <>
      <h1>Select accounts to merge</h1>
      <Grid container spacing={6}>
        <Grid container item sm={6} direction="column">
          <SelectPerson
            label={'Person being replaced'}
            items={people}
            value={replacedPerson}
            onChange={setReplacedPerson}
          />
          <MergeAccountReferenceList references={replacedPersonReferences} person={replacedPerson}/>
        </Grid>
        <Grid container item sm={6} direction="column">
          <SelectPerson
            label={'Person replacing with'}
            items={people}
            value={replacingPerson}
            onChange={setReplacingPerson}
          />
          <MergeAccountReferenceList references={replacingPersonReferences} person={replacingPerson}/>
        </Grid>
      </Grid>
      <Button disabled={disabled} onClick={onMerge}>Merge Accounts</Button>
    </>
  )
}
