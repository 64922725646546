import React, { useMemo, useState } from 'react'
import {
  AddCircle as AddCircleIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { Checkbox, Chip, FormControlLabel, makeStyles, Menu, MenuItem } from '@material-ui/core'
import { FieldProps } from 'formik'
import keyBy from 'lodash/keyBy'
import filter from 'lodash/filter'

import { TableMenuButton } from 'civic-champs-shared/core'

import { useFeatureEnabled } from 'core/feature/hooks'
import { useQuestionnairesCollection } from 'question-sets/hooks'
import { Questionnaire, QuestionnaireStatus } from 'question-sets/types'

const useStyles = makeStyles(theme => ({
  attachButton: {
    margin: '8px 8px 0px 0px',
  },
  popupMenu: {
    maxHeight: '500px',
  },
  paper: {
    marginTop: '-5px',
    backgroundColor: '#f9faff',
    border: '1px solid #dbdcdd',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
  },
  chip: {
    margin: '8px 8px 0px 0px',
    backgroundColor: '#f4bf2c',
    maxWidth: '200px',
  },
  checkboxRoot: {
    padding: '6px 8px',
  },
  checkboxLabel: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: theme.palette.secondary.main,
  },
  checkbox: {
    color: theme.palette.secondary.main,
    fontSize: '18px',
  },
  checkboxChecked: {
    color: theme.palette.primary.main,
    fontSize: '18px',
  },
}))

export const AttachQuestionnaires = ({
  field: { name: fieldName, value = [] },
  form: { setFieldValue },
}: FieldProps) => {
  const isQuestionnaireAttachmentsEnabled = useFeatureEnabled('MessageQuestionnairesAttachment')
  const [{ questionnaires }] = useQuestionnairesCollection() as any[]

  const { filteredQuestionnaires, questionnairesById } = useMemo(() => {
    const filteredQuestionnaires = filter(questionnaires, { status: QuestionnaireStatus.ACTIVE })
    return {
      filteredQuestionnaires,
      questionnairesById: keyBy(filteredQuestionnaires, 'id'),
    }
  }, [questionnaires])

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!isQuestionnaireAttachmentsEnabled) {
    return null
  }

  return (
    <div>
      <Menu
        PopoverClasses={{ paper: classes.paper }}
        className={classes.popupMenu}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        disableAutoFocusItem={true}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        variant="menu"
      >
        {filteredQuestionnaires.map((questionnaire: Questionnaire) => {
          const { id, name } = questionnaire

          return (
            <MenuItem key={id}>
              <FormControlLabel
                classes={{ label: classes.checkboxLabel }}
                control={
                  <Checkbox
                    classes={{ root: classes.checkboxRoot }}
                    icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} fontSize="small" />}
                    checkedIcon={<CheckBoxIcon className={classes.checkboxChecked} fontSize="small" />}
                    checked={value.includes(id)}
                    onChange={(_, checked) => {
                      const newSelectedIds = checked ? [...value, id] : value.filter((itemId: number) => itemId !== id)

                      setFieldValue(fieldName, newSelectedIds)
                    }}
                  />
                }
                label={name}
                key={id}
              />
            </MenuItem>
          )
        })}
      </Menu>
      <TableMenuButton className={classes.attachButton} startIcon={<AddCircleIcon />} onClick={handleClick} rightMargin>
        Attach Questionnaire
      </TableMenuButton>
      {value.map((id: number) => (
        <Chip
          className={classes.chip}
          label={questionnairesById[id].name}
          deleteIcon={<CloseIcon />}
          onDelete={() => {
            setFieldValue(
              fieldName,
              value.filter((itemId: number) => itemId !== id),
            )
          }}
        />
      ))}
    </div>
  )
}
