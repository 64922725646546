import { map } from 'lodash'
import cn from 'classnames'
import React, { useCallback } from 'react'
import { Field, Formik } from 'formik'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import { muiTheme } from 'theme'

import { PersonRef } from 'civic-champs-shared/common/types'
import yup from 'civic-champs-shared/utils/yup'
import { ContainedButton, Loading, OutlinedButton } from 'civic-champs-shared/core'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'

import { Person } from 'people/interface'
import { useGroups } from 'group/hooks/useGroups'
import { StyledAutocomplete } from 'components/StyledAutocomplete'
import { useEnabledGroups } from 'Event/components/opportunity/GroupPicker/hooks'
import VolunteerSelector from 'tracking/activity/components/VolunteerSelector'
import { AssociationType, EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import { useOptions } from 'Event/components/opportunity/GroupPicker/hooks'
import useMultiGroupAddMembersPromptStyles from 'group/hooks/useMultiGroupAddMembersPromptStyles'

export interface MultiGroupAddMembersPromptProps {
  persons: Person[]
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const refArraySchema = (minErrorMsg: string) =>
  yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().integer().moreThan(0),
      }),
    )
    .min(1, minErrorMsg)
    .required()

const multiGroupAddMembersSchema = yup.object().shape({
  persons: refArraySchema('At least 1 person must be selected'),
  groups: refArraySchema('At least 1 group must be selected'),
})

const useStyles = makeStyles({
  dialog: { width: '533px' },
  title: { marginBottom: 0 },
  gap: { marginBottom: '16px' },
})

const MultiGroupAddMembersPrompt = ({ persons, showing, complete, close }: MultiGroupAddMembersPromptProps) => {
  const classes = useMultiGroupAddMembersPromptStyles()
  const styles = useStyles()

  const { groups: rawGroups, loading: loadingGroups } = useGroups()
  const groups = useEnabledGroups(rawGroups)

  //TODO is this right?  Seems narrowly focused for the local editor
  const groupOptions = useOptions({ groups, associationType: AssociationType.ADD_PARTICIPANTS_TO_GROUP, value: [] })

  const handleSubmit = useCallback(
    ({ persons, groups }) => {
      complete({
        personIds: map(persons, 'id'),
        groupIds: map(groups, 'groupId'),
      })
    },
    [complete],
  )

  const initialValues = {
    persons,
    groups: [],
  }

  return (
    //@ts-ignore
    <Dialog
      open={showing}
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#multi-group-add-members-prompt` }}
      aria-labeledBy="add-to-group"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, styles.dialog) }}
      disableEnforceFocus
    >
      <DialogTitle
        classes={{ root: cn(classes.title, styles.title) }}
        disableTypography={true}
        id="multi-group-add-members-prompt"
      >
        Add to Group
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <Formik initialValues={initialValues} validationSchema={multiGroupAddMembersSchema} onSubmit={handleSubmit}>
        {({ setFieldValue, isSubmitting, submitForm, values, errors }) => (
          <>
            <DialogContent classes={{ root: classes.content }}>
              <VolunteerSelector
                volunteers={values.persons}
                onChange={(value: PersonRef[]) => setFieldValue('persons', value)}
              />
              <FormHelperText error={!!errors.persons}>{errors.persons}</FormHelperText>
              <Field
                name="groups"
                placeholder="Select Group(s)"
                component={StyledAutocomplete}
                options={groupOptions}
                getOptionLabel={({ name }: EventGroup) => name}
                disabled={isSubmitting || loadingGroups}
                multiple
                notched={true}
                className={styles.gap}
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <OutlinedButton disabled={isSubmitting} onClick={close}>
                Cancel
              </OutlinedButton>
              <ContainedButton disabled={isSubmitting || loadingGroups} isLoading={isSubmitting} onClick={submitForm}>
                Add
              </ContainedButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: MultiGroupAddMembersPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <MultiGroupAddMembersPrompt {...props} />
  </ThemeProvider>
)
