import { useEffect, useState } from 'react'
import { getTextWidth } from 'utils/getTextWidth'

export type GetFirstLastId<T> = (contact: T) => { firstName: string; lastName: string; id: number }

async function getText<T>({
  contacts,
  maxLength = 27,
  getFirstLastId,
  maxWidth = 275,
}: {
  contacts: T[]
  maxLength?: number
  maxWidth?: number
  getFirstLastId: GetFirstLastId<T>
}): Promise<{
  text: string
  more: number
}> {
  let more = contacts.length
  let text = ''
  let concatenator = ''
  for (const contact of contacts) {
    const { firstName, lastName } = getFirstLastId(contact)
    const nameLength = firstName.length + lastName.length + concatenator.length + 1
    if (text.length + nameLength <= maxLength - more.toString().length) {
      text += concatenator + `${firstName} ${lastName}`
      more--
    } else {
      let remainingLength = maxLength - more.toString().length - text.length - concatenator.length
      if (more === 1) {
        remainingLength += 8
      }
      if (remainingLength >= 6) {
        text += concatenator + `${firstName} ${lastName}`.substring(0, remainingLength - 3) + '...'
        more--
      }
      break
    }

    concatenator = ', '
  }
  const textString = text + (more ? `\u00A0+${more} more` : '')
  const width = await getTextWidth(textString, '400 16px/24px Nunito', 0.5)
  return width <= maxWidth ? { text, more } : await getText({ contacts, getFirstLastId, maxLength: maxLength - 1 })
}

export const useRecipientsPlaceholderText = <T>({
  contacts,
  getFirstLastId,
  maxLength,
  maxWidth,
}: {
  contacts: T[]
  getFirstLastId: GetFirstLastId<T>
  maxLength?: number
  maxWidth?: number
}) => {
  const [text, setText] = useState({ text: '', more: 0 })
  useEffect(() => {
    getText({ contacts, getFirstLastId, maxLength, maxWidth }).then(setText)
  }, [contacts, getFirstLastId, maxLength, maxWidth])
  return text
}
