import React from 'react'
import styled from 'styled-components'
import './style.scss'

const Header = styled('div')`
  width: ${props => props.customWidth};
`

function TableHeaders(props) {
  const { headers, onSort, asc, sortBy } = props

  const handleSort = (item) => (() => {
    return item.sortable ? onSort(item.key) : null
  })

  return (
    <div className="table-header">
      {headers &&
      headers.length &&
      headers.map((item, i) => (
        <Header
          className={`table-header__row ${item.className || ''}`}
          width={headers.length}
          key={i}
          onClick={handleSort(item)}
          customWidth={item.customWidth}
        >
          {item.title}
          {item.sortable && (
            <img
              src="/assets/icons/arrow-for-table.svg"
              alt="arrow"
              className={asc && sortBy === item.key ? 'active' : ''}
            />
          )}
        </Header>
      ))}
    </div>
  )
}

export default TableHeaders
