import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { mdiSwapVerticalBold } from '@mdi/js'
import { Icon } from '@mdi/react'

export const ChampionsDatafeedButton = () => (
  <Button
    component={Link}
    variant="contained"
    color="secondary"
    to="/datafeeds"
  >
    <Icon path={mdiSwapVerticalBold} size={1} color="white" />
    Datafeed
  </Button>
)