import React from 'react'
import { FormControl, FormHelperText } from '@material-ui/core'

export default function CheckboxField(props) {
  const { form, field, labelstyle = {}, label, onChange, invertValue = false } = props
  const { name, value } = field
  const helperText = form.errors[name]
  const error = !!helperText

  const getValue = (val) => invertValue ? !val : val

  // We have to set the checkbox value, due to we can't put in the initial values,
  // // formik provide this way to do the change.
  // This will only happen when we add new question set.
  if (value === undefined) {
    // If we do not set this value, and we do not trigger onChange event,
    // the checkbox will not be set to any value
    form.setFieldValue(name, false)
  }

  return (
    <FormControl error={error} className="checkbox-container">
      <label style={labelstyle}>
        <input
          type="checkbox"
          {...props}
          name={name}
          className="checkbox-input"
          checked={getValue(value)}
          onChange={(e) => (onChange ? onChange(getValue(e.target.checked)) : form.setFieldValue(name, !value))}
        />
        {label}
      </label>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}
