import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import * as _ from 'lodash'
import { FieldArray, Form, Formik } from 'formik'
import { Button, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { mdiDownload } from '@mdi/js'
import { Icon } from '@mdi/react'
import { AddButton } from 'civic-champs-shared/core/add-button'
import Loading from 'civic-champs-shared/core/Loading'
import { FormikEffect } from 'civic-champs-shared/question-sets/components/FormikEffect'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useOrganizationCredentials from '../../../credential/hooks/useOrganizationCredentials'
import CreateOrUpdateCredentialPrompt from '../../../credential/components/CreateOrUpdateCredentialPrompt'
import { emptyRow } from '../QuestionSetPicker/hooks'
import { SurveyEditModal } from 'surveys/components'

import {
  useGetIdentificationOptionsLabel,
  useGetRequirementsDirtyLookup,
  useGetWaiverOptionsLabel,
  useHandleNewIdentification,
  useHandleNewQuestionSet,
  useHandleNewSurvey,
  useHandleNewWaiver,
  useIdentificationOptions,
  useOnIdentificationEdit,
  useOnWaiverEdit,
  useOptions as useQuestionOptions,
  usePickerSchema,
  useSubmit,
  useWaiverOptions,
} from './hooks'

import { DirtyRow as WaiverDirtyRow } from './WaiverDirtyRow'
import { CleanRow as WaiverCleanRow } from './WaiverCleanRow'
import { CleanRow as QuestionCleanRow } from './QuestionSetCleanRow'
import { DirtyRow as QuestionDirtyRow } from './QuestionSetDirtyRow'
import { SurveyDirtyRow } from './SurveyDirtyRow'
import { DirtyRow as IDDirtyRow } from './IDDirtyRow'
import { CleanRow as IDCleanRow } from './IDCleanRow'

import { useFetchQuestionSets } from 'civic-champs-shared/question-sets/hooks'
import QuestionSetCreationModal from '../QuestionSetCreationModal'
import CreateOrUpdateIdentificationCredentialPrompt from '../../../credential/components/CreateUpdateIdentificationCredential'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Redirect } from 'react-router'

interface Props {
  loading: boolean
  min: number
  max: number
  value: any[]
  title: ReactNode
  onChange: Function
  onSubmit: Function
  showAnonymousCheckbox?: boolean
  backLink: string
}

const useStyles = makeStyles(() => ({
  typeLabel: {
    marginRight: '5px',
  },
}))

export const RequirementPicker = (props: Props) => {
  const { loading, value = [], onSubmit, min = 0, max = 1000, onChange, showAnonymousCheckbox = true, backLink } = props
  const [credentials, setCredentials] = useState([])
  const [waiverCredentials, setWaiverCredentials] = useState([])
  const [identificationCredentials, setIdentificationCredentials] = useState([])
  const [credentialsLoading, setCredentialLoading] = useState(true)
  const isCredentialEnabled = useFeatureEnabled('CredentialIDs')
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const isNewQuestionnairesEditorEnabled = useFeatureEnabled('NewQuestionnairesEditor')
  const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'
  const classes = useStyles()

  const onSuccess = useCallback(
    res => {
      setCredentials(res)
      setWaiverCredentials(res.filter((x: any) => x.credential.type === 'waiver'))
      setIdentificationCredentials(res.filter((x: any) => x.credential.type === 'identification'))
      setCredentialLoading(false)
    },
    [setCredentials, setWaiverCredentials, setIdentificationCredentials, setCredentialLoading],
  )
  useOrganizationCredentials(onSuccess)
  const [redirectLocation, setRedirectLocation] = useState<string | undefined>()

  const credentialById = useMemo(() => _.keyBy(credentials, 'credential.id'), [credentials])

  const waiverCredentialById = useMemo(() => _.keyBy(waiverCredentials, 'credential.id'), [waiverCredentials])
  const identificationCredentialsById = useMemo(
    () => _.keyBy(identificationCredentials, 'credential.id'),
    [identificationCredentials],
  )

  const pickerSchema = usePickerSchema(min, max, isQuestionnaireAsSurveyEnabled)
  const getWaiverOptionsLabel = useGetWaiverOptionsLabel(waiverCredentialById)

  const getIdentificationOptionsLabel = useGetIdentificationOptionsLabel(identificationCredentialsById)
  const submit = useSubmit({ onSubmit, pickerSchema })

  const openNewWaiverModal = useShowPrompt(CreateOrUpdateCredentialPrompt)
  const openNewIdentificationModal = useShowPrompt(CreateOrUpdateIdentificationCredentialPrompt)

  const handleNewWaiver = useHandleNewWaiver({
    openNewWaiverModal,
    setWaiverCredentials,
    waiverCredentials,
    setCredentials,
    credentials,
  })
  const handleNewIdentification = useHandleNewIdentification({
    openNewIdentificationModal,
    setIdentificationCredentials,
    identificationCredentials,
    setCredentials,
    credentials,
  })

  const waiverCredentialOptions = useWaiverOptions(waiverCredentials)
  const identificationCredentialOptions = useIdentificationOptions(identificationCredentials)

  // Search by type
  const byType = useMemo(() => {
    return _.groupBy(value, 'type')
  }, [value])

  // Questionnaires, credentials and waivers shown on screen
  const questionValueQuestionSets = byType['questionSet']?.map(i => i.value)
  const credentialsOnScreen = byType['identification']
  const waiversOnScreen = byType['waiver']

  const getRequirementsDirtyLookup = useGetRequirementsDirtyLookup(value)

  const onWaiverEdit = useOnWaiverEdit({ waiverCredentials, setWaiverCredentials, openNewWaiverModal })
  const onIdentificationEdit = useOnIdentificationEdit({
    identificationCredentials,
    setIdentificationCredentials,
    openNewIdentificationModal,
  })

  const isGlobalUserProfile = false

  const [questionSets, setQuestionSets] = useState([])
  const [fetchQuestionSets] = useFetchQuestionSets(isGlobalUserProfile, isQuestionnaireAsSurveyEnabled, false, true)

  const questionOptions = useQuestionOptions({
    questionValueQuestionSets,
    questionSets,
    isGlobalUserProfile,
    isQuestionnaireAsSurveyEnabled,
  })

  const openQuestionSetModal = useShowPrompt(QuestionSetCreationModal)
  const handleNewQuestionSet = useHandleNewQuestionSet({
    openQuestionSetModal,
    setQuestionSets,
    questionSets,
    showAnonymousCheckbox,
  })

  const showCreateUpdatePrompt = useShowPrompt(SurveyEditModal)
  const handleNewSurvey = useHandleNewSurvey({
    openQuestionSetModal: showCreateUpdatePrompt,
    setQuestionSets,
    questionSets,
    showAnonymousCheckbox,
  })

  const handleNewSurveyOrQuestionSet = (setFieldValue: any, index: number, id?: number, optional?: boolean) => {
    if (isNewQuestionnairesEditorEnabled) {
      setRedirectLocation(backLink + (id ? `/questionnaires/${id}/edit` : `/questionnaires/new`))
    } else {
      return (isQuestionnaireAsSurveyEnabled ? handleNewSurvey : handleNewQuestionSet)(
        setFieldValue,
        index,
        id,
        optional,
      )
    }
  }

  useEffect(() => {
    fetchQuestionSets({}).then(res => {
      // @ts-ignore
      setQuestionSets(res)
    })
  }, [fetchQuestionSets])

  if (redirectLocation) {
    return <Redirect to={redirectLocation} />
  }

  if (loading || credentialsLoading) {
    return <Loading />
  }

  //TODO make requirements statuses
  const handleChangeRequirement = (setFieldValue: any, index: number, value: any) => {
    setFieldValue(`requirements[${index}].tempType`, value)
    if (value === 'questionnaire' || value === 'survey') {
      setFieldValue(`requirements[${index}].value`, _.cloneDeep(emptyRow(key)))
    }
    if (value === 'waiver' || value === 'id') {
      setFieldValue(`requirements[${index}].value`, '')
    }
  }

  return (
    <Grid container direction="column">
      <Formik
        initialValues={{ requirements: _.clone(value) }}
        onSubmit={submit}
        validationSchema={pickerSchema}
        enableReinitialize
      >
        {formikBag => {
          const { isSubmitting, values, submitForm, isValid, setFieldValue, dirty } = formikBag

          const requirementsDirtyLookup = getRequirementsDirtyLookup(values.requirements)
          const requirementsDirty = Object.values(requirementsDirtyLookup).some(changed => Boolean(changed))

          //currently set to true in original set up
          const showRequired = true

          return (
            <Form>
              <FormikEffect
                onChange={state => {
                  try {
                    // ideally, we'd only call onChange if 'isValid', but isValid has a slight delay
                    // where it comes as 'true' right before false and vice versa
                    // @ts-ignore
                    const castValues = pickerSchema.cast(state.values)

                    const filteredCredentials = castValues.requirements
                      .filter(x => {
                        if (
                          x.type === 'waiver' ||
                          x.type === 'identification' ||
                          x.tempType === 'waiver' ||
                          x.tempType === 'id'
                        ) {
                          return x
                        }
                      })
                      .map(x => (x.value.id ? x.value.id : x.value))

                    onChange &&
                      onChange({
                        ...state,
                        values: {
                          credentials: filteredCredentials.map((credentialId: number) => credentialById[credentialId]),
                          questionSets: castValues.requirements
                            .filter(x => {
                              if (x.type === 'questionSet' || x.tempType === 'questionnaire') {
                                return x
                              }
                            })
                            .map(x => x.value),
                        },
                      })
                  } catch (err) {
                    console.log(err)
                  }
                }}
                formik={formikBag}
              />
              <FieldArray
                name="requirements"
                validateOnChange={true}
                render={arrayHelpers => (
                  <>
                    <Grid container item alignItems="center">
                      {props.title}
                      <Grid item style={{ marginLeft: 10 }}>
                        <AddButton
                          disabled={isSubmitting || !isValid}
                          title="add"
                          onClick={() => arrayHelpers.push({ value: {}, type: 'newRequirement' })}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="column">
                      {values.requirements.map((requirement, index) => {
                        if (requirement.type === 'questionSet') {
                          return (
                            <Grid
                              item
                              container
                              direction="row"
                              key={index}
                              spacing={2}
                              justify="flex-start"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginBottom: '10px' }}
                            >
                              <strong>Question Set</strong>
                              {requirementsDirtyLookup[index][key] ? (
                                <QuestionDirtyRow
                                  index={index}
                                  options={questionOptions}
                                  formikBag={formikBag}
                                  handleNewQuestionSet={handleNewSurveyOrQuestionSet}
                                  arrayHelpers={arrayHelpers}
                                  showRequired={showRequired}
                                />
                              ) : (
                                <QuestionCleanRow
                                  questionSet={requirement.value}
                                  index={index}
                                  disabled={isSubmitting}
                                  onEdit={() =>
                                    handleNewSurveyOrQuestionSet(
                                      setFieldValue,
                                      index,
                                      isQuestionnaireAsSurveyEnabled
                                        ? requirement.value.surveyId
                                        : requirement.value.questionSetId,
                                      requirement.value.optional,
                                    )
                                  }
                                  onRemove={() => {
                                    arrayHelpers.remove(index)
                                    if (onSubmit) {
                                      return submitForm()
                                    }
                                  }}
                                  showRequired={showRequired}
                                  onChangeOptional={(value: boolean) =>
                                    setFieldValue(`requirements[${index}].value.required`, value)
                                  }
                                />
                              )}
                            </Grid>
                          )
                        }

                        if (requirement.type === 'identification' && isCredentialEnabled) {
                          return (
                            <Grid
                              item
                              container
                              direction="row"
                              key={index}
                              spacing={2}
                              justify="flex-start"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginBottom: '10px' }}
                            >
                              <strong>ID</strong>
                              {requirementsDirtyLookup[index] ? (
                                <IDDirtyRow
                                  formikBag={formikBag}
                                  index={index}
                                  getOptionsLabel={getIdentificationOptionsLabel}
                                  handleNewWaiver={handleNewIdentification}
                                  arrayHelpers={arrayHelpers}
                                  options={identificationCredentialOptions}
                                />
                              ) : (
                                <IDCleanRow
                                  onRemove={() => {
                                    arrayHelpers.remove(index)
                                    if (onSubmit) {
                                      return submitForm()
                                    }
                                  }}
                                  disabled={isSubmitting}
                                  onEdit={() => onIdentificationEdit(requirement.value.id)}
                                  credentialId={requirement.value.id}
                                  label={getIdentificationOptionsLabel(requirement.value.id)}
                                />
                              )}
                            </Grid>
                          )
                        }

                        if (requirement.type === 'waiver') {
                          return (
                            <Grid
                              item
                              container
                              direction="row"
                              key={index}
                              spacing={2}
                              justify="flex-start"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginBottom: '10px' }}
                            >
                              <strong>Waiver</strong>
                              {requirementsDirtyLookup[index] ? (
                                <WaiverDirtyRow
                                  formikBag={formikBag}
                                  index={index}
                                  getOptionsLabel={getWaiverOptionsLabel}
                                  handleNewWaiver={handleNewWaiver}
                                  arrayHelpers={arrayHelpers}
                                  options={waiverCredentialOptions}
                                />
                              ) : (
                                <WaiverCleanRow
                                  onRemove={() => {
                                    arrayHelpers.remove(index)
                                    if (onSubmit) {
                                      return submitForm()
                                    }
                                  }}
                                  disabled={isSubmitting}
                                  onEdit={() => onWaiverEdit(requirement.value.id)}
                                  credentialId={requirement.value.id}
                                  label={getWaiverOptionsLabel(requirement.value.id)}
                                />
                              )}
                            </Grid>
                          )
                        }

                        if (requirement.type === 'newRequirement') {
                          return (
                            <Grid
                              item
                              container
                              direction="row"
                              key={index}
                              spacing={2}
                              justify="flex-start"
                              alignContent="center"
                              alignItems="center"
                              style={{ marginBottom: '10px' }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label" className={classes.typeLabel}>
                                Type
                              </InputLabel>

                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={requirement.tempType}
                                variant="outlined"
                                onChange={e => {
                                  handleChangeRequirement(setFieldValue, index, e.target.value)
                                }}
                              >
                                <MenuItem value="questionnaire">Questionnaire</MenuItem>
                                {isCredentialEnabled && <MenuItem value="id">ID Prompt</MenuItem>}
                                <MenuItem value="waiver">Waiver</MenuItem>
                              </Select>

                              {requirement.tempType === 'questionnaire' &&
                                (isQuestionnaireAsSurveyEnabled ? (
                                  <SurveyDirtyRow
                                    surveysOnScreen={questionValueQuestionSets}
                                    index={index}
                                    options={questionOptions}
                                    formikBag={formikBag}
                                    handleNewSurvey={handleNewSurveyOrQuestionSet}
                                    arrayHelpers={arrayHelpers}
                                    showRequired={showRequired}
                                  />
                                ) : (
                                  <QuestionDirtyRow
                                    questionsOnScreen={questionValueQuestionSets}
                                    index={index}
                                    options={questionOptions}
                                    formikBag={formikBag}
                                    handleNewQuestionSet={handleNewSurveyOrQuestionSet}
                                    arrayHelpers={arrayHelpers}
                                    showRequired={showRequired}
                                  />
                                ))}

                              {isCredentialEnabled && requirement.tempType === 'id' && (
                                <IDDirtyRow
                                  credentialsOnScreen={credentialsOnScreen}
                                  formikBag={formikBag}
                                  index={index}
                                  getOptionsLabel={getIdentificationOptionsLabel}
                                  handleNewWaiver={handleNewIdentification}
                                  arrayHelpers={arrayHelpers}
                                  options={identificationCredentialOptions}
                                />
                              )}

                              {requirement.tempType === 'waiver' && (
                                <WaiverDirtyRow
                                  waiversOnScreen={waiversOnScreen}
                                  formikBag={formikBag}
                                  index={index}
                                  getOptionsLabel={getWaiverOptionsLabel}
                                  handleNewWaiver={handleNewWaiver}
                                  arrayHelpers={arrayHelpers}
                                  options={waiverCredentialOptions}
                                />
                              )}
                            </Grid>
                          )
                        }

                        return null
                      })}
                      <Grid container direction="row" style={{ marginTop: '25px' }}>
                        {onSubmit && (
                          <Button
                            disabled={isSubmitting || !isValid || !(requirementsDirty || dirty)}
                            type="submit"
                            variant="contained"
                            color="secondary"
                          >
                            <Icon path={mdiDownload} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
