import React, { useCallback, useMemo } from 'react'
import List from '@material-ui/core/List'

import NavMenuItem from './NavMenuItem'
import InitializationGate from './InitializationGate'
import { INTERNAL_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { palette } from 'civic-champs-shared/config/palette'
import { useUISettings } from 'hooks/useUISettings'
import { NavSwitch } from 'App/components/NavSwitch'
import cn from 'classnames'
import { useCurrentOrg, useHasRole } from 'auth/hooks'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  toggleContainer: {
    backgroundColor: theme.palette.secondary.main,
  },
  toggle: {
    fontSize: '12px',
    lineHeight: '15px',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    color: 'white',
    padding: '0 12px 0 18px',
  },
  toggleBar: {
    cursor: 'pointer',
    width: '4px',
    overflow: 'visible',
    backgroundColor: palette.background.gray,
    transition:
      theme.transitions.create('background-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }) +
      ',' +
      theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      '& $toggleIcon': {
        backgroundColor: palette.background.gray,
        opacity: 1,
      },
    },
    '&$toggleBarClosed': {
      marginLeft: `-${theme.drawer.width - theme.drawer.icon.halfWidth}px`,
    },
    '&$toggleBarOverflow': {
      overflow: 'hidden',
      background: 'rgba(73, 133, 223, 0.8)',
      cursor: 'default',
    },
    '&$toggleBarOverflow:hover': {
      background: 'rgba(73, 133, 223, 0.8)',
      '& $toggleIcon': {
        opacity: 0,
      },
    },
  },
  toggleBarClosed: {},
  toggleBarOverflow: {},
  toggleIcon: {
    marginLeft: `-${theme.drawer.icon.halfWidth}px`,
    marginTop: '50px',
    opacity: 0,
    transition: theme.transitions.create(['background-color', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    minHeight: 'calc(100% - 69px)',
  },
  bottom: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  switch: {
    display: 'flex',
    height: '50px',
    paddingTop: '17px',
    '&$closed': {
      width: '100%',
      justifyContent: 'center',
    },
    '&$checked': {
      background: '#FFF',
      '& > $toggle': {
        color: '#000',
      },
    },
  },
  closed: {},
  checked: {},
  silentModeEnabled: {
    backgroundColor: '#F4BF2C',
    '&:hover': {
      backgroundColor: '#F4BF2C',
    },
  },
}))

export default function NavMenu(props) {
  const { items = [], history, onChangeRoute, open } = props
  const classes = useStyles()
  const org = useCurrentOrg()
  const uiSettings = useUISettings()
  const { showAdminItems, setShowAdminItems } = uiSettings

  const handleChangeRoute = useCallback(
    route => {
      history.push(route)
      if (onChangeRoute) onChangeRoute(route)
    },
    [history, onChangeRoute],
  )

  const bottomItem = useMemo(() => items.find(item => item.atBottom), [items])
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const isSuperAdmin = useHasRole(SUPER_ADMIN)

  return (
    <InitializationGate>
      <div className={classes.flex}>
        <div>
          <List>
            {org?.sandboxModeEnabled && (
              <NavMenuItem
                IconComponent={() => <InfoOutlinedIcon size="large" />}
                label="Silent Mode Enabled"
                isAdminItem={false}
                showAdminItems={showAdminItems}
                key="sandbox"
                onChangeRoute={() => (isSuperAdmin ? handleChangeRoute('/organization') : undefined)}
                collapsed={!open}
                className={classes.silentModeEnabled}
              />
            )}
            {items
              .filter(item => !item.atBottom)
              .map((item, index) => (
                <NavMenuItem
                  {...item}
                  isAdminItem={item.isAdminItem}
                  showAdminItems={showAdminItems}
                  key={index}
                  onChangeRoute={handleChangeRoute}
                  collapsed={!open}
                />
              ))}
          </List>
        </div>

        {(isInternalAdmin || !!bottomItem) && (
          <div className={classes.bottom}>
            {isInternalAdmin && items.length && (
              <div className={cn(classes.switch, { [classes.closed]: !open, [classes.checked]: showAdminItems })}>
                {open && (
                  <Typography className={classes.toggle} component="span">
                    Support Tools
                  </Typography>
                )}
                <NavSwitch
                  color="default"
                  checked={showAdminItems}
                  onChange={() => setShowAdminItems(!showAdminItems)}
                />
              </div>
            )}
            {!!bottomItem && (
              <List>
                <NavMenuItem
                  {...bottomItem}
                  collapsed={!open}
                  onChangeRoute={handleChangeRoute}
                  showAdminItems={showAdminItems}
                />
              </List>
            )}
          </div>
        )}
      </div>
    </InitializationGate>
  )
}
