import React, { useState } from 'react'
import { Field, Form, useFormikContext, getIn } from 'formik'
import { useFormikWizardContext } from 'civic-champs-shared/formik/FormikWizard'
import {
  makeStyles,
  FormControl,
  IconButton,
  Modal,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Close from '@material-ui/icons/Close'
import { RadioGroup } from 'formik-material-ui'

import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useHasRole } from '../../../auth/hooks'
import { labelAudience, labelFrequency, labelViewsPerUser } from '../utils'
import SummaryInfoDonations from './SummaryInfoDonations'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'

const useStyles = makeStyles(theme => ({
    buttonContainer: {
      marginTop: '250px',
    },
    content:{
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      flexDirection: 'column',
      },
    titleText: {
      fontSize: '24px',
      fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif'
    },
    text: {
      fontSize: '18px',
      marginTop: '15px',
      // textTransform: 'capitalize',
      },
    settingsText: {
      color: '#5F8FE8'
    },
    addButton: {
      marginLeft: -10,
      marginTop: '45px',
      alignSelf: 'flex-start',
    },
    modalBody: {
      display: 'flex',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    }, 
    icon: {
      alignSelf: 'flex-end',
      width: '60px',
      height: '60px',
      color: '#FF6F3E'
    },
    modalTitle: {
      fontSize: '34px',
      color: '#3D3D3D',
      fontFamily: 'SemiBold Open Sans',
      marginBottom: '25px',
    },
    question: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontFamily: 'Open Sans',
      marginBottom: '29px',
      focusedColor: 'black',
      color: "black",
      "&.Mui-focused": {
        color: "black"
      }
    },
    number: {
      width: '55px',
      height: '44px',
      marginLeft: '5px',
      marginRight: '5px',
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalButton: {
      width: '146px',
      height: '45px',
      background: '#5F8FE8',
      alignItems: 'center', 
      justifyContent: 'center',
      marginLeft: '54px',
    },
    nextText: {
      color: 'white',
      fontSize: '15px',
     },
     cancelText: {
      color: '#FF6F3E',
      fontSize: '15px',
     },
     simpleRow: {
       flexDirection: 'row'
     },
     radioGroup: {
       marginBottom: '30px'
     },
     recommended: {
      color: '#FF6F3E',
      fontSize: '11px',
     },
     asterisk: {
      color: '#FF6F3E',
      marginLeft: '2px',
     },
     saveDraft: {
      width: '146px',
      height: '49px',
    },
    saveDraftContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      marginBottom: '62px',
      marginLeft: '90px',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexGrow: 1,
      width: '100%',
  },
  summaryContainer: {
      borderRadius: '4px 0 0 0',
      boxShadow: '0 4px 7px rgba(100, 124, 142, 0.18)',
      borderTop: '1px solid #ABB6CC',
      borderLeft: '1px solid #ABB6CC',
      borderBottom: '1px solid #ABB6CC',
      padding: '21px 29px',
      alignSelf: 'flex-end',
      marginRight: '-85px',
      height: '750px',
      width: '360px',
  }         
}));

function LifetimeViewsField(props) {
  const { field, form, ...rest } = props

  const fieldError = getIn(form.errors, field.name)

  const [choice, setChoice] = useState(() => {
    if(field.value === 0) return 'do_not_stop'
    else if(field.value === 1) return 'once'
    else return 'custom'
  })

  const [customViews, setCustomViews] = useState(field.value)

  const handleChoiceChange = event => { 
    const newChoice = event.target.value
    setChoice(newChoice) 

    if(newChoice === 'do_not_stop' && field.value != 0) {
      form.setFieldValue(field.name, 0)
    } else if(newChoice === 'once' && field.value != 1) {
      form.setFieldValue(field.name, 1)
    } else if(newChoice === 'custom' && field.value != customViews) {
      form.setFieldValue(field.name, customViews)
    }
  }

  const handleCustomViewsChange = event => {
    setCustomViews(event.target.value)
    if(choice === 'custom') {
      form.setFieldValue(field.name, event.target.value)
    }
  }

  const classes = useStyles()

  return (
    <MuiRadioGroup
      className={classes.radioGroup} 
      aria-label="lifetimeViewsPerUser" 
      value={choice}
      onChange={handleChoiceChange}
      {...rest}
    >
      <FormControlLabel value="do_not_stop" control={<Radio />} label="Dont stop asking" />
      <FormControlLabel value="custom" control={<Radio />} label={
        <div className={classes.row}>
          Only ask
          <TextField
            className={classes.number}
            placeholder="#"
            variant="outlined"
            value={customViews}
            onChange={handleCustomViewsChange}
            error={!!fieldError}
            disabled={form.isSubmitting}
          /> times
        </div> } 
      />
      <FormControlLabel value="once" control={<Radio />} label="Only ask once" />
      <p className={classes.recommended}><b>*recommended</b></p> 
    </MuiRadioGroup>
  )
}

//TODO the modal changes stuff, doesn't commit the data!
export default function Frequency(props) {
    const { useRecommended, targetGroup, frequency, lifetimeViewsPerUser=0 } = props.values

    const isInternalAdmin = useHasRole(INTERNAL_ADMIN)

    const [modalOpen, setModalOpen] = useState(false)

    const closeModal = () => { setModalOpen(false) }
    const openModal = () => { setModalOpen(true) }

    const classes = useStyles()

    const newValues= useFormikContext()
    const stepInfo = useFormikWizardContext()

    const fullStepData = {...newValues.values, ...stepInfo}

    return (
      <div className={classes.mainContainer}>
      <div className={classes.content}>
        <h2 className={classes.titleText}>Frequency</h2>  
        {useRecommended 
          ? (<div className={classes.text}>
              Based on your target audience of Recommended <b>By Civic Champs</b>,
              <br></br>
              the platform will automatically update the settings for this campaign.
            </div>)
          : (<>
              <div className={classes.text}>
                Based on your target audience of <b>{labelAudience(targetGroup)}</b>, we recommend:</div>    
              <div className={classes.text}>
                {labelFrequency(frequency)} ask, {labelAudience(targetGroup)}, and {labelViewsPerUser(lifetimeViewsPerUser)}.
              </div>
            </>)}    
        { (!useRecommended || isInternalAdmin) && (
          <Button
            onClick={openModal}
            className={classes.addButton}
            type="text"
          >
            <p className={classes.settingsText}><u>Advanced settings</u></p>
          </Button>
        )}
        <Dialog
          fullScreen
          disableBackdropClick={true}
          onClose={closeModal}
          open={modalOpen}
        >
          <DialogContent className={classes.modalBody}>
            <IconButton 
              color="#FF6F3E" 
              className={classes.icon} 
              aria-label="close" 
              component="span" 
              onClick={closeModal}
            >
              <Close />
            </IconButton>
            <div className={classes.modalTitle}><b>Advanced settings</b></div>
            <FormControl component="fieldset">
              <FormLabel 
                className={classes.question} 
                component="legend"
              >
                How often would you like to ask volunteer for a donation?
              </FormLabel>
              <Field
                component={RadioGroup}
                className={classes.radioGroup} 
                name="frequency"
                aria-label="frequency"
              >
                <FormControlLabel 
                  focused={classes.formLabelFocused} 
                  value="every_shift" 
                  control={<Radio />}
                  label={
                    <div className={classes.row}>
                      Always (Ask at the end of every shift)
                      <div className={classes.asterisk}> *</div>  
                    </div> 
                  }
                />
                <FormControlLabel value="once_a_week" control={<Radio />} label="Frequently (Ask once a week at most)" />
                <FormControlLabel value="once_a_month" control={<Radio />} label="Sometimes (Ask once a month at most)" />
                <FormControlLabel value="once_every_three_month" control={<Radio />} label="Rarely (Ask once every 3 months at most)"/>
              </Field>
              <FormLabel className={classes.question} component="legend">Who would you like us to ask at the end of their shift?</FormLabel>
              <Field
                component={RadioGroup}
                className={classes.radioGroup} 
                aria-label="targetGroup" 
                name="targetGroup"
              >
                <FormControlLabel value="everyone" control={<Radio />} label={
                  <div className={classes.row}>
                      Everyone (This will ask every volunteer)
                      <div className={classes.asterisk}> *</div>  
                  </div>}
                />
                <FormControlLabel value="current_donor" control={<Radio />} label="Current Donors (Volunteers who have previously donated)" />
                <FormControlLabel value="never_donated" control={<Radio />} label="Never donated (Volunteers who have never donated)" />
              </Field>
              <FormLabel className={classes.question} component="legend">How many times should a single volunteer be asked?</FormLabel>
              <Field
                component={LifetimeViewsField}
                name="lifetimeViewsPerUser"
              />
            </FormControl>
          </DialogContent>  
          <DialogActions>
              <Button onClick={closeModal}>
                <div className={classes.cancelText}><b>Cancel</b></div> 
              </Button>
              <Button
                className={classes.modalButton}
                onClick={closeModal}
              >
                <div className={classes.nextText}><b>Save</b></div> 
              </Button>
          </DialogActions>
        </Dialog> 
        <div className={classes.buttonContainer}>
        <StepNavigation />
      </div>  
      </div>
      <div className={classes.summaryContainer}>
          <SummaryInfoDonations 
            campaignId={newValues.values.id} 
            currentStep={fullStepData} 
          />
      </div>
    </div>)
}
  
Frequency.SCHEMA_FIELDS = ['targetGroup', 'frequency', 'lifetimeViewsPerUser']
