import React, { useState } from 'react'
import { RadioList, ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog'

enum CancelType {
  Single = 'single',
  Future = 'future',
  All = 'all',
}

export interface Props {
  event: any
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

export default function CancelEventDialog(props: Props) {
  const { showing, event, complete, close } = props

  const [cancelType, setCancelType] = useState<CancelType>(CancelType.Single)

  const handleCancelTypeChange = (e: any) => {
    if (!event.is_recurring) return
    setCancelType(e.target.value)
  }

  const handleSubmit = () => {
    complete({ eventId: event.id, cancelType })
  }

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="Are you sure you want to cancel this event?"
      text={`Once an event is canceled, it will be removed from the calendar, and all associated registrations will be lost. ${
        event.is_recurring ? 'For recurring events you may cancel the following:' : ''
      }`}
      cancelText="Do not Cancel"
      confirmText="Continue with Cancellation"
    >
      {event.is_recurring && (
        <RadioList
          onChange={handleCancelTypeChange}
          value={cancelType}
          name="cancel-type"
          options={[
            {
              value: CancelType.Single,
              label: 'Only cancel this event',
            },
            {
              value: CancelType.Future,
              label: 'Cancel this and all future recurring events in this sequence',
            },
            {
              value: CancelType.All,
              label: 'Cancel all events in this sequence',
            },
          ]}
        />
      )}
    </ConfirmDialog>
  )
}
