import { makeStyles } from '@material-ui/styles'

export const useChatStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '20px 50px',
    flexWrap: 'wrap',
    width: '100%',
  },
  chat: {
    width: '428px',
    flexShrink: 0,
    height: 'calc(100vh - 100px)',
    backgroundColor: 'white',
  },
  overview: {
    maxWidth: '621px',
    minWidth: '336px',
    flex: '2 0 336px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  overviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #C4C7C7',
    alignItems: 'flex-end',
    paddingBottom: '3px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: '#000000',
    },
  },
  overviewContent: {
    display: 'flex',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
    '& > div': {
      fontWeight: 700,
      width: '130px',
    },
  },
  whiteBg: {
    backgroundColor: 'white',
  },
  tableWrapper: {
    display: 'flex',
    '& > div': {
      width: 'auto',
    },
  },
})

export default useChatStyles
