import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog'
import { Admin } from '../interface'

export interface Props {
  admins: Admin[]
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    textAlign: 'justify',
  },
  field: {
    fontSize: '14px',
    lineHeight: '20px',
    width: '100%',
  },
  container: {
    padding: '0px 0px 8px 0px',
    marginTop: '-20px',
  },
  adminsContainer: {
    maxHeight: '200px',
    paddingBottom: '5px',
    overflowY: 'scroll',
  },
  admin: {
    fontSize: '12px',
    fontFamily: 'Nunito, sans-sertif',
    lineHeight: '14px',
    padding: '5px',
  },
})

const MAX_ALLOWED_NUMBER = 25

export function RemoveAdminsModal(props: Props) {
  const { showing, complete, close, admins } = props

  const text =
    admins.length > MAX_ALLOWED_NUMBER
      ? `For security reasons, you cannot remove more than ${MAX_ALLOWED_NUMBER} admins at time.
      Only the following accounts will be removed:`
      : 'You are removing the following accounts as administrators:'

  const allowedToDelete = admins.slice(0, MAX_ALLOWED_NUMBER)

  const handleSubmit = () => {
    complete({ admins: allowedToDelete })
  }

  const classes = useStyles()

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="Are you sure you want to remove the following admin(s)?"
      text={text}
      cancelText="Cancel"
      confirmText="Remove Admins"
    >
      <div className={classes.container}>
        <ul className={classes.adminsContainer}>
          {allowedToDelete.map(({ person, id }: Admin) => (
            <li>
              <Typography className={classes.admin} key={id}>
                {person.familyName} {person.givenName} - {person.phoneNumber || person.email}
              </Typography>
            </li>
          ))}
        </ul>
        <Typography className={classes.text}>
          The removed accounts will no longer be able to access any admin features for Civic Champs, including in the
          mobile app.
        </Typography>
      </div>
    </ConfirmDialog>
  )
}

export default RemoveAdminsModal
