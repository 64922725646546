import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { ProgramMeetingGridData } from 'new-mentorship/types'

export const useFetchMeetingsGrid = (): [() => Promise<ProgramMeetingGridData>, Status<ProgramMeetingGridData>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<ProgramMeetingGridData>({
    errorMessage: 'Error Fetching Meetings.  Please refresh the page and try again',
    emptyValue: { meetings: [], questionColumns: {} },
  })

  const fetchMeetings = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/mentorship-meetings-grid`,
    })
  }, [organization, request])

  return [fetchMeetings, status]
}

export default useFetchMeetingsGrid
