import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { isNil, omitBy } from 'lodash'

export const useGetLocationOrder = () => {
  const organization = useCurrentOrg()
  const [request] = useFetchRefactored<{ order: number }>()

  return useCallback(
    async (params: { locationId?: number; opportunityOccurrenceId?: number }) =>
      request({
        url: `/organizations/${organization.id}/opportunity-location-order`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, organization.id],
  )
}
