import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { chainPropTypes } from '@material-ui/utils';
import withStyles from '@material-ui/styles/withStyles';
import * as _ from "lodash";

export const styles = (theme) => ({
    /* Styles applied to the root element. */
    root: {
        userSelect: 'none',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fill: 'currentColor',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(24),
        transition: theme.transitions.create('fill', {
            duration: theme.transitions.duration.shorter,
        }),
    },
    /* Styles applied to the root element if `color="primary"`. */
    colorPrimary: {
        color: theme.palette.primary.main,
    },
    /* Styles applied to the root element if `color="secondary"`. */
    colorSecondary: {
        color: theme.palette.secondary.main,
    },
    /* Styles applied to the root element if `color="action"`. */
    colorAction: {
        color: theme.palette.action.active,
    },
    /* Styles applied to the root element if `color="error"`. */
    colorError: {
        color: theme.palette.error.main,
    },
    /* Styles applied to the root element if `color="disabled"`. */
    colorDisabled: {
        color: theme.palette.action.disabled,
    },
    /* Styles applied to the root element if `fontSize="inherit"`. */
    fontSizeInherit: {
        fontSize: 'inherit',
    },
    /* Styles applied to the root element if `fontSize="small"`. */
    fontSizeSmall: {
        fontSize: theme.typography.pxToRem(20),
    },
    /* Styles applied to the root element if `fontSize="large"`. */
    fontSizeLarge: {
        fontSize: theme.typography.pxToRem(35),
    },
});

const SvgIcon = React.forwardRef(function SvgIcon(props, ref) {
    const {
        children,
        classes,
        className,
        color = 'inherit',
        component: Component = 'svg',
        fontSize = 'medium',
        htmlColor,
        titleAccess,
        viewBox = '0 0 24 24',
        ...other
    } = props;

    return (
        <Component
            className={clsx(
                classes.root,
                {
                    [classes[`color${_.capitalize(color)}`]]: color !== 'inherit',
                    [classes[`fontSize${_.capitalize(fontSize)}`]]:
                    fontSize !== 'default' && fontSize !== 'medium',
                },
                className,
            )}
            focusable="false"
            viewBox={viewBox}
            color={htmlColor}
            aria-hidden={titleAccess ? undefined : true}
            role={titleAccess ? 'img' : undefined}
            ref={ref}
            {...other}
        >
            {children}
            {titleAccess ? <title>{titleAccess}</title> : null}
        </Component>
    );
});

SvgIcon.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * Node passed into the SVG element.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     * You can use the `htmlColor` prop to apply a color attribute to the SVG element.
     */
    color: PropTypes.oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes /* @typescript-to-proptypes-ignore */.elementType,
    /**
     * The fontSize applied to the icon. Defaults to 24px, but can be configure to inherit font size.
     */
    fontSize: chainPropTypes(
        PropTypes.oneOf(['default', 'inherit', 'large', 'medium', 'small']),
        (props) => {
            const { fontSize } = props;

            if (fontSize === 'default') {
                throw new Error(
                    'Material-UI: `fontSize="default"` is deprecated. Use `fontSize="medium"` instead.',
                );
            }

            return null;
        },
    ),

    /**
     * Applies a color attribute to the SVG element.
     */
    htmlColor: PropTypes.string,
    /**
     * The shape-rendering attribute. The behavior of the different options is described on the
     * [MDN Web Docs](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/shape-rendering).
     * If you are having issues with blurry icons you should investigate this property.
     */
    shapeRendering: PropTypes.string,
    /**
     * Provides a human-readable title for the element that contains it.
     * https://www.w3.org/TR/SVG-access/#Equivalent
     */
    titleAccess: PropTypes.string,
    /**
     * Allows you to redefine what the coordinates without units mean inside an SVG element.
     * For example, if the SVG element is 500 (width) by 200 (height),
     * and you pass viewBox="0 0 50 20",
     * this means that the coordinates inside the SVG will go from the top left corner (0,0)
     * to bottom right (50,20) and each unit will be worth 10px.
     */
    viewBox: PropTypes.string,
};

SvgIcon.muiName = 'SvgIcon';

export default withStyles(styles, { name: 'MuiSvgIcon' })(SvgIcon);