import {isFunction} from 'lodash'
import React, {useMemo} from 'react'
import {Button} from '@material-ui/core'

const useCredentialIDApprovalButtonCell = (onGotoApprovals, credentialType) => useMemo(() => {
  const ApprovalButtonCell = ({cell}) => {

    const handleClick = (e) => {
      if (isFunction(onGotoApprovals)) onGotoApprovals(credentialType, cell.row.values.id)
      e.preventDefault()
    }

    return <Button onClick={handleClick} style={{textDecoration: "underline"}}>
        VIEW
      </Button>

  }

  return ApprovalButtonCell
}, [onGotoApprovals, credentialType])

export default useCredentialIDApprovalButtonCell;