import React, { useRef } from 'react'
import { makeStyles, IconButton, Modal,} from '@material-ui/core'
import Close from '@material-ui/icons/Close';
import cn from 'classnames'
import ThankYouPrompt from './ThankYouPrompt'
import DonatePromptPhone from './DonatePromptPhone'

const useStyles = makeStyles(theme => ({
modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    color: '#FF6F3E'
  },
  row: {
    display: 'flex',
    flexDirection: 'row', 
  },
  smallTitle: {
    color: '#212121',
    marginTop: '41px'
  },
  propmpt: {
    marginRight: '100px',
   },
   promptTitle: {
     marginBottom: '21px',
   }, 
   mainTitle: {
      fontSize: '34px',
      alignSelf: 'flex-start',
      marginLeft: -100,
      font: 'SemiBold Open Sans'
   }
}));

export default function Prompts(props) {
    const { showing, close, campaign } = props
    const classes = useStyles()

    return (
     <>
      <Modal
        open={showing}
        className={classes.modal}
        >
        <div className={classes.modalBody}>
            <IconButton color="#FF6F3E" className={classes.icon} aria-label="close" component="span"
                onClick={close} 
            >
                <Close />
            </IconButton>
            <div>
            <div className={classes.mainTitle}>Prompts</div> 
            <div className={classes.row}>
              <div className={classes.propmpt}> 
              <div className={cn([classes.smallTitle, classes.promptTitle])}><b>Thank You Prompt</b></div>
              <ThankYouPrompt data={campaign.thankYouPrompt}/>
              </div>
              <div> 
              <div className={cn([classes.smallTitle, classes.promptTitle])}><b>Donate Prompt</b></div>
              <DonatePromptPhone data={campaign.donatePrompt}/>
              </div>
           </div> 
           </div> 
        </div>
        </Modal>    
     </>)
}
