import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import CancelDialog from 'civic-champs-shared/core/confirm-dialog/CancelDialog'
import useDeleteIntegrationKey from 'integrations/hooks/useDeleteIntegrationKey'
import { IntegrationProvider } from 'integrations/types'

const useDeleteIntegrationKeyDialog = ({ onSuccess }: { onSuccess: () => any }) => {
  const showPrompt = useShowPrompt(CancelDialog)
  const deleteIntegrationKey = useDeleteIntegrationKey()

  return useCallback(
    async (provider: IntegrationProvider) => {
      const confirmed = await showPrompt({
        text: `Are you sure you want to delete integration?`,
        title: 'Confirm Action',
        confirmText: 'Yes',
        cancelText: 'No',
      })

      if (confirmed) {
        await deleteIntegrationKey(provider)
        onSuccess()
      }
    },
    [deleteIntegrationKey, onSuccess, showPrompt],
  )
}

export default useDeleteIntegrationKeyDialog
