import _ from 'lodash';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardHeader, Button, Grid, CardContent, CardActions, Card } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    background: '#FFCD00',
    color: '#3d3d3d',
    fontSize: '24px',
    fontWeight: 'bold',
    height:"65px"
  },
  kioskButton: {
    height: 50,
    padding: '0 35px',
    marginBottom: 10,
    background:'#5C8DE8',
  }
}));

export default function AddressCard(props) {
  const { children, opportunity, openDialogWindow, isRowSelected } = props;
  const classes = useStyles();

  const title = _.get(opportunity, 'name');

  const handleOpen = () => {
    openDialogWindow();
  };

  return (
    <Card>
      <CardHeader title={title} className={classes.cardHeader} />
      <CardContent>{children}</CardContent>
      <CardActions>
        <Grid container justify="center">
          <Button
            variant="contained"
            color="secondary"
            disabled={!isRowSelected}
            onClick={handleOpen}
            className={classes.kioskButton}
          >
            Start Kiosk Mode
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
}
