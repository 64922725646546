// TODO: this whole thing needs to be re-written, ideally in TS
import React, { useState, useCallback } from 'react'
import { makeStyles, withStyles, Button, IconButton, Slider, Modal } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import useFileManagementUpload from '../hooks/useFileManagementUpload'
import Loading from 'components/Loading'
import Cropper from 'react-easy-crop'
import InsertPhoto from '@material-ui/icons/InsertPhoto'

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    color: '#FF6F3E',
  },
  modalTitle: {
    fontSize: '34px',
    color: '#3D3D3D',
    fontFamily: 'SemiBold Open Sans',
    marginBottom: '25px',
    alignSelf: 'center',
  },
  button: {
    width: '145px',
    height: '45px',
    marginTop: '10px',
    marginBottom: '30px',
  },
  text: {
    marginTop: '10px',
    fontSize: '21px',
    marginBottom: '10px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bottomContainer: {
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  slider: {
    width: '300px',
  },
}))

const CustomSlider = withStyles({
  root: {
    color: '#6f8eff',
    height: 3,
    padding: '13px 0',
    width: '300px',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid #5C8DE8',
    marginTop: -9,
    marginLeft: -11,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    color: '#fff',
  },
})(Slider)

function readFileDataAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = event => {
      resolve(event.target.result)
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsDataURL(file)
  })
}

const Spinner = ({ scanning }) => {
  return (
    <>
      <Loading />
      <p>{scanning ? 'Scanning for viruses...' : 'Uploading file...'}</p>
    </>
  )
}

const CropImagePrompt = props => {
  const { showing, close, complete, currentFile, metadata } = props
  const classes = useStyles()
  const [currentImage, setCurrentImage] = useState()
  const [, setCroppedImage] = useState()
  const [fullUpload, fullUploadStatus] = useFileManagementUpload()
  const [loading, setLoading] = useState(false)
  // if scanning is true, that means the upload is complete, but we are waiting for the virus scan to be done
  const { loading: fileManagementLoading, scanning } = fullUploadStatus

  const [crop, onCropChange] = useState({ x: 0, y: 0 })
  const [zoom, onZoomChange] = useState(1)
  const [aspect] = useState(5 / 3)
  const [croppedArea, setCroppedArea] = useState()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }, [])

  readFileDataAsBase64(currentFile).then(setCurrentImage)

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', error => reject(error))
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
    })

  const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        dataURLtoFile(reader.result, currentFile.name)
      }
    })
  }

  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    let croppedImage = new File([u8arr], filename, { type: mime })
    uploadFile(croppedImage)
    setCroppedImage(croppedImage)
  }

  function onSave(image, crop) {
    setLoading(true)
    if (crop.width !== 0 && crop.height !== 0) {
      getCroppedImg(image, crop)
    }
  }

  const uploadFile = async file => {
    const newFile = await fullUpload(file, metadata)
    setLoading(false)
    if (newFile) {
      complete(newFile)
    }
  }

  return (
    <Modal open={showing}>
      <div className={classes.modalBody}>
        <IconButton color="#FF6F3E" className={classes.icon} aria-label="close" component="span" onClick={close}>
          <Close />
        </IconButton>
        <div className={classes.modalTitle}>Crop & Resize Image</div>
        <div className={classes.content}>
          {(fileManagementLoading || loading || scanning) && <Spinner scanning={scanning} />}
          {currentImage != null && !loading && (
            <Cropper
              image={currentImage}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
              style={{ containerStyle: { marginTop: '120px', marginBottom: '160px' } }}
            />
          )}
          <div className={classes.bottomContainer}>
            <span className={classes.text}>Scale and drag the image within the crop area.</span>
            <div className={classes.row}>
              <InsertPhoto style={{ fontSize: 24, marginRight: '15px' }} />
              <CustomSlider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => onZoomChange(zoom)}
              />
              <InsertPhoto style={{ fontSize: 30, marginLeft: '15px' }} />
            </div>
            <Button
              className={classes.button}
              variant="contained"
              disabled={fileManagementLoading || loading}
              color="secondary"
              onClick={() => onSave(currentImage, croppedArea)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CropImagePrompt
