import { Field, Form, Formik } from 'formik'
import { AdvancedPersonAutocomplete } from 'civic-champs-shared/core/AdvancedPersonAutocomplete'
import { AddressField } from 'civic-champs-shared/formik/components'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from 'civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import React from 'react'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import moment, { Moment } from 'moment'
import useTripModalStyles from 'volunteering/trips/hooks/useTripModalStyles'
import './CKEditorForTripModal.css'
import { Address } from 'civic-champs-shared/formik/components/AddressField'
import * as yup from 'yup'
import { useFillCounty } from 'volunteering/trips/hooks/useFillCounty'
import Loading from 'civic-champs-shared/core/Loading'
import { FormModalScreen } from 'components/FormModal'

export interface MainScreenFormValues {
  rider: null | PersonSuggestion
  pickUpAddress: null | Address
  dropOffAddress: null | Address
  pickUpDate: null | Moment
  pickUpTime: null | Moment
  dropOffDate: null | Moment
  dropOffTime: null | Moment
}

const getTripPayloadSchema = (isUpdatePayload = false) => {
  return yup.object({
    rider: yup
      .mixed()
      .required('A rider is required. Please select a rider.')
      .typeError('A rider is required. Please' + ' select' + ' a rider.'),
    pickUpAddress: yup
      .object({
        line1: yup
          .string()
          .min(1, 'A pickup address is required. Please enter a pickup address.')
          .required('A pickup address is required. Please enter a pickup address.')
          .typeError('A pickup address is required. Please enter a pickup address.'),
      })
      .required('A pickup address is required. Please enter a pickup address.')
      .typeError('A pickup address is required. Please enter a pickup address.'),
    dropOffAddress: yup
      .object({
        line1: yup
          .string()
          .min(1, 'A drop-off address is required. Please enter a drop-off address.')
          .required('A drop-off address is required. Please enter a drop-off address.')
          .typeError('A drop-off address is required. Please enter a drop-off address.'),
      })
      .required('A drop-off address is required. Please enter a drop-off address.')
      .typeError('A drop-off address is required. Please enter a drop-off address.'),
    pickUpDate: yup
      .date()
      .required('A pickup date is required. Please enter a pickup date.')
      .typeError('A pickup date is required. Please enter a pickup date.'),
    pickUpTime: yup
      .object()
      .required('A pickup time is required. Please enter a pickup time.')
      .typeError('A pickup time is required. Please enter a pickup time.'),
    dropOffDate: yup
      .date()
      .test('min-by-date', 'The drop-off date cannot occur before the pickup date.', function (value: any) {
        return value && moment(value).isSameOrAfter(moment(this.parent.pickUpDate), 'day')
      })
      .required('A drop-off date is required. Please enter a drop-off date.')
      .typeError('A drop-off date is required. Please enter a drop-off date.'),
    dropOffTime: yup
      .object()
      .test('min-by-time', 'The drop-off time cannot occur before the pickup time.', function (value: any) {
        if (!this.parent.pickUpDate || !this.parent.pickUpTime || !this.parent.dropOffDate || !value) return true
        const pickUpDate = moment(this.parent.pickUpDate)
        const pickUpTime = moment(this.parent.pickUpTime).year(pickUpDate.year()).dayOfYear(pickUpDate.dayOfYear())
        const dropOffDate = moment(this.parent.dropOffDate)
        const dropOffTime = moment(value).year(dropOffDate.year()).dayOfYear(dropOffDate.dayOfYear())
        return value && dropOffTime.isSameOrAfter(pickUpTime)
      })
      .required('A drop-off time is required. Please enter a drop-off time.')
      .typeError('A drop-off time is required. Please enter a drop-off time.'),
  })
}

export interface MainScreenFormProps {
  values: MainScreenFormValues
  onSubmit: (values: MainScreenFormValues) => void
  riderGroupId: number
  close: () => void
  isNewTrip: boolean
}

export const TripModalMainScreen = ({
  values: { pickUpAddress, dropOffAddress, ...initialValues },
  onSubmit,
  close,
  riderGroupId,
  isNewTrip,
}: MainScreenFormProps) => {
  const classes = useTripModalStyles()
  const [pickupCounty, pickupCountyLoading] = useFillCounty(!isNewTrip ? (pickUpAddress as Address) : undefined)
  const [dropOffCounty, dropOffCountyLoading] = useFillCounty(!isNewTrip ? (dropOffAddress as Address) : undefined)
  if (pickupCountyLoading || dropOffCountyLoading) {
    return <Loading />
  }
  return (
    <Formik
      initialValues={{
        ...initialValues,
        pickUpAddress: isNewTrip
          ? null
          : ({
              ...pickUpAddress,
              county: pickupCounty,
            } as Address),
        dropOffAddress: isNewTrip
          ? null
          : ({
              ...dropOffAddress,
              county: dropOffCounty,
            } as Address),
      }}
      validationSchema={getTripPayloadSchema(!isNewTrip)}
      onSubmit={onSubmit}
      validateOnChange
      isInitialValid={!isNewTrip}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, touched, errors }) => {
        return (
          <Form>
            <FormModalScreen
              onBack={close}
              onNext={submitForm}
              nextText="Next"
              backText="Cancel"
              disabled={isSubmitting}
            >
              <AdvancedPersonAutocomplete
                label={'Rider *'}
                placeholder={'Select a person to be the trip’s rider'}
                groupId={riderGroupId}
                value={values.rider}
                excludeNonGroupMembers={true}
                onChange={rider => setFieldValue('rider', rider)}
                error={touched.rider ? (errors.rider as string) : undefined}
              />
              <Field
                fullWidth
                variant="outlined"
                hideMap
                labelShrink
                label="Pickup Address *"
                placeholder="Enter the address the rider will be picked up"
                component={AddressField}
                name="pickUpAddress"
              />
              <div className={classes.dateTimePickers}>
                <div className={classes.dateTimePicker}>
                  <Field
                    inputVariant="outlined"
                    label="Pickup Date *"
                    higher
                    component={StyledKeyboardDatePicker}
                    name="pickUpDate"
                  />
                </div>

                <div className={classes.dateTimePicker}>
                  <Field
                    inputVariant="outlined"
                    label="Pickup Time *"
                    higher
                    component={StyledKeyboardTimePicker}
                    name="pickUpTime"
                  />
                </div>
              </div>
              <Field
                fullWidth
                variant="outlined"
                hideMap
                labelShrink
                label="Drop-Off Address*"
                placeholder="Enter the address the rider will be dropped off"
                component={AddressField}
                name="dropOffAddress"
              />
              <div className={classes.dateTimePickers}>
                <div className={classes.dateTimePicker}>
                  <Field
                    higher
                    inputVariant="outlined"
                    label="Drop-off Date *"
                    component={StyledKeyboardDatePicker}
                    name="dropOffDate"
                    minDate={moment(values.pickUpDate || undefined)
                      .startOf('day')
                      .toDate()}
                    minDateMessage={'The drop-off date cannot occur before the pickup date.'}
                  />
                </div>

                <div className={classes.dateTimePicker}>
                  <Field
                    higher
                    inputVariant="outlined"
                    label="Drop-off Time *"
                    component={StyledKeyboardTimePicker}
                    name="dropOffTime"
                  />
                </div>
              </div>
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
