import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import Loading from 'components/Loading'
import useStripeAcountStatus from 'donation/hooks/useStripeAcountStatus'
import useOpenStripeConnectPage from 'donation/hooks/useOpenStripeConnectPage'
import './index.scss'

export const StripeAccountWrapper = (props: any) => {
  const { loading: stripeStatusLoading, called, result: stripeAccountStatus } = useStripeAcountStatus()
  // @ts-ignore
  const [openStripeConnectPage, { loading: stripeConnectUrlLoading }] = useOpenStripeConnectPage()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const loading = stripeStatusLoading || !called || stripeConnectUrlLoading

  const openPage = (type:string) => {
    // @ts-ignore
    openStripeConnectPage(type)
  }

  const ConnectModal = () => (
    <Dialog maxWidth={'md'} open={modalOpen} onClose={() => setModalOpen(false)}>
      <DialogTitle style={{ textAlign: 'center', paddingBottom: '0' }}>
        <span className="dialog-title">Stripe Connection</span>
      </DialogTitle>
      <DialogContent style={{ paddingTop: '0' }}>
        <p className='stripe-question'>Do you have an existing Stripe account?</p>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: '15px' }}>
        <Button
          className="orange-button connect-button"
          disabled={stripeConnectUrlLoading}
          onClick={() => openPage('express')}
        >
          No
        </Button>
        <Button
          className="blue-button connect-button"
          disabled={stripeConnectUrlLoading}
          onClick={() => openPage('standard')}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )

  const AccountSetUp = () => (
    <Grid container direction="column">
      <Grid item>
        <h1 className="stripe-connect-header">Donations</h1>
      </Grid>
      <Grid item container justify="center">
        <Grid item xs={8}>
          <h3 className="stripe-connect-sub-header">To get started we need to setup a place for your donations to be deposited. Please set up your payment connection with Stripe.</h3>
        </Grid>
      </Grid>
      <Grid item container justify="center">
        <Button
          className="start-button"
          onClick={() => setModalOpen(true)}
        >
          Get Started
        </Button>
      </Grid>
    </Grid>
  )

  if (loading) {
    return <Loading />
  } else if (modalOpen) {
    return <ConnectModal/>
  } else if (!stripeAccountStatus || !stripeAccountStatus.ready) {
    return <AccountSetUp/>
  } else {
    return <div>{props.children}</div>
  }
}
