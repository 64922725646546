import React, { useEffect } from 'react'
import Loading from 'civic-champs-shared/core/Loading'
import { ViewAnswerSetsContainerProps } from 'civic-champs-shared/question-sets/types'
import { Grid, makeStyles } from '@material-ui/core'
import { useFetchRequirements } from 'civic-champs-shared/question-sets/hooks'
import { QuestionAnswerSetsPagedView } from 'civic-champs-shared/question-sets/components/QuestionAnswerSetsPagedView'

const useStyle = makeStyles(() => ({
  waiverContainer: ({ fullHeight }: { fullHeight: boolean }) => ({
    height: fullHeight ? '90%' : 'auto',
  }),
  answersTitle: {
    textAlign: 'left',
  },
  answersContainer: ({ fullHeight }: { fullHeight: boolean }) => ({
    height: fullHeight ? '100%' : '300px',
    overflowY: 'auto',
    textAlign: 'left',
  }),
  loadingContainer: {
    height: '359px',
  },
}))

export const ProcessMemberAnswerSetContainer = (props: ViewAnswerSetsContainerProps) => {
  const { filters, fullHeight } = props
  const classes = useStyle({ fullHeight })
  const [fetchRequirements, { result: requirements, loading, called }] = useFetchRequirements()

  useEffect(() => {
    if (!!filters) fetchRequirements(filters)
  }, [filters, fetchRequirements])

  if (loading || !called) {
    return (
      <Grid className={classes.loadingContainer}>
        <Loading />
      </Grid>
    )
  }

  return <QuestionAnswerSetsPagedView prerequisites={requirements} fullHeight={fullHeight} />
}
