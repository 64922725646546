import { IntegrationKey, IntegrationProvider } from 'integrations/types'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useSaveIntegrationKey = (): ((props: {
  provider: IntegrationProvider
  token: string
  enabled: boolean
}) => Promise<IntegrationKey>) => {
  const [request] = useFetchRefactored<IntegrationKey>({
    successMessage: 'Integration Saved',
    errorMessage: 'Failed to save integration',
    useNewNotification: true,
  })
  const org = useCurrentOrg()

  return useCallback(
    ({ provider, token, enabled }: { provider: IntegrationProvider; token: string; enabled: boolean }) => {
      return request({
        method: 'put',
        url: `/organizations/${org.id}/integration-keys/${provider}`,
        config: {
          body: {
            token,
            enabled,
          },
        },
      })
    },
    [org.id, request],
  )
}

export default useSaveIntegrationKey
