import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export default function useRevokeInvitation(onSuccess) {
  const [request, { loading, error, result }] = useFetch(
    'Error resending invitation.  Please refresh the page to try again',
  )
  const showSuccess = useSuccessNotification()
  const revokeInvitation = useCallback(
    invitation => {
      return request({
        method: 'put',
        url: `/invitations/${invitation.id}/revoke`,
        onSuccess: result => {
          showSuccess('Successfully revoked invitation')
          onSuccess && onSuccess(result)
        },
      })
    },
    [request, showSuccess, onSuccess],
  )

  return useMemo(
    () => [
      revokeInvitation,
      {
        loading,
        error,
      },
    ],
    [loading, error],
  )
}
