import { isEmpty } from "lodash";

import { GET_WAIVERS, GET_WAIVERS_SUCCEEDED, GET_WAIVERS_FAILED } from "../actions";
import getErrorMessage from '../../utils/getErrorMessage';

export default function(state, action) {
    switch(action.type) {
        case GET_WAIVERS:
            return { 
                ...state,
                getWaivers: { running: true, error: null }
            }
        
        case GET_WAIVERS_SUCCEEDED:
            return {
                ...state,
                hasWaivers: !isEmpty(action.payload.waivers),
                getWaivers: { running: false, error: null }
            }
        
        case GET_WAIVERS_FAILED:
            return {
                ...state,
                getWaivers: {
                    running: false,
                    error: getErrorMessage(action.payload.error)
                }
            }
        
        default:
            return state;
    }
}