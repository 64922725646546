import StyledOutlinedTextFieldComponent from './StyledOutlinedTextFieldComponent'
import InputMask from 'react-input-mask'
import React from 'react'
import { fieldToTextField, TextFieldProps } from 'formik-material-ui'

export default function StyledOutlinedPhoneField(props: TextFieldProps) {
  return (
    // @ts-ignore
    <InputMask {...fieldToTextField(props)} mask="+1 (999) 999-9999" maskChar=" " alwaysShowMask={false}>
      {(inputProps: any) => <StyledOutlinedTextFieldComponent {...inputProps} />}
    </InputMask>
  )
}
