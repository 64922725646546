import * as types from '../types'

export interface MatchesState {
  loading: boolean
  matches: []
  error: any
}

const initialState = {
  loading: false,
  matches: [] as any,
  error: null
}

export default function matchesReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.MATCHES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.MATCHES_LIST_RECEIVE:
      return {
        loading: false,
        error: null,
        matches: [...action.payload]
      }
    case types.MATCHES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    case types.MATCHES_LIST_CLEAR:
      return {
        loading: false,
        error: null,
        matches: []
      }  
    default:
      return state
  }
}
