import { History } from 'history'
import { useCallback } from 'react'
import isUndefined from 'lodash/isUndefined'
import qs from 'query-string'
import { isObject } from 'lodash'

export const useSetFilterRowsHistory = (history: History | undefined) => {
  return useCallback(
    rows => {
      if (isUndefined(history)) return
      const fragments = rows.reduce((acc: any, row: any) => {
        const key = row.value.hasOwnProperty('operator') ? `${row.column}:${row.value.operator}` : row.column
        let value = row.value.hasOwnProperty('operand') ? row.value.operand : row.value
        if (isObject(value)) {
          value = JSON.stringify(value)
        }
        return { ...acc, [key]: value }
      }, {})
      let path = history.location.pathname
      if (rows.length) path += '?' + qs.stringify(fragments, { arrayFormat: 'bracket' })
      history.push(path, { filterRows: [...rows] })
    },
    [history],
  )
}
export default useSetFilterRowsHistory
