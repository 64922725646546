import React from 'react'
import { Link } from 'react-router-dom'
import { NotificationBlastSummary } from 'messages/types'
import { useMessageOverviewStyles } from 'messages/hooks/useMessageOverviewStyles'

export const MessageAttachments = ({ info: { attachments } }: { info: NotificationBlastSummary }) => {
  const classes = useMessageOverviewStyles()

  const { questionnaires = [] } = attachments || {}
  if (!questionnaires.length) {
    return null
  }

  return (
    <div className={classes.attachments}>
      <div>Attachments:</div>
      <div>
        {questionnaires.map(({ questionSet, survey }) => {
          const id = survey ? survey.id : questionSet.id
          return (
            <Link key={id} className={classes.attachmentLink} to={`/questionnaires/${id}`}>
              {questionSet.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
