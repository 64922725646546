import { map } from 'lodash'
import { useReducer, useEffect } from 'react'

import getErrorMessage from 'utils/getErrorMessage'
import { getPersonReferences } from './api'
import { Action, ActionType, MergeEntry, State, Status } from './types'
import { useDoMerge } from './useDoMerge'

export interface ManagementActions {
  swapPeople: () => void,
  merge: () => Promise<boolean>
}

const init = (people: any[]): State =>  ({ 
  status: Status.Uninitialized,
  people: map(people, person => ({ person }))
})

const reducer = (state: State, action: Action) => {
  switch(action.type) {
    case ActionType.StartLoading:
      return { ...state, status: Status.Loading }

    case ActionType.LoadFailed:
      return { ...state, status: Status.LoadFailed, error: action.payload.error }

    case ActionType.LoadSuccess:
      const { referenceLists } = action.payload
      return { 
        ...state, 
        status: Status.Ready,  
        people: map(state.people, (entry, index) => ({ ...entry, referenceList: referenceLists[index] }))
      }
    
    case ActionType.SwapPeople:
      return { ...state, people: [state.people[1], state.people[0]], error: undefined }
    
    case ActionType.StartMerge:
      return { ...state, status: Status.Merging, error: undefined }

    case ActionType.MergeCanceled:
      return { ...state, status: Status.Ready }
    
    case ActionType.MergeFailed:
      return { ...state, status: Status.Ready, error: action.payload.error }
  }
}

export const useMergePeopleManagement = (people: any[]): [State, ManagementActions] => {
  const [state, dispatch] = useReducer(reducer, people, init)
  const doMerge = useDoMerge(dispatch)

  const startLoading = async () => {
    dispatch({ type: ActionType.StartLoading })
    try {
      dispatch({
        type: ActionType.LoadSuccess,
        payload: {
          referenceLists: await Promise.all(
            map<MergeEntry, Promise<any>>(
              state.people,
              ({ person }) => getPersonReferences(person)
            )
          )
        }
      })
    } catch(error) {
      dispatch({ 
        type: ActionType.LoadFailed,
        payload: { 
          error: getErrorMessage(error)
        }
      })
    }
  }

  useEffect(() => {
    if(state.status == Status.Uninitialized) {
      startLoading();
    }
  }, [state.status])

  const actions = {
    swapPeople: () => { dispatch({ type: ActionType.SwapPeople }) },
    merge: () => {
      const [replacing, replaced] = state.people
      return doMerge(replacing.person, replaced.person)
    }
  }

  return [state, actions]
}