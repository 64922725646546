import React from 'react'
import './index.scss'
import classNames from 'classnames'

interface InputProps {
  value: string | any
  onChange: (event: any) => void
  className?: string
  autoCapitalize?: string
  name?: string
  type?: string
  placeholder?: string
  onEnterPress?: (event: any) => void
  disabled?: boolean
  label?: string
  errorMessage?: string
  onBlur?: (event: any) => void
  isInteger?: boolean
}

const KEY_CODE_OF_DOT = 46

function InputComponent(props: InputProps) {
  const {
    value,
    className,
    name,
    onChange,
    onEnterPress,
    type,
    placeholder,
    disabled,
    errorMessage,
    onBlur,
    autoCapitalize,
    isInteger
  } = props
  const inputClasses = classNames('input_component_wrapper', {
    [`${className}`]: className,
    'input_error': props.errorMessage
  })
  const onKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      onEnterPress && onEnterPress(event.target.value)
    }
    if (type === 'number' && isInteger && event.charCode === KEY_CODE_OF_DOT) {
      event.preventDefault()
    }
  }
  return (
    <div className={inputClasses}>
      {props.label && <label className="input_label">{props.label}</label>}
      <input
        type={type}
        name={name}
        value={value}
        autoCapitalize={autoCapitalize || 'none'}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
      />
      {errorMessage && <div className="error_message">{errorMessage}</div>}
    </div>
  )
}

export default InputComponent
