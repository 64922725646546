import { connect } from 'react-redux'
import CreateEvent from '../../scenes/create-event'
import { changeStep, setEventField, clearEventForm } from '../reducers/CreateEvent'
import { isFeatureEnabled } from '../../../core/feature/selectors'

function MSTPCreateEvent(state) {
  return {
    createEventForm: state.CreateEvent,
    isQuestionnaireAsSurveyEnabled: isFeatureEnabled(state, 'QuestionnaireAsSurvey'),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    changeStep: step => dispatch(changeStep(step)),
    setEventField: (key, val) => dispatch(setEventField(key, val)),
    clearEventForm: () => dispatch(clearEventForm()),
  }
}

export default connect(MSTPCreateEvent, mapDispatchToProps)(CreateEvent)
