import React from 'react'
import { Button, CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core'
import { Select, TextField } from 'formik-material-ui'
import { Field, Formik } from 'formik'
import yup from 'civic-champs-shared/utils/yup'
import Loading from 'components/Loading'
import BackLink from '../../donation/campaigns/components/BackLink'
import PhoneField from 'civic-champs-shared/formik/components/PhoneField'
import usePost from 'civic-champs-shared/api/hooks/usePost'
import { useCurrentOrg, useHasRole } from '../../auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

const PersonContactType = {
  EMAIL: 'email',
  MOBILE_PHONE: 'mobile-phone',
  HOME_PHONE: 'home-phone',
}

const addContactSchema = yup
  .object({
    personContactType: yup.string().oneOf(Object.values(PersonContactType)),
    confirmed: yup.boolean().default(false),
    email: yup
      .string()
      .email()
      .nullable()
      .notRequired(),
    phoneNumber: yup
      .string()
      .phoneNumber()
      .nullable()
      .notRequired(),
  })
  .atLeastOneOf(['email', 'phoneNumber'])

const initialValues = { personContactType: PersonContactType.EMAIL, confirmed: false, email: '', phoneNumber: '' }

export const PersonAddContact = props => {
  const personId = props.match.params.id
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const currentOrg = useCurrentOrg()
  const [addContact, { loading }] = usePost({
    successMessage: 'Successfully added contact',
    errorMessage: 'Error creating contact',
    configMapper: body => ({
      url: `/organizations/${currentOrg.id}/persons/${personId}/person-contacts`,
      config: { body },
    }),
  })
  if (loading) {
    return <Loading />
  }
  const handleFormikSubmit = async values => {
    await addContact(values)
    props.history.push(`/people/${personId}`)
  }
  return (
    <>
      <BackLink to={`/people/${personId}`} text="Back to Person" />
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleFormikSubmit(addContactSchema.cast(values))}
        validationSchema={addContactSchema}
      >
        {({ dirty, values, handleSubmit, isSubmitting, isValid }) => {
          const disabled = !dirty || !isValid || (!values.phoneNumber && !values.email) || isSubmitting
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h4">Add Contact</Typography>
                </Grid>
                <Grid item>
                  <label>Contact Type</label>
                  <Field component={Select} variant="outlined" name="personContactType" label="Contact Type">
                    <MenuItem value={PersonContactType.EMAIL}>Email</MenuItem>
                    <MenuItem value={PersonContactType.MOBILE_PHONE}>Mobile Phone</MenuItem>
                    <MenuItem value={PersonContactType.HOME_PHONE}>Home Phone</MenuItem>
                  </Field>
                </Grid>
                {isInternalSuperAdmin && (
                  <Grid item>
                    <label>Confirmed?</label>
                    <Field component={Select} variant="outlined" name="confirmed" label="Confirmed">
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Field>
                  </Grid>
                )}
                <Grid item>
                  {values.personContactType === PersonContactType.EMAIL ? (
                    <Field variant="outlined" component={TextField} name="email" label="Contact" />
                  ) : (
                    <Field component={PhoneField} name="phoneNumber" label="Contact" variant="outlined" />
                  )}
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={disabled}>
                    {loading || (isSubmitting && <CircularProgress size={24} color="primary" />)}
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
