import BaseService from '../services/base.service'

type ID = number
type EVENT_TYPE = 'draft' | 'publish'

const baseService = new BaseService()

const base = (rest: string) => `${baseService.getEnvVar('API_ENDPOINT')}/${rest}`

const API_ENDPOINTS = {
  EventDetail: {
    addVolunteer: (orgId: ID) => base(`v2/organizations/${orgId}/volunteers`),
    registerVolunteer: (encodedOccurrence: string, returnRegistrant = false) =>
      base(
        `opportunity_instances/${encodedOccurrence}/register/volunteer${
          returnRegistrant ? '?returnRegistrant=true' : ''
        }`,
      ),
    findVolunteer: ({ orgId, search }: { orgId: ID; search: string }) =>
      base(`organizations/${orgId}/people?search=${search}`),
  },
  Events: {
    occurrenceRefactorCancelEvent: ({
      encodedOccurrence,
      cancelType,
    }: {
      encodedOccurrence: string
      cancelType?: string
    }) => base(`opportunity_instances/${encodedOccurrence}?cancelType=${cancelType}`),
    occurrenceRefactorPublishEvent: (orgId: ID) => base(`organizations/${orgId}/opportunities/publish`),
    occurrenceRefactorSaveDraftEvent: (orgId: ID) => base(`organizations/${orgId}/opportunities/draft`),
    occurrenceRefactorAdminSearchEvents: (orgId: ID, params: string) =>
      base(`organizations/${orgId}/opportunity_instances?${params}`),
    getOccurrence: ({
      encodedOccurrence,
      populateRecurrenceInfo,
    }: {
      encodedOccurrence: string
      populateRecurrenceInfo: boolean
    }) => base(`opportunity_instances/${encodedOccurrence}/?populateRecurrenceInfo=${populateRecurrenceInfo}`),
    getOccurrenceQuestionSets: (encodedOccurrence: string, isQuestionnaireAsSurveyEnabled: boolean = false) =>
      base(
        `opportunity_instances/${encodedOccurrence}/${isQuestionnaireAsSurveyEnabled ? 'surveys' : 'question-sets'}`,
      ),
    getOccurrenceGroups: (encodedOccurrence: string) => base(`opportunity_instances/${encodedOccurrence}/groups`),
    occurrenceRefactorUpdateEvent: (encodedOccurrence: string, eventType: EVENT_TYPE) =>
      base(`opportunity_instances/${encodedOccurrence}/${eventType}`),
  },
  Volunteers: {
    getAllVolunteers: (encodedOccurrence: string) => base(`opportunity_instances/${encodedOccurrence}/registrations`),
    searchVolunteersOccurrenceRefactor: (encodedOccurrence: string, query: string) =>
      base(`opportunity_instances/${encodedOccurrence}/registrations/?${query}`),
    exportCsvOccurrenceRefactor: (encodedOccurrence: string) =>
      base(`opportunity_instances/${encodedOccurrence}/registration/export`),
  },
}

export default API_ENDPOINTS
