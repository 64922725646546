import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { Person } from '../../civic-champs-shared/common/types'
import { useFetchOfferSignups } from './useFetchOfferSignups'
import useCancelOfferSignupsPrompt from './useCancelOfferSignupsPrompt'
import { useShowAddOfferPrompt } from './useShowAddSignupsPromps'
import { CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'

export interface OfferSignup {
  id: number
  organizationId: number
  opportunityId: number
  opportunityOccurrenceId: number
  person: Person
  createdAt: Date
  deletedAt?: Date | null
  groups: string
}

export type UseOfferSignupCollectionReturnValue = [
  {
    signups: OfferSignup[]
    loading: boolean
    error: Error | null
    initiallyLoaded: boolean
  },
  {
    addSignUps: () => Promise<void>
    cancelSignUps: (signups: OfferSignup[]) => Promise<void>
  },
  CollectionEventListeners<OfferSignup>,
]

export const useOfferSignupCollection = (opportunityId: number): UseOfferSignupCollectionReturnValue => {
  const { fetchOfferSignups, loading, error } = useFetchOfferSignups(opportunityId)
  const [signups, operations, events] = useRemoteCollection<OfferSignup>()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerRemoveMany, eagerAdd } = operations
  const getDocumentTypes = useCallback(async () => {
    const signups = await fetchOfferSignups()

    syncCollection(signups)
    setInitiallyLoaded(true)
  }, [fetchOfferSignups, syncCollection])

  useEffect(() => {
    getDocumentTypes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addSignUps = useShowAddOfferPrompt(opportunityId, eagerAdd)
  const cancelSignUps = useCancelOfferSignupsPrompt(opportunityId, eagerRemoveMany)

  return [
    {
      signups,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addSignUps,
      cancelSignUps,
    },
    events,
  ] as UseOfferSignupCollectionReturnValue
}

export default useOfferSignupCollection
