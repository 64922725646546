import React, { useState } from 'react'
import { Grid, Typography, Paper, Button } from '@material-ui/core'
import useRefundDonation from 'donation/hooks/useRefundDonation'
import Loading from 'components/Loading'
import format from 'civic-champs-shared/utils/format'
import BackLink from '../campaigns/components/BackLink'
import { get } from 'lodash'
import { StripeAccountWrapper } from './StripeAccountWrapper'
import DonationStatusDisplay from '../campaigns/components/DonationStatusDisplay'
import { DonationStatus } from '../campaigns/interface'
import { Donation } from '../../champion/utils/interface'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'

const getIntervalFriendly = (interval: string) => {
  const lookup = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly',
  }
  return get(lookup, interval, 'Unknown frequency')
}

export const DonationDetail = (props: any) => {
  const id = props.match.params.id
  const [donation, setDonation] = useState<any>(null)
  const [, { loading }] = useGetOnMount<Donation>({  url: `/donations/${id}`, onSuccess: setDonation })
  const { refundDonation, loading: refundLoading } = useRefundDonation(setDonation)
  const canRefund = donation && donation.status === DonationStatus.PAID

  if (loading || refundLoading) {
    return <Loading />
  }
  return (
    <StripeAccountWrapper>
      <BackLink to={'/donations/overview'} text="Back to Donations" />
      <Grid container direction="column">
        <Grid container item sm={8} justify="flex-end" direction="row">
          <Grid item>
            <DonationStatusDisplay donation={donation} />
          </Grid>
        </Grid>
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Donor</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>First Name</strong>
                  <p>{donation.person.givenName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Last Name</strong>
                  <p>{donation.person.familyName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Email Address</strong>
                  <p>{donation.person.email || '-'}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Mobile Phone</strong>
                  <p>{donation.person.phoneNumber ? format.phoneNumber(donation.person.phoneNumber) : '-'}</p>
                </Grid>
              </Grid>
              {donation.person.homePhoneNumber && (
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <strong>Home Phone</strong>
                    <p>{donation.person.homePhoneNumber ? format.phoneNumber(donation.person.homePhoneNumber) : '-'}</p>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Payment</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid container item>
                  <Grid item sm={4}>
                    <strong>Amount</strong>
                    <p>${format.fixedPoint(donation.amountInCents / 100, 2)}</p>
                  </Grid>
                  <Grid item sm={4}>
                    <strong>Fees</strong>
                    <p>${format.fixedPoint(donation.fees / 100, 2)}</p>
                  </Grid>
                  <Grid item sm={4}>
                    <strong>Net</strong>
                    <p>${format.fixedPoint((donation.amountInCents - donation.fees) / 100, 2)}</p>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item sm={4}>
                    <strong>Date</strong>
                    <p>{format.datetime(donation.createdAt)}</p>
                  </Grid>
                  <Grid item sm={4}>
                    <strong>Campaign</strong>
                    <p>{get(donation, 'campaign.name', '')}</p>
                  </Grid>
                  <Grid item sm={4}>
                    <strong>Payment Type</strong>
                    <p>{donation.subscription ? 'Recurring' : 'One-time'}</p>
                    {donation.subscription && donation.subscription.interval && (
                      <p>
                        <i>({getIntervalFriendly(donation.subscription.interval)})</i>
                      </p>
                    )}
                  </Grid>
                </Grid>
                {/* <Grid container item>
              <Grid item sm={4}>
                <strong>Method of Payment</strong>
              </Grid>
              <Grid item sm={4}>
                <strong>Channel</strong>
              </Grid>
            </Grid> */}
              </Grid>
            </Paper>
          </Grid>
          {/* <Grid item sm={12} container justify='space-between'>
        <Button color='secondary' variant='contained'>Print</Button>
        <Button color='primary' variant='contained'>Issue Refund</Button>
      </Grid> */}
        </Grid>
        {canRefund && (
          <Grid container item sm={8} spacing={2}>
            <Grid item sm={12}>
              <Typography variant="h5">Actions</Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper variant="outlined" style={{ padding: 20 }}>
                <Grid container spacing={2}>
                  <Grid container item>
                    <Grid item sm={4}>
                      <Button type="button" variant="contained" onClick={() => refundDonation(id)}>
                        Refund
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </StripeAccountWrapper>
  )
}
