import ConfirmationDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React from 'react'

export const ConfirmSurveyChangesDialog = ({ showing, complete }: any) => (
  <ConfirmationDialog
    showing={showing}
    close={() => complete(false)}
    complete={() => complete(true)}
    title="Are you sure you want to make these changes?"
    text=" There is currently no way to revert to a previous version. If the changes are extensive, creating an entirely new survey is recommended."
    cancelText="Cancel"
    confirmText="Continue with Edit"
  />
)

export default ConfirmSurveyChangesDialog
