import React from 'react'
import { useAddSMSCredits } from '../hooks/useAddSMSCredits'
import { useHistory } from 'react-router'
import yup from 'civic-champs-shared/utils/yup'
import { Button, Grid, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import Loading from 'civic-champs-shared/core/Loading'
import { useCurrentOrg } from '../../auth/hooks'

const validationSchema = yup.object({
  smsCredits: yup
    .number()
    .integer()
    .positive(),
})

export default function AddSMSCredits() {
  const [addSMSCredits, { loading }] = useAddSMSCredits()
  const currentOrg = useCurrentOrg()
  const history = useHistory()
  const handleSubmit = async ({ smsCredits }) => {
    await addSMSCredits(smsCredits)
    history.push('/custom-messages')
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Formik initialValues={{ smsCredits: 0 }} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, isValid }) => {
        return (
          <Form>
            <Grid container spacing={2} direction="column">
              <Grid>
                <Typography variant="h4">Give Free SMS Credits to {currentOrg.name}</Typography>
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="smsCredits"
                  type="number"
                  label="SMS Credits"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" disabled={!isValid || isSubmitting || !dirty}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
