import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import cn from 'classnames'
import { RadioGroup, TextField } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
  },
  submit: {
    margin: '16px 16px 0',
  },
}))

export default function CompleteTaskPrompt(props) {
  const promptClasses = usePromptStyles()
  const classes = useStyles()
  const { showing, close, complete, submitting } = props

  const validate = values => {
    const errors = {}
    if (values.approval === 'declined' && !values.description) {
      errors.description = 'Required if request is cancelled.'
    }
    return errors
  }

  const handleSubmit = () => {
    complete()
  }

  return (
    <Dialog className={cn({ fullScreen: true })} open={showing} onClose={close}>
      <DialogTitle className={promptClasses.windowedActionContainer}>
        <Typography className={promptClasses.title}>Complete Request</Typography>
        <IconButton disabled={submitting} onClick={close} className={promptClasses.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={cn(classes.content, promptClasses.windowedContentContainer)}>
        <Formik initialValues={{ approval: 'approved', description: '' }} onSubmit={handleSubmit} validate={validate}>
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <Field component={RadioGroup} name="approval">
                <FormControlLabel
                  value="approved"
                  control={<Radio disabled={isSubmitting} />}
                  label="Finish request"
                  disabled={isSubmitting}
                />
                <FormControlLabel
                  value="declined"
                  control={<Radio disabled={isSubmitting} />}
                  label="This request has been cancelled"
                  disabled={isSubmitting}
                />
              </Field>
              <Field
                component={TextField}
                label="Add reasons here"
                variant="outlined"
                name="description"
                disabled={values.approval !== 'declined' || isSubmitting}
                fullWidth
                multiline
                rows={3}
              />
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.submit}
                disabled={!isValid || isSubmitting}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
