import React, { useCallback } from 'react'
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import ReactGoogleMap from 'components/ReactGoogleMap'
import { MIN_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import ExpandingText from 'components/ExpandingText'
import { mapCoordinatesArrayToObject } from 'utils/event'
import moment from 'moment'
import format from 'civic-champs-shared/utils/format'

const useStyles = makeStyles(theme => ({
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    lineHeight: '2rem',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  locationMap: {
    width: '100%',
    height: '240px',
    marginTop: theme.spacing(2),
  },
  actionButton: {
    marginTop: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:first-child': {
      'margin-left': '0',
    },
    '&:last-child': {
      'margin-right': '0',
    },
  },
}))

const Text = styled(Typography)({ lineHeight: '2rem' })

const Header = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  lineHeight: '1.5rem',
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

interface TripReviewProps {
  values: any
  onBack: Function
  onCreate: Function
  rider: any
}

const TripLocation = ({ location }: any, classes: any) => {
  return (
    <>
      <Text>{location.address.line1}</Text>
      {location.address.line2 && <Text>{location.address.line2}</Text>}
      <Text>
        {location.address.city}, {location.address.state} {location.address.zipCode}
      </Text>
    </>
  )
}

export const TripReview: React.FC<TripReviewProps> = props => {
  const { values, onBack, onCreate, rider } = props
  const classes = useStyles()

  const handleSubmitTripRequest = useCallback(() => {
    onCreate(values)
  }, [onCreate, values])
  return (
    <div>
      <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.row}>
        <Grid item>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item>
          <Header>Status</Header>
          <Text>Pending Creation</Text>
        </Grid>
      </Grid>

      <Divider />

      <Grid container item direction="row" spacing={2} className={classes.row}>
        <Grid item container xs={4}>
          <Grid item>
            <Header>Rider</Header>
            <Text>
              {rider.givenName} {rider.familyName}
            </Text>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Header>Rider Phone Number</Header>
          <Text>{rider.phoneNumber}</Text>
        </Grid>
      </Grid>

      <Divider />

      {values.tripOrders.map((tripOrder: any, index: number) => {
        return (
          <div key={`order-${index}`}>
            <Grid
              key={`order-${index}-details-container`}
              container
              item
              direction="row"
              spacing={2}
              className={classes.row}
            >
              <Grid key={`order-${index}-pickup-details`} item xs={4}>
                <Typography variant="h5">Order Details</Typography>
                <Header>Pickup Location</Header>
                <TripLocation location={tripOrder.pickupLocation} classes={classes} />
                <Header>Pickup Time</Header>
                <Text>{format.datetime(tripOrder.pickupAt)}</Text>
              </Grid>
              <Grid item xs={8} key={`order-${index}-pickup-location`}>
                <div className={classes.locationMap} key={`google-view-pickup-${index}`}>
                  <ReactGoogleMap
                    key={`order-${index}-pickup-map`}
                    zoom={17}
                    geofenceRadius={MIN_RADIUS_IN_METER}
                    coordinates={
                      tripOrder.pickupLocation.geofencing.position.coordinates &&
                      mapCoordinatesArrayToObject(tripOrder.pickupLocation.geofencing.position.coordinates)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={4} key={`order-${index}-drop-off-details`}>
                <Header>Drop Off Location</Header>
                <TripLocation location={tripOrder.dropOffLocation} classes={classes} />
                <Header>Drop Off Time</Header>
                <Text>{format.datetime(tripOrder.dropOffAt)}</Text>
              </Grid>
              <Grid item xs={8} key={`order-${index}-drop-off-location`}>
                <div className={classes.locationMap} key={`google-view-drop-off-${index}`}>
                  <ReactGoogleMap
                    key={`order-${index}-drop-off-map`}
                    zoom={17}
                    geofenceRadius={MIN_RADIUS_IN_METER}
                    coordinates={
                      tripOrder.dropOffLocation.geofencing.position.coordinates &&
                      mapCoordinatesArrayToObject(tripOrder.dropOffLocation.geofencing.position.coordinates)
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={8} key={`order-${index}-description`}>
                <Header>Description</Header>
                <ExpandingText text={tripOrder.description} />
              </Grid>
            </Grid>
            {index + 1 !== values.tripOrders.length && <Divider />}
          </div>
        )
      })}
      <Grid>
        <Button onClick={() => onBack()} className={classes.actionButton}>
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmitTripRequest}
          className={classes.actionButton}
        >
          Submit
        </Button>
      </Grid>
    </div>
  )
}
