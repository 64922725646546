import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useInvitation(id, onSuccess) {
  const [request, { loading, error, result, called }] = useFetch(
    'Error fetching people.  Please refresh the page to try again',
  )
  const getInvitation = useCallback(
    id => {
      return request({ method: 'get', url: `/invitations/${id}/view`, onSuccess })
    },
    [request, onSuccess],
  )

  useEffect(() => {
    getInvitation(id)
  }, [id])

  return useMemo(() => [result, { loading: loading || !called, error }], [result, loading, called, error])
}
