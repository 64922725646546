import React, {useMemo} from 'react'
import {filterByRequireRules} from 'civic-champs-shared/utils/filterByRequireRules'
import {useHasRole} from 'auth/hooks'
import {INTERNAL_ADMIN} from 'civic-champs-shared/auth/utils/permissions'
import useCredentialIDApprovalButtonCell from './useCredentialIDApprovalButtonCell'

const useUserCredentialIDColumns = (onGotoApprovals, credentialType) => {

  const isInternalAdmin = useHasRole(INTERNAL_ADMIN);
  const ApprovalButtonCell = useCredentialIDApprovalButtonCell(onGotoApprovals, credentialType)

  return useMemo(() => filterByRequireRules([
    {
      Header: 'ID',
      accessor: 'id',
      requires: 'isInternalAdmin'
    },
    {id: 'firstName', Header: 'First Name', accessor: 'user.givenName'},
    {id: 'lastName', Header: 'Last Name', accessor: 'user.familyName'},
    {
      id: '$approvals',
      Header: 'Actions',
      Cell: ApprovalButtonCell,
      disableSortBy: true
    }
    ], {
    isInternalAdmin
  }), [ApprovalButtonCell, isInternalAdmin, credentialType])
}

export default useUserCredentialIDColumns;