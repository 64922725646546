import decodeJwtToken from './decodeJwtToken'

export const isFirstLoginWithToken = () => {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const payload = decodeJwtToken(token)
      if (payload.exp * 1000 > Date.now()) {
        return !localStorage.getItem('firstLogin')
      }
    } catch (e) {}
  }
  return false
}
