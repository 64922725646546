import { useMemo } from 'react'

export interface GroupedOptions<T> {
  [group: string]: T[]
}

interface DisplayAreaInfo<T> {
  topPlaceholderHeight: number
  bottomPlaceholderHeight: number
  visibleOptions: T[]
  visibleGroupedOptions: GroupedOptions<T>
}

interface DisplayAreaInfoProps<T> {
  options: T[]
  scrollTopPosition: number
  height: number
  rowHeight: number
  groupHandler?: (options: T[]) => GroupedOptions<T>
  groupTitleHeight?: number
  groupCount?: number
  optionsCount?: number
}

const getDisplayPosition = ({
  scrollTopPosition,
  height,
  rowHeight,
  groupTitleHeight,
  groupLengths,
}: {
  scrollTopPosition: number
  height: number
  rowHeight: number
  groupTitleHeight: number
  groupLengths: number[]
}): [number, number] => {
  if (scrollTopPosition <= height) {
    return [0, 0]
  } else {
    let hideHeight = scrollTopPosition - height
    let i = 0
    for (const groupLength of groupLengths) {
      if (hideHeight <= groupTitleHeight) {
        return [i, 0]
      }
      hideHeight -= groupTitleHeight
      if (hideHeight > groupLength * rowHeight) {
        hideHeight -= groupLength * rowHeight
      } else {
        return [i, Math.floor(hideHeight / rowHeight)]
      }
      i++
    }
  }
  const lastGroup = groupLengths.length - 1
  return [lastGroup, groupLengths[lastGroup] - 1]
}

export const useDisplayAreaInfo = <T>({
  options,
  scrollTopPosition,
  height,
  rowHeight,
  groupHandler,
  groupTitleHeight,
  optionsCount,
  groupCount,
}: DisplayAreaInfoProps<T>) =>
  useMemo<DisplayAreaInfo<T>>(() => {
    const optionsLength = optionsCount ?? options.length
    if (typeof groupHandler === 'undefined' || typeof groupTitleHeight === 'undefined') {
      const rowsInHeight = height / rowHeight
      const displayFrom = scrollTopPosition <= height ? 0 : Math.floor((scrollTopPosition - height) / rowHeight)
      const rowsAbove = displayFrom > rowsInHeight ? rowsInHeight : displayFrom
      let displayTo = displayFrom + rowsInHeight * 2 + rowsAbove
      if (displayTo > optionsLength) {
        displayTo = optionsLength
      }
      const topPlaceholderHeight = displayFrom * rowHeight
      const bottomPlaceholderHeight = (optionsLength - displayTo) * rowHeight
      const visibleOptions = options.slice(displayFrom, displayTo)
      return { visibleOptions, topPlaceholderHeight, bottomPlaceholderHeight, visibleGroupedOptions: {} }
    } else {
      const groupedOptions = groupHandler(options)
      const groups = Object.keys(groupedOptions)
      const groupLengths = Object.values(groupedOptions).map(group => group.length)
      if (typeof optionsCount !== 'undefined' && typeof groupCount !== 'undefined' && optionsCount > options.length) {
        const notLoadedGroupCount = groupCount - groups.length
        const additionalOptionsInLastGroup = optionsCount - options.length - notLoadedGroupCount
        groupLengths[groupLengths.length - 1] += additionalOptionsInLastGroup
        for (let i = 0; i < notLoadedGroupCount; i++) {
          groupLengths.push(1)
        }
      }
      const [displayFromGroup, displayFromOption] = getDisplayPosition({
        scrollTopPosition,
        height,
        rowHeight,
        groupTitleHeight: groupTitleHeight,
        groupLengths,
      })
      const [displayToGroup, displayToOption] = getDisplayPosition({
        scrollTopPosition: scrollTopPosition + height * 3,
        height,
        rowHeight,
        groupTitleHeight: groupTitleHeight,
        groupLengths,
      })
      const visibleGroups = groups.slice(displayFromGroup, displayToGroup + 1)
      const visibleGroupedOptions = visibleGroups.reduce((acc, group) => {
        switch (group) {
          case groups[displayFromGroup]:
            return {
              ...acc,
              [group]: groupedOptions[group].slice(
                displayFromOption,
                displayFromGroup === displayToGroup ? displayToOption + 1 : undefined,
              ),
            }
          case groups[displayToGroup]:
            return {
              ...acc,
              [group]: groupedOptions[group].slice(0, displayToOption + 1),
            }
          default:
            return {
              ...acc,
              [group]: groupedOptions[group],
            }
        }
      }, {} as GroupedOptions<T>)
      let topPlaceholderHeight = displayFromGroup * groupTitleHeight + displayFromOption * rowHeight
      for (let i = 0; i < displayFromGroup; i++) {
        topPlaceholderHeight += groupLengths[i] * rowHeight
      }
      let bottomPlaceholderHeight = (groupLengths[displayToGroup] - displayToOption - 1) * rowHeight
      for (let i = displayToGroup + 1; i < groupLengths.length; i++) {
        bottomPlaceholderHeight += groupLengths[i] * rowHeight + groupTitleHeight
      }
      return { visibleOptions: [], topPlaceholderHeight, bottomPlaceholderHeight, visibleGroupedOptions }
    }
  }, [optionsCount, options, groupHandler, groupTitleHeight, height, rowHeight, scrollTopPosition, groupCount])
