import { makeStyles } from '@material-ui/styles'

export const useReflectionCalendarTableStyles = makeStyles({
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    borderBottom: '1px solid #EEF0FA',
    '&:first-child': {
      borderTop: '1px solid #EEF0FA',
    },
  },
  cell: {
    width: '88px',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    height: '63px',
    padding: '2px 5px',
    background: '#FFFFFF',
    borderRight: '1px solid #EEF0FA',
    '&:first-child': {
      borderLeft: 'none',
    },
    cursor: 'pointer',
    '&$selected': {
      cursor: 'default',
    },
  },
  header: {
    '& > $cell': {
      fontWeight: 700,
      textAlign: 'center',
      height: '26px',
      padding: '5px 0',
    },
  },
  date: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    padding: '2px',
    width: '22px',
    height: '22px',
    textAlign: 'center',
    border: 0,
    '&$selected': {
      borderRadius: '100px',
      background: '#5691EC',
      color: 'white',
    },
  },
  selected: {},
  today: {
    color: '#0F5DB5',
    fontWeight: 700,
    letterSpacing: '0.1px',
  },
  smiley: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  counter: {
    display: 'flex',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100px',
    background: '#A8C7FF',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    width: '18px',
    height: '18px',
  },
  relative: {
    position: 'relative',
  },
})
