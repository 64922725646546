import React, { useCallback, useMemo } from 'react'
import { Credential, CredentialFull } from 'civic-champs-shared/question-sets/types'
import { Column } from 'types/react-table'
import { makeStyles } from '@material-ui/styles'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { Cell, CellProps } from 'react-table'
import { Link } from 'react-router-dom'
import { DateCell, EditCell, EditHeader, NumberCell } from 'core/table/cells'
import { createDateValueSort } from 'components/table/sort'
import { RoleWithReporting } from 'volunteer-role/types'
import { getExpirationRate } from 'credential/helpers'

export const useStyles = makeStyles({
  selectColumn: {
    '&>div': {
      width: '30px',
    },
  },
  titleColumn: {
    '&>div>div': {
      width: '190px',
      marginRight: '50px',
    },
  },
  ellipsisOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  eventsColumn: {
    '&>div': {
      width: '50px',
      marginRight: '30px',
    },
  },
  volunteersColumn: {
    '&>div': {
      width: '70px',
      marginRight: '30px',
    },
  },
  dateCreatedColumn: {
    '&>div': {
      width: '84px',
      marginRight: '30px',
    },
  },
  lastModifiedColumn: {
    '&>div': {
      width: '84px',
      marginRight: '50px',
    },
  },
  expirationColumn: {
    '&>div': {
      width: '120px',
      marginRight: '50px',
    },
  },
  editColumn: {
    '&>div': {
      width: '24px',
    },
  },
})

export const TitleCell = ({ value, row }: Cell<any, string>) => {
  const classes = useStyles()
  return (
    <div className={classes.ellipsisOverflow}>
      <Link title={value} className={classes.link} to={`/credentials/${row.original.credential.id}`}>
        {value}
      </Link>
    </div>
  )
}

export const ExpirationRateColumn = ({ value }: Cell<Credential>) => {
  return getExpirationRate(value)
}

export const useWaiverColumns = (updateCredential: (id: number) => void) => {
  const classes = useStyles()
  const handleEdit = useCallback((row: CredentialFull) => updateCredential(row.credential.id), [updateCredential])
  return useMemo(
    () => [
      {
        id: 'title',
        Header: 'Title',
        accessor: 'version.title',
        Cell: TitleCell,
        filter: DYNAMIC_TEXT,
        className: classes.titleColumn,
        sortType: createStringSort('title'),
      },
      {
        id: 'events',
        Header: 'Events',
        accessor: 'stats.totalOpportunities',
        Cell: NumberCell,
        filter: DYNAMIC_NUMBER,
        className: classes.eventsColumn,
      },
      {
        id: 'volunteers',
        Header: 'Volunteers',
        accessor: 'stats.totalVolunteers',
        Cell: NumberCell,
        filter: DYNAMIC_NUMBER,
        className: classes.volunteersColumn,
      },
      {
        id: 'createdAt',
        Header: 'Date Created',
        accessor: 'credential.createdAt',
        Cell: DateCell,
        filter: DYNAMIC_NUMBER,
        className: classes.dateCreatedColumn,
        sortType: createDateValueSort('createdAt'),
      },
      {
        id: 'lastModified',
        Header: 'Last Modified',
        accessor: 'version.createdAt',
        Cell: DateCell,
        filter: DYNAMIC_NUMBER,
        className: classes.lastModifiedColumn,
        sortType: createDateValueSort('lastModified'),
      },
      {
        id: 'expirationRate',
        Header: 'Expiration Rate',
        accessor: 'credential',
        Cell: ExpirationRateColumn,
        filter: DYNAMIC_NUMBER,
        className: classes.expirationColumn,
        disableSortBy: true,
        disableGlobalFilter: true,
      },

      {
        id: 'edit',
        accessor: 'credential.id',
        Header: <EditHeader hideDelete={true} />,
        Cell: ({ cell }: CellProps<RoleWithReporting>) => <EditCell cell={cell} onEdit={handleEdit} />,
        disableSortBy: true,
        disableGlobalFilter: true,
        className: classes.editColumn,
      },
    ],
    [
      classes.dateCreatedColumn,
      classes.editColumn,
      classes.eventsColumn,
      classes.expirationColumn,
      classes.lastModifiedColumn,
      classes.titleColumn,
      classes.volunteersColumn,
      handleEdit,
    ],
  ) as any as Column<CredentialFull>[]
}
