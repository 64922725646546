import React, {useState, useMemo} from 'react'
import { Dialog, DialogTitle, IconButton, Typography, Button, Grid, Card } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/styles'
import {useUserCredentialApprovalManagement} from '../hooks/useUserCredentialApprovalManagement'

import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import * as moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '20px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'center'
  },
  cancelButton: {
    color: theme.palette.danger.main,
  },
  statusDiv: {
    padding: "16px 24px"
  },
  memberDiv: {
    padding: "1em 5em"
  },
  groupTitle: {
    fontSize: "2rem",
    fontWeight: 600
  },
  memberTitle: {
    fontSize: "1.5rem"
  },
  statusTitle: {
    fontSize: "1.25rem",
  },
  applicantColor: {
    color: '#ff6f3e'
  },
  card: {
    padding: theme.spacing(2),
    border: "none",
    boxShadow: "none"
  },
  middleContainer: {
    marginTop: "50px"
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  credentialImage: {
    padding: "0 5px"
  },
  expirationContainer: {
    textAlign: "center",
    marginTop: "40px"
  },
  approvalContainer: {
    marginTop: "40px"
  }
}))

export default function ProcessUserCredentialIDPrompt(props) {

  const { showing, credentialTypeLabel } = props

  const {
    submitting: disabled,
    prev,
    current: userCredential,
    next,
    gotoPrev,
    gotoNext,
    approve,
    decline,
    exit: close,
    total,
    currentIndex
  } = useUserCredentialApprovalManagement(props)


  const promptClasses = usePromptStyles()
  const classes = useStyles()

  return (
    <Dialog fullScreen open={showing} onClose={close} >
      {userCredential && <Grid container direction="column" wrap="nowrap" justify="space-between" className={classes.fullHeight}>
        <Grid container item direction="row" wrap="noWrap" >

          <Grid container direction="row" wrap="nowrap">
            <DialogTitle>
              <Typography variant="h2" className={classes.groupTitle}>{credentialTypeLabel} Review</Typography>
              <IconButton disabled={disabled} onClick={close} className={promptClasses.closeButton}>
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
          </Grid>

          <Grid container className={classes.middleContainer}>
            <Grid item xs={12} sm={2}>
              <div className={classes.nameContainer}>
                <Typography variant="h4" className={classes.memberTitle}>Name&nbsp;:&nbsp;</Typography>
                {userCredential && <Typography variant="h4" className={classes.memberTitle}>{`${userCredential.user.givenName} ${userCredential.user.familyName}`}</Typography>}
              </div>
            </Grid>
            <Grid item xs={12} sm={10} >
              <Grid container direction="row" wrap={true}>
                {userCredential.attachments.map((row, index) =>
                  <Grid key={index}>
                    <img src={row.url} className={classes.credentialImage}/>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container >
            <Grid item xs={12} className={classes.expirationContainer}>
              <Typography variant="h4" className={classes.memberTitle}>Expiration Date: {userCredential.expiredAt && moment(userCredential.expiredAt).startOf('day').format('MM/DD/YYYY')}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item justify="space-between" style={{padding: "20px"}}>
          <Grid item>
            <Button variant="contained" color="secondary" disabled={!!!prev} onClick={gotoPrev}>
              PREVIOUS
            </Button>
          </Grid>
          <Grid item container direction="column" justify="center"
                style={{width: "auto"}}>{currentIndex} of {total}</Grid>
          <Grid item>
            <Button variant="contained" color="secondary" disabled={!!!next} onClick={gotoNext}>
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Grid>}
    </Dialog>
  )
}
