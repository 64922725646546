import { Status } from '../../civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../auth/hooks'
import useFetchRefactored from '../../civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { UploadedVolunteer } from '../../import/interfaces'
import { Volunteer } from '../../Event/interfaces'

export const useConfirmVolunteers = (): [
  (payload: { volunteers: UploadedVolunteer[]; sendInvite: boolean }) => Promise<Volunteer[]>,
  Status<Volunteer[]>,
] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Volunteer[]>({
    successMessage: 'Volunteers Import Started',
    useNewNotification: true,
  })

  const confirmVolunteers = useCallback(
    (payload: { volunteers: UploadedVolunteer[]; sendInvite: boolean }) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/volunteers/multi`,
        config: {
          body: payload,
        },
      })
    },
    [organization.id, request],
  )

  return [confirmVolunteers, status]
}
