import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'
import { Tag, TagPayload } from '../types'

export const useSaveTag = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<Tag>()
  const saveTag = useCallback(
    async ({ id, ...tag }: TagPayload, options?: { reporting: boolean }) =>
      request({
        method: id ? 'put' : 'post',
        url: id ? `/tags/${id}` : `/organizations/${organization.id}/tags`,
        config: {
          queryStringParameters: options,
          body: tag,
        },
      }),
    [request, organization.id],
  )

  return { saveTag, loading, error }
}
