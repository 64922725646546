import { RUN_QUERY_REQUEST, RUN_QUERY_SUCCEEDED, RUN_QUERY_FAILED } from './actions';
import getErrorMessage from "../utils/getErrorMessage";
import getQueryKey from './utils/getQueryKey';

const defaultState = {
    queries: {},
    results: {}
}

export default function(state=defaultState, action) {
    switch(action.type) {
        case RUN_QUERY_REQUEST:
            return onRequestStart(state, action);
        
        case RUN_QUERY_SUCCEEDED:
        case RUN_QUERY_FAILED:
            return onRequestEnd(state, action);

        default:
            return state;
    }
}

function onRequestStart(state, action) {
    const { queryConfig } = action.payload;
    const queryKey = getQueryKey(queryConfig);

    const prev = state.queries[queryKey];
    if(prev) {
        delete prev.prev;
    }

    return {
        ...state,
        queries: {
            ...state.queries,
            [queryKey]: {
                running: true,
                completedAt: null,
                prev
            }
        }
    }
}

function onRequestEnd(state, action) {
    const { queryConfig, result, error } = action.payload;
    const queryKey = getQueryKey(queryConfig);

    let newState = { 
        ...state,
        queries: {
            ...state.queries,
            [queryKey]: {
                running: false,
                completedAt: new Date().toISOString(),
                error: error ?  getErrorMessage(error) : null
            }
        }
    }

    if(!error) {
        newState.results = queryConfig.update(state.results, result);
    }

    return newState;
}
