import { useCallback } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'

export default function useEditStripeProduct() {
  const [request, status] = useFetch('Error setting stripe product')
  const showSuccess = useSuccessNotification()
  const editStripeProduct = useCallback( (payload) => {
    const { id } = payload
    return request({
      method: id ? 'put' : 'post',
      url: id ? `/stripe-products/${id}` : '/stripe-products',
      config: {
        body: payload
      },
      onSuccess: () => showSuccess('Successfully updated stripe product')
    })
  }, [request])

  return [editStripeProduct, status]
}
