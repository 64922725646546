import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { EntityType, Note } from 'note/Note'
import { useCurrentOrg } from 'auth/hooks'

const useAddEditNote = (
  entityType?: EntityType,
): [
  ({ entityId, id, title, body }: { entityId?: number; id?: number; title?: string; body: string }) => Promise<Note>,
  Status<Note>,
] => {
  const [request, status] = useFetchRefactored<Note>({
    successMessage: 'Note saved',
    errorMessage: 'Failed to save note',
  })
  const organization = useCurrentOrg()

  const saveNote = useCallback(
    ({ id, entityId, title, body }) => {
      return request({
        method: id ? 'put' : 'post',
        url: `/organizations/${organization.id}/notes${id ? `/${id}` : ''}`,
        config: {
          body: {
            title,
            body,
            entityId,
            entityType,
          },
        },
      })
    },
    [entityType, organization.id, request],
  )

  return [saveNote, status]
}

export default useAddEditNote
