import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCurrentOrg, useCurrentUser } from 'auth/hooks'
import { getFeatures } from '../selectors'
import { getFeaturesForUser, getProductFeaturesForOrg, updateProductFeature } from '../actions'

export const useFeatureEnabled = key => {
  let features = useSelector(getFeatures)
  let feature = features[key]

  return feature && !!feature.enabled
}

export const useReloadLocalFeatures = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()

  return useCallback(() => {
    dispatch(getFeaturesForUser(user))
  }, [user, dispatch])
}

export const useReloadProductFeatures = () => {
  const dispatch = useDispatch()
  const organization = useCurrentOrg()

  return useCallback(() => {
    dispatch(getProductFeaturesForOrg(organization))
  }, [organization, dispatch])
}

export const useUpdateProductSwitch = () => {
  const dispatch = useDispatch()

  return useCallback(
    ({ feature, organization, enabled }) => {
      dispatch(updateProductFeature(organization, feature, enabled))
    },
    [dispatch],
  )
}

export { default as useOrganizationSettingsTabs } from './useOrganizationSettingsTabs'
export { default as useMigrateQuestionnairesConfirmationPrompt } from './useMigrateQuestionnairesConfirmationPrompt'
