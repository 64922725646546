import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import useGetUnsignedWaivers from 'kiosk/hooks/useGetUnsignedWaivers'
import { createMarkup } from '../../civic-champs-shared/core/utils/helpers'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '21px',
  },
  nextText: {
    color: 'white',
    fontSize: '15px',
  },
}))

export default function FullWaiver(props) {
  const classes = useStyles()

  const waiverParam = useParams()
  const [waiver, setWaiver] = useState()

  const [getUnsignedWaivers, { loading: loadingWaivers }] = useGetUnsignedWaivers()

  useEffect(() => {
    async function fetchData() {
      const userId = props.location.search.split('=').pop()
      const unsignedWaivers = await getUnsignedWaivers(userId)
      const waiver = _.filter(unsignedWaivers, function (waiver) {
        return Number(waiver.version.id) === Number(waiverParam.id)
      })
      setWaiver(waiver[0])
    }
    fetchData()
  }, [getUnsignedWaivers, waiverParam.id, props.location.search])

  return (
    <>
      <div className={classes.container}>
        {waiver != null && (
          <p className={classes.title}>
            <b>{waiver.version.title}</b>
          </p>
        )}
        {waiver != null && waiver.version.url === '' && waiver.version.content != null && (
          <div dangerouslySetInnerHTML={createMarkup(waiver.version.content)} />
        )}
      </div>
    </>
  )
}
