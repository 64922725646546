import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const useUpdateNonSchedulableOpportunity = () => {
  const [request, status] = useFetch()
  const postOpportunity = useCallback(
    (body, encodedOccurrence) => {
      return request({
        method: 'put',
        url: `/opportunity_instances/${encodedOccurrence}/non_schedulable`,
        config: { body },
      })
    },
    [request],
  )

  return [postOpportunity, status]
}

export default useUpdateNonSchedulableOpportunity
