import React from 'react'
import Loader from '../../Event/components/loader'
import SalsaIntegrationForm from './SalsaIntegrationForm'
import SalsaSyncSupporters from './SalsaSyncSupporters'
import SalsaClearDemoData from './SalsaClearDemoData'
import { makeStyles } from '@material-ui/core/styles'
import { useSalsaIntegrationManagement } from '../hooks/useSalsaIntegrationManagement'

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  submitButton: {
    background: '#5C8DE8FA',
    color: '#FFFFFFFA',
  },
  formLabelWrapper: {
    margin: 'auto 0',
  },
  formLabel: {
    lineHeight: '50px',
    fontSize: '18px',
    textAlign: 'left',
  },
  disabledButton: {
    background: '#CCCCCC',
    color: '#FFFFFFFA',
  },
  inProgressButton: {
    background: '#CCCCCC',
    color: '#3D3D3D',
  },
  connectedButton: {
    background: '#3D3D3D',
    color: '#FFFFFFFA',
  },
  orangeButton: {
    background: '#FF6F3E',
  },
  orangeText: {
    color: '#FF6F3E',
  },
  yellowButton: {
    background: '#FFCD00',
  },
  explanationLabel: {
    color: '#3D3D3D',
    fontSize: '10px',
  },
  notConnectedLabel: {
    color: '#FF6F3E',
    fontSize: '10px',
  },
  link: {
    color: '#5C8DE8FA',
  },
  paper: {
    maxWidth: '40vw',
    width: '40vw',
    background: '#FFFAE6',
    fontSize: '15px',
  },
  neitherUserSelected: {
    color: '#3D3D3D',
  },
  selectedUser: {
    color: '#5C8DE8FA',
  },
  nonSelectedUser: {
    color: '#CCCCCC',
  },
})

export default function SalsaSettings(props: any) {
  const {
    salsaIntegration,
    organizationId,
    loading,
    initialLoadComplete,
    disconnectLoading,
    reFetch,
    updateSalsaIntegration,
    syncIntegration,
    resolveSalsaMigrationIssues,
    disconnectSalsaIntegration,
    pauseSalsaIntegration,
  } = useSalsaIntegrationManagement()
  const classes = useStyles()
  return !initialLoadComplete || disconnectLoading ? (
    <Loader />
  ) : (
    <>
      <SalsaIntegrationForm
        onSubmit={updateSalsaIntegration}
        classes={classes}
        loading={loading}
        organizationId={organizationId}
        salsaIntegration={salsaIntegration}
        disconnectSalsaIntegration={disconnectSalsaIntegration}
        pauseSalsaIntegration={pauseSalsaIntegration}
      />
      <SalsaSyncSupporters
        salsaIntegration={salsaIntegration}
        classes={classes}
        loading={loading}
        reFetch={reFetch}
        syncIntegration={syncIntegration}
        resolveSalsaMigrationIssues={resolveSalsaMigrationIssues}
      />
      <SalsaClearDemoData/>
    </>
  )
}
