import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { Chat } from '../interfaces'
import useAddChat from './useAddChat'
import useAddChatMembers from './useAddChatMembers'
import useGetChats from './useGetChats'
import { useDateRangeFilter } from '../../filtering/hooks'
import filter from 'lodash/filter'
import useDeleteChatsPrompt from './useDeleteChatsPrompt'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import { AddChatPrompt } from '../components'
import map from 'lodash/map'

export const useChatsCollection = () => {
  const [chats, operations, events] = useRemoteCollection<Chat>()
  const [dateRange] = useDateRangeFilter()
  const { syncCollection, eagerAdd, eagerRemoveMany } = operations
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const [fetchChats, { loading, error }] = useGetChats()
  const [createChat] = useAddChat()
  const [addChatMembers] = useAddChatMembers()
  const showDeletePrompt = useDeleteChatsPrompt({ eagerRemoveMany })
  const showAddChatPrompt = useShowPrompt(AddChatPrompt)
  const handleAddChat = useCallback(
    async ({ name, description, members }) => {
      const chat = await createChat({ name, description })
      eagerAdd({ ...chat, membersCount: members.length })
      await addChatMembers({ chatId: chat.id, uuids: map(members, 'pubnubId') })
    },
    [addChatMembers, createChat, eagerAdd],
  )
  const addChat = useCallback(() => showAddChatPrompt().then(handleAddChat), [handleAddChat, showAddChatPrompt])

  useEffect(() => {
    fetchChats().then(result => {
      const { startDate, endDate } = dateRange
      syncCollection(filter(result, ({ lastActivityAt }) => lastActivityAt >= startDate && lastActivityAt <= endDate))
      setInitiallyLoaded(true)
    })
  }, [dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {
      chats,
      loading,
      error,
      initiallyLoaded,
    },
    {
      deleteChats: showDeletePrompt,
      addChat,
    },
    events,
  ] as [
    {
      chats: Chat[]
      loading: boolean
      error: any
      initiallyLoaded: boolean
    },
    {
      deleteChats: (chats: Chat[]) => Promise<void>
      addChat: () => Promise<void>
    },
    CollectionEventListeners<Chat>,
  ]
}

export default useChatsCollection
