import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'

export const useFetchDocumentTypes = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<DocumentType[]>()
  const fetchDocumentTypes = useCallback(
    async () =>
      request({
        url: `/organizations/${organization.id}/document_types`,
      }),
    [request, organization.id],
  )

  return { fetchDocumentTypes, loading, error }
}
