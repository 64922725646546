import { useCallback } from "react"
import { useErrorNotification, useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import CompleteTaskPrompt from '../components/CompleteTaskPrompt'

export const TaskEventType = {
  REQUESTED: 'requested',
  REQUESTER_CANCELED: 'requester-canceled',
  ADMIN_CANCELED: 'admin-canceled',
  TASK_ACCEPTED: 'task-accepted',
  TASK_DECLINED: 'task-declined',
  DETAILS_NEEDED: 'details-needed',
  DETAILS_UPDATED: 'details-updated',
  VOLUNTEER_ASSIGNED: 'volunteer-assigned',
  VOLUNTEER_ASSIGNED_SELF: 'volunteer-assigned-self',
  VOLUNTEER_REMOVED: 'volunteer-removed',
  VOLUNTEER_DROPPED: 'volunteer-dropped',
  VOLUNTEER_RESCHEDULE_REQUEST: 'volunteer-reschedule-request',
  RECIPIENT_RESCHEDULE_ACCEPTED: 'recipient-reschedule-accepted',
  RECIPIENT_RESCHEDULE_DECLINED: 'recipient-reschedule-declined',
  RECIPIENT_RESCHEDULE_REQUEST: 'recipient-reschedule-request',
  VOLUNTEER_RESCHEDULE_ACCEPTED: 'volunteer-reschedule-accepted',
  VOLUNTEER_RESCHEDULE_DECLINED: 'volunteer-reschedule-declined',
  ADMIN_RESCHEDULE_REQUEST: 'admin-reschedule-request',
  TASK_STARTED: 'task-started',
  TASK_COMPLETED: 'task-completed',
  COMPLETION_CONFIRMED: 'completion-confirmed',
  COMPLETION_DENIED: 'completion-denied',
  TASK_UNCOMPLETABLE: 'task-uncompletable',
  TASK_OCCURRED_AT_UPDATED: 'occurred_at_updated',
  PROVIDE_RECIPIENT_FEEDBACK: 'recipient-feedback',
}

const makeEventHook = eventCode => () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetch()
  const taskEventCall = useCallback(
    (task, payload) => {
      return request({
        method: 'put',
        url: `/organizations/${organization.id}/tasks/${task.id}`,
        config: {
          body: {
            taskId: task.id,
            eventCode,
            ...payload,
          },
        },
      })
    },
    [request, organization.id],
  )
  return [taskEventCall, loading, error]
}

export const useAcceptTask = makeEventHook(TaskEventType.TASK_ACCEPTED)
export const useAdminCancelTask = makeEventHook(TaskEventType.ADMIN_CANCELED)
export const useRemoveVolunteerFromTask = makeEventHook(TaskEventType.VOLUNTEER_REMOVED)
export const useCompleteTask = makeEventHook(TaskEventType.TASK_COMPLETED)
export const useConfirmCompleteTask = makeEventHook(TaskEventType.COMPLETION_CONFIRMED)
export const useAssignVolunteerTask = makeEventHook(TaskEventType.VOLUNTEER_ASSIGNED)
export const useStartTask = makeEventHook(TaskEventType.TASK_STARTED)
export const useDeclineTask = makeEventHook(TaskEventType.TASK_DECLINED)
export const useUpdateTask = makeEventHook(TaskEventType.DETAILS_UPDATED)
export const useRecipientReschedule = makeEventHook(TaskEventType.RECIPIENT_RESCHEDULE_REQUEST)
export const useOccurredAtUpdated = makeEventHook(TaskEventType.TASK_OCCURRED_AT_UPDATED)

export const useCompleteTaskPrompt = refresh => {
  const prompt = usePrompt(props => CompleteTaskPrompt({ ...props }))
  const [completeTask] = useConfirmCompleteTask()
  const onError = useErrorNotification()

  const handleComplete = useCallback(
    async task => {
      try {
        await completeTask(task)
      } catch (err) {
        onError('There was a problem accepting the task', err)
      } finally {
        await refresh()
      }
    },
    [completeTask, refresh, onError],
  )

  return useCallback(
    task => {
      if (prompt.showing) return

      const unlistens = [
        prompt.onComplete(() => {
          handleComplete(task)
        }),
        prompt.onHide(() => {
          unlistens.forEach(unlisten => unlisten())
        }),
      ]

      prompt.show()
    },
    [handleComplete, prompt],
  )
}
