import React from 'react'
import { makeStyles } from '@material-ui/core'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { Opportunity } from 'Event/interfaces'
import CalendarHeader from './CalendarHeader'
import EventCalendarDay from './EventCalendarDay'
import EventPreviewPrompt from './EventPreviewPrompt'
import { useEventCalendarGrid } from './hooks/useEventCalendarGrid'

const useStyles = makeStyles(theme => ({
    calendarContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.default,
        boxShadow: theme.shadows[4],
        borderRadius: 4,
        padding: '25px 10px 16px 10px',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            borderRadius: 0,
            boxShadow: 'none',
        }
    },
    calendarBodyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            border: '1px solid #cfd7e8'
        }
    },
    calendarWeek: {
        display: 'flex',
        flex: '1 1 auto',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    }
}))

interface Props {
    events: Opportunity[],
    date: number,
    month: number,
    year: number,
    viewType: string,
}

const EventCalendar = (props: Props) => {
    const { date, month, year, events, viewType } = props
    const classes = useStyles()
    const grid = useEventCalendarGrid(date, month, year, events, viewType)

    const showPreview = useShowPrompt(EventPreviewPrompt)

    return <div className={classes.calendarContainer}>
        <CalendarHeader />
        <div className={classes.calendarBodyContainer}>
            {grid.map( (row, week) => (
                <div key={week} className={classes.calendarWeek}>
                    {row.map( (cell, day) => (
                        <EventCalendarDay
                            key={day}
                            {...cell}
                            onShowEventPopup={showPreview}
                            month={month}
                            viewType={viewType}
                        />
                    ))}
                </div>
            ))}
        </div>
    </div>
}

export default EventCalendar
