import { Field, Form, Formik } from 'formik'
import { Box, Button, Grid, makeStyles, Typography, Link } from '@material-ui/core'
import { CheckboxWithLabel } from 'formik-material-ui'
import React from 'react'
import { StepOneProps, StepOneForm, chooseContactsSchema } from './schema'
import { parseEmailAndSmSPersonIds } from './utils'
import { useHistory } from 'react-router'
import cn from 'classnames';
import { size } from 'lodash'

const useStyles = makeStyles((theme) => ({
  button: {
    borderColor: 'currentColor',
    textTransform: 'initial',
    fontWeight: 'bold',
    minWidth: 100,
  },
  backButton: {
    marginRight: 'auto',
  },
  cancelButton: {
    // @ts-ignore
    color: theme.palette.danger.main,
  },
  getCreditsButton: {
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 15,
    textTransform: 'initial',
    fontWeight: 'bold',
  },
  colorWarning: {
    // @ts-ignore
    color: theme.palette.danger.main,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  buttons: {
    alignItems: 'flex-end',
},
  row: {
    flex: 1,
    marginBottom: 20,
  },
  height50: {
    minHeight: '50px',
  },
  marginLeft: {
    marginLeft: '10px',
  },
  checkbox: {
    marginTop: '5px',
  },
  multipleContacts: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  stats: {
    fontSize: '1rem',
  }
}))

export default function StepOne(props: StepOneProps) {
  const { onSubmit, preview, cancel, recipientPersonIds } = props
  const { recipientIdsWithBoth, recipientIdsWithEmails, recipientIdsWithPhoneNumbers, smsCredits } = preview
  const classes = useStyles()
  const history = useHistory()
  const handleFormikSubmit = ({ contactBoth }: StepOneForm) => {
    return onSubmit(contactBoth)
  }
  return (
    <Formik
      initialValues={{
        contactBoth: false,
      }}
      validationSchema={chooseContactsSchema}
      onSubmit={values => handleFormikSubmit(chooseContactsSchema.cast(values))}
    >
      {formikProps => {
        const { handleSubmit, isSubmitting, isValid, values } = formikProps
        const { smsPersonIds = [], emailPersonIds = [] } = parseEmailAndSmSPersonIds({
          contactBoth: values.contactBoth,
          preview,
        })
        const numRecipients = size(smsPersonIds) + size(emailPersonIds)
        const notEnoughCredits = smsPersonIds.length > preview.smsCredits
        const disabled = !isValid || notEnoughCredits || isSubmitting || !numRecipients

        return (
          <Form onSubmit={handleSubmit} className="fullheight">
            <Grid container direction="column" className="fullheight" wrap="nowrap">
              <Grid xs={12} item container direction="row" className={classes.row}>
                <Grid xs={2} item>
                  <Typography variant="subtitle1">
                    <b>Contacts</b>
                  </Typography>
                </Grid>
                <Grid xs={10} item className={classes.stats}>
                  {recipientPersonIds.length} people selected
                </Grid>
              </Grid>
              <Grid xs={12} item container direction="row" className={classes.row}>
                <Grid xs={2} item>
                  <Typography variant="subtitle1">
                    <b>Sending</b>
                  </Typography>
                </Grid>
                <Grid xs={10} item className={classes.stats}>
                  <Box mb={2}>
                    <Link color="secondary" className={classes.link}><b>{recipientIdsWithEmails.length}</b></Link> Emails <Typography component="i" color="secondary">(Unlimited)</Typography>
                  </Box>

                  <div>
                    <Link color="secondary" className={classes.link}><b>{recipientIdsWithPhoneNumbers.length}</b></Link> Text Messages <i className={classes.colorWarning}>({smsCredits} Text Message credits available)</i>{' '}
                    {recipientIdsWithPhoneNumbers.length > smsCredits && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => history.push('/buy-sms-credits')}
                        className={classes.getCreditsButton}
                      >
                        Get Credits
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid xs={12} item container direction="row" className={classes.row}>
                <Grid xs={2} item className={cn(classes.height50, classes.stats)} />
                {!!recipientIdsWithBoth.length && (
                  <Grid xs={10} item className={cn(classes.multipleContacts, classes.stats)}>
                    <Typography className={classes.colorWarning}>
                      <Link className={cn(classes.link, classes.colorWarning)}>{recipientIdsWithBoth.length}</Link>{' '}
                      <b>users have both email and mobile number contacts</b></Typography>
                    {recipientIdsWithBoth.length && (
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        className={classes.checkbox}
                        name="contactBoth"
                        Label={{ label: 'Please send to both contacts for all of these people' }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid xs={12} item container direction="row" justify="flex-end" className={classes.buttons}>
                <Grid className={classes.backButton}>
                  <Button onClick={cancel} variant="outlined" color="secondary" className={classes.button} disabled={isSubmitting}>
                    Back
                  </Button>
                </Grid>
                <Grid>
                  <Button onClick={cancel} variant="outlined" className={cn(classes.button, classes.cancelButton)} disabled={isSubmitting}>
                    Cancel
                  </Button>
                </Grid>
                <Grid>
                  <Button className={cn(classes.button, classes.marginLeft)} type="submit" variant="contained" color="secondary" disabled={disabled}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
