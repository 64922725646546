import {
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import React, { useEffect, useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Loading from 'components/Loading'
import { RouteComponentProps } from 'react-router'
import DEFAULT_FILTERS from 'core/table/filters'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import AddIcon from '@material-ui/icons/Add'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { AddTripPrompt } from 'volunteering/trips/components'
import { tripPlannerFilter } from 'volunteering/trips'
import { preprocessTripRows } from '../utils'
import { MappedTripTableRow } from '../types'
import { useDateRangeFilter } from 'filtering/hooks'
import moment from 'moment'
import {
  useFetchDrivers,
  useFetchTrips,
  useRemoveTripPrompt,
  useTripColumns,
  useTripOrderDriverAssignment,
} from '../hooks'

const tableName = 'trip-planner'

const getHeaderCellStyle = (cell: any) => {
  if (cell?.id === 'expand') {
    return {
      style: {
        lineHeight: '32px',
      },
    }
  }
}

const getCellStyle = (rowInfo: any, cell: any) => {
  if (rowInfo.depth && !rowInfo.original.driver && cell.column.id !== 'expand') {
    return {
      style: {
        background: '#FAE09D',
      },
    }
  }
}

// Refer to src/champions/components/Champions.tsx for more functionality
export const TripPlannerOld = ({ location, history }: RouteComponentProps) => {
  const showAddTrip = useShowPrompt(AddTripPrompt)
  const [fetchTrips, { result: trips, loading: tripsLoading }] = useFetchTrips() as any
  const [fetchDrivers, { result: drivers, loading: driversLoading }] = useFetchDrivers() as any
  const showRemoveTripPrompt = useRemoveTripPrompt(fetchTrips)
  const [updateDriver] = useTripOrderDriverAssignment()

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchDrivers()
  }, [fetchDrivers])

  useEffect(() => {
    fetchTrips()
  }, [fetchTrips])

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const onDriverAssignment = async (data: any) => {
    await updateDriver(data)
    fetchTrips()
  }

  const mappedTrips = useMemo((): MappedTripTableRow[] => preprocessTripRows(trips, drivers), [trips, drivers])

  const [dateRangeFilter] = useDateRangeFilter()

  const filteredTrips = useMemo(() => {
    if (!mappedTrips) return []
    const { startDate, endDate } = dateRangeFilter

    if (startDate === undefined || endDate === undefined) return mappedTrips

    const filterStart = moment(startDate)
    const filterEnd = moment(endDate)

    return mappedTrips.filter((a: any) => {
      const tripDate = moment(a.date)
      return filterStart.isSameOrBefore(tripDate, 'day') && filterEnd.isSameOrAfter(tripDate, 'day')
    })
  }, [mappedTrips, dateRangeFilter])

  const filterOptions = { filteredIds: [] }

  const columns = useTripColumns({ onDriverAssignment, showRemoveTripPrompt })

  const table: any = useTable(
    {
      initialState: {
        // @ts-ignore
        filterOptions,
      },
      getSubRows: (row: any) => row.subRows,
      globalFilter: (rows: any, columnIds: any, filterValue: any) =>
        // TODO debug tripPlannerFilter and get working correctly, especially with Driver names
        tripPlannerFilter(rows, columnIds, filterValue, filterOptions),
      paginateExpandedRows: false,
      filterTypes: DEFAULT_FILTERS,
      data: filteredTrips,
      columns,
      autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useColumnOrder,
  )

  const handleAddTripClick = async () => {
    await showAddTrip()
    return fetchTrips()
  }

  if (driversLoading || tripsLoading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography variant="h4">Trip Requests</Typography>
      </Grid>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Trip Requests"
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={handleAddTripClick}>
            Create Trip Request
          </ContainedButton>
        }
        useGlobalSearch
        useDateRange
        getCellStyle={getCellStyle}
        getHeaderCellStyle={getHeaderCellStyle}
      />
    </Grid>
  )
}
