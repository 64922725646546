export const GET_VOLUNTEERS = {
  request: ({ filter }) => {
    let url = `/organizations/${filter.organizationId}/volunteers?`

    const reporting = typeof(filter.reporting) !== 'undefined' ? filter.reporting : true

    url += `reporting=${reporting}`

    if (filter.endDate)
      url += `&endDate=${filter.endDate
        .clone()
        .utc()
        .format('MM/DD/YYYY hh:mm:ss a')}`

    return { url }
  },
  caching: {
    maxAge: '1 hour',
    staleAfter: '1 minute',
  },
  update: (queryConfig, state, result) => ({
    ...state,
    volunteers: result,
  }),
  select: (queryConfig, state) => state.volunteers,
}
