import React, { useReducer, useRef, useMemo, useCallback, useEffect } from 'react'
import { getIn, useFormik, FormikProvider } from 'formik'

import { FormikWizardProvider } from './FormikWizardContext'
import ProgressTracker from './ProgressTracker'

const clamp = (value, min, max) => {
    if(value > max) return max
    if(value < min) return min
    return value;
}

//TODO move to utils/hooks?
const useOnChange = (value, onChange) => {
    const errorsRef = useRef(value)
    useEffect( () => {
        if(errorsRef.current !== value) {
            errorsRef.current = value
            onChange(value)
        }
    }, [onChange, value])
}

const useOnMount = fn => {
    const mountedRef = useRef(false)
    useEffect(() => {
        if(!mountedRef.current) {
            mountedRef.current = true
            fn()
        }
    })
}

const updateStepValidation = (steps, errors) => 
    steps.map( prevStep => {
        if(!prevStep.schemaFields) return prevStep

        const newStep = { 
            ...prevStep, 
            isValid: true,
            errors: [] 
        }

        for(let field of prevStep.schemaFields) {
            let error = getIn(errors, field)
            if(error) {
                newStep.errors.push(error)
                newStep.isValid = false
            }
        }        

        return newStep;
    })

const GOTO_STEP = 'gotoStep';
const ON_ERRORS_CHANGED = 'onErrorsChanged'

function init(props) {
    const { initialFurthestStep, children } = props

    const steps = []
    React.Children.forEach(children, child => {
        if( !React.isValidElement(child) ) return

        steps.push({
            name: child.props.name,
            schemaFields: child.props.schemaFields || [],
            isValid: true, //TODO !? 
            touched: false,
            errors: {}
        })
    })

    const furthestStep = Math.min(initialFurthestStep || 0, steps.length)
    return {
        steps,
        stepIndex: furthestStep,
        furthestStep
    }
}

function reducer(state, action) {
    switch(action.type) {
        case GOTO_STEP:
            const newIndex = clamp(action.payload.newIndex, 0, state.steps.length)
            return {
                ...state,
                stepIndex: newIndex,
                furthestStep: Math.max(newIndex, state.furthestStep)
            }
        
        case ON_ERRORS_CHANGED:
            return {
                ...state,
                steps: updateStepValidation(state.steps, action.payload.errors || {})
            }

        default:
            return state
    }
}

function useWizard(props) {
    const { 
        initialValues,
        validationSchema,
        published,
        onSaveDraft,
        onPublish,
        publishButtonLabel
    } = props

    const [state, dispatch] = useReducer(reducer, props, init)

    const gotoStep = useCallback( newIndex => { 
        dispatch({
            type: GOTO_STEP,
            payload: { newIndex }
        })
    }, [])

    const onErrorsChanged = useCallback( errors => {
        dispatch({
            type: ON_ERRORS_CHANGED, 
            payload: { errors } 
        })
    }, [])

    const onSubmit = useCallback( (values, formikBag) => {
        const editorMeta = { furthestStep: state.furthestStep }
        if(submitTypeRef.current === 'draft') {
            if(onSaveDraft) onSaveDraft(values, editorMeta) 
        } else if(submitTypeRef.current === 'publish') {
            if(onPublish) onPublish(values, editorMeta)
        }
        formikBag.setSubmitting(false)
    }, [onPublish, onSaveDraft, state.furthestStep])

    const formikBag = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    useOnChange(formikBag.errors, onErrorsChanged)
    useOnMount( () => { formikBag.validateForm() }) 

    const submitTypeRef = useRef(null)
    const { submitForm } = formikBag

    const saveDraft = useCallback(async () => {
        submitTypeRef.current = 'draft'
        try {
            await submitForm()
        } finally {
            submitTypeRef.current = null
        }
    }, [submitForm])

    const publish = useCallback(async () => {
        submitTypeRef.current = 'publish'
        try {
            await submitForm()
        } finally {
            submitTypeRef.current = null
        }
    }, [submitForm])

    const wizard = useMemo(() => ({
        ...state,
        published,
        gotoStep,
        saveDraft,
        publish,
        publishButtonLabel
    }), [published, gotoStep, publish, saveDraft, state, publishButtonLabel])

    //TODO this return signature is pretty groady... 
    return [formikBag, wizard]
}

export function FormikWizard(props) {
    const { 
        additionalProps = {},
        children 
    } = props 

    const [formikBag, wizard] = useWizard(props)

    let element, index = 0;
    React.Children.forEach(children, child => {
        if( element || !React.isValidElement(child) ) return

        if(wizard.stepIndex === index || index === React.Children.count(children) - 1) {
            element = child
        } 

        index++
    })

    return (<div>
        <FormikWizardProvider value={wizard} >
            <ProgressTracker 
                steps={wizard.steps}
                stepIndex={wizard.stepIndex}
                furthestStep={wizard.furthestStep}
                gotoStep={wizard.gotoStep}
            />
            <div>
                <FormikProvider value={formikBag}>
                    {React.cloneElement(element, {
                        stepIndex: wizard.stepIndex, //TODO pass in wizard instead?
                        additionalProps,
                        formikBag
                    })}
                </FormikProvider>
            </div>
        </FormikWizardProvider>
    </div>)
}