import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import pickBy from 'lodash/pickBy'

import format from 'civic-champs-shared/utils/format'
import { useCallback, useEffect } from 'react'
import { useFetch, useRemoteCollection, useRemoteCollectionPointer } from 'civic-champs-shared/api/hooks'

import { useCurrentOrg } from 'auth/hooks'
import { mapOpportunities } from 'volunteering/opportunities/helpers/mapOpportunity'

export default function useOpportunitiesCollection(filters) {
  const [request, { loading, error }] = useFetch()
  const [opportunities, operations, events] = useRemoteCollection()
  const [selected, setSelected] = useRemoteCollectionPointer(events)
  const currentOrg = useCurrentOrg()
  const { startDate, endDate, schedulable, isOffer } = filters

  const { syncCollection, eagerRemoveMany } = operations
  const getOpportunities = useCallback(async () => {
    const queryStringParameters = pickBy(
      {
        reporting: true,
        startDate: format.dateForUrl(startDate),
        endDate: format.dateForUrl(endDate),
        ...(!isUndefined(schedulable) ? { schedulable } : {}),
        ...(isOffer ? { isOffer } : {}),
      },
      value => !isNull(value) && !isUndefined(value),
    )

    const updatedOpportunities = mapOpportunities(
      await request({
        url: `/organizations/${currentOrg.id}/opportunities`,
        config: { queryStringParameters },
      }),
    )

    syncCollection(updatedOpportunities)
  }, [startDate, endDate, schedulable, isOffer, request, currentOrg.id, syncCollection])

  const reload = useCallback(() => getOpportunities(), [filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reload()
  }, [format.dateForUrl(startDate), format.dateForUrl(endDate), schedulable]) // eslint-disable-line react-hooks/exhaustive-deps

  return [
    {
      opportunities,
      loading,
      error,
      selected,
    },
    {
      reload,
      setSelected,
      eagerRemoveMany,
    },
  ]
}
