import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export const usePauseSalsaIntegration = onResultUpdate => {
  const [request, { loading }] = useFetch('Error Pausing Salsa Integration.  Please refresh the page and try again')
  const { id: organizationId } = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const pauseSalsaIntegration = useCallback(
    async paused => {
      const confirmed =
        !paused ||
        (await confirm(`Are you sure you want to pause the Salsa synchronization?`, {
          title: `Pause Salsa`,
          confirmText: `Pause Now`,
          rejectText: 'Nevermind',
          subText: `Clicking the 'PAUSE NOW' button below will stop volunteer data from synchronizing between Salsa and Civic Champs until you reconnect`,
        }))
      if (!confirmed) {
        return
      }
      return request({
        onSuccess: result => {
          showSuccess(`Successfully ${paused ? 'paused' : 'reconnected'} Salsa Integration`)
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/salsa/organizations/${organizationId}/pause`,
        config: { body: { paused } },
      })
    },
    [request],
  )

  return {
    loading,
    pauseSalsaIntegration,
  }
}
