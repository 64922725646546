import React  from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons';

import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';
import { DatafeedParseResult } from './DatafeedParseResult'
import { ChampionDatafeedImportRow, ExternalCustomerDatafeed } from 'external-datafeed/types';
import useImportIdMappings from 'external-datafeed/hooks/useImportIdMappings';
import { some } from 'lodash';

export interface DatafeedImportPreviewPromptProps {
  datafeed: ExternalCustomerDatafeed,
  importPreview: ChampionDatafeedImportRow[]
  showing: boolean
  close: () => void
  cancel: () => void
  complete: Function
}

export const DatafeedImportPreviewPrompt: React.FC<DatafeedImportPreviewPromptProps> = (props) => {
  const classes = usePromptStyles()
  const { datafeed, importPreview: data, showing, close, cancel, complete } = props

  const [importIdMappings, { loading: importing }] = useImportIdMappings()

  const canImport = !some(data, row => row.isError)
  const needsImport = some(data, row => row.isNew)

  const handleSubmit = async () => {
    if(needsImport) await importIdMappings(datafeed, data)
    complete()
  }

  return (
    <Dialog
      fullScreen
      className="fullScreen" 
      open={showing}
      onClose={close}
      disableBackdropClick={true}
    >
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Update ID Mappings</Typography>
        <IconButton
          onClick={close}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <DatafeedParseResult data={data} />
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button 
          className={classes.actionButton}
          color="secondary"
          onClick={cancel}
          disabled={importing}
        >
          Cancel
        </Button>
        <Button
          className={classes.actionButton}
          variant="contained"
          color="primary"
          disabled={!canImport || !needsImport || importing}
          onClick={handleSubmit}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
)
}