import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { Survey } from 'surveys/types'

export const useCreateSurveyVersion = (): [(surveyId: number, body: any) => Promise<Survey>, Status<Survey>] => {
  const [request, status] = useFetchRefactored<Survey>()

  const createSurveyVersion = useCallback(
    (surveyId, body) => {
      return request({
        method: 'post',
        url: `/surveys/${surveyId}/versions`,
        config: {
          body,
        },
      })
    },
    [request],
  )

  return [createSurveyVersion, status]
}

export default useCreateSurveyVersion
