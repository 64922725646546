import { Dispatch, SetStateAction, UIEvent, useRef } from 'react'

export const useHandleScroll = (setScrollTopPosition: Dispatch<SetStateAction<number>>) => {
  const requestRunning = useRef<number | null>(null)

  return ({ currentTarget: { scrollTop } }: UIEvent<HTMLDivElement>) => {
    if (requestRunning.current === null) {
      requestRunning.current = window.requestAnimationFrame(function () {
        setScrollTopPosition(scrollTop)
        requestRunning.current = null
      })
    }
  }
}
