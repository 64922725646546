import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback, useEffect } from 'react'

const STABLE_EMPTY_ARRAY = []

const useTaskVolunteeringRoles = () => {
  const organization = useCurrentOrg()
  const [request, { result, loading, called }] = useFetch()

  const getTaskRoles = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/task_roles`,
    })
  }, [organization.id, request])

  useEffect(() => {
    getTaskRoles()
  }, [getTaskRoles])

  return {
    roles: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh: getTaskRoles,
  }
}

export default useTaskVolunteeringRoles
