import { isFunction } from 'lodash';
import React, { useContext, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import MenuContext from './MenuContext';

//TODO refactor into makeStyles call
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function ActionMenuItem(props) {
    const context = useContext(MenuContext);

    const { onClick } = props;
    const handleClick = useCallback( () => {
        try {
            if( isFunction(onClick) ) onClick();
        } finally {
            if(context) context.closeMenu();
        }
    }, [context, onClick])

    return (<StyledMenuItem
        {...props}
        onClick={handleClick}
    />)

}