import { useCallback } from 'react'
import fileDownload from 'js-file-download'
import moment from 'moment'

import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { DEFAULT_TIME_ZONE } from 'civic-champs-shared/utils/format'
import { OrgNamedRef } from 'civic-champs-shared/question-sets/types'

import { useCurrentOrg } from 'auth/hooks'
import { Chat } from 'chats/interfaces'

const formatFileName = (name: string, organization: OrgNamedRef, separator = '_') => {
  const today = moment()
    .tz(organization.timeZone || DEFAULT_TIME_ZONE)
    .format(`DDMMMYYYY${separator}HH:mm`)
  return `${name.replace(/\s/g, separator)}${separator}${today}.csv`
}

export const useDownloadExportedChats = () => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored({
    successMessage: 'Export completed',
    errorMessage: 'Error exporting chats',
  })

  const downloadExportedChats = useCallback(
    (chats: Chat[]) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/chats/export`,
        config: {
          queryStringParameters: {
            ids: chats.map(({ id }) => id).join(','),
          },
          responseType: 'blob',
        },
      }).then(blob => fileDownload(blob as ArrayBuffer, formatFileName('Organization Chats', organization)))
    },
    [organization, request],
  )

  return [downloadExportedChats, status]
}

export default useDownloadExportedChats
