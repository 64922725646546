import React, { useCallback } from 'react'
import ConfirmDialog from '../../civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import { useHasRole } from '../../auth/hooks'
import { SUPER_ADMIN } from '../../civic-champs-shared/auth/utils/permissions'

interface SilentModeWarningDialogProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}
export const SilentModeWarningDialog = ({ complete, ...props }: SilentModeWarningDialogProps) => {
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const completeHandler = useCallback(() => {
    if (isSuperAdmin) {
      complete(true)
    } else {
      complete(false)
    }
  }, [complete, isSuperAdmin])
  let text =
    'Silent Mode is currently enabled, which blocks all of the organization’s outbound messages, including Custom Messages. '
  if (isSuperAdmin) {
    text += 'To be able to send a Custom Message, you must first switch off Silent Mode.'
  } else {
    text += 'Super Admin permissions are required to disable Silent Mode.'
  }
  const cancelText = isSuperAdmin ? 'Cancel' : undefined
  const confirmText = isSuperAdmin ? 'Disable Silent Mode' : 'OK'
  return (
    <ConfirmDialog
      {...props}
      complete={completeHandler}
      cancelText={cancelText}
      confirmText={confirmText}
      title="Silent Mode is Currently Enabled"
      text={text}
      type="warning"
    />
  )
}
