import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'

export const useSetGroupQuestionSets = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error setting questionnaires')
  const showSuccess = useSuccessNotification()
  const organization = useCurrentOrg()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const setGroupQuestionSets = useCallback(
    async (groupId, questionSets) => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully updated questionnaires')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/organizations/${organization.id}/groups/${groupId}/${
          isQuestionnaireAsSurveyEnabled ? 'group-surveys' : 'group-question-sets'
        }`,
        config: {
          body: { questionSets },
        },
      })
    },
    [isQuestionnaireAsSurveyEnabled, onResultUpdate, organization.id, request, showSuccess],
  )

  return [
    setGroupQuestionSets,
    {
      groupQuestionSets: result,
      loading,
    },
  ]
}
