import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import { useCreateApiNotification } from '../../civic-champs-shared/api/hooks'
import { OfferSignup } from './useOfferSignupsCollection'
import AddSignupsPrompt from '../components/AddSignupsPrompt'
import { useAddOfferSignups } from './useAddOfferSignups'

export const useShowAddOfferPrompt = (opportunityId: number, onSuccess?: (result: OfferSignup[]) => void) => {
  const showPrompt = useShowPrompt(AddSignupsPrompt)
  const createNotification = useCreateApiNotification()
  const addSignUps = useAddOfferSignups(opportunityId)

  return useCallback(async () => {
    const userIds: number[] = await showPrompt()
    const notification = createNotification(`Adding sign-ups`)
    try {
      const response = await addSignUps(userIds)
      notification.onSuccess(`Sign-ups Created`)
      onSuccess?.(response)
    } catch (e) {
      notification.onError(`Failed to Add sign-ups`, e)
    }
  }, [addSignUps, createNotification, onSuccess, showPrompt])
}
