import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core/'
import { Add as AddIcon, DragHandle as DragHandleIcon } from '@material-ui/icons'

import { ContainedButton, TableMenuButton } from 'civic-champs-shared/core'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import Loading from 'components/Loading'
import { useLocationsCollection, useStyles } from '../hooks'
import PageHeader from 'App/components/PageHeader'
import { useLocationsColumns } from 'locations/hooks/useLocationColumns'
import ExportLocationsButton from 'locations/components/ExportLocationsButton'
import { useFeatureEnabled } from '../../core/feature/hooks'
import { useEventsPageState } from '../../Event/hooks/useEventsPageState'
import { EventPages } from '../../Event/redux/actions/EventNavigation'

const tableName = 'locations'

export const LocationsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ locations, initiallyLoaded }, { addEditLocation, removeLocation, reorderLocations }, eventListeners] =
    useLocationsCollection()
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columns = useLocationsColumns({ removeLocation, editLocation: addEditLocation })

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['timesUsedTotal'],
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      data: locations,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const handleReorder = useCallback(() => reorderLocations(table), [reorderLocations, table])
  const handleAddLocation = useCallback(() => addEditLocation(), [addEditLocation])

  const selectedRows = useMemo(() => table.selectedFlatRows.map((row: any) => row.original), [table.selectedFlatRows])

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Locations"
        buttons={
          <>
            <TableMenuButton startIcon={<DragHandleIcon />} onClick={handleReorder} rightMargin>
              Reorder
            </TableMenuButton>
            <ExportLocationsButton data={selectedRows} disabled={!selectedRows.length} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={handleAddLocation}>
            Add Location
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const Locations = (props: RouteComponentProps) => {
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const [, setEventsPage] = useEventsPageState()
  useEffect(() => {
    if (offersEnabled) {
      setEventsPage(EventPages.Locations)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageHeader
      title="Locations"
      subTitle={
        offersEnabled
          ? 'Create and manage locations for volunteer activities'
          : 'Use this page to keep commonly used locations organized and up to date.'
      }
    >
      <LocationsComponent {...props} />
    </PageHeader>
  )
}
