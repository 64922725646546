import { Method, RequestConfig } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { requestWithRetry } from 'civic-champs-shared/api/requestWithRetry'
import { QuestionSet } from 'civic-champs-shared/question-sets/types'
import { Survey } from './types'

export const request = <T, P = any>(requestConfig: RequestConfig<T, P>): Promise<T> => {
  const { method = 'get', url, config } = requestConfig
  return requestWithRetry({ method, url, config })
}

//TODO cloned from `useEditQuestionSet()` for not any particularly great reason...
export const editQuestionSet = (organizationId: number, questionSet: any) => {
  const { url, method } = questionSet.questionSetId
    ? {
        url: questionSet.organization
          ? `/organizations/${organizationId}/question-set/${questionSet.questionSetId}`
          : `/user-profile-question-set/${questionSet.questionSetId}`,
        method: 'put' as Method,
      }
    : {
        url: `/organizations/${organizationId}/question-set`,
        method: 'post' as Method,
      }

  return request<QuestionSet>({
    method,
    url,
    config: {
      body: questionSet,
    },
  })
}

export const getSurvey = (id: number) => request<Survey>({ method: 'get', url: `/surveys/${id}` })
