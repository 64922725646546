import React, { FC, useCallback, useEffect } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import isUndefined from 'lodash/isUndefined'
import useGetSurvey from 'surveys/hooks/useGetSurvey'
import { useSaveSurvey } from 'surveys/hooks'
import map from 'lodash/map'
import { EventQuestionnaireEditorProps } from 'Event/components/EventSurveyQuestionSetEditor'

const EventSurveyEditor: FC<EventQuestionnaireEditorProps> = ({ eventForm, setEventField, backLink, id, channel }) => {
  const isNew = isUndefined(id)
  const [getSurvey, { loading, result: survey }] = useGetSurvey()
  const surveyId = isNew ? undefined : parseInt(id as string)
  useEffect(() => {
    surveyId && getSurvey(surveyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [{ loading: surveySaving }, saveSurvey] = useSaveSurvey()
  const onSubmit = useCallback(
    async payload => {
      const result = await saveSurvey(payload, surveyId)
      if (isNew) {
        setEventField('questionSets', [
          ...eventForm.event.questionSets,
          {
            surveyId: result.id,
            name: result.versions[0].questionSet?.name,
            optional: false,
            required: true,
          },
        ])
      } else {
        setEventField(
          'questionSets',
          map(eventForm.event.questionSets, questionSet =>
            questionSet.surveyId !== surveyId
              ? { ...questionSet }
              : { ...questionSet, name: result.versions[0].questionSet?.name },
          ),
        )
      }
      return result
    },
    [eventForm.event.questionSets, isNew, saveSurvey, setEventField, surveyId],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={surveySaving}
      onSubmit={onSubmit}
      questionSet={survey?.versions[0].questionSet}
      backLink={backLink}
      backText="Back to Event Surveys"
      title={isNew ? 'New Survey' : 'Edit Survey'}
      channel={channel}
    />
  )
}

export default EventSurveyEditor
