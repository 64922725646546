import { Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as ErrorIcon } from 'images/error.svg'
import { UnsignedWaiver } from 'Event/interfaces'

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    border: '1px #E0E3E3 solid',
    padding: '8px',
    display: 'block',
  },
  tooltipHeader: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  arrow: {
    color: '#fff',
  },
  errorIcon: {
    verticalAlign: 'middle',
  },
  tooltipList: {
    paddingLeft: '15px',
  },
})

interface Credential {
  version: {
    id: number
    title: string
    content: string
    description: string
  }
}

export const WaiversTooltip = ({ value, children }: { value: UnsignedWaiver[]; children: React.ReactElement }) => {
  const classes = useStyles()
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        !!value.length && (
          <>
            <Typography className={classes.tooltipHeader}>Incomplete Waivers</Typography>
            <ul className={classes.tooltipList}>
              {value.map(({ credentialVersionId, title }: UnsignedWaiver) => (
                <li key={credentialVersionId}>{title}</li>
              ))}
            </ul>
          </>
        )
      }
      placement="left-start"
      arrow
      interactive
    >
      {children}
    </Tooltip>
  )
}

export const WaiversCell = ({ value }: { value: UnsignedWaiver[] }) => {
  const classes = useStyles()

  return value.length === 0 ? (
    'Complete'
  ) : (
    <WaiversTooltip value={value}>
      <div>
        Action Required <ErrorIcon className={classes.errorIcon} />
      </div>
    </WaiversTooltip>
  )
}
