import { useFetchRefactored, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect } from 'react'
import { RegistrantResponse } from 'Event/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { Action, BulkAction, CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'

export const useOpportunityOccurrenceRegistrants = ({ occurrenceId, date }: { occurrenceId: number; date: string }) => {
  const [fetch, { loading, called }] = useFetchRefactored<RegistrantResponse[]>()
  const [registrants, operations, eventListeners] = useRemoteCollection<RegistrantResponse>()
  const organization = useCurrentOrg()

  const refreshCollection = useCallback(async () => {
    operations.syncCollection(
      await fetch({
        url: `/organizations/${organization.id}/registrants`,
        config: {
          queryStringParameters: {
            date,
            occurrenceId,
          },
        },
      }),
    )
  }, [date, fetch, occurrenceId, operations, organization.id])

  useEffect(() => {
    refreshCollection()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [
    { registrants, loading, initiallyLoaded: called && !loading },
    {
      refreshCollection,
      eagerAdd: operations.eagerAdd,
      eagerRemoveMany: operations.eagerRemoveMany,
    },
    eventListeners,
  ] as [
    { registrants: RegistrantResponse[]; loading: boolean; initiallyLoaded: boolean },
    {
      refreshCollection: () => Promise<void>
      eagerAdd: Action<RegistrantResponse>
      eagerRemoveMany: BulkAction<RegistrantResponse>
    },
    CollectionEventListeners<RegistrantResponse>,
  ]
}
