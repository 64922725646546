import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { Visibility } from '../../../Event/modules/events/create-edit/participants/Visibility'
import { OpportunityVisibility } from '../../../Event/interfaces'

const useStyles = makeStyles(theme => ({
  datePickerWrap: {
    width: '128px',
  },
  timePickerRow: {
    margin: '16px 0',
  },
}))

const VisibilityStep = props => {
  const {
    goToPrevStep,
    goToNextStep,
    isLastStep,
    isUpdate,
    formikProps,
  } = props
  const { values, handleSubmit, setFieldValue } = formikProps
  const { visibility, visibilityGroups } = values
  const classes = useStyles()
  const disabled = visibility === OpportunityVisibility.SELECT_GROUPS_ONLY && !visibilityGroups.length;
  return (
    <Grid container direction="row">
      <Grid item container direction="row" spacing={2} className={classes.timePickerRow}>
        <Visibility visibility={visibility} setEventField={setFieldValue} visibilityGroups={visibilityGroups} allowPrivate={false} />
      </Grid>
      {!isLastStep && (
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={goToPrevStep}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={goToNextStep} disabled={disabled}>
              Next Step
            </Button>
          </Grid>
        </Grid>
      )}
      {isLastStep && (
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={goToPrevStep}>
              Go Back
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" type="submit" onClick={handleSubmit} disabled={disabled}>
              {isUpdate ? 'Update Opportunity' : 'Create Opportunity'}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default VisibilityStep
