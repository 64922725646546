import { first } from 'lodash'
import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { NotificationBlastReportingWithMessages } from 'messages/types'

import { Status, useFetchRefactored } from 'civic-champs-shared/api/hooks'

export const useGetAutomatedMessage = (): [
  (date: string, templateId: string) => Promise<NotificationBlastReportingWithMessages>,
  Status<NotificationBlastReportingWithMessages>,
] => {
  const [fetch, status] = useFetchRefactored<
    NotificationBlastReportingWithMessages,
    NotificationBlastReportingWithMessages[]
  >({ mapper: infos => first(infos) as NotificationBlastReportingWithMessages })

  const currentOrg = useCurrentOrg()
  const getMessages = useCallback(
    (date: string, templateId: string) => {
      return fetch({
        method: 'get',
        url: `/organizations/${currentOrg.id}/automated-messages`,
        config: {
          queryStringParameters: { date, templateId, includeMessages: true },
        },
      })
    },
    [currentOrg.id, fetch],
  )

  return [getMessages, status]
}
