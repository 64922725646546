import React, { useCallback, useState } from 'react'
import { TextField } from '@material-ui/core'

const Search = ({ onChange, initialValue = '' }: { onChange: (value: string) => void; initialValue?: string }) => {
  const [search, setSearch] = useState<string>(initialValue)
  const [stateTimeout, setStateTimeout] = useState<any>(null)

  const onInputChange = useCallback(
    (e: any) => {
      const value = e.target.value
      setSearch(value)
      clearTimeout(stateTimeout)
      setStateTimeout(
        setTimeout(() => {
          onChange(value)
        }, 250),
      )
    },
    [onChange, stateTimeout],
  )
  return (
    <>
      <TextField
        type="text"
        id="search"
        name="search"
        placeholder="Search"
        variant="outlined"
        value={search}
        onChange={onInputChange}
      />
    </>
  )
}

export default Search
