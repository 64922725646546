import { Status, useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { OpportunityOccurrencePersonGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import { useCallback } from 'react'

export const useGetEventGroups = (): [
  (eventId: number) => Promise<OpportunityOccurrencePersonGroup[]>,
  Status<OpportunityOccurrencePersonGroup[]>,
] => {
  const [fetch, status] = useFetchRefactored<OpportunityOccurrencePersonGroup[]>({ emptyValue: [] })
  const fetchEventGroups = useCallback(
    async (eventId: number) => {
      return fetch({ url: `/opportunity_instances/${eventId}/groups` })
    },
    [fetch],
  )

  return [fetchEventGroups, status]
}
