import React, { useCallback, useRef, useState } from 'react'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import { useOpportunityOccurrenceRegistrants } from 'Event/hooks/useOpportunityOccurrenceRegistrants'
import { RegistrantsTable } from 'registrant/components/RegistrantsTable'
import { useGetAllSearchableContacts } from 'messages/hooks'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import { RegistrantResponse } from 'Event/interfaces'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { RegistrationResponse } from '../scenes/events/detail/volunteers/hooks/RemoveShiftsPrompt'

const tableName = 'eventRegistrations'

export const EventDrilldownRegistrations = ({
  event,
  scrollTop,
  onAdd,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  scrollTop: number
  onAdd: (response: RegistrationResponse) => void
}) => {
  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)

  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  const [{ registrants, initiallyLoaded }, { refreshCollection, eagerRemoveMany, eagerAdd }, eventListeners] =
    useOpportunityOccurrenceRegistrants({
      occurrenceId: event.occurrenceId,
      date: event.startsAt as string,
    })
  const getSearchableContacts = useGetAllSearchableContacts()
  const handleAdd = useCallback(
    (response: RegistrationResponse, registrant: RegistrantResponse) => {
      eagerAdd(registrant)
      onAdd(response)
    },
    [eagerAdd, onAdd],
  )
  return (
    <>
      <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
        <EventExpansionPanelSummary name="event-registrations" style={style} ref={summaryRef}>
          <h1>Registrations</h1>
        </EventExpansionPanelSummary>
        <EventExpansionPanelDetails ref={detailsRef}>
          <RegistrantsTable
            registrants={registrants as RegistrantResponse[]}
            initiallyLoaded={initiallyLoaded as boolean}
            tableName={tableName}
            getSearchableContacts={getSearchableContacts}
            autocompleteSectionTitle="All Volunteers"
            onAdd={handleAdd}
            eventListeners={eventListeners}
            eagerRemoveMany={eagerRemoveMany}
            event={event}
            reloadRegistrants={refreshCollection as () => Promise<void>}
          />
        </EventExpansionPanelDetails>
      </EventExpansionPanel>
    </>
  )
}
