import { filter, size, sum, map, mean } from 'lodash'
import { isCampaignActive } from '../utils'

export default function useCampaignsSummary(campaigns) {
    const getDonations = c => c.totalDonations || 0

    const activeDonations = filter(campaigns, isCampaignActive).map(getDonations)
    const allDonations = map(campaigns, getDonations)

    return {
        count: size(campaigns),
        totalDonations: sum(allDonations) / 100,
        averageDonations: mean(allDonations) / 100,
        active: {
            count: size(activeDonations),
            totalDonations: sum(activeDonations) / 100
        }
    }
}
