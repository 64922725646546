import moment from 'moment'
import React, { FC } from 'react'
import { Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, withStyles } from '@material-ui/core'

import { CredentialFull } from 'civic-champs-shared/question-sets/types'

export interface ProfileWaiverTableProps {
  credentials: CredentialFull[]
}

const TableCell = withStyles(theme => ({
  root: {
    borderBottom: 'none',
  },
  head: {
    fontWeight: 700,
  },
  body: {
    color: (props: any) => props.color || theme.palette.text.primary,
  },
}))(MuiTableCell)

export const ProfileWaiverTable: FC<ProfileWaiverTableProps> = props => {
  const { credentials = [] } = props

  return (
    <Table>
      <TableHead>
        <TableRow style={{ fontWeight: 700 }}>
          <TableCell>Name</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Expires</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {credentials.map(waiver => {
          let status = 'Valid'
          let color = 'black'
          const expiration = moment(waiver.expiredAt || waiver.userCredential?.expiredAt || NaN)
          if (!waiver.signedAt) {
            status = 'Unsigned'
            color = '#5c8de8'
          } else if (moment().isAfter(expiration)) {
            status = 'Expired'
            color = '#ee875b'
          }

          return (
            <TableRow key={waiver.credential.id}>
              <TableCell color={color}>{waiver.version.title}</TableCell>
              <TableCell color={color}>{status}</TableCell>
              <TableCell color={color}>{expiration.isValid() ? expiration.format('MM/DD/YYYY') : ''}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
