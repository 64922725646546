export const APP = 'app';
export const KIOSK = 'kiosk';
export const WEB = 'web';
export const SYSTEM = 'system';

export default Object.freeze({
    APP,
    KIOSK,
    WEB,
    SYSTEM
});