import useMigrateQuestionnaires from '../../../question-sets/hooks/useMigrateQuestionnaires'
import { Button, CircularProgress } from '@material-ui/core'
import { Icon } from '@mdi/react'
import { mdiArrowRightBoldBoxOutline } from '@mdi/js'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useMigrateQuestionnairesConfirmationPrompt } from '../hooks/'

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'middle',
    fill: '#5F8FE8',
  },
})

export const MigrateQuestionnairesButton = () => {
  const [migrateQuestionnaires, { loading }] = useMigrateQuestionnaires()
  const classes = useStyles()

  const showConfirmationPrompt = useMigrateQuestionnairesConfirmationPrompt(migrateQuestionnaires)

  return (
    <Button onClick={showConfirmationPrompt} disabled={loading}>
      Migrate questionnaires
      {loading ? <CircularProgress /> : <Icon path={mdiArrowRightBoldBoxOutline} size={0.9} className={classes.icon} />}
    </Button>
  )
}
