import React, { useEffect } from 'react'
import { PeopleOverview } from '../components/PeopleOverview'
import { useFetchPeople } from '../hooks/useFetchPeople'

export const AllPeopleOverview = props => {
  const { loading, loadingProgress, people, getPeople, initiallyLoaded } = useFetchPeople()

  useEffect(() => {
    getPeople()
  }, [getPeople])

  return (
    <PeopleOverview
      title="All People"
      loading={loading || !initiallyLoaded}
      loadingProgress={loadingProgress}
      people={people}
      refresh={getPeople}
    />
  )
}
