import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    formLabel: {
      lineHeight: '50px', 
      fontSize: '18px', 
      textAlign: 'right',
    },
    formActions: {
      textAlign: 'right',
    },
}));