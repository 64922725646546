import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import qs from 'query-string'

import { EditCell, EditHeader } from 'core/table/cells'
import { DATE_RANGE, DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import format from 'civic-champs-shared/utils/format'
import { STATUS_FILTER } from 'new-mentorship/helpers/filters'

const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '50px',
    },
  },
})

const getFilterAutocomplete = () => ['Active', 'Draft']

export const useMentorshipProgramsColumns = ({ editProgram }: any) => {
  const classes = useStyles()
  return useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        filter: DYNAMIC_TEXT,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          o.matchCount > 0 ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/matches',
                search: qs.stringify({ 'programName:anyOf': `["${value}"]` }),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value}</span>
          ),
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        filter: DYNAMIC_TEXT,
      },
      {
        Header: 'Period',
        id: 'startsAt',
        disableFilters: true,
        accessor: 'startsAt',
        filter: DATE_RANGE,
        Cell: ({
          cell: {
            value,
            row: {
              original: { endsAt },
            },
          },
        }: CellProps<any>) => {
          const startDate = format.date(value)
          const endDate = endsAt ? format.date(endsAt) : 'Present'
          return (
            <span>
              {startDate} - {endDate}
            </span>
          )
        },
      },
      {
        Header: 'Total Matches',
        id: 'matchCount',
        accessor: 'matchCount',
        filter: DYNAMIC_NUMBER,
      },
      {
        Header: 'Avg. Meeting Duration',
        id: 'meetingLengthAverage',
        accessor: 'meetingLengthAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value / 60)} min` : ''),
      },
      {
        Header: '% Positive Rating by Mentors',
        id: 'mentorFeedbackAverage',
        accessor: 'mentorFeedbackAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value * 100)}%` : ''),
      },
      {
        Header: '% Positive Rating by Mentees',
        id: 'menteeFeedbackAverage',
        accessor: 'menteeFeedbackAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value * 100)}%` : ''),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        filter: STATUS_FILTER,
        getFilterAutocomplete,
        Cell: ({ cell: { value } }: CellProps<any>) => `${value[0]?.toUpperCase() || ''}${value.slice(1)}`,
        className: classes.statusColumn,
      },
      {
        id: 'actions',
        Header: <EditHeader hideDelete />,
        Cell: ({ cell }: any) => <EditCell cell={cell} onEdit={editProgram} />,
        disableSortBy: true,
        disableFilters: true,
        className: classes.actionsColumn,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes],
  )
}

export default useMentorshipProgramsColumns
