import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useUISettings } from 'hooks/useUISettings'
import { EditCell, EditHeader } from 'core/table/cells'
import { NamedRef } from 'civic-champs-shared/common/types'
import { ColorWithNameCell } from 'tags/components/ColorWithNameCell'
import { Tag, TagEntityType } from '../types'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  colorColumn: {
    '&>div': {
      width: '100px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '150px',
    },
  },
  typeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  actionsColumn: {
    '&>div': {
      width: '80px',
    },
  },
})

const TagPageComponent = ({ value = [] }: CellProps<Tag>) =>
  value.length
    ? value
        .map(
          (entity: TagEntityType) => ({ mentorship_match: 'Mentorship', group: 'Group', champion: 'Champion' }[entity]),
        )
        .join(',')
    : 'None'

export function useTagsColumns({
  editTag,
  deleteTag,
}: {
  editTag: (tag: NamedRef) => void
  deleteTag: (tag: NamedRef) => void
}) {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'order',
            Header: 'Order',
            accessor: 'order',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'color',
            Header: 'Color',
            Cell: ColorWithNameCell,
            disableFilters: true,
            className: classes.colorColumn,
          },
          {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            disableFilters: true,
          },
          {
            id: 'usedIn',
            Header: 'Page(s) Used',
            accessor: 'usedIn',
            Cell: TagPageComponent,
            disableFilters: true,
          },
          {
            id: 'usedTimes',
            Header: 'Times Used',
            accessor: 'usedTimes',
            disableFilters: true,
          },
          {
            id: 'inUse',
            Header: 'In Use',
            accessor: 'usedTimes',
            Cell: ({ value }: CellProps<Tag>) => (value > 0 ? 'Yes' : 'No'),
            disableFilters: true,
          },
          {
            id: 'actions',
            Header: EditHeader,
            Cell: ({ cell }: CellProps<Tag>) => <EditCell cell={cell} onEdit={editTag} onDelete={deleteTag} />,
            disableSortBy: true,
            disableFilters: true,
            className: classes.actionsColumn,
          },
        ],
        {
          showAdminItems,
        },
      ),
    [classes.nameColumn, classes.colorColumn, classes.actionsColumn, showAdminItems, editTag, deleteTag],
  )
}

export default useTagsColumns
