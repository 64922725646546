import { find, get } from 'lodash'
import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'

import Loading from 'components/Loading'
import useStyles from '../hooks/useMyTeamStyles'
import useTeamColumns from '../hooks/useTeamColumns'
import { useTableSearch } from 'components/table/hooks'
import DataTable from 'components/DataTable'
import Footer from 'components/Footer'
import TableToolbar from 'components/table/TableToolbarLight'
import useAdminsCollection from '../hooks/useAdminsCollection'
import MyTeamActionMenu from './MyTeamActionMenu'
import Search from 'core/table/components/Search'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { useGroups } from 'group/hooks/useGroups'
import AddPersonButton from 'civic-champs-shared/core/add-person/AddPersonButton'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Overlay } from 'components/overlay/Overlay'
import { isFirstLoginWithToken } from 'civic-champs-shared/auth/utils/isFirstLoginWithToken'
import StartingOverlay from 'components/overlay/StartingOverlay'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'

const searchConfig = {
  fields: ['person.givenName', 'person.familyName', 'person.email', 'person.phoneNumber'],
  extractField: get,
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true,
  },
}

export default function MyTeam() {
  const singleAddPersonUIEnabled = useFeatureEnabled('SingleAddPersonUI')
  const classes = useStyles()
  const isFirstLogin = isFirstLoginWithToken()
  const menuHidden = useBreakPoint(1280)

  //TODO rolled selection into collection for now...
  //     useRemoteCollection() and useRemoteCollectionPointer() interop needs work
  const [
    { members, loading, selected: selectedMember },
    { setSelected: setSelectedMember, expelMember, changeRole, addMember, addMemberLegacy },
  ] = useAdminsCollection()
  const { groups } = useGroups()
  const adminGroupId = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Admin } })?.id, [groups])

  const { query, setQuery, results: filteredMembers } = useTableSearch(members, searchConfig)
  const columns = useTeamColumns()

  return (
    <>
      {/* removed until we'll find a better UI solution*/}
      {false && (
        <Overlay>
          <StartingOverlay />
        </Overlay>
      )}
      <div className={classes.activityWrapper}>
        <Grid container xs={12} item justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Typography
              gutterBottom
              variant="h4"
              style={isFirstLogin && !menuHidden ? { zIndex: 11, background: 'white', padding: '4px' } : {}}
            >
              Admins
              <Grid item style={{ marginLeft: 10 }} component="span">
                {singleAddPersonUIEnabled ? (
                  <AddPersonButton
                    groupId={adminGroupId}
                    groupTitle="Existing Admins"
                    autocompleteLabel="Select User"
                    otherTitle="Existing Users"
                    personName="Admin"
                    disableGroupPersons={true}
                    legendWidthFixture={90}
                    handlePersonCreate={addMember}
                  />
                ) : (
                  <AddButton onClick={addMemberLegacy} />
                )}
              </Grid>
            </Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={6}>
              <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
            </Grid>
          </Grid>
        </Grid>
        {!loading ? (
          <>
            <div>
              <TableToolbar
                query={query}
                onQueryChange={setQuery}
                actionMenu={
                  <MyTeamActionMenu
                    members={filteredMembers}
                    selectedMember={selectedMember}
                    onAddMember={addMemberLegacy}
                    onRemoveMember={expelMember}
                    onChangeRole={changeRole}
                  />
                }
              />
              <DataTable columns={columns} data={filteredMembers} onSelectedChanged={setSelectedMember} />
            </div>
            <Footer />
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}
