type Keys = 'AWS_REGION' |
  'COGNITO_USER_POOL_ID' |
  'COGNITO_WEB_CLIENT_ID' |
  'COGNITO_AUTH_FLOW' |
  'API_ENDPOINT' |
  'GOOGLE_MAPS_KEY'

export default class EnvService {
  getValue(key: Keys) {
    return process.env[`REACT_APP_${key}`]
  }
}
