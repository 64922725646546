import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'

import { AddEditTagModal } from '../components/AddEditTagModal'
import { useSaveTag } from './index'

import { Tag, TagPayload } from '../types'

export const useAddEditTagPrompt = ({
  eagerAdd,
  eagerReplace,
}: {
  eagerReplace: (tag: TagPayload) => {}
  eagerAdd: (tag: TagPayload) => {}
}) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(AddEditTagModal)
  const { saveTag } = useSaveTag()
  return useCallback(
    async (tag?: TagPayload): Promise<Tag | null> => {
      const formData = await showPrompt({ tag })
      const notification = createNotification('Saving tag')
      try {
        const savedTag = await saveTag(formData, { reporting: true })
        if (formData.id) {
          eagerReplace(savedTag)
        } else {
          eagerAdd(savedTag)
        }

        notification.onSuccess('Tag was saved')
        return savedTag
      } catch (error) {
        notification.onError('Failed to save tag', error)
      }

      return null
    },
    [createNotification, eagerAdd, eagerReplace, saveTag, showPrompt],
  )
}

export default useAddEditTagPrompt
