import { useCallback } from 'react'
import _ from 'lodash'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { memberSummary } from './utils'

const useRemoveMember = (eagerRemoveMany) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (group, members, comment) => {
      const { numMembers, onlyOneMember, memberName } = memberSummary(members)
      const text = onlyOneMember ? `Removing ${memberName} from members...` : `Removing ${numMembers} members...`
      const undo = eagerRemoveMany(members)
      const notification = createNotification(text)

      const requestAction = (type, operation, statusCode) => {
        const personIds = _(members).filter(['status.code', statusCode]).map('person.id').value()
        if (!personIds.length) {
          return Promise.resolve(true)
        }

        return request({
          method: 'post',
          url: `/groups/${group.id}/membership_actions`,
          config: {
            body: {
              type,
              personIds,
              comment: comment || undefined,
            },
          },
        })
          .then(() => true)
          .catch((error) => {
            const text = onlyOneMember
              ? `Could not ${operation} ${memberName} from ${group.name}`
              : `Could not ${operation} ${numMembers} ${group.name}`
            notification.onError(text, error)
            undo()
            return false
          })
      }

      const results = await Promise.all([
        requestAction('withdrawn', 'withdraw', 'applicant'),
        requestAction('removed', 'remove', 'member'),
      ])

      if (results.every(Boolean)) {
        notification.onSuccess(`${numMembers} member${onlyOneMember ? '' : 's'} removed!`)
      }
    },
    [createNotification, eagerRemoveMany, request],
  )
}

export default useRemoveMember
