import React from 'react';
import { IconButton } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/FullscreenRounded';

export default function FullscreenButton({ isFullscreen, goFullscreen, ...props}) {
    return isFullscreen 
        ? null 
        : (<IconButton {...props} onClick={goFullscreen}>
            <FullscreenIcon />
        </IconButton>)
}

