import { lowerCase } from 'lodash'
import React from 'react'
import { Button, Box, CircularProgress, Typography } from '@material-ui/core'

import getErrorMessage from 'utils/getErrorMessage'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import useStyles from '../hooks/useMyUserStyles';

export default function AddUserStep(props) {
    const classes = useStyles();

    const { userLabel="User", user, submitting, error, onBack, onCancel, onDone } = props

    return (<>
        {submitting && (<Box>
            <Typography>Adding {user.givenName} {user.familyName}...</Typography>
            <CircularProgress />
        </Box>)}
        {(!submitting && error) && (<Box>
            <Alert severity="error">
                <AlertTitle>Already a Member!</AlertTitle>
                {getErrorMessage(error)}
            </Alert>
            <div>
                <Button onClick={onBack} className={classes.actionButton}>Back</Button>
                <Button onClick={onCancel} className={classes.actionButton}>Cancel</Button>
            </div>
        </Box>)}
        {(!submitting && !error) && (<Box>
            <Alert severity="success">
                <AlertTitle>{userLabel} Added</AlertTitle>
                Successfully added {user.givenName} {user.familyName} as your newest {lowerCase(userLabel)}!
            </Alert>
            <div>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onDone}
                    className={classes.actionButton}>
                    Done
                </Button>
            </div>
        </Box>)}
    </>)
}
