import { useSelector } from 'react-redux'

import { getFeatures } from '../selectors'

export const useFeatureEnabled = key => {
  let features = useSelector(getFeatures)
  let feature = features[key]

  return feature && !!feature.enabled
}
