import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export const useDisconnectSalsaIntegration = onResultUpdate => {
  const [request, { loading }] = useFetch(
    'Error Disconnecting Salsa Integration.  Please refresh the page and try again',
  )
  const { id: organizationId } = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const disconnectSalsaIntegration = useCallback(async () => {
    const confirmed = await confirm(`Are you sure you want to disconnect Salsa from Civic Champs?`, {
      title: `Disconnect Salsa`,
      confirmText: `Disconnect Now`,
      rejectText: 'Nevermind',
      subText: `Clicking the 'DISCONNECT NOW' button below will stop volunteer data from synchronizing between Salsa and Civic Champs and you will need to reenter your API Key and Group ID to restart`,
    })
    if (!confirmed) {
      return
    }
    return request({
      onSuccess: result => {
        showSuccess('Successfully disconnected Salsa Integration')
        onResultUpdate && onResultUpdate(result)
      },
      method: 'del',
      url: `/salsa/organizations/${organizationId}`,
    })
  }, [request])

  return {
    loading,
    disconnectSalsaIntegration,
  }
}
