import React from 'react'
import { Field, Form, useFormikContext } from 'formik'
import { useFormikWizardContext } from 'civic-champs-shared/formik/FormikWizard'
import { makeStyles } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import SummaryInfoDonations from './SummaryInfoDonations'
import CKEditorField from 'civic-champs-shared/formik/components/CkEditorField'
import UploadedImageField from 'civic-champs-shared/formik/components/UploadedImageField'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'

const useStyles = makeStyles(theme => ({
    buttonContainer: { 
       marginTop: '80px',
    },
    content:{
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      flexDirection: 'column'
      },
    titleText: {
      fontSize: '24px',
      fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif'
    },
    subTitle: {
        fontSize: '19px',
        fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif',
        marginTop: '46px',
      },
    titleInput:{
      borderRadius: '2px',
      width: '212px',
      heiht: '38px',
    },
    addButton: {
        display: 'flex',
        backgroundColor: '#FFCD00',
        borderRadius: '4px',
        width: '135px',
        height: '59px',
        alignItems: 'center', 
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: '40px',
    },
    editorContainer: {
        width: '757px',
        marginTop: '33px',
    },
    error: {
        color: 'red',
        fontSize: '13px',
        marginLeft: '15px',
        marginTop: '5px',
      },
    saveDraft: {
        width: '146px',
        height: '49px',
    },
    saveDraftContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        marginBottom: '62px',
        marginLeft: '90px',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexGrow: 1,
        width: '100%',
    },
    summaryContainer: {
        borderRadius: '4px 0 0 0',
        boxShadow: '0 4px 7px rgba(100, 124, 142, 0.18)',
        borderTop: '1px solid #ABB6CC',
        borderLeft: '1px solid #ABB6CC',
        borderBottom: '1px solid #ABB6CC',
        padding: '21px 29px',
        alignSelf: 'flex-end',
        marginRight: '-85px',
        height: '750px',
    }     
}));

export default function DonatePrompt(props) {
    const { errors } = props
    const classes = useStyles()

    const allValues= useFormikContext()
    const stepInfo = useFormikWizardContext()
    const fullStepData = {...allValues.values, ...stepInfo}

    return (
        <div className={classes.mainContainer}>
        <div className={classes.content}>
           <h2 className={classes.titleText}>Donate Prompt</h2>  
            <Form>
                <Field 
                    component={TextField}
                    name="donatePrompt.title"
                    className={classes.titleInput}
                    placeholder="Donation title"
                    variant="outlined"
                />
                <div className={classes.editorContainer}>
                    <Field
                        component={CKEditorField}
                        editor={ClassicEditor}
                        config={{         
                            toolbar: ['bold', 'italic', 'undo', 'redo', '|',]
                        }}  
                        name="donatePrompt.firstParagraph"
                    />
                </div>
                <Field
                    component={UploadedImageField}
                    name="donatePrompt.image"
                />
            </Form>
            
            <div className={classes.buttonContainer}>
                <StepNavigation />
            </div>
        </div>
        <div className={classes.summaryContainer}>
            <SummaryInfoDonations currentStep={fullStepData}/>
        </div>
        </div>
       )
}

DonatePrompt.SCHEMA_FIELDS = [
    'donatePrompt.tile',
    'donatePrompt.firstParagraph',
    'donatePrompt.image'
]
