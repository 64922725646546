import React, { useCallback, useEffect, useMemo } from 'react'
import { useDateRangeFilter } from 'filtering/hooks'
import useEventsCollection from 'Event/scenes/events/hooks/useEventsCollection'
import { DATE_RANGE_THIS_WEEK, getDateRangeFromPreset } from 'components/filter/DateRangePresets'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import Loading from 'civic-champs-shared/core/Loading'
import { mapDailyEvents } from 'Event/helpers/mapDailyEvents'
import { Row, useColumnOrder, useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import { ExtendedPagedTable } from 'core/table/components'
import { TableMenuButton } from 'civic-champs-shared'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import useFakeHistory from 'core/helpers/useFakeHistory'
import useDailyEventsColumns, { useStyles } from 'Event/hooks/useDailyEventsColumns'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import { OrgNamedRef } from 'civic-champs-shared/common/types'
import { useRowSelect } from 'core/table/table-hooks/useRowSelect'
import { useGlobalFilter } from 'core/table/table-hooks/useGlobalFilter'
import useCancelEventsDialog from 'Event/hooks/useCancelEventsDialog'
import { useMessageEventsRegistrants } from 'Event/scenes/events/hooks/useMessageEventsRegistrants'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import ExportDailyEventsButton from 'Event/components/ExportDailyEventsButton'
import cn from 'classnames'

const tableName = 'dailyEvents'
const defaultDateRange = getDateRangeFromPreset(DATE_RANGE_THIS_WEEK)
const expanded = { '0': true, '1': true, '2': true, '3': true, '4': true, '5': true, '6': true }

export interface OpportunityRegistrations {
  total: number
  confirmed: number
}

export interface OpportunityRow {
  id: number
  occurrenceId?: number
  opportunityId?: number
  startsAt: Date
  endsAt?: Date
  name: string
  registrations: OpportunityRegistrations
  organization?: OrgNamedRef
  subRows?: OpportunityRow[]
}

export const DailyEventsTable = ({ search }: { search: string }) => {
  const [{ startDate, endDate }] = useDateRangeFilter({ key: tableName, defaultDateRange })
  const [{ events, loading }, { eagerRemoveMany }] = useEventsCollection({
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  })
  const rows = useMemo(() => mapDailyEvents(events), [events])
  const history = useFakeHistory()
  const columnStyles = useStyles()
  const showCancelEventsDialog = useCancelEventsDialog({ eagerRemoveMany })
  const messageEventRegistrants = useMessageEventsRegistrants()

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useDailyEventsColumns()
  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: DEFAULT_FILTERS,
    getOperatorOptions: () => [],
  })

  const table: any = useTable<OpportunityRow>(
    {
      initialState: {
        // @ts-ignore
        globalFilter: '',
        expanded,
      },
      globalFilter,
      paginateExpandedRows: false,
      filterTypes: DEFAULT_FILTERS,
      data: rows,
      columns,
      autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(
      () => true,
      columnStyles.selectColumn,
      (row: any): string =>
        cn({ [columnStyles.subRow]: !row.canExpand, [columnStyles.firstSubRow]: !row.canExpand && row.index === 0 }),
    ),
  )

  const [selectedFlatRows, selectedFlatRowsWithRegistrations] = useMemo(() => {
    const selectedFlatRows: Row<OpportunityRow>[] = table.selectedFlatRows.filter(({ canExpand }: any) => !canExpand)
    const selectedFlatRowsWithRegistrations = selectedFlatRows.filter(row => row.original.registrations.confirmed)
    return [selectedFlatRows, selectedFlatRowsWithRegistrations]
  }, [table.selectedFlatRows])

  const handleCancel = useCallback(() => {
    showCancelEventsDialog(selectedFlatRows)
  }, [selectedFlatRows, showCancelEventsDialog])

  const handleMessage = useCallback(() => {
    messageEventRegistrants(
      selectedFlatRowsWithRegistrations.map(({ original }) => encodeOccurrenceFromEvent(original)),
    )
  }, [messageEventRegistrants, selectedFlatRowsWithRegistrations])

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <ExtendedPagedTable
      history={history}
      table={table}
      columns={columns}
      columnState={columnState}
      tableName={tableName}
      externalSearch={search}
      customDateKey={tableName}
      defaultDateRange={defaultDateRange}
      compactView
      expandable={false}
      title="Daily Events"
      buttons={
        <>
          <TableMenuButton
            startIcon={<MessageOutlinedIcon />}
            onClick={handleMessage}
            disabled={!selectedFlatRowsWithRegistrations.length}
            rightMargin
          >
            Message
          </TableMenuButton>
          <TableMenuButton
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
            onClick={handleCancel}
            disabled={!selectedFlatRows.length}
            rightMargin
          >
            Cancel
          </TableMenuButton>
        </>
      }
      popover={
        <>
          <ExportDailyEventsButton data={selectedFlatRows} disabled={!selectedFlatRows.length} />
        </>
      }
      useGlobalSearch
      useFilters
      useDateRange
    />
  )
}
