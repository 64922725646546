import React from 'react'
import { Field } from 'formik'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import TaskRoleField from '../formik/TaskRoleField'
import RichTextField from 'civic-champs-shared/formik/components/RichTextField'
import TimeslotsField from '../formik/TimeslotsField'

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(4)
    }
}))

export default function TaskDetails(props) {
    const classes = useStyles()
    return (
        <div>
            <Typography variant="h4" className={classes.header}>Details</Typography> 
            <Grid container direction='column' spacing={4}>
               <Grid item>
                    <Field
                        name="volunteeringRole"
                        label="Service Type"
                        component={TaskRoleField}
                    />
                </Grid>
                <Grid item>
                    <Field 
                        name="description"
                        label='Description'
                        placeholder='Please enter a description or instructions for the task, if needed'
                        component={RichTextField}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name='timeslots'
                        maxSlots={3}
                        component={TimeslotsField}
                    />
                </Grid>
                <StepNavigation />
            </Grid>
        </div>
    )
}

TaskDetails.SCHEMA_FIELDS = ['volunteeringRole', 'description', 'timeslots', 'overdueAt']