import { useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react';

export const useOpportunityPrivateTokens = (encodedOccurrence: string) => {
  const [request, { result, loading }] = useFetch() as any

  const getOpportunityPrivateTokens = useCallback(async () => {
    return await request({
      url: `/opportunity_instances/${encodedOccurrence}/private_token`,
    })
  }, [request, encodedOccurrence])

  useEffect(() => { getOpportunityPrivateTokens() }, [getOpportunityPrivateTokens])

  return {
    oneToken: result && result.token,
    loading: loading,
    refetch: getOpportunityPrivateTokens,
  }
}
