import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { DateRangePresetOptions } from './DateRangePresets';

const useStyles = makeStyles(theme => ({
    presetRangePanel: {
        margin: 'auto 1em',
        display: 'inline-grid'
    },
    header: {
        display: 'inline-flex',
        justifyContent: 'center',
        marginTop: '20px'
    },
    headerLabel: {
        //backgroundColor: '#C7DAEE',
        //border: '1px solid #D4DDE9',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '4px 15px',
        width: '100%'
    },
    list: {
        border: '1px solid #B2B2B2',
        borderRadius: '5px',
        margin: '3px 0',
        fontSize: '13px',
        listStyle: 'none',
        padding: '4px 0',
        overflow: 'hidden',
    },
    listItem: {
        padding: '0 1em',
        color: '#000000',
        '&:checked': {
            background: '#C7DAEE linear-gradient(0deg, #C7DAEE 0%, #C7DAEE 100%);'
        },
    },
    footer: {
        display: 'inline-flex',
        justifyContent: 'center',
    },
    footerButton: {
        backgroundColor: '#809ADB',
        border: '1px solid #A7AEC2',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '4px 15px',
        width: '100%',
        color: '#FAFBFD'
    },
    disabled: {
        backgroundColor: 'grey'
    }
}));

export default function DateRangePresetSelector(props) {
    const { selectedPreset, onPresetChange, canApply, onApply } = props;
    const classes = useStyles();
    const buttonClass = canApply ? classes.footerButton : cn(classes.footerButton, classes.disabled);

    return (
        <div className={classes.presetRangePanel}>
            <div className={classes.header}>
                <span className={classes.headerLabel}>Presets</span>
            </div>
            <select
                className={classes.list}
                size={DateRangePresetOptions.length}
                onChange={onPresetChange}
                value={selectedPreset}
            >
                {DateRangePresetOptions.map(option => (
                    <option
                        className={classes.listItem}
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
            <div className={classes.footer}>
                <button
                    className={buttonClass}
                    onClick={onApply}
                    disabled={!canApply}
                >
                    Apply
                </button>
            </div>
        </div>
    )
}