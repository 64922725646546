import { useFetchEventRegistrations } from 'Event/scenes/events/hooks/useFetchEventRegistrations'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import { useCallback } from 'react'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import { useGetAllSearchableContacts } from 'messages/hooks'

export const useMessageEventsRegistrants = () => {
  const fetchRegistrations = useFetchEventRegistrations()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const getSearchableContacts = useGetAllSearchableContacts()

  return useCallback(
    async (encodedOccurrences: string[]) => {
      const allRegistrations = await Promise.all(encodedOccurrences.map(fetchRegistrations))
      const recipientPersonIds = uniq(
        allRegistrations.reduce((acc, registrations) => {
          return [...acc, ...map(registrations, 'user.id')]
        }, [] as number[]),
      )
      showMessageRecipientsPrompt({
        recipientPersonIds,
        getSearchableContacts,
        autocompleteSectionTitle: 'All Volunteers',
      })
    },
    [fetchRegistrations, getSearchableContacts, showMessageRecipientsPrompt],
  )
}
