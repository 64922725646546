import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'

export const useSaveDocumentType = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<DocumentType>()
  const saveDocumentType = useCallback(
    async ({ id, ...documentType }: DocumentType) =>
      request({
        method: id ? 'put' : 'post',
        url: id
          ? `/organizations/${organization.id}/document_types/${id}`
          : `/organizations/${organization.id}/document_types`,
        config: {
          body: documentType,
        },
      }),
    [request, organization.id],
  )

  return { saveDocumentType, loading, error }
}
