import React, { useCallback } from 'react'
import { RadioGroupProps } from 'formik-material-ui/dist/RadioGroup'
import { RadioGroup as MuiRadioGroup } from '@material-ui/core'
import isString from 'lodash/isString'

export const RadioGroup = ({
  field: { name, value },
  form,
  meta,
  valueIsBoolean,
  ...props
}: RadioGroupProps & { valueIsBoolean?: boolean }) => {
  const stringValue = isString(value) ? value : value.toString()
  const setFieldValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      return form.setFieldValue(name, valueIsBoolean ? value === 'true' : value)
    },
    [form, name, valueIsBoolean],
  )
  return <MuiRadioGroup value={stringValue} onChange={setFieldValue} {...props} />
}
