import { useCallback } from 'react'
import { Status, useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { Mappings } from 'volunteer-role/components/RoleImportDialog'

export interface RolesImportConfig {
  file: string
  mappings: Mappings
  isActive: boolean
  isDefault: boolean
}

export const useRolesActivities = (): [
  (config: { orgId: number; importConfig: RolesImportConfig }) => Promise<void>,
  Status<void>,
] => {
  const [request, status] = useFetchRefactored<void>({
    successMessage: 'Roles import started successfully',
    errorMessage: 'Roles import failed to start',
    useNewNotification: true,
  })

  const importRoles = useCallback(
    ({ orgId, importConfig }: { orgId: number; importConfig: RolesImportConfig }) => {
      return request({
        method: 'post',
        url: `/organizations/${orgId}/role_import`,
        config: {
          body: importConfig,
        },
      })
    },
    [request],
  )

  return [importRoles, status]
}

export default useRolesActivities
