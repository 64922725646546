import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'

const useCreatePersonLocation = () => {
  const [request, { loading }] = useFetch()

  const createPersonLocation = useCallback(
    (personId, location) => {
      return request({
        method: 'post',
        url: `/persons/${personId}/locations`,
        config: {
          body: location,
        },
      })
    },
    [request],
  )

  return { createPersonLocation, loading }
}

export default useCreatePersonLocation
