import React from 'react'
import { DocumentFile } from 'documents/hooks/useFetchDocuments'
import ExportButton from 'core/table/components/ExportButton'
import moment from 'moment-timezone'
import map from 'lodash/map'
import { Row } from 'react-table'

const headers = [
  { label: 'Document Name', key: 'name' },
  { label: 'Document Url', key: 'url' },
  { label: 'Last Collected On', key: 'createdAt' },
  { label: 'Volunteer', key: 'uploader' },
  { label: 'Source', key: 'questionSetName' },
  { label: 'File Type', key: 'type' },
]

const formatData = (rows: Row<DocumentFile>[]) =>
  map(rows, ({ original: row }) => ({
    name: row.file.filename.substring(0, row.file.filename.lastIndexOf('.')),
    url: row.file.url,
    createdAt: moment(row.createdAt).format('MM/DD/YYYY hh:mm:ss a'),
    uploader: `${row.uploader.givenName} ${row.uploader.familyName}`,
    questionSetName: row.questionSetName,
    type: row.file.filename.substring(row.file.filename.lastIndexOf('.')),
  }))

export const ExportDocumentsButton = ({
  data,
  documentTypeName,
}: {
  data: DocumentFile[]
  documentTypeName: string
}) => {
  return (
    <ExportButton
      data={data}
      disabled={data.length === 0}
      headers={headers}
      filename={`${documentTypeName.replace(' ', '-')}-documents.csv`}
      formatData={formatData}
    />
  )
}
