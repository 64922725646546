import React, { useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { Box, Button as MuiButton, IconButton, InputBase, makeStyles, MenuItem, Select } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'

import { viewTypes } from './viewTypes'
import { changeCurrentViewType } from '../../redux/reducers/ViewEvents'

const useStyles = makeStyles((theme) => ({
    calendarNavigationBar: {
      position: 'relative',
      marginBottom: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },

    todayButton: {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      '&:hover': {
        // @ts-ignore
        backgroundColor: theme.palette.background.blue
      }
    },

    monthNavigation: {
      [theme.breakpoints.up('md')]: {
        bottom: 0,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 0)',
      },
      '& div': {
        width: 160,
        [theme.breakpoints.down('sm')]: {
          width: 100
        }
      },
      '& div input': {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          fontSize: 14
        }
      },
      '& button': {
        color: theme.palette.secondary.main
      },
    },

    viewTypeSelect: {
      position: 'absolute',
      right: 0,
      top: 0,
      textTransform: 'capitalize',

      '& .MuiSelect-select': {
        paddingTop: 9,
        paddingBottom: 9,
      },
    },

    viewTypeSelectItem: {
      textTransform: 'capitalize',
    },
  }),
)

const viewTypeToPathMap = {
  [viewTypes.DAY]: '/events/day',
  [viewTypes.WEEK]: '/events/week',
  [viewTypes.MONTH]: '/events/month',
}

export const CalendarNavigationBar = (props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { viewType } = useParams()

  const handleViewTypeChange = useCallback((ev) => {
    dispatch(changeCurrentViewType(ev.target.value))
  }, [dispatch])

  return (
    <div>
      {/* TODO event filters */}
      <div className={classes.calendarNavigationBar}>
        <MuiButton
          variant="outlined"
          onClick={props.onTodayClick}
          className={classes.todayButton}
        >
          Today
        </MuiButton>

        <div className={classes.monthNavigation}>
          <IconButton onClick={props.onPrevClick}>
            <ChevronLeft />
          </IconButton>

          <Box display="inline-flex" flexDirection="column" alignItems="center">
            <DatePicker
              value={props.date}
              format="MMMM YYYY"
              openTo="month"
              views={['month', 'year']}
              onChange={props.onMonthChange}
              TextFieldComponent={InputBase}
            />

            {viewType === viewTypes.WEEK &&
            <Box component="span">
              <b>Week:</b> {props.date.week()}
            </Box>
            }
          </Box>
          <IconButton onClick={props.onNextClick}>
            <ChevronRight />
          </IconButton>
        </div>

        <Select
          variant="outlined"
          value={viewType}
          className={classes.viewTypeSelect}
          onChange={handleViewTypeChange}
        >
          {map(viewTypes, (value, key) => (
              <MenuItem
                className={classes.viewTypeSelectItem}
                to={viewTypeToPathMap[value]}
                key={key}
                component={Link}
                value={viewTypes[key]}
              >
                {viewTypes[key]}
              </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

CalendarNavigationBar.propTypes = {
  date: PropTypes.object,
  onTodayClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
}
