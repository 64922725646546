import useFetch from './useFetch'
import { useCallback, useEffect, useMemo } from 'react'

export default function useGetLegacy(url) {
  const [fetch, status] = useFetch()
  const refetch = useCallback(() => {
    fetch({ method: 'get', url })
  }, [fetch, url])

  useEffect(refetch, [refetch])

  return useMemo(
    () => ({
      ...status,
      loading: status.loading || !status.called,
      refetch,
    }),
    [status, refetch],
  )
}
