import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { startKiosk } from "kiosk/actions"
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

const useStartKiosk = () => {
    const dispatch = useDispatch()
    const confirm = useConfirm()

    return useCallback(async (opportunity) => {
        const confirmed = await confirm(
            `Are you sure you want to start a kiosk for "${opportunity.name}?"`,
            {
                title: 'Start Kiosk',
                subText: 'Starting a kiosk will log you out of the Civic Champs Dashboard',
                confirmText: 'Start',
                rejectText: 'Cancel'
            }
        )

        if(confirmed) {
            dispatch(startKiosk(opportunity))
        }
    }, [dispatch, confirm])
}

export default useStartKiosk