import React from 'react'
import classNames from 'classnames'

import './index.scss'

interface DuplicateButtonProps {
  onClick?: () => void
  className?: string
  buttonText?: string
  iconOnly?: boolean
}

export const DuplicateButton = ({
  onClick,
  className,
  buttonText = 'Duplicate',
  iconOnly = false,
}: DuplicateButtonProps): JSX.Element => {
  const classes = classNames('duplicate-button', { [`${className}`]: className })

  return (
    <div onClick={onClick} className={classes}>
      <span className="duplicate-button__icon" />
      {!iconOnly && <span className="duplicate-button__text">{buttonText}</span>}
    </div>
  )
}

export default DuplicateButton
