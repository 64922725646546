import { takeEvery, put } from 'redux-saga/effects';
import actions from '../actions';
import { GIVINGORGS_REQUEST  } from '../actions/givingOrgs';
import { API, Auth as AuthAmplify } from 'aws-amplify';
import { get } from 'lodash';

export function* getGivingOpportunities() {
  try {
    const user = yield AuthAmplify.currentAuthenticatedUser();
    const permissions = JSON.parse(get(user, 'attributes.custom:permissions'));
    const organizationId = get(permissions, 'managedOrganization.id');
    const contributions = yield API.get('civicChampsApi', `/contributions/${organizationId}`);
    yield put(actions.givingOrgs.success(contributions));
  } catch (e) {
    yield put(actions.givingOrgs.failure());
  }
}

export default function* contributionsSaga() {
  yield takeEvery(GIVINGORGS_REQUEST , getGivingOpportunities);
}
