import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useAddGroup = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error creating group')
  const showSuccess = useSuccessNotification()
  const organization = useCurrentOrg()
  const addGroup = useCallback(
    async body => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully added group')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/organizations/${organization.id}/groups`,
        config: {
          body,
        },
      })
    },
    [onResultUpdate, organization.id, request, showSuccess],
  )

  return [
    addGroup,
    {
      group: result,
      loading,
    },
  ]
}
