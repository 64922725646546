import React, { useCallback, useState } from 'react'
import FillProgramBasicDataStep from 'new-mentorship/legacy-components/FillProgramBasicDataStep'
import SelectProgramSurveysStep from 'new-mentorship/legacy-components/SelectProgramSurveysStep'
import StepModalContainer from 'civic-champs-shared/core/StepModalContainer.old'

const steps = ['Fill Program Data', 'Configure Surveys']

export default function ProgramFlowEditor(props: any) {
  const { showing, submit, cancel, program: initialProgram } = props
  const [activeStep, setActiveStep] = useState(0)
  const [program, setProgram] = useState(initialProgram)

  const handleNext = useCallback(() => {
    if (activeStep < 1) {
      setActiveStep(activeStep + 1)
    }
  }, [activeStep])

  const onSuccess = (createdProgram: any) => {
    setProgram({ ...createdProgram })
    setActiveStep(activeStep + 1)
  }

  return (
    <StepModalContainer showing={showing} cancel={cancel} steps={steps} activeStep={activeStep}>
      {
        [
          <FillProgramBasicDataStep onSuccess={onSuccess} onNext={handleNext} program={program} cancel={cancel} />,
          <SelectProgramSurveysStep
            program={program}
            onSuccess={(data: any) => {
              submit(data)
              cancel()
            }}
            cancel={cancel}
          />,
        ][activeStep]
      }
    </StepModalContainer>
  )
}
