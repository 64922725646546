import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { MentorshipProgram } from 'new-mentorship/types'

export const useFetchProgram = (): [(id: number) => Promise<MentorshipProgram>, Status<MentorshipProgram>] => {
  const [request, status] = useFetchRefactored<MentorshipProgram>({
    errorMessage: 'Error Fetching Programs.  Please refresh the page and try again',
  })

  const fetchProgram = useCallback(
    (id: number) => {
      return request({
        method: 'get',
        url: `/mentorship-programs/${id}?includeStats=1`,
        config: {
          queryStringParameters: {
            includeStats: 1,
          },
        },
      })
    },
    [request],
  )

  return [fetchProgram, status]
}

export default useFetchProgram
