import React, { useEffect, useMemo, useRef, useState } from 'react'
import useGetWebhooks from 'integrations/hooks/useGetWebhooks'
import Loader from 'Event/components/loader'
import { useColumnOrder, usePagination, useSortBy, useTable } from 'react-table'
import Grid from '@material-ui/core/Grid'
import PagedTable from 'core/table/components/PagedTable'
import { useExtendedTableStyles } from 'core/table/table-hooks/useExtendedTableStyles'
import { useStyles } from 'champion/hooks/useChampionColumns'
import { WithLinkCell } from 'core/table/cells'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'

const useGetColumns = (classes: any) =>
  useMemo(
    () => [
      {
        id: 'eventType',
        Header: 'Event Type',
        accessor: 'eventType',
        Cell: WithLinkCell(
          ({ value }: any) => <span className={classes.link}>{value}</span>,
          (id: number) => `/webhooks/${id}/event-responses`,
        ),
      },
      {
        id: 'url',
        Header: 'Url',
        accessor: 'url',
        Cell: ({ value }: any) => {
          return (
            <a className={classes.link} href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          )
        },
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [classes],
  )

export function Webhooks({ scrollTop }: { scrollTop: number }) {
  const [getWebhooks, { loading, result: webhooks }] = useGetWebhooks()
  useEffect(() => {
    getWebhooks()
  }, [getWebhooks])
  const classes = useExtendedTableStyles()
  const cellClasses = useStyles()
  const columns = useGetColumns(cellClasses)
  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  const table = useTable(
    {
      data: webhooks,
      // @ts-ignore
      columns,
    },
    useSortBy,
    usePagination,
    useColumnOrder,
  )

  if (loading) {
    return <Loader />
  }

  // @ts-ignore
  return (
    <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
      <EventExpansionPanelSummary style={style} ref={summaryRef} name="webhooks">
        <h1>Webhooks</h1>
      </EventExpansionPanelSummary>
      <EventExpansionPanelDetails ref={detailsRef}>
        <Grid container>
          <Grid container item>
            <Grid item xs={12} className={classes.wrapper}>
              <PagedTable
                {...table}
                // @ts-ignore
                newStyles
              />
            </Grid>
          </Grid>
        </Grid>
      </EventExpansionPanelDetails>
    </EventExpansionPanel>
  )
}
