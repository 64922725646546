import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useApproveMember = () => {
  const [request, { loading }] = useFetch('Error approving member')
  const showSuccess = useSuccessNotification()

  const approveMember = useCallback(
    (member, comment) => {
      return request({
        method: 'post',
        url: `/groups/${member.group.id}/membership_actions`,
        config: {
          body: {
            type: 'approved',
            personIds: [member.id],
            comment: comment || undefined,
          },
        },
        onSuccess: () => showSuccess('Successfully approved membership'),
      })
    },
    [request],
  )

  return { approveMember, loading }
}

export default useApproveMember
