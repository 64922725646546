import React, { useCallback, useContext, useEffect } from 'react'
import makeStyles from '@material-ui/styles/makeStyles/makeStyles'
import { ReactComponent as Hand } from 'images/Hand.svg'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'
import { ReactComponent as TopArrow } from 'images/onBoardingTopArrow.svg'
import { ReactComponent as MiddleArrow } from 'images/onBoardingMiddleArrow.svg'
import { ReactComponent as BottomArrow } from 'images/onBoardingBottomArrow.svg'
import { useFocus } from 'civic-champs-shared/helpers/useFocus'
import OverlayContext from 'components/overlay/OverlayContext'

const useStyles = makeStyles({
  // @ts-ignore
  root: ({ isVSmall, isSmall, menuHidden }) => {
    const translateY = isVSmall ? '-50%' : '0'
    const translateX = menuHidden ? '-50%' : '0'
    return {
      width: isSmall ? '581px' : '736px',
      position: 'absolute',
      left: menuHidden ? '50%' : '400px',
      top: isVSmall ? '50%' : '155px',
      transform: `translate(${translateX}, ${translateY})`,
      background: '#FFFFFF',
      border: '1px solid #E1E1E2',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 35px',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      '& > p': {
        fontFamily: 'Poppins, sans-serif',
        color: '#5691EC',
        padding: 0,
        textAlign: 'center',
        margin: 0,
      },
    }
  },
  header: {
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '60px',
    marginBottom: '20px !important',
  },
  subHeader: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '20px !important',
  },
  bottom: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    margin: '10px 0 !important',
    letterSpacing: '0.25px',
  },
  bottomBold: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '20px !important',
    letterSpacing: '0.1px',
  },
  topArrow: {
    position: 'absolute',
    top: '90px',
    left: '260px',
  },
  middleArrow: {
    position: 'absolute',
    top: '153px',
    left: '10px',
  },
  bottomArrow: {
    position: 'absolute',
    top: '337px',
    left: '10px',
  },
})

const useHandleBlur = (setOpen: (v: boolean) => void) => {
  return useCallback(
    (e: any) => {
      if (document.activeElement !== e.target) {
        setOpen(false)
      }
    },
    [setOpen],
  )
}

export const StartingOverlay = () => {
  const [inputRef, setFocus] = useFocus()
  const context = useContext(OverlayContext)
  const handleBlur = useHandleBlur(context.setOpen)
  const isSmall = useBreakPoint(1440)
  const menuHidden = useBreakPoint(1280)
  const isVSmall = useBreakPoint(700, true)
  const classes = useStyles({ isVSmall, isSmall, menuHidden })
  useEffect(() => {
    // @ts-ignore
    setFocus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {!menuHidden && (
        <>
          <TopArrow className={classes.topArrow} />
          <MiddleArrow className={classes.middleArrow} />
          <BottomArrow className={classes.bottomArrow} />
        </>
      )}
      <div className={classes.root} tabIndex={0} ref={inputRef} onBlur={handleBlur}>
        <p className={classes.header}>Get started using {isSmall && <br />}Civic Champs!</p>
        <p className={classes.subHeader}>
          You can start by adding administrators,
          <br />
          volunteers, and creating events.
        </p>
        <Hand />
        <p className={classes.bottomBold}>Still have questions?</p>
        <p className={classes.bottom}>
          You can schedule an onboarding session with our customer success
          <br />
          team, just pick a date and time that works best for you below!
        </p>
        <ContainedButton
          // Avoiding button retrieve focus that results closing overlay
          onMouseDown={e => e.preventDefault()}
          onClick={() => window.open(process.env.REACT_APP_SCHEDULE_ONBOARDING_LINK)}
        >
          Schedule Onboarding Session
        </ContainedButton>
      </div>
    </>
  )
}

export default StartingOverlay
