import { makeStyles } from '@material-ui/core/styles'

export const useWaiverAddEditStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '20px',
  },
  backLink: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textDecoration: 'none',
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% + 21px)',
    marginLeft: '-21px',
    height: '50px',
    alignItems: 'center',
  },
  buttons: {
    display: 'flex',
    gap: '5px',
  },
  form: {
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    gap: '15px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  title: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
    color: '#000000',
    margin: 0,
    flexShrink: 0,
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    margin: 0,
    flexShrink: 0,
    letterSpacing: '0.5px',
  },
  expirationContainer: {
    display: 'flex',
    gap: '20px',
    marginTop: '4px',
  },
  expiration: {
    width: '252px',
  },
  textarea: {
    maxWidth: '1026px',
  },
}))
