import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import AddGroupMemberPrompt from '../components/AddGroupMemberPrompt';
import { useCallback } from 'react';

const useAddMemberPrompt = (groupId, onAddMember) => {
    const prompt = usePrompt(props => AddGroupMemberPrompt({...props, groupId}));

    return useCallback(() => {
        if(prompt.showing) return;

        //TODO this is still awkward and we're still repeating it...
        const unlistens = [
            prompt.onComplete(onAddMember),
            prompt.onHide( () => { unlistens.forEach( unlisten => unlisten() ) })
        ]

        prompt.show();
    }, [onAddMember, prompt])
}

export default useAddMemberPrompt
