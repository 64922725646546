import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import { volunteerSummary } from '../../volunteering/volunteers/hooks/utils'
import useRemoveWaiverSignature from './useRemoveWaiverSignature'

const useRemoveWaiverSignaturePrompt = (eagerRemove: (item: any) => () => void) => {
  const confirm = useConfirm()
  const removeWaiverSignature = useRemoveWaiverSignature(eagerRemove)

  return useCallback(
    async userCredentials => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(userCredentials)
      const text = onlyOneVolunteer
        ? `Are you sure you want to remove Waiver Signature for ${volunteerName}?`
        : `Are you sure you want to remove ${numVolunteers} Waiver Signatures?`
      const confirmed = await confirm(text, {
        title: 'Remove Waiver Signature',
        confirmText: 'Remove Waiver Signature',
        rejectText: 'Cancel',
      })

      if (confirmed) {
        await removeWaiverSignature(userCredentials)
      }
    },
    [confirm, removeWaiverSignature],
  )
}

export default useRemoveWaiverSignaturePrompt
