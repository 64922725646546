import { useCallback } from 'react'
import { usePrompt, useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import MessageRecipientsPromptOld from '../components/MessageRecipientsPrompt.old'
import MessageRecipientsPrompt from '../components/MessageRecipientsPrompt'
import { MessageSourceType, MessagingContact } from '../types'
import { useFeatureEnabled } from 'core/feature/hooks'
import { SilentModeWarningDialog } from '../components/SilentModeWarningDialog'
import { useCurrentOrg } from '../../auth/hooks'
import { currentOrgUpdated } from '../../auth/actions'
import useCreateOrUpdateOrganization from '../../volunteering/organizations/hooks/useCreateOrUpdateOrganization'
import { useDispatch } from 'react-redux'
import { useGetOrganizationCreateUpdatePayload } from '../../organization/useGetOrganizationCreateUpdatePayload'
import { CreateOrUpdateOrganizationParam } from '../../volunteering/organizations/models'

export const useMessageRecipientsPromptExtended = () => {
  const newMessagingDialogEnabled = useFeatureEnabled('NewMessagingDialog')
  const createOrUpdateOrganization = useCreateOrUpdateOrganization()
  const dispatch = useDispatch()
  const organization = useCurrentOrg()
  const organizationPayload = useGetOrganizationCreateUpdatePayload(organization) as CreateOrUpdateOrganizationParam
  const showWarning = useShowPrompt(SilentModeWarningDialog)
  const prompt = usePrompt(newMessagingDialogEnabled ? MessageRecipientsPrompt : MessageRecipientsPromptOld)
  const disableSandboxMode = async () => {
    const updatedOrg = await createOrUpdateOrganization(
      { ...organizationPayload, sandboxModeEnabled: false },
      organization.id,
    )
    dispatch(currentOrgUpdated(updatedOrg))
  }
  const showPrompt = useCallback(
    async ({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle,
      sourceType = MessageSourceType.Champions,
      sourceId,
    }: {
      recipientPersonIds: number[]
      getSearchableContacts: () => Promise<MessagingContact[]>
      autocompleteSectionTitle?: string
      sourceType?: MessageSourceType
      sourceId?: number | string
    }) => {
      if (organization.sandboxModeEnabled) {
        if (!(await showWarning())) {
          return
        }
        await disableSandboxMode()
      }

      // @ts-ignore
      prompt.show({ recipientPersonIds, getSearchableContacts, autocompleteSectionTitle, sourceType, sourceId })
    },
    [disableSandboxMode, organization.sandboxModeEnabled, prompt, showWarning],
  )
  return [showPrompt, (prompt as any).onComplete.bind(prompt)]
}

export const useMessageRecipientsPrompt = () => {
  const [showPrompt] = useMessageRecipientsPromptExtended()
  return showPrompt
}

export default useMessageRecipientsPrompt
