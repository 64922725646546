import { useFeatureEnabled } from '../hooks';

export default function FeatureEnabled(props) {
    let { code, children, fallback } = props;
    let enabled = useFeatureEnabled(code || '');

    if(enabled) {
        return children;
    } else if(fallback) {
        return fallback();
    } else {
        return null;
    }
}