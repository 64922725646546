export interface HasId {
    id: number
}

export enum Status {
  Uninitialized = 'uninitialized',
  Loading = 'loading',
  LoadFailed = 'load-failed',
  Ready = 'ready',
  Merging = 'merging'
}

export interface MergeEntry {
  person: HasId
  referenceList?: any[]
}

export interface State {
  status: Status
  people: MergeEntry[]
  error?: any //TODO should be string
}

export enum ActionType {
  StartLoading = 'start-loading',
  LoadFailed = 'load-failed',
  LoadSuccess = 'load-success',
  SwapPeople = 'swap-people',
  StartMerge = 'start-merge',
  MergeCanceled = 'cancel-merge',
  MergeFailed = 'merge-failed'
}

interface LoadSuccessAction {
  type: ActionType.LoadSuccess,
  payload: {
    referenceLists: any[]
  }
}

interface ErrorAction {
  type: ActionType.MergeFailed | ActionType.LoadFailed
  payload: {
    error: string
  }
}

interface EmptyAction {
  type: ActionType.StartLoading | ActionType.SwapPeople | ActionType.StartMerge | ActionType.MergeCanceled
}

export type Action = LoadSuccessAction | ErrorAction | EmptyAction