import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import AddEditTripModal from 'volunteering/trips/components/AddEditTripModal'
import { MainScreenFormValues } from 'volunteering/trips/components/TripModalMainScreen'
import { SecondScreenFormValues } from 'volunteering/trips/components/TripModalSecondScreen'
import { Moment } from 'moment'
import { Address } from 'civic-champs-shared/formik/components/AddressField'
import { mapGeofencingToEventGeofencing } from 'utils/event'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { TripOrder, TripRequest, TripRequestPayload } from 'volunteering/trips/types'

export const getDateTimeString = ({ date, time }: { date: Moment; time: Moment }): string =>
  time.year(date.year()).dayOfYear(date.dayOfYear()).toISOString()

export const useAddEditTripPrompt = ({
  updateTripOrder,
  addTripOrder,
}: {
  updateTripOrder: (id: number, body: TripRequestPayload) => void
  addTripOrder: (body: Partial<TripRequest>) => void
}) => {
  const showPrompt = useShowPrompt(AddEditTripModal)

  return useCallback(
    async (props?: { trip?: TripOrder }): Promise<any> => {
      const formData: MainScreenFormValues & SecondScreenFormValues = await showPrompt({ trip: props?.trip })
      const { geofencing: dropOffGeofencing, ...dropOffAddress } = formData.dropOffAddress as Address
      const { geofencing: pickUpGeofencing, ...pickUpAddress } = formData.pickUpAddress as Address
      //const notification = createNotification('Creating trip')
      const tripData = {
        riderId: formData.rider?.id,
        tripOrders: [
          {
            id: props?.trip?.id,
            description: formData.description || '',
            driverId: formData?.driver?.id || null,
            dropOffAt: getDateTimeString({
              date: formData.dropOffDate as Moment,
              time: formData.dropOffTime as Moment,
            }),
            dropOffLocation: {
              address: dropOffAddress,
              geofencing: {
                ...mapGeofencingToEventGeofencing(dropOffGeofencing),
                radius: DEFAULT_GEOFENCING.radius,
              },
            },
            pickupAt: getDateTimeString({ date: formData.pickUpDate as Moment, time: formData.pickUpTime as Moment }),
            pickupLocation: {
              address: pickUpAddress,
              geofencing: {
                ...mapGeofencingToEventGeofencing(pickUpGeofencing),
                radius: DEFAULT_GEOFENCING.radius,
              },
            },
            reason: formData.reason,
            recovery: formData.recovery,
            cji: formData.recovery,
            sudMh: formData.sudMh === 'null' ? null : formData.sudMh,
          },
        ],
      }
      if (props?.trip) {
        updateTripOrder(props.trip.tripRequest?.id as number, tripData)
      } else {
        addTripOrder(tripData)
      }
    },
    [addTripOrder, showPrompt, updateTripOrder],
  )
}

export default useAddEditTripPrompt
