import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid, Tooltip } from '@material-ui/core/'
import Loading from 'components/Loading'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

import DEFAULT_FILTERS from 'core/table/filters'
import ExportQuestionnairesButton from '../components/ExportQuestionnairesButton'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import AddIcon from '@material-ui/icons/Add'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import { useQuestionnairesColumns, useStyles } from '../hooks/useQuestionnairesColumns'
import { useQuestionnairesCollection } from '../hooks'
import PageHeader from 'App/components/PageHeader'

const tableName = 'questionnaires'

export const Questionnaires = (props: RouteComponentProps) => (
  <PageHeader title="Questionnaires" subTitle="Create and manage questionnaires for volunteer onboarding">
    <QuestionnairesComponent {...props} />
  </PageHeader>
)

export const QuestionnairesComponent = ({ location, history }: RouteComponentProps) => {
  const [{ questionnaires, initiallyLoaded }, { duplicateOne, deleteOne }] = useQuestionnairesCollection() as any
  const columns = useQuestionnairesColumns({ duplicateOne, deleteOne }) as any
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['version', 'lastEditedBy', 'lastEditedAt'],
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: questionnaires,
      columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Questionnaires"
        buttons={
          <Tooltip
            placement="right"
            arrow
            title={
              'This export creates a .csv of the table below, and does not include Questionnaire question and response data. To export responses, click into an individual Questionnaire.'
            }
          >
            <ExportQuestionnairesButton data={selectedRows} disabled={!selectedRows.length} />
          </Tooltip>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={() => history.push('/questionnaires/new')}>
            Add Questionnaire
          </ContainedButton>
        }
        useGlobalSearch
      />
    </Grid>
  )
}
