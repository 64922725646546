export enum EventPages {
  Calendar,
  List,
  Locations,
}
export const SET_EVENTS_PAGE = 'set-events-page'

export const setEventsPage = (page: any) => ({
  type: SET_EVENTS_PAGE,
  payload: { page },
})
