import React, { useCallback, useMemo } from 'react'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import * as _ from 'lodash'
import useDelete from 'civic-champs-shared/api/hooks/useDelete'
import { Delete, Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

export const useCancelRegistrations = (onResultUpdate: (res: any) => any): [Delete, Status<boolean>] => {
  const [deleteRegistration, status] = useDelete({
    errorMessage: 'Error cancelling registrations',
    successMessage: 'Successfully cancelled registrations',
    configMapper: ({ encodedOccurrence, volunteers }) => ({
      url: `/opportunity_instances/${encodedOccurrence}/registrations`,
      config: { queryStringParameters: { ids: _.map(volunteers, 'id') } },
      onSuccess: onResultUpdate,
    }),
  })
  const confirm = useConfirm()

  const cancelRegistrations = useCallback(
    async ({ encodedOccurrence, volunteers }) => {
      const confirmed = await confirm(
        `Are you sure you want to cancel these registrations?  This action cannot be undone`,
        {
          title: `Cancel Registrations`,
          confirmText: `Confirm`,
          rejectText: 'Cancel',
        },
      )
      if (!confirmed) {
        return
      }
      return deleteRegistration({ encodedOccurrence, volunteers })
    },
    [deleteRegistration],
  )
  return useMemo(() => [cancelRegistrations, status], [cancelRegistrations, status])
}
