import React, { useEffect } from 'react'
import { FormikHelpers as FormikActions, FormikProps, FormikValues, withFormik } from "formik"
import yup from 'civic-champs-shared/utils/yup'

import InputComponent from '../../../components/default-input'
import ActionBar from "../../../components/action-bar"
import FormWrapper from "../../../components/form-wrapper"
import {getFormikFieldError} from "../../../../../utils/getErrorMessage"
import {IUpdateUser} from "../../../../redux/actions";
import parseUsername from '../../../../../auth/utils/parseUsername'

const editUserPhoneValidationSchema = yup.object({
  // @ts-ignore
  phone: yup.string().phoneNumber().required()
})

interface IFormProps extends FormikProps<FormikValues> {
  onClickCancel?: VoidFunction
}

const EditUserPhoneForm = (props: IFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    onClickCancel,
    setFieldValue
  } = props

  useEffect(() => {
    if (values.phone) {
      setFieldValue('phone', parseUsername(values.phone))
    }
  }, [values.phone])

  return(
    <form onSubmit={handleSubmit}>
      <InputComponent
        name='phone'
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Phone Number"
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('phone', errors, touched)}
      />
      <ActionBar
        actionLeft={{
          variant: 'text',
          text: 'CANCEL',
          onClick: onClickCancel
        }}
        actionRight={{
          type: 'submit',
          color: 'yellow',
          text: 'CHANGE',
          disabled: !isValid || isSubmitting
        }}
      />
    </form>
  )
}

interface IEditUserPhoneProps {
  closePopup: VoidFunction
  onUpdate: (updates: IUpdateUser) => {}
  initialValues: {
    phone: string
  }
}

const EditUserPhone = (props: IEditUserPhoneProps) => {
  const {
    closePopup,
    onUpdate,
    initialValues
  } = props

  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>) => {
    const updates = {
      phone_number: values.phone,
      phone_number_verified: false
    }
    await onUpdate(updates)
    actions.setSubmitting(false)
  }

  const Form = withFormik({
    mapPropsToValues: ({initialValues}: any) => initialValues,
    validationSchema: editUserPhoneValidationSchema,
    handleSubmit: (values, actions) => submitFormHandler(editUserPhoneValidationSchema.cast(values), actions)
  })(EditUserPhoneForm)

  return (
    <FormWrapper title={'Edit Phone Number'}>
      <Form
        initialValues={initialValues}
        onClickCancel={closePopup}
      />
    </FormWrapper>
  )
}

export default EditUserPhone
