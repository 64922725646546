import React from 'react'
import { CellProps, Row } from 'react-table'
import { get } from 'lodash'

import { DateCell, NullableTextCell } from './cells'
import map from 'lodash/map'

export const contactAccessor =
  ({ valuePath, verificationStatusPath, type }: { valuePath: string; verificationStatusPath: string; type: string }) =>
  (row: Row): { value: any; verified: boolean; type: string } => ({
    value: get(row, valuePath),
    verified: get(row, verificationStatusPath),
    type,
  })

export const openMailToInNewTab = (email: string, event: MouseEvent) => {
  window.open(`mailto:${email}`, 'mail')
  event.preventDefault()
}

export const styleCell = (Cell: React.FC<any>, className: string) => (props: CellProps<any>) =>
  <Cell {...props} className={className} />

export const styleDateCell = (className: string) => styleCell(DateCell, className)

export const styleTextCell = (className: string) => styleCell(NullableTextCell, className)

export const createStringSort = (accessor: string) => {
  return (rowA: any, rowB: any) => {
    const valueA = (rowA.values[accessor] || '').toLowerCase().trim()
    const valueB = (rowB.values[accessor] || '').toLowerCase().trim()

    return valueA.localeCompare(valueB)
  }
}

export const createTagsSort = (accessor: string) => {
  return (rowA: any, rowB: any) => {
    const valueA = map(rowA.values[accessor], 'name').join(',').toLowerCase().trim()
    const valueB = map(rowB.values[accessor], 'name').join(',').toLowerCase().trim()

    return valueA.localeCompare(valueB)
  }
}

export const getColumnHeaderText = (column: any) =>
  typeof column.Header === 'string' ? column.Header : column.Title || column.id

export const getTableSort = (table: any): { id: string; desc: boolean } | undefined => {
  const column = table.columns.find((column: any) => column.isSorted)
  if (column) {
    return { id: column.id, desc: column.isSortedDesc }
  }
}
