import * as moment from 'moment'
import { useEffect, useCallback } from 'react'

import { useCurrentOrg } from 'auth/hooks'
import { useFetch } from 'civic-champs-shared/api/hooks'

const useKioskOpportunities = () => {
  const [request, { result, loading, error }] = useFetch()
  const organization = useCurrentOrg()

  const getOpportunities = useCallback(async () => {
    return await request({
      url: `/organizations/${organization.id}/opportunities`,
      config: {
        queryStringParameters: {
          startDate: moment().subtract(1, 'day').startOf('day').utc().format(),
          endDate: moment().add(1, 'day').endOf('day').utc().format(), //TODO this should accept a date & automatically go to end of that day!
        },
      },
    })
  }, [request, organization.id])

  useEffect(() => {
    getOpportunities()
  }, [getOpportunities])

  return {
    opportunities: result,
    loading,
    error,
    refresh: getOpportunities,
  }
}

export default useKioskOpportunities
