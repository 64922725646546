import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import ActivityReflectionIcon from './ActivityReflectionIcon'
import SmileyRatingIcon from './SmileyRatingIcon'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  value: {
    flex: 1,
    lineHeight: '24px',
    'white-space': 'nowrap',
    '-moz-text-overflow': 'ellipsis',
    '-ms-text-overflow': 'ellipsis',
    '-o-text-overflow': 'ellipsis',
    'text-overflow': 'ellipsis',
  },
}))

export default function ActivityDetailCell({ cell }) {
    const activity = cell.row.original
    const { reflection, volunteerSatisfaction } = activity

    const classes = useStyles()

    return (
        <Box component="div" className={classes.root}>
            <span className={classes.value}>{cell.value}</span>
            <SmileyRatingIcon rating={volunteerSatisfaction} />
            <ActivityReflectionIcon reflection={reflection} />
        </Box>
    )
}