import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import { contactSort, PersonLinkCell } from 'core/table/cells/modern'
import { DateCell, EditCell, NullableTextCell } from 'core/table/cells'
import { createStringSort } from 'core/table/utils'
import { createDateValueSort } from 'components/table/sort'
import DeleteIcon from '@material-ui/icons/Delete'
import { CellProps } from 'react-table'
import { ChatColumn, Member } from 'chats/interfaces'

const useColumnStyles = makeStyles(() => ({
  deleteIcon: {
    fontSize: '16px',
  },
  centered: {
    paddingTop: '5px',
    textAlign: 'center',
  },
}))
export const useChatColumns = (showDeleteChatMemberPrompt: (person: Member) => Promise<void>) => {
  const classes = useColumnStyles()
  return useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'person',
        Cell: PersonLinkCell,
        sortType: contactSort,
      },
      {
        id: 'role',
        Header: 'Permission',
        accessor: 'person.role',
        Cell: NullableTextCell,
        sortType: createStringSort('role'),
      },
      {
        id: 'memberSince',
        Header: 'Member Since',
        accessor: 'person.memberSince',
        Cell: DateCell,
        sortType: createDateValueSort('createdAt'),
      },
      {
        id: 'delete',
        Header: <DeleteIcon className={classes.deleteIcon} />,
        accessor: 'id',
        Cell: ({ row: { original }, cell }: CellProps<ChatColumn, number>) => (
          <EditCell cell={cell} onDelete={() => showDeleteChatMemberPrompt(original.person)} />
        ),
        className: classes.centered,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [classes], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
