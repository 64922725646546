import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Dialog, Typography } from '@material-ui/core';

import Loading from '../../components/Loading';
import KioskCheckIn from '../KioskCheckIn';
import KioskSignUp from '../KioskSignUp';
import StopKioskFooter from './StopKioskFooter';
import FullWaiver from './FullWaiver';
import useStyles from './useStyles';
import ErrorBoundary from 'core/ErrorBoundary';

export default function StandaloneKiosk(props) {
    const classes = useStyles();
    const { isKioskMode } = props;

    const ErrorFallback = ({ error }) => <>
        <Typography variant="h4">An Error Occurred. Please Refresh This Page.</Typography>
        {error && <Typography color="error">{error}</Typography>}
        <StopKioskFooter logoutRequired={false} />
    </>

    return (
        <Dialog
            fullScreen
            open={isKioskMode}
            className={classes.diologWrapper} //sic
        >
            <ErrorBoundary fallback={ErrorFallback}>
                {renderContent(props)}
            </ErrorBoundary>
        </Dialog>
    );
}

function renderContent(props) {
    const { opportunityName, kioskStatus, error } = props;

    switch(kioskStatus) {
        case 'starting':
            return (<>
                    <Loading />
                    <StopKioskFooter logoutRequired={false} />
                </>)
         case 'stopping':
         case 'active':
             return (<>
                 <Switch>
                     <Route path="/kiosk/signUp" component={KioskSignUp} />
                     <Route path="/kiosk/waiver/:id" component={FullWaiver} />
                     <Route path="/kiosk" component={KioskCheckIn}/>
                     <Redirect to="/kiosk" />
                 </Switch>
                 <StopKioskFooter logoutRequired={true} />
             </>)
        case 'failed':
            return (
            <div style={{ padding: 3 + 'em'}}>
                <Typography variant="h2" gutterBottom>
                    Oops!  An Error Occurred
                </Typography>
                <Typography variant="subtitle2">
                    {`We could not start a kiosk for the opportunity "${opportunityName}" at this time.`}
                    Please try again later and contact
                    &nbsp;<a href="mailto:support@civicchamps.com">Customer Support</a>&nbsp;
                    if the problem persists.
                </Typography>
                <Typography color="error">{error}</Typography>
                <StopKioskFooter logoutRequired={false} />
            </div>)
        default:
             return <StopKioskFooter logoutRequired={true}/>;
    }
}
