import { Auth } from 'aws-amplify'

import { AWS_ERRORS_CODE_TO_MESSAGE, INTERNAL_SERVER_ERROR } from '../../constants/errors'
import {
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  GET_CURRENT_USER_INFO_SUCCESS,
  GET_CURRENT_USER_INFO_FAILURE,
  USER_LOADING,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from '../types'
import BaseService from '../../services/base.service'
import ENDPOINT_ROUTES from '../../constants/APIs'
import getErrorMessage from '../../../utils/getErrorMessage'

const API = new BaseService()
export type UserAttributeType = 'email' | 'phone_number'

const userSetLoading = (isLoading: boolean) => ({
  type: USER_LOADING,
  payload: isLoading,
})

const changeUserPasswordSuccess = () => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
})
const changeUserPasswordFailure = (error: any) => ({
  type: CHANGE_USER_PASSWORD_FAILURE,
  payload: { message: error.message },
})
const changeUserPassword = (oldPass: string, newPass: string) => async (dispatch: any) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await Auth.changePassword(user, oldPass, newPass)
    dispatch(changeUserPasswordSuccess())
  } catch (exception) {
    const error = {
      // @ts-ignore
      message: AWS_ERRORS_CODE_TO_MESSAGE[exception.code] || INTERNAL_SERVER_ERROR,
    }
    dispatch(changeUserPasswordFailure(error))
    throw exception
  }
}

export interface IUserInfo {
  id: number
  givenName: string
  familyName: string
  email?: string
  emailVerified?: boolean
  phoneNumber?: string
  phoneVerified?: boolean
  cognitoSub?: string
}

const getCurrentUserInfoSuccess = (userInfo: IUserInfo) => ({
  type: GET_CURRENT_USER_INFO_SUCCESS,
  payload: { info: userInfo },
})
const getCurrentUserInfoFailure = (error: any) => ({
  type: GET_CURRENT_USER_INFO_FAILURE,
  payload: { message: getErrorMessage(error) },
})
const getCurrentUserInfo = () => async (dispatch: any) => {
  dispatch(userSetLoading(true))
  try {
    const userInfo: IUserInfo = await API.currentUser()
    dispatch(getCurrentUserInfoSuccess(userInfo))
  } catch (exception) {
    dispatch(getCurrentUserInfoFailure(exception))
    throw exception
  } finally {
    dispatch(userSetLoading(false))
  }
}

export interface IUpdateUser {
  phone_number?: string
  email?: string
  given_name?: string
  family_name?: string
  email_verified?: boolean
  phone_number_verified?: boolean
}
const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
})
const updateUserFailure = (error: any) => ({
  type: UPDATE_USER_FAILURE,
  payload: { message: error.message },
})
const updateUser = (updates: IUpdateUser) => async (dispatch: any) => {
  let response
  try {
    response = await API.postJSON(ENDPOINT_ROUTES.User.updateUser, updates)
  } catch (exception) {
    dispatch(updateUserFailure(exception))
    // @ts-ignore
    throw new Error(exception.message)
  }

  const { error } = response
  if (!error) {
    dispatch(updateUserSuccess())
  } else {
    dispatch(updateUserFailure(error))
    throw new Error(error)
  }
}

const sendUserVerificationCodeByAttribute = async (attr: UserAttributeType) => {
  try {
    await Auth.verifyCurrentUserAttribute(attr)
  } catch (exception) {
    // @ts-ignore
    throw new Error(exception.message)
  }
}
const verifyChangeUserAttribute = async (attr: UserAttributeType, code: string) => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attr, code)
  } catch (exception) {
    // @ts-ignore
    throw new Error(exception.message)
  }
}

export {
  updateUser,
  changeUserPassword,
  getCurrentUserInfo,
  verifyChangeUserAttribute,
  sendUserVerificationCodeByAttribute,
}
