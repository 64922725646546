import React from 'react'
import { Scrollbars as ScrollbarsComponent } from 'react-custom-scrollbars'

const ThumbHorizontal = (props: any) => {
  return <div style={{ background: '#74777F', borderRadius: '4px', height: '8px' }} {...props} />
}

const ThumbVertical = ({ style, ...props }: any) => {
  return <div style={{ ...style, background: '#74777F', borderRadius: '4px', width: '8px' }} {...props} />
}

export const Scrollbars = (props: any) => (
  <ScrollbarsComponent {...props} renderThumbVertical={ThumbVertical} renderThumbHorizontal={ThumbHorizontal} />
)

export default Scrollbars
