import { Status } from '../../../api/hooks/fetchRefactoredSchema'
import { useCurrentOrg } from '../../../auth/hooks'
import useFetchRefactored from '../../../api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Person } from '../../../question-sets/types'

export const usePersonSearch = (): {
  searchPerson: (email: string | null, phone: string | null, firstName: string, lastName: string) => Promise<Person[]>
  status: Status<Person[]>
} => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Person[]>()

  const searchPerson = useCallback(
    (email, phone, firstName, lastName) => {
      return request({
        method: 'get',
        url: `/organizations/${organization.id}/people`,
        config: {
          queryStringParameters: {
            email,
            phone,
            firstName,
            lastName,
          },
        },
      })
    },
    [organization, request],
  )

  return {
    searchPerson,
    status,
  }
}

export default usePersonSearch
