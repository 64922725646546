import React from 'react'
import { difference, keyBy, toUpper, truncate } from 'lodash'

import { OrgNamedRef } from 'civic-champs-shared/common/types'
import {
  NotificationBlastPreview,
  EmailAndSmSPersonIdsWithBadContacts,
} from './schema'
import { MAX_SMS_LENGTH } from '../../config'

export const getSubjectText = ({
  body,
  subject,
  organization,
}: {
  body: string
  subject: string
  organization: OrgNamedRef
}) => {
  const link = 'https://vlntr.com/ABCDEF'
  const linkElement = `<a href="${link}">${link}</a>`
  const limitText = body ? `' Read more: ${linkElement}` : ''
  const message = `${toUpper(organization.name)} has sent you a message about: ${subject && `“${subject}”`}`

  const linkElementLength = limitText.length
    ? linkElement.length - link.length
    : 0

  // each sms can contain a maximum of 160 characters in the 'GSM 03.38' character set
  const availableCharactersLength = MAX_SMS_LENGTH - Math.abs(limitText.length - linkElementLength)

  return `${truncate(message, { length: availableCharactersLength })} ${limitText}`
}

export const parseEmailAndSmSPersonIds = ({
  contactBoth,
  preview,
}: {
  contactBoth: boolean
  preview: NotificationBlastPreview
}): EmailAndSmSPersonIdsWithBadContacts => {
  const {
    recipientIdsWithEmails,
    recipientIdsWithPhoneNumbers,
    recipientIdsWithBoth,
    badContacts: allBadContacts,
  } = preview
  const emailPersonIdsSet = new Set(recipientIdsWithEmails)
  const phoneNumberPersonIdsSet = new Set(recipientIdsWithPhoneNumbers)
  const smsPersonIds = contactBoth
    ? recipientIdsWithPhoneNumbers
    : difference(recipientIdsWithPhoneNumbers, recipientIdsWithBoth)
  const badEmails = allBadContacts.filter(
    badContact =>
      badContact.currentOwner &&
      ((badContact.email && emailPersonIdsSet.has(badContact.currentOwner.id))),
  )
  const badPhoneNumbers = allBadContacts.filter(
    badContact =>
      badContact.currentOwner &&
        (badContact.phoneNumber && phoneNumberPersonIdsSet.has(badContact.currentOwner.id))
  )
  const badEmailByRecipientId = keyBy(badEmails, 'currentOwner.id')
  const badPhoneNumberByRecipientId = keyBy(badPhoneNumbers, 'currentOwner.id')
  return {
    emailPersonIds: recipientIdsWithEmails.filter(recipientId => !badEmailByRecipientId[recipientId]),
    smsPersonIds: smsPersonIds.filter(recipientId => !badPhoneNumberByRecipientId[recipientId]),
    badContacts: [...badEmails, ...badPhoneNumbers],
  }
}
