import { useCallback } from 'react'

import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

const useChangeRole = eagerReplace => {
  const [request] = useFetch()
  const createApiNotification = useCreateApiNotification()

  return useCallback(
    async (member, newRole) => {
      if (member.role === newRole) return

      const undo = eagerReplace({ ...member, role: newRole })
      const notification = createApiNotification('Updating member role...')

      try {
        await request(
          newRole != null
            ? {
                method: 'put',
                url: `/membership/${member.id}/role`,
                config: {
                  body: { role: newRole },
                },
              }
            : {
                method: 'del',
                url: `/membership/${member.id}/role`,
              },
        )
        notification.onSuccess('Member updated!')
      } catch (error) {
        notification.onError('Failed to update role', error)
        undo()
      }
    },
    [createApiNotification, eagerReplace, request],
  )
}

export default useChangeRole
