import moment from 'moment-timezone'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import ExportAnswersPrompt from '../components/ExportAnswersPrompt'
import { useCallback } from 'react'
import { useDateTimeUtils } from '../../civic-champs-shared/utils/useDateTimeUtils'

export const useShowShowExportAnswersPrompt = (
  onSuccess: (value: { startDate?: string; endDate?: string; mostRecentOnly?: boolean }) => void,
) => {
  const showPrompt = useShowPrompt(ExportAnswersPrompt)
  const { unfakeAsLocalTimeForUIAsString } = useDateTimeUtils()

  return useCallback(async () => {
    const {
      startDate,
      endDate,
      mostRecentOnly,
    }: { startDate?: moment.Moment; endDate?: moment.Moment; mostRecentOnly?: boolean } = await showPrompt()

    onSuccess({
      startDate: startDate && unfakeAsLocalTimeForUIAsString(startDate),
      endDate: endDate && unfakeAsLocalTimeForUIAsString(endDate),
      mostRecentOnly,
    })
  }, [onSuccess, showPrompt, unfakeAsLocalTimeForUIAsString])
}
