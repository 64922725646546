import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import OverlayContext from './OverlayContext'

const useStyles = makeStyles({
  onBoarding: {
    background: 'rgba(73, 133, 223, 0.8)',
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})

export const Overlay = ({ children }: { children?: React.ReactNode }) => {
  const classes = useStyles()
  const context = useContext(OverlayContext)
  return context.open && <div className={classes.onBoarding}>{children}</div>
}
