import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'

const useRemovePersonLocation = () => {
  const [request, { loading }] = useFetch()

  const removePersonLocation = useCallback(
    personLocation => {
      return request({
        method: 'del',
        url: `/persons/${personLocation.person.id}/locations/${personLocation.id}`,
        config: {
          body: personLocation,
        },
      })
    },
    [request],
  )

  return { removePersonLocation, loading }
}

export default useRemovePersonLocation
