import React, { useMemo } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core';

interface Props {
  total: number,
  current: number,
  big?: boolean,
}

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    boxSizing: 'content-box',
    width: 'calc(100% - 16px)',
    position: 'absolute',
    bottom: '4px',
    left: '8px',
    border: '1px solid #cccccc',
    color: theme.palette.neutral.black,
    background: theme.palette.background.default
  },
  progressMade: {
    display: 'block',
    height: '13px',
    backgroundColor: '#bfdf57',
  },
  big: {
    height: '32px'
  },
  critical: {
    backgroundColor: '#ff6f3e'
  },
  warning: {
    backgroundColor: '#ffcd00'
  },
  progressText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '9px'
  }
}))

const EventVolunteersProgress = (props: Props) => {
  const { total, current, big } = props
  const classes = useStyles()
  const progressPercent = total > 0 ? (current / total) * 100 : 0

  const progressMadeClasses = useMemo(
    () =>
      cn({
        [classes.progressMade]: true,
        [classes.critical]: progressPercent < 40,
        [classes.warning]: progressPercent < 70 && progressPercent > 40,
        [classes.big]: big,
      }),
    [classes.progressMade, classes.critical, classes.warning, classes.big, progressPercent, big],
  )

  return (
    <div className={classes.wrapper}>
      <span className={classes.progressText}>
        {props.current} / {props.total}
      </span>
      <span className={progressMadeClasses} style={{ width: `${progressPercent}%` }} />
    </div>
  )
}

export default EventVolunteersProgress
