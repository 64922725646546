import React from 'react'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { ExpansionPanelSummary } from '@material-ui/core'

export const EventExpansionPanelSummary = React.forwardRef(
  (
    {
      children,
      name,
      style,
    }: {
      children: React.ReactNode
      name: string
      style?: any
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const classes = useEventDrilldownStyles()
    return (
      <ExpansionPanelSummary
        classes={{
          root: classes.summaryRoot,
          expandIcon: classes.expandIcon,
          expanded: classes.expanded,
          content: classes.summaryContent,
        }}
        style={style}
        expandIcon={<ExpandLessIcon />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
        ref={ref}
      >
        {children}
      </ExpansionPanelSummary>
    )
  },
)
