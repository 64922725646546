import { useCallback } from 'react'

import { useFetchRefactored } from 'civic-champs-shared/api/hooks'

export const useSetMentoringCustomer = () => {
  const [request] = useFetchRefactored()

  return useCallback(
    async (organizationId: number, isMentoringCustomer: boolean) => {
      return request({
        method: 'put',
        url: `/organizations/${organizationId}/mentoring-customer`,
        config: {
          body: { isMentoringCustomer },
        },
      })
    },
    [request],
  )
}

export default useSetMentoringCustomer
