import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from "auth/hooks";

export default function useDonations() {
  const currentOrg = useCurrentOrg()
  const [request, { loading, error, result: donations, called }] = useFetch();
  const getDonations = useCallback(
    () => request({ url: `/organizations/${currentOrg.id}/donations` }),
    [request, currentOrg],
  )

  useEffect( () => { getDonations() }, [getDonations])

  return {
    donations,
    loading: loading || !called,
    error,
    refresh: getDonations,
  }
}

