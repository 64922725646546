import _  from 'lodash';

export const getQueryState = config => state => {
    const queryKey = _.get(config, 'queryKey', config);
    const query = state.api.queries[queryKey];
    if (query != null) {
        let queryState = {
            loading: query.running,
            error: query.error
        } 

        if( _.isFunction(config.select) ) {
            queryState.data = config.select(state);
        }

        return queryState;
    } else {
        return { data: null, loading: false, error: null }
    }
}
