import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Loading from 'components/Loading'
import yup from 'civic-champs-shared/utils/yup'
import { Field, Formik, Form } from 'formik'
import useOrganizationLongCodes from '../hooks/useOrganizationLongCodes'
import useEditOrganizationLongCode from '../hooks/useEditOrganizationLongCode'
import useDeleteOrganizationLongCode from '../hooks/useDeleteOrganizationLongCode'
import * as _ from 'lodash'
import PhoneField from 'civic-champs-shared/formik/components/PhoneField'

export const UseCase = {
  MARKETING: 'marketing',
  ACCOUNT_NOTIFICATION: 'account_notification',
}

const codeSchema = yup.object({
  code: yup
    .string()
    .phoneNumber()
    .label('Long Code')
    .required(),
  useCase: yup
    .string()
    .oneOf(Object.values(UseCase))
    .required(),
})

export default function OrganizationLongCode() {
  const { longCodes, loading: codeLoading, refresh } = useOrganizationLongCodes()
  const longCodeByUseCase = _.keyBy(longCodes, 'useCase')
  const [editOrganizationLongCode, { loading: editLoading }] = useEditOrganizationLongCode()
  const [deleteOrganizationLongCode, { loading: deleteLoading }] = useDeleteOrganizationLongCode()
  const handleSubmit = async values => {
    const longCode = longCodeByUseCase[values.useCase]
    await editOrganizationLongCode(values, longCode && longCode.id)
    await refresh()
  }
  const handleDelete = async (id) => {
    await deleteOrganizationLongCode(id)
    await refresh()
  }

  const loading = codeLoading || editLoading || deleteLoading
  if (loading) {
    return <Loading />
  }
  return Object.values(UseCase).map(useCase => {
    const label = `10 Digit Long Code: ${useCase}`
    const longCode = longCodeByUseCase[useCase]
    const longCodeId = longCode && longCode.id
    return (
      <Formik
        key={useCase}
        initialValues={{ code: (longCode && longCode.code) || '', useCase }}
        validationSchema={codeSchema}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, isValid }) => {
          return (
            <Form>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Field component={PhoneField} id="code" name="code" label={label} variant="outlined" fullWidth />
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={!isValid || isSubmitting || !dirty}>
                    Submit
                  </Button>
                  {longCodeId && (
                    <Button disabled={isSubmitting} onClick={() => handleDelete(longCodeId)}>
                      Delete
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
  })
}
