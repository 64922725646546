import React, { useMemo, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import moment from 'moment'

import SelectList from 'components/select/SelectList'
import { useQuery } from 'civic-champs-shared/api/hooks'
import { GET_OPPORTUNITIES } from 'volunteering/opportunities/queries'

const useOpportunities = (organization, reporting, startDate, endDate) => {
  const params = useMemo(
    () => ({
      variables: {
        filter: Object.assign(
          { organizationId: organization.id },
          typeof reporting !== 'undefined' ? { reporting } : null,
          startDate ? { startDate } : null,
          endDate ? { endDate: endDate.clone().add(1, 'days') } : null,
        ),
      },
    }),
    [organization.id, reporting, startDate, endDate],
  )

  return useQuery(GET_OPPORTUNITIES, params)
}

export default function OpportunitySelector(props) {
  const [isEmptyResponse, setEmptyResponse] = useState(false)
  const [disabledMessage, setDisabledMessage] = useState('')
  const { organization, value, onChange, required, disabled, startDate, endDate, reporting } = props

  const { data: opportunities, loading } = useOpportunities(organization, reporting, startDate, endDate)

  const sortyByNameAndMostRecentEndDate = (a, b) => {
    const nameComparison = a.name.localeCompare(b.name)

    if (nameComparison !== 0) {
      return nameComparison
    }

    const isAfter = moment(a.endsAt).isAfter(b.endsAt)

    return isAfter ? -1 : 1
  }

  const filterDuplicates = (acc, cv) => {
    if (!acc.some(({ opportunityId }) => opportunityId === cv.opportunityId)) {
      acc.push(cv)
    }

    return acc
  }

  let sortedOpportunities = useMemo(() => {
    if (!opportunities || opportunities.length === 0) {
      setEmptyResponse(true)
      setDisabledMessage('No opportunities available for the dates provided.')
      return
    }

    setDisabledMessage('')
    setEmptyResponse(false)

    return opportunities
      .reduce(filterDuplicates, [])
      .sort(sortyByNameAndMostRecentEndDate)
      .map(({ opportunityId, name }) => ({ id: opportunityId, name }))
  }, [opportunities])

  if (sortedOpportunities && value) {
    const valueInSelection = sortedOpportunities.find(opp => opp.id === value)

    if (typeof valueInSelection === 'undefined') {
      onChange('')
    }
  }

  return (
    <SelectList
      id="opportunity"
      name="opportunity"
      label="Opportunity"
      items={sortedOpportunities}
      value={value}
      getItemRenderer={i => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      )}
      onChange={onChange}
      required={required}
      disabled={disabled || loading || isEmptyResponse}
      disabledMessage={!loading && disabledMessage}
    />
  )
}
