import { trim } from 'lodash'
import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Formik, FormikValues, getIn } from 'formik'
import * as yup from 'yup'
import { I18n } from '@aws-amplify/core'
import { Link, Hint } from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'
import parseUsername from 'auth/utils/parseUsername'

const credentialsSchema = yup.object({
  username: yup.string().required(),
  password: yup.string().required(),
})

interface AdminProps {
  onSubmit: (userCredential: any) => void
  submitting: boolean
  error?: any
  onForgotPassword: (val: boolean) => void
  classes: any
  cancelButton: React.ReactNode
}

export default function AdminLogIn(props: AdminProps) {
  const { onSubmit, submitting, error, onForgotPassword, classes, cancelButton } = props
  const handleFormikSubmit = (values: FormikValues) => {
    let formValues = {
      username: parseUsername(trim(values.username)),
      password: values.password,
    }

    return Promise.resolve(onSubmit(formValues))
  }
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={handleFormikSubmit}
      validationSchema={credentialsSchema}
    >
      {({ values, errors, submitCount, handleChange, handleBlur, handleSubmit }) => {
        const hasError = (field: string) => submitCount > 0 && !!getIn(errors, field)
        const getError = (field: string) => hasError(field) && getIn(errors, field)

        return (
          <form onSubmit={handleSubmit} noValidate>
            <DialogTitle id="alert-dialog-title">Leave Kiosk Mode</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                An Administrator must login to exit kiosk mode.
                {error && (
                  <Typography display="block" color="error">
                    {error}
                  </Typography>
                )}
              </DialogContentText>
              <TextField
                id="username"
                name="username"
                label="Username"
                variant="outlined"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError('username')}
                helperText={getError('username')}
                disabled={submitting}
                margin="normal"
                required
                fullWidth
                autoCapitalize="none"
                autoCorrect="off"
                spellCheck="false"
                autoFocus
              />
              <TextField
                id="password"
                name="password"
                label="Password"
                variant="outlined"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={hasError('password')}
                helperText={getError('password')}
                disabled={submitting}
                margin="normal"
                required
                fullWidth
              />
              <Hint>
                {I18n.get('Forget your password? ')}
                <Link onClick={() => onForgotPassword(true)}>{I18n.get('Reset password')}</Link>
              </Hint>
            </DialogContent>
            <DialogActions>
              {cancelButton}
              <Button type="submit" className={classes.submitButton} disabled={submitting} autoFocus>
                {submitting && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
                {'Login & Exit'}
              </Button>
            </DialogActions>
          </form>
        )
      }}
    </Formik>
  )
}
