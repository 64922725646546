import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import givingOrgs from './givingOrgs'
import currentFile from './currentFile'

import auth from '../auth/reducer'
import kiosk from '../kiosk/reducer'
import fullscreen from '../kiosk/fullscreen/reducer'
import notifications from '../notifications/reducer'
import CreateEvent from '../Event/redux/reducers/CreateEvent'
import EditEvent from '../Event/redux/reducers/EditEvent'
import ViewEvents from '../Event/redux/reducers/ViewEvents'
import EventNavigation from '../Event/redux/reducers/EventNavigation'
//import tracking from '../tracking/reducer';
import api from 'civic-champs-shared/api/reducer'
//TODO both of these have STORE_KEYs defined, but it is not used here when compiling the reducer!
import features from '../core/feature/reducers/development-features'
import productFeatures from '../core/feature/reducers/product-features'
import Matches from '../Mentorship/redux/reducers/Matches'
import Match from '../Mentorship/redux/reducers/Match'
import User from '../Mentorship/redux/reducers/User'
import filter from '../filtering/reducer'
import notification from '../notification/redux/reducer'

export default history =>
  combineReducers({
    api,
    auth,
    CreateEvent,
    currentFile,
    EditEvent,
    features,
    filter,
    fullscreen,
    givingOrgs,
    kiosk,
    Match,
    Matches,
    notifications,
    productFeatures,
    router: connectRouter(history),
    ViewEvents,
    EventNavigation,
    User,
    notification,
  })
