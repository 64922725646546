import useOngoingOpportunitiesColumns, {
  useStyles as useColumnStyles,
} from 'Event/hooks/useOngoingOpportunitiesColumns'
import React, { useEffect, useMemo } from 'react'
import useOpportunitiesCollection from 'volunteering/opportunities/hooks/useOpportunitiesCollection'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import useFakeHistory from 'core/helpers/useFakeHistory'
import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import { Row } from 'types/react-table'
import useCancelOpportunitiesDialog from 'Event/hooks/useCancelOpportunitiesDialog'
import Loading from 'civic-champs-shared/core/Loading'
import { useDateRangeFilter } from 'filtering/hooks'
import { OpportunityVisibility } from 'Event/interfaces'
import { TableMenuButton } from 'civic-champs-shared'
import ExportListOfEventsButton from 'Event/components/ExportListOfEventsButton'
import { ExtendedPagedTable } from 'core/table/components'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import { linkify } from 'civic-champs-shared/utils/linkify'
import { useFeatureEnabled } from '../../core/feature/hooks'

const tableName = 'listOfEvents'

const getVisibility = (visibility: string): string => {
  switch (visibility) {
    case OpportunityVisibility.PRIVATE:
      return 'Private'
    case OpportunityVisibility.PUBLIC:
      return 'Public'
    case OpportunityVisibility.SELECT_GROUPS_ONLY:
      return 'Groups Only'
    default:
      return ''
  }
}

export interface ListOfEventsRow {
  id: number
  name: string
  startsAt: string | Date
  endsAt?: string | Date
  location: string | React.ReactNode
  visibility: string
  encodedOccurrence: string
  signupCount?: number
}

export const OngoingOpportunitiesTable = ({ search }: { search: string }) => {
  const [dateRange] = useDateRangeFilter()
  const columnStyles = useColumnStyles()
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const filters = useMemo(
    () => ({ ...dateRange, schedulable: false, isOffer: offersEnabled }),
    [dateRange, offersEnabled],
  )
  const [{ opportunities, loading }, { eagerRemoveMany, reload }] = useOpportunitiesCollection(filters)
  const data: ListOfEventsRow[] = useMemo(
    () =>
      opportunities.map(
        ({
          id,
          opportunityId,
          startsAt,
          endsAt,
          name,
          address,
          city,
          state,
          zip,
          visibility,
          status,
          locationIsAddress,
          locationDetails,
          signupCount,
        }) => ({
          id,
          encodedOccurrence: encodeOccurrenceFromEvent({ opportunityId, startsAt }),
          name,
          startsAt,
          endsAt,
          location: locationIsAddress
            ? [address, city, state, zip].filter(Boolean).join(', ')
            : linkify(locationDetails),
          visibility: getVisibility(visibility),
          status,
          signupCount,
        }),
      ),
    [opportunities],
  )
  const history = useFakeHistory()
  const columns = useOngoingOpportunitiesColumns()

  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: DEFAULT_FILTERS,
    getOperatorOptions: () => [],
  })

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable<ListOfEventsRow>(
    {
      initialState: {
        // @ts-ignore
        globalFilter: '',
      },
      globalFilter,
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data,
      // @ts-ignore
      columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const selectedRows: Row<ListOfEventsRow>[] = useMemo(() => table.selectedFlatRows, [table.selectedFlatRows])
  const showCancelOpportunitiesDialog = useCancelOpportunitiesDialog({ eagerRemoveMany, selectedRows, opportunities })

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <ExtendedPagedTable
      history={history}
      table={table}
      columns={columns}
      columnState={columnState}
      tableName={tableName}
      externalSearch={search}
      compactView
      title={offersEnabled ? 'Offers' : 'Ongoing Opportunities'}
      buttons={
        <>
          <TableMenuButton
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
            onClick={showCancelOpportunitiesDialog}
            disabled={!selectedRows.length}
            rightMargin
          >
            Cancel
          </TableMenuButton>
        </>
      }
      popover={
        <>
          <ExportListOfEventsButton data={selectedRows} disabled={!selectedRows.length} />
        </>
      }
      useGlobalSearch
      useFilters
      useDateRange
    />
  )
}

export default OngoingOpportunitiesTable
