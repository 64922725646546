import { every, reduce, min, max } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import useOpportunitiesCollection from 'volunteering/opportunities/hooks/useOpportunitiesCollection'
import useGetAffiliatedOpportunities from './useGetAffiliatedOpportunties'
import useUpdateAffiliatedOpportunities from './useUpdatedAffiliatedOpportunties'
import { useTableSearch } from 'components/table/hooks'

// the array here should be an array of 'opportunities', not occurrences
// so, we can use the key 'id', instead of 'opportunityId'
const getAsSelection = (array) => reduce(array, (selection, item) => {
    if(item && item.id) selection.add(item.id)
    return selection
}, new Set())

const updateSelection = (prev, next, opportunityId, selected) => {
    if( prev.has(opportunityId) ) {
        if(!selected) next.delete(opportunityId)
    } else if(selected) {
        next.add(opportunityId)
    }
}

// NOTE: somewhat hacky, but we need the date range from the occurrences, but we only want to show one line per opportunity
// as ultimately, the campaign is tied to an opportunity and not an occurrence
const combineOccurrencesByOpportunityId = (occurrences) => {
    const filteredOppsLookup = reduce(occurrences, (acc, occurrence) => {
        const { startsAt, endsAt, opportunityId } = occurrence
        const existingOccurrence = acc[opportunityId]
        if (existingOccurrence) {
            acc[opportunityId] = { ...occurrence, startsAt: min([startsAt, existingOccurrence.startsAt]), endsAt: max([endsAt, existingOccurrence.endsAt]) }
        } else {
            acc[opportunityId] = { ...occurrence }
        }
        return acc
    }, {})
    return Object.values(filteredOppsLookup)
}

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
      processTerm: term => term.toLowerCase(),
      prefix: true
  }
}

const useAffiliateOpportunitySelection = (campaignId, startDate, endDate) => {
    const filter = useMemo(() => ({ startDate, endDate }), [startDate, endDate])
    const [{ 
        opportunities: possibleOpps, 
        loading: loadingOpps 
    }, { 
        refresh: getPossibleOpps 
    }] = useOpportunitiesCollection(filter) 

    const { query, setQuery, results: occurrencesMatchingQuery } = useTableSearch(possibleOpps, searchConfig)
    const filteredOpps = combineOccurrencesByOpportunityId(occurrencesMatchingQuery)
    const [selected, setSelected] = useState(() => new Set())
    const isAllSelected = useMemo(
        () => every(filteredOpps, opp => selected.has(opp.opportunityId)),
        [selected, filteredOpps]
    )

    const markSelection = useCallback( (opportunityId, selected) => {
        setSelected( prev => {
            const next = new Set(prev)
            updateSelection(prev, next, opportunityId, selected)

            return next
        })
    }, [])

    const setAllSelected = useCallback( (isSelected) => {
        setSelected( prev => {
            const next = new Set(prev)
            for(let { opportunityId } of filteredOpps) {
                updateSelection(prev, next, opportunityId, isSelected)
            }

            return next
        })
    }, [filteredOpps])

    const [getAffiliateOpportunities, { loading: loadingAffiliates }] = useGetAffiliatedOpportunities()

    useEffect(() => { 
        (async () => {
            const affiliatedOpps = await getAffiliateOpportunities(campaignId)
            setSelected( getAsSelection(affiliatedOpps) )
        })()
    }, [campaignId, getAffiliateOpportunities])

    const refresh = useCallback( () => Promise.all(
        getAffiliateOpportunities(campaignId), 
        getPossibleOpps()
    ), [getAffiliateOpportunities, getPossibleOpps, campaignId])

    //TODO update
    const [updateAffiliateOpportunities, { loading: updating }] = useUpdateAffiliatedOpportunities()

    const save = useCallback( async () => {
        const ids = Array.from(selected)
        await updateAffiliateOpportunities(campaignId, ids)
        //TODO something else?
    }, [campaignId, selected, updateAffiliateOpportunities])

    return [{
        loading: loadingOpps || loadingAffiliates,
        updating,
        query,
        opportunities: filteredOpps,
        selected,
        isAllSelected,
    }, {
       refresh,
       setQuery,
       setSelected: markSelection, //bleh, name overlap...
       setAllSelected,
       save
    }]
}

export default useAffiliateOpportunitySelection;
