import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useCredentialSigningManagement(credentialId, mostRecentPerUser, syncCollection, setLoading) {
  const [request] = useFetch('Error Fetching Volunteers')
  const getSignedCredentials = useCallback(() => {
    request({ method: 'get', url: `/credentials/${credentialId}/signed_credentials?mostrecentperuser=${mostRecentPerUser}`})
      .then(data => {
        syncCollection(data);
        setLoading(false);
      })
  }, [credentialId, mostRecentPerUser, request, setLoading, syncCollection])
  useEffect(() => {
    getSignedCredentials()
  }, [getSignedCredentials, credentialId, mostRecentPerUser, request])

}
