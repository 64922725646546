import { size, sumBy } from 'lodash'
import { Volunteer } from 'champion/utils/interface'
import { Row } from 'react-table'
import { useCallback } from 'react'

export function useGetChampionSummary(valuePerHour: number) {
  return useCallback(
    (volunteerRows: Row<Volunteer>[]) => {
      const totalHours = sumBy(volunteerRows, 'original.volunteering.totalVolunteerHours') || 0
      const totalDonations = sumBy(volunteerRows, 'original.donation.totalDonations') / 100 || 0
      return {
        count: size(volunteerRows),
        totalHours: Math.round(totalHours * 100) / 100,
        totalDonations: totalDonations,
        estimatedImpact: valuePerHour * totalHours + totalDonations,
      }
    },
    [valuePerHour],
  )
}

export default useGetChampionSummary
