import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Loading from 'components/Loading'
import * as yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useOrganizationStripeId from '../hooks/useOrganizationStripeId'
import useEditOrganizationStripeId from '../hooks/useEditOrganizationStripeId'

const stripeCustomerIdSchema = yup.object({
  stripeCustomerId: yup
    .string()
    .trim()
    .matches(/^cus_[A-Za-z0-9]{14}$/, 'Stripe Customer Id must start with cus_ and end with 14 letters/numbers')
    .label('Stripe Customer Id')
    .required(),
})

export default function OrganizationStripeId() {
  const { stripeId, loading: stripeCustomerIdLoading, refresh } = useOrganizationStripeId()
  const [editOrganizationLongCode, { loading: editLoading }] = useEditOrganizationStripeId()
  const handleSubmit = async values => {
    await editOrganizationLongCode(values.stripeCustomerId, stripeId && stripeId.id)
    await refresh()
  }
  const loading = stripeCustomerIdLoading || editLoading
  if (loading) {
    return <Loading />
  }
  return (
    <Formik
      initialValues={{ stripeCustomerId: (stripeId && stripeId.stripeCustomerId) || '' }}
      validationSchema={stripeCustomerIdSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, isValid }) => {
        return (
          <Form>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Field
                  component={TextField}
                  id="stripeCustomerId"
                  name="stripeCustomerId"
                  label="Stripe Customer ID"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <p>Whenever an organization purchases a product, Stripe sends our backend information about the purchase including the customerId and the productId</p>
                <p>We must therefore save the organization's Stripe customerId, so that we can track who made each purchase and credit their account accordingly</p>
              </Grid>
              <Grid item>
                <Button type="submit" disabled={!isValid || isSubmitting || !dirty}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
