import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import './index.scss'

export default function Label(props: any) {
  const { title, className, icon, customBackgroundColor, customTextColor, color = '#5C8DE8' } = props
  const classNameList = classNames('label-component', {
    [`${className}`]: className,
    'label-component--icon': !!icon,
  })
  const setDefaultColor = color === '#EEF6FC' ? '#7387AC' : '#fff'
  const labelColorStyle: { [key: string]: string } = {
    textColor: customTextColor ? customTextColor : setDefaultColor,
    backgroundColor: customBackgroundColor ? customBackgroundColor : color,
  }
  const StyledLabel = styled('span')`
    background: ${labelColorStyle['backgroundColor']}
    color: ${labelColorStyle['textColor']}
  `
  return (
    <StyledLabel className={classNameList}>
      {icon ? icon : null}
      {title}
    </StyledLabel>
  )
}
