import { useRemoteCollection } from '../../civic-champs-shared/api/hooks'
import { useGetOrganizationCredentials } from './useGetOrganizationCredentials'
import { CredentialFull } from '../../civic-champs-shared/question-sets/types'
import { useCallback, useEffect } from 'react'
import useCreateOrUpdateCredentialPrompt from './useCreateOrUpdateCredentialPrompt'
import { CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'
import { useFeatureEnabled } from '../../core/feature/hooks'
import { useHistory } from 'react-router'

export const useOrganizationCredentialCollection = () => {
  const isNewAddEditWaiverUiEnabled = useFeatureEnabled('NewAddEditWaiverUi')
  const [credentials, operations, eventListeners] = useRemoteCollection<CredentialFull>('credential.id')
  const [getCredentials, { loading, called }] = useGetOrganizationCredentials()
  const { syncCollection, eagerAdd, eagerReplace } = operations
  const history = useHistory()

  const [addEditCredential, onCredentialAdded] = useCreateOrUpdateCredentialPrompt(false)

  useEffect(() => {
    getCredentials().then(result => syncCollection(result))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onCredentialAdded(({ updated, ...credential }: CredentialFull) => {
      if (updated) {
        eagerReplace(credential)
      } else {
        eagerAdd(credential)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const createCredential = useCallback(() => {
    isNewAddEditWaiverUiEnabled ? history.push('waivers/new') : addEditCredential()
  }, [addEditCredential, history, isNewAddEditWaiverUiEnabled])

  const updateCredential = useCallback(
    (id: number) => {
      isNewAddEditWaiverUiEnabled ? history.push(`/waivers/${id}/edit`) : addEditCredential(id)
    },
    [addEditCredential, history, isNewAddEditWaiverUiEnabled],
  )

  return [
    { credentials, loading, initiallyLoaded: called },
    { createCredential, updateCredential },
    eventListeners,
  ] as [
    { credentials: CredentialFull[]; loading: boolean; initiallyLoaded: boolean },
    { createCredential: () => void; updateCredential: (credentialId: number) => void },
    CollectionEventListeners<CredentialFull>,
  ]
}
