import { useCallback } from "react"
import { usePrompt } from "civic-champs-shared/core/modal/hooks"
import Prompts from "../components/Prompts"

const useDonationPrompt = (campaign) => {
        const prompt = usePrompt(Prompts);
        
        return useCallback(() => prompt.show({campaign}), [prompt]);
} 

export default useDonationPrompt
