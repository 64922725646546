import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

interface DisabledButtonProps {
  onClick: () => void
  classes: any
  disabled: boolean
  loading: boolean
}

export default function SalsaDisconnectButton(props: DisabledButtonProps) {
  const { onClick, classes, disabled, loading } = props
  return (
    <Button onClick={onClick} className={classes.orangeButton} disabled={disabled}>
      {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
      Disconnect
    </Button>
  )
}
