import { useState, useEffect, useCallback } from "react"

import { ExternalCustomerDatafeed } from "../types"
import useCreateCustomerDatafeed from "./useCreateCustomerDatafeed"
import useGetCustomerDatafeed  from "./useGetCustomerDatafeed"

export interface CustomerDatafeedManagement {
  datafeeds: ExternalCustomerDatafeed[]
  loading: boolean
  creating: boolean
  addDatafeed: () => Promise<ExternalCustomerDatafeed>
}

const useCustomerDatafeedManagement = () => {
  const [datafeeds, setDatafeeds] = useState<ExternalCustomerDatafeed[]>([])
  const [getDatafeeds, { loading: loadingFeeds }] = useGetCustomerDatafeed()

  useEffect(() => {
    setDatafeeds([])
    getDatafeeds().then(setDatafeeds)
  }, [getDatafeeds])

  //TODO this should probably get moved to a modal
  const [createDatafeed, { loading: creating }] = useCreateCustomerDatafeed()

  const addDatafeed = useCallback(async () => {
    const newDatafeed = await createDatafeed()
    setDatafeeds( oldValue => [...oldValue, newDatafeed ])
    return newDatafeed
  }, [createDatafeed])

  return {
    datafeeds,
    loading: loadingFeeds,
    creating, //TODO not the best name
    addDatafeed
  }
}

export default useCustomerDatafeedManagement