import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { UploadedFile } from 'civic-champs-shared/core/file-upload/interface'
import { PersonRef } from 'civic-champs-shared/question-sets/types'

export interface DocumentFileBasics {
  id: number
  createdAt: Date
  deletedAt?: Date | null
  file: UploadedFile
  uploader: PersonRef
  questionSetName: string
}

export interface DocumentFileForSurvey extends DocumentFileBasics {
  surveyId: number
}

export interface DocumentFileForQuestionnaire extends DocumentFileBasics {
  questionSetId: number
}

export type DocumentFile = DocumentFileForSurvey | DocumentFileForQuestionnaire

export const useFetchDocuments = () => {
  const [request, { loading, error, result }] = useFetchRefactored<DocumentFile[]>({ emptyValue: [] })
  const fetchDocuments = useCallback(
    async (documentTypeId: number) =>
      request({
        url: `/document_types/${documentTypeId}/document_files`,
      }),
    [request],
  )

  return { fetchDocuments, loading, error, documents: result }
}
