import { take, race, put, call, delay, fork, takeEvery, select } from 'redux-saga/effects'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import {
  KIOSK_STARTED,
  KIOSK_STOPPED,
  getWaivers,
  GET_WAIVERS,
  getWaiversSucceeded,
  getWaiversFailed,
} from 'kiosk/actions'
import { getEncodedOccurrence } from 'kiosk/selectors'

export default function* waiversSaga() {
  yield fork(watchGetWaivers)
  yield fork(synchronizeWaiversFlow)
}

export function* synchronizeWaiversFlow() {
  while (true) {
    yield take(KIOSK_STARTED)
    yield race([
      take(KIOSK_STOPPED),
      call(function* () {
        while (true) {
          yield put(getWaivers())
          yield delay(1000 * 60 * 60)
        }
      }),
    ])
  }
}

export function* watchGetWaivers() {
  yield takeEvery(GET_WAIVERS, onGetWaivers)
}

export function* onGetWaivers() {
  try {
    const waivers = yield fetchWaivers()
    yield put(getWaiversSucceeded(waivers))
  } catch (error) {
    yield put(getWaiversFailed(error))
  }
}

function* fetchWaivers() {
  let opportunityId = yield select(getEncodedOccurrence)
  let url = `/opportunity_instances/${opportunityId}/credentials`
  return yield call(requestWithRetry, { url })
}
