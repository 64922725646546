import React from 'react';
import { useSelector } from 'react-redux';

import { authInitialized } from '../selectors';

export default function AuthInitializedGate(props) {
    const { children } = props;
    const initialized = useSelector(authInitialized);

    return initialized ? <>{children}</> : null;
}