import { Notification } from 'notification/interfaces'

export const NOTIFICATION_INITIALIZED = 'notification.initialized'
export const NOTIFICATION_UPDATED = 'notification.updated'

export const notificationsInit = (notifications: Notification[]) => ({
  type: NOTIFICATION_INITIALIZED,
  payload: { notifications },
})

export const notificationUpdate = (notifications: Notification[]) => ({
  type: NOTIFICATION_UPDATED,
  payload: { notifications },
})
