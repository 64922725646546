import React, { useEffect } from 'react'
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import { formats, modules } from './formats'
import 'react-quill/dist/quill.snow.css'
import classNames from 'classnames'

import './index.scss'

/**
 * IMPORTANT NOTICE:
 * DO NOT UPGRADE REACT-QUILL TO VERSION 2.0.0
 * IT WILL CAUSE BUG, WHICH IS NOT CURRENTLY FIXED IN V2 AND DOESN'T HAVE ANY WORKAROUND
 * https://github.com/zenoamaro/react-quill/issues/409
 */
const CKEditor = props => {
  const {
    value,
    placeholder,
    error,
    //  if you need other props, insert it here
  } = props

  const handleChange = value => {
    props.onChange(value)
  }

  const handleBlur = (previousRange, source, editor) => {
    const content = editor.getText()
    const htmlContent = editor.getHTML()
    props.onBlur && props.onBlur(content.length <= 1 ? '' : htmlContent)
  }

  useEffect(() => {
    props.onBlur && value && props.onBlur(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const ckeditorClasses = classNames('custom-ckeditor', { 'custom-ckeditor--error': error })

  return (
    <React.Fragment>
      <ReactQuill
        placeholder={placeholder}
        className={ckeditorClasses}
        value={value || ''}
        modules={modules}
        formats={formats}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span className="ckeditor-error-msg">{!!error && error}</span>
    </React.Fragment>
  )
}

CKEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
}

export default CKEditor
