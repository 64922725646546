import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames'

interface LoaderProps {
  width?: number
  height?: number
  color?: string
}

const useStyles = makeStyles(theme => ({
  progress: {
    width: 100,
    height: 100,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh'
  }
}));

const Loader = (props: LoaderProps) => {
  const { width = 150, color } = props
  const classes = useStyles();
  return (
    <div className={classNames(classes.wrapper, 'custom-loader-wrap')}>
      <CircularProgress classes={{root: 'custom-loader'}} className={classes.progress} style={{ color }} size={width} />
    </div>
  )
}

export default Loader
