import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useRetryFailedMigration = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error initiating retry of failed Salsa migration')
  const showSuccess = useSuccessNotification()
  const { id: organizationId } = useCurrentOrg()

  const retryFailedMigration = useCallback(
    async (migrationIssueId, retry) => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully initiated retry')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/salsa/organizations/${organizationId}/migration-failures/${migrationIssueId}`,
        config: {
          body: {
            retry,
          },
        },
      })
    },
    [request],
  )

  return {
    retryFailedMigration,
    failedMigrations: result,
    loading,
  }
}
