import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import useReorderDocumentTypesPrompt from 'documents/hooks/useReorderDocumentTypesPrompt'
import { useFetchDocumentTypes } from 'documents/hooks/useFetchDocumentTypes'
import useRemoveDocumentTypePrompt from 'documents/hooks/useRemoveDocumentTypePrompt'
import useAddEditDocumentsPrompt from 'documents/hooks/useAddEditDocumentsPrompt'
import { CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'

export interface DocumentType {
  id: number
  organizationId: number
  name: string
  isActive: boolean
  order: number
  documentCount?: number
  lastCreated?: Date | null
  createdAt: Date
  deletedAt?: Date | null
}

export type UseDocumentTypesCollectionReturnValue = [
  {
    documentTypes: DocumentType[]
    loading: boolean
    error: Error | null
    initiallyLoaded: boolean
  },
  {
    deleteDocumentType: (documentType: DocumentType) => Promise<void>
    reorderDocumentTypes: (table: any) => Promise<void>
    addEditDocumentType: (documentType?: DocumentType) => Promise<void>
  },
  CollectionEventListeners<DocumentType>,
]

export const useDocumentTypesCollection = (): UseDocumentTypesCollectionReturnValue => {
  const { fetchDocumentTypes, loading, error } = useFetchDocumentTypes()
  const [documentTypes, operations, events] = useRemoteCollection<DocumentType>()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerRemove, eagerAdd, eagerReplace } = operations
  const getDocumentTypes = useCallback(async () => {
    const updateDocumentTypes = await fetchDocumentTypes()

    syncCollection(updateDocumentTypes)
    setInitiallyLoaded(true)
  }, [fetchDocumentTypes, syncCollection])

  useEffect(() => {
    getDocumentTypes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteDocumentType = useRemoveDocumentTypePrompt(eagerRemove)
  const reorderDocumentTypes = useReorderDocumentTypesPrompt(syncCollection)
  const addEditDocumentType = useAddEditDocumentsPrompt({ eagerAdd, eagerReplace })

  return [
    {
      documentTypes,
      loading,
      error,
      initiallyLoaded,
    },
    {
      deleteDocumentType,
      reorderDocumentTypes,
      addEditDocumentType,
    },
    events,
  ] as UseDocumentTypesCollectionReturnValue
}

export default useDocumentTypesCollection
