import _ from 'lodash'
import moment from 'moment'
import { 
    SET_TIMESHEET_MODE,
    CHECK_IN, CHECK_IN_FAILED, CHECK_IN_SUCCEEDED,
    CHECK_OUT, CHECK_OUT_FAILED, CHECK_OUT_SUCCEEDED,
    GET_VOLUNTEERING_ROLES, GET_VOLUNTEERING_ROLES_SUCCEEDED, GET_VOLUNTEERING_ROLES_FAILED
} from '../actions';
import getErrorMessage from '../../utils/getErrorMessage';

export default function(state, action) {
    switch(action.type) {
        case SET_TIMESHEET_MODE:
            return {
                ...state, 
                timesheetMode: action.payload.mode
            }

        case CHECK_IN:
            return {
                ...state,
                volunteers: updateVolunteerCheckIn(
                    state.volunteers, 
                    action.payload.volunteer,
                    moment() 
                ),
                checkIn: { running: true, error: null }
            }

        case CHECK_IN_SUCCEEDED:
            return {
                ...state,
                checkIn: { running: false, error: null }
            }

        case CHECK_IN_FAILED:
            return {
                ...state,
                checkIn: { 
                    running: false, 
                    error: getErrorMessage(action.payload.error)
                }
            }

        case CHECK_OUT:
            return {
                ...state,
                volunteers: updateVolunteerCheckIn(
                    state.volunteers, 
                    action.payload.volunteer,
                    null
                ),
                checkOut: { running: true, error: null }
            }

        case CHECK_OUT_SUCCEEDED:
            return {
                ...state,
                checkOut: { running: false, error: null }
            }

        case CHECK_OUT_FAILED:
            return {
                ...state,
                checkOut: { 
                    running: false, 
                    error: getErrorMessage(action.payload.error)
                }
            }
        
        case GET_VOLUNTEERING_ROLES:
            return {
                ...state,
                volunteeringRoles: [],
                getVolunteeringRoles: { running: true, error: null }
            }
        
        case GET_VOLUNTEERING_ROLES_SUCCEEDED:
            return {
                ...state,
                volunteeringRoles: action.payload.roles,
                getVolunteeringRoles: { running: false, error: null }
            }

        case GET_VOLUNTEERING_ROLES_FAILED:
            return {
                ...state,
                getVolunteeringRoles: { 
                    running: false,
                    error: getErrorMessage(action.payload.error)
                }
            }

        default:
            return state;
    }
}

function updateVolunteerCheckIn(volunteers, updatee, checkedInAt) {
    let newVolunteers = _.cloneDeep(volunteers);
    let toUpdate = _.find(newVolunteers, { id: updatee.id });

    toUpdate.checkedInAt = checkedInAt;

    return newVolunteers;
}