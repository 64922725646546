import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { MentorshipProgram } from 'new-mentorship/types'

export const useUpdateProgram = (): [
  (id: number, body: any) => Promise<MentorshipProgram>,
  Status<MentorshipProgram>,
] => {
  const [request, status] = useFetchRefactored<MentorshipProgram>({
    errorMessage: 'Program Update Failed',
    successMessage: 'Program Updated Successfully',
  })

  const updateProgram = useCallback(
    (id, body) => {
      return request({
        method: 'put',
        url: `/mentorship-programs/${id}`,
        config: {
          body,
        },
      })
    },
    [request],
  )

  return [updateProgram, status]
}

export default useUpdateProgram
