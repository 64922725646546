import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as yup from 'yup';

import VolunteeringRoleSelect from 'components/VolunteeringRoleSelect';

const useStyles = makeStyles( theme => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    submitButton: {
        width: '100%',
        height: 50,
        borderRadius: 25,
        background: '#FF6F3E',
        color: 'white'
    }
}))

export default function CheckOutDialog(props) {
    const { volunteer, volunteeringRoles, open, onClose, onSubmit } = props;
    const name = `${_.get(volunteer, 'user.givenName')} ${_.get(volunteer, 'user.familyName')}`;
    const [validationSchema, setValidationSchema] = useState();
    const classes = useStyles();
    
    useEffect(() => {
        setValidationSchema(yup.object({
            volunteeringRole: yup.object()
                //.oneOf(volunteeringRoles, 'must be an activity in this list')
                .nullable()
                .required("Activity is required")
                .label('activity'),
            team: yup.string().max(256).nullable().notRequired(),
            reflection: yup.string().max(1024).nullable().notRequired()
        }))
    }, [volunteeringRoles])

    //TODO this isn't the right place to do this translation...
    const handleFormikSubmit = (values, { setSubmitting }) => {
        let { team, reflection } = values;
        let formValues = {
            role: _.get(values, 'volunteeringRole.id'),
            team: !_.isEmpty(team) ? team : null,
            reflection: !_.isEmpty(reflection) ? reflection : null,
        };

        onSubmit(formValues);
        setSubmitting(false);
    }
    return (
        <Dialog 
            open={open}
            onClose={onClose}
            size="lg"
        >
            <DialogTitle disableTypography className={classes.header}>
                <h4>Complete Check-Out for {name}</h4>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik
                initialValues={{
                    volunteeringRole: null,
                    team: '',
                    reflection: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
            >{({
                values,
                errors,
                submitCount,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
            }) => (<form onSubmit={handleSubmit} noValidate>
                    <DialogContent>
                        <VolunteeringRoleSelect 
                            roles={volunteeringRoles}
                            value={values.volunteeringRole}
                            onChange={role => setFieldValue('volunteeringRole', role)}
                            error={submitCount > 0 && errors.volunteeringRole}
                        />
                        <TextField 
                            id="team"
                            name="team"
                            label="Team"
                            value={values.team}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.team}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            id="reflection"
                            name="reflection"
                            label="Reflection"
                            value={values.reflection}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.reflection}
                            variant="outlined"
                            margin="normal"
                            multiline={true}
                            rows={4}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" className={classes.submitButton}>Check-Out</Button>
                    </DialogActions>
                </form>)}
            </Formik>
        </Dialog>
    )
}
