import React, { useState } from 'react'
import { Collapse, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { createMarkup } from '../civic-champs-shared/core/utils/helpers'

const useStyles = makeStyles(theme => ({
  fade: {
    maskImage: 'linear-gradient(to bottom, black 50%, transparent 100%)',
    maskSize: 'auto 3rem',
    maskRepeat: 'no-repeat',
  },
  control: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    textAlign: 'center',
  },
}))

export default function ExpandingText(props) {
  const { text, ...rest } = props
  const [showMore, setShowMore] = useState(false)
  const classes = useStyles()

  return (
    <div {...rest}>
      <Collapse in={showMore} collapsedHeight="3rem">
        <div
          className={cn({
            [classes.fade]: !showMore,
          })}
          dangerouslySetInnerHTML={createMarkup(text)}
        />
      </Collapse>

      <span className={classes.control} onClick={() => setShowMore(!showMore)}>
        {showMore ? 'Hide...' : 'See more...'}
      </span>
    </div>
  )
}
