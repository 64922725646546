import React from 'react'
import {FormikHelpers as FormikActions, FormikProps, FormikValues, withFormik} from "formik"
import {object, string} from 'yup'

import InputComponent from '../../../components/default-input'
import FormWrapper from "../../../components/form-wrapper"
import ActionBar from "../../../components/action-bar"
import {getFormikFieldError} from "../../../../../utils/getErrorMessage"
import {IUpdateUser} from "../../../../redux/actions";
import { trim } from 'lodash'

const editUserEmailValidationSchema = object().shape({
  email: string()
    .trim()
    .lowercase()
    .email('Email is not valid')
    .required()
})

interface IFormProps extends FormikProps<FormikValues> {
  onClickCancel?: VoidFunction
}

const EditUserEmailForm = (props: IFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    onClickCancel
  } = props

  return(
    <form onSubmit={handleSubmit}>
      <InputComponent
        type='email'
        name='email'
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        autoCapitalize={'none'}
        label="Email Address"
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('email', errors, touched)}
      />
      <ActionBar
        actionLeft={{
          variant: 'text',
          text: 'CANCEL',
          onClick: onClickCancel
        }}
        actionRight={{
          type: 'submit',
          color: 'yellow',
          text: 'CHANGE',
          disabled: !isValid || isSubmitting
        }}
      />
    </form>
  )
}

interface IEditUserEmailProps {
  closePopup: VoidFunction,
  onUpdate: (updates: IUpdateUser) => {},
  initialValues: {
    email: string
  }
}

const EditUserEmail = (props: IEditUserEmailProps) => {
  const {
    closePopup,
    onUpdate,
    initialValues
  } = props

  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>) => {
    const email = trim(values.email.toLowerCase())
    const updates = {
      email,
      email_verified: false
    }
    await onUpdate(updates)
    actions.setSubmitting(false)
  }

  const Form = withFormik({
    mapPropsToValues: ({initialValues}: any) => initialValues,
    validationSchema: editUserEmailValidationSchema,
    handleSubmit: (values, actions) => submitFormHandler(editUserEmailValidationSchema.cast(values), actions)
  })(EditUserEmailForm)

  return (
    <FormWrapper title={'Edit email'}>
      <Form
        initialValues={initialValues}
        onClickCancel={closePopup}
      />
    </FormWrapper>
  )
}

export default EditUserEmail
