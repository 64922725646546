import { size } from 'lodash'
import React, { useEffect, useCallback }  from 'react'
import {  makeStyles, Link } from "@material-ui/core";

import { DateCell } from "core/table/cells";
import PropertyGrid from './PropertyGrid';
import useDonationPrompt from '../hooks/useDonationPrompt';
import AffiliatedPrompt from './AffiliatedPrompt';
import { usePrompt, useShowPrompt } from 'civic-champs-shared/core/modal/hooks';
import useGetAffiliatedOpportunities from '../hooks/useGetAffiliatedOpportunties'

const useStyles = makeStyles(theme => ({
    link: {
        color: '#5F8FE8',
    }
   }));

export default function CampaignProperties(props) {
    const { campaign, ...rest } = props
    const classes = useStyles()

    const [affiliatedOpps, setAffiliatedOpps] = React.useState(0);
    const [getAffiliateOpportunities, ] = useGetAffiliatedOpportunities()

    useEffect(() => {
        async function fetchData() {
            const affiliatedOpps = await getAffiliateOpportunities(campaign.id) 
            setAffiliatedOpps(affiliatedOpps)
        }
        fetchData();
      }, [campaign.id, getAffiliateOpportunities]);

    const showAffiliatePrompt = useShowPrompt(AffiliatedPrompt)  
    
    const showAffilatedOpps = async () => {
        await showAffiliatePrompt({
          campaignId: campaign.id,
          startsAt: campaign.startsAt,
          endsAt: campaign.endsAt,
          preview: true,
        }) 
      }  

      //TODO migrate this to a Cell, maybe EnumCell?
    const showCampaignType = () => {
        if(campaign.campaignType === 'matching_hours') {
            return "Time matching"
        }
      }

    const showTargetAudience = () => {
        if(campaign.targetGroup === 'everyone') {
            return "Everyone"
        } else if (campaign.targetGroup === 'current_donor'){
            return "Current Donors"
        } else if (campaign.targetGroup === 'never_donated'){
            return "Never donated"
        }
      }  
      
    const showFrequency = () => {
        if(campaign.frequency === "every_shift") {
            return "Always"
        } else if (campaign.frequency === 'once_a_week'){
            return "Frequently"
        } else if (campaign.frequency === 'once_a_month'){
            return "Sometimes"
        } else if (campaign.frequency === 'once_every_three_month'){
          return "Rarely"
        }
      }  

      const donationPrompts = useDonationPrompt(campaign);
    
    const campaignProps = [{
        Header: 'Campaign Type',
        accessor: () => showCampaignType()
    }, {
        Header: 'Start Date',
        accessor: 'startsAt',
        Cell: DateCell
    }, {
        Header: 'End Date',
        accessor: 'endsAt',
        Cell: DateCell
    }, {
        Header: 'Affiliated Events',
        accessor: 'opportunities',
        Cell: ({ cell }) => <Link className={classes.link} onClick={showAffilatedOpps}><u>{affiliatedOpps.length} Events</u></Link> //TODO make real!
    }, {
        Header: 'Target',
        accessor: () =>  showTargetAudience() 
    }, {
        Header: 'Campaign Settings',
        accessor: ()=> `$${campaign.timeMatchingAmountInCents/100} for every ${campaign.timeMatchingHour} hour` 
    }, {
        Header: 'Frequency',
        accessor: () => showFrequency()
    }, {
        Header: 'Prompts',
        accessor: () => null,
        Cell: ({ cell }) => <Link className={classes.link} onClick={() => donationPrompts(campaign)}  
        ><u>View Prompts</u></Link> 
    }]

    return <PropertyGrid
        {...rest}
        properties={campaignProps}
        object={campaign}
    />
}
