import { ParseResult } from 'papaparse'
import React from 'react'
import CloudUpload from '@material-ui/icons/CloudUpload'

import { ExternalCustomerDatafeed } from '../types'
import { ImportCsvButton } from './ImportCsvButton'
import { validateImport } from 'external-datafeed/api/validateImport'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { DatafeedImportPreviewPrompt } from './DatafeedImportPreviewPrompt'

interface ImportDatafeedButtonProps {
  datafeed: ExternalCustomerDatafeed
}

export const ImportDatafeedButton: React.FC<ImportDatafeedButtonProps> = (props) => {
  const { datafeed } = props

  const showImportPreview = useShowPrompt(DatafeedImportPreviewPrompt)

  const handleImport = async (result: ParseResult<any>[]) => {
    const processedRows = await validateImport(datafeed, result)
    await showImportPreview({ datafeed, importPreview: processedRows })
  }

  return (
    <ImportCsvButton onImportParsed={handleImport}>
      <CloudUpload style={{ marginRight: 5 }} />
      Import
    </ImportCsvButton>
  )
}