import type { History } from 'history'
import { LocationDescriptorObject, LocationState } from 'history'
import { useCallback, useMemo, useState } from 'react'

/**
 * We have table filters tightly coupled to the URL,
 * so we need to mock the history object to have more than one filterable table on the same page.
 */
export const useFakeHistory = (): History => {
  const [search, setSearch] = useState('')
  const [pathname, setPathname] = useState('')
  const [state, setState] = useState<any>(null)
  const push = useCallback((location: LocationDescriptorObject | string, state?: LocationState) => {
    if (typeof location === 'string') {
      const url = new URL(location, 'http://example.com')
      setSearch(url.search)
      setPathname(url.pathname)
      setState(state || null)
    } else {
      setSearch(location.search || '')
      setPathname(location.pathname || '')
      setState(location.state || null)
    }
  }, [])

  return useMemo(
    () =>
      ({
        length: 0,
        action: 'PUSH',
        location: {
          pathname,
          state,
          search,
          hash: '',
        },
        push,
        replace: push,
        go: (n: number) => {},
        goBack: () => {},
        goForward: () => {},
        block: (prompt: any) => () => {},
        listen: (listener: any) => () => {},
        createHref: (location: LocationDescriptorObject<LocationState>) => pathname + (search ? '?' + search : ''),
      } as History<LocationState>),
    [pathname, state, search, push],
  )
}

export default useFakeHistory
