export const ENQUEUE_NOTIFICATION = 'notifications.enqueue';
export const REMOVE_NOTIFICATION = 'notifications.remove';

export const enqueueNotification = (message, options={}) => ({
    type: ENQUEUE_NOTIFICATION,
    payload: {
        notification: {
            message,
            options: {
                key: new Date().getTime() + Math.random(),
                ...options,
            }
        }
    }
});

export const removeNotification = key => ({
    type: REMOVE_NOTIFICATION,
    payload: { key }
});