import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import { CredentialsDetailsHeader } from '../components/CredentialDetailsHeader'
import useCredential from '../hooks/useCredential'
import useWaiverContentPrompt from '../hooks/useWaiverContentPrompt'
import useWaiverAgreementPrompt from '../hooks/useWaiverAgreementPrompt'
import Loading from '../../components/Loading'
import { CredentialContentType } from '../models'
import { createMarkup } from 'civic-champs-shared/core/utils/helpers'

const useStyles = makeStyles(() => ({
  moreText: {
    color: '#5C8DE8',
    cursor: 'pointer',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    marginBottom: '14px',
  },
  textSecond: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    marginTop: '-12px',
    lineHeight: '16px',
  },
}))

export function CredentialDetails(props: any) {
  const credentialId = props.match.params.id
  const { result, loading } = useCredential(credentialId)
  const classes = useStyles()

  const waiverContentPrompt = useWaiverContentPrompt(result)
  const waiverAgreementPrompt = useWaiverAgreementPrompt(result)

  if (loading) {
    return <Loading />
  }
  const { version } = result

  // note: will need to update headers, sections if we ever have other credential types
  return (
    <>
      <CredentialsDetailsHeader credentialId={credentialId} history={props.history} />
      <Grid container spacing={1}>
        <Grid container item>
          <Grid item>
            <h4>Description:</h4>
            <div dangerouslySetInnerHTML={createMarkup(version.description)} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} direction="row">
          <Grid item xs={6}>
            <h4>Waiver Content:</h4>
            <div id="content" className={classes.textSecond} dangerouslySetInnerHTML={createMarkup(version.content)} />
            {version.contentType === CredentialContentType.Url && (
              <a className={classes.moreText} href={version.url}>
                more
              </a>
            )}
            {version.contentType === CredentialContentType.Text && (
              <div onClick={() => waiverContentPrompt()}>
                <div className={classes.moreText}>
                  <u>more</u>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <h4>Waiver Agreement:</h4>
            <div className={classes.text} dangerouslySetInnerHTML={createMarkup(version.waiverStatement)} />
            <div onClick={() => waiverAgreementPrompt()}>
              <div className={classes.moreText}>
                <u>more</u>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
