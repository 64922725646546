import { useCallback } from 'react'
import { useFetchRefactored, Status } from 'civic-champs-shared/api/hooks'
import { ActivityPayload } from '../types'
import { Activity } from 'champion/utils/interface'

export const useEditActivity = (): [(activity: ActivityPayload) => Promise<Activity>, Status<Activity>] => {
  const [request, status] = useFetchRefactored<Activity>({
    useNewNotification: true,
  })

  const editActivity = useCallback(
    (activity: ActivityPayload) => {
      const {
        volunteer: { name },
        ...activityPayload
      } = activity

      return request({
        method: 'put',
        url: `/activities/${activity.id}`,
        config: {
          body: activityPayload,
        },
        successMessage: `${name}'s activity was successfully edited`,
        errorMessage: `${name}'s activity was not edited`,
      })
    },
    [request],
  )

  return [editActivity, status]
}

export default useEditActivity
