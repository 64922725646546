import React, { useMemo, useState } from 'react'
import * as yup from 'yup'
import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, MenuItem } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import Loading from 'civic-champs-shared/core/Loading'
import { map } from 'lodash'
import Grid from '@material-ui/core/Grid'
import StyledSelect from 'civic-champs-shared/formik/legacy-components/StyledSelect'
import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer'
import useUpdateProgram from 'new-mentorship/hooks/useUpdateProgram'
import useFetchSurveys from 'new-mentorship/hooks/useFetchSurveys'
import { SurveyWithQuestionSet } from 'surveys/types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import keyBy from 'lodash/keyBy'
import first from 'lodash/first'
import { ReadOnlyQuestionSetQuestions } from 'civic-champs-shared/question-sets/components/ReadOnlyQuestionSetQuestions'

const formSchema = yup.object({})

export default function SelectProgramSurveysStep(props: any) {
  const classes = useModalStyles()
  const { onSuccess, program } = props
  const [mentorQuestionSetExpanded, setMentorQuestionSetExpanded] = useState<boolean>(false)
  const [menteeQuestionSetExpanded, setMenteeQuestionSetExpanded] = useState<boolean>(false)

  const initialValues = {
    mentorSurveyId: program?.mentorSurveyId,
    menteeSurveyId: program?.menteeSurveyId,
  }
  const [updateProgram] = useUpdateProgram()
  const { surveys, loading } = useFetchSurveys()

  const handleFormikSubmit = async ({
    mentorSurveyId,
    menteeSurveyId,
  }: {
    mentorSurveyId: number
    menteeSurveyId: number
  }) => {
    const updatePayload: any = {}
    if (mentorSurveyId) updatePayload.mentorSurveyId = mentorSurveyId
    if (menteeSurveyId) updatePayload.menteeSurveyId = menteeSurveyId

    if (Object.keys(updatePayload).length) {
      updateProgram(program.id, updatePayload).then(onSuccess)
    } else {
      onSuccess(program)
    }
  }

  const surveyIndex = useMemo(() => keyBy(surveys, 'id'), [surveys])

  if (loading) return <Loading />
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleFormikSubmit}>
        {({ isSubmitting, values }) => {
          const mentorQuestions = first(surveyIndex[values.mentorSurveyId]?.versions)?.questionSet?.questions
          const menteeQuestions = first(surveyIndex[values.menteeSurveyId]?.versions)?.questionSet?.questions
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ExpansionPanel disabled={isSubmitting} expanded={mentorQuestionSetExpanded}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon onClick={() => setMentorQuestionSetExpanded(prev => !prev)} />}
                      aria-controls="mentor-survey-content"
                      id="mentor-survey-header"
                    >
                      <Field
                        name="mentorSurveyId"
                        variant="outlined"
                        fullWidth
                        component={StyledSelect}
                        label="Mentor Question Set"
                        labelId="mentorSurveyId-label"
                      >
                        {map(
                          surveys,
                          ({
                            id,
                            versions: [
                              {
                                questionSet: { name },
                              },
                            ],
                          }: SurveyWithQuestionSet) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {mentorQuestions ? <ReadOnlyQuestionSetQuestions questions={mentorQuestions} /> : ''}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel disabled={isSubmitting} expanded={menteeQuestionSetExpanded}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon onClick={() => setMenteeQuestionSetExpanded(prev => !prev)} />}
                      aria-controls="mentee-survey-content"
                      id="mentee-survey-header"
                    >
                      <Field
                        name="menteeSurveyId"
                        variant="outlined"
                        fullWidth
                        component={StyledSelect}
                        label="Mentee Question Set"
                        labelId="menteeSurveyId-label"
                      >
                        {map(
                          surveys,
                          ({
                            id,
                            versions: [
                              {
                                questionSet: { name },
                              },
                            ],
                          }: SurveyWithQuestionSet) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {menteeQuestions ? <ReadOnlyQuestionSetQuestions questions={menteeQuestions} /> : ''}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                classes={{ root: classes.buttonsContainer }}
              >
                <Button
                  disabled={isSubmitting}
                  onClick={() => onSuccess(program)}
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Set Surveys
                </Button>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
