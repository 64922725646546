import React, { useMemo, useRef, useState } from 'react'
import {
  DateCell,
  EditCell,
  EditHeader,
  NullableTextCell,
  NumberCell,
  PersonNameCell,
  TimeCell,
} from 'core/table/cells'
import { createDateValueSort, createPersonSort } from 'components/table/sort'
import { CellProps } from 'react-table'
import { Address } from 'civic-champs-shared/core/location/utils'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { createStringSort } from 'core/table/utils'
import {
  SudMh,
  TripOrder,
  TripOrderPayload,
  TripOrderReason,
  TripOrderStatus,
  TripRequestPayload,
} from 'volunteering/trips/types'
import capitalize from 'lodash/capitalize'
import { HtmlExpandableCell } from 'core/table/cells/modern'
import { Person } from 'civic-champs-shared/common/types'
import { Column } from 'types/react-table'
import useTripPlannerColumnStyles from 'volunteering/trips/hooks/useTripPlannerColumnStyles'
import { Tooltip } from '@material-ui/core'
import useAssignDriverPrompt from 'volunteering/trips/hooks/useAssignDriverPrompt'
import useAdjustTripPrompt from 'volunteering/trips/hooks/useAdjustTripPrompt'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { ADDRESS_FILTER, PERSON_FILTER, STRING_ARRAY_FILTER, YES_NO_FILTER } from 'volunteering/trips/filters'
import { useGroups } from 'group/hooks/useGroups'
import { find } from 'lodash'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import isNil from 'lodash/isNil'

const DriverCell = (classes: any, assignDriver: (v: any) => any) => (cell: CellProps<TripOrder, Person | null>) => {
  if (
    ![TripOrderStatus.Unassigned, TripOrderStatus.Assigned, TripOrderStatus.Cancelled].includes(
      cell.row.original.tripOrderStatus as TripOrderStatus,
    )
  ) {
    return cell.value ? PersonNameCell(cell) : 'Unassigned'
  }
  return (
    <div className={classes.driver} onClick={() => assignDriver(cell.row.original)}>
      {cell.value ? PersonNameCell(cell) : 'Unassigned'}
    </div>
  )
}

const AdjustCell = (
  Cell: React.FC<CellProps<TripOrder>>,
  classes: any,
  showAdjustTripPrompt: (v: TripOrder) => any,
) => {
  return (props: CellProps<TripOrder>) => {
    const { cell, value } = props
    return (
      <div className={classes.driver} onClick={() => showAdjustTripPrompt(cell.row.original)}>
        {!isNil(value) ? <Cell {...props} /> : 'N/A'}
      </div>
    )
  }
}

const AddressCell = ({ value }: CellProps<TripOrder, Address>) => {
  const { line1, line2, city, state, zipCode } = value
  const address = Object.values(omitBy([line1, line2, city, state, zipCode], isEmpty)).join(', ')
  const [open, setOpen] = useState(false)

  const anchorEl = useRef<any>()

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    if (anchorEl?.current.scrollWidth > 200) {
      setOpen(true)
    }
  }
  return (
    <Tooltip open={open} onOpen={handleOpen} onClose={handleClose} title={address}>
      <div ref={anchorEl}>{address}</div>
    </Tooltip>
  )
}

const getOperandTextValue = (person: PersonSuggestion) =>
  `${person.givenName} ${person.familyName} ${person.contact ? `- ${person.contact}` : ''}`.trim()

const ReasonCell = ({ value }: CellProps<TripOrder, string>) => {
  return <div>{capitalize(value)}</div>
}

export const getSudMhText = (value: SudMh | null) => {
  let text
  switch (value) {
    case SudMh.SUD:
      text = 'SUD'
      break
    case SudMh.MH:
      text = 'MH'
      break
    case SudMh.CO_OCCURRING:
      text = 'Co-Occurring'
      break
    default:
      text = 'N/A'
  }
  return text
}

const SudMhCell = ({ value }: CellProps<TripOrder, SudMh | null>) => {
  return <div>{getSudMhText(value)}</div>
}

const YesNoCell = ({ value }: CellProps<TripOrder, boolean>) => {
  return <div>{value ? 'Yes' : 'No'}</div>
}

export default ({
  onEdit,
  updateTripOrder,
  assignDriver,
}: {
  onEdit: (row: TripOrder) => any
  updateTripOrder: (id: number, body: TripRequestPayload) => void
  assignDriver: (body: Partial<TripOrderPayload>) => void
}) => {
  const classes = useTripPlannerColumnStyles()
  const showAdjustTripPrompt = useAdjustTripPrompt(updateTripOrder)
  const showAssignDriverPrompt = useAssignDriverPrompt(assignDriver)
  const { groups } = useGroups()
  const riderGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Rider } })?.id, [groups])
  const driverGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Driver } })?.id, [groups])
  return useMemo(() => {
    return [
      {
        id: 'rider',
        Header: 'Rider',
        accessor: 'rider',
        Cell: PersonNameCell,
        sortType: createPersonSort('rider'),
        className: classes.riderColumn,
        filter: PERSON_FILTER,
        personAutocompleteConfig: {
          groupId: riderGroupId,
          excludeNonGroupMembers: true,
        },
        getOperandTextValue,
      },
      {
        id: 'pickupDate',
        Header: 'Pickup Date',
        accessor: 'pickupAt',
        Cell: DateCell,
        sortType: createDateValueSort('pickupDate'),
        className: classes.pickupDateColumn,
        disableFilters: true,
      },
      {
        id: 'pickupTime',
        Header: 'Pickup Time (Scheduled)',
        accessor: 'pickupAt',
        Cell: TimeCell,
        sortType: createDateValueSort('pickupTime'),
        className: classes.pickupTimeScheduledColumn,
        disableFilters: true,
      },
      {
        id: 'dropOffTime',
        Header: 'Drop-Off Time (Scheduled)',
        accessor: 'dropOffAt',
        Cell: TimeCell,
        sortType: createDateValueSort('dropOffTime'),
        className: classes.dropOffTimeScheduledColumn,
        disableFilters: true,
      },
      {
        id: 'driver',
        Header: 'Driver',
        accessor: 'driver',
        Cell: DriverCell(classes, showAssignDriverPrompt),
        sortType: createPersonSort('driver'),
        className: classes.driverColumn,
        filter: PERSON_FILTER,
        personAutocompleteConfig: {
          groupId: driverGroupId,
          excludeNonGroupMembers: true,
        },
        getOperandTextValue,
      },
      {
        id: 'mileage',
        Header: 'Miles Driven',
        accessor: 'mileage',
        Cell: AdjustCell(NumberCell, classes, showAdjustTripPrompt),
        className: classes.milesColumn,
        filter: DYNAMIC_NUMBER,
      },
      {
        id: 'actualPickupTime',
        Header: 'Pickup Time (Actual)',
        accessor: 'pickedUpAt',
        Cell: AdjustCell(TimeCell as React.FC, classes, showAdjustTripPrompt),
        sortType: createDateValueSort('actualPickupTime'),
        className: classes.pickupTimeActualColumn,
        disableFilters: true,
      },
      {
        id: 'actualDropOffTime',
        Header: 'Drop-Off Time (Actual)',
        accessor: 'droppedOffAt',
        Cell: AdjustCell(TimeCell as React.FC, classes, showAdjustTripPrompt),
        sortType: createDateValueSort('actualDropOffTime'),
        className: classes.dropOffTimeActualColumn,
        disableFilters: true,
      },
      {
        id: 'pickupLocation',
        Header: 'Pickup Address',
        accessor: 'pickupLocation.address',
        Cell: AddressCell,
        className: classes.addressColumn,
        filter: ADDRESS_FILTER,
      },
      {
        id: 'dropOffLocation',
        Header: 'Drop-Off Address',
        accessor: 'dropOffLocation.address',
        Cell: AddressCell,
        className: classes.addressColumn,
        filter: ADDRESS_FILTER,
      },
      {
        id: 'dropOffCounty',
        Header: 'Drop-Off County',
        accessor: 'dropOffLocation.address.county',
        Cell: NullableTextCell,
        sortType: createStringSort('dropOffLocation.address.county'),
        className: classes.dropOffCountyColumn,
        filter: DYNAMIC_TEXT,
      },
      {
        id: 'reason',
        Header: 'Reason',
        accessor: 'reason',
        Cell: ReasonCell,
        sortType: createStringSort('reason'),
        className: classes.reasonColumn,
        getFilterAutocomplete: () => {
          return Object.values(TripOrderReason).map(reason => ({
            value: reason,
            label: capitalize(reason),
          }))
        },
        filter: STRING_ARRAY_FILTER,
      },
      {
        id: 'recovery',
        Header: 'Recovery',
        accessor: 'recovery',
        Cell: YesNoCell,
        className: classes.recoveryColumn,
        disableSortBy: true,
        singleSelect: true,
        filter: YES_NO_FILTER,
        getFilterAutocomplete: () => [
          {
            value: true,
            label: 'Yes',
          },
          {
            value: false,
            label: 'No',
          },
        ],
      },
      {
        id: 'sudMh',
        Header: 'SUD / MH',
        accessor: 'sudMh',
        Cell: SudMhCell,
        sortType: createStringSort('sudMh'),
        className: classes.sudMhColumn,
        getFilterAutocomplete: () => [
          {
            value: SudMh.SUD,
            label: 'SUD',
          },
          {
            value: SudMh.MH,
            label: 'MH',
          },
          {
            value: SudMh.CO_OCCURRING,
            label: 'Co-Occurring',
          },
          {
            value: 'null',
            label: 'N/A',
          },
        ],
        filter: STRING_ARRAY_FILTER,
      },
      {
        id: 'cji',
        Header: 'CJI',
        accessor: 'cji',
        Cell: YesNoCell,
        className: classes.cjiColumn,
        filter: YES_NO_FILTER,
        disableSortBy: true,
        singleSelect: true,
        getFilterAutocomplete: () => [
          {
            value: true,
            label: 'Yes',
          },
          {
            value: false,
            label: 'No',
          },
        ],
      },
      {
        id: 'notes',
        Header: 'Notes',
        accessor: 'description',
        Cell: HtmlExpandableCell,
        className: classes.notesColumn,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: 'actions',
        Header: <EditHeader hideDelete />,
        Cell: ({ cell }: any) => <EditCell cell={cell} onEdit={onEdit} />,
        disableSortBy: true,
        disableFilters: true,
        className: classes.editColumn,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'tripOrderStatus',
        Cell: ({ value }: CellProps<TripOrder, string>) => capitalize(value),
        sortType: createStringSort('status'),
        disableFilters: true,
        className: classes.statusColumn,
      },
    ]
  }, [
    classes,
    driverGroupId,
    onEdit,
    riderGroupId,
    showAdjustTripPrompt,
    showAssignDriverPrompt,
  ]) as Column<TripOrder>[]
}
