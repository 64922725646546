import React from 'react'
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core'
import useTasks from '../hooks/useTasks'
import useTaskVolunteers from '../hooks/useTaskVolunteers'
import SummaryCards from 'core/table/components/SummaryCards'
import { NumberCell, PercentageCell } from 'core/table/cells'

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    display: 'flex',
    marginBottom: '10px',
  },
}))

export default function HelpingHandsCards(props) {
  const classes = useStyles()
  const { tasks, completedTasks, loading: tasksLoading } = useTasks()
  const { volunteers, loading: volunteersLoading } = useTaskVolunteers()

  const loading = tasksLoading || volunteersLoading

  const cards = [
    {
      Header: 'Tasks',
      accessor: 'tasks',
      Cell: NumberCell
    },
    {
      Header: 'Completed',
      accessor: 'completedTasks',
      Cell: NumberCell
    },
    {
      Header: 'Completion',
      accessor: 'completion',
      Cell: PercentageCell
    },
    {
      Header: 'Tasks/Volunteer',
      accessor: 'taskVolunteer',
      Cell: NumberCell
    },
  ]

  const summary = {
    tasks: tasks.length || 0,
    completedTasks:  completedTasks.length || 0,
    completion:  Math.round((completedTasks.length / (completedTasks.length + tasks.length)) * 100) || 0,
    taskVolunteer:  tasks.length === 0 || volunteers.length === 0? 0: (tasks.length / volunteers.length).toFixed(2)
  }


  return (
    <Grid item className={classes.cardWrapper}>
    {!loading &&
      <SummaryCards
        cards={cards}
        summary={summary}/>}
    </Grid>
  )
}