import React, { useState } from 'react'
import MaterialPopover from '@material-ui/core/Popover'
import { mdiInformation } from '@mdi/js'
import Icon from '@mdi/react'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  paper: {
    maxWidth: '40vw',
    width: 'fit-content',
    background: '#FFFAE6',
    fontSize: '15px',
  },
})

export default function Popover(props: any) {
  const { children, icon = mdiInformation } = props
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'Group Id information' : undefined

  return (
    <div>
      <IconButton aria-label="inherit" aria-describedby={id} onClick={handleClick}>
        {/* TODO: have the icon be blue*/}
        <Icon path={icon} size={1} color="primary" />
      </IconButton>
      {/* TODO: get anchorPosition and margins to work*/}
      <MaterialPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={classes}
      >
        {children}
      </MaterialPopover>
    </div>
  )
}
