import { isEmpty, map } from 'lodash'
import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button, Grid, Box, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SwapIcon from '@material-ui/icons/SwapHoriz'
import Loading from 'civic-champs-shared/core/Loading'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';

import { Status } from './types'
import { useMergePeopleManagement } from './useMergePeopleManagement'
import { MergeDetails } from './MergeDetails'

//TODO create and extend props from modal types?
export interface MergePeoplePromptProps {
    showing: boolean
    close: any
    cancel: any
    complete: any
    people: any[] //TODO should be a Person interface
}

const useStyles = makeStyles({
    swapButton: {
        marginTop: '50px',
        marginLeft: '35px',
        marginRight: '35px'
    }
})

export const MergePeoplePrompt : React.FC<MergePeoplePromptProps> = (props) => {
    const { showing, close, cancel, complete, people: initialPeople } = props;

    const [state, actions] = useMergePeopleManagement(initialPeople)
    const [replacing, replaced] = state.people

    const promptClasses = usePromptStyles()
    const classes = useStyles()

    const handleMerge = async () => {
        const didMerge = await actions.merge()
        if(didMerge) complete()
    }

    return (
        <Dialog
            fullScreen
            fullWidth
            className={"fullScreen"}
            open={showing}
            onClose={close}
            disableBackdropClick={true}

        >
            <DialogTitle className={promptClasses.titleContainer}>
                <Typography className={promptClasses.title}>Merge Accounts</Typography>
                <IconButton
                    disabled={state.status == Status.Merging}
                    onClick={close}
                    className={promptClasses.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container style={{width: '100%'}}>
                    <Grid item container direction="row" spacing={1} justify="center"> 
                        <Grid item>
                            <MergeDetails 
                                title="Keep:"
                                person={replacing.person}
                                references={replacing.referenceList}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.swapButton}
                                variant="outlined"
                                color="secondary"
                                disabled={state.status != Status.Ready}
                                onClick={actions.swapPeople} 
                            >
                                <SwapIcon style={ {marginRight: '5px'} } />
                                Swap
                            </Button>
                        </Grid>
                        <Grid item>
                            <MergeDetails 
                                title="Merge:"
                                person={replaced.person}
                                references={replaced.referenceList}
                            />
                        </Grid>
                    </Grid>
                    {state.status == Status.Loading && <Grid item>
                        <Loading /> 
                    </Grid>}
                    {!!state.error && <Grid item>
                        <Typography>{state.error}</Typography>
                    </Grid>}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled={state.status == Status.Merging}
                    onClick={cancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={state.status != Status.Ready}
                    onClick={handleMerge}
                >
                    Merge 
                </Button>
            </DialogActions>
        </Dialog>
    )
}