import useFetchRefactored from '../../../civic-champs-shared/api/hooks/useFetchRefactored'
import moment from 'moment'
import { Opportunity, OpportunityApiResponse } from '../../../Event/interfaces'
import { mapOpportunities } from '../helpers/mapOpportunity'
import { useCallback } from 'react'
import pickBy from 'lodash/pickBy'
import format from '../../../civic-champs-shared/utils/format'
import isNil from 'lodash/isNil'
import { Status } from '../../../civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../civic-champs-shared/auth/hooks'

interface Props {
  reporting?: boolean
  startDate?: string | Date | moment.Moment
  endDate?: string | Date | moment.Moment
  schedulable?: boolean
}
export const useGetOpportunities = (): [(props: Props) => Promise<Opportunity[]>, Status<Opportunity[]>] => {
  const currentOrg = useCurrentOrg()
  const [request, status] = useFetchRefactored<Opportunity[], OpportunityApiResponse[]>({
    mapper: mapOpportunities,
    emptyValue: [],
  })

  const getOpportunities = useCallback(
    ({ reporting, endDate, startDate, schedulable }: Props) =>
      request({
        url: `/organizations/${currentOrg.id}/opportunities`,
        config: {
          queryStringParameters: pickBy(
            {
              reporting,
              startDate: format.dateTimeForUrl(startDate),
              endDate: format.dateTimeForUrl(endDate),
              schedulable,
            },
            value => !isNil(value),
          ),
        },
      }),
    [currentOrg.id, request],
  )

  return [getOpportunities, status]
}
