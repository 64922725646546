import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { DateCell, WithLinkCell } from 'core/table/cells'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { makeStyles } from '@material-ui/core/styles'
import { DocumentFile } from 'documents/hooks/useFetchDocuments'
import { PersonRef } from 'civic-champs-shared/question-sets/types'
import { Link } from 'react-router-dom'

export const useStyles = makeStyles(theme => ({
  selectColumn: {
    '&>div': {
      width: '30px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '150px',
      marginLeft: '48px',
    },
  },
  dateColumn: {
    '&>div': {
      width: '123px',
      marginRight: '48px',
    },
  },
  volunteerColumn: {
    '&>div': {
      width: '150px',
      marginRight: '48px',
    },
  },
  sourceColumn: {
    '&>div': {
      width: '159px',
      marginRight: '48px',
    },
  },
  extColumn: {
    '&>div': {
      width: '111px',
      marginRight: '6px',
    },
  },
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
  },
}))

export default function useDocumentsColumns() {
  const classes = useStyles()
  return useMemo(
    () => [
      {
        id: 'name',
        Header: 'Document Name',
        accessor: 'file.filename',
        Cell: ({
          value,
          cell: {
            row: {
              original: {
                file: { url },
              },
            },
          },
        }: CellProps<DocumentFile, string>) => (
          <a href={url} className={classes.link} target="_blank">
            {value.substring(0, value.lastIndexOf('.'))}
          </a>
        ),
        filter: DYNAMIC_TEXT,
        className: classes.nameColumn,
      },
      {
        id: 'createdAt',
        Header: 'Last Collected On',
        accessor: 'createdAt',
        Cell: DateCell,
        disableFilters: true,
        className: classes.dateColumn,
      },
      {
        id: 'uploader',
        Header: 'Volunteer',
        accessor: 'uploader',
        Cell: (props: CellProps<DocumentFile, PersonRef>) =>
          WithLinkCell(
            ({ value }: CellProps<DocumentFile, PersonRef>) => `${value.givenName} ${value.familyName}`,
            (id: number) => `/champion/${id}`,
            'uploader.id',
          )({ ...props, linkProps: { className: classes.link } }),
        className: classes.volunteerColumn,
        filter: DYNAMIC_TEXT,
      },
      {
        id: 'questionSetName',
        Header: 'Source',
        accessor: 'questionSetName',
        Cell: ({
          value,
          cell: {
            row: {
              // @ts-ignore
              original: { surveyId, questionSetId },
            },
          },
        }: CellProps<DocumentFile, string>) => {
          return (
            <Link className={classes.link} to={`/questionnaires/${surveyId || questionSetId}`}>
              {value}
            </Link>
          )
        },
        filter: DYNAMIC_TEXT,
        className: classes.sourceColumn,
      },
      {
        id: 'extension',
        Header: 'File Type',
        accessor: 'file.filename',
        Cell: ({ cell: { value } }: CellProps<DocumentType, string>) => value.substring(value.lastIndexOf('.')),
        className: classes.extColumn,
        filter: DYNAMIC_TEXT,
      },
    ],
    [
      classes.dateColumn,
      classes.extColumn,
      classes.link,
      classes.nameColumn,
      classes.sourceColumn,
      classes.volunteerColumn,
    ],
  )
}
