import { isEmpty, isFunction } from 'lodash'
import React, { useMemo, useCallback } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ADMIN,
  SUPER_ADMIN,
  INTERNAL_ADMIN,
  INTERNAL_SUPER_ADMIN,
  DEVELOPER,
  ROLE_TO_NAME,
} from 'civic-champs-shared/auth/utils/permissions'
import { useHasRole } from 'auth/hooks'

const BASE_ADMINS = [ADMIN, SUPER_ADMIN]
const INTERNAL_ADMINS = [INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN]

const useStyles = makeStyles({
  root: {
    padding: '10px 14px',
    '&:focus': {
      borderRadius: '25px',
    },
  },
  select: {
    borderRadius: '25px',
    fontSize: '12px',
    '& fieldset': {
      borderColor: '#3776CF',
      borderWidth: '2px',
    },
  },
})

export function MemberRoleSelect(props) {
  const { disabled, value, onChange, label, styled = false } = props
  const classes = useStyles()

  const isSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const isDeveloper = useHasRole(DEVELOPER)
  const rolesToGrant = useMemo(() => {
    let roles = BASE_ADMINS
    if (isDeveloper) {
      roles = [...BASE_ADMINS, ...INTERNAL_ADMINS, DEVELOPER]
    } else if (isSuperAdmin) {
      roles = [...BASE_ADMINS, ...INTERNAL_ADMINS]
    }
    return roles.map(role => ({ label: ROLE_TO_NAME[role], value: role }))
  }, [isSuperAdmin, isDeveloper])

  const actualValue = isEmpty(value) ? '' : value

  const handleChange = useCallback(
    e => {
      let rawValue = e.target.value
      let selectedRole = !isEmpty(rawValue) ? rawValue : null

      if (isFunction(onChange)) {
        onChange(selectedRole)
      }
    },
    [onChange],
  )

  return (
    <FormControl variant="outlined" required fullWidth>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Select
        {...(styled && {
          classes: { root: classes.root },
          className: classes.select,
        })}
        disabled={!!disabled}
        value={actualValue}
        onChange={handleChange}
        displayEmpty
      >
        {rolesToGrant.map(role => (
          <MenuItem key={role.value} value={role.value}>
            {role.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MemberRoleSelect
