import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { EntityType, Note } from 'note/Note'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useGetNotes = (entityType: EntityType): [(entityId: number) => Promise<Note[]>, Status<Note[]>] => {
  const [request, status] = useFetchRefactored<Note[]>({ emptyValue: [] })
  const organization = useCurrentOrg()

  const getNotes = useCallback(
    (entityId: number) => {
      return request({
        method: 'get',
        url: `/organizations/${organization.id}/${entityType}/${entityId}/notes`,
      })
    },
    [entityType, organization.id, request],
  )

  return [getNotes, status]
}

export default useGetNotes
