import React, { useCallback, useEffect, useState } from 'react'
import PageHeader from 'App/components/PageHeader'
import { InputAdornment, OutlinedInput } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import useDebounce from 'utils/useDebounce'
import useExtendedTableStyles from 'core/table/table-hooks/useExtendedTableStyles'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { DailyEventsTable } from 'Event/components/DailyEventsTable'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'
import OngoingOpportunitiesTable from 'Event/components/OngoingOpportunitiesTable'
import { useShowEventAddEditPrompt } from 'Event/hooks/useShowEventAddEditPrompt'
import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useHistory } from 'react-router'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { useFeatureEnabled } from '../../core/feature/hooks'
import { useShowAddEditOfferPrompt } from '../hooks/useShowAddEditOfferPrompt'
import { OutlinedButton } from '../../civic-champs-shared'

const useStyles = makeStyles({
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  },
  tables: {
    display: 'flex',
    flexDirection: 'column',
    gap: '35px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const ListOfEventsComponent = () => {
  const [search, setSearch] = useState<string>('')
  const history = useHistory()
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const newEventEditUIEnabled = useFeatureEnabled('NewEditEventUICalendarEvent')
  const currentOrg = useCurrentOrg()
  const onSuccess = useCallback(
    (event: OpportunityResponse) => {
      let url: string
      if (event.isOffer) {
        url = `/offers/${encodeOccurrenceFromEvent(event)}`
      } else {
        url = event.schedulable
          ? `/events/${encodeOccurrenceFromEvent(event)}/${currentOrg.id}`
          : `/opportunities/${encodeOccurrenceFromEvent(event)}`
      }
      history.push(url)
    },
    [currentOrg.id, history],
  )
  const showOpportunityPrompt = useShowEventAddEditPrompt(onSuccess)
  const showOfferPrompt = useShowAddEditOfferPrompt(onSuccess)
  const debouncedSearch = useDebounce(search, 250)
  const tableClasses = useExtendedTableStyles()
  const classes = useStyles()
  const [, setEventsPage] = useEventsPageState()
  useEffect(() => {
    setEventsPage(EventPages.List)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  let buttons: React.ReactElement
  if (offersEnabled) {
    if (newEventEditUIEnabled) {
      buttons = (
        <>
          <OutlinedButton startIcon={<AddIcon />} onClick={() => showOfferPrompt()}>
            Add Offer
          </OutlinedButton>
          <ContainedButton startIcon={<AddIcon />} onClick={() => showOpportunityPrompt()}>
            Add Event
          </ContainedButton>
        </>
      )
    } else {
      buttons = (
        <ContainedButton startIcon={<AddIcon />} onClick={() => showOfferPrompt()}>
          Add Offer
        </ContainedButton>
      )
    }
  } else {
    buttons = (
      <ContainedButton startIcon={<AddIcon />} onClick={() => showOpportunityPrompt()}>
        Add Opportunity
      </ContainedButton>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <OutlinedInput
          labelWidth={0}
          id="search"
          placeholder="Search Events"
          value={search}
          onChange={(event: any) => setSearch(event.target.value)}
          classes={{
            root: tableClasses.searchField,
            notchedOutline: tableClasses.notchedOutline,
            input: tableClasses.input,
          }}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon style={{ fontSize: '16px' }} />
            </InputAdornment>
          }
        />
        <div className={classes.buttons}>{buttons}</div>
      </div>
      <div className={classes.tables}>
        <OngoingOpportunitiesTable search={debouncedSearch} />
        <DailyEventsTable search={debouncedSearch} />
      </div>
    </div>
  )
}

export const ListOfEvents = () => {
  return (
    <PageHeader title="List of Events" subTitle="Create and manage your organization’s events">
      <ListOfEventsComponent />
    </PageHeader>
  )
}

export default ListOfEvents
