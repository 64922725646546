import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'
import { useRemoveRole } from './useRemoveRole'

export const useRemoveRolePrompt = (eagerRemove: any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeRole = useRemoveRole(eagerRemove)

  return useCallback(
    async role => {
      const text = `Are you sure you want to delete "${role.name}"? The action can not be undone.`
      const confirmed = await showPrompt({
        text,
        title: 'Delete Role',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        await removeRole(role)
      }
    },
    [showPrompt, removeRole],
  )
}

export default useRemoveRolePrompt
