import React, { useCallback, useEffect } from 'react'
import TabSwitcher from '../../core/tab-switcher'
import useCredential from '../hooks/useCredential'
import Loading from '../../components/Loading'
import { Button, makeStyles } from '@material-ui/core'
import format from 'civic-champs-shared/utils/format'
import EditOutlined from '@material-ui/icons/EditOutlined'
import useCreateOrUpdateCredentialPrompt from '../hooks/useCreateOrUpdateCredentialPrompt'
import { useFeatureEnabled } from '../../core/feature/hooks'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '36px',
    marginRight: '34px',
  },
  subTitle: {
    marginRight: '3px',
    marginLeft: '27px',
  },
  subTitleSecond: {
    marginRight: '3px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#FFCD00',
  },
  bottom: {
    marginBottom: '20px',
  },
}))

export function CredentialsDetailsHeader(props: any) {
  const classes = useStyles()
  const { result, loading, refresh: getCredential } = useCredential(props.credentialId)
  const isNewAddEditWaiverUiEnabled = useFeatureEnabled('NewAddEditWaiverUi')
  const history = useHistory()
  const [onCreateOrUpdateCredential, onCredentialAdded] = useCreateOrUpdateCredentialPrompt(false)
  const handleCreate = useCallback(() => {
    if (isNewAddEditWaiverUiEnabled) {
      history.push(`waivers/${props.credentialId}/edit`)
    } else {
      onCreateOrUpdateCredential(props.credentialId)
    }
  }, [history, isNewAddEditWaiverUiEnabled, onCreateOrUpdateCredential, props.credentialId])

  useEffect(() => {
    onCredentialAdded(() => getCredential())
  }, [getCredential, onCredentialAdded])

  if (loading) {
    return <Loading />
  }

  const { credential, version, stats } = result
  const { totalVersions } = stats || {}

  const tabDetails = [
    { to: `/credentials/${props.credentialId}`, text: 'Details' },
    { to: `/credentials/${props.credentialId}/volunteers`, text: 'Volunteers' },
  ]

  return (
    <article className="section-scene">
      <div className="detail_event_block">
        <div className="detail_event_block_back_arrow" onClick={() => props.history.push('/credentials')}>
          <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
          Waivers and Credentials
        </div>
        <div className={classes.row}>
          <div className={classes.title}>
            <b>{version.title}</b>
          </div>
          <Button variant="contained" className={classes.button} startIcon={<EditOutlined />} onClick={handleCreate}>
            <b>Edit</b>
          </Button>
          <div className={classes.subTitle}>
            <b>Last updated:</b>
          </div>
          <div>{format.date(version.createdAt)}</div>
          <div className={classes.subTitle}>
            <b>Version:</b>
          </div>
          <div>{totalVersions}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.subTitleSecond}>
            <b>Expiration: </b>
          </div>
          <p>
            {credential.expirationOptionLength} {credential.expirationOption}
          </p>
        </div>
        <div>
          <TabSwitcher linkDetails={tabDetails} />
          <div className={classes.bottom} />
        </div>
      </div>
    </article>
  )
}
