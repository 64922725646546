import moment from 'moment'
import React from 'react'
import format, { DEFAULT_TIME_ZONE } from 'civic-champs-shared/utils/format'
import { OrgNamedRef } from 'civic-champs-shared/question-sets/types'
import { ExportButton } from 'core/table/components'
import map from 'lodash/map'

const headers = [
  { label: 'Volunteer First Name', key: 'firstName' },
  { label: 'Volunteer Last Name', key: 'lastName' },
  { label: 'Volunteer Email Address', key: 'email' },
  { label: 'Volunteer Mobile Phone', key: 'phoneNumber' },
  { label: 'Volunteer Home Phone', key: 'homePhoneNumber' },
  { label: 'Volunteer Groups', key: 'groups' },
  { label: 'Event Name', key: 'eventName' },
  { label: 'Event Date', key: 'eventDate' },
  { label: 'Signup Date', key: 'signupDate' },
  { label: 'Volunteer Shift', key: 'shiftName' },
  { label: 'Shift Time', key: 'shiftTime' },
  { label: 'Volunteer Role', key: 'roleName' },
  { label: 'Volunteer Status', key: 'status' },
  { label: 'Waivers Status', key: 'waiverStatus' },
]
const formatData = (registrants: any[]) =>
  registrants.map(
    ({ original: { user, opportunity, occurrence, unsignedWaivers, role, timeshift, status, groups, createdAt } }) => ({
      firstName: user.givenName,
      lastName: user.familyName,
      eventName: opportunity.name,
      eventDate: format.datetime(occurrence.startsAt),
      shiftName: timeshift.name,
      shiftTime: `${timeshift.timeStart} - ${timeshift.timeEnd}`,
      roleName: role.name,
      waiverStatus: unsignedWaivers.length ? 'Action Required: ' + map(unsignedWaivers, 'title').join(',') : 'Complete',
      email: user.email,
      phoneNumber: format.phoneNumber(user.phoneNumber),
      homePhoneNumber: format.phoneNumber(user.homePhoneNumber),
      status,
      signupDate: format.datetime(createdAt),
      groups: groups.map(({ name }: any) => name).join(', '),
    }),
  )

const formatFileName = (filename: string, organization: OrgNamedRef, separator: string = '_') => {
  const today = moment()
    .tz(organization.timeZone || DEFAULT_TIME_ZONE)
    .format(`DDMMMYYYY${separator}HH:mm`)
  return `${organization.name}${separator}${filename}${separator}${today}.csv`
}

export const ExportRegistrantsButton = ({
  data,
  disabled,
  organization,
}: {
  data: any[]
  disabled?: boolean
  organization: OrgNamedRef
}) => (
  <ExportButton
    data={data}
    disabled={disabled}
    formatData={formatData}
    headers={headers}
    filename={formatFileName('registrations', organization)}
  />
)

export default ExportRegistrantsButton
