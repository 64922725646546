import { useCallback, useMemo } from 'react'
import { useFetch } from '../../api/hooks'
import { useCurrentOrg } from '../../auth/hooks'
import { useSuccessNotification } from '../../api/hooks'

export const useEditQuestionSet = (onSuccess, notifyOnSuccess = true) => {
  const [request, { loading, error, result }] = useFetch(
    'Error updating question set.  Please refresh the page to try again',
  )
  const currentOrg = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const editQuestionSet = useCallback(
    questionSet => {
      const { url, method } = questionSet.questionSetId
        ? {
            url: questionSet.organization
              ? `/organizations/${currentOrg.id}/question-set/${questionSet.questionSetId}`
              : `/user-profile-question-set/${questionSet.questionSetId}`,
            method: 'put',
          }
        : { url: `/organizations/${currentOrg.id}/question-set`, method: 'post' }
      return request({
        method,
        url,
        config: {
          body: questionSet,
        },
        onSuccess: res => {
          notifyOnSuccess && showSuccess('Successfully saved questionnaire')
          onSuccess && onSuccess(res)
        },
      })
    },
    [currentOrg.id, notifyOnSuccess, onSuccess, request, showSuccess],
  )

  return useMemo(
    () => [
      editQuestionSet,
      {
        result,
        loading,
        error,
      },
    ],
    [editQuestionSet, result, loading, error],
  )
}
