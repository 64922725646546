import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { Typography } from '@material-ui/core'
import cn from 'classnames'
import { CellProps } from 'react-table'
import { LegacyExpandRowCell } from 'core/table/cells/modern'
import { DriverInfoCell, OrderNameCell, TotalDriversCell } from '../cells'
import { EditCell, EditHeader } from 'core/table/cells'

export const useStyles = makeStyles({
  link: {
    fontSize: '12px',
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  pickupAtColumn: {
    '&>div': {
      width: '150px',
      textAlign: 'right',
    },
  },
  dateColumn: {
    '&>div': {
      width: '140px',
      textAlign: 'right',
    },
  },
  driverColumn: {
    '&>div': {
      width: '200px',
      textAlign: 'center',
    },
  },
  statusColumn: {
    '&>div': {
      width: '110px',
    },
  },
  deleteColumn: {
    '&>div': {
      width: '40px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  actionsNeededColumn: {
    '&>div': {
      width: '120px',
      textAlign: 'center',
    },
  },
})

const driverSort = (rowA: any, rowB: any): number => {
  const driverA = `${rowA.values.driver}`.toLowerCase().trim()
  const driverB = `${rowB.values.driver}`.toLowerCase().trim()

  return driverA.localeCompare(driverB)
}

const orderNameSort = (rowA: any, rowB: any): number => {
  const orderNameA = `${rowA.values.orderName}`.toLowerCase().trim()
  const orderNameB = `${rowB.values.orderName}`.toLowerCase().trim()

  return orderNameA.localeCompare(orderNameB)
}

const hyperlinkStatuses = ['complete', 'in-progress', 'cancelled']

export default function useTripColumns({ onDriverAssignment, showRemoveTripPrompt }: any) {
  const classes = useStyles()

  const onDelete = async (row: any) => {
    await showRemoveTripPrompt(row.data.id)
  }

  const DriverCell = useCallback(
    ({ row }: any) =>
      row.original.tripOrderCount ? (
        <TotalDriversCell row={row} />
      ) : (
        <DriverInfoCell row={row} onDriverAssignment={onDriverAssignment} />
      ),
    [onDriverAssignment],
  )

  const DeleteCell = useCallback(
    ({ cell }: any) => {
      return cell.row.subRows ? <EditCell cell={cell} onDelete={onDelete} /> : null
    },
    [onDelete],
  )

  return useMemo(
    () => [
      {
        id: 'expand',
        Cell: LegacyExpandRowCell,
      },
      {
        id: 'orderName',
        Header: 'Order Name',
        accessor: 'orderName',
        disableFilters: DYNAMIC_TEXT,
        Cell: OrderNameCell,
        sortType: orderNameSort,
      },
      {
        id: 'pickupTime',
        Header: 'Pickup Time',
        accessor: 'pickupTime',
        filter: DYNAMIC_TEXT,
        className: classes.pickupAtColumn,
        sortType: createStringSort('pickupTime'),
      },
      {
        id: 'date',
        Header: 'Date',
        accessor: 'date',
        filter: DYNAMIC_TEXT,
        className: classes.dateColumn,
        sortType: createStringSort('date'),
      },
      {
        id: 'driver',
        Header: 'Drivers Assigned',
        accessor: 'driverInfo',
        Cell: DriverCell,
        className: classes.driverColumn,
        disableFilters: true,
        sortType: driverSort,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'status',
        filter: DYNAMIC_TEXT,
        Cell: ({ value }: CellProps<any>) => {
          return (
            <span className={cn({ [classes.link]: hyperlinkStatuses.includes(value) })}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </span>
          )
        },
        className: classes.statusColumn,
        sortType: createStringSort('status'),
      },
      {
        id: 'actions',
        Header: 'Actions Needed?',
        accessor: 'actions',
        Cell: ({ value: actions }: any) => {
          return actions && <Typography className={classes.link}>Assign Drivers</Typography>
        },
        disableFilters: true,
        className: classes.actionsNeededColumn,
        sortType: createStringSort('actions'),
      },
      {
        id: 'delete',
        Header: <EditHeader hideEdit={true} />,
        className: classes.deleteColumn,
        Cell: DeleteCell,
      },
    ],
    [classes], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
