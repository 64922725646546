import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const useCampaignActivities = campaign => {
  const [request, { result, loading, error }] = useFetch()
  const getCampaignActivities = useCallback(() => {
    return request({
      method: 'get',
      url: `/campaigns/${campaign.id}/campaign_activities`,
    })
  }, [request, campaign.id])

  useEffect(() => {
    getCampaignActivities()
  }, [getCampaignActivities])

  return {
    refresh: getCampaignActivities,
    campaignActivities: result,
    loading,
    error,
  }
}

export default useCampaignActivities
