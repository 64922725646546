import Paper, { PaperProps } from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import React from 'react'

interface Props extends PaperProps {
  handle: string
}

export const DraggablePaper = ({ handle, ...props }: Props) => (
  <Draggable handle={handle} cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
)
export default DraggablePaper
