import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'

export const useFetchGroupQuestionSets = onResultUpdate => {
  const [request, { result, loading, error, called }] = useFetch(
    'Error fetching questionnaires.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const fetchGroupQuestionSets = useCallback(
    async groupId => {
      return request({
        onSuccess: result => {
          onResultUpdate && onResultUpdate(result)
        },
        method: 'get',
        url: `/organizations/${currentOrg.id}/groups/${groupId}/${
          isQuestionnaireAsSurveyEnabled ? 'group-surveys' : 'group-question-sets'
        }`,
      })
    },
    [currentOrg.id, isQuestionnaireAsSurveyEnabled, onResultUpdate, request],
  )

  return [
    fetchGroupQuestionSets,
    {
      result,
      loading,
      called,
      error,
    },
  ]
}
