import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'
import { PubNubProvider } from 'pubnub-react'
import { AddCircle as AddCircleIcon, Create as CreateIcon } from '@material-ui/icons'

import PageHeader from 'App/components/PageHeader'
import {
  useAddChatMembers,
  useChatColumns,
  useChatStyles as useStyles,
  useGetChat,
  useGetChatMembers,
  usePubnub,
  useRemoveChatMemberPrompt,
  useUpdateChat,
} from 'chats/hooks'
import { Chat as IChat, ChatColumn, Member } from 'chats/interfaces'
import Loading from 'civic-champs-shared/core/Loading'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'

import PagedTable from 'core/table/components/PagedTable'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import EditChatPrompt from 'chats/components/EditChatPrompt'
import { AddChatMembersPrompt } from 'chats/components/AddChatMembersPrompt'
import ChatDialog from 'chats/components/ChatDialog'

const getErrorText = (error: any) => error.response?.data?.message || error.message

export const Chat = (props: RouteComponentProps<{ id: string }>) => {
  const [getChat, { result: chat, loading, called, error }] = useGetChat()
  const [getChatMembers, { result: members, loading: membersLoading }] = useGetChatMembers()
  useEffect(() => {
    getChat(props.match.params.id)
    getChatMembers(props.match.params.id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const title = useMemo(() => {
    if (error) {
      return getErrorText(error)
    }
    return !called || loading ? '' : chat.name
  }, [called, error, loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageHeader
      title={title}
      subTitle="Back to Chats"
      backLink="/chats"
      error={error ? getErrorText(error) : undefined}
    >
      {!called || loading || membersLoading ? <Loading /> : <ChatComponent chat={chat} members={members} {...props} />}
    </PageHeader>
  )
}

interface ChatProps extends RouteComponentProps {
  chat: IChat
  members: Member[]
}

export const ChatComponent = ({ chat: initialChat, members: initialMembers }: ChatProps) => {
  const { pubnub, loading } = usePubnub()
  const classes = useStyles()
  const [members, setMembers] = useState<Member[]>(sortBy(initialMembers, 'givenName', 'familyName'))
  const [chat, setChat] = useState<IChat>(initialChat)
  const showDeleteChatMemberPrompt = useRemoveChatMemberPrompt(chat.id, setMembers)
  const columns = useChatColumns(showDeleteChatMemberPrompt)
  const showEditChatPrompt = useShowPrompt(EditChatPrompt)
  const showAddChatMembersPrompt = useShowPrompt(AddChatMembersPrompt)
  const [addChatMembers] = useAddChatMembers()
  const [editChat] = useUpdateChat()
  const handleAddChatMembers = useCallback(
    async (newMembers: Member[]) => {
      // We add new members instantly not to wait for the server response and provide best UX
      setMembers(members => sortBy([...members, ...newMembers], 'givenName', 'familyName'))
      // We update members list, so that newly added members had their id filled
      setMembers(
        sortBy(
          await addChatMembers({ chatId: chat.id, uuids: newMembers.map(m => m.pubnubId) }),
          'givenName',
          'familyName',
        ),
      )
    },
    [addChatMembers, chat.id],
  )
  const handleEditChat = useCallback(
    async ({ name, description }: { name: string; description: string }) => {
      setChat(chat => ({ ...chat, name, description }))
      await editChat({ id: chat.id, name, description })
    },
    [chat.id, editChat],
  )
  const data: ChatColumn[] = useMemo(
    () =>
      map(members, person => ({
        id: person.id,
        person,
      })),
    [members],
  )
  const table = useTable(
    {
      data,
      // @ts-ignore
      columns,
    },
    useSortBy,
    usePagination,
  )

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <div className={classes.chat}>
        <PubNubProvider client={pubnub}>
          <ChatDialog currentChannel={chat} />
        </PubNubProvider>
      </div>
      <div className={classes.overview}>
        <div className={classes.overviewHeader}>
          <span>Overview</span>
          <TableMenuButton
            startIcon={<CreateIcon />}
            className={classes.whiteBg}
            onClick={() => showEditChatPrompt(chat).then(handleEditChat)}
          >
            Edit Chat
          </TableMenuButton>
        </div>
        <div className={classes.overviewContent}>
          <div>Chat Name:</div>
          {chat.name}
        </div>
        <div className={classes.overviewContent}>
          <div>Chat Description:</div>
          {chat.description}
        </div>
        <div className={classes.overviewHeader}>
          <span>Members</span>
          <TableMenuButton
            startIcon={<AddCircleIcon />}
            className={classes.whiteBg}
            onClick={() => showAddChatMembersPrompt({ existingMembers: members }).then(handleAddChatMembers)}
          >
            Add Members
          </TableMenuButton>
        </div>
        <div className={classes.tableWrapper}>
          <PagedTable
            {...table}
            // @ts-ignore
            newStyles
            isDraggable
          />
        </div>
      </div>
    </div>
  )
}

export default Chat
