import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import useCredentialTypesCollection from "../hooks/useCredentialTypesCollection";
import useCredentialIDColumns from "../hooks/useCredentialIDColumns";
import matchSorter from "match-sorter";
import Loading from 'components/Loading'
import PagedTable from 'core/table/components/PagedTable'

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.name'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
}

export const CredentialIDs = () => {
  const [
    {
      credentialIDTypes,
      initiallyLoaded
    },
    {
      refresh
    },
  ] = useCredentialTypesCollection()

  const columns = useCredentialIDColumns()

  const table = useTable(
    {
      data: credentialIDTypes,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  return (
    <Grid container>
      <Grid container item sm={12} direction="column" spacing={3}>
        <Grid container item justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Grid item>
              <Typography variant="h4">Credentials</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
            </Grid>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {!initiallyLoaded ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
