import React from 'react'
import Chip from '@material-ui/core/Chip'
import { withStyles } from '@material-ui/core'

export interface TagProps {
  id: number
  label: string
  color: string
  foregroundColor?: string
  onDelete?: (id: number) => void
  deleteIcon?: React.ReactElement
  classes: Record<'label', string>
  pointer?: boolean
  margin?: string
}

function Tag({ id, label, deleteIcon, color, foregroundColor, onDelete, classes, pointer, margin }: TagProps) {
  return (
    <Chip
      classes={classes}
      label={label}
      deleteIcon={deleteIcon}
      onDelete={onDelete ? () => onDelete(id) : undefined}
      style={{
        color: foregroundColor || '#000000',
        backgroundColor: color,
        cursor: pointer ? 'pointer' : 'auto',
        maxWidth: '300px',
        margin,
      }}
    />
  )
}

export default withStyles({
  label: {
    fontWeight: 400,
  },
})(Tag)
