import { Auth } from 'aws-amplify'
import fileDownload from 'js-file-download'
import { getAuthorizationToken } from 'config-amplify'

type ENV_KEYS =
  | 'AWS_REGION'
  | 'COGNITO_USER_POOL_ID'
  | 'COGNITO_WEB_CLIENT_ID'
  | 'COGNITO_AUTH_FLOW'
  | 'COGNITO_DOMAIN'
  | 'API_ENDPOINT'
  | 'ENV'
  | 'URL'

export default class BaseService {
  getEnvVar = (key: ENV_KEYS) => process.env['REACT_APP_' + key]

  base = (rest: string) => `${this.getEnvVar('API_ENDPOINT')}/${rest}`

  currentUser = async () => {
    try {
      const currentUser = await this.getJSON(this.base('user'))
      return currentUser
    } catch (e) {
      if (e === 'No current user') {
        return null
      }
      console.log('Error while getting current user in baseService', e)
      throw Error(e)
    }
  }

  //TODO adding the X-Api-Version header here is... not great
  requestWithToken = async () => {
    const token = await getAuthorizationToken()
    if (!!token) {
      return {
        'Content-Type': 'application/json',
        Authorization: token,
        'X-Api-Version': '2021-04-12',
      }
    } else {
      return {
        'Content-Type': 'application/json',
        'X-Api-Version': '2021-04-12',
      }
    }
  }

  async getJSON(api: string): Promise<any> {
    try {
      const req = await fetch(api, {
        method: 'get',
        headers: await this.requestWithToken(),
      } as any)
      const json = await req.json()
      if (json && json.statusCode === 403) {
        try {
          await Auth.signOut()
        } catch (e) {
          console.log(e)
        }
        return
      }
      return json
    } catch (e) {
      console.log(`error in getJSON with api: ${api}`, e)
      throw e
    }
  }

  async postFile(api: string, body: object): Promise<any> {
    const headers = await this.requestWithToken()
    //@ts-ignore
    delete headers['Content-Type']
    try {
      const response = await fetch(api, {
        method: 'post',
        headers,
        body,
      } as any)
      const data = await response.json()
      if (data.statusCode >= 400 && data.statusCode < 600) {
        throw new Error(data.message)
      }
      return data
    } catch (err) {
      console.log(`error in getJSON with api: ${api}`, err)
      throw err
    }
  }

  async postJSON(api: string, body: object): Promise<any> {
    try {
      const req = await fetch(api, {
        method: 'post',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      } as any)
      return req.json()
    } catch (e) {
      console.log(`error in postJSON with api: ${api}`, e)
      throw e
    }
  }

  async delete(api: string): Promise<any> {
    try {
      const req = await fetch(api, {
        method: 'DELETE',
        headers: await this.requestWithToken(),
      } as any)
      return req.json()
    } catch (e) {
      console.error(`An error has been occurred while removing data at ${api}`, e)
    }
  }

  async putJSON(api: string, body: object): Promise<any> {
    try {
      const req = await fetch(api, {
        method: 'put',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      } as any)
      return req.json()
    } catch (e) {
      console.error(`An error has been occurred while updating data at ${api}`, e)
      throw e
    }
  }

  async downloadFile(api: string, body: object, name = 'file'): Promise<any> {
    await fetch(api, {
      method: 'post',
      headers: await this.requestWithToken(),
      body: JSON.stringify(body),
    } as any)
      .then(response => {
        if (!String(response.status).startsWith('2')) {
          throw new Error(response.statusText || 'Error downloading file')
        }
        return response.blob()
      })
      .then(blob => fileDownload(blob, `${name}-${new Date().getTime()}.csv`))
  }
}
