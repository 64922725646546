import { FormControlLabel } from '@material-ui/core'
import React from 'react'
import { FieldProps } from 'formik'
import { BlueSwitch } from 'civic-champs-shared/formik/components/BlueSwitch'

export interface ToggleProps extends FieldProps {
  label: string
}

export default function SwitchToggle({ field: { value, name, onChange }, label }: ToggleProps) {
  return <FormControlLabel control={<BlueSwitch name={name} checked={value} onChange={onChange} />} label={label} />
}
