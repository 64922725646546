import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Person } from 'civic-champs-shared/question-sets/types'
import { AddUserPayload } from 'civic-champs-shared/core/add-person/types'

const useAddGroupMember = (): [(groupId: number, payload: AddUserPayload) => Promise<Person>, Status<Person>] => {
  const [request, status] = useFetchRefactored<Person>({
    successMessage: 'Champion added successfully',
    errorMessage: 'Error adding champion',
  })

  const addGroupMember = useCallback(
    (groupId: number, payload: AddUserPayload) => {
      return request({
        method: 'post',
        url: `/groups/${groupId}/members`,
        config: {
          body: payload,
        },
      })
    },
    [request],
  )

  return [addGroupMember, status]
}

export default useAddGroupMember
