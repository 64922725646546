import React from 'react'
import invariant from 'tiny-warning'

export const FormikWizardContext = React.createContext(undefined)

export const FormikWizardProvider = FormikWizardContext.Provider
export const FormikWizardConsumer = FormikWizardContext.Consumer

export function useFormikWizardContext() {
    const wizard = React.useContext(FormikWizardContext)

    invariant(
        !!wizard,
        'Formik Wizard context is undefined, please verify you are calling useFormikWizardContext() as a child of a <FormikWizard> component.'
    )
    
    return wizard;
} 