import React, { useMemo } from 'react'
import { Tooltip, Box, IconButton, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
})

const useActivityActionCell = actions => {
  const { onDelete, onEdit } = actions
  const classes = useStyles()

  return useMemo(
    () =>
      function ActivityActionCell({ cell }) {
        const activity = cell.row.original

        const handleDelete = e => {
          e.stopPropagation()
          onDelete && onDelete(activity)
        }

        const handleEdit = e => {
          e.stopPropagation()
          onEdit && onEdit(activity)
        }

        return (
          <Box component="span" className={classes.toolbar}>
            {onEdit && (
              <Tooltip title="Edit">
                <IconButton onClick={handleEdit} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {onDelete && (
              <Tooltip title="Delete">
                <IconButton onClick={handleDelete} size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    [onEdit, onDelete, classes],
  )
}

export default useActivityActionCell
