import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { startManagingOrg } from 'auth/actions'

const useManageOrganization = () => {
    const dispatch = useDispatch()

    return useCallback( (organization) => {
        dispatch( startManagingOrg(organization) )
    }, [dispatch])
}

export default useManageOrganization