import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field, Form, Formik } from 'formik'
import { StyledKeyboardDatePicker } from 'components/StyledFormikDateTimePickers'
import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { ChampionProfile } from 'champion/interfaces'
import StyledOutlinedPhoneField from 'civic-champs-shared/formik/components/StyledOutlinedPhoneField'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import Modal from '@material-ui/core/Modal'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import { AddressField } from 'civic-champs-shared/formik/components'
import { pick } from 'lodash'
import { profilePersonSchema } from 'champion/schema'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'
import format from 'civic-champs-shared/utils/format'
import useProfileStyles from 'champion/hooks/useProfileStyles'
import ProfileField from 'champion/components/ProfileField'
import moment from 'moment-timezone'

const EMPTY_EMERGENCY_CONTACT = {
  id: null,
  givenName: '',
  familyName: '',
  phoneNumber: null,
  workPhoneNumber: null,
  homePhoneNumber: null,
  email: null,
  relationship: null,
}

export default function ProfileEdit(props: {
  showing: boolean
  complete: (...props: any) => Promise<any>
  cancel: () => Promise<any>
  profile: ChampionProfile
}) {
  const { showing, complete, cancel, profile } = props

  const classes = useModalStyles()
  const styles = useProfileStyles()
  const {
    user: { emergencyContact, emergencyContactRelationship, address, ...user },
  } = profile

  const initialValues = {
    ...pick(user, [
      'givenName',
      'familyName',
      'email',
      'emailVerified',
      'phoneNumber',
      'phoneNumberVerified',
      'homePhoneNumber',
      'birthDate',
    ]),
    address,
    emergencyContact:
      emergencyContact != null
        ? {
            ...pick(emergencyContact, [
              'id',
              'givenName',
              'familyName',
              'phoneNumber',
              'workPhoneNumber',
              'homePhoneNumber',
              'email',
            ]),
            relationship: emergencyContactRelationship,
          }
        : null,
  }

  const handleSubmit = useCallback(
    (input: any) => {
      const payload = profilePersonSchema.cast({
        id: user.id,
        ...input,
        emergencyContactRelationship: input.emergencyContact?.relationship,
      })
      return complete({
        ...payload,
        birthDate: payload.birthDate
          ? moment.tz(input.birthDate, Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD')
          : null,
      })
    },
    [complete, user.id],
  )

  return (
    <Modal open={showing}>
      <Container classes={{ root: classes.container }} className="compact" maxWidth={false}>
        <Container classes={{ maxWidthMd: classes.maxWidthMd }} className={classes.compact} maxWidth="md">
          <IconButton aria-label="close" style={{ position: 'absolute', right: 0, top: 0 }} onClick={cancel}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box>
            <Typography className={styles.header}>Edit Contact Information</Typography>
            <Formik initialValues={initialValues} validationSchema={profilePersonSchema} onSubmit={handleSubmit}>
              {({ submitForm, isSubmitting, setFieldValue, values }) => {
                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography className={styles.label}>First Name</Typography>
                        <ProfileField name="givenName" setFieldValue={setFieldValue} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={styles.label}>Last Name</Typography>
                        <ProfileField name="familyName" setFieldValue={setFieldValue} />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={styles.label}>
                          Email {values.emailVerified && <VerifiedIcon className={styles.verified} />}
                        </Typography>
                        {!values.emailVerified && <ProfileField name="email" setFieldValue={setFieldValue} />}
                        {values.emailVerified && <span className={styles.verifiedText}>{values.email}</span>}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={styles.label}>
                          Mobile {values.phoneNumberVerified && <VerifiedIcon className={styles.verified} />}
                        </Typography>
                        {!values.phoneNumberVerified && (
                          <ProfileField
                            name="phoneNumber"
                            setFieldValue={setFieldValue}
                            component={StyledOutlinedPhoneField}
                          />
                        )}
                        {values.phoneNumberVerified && (
                          <span className={styles.verifiedText}>{format.phoneNumber(values.phoneNumber)}</span>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={styles.label}>Home Phone</Typography>
                        <ProfileField
                          name="homePhoneNumber"
                          setFieldValue={setFieldValue}
                          component={StyledOutlinedPhoneField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={styles.label}>Address</Typography>
                        <Field
                          fullWidth
                          variant="outlined"
                          size="small"
                          hideMap
                          component={AddressField}
                          smaller
                          name="address"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={styles.label}>Birthday</Typography>
                        <Field
                          name="birthDate"
                          fullWidth
                          component={StyledKeyboardDatePicker}
                          inputVariant="outlined"
                          format="MM/DD/YYYY"
                          smaller
                          clearable={true}
                        />
                      </Grid>
                      <div className={styles.spoiler} />
                      <Grid item xs={6}>
                        <Typography className={styles.subHeader}>
                          <LocalHospitalIcon className={styles.icon} />
                          Emergency contact
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {values.emergencyContact !== null && (
                          <Typography className={styles.delete} onClick={() => setFieldValue('emergencyContact', null)}>
                            Delete
                          </Typography>
                        )}
                        {values.emergencyContact === null && (
                          <Typography
                            className={styles.add}
                            onClick={() => setFieldValue('emergencyContact', EMPTY_EMERGENCY_CONTACT)}
                          >
                            Add
                          </Typography>
                        )}
                      </Grid>

                      {values.emergencyContact !== null && (
                        <>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>First Name</Typography>
                            <ProfileField name="emergencyContact.givenName" setFieldValue={setFieldValue} />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>Last Name</Typography>
                            <ProfileField name="emergencyContact.familyName" setFieldValue={setFieldValue} />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>Relationship</Typography>
                            <ProfileField name="emergencyContact.relationship" setFieldValue={setFieldValue} />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>Email</Typography>
                            <ProfileField name="emergencyContact.email" setFieldValue={setFieldValue} />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>Mobile</Typography>
                            <ProfileField
                              name="emergencyContact.phoneNumber"
                              setFieldValue={setFieldValue}
                              component={StyledOutlinedPhoneField}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={styles.label}>Home Phone</Typography>
                            <ProfileField
                              name="emergencyContact.homePhoneNumber"
                              setFieldValue={setFieldValue}
                              component={StyledOutlinedPhoneField}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                      classes={{ root: classes.buttonsContainer }}
                    >
                      <OutlinedButton disabled={isSubmitting} onClick={cancel}>
                        Cancel
                      </OutlinedButton>
                      <ContainedButton disabled={isSubmitting} onClick={submitForm}>
                        Save
                      </ContainedButton>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Box>
        </Container>
      </Container>
    </Modal>
  )
}
