const keyAllowed = (key: any) => {
  const keys = [8,9,13,16,17,18,19,20,27,46,48,49,50,
                51,52,53,54,55,56,57,91,92,93];
  return !(key && keys.indexOf(key) === -1)
}

export const disableEntryNegativeNumbers = (e: any) => {
  const key = !isNaN(e.charCode) ? e.charCode : e.keyCode;
  if (!keyAllowed(key)) {
    e.preventDefault()
  }
}