import { isEmpty } from 'lodash'
import { useCallback, useState } from 'react'
import { useLatest } from 'react-use'
import { useCurrentOrg } from 'auth/hooks'

import { requestWithRetry } from 'civic-champs-shared/api/requestWithRetry'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ProcessUserCredentialIDPrompt from 'credential-ids/components/ProcessUserCredentialIDPrompt'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

export default function useProcessUserCredentialIDs(refresh) {
  const [loading, setLoading] = useState(false)
  const loadingRef = useLatest(loading)
  const currentOrg = useCurrentOrg()

  const openUserCredentialPrompt = useShowPrompt(ProcessUserCredentialIDPrompt)
  const showError = useErrorNotification()

  const processUserCredentials = useCallback(
    async (credentialType, chosenMemberID) => {
      if (loadingRef.current) return

      setLoading(true)
      try {
        const config = {
          headers: {
            'X-Api-Version': '2020-05-14',
          },
          queryStringParameters: {
            typeId: credentialType.id,
          },
        }
        const allMembers = await requestWithRetry({
          url: `/organizations/${currentOrg.id}/user-credentials`,
          config,
        })
        if (isEmpty(allMembers)) {
          showError(`Credential type '${credentialType.label}' does not have any submissions`)
        } else {
          openUserCredentialPrompt({
            refresh,
            allMembers,
            credentialTypeLabel: credentialType.label,
            chosenMemberID,
          })
        }
      } catch (error) {
        showError(`An error occurred while loading identifications`, error)
      } finally {
        setLoading(false)
      }
    },
    [currentOrg.id, loadingRef, openUserCredentialPrompt, refresh, showError],
  )

  return { loading, processUserCredentials }
}
