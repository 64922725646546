import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'
import { useRemoveLocation } from 'locations/hooks/useRemoveLocation'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OrganizationLocationReporting } from 'locations/types'
import { useEndOpportunityFromLocations } from 'locations/hooks/useCancelEventFromLocation'

export const useRemoveLocationPrompt = (
  remove: Action<OrganizationLocationReporting>,
  replace: Action<OrganizationLocationReporting>,
  eager = true,
) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeLocation = useRemoveLocation(remove, eager)
  const endOpportunity = useEndOpportunityFromLocations(replace, true, eager)

  return useCallback(
    async (location: OrganizationLocationReporting) => {
      const text = `Are you sure you want to delete ${
        location.name ? `"${location.name}"` : 'selected location'
      }? The action can not be undone.`
      const confirmed = await showPrompt({
        text,
        title: 'Delete Location',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        if (location.encodedOccurrence) {
          await endOpportunity(location)
        } else {
          await removeLocation(location)
        }
      }
    },
    [showPrompt, endOpportunity, removeLocation],
  )
}

export default useRemoveLocationPrompt
