import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { RemoveModal } from './RemoveModal'
import { Field } from 'formik'
import CheckboxField from 'civic-champs-shared/question-sets/components/CheckboxField'

const useStyles = makeStyles(theme => ({
  removeText: {
    color: theme.palette.danger.main,
  },
}))

export const CleanRow = props => {
  const { questionSet, onRemove, onEdit, disabled, showRequired, index, onChangeOptional } = props

  const classes = useStyles()
  const { name, optional } = questionSet
  const showRemoveModal = useShowPrompt(RemoveModal)
  return (
    <>
      <Grid item xs={3}>
        <div>
          {name} {optional ? '(optional)' : ''}
        </div>
      </Grid>
      {showRequired && (
        <Grid item xs={1} container justify="center">
          <Field
            component={CheckboxField}
            name={`questionSets.${index}.optional`}
            label="Optional"
            disabled={disabled}
            onChange={onChangeOptional}
            labelstyle={{ font: 'normal normal normal 0.875rem Open Sans', marginTop: '15px' }}
          />
        </Grid>
      )}
      <Grid item xs={1} container justify="center">
        <Button variant="text" color="secondary" onClick={onEdit} disabled={disabled}>
          Edit
        </Button>
      </Grid>
      <Grid item xs={1} container justify="center">
        <Button
          variant="text"
          className={classes.removeText}
          onClick={() => showRemoveModal({ submit: onRemove })}
          disabled={disabled}
        >
          Remove
        </Button>
      </Grid>
    </>
  )
}
