import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import { useColumns } from './hooks'
import useGetMessages from '../../hooks/useGetMessages'

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.method',
      'original.subject',
      'original.templateVersion.description',
      'original.userEnteredSubject',
      'original.sentTo.givenName',
      'original.sentTo.familyName',
      'original.recipientContact',
      'original.opportunity.name',
      'original.registration.name',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const AllMessagesOverview = () => {
  const { result: messages, loading } = useGetMessages()
  const [dateRange, setDateRange] = useDateRangeFilter()
  const columns = useColumns()
  const table = useTable(
    {
      data: messages,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} item>
            <Typography variant="h4">Non-Custom Messages</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={setDateRange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
