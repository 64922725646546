import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'
import { HasId } from './types'

//TODO need a better solution than doing API.get | API.post raw

export const mergePeople = (replacingPerson: HasId, replacedPerson: HasId) => {
  const config = {
    body: {
      replacingPersonId: replacingPerson.id,
      replacedPersonId: replacedPerson.id,
    },
  }

  return requestWithRetry({ method: 'post', url: '/persons/merges', config })
}

//TODO it's wild that you *have* to provide a third argument on GET
export const getPersonReferences = (person: HasId) => requestWithRetry({ url: `/persons/${person.id}/merge-preview` })
