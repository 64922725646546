import React from 'react'
import * as _ from 'lodash'
import { Grid, Box, Typography, Tooltip, makeStyles } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import classNames from 'classnames'
import format from 'civic-champs-shared/utils/format'
import { NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR } from '../components/ChampionsSummaryCards'

const useStyles = makeStyles(() => ({
  box: {
    width: 125,
    height: 100,
    padding: 10,
    border: 'solid 1px rgba(179, 179, 179)',
  },
  wideItemBox: {
    minWidth: '200px'
  },
  alignItemBox: {
    display: 'flex',
    alignItems: 'center',
  }
}))

export const ChampionSummaryCards = ({ activities, donation }) => {
  const classes = useStyles()
  const totalVolunteerHours = _.sumBy(activities, 'hoursVolunteered', 0)
  const totalDonations = donation.totalDonations || 0
  const estimatedImpact = totalVolunteerHours * NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR + totalDonations

  const estimatedImpactTooltip = `This value was calculated using the national average of $${NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR} / hour for volunteers.`

  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      {[
        { name: 'Activities', value: activities.length },
        { name: 'Hours', value: totalVolunteerHours.toFixed(2) },
        { name: 'Donations', value: `$${totalDonations}` },
        { name: 'Last Activity', value: format.date(_.get(activities, '0.occurredAt')) },
        { name: 'Estimated Impact', value: `$${estimatedImpact.toFixed(2)}`, tooltip: estimatedImpactTooltip, wideItemBox: true },
      ].map(({ name, value, tooltip, wideItemBox }) => (
        <Grid item key={name}>
          <Box className={classNames(classes.box, {[classes.wideItemBox]: wideItemBox})} >
            <Grid container spacing={1} direction="column" alignItems="center">
              <Grid item className={ wideItemBox ? classes.alignItemBox : '' }>
                <Typography variant="body1">{name}</Typography>
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <HelpOutlineIcon style={{ fontSize: 18, verticalAlign: 'middle', marginLeft: 5 }} />
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h6">{value}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
