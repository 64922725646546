// NOTE: this is most copy-pasta'd from AddActivityDialog, but should be replaced with Formik, if possible
import moment from 'moment'
import React, { useMemo, useCallback } from 'react'
import { Grid, Typography, IconButton, Dialog, CircularProgress, Button } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'
import DurationField from '../../../tracking/activity/components/DurationField'
import useDateTimeRange from 'utils/useDateTimeRange'
import { useOccurredAtUpdated } from '../hooks'
import getErrorMessage from '../../../utils/getErrorMessage'

const useStyles = makeStyles(() => ({
  labelStyle: {
    fontSize: '1.2em',
    fontWeight: '600',
    display: 'inline',
  },
  textStyle: {
    fontSize: '1.2em',
    display: 'inline',
    paddingLeft: '5px',
  },
  rowContent: {
    padding: '1em 0.25em',
  },
  rowContentCentered: {
    padding: '0.1em 1em',
    alignContent: 'center',
  },
  dtPicker: {},
  submitButton: {
    backgroundColor: '#5C8DE8',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const styles = () => ({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    borderRadius: '4px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'white',
  },
  title: {
    fontSize: '2em',
    fontWeight: '500',
    backgroundColor: '#5C8DE8',
    color: 'white',
    marginTop: '0px',
    width: '100%',
    padding: '0.4em 1em',
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: '10px 30px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const useActivityForm = (task) => {
  const timeZone = task.organization.timeZone || 'America/New_York'
  const start = task.occurredAt || moment().subtract(1, 'hour').toDate()
  const [range, rangeActions] = useDateTimeRange({ start: moment(start).tz(timeZone), duration: moment.duration(task.duration || 1, 'hour') })

  const actions = useMemo(
    () => ({
      setStartedAt: rangeActions.setStart,
      setEndedAt: rangeActions.setEnd,
      setDuration: rangeActions.setDuration,
    }),
    [rangeActions],
  )

  return useMemo(() => {
    const formData = {
      startedAt: range.start,
      endedAt: range.end,
      duration: range.duration,
    }

    return [formData, actions]
  }, [range, actions])
}

export default function EditVolunteerTimePrompt(props) {
  const { showing, close, task, complete } = props
  const classes = useStyles()

  const [formData, actions] = useActivityForm(task)
  const [updateOccurredAt, submitting, submitError] = useOccurredAtUpdated()

  let submit = useCallback(async () => {
    const payload = {
      occurredAt: formData.startedAt,
      duration: formData.duration.as('hours'),
    }
    await updateOccurredAt(task, payload)
    complete()
  }, [updateOccurredAt, formData, close])

  return (
    <Dialog open={showing} onClose={close}>
      <form>
        <DialogTitle>Edit Task Time</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid item className={classes.rowContent}>
              <Typography><b>Volunteer</b>: {task.volunteer.givenName} {task.volunteer.familyName}</Typography>
            </Grid>
            <Grid item className={classes.rowContent}>
              <Typography><b>Task For</b>: {task.recipient.givenName} {task.recipient.familyName}</Typography>
            </Grid>
            <Grid item className={classes.rowContent}>
              <KeyboardDateTimePicker
                name="startedAt"
                label="Check-In"
                value={formData.startedAt}
                onChange={actions.setStartedAt}
                format="MM/DD/YYYY hh:mm a"
                inputVariant="outlined"
                className={classes.dtPicker}
                disabled={submitting}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <KeyboardDateTimePicker
                name="endedAt"
                label="Check-Out"
                minDate={formData.startedAt}
                value={formData.endedAt}
                onChange={actions.setEndedAt}
                format="MM/DD/YYYY hh:mm a"
                inputVariant="outlined"
                className={classes.dtPicker}
                disabled={submitting}
              />
            </Grid>
            <Grid item className={classes.rowContentCentered}>
              <DurationField value={formData.duration} onChange={actions.setDuration} disabled={submitting} />
            </Grid>
            {submitError && (
              <Grid item className={classes.rowContent}>
                <p style={{ color: 'red' }}>{getErrorMessage(submitError)}</p>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={submitting}>
            Cancel
          </Button>
          <Button className={classes.submitButton} disabled={submitting} onClick={submit}>
            {submitting && <CircularProgress className={classes.buttonProgress} size={24} color="primary" />}
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
