import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { TripRequest } from '../types'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

const useRemoveTrip = (
  showNotifications: boolean = true,
): [(body: number) => Promise<TripRequest>, Status<TripRequest>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripRequest>(
    showNotifications
      ? {
          successMessage: 'Trip successfully removed',
          errorMessage: 'Error removing trip',
        }
      : {},
  )
  const removeTrips = useCallback(
    (tripRequestId: number) => {
      return request({
        method: 'del',
        url: `/organizations/${organization.id}/trip-requests/${tripRequestId}`,
      })
    },
    [request, organization.id],
  )

  return [removeTrips, status]
}

export default useRemoveTrip
