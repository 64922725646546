import { makeStyles } from '@material-ui/core';

export const useCalendarDayStyles = makeStyles((theme: any) => ({
  calendarCell: {
      width: 'calc(100% / 7)',
      display: 'flex',
      flexDirection: 'column',
      padding: '4px 8px',
      background: theme.palette.background.default,
      height: '10vw',
      boxSizing: 'border-box',
      position: 'relative',
      color: theme.palette.secondary.main,
      border: '1px solid',
      marginRight: -1,
      marginBottom: -1,
      borderColor: theme.palette.secondary.main,
      paddingBottom: 24,
    
      [theme.breakpoints.down('sm')]: {
          width: '100%',
          borderRight: 'none',
          padding: '16px 8px 24px 8px;'
      }
  },
  calendarCellWeek: {
    height: 'auto',
  },
  pastDay: {
      background: theme.palette.background.blue,
      color: theme.palette.neutral.main
  },
  today: {
      background: theme.palette.background.yellow,
      color: theme.palette.neutral.black
  },
  otherMonth: {
      background: theme.palette.background.gray,
      color: theme.palette.neutral.main,
      borderColor: theme.palette.neutral.main,
  },
  pointer: {
      cursor: 'pointer'
  },
  dayInfo: {
      textAlign: 'center',
      fontSize: '1.25em',
      lineHeight: '1.5em',
      color: theme.palette.neutral.darkGray,
      [theme.breakpoints.down('sm')]: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
      }
  },
  calendarEvent: {
      textDecoration: 'underline',
      fontSize: '1.5vmin',
      lineHeight: '2vmin',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      margin: 0,
      '@media (max-height: 750px)': {
        fontSize: '1em',
        lineHeight: '1.5em'
      },
      [theme.breakpoints.down('sm')]: {
          fontSize: '1em',
          lineHeight: '1.5em'
      },
  },
  calendarEventWeek: {
    paddingBottom: 5,
  },
  tripleLine: {
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box'
  },
  doubleLine: {
      WebkitLineClamp: 2,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
  },
  singleLine: {
      whiteSpace: 'nowrap'
  }
}))

export default useCalendarDayStyles
