import reduceReducers from 'reduce-reducers';

import defaultState from './defaultState';
import kioskModeReducer from './kioskMode';
import volunteerReducer from './volunteers';
import timesheetReducer from './timesheet';
import waiverReducer from './waivers'

export default reduceReducers(
    defaultState(), 
    kioskModeReducer, 
    volunteerReducer,
    timesheetReducer,
    waiverReducer
);