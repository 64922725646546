import { Dayjs } from 'dayjs'
import { Activity } from 'champion/utils/interface'
import { useMemo } from 'react'

export const useReflectionCalendarData = (date: Dayjs, activities: Activity[]) => {
  return useMemo(() => {
    const startOfMonth = date.set('date', 1)
    const endOfMonth = date.set('date', date.daysInMonth())
    const startOfMonthWeekDay = startOfMonth.day()
    const endOfMonthWeekDay = endOfMonth.day()
    const start = startOfMonth.subtract(startOfMonthWeekDay, 'day')
    const end = endOfMonth.add(6 - endOfMonthWeekDay, 'day')
    const data: { day: Dayjs; activities: Activity[] }[][] = []

    let j = 0
    for (let i = 0, n = end.diff(start, 'day'); i <= n; i++) {
      const day = start.add(i, 'day')
      if (day.day() === 0) {
        data.push([])
      }
      data[j].push({
        day,
        activities: activities.filter(activity => day.isSame(activity.occurredAt, 'day')),
      })
      if (day.day() === 6) {
        j++
      }
    }
    return data
  }, [date, activities])
}
