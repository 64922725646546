import React from 'react'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import { Field } from 'formik'

export interface ProfileFieldProps {
  name: string
  setFieldValue: (name: string, value: string) => void
  component?: React.FC<any>
}
export const ProfileField = ({ name, setFieldValue, component }: ProfileFieldProps) => (
  <Field
    fullWidth
    name={name}
    component={component || StyledOutlinedTextField}
    marginless
    smaller
    onClear={() => setFieldValue(name, '')}
  />
)

export default ProfileField
