import React from 'react'

import './index.scss'

interface IProps {
  title: string
  children: React.ReactNode
}

const FormWrapper = (props: IProps) => {
  const {
    children,
    title
  } = props

  return(
    <div className="form_wrapper">
      <div className="form_wrapper_block">
        <div className="form_wrapper_header">
          <span className="form_wrapper_title">{title}</span>
        </div>
        {children}
      </div>
    </div>
  )
}

export default FormWrapper
