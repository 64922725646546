import { map, filter, orderBy, isEmpty } from 'lodash';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Dialog, DialogTitle, IconButton, DialogActions, Button, DialogContent, Paper, Toolbar, InputBase, ListItem, ListItemText } from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import Loading from 'components/Loading';
import useGet from 'civic-champs-shared/api/hooks/useGetLegacy'
import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import useDebounce from 'utils/useDebounce';
import { useReloadLocalFeatures } from '../hooks';

const removeOrgsWithSwitches = (feature, orgs) => {
    const usedIds = new Set( map(feature.switches, 'organization.id') );
    return filter(orgs, o => !usedIds.has(o.id))
}

export const useGetOrgsWithSwitches = (feature) => {
    const { result: organizations, loading } = useGet('/organizations')

    const [filteredOrgs, setFilteredOrgs] = useState(null);

    useEffect(() => {
        setFilteredOrgs( orderBy( removeOrgsWithSwitches(feature, organizations), ['name'], ['asc']) )
    }, [feature, organizations, setFilteredOrgs]);

    return useMemo(
      () => ({
        organizations: filteredOrgs,
        loading,
      }),
      [filteredOrgs, loading],
    )
}

const useCreateCustomSwitch = () => {
    const [fetch, status] = useFetch();

    const createSwitch = useCallback((feature, organization) => {
        return fetch({
            method: 'put',
            url: `/settings/features/${feature.id}/switches`,
            config: {
                body: {
                    organizationId: organization.id,
                    enabled: feature.enabledByDefault
                }
            }
        })
    }, [fetch])

    return [createSwitch, status]
}

export default function AddCustomSwitchPrompt(props) {
    const { feature, showing, close, cancel, complete } = props;

    const { organizations, loading } = useGetOrgsWithSwitches(feature);

    const [query, setQuery] = useState('');
    const handleClearQuery = useCallback( () => setQuery(''), [setQuery]);
    const handleQueryChange = useCallback( e => setQuery(e.target.value), [setQuery]);

    const debouncedQuery = useDebounce(query, 250)
    const filteredOrgs = useMemo(() => {
      const normalizedQuery = debouncedQuery.toLowerCase()
      const containsQuery = org => org.name.toLowerCase().indexOf(normalizedQuery) >= 0
      return filter(organizations, containsQuery)
    }, [debouncedQuery, organizations])

    const [selected, setSelected] = useState(null);

    const renderRow = useCallback( props => {
        const { index, style } = props;
        const org = filteredOrgs[index];
        const handleSelectItem = () => {
            setSelected( prev => !!prev && prev.id === org.id ? null : org)
        }

        return (
          <ListItem key={org.id} selected={selected === org} onClick={handleSelectItem} style={style}>
            <ListItemText primary={org.name} />
          </ListItem>
        )
    }, [filteredOrgs, selected, setSelected])

    const [createCustomSwitch, { loading: submitting }] = useCreateCustomSwitch();
    const reload = useReloadLocalFeatures()
    const handleSubmit = useCallback(async () => {
      await createCustomSwitch(feature, selected)
      reload() //TODO should call this in the parent!
      complete()
    }, [createCustomSwitch, feature, selected, reload, complete])

    return (
      <Dialog fullScreen open={showing} onClose={close}>
        <DialogTitle>
          Add Custom Switch for Feature '{feature.featureKey}'
          <IconButton onClick={close} disabled={submitting}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Toolbar>
                  <Paper>
                    <InputBase placeholder="Search" value={query} onChange={handleQueryChange} />
                    {isEmpty(query) ? (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handleClearQuery}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Paper>
                </Toolbar>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList height={height} width={width} itemSize={37} itemCount={filteredOrgs.length}>
                      {renderRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </>
            )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel} disabled={submitting}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading || submitting || selected == null}
          >
            Add Switch
          </Button>
        </DialogActions>
      </Dialog>
    )
}
