import { FormValues, Mappings } from 'tracking/activity/components/ActivityImportDialog'
import Papa from 'papaparse'
import { isEmpty, trim } from 'lodash'
import moment from 'moment-timezone'
import { Role } from 'volunteer-role/types'
import map from 'lodash/map'
import { mapRow, Outcome } from '../../../utils/import'

export const parseAndValidateInput = (values: FormValues, companyRoles: Role[]): Promise<Record<string, any>[]> => {
  const rows: Record<string, any>[] = []
  return new Promise(resolve => {
    Papa.parse<any, File>(values.file as File, {
      header: true,
      skipEmptyLines: true,
      step: results => {
        if (results.errors.length === 0) {
          rows.push(
            mapAndValidateImportRow({
              row: results.data,
              mappings: values.mappings,
              timeZone: values.timeZone.value,
              dateFormat: values.dateFormat,
              defaultRoleDefined: !!values.volunteeringRole,
              companyRoles,
            }),
          )
        } else {
          rows.push({
            ...results.data,
            outcome: Outcome.Invalid,
            reason: map(results.errors, 'message').join('; '),
          })
        }
      },
      complete: () => resolve(rows),
    })
  })
}

export const mapAndValidateImportRow = ({
  row,
  mappings,
  dateFormat,
  timeZone,
  defaultRoleDefined,
  companyRoles,
}: {
  row: Record<string, any>
  mappings: Mappings
  dateFormat: string
  timeZone: string
  defaultRoleDefined: boolean
  companyRoles: Role[]
}): Record<string, any> => {
  const mappedRow = mapRow<Mappings>({ row, mappings })

  if (!mappedRow.volunteeringRole && !defaultRoleDefined) {
    return {
      ...row,
      outcome: Outcome.Skipped,
      reason: 'No role defined',
    }
  }

  if (mappedRow.volunteeringRole && !companyRoles.find(role => role.name === mappedRow.volunteeringRole)) {
    return {
      ...row,
      outcome: Outcome.Invalid,
      reason: "Role doesn't exist",
    }
  }

  if (!mappedRow.email && !mappedRow.phone && (!mappedRow.firstName || !mappedRow.lastName)) {
    return {
      ...row,
      outcome: Outcome.Skipped,
      reason: 'No contact provided',
    }
  }

  if (!mappedRow.email && mappedRow.phone) {
    const phone = mappedRow.phone.replace(/\D*/g, '')
    if (!(phone.length === 10 || (phone.length === 11 && phone.startsWith('1')))) {
      return {
        ...row,
        outcome: Outcome.Invalid,
        reason: 'provided phone is invalid',
      }
    }
  }

  const rawHours = trim(mappedRow.hours as string)
  let hours = 0

  if (!isEmpty(rawHours)) {
    hours = parseFloat(rawHours.replace(/[^.0-9]*/g, ''))
    if (isNaN(hours)) {
      return {
        ...row,
        outcome: Outcome.Invalid,
        reason: 'invalid hours',
      }
    }
  }

  if (hours <= 0) {
    return {
      ...row,
      outcome: Outcome.Skipped,
      reason: 'No activity hours',
    }
  }

  const occurredAt = moment.tz(mappedRow.occurredAt as string, dateFormat, timeZone).startOf('day')
  if (!occurredAt.isValid()) {
    return {
      ...row,
      outcome: Outcome.Invalid,
      reason: 'missing or invalid activity date',
    }
  }

  return {
    ...row,
    outcome: Outcome.Valid,
  }
}
