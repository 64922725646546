import { useCallback } from 'react'

import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { QuestionPayload } from 'civic-champs-shared/question-sets/types'

const useSaveQuestionnairePreview = (): ((payload: {
  name: string
  questions: QuestionPayload[]
}) => Promise<string>) => {
  const [request] = useFetchRefactored<string>()

  return useCallback(
    body => {
      return request({
        method: 'post',
        url: `/questionnaire-preview`,
        config: { body },
      })
    },
    [request],
  )
}

export default useSaveQuestionnairePreview
