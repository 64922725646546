import * as React from 'react'
import Popup from '../../../../components/popup'
import Button from '../../../../../components/button'
import './index.scss'

interface ErrorPopupProps {
  handleClose: () => void
  errorMessage: string
}

const ErrorPopup = (props: ErrorPopupProps) => {
  const { handleClose, errorMessage } = props

  return (
    <Popup
      shouldCloseOnOverlayClick={false}
      hideCloseButton={true}
      width={583}
      className='congratulations-popup'
      contentClassName='congratulations-popup__content'
    >
      <h3 className="congratulations-popup__title">
        Oops!
      </h3>
      <p className="congratulations-popup__text">
        {errorMessage}
      </p>
      <Button
        className="congratulations-popup__button"
        fullWidth
        color='secondary'
        onClick={handleClose}
        customBackgroundColor='#FF0101'
      >
        Ok
      </Button>
    </Popup>
  )
}

export default ErrorPopup