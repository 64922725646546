import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useResolveSalsaMigrationIssues = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error resolved selected volunteer issues')
  const showSuccess = useSuccessNotification()
  const { id: organizationId } = useCurrentOrg()
  const resolveSalsaMigrationIssues = useCallback(
    async migrationResolutionMap => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully resolved selected volunteer issues')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/salsa/organizations/${organizationId}/issues`,
        config: {
          body: { ...migrationResolutionMap },
        },
      })
    },
    [request],
  )

  return {
    resolveSalsaMigrationIssues,
    salsaIntegration: result,
    loading,
  }
}
