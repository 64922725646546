import { Geofencing } from 'civic-champs-shared/core/location/utils'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { EventAddressForm } from 'Event/modules/events/create-edit/location'

export interface EventAddressProps {
  address: string
  city: string
  state: string
  zip: string
  geofencing?: Geofencing
  published: boolean
}

export interface EventGeofencing {
  radius: number
  location: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export const mapCoordinatesArrayToObject = ([lng, lat]: [number, number]) => ({
  lat,
  lng,
})

export const mapCoordinatesObjectToArray = ({ lng, lat }: { lng: number; lat: number }) => [lng, lat]

export const mapEventToAddressField: (event: EventAddressProps) => EventAddressForm = event => {
  const { address: line1 = '', city = '', state = '', zip = '', geofencing = DEFAULT_GEOFENCING } = event
  return {
    address: {
      line1,
      line2: '',
      city,
      state,
      zip,
      geofencing,
      country: '',
      utcOffset: 0,
    },
  }
}

export const mapGeofencingToEventGeofencing: (geofencing: Geofencing) => EventGeofencing = geofencing => {
  const {
    location: {
      coordinates: { lat, lng },
      type,
    },
    radius,
  } = geofencing
  return {
    location: {
      coordinates: [lng, lat],
      type,
    },
    radius,
  }
}

export const mapEventGeofencingToGeofencing: (eventGeofencing: EventGeofencing) => Geofencing = eventGeofencing => {
  const {
    location: {
      coordinates: [lng, lat],
      type,
    },
    radius,
  } = eventGeofencing
  return {
    location: {
      coordinates: {
        lng,
        lat,
      },
      type,
    },
    radius,
  }
}
