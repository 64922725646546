import React from 'react'
import { EditStripeProfileLink } from '../../../donation/components/EditStripeProfileLink'
import { MigrateQuestionnairesButton } from './MigrateQuestionnairesButton'

export const AdditionalFeatureTools = ({ feature }) => {
  const Component = {
    Donations: EditStripeProfileLink,
    QuestionnaireAsSurvey: MigrateQuestionnairesButton,
  }[feature.key]

  if (!Component || !feature.enabled) {
    return null
  }

  return <Component />
}
