import React from 'react'
import classNames from 'classnames'

import './index.scss'

interface BigPlusButtonProps {
  onClick: () => void
  className: string
  title: string
  width?: string
}

const BigPlusButton = ({ onClick, className, title, width }: BigPlusButtonProps) => {
  const classes = classNames('big-plus-button', { [`${className}`]: className })

  return (
    <div style={{ width }} onClick={onClick} className={classes}>
      <span className="big-plus-button__icon" />
      <span className="big-plus-button__text">{title}</span>
    </div>
  )
}

export default BigPlusButton
