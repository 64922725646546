import * as React from 'react'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import classNames from 'classnames'
import MaskedInput from 'react-maskedinput'
import './index.scss'

export interface DatePickerCustomProps {
  value: string
  onChange: (date: string) => void
  onBlur: (value: any) => void
  minDate?: string
  isDisabled?: boolean
  inline?: boolean
  highlightedDates?: []
  isRequired?: boolean
  hasError?: boolean
  placeholder?: string
}
const MASK = '11/11/1111'
const DatePickerCustom = (props: DatePickerCustomProps) => {
  const {
    value,
    onChange,
    minDate,
    isDisabled,
    inline,
    highlightedDates,
    isRequired = false,
    hasError = false,
    placeholder,
  } = props

  const [isReq, setRequired] = useState(isRequired)

  useEffect(() => {
    setRequired(false)
  }, [])

  useEffect(() => {
    if (!isRequired) return setRequired(false)
    if (isRequired && hasError) return setRequired(true)
  }, [isRequired, hasError])

  const handleChange = (date: Date) => {
    const formattedDate = date && moment(date).format('MM/DD/YYYY')
    isRequired && !formattedDate ? setRequired(true) : setRequired(false)
    onChange(formattedDate)
  }

  const handleBlur = (e: any) => {
    props.onBlur && props.onBlur(e.currentTarget.value)
  }

  // const formattedValue = moment(value, 'MM/DD/YYYY HH:mm').format('MM/DD/YYYY')
  const formattedValue = moment(value)
    .format('MM/DD/YYYY')
  const defaultSelectedValue = value ? new Date(formattedValue) : null
  const highlights: any[] = []

  highlightedDates &&
    highlightedDates.map(item => {
      highlights.push(moment(item).toDate())
    })

  const iconClassName = isDisabled ? 'custom-datepicker-wrap_icon--disabled' : 'custom-datepicker-wrap_icon'
  const datepickerClasses = classNames(
    'custom-datepicker-wrap',
    {
      'custom-datepicker-wrap--disabled': isDisabled,
      'custom-datepicker-wrap--error': isReq
    })

  return (
    <div className={datepickerClasses}>
      <DatePicker
        onBlur={handleBlur}
        disabled={isDisabled}
        dateFormat="MM/dd/yyyy"
        minDate={minDate ? new Date(minDate) : moment().toDate()}
        selected={defaultSelectedValue}
        showYearDropdown
        onChange={handleChange}
        inline={inline}
        customInput={<MaskedInput mask={MASK}/>}
        highlightDates={highlights}
        placeholderText={placeholder}
      />
      <span className={iconClassName} />
      <span className='datepicker-error-msg'>{isReq && 'This field is required'}</span>
    </div>
  )
}

export default DatePickerCustom
