import dayjs from 'utils/dayjs'
import { SmileyIcon } from 'tracking/activity/components'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Activity } from 'champion/utils/interface'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { DEFAULT_TIME_ZONE } from 'civic-champs-shared/utils/format'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  reflection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    gap: '5px',
    background: '#FFF',
  },
  title: {
    color: '#000',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  eventName: {
    color: '#0F5DB5',
    fontWeight: 400,
    letterSpacing: '0.25px',
  },
  text: {
    color: '#000',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  titleContainer: {
    display: 'flex',
    gap: '15px',
    alignItems: 'flex-end',
  },
})

export const ReflectionsList = ({ activities }: { activities: Activity[] }) => {
  const classes = useStyles()
  const organization = useCurrentOrg()
  const timeZone = organization?.timezone || DEFAULT_TIME_ZONE
  return (
    <div className={classes.container}>
      {activities.map(({ id, occurredAt, opportunity, reflection, volunteerSatisfaction }) => (
        <div className={classes.reflection} key={id}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>
              {dayjs(occurredAt).tz(timeZone).format('MM/DD/YYYY')}
              {opportunity && (
                <>
                  {' '}
                  - Event: <span className={classes.eventName}>{opportunity.name}</span>
                </>
              )}
            </div>
            {volunteerSatisfaction !== null && (
              <SmileyIcon height={22} width={22} rating={activities[0].volunteerSatisfaction as number} />
            )}
          </div>
          <div className={classes.text}>{reflection}</div>
        </div>
      ))}
    </div>
  )
}
