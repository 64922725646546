import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { TripOrder } from '../types'
import moment from 'moment'

interface fetchTripOrdersProps {
  startDate: string | moment.Moment
  endDate: string | moment.Moment
}

const useFetchTripOrders = (): [(props: fetchTripOrdersProps) => Promise<TripOrder[]>, Status<TripOrder[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripOrder[]>({
    errorMessage: 'Error Fetching Trips.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchTripOrders = useCallback(
    ({ startDate, endDate }: fetchTripOrdersProps) => {
      return request({
        method: 'get',
        url: `/organizations/${organization.id}/trip-orders`,
        config: {
          queryStringParameters: {
            startDate: moment(startDate).startOf('day').toISOString(),
            endDate: moment(endDate).endOf('day').toISOString(),
          },
        },
      })
    },
    [organization, request],
  )

  return [fetchTripOrders, status]
}

export default useFetchTripOrders
