import { useCurrentOrg } from '../../auth/hooks'
import usePut from 'civic-champs-shared/api/hooks/usePut'

export const useEditGroup = (groupId, setGroup) => {
  const currentOrg = useCurrentOrg()
  return usePut({
    errorMessage: 'Error updating group',
    configMapper: (payload) => ({
      config: { body: payload },
      url: `/organizations/${currentOrg.id}/groups/${groupId}`,
      onSuccess: setGroup,
    }),
  })
}
