import React from 'react'
import { ExportButton } from 'core/table/components'
import format from '../../civic-champs-shared/utils/format'
import { OfferSignup } from 'Event/hooks/useOfferSignupsCollection'

const filename = 'offer-signups.csv'
const headers = [
  { label: 'Id', key: 'id' },
  { label: 'First Name', key: 'person.givenName' },
  { label: 'Last Name', key: 'person.familyName' },
  { label: 'Email', key: 'person.email' },
  { label: 'Mobile', key: 'person.phoneNumber' },
  { label: 'Home Phone', key: 'person.homePhoneNumber' },
  { label: 'Groups', key: 'groups' },
  { label: 'Sign-up Date', key: 'createdAt' },
]
const formatData = (data: OfferSignup[]) =>
  data.map(o => {
    o.person.phoneNumber = format.phoneNumber(o.person.phoneNumber)
    o.person.homePhoneNumber = format.phoneNumber(o.person.homePhoneNumber)
    o.createdAt = format.datetimeForExport(o.createdAt) as any
    return o
  })

const ExportOfferSignupsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  return <ExportButton data={data} disabled={disabled} formatData={formatData} headers={headers} filename={filename} />
}

export default ExportOfferSignupsButton
