import moment from 'moment'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
   active: {
    fontWeight: 'bold',
    color: '#5F8FE8',
    fontSize: '22px' 
  },
   inactive: {
    color: 'grey',
    fontSize: '22px'
  },
  draft:{
    color: '#FF6F3E',
    fontSize: '22px'
  },
}))

const isActive = campaign => {
    const { startsAt, endsAt } = campaign
    const now = moment()
    return moment(startsAt).isSameOrBefore(now) && moment(endsAt).isSameOrAfter(now)
}

export default function CampaignStatus({ campaign }) {
    const classes = useStyles()

    if(!campaign.publishedAt) return <div className={classes.draft}>Draft</div>
    
    return isActive(campaign) 
        ? <span className={classes.active}>Active</span>
        : <span className={classes.inactive}>Inactive</span>
}