import React, { useCallback, useEffect, useMemo } from 'react'
import reduce from 'lodash/reduce'
import AddIcon from '@material-ui/icons/Add'
import {
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import format from 'civic-champs-shared/utils/format'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks/'
import {
  NUMBER_OPERATOR_OPTIONS,
  OperatorOption,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'

import Loading from 'components/Loading'
import { MentorshipProgramWithStatistics } from 'new-mentorship/types'
import PageHeader from 'App/components/PageHeader'
import useColumns from '../hooks/useMentorshipProgramsColumns'
import MENTORSHIP_FILTERS from 'new-mentorship/helpers/filters'
import { useMentorshipProgramsCollection } from '../hooks/useMentorshipProgramsCollection'

const csvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Description', key: 'description' },
  { label: 'Start', key: 'startsAt' },
  { label: 'End', key: 'endsAt' },
  { label: '# Matches', key: 'matchCount' },
  { label: 'Avg. Meeting Time', key: 'meetingLengthAverage' },
  { label: '% Positive Rating by Mentors', key: 'mentorFeedbackAverage' },
  { label: '% Positive Rating by Mentees', key: 'menteeFeedbackAverage' },
  { label: 'Status', key: 'status' },
]

const getOperatorOptions = (column: string): OperatorOption[] => {
  switch (column) {
    case 'name':
    case 'description':
      return STRING_OPERATOR_OPTIONS
    case 'matchCount':
    case 'meetingLengthAverage':
    case 'mentorFeedbackAverage':
    case 'menteeFeedbackAverage':
      return NUMBER_OPERATOR_OPTIONS
    case 'status':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Includes' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Does not include' },
      ]
    default:
      return []
  }
}

const cards = [
  {
    header: 'Programs',
    accessor: 'programs',
    transform: format.number,
  },
  {
    header: 'Matches',
    accessor: 'matches',
    transform: format.number,
  },
]

const tableName = 'mentorshipPrograms'

const useSummary = (rows: Row<any>[]) =>
  useMemo(() => {
    return {
      programs: rows.length,
      matches: reduce(
        rows,
        (sum: number, { original: { matchCount } }: Row<MentorshipProgramWithStatistics>) => matchCount + sum,
        0,
      ),
    }
  }, [rows])

const NewMentorshipProgramsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()
  const [{ programs, loading, initiallyLoaded }, { addProgram, editProgram }, events] =
    useMentorshipProgramsCollection()

  const columns = useColumns({ editProgram })

  const table = useTable(
    {
      data: programs,
      columns,
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
        hiddenColumns: ['description'],
      },
      filterTypes: { ...DEFAULT_FILTERS, ...MENTORSHIP_FILTERS },
      autoResetPage: !skipReset.current,
      autoResetSortBy: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const formatData = useCallback(
    (inputData: any[]) =>
      inputData.map(
        ({ original: { meetingLengthAverage, mentorFeedbackAverage, menteeFeedbackAverage, ...row } }: any) => ({
          ...row,
          meetingLengthAverage: meetingLengthAverage ? Math.round(meetingLengthAverage / 60) : '',
          mentorFeedbackAverage: mentorFeedbackAverage ? Math.round(mentorFeedbackAverage * 100) : '',
          menteeFeedbackAverage: menteeFeedbackAverage ? Math.round(menteeFeedbackAverage * 100) : '',
        }),
      ),
    [],
  )

  const summary = useSummary(table.rows)

  if (loading || columnStateLoading || !initiallyLoaded) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Programs"
        filterSubHeader="Select program attributes to filter by:"
        buttons={
          <>
            <ExportButton
              data={table.selectedFlatRows}
              formatData={formatData}
              headers={csvHeaders}
              filename={'mentorship-programs.csv'}
            />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addProgram}>
            Add Program
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
        eventListeners={events}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const NewMentorshipPrograms = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Programs" subTitle="Create and manage mentorship programs">
      <NewMentorshipProgramsComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipPrograms
