import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog, { useStyles } from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import useDeleteChatMembers from 'chats/hooks/useDeleteChatMembers'
import { Member } from 'chats/interfaces'

export const useRemoveChatMemberPrompt = (
  chatId: string,
  setMembers: Dispatch<SetStateAction<Member[]>>,
): ((person: Member) => Promise<void>) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const classes = useStyles()
  const [deleteMembers] = useDeleteChatMembers()

  return useCallback(
    async (person: Member) => {
      const children = (
        <DialogContentText classes={{ root: classes.text }}>
          Are you sure you want to remove{' '}
          <strong>
            {person.givenName} {person.familyName}
          </strong>{' '}
          from the chat?
        </DialogContentText>
      )
      const confirmed = await showPrompt({
        children,
        title: 'Remove Member?',
        confirmText: 'Remove',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        setMembers(members => members.filter(m => m.id !== person.id))
        await deleteMembers({ chatId, uuids: [person.pubnubId] })
      }
    },
    [classes.text, showPrompt, setMembers, deleteMembers, chatId],
  )
}

export default useRemoveChatMemberPrompt
