import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const SelectPerson = ({ label, value, onChange, items }) => {
  return (
    <Autocomplete
      id="question-input"
      value={value}
      getOptionLabel={person =>
        person
          ? `#${person.id}. ${person.givenName} ${person.familyName} (${person.email || person.phoneNumber})`
          : null
      }
      options={items}
      onChange={(event, person) => onChange(person)}
      renderInput={params => (
        <TextField
          {...params}
          classes={{ root: 'custom-input-component' }}
          variant="outlined"
          fullWidth
          label={label}
          required
        />
      )}
    />
  )
}
