import React, { forwardRef, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { usePrevious } from 'react-use'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import { selectionColumnId } from '../table-hooks/useSelectColumn'
import { useLegacyTableStyles } from 'core/table/table-hooks/useLegacyTableStyles'
import { useTableStyles } from 'core/table/table-hooks/useTableStyles'
import { isFunction } from 'lodash'

const PAGE_SIZES = [10, 25, 50, 100]

const PagedTable = forwardRef((props, ref) => {
  const {
    state: { pageIndex, pageSize },
    getTableProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    style,
    groupBy,
    setGroupBy,
    selectHeaderCellClassName,
    wrapHeader,
    newStyles,
    header,
    getCellStyle,
    getHeaderCellStyle,
    compactView,
  } = props

  const prevData = usePrevious(props.data)

  /* automatically toggle on grouping */
  useEffect(() => {
    if (groupBy && props.data.length && props.data !== prevData) {
      setGroupBy(groupBy)
    }
  }, [groupBy, prevData, props.data, setGroupBy])

  const handleChangePage = (event, newPage) => gotoPage(newPage)

  const handleChangePageSize = event => {
    const pageSize = parseInt(event.target.value, 10)
    setPageSize(pageSize)
    gotoPage(0)
  }

  const oldClasses = useLegacyTableStyles()
  const newClasses = useTableStyles()
  const classes = newStyles ? newClasses : oldClasses

  const getSortStyle = useCallback(
    column => {
      if (column.canSort && column.isSorted) {
        return column.isSortedDesc ? classes.sortDesc : classes.sortAsc
      }

      return null
    },
    [classes],
  )

  return (
    <TableContainer component={Paper} className={cn({ [classes.compactView]: compactView })}>
      {header}
      <Table ref={ref} {...getTableProps()} classes={{ root: classes.table }}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow style={style} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...column.getHeaderProps([
                    {
                      ...(isFunction(getHeaderCellStyle) && getHeaderCellStyle(column)),
                    },
                    column.getSortByToggleProps(),
                  ])}
                  className={cn(getSortStyle(column), classes.cell, classes.headerCell, column.className, {
                    [classes.filterHeader]: column.filterValue && !column.disableFilters,
                    [selectHeaderCellClassName]: column.id === selectionColumnId,
                    [classes.wrapHeader]: wrapHeader,
                  })}
                >
                  <div>
                    {column.canSort ? (
                      <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : (
                      column.render('Header')
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {page.map(row => {
            prepareRow(row)

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <TableCell
                    {...cell.getCellProps([
                      {
                        ...(isFunction(getCellStyle) && getCellStyle(row, cell)),
                      },
                    ])}
                    className={cn(classes.cell, props.classes.cell, cell.column.className, {
                      [classes.highlight]: row.original?.isNewInCollection,
                    })}
                  >
                    <div>
                      {cell.isGrouped
                        ? cell.render('Cell')
                        : cell.isAggregated
                        ? cell.render('Aggregated')
                        : cell.isPlaceholder
                        ? null
                        : /* For cells with repeated values, render null, otherwise, just render the regular cell */
                          cell.render('Cell')}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{
                root: classes.pagination,
                toolbar: classes.pagination,
                spacer: classes.spacer,
                menuItem: classes.pagination,
                caption: classes.pagination,
                input: classes.pagination,
                selectRoot: classes.pagination,
                select: classes.pagination,
                actions: classes.pagination,
              }}
              count={rows.length}
              page={pageIndex}
              rowsPerPage={pageSize}
              rowsPerPageOptions={PAGE_SIZES}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangePageSize}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
})

export default PagedTable

PagedTable.defaultProps = {
  classes: {
    cell: '',
    headerCell: '',
  },
}
