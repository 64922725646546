import { makeStyles } from '@material-ui/styles'

export const useExportStyles = makeStyles({
  fill: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
})

export default useExportStyles
