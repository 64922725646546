import { useCallback } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'

const useUpdateAffiliatedOpportunities = () => {
  const [request, status] = useFetch()

  const updateOpportunities = useCallback(
    async (campaignId, selected) => {
      return request({
        method: 'post',
        url: `/campaigns/${campaignId}/opportunities`,
        config: {
          body: {
            opportunityIds: selected || [],
          },
        },
      })
    },
    [request],
  )

  return [updateOpportunities, status]
}

export default useUpdateAffiliatedOpportunities
