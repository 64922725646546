import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useRemoveAdmins from './useRemoveAdmins'
import { useCallback } from 'react'
import { Admin } from '../interface'
import { RemoveAdminsModal } from '../components'

const useRemoveAdminsPrompt = (eagerRemoveMany: any) => {
  const showPrompt = useShowPrompt(RemoveAdminsModal)
  const removeAdmin = useRemoveAdmins(eagerRemoveMany)

  return useCallback(
    async (admins: Admin[]) => {
      const confirmed = await showPrompt({
        admins,
      })

      if (confirmed) {
        await removeAdmin(admins)
      }
    },
    [showPrompt, removeAdmin],
  )
}

export default useRemoveAdminsPrompt
