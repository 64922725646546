import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useFeatureEnabled } from '../../core/feature/hooks'

const STABLE_EMPTY_ARRAY = []

export const useGroupCredentials = (groupId, onSuccess) => {
  const [request, { result, loading, called }] = useFetch(
    'Error fetching waivers.  Please refresh the page and try again',
  )
  const groupCredentialEnabled = useFeatureEnabled('Groups')
  const currentOrg = useCurrentOrg()
  const reFetch = useCallback(async () => {
    return request({
      method: 'get',
      url: `/organizations/${currentOrg.id}/groups/${groupId}/credentials`,
      onSuccess: res => {
        onSuccess && onSuccess(res)
      },
    })
  }, [request, currentOrg, groupId])
  useEffect(() => {
    if (groupId && groupCredentialEnabled) {
      reFetch()
    }
  }, [reFetch, groupId, groupCredentialEnabled])

  return {
    credentials: result || STABLE_EMPTY_ARRAY,
    loading: groupCredentialEnabled && (loading || !called),
    reFetch,
  }
}
