import React from 'react'
import Button from '../../../../../components/button'

import './index.scss'

interface MultiStepNavButtonProps {
  onPrevClick?: () => void
  onNextClick?: () => void
  onSaveClick?: () => void
  onSaveDraftClick?: () => void
  isSendingRequest?: boolean
  isShowDraftButton?: boolean
  draftButtonText?: string,
  nextDisabled?: boolean
}

const MultiStepNavButton = (props: MultiStepNavButtonProps) => {
  const {
    onPrevClick,
    onNextClick,
    onSaveDraftClick,
    isShowDraftButton,
    isSendingRequest = false,
    draftButtonText = 'Save For Later',
    nextDisabled = false
  } = props
  return (
    <div className="multi-step-nav-button">
      {isShowDraftButton && !!onSaveDraftClick && (
        <Button isLoading={isSendingRequest} onClick={onSaveDraftClick} type="outlined" color="secondary">
          {draftButtonText}
        </Button>
      )}
      <div className="multi-step-nav-button__right">
        {!!onPrevClick && (
          <Button
            className="multi-step-nav-button__back-btn"
            onClick={onPrevClick}
            type="text"
            color="secondary"
          >
            BACK
          </Button>
        )}
        {!!onNextClick && (
          <Button disabled={nextDisabled} className="multi-step-nav-button__next-btn" onClick={onNextClick}>NEXT</Button>
        )}
      </div>
    </div>
  )
}

export default MultiStepNavButton
