import { useCallback, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export const useSignUpVolunteer = (onResultUpdate) => {
  const [request, { result, loading, error, called }] = useFetch(
    'Error Fetching Requirements.  Please refresh the page and try again',
  )
  const useSignUpVolunteer = useCallback(async (orgId, body) => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'post',
      url: `/organizations/${orgId}/volunteers`,
      config: { body }
    })
  }, [request])
  return useMemo(
    () => [
      useSignUpVolunteer,
      {
        result,
        loading: loading,
        called,
        error,
      },
    ],
    [result, loading, error],
  )
}
