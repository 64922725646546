import { useEffect, useState } from 'react'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import { getAddressFromSuggestion, getAddressSuggestions } from 'volunteering/trips/helpers/addressTools'
import { Address } from 'civic-champs-shared/core/location/utils'

export const useFillCounty = (address?: Address): [string | undefined, boolean] => {
  const [county, setCounty] = useState(address?.county)
  const [loading, setLoading] = useState(!!(address && !county))

  useEffect(() => {
    ;(async () => {
      if (address && !county) {
        const { line1, line2, city, state, zipCode } = address
        const addressString = Object.values(omitBy([line1, line2, city, state, zipCode], isEmpty)).join(', ')
        const suggestions = await getAddressSuggestions(addressString)
        if (!suggestions.length) {
          setLoading(false)
          return
        }
        try {
          const address = await getAddressFromSuggestion(suggestions[0])
          setCounty(address.county)
        } catch (e) {
        } finally {
          setLoading(false)
        }
      }
    })()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [county, loading]
}
