import { programFilter } from './programs'
import { ratingFilter } from './rating'
import { tagsFilter } from './tags'
import { statusFilter } from './status'

export const PROGRAMS_FILTER = 'programs'
export const RATING_FILTER = 'rating'
export const TAGS_FILTER = 'tags'
export const STATUS_FILTER = 'status'
export const MENTORSHIP_FILTERS = {
  [PROGRAMS_FILTER]: programFilter,
  [RATING_FILTER]: ratingFilter,
  [TAGS_FILTER]: tagsFilter,
  [STATUS_FILTER]: statusFilter,
}
export default MENTORSHIP_FILTERS
