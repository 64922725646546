import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  sectionLabel: {
    fontSize: '1.5em',
  },
  actionButton: {
    marginTop:'10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:first-child': {
        'margin-left': '0',
    },
    '&:last-child': {
        'margin-right': '0',
    }
  },
  userList:{
    backgroundColor:''
  },
  inputFieldOutlined:{
    marginTop:'10px',
    marginBottom:'10px',
    color:'#3d3d3d'
  }
}));

export default useStyles;
