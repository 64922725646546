import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Chip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { FormReferenceItem } from 'Event/helpers/add-edit-helpers'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    width: '100%',
  },
  item: {
    backgroundColor: '#F4BF2C',
  },
  label: {
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontWeight: 400,
  },
})

export const ReferenceItemGroup = ({
  items,
  name,
  setFieldValue,
}: {
  items: FormReferenceItem[]
  name: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}) => {
  const classes = useStyles()
  const handleRemove = useCallback(
    (id: number) => {
      setFieldValue(
        name,
        items.filter(item => item.id !== id),
      )
    },
    [items, name, setFieldValue],
  )
  return (
    <div className={classes.container}>
      {items.map(({ name, id }) => (
        <Chip
          classes={{ label: classes.label }}
          label={name}
          onDelete={() => handleRemove(id)}
          className={classes.item}
          deleteIcon={<ClearIcon />}
        />
      ))}
    </div>
  )
}
