import _ from 'lodash'
import { APP, KIOSK, WEB, SYSTEM } from './source'
import { INITIAL_ENTRY, EDITED } from './entryType'
import { TIMESHEET, LOG_OTHER_HOURS } from './entryMethod'
import { SELF, ADMIN, SCHEDULED_PROCESS } from './actor'

class ActivitySource {
  constructor(props) {
    Object.defineProperties(
      this,
      _.mapValues(props, value => ({ value })),
    )
  }
}

//TODO replace strings with the enums from this directory!
const sources = _.mapValues(
  {
    MOBILE_CHECK_IN: {
      id: 1,
      name: 'mobile check-in',
      source: APP,
      actor: SELF,
      entryMethod: TIMESHEET,
      entryType: INITIAL_ENTRY,
    },
    LOG_OTHER_HOURS: {
      id: 2,
      name: 'log other hours',
      source: APP,
      actor: SELF,
      entryMethod: LOG_OTHER_HOURS,
      entryType: INITIAL_ENTRY,
    },
    ADMIN_MOBILE_CHECK_IN: {
      id: 3,
      name: 'admin mobile check-in',
      source: APP,
      actor: ADMIN,
      entryMethod: TIMESHEET,
      entryType: INITIAL_ENTRY,
    },
    KIOSK_CHECK_IN: {
      id: 4,
      name: 'kiosk check-in',
      source: KIOSK,
      actor: SELF,
      entryMethod: TIMESHEET,
      entryType: INITIAL_ENTRY,
    },
    MOBILE_CHECK_IN_EDIT: {
      id: 5,
      name: 'mobile check-in edit',
      source: APP,
      actor: SELF,
      entryMethod: TIMESHEET,
      entryType: EDITED,
    },
    LOG_OTHER_HOURS_EDIT: {
      id: 6,
      name: 'log other hours edit',
      source: APP,
      entryMethod: LOG_OTHER_HOURS,
      entryType: EDITED,
    },
    AUTOMATIC_CHECK_OUT: {
      id: 7,
      name: 'automatic check-out',
      source: SYSTEM,
      actor: SCHEDULED_PROCESS,
      entryMethod: TIMESHEET,
      entryType: INITIAL_ENTRY,
    },
    ADMIN_TIMESHEET_EDIT: {
      id: 8,
      name: 'admin timesheet edit',
      source: WEB,
      actor: ADMIN,
      entryMethod: TIMESHEET,
      entryType: EDITED,
    },
    ADMIN_LOG_OTHER_HOURS_EDIT: {
      id: 9,
      name: 'admin log outher hours edit',
      source: WEB,
      actor: ADMIN,
      entryMethod: LOG_OTHER_HOURS,
      entryType: EDITED,
    },
    ADMIN_LOG_OTHER_HOURS: {
      id: 10,
      name: 'admin log other hours',
      source: WEB,
      actor: ADMIN,
      entryMethod: LOG_OTHER_HOURS,
      entryType: INITIAL_ENTRY,
    },
  },
  config => new ActivitySource(config),
)

const keyById = _.reduce(
  sources,
  (keyById, source, key) => {
    keyById[source.id] = key
    return keyById
  },
  {},
)

const getById = id => keyById[id]

export default Object.freeze({
  ...sources,
  getById,
})
