import React, { useState } from 'react'
import { StyledAutocomplete } from 'components/StyledAutocomplete.old'
import Tag from 'components/tag/Tag'
import { makeStyles } from '@material-ui/core/styles'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import filter from 'lodash/filter'
import find from 'lodash/find'
import { RgbaColorPicker } from 'react-colorful'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { Button, Grid, Modal } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { RgbaColor } from 'react-colorful/dist/types'
import useTagManagement from 'new-mentorship/hooks/useTagsManagement'

const useStyles = makeStyles({
  listbox: {
    display: 'flex',
  },
  option: {
    padding: '4px',
  },
  button: {
    borderRadius: '100px',
    color: '#fff',
    textShadow: '1px 1px rgba(1,1,1,0.2)',
    margin: '12px 12px',
  },
})

const ColorModal = (props: any) => {
  const classes = useStyles()
  const { showing, submit, cancel } = props
  const [color, setColor] = useState<RgbaColor>({ r: 0, g: 0, b: 0, a: 0 })
  return (
    <Modal open={showing}>
      <div
        style={{
          width: '300px',
          position: 'fixed',
          top: 'calc(50% - 150px)',
          left: 'calc(50% - 150px)',
          backgroundColor: 'rgba(255,255,255,0.9)',
          border: 0,
          borderRadius: '12px',
        }}
      >
        <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: 400 }}>Pick Tag Color</h2>
        <RgbaColorPicker style={{ margin: '0 auto' }} onChange={setColor} />
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Button onClick={cancel} variant="contained" color="secondary" className={classes.button}>
            Cancel
          </Button>

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => submit(`rgba(${color.r},${color.g},${color.b},${color.a})`).then(cancel())}
          >
            Select
          </Button>
        </Grid>
      </div>
    </Modal>
  )
}

const filterSuggestions = createFilterOptions({ stringify: ({ name }: any) => name })
export const TagAutocomplete = ({ addOption, form: { setFieldValue, ...form }, ...props }: any) => {
  const addPrefix = '+ Add '
  const showAddColor = useShowPrompt(ColorModal)
  const classes = useStyles()
  const { createTag } = useTagManagement()
  return (
    <StyledAutocomplete
      form={{
        ...form,
        setFieldValue: (name: string, value: any[]) => {
          const createItem = find(value, { id: null })
          const filtered = filter(value, 'id')
          if (createItem) {
            showAddColor({
              submit: (color: string) => {
                return createTag({
                  name: createItem.name.substr(addPrefix.length),
                  color,
                }).then(tag => {
                  setFieldValue(name, [...filtered, tag])
                  addOption(tag)
                })
              },
            })
          } else {
            setFieldValue(name, filtered)
          }
        },
      }}
      {...props}
      classes={classes}
      multiple
      filterSelectedOptions
      renderTags={(value: any, getTagProps: any) =>
        value.map((option: any, index: any) => (
          <Tag margin="2px" id={option.id} color={option.color} label={option.name} {...getTagProps({ index })} />
        ))
      }
      renderOption={(props: any) => {
        return <Tag pointer id={props.id} color={props.color} label={props.name} />
      }}
      filterOptions={(options: any, params: any) => {
        const filtered = filterSuggestions(options, params)
        return !isEmpty(params.inputValue)
          ? [
              ...filtered,
              {
                id: null,
                name: `${addPrefix}${params.inputValue}`,
                color: 'rgba(1,1,1,0.1)',
              },
            ]
          : filtered
      }}
    />
  )
}
