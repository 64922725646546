/* eslint-disable react-hooks/exhaustive-deps */
// TODO: this whole thing should be re-written using formik and all the functions should be cleaned up.  This and 'AnswerListItem' need to be re-written
import React, { FC, useEffect, useMemo, useState } from 'react'
import AnswerListItem from './answer-item/index'
import SelectComponent from 'civic-champs-shared/core/select'
import { Checkbox } from 'civic-champs-shared/core/new-checkbox/index'
import { Question, QuestionItemProps, QuestionType } from 'civic-champs-shared/question-sets/types'
import { INIT_VALIDATOR } from 'civic-champs-shared/core/utils/validation'

import { cloneDeep, isNil, map, noop } from 'lodash'
import './index.scss'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
// @ts-ignore
import matchSorter from 'match-sorter'
import DeleteIcon from '@material-ui/icons/Delete'
import { mdiContentDuplicate, mdiDragHorizontal, mdiPlusCircleOutline } from '@mdi/js'
import { Icon } from '@mdi/react'

import { IconButton, Tooltip, TooltipProps, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useFeatureEnabled } from 'core/feature/hooks'

const defaultErrorMessages = {
  question: '',
}

const defaultOptions: string[] = ['', '']

const QuestionItem: FC<QuestionItemProps> = props => {
  const {
    question,
    onRemoveQuestion,
    onDuplicateQuestion,
    onChangeField,
    onChangeValidField,
    isClickedToNextStep,
    questions,
    existingQuestions = [],
  } = props

  const [questionLabel, setQuestionLabel] = useState(question.question || '')
  const [answerStateWithValidField, setAnswerStateWithValidField] = useState([])
  const { options = [] } = question
  const [prevOptions, setPrevOptions] = useState(options.length ? options : defaultOptions)

  const mappedOptions = useMemo(() => {
    return map(options, value => ({ value })) || []
  }, [options])

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  const questionOptions = [
    QuestionType.SHORT_TEXT,
    QuestionType.LONG_TEXT,
    QuestionType.MULTIPLE_CHOICE,
    QuestionType.DROPDOWN,
    QuestionType.SINGLE_CHOICE,
    QuestionType.NUMBER,
    QuestionType.HOME_ADDRESS,
    QuestionType.EMERGENCY_CONTACT,
  ].map(type => ({ label: type, value: type }))

  const showOptions = (type: QuestionType): boolean =>
    [QuestionType.MULTIPLE_CHOICE, QuestionType.SINGLE_CHOICE, QuestionType.DROPDOWN].includes(type)

  useEffect(() => {
    isClickedToNextStep !== null &&
      isClickedToNextStep &&
      !question.question &&
      onChangeErrorMessage('question', 'The question field is required')
  }, [isClickedToNextStep])

  useEffect(() => {
    const checkAllQuestionName = questions.every(question => !!question.question)
    if (!showOptions(question.questionType) || !options.length) {
      onChangeValidField(checkAllQuestionName && !errorMessages.question)
    } else {
      onChangeValidField(checkAllQuestionName && !errorMessages.question && !isHaveErrorAtAnswers())
    }
  }, [errorMessages.question, answerStateWithValidField, options, question.question, question.questionType])

  useEffect(() => {
    !options.length && generateOptionsAndUpdateAnswers(question.questionType)
    if (!options.length) {
      !question.question && onChangeValidField(null)
    } else {
      !question.question && isHaveErrorAtAnswers() && onChangeValidField(null)
    }
  }, [])

  const getInitialQuestionType = (type: QuestionType) => questionOptions.filter(item => item.value === type)

  const updateAnswers = (options: string[]) => {
    onChangeField(options, 'options')
    setPrevOptions(options)
  }

  const questionTypeHasOptions = (type: QuestionType) =>
    [QuestionType.MULTIPLE_CHOICE, QuestionType.DROPDOWN, QuestionType.SINGLE_CHOICE].includes(type)

  const generateOptions = (type: QuestionType, options?: string[]) => {
    let newOptions: string[] = []
    if (questionTypeHasOptions(type)) {
      newOptions = options?.length ? options : defaultOptions
    }
    return newOptions
  }

  const generateOptionsAndUpdateAnswers = (type: QuestionType, options?: string[]) => {
    const newOptions = generateOptions(type, options)
    updateAnswers(newOptions)
  }

  const handleChangeQuestionType = (option: any) => {
    if (questionTypeHasOptions(option.value) && !options.length) {
      generateOptionsAndUpdateAnswers(option.value, prevOptions)
    } else if (!questionTypeHasOptions(option.value) && options.length) {
      onChangeField([], 'options')
    }
    onChangeField(option.value, 'questionType')
  }

  const handleAddNewAnswer = () => {
    updateAnswers([...options, ''])
  }

  const handleRemoveAnswer = (idx: number) => () => {
    if (options.length === 2) {
      return
    }
    const answers = [...options]
    answers.splice(idx, 1)
    updateAnswers([...answers])
  }

  const handleChangeAnswer = (idx: number) => (val: any) => {
    const updatedAnswers = [...options]
    updatedAnswers[idx] = val
    updateAnswers(updatedAnswers)
  }

  const handleChangeField = (field: any) => (val: any) => {
    onChangeField(val, field)
  }

  const handleChangeValidField = (idx: number) => (value: boolean | null) => {
    const updatedAnswers: any = cloneDeep(mappedOptions)
    updatedAnswers[idx]['is_valid'] = value
    setAnswerStateWithValidField(updatedAnswers)
  }

  const isHaveErrorAtAnswers = () => {
    const isHaveFalseVal = answerStateWithValidField.some((i: any) => i.is_valid === false)
    const isHaveNullVal = answerStateWithValidField.some((i: any) => i.is_valid === null)
    return isHaveFalseVal || isHaveNullVal
  }

  const handleQuestionChange = (selection: Question | null) => {
    const questionLabel = (selection && selection.question) || ''
    if (selection) {
      const options = generateOptions(selection.questionType, selection.options)
      onChangeField({ ...selection, options })
    } else {
      // called when user clears the selection
      const options = generateOptions(question.questionType)
      onChangeField({ ...question, options, question: questionLabel })
    }
    setQuestionLabel(questionLabel)
    onValidateField('question', { required: true, maxLength: 100 })(questionLabel)
  }

  const handleLabelChange = (newValue: string) => {
    setQuestionLabel(newValue)
    onChangeField(newValue, 'question')
  }

  const filteredOptions = useMemo(
    () => matchSorter(existingQuestions, questionLabel, { keys: ['question'] }),
    [questionLabel],
  )

  return (
    <div className="question-list-item">
      <div className="question-row">
        <IconButton onClick={noop} disabled>
          <Icon path={mdiDragHorizontal} size={1} style={{ fill: '#5C8DE8' }} />
        </IconButton>

        <div className="question-row__item">
          <SelectComponent
            value={getInitialQuestionType(question.questionType)}
            onChange={handleChangeQuestionType}
            options={questionOptions}
          />
        </div>
        <div className="question-row__item">
          <Autocomplete
            id="question-input"
            freeSolo
            value={question}
            inputValue={questionLabel}
            getOptionLabel={(option: any) => (isNil(option.question) ? option : option.question)}
            options={filteredOptions}
            onBlur={onValidateField('question', { required: true, maxLength: 100 })}
            placeholder="Enter your question here"
            onInputChange={(event, newInputValue) => handleLabelChange(newInputValue)}
            onChange={(event, newQuestion: string | Question | null) =>
              handleQuestionChange(newQuestion as Question | null)
            }
            renderInput={params => (
              <TextField
                {...params}
                error={!!errorMessages.question}
                helperText={errorMessages.question}
                classes={{ root: 'custom-input-component' }}
                variant="outlined"
                fullWidth
              />
            )}
          />
          <div className="answer-list">
            {showOptions(question.questionType) &&
              options.map((answer: string, idx: number) => (
                <AnswerListItem
                  questions={questions}
                  key={idx}
                  answerValue={answer}
                  type={question.questionType}
                  isClose={idx !== 0 && idx !== 1}
                  onChangeAnswer={handleChangeAnswer(idx)}
                  onRemoveAnswer={handleRemoveAnswer(idx)}
                  onChangeValidField={handleChangeValidField(idx)}
                  isClickedToNextStep={isClickedToNextStep}
                />
              ))}

            {showOptions(question.questionType) && (
              <Button
                className="add-another-question"
                onClick={handleAddNewAnswer}
                startIcon={<Icon path={mdiPlusCircleOutline} size={0.75} />}
              >
                Add another answer
              </Button>
            )}
          </div>
        </div>
        <div className="question-row__item">
          <Checkbox value={question.isRequired} onClick={handleChangeField('isRequired')} label="Required" />
        </div>
        <StyledTooltip title="Duplicate question" {...commonTooltipProps}>
          <IconButton onClick={onDuplicateQuestion}>
            <Icon path={mdiContentDuplicate} size={1} style={{ fill: '#5C8DE8' }} />
          </IconButton>
        </StyledTooltip>
        <StyledTooltip title="Delete question" {...commonTooltipProps}>
          <IconButton onClick={onRemoveQuestion}>
            <DeleteIcon style={{ color: '#FF6F3E' }} />
          </IconButton>
        </StyledTooltip>
      </div>
    </div>
  )
}

const commonTooltipProps: Partial<TooltipProps> = { placement: 'top-end', arrow: true }

const StyledTooltip = withStyles({
  tooltip: { color: 'black', backgroundColor: '#fff0b2', fontStyle: 'italic' },
  arrow: { color: '#fff0b2' },
})(Tooltip)

export default QuestionItem
