import _ from 'lodash';

export default function getRequest(queryConfig, params={}) {
    const { request: requestConfig } = queryConfig;
    if( _.isFunction(requestConfig) ) {
        let { variables={} } = params;
        return requestConfig(variables);
    } else if( _.isObject(requestConfig) ) {
        return { ...requestConfig };
    } else {
        throw new Error('query configuration property "request" must be an object or function!');
    }
}
