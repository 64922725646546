import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button as ButtonMaterial, makeStyles, Tooltip } from '@material-ui/core'
import './style.scss'
import EventStatus from '../../../../components/EventStatus'
import API_ENDPOINTS from '../../../../constants/API_ENDPOINTS'
import BaseService from '../../../../services/base.service'
import { Opportunity } from '../../../../interfaces'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import useCancelEventPrompt from './useCancelEventPrompt'
import { StartKioskButton } from 'Event/scenes/events/StartKioskButton'
import { useHasRole } from 'civic-champs-shared/auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

const useStyles = makeStyles((theme: any) => ({
  start_kiosk_button: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  status: {
    textAlign: 'right',
  },
  button: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.neutral.white,
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    },
  },
  tooltip: {
    fontSize: '14px',
    lineHeight: 1.5,
  },
}))

const baseService = new BaseService()

interface Props {
  isVolunteers?: boolean
  volunteersList: any
  isDraft?: boolean
  onAddVolunteer?: () => void
  event: Opportunity
  encodedOccurrence: string
}

export enum CancelType {
  Single = 'single',
  Future = 'future',
  All = 'all',
}

interface CancelEvent {
  eventId: number
  cancelType: CancelType
}

type CancelHandler = (event: CancelEvent) => void

function EventDetailsHeader(props: Props | any) {
  const classes = useStyles()

  const { encodedOccurrence } = props.match.params

  const { event } = props

  const passed = new Date() > new Date(event.endsAt)

  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  // Disable for not internal admins, and event has finished
  const disabledForNonInternalAdmin = !isInternalSuperAdmin && passed

  const handleEditEvent = (encodedOccurrence: string) => () => {
    props.history.push(`/events/${encodedOccurrence}/edit-event/basic`)
  }

  const isPublished = event.published

  const handleCancelEvent: CancelHandler = async ({ cancelType }) => {
    await baseService.deleteJSON(API_ENDPOINTS.Events.occurrenceRefactorCancelEvent({ encodedOccurrence, cancelType }))
    props.history.push('/events')
  }

  const showCancelEventPrompt = useCancelEventPrompt(handleCancelEvent)

  // Message inside tooltip
  const setTitleTooltipButtonDisabled = (customMessage: string, name?: string) =>
    `${!customMessage ? name + ' button disabled, just internal admins can modify' : customMessage}`

  return (
    <section className="header_container">
      <nav className="header_container_title_block">
        <div className="title">{event.name}</div>
        <FeatureEnabled code="EventScheduling">
          <Tooltip
            disableHoverListener={!disabledForNonInternalAdmin}
            arrow
            title={
              <span className={classes.tooltip}>
                {setTitleTooltipButtonDisabled('Events with a status of Passed can no longer be edited.')}
              </span>
            }
          >
            <span>
              <ButtonMaterial
                className={'event'}
                variant="contained"
                color="primary"
                onClick={handleEditEvent(encodedOccurrence)}
                disabled={disabledForNonInternalAdmin}
              >
                EDIT EVENT
              </ButtonMaterial>
            </span>
          </Tooltip>
          <Tooltip
            disableHoverListener={!disabledForNonInternalAdmin}
            arrow
            title={
              <span className={classes.tooltip}>
                {setTitleTooltipButtonDisabled('Events with a status of Passed can no longer be canceled.')}
              </span>
            }
          >
            <span>
              <ButtonMaterial
                className={classes.button}
                variant="contained"
                onClick={() => showCancelEventPrompt(props.event)}
                disabled={disabledForNonInternalAdmin}
              >
                CANCEL EVENT
              </ButtonMaterial>
            </span>
          </Tooltip>
        </FeatureEnabled>
      </nav>
      <nav className="header_container_buttons">
        {isPublished && (
          <Tooltip
            disableHoverListener={!disabledForNonInternalAdmin}
            arrow
            title={
              <span className={classes.tooltip}>
                {setTitleTooltipButtonDisabled('Kiosk Mode is not available for events that have ended')}
              </span>
            }
          >
            <span>
              <div className={classes.start_kiosk_button}>
                <StartKioskButton opportunity={event} disabled={disabledForNonInternalAdmin} />
              </div>
            </span>
          </Tooltip>
        )}
        <div className={classes.status}>
          <div>
            <b>Status</b>
          </div>
          <EventStatus className={'label-wrap'} title={event.status} />
        </div>
      </nav>
    </section>
  )
}

export default withRouter(EventDetailsHeader)
