import {
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_USER_PASSWORD_SUCCESS,
  GET_CURRENT_USER_INFO_FAILURE,
  GET_CURRENT_USER_INFO_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  USER_LOADING
} from "../types"

const initialState = {
  info: {},
  isLoading: false,
  errors: {
    passwordError: null,
    updateError: null,
    getInfoError: null
  }
}

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          passwordError: null
        }
      }
    case CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          passwordError: action.payload.message
        }
      }
    case GET_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        info: action.payload.info,
        errors: {
          ...state.errors,
          getInfoError: null
        }
      }
    case GET_CURRENT_USER_INFO_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          getInfoError: action.payload.message
        }
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateError: null
        }
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          updateError: action.payload.message
        }
      }
    default:
      return state
  }
}
