import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import { CellProps } from 'react-table'
import { Tag } from 'new-mentorship/types'
import { DATE_RANGE, DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { EditHeader } from 'core/table/cells'
import { useTableColumnStyles as useStyles } from 'core/table/table-hooks'

import Tags from 'components/tag/Tags'
import { MentorshipMatchesEditCell } from '../components/MentorshipMatchesEditCell'
import { useGroups } from 'group/hooks/useGroups'
import find from 'lodash/find'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import useGetProgramFilterAutocomplete from 'new-mentorship/hooks/useGetProgramFilterAutocomplete'
import { PROGRAMS_FILTER, TAGS_FILTER } from 'new-mentorship/helpers/filters'
import useTagManagement from 'new-mentorship/hooks/useTagsManagement'

export function useMentorshipMatchesColumns({ editMatch }: any) {
  const classes = useStyles()
  const { groups } = useGroups(undefined, false)
  const [tags, setTags] = useState<Tag[]>([])
  const { mentorGroupId, menteeGroupId } = useMemo(
    () => ({
      mentorGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentor } })?.id,
      menteeGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentee } })?.id,
    }),
    [groups],
  )
  const { getOrgTags } = useTagManagement()

  useEffect(() => {
    getOrgTags().then(setTags)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getTagFilterAutocomplete = useCallback(() => {
    return tags.map(tag => tag.name)
  }, [tags])

  const getProgramFilterAutocomplete = useGetProgramFilterAutocomplete()

  return useMemo(
    () => [
      {
        Header: 'Program',
        accessor: 'programName',
        id: 'programName',
        filter: PROGRAMS_FILTER,
        getFilterAutocomplete: getProgramFilterAutocomplete,
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ 'program:anyOf': `["${value}"]` }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentor',
        accessor: 'mentorName',
        id: 'mentorName',
        filter: DYNAMIC_TEXT,
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ 'mentor:=': `["${value}"]` }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentee',
        accessor: 'menteeName',
        id: 'menteeName',
        filter: DYNAMIC_TEXT,
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ 'mentee:=': `["${value}"]` }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Matched Since',
        accessor: 'startedAt',
        id: 'startedAt',
        disableFilters: true,
        filter: DATE_RANGE,
        Cell: ({ cell: { value } }: CellProps<any>) => moment(value).format('MMMM D, YYYY'),
      },
      {
        Header: '# Meetings',
        accessor: 'meetingCount',
        id: 'meetingCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify({ 'mentee:=': `["${o.menteeName}"]`, 'mentor:=': `["${o.mentorName}"]` }),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },
      {
        Header: '# Moments',
        accessor: 'momentCount',
        id: 'momentCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify({ 'mentee:=': `["${o.menteeName}"]`, 'mentor:=': `["${o.mentorName}"]` }),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },

      {
        Header: 'Avg. Meeting Time',
        accessor: 'averageMeetingDuration',
        id: 'averageMeetingDuration',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value && value + ' min') || '-',
      },

      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags',
        getFilterAutocomplete: getTagFilterAutocomplete,
        filter: TAGS_FILTER,
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Tags tags={value.map(({ name: label, id, color }: Tag) => ({ label, id, color }))} />
        ),
      },
      {
        Header: <EditHeader hideDelete />,
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<any>) => (
          <MentorshipMatchesEditCell
            cell={cell}
            editMatch={editMatch}
            mentorGroupId={mentorGroupId}
            menteeGroupId={menteeGroupId}
          />
        ),
      },
    ],
    [getProgramFilterAutocomplete, getTagFilterAutocomplete, classes.link, editMatch, mentorGroupId, menteeGroupId],
  )
}

export default useMentorshipMatchesColumns
