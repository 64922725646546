import { useMemo, useCallback } from 'react'
import moment, { Moment } from 'moment-timezone'
import { useCurrentOrg } from '../../auth/hooks'
import { get } from 'lodash'

export const useFormatDates = () => {
  const organization = useCurrentOrg()

  const timeZone = useMemo(() => get(organization, 'timeZone', 'America/New_York'), [organization])

  const toFormatFullDate = useCallback(
    (time: Moment, date: string, dayStart: boolean = true) => {
      const momentDate = moment(date).tz(timeZone)
      if (momentDate.isValid()) {
        if (time.isValid()) {
          return momentDate
            .set({ hours: time.tz(timeZone).hours() })
            .set({ minutes: time.tz(timeZone).minutes() })
            .format()
        }
        return dayStart ? momentDate.startOf('day').format() : momentDate.endOf('day').format()
      }
      return null
    },
    [timeZone],
  )

  const toFormatFullCustomDate = useCallback(
    (time: Moment, date: string, dayStart: boolean = true) => {
      const momentDate = moment(date).tz(timeZone, true)
      if (momentDate.isValid()) {
        if (time.isValid()) {
          return momentDate
            .set({ hours: time.tz(timeZone).hours() })
            .set({ minutes: time.tz(timeZone).minutes() })
            .format()
        }
        return dayStart ? momentDate.startOf('day').format() : momentDate.endOf('day').format()
      }
      return null
    },
    [timeZone],
  )

  return useMemo(() => ({toFormatFullDate, toFormatFullCustomDate, timeZone }), [toFormatFullDate, toFormatFullCustomDate, timeZone])
}
