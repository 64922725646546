import PageHeader from 'App/components/PageHeader'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'
import { useGetEvent } from 'Event/hooks/useGetEvent'
import Loader from 'Event/components/loader'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { EventsDrilldownInfo } from 'Event/components/EventDrilldownInfo'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { OpportunityDrilldownActivities } from 'Event/components/OpportunityDrilldownActivities'
import { OfferDrilldownSignups } from '../components/OfferDrilldownSignups'

const OpportunityDrilldownComponent = ({
  event,
  encodedOccurrence,
  refresh,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  encodedOccurrence: string
  refresh?: () => void
}) => {
  const classes = useEventDrilldownStyles()
  const [scrollTop, setScrollTop] = React.useState(0)
  const handleScroll = useHandleScroll(setScrollTop)

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <EventsDrilldownInfo
        event={event}
        encodedOccurrence={encodedOccurrence}
        scrollTop={scrollTop}
        refresh={refresh}
      />
      <OpportunityDrilldownActivities event={event} scrollTop={scrollTop} />
    </div>
  )
}

const OfferDrilldownComponent = ({
  event,
  encodedOccurrence,
  refresh,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  encodedOccurrence: string
  refresh?: () => void
}) => {
  const classes = useEventDrilldownStyles()
  const [scrollTop, setScrollTop] = React.useState(0)
  const handleScroll = useHandleScroll(setScrollTop)

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <EventsDrilldownInfo
        event={event}
        encodedOccurrence={encodedOccurrence}
        scrollTop={scrollTop}
        refresh={refresh}
        type={'Offer'}
      />
      {event.trackSignups && <OfferDrilldownSignups event={event} scrollTop={scrollTop} />}
    </div>
  )
}

export const OpportunityDrilldown = (props: RouteComponentProps<{ encodedOccurrence: string }>) => {
  const [eventsPage] = useEventsPageState()
  let backLink: string
  switch (eventsPage) {
    case EventPages.List:
      backLink = '/list-of-events'
      break
    case EventPages.Locations:
      backLink = '/locations'
      break
    default:
      backLink = '/events'
  }
  const { encodedOccurrence } = props.match.params
  const { event, loading, refresh } = useGetEvent(encodedOccurrence)
  useEffect(() => {
    if (event) {
      if (event.isOffer) {
        if (!props.location.pathname.includes('offers')) {
          props.history.replace(props.location.pathname.replace('opportunities', 'offers'))
        }
      } else {
        if (!props.location.pathname.includes('opportunities')) {
          props.history.replace(props.location.pathname.replace('offers', 'opportunities'))
        }
      }
    }
  }, [event, props.history, props.location.pathname])
  if (loading) {
    return <Loader />
  }
  return (
    <PageHeader
      title={(event as OpportunityResponseWithRecurrenceInfo).name}
      subTitle="Back to Events"
      backLink={backLink}
      noPadding={true}
    >
      {event?.isOffer ? (
        <OfferDrilldownComponent
          event={event as OpportunityResponseWithRecurrenceInfo}
          encodedOccurrence={encodedOccurrence}
          refresh={refresh}
        />
      ) : (
        <OpportunityDrilldownComponent
          event={event as OpportunityResponseWithRecurrenceInfo}
          encodedOccurrence={encodedOccurrence}
          refresh={refresh}
        />
      )}
    </PageHeader>
  )
}

export default OpportunityDrilldown
