import { isNil, omitBy } from 'lodash'
import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'

export const useFetchVolunteerCount = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<{ count: number }>()
  const fetchVolunteerCount = useCallback(
    async (
      params: {
        showInactive?: boolean
        startDate?: string
        endDate?: string
      } = {},
    ) =>
      request({
        url: `/organizations/${organization.id}/volunteer-count`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, organization.id],
  )

  return { fetchVolunteerCount, loading, error }
}
