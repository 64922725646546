import { useCallback } from 'react'

import { Status, useFetchRefactored } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'

import { useCurrentOrg } from 'auth/hooks'
import { BulkAddActivitiesPayload } from '../types'
import { Activity } from 'champion/utils/interface'

export const useBulkAddActivities = (): [
  (activities: BulkAddActivitiesPayload | BulkAddActivitiesPayload[]) => Promise<Activity[]>,
  Status<Activity[]>,
] => {
  const organization = useCurrentOrg()

  const [request, status] = useFetchRefactored<Activity[]>({
    useNewNotification: true,
  })

  const addActivity = useCallback(
    (activities: BulkAddActivitiesPayload | BulkAddActivitiesPayload[]) => {
      let volunteerCount = 0
      let name = ''

      if (!Array.isArray(activities)) {
        activities = [activities]
      }

      const body = activities.map(({ volunteers, ...activity }) => {
        if (name === '') {
          name = format.name(volunteers[0])
        }
        volunteerCount += volunteers.length
        return { ...activity, personIds: volunteers.map(({ id }) => id) }
      })

      const commonName = `${name} and ${volunteerCount - 1} others`

      return request({
        method: 'post',
        url: `/organizations/${organization.id}/bulk_activities`,
        config: {
          body,
        },
        successMessage: `An activity was added for ${commonName}`,
        errorMessage: `An activity was not added for ${commonName}`,
      })
    },
    [organization, request],
  )

  return [addActivity, status]
}

export default useBulkAddActivities
