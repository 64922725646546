import { useCallback, useState } from 'react'
import map from 'lodash/map'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import {
  AddChatMembersWithChatPrompt,
  AddChatPrompt,
  SelectChatActionPrompt,
  useAddChat,
  useAddChatMembers,
} from 'chats'
import * as H from 'history'
import { Row } from 'react-table'

export const useHandleChat = ({
  selectedRows,
  history,
}: {
  selectedRows: Row<any>[]
  history: H.History
}): [() => Promise<void>, boolean] => {
  const showChatPrompt = useShowPrompt(SelectChatActionPrompt)
  const showAddChatPrompt = useShowPrompt(AddChatPrompt)
  const showAddChatMembersPrompt = useShowPrompt(AddChatMembersWithChatPrompt)
  const [addChat] = useAddChat()
  const [addChatMembers] = useAddChatMembers()
  const [processing, setProcessing] = useState<boolean>(false)
  return [
    useCallback(async () => {
      const { isNew } = await showChatPrompt()
      let chatId, members
      if (isNew) {
        const {
          members: chatMembers,
          name,
          description,
        } = await showAddChatPrompt({
          initialMembers: map(selectedRows, 'original.user'),
        })
        const chat = await addChat({ name, description })
        chatId = chat.id
        members = chatMembers
      } else {
        const result = await showAddChatMembersPrompt({ initialMembers: map(selectedRows, 'original.user') })
        chatId = result.chatId
        members = result.members
      }
      setProcessing(true)
      try {
        await addChatMembers({ chatId, uuids: map(members, 'pubnubId') })
        history.push(`/chats/${chatId}`)
      } catch (e) {
        setProcessing(false)
      }
    }, [addChat, addChatMembers, history, selectedRows, showAddChatMembersPrompt, showAddChatPrompt, showChatPrompt]),
    processing,
  ]
}
