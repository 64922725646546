import React, { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useRegistrants } from 'volunteering/volunteers/hooks'
import Loading from 'components/Loading'
import { DayNavigationBar } from '../components/DayNavigationBar'
import PageHeader from 'App/components/PageHeader'
import { useGetSearchableContactsFromVolunteersArray } from 'messages/hooks/useGetSearchableContactsFromVolunteersArray'
import { RegistrantsTable } from 'registrant/components/RegistrantsTable'
import { makeStyles } from '@material-ui/core/styles'
import { useTimeZone } from 'civic-champs-shared/auth/hooks'
import dayjs from 'utils/dayjs'

const tableName = 'registrants'

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
})

export const Registrants = (props: RouteComponentProps) => (
  <PageHeader title="Registrations" subTitle="Review and manage daily volunteer registrations">
    <RegistrantsComponent {...props} />
  </PageHeader>
)

export const RegistrantsComponent = ({ location, history }: RouteComponentProps) => {
  const [date, setDate] = useState(new Date())

  const [{ registrants, loading, initiallyLoaded, reload }] = useRegistrants({ date } as any) as any

  const getSearchableContacts = useGetSearchableContactsFromVolunteersArray(registrants)

  const timeZone = useTimeZone()
  const dateAlias = useMemo(() => {
    const today = dayjs().tz(timeZone)
    const tomorrow = today.add(1, 'day')
    const yesterday = today.subtract(1, 'day')
    const selectedDate = dayjs(date).tz(timeZone)
    if (selectedDate.isSame(today, 'day')) {
      return `Today`
    } else if (selectedDate.isSame(tomorrow, 'day')) {
      return `Tomorrow`
    } else if (selectedDate.isSame(yesterday, 'day')) {
      return `Yesterday`
    }
  }, [date, timeZone])

  const autocompleteSectionTitle = useMemo(() => {
    return `${dateAlias || dayjs(date).format('MM/DD/YYYY')}'s Registrants`
  }, [date, dateAlias])

  const classes = useStyles()

  return (
    <div className={classes.contaier}>
      <div>
        <DayNavigationBar date={date} setDate={setDate} dateAlias={dateAlias} />
      </div>
      {loading && (
        <div>
          <Loading />
        </div>
      )}
      <div style={{ display: loading ? 'none' : 'block' }}>
        <RegistrantsTable
          registrants={registrants}
          initiallyLoaded={initiallyLoaded}
          tableName={tableName}
          getSearchableContacts={getSearchableContacts}
          autocompleteSectionTitle={autocompleteSectionTitle}
          reloadRegistrants={reload}
        />
      </div>
    </div>
  )
}
