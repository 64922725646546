import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { Survey } from '../types'

export const useCreateSurvey = (): [(body: any) => Promise<Survey>, Status<Survey>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Survey>()

  const createSurvey = useCallback(
    body => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/surveys`,
        config: {
          body,
        },
      })
    },
    [organization, request],
  )

  return [createSurvey, status]
}

export default useCreateSurvey
