import { viewTypes } from '../../scenes/events/viewTypes'

export enum ACTION_TYPES {
  SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'
}

const { SET_CURRENT_VIEW } = ACTION_TYPES

export const defaultState = {
  currentViewType: viewTypes.WEEK,
};

export default function config(state = defaultState, action: any) {
  switch (action.type) {
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentViewType: action.payload.currentViewType,
      };

    default:
      return state;
  }
}

const statePath = 'ViewEvents'

const stateSelector = (state: any) => state[statePath]

export const currentViewTypeSelector = (state: any) => {
  return stateSelector(state).currentViewType
}

export const changeCurrentViewType = (currentViewType: string) => ({
  type: SET_CURRENT_VIEW,
  payload: {
    currentViewType,
  },
})
