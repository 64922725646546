import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { RowsReorderModal } from 'core/table/components/RowsReorderModal'
import useGetSortOrder from 'core/table/table-hooks/useGetSortOrder'
import useReorderLocations from 'locations/hooks/useReorderLocations'
import { OrganizationLocationReporting } from 'locations/types'
import useReorderOpportunityLocations from './useReorderOpportunityLocations'
import { useFeatureEnabled } from '../../core/feature/hooks'

const filter = (row: OrganizationLocationReporting) => !!row.order

export const useReorderLocationsPrompt = (getLocations: () => void) => {
  const showModal = useShowPrompt(RowsReorderModal)
  const reorderLocations = useReorderLocations(getLocations)
  const reorderOpportunityLocations = useReorderOpportunityLocations(getLocations)
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const organization = useCurrentOrg()
  const [fetchSortOrder] = useGetSortOrder()

  return useCallback(
    async table => {
      const sortOrder = await fetchSortOrder('locations')

      const reordered = await showModal({
        table,
        filter,
        title: 'Adjust Locations Order',
        description: `Drag locations or choose other sorting options to adjust the order they will appear throughout ${organization.name}`,
        sortOptions: [
          { name: 'Custom', field: 'order', order: 'asc', isCustom: true },
          { name: 'A-Z', field: 'name', order: 'asc' },
          { name: 'Most Frequently Used', field: 'timesUsedActive', order: 'desc' },
        ],
        sortOrder,
      })

      if (reordered) {
        const { rows, sortOption } = reordered
        if (offersEnabled) {
          await reorderOpportunityLocations({ rows, sortOption })
        } else {
          await reorderLocations({ rows, sortOption })
        }
      }
    },
    [fetchSortOrder, offersEnabled, organization.name, reorderLocations, reorderOpportunityLocations, showModal],
  )
}

export default useReorderLocationsPrompt
