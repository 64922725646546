import React from 'react'
import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'

const BasicInfoStep = (props) => {
  const { goToNextStep, isTest, setFieldValue, isTester } = props
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <Field
          component={TextField}
          name='name'
          fullWidth
          label='Opportunity Name*'
          variant='outlined'
        />
      </Grid>
      <Grid item>
        <Field
          component={TextField}
          name='description'
          fullWidth
          label='Description'
          variant='outlined'
        />
      </Grid>
      {isTester && (
        <Grid item>
          <FormControlLabel
            control={<Switch name="isTest" checked={isTest} onChange={() => setFieldValue('isTest', !isTest)}/>}
            label="Test Opportunity?"
          />
        </Grid>
      )}
      <Grid item>
        <Button
          variant='contained' 
          color='secondary' 
          onClick={goToNextStep}
        >
          Next step
        </Button>
      </Grid>
    </Grid>
  )
}

export default BasicInfoStep
