import _ from 'lodash';
import moment from 'moment';
import React, { forwardRef, useMemo } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { useMutation } from 'civic-champs-shared/api/hooks'
import { DELETE_ACTIVITY } from '../queries';
import { getVolunteerName, getOpportunityName } from '../utils'

const useStyles = makeStyles( theme => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    backButton: {
        color: 'grey'
    },
    submitButton: {
        background: '#FF6F3E',
        color: 'white'
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function createActivityDescription(activity) {
    if(!activity) return '';

    let text = []

    const name = getVolunteerName(activity);
    if( !_.isEmpty(name) ) text.push(`by ${name}`)

    let opp = getOpportunityName(activity);
    if( !_.isEmpty(opp) ) text.push(`at '${opp}'`);

    let datetime = _.get(activity, 'occurredAt')
    if( !_.isEmpty(datetime) ) {
        let date = moment(datetime).format('MM/DD/YYYY');
        text.push(`on ${date}`)
    }

    return text.join(' ');
}

export default function DeleteActivityDialog(props) {
    const { open, onClose, activity } = props;

    const activityDesc = useMemo( () => createActivityDescription(activity), [activity]);

    const classes = useStyles();
    const [deleteActivity, { loading, error }] = useMutation(DELETE_ACTIVITY);

    return (
      <Dialog open={open} onClose={onClose} disableBackdropClick={loading} TransitionComponent={Transition}>
        <DialogTitle>Delete Activity?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the activity {activityDesc}?
            {error && (
              <Typography display="block" color="error">
                {error}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.backButton} onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            className={classes.submitButton}
            disabled={loading}
            onClick={() =>
              deleteActivity({
                variables: { activity },
                onSuccess: onClose,
              })
            }
          >
            {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
            Delete Activity
          </Button>
        </DialogActions>
      </Dialog>
    )
}
