import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { ProgramMatch } from 'new-mentorship/types'

export const useFetchMatches = (): [() => Promise<ProgramMatch[]>, Status<ProgramMatch[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<ProgramMatch[]>({
    errorMessage: 'Error Fetching Matches.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchMatches = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/mentorship-matches`,
      config: {
        queryStringParameters: {
          includeStats: 1,
          archived: 'false',
        },
      },
    })
  }, [organization, request])

  return [fetchMatches, status]
}

export default useFetchMatches
