import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import Input from '@material-ui/core/Input'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

const getMinutes = value => Math.max(0, moment.duration(value).get('minutes'))
const getHours = value => Math.max(0, Math.floor(moment.duration(value).as('hours')))

const useStyles = makeStyles(() => ({
  icon: {
    color: '#0F5DB5',
  },
  timeField: {
    fontWeight: '500',
    color: '#000',
  },
  timeStyle: {
    color: '#0F5DB5',
    fontSize: '14px',
    textAlign: 'center',
    width: '25px',
    '& input': {
      textAlign: 'center',
      padding: 0,
    },
  },
}))

export default function DurationEditField(props) {
  const { value, onChange, disabled } = props
  const classes = useStyles()

  const [editable, setEditable] = useState(false)
  const [hours, minutes] = useMemo(() => [getHours(value), getMinutes(value)], [value])

  useEffect(() => {
    if (onChange) {
      let newValue = moment.duration({ hours, minutes })
      if (newValue.valueOf() !== value.valueOf()) {
        onChange(newValue)
      }
    }
  }, [minutes, hours, onChange, value])

  const handleChange = name => e => onChange(moment.duration({ hours, minutes, [name]: e.target.value }))

  return (
    <>
      Activity length:{' '}
      <span className={classes.timeField}>
        {editable ? (
          <Input
            name="hours"
            value={hours}
            onChange={handleChange('hours')}
            disabled={disabled}
            className={classes.timeStyle}
            placeholder={'0'}
          />
        ) : (
          hours
        )}
        {' hours '}
        {editable ? (
          <Input
            name="minutes"
            value={minutes}
            onChange={handleChange('minutes')}
            disabled={disabled}
            className={classes.timeStyle}
            placeholder={'0'}
          />
        ) : (
          minutes
        )}
        {' minutes '}
      </span>
      <Tooltip title="Edit">
        <IconButton
          className={classes.iconButton}
          onClick={e => {
            e.stopPropagation()
            setEditable(!editable)
          }}
        >
          <EditIcon className={classes.icon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}
