import React, { Dispatch } from 'react'
import { Activity } from 'champion/utils/interface'
import cn from 'classnames'
import { SmileyIcon } from 'tracking/activity/components'
import { Dayjs } from 'dayjs'
import { useReflectionCalendarTableStyles } from 'champion/hooks/useReflectionCalendarTableStyles'

const headers = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const ReflectionCalendarTable = ({
  data,
  selectedDate,
  setSelectedDate,
  today,
}: {
  data: { day: Dayjs; activities: Activity[] }[][]
  selectedDate: Dayjs
  setSelectedDate: Dispatch<Dayjs>
  today: Dayjs
}) => {
  const classes = useReflectionCalendarTableStyles()

  return (
    <div className={classes.table}>
      <div className={cn(classes.row, classes.header)}>
        {headers.map((header, key) => (
          <div className={classes.cell} key={key}>
            {header}
          </div>
        ))}
      </div>
      {data.map((week, key) => (
        <div className={classes.row} key={key}>
          {week.map(({ day, activities }) => (
            <div
              className={cn(classes.cell, { [classes.selected]: day.isSame(selectedDate, 'day') })}
              onClick={() => setSelectedDate(day)}
              key={day.unix()}
            >
              <div
                className={cn(classes.date, {
                  [classes.today]: day.isSame(today, 'day'),
                  [classes.selected]: day.isSame(selectedDate, 'day'),
                })}
              >
                {day.format('D')}
              </div>
              {activities.length > 0 && (
                <div className={classes.smiley}>
                  {
                    <div className={classes.relative}>
                      <SmileyIcon
                        height={36}
                        width={36}
                        rating={activities[0].volunteerSatisfaction as number | null}
                      />
                      {activities.length > 1 && <div className={classes.counter}>{activities.length}</div>}
                    </div>
                  }
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
