import useFetchMeeting from 'new-mentorship/hooks/useFetchMeeting'
import React, { useEffect, useMemo } from 'react'
import Loading from 'civic-champs-shared/core/Loading'
import { Link } from 'react-router-dom'
import LabelType from 'new-mentorship/components/type-label'
import moment from 'moment-timezone'
import RatingIcon from '../components/rating-icon'
import { Answers } from 'civic-champs-shared/question-sets/components'
import { find, isUndefined } from 'lodash'
import { MentoringFeedbackType, ProgramMeetingType } from 'new-mentorship/types'
import useStyles from 'new-mentorship/hooks/useMeetingOverviewStyles'

export function MentorMeetingOverview({
  match: {
    params: { id },
  },
}: {
  match: {
    params: {
      id: string
    }
  }
}) {
  const [fetchMeeting, { loading, result }] = useFetchMeeting()
  useEffect(() => {
    fetchMeeting(parseInt(id))
  }, [fetchMeeting, id])

  const meeting = useMemo(() => {
    const data = result || {}
    const mentorFeedback = find(data.feedback, { type: MentoringFeedbackType.mentor })
    const menteeFeedback = find(data.feedback, { type: MentoringFeedbackType.mentee })
    return { ...data, mentorFeedback, menteeFeedback }
  }, [result])

  const classes = useStyles()

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Link to="/mentorship/meetings" className={classes.back}>
          <span className={classes.arrowBack} />
          Back to Meetings
        </Link>
        <div className={classes.header}>
          <div className={classes.headerBlock}>
            <div className={classes.headerTitle}>{meeting.title}</div>
            <LabelType type={meeting.type} />
          </div>
        </div>
        <div className={classes.info}>
          {meeting.type === ProgramMeetingType.meeting && (
            <>
              <div className={classes.infoDetails}>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsTitle}>When</div>
                  <div className={classes.detailsName}>
                    {moment(meeting.startsAt)
                      .tz(meeting.match?.meetingsTimezone || 'UTC')
                      .format('MMMM Do, YYYY')}
                    &nbsp;from&nbsp;
                    {moment(meeting.startsAt)
                      .tz(meeting.match?.meetingsTimezone || 'UTC')
                      .format('h:mm a')}
                    &nbsp;to&nbsp;
                    {moment(meeting.endsAt)
                      .tz(meeting.match?.meetingsTimezone || 'UTC')
                      .format('h:mm a')}
                  </div>
                </div>
              </div>
              <div className={classes.infoDetails}>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsTitle}>Where</div>
                  <div className={classes.detailsName}>{meeting.location}</div>
                </div>
              </div>
              <div className={classes.infoDetails}>
                <div className={classes.detailsItem}>
                  <div className={classes.detailsTitle}>Duration</div>
                  <div className={classes.detailsName}>
                    {moment(meeting.endsAt).diff(moment(meeting.startsAt), 'minutes')} minutes
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={classes.infoDetails}>
            <div className={classes.detailsItem}>
              <div className={classes.detailsTitle}>Mentor</div>
              <div className={classes.detailsName}>
                {meeting.match?.mentor?.givenName} {meeting.match?.mentor?.familyName}
              </div>
            </div>
            {!isUndefined(meeting.mentorFeedback?.rating) && <RatingIcon icon={meeting.mentorFeedback?.rating} />}
          </div>
          <div className={classes.infoDetails}>
            <div className={classes.detailsItem}>
              <div className={classes.detailsTitle}>Mentee</div>
              <div className={classes.detailsName}>
                {meeting.match?.mentee?.givenName} {meeting.match?.mentee?.familyName}
              </div>
            </div>
            {!isUndefined(meeting.menteeFeedback?.rating) && <RatingIcon icon={meeting.menteeFeedback?.rating} />}
          </div>
        </div>
        {(meeting.mentorFeedback?.answersSet || meeting.menteeFeedback?.answersSet) && (
          <>
            <div className={classes.surveyTitle}>Surveys</div>
            <div className={classes.surveys}>
              <div className={classes.surveyItem}>
                <div className={classes.surveyItemBlock}>
                  <div className={classes.surveyItemTitle}>Mentor</div>
                  <div className={classes.surveyItemName}>
                    {meeting.match?.mentor?.givenName} {meeting.match?.mentor?.familyName}
                  </div>
                </div>
                <div className={classes.surveyItemBlock}>
                  {meeting.mentorFeedback?.answersSet && (
                    <Answers answers={meeting.mentorFeedback?.answersSet.questions} />
                  )}
                </div>
              </div>

              <div className={classes.surveyItem}>
                <div className={classes.surveyItemBlock}>
                  <div className={classes.surveyItemTitle}>Mentee</div>
                  <div className={classes.surveyItemName}>
                    {meeting.match?.mentee?.givenName} {meeting.match?.mentee?.familyName}
                  </div>
                </div>
                <div className={classes.surveyItemBlock}>
                  {meeting.menteeFeedback?.answersSet && (
                    <Answers answers={meeting.menteeFeedback?.answersSet.questions} />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
