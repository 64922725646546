import intersection from 'lodash/intersection'
import difference from 'lodash/difference'

const prepare = (input: string): string[] => input.split(',').map(str => str.trim())

const anyOf = (rowValue: string, operand: string[]): boolean => !!intersection(prepare(rowValue), operand).length
const allOf = (rowValue: string, operand: string[]): boolean => !difference(operand, prepare(rowValue)).length
const notAnyOf = (rowValue: string, operand: string[]): boolean => !intersection(operand, prepare(rowValue)).length
const notAllOf = (rowValue: string, operand: string[]): boolean =>
  intersection(operand, prepare(rowValue)).length !== operand.length

const operatorToMethodMap = {
  anyOf,
  allOf,
  notAllOf,
  notAnyOf,
}

export const groupsFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}
