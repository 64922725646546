import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { WebhookEventResponse } from 'integrations/types'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useGetWebhookEventResponses = (): [
  (webhookId: number) => Promise<WebhookEventResponse[]>,
  Status<WebhookEventResponse[]>,
] => {
  const [request, status] = useFetchRefactored<WebhookEventResponse[]>({
    errorMessage: 'Error Fetching Webhooks.  Please refresh the page and try again',
    emptyValue: [],
  })
  const organization = useCurrentOrg()

  const fetchWebhookEventResponses = useCallback(
    webhookId => {
      return request({
        method: 'get',
        url: `/integrations/v1/organizations/${organization.id}/stats/webhooks/${webhookId}/event-responses`,
      })
    },
    [organization.id, request],
  )

  return [fetchWebhookEventResponses, status]
}

export default useGetWebhookEventResponses
