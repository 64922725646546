import { RouteComponentProps } from 'react-router'
import PageHeader from 'App/components/PageHeader'
import React from 'react'
import Loading from 'civic-champs-shared/core/Loading'
import { useStyles, useWaiverColumns } from 'credential/hooks/useWaiverColumns'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import { ExtendedPagedTable } from 'core/table/components'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import AddIcon from '@material-ui/icons/Add'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import ExportWaiversButton from 'credential/components/ExportWaiversButton'
import { CredentialFull } from 'civic-champs-shared/question-sets/types'
import { Grid } from '@material-ui/core'
import { useOrganizationCredentialCollection } from '../hooks/useOrganizationCredentialCollection'

const tableName = 'waivers'
const WaiversComponent = (props: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ credentials, loading, initiallyLoaded }, { createCredential, updateCredential }, eventListeners] =
    useOrganizationCredentialCollection()
  const columns = useWaiverColumns(updateCredential)
  const classes = useStyles()
  const [, { loading: columnStateLoading, result: columnState }] = useGetColumnState(tableName)

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['lastModified'],
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: credentials,
      columns,
      autoResetPage: !skipReset.current,
      autoResetSortBy: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, classes.selectColumn),
  )

  if (loading || columnStateLoading || !initiallyLoaded) return <Loading />
  return (
    <Grid container>
      <ExtendedPagedTable<CredentialFull>
        history={props.history}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Waivers"
        buttons={
          <>
            <ExportWaiversButton data={table.selectedFlatRows} disabled={!table.selectedFlatRows.length} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={createCredential}>
            Add Waiver
          </ContainedButton>
        }
        useGlobalSearch
        skipReset={skipReset}
        eventListeners={eventListeners}
      />
    </Grid>
  )
}
export const Waivers = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Waivers" subTitle="View, add, and edit volunteer waivers">
      <WaiversComponent {...props} />
    </PageHeader>
  )
}

export default Waivers
