import { useCallback } from 'react'
import { Status, useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { Mappings } from 'tracking/activity/components/ActivityImportDialog'

export interface ActivityImportConfig {
  file: string
  mappings: Mappings
  volunteeringRoleId?: number
  dateFormat: string
  timeZone: string
}

export const useImportActivities = (): [
  (config: { orgId: number; importConfig: ActivityImportConfig }) => Promise<void>,
  Status<void>,
] => {
  const [request, status] = useFetchRefactored<void>({
    successMessage: 'Activities import started successfully',
    errorMessage: 'Activities import failed to start',
    useNewNotification: true,
  })

  const importActivities = useCallback(
    ({ orgId, importConfig }: { orgId: number; importConfig: ActivityImportConfig }) => {
      return request({
        method: 'post',
        url: `/organizations/${orgId}/activity_import`,
        config: {
          body: importConfig,
        },
      })
    },
    [request],
  )

  return [importActivities, status]
}

export default useImportActivities
