import orderBy from 'lodash/orderBy'
import Typography from '@material-ui/core/Typography'
import { FormControl, InputLabel, MenuItem, Modal, Select } from '@material-ui/core'
import React, { useCallback, useMemo, useState } from 'react'
import { TableInstance } from 'react-table'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import cn from 'classnames'

import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import useFiltersModalStyles from 'core/table/table-hooks/useFilterModalStyles'
import { muiTheme } from 'theme'

const useStyles = makeStyles(theme => ({
  modalBody: {
    padding: '20px',
    background: '#F8FAFF',
    borderRadius: '4px',
    border: '0px',
    position: 'fixed',
    width: '384px',
    left: 'calc(50% - 187px)',
    top: 'calc(50% - 265px)',
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#74777F',
  },
  subtitle: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#74777F',
    paddingBottom: '12px',
    marginTop: '4px',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  checkbox: {
    // @ts-ignore
    color: theme.palette.primary.main,
    fontSize: '18px',
  },
  checkboxRoot: {
    padding: '6px 8px',
  },
  item: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'grab',
  },
  subItem: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  orderColumn: {
    minWidth: '30px',
    paddingRight: '5px',
  },
  icon: {
    fontSize: '20px',
  },
  dropContainer: {
    display: 'block',
    width: '100%',
    position: 'relative',
    marginBottom: '16px',
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  disabled: {
    opacity: '0.5',
    cursor: 'no-drag',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  sortOptions: {
    margin: '10px 0 10px',
  },
}))

const getItemStyle = (isDraggingOver: boolean, style: any) => ({
  ...style,
  background: isDraggingOver ? 'lightblue' : 'none',
})

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
interface SortOption {
  name: string
  field: string
  order: 'asc' | 'desc'
  default?: boolean
  isCustom?: boolean
}
interface Props {
  showing: boolean
  complete: (args?: { rows: Array<any>; sortOption: SortOption }) => {}
  table: TableInstance<any>
  title: string
  description: string
  sortOptions: SortOption[]
  sortOrder?: string
  filter?: (row: any) => boolean
}

const defaultSortOptions: SortOption[] = [
  { name: 'Custom', field: 'order', order: 'asc', default: true, isCustom: true },
  { name: 'A-Z', field: 'name', order: 'asc' },
]

const optionValue = (option: SortOption) => `"${option.field}" ${option.order}`

export const RowsReorderModalComponent = (props: Props) => {
  const {
    showing,
    complete,
    table,
    title,
    description,
    sortOptions = defaultSortOptions,
    sortOrder,
    filter = () => true,
  } = props
  const classes = useStyles()

  const currentRowsState = useMemo(
    () => table.rows.map(({ original }: any) => original).filter(filter),
    [filter, table.rows],
  )

  const filtersClasses = useFiltersModalStyles()

  const [rows, setRows] = useState(currentRowsState)

  const getSortOptionByValue = useCallback(
    (value?: string) =>
      sortOptions.find((opt: any) => (value ? value === optionValue(opt) : opt.default)) || sortOptions[0],
    [sortOptions],
  )

  const defaultSortOption = getSortOptionByValue(sortOrder)
  const [sortOption, setSortOption] = useState<SortOption>(defaultSortOption)

  const onDragEnd = useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      setRows(reorder(rows, result.source.index, result.destination.index))
    },
    [rows],
  )

  const onSortChanged = useCallback(
    ({ value }: any) => {
      const newSortOption = getSortOptionByValue(value)
      setSortOption(newSortOption)
      const { field, order } = newSortOption
      setRows(orderBy(rows, field, order))
    },
    [getSortOptionByValue, rows],
  )

  const isDragDisabled = !sortOption.isCustom

  return (
    <Modal open={showing}>
      <div className={classes.modalBody}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{description}</Typography>
        <div className={classes.sortOptions}>
          <FormControl>
            <InputLabel className={filtersClasses.label}>Sort by</InputLabel>
            <Select
              classes={{ outlined: filtersClasses.outlined }}
              variant="outlined"
              name="sortOptions"
              value={optionValue(sortOption)}
              onChange={e => {
                onSortChanged(e.target)
              }}
            >
              {sortOptions.map((option: SortOption, index: number) => (
                <MenuItem key={index} value={optionValue(option)}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className={cn(classes.dropContainer, isDragDisabled && classes.disabled)}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {rows.map((row: any, index: number) => (
                  <Draggable key={row.id} isDragDisabled={isDragDisabled} draggableId={String(row.id)} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          key={row.id}
                          className={classes.item}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div className={classes.subItem}>
                            <Typography className={classes.orderColumn}>{index + 1}</Typography>
                            <Typography>{row.name}</Typography>
                          </div>
                          <DragHandleIcon className={classes.icon} />
                        </div>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={classes.buttons}>
          <div>
            <OutlinedButton borderless onClick={() => complete()}>
              Cancel
            </OutlinedButton>
            <OutlinedButton borderless onClick={() => complete({ rows, sortOption })}>
              Save
            </OutlinedButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const RowsReorderModal = (props: any) => (
  <ThemeProvider theme={muiTheme}>
    <RowsReorderModalComponent {...props} />
  </ThemeProvider>
)
