import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import useChatPromptStyles from 'chats/hooks/useChatPromptStyles'
import cn from 'classnames'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

interface SelectActionPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const useStyles = makeStyles({
  dialog: { width: '380px' },
  button: {
    width: '340px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    border: '1px solid #74777F',
    borderRadius: '4px',
    padding: '0 19px 0 16px',
    height: '56px',
    lineHeight: '56px',
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.5px',
    color: '#191C1D',
    alignItems: 'center',
  },
})

export const SelectChatActionPrompt = (props: SelectActionPromptProps) => {
  const { showing, close, complete } = props
  const classes = useChatPromptStyles()
  const styles = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#add-chat-prompt` }}
      aria-labelledby="add-chat-dialog-title"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, styles.dialog) }}
      disableEnforceFocus
    >
      <DialogTitle classes={{ root: cn(classes.title, styles.title) }} disableTypography={true} id="add-chat-prompt">
        Add Members
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <button className={styles.button} onClick={() => complete({ isNew: true })}>
          Create New Chat <NavigateNextIcon />
        </button>
        <button className={styles.button} onClick={() => complete({ isNew: false })}>
          Add Members to Existing Chat <NavigateNextIcon />
        </button>
      </DialogContent>
    </Dialog>
  )
}

export default (props: SelectActionPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <SelectChatActionPrompt {...props} />
  </ThemeProvider>
)
