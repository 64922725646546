import { connect } from 'react-redux';

import { kioskStatus, lastError } from '../../selectors';
import { stopKiosk, clearStopKioskFailure } from '../../actions';
import StopKioskFooter from './StopKioskFooter';

const mapStateToProps = state => ({
    kioskStatus: kioskStatus(state),
    error: lastError(state)
});

const mapDispatchToProps = dispatch => ({
    stopKiosk: (credentials) => dispatch( stopKiosk(credentials) ),
    abortKiosk: () => dispatch( clearStopKioskFailure() )
});

export default connect(mapStateToProps, mapDispatchToProps)(StopKioskFooter);