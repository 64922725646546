import React from 'react'
import { FormHelperText } from '@material-ui/core'

import TaskRecipientPicker from '../components/TaskRecipientPicker'

export default function TaskRecipientField({ field, form, ...props }) {
    const error = form.errors[field.name]
    
    return (
        <div>
            <TaskRecipientPicker
                {...props}
                name={field.name}
                value={field.value}
            />
            {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </div>
    )
}