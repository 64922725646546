import * as _ from 'lodash'
import {
  CreateEventFields,
  EditEventFields,
  OpportunityOccurrencePersonGroupPayloadLessOccurrenceId,
  TimeshiftsBase,
} from '../../../interfaces/interfaceCreateEditEvent'
import { OpportunityVisibility } from '../../../interfaces'
import moment from 'moment-timezone'

import format from 'civic-champs-shared/utils/format'

export const mapGroupsPayload = (
  event: CreateEventFields | EditEventFields,
): OpportunityOccurrencePersonGroupPayloadLessOccurrenceId[] => {
  const visibilityGroups =
    event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY ? event.visibilityGroups || [] : []
  const onboardingGroups =
    event.visibility !== OpportunityVisibility.SELECT_GROUPS_ONLY ? event.onboardingGroups || [] : []
  return [...visibilityGroups, ...onboardingGroups].filter(group => !group.lockedTag)
}

export const filterTimeShiftsForEmptyRoles = (timeshifts?: TimeshiftsBase[]) => {
  if (!Array.isArray(timeshifts)) return timeshifts
  return timeshifts.map(timeshift => {
    const roles = timeshift.roles?.filter(role => role.name !== '')
    return {
      ...timeshift,
      roles,
    }
  })
}
export const mapEventPayload = (event: CreateEventFields | EditEventFields, timeZone: string) => {
  const groups = mapGroupsPayload(event)
  const timeshifts = filterTimeShiftsForEmptyRoles(event.timeshifts)

  const eventPayload = _.cloneDeep({
    ...event,
    ...mapStartEndDates(event, timeZone),
    groups,
    timeshifts,
  })
  if (eventPayload?.geofencing) {
    const { lng, lat } = eventPayload.geofencing.location.coordinates
    _.set(eventPayload, 'geofencing.location.coordinates', [lng, lat])
  }
  return eventPayload
}

export const mapStartEndDates = (
  event: CreateEventFields | EditEventFields,
  timeZone: string,
): { startsAt: string; endsAt: string; dates: string[] } => {
  const { startsAt, endsAt, dates, timeshifts } = event
  const minTime = moment.min((timeshifts as any[]).map(item => moment(item.time_start, 'h:mmA')))
  const maxTime = moment.max((timeshifts as any[]).map(item => moment(item.time_end, 'h:mmA')))
  return {
    startsAt: format.datetimeInTimezone(startsAt.substring(0, 10), minTime, timeZone),
    endsAt: format.datetimeInTimezone((endsAt || startsAt).substring(0, 10), maxTime, timeZone),
    dates: dates.map(date => format.datetimeInTimezone(usDateToISO(date), minTime, timeZone)),
  }
}

const usDateToISO = (date: string): string => {
  const [month, day, year] = date.split('/')
  return `${year}-${month}-${day}`
}
