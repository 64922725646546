import { useEffect } from 'react'
import { useLatest } from 'react-use'

export const usePolling = (callback, delay) => {
  const savedCallback = useLatest(callback)
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
