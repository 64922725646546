import React, {useEffect, useMemo} from 'react';

import PagedTable from 'core/table/components/PagedTable'
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable, useRowSelect} from "react-table";
import useUserCredentialIDColumns from "../hooks/useUserCredentialIDColumns";
import useProcessUserCredentialIDs from "../hooks/useProcessUserCredentialIDs";
import {Button, Grid} from "@material-ui/core";
import {size} from "lodash";
import useSelectColumn from "../../core/table/table-hooks/useSelectColumn";
import matchSorter from "match-sorter";
import DEFAULT_FILTERS from 'core/table/filters'


const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.id', 'original.user.givenName', 'original.user.familyName', 'original.status.displayName', 'original.user.email'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

const UserCredentialIDs = props => {
  const {loading: approvalLoading, processUserCredentials: handleShowApprovals} = useProcessUserCredentialIDs(props.refresh)
  const columns = useUserCredentialIDColumns(handleShowApprovals, props.credentialType)

  const table = useTable(
    {
      data: props.userCredentialIds,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn
  )

  useEffect(() => {
    table.setGlobalFilter(props.query)
  }, [props.query, table])

  return (

    <Grid container>
      <Grid container item sm={12} direction="column" spacing={3}>
        <Grid>
          <PagedTable {...table} />
        </Grid>
      </Grid>
    </Grid>


  );
};

export default UserCredentialIDs;