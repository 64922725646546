import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export default function useRefundDonation(onResultUpdate) {
  const showSuccess = useSuccessNotification()
  const [request, { loading, error }] = useFetch('Error refunding donation.  Please try again')
  const confirm = useConfirm()
  const refundDonation = useCallback(
    async donationId => {
      const confirmed = await confirm(`Are you sure you want to refund this donation?  This action cannot be undone`, {
        title: `Refund donation`,
        confirmText: `Refund`,
        rejectText: 'Cancel',
      })
      if (!confirmed) {
        return
      }
      return request({
        onSuccess: result => {
          showSuccess('Successfully initiated refund.  It may take some time to complete the refund')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/donation/refund/${donationId}`,
      })
    },
    [request],
  )

  return {
    refundDonation,
    loading,
    error,
  }
}
