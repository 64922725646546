import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useFetchFailedMigrations = (salsaIntegration, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Failed Migrations.  Please refresh the page and try again',
  )
  const { id: organizationId } = useCurrentOrg()

  const reFetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/salsa/organizations/${organizationId}/migration-failures`,
    })
  }, [request])
  useEffect(() => {
      // because salsaIntegration is polled, this will also poll now
      reFetch()
  }, [reFetch, salsaIntegration])

  return {
    failedMigrations: result,
    loading: loading || !called,
    reFetch,
  }
}
