import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { ExportButton } from 'core/table/components'

const filename = 'questionnaires.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Created On', key: 'createdAt' },
  { label: 'Created By', key: 'createdBy' },
  { label: 'Status', key: 'status' },
]

const formatPerson = (person: any) => (person ? `${person.givenName} ${person.familyName}` : null)

const formatData = (questionnaires: any[]) =>
  questionnaires.map(({ original: { name, createdAt, createdBy, status } }) => ({
    name,
    createdAt: format.datetime(createdAt),
    createdBy: formatPerson(createdBy),
    status,
  }))

export const ExportQuestionnairesButton = React.forwardRef(
  (props: { data: any[]; disabled?: boolean; children?: React.ReactNode }, ref) => {
    return (
      <ExportButton
        // @ts-ignore
        ref={ref}
        {...props}
        formatData={formatData}
        headers={headers}
        filename={filename}
      />
    )
  },
)


export default ExportQuestionnairesButton
