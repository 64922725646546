import { Status } from '../../civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../auth/hooks'
import useFetchRefactored from '../../civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export const useUploadFile = <T extends { isNew: boolean }>(
  reference: string,
): [(payload: FormData) => Promise<T[]>, Status<T[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<T[]>({
    errorMessage: 'Problems with file upload',
  })

  const uploadFile = useCallback(
    (payload: FormData) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/uploads/${reference}`,
        config: {
          body: payload,
        },
      })
    },
    [organization.id, reference, request],
  )

  return [uploadFile, status]
}
