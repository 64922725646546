import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { TripRequest, TripRequestPayload } from '../types'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

const useUpdateTripRequest = (
  showNotifications: boolean = true,
): [(id: number, body: TripRequestPayload) => Promise<TripRequest>, Status<TripRequest>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripRequest>(
    showNotifications
      ? {
          successMessage: 'Trip successfully updated',
          errorMessage: 'Failed to update trip',
        }
      : {},
  )
  const updateTrip = useCallback(
    (id: number, formData: any) => {
      return request({
        method: 'put',
        url: `/organizations/${organization.id}/trip-requests/${id}`,
        config: {
          body: formData,
        },
      })
    },
    [request, organization.id],
  )

  return [updateTrip, status]
}

export default useUpdateTripRequest
