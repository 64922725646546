import React from 'react'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'

import { TableMenuButton } from 'civic-champs-shared'
import { useDownloadExportedChats } from 'chats/hooks'

const ExportChatsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const [downloadExportedChats] = useDownloadExportedChats()

  return (
    <TableMenuButton
      startIcon={<SaveAltOutlinedIcon />}
      disabled={disabled}
      onClick={() => {
        // @ts-ignore
        downloadExportedChats(data.map(({ original }) => original))
      }}
    >
      Export
    </TableMenuButton>
  )
}

export default ExportChatsButton
