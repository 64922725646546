import React from 'react'
import { ExpansionPanelSummaryProps } from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ExpansionPanelSummary } from '@material-ui/core'
import { useProfileAccordionStyles } from 'champion/hooks/useProfileAccordionStyles'

export const ProfileExtensionPanelSummary = ({
  children,
  ...props
}: Omit<ExpansionPanelSummaryProps, 'classes' | 'expandIcon'>) => {
  const classes = useProfileAccordionStyles()
  return (
    <ExpansionPanelSummary
      classes={{
        content: classes.content,
        expandIcon: classes.expandIcon,
        expanded: classes.expanded,
        root: classes.summaryRoot,
      }}
      expandIcon={<ExpandMoreIcon />}
      {...props}
    >
      {children}
    </ExpansionPanelSummary>
  )
}
