import React from 'react'
import * as _ from 'lodash'

export const MergeAccountReferenceList = props => {
  const { person, references } = props
  if (!person) {
    return null
  }

  const groupedReferences = references ? _.groupBy(_.sortBy(references, 'friendlyName'), 'friendlyName') : {}

  if (!Object.entries(groupedReferences).length) {
    return <div>This person has no associated references</div>
  }
  return (
    <>
      <ul>
        {Object.entries(groupedReferences).map((entry, index) => {
          const [friendlyName, refs] = entry
          return (
            <li key={index}>
              {friendlyName} - ({refs.length})
            </li>
          )
        })}
      </ul>
    </>
  )
}
