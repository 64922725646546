import React from 'react'
import { Button, Grid, IconButton, makeStyles, Modal } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import useRequiredGroups from '../hooks/useRequiredGroups'
import Loading from 'civic-champs-shared/core/Loading'
import { useHistory } from 'react-router'
import { GroupType } from '../../../group/hooks/useGroupLink'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    width: '50vw',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#FF6F3E',
  },
  row: {
    marginBottom: '25px',
  },
}))

export default function HelpingHandsQuestionSetModal(props) {
  const { showing, close } = props
  const { requiredGroups, loading } = useRequiredGroups()
  const history = useHistory()
  const classes = useStyles()
  const goToQuestionnairePage = (groupType) => {
    const group = requiredGroups.find(group => group.groupType.code === groupType)
    history.push(`/groups/${group.id}/questionnaires`)
  }

  if (loading) {
    return <Loading/>
  }
  return (
    <Modal open={showing} className={classes.modal} maxWidth="sm">
      <div className={classes.modalBody}>
        <IconButton className={classes.icon} aria-label="close" onClick={close}>
          <Close />
        </IconButton>
        <Grid container direction="column" alignItems="center">
          <Grid item className={classes.row}>
            What Questionnaire would you like to edit?
          </Grid>
          <Grid item container direction="row" justify="space-evenly" className={classes.row}>
            <Grid>
              <Button onClick={() => goToQuestionnairePage(GroupType.TaskRecipient)} variant="contained" color="secondary">
                Recipient
              </Button>
            </Grid>
            <Grid>
              <Button onClick={() => goToQuestionnairePage(GroupType.TaskRequester)} variant="contained" color="secondary">
                Requester
              </Button>
            </Grid>
            <Grid>
              <Button onClick={() => goToQuestionnairePage(GroupType.TaskVolunteer)} variant="contained" color="secondary">
                Volunteer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
