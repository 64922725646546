import { useCallback } from 'react'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import CancelEventDialog from 'Event/modules/events/details/header/CancelEventDialog'

const useCancelEventPrompt = onCancelEvent => {
  const cancelEventPrompt = useShowPrompt(CancelEventDialog)

  return useCallback(event => cancelEventPrompt({ event }).then(onCancelEvent), [cancelEventPrompt, onCancelEvent])
}

export default useCancelEventPrompt
