import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core/'
import { Add as AddIcon } from '@material-ui/icons'

import { ContainedButton } from 'civic-champs-shared/core'

import DEFAULT_FILTERS from 'core/table/filters'
import ExportTagsButton from '../components/ExportTagsButton'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import Loading from 'components/Loading'
import { useStyles, useTagsCollection, useTagsColumns } from '../hooks'
import PageHeader from 'App/components/PageHeader'

const tableName = 'tags'

export const TagsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ tags, initiallyLoaded }, { addTag, editTag, deleteTag }, eventListeners] = useTagsCollection() as any
  const columns = useTagsColumns({ editTag, deleteTag })
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['description', 'order', 'usedTimes'],
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      data: tags,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows.map((row: any) => row.original)
  const disabled = !selectedRows.length

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Tags"
        buttons={
          <>
            <ExportTagsButton data={selectedRows} disabled={disabled} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={() => addTag()}>
            New Tag
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const Tags = (props: RouteComponentProps) => (
  <PageHeader title="Tags" subTitle="Create and manage tags">
    <TagsComponent {...props} />
  </PageHeader>
)
