import React, { useCallback, useState } from 'react'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'

import { TableMenuButton, TablePopoverButton } from 'civic-champs-shared/core'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import useExportStyles from 'export/useExportStyles'

export const ExportButton = React.forwardRef(
  (
    props: {
      data: any[]
      disabled?: boolean
      headers: { label: string; key: string }[]
      filename: string
      formatData?: (inputData: any[]) => any[]
      type?: 'popover' | 'menu'
      children?: React.ReactNode
    },
    ref,
  ) => {
    const { data, disabled, headers, filename, formatData, type = 'menu', ...rest } = props

    const fileNameWithDate = useCurrentDateWithFilename(filename)
    const [formattedData, setFormattedData] = useState<any[]>([])
    const classes = useExportStyles()
    const createFormattedData = useCallback(() => {
      setFormattedData(formatData ? formatData(data) : data)
    }, [data, formatData])

    const TableButtonComponent = {
      menu: TableMenuButton,
      popover: TablePopoverButton,
    }[type]

    return (
      <TableButtonComponent
        // @ts-ignore
        ref={ref}
        {...rest}
        startIcon={<SaveAltOutlinedIcon />}
        disabled={disabled || data.length === 0}
        rightMargin
      >
        <CSVLink
          style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
          onClick={createFormattedData}
          className={classes.fill}
          filename={fileNameWithDate as string}
          headers={headers}
          data={formattedData}
        />
        Export
      </TableButtonComponent>
    )
  },
)

export default ExportButton
