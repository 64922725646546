import React, { useEffect } from 'react'
import Loading from 'components/Loading'
import queryString from 'query-string'
import useConnectStripeAccount from '../hooks/useConnectStripeAccount'

// TODO: fill in with new language
export const DonationStripeConnect = (props: any) => {
  const { state, code } = queryString.parse(props.location.search)
  const { succeeded, loading, connectStripeAccount } = useConnectStripeAccount(String(state), String(code))

  useEffect(() => {
    connectStripeAccount()
  }, [connectStripeAccount])

  if (loading) {
    return <Loading/>
  } else if (succeeded) {
    return <div>Success!  Congratulations, you are now registered with Stripe.  You can begin receiving donations.</div>
  } else {
    return <div>Sorry, we were unable to register your Stripe account at the moment.  Please try again soon.</div>
  }
}
