import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const STABLE_EMPTY_ARRAY = []

export const useUserCredentialIDs = (credentialTypeID, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Groups.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()
  const refetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/user-credentials`,
      config: {
        queryStringParameters: { typeId: credentialTypeID }
      }
    })
  }, [currentOrg.id, request])

  useEffect(() => { refetch() }, [refetch])

  return {
    userCredentialIds: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refetch,
  }
}
