import { get } from 'lodash';
import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Typography, Paper } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import MemberRoleSelect from './MemberRoleSelect';
import ShortMemberDetails from './ShortMemberDetails';
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';
import cn from 'classnames';

//TODO probably the DialogTitle|Content|Actions components aren't very useful once we're styling fullscreen

export default function ChangeRolePrompt(props) {
    const classes = usePromptStyles();
    const { member, showing, close, cancel, complete } = props;    

    const [newRole, setNewRole] = useState( get(member, 'role') ); 

    let submit = useCallback( () => { complete(newRole) }, [newRole, complete]);

    return (
        <Dialog
            fullScreen
            className={cn({ fullScreen: true})}
            open={showing}
            onClose={close}
            disableBackdropClick={true}
        >
            <DialogTitle className={classes.titleContainer}>
                <Typography className={classes.title}>Change Admin Role</Typography>
                <IconButton
                    onClick={close}
                    className={classes.closeButton}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.contentContainer}>
                <Grid>
                    <Grid className={classes.field} item>
                        <ShortMemberDetails member={member} />
                    </Grid>
                    <Grid className={classes.field} item>
                        <MemberRoleSelect
                            label="New Role"
                            value={newRole}
                            onChange={setNewRole}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.actionContainer}>
                <Button
                    className={classes.actionButton}
                    color='secondary'
                    onClick={cancel}>
                    Cancel
                </Button>
                <Button
                    className={classes.actionButton}
                    variant='contained'
                    color='primary'
                    onClick={submit}>
                    Update Role
                </Button>
            </DialogActions>
        </Dialog>
    );
}
