import React, { useEffect, useMemo } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import matchSorter from 'match-sorter'
import PagedTable from 'core/table/components/PagedTable'
import { DateCell, EmailCell, WithLinkCell, NullableTextCell, PersonNameCell, PhoneNumberCell } from 'core/table/cells'
import Loading from 'components/Loading'
import useTasks from '../hooks/useTasks'
import * as _ from 'lodash'

const columns = [
  {
    Header: 'Requester',
    id: 'name',
    accessor: row => row,
    Cell: props => WithLinkCell(PersonNameCell, id => `/task-persons/${id}`)(props),
  },
  {
    Header: 'Mobile',
    accessor: 'phoneNumber',
    Cell: PhoneNumberCell
  },
  {
    Header: 'Home',
    accessor: 'homePhoneNumber',
    Cell: PhoneNumberCell
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: EmailCell,
  },
  {
    Header: 'Open Tasks',
    accessor: 'openTasks',
    Cell: NullableTextCell,
  },
  {
    Header: 'Completed Tasks',
    accessor: 'completedTasks',
    Cell: NullableTextCell,
  },
  {
    Header: 'Joined',
    accessor: 'createdAt',
    Cell: DateCell,

  },
  // TODO: add number of requests to api call
  // {
  //   Header: 'Requests',
  //   accessor: 'requests',
  // },
  // TODO: Add rating system
  // {
  //   Header: 'Rating',
  //   accessor: 'rating',
  // }
]

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.givenName',
      'original.familyName',
      'original.email',
      'original.phoneNumber',
      'original.homePhoneNumber',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const useMappedRequesters = ({ requesters, tasks, completedTasks }) =>
  useMemo(() => {
    const openTasksByRequesterId = _.groupBy(tasks, 'requester.id')
    const completedTasksByRequesterId = _.groupBy(completedTasks, 'requester.id')
    return _.map(requesters, requester => ({
      ...requester,
      openTasks: _.size(openTasksByRequesterId[requester.id]),
      completedTasks: _.size(completedTasksByRequesterId[requester.id]),
    }))
  }, [requesters, tasks, completedTasks])

export default function RequestersTab(props) {
  const { query, requesters, loading: loadingProp } = props
  const { tasks, completedTasks, loading: tasksLoading } = useTasks()
  const loading = loadingProp || tasksLoading
  const data = useMappedRequesters({ requesters, tasks, completedTasks })
  const table = useTable({
      data,
      columns,
      getRowId: row => row.id,
      globalFilter: fuzzyMatchFilter,
      initialState: {
        globalFilter: query,
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  useEffect(() => table.setGlobalFilter(query), [query, table])

  return (
    <div>
      {
        loading ?
        <Loading />
        :
        <PagedTable {...table } />
      }
    </div>
  )
}
