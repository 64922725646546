import React from 'react'
import { GroupPicker } from '../../../../components/opportunity/GroupPicker'
import { OpportunityVisibility } from '../../../../interfaces'
import { AssociationType } from '../../../../interfaces/interfaceCreateEditEvent'

export const GroupMembership = props => {
  const { visibility, onChange, onboardingGroups } = props
  return (
    <div>
      {visibility === OpportunityVisibility.SELECT_GROUPS_ONLY ? (
        <div>
          <b>Selected Groups Only</b>{' '}
          <span>
            visibility does not allow adding volunteers to additional groups. Members of the selected groups are asked
            to answer questions and sign waivers that were added or became out of date since the volunteer's last
            activity.
          </span>
        </div>
      ) : (
        <>
          <div>
            Volunteers are added to these groups and sent onboarding questionnaires and waivers needed for membership.
            This will occur during registration or when checking into this event.
          </div>
          <GroupPicker
            loading={false}
            value={onboardingGroups}
            associationType={AssociationType.ADD_PARTICIPANTS_TO_GROUP}
            onChange={onChange}
          />
        </>
      )}
    </div>
  )
}
