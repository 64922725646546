import React from 'react'
import { makeStyles } from '@material-ui/styles'
import cn from 'classnames'
import { useRouteMatch } from 'react-router'

interface NavMenuItemProps {
  key: number
  label: string
  route: string
  childRoute?: string
  onChangeRoute: (route: string) => void
  items: NavMenuItemProps[]
  isAdminItem?: boolean
  showAdminItems: boolean
  collapsed: boolean
  className?: string
  aClassName?: string
  setPopoverOpen?: (open: boolean) => void
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    minWidth: '110px',
    borderRadius: '5px',
    backgroundColor: '#5691EC',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'auto',
    position: 'relative',
    '& > a': {
      fontFamily: 'Nunito',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#FFFFFF',
      textDecoration: 'none',
      '&$parent': {
        fontWeight: 700,
      },
    },
  },
  parent: {
    padding: '4px 10px 4px 5px',
  },
  child: {
    padding: '4px 10px 4px 20px',
    '&:hover': {
      backgroundColor: '#3776CF',
    },
  },
  active: {
    backgroundColor: '#3776CF',
    '&$adminItem': {
      // @ts-ignore
      backgroundColor: theme.palette.accent.yellowGreen.light,
    },
  },
  adminItem: {
    // @ts-ignore
    backgroundColor: theme.palette.background.green,
  },
  button: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '1px',
    height: '1px',
    opacity: 0,
  },
}))

const ChildItem = ({ key, label, route, onChangeRoute, isAdminItem, setPopoverOpen }: NavMenuItemProps) => {
  const classes = useStyles()
  const match = useRouteMatch(route)
  return (
    <a
      key={key}
      className={cn(classes.child, { [classes.adminItem]: isAdminItem, [classes.active]: match })}
      href={route || '#'}
      onClick={e => {
        e.preventDefault()
        onChangeRoute(route)
        setPopoverOpen?.(false)
      }}
    >
      {label}
    </a>
  )
}

export default function NavMenuPopoverItem({
  key,
  label,
  childRoute,
  route,
  onChangeRoute,
  items = [],
  isAdminItem,
  showAdminItems,
  setPopoverOpen,
}: NavMenuItemProps) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper} tabIndex={-1} onBlur={() => setPopoverOpen?.(false)}>
      {/* We need this invisible button to make parent div receive focus on render and close on blur */}
      <button autoFocus className={classes.button} />
      <a
        key={key}
        className={cn(classes.parent, { [classes.adminItem]: isAdminItem })}
        href={childRoute || route || '#'}
        onClick={e => {
          e.preventDefault()
          onChangeRoute(childRoute || route)
        }}
      >
        {label}
      </a>
      {items
        .filter(({ isAdminItem }) => !isAdminItem || showAdminItems)
        .map((item, index) => (
          <ChildItem
            {...item}
            key={index}
            isAdminItem={item.isAdminItem || isAdminItem}
            onChangeRoute={onChangeRoute}
            setPopoverOpen={setPopoverOpen}
          />
        ))}
    </div>
  )
}
