import qs from 'query-string'
import BaseService from '../services/base.service'
import { MeetingRole } from '../scenes/MentorShipActivities'
const baseService = new BaseService()
const MAIN_API = `${baseService.getEnvVar('API_ENDPOINT')}/`

const queryString = (params: { [key: string]: any }) => qs.stringify(params, { skipNull: true, skipEmptyString: true })

const ENDPOINT_ROUTES = {
  Meetings: {
    get: (orgId: number, params: string) => `${MAIN_API}organizations/${orgId}/meetings?${params}`,
    getById: (id: number) =>  `${MAIN_API}meetings/${id}`,
    exportMeetings: (orgId: number) => `${MAIN_API}organizations/${orgId}/meetings/export`,
    statistic: (params?: { [key: string]: any }) => `${MAIN_API}meetings/statistics?${queryString(params || {})}`
  },
  Uploads: {
    uploadFile: (orgId: number, reference: string) => `${MAIN_API}organizations/${orgId}/uploads/${reference}`,
    uploadsList: (orgId: number, reference: string) => `${MAIN_API}organizations/${orgId}/uploads/${reference}`,
    downloadHistoryItem: (orgId: number, fileId: number) => `${MAIN_API}organizations/${orgId}/upload/${fileId}`,
  },
  Volunteers: {
    confirmVolunteers: (orgId: number) => `${MAIN_API}organizations/${orgId}/volunteers/multi`
  },
  Matches: {
    matches: (orgId: number, params?: { limit?: number, offset?: number }) => `${MAIN_API}organizations/${orgId}/matches?${queryString(params || {})}`,
    match: (id: number, params?: { [key: string]: any }) => `${MAIN_API}matches/${id}?${queryString(params || {})}`,
    archive: (orgId: number) => `${MAIN_API}organizations/${orgId}/matches/archive`,
    unarchive: (orgId: number) => `${MAIN_API}organizations/${orgId}/matches/unarchive`,
    confirmMatches: (orgId: number) => `${MAIN_API}organizations/${orgId}/matches/multi`,
    exportMatches: (orgId: number) => `${MAIN_API}organizations/${orgId}/matches/export`
  },
  Feedback: {
    getQuestions: (role: MeetingRole, params?: { [key: string]: any }) => `${MAIN_API}feedback/questions/${role}?${queryString(params || {})}`,
    getFeedbackByUuid: (uuid: string, mentee_id: number) => `${MAIN_API}feedback/${uuid}?mentee_id=${mentee_id}`,
    editFeedbackByUuid: (uuid: string) => `${MAIN_API}feedback/${uuid}`
  },
  Company: {
    getAllCompanies: (orgId: number, params?: { [key: string]: any }) =>
      `${MAIN_API}organizations/${orgId}/company?${queryString(params || {})}`,
    createNewCompany: (orgId: number) => `${MAIN_API}organizations/${orgId}/company`,
    exportCompanies: (orgId: number) => `${MAIN_API}organizations/${orgId}/company/export`
  },
  User: {
    updateUser: `${MAIN_API}user/v2/update`
  }
}

export default ENDPOINT_ROUTES
