import { RegistrantResponse } from 'Event/interfaces'
import moment from 'moment-timezone'
import { useTimeZone } from 'civic-champs-shared/auth/hooks'
import { useCallback } from 'react'

const getRegisteredPeriod = (
  registration: RegistrantResponse,
  timeZone: string,
): { startDate: moment.Moment; endDate: moment.Moment } => {
  const date = moment.tz(registration.date, timeZone).format('YYYY-MM-DD')
  const startDate = moment.tz(`${date} ${registration.timeshift.timeStart}`, 'YYYY-MM-DD LT', timeZone)
  const endDate = moment.tz(`${date} ${registration.timeshift.timeEnd}`, 'YYYY-MM-DD LT', timeZone)
  return { startDate, endDate }
}

export const useGetRegisteredPeriod = (): ((registration: RegistrantResponse) => {
  startDate: moment.Moment
  endDate: moment.Moment
}) => {
  const timeZone = useTimeZone()
  return useCallback((registration: RegistrantResponse) => getRegisteredPeriod(registration, timeZone), [timeZone])
}
