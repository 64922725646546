import moment from 'moment'
import get from 'lodash/get'
import { useCallback } from 'react'
import find from 'lodash/find'
import { operatorTypes } from 'core/table/interfaces/Filters'
import isUndefined from 'lodash/isUndefined'
import unionBy from 'lodash/unionBy'
import intersectionBy from 'lodash/intersectionBy'

export const DATE_RANGE = 'dateRange'
export const DYNAMIC_NUMBER = 'dynamicNumber'
export const DYNAMIC_TEXT = 'dynamicText'
export const DYNAMIC_TEXT_ARRAY = 'dynamicTextArray'

export * from './groups'
export * from './tags'

export const dateRangeFilter = (rows, id, range) =>
  range == null
    ? rows
    : rows.filter(row => {
        const value = moment(row.values[id])
        return value.isBetween(range.startDate, range.endDate, 'day', '[]')
      })

dateRangeFilter.autoRemove = value => !value

const greaterOrEqual = (rowValue, operand) => rowValue >= operand
const greaterThan = (rowValue, operand) => rowValue > operand
const lessThan = (rowValue, operand) => rowValue < operand
const lessThanOrEqual = (rowValue, operand) => rowValue <= operand
const equals = (rowValue, operand) => rowValue == operand
const startsWith = (rowValue, operand) => String(rowValue).toLowerCase().startsWith(String(operand).toLowerCase())
const endsWith = (rowValue, operand) => String(rowValue).toLowerCase().endsWith(String(operand).toLowerCase())
export const contains = (rowValue, operand) => String(rowValue).toLowerCase().includes(String(operand).toLowerCase())

const operatorToMethodMap = {
  '>=': greaterOrEqual,
  '>': greaterThan,
  '<': lessThan,
  '<=': lessThanOrEqual,
  '=': equals,
  startsWith,
  endsWith,
  contains,
}

const dynamicNumber = (rows, ids, filterValue) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      const filterMethod = operatorToMethodMap[filterValue.operator]

      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

const dynamicText = (rows, ids, filterValue) => {
  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      const filterMethod = operatorToMethodMap[filterValue.operator]

      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

export const DynamicTextArray = key => {
  const filter = (rows, ids, filterValue) => {
    return rows.filter(row => {
      return ids.some(id => {
        const rowValues = row.values[id]
        const filterMethod = operatorToMethodMap[filterValue.operator]

        return rowValues.reduce(
          (acc, rowValue) => acc || filterMethod(key ? rowValue[key] : key, filterValue.operand),
          false,
        )
      })
    })
  }
  filter.toString = () => `DynamicTextArray_${key}`
  return filter
}

export const getMultifieldDynamicTextFilter =
  (fields, glue = ' ') =>
  (rows, _, filterValue) =>
    rows.filter(row => {
      const rowValue = fields.map(field => get(row.values, field)).join(glue)
      const filterMethod = operatorToMethodMap[filterValue.operator]
      return filterMethod(rowValue, filterValue.operand)
    })

export const useGetGlobalFilter = ({ columns, getOperatorOptions, filterTypes }) =>
  useCallback(
    (rows, columnIds, globalFilterValue) => {
      const filterMethods = columnIds.reduce((acc, id) => {
        const column = find(columns, { id })
        if (isUndefined(column) || column.disableGlobalFilter) return acc

        const useColumnFilter =
          !isUndefined(column.filter) &&
          !isUndefined(find(getOperatorOptions(column.id), { value: operatorTypes.CONTAINS }))
        const filterMethod = useColumnFilter ? column.filter : DYNAMIC_TEXT
        if (typeof filterMethod === 'function') {
          if (!filterTypes[filterMethod]) {
            filterTypes[filterMethod] = filterMethod
          }
        }
        return { ...acc, [filterMethod]: [...(acc?.[filterMethod] || []), id] }
      }, {})

      const filteredRows = Object.entries(filterMethods).reduce((acc, [filterMethod, methodColumnIds]) => {
        const filteredRow = filterTypes[filterMethod](rows, methodColumnIds, {
          operator: operatorTypes.CONTAINS,
          operand: globalFilterValue,
        })
        return unionBy(acc, filteredRow, 'id')
      }, [])

      return intersectionBy(rows, filteredRows, 'id')
    },
    [columns, filterTypes, getOperatorOptions],
  )

export const firstLastFilter = getMultifieldDynamicTextFilter(['name.givenName', 'name.familyName'])

export const DEFAULT_FILTERS = {
  [DATE_RANGE]: dateRangeFilter,
  [DYNAMIC_NUMBER]: dynamicNumber,
  [DYNAMIC_TEXT]: dynamicText,
  [DYNAMIC_TEXT_ARRAY]: DynamicTextArray(),
}

export default DEFAULT_FILTERS
