import { useCreateApiNotification, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { TripOrder, TripOrderPayload, TripRequest, TripRequestPayload } from 'volunteering/trips/types'
import useFetchTripOrders from 'volunteering/trips/hooks/useFetchTripOrders'
import { useDateRangeFilter } from 'filtering/hooks'
import { useCallback, useEffect } from 'react'
import useRemoveTrip from 'volunteering/trips/hooks/useRemoveTrip'
import useUpdateTripRequest from 'volunteering/trips/hooks/useUpdateTripRequest'
import { useCreateTripRequest, useTripOrderDriverAssignment } from 'volunteering/trips/hooks/index'
import { CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'

interface TripOrdersCollectionData {
  tripOrders: TripOrder[]
  loading: boolean
  refresh: () => void
}
interface TripOrdersCollectionOperations {
  deleteTripOrders: (tripOrders: TripOrder[]) => void
  updateTripOrder: (id: number, body: TripRequestPayload) => void
  addTripOrder: (body: Partial<TripRequest>) => void
  assignDriver: (body: Partial<TripOrderPayload>) => void
}

export default function useTripOrdersCollection(): [
  TripOrdersCollectionData,
  TripOrdersCollectionOperations,
  CollectionEventListeners<TripOrder>,
] {
  const createNotification = useCreateApiNotification()
  const [fetchTripOrders, { loading }] = useFetchTripOrders()
  const [{ startDate, endDate }] = useDateRangeFilter()
  const [tripOrders, operations, events] = useRemoteCollection<TripOrder>()
  const [removeTrip] = useRemoveTrip(false)
  const [updateTrip] = useUpdateTripRequest(false)
  const [addTrip] = useCreateTripRequest(false)
  const [handleAssignDriver] = useTripOrderDriverAssignment(false)

  const refresh = useCallback(() => {
    fetchTripOrders({ startDate, endDate }).then(operations.syncCollection)
  }, [endDate, fetchTripOrders, startDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refresh()
  }, [fetchTripOrders, startDate, endDate]) // eslint-disable-line react-hooks/exhaustive-deps

  const deleteTripOrders = useCallback(
    async (tripOrders: TripOrder[]) => {
      const notification = createNotification('Removing trips')
      let removedCount = 0
      let failedCount = 0
      let error: any
      for (const tripOrder of tripOrders) {
        const undo = operations.eagerRemove(tripOrder)
        try {
          await removeTrip(tripOrder.tripRequest?.id as number)
          removedCount++
        } catch (e) {
          failedCount++
          error = e
          undo()
        }
      }
      if (removedCount) notification.onSuccess(`${removedCount} trips removed`)
      if (failedCount) notification.onError(`Failed to remove ${failedCount} trips`, error)
    },
    [createNotification, operations, removeTrip],
  )

  const updateTripOrder = useCallback(
    async (id: number, body: TripRequestPayload) => {
      const notification = createNotification('Updating trip')
      try {
        const tripRequest = await updateTrip(id, body)
        operations.eagerReplace({ ...tripRequest.tripOrders[0], tripRequest: { id: tripRequest.id } } as TripOrder)
        notification.onSuccess(`Trip updated`)
      } catch (e) {
        notification.onError(`Failed to update trip`, e)
      }
    },
    [createNotification, operations, updateTrip],
  )

  const addTripOrder = useCallback(
    async (body: Partial<TripRequest>) => {
      const notification = createNotification('Adding trip')
      try {
        const tripRequest = await addTrip(body)
        operations.eagerAdd({ ...tripRequest.tripOrders[0], tripRequest: { id: tripRequest.id } } as TripOrder)
        notification.onSuccess(`Trip added`)
      } catch (e) {
        notification.onError(`Failed to add trip`, e)
      }
    },
    [addTrip, createNotification, operations],
  )

  const assignDriver = useCallback(
    async (body: Partial<TripOrderPayload>) => {
      const notification = createNotification('Assigning driver')
      try {
        const tripOrder = await handleAssignDriver(body)
        operations.eagerReplace({ ...tripOrder, tripRequest: { id: tripOrder.tripRequestId } } as TripOrder)
        notification.onSuccess('Driver assigned')
      } catch (e) {
        notification.onError('Failed to assign driver')
      }
    },
    [createNotification, handleAssignDriver, operations],
  )

  return [
    {
      tripOrders,
      loading,
      refresh,
    },
    {
      deleteTripOrders,
      updateTripOrder,
      addTripOrder,
      assignDriver,
    },
    events,
  ]
}
