import React from 'react'
import { Card, Grid, makeStyles, Typography } from '@material-ui/core'
import useFormatToOrgTime from 'Event/scenes/events/hooks/useFormatToOrgTime'
import { isEqual } from 'lodash'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    historyCard: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    text: {
        lineHeight: '2rem',
        display: 'flex',
        alignItems: 'center',
    },
    header: {
        fontSize: '1.2rem',
        lineHeight: '1.5rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
}))

const determineActorRole = (historyRecord, task) => {
    const { actor } = historyRecord
    if (isEqual(actor, task.volunteer)) return 'Volunteer: '
    if (isEqual(actor, task.recipient)) return 'Recipient: '
    if (isEqual(actor, task.requester)) return 'Requester: '
    return 'Admin: '
}

const sortHistory = (a, b) => {
    if ((moment(a.occurredAt)).isBefore(moment(b.occurredAt))) { return 1 }
    return -1
}

export default function TaskHistory(props) {
    const { taskHistory, task } = props
    const classes = useStyles()
    const formatDate = useFormatToOrgTime()

    return (
        <>
            {taskHistory.sort(sortHistory).map(th => (
                <Card key={th.id} className={classes.historyCard}>
                    <Grid container direction='row' justify='space-between'>
                        <Grid item xs={4}>
                            <Typography className={classes.header}>{th.eventType.displayName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.text}> {determineActorRole(th, task)} {th.actor.givenName} {th.actor.familyName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.text}>{formatDate(th.occurredAt, 'MM/DD/YYYY hh:mm a')}</Typography>
                        </Grid>
                    </Grid>
                </Card>
            ))}
        </>
    )
}