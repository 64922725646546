import React, { useCallback, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { usePromptStyles } from '../../core/modal/hooks'
import { QuestionSetResponse } from '../types'
import InputComponent from '../../core/input'
import { useDuplicateQuestionSet } from '../hooks'
import cn from 'classnames'

interface Props {
  showing: boolean
  cancel: any
  complete: (duplicatedQuestionSet: QuestionSetResponse) => any
  questionSet: QuestionSetResponse
}

const DuplicateQuestionSetPrompt = (props: Props) => {
  const { showing, complete, cancel, questionSet } = props
  const [name, setName] = useState(`${questionSet.name} (1)`)
  const promptClasses = usePromptStyles()
  const [duplicateQuestionSet, { loading }] = useDuplicateQuestionSet((duplicatedQuestionSet: QuestionSetResponse) =>
    complete(duplicatedQuestionSet),
  )
  const disabled = loading || !name || name === questionSet.name
  const handleSubmit = useCallback(async () => {
    await duplicateQuestionSet({ questionSetId: questionSet.questionSetId, name })
  }, [duplicateQuestionSet, questionSet, name])

  return (
    <Dialog open={showing}>
      <DialogContent className={promptClasses.windowedContentContainer}>
        <h3>Please enter a new name for the duplicate of question-set '{questionSet.name}':</h3>
        <InputComponent
          value={name}
          onChange={setName}
          required={true}
          label={'Questionnaire name'}
          placeholder={'Enter new name for duplicate question set'}
        />
      </DialogContent>
      <DialogActions className={cn(promptClasses.actionContainer, promptClasses.windowedActionContainer)}>
        <Button
          variant="text"
          color="primary"
          className={`${promptClasses.actionButton} ${promptClasses.orangeCancel}`}
          disabled={loading}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={`${promptClasses.actionButton} ${promptClasses.orangeButton}`}
          disabled={disabled}
          onClick={handleSubmit}
        >
          Duplicate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DuplicateQuestionSetPrompt
