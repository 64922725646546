import { useCallback } from 'react'

import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { default as useFetch } from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { ExternalCustomerDatafeed } from '../types'

export type FetchHookResult<T> = [() => Promise<T>, Status<T>]

const useCustomerDatafeeds = (): FetchHookResult<ExternalCustomerDatafeed[]> => {
  const organization = useCurrentOrg()
  const [request, status] = useFetch<ExternalCustomerDatafeed[]>() //TODO handle errors

  //TODO I sort of want an even *thinner* request that doesn't store 
  //     the result for you
  const getDatafeeds = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/external_datafeeds`
    })
  }, [organization, request])

  return [getDatafeeds, status] 
}

export default useCustomerDatafeeds