import React from 'react'
import { Icon } from '@mdi/react'
import { mdiCircle, mdiCircleOutline } from '@mdi/js'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { TripDriverPicker } from './components/TripDriverPicker'
import cn from 'classnames'

const useStyles = makeStyles({
  orderNameColumn: {
    fontSize: '13px',
    width: '200px',
    '&>div': {
      textAlign: 'left',
    },
  },
  subRowOrderNameColumn: {
    fontSize: '12px',
    paddingLeft: '20px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    width: '200px',
    '&>div': {
      padding: '16px',
    },
  },
  driversAssignedFillIcon: {
    stroke: '#1d65b7',
    fill: '#1d65b7',
    height: '16px',
    verticalAlign: 'sub',
    marginLeft: '4px',
  },
  driversAssignedIcon: {
    strokeWidth: '3px',
  },
  deleteColumn: {
    color: '#BA1B1B',
    cursor: 'pointer',
  },
})

export const OrderNameCell = ({ row: { original } }: any) => {
  const classes = useStyles()

  return (
    <Typography className={original.subRows ? classes.orderNameColumn : classes.subRowOrderNameColumn}>
      {original.orderName}
    </Typography>
  )
}

export const TotalDriversCell = ({ row }: any) => {
  const tripOrdersCount = row.original.tripOrderCount
  const assignedDrivers = row.original.assignedDriverCount
  const allTripOrderAssigned = tripOrdersCount === assignedDrivers
  const classes = useStyles()
  const totalDriversCellclassName = cn(
    allTripOrderAssigned
      ? [classes.driversAssignedIcon, classes.driversAssignedFillIcon]
      : classes.driversAssignedFillIcon,
  )

  return (
    <span>
      {assignedDrivers} / {tripOrdersCount}
      <Icon path={allTripOrderAssigned ? mdiCircle : mdiCircleOutline} className={totalDriversCellclassName} />
    </span>
  )
}

interface DriverInfoCellProps {
  row: any
  onDriverAssignment: Function
}

export const DriverInfoCell = (props: DriverInfoCellProps) => {
  const { row, onDriverAssignment } = props
  const label = row.original.driver || 'Driver Needed'
  return (
    <TripDriverPicker
      label={label}
      tripOrder={row.original.data}
      onDriverAssignment={onDriverAssignment}
      drivers={row.original.drivers}
      driversLoading={row.driversLoading}
    />
  )
}
