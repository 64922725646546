import { useCallback } from 'react'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { MergePeoplePrompt } from './MergePeoplePrompt'

export const useMergePeople = () => {
    const showPrompt = useShowPrompt(MergePeoplePrompt)

    //TODO people should be a real type...
    return useCallback(async (people: any[]) => {
      //TODO flesh this out...
      return showPrompt({ people })
    }, [showPrompt])

}