import React from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Field } from 'formik'
import { DateTimePicker } from 'formik-material-ui-pickers'

const useStyles = makeStyles((theme) => ({
  datePickerWrap: {
    width: '128px'
  },
  timePickerRow: {
    margin: '16px 0'
  }
}))

const TimeStep = (props) => {
  const { goToPrevStep, startsAt, goToNextStep } = props
  const classes = useStyles()

  return (
    <Grid container direction='row'>
      <Grid item container direction='row' spacing={2} className={classes.timePickerRow}>
        <Grid item>
          <Field 
            name='startsAt'
            label='Starts At'
            component={DateTimePicker}
            format='MM/DD/YYYY hh:mm a'
            variant='inline'
            inputVariant='outlined'
          />
        </Grid>
        <Grid item>
          <Typography>To</Typography>
        </Grid>
        <Grid item>
          <Field
            name='endsAt'
            label='Ends At'
            component={DateTimePicker}
            minDate={startsAt}
            format='MM/DD/YYYY hh:mm a'
            variant='inline'
            inputVariant='outlined'
          />
        </Grid>
      </Grid>
      <Grid item container direction='row' spacing={2}>
        <Grid item>
          <Button variant='outlined' color='primary' onClick={goToPrevStep}>Go Back</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='secondary' onClick={goToNextStep}>Next Step</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TimeStep
