import * as React from 'react'
import { useCurrentUser } from 'auth/hooks'
import { USER } from 'civic-champs-shared/auth/utils/permissions'

//For Class Component
//If you need the current user, wrap your class component in this HOC when component is export. For example: "export default withCurrentUserHOC(YourComponent)"
export const withCurrentUserHOC = (Component: any) => {
  return (props: any) => {
    let user = useCurrentUser();

    if(!user) user = {};
    if(!user.permissions) {
      user.permissions = {
        role: USER,
        managedOrganization: null
      }
    }
    return <Component currentUser={user} {...props}/>
  }
}
