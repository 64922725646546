import React, { useRef, useState } from 'react'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { OfferSignups } from './OfferSignups'

export const OfferDrilldownSignups = ({
  event,
  scrollTop,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  scrollTop: number
}) => {
  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)

  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  return (
    <>
      <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
        <EventExpansionPanelSummary name="offer-signups" style={style} ref={summaryRef}>
          <h1>Sign-Ups</h1>
        </EventExpansionPanelSummary>
        <EventExpansionPanelDetails ref={detailsRef}>
          <OfferSignups opportunityId={event.opportunityId} tableName="offerDrilldownSignups" />
        </EventExpansionPanelDetails>
      </EventExpansionPanel>
    </>
  )
}
