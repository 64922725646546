import React, { useCallback } from 'react'
import moment from 'moment-timezone'
import { FormModal, FormModalScreen } from '../../components/FormModal'
import { Field, Form, Formik } from 'formik'
import RadioGroupFormField from '../../components/RadioGroupFormField'
import { StyledKeyboardDatePicker } from '../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import yup from '../../civic-champs-shared/utils/yup'
import StyledCheckboxField from '../../civic-champs-shared/formik/components/StyledCheckboxField'
import { muiTheme } from '../../theme'
import { useFeatureEnabled } from '../../core/feature/hooks'

interface FormData {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  restrictByDate: '0' | '1'
  mostRecentOnly: boolean
}

const useStyles = makeStyles(() => ({
  dateTimePickers: {
    marginLeft: '10px',
    display: 'flex',
    gap: '24px',
    width: '440px',
  },
  dateTimePicker: {
    width: '208px',
  },
  section: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-10px',
    '&:last-child': {
      marginBottom: '14px',
    },
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
  dialog: {
    maxWidth: '490px',
  },
}))
const validationSchema = yup.object({
  restrictByDate: yup.string().required(),
  mostRecentOnly: yup.boolean(),
  startDate: yup
    .date()
    .nullable()
    .when(['restrictByDate'], (restrictByDate: '0' | '1', schema: yup.DateSchema) => {
      if (restrictByDate === '1') {
        return schema.required('Start Date is Required')
      } else {
        return schema.notRequired().nullable()
      }
    }),
  endDate: yup
    .date()
    .nullable()
    .when(
      ['restrictByDate', 'startDate'],
      (restrictByDate: '0' | '1', startDate: moment.Moment | null, schema: yup.DateSchema) => {
        if (restrictByDate === '1') {
          if (!startDate) {
            return schema.required('End Date is Required')
          } else {
            return schema
              .required('End Date is Required')
              .test('end-after-start', 'The end date cannot occur before the start date.', function (value: any) {
                return value && moment(value).isSameOrAfter(moment(this.parent.startDate))
              })
          }
        } else {
          return schema.notRequired().nullable()
        }
      },
    ),
})
interface Props {
  showing: boolean
  complete: (value: { startDate?: moment.Moment; endDate?: moment.Moment; mostRecentOnly?: boolean }) => void
  close: () => void
}

const defaultFormData: FormData = {
  startDate: null,
  endDate: null,
  restrictByDate: '0',
  mostRecentOnly: false,
}

export const ExportAnswersPrompt = ({ showing, close, complete }: Props) => {
  const surveysEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const submit = useCallback(
    (values: FormData) => {
      if (values.restrictByDate === '0') {
        complete({ mostRecentOnly: values.mostRecentOnly })
      } else {
        complete({
          startDate: (values.startDate as moment.Moment).startOf('day'),
          endDate: (values.endDate as moment.Moment).endOf('day'),
          mostRecentOnly: values.mostRecentOnly,
        })
      }
    },
    [complete],
  )
  const classes = useStyles()
  return (
    <FormModal id="export-answers" showing={showing} className={classes.dialog} close={close} title="Export Responses">
      <Formik
        initialValues={defaultFormData}
        validationSchema={validationSchema}
        onSubmit={submit}
        validateOnChange
        isInitialValid={true}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <FormModalScreen
                onBack={close}
                onNext={submitForm}
                nextText="Export"
                backText="Cancel"
                disabled={isSubmitting}
              >
                <div className={classes.section}>
                  <RadioGroupFormField
                    name="restrictByDate"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Export all responses',
                        value: '0',
                      },
                      {
                        label: 'Export responses from a specified date range',
                        value: '1',
                      },
                    ]}
                  />
                  {values.restrictByDate === '1' && (
                    <>
                      <div className={classes.dateTimePickers}>
                        <div className={classes.dateTimePicker}>
                          <Field
                            inputVariant="outlined"
                            label="Start Date *"
                            higher
                            component={StyledKeyboardDatePicker}
                            name="startDate"
                          />
                        </div>

                        <div className={classes.dateTimePicker}>
                          <Field
                            higher
                            inputVariant="outlined"
                            label="End Date *"
                            component={StyledKeyboardDatePicker}
                            name="endDate"
                            minDate={moment(values.startDate || undefined)
                              .startOf('day')
                              .toDate()}
                            minDateMessage={'The end date cannot occur before the start date.'}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {surveysEnabled && (
                    <Field
                      component={StyledCheckboxField}
                      name={`mostRecentOnly`}
                      label="Only export the most recent response per volunteer"
                    />
                  )}
                </div>
              </FormModalScreen>
            </Form>
          )
        }}
      </Formik>
    </FormModal>
  )
}
export default (props: Props) => (
  <ThemeProvider theme={muiTheme}>
    <ExportAnswersPrompt {...props} />
  </ThemeProvider>
)
