import React from 'react'
import { Grid, Button } from '@material-ui/core'
import Loading from 'components/Loading'
import * as yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import useStripeProduct from '../hooks/useStripeProduct'
import useEditStripeProduct from '../hooks/useEditStripeProduct'
import BackLink from '../../../donation/campaigns/components/BackLink'

const stripeProductPayloadSchema = yup.object({
  smsCredits: yup
    .number()
    .min(0)
    .integer()
    .default(0),
  emailCredits: yup
    .number()
    .min(0)
    .integer()
    .default(0),
})

export default function ProductForm(props) {
  const { productCode } = props.match.params
  const { result: stripeProduct, loading: stripeProductLoading, refresh } = useStripeProduct(productCode)
  const [editStripeProduct, { loading: editLoading }] = useEditStripeProduct()
  const handleSubmit = async values => {
    const { smsCredits, emailCredits } = values
    await editStripeProduct({ smsCredits, emailCredits, productCode, id: stripeProduct && stripeProduct.id })
    await refresh()
  }
  const loading = stripeProductLoading || editLoading
  if (loading) {
    return <Loading />
  }
  return (
    <Formik
      initialValues={{ smsCredits: stripeProduct.smsCredits || 0, emailCredits: stripeProduct.emailCredits || 0 }}
      validationSchema={stripeProductPayloadSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, isValid, values }) => {
        const atLeastOneEntered = values.smsCredits || values.emailCredits
        return (
          <Form>
            <Grid container spacing={2} direction="column">
              <BackLink to="/stripe-products" text="Back to Stripe Products" />
              <Grid item>
                <div>
                  <span>
                    <b>{stripeProduct.name}&nbsp;</b>
                  </span>
                  <span>
                    <i>{productCode}</i>
                  </span>
                </div>
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="smsCredits"
                  type="number"
                  label="SMS Credits"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  name="emailCredits"
                  type="number"
                  label="Email Credits"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button type="submit" disabled={!isValid || isSubmitting || !dirty || !atLeastOneEntered}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
