import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { Status } from './fetchRefactoredSchema'
import useFetch from './useFetchRefactored'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { PersonRef } from '../../core/location/utils'

export interface PersonSuggestion {
  id: number
  givenName: string
  familyName: string
  contact: string
  title: string
  groupId: number
  pubnubId: string
}

export interface PersonSuggestionResponse {
  suggestions: PersonSuggestion[]
  count: number
  groupCount: number
}

export interface TimedResponse {
  suggestions: PersonSuggestion[]
  count?: number
  groupCount?: number
  time: number
}

export const mapPersonToSuggestion = (
  { id, givenName, familyName, email, phoneNumber }: PersonRef,
  groupId: number = 0,
): PersonSuggestion => ({
  id,
  givenName: givenName || '',
  familyName: familyName || '',
  contact: email || phoneNumber || '',
  title: '',
  groupId,
  pubnubId: '',
})

export type FetchHookResult<T, S> = [(search?: string, limit?: number, offset?: number) => Promise<T>, Status<S>]

const useFetchPersonSuggestions = ({
  groupId,
  orderDesc = false,
  includeDeleted = false,
  withStat,
  excludeNonGroupMembers = false,
}: {
  groupId?: number
  orderDesc?: boolean
  includeDeleted?: boolean
  withStat?: boolean
  excludeNonGroupMembers?: boolean
} = {}): FetchHookResult<TimedResponse, PersonSuggestion[] | PersonSuggestionResponse> => {
  const currentOrg = useCurrentOrg()
  const [request, status] = useFetch<PersonSuggestion[] | PersonSuggestionResponse>()

  const getPersonSuggestions = useCallback(
    (search: string = '', limit: number = 0, offset: number = 0) => {
      const time = performance.now()
      return request({
        method: 'get',
        url: groupId
          ? `/organizations/${currentOrg.id}/groups/${groupId}/person-suggestions`
          : `/organizations/${currentOrg.id}/person-suggestions`,
        config: {
          queryStringParameters: omitBy(
            {
              search,
              orderDesc,
              limit,
              includeDeleted,
              withStat,
              offset,
              excludeNonGroupMembers,
            },
            isNil,
          ),
        },
      }).then(response => {
        // Old-styled response when we don't fetch count with suggestion list
        if (Array.isArray(response)) return { suggestions: response, time }
        return {
          suggestions: response.suggestions,
          time,
          count: response.count,
          groupCount: response.groupCount,
        }
      })
    },
    [currentOrg.id, excludeNonGroupMembers, groupId, includeDeleted, orderDesc, request, withStat],
  )

  return [getPersonSuggestions, status]
}

export default useFetchPersonSuggestions
