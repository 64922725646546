import React from 'react'

// "borrowed" (with love) from react-router (https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Route.js)
export function Step(props) {
    const { 
        children,
        component,
        render,
        formikBag,
        additionalProps
    } = props

    const renderProps = {
        ...additionalProps,
        ...formikBag
    } 

    return (
        children
        ? typeof children === "function"
            ? children(renderProps)
            : children 
        : component
        ? React.createElement(component, renderProps)
        : render
        ? render(renderProps)
        : null
    )
    
}