import React, { useState } from 'react'
import moment from 'moment'
import { usePolling } from '../hooks/usePolling'
import { Link, Typography, CircularProgress, Button, Grid } from '@material-ui/core'
import { mdiOpenInNew } from '@mdi/js'
import { Icon } from '@mdi/react'
import SalsaMigrationIssuesModal from './SalsaMigrationIssuesModal'
import SalsaRetryFailedMigrations from './SalsaRetryFailedMigrations'

const getButtonInfo = ({
  classes,
  syncInProgress,
  disabled,
}: {
  classes: any
  syncInProgress: boolean
  disabled: boolean
}) => {
  let buttonClass, buttonLabel
  if (disabled) {
    buttonClass = classes.disabledButton
    buttonLabel = syncInProgress ? 'In Progress' : 'Sync'
  } else {
    buttonClass = classes.submitButton
    buttonLabel = 'Sync'
  }
  return { buttonClass, buttonLabel }
}

export default function SalsaSyncSupporters(props: any) {
  const [issuesModalOpen, setIssuesModalOpen] = useState<boolean>(false)
  const { classes, salsaIntegration, loading, syncIntegration, reFetch, resolveSalsaMigrationIssues } = props
  const {
    id,
    lastSyncEndAt,
    lastSyncStartAt,
    hasMigrationIssues,
    pausedAt,
    globallyDisabledAt = null,
  } = salsaIntegration
  const paused = !!pausedAt
  // a sync is in progress if the most recent sync start happened more recently than the most recent sync end
  // however, if it has been over 30 minutes since the last sync start, we can assume that it failed and allow the user to initiate a new sync
  const lastSyncFailed = lastSyncStartAt && moment().isAfter(moment(lastSyncStartAt).add(30, 'minutes'))
  const syncInProgress =
    lastSyncStartAt && (!lastSyncEndAt || moment(lastSyncStartAt).isAfter(moment(lastSyncEndAt))) && !lastSyncFailed
  const somethingInProgress = loading || syncInProgress
  const disabledBecausePausedOrNotConfigured = !id || paused || !!globallyDisabledAt
  const disabled = somethingInProgress || disabledBecausePausedOrNotConfigured
  const { buttonClass, buttonLabel } = getButtonInfo({ classes, syncInProgress, disabled })
  usePolling(reFetch, 5000)
  return (
    <>
      <h2>Sync My Salsa Supporters</h2>
      <div>
        {(syncInProgress || !disabled) && (
          <p className={classes.explanationLabel}>
            {syncInProgress
              ? 'Your Salsa synchronization will be done shortly. This may take a few minutes but you will receive an email when it is complete'
              : 'By clicking the button we will sync your Salsa supporters into Civic Champs. This may take a few moments but you will receive an email when it is complete.'}
          </p>
        )}
        <div>
          <Grid container spacing={4}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={disabledBecausePausedOrNotConfigured ? 2 : 8}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <Button
                      type="submit"
                      className={buttonClass}
                      disabled={disabled}
                      autoFocus
                      onClick={() => syncIntegration(salsaIntegration)}
                    >
                      {somethingInProgress && (
                        <CircularProgress size={24} color="primary" className={classes.buttonProgress} />
                      )}
                      {buttonLabel}
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    {lastSyncEndAt && !disabledBecausePausedOrNotConfigured && (
                      <div className={classes.explanationLabel}>
                        Last Sync {moment(lastSyncEndAt).format('MM/DD/YYYY h:mm a')}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {hasMigrationIssues && !disabledBecausePausedOrNotConfigured && (
                      <Button className={classes.orangeText} component={Link} onClick={() => setIssuesModalOpen(true)}>
                        <span>
                          Review Sync Issues
                          <Icon path={mdiOpenInNew} size={0.8} style={{ verticalAlign: 'middle', fill: '#FF6F3E' }} />
                        </span>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {(!salsaIntegration.id || paused) && (
                <Grid item className={classes.formLabelWrapper} xs={4}>
                  <Typography className={classes.notConnectedLabel}>
                    {paused ? 'Please reconnect to be able to do a full sync' : 'Please complete setup above'}
                  </Typography>
                </Grid>
              )}
              {!disabledBecausePausedOrNotConfigured && (
                <Grid item xs={4}>
                  <SalsaRetryFailedMigrations classes={classes} salsaIntegration={salsaIntegration} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      <SalsaMigrationIssuesModal
        onSubmit={resolveSalsaMigrationIssues}
        open={issuesModalOpen}
        onClose={() => setIssuesModalOpen(false)}
        classes={classes}
      />
    </>
  )
}
