import { useCallback } from 'react'
import { ProgramMeeting } from 'new-mentorship/types'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

export const useFetchMeeting = (): [(id: number) => Promise<ProgramMeeting>, Status<ProgramMeeting>] => {
  const [request, status] = useFetchRefactored<ProgramMeeting>()

  const fetchMeeting = useCallback(
    id => {
      return request({
        method: 'get',
        url: `/mentorship-meetings/${id}`,
      })
    },
    [request],
  )

  return [fetchMeeting, status]
}

export default useFetchMeeting
