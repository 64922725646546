import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        // maxHeight: 525,
        padding: 5
        // overflow: 'auto'
    },
    listItemWrapper: {
        margin: '0 0 15px'
    },
    selected: {
        background: '#E3EBFF'
    },
    listItem: {
        height: 50,
        color: '#707070',
        fontSize: '1rem'
    },
    '@global': {
        '*::-webkit-scrollbar': {
        width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
        }
    },
    searchResult: {
        overflow: 'auto',
        maxHeight: '35vh'
    },
    checkedInSelected: {
        background: '#E3EBFF',
        '& :hover': {
        background: '#E3EBFF'
        }
    },
    checkedOutSelected: {
        background: '#FF6F3E',
        '& div': {
        color: 'white'
        },
        '& :hover': {
        background: '#FF6F3E'
        }
    },
    checkedCheckbox: {
        '& svg': {
        fill: 'white'
        }
    },
    disabled: {
        opacity: 0.3
    },
}));
