import React, { useMemo, useEffect } from 'react'
import Loading from 'civic-champs-shared/core/Loading'
import { Prerequisites, ViewAnswerSetsContainerProps } from 'civic-champs-shared/question-sets/types'
import { useFetchRequirements } from 'civic-champs-shared/question-sets/hooks'

import Waivers from 'civic-champs-shared/question-sets/components/Waivers'
import { makeStyles, Grid } from '@material-ui/core'

const useStyle = makeStyles(() => ({
  waiverContainer: ({ fullHeight }: { fullHeight: boolean }) => ({
    height: fullHeight ? '100%' : '359px',
    overflowY: 'auto',
    textAlign: 'left',
  }),
}))

const useCurrentPrevNextAnswerSet = (requirements: Prerequisites) =>
  useMemo(() => {
    const { credentials } = requirements
    return { credentials }
  }, [requirements])

export const ProcessMemberWaiverContainer = (props: ViewAnswerSetsContainerProps) => {
  const { filters, fullHeight } = props
  const classes = useStyle({ fullHeight })
  const [fetchRequirements, { result: requirements, loading, called }] = useFetchRequirements()

  useEffect(() => {
    if (!!filters) fetchRequirements(filters)
  }, [filters, fetchRequirements])

  const { credentials } = useCurrentPrevNextAnswerSet(requirements)

  return (
    <Grid container direction="column" className={classes.waiverContainer}>
      {(loading || !called) && <Loading />}
      {credentials && <Waivers waivers={credentials} className={classes.waiverContainer} />}
    </Grid>
  )
}
