import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { TaskStatusDisplayNames } from '../TaskStatus'
import StatusButton from './StatusButton'
import { useAdminCancelTask } from '../hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import { useHistory } from 'react-router'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import useIsActive from '../hooks/useIsActive'

const useStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.neutral.white,
    border: '1px solid',
    borderColor: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.main,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.danger.dark,
    },
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    lineHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  title: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    alignContent: 'center',
  },
}))

export default function TaskHeader(props) {
  const { task, refresh } = props
  const classes = useStyles()
  const isActive = useIsActive(task)
  const [cancelTask] = useAdminCancelTask()
  const confirmPrompt = useConfirm()
  const history = useHistory()
  const showError = useErrorNotification()

  const handleCancelClick = async () => {
    const confirm = await confirmPrompt('Are you sure you want to cancel this event?', {
      title: 'Are you sure?',
      confirmText: 'Yes',
      rejectText: 'No',
    })

    if (confirm) {
      try {
        await cancelTask(task)
        history.push('/helping-hands')
      } catch (err) {
        showError('There was a problem cancelling the task.', err)
      }
    }
  }
  return (
    <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.row}>
      <Grid item>
        <div className={classes.titleRow}>
          <Typography variant="h4" className={classes.title}>
            Task
          </Typography>
          <StatusButton task={task} refreshParent={refresh} />
          {isActive && (
            <Button onClick={handleCancelClick} variant="contained" className={classes.cancelButton}>
              Cancel Task
            </Button>
          )}
        </div>
      </Grid>
      <Grid item>
        <Typography className={classes.header}>Status</Typography>
        <Typography className={classes.text}>{TaskStatusDisplayNames[task.taskStatus]}</Typography>
      </Grid>
    </Grid>
  )
}
