import { makeStyles } from '@material-ui/core/styles'
import { basicFileDialogStyles } from '../utils/basicFileDialogStyles'

export const useVolunteerImportDialogStyles = makeStyles({
  ...(basicFileDialogStyles as any),
  title: {
    ...basicFileDialogStyles.title,
    marginBottom: '12px',
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    textAlign: 'left',
    color: '#74777F',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '4px',
  },
  dialogCloseIcon: {
    fontSize: '24px',
    color: 'black',
  },
  helpBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helpButton: {
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  history: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  historyItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default useVolunteerImportDialogStyles
