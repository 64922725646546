import React, { useMemo } from 'react'
import { Grid, Typography, Box, Button, Link, makeStyles } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy, CellProps } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import { DateCell, PersonNameCell } from 'core/table/cells'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import useBadContactsSummary from '../hooks/useBadContactsSummary'
import { ClearBadContactsPrompt } from '../components/ClearBadContactsPrompt'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

const useColumns = (refresh) => {
  const showClearBadContactsModal = useShowPrompt(ClearBadContactsPrompt)
  return useMemo(
    () => [
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateCell,
        filter: 'dateRange',
      },
      { Header: 'Contact', accessor: 'contact' },
      { Header: 'Bounces', accessor: 'count' },
      { Header: 'Status', Cell: ({ cell }) => <div>{cell.row.original.count > 1 ? 'BLOCKED' : 'Still active'}</div> },
      {
        Header: 'Sent To',
        accessor: 'sentTo',
        Cell: PersonNameCell,
      },
      {
        Header: 'Current Owner',
        accessor: 'currentOwner',
        Cell: PersonNameCell,
      },
      {
        Header: '',
        id: 'view',
        Cell: ({ cell }) => (
          <Button variant="contained" color="primary" onClick={() => showClearBadContactsModal({ refresh, badContact: cell.row.original })}>
            CLEAR
          </Button>
        ),
      },
    ],
    [showClearBadContactsModal, refresh],
  )
}

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.contact',
      'original.sentTo.givenName',
      'original.sentTo.familyName',
      'original.currentOwner.givenName',
      'original.currentOwner.familyName',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const BadContactsOverview = () => {
  const { result: badContacts, loading, refresh } = useBadContactsSummary()
  const [dateRange, setDateRange] = useDateRangeFilter()
  const table = useTable(
    {
      data: badContacts,
      columns: useColumns(refresh),
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} item>
            <Typography variant="h4">Bounced Contacts</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
