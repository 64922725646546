import { get, pickBy, iteratee } from 'lodash'
import { fork, takeEvery, select, put, call } from 'redux-saga/effects'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import {
  GET_PRODUCT_FEATURES_FOR_ORG,
  GET_FEATURES_FOR_USER_SUCCEEDED,
  FEATURES_INITIALIZED,
  getProductFeaturesForOrg,
  getProductFeaturesForOrgFailed,
  getProductFeaturesForOrgSucceeded,
} from '../actions'
import { currentOrganization } from 'auth/selectors'
import { isProductFeaturesInitialized } from '../selectors'
import { START_MANAGING_ORG } from 'auth/actions'

const isProduct = iteratee({ type: 'product' })
const getProductFeatures = features => pickBy(features, isProduct)

export default function* productFeatureRootSaga() {
  yield fork(watchForGetProductFeaturesEvents)
  yield fork(watchForGetUserFeaturesEvents)
  yield fork(watchForFeatureInitializedEvents)
  yield fork(watchForStartManagingOrg)
}

export function* watchForStartManagingOrg() {
  yield takeEvery(START_MANAGING_ORG, onStartManagingOrgEvent)
}

export function* onStartManagingOrgEvent(manageOrgEvent) {
  const { organization } = manageOrgEvent.payload
  yield put(getProductFeaturesForOrg(organization))
}

export function* watchForFeatureInitializedEvents() {
  yield takeEvery(FEATURES_INITIALIZED, onFeaturesInitializedEvent)
}

export function* onFeaturesInitializedEvent() {
  const { allFeatures, prevFeatures, productFeaturesOrgId, organization } = yield select(state => ({
    allFeatures: get(state, 'features.settings', {}), //TODO use key, use selector?
    prevFeatures: get(state, 'productFeatures.settings', {}),
    productFeaturesOrgId: get(state, 'productFeatures.organization.id'),
    organization: currentOrganization(state),
  }))
  const orgId = get(organization, 'id')
  const canReusePrevFeatures = productFeaturesOrgId != null && productFeaturesOrgId === orgId

  const productFeatures = canReusePrevFeatures ? prevFeatures : getProductFeatures(allFeatures)

  yield put(getProductFeaturesForOrgSucceeded(organization, productFeatures)) //TODO this should be its own event!
}

export function* watchForGetUserFeaturesEvents() {
  yield takeEvery(GET_FEATURES_FOR_USER_SUCCEEDED, onGetUserFeaturesEvent)
}

export function* onGetUserFeaturesEvent(resultEvent) {
  const { user, features } = resultEvent.payload

  const resultOrgId = get(user, 'permissions.managedOrganization.id')
  const desiredOrg = yield select(currentOrganization)
  const desiredOrgId = get(desiredOrg, 'id')
  const isInitialized = yield select(isProductFeaturesInitialized)

  if (!isInitialized || resultOrgId === desiredOrgId) {
    const productFeatures = getProductFeatures(features)
    yield put(getProductFeaturesForOrgSucceeded(desiredOrg, productFeatures))
  }
}

export function* watchForGetProductFeaturesEvents() {
  yield takeEvery(GET_PRODUCT_FEATURES_FOR_ORG, onGetProductFeaturesEvent)
}

export function* onGetProductFeaturesEvent(fetchEvent) {
  const { organization } = fetchEvent.payload

  const url = `/organizations/${organization.id}/features`
  const config = {
    queryStringParameters: {
      type: 'product',
    },
  }

  try {
    const features = yield call(requestWithRetry, { url, config })
    yield put(getProductFeaturesForOrgSucceeded(organization, features))
  } catch (error) {
    yield put(getProductFeaturesForOrgFailed(organization, error))
  }
}
