export const REQUEST_SET_FULLSCREEN = 'REQUEST_SET_FULLSCREEN';
export const UPDATE_FULLSCREEN_STATUS = 'UPDATE_FULLSCREEN_STATUS';

export const requestSetFullscreen = (isFullscreen) => ({
    type: REQUEST_SET_FULLSCREEN,
    payload: { isFullscreen }
});

export const updateFullscreenStatus = (isFullscreen) => ({
    type: UPDATE_FULLSCREEN_STATUS,
    payload: { isFullscreen }
});