import React  from 'react'
import { Field, Form, useFormikContext } from 'formik'
import { useFormikWizardContext } from 'civic-champs-shared/formik/FormikWizard'
import { TextField } from 'formik-material-ui'
import { makeStyles } from '@material-ui/core'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import cn from 'classnames'

import CKEditorField from 'civic-champs-shared/formik/components/CkEditorField'
import UploadedImageField from 'civic-champs-shared/formik/components/UploadedImageField'
import SummaryInfoDonations from './SummaryInfoDonations'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'

const useStyles = makeStyles(theme => ({
    buttonContainer: {
      marginBottom: '58px',
      marginTop: '10px',
    },
    content:{
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      flexDirection: 'column'
      },
    titleText: {
      fontSize: '24px',
      fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif'
    },
    subTitle: {
        fontSize: '19px',
        fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif',
        marginTop: '46px',
      },
    titleInput:{
      borderRadius: '2px',
      width: '212px',
      heiht: '38px',
      marginBottom: '28px',
    },
    editorContainer: {
      width: '757px',
    },
    bottomContainer: {
      marginBottom: '40px',
    },
    error: {
      color: 'red',
      fontSize: '13px',
      marginLeft: '15px',
      marginTop: '5px',
    },
    saveDraft: {
      width: '146px',
      height: '49px',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
  },
  summaryContainer: {
      borderRadius: '4px 0 0 0',
      boxShadow: '0 4px 7px rgba(100, 124, 142, 0.18)',
      borderTop: '1px solid #ABB6CC',
      borderLeft: '1px solid #ABB6CC',
      borderBottom: '1px solid #ABB6CC',
      padding: '21px 29px',
      marginRight: '-85px',
      height: '750px',
  }  
}));

export default function ThankYou(props) {
    const { errors, touched } = props

    const allValues = useFormikContext()
    const stepInfo = useFormikWizardContext()
    const fullStepData = {...allValues.values, ...stepInfo}

    const classes = useStyles()
    return (
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <h2 className={classes.titleText}>Thank You Prompt</h2>  
          <Form>
            <Field 
              component={TextField}
              name="thankYouPrompt.title"
              className={classes.titleInput}
              placeholder="Thank you title"
              variant="outlined"
            />
            <div className={classes.editorContainer}>
                <Field 
                  component={CKEditorField}
                  editor={ClassicEditor}
                  config={{
                    toolbar: ['bold', 'italic', 'undo', 'redo',]
                  }}
                  name="thankYouPrompt.firstParagraph"
                />
              </div>
              <Field
                component={UploadedImageField}
                name="thankYouPrompt.image"
              />
              <div className={cn([classes.editorContainer, classes.bottomContainer])} >
                <Field 
                  component={CKEditorField}
                  editor={ClassicEditor}
                  config={{
                    toolbar: ['bold', 'italic', 'undo', 'redo',]
                  }}
                  name="thankYouPrompt.secondParagraph"
                />
              </div>
              <Field 
                  component={TextField}
                  name="thankYouPrompt.buttonText"
                  className={classes.titleInput}
                  placeholder="Button text"
                  variant="outlined"
              />
              <div className={classes.buttonContainer}>
                <StepNavigation />
              </div>
          </Form>
        </div>
        <div className={classes.summaryContainer}>
            <SummaryInfoDonations currentStep={fullStepData}/>
        </div>
       </div> 
    )
}

ThankYou.SCHEMA_FIELDS = [
  'thankYouPrompt.title',
  'thankYouPrompt.firstParagraph',
  'thankYouPrompt.image',
  'thankYouPrompt.secondParagraph',
  'thankYouPrompt.buttonText',
]
