import React, { useCallback } from 'react'
import { usePrompt, usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { useCurrentOrg } from 'auth/hooks'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { KeyboardDatePicker, KeyboardTimePicker } from 'formik-material-ui-pickers'
import { slotIsPast } from '../../../volunteering/tasks/utils'
import { Schedule } from '@material-ui/icons'
import useTestReminder from './useTestReminders'
import { TextField } from 'formik-material-ui'
import { validateEmail } from '../../../Event/helpers/validation'
import cn from 'classnames'
import CloseIcon from '@material-ui/icons/Close'

function UseTestReminderPrompt(props) {
  const { showing, close } = props
  const classes = usePromptStyles()
  const org = useCurrentOrg()
  const [testReminder] = useTestReminder()
  const tz = get(org, 'timeZone', 'America/New_York')

  const initialValues = {
    date: moment()
      .tz(tz)
      .add('1', 'day'),
    startTime: moment()
      .tz(tz)
      .add('1', 'day'),
    endTime: moment()
      .tz(tz)
      .add('1', 'day')
      .add(6, 'hours'),
    email: '',
  }

  const validateForm = values => {
    const errors = {}
    if (!values.date) {
      errors.date = 'Date is required'
    }
    if (!values.startTime) {
      errors.startTime = 'Start is required'
    }
    if (!values.endTime) {
      errors.endTime = 'End is required'
    }
    if (!values.email || !validateEmail(values.email)) {
      errors.email = 'A valid email is required'
    }
    if (!isEmpty(errors)) return errors

    const newSlot = {
      startTime: moment(values.date)
        .hour(values.startTime.hour())
        .minute(values.startTime.minute()),
      endTime: moment(values.date)
        .hour(values.endTime.hour())
        .minute(values.endTime.minute()),
    }
    if (slotIsPast(newSlot)) {
      errors.startTime = 'Timeslot start must be in the future.'
    }
    if (newSlot.endTime.isBefore(newSlot.startTime)) {
      errors.endTime = 'Timeslot end must be after start.'
    }
    return errors
  }

  const handleFormikSubmit = async values => {
    await testReminder({
      start: moment(values.date)
        .hour(values.startTime.hour())
        .minute(values.startTime.minute()),
      end: moment(values.date)
        .hour(values.endTime.hour())
        .minute(values.endTime.minute()),
      email: values.email,
    })
    close()
  }

  return (
    <Dialog fullScreen className={cn({ fullScreen: true })} open={showing} onClose={close} disableBackdropClick={true}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Test Reminder Emails</Typography>
        <IconButton onClick={close} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.dialog}>
          <Formik initialValues={initialValues} onSubmit={handleFormikSubmit} validate={validateForm}>
            {formikProps => {
              const { handleSubmit, values, isSubmitting } = formikProps
              return (
                <Form onSubmit={handleSubmit}>
                  <Field
                    name="date"
                    component={KeyboardDatePicker}
                    label="Date"
                    inputVariant="outlined"
                    className={classes.field}
                    disablePast
                  />
                  <Field
                    name="startTime"
                    component={KeyboardTimePicker}
                    label="Start Time"
                    inputVariant="outlined"
                    className={classes.field}
                    disablePast
                    keyboardIcon={<Schedule />}
                  />
                  <Field
                    name="endTime"
                    component={KeyboardTimePicker}
                    label="End Time"
                    inputVariant="outlined"
                    className={classes.field}
                    disablePast
                    minDate={values.startTime}
                    keyboardIcon={<Schedule />}
                  />
                  <Field fullWidth variant="outlined" component={TextField} name="email" label="Email" />
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                    Send
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const useTestReminderPrompt = () => {
  const prompt = usePrompt(UseTestReminderPrompt)

  return useCallback(() => prompt.show(), [prompt])
}

export default useTestReminderPrompt
