import { useCallback, useEffect } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import useAddRequiredGroupMemberPrompt from './useAddRequiredGroupMemberPrompt'
import REQUIRED_GROUP_TYPES from '../requiredGroupTypes'

const STABLE_EMPTY_ARRAY = [] //TODO this is reel dum

const useTaskVolunteers = () => {
  const organization = useCurrentOrg()
  const [request, { result, loading, called }] = useFetch()

  const getTaskVolunteers = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/task_volunteers`,
    })
  }, [organization.id, request])

  useEffect(() => {
    getTaskVolunteers()
  }, [getTaskVolunteers])
  const onOpenAddVolunteerPrompt = useAddRequiredGroupMemberPrompt(REQUIRED_GROUP_TYPES.volunteer, getTaskVolunteers)

  return {
    volunteers: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh: getTaskVolunteers,
    onOpenAddVolunteerPrompt,
  }
}

export default useTaskVolunteers
