import * as yup from 'yup'

export enum TagEntityType {
  MentorshipMatch = 'mentorship_match',
  Group = 'group',
  Champion = 'champion',
}

export enum TagColors {
  Blue = '#aac7f5',
  Yellow = '#f9de95',
  Green = '#7fd1b3',
  Red = '#ed9a97',
  Purple = '#e8c5ed',
  Pink = '#fab3cc',
  Teal = '#b0ebeb',
  Orange = '#ffcd92',
}

export interface TagPayload {
  id?: number
  name: string
  description?: string
  color: string
  colorName: string
  order?: number
}

export interface TagColor {
  htmlColor: string
  colorName: string
}

export interface Tag extends TagPayload {
  id: number
  organizationId: number
}

export interface TagWithReporting extends Tag {
  usedIn: TagEntityType[]
  usedTimes: number
}

export const tagPayloadSchema = yup.object({
  name: yup.string().required(),
  color: yup.object({
    htmlColor: yup.string().required(),
    colorName: yup.string().required(),
  }),
})


