import React from 'react'
import cn from 'classnames'
import { Redirect } from 'react-router'
import { Route, Switch, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import { AddButton } from 'civic-champs-shared/core/add-button'
import { MonthView } from './MonthView'
import { WeekView } from './WeekView'
import { DayView } from './DayView'
import { currentViewTypeSelector } from '../../redux/reducers/ViewEvents'
import { viewTypes } from './viewTypes'

const useStyles = makeStyles((theme) => ({
    calendarScene: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 48px)',

        '&$dayView': {
            height: 'auto !important',
        },
    },
    dayView: {},
    eventsHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 80,
        '& button': {
            marginLeft: 8
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 16,
        }
    },
}))

const viewTypeToComponentMap = {
    [viewTypes.DAY]: DayView,
    [viewTypes.WEEK]: WeekView,
    [viewTypes.MONTH]: MonthView,
}

export default function Events(props) {
    const { history } = props
    const params = useParams()
    const persistedViewType = useSelector(currentViewTypeSelector)
    const viewType = params.viewType || persistedViewType
    const classes = useStyles()

    const handleCreate = () => history.push('/event/new-event/basic')

    return (
      <article className={cn(classes.calendarScene, {
          [classes.dayView]: viewType === viewTypes.DAY,
      })}>
          <header className={classes.eventsHeader}>
              <Grid xs={12} container alignItems="center" item>
                  <Grid item>
                      <Typography variant="h4">Events</Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: 10 }}>
                      <AddButton onClick={handleCreate} />
                  </Grid>
              </Grid>
          </header>

          <Switch>
              <Route
                path={'/events/:viewType'}
                render={() => {
                    const Component = viewTypeToComponentMap[viewType]

                    return <Component />
                }}
                />
              <Redirect from="/events" to={`/events/${viewType}`} />
          </Switch>
      </article>
    )
}
