import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { DatePicker, DateTimePicker, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

export const StyledDateTimePicker = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3d3d3d',
    width: '100%',
    '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      lineHeight: '20px',
      minHeight: '45px',
      '& input.MuiOutlinedInput-input': {
        padding: '14px',
      },
      '& fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid',
      },
    },
  },
})(DateTimePicker)

const usePickerStyles = makeStyles({
  root: ({ higher }: { higher?: boolean }) => ({
    ...(higher ? {} : { marginTop: '10px', marginBottom: '10px' }),
    color: '#3d3d3d',
    width: '100%',
  }),
  inputRoot: {
    fontSize: '16px',
    lineHeight: '20px',
    minHeight: '45px',
    '&$focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87) !important',
      border: '1px solid !important',
    },
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
  },
  input: ({ higher }: { higher?: boolean }) =>
    higher
      ? {}
      : {
          padding: '14px',
        },
  focused: {},
  labelFocused: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  labelFilled: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  labelOutlined: {
    '&:not($labelShrink)': ({ higher }: { higher?: boolean }) =>
      higher
        ? {}
        : {
            transform: 'translate(14px, 14px) scale(1)',
          },
  },
  labelShrink: {},
})

export const StyledKeyboardTimePicker = ({ higher, ...props }: any) => {
  const classes = usePickerStyles({ higher })
  return (
    <KeyboardTimePicker
      {...props}
      classes={{ root: classes.root }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          focused: classes.focused,
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          focused: classes.labelFocused,
          filled: classes.labelFilled,
          outlined: classes.labelOutlined,
          shrink: classes.labelShrink,
        },
      }}
      keyboardIcon={<AccessTimeIcon />}
    />
  )
}

export const StyledKeyboardDatePicker = ({ higher, ...props }: any) => {
  const classes = usePickerStyles({ higher })
  return (
    <KeyboardDatePicker
      {...props}
      format={'MM/DD/YYYY'}
      classes={{ root: classes.root }}
      InputProps={{
        ...props.InputProps,
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          focused: classes.focused,
        },
      }}
      InputLabelProps={{
        ...props.InputLabelProps,
        classes: {
          focused: classes.labelFocused,
          filled: classes.labelFilled,
          outlined: classes.labelOutlined,
          shrink: classes.labelShrink,
        },
      }}
    />
  )
}

export const StyledDatePicker = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3d3d3d',
    width: '100%',
    '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      lineHeight: '20px',
      minHeight: '45px',
      '& input.MuiOutlinedInput-input': {
        padding: '14px',
      },
      '& fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid',
      },
    },
  },
})(DatePicker)
