import React, { useCallback } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { usePromptStyles } from './hooks'
import cn from 'classnames'

export default function ConfirmPrompt(props) {
  const classes = usePromptStyles()
  const {
    type, // info | confirm | success | warning | error
    title,
    content,
    subText,
    confirmText,
    rejectText,
    showing,
    complete,
    children,
  } = props

  const onConfirm = useCallback(() => complete(true), [complete])
  const onReject = useCallback(() => complete(false), [complete])

  return (
    <Dialog className={cn(`${type}Prompt`)} open={showing} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogTitle className={cn(classes.titleContainer, classes.windowedTitleContainer)}>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={cn(classes.contentContainer, classes.windowedContentContainer)}>
        <Typography className={classes.content}>{content}</Typography>
        {subText && <Typography className={classes.subText}>{subText}</Typography>}
        {children}
      </DialogContent>
      <DialogActions className={cn(classes.actionContainer, classes.windowedActionContainer)}>
        <Button className={classes.actionButton} color="secondary" onClick={onReject}>
          {rejectText}
        </Button>
        <Button className={classes.actionButton} variant="contained" color="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
