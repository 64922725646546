import React, { useMemo, useState } from 'react'
import * as yup from 'yup'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import Loading from 'civic-champs-shared/core/Loading'
import { map } from 'lodash'
import Grid from '@material-ui/core/Grid'
import StyledSelect, { MenuItem } from 'civic-champs-shared/formik/components/StyledSelect'
import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer'
import useUpdateProgram from 'new-mentorship/hooks/useUpdateProgram'
import useFetchSurveys from 'new-mentorship/hooks/useFetchSurveys'
import { SurveyWithQuestionSet } from 'surveys/types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import keyBy from 'lodash/keyBy'
import first from 'lodash/first'
import { ReadOnlyQuestionSetQuestions } from 'civic-champs-shared/question-sets/components/ReadOnlyQuestionSetQuestions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

const formSchema = yup.object({})

export default function SelectProgramSurveysStep(props: any) {
  const classes = useModalStyles()
  const { onSuccess, program, back } = props
  const [mentorQuestionSetExpanded, setMentorQuestionSetExpanded] = useState<boolean>(false)
  const [menteeQuestionSetExpanded, setMenteeQuestionSetExpanded] = useState<boolean>(false)

  const initialValues = {
    mentorSurveyId: program?.mentorSurveyId,
    menteeSurveyId: program?.menteeSurveyId,
  }
  const [updateProgram] = useUpdateProgram()
  const { surveys, loading } = useFetchSurveys()

  const handleFormikSubmit = async ({
    mentorSurveyId,
    menteeSurveyId,
  }: {
    mentorSurveyId: number
    menteeSurveyId: number
  }) => {
    const updatePayload: any = {}
    if (mentorSurveyId) updatePayload.mentorSurveyId = mentorSurveyId
    if (menteeSurveyId) updatePayload.menteeSurveyId = menteeSurveyId

    if (Object.keys(updatePayload).length) {
      updateProgram(program.id, updatePayload).then(onSuccess)
    } else {
      onSuccess(program)
    }
  }

  const surveyIndex = useMemo(() => keyBy(surveys, 'id'), [surveys])

  if (loading) return <Loading />
  return (
    <>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleFormikSubmit}>
        {({ isSubmitting, values }) => {
          const mentorQuestions = first(surveyIndex[values.mentorSurveyId]?.versions)?.questionSet?.questions
          const menteeQuestions = first(surveyIndex[values.menteeSurveyId]?.versions)?.questionSet?.questions
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ExpansionPanel
                    disabled={isSubmitting}
                    expanded={mentorQuestionSetExpanded}
                    style={{ boxShadow: 'none' }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon onClick={() => setMentorQuestionSetExpanded(prev => !prev)} />}
                      aria-controls="mentor-survey-content"
                      id="mentor-survey-header"
                    >
                      <Field
                        name="mentorSurveyId"
                        variant="outlined"
                        fullWidth
                        component={StyledSelect}
                        label={values.mentorSurveyId ? 'Mentor Survey' : 'Select a Survey for Mentors'}
                        labelId="mentorSurveyId-label"
                      >
                        {map(
                          surveys,
                          ({
                            id,
                            versions: [
                              {
                                questionSet: { name },
                              },
                            ],
                          }: SurveyWithQuestionSet) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {mentorQuestions ? <ReadOnlyQuestionSetQuestions questions={mentorQuestions} /> : ''}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
                <Grid item xs={12} style={{ padding: '4px 24px' }}>
                  <div style={{ borderBottom: '1px solid #74777F', width: '100%' }} />
                </Grid>
                <Grid item xs={12}>
                  <ExpansionPanel
                    disabled={isSubmitting}
                    expanded={menteeQuestionSetExpanded}
                    style={{ boxShadow: 'none' }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon onClick={() => setMenteeQuestionSetExpanded(prev => !prev)} />}
                      aria-controls="mentee-survey-content"
                      id="mentee-survey-header"
                    >
                      <Field
                        name="menteeSurveyId"
                        variant="outlined"
                        fullWidth
                        component={StyledSelect}
                        label={values.menteeSurveyId ? 'Mentee Survey' : 'Select a Survey for Mentees'}
                        labelId="menteeSurveyId-label"
                      >
                        {map(
                          surveys,
                          ({
                            id,
                            versions: [
                              {
                                questionSet: { name },
                              },
                            ],
                          }: SurveyWithQuestionSet) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {menteeQuestions ? <ReadOnlyQuestionSetQuestions questions={menteeQuestions} /> : ''}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                classes={{ root: classes.buttonsContainer }}
              >
                <OutlinedButton disabled={isSubmitting} onClick={back}>
                  Back
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} type="submit">
                  Set Surveys
                </ContainedButton>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
