import { isEmpty } from 'lodash'
import { useCallback, useState } from 'react'
import { useLatest } from 'react-use'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ProcessMemberPrompt from 'group/components/ProcessMemberPrompt'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

export default function useProcessMemberApprovals(refresh) {
  const [loading, setLoading] = useState(false)
  const loadingRef = useLatest(loading)

  const processVolunteerPrompt = useShowPrompt(ProcessMemberPrompt)
  const showError = useErrorNotification()

  const processApprovals = useCallback(
    async (group, type, chosenMemberID) => {
      if (loadingRef.current) return

      //TODO this is stomping on useFetch but it has to get called each time :-/
      setLoading(true)
      try {
        const allMembers = await requestWithRetry({ url: `/groups/${group.id}/members` })
        if (isEmpty(allMembers)) {
          showError(`Group '${group.name}' does not have any applicants`)
        } else {
          processVolunteerPrompt({
            refresh,
            allMembers,
            groupName: group.name,
            type,
            chosenMemberID,
          })
        }
      } catch (error) {
        showError(`An error occurred while loading applicants`, error)
      } finally {
        setLoading(false)
      }
    },
    [loadingRef, processVolunteerPrompt, refresh, showError],
  )

  return { loading, processApprovals }
}
