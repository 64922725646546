import { get } from 'lodash'
import React, { FC } from 'react'
import { Button } from "@material-ui/core"
import { mdiMonitorStar } from '@mdi/js'

import useStartKiosk from "kiosk/hooks/useStartKiosk"
import Icon from '@mdi/react'

const KioskIcon = (props: any) => (
    <Icon 
        path={mdiMonitorStar}
        size={1}
        color="#5C8DE8"
        {...props}
    />
)

interface Props {
    opportunity: any
    disabled?: boolean
}

export const StartKioskButton: FC<Props> = (props) => {
    const { opportunity, disabled=false } = props

    const startKiosk = useStartKiosk()

    return (<Button
        variant="outlined"
        color="secondary"
        startIcon={<KioskIcon/>}
        disabled={disabled}
        onClick={() => startKiosk(opportunity)}
    >
        Kiosk 
    </Button>)
}
