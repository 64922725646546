import React from 'react'
import { Question, QuestionType } from '../types'
import { OTHER_OPTION } from '../utils'
import * as _ from 'lodash'

export default function Answers({
  answers,
  showQuestionLabelEvenIfNotCompleted = false,
  questionClassName,
  answerClassName,
}: {
  answers: Question[]
  showQuestionLabelEvenIfNotCompleted?: boolean
  questionClassName?: string
  answerClassName?: string
}) {
  const getDisplayResponse = (answer: Question) => {
    const { response, otherAnswer, completed } = answer

    let displayResponse = null
    switch (answer.questionType) {
      case QuestionType.EMERGENCY_CONTACT:
        if (response) {
          const { relationship = '', givenName, familyName, phoneNumber, workPhoneNumber, homePhoneNumber } = response
          displayResponse = `${relationship}${relationship ? ': ' : ''}${givenName} ${familyName}, ${
            phoneNumber || workPhoneNumber || homePhoneNumber
          }`
        }
        break
      case QuestionType.HOME_ADDRESS:
        if (response?.location?.address) {
          const { line1, line2, city, state, zip } = response.location.address
          displayResponse = `${line1}${line2 || ''}, ${city}, ${state} ${zip}`
        }
        break
      case QuestionType.NUMBER:
        displayResponse = String(response)
        break
      default:
        displayResponse = Array.isArray(response)
          ? response.filter(selection => selection !== OTHER_OPTION).join(', ')
          : response
    }

    const displayOther = otherAnswer ? `Other: ${otherAnswer}` : ''
    const finalDisplay = completed ? _.filter([displayResponse, displayOther]).join(', ') : '-'

    return finalDisplay
  }

  return (
    <>
      {answers
        .filter((answer: Question) => showQuestionLabelEvenIfNotCompleted || answer.completed)
        .map((answer: Question, key: number) => {
          const { otherAnswer, question, completed } = answer
          const displayResponse = getDisplayResponse(answer)
          const displayOther = otherAnswer ? `Other: ${otherAnswer}` : ''
          const finalDisplay = completed ? _.filter([displayResponse, displayOther]).join(', ') : '-'

          return (
            <div key={key}>
              <label className={questionClassName}>
                <b>{question}</b>
              </label>
              <p className={answerClassName}>
                <i>{finalDisplay}</i>
              </p>
            </div>
          )
        })}
    </>
  )
}
