import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import cn from 'classnames'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { ReactComponent as UploadIcon } from 'images/fileUpload.svg'
import { readFile } from 'champion/utils/files'
import useVolunteerImportDialogStyles from '../hooks/useVolunteerImportDialogStyles'

enum DragState {
  None,
  Valid,
  Invalid,
}

const isValidFile = (e: React.DragEvent<any>): boolean => {
  if (e.dataTransfer.items.length !== 1) return false
  const item = e.dataTransfer.items[0]
  return item.kind === 'file' && item.type === 'text/csv'
}

const getFile = (e: React.DragEvent<any>): File | null => {
  if (isValidFile(e)) {
    return e.dataTransfer.items[0].getAsFile()
  }
  return null
}

export function ImportDragContainer<B extends boolean, T extends B extends true ? string : File>({
  setFile,
  readFromFile,
}: {
  setFile: Dispatch<SetStateAction<T | null>> | ((file: T) => void | Promise<void>)
  readFromFile: B
}) {
  const classes = useVolunteerImportDialogStyles()
  const [state, setState] = useState(DragState.None)
  const inputFileRef = React.useRef<any>()
  const handleClick = useCallback(() => {
    inputFileRef.current?.click()
  }, [])

  return (
    <div
      className={cn(classes.dragndrop, {
        [classes.valid]: state === DragState.Valid,
        [classes.invalid]: state === DragState.Invalid,
      })}
      onDrop={e => {
        e.preventDefault()
        const file = getFile(e)
        if (!file) return
        if (readFromFile) {
          readFile(file).then((text: string) => setFile(text as any as T))
        } else {
          setFile(file as any as T)
        }
      }}
      onDragOver={e => {
        e.preventDefault()
        if (isValidFile(e)) {
          setState(DragState.Valid)
        } else {
          setState(DragState.Invalid)
        }
      }}
      onDragLeave={e => {
        setState(DragState.None)
      }}
    >
      <UploadIcon />
      <p className={classes.dragTitle}>
        {state === DragState.Invalid ? 'Incorrect File Type' : 'Drop Import File Here or'}
      </p>
      <OutlinedButton className={classes.button} onClick={handleClick}>
        Browse Files
      </OutlinedButton>
      <input
        ref={inputFileRef}
        className={classes.hidden}
        onChange={e => {
          const file = (e.target.files as FileList)[0]
          if (readFromFile) {
            readFile(file).then((text: string) => setFile(text as any as T))
          } else {
            setFile(file as any as T)
          }
        }}
        type="file"
        accept="text/csv"
      />
      <p className={classes.supports}>Supports CSV</p>
    </div>
  )
}

export default ImportDragContainer
