import React from 'react'
import isBoolean from 'lodash/isBoolean'
import { ReactComponent as ThumbUpIcon } from 'images/like.svg'
import { ReactComponent as ThumbDownIcon } from 'images/dislike.svg'

interface Props {
  positive: boolean | undefined
  width?: string | number
  height?: string | number
}

function RatingIcon({ positive, ...props }: Props) {
  if (!isBoolean(positive)) {
    return null
  }
  return <div className="rating_icon">{positive ? <ThumbUpIcon {...props} /> : <ThumbDownIcon {...props} />}</div>
}

export default RatingIcon
