import { makeStyles } from '@material-ui/core'

export const useLegacyTableStyles = makeStyles(theme => ({
  filterHeader: {
    backgroundColor: '#FFF0B2 !important',
  },
  sortAsc: {
    boxShadow: 'inset 0 3px 0 0 rgba(0,0,0,.6)',
  },
  sortDesc: {
    boxShadow: 'inset 0 -3px 0 0 rgba(0,0,0,.6)',
  },
  selectedRow: {
    background: '#5C8DE8',
  },
  selectedCell: {
    color: 'white',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    msTextOverflow: 'ellipsis',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
    border: '1px solid rgba(0,0,0,0.05)',
    borderLeft: '1px solid rgba(0,0,0,0.02)',
    borderRight: '1px solid rgba(0,0,0,0.02)',
    '&:first-child': {
      'border-left': '0',
    },
    '&:last-child': {
      'border-right': '0',
    },
  },
  header: {
    cursor: 'pointer',
  },
  headerCell: {
    paddingRight: '16px',
    backgroundColor: '#B8CDF5',
    fontWeight: 600,
  },
  selectHeaderCell: {
    // @ts-ignore
    backgroundColor: theme.palette.background.green,
  },
  cell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    msTextOverflow: 'ellipsis',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
    border: '1px solid rgba(0,0,0,0.05)',
    borderLeft: '1px solid rgba(0,0,0,0.02)',
    borderRight: '1px solid rgba(0,0,0,0.02)',
    '&:first-child': {
      'border-left': '0',
    },
    '&:last-child': {
      'border-right': '0',
    },
  },
  warningRow: {
    // @ts-ignore
    backgroundColor: theme.palette.background.warning,
    '& td': {
      fontWeight: 'bold',
    },
  },
  resizer: {
    display: 'inline-block',
    width: '10px',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: 1,
    touchAction: 'none',
  },
  wrapHeader: {
    whiteSpace: 'normal',
  },
}))
