import React from 'react'
import NewTimeslotPicker from '../components/NewTimeslotPicker'

export default function TimeslotsField({ field, form, ...props }) {
    const handleChange = value => form.setFieldValue(field.name, value)

    return <NewTimeslotPicker
        {...props}
        value={field.value}
        onChange={handleChange}
    />
}