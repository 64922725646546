import * as colors from '../core/styles/colors.scss';

export const palette = {
  main: {
    primary: colors.mainPrimary,
    secondary: colors.mainSecondary,
    secondaryLight: colors.mainSecondaryLight,
    third: colors.mainThird,
    thirdLight: colors.mainThirdLight,
    success: colors.mainSuccess,
    error: colors.mainError,
    warning: colors.mainWarning,
    warningDark: colors.mainWarningDark,
    default: colors.mainDefault,
  },

  text: {
    primary: colors.textPrimary,
    primaryLight: colors.textPrimaryLight,
    secondary: colors.textSecondary,
    secondaryLight: colors.textSecondaryLight,
    secondaryLighten: colors.textSecondaryLighten,
    third: colors.textThird,
    thirdLight: colors.textThirdLight,
    error: colors.textError,
    errorLight: colors.textErrorLight,
    default: colors.textDefault,
    defaultLight: colors.textDefaultLight,
    white: colors.textWhite,
    gray: colors.textGray,
    grayDark: colors.textGrayDark,
  },

  boxShadow: {
    primary: colors.boxShadowPrimary,
    secondary: colors.boxShadowSecondary,
  },

  background: {
    blue: colors.backgroundBlue,
    default: colors.backgroundDefault,
    gray: colors.backgroundGray,
    green: colors.backgroundGreen,
    darkGreen: colors.backgroundDarkGreen,
    lightGreen: colors.backgroundLightGreen,
    primary: colors.backgroundPrimary,
    yellow: colors.backgroundYellow,
  },

  border: {
    blue: colors.borderBlue,
    gray: colors.borderGray,
    red: colors.borderRed,
  },
};
