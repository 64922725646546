import { useDateRangeFilter } from '../../filtering/hooks'
import { useCallback, useEffect, useState } from 'react'
import format from '../../civic-champs-shared/utils/format'
import { useFetchRefactored, useRemoteCollection } from '../../civic-champs-shared/api/hooks'
import { MessageSourceType, MessagingContact, NotificationBlastSummary } from '../types'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'
import { useMessageRecipientsPromptExtended } from './useMessageRecipientsPrompt'
import { CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'

export const useMessagesCollection = () => {
  const [dateRange] = useDateRangeFilter()
  const [loading, setLoading] = useState<boolean>(false)
  const [request, { called }] = useFetchRefactored<NotificationBlastSummary[]>()
  const [messages, operations, eventListeners] = useRemoteCollection<NotificationBlastSummary>('uuid')
  const { syncCollection, eagerAdd } = operations
  const organization = useCurrentOrg()
  const [addMessage, onAddMessage] = useMessageRecipientsPromptExtended()

  const getMessages = useCallback(
    async (filters?: any) => {
      return request({
        method: 'get',
        url: `/organizations/${organization.id}/notification-blasts`,
        config: {
          queryStringParameters: filters,
        },
      })
    },
    [organization.id, request],
  )

  useEffect(() => {
    setLoading(true)
    getMessages({
      startDate: format.dateForUrl(dateRange.startDate),
      endDate: format.dateForUrl(dateRange.endDate),
      forGrid: true,
    }).then(result => {
      syncCollection(result)
      setLoading(false)
    })
  }, [dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let poll = true
    onAddMessage(async ({ uuid }: { uuid: string }) => {
      // Poll until we get the new message as message is sent through the background task and can be not available instantly
      let messages
      do {
        messages = await getMessages({ uuid })
        if (messages.length) {
          eagerAdd(messages)
        } else {
          await new Promise(resolve => setTimeout(resolve, 500))
        }
      } while (poll && messages.length === 0)
    })
    return () => {
      poll = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return [{ messages, loading, initiallyLoaded: called }, { addMessage }, eventListeners] as [
    { messages: NotificationBlastSummary[]; loading: boolean; initiallyLoaded: boolean },
    {
      addMessage: (props: {
        recipientPersonIds: number[]
        getSearchableContacts: () => Promise<MessagingContact[]>
        autocompleteSectionTitle?: string
        sourceType?: MessageSourceType
        sourceId?: number | string
      }) => Promise<void>
    },
    CollectionEventListeners<NotificationBlastSummary>,
  ]
}
