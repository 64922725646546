import { PersonRef } from 'civic-champs-shared/common/types'

export enum QuestionnaireStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DRAFT = 'Draft',
}

export interface Questionnaire {
  id: number
  name: string
  createdAt: Date
  deletedAt?: Date
  createdBy?: PersonRef
  lastEditedBy?: PersonRef
  lastEditedAt?: Date
  version?: number | null
  status: QuestionnaireStatus
}
