import { take, fork } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import authSaga from '../auth/saga';
import kioskModeSaga from '../kiosk/sagas';
import contributionsSaga from './contributions';
import { rootSaga as fullscreenSaga } from '../kiosk/fullscreen/sagas';
import apiSaga from 'civic-champs-shared/api/saga'
import featuresSaga from '../core/feature/sagas';

export default function* rootSaga() {
  yield fork(authSaga);

  console.log('waiting for rehydration');
  yield take(REHYDRATE);
  console.log('rehydrated');

  yield fork(kioskModeSaga);
  yield fork(fullscreenSaga);
  yield fork(contributionsSaga);
  yield fork(apiSaga);
  yield fork(featuresSaga);
}
