import _ from 'lodash'
import React, { useState } from 'react'
import { useOpportunityPrivateTokens } from './hooks'
import { Box, IconButton } from '@material-ui/core'
import { OpportunityVisibility as OpportunityVisibilityType, Opportunity } from 'Event/interfaces/index';
import { EmailOutlined, Check, FileCopy } from '@material-ui/icons'
import moment from 'moment'
import { encodeOccurrenceFromEvent } from '../../../../helpers/encodeOccurrence'
import { getVisibilityLabel } from 'Event/interfaces/index'

const buildEmailContent = (organizationName: string, opportunityTime: string, opportunityName: string, url: string) => {
  const content = `
Come Join ${organizationName} on ${opportunityTime} for “${opportunityName}”.

Register here: ${url}`
  return encodeURIComponent(content)
}

const OpportunityVisibility = (props: {
  opportunity: Opportunity,
  encodedOccurrence: string
}) => {
  const isDraft = !props.opportunity.published
  const { oneToken, loading } = useOpportunityPrivateTokens(props.encodedOccurrence)
  const [ copiedUrl, setCopiedUrl ] = useState('')
  const isPrivate = props.opportunity.visibility === OpportunityVisibilityType.PRIVATE && oneToken
  const passed = moment().isAfter(moment(props.opportunity.endsAt))

  let root = process.env.REACT_APP_EVENTS_SITE_ENDPOINT
  let orgId = _.get(props.opportunity, 'organization.id')
  let baseUrl = `${root}/organizations/${orgId}/opportunities/${encodeOccurrenceFromEvent(props.opportunity)}`

  if (isPrivate) {
    baseUrl += '?token='+oneToken
  }
  const opportunityTimeDisplay = moment(props.opportunity.startsAt).format('dddd, MMMM Do') + ' at ' + moment(props.opportunity.startsAt).format('hh:mm a') + ' - ' + moment(props.opportunity.endsAt).format('hh:mm a')
  return <div>
    <label className="right_side_title gap">Visibility: </label><span>{getVisibilityLabel(props.opportunity.visibility)}</span>
    {!loading && !isDraft && !passed && <Box>
      <Box>
        <a target='_blank' href={baseUrl}>{ isPrivate ? 'Private Registration Link' : 'Open Registration Link' }</a>
        <IconButton edge="end" onClick = { async e => {
          await navigator.clipboard.writeText(baseUrl)
          setCopiedUrl(baseUrl)
        }}>
          { copiedUrl === baseUrl ? <Check color='primary'/> : <FileCopy /> }
        </IconButton>
        <IconButton edge="end" onClick={ () => {
          const mailTo = `mailto:?subject=Invitation&body=${buildEmailContent(_.get(props.opportunity.organization, 'name', ''), opportunityTimeDisplay, props.opportunity.name, baseUrl)}`
          window.open(mailTo)
        }}>
          <EmailOutlined/>
        </IconButton>
      </Box>
    </Box>}
  </div>
}

export default OpportunityVisibility
