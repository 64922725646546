import { useFetch, useRemoteCollection, useRemoteCollectionPointer } from 'civic-champs-shared/api/hooks'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useCallback, useEffect } from 'react'

export default function useOrganizationsCollection() {
  const [request, { loading, error }] = useFetch()
  const [organizations, operations, events] = useRemoteCollection()
  const [selected, setSelected] = useRemoteCollectionPointer(events)

  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)

  const getOrganizations = useCallback(async () => {
    let updateOrganizations = await request({
      url: '/organizations',
      config: {
        queryStringParameters: {
          reporting: isInternalAdmin,
        },
      },
    })

    operations.syncCollection(updateOrganizations)
  }, [request, isInternalAdmin, operations.syncCollection])

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  return [
    {
      organizations,
      loading,
      error,
      selected,
    },
    {
      reload: getOrganizations,
      setSelected,
    },
  ]
}
