import { sumBy, meanBy, size } from 'lodash'
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import SummaryCards from 'core/table/components/SummaryCards'
import { NumberCell } from 'core/table/cells'

const useStyles = makeStyles(theme => ({
  summary: {
    marginTop: '30px',
    marginBottom: '30px',
  }
}));

const cards = [
  {
      Header: 'Opportunities',
      accessor: 'count',
      Cell: NumberCell
  },
  {
      Header: 'Total Hours',
      accessor: 'totalVolunteerHours',
      Cell: NumberCell
  },
  {
      Header: 'Avg. Opportunity Hours',
      accessor: 'averageHours',
      Cell: NumberCell
  },
]

export const useOpportunitySummary = (opportunities) => {
  return useMemo(() => {
      const totalVolunteerHours = sumBy(opportunities, 'totalVolunteerHours') || 0;
      const averageHours = meanBy(opportunities, 'totalVolunteerHours') || 0;

      return {
        count: size(opportunities),
        totalVolunteerHours: totalVolunteerHours,
        averageHours: averageHours,
    }
  }, [opportunities]);
}

export default function OpportunitySummaryCards(props) {
    const summary = useOpportunitySummary(props.opportunities)
    const classes = useStyles()

    return (
          <div className={classes.summary}>
            <SummaryCards
                cards={cards}
                summary={summary}/>
          </div>
    )
}