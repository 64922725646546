import React from 'react';
import format from 'civic-champs-shared/utils/format'
import ExportCsvMenuItem from 'components/table/ExportCsvMenuItem';

const filename = "admins.csv";
const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Mobile', key: 'phoneNumber' },
    { label: 'Home', key: 'homePhoneNumber' },
    { label: 'Joined Date', key: 'startedAt' },
    { label: 'Role', key: 'role' },
];
const useFormattedData = (members) => members.map(o => {
    return {
        firstName: o.person.givenName,
        lastName: o.person.familyName,
        email: o.person.email,
        phoneNumber: format.phoneNumber(o.person.phoneNumber),
        homePhoneNumber: format.phoneNumber(o.person.homePhoneNumber),
        startedAt: format.datetimeForExport(o.startedAt),
        role: o.role,
    };
});

const exportConfig = {
    filename,
    headers,
    useFormattedData
};

const ExportMyTeamActionItem = ({ members }) => (
    <ExportCsvMenuItem
        data={members}
        config={exportConfig}
    />)

export default ExportMyTeamActionItem;
