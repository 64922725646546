import { usePrompt } from '../../modal/hooks'
import { useCallback } from 'react'
import CropImagePrompt from '../components/CropImagePrompt'

const useCropImagePrompt = onSaveNewFile => {
  const prompt = usePrompt(CropImagePrompt)
  return useCallback(
    ({ currentFile, metadata }) => {
      if (prompt.showing) return

      const unlistens = [
        prompt.onComplete(onSaveNewFile),
        prompt.onHide(() => {
          unlistens.forEach(unlisten => unlisten())
        }),
      ]

      prompt.show({ currentFile, metadata })
    },
    [onSaveNewFile, prompt],
  )
}

export default useCropImagePrompt
