import React from 'react'
import { CredentialFull } from 'civic-champs-shared/question-sets/types'
import { Chip } from '@material-ui/core'
import cn from 'classnames'
import useProfileInfoCardStyles from 'champion/hooks/useProfileInfoCardStyles'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'
import { ReactComponent as UnverifiedIcon } from 'images/cancel.svg'

export const WaiverChip = ({ credential }: { credential: CredentialFull }) => {
  const invalid = !credential.signedAt || (credential.expiredAt && new Date(credential.expiredAt) < new Date())
  const classes = useProfileInfoCardStyles()
  return (
    <Chip
      classes={{
        root: classes.chip,
        label: cn(classes.signedLabel, {
          [classes.invalidLabel]: invalid,
        }),
        deleteIcon: cn({ [classes.deleteIcon]: invalid, [classes.verifiedIcon]: !invalid }),
      }}
      variant="outlined"
      deleteIcon={invalid ? <UnverifiedIcon /> : <VerifiedIcon />}
      onDelete={() => {}}
      label={credential.version.title}
    />
  )
}

export default WaiverChip
