import * as React from 'react'
import Popup from '../../../../components/popup'
import Button from '../../../../../components/button'

import './index.scss'

interface CongratulationsPopupProps {
  handleClose: () => void
  isRecurring?: boolean
  editMode: boolean
}

const CongratulationsPopup = (props: CongratulationsPopupProps) => {
  const {handleClose, isRecurring, editMode} = props
  const editModeText = `${isRecurring ? 'Your recurring events are live ' : 'Your event is live '} and you can start accepting volunteers.`
  const createModeText = 'Your event is live and you can start accepting volunteers.'

  return (
    <Popup
      shouldCloseOnOverlayClick={false}
      hideCloseButton={true}
      width={583}
      className='congratulations-popup'
      contentClassName='congratulations-popup__content'
    >
      <h3 className="congratulations-popup__title">
        Congratulations!
      </h3>
      <p className="congratulations-popup__text">
        {editMode ? editModeText : createModeText}
      </p>
      <Button
        className="congratulations-popup__button"
        fullWidth
        color='secondary'
        onClick={handleClose}
      >
        Done
      </Button>
    </Popup>
  )
}

export default CongratulationsPopup