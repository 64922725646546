import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export const useClearSalsaData = onResultUpdate => {
  const [request, { loading }] = useFetch(
    'Error Disconnecting Salsa Integration.  Please refresh the page and try again',
  )
  const { id: organizationId } = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const clearSalsaData = useCallback(async () => {
    const confirmed = await confirm(`Are you sure you want to clear Salsa data for demo purposes?  This is not recommended`, {
      title: `Clear Salsa Data`,
      confirmText: `Clear Now`,
      rejectText: 'Cancel',
    })
    if (!confirmed) {
      return
    }
    return request({
      onSuccess: result => {
        showSuccess('Successfully cleared Salsa data')
        onResultUpdate && onResultUpdate(result)
      },
      method: 'del',
      url: `/salsa/organizations/${organizationId}/clear-data`,
    })
  }, [request])

  return {
    loading,
    clearSalsaData,
  }
}
