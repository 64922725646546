import React from 'react';
import { withRouter } from 'react-router';

import getErrorMessage from 'utils/getErrorMessage';

class ErrorBoundary extends React.Component {

    state = {
        hasError: false,
        fallbackProps: null
    }

    static getDerivedStateFromError(error) {
        return { 
            hasError: true,
            fallbackProps: {
                error: getErrorMessage(error)
            }
        };
    }

    componentDidCatch(error, errorInfo) {
        const { history } = this.props;
        this._unlisten = history.listen( (location, action) => {
            this.onLeaveRoute(); 
        });
    }

    componentWillUnmount() {
        this.clearHistoryCallback();
    }

    onLeaveRoute() {
        this.setState({ hasError: false, error: null });
        this.clearHistoryCallback();
    }

    clearHistoryCallback() {
        let removeHistoryCallback = this._unlisten;
        if(removeHistoryCallback) removeHistoryCallback();
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError, fallbackProps } = this.state;

        if(hasError) {
            return fallback != null ? fallback(fallbackProps) : null;
        } else {
            return children;
        }
    }
}

export default withRouter(ErrorBoundary);