import React, { useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'

import Loading from 'components/Loading'
import useEventsCollection from './hooks/useEventsCollection'
import { CalendarNavigationBar } from './CalendarNavigationBar'
import { DayEvents } from './DayEvents'
import { Opportunity } from 'Event/interfaces'
import { useCalendarPeriod } from './hooks/useCalendarPeriod'
import { useViewEventDetails } from './hooks/useViewEventDetails'
import { viewTypes } from './viewTypes'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'

const useStyles = makeStyles(() => ({
  eventPreviewPrompt: {
    maxHeight: 'initial !important',
    maxWidth: '100%',
  },
}))

export const DayView = () => {
  const classes = useStyles()
  const [, setEventsPage] = useEventsPageState()
  useEffect(() => {
    setEventsPage(EventPages.Calendar)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    gotoNextDay,
    gotoPrevDay,
    gotoCurrentDay: gotoToday,
    firstOfDay: today,
    setFirstOfDay: setToday,
  } = useCalendarPeriod(viewTypes.DAY)

  const handleChangeMonth = (newValue: any) => setToday(moment(newValue).startOf('month'))

  const [{ events, loading }] = useEventsCollection({
    startDate: today.clone().startOf('day').add(1, 'day').format('MM-DD-YYYY'),
    endDate: today.clone().endOf('day').add(1, 'day').format('MM-DD-YYYY'),
    debounceDelay: 250,
  })

  const handleViewDetails = useViewEventDetails()

  return (
    <div>
      <CalendarNavigationBar
        date={today}
        onTodayClick={gotoToday}
        onNextClick={gotoNextDay}
        onPrevClick={gotoPrevDay}
        onMonthChange={handleChangeMonth}
      />

      {loading ? (
        <Loading />
      ) : (
        <DayEvents classes={classes} today={today} events={events as Opportunity[]} onViewDetails={handleViewDetails} />
      )}
    </div>
  )
}
