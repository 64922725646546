import { useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useGroups } from './useGroups'
import useAddGroupPrompt from './useAddGroupPrompt'

const useGroupsCollection = () => {
  const [groups, operations, events] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection } = operations
  const { refetch } = useGroups(groups => {
    syncCollection(groups)
    setInitiallyLoaded(true)
  })

  const addGroup = useAddGroupPrompt(operations.eagerAdd)

  return [
    {
      groups,
      initiallyLoaded,
    },
    {
      addGroup,
      refresh: refetch,
    },
    events,
  ]
}

export default useGroupsCollection
