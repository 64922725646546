import React from 'react'
import * as ReactModal from 'react-modal'
import './index.scss'

function Popup(props) {
  const {
    handleCloseModal, // Func
    children, // ReactNode
    height, // String
    width, // String
    className, // String
    contentClassName,
    hideCloseButton, //Boolean
    shouldCloseOnOverlayClick = false, //Boolean
  } = props

  const style = {
    content: { height, width },
    overlay: { zIndex: 1300 },
  }

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose"
      onRequestClose={handleCloseModal}
      className={['modal', className].join(' ')}
      style={style}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={true}
      overlayClassName="overlay"
    >
      {!hideCloseButton && (
        <span className="close-btn-circle" onClick={handleCloseModal}>
          <span className="close-btn" />
        </span>
      )}
      <div className={['popup-content', contentClassName].join(' ')}>{children}</div>
    </ReactModal>
  )
}

export default Popup
