import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { CancelType } from 'Event/modules/events/details/header'
import { FetchProps } from 'civic-champs-shared/api/hooks'

export const useCancelOpportunities = (
  props: FetchProps<void, void> = {
    errorMessage: 'Failed to cancel opportunities',
    successMessage: 'Opportunities cancelled',
    useNewNotification: true,
  },
): ((encodedOccurrences: string[], cancelType: CancelType) => Promise<void>) => {
  const [request] = useFetchRefactored<void>(props)

  return useCallback(
    (encodedOccurrences: string[], cancelType: CancelType) =>
      request({
        method: 'post',
        url: '/delete-opportunity-instances',
        config: {
          body: {
            encodedOccurrences,
          },
          queryStringParameters: { cancelType },
        },
      }),
    [request],
  )
}

export default useCancelOpportunities
