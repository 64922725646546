import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React, { useCallback } from 'react'
import { DocumentFile } from 'documents/hooks/useFetchDocuments'
import { useDeleteDocuments } from 'documents/hooks/useDeleteDocuments'
import map from 'lodash/map'

const useDeleteDocumentsPrompt = () => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const { deleteDocuments } = useDeleteDocuments()

  return useCallback(
    async (documents: DocumentFile[]) => {
      const children = (
        <ol>
          {documents.map(({ file: { filename } }, index) => (
            <li key={index}>{filename}</li>
          ))}
        </ol>
      )
      const confirmed = await showPrompt({
        children,
        title: 'Are you sure you want to permanently delete the selected documents?',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        await deleteDocuments(map(documents, 'id'))
      }
    },
    [deleteDocuments, showPrompt],
  )
}

export default useDeleteDocumentsPrompt
