import { useCallback } from 'react'
import { UserAvatar } from 'champion/interfaces'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import usePost from 'civic-champs-shared/api/hooks/usePost'

type Upload = (file: File, personId: number) => Promise<UserAvatar>
export default function useAvatarUpload(): Upload {
  const showError = useErrorNotification()

  const [start] = usePost<{ url: string; avatar: UserAvatar }, { content_type: string }>({
    errorMessage: 'Error starting file upload',
    configMapper: ({ personId, contentType }: { personId: number; contentType: string }) => ({
      url: `/users/${personId}/avatar/upload/start`,
      config: {
        body: {
          content_type: contentType,
        },
      },
    }),
  })

  const [done] = usePost<void, { id: number }>({
    errorMessage: 'Error saving file upload',
    configMapper: ({ personId, id }: { personId: number; id: number }) => ({
      url: `/users/${personId}/avatar/upload/done`,
      config: { body: { id } },
    }),
  })

  const postToS3 = useCallback(
    async ({ uploadUrl, file }: { uploadUrl: string; file: File }) => {
      try {
        await fetch(uploadUrl, {
          method: 'put',
          body: file,
        })
      } catch (err) {
        showError('Error uploading file', err)
        throw err
      }
    },
    [showError],
  )

  const uploadAvatar = useCallback(
    async (file: File, personId: number) => {
      // 1. get signedUrls
      const { url, avatar } = await start({ personId, contentType: file.type })
      // 2. upload file to s3
      await postToS3({ uploadUrl: url, file })
      await done({ personId, id: avatar.id })
      return avatar
    },
    [done, postToS3, start],
  )

  return uploadAvatar
}
