import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { AddActivityDialog, AddOrEditActivityPrompt } from '../components'
import { Activity } from 'champion/utils/interface'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Opportunity } from '../../../Event/interfaces'

export const useAddActivityPrompt = (eagerAdd: (activity: Activity) => {}) => {
  const isBulkActivityEditEnabled = useFeatureEnabled('BulkActivityEdit')
  const showPrompt = useShowPrompt(isBulkActivityEditEnabled ? AddOrEditActivityPrompt : AddActivityDialog)

  return useCallback(
    async (opportunity?: Opportunity) => {
      const savedActivity = await showPrompt({ opportunity })
      if (savedActivity) {
        eagerAdd(savedActivity)
      }
    },
    [eagerAdd, showPrompt],
  )
}

export default useAddActivityPrompt
