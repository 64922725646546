import * as React from 'react'
import { FeedbackSceneProps } from '../../../scenes/FeedbackScene'
import ENDPOINT_ROUTES from '../../../constants/APIs'
import BaseService from '../../../services/base.service'
import { queryStringToObject } from '../../../helpers/helpers'
import SuccessfullyComponent from './successfully-component'
import Loading from 'components/Loading'
import { FeedbackModuleProps, FeedbackPayload } from './interfaces'
import { initQuestionAnswers, mapQuestionToAnswerPayload } from 'civic-champs-shared/question-sets/utils'
import * as _ from 'lodash'
import './index.scss'
import { durationQuestion } from './questions/standard-questions'
import * as Yup from 'yup'
import { QuestionAnsweringForm } from 'civic-champs-shared/question-sets/components'
import { Question, QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import RatingBlock from './rating-block'
import { Field } from 'formik'

export const additionalSchema = Yup.object().shape({
  ratingValue: Yup.boolean()
    .required('One choice required')
    .nullable(true)
})

export interface OtherFeedback {
  ratingValue: boolean | null
}

export interface MenteeFeedback extends OtherFeedback {
  questionSet: QuestionSetResponse
}

const getMenteeQuestion = (question: Question) => question.questionId === durationQuestion.questionId

class FeedbackModule extends React.Component<FeedbackSceneProps, FeedbackModuleProps> {
  constructor(props: FeedbackSceneProps) {
    super(props)
    this.state = {
      meetingTitle: '',
      menteeRating: null,
      menteeDuration: _.cloneDeep(durationQuestion),
      feedbackError: '',
      menteeAnswerSet: { questions: [], questionSetId: 1, name: '', questionSetCreatedAt: new Date(), required: true, anonymous: false },
      isSubmittingForm: false,
      errorWithSubmittingForm: '',
      success: false,
      exists: false,
      loading: false,
    }
  }

  private request: BaseService = new BaseService()

  async componentDidMount() {
    this.setState({ loading: true })
    await this.initQuestions()
    this.setState({ loading: false })
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.exists || this.state.success) {
      return <SuccessfullyComponent />
    }

    if (this.state.feedbackError) {
      return <div className="not_exist">Feedback with this uuid, doesn't exist</div>
    }

    if (!_.get(this.state, 'menteeAnswerSet.questions')) {
      return null
    }

    const additionalInitialValues: OtherFeedback = {
      ratingValue: this.state.menteeRating,
    }

    return (
      <div className="feedback-wrap">
        <div className="feedback-wrap__header">{`Log Feedback for “${this.state.meetingTitle}”`}</div>
        <QuestionAnsweringForm
          onSubmit={this.handleSubmit}
          questionSet={this.state.menteeAnswerSet}
          additionalInitialValues={additionalInitialValues}
          additionalSchema={additionalSchema}
          required={true}
        >
          <Field name="ratingValue" component={RatingBlock} />
        </QuestionAnsweringForm>
      </div>
    )
  }

  private handleSubmit = async (values: MenteeFeedback) => {
    const payload: FeedbackPayload = {
      menteeRating: !!values.ratingValue,
      // @ts-ignore
      menteeDuration: Number(values.questionSet.questions.find(getMenteeQuestion).response),
      menteeAnswerSet: {
        ...this.state.menteeAnswerSet,
        questions: values.questionSet.questions.filter(question => !getMenteeQuestion(question)).map(mapQuestionToAnswerPayload),
      },
    }
    await this.editFeedbackRequest(payload, this.props.match.params.uuid)
  }

  private editFeedbackRequest = async (data: FeedbackPayload, uuid: string) => {
    this.setState({ isSubmittingForm: true })
    try {
      const response = await this.request.putJSON(ENDPOINT_ROUTES.Feedback.editFeedbackByUuid(uuid), data)
      if (response.error || (response.statusCode && response.statusCode !== 200)) {
        throw new Error(response.message)
      }
      this.setState({ isSubmittingForm: false })
      this.setState({ success: true })
    } catch (e) {
      this.setState({ errorWithSubmittingForm: 'Oops something went wrong. Please try again' })
      this.setState({ isSubmittingForm: false })
      console.log(e)
    }
  }

  private initQuestions = async () => {
    const parsedQueryString = queryStringToObject(this.props.location.search)
    const menteeRating = this.getRatingValue(parsedQueryString.liked)
    const { mentee_id } = parsedQueryString

    try {
      const questionSet = await this.request.getJSON(
        ENDPOINT_ROUTES.Feedback.getQuestions('mentee', { menteeId: mentee_id }),
      )

      this.setState({
        menteeAnswerSet: {
          ...questionSet,
          questions: initQuestionAnswers({
            ...questionSet,
            questions: [_.cloneDeep(durationQuestion), ...questionSet.questions],
          }),
        },
        menteeRating,
      })
    } catch (e) {
      console.log(e)
    }

    await this.fetchFeedback(mentee_id)
  }

  private fetchFeedback = async (menteeId: number) => {
    const { uuid } = this.props.match.params
    if (!menteeId) {
      this.setState({ feedbackError: true })
      return
    }
    try {
      const feedback = await this.request.getJSON(ENDPOINT_ROUTES.Feedback.getFeedbackByUuid(uuid, menteeId))
      const { meetingTitle, menteeAnswerSet } = feedback

      if (feedback.error || (feedback.statusCode && feedback.statusCode !== 200)) {
        throw new Error(feedback.message)
      }

      if (menteeAnswerSet) {
        return this.setState({
          exists: true,
          menteeAnswerSet: { ...menteeAnswerSet, required: true, questions: initQuestionAnswers(menteeAnswerSet) },
        })
      }

      this.setState({ meetingTitle })
    } catch (e) {
      this.setState({ feedbackError: 'Error with fetching' })
      console.log(e)
    }
  }

  private getRatingValue = (liked: string) => {
    if (!liked || (liked !== 'true' && liked !== 'false')) {
      return null
    } else {
      return JSON.parse(liked)
    }
  }
}

export default FeedbackModule
