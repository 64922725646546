import React from 'react'
import { useRegistrants } from 'volunteering/volunteers/hooks'
import Loading from 'components/Loading'
import { RegistrantsTable } from 'registrant/components/RegistrantsTable'
import makeStyles from '@material-ui/styles/makeStyles'

const tableName = 'registrants'

const useStyles = makeStyles({
  container: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
  },
})

export const ProfileRegistrations = ({ personId }: { personId: number }) => {
  const classes = useStyles()
  const [{ registrants, loading, initiallyLoaded, reload }] = useRegistrants({ personId } as any) as any
  return (
    <div className={classes.container}>
      {loading || !initiallyLoaded ? (
        <Loading />
      ) : (
        <RegistrantsTable
          registrants={registrants}
          initiallyLoaded={initiallyLoaded}
          tableName={tableName}
          reloadRegistrants={reload}
          forProfile={true}
        />
      )}
    </div>
  )
}
