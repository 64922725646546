import React, {useMemo} from 'react'
import {filterByRequireRules} from 'civic-champs-shared/utils/filterByRequireRules'

import {useHasRole} from 'auth/hooks'
import {INTERNAL_ADMIN} from 'civic-champs-shared/auth/utils/permissions'
import {DateCell, NullableTextCell} from 'core/table/cells'
import useGroupDetailApprovalButtonCell from './useGroupDetailApprovalButtonCell'
import {ContactCell, PhoneNumberCell} from "../../core/table/cells";
import {contactAccessor} from 'core/table/utils'


const useGroupDetailColumns = (onGotoApprovals, group) => {
    const isInternalAdmin = useHasRole(INTERNAL_ADMIN);
    const ApprovalButtonCell = useGroupDetailApprovalButtonCell(onGotoApprovals, group)

    return useMemo(() => filterByRequireRules([
        {
            Header: 'ID',
            accessor: 'id',
            requires: 'isInternalAdmin'
        },
        {id: 'firstName', Header: 'First Name', accessor: 'person.givenName'},
        {id: 'lastName', Header: 'Last Name', accessor: 'person.familyName'},
        {
            id: 'email',
            Header: 'Email',
            accessor: contactAccessor({
                valuePath: 'person.email',
                verificationStatusPath: 'person.emailVerified',
                type: 'email',
            }),
            Cell: ContactCell,
        },
        {
            id: 'mobile',
            Header: 'Mobile',
            accessor: contactAccessor({
                valuePath: 'person.phoneNumber',
                verificationStatusPath: 'person.phoneNumberVerified',
                type: 'phone',
            }),
            Cell: ContactCell,
        },
        {
            id: 'homePhoneNumber',
            Header: 'Home',
            accessor: 'person.homePhoneNumber',
            Cell: PhoneNumberCell,
        },
        {
            id: 'createdAt',
            Header: 'Joined',
            accessor: 'createdAt',
            Cell: DateCell,
            filter: 'dateRange',
        },
        {id: 'status', Header: 'Status', accessor: 'status.displayName'},
        {
            id: '$approvals',
            Header: 'Actions',
            Cell: ApprovalButtonCell,
            disableSortBy: true
        }], {
        isInternalAdmin
    }), [ApprovalButtonCell, isInternalAdmin, group])
}

export default useGroupDetailColumns;