import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import ChangePasswordPrompt from '../components/ChangePasswordPrompt'
import { useCallback } from 'react'

export const useChangePasswordPrompt = (onChangePassword) => {
  const prompt = usePrompt(ChangePasswordPrompt)

  return useCallback(() => {
    if (prompt.showing) return;

    if (onChangePassword) {
      const unlistens = [
        prompt.onComplete(onChangePassword),
        prompt.onHide(() => { unlistens.forEach(unlisten => unlisten()) })
      ]
    }

    prompt.show()
  }, [prompt, onChangePassword])
}
