import { OrgNamedRef, QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import { PersonRef } from 'civic-champs-shared/core/location/utils'

export interface Survey {
  id: number
  organizationId: number
  versions: SurveyVersion[]
}

export interface SurveyVersion {
  id: number
  surveyId: number
  questionSetId: number
  version: number
  createdAt: Date
  questionSet?: QuestionSetResponse
}

export interface SurveyVersionWithQuestionSet extends SurveyVersion {
  questionSet: QuestionSetResponse
}

export interface SurveyWithQuestionSet extends Survey {
  versions: SurveyVersionWithQuestionSet[]
}

export enum SurveyQuestionnaireStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export interface SurveyQuestionnaire {
  id: number
  organization?: OrgNamedRef
  name: string
  isGlobalUserProfile?: boolean
  createdAt: Date
  createdBy: PersonRef
  deletedAt?: Date
  lastEditedAt?: Date
  lastEditedBy?: PersonRef
  version: number
  versions: SurveyVersion[]
  status: SurveyQuestionnaireStatus
  answerCount: number
  inUse: boolean
}
