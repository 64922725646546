import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import AddVolunteerPrompt from '../components/AddVolunteerPrompt'
import { useCallback } from 'react'

const useAddVolunteerPrompt = onAddVolunteer => {
  const prompt = usePrompt(AddVolunteerPrompt)

  return useCallback(() => {
    if (prompt.showing) return

    //TODO this is still awkward and we're still repeating it...
    const unlistens = [
      prompt.onComplete(onAddVolunteer),
      prompt.onHide(() => {
        unlistens.forEach(unlisten => unlisten())
      }),
    ]

    prompt.show()
  }, [onAddVolunteer, prompt])
}

export default useAddVolunteerPrompt
