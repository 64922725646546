import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import PageHeader from 'App/components/PageHeader'
import { NUMBER_OPERATOR_OPTIONS, operatorTypes } from 'core/table/interfaces/Filters'
import format from 'civic-champs-shared/utils/format'
import { useTripPlannerColumns, useTripPlannerColumnStyles } from 'volunteering/trips/hooks'
import { ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import { useConditionalSelectColumn, useGetColumnState } from 'core/table/table-hooks'
import moment from 'moment'
import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import {
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Loading } from 'aws-amplify-react'
import { ContainedButton, TableMenuButton } from 'civic-champs-shared'
import AddIcon from '@material-ui/icons/Add'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import { useGetAllSearchableContacts } from 'messages/hooks'
import { TripOrder } from 'volunteering/trips/types'
import ExportTripsButton from 'volunteering/trips/components/ExportTipsButton'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import useRemoveTripRequestDialog from 'volunteering/trips/hooks/useRemoveTripRequestDialog'
import useAddEditTripPrompt from 'volunteering/trips/hooks/useAddEditTripPrompt'
import CUSTOM_FILTERS from 'volunteering/trips/filters'
import useTripOrdersCollection from 'volunteering/trips/hooks/useTripOrdersCollection'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'mileage':
      return NUMBER_OPERATOR_OPTIONS
    case 'pickupLocation':
    case 'dropOffLocation':
    case 'dropOffCounty':
      return [{ value: operatorTypes.CONTAINS, displayValue: 'Contains' }]
    case 'reason':
    case 'sudMh':
      return [{ value: operatorTypes.INCLUDES, displayValue: 'Includes' }]
    case 'driver':
    case 'rider':
    case 'recovery':
    case 'cji':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]
    default:
      return []
  }
}

const tableName = 'trip-planner'

const cards = [
  {
    header: 'Total Rides',
    accessor: 'total',
    transform: format.number,
  },
  {
    header: 'Today’s Rides',
    accessor: 'today',
    transform: format.number,
  },
  {
    header: 'Unassigned Rides',
    accessor: 'unassigned',
    transform: format.number,
  },
]

const getSummary = (rows: Row<TripOrder>[]) => ({
  total: rows.length,
  today: rows.filter(({ original: { pickupAt } }) => moment(pickupAt).isSame(moment(), 'day')).length,
  unassigned: rows.filter(({ original: { driver } }) => !driver).length,
})

export const TripPlannerComponent = ({ history, location }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [
    { tripOrders: result, loading },
    { deleteTripOrders, updateTripOrder, addTripOrder, assignDriver },
    eventListeners,
  ] = useTripOrdersCollection()
  const showAddEditTripModal = useAddEditTripPrompt({ updateTripOrder, addTripOrder })
  const onEdit = useCallback(trip => showAddEditTripModal({ trip }), []) // eslint-disable-line react-hooks/exhaustive-deps
  const columns = useTripPlannerColumns({ onEdit, updateTripOrder, assignDriver })
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const getSearchableContacts = useGetAllSearchableContacts()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const columnStyles = useTripPlannerColumnStyles()
  const showRemoveTripsDialog = useRemoveTripRequestDialog(deleteTripOrders)
  const [hideCompleted, setHideCompleted] = React.useState(false)
  const [hideAssignedDrivers, setHideAssignedDrivers] = React.useState(false)

  const booleanFilters = useMemo(
    () => [
      { name: 'Hide completed trips', value: hideCompleted, onChange: setHideCompleted },
      { name: 'Hide rides with assigned drivers', value: hideAssignedDrivers, onChange: setHideAssignedDrivers },
    ],
    [hideCompleted, hideAssignedDrivers],
  )

  const tripOrders = useMemo(
    () =>
      hideCompleted || hideAssignedDrivers
        ? (result as TripOrder[]).filter(
            ({ droppedOffAt, driver }) =>
              (hideCompleted ? !droppedOffAt : true) && (hideAssignedDrivers ? !driver : true),
          )
        : result,
    [hideCompleted, hideAssignedDrivers, result],
  ) as TripOrder[]
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
    getOperatorOptions,
  })

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        // @ts-ignore
        globalFilter: '',
        filters,
        hiddenColumns: [
          'dropOffTime',
          'actualPickupTime',
          'actualDropOffTime',
          'dropOffCounty',
          'reason',
          'recovery',
          'sudMh',
          'cji',
          'notes',
          'status',
        ],
      },
      globalFilter,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
      data: tripOrders,
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const handleRemoveTrips = useCallback(() => {
    showRemoveTripsDialog(table.selectedFlatRows.map((row: Row<TripOrder>) => row.original))
  }, [table.selectedFlatRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMessageVolunteers = useCallback(() => {
    const recipientPersonIds = table.selectedFlatRows.reduce((ids: number[], row: Row<TripOrder>) => {
      if (row.original.tripRequest?.rider?.id && !ids.includes(row.original.tripRequest.rider.id)) {
        ids.push(row.original.tripRequest.rider.id)
      }
      if (row.original.driver?.id && !ids.includes(row.original.driver.id)) {
        ids.push(row.original.driver.id)
      }
      return ids
    }, [])
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }, [table.selectedFlatRows]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || columnStateLoading) {
    return <Loading />
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <ExtendedPagedTable<TripOrder>
        getSummary={getSummary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Trip Requests"
        filterSubHeader="Select trip attributes to filter by:"
        binaryFilters={booleanFilters}
        buttons={
          <>
            <TableMenuButton
              startIcon={<MessageOutlinedIcon />}
              onClick={onMessageVolunteers}
              disabled={!table.selectedFlatRows.length}
              rightMargin
            >
              Message
            </TableMenuButton>
            <ExportTripsButton data={table.selectedFlatRows} disabled={!table.selectedFlatRows.length} />
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              disabled={!table.selectedFlatRows.length}
              onClick={handleRemoveTrips}
            >
              Remove
            </TableMenuButton>
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={() => showAddEditTripModal()}>
            Add Trip Request
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
      <div style={{ display: 'flex', gap: '15px' }}>
        <div style={{ width: '300px' }}></div>
      </div>
    </div>
  )
}

export const TripPlanner = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Trip Planner" subTitle="Add and manage trip requests">
      <TripPlannerComponent {...props} />
    </PageHeader>
  )
}
