import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useDeleteOpportunityTemplate = (): [
  (opportunityTemplate: OpportunityTemplate) => Promise<void>,
  Status<void>,
] => {
  const org = useCurrentOrg()
  const [request, status] = useFetchRefactored<void>()

  const deleteOpportunityTemplate = useCallback(
    (opportunityTemplate: OpportunityTemplate) => {
      return request({
        method: 'del',
        url: `/organizations/${org.id}/opportunity_templates/${opportunityTemplate.id}`,
      })
    },
    [org.id, request],
  )

  return [deleteOpportunityTemplate, status]
}

export default useDeleteOpportunityTemplate
