import React from 'react'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Grid, Typography } from '@material-ui/core'
import matchSorter from 'match-sorter'

import Loading from 'components/Loading'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import useGroupsCollection from '../hooks/useGroupsCollection'
import useGroupOverviewColumnsOld from 'group/hooks/useGroupOverviewColumns.old'
import useProcessMemberApprovals from 'group/hooks/useProcessMemberApprovals'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { useEnabledGroups } from '../../Event/components/opportunity/GroupPicker/hooks'
//import format from 'civic-champs-shared/utils/format'
//accessor: (row, index) => format.name(row),

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.name'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
}

export const GroupsOverviewOld = props => {
  const [{ groups, initiallyLoaded }, { addGroup, refresh }] = useGroupsCollection()

  const { loading, processApprovals: handleShowApprovals } = useProcessMemberApprovals(refresh)

  const filteredGroups = useEnabledGroups(groups)

  const columns = useGroupOverviewColumnsOld(handleShowApprovals)

  const table = useTable(
    {
      data: filteredGroups,
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  return (
    <Grid container>
      <Grid container item sm={12} direction="column" spacing={3}>
        <Grid container item justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Grid item>
              <Typography variant="h4">Groups</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <AddButton onClick={addGroup} />
            </Grid>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {!initiallyLoaded ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
