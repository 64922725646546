import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { TripOrder, TripOrderEventType, TripOrderPayload } from 'volunteering/trips/types'
import AssignDriverModal from 'volunteering/trips/components/AssignDriverModal'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

export const useAssignDriverPrompt = (assignDriver: (body: Partial<TripOrderPayload>) => void) => {
  const showPrompt = useShowPrompt(AssignDriverModal)

  return useCallback(
    async (trip: TripOrder): Promise<any> => {
      const driver: PersonSuggestion | null = await showPrompt({ trip })
      let eventCode
      if (!trip.driver && driver) {
        eventCode = TripOrderEventType.DRIVER_ASSIGNED
      } else if (trip.driver && !driver) {
        eventCode = TripOrderEventType.DRIVER_UNASSIGNED
      } else if (trip.driver && driver && trip.driver?.id !== driver?.id) {
        eventCode = TripOrderEventType.DRIVER_REASSIGNED
      } else {
        return false
      }

      assignDriver({
        id: trip.id,
        eventCode,
        payload: {
          driverId: driver ? driver?.id : null,
        },
      })
    },
    [assignDriver, showPrompt],
  )
}

export default useAssignDriverPrompt
