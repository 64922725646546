export const GET_WAIVERS = 'kiosk.get-waivers'
export const GET_WAIVERS_SUCCEEDED = 'kiosk.get-waivers.succeeded'
export const GET_WAIVERS_FAILED = 'kiosk.get-waivers.failed'

export const getWaivers = () => ({ type: GET_WAIVERS })

export const getWaiversSucceeded = (waivers) => ({
    type: GET_WAIVERS_SUCCEEDED,
    payload: { waivers }
})

export const getWaiversFailed = (error) => ({
    type: GET_WAIVERS_FAILED,
    payload: { error }
})