import { isEmpty } from 'lodash'
import React from 'react'
import { Button, makeStyles, FormHelperText } from '@material-ui/core';
import { AddCircle, Edit } from '@material-ui/icons';
import { getIn } from 'formik'

import { FileManagementPrompt } from '../../core/file-upload/components/FileManagementPrompt'
import { useShowPrompt } from '../../core/modal/hooks'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'center',
  },
  helperText: {
    marginTop: '55px',
    marginLeft: '10px',
  },
})

const useButtonStyles = makeStyles({
  button: {
    display: 'flex',
    backgroundColor: '#FFCD00',
    borderRadius: '4px',
    width: '135px',
    height: '59px',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '31px',
    marginBottom: '35.5px',
  },
})

//TODO need to separate this from other uploaded files?  limit by content type...
export function UploadedImageSelect(props) {
  const { value, onChange, otherProps } = props

  const showPrompt = useShowPrompt(FileManagementPrompt)
  const handleClick = async () => {
    const file = await showPrompt() // TODO pass in currently selected URL
    if (!file) return

    if (!value || value.id !== file.id) {
      onChange(file)
    }
  }

  const classes = useButtonStyles()

  return (
    <Button
      {...otherProps}
      className={classes.button}
      type="button"
      variant="contained"
      startIcon={value != null ? <Edit /> : <AddCircle />}
      onClick={handleClick}
    >
      {value != null ? 'Change' : 'Add Image'}
    </Button>
  )
}

const getErrorMessage = (error) => {
  if (!error) {
    return error
  }
  if (typeof error === 'string') {
    return error
  }
  if (Array.isArray(error)) {
    return error.map(getErrorMessage).join(', ')
  }
  if (typeof error === 'object' && !isEmpty(error)) {
    return Object.values(error)[0]
  }
}

export default function UploadedImageField(props) {
  const { field, form, ...other } = props
  const error = getIn(form.errors, field.name)

  const handleChange = (image) => {
    form.setFieldValue(field.name, image, true)
  }

  const classes = useStyles()
  const errorMessage = getErrorMessage(error)
  //TODO show a validation error to the right!
  return (
    <span className={classes.root}>
      <UploadedImageSelect {...other} value={field.value} onChange={handleChange} />
      {errorMessage && (
        <FormHelperText className={classes.helperText} error={true}>
          {errorMessage}
        </FormHelperText>
      )}
    </span>
  )
}
