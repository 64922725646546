import React from 'react';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/styles';
import cn from 'classnames';

const useStyles = makeStyles({
  wrapper: {
    marginBottom: 20
  },
  unselectedCheckIn: {
    backgroundColor: '#5C8DE8 !important',
    color: 'white !important',
    fontWeight: 600,
  },
  selectedCheckIn: {
    background: 'white !important',
    color: '#AAAAAA !important',
    fontWeight: 600,
  },
  unselectedCheckOut: {
    backgroundColor: '#FF6F3E! important',
    color: 'white !important',
    fontWeight: 600,
  },
  selectedCheckOut: {
    background: 'white !important',
    color: '#AAAAAA !important',
    fontWeight: 600,
  },
});

export default function Toggle({ alignment, setAlignment }) {
  const classes = useStyles();

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment || alignment);
  };

  return (
    <Grid container spacing={2} direction="column" alignItems="center" className={classes.wrapper}>
      <Grid item>
        <ToggleButtonGroup size="medium" value={alignment} exclusive={true} onChange={handleChange}>
          <ToggleButton
            value="check-in"
            style={{ width: 175 }}
            className={cn(alignment !== 'check-in' ? classes.selectedCheckIn : classes.unselectedCheckIn)}
          >
            Check In
          </ToggleButton>,
          <ToggleButton
            value="check-out"
            style={{ width: 175 }}
            className={cn(alignment !== 'check-out' ? classes.selectedCheckOut : classes.unselectedCheckOut)}
          >
      Check Out
    </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
}
