import moment from 'moment'
import { useCallback } from 'react'

export interface DateRange {
  startDate: moment.Moment
  endDate: moment.Moment
}

export const useWithinRange = ({ startDate, endDate }: DateRange) => {
  const withinRange = useCallback(
    (start: Date | string, end: Date | string | undefined) =>
      moment(start).isSameOrBefore(endDate) && (!end || moment(end).isSameOrAfter(startDate)),
    [endDate, startDate],
  )

  return [withinRange]
}
