import React, { useState, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';

import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useCurrentOrg } from 'auth/hooks';
import { useUpdateProductSwitch } from '../hooks';

export const useUpdateEnabled = (feature) => {
    const [fetch, status] = useFetch();
    const organization = useCurrentOrg()

    const updateEnabled = useCallback(value => {
        return fetch({
            method: 'put',
            url: `/settings/features/${feature.id}/switches`,
            config: {
                body: {
                    organizationId: organization.id,
                    enabled: !!value
                }
            }
        })
    }, [fetch, feature.id, organization.id]);

    return [updateEnabled, status];
}

export default function ProductFeatureSwitch(props) {
    const { feature } = props;

    const [enabled, setEnabled] = useState(feature.enabled);
    const [updateEnabled, { loading }] = useUpdateEnabled(feature);

    const updateSwitch = useUpdateProductSwitch();

    const toggleEnabled = useCallback(async () => {
        if (loading) return;

        let prev = enabled;

        try {
            setEnabled(!enabled)
            updateSwitch(await updateEnabled(!enabled));
        } catch (error) {
            console.error(error);
            setEnabled(prev);
            //TODO flash the background so it shows an error color
            //TODO display the error as a toast
        }
    }, [loading, enabled, updateEnabled, setEnabled, updateSwitch]);

    return (
        <FormGroup>
            <FormControlLabel
                control={<Switch
                    disabled={loading}
                    checked={enabled}
                    onChange={toggleEnabled}
                />}
                label={<>
                    Enabled
                    {loading && <CircularProgress size="sm" />}
                </>}
            />
        </FormGroup>
    );
}
