export const basicFileDialogStyles = {
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '20px',
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    width: '400px',
    '&$wide': {
      width: '900px',
      minWidth: '900px',
    },
  },
  wide: {},
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  dragndrop: {
    display: 'flex',
    width: '360px',
    height: '220px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      fontSize: '48px',
      color: '#0F5DB5',
    },
    background: 'white',
    borderRadius: '8px',
    gap: '10px',
    '&$valid': {
      background: '#0F5DB5',
      '& > svg, & > $dragTitle, & > $supports, & > $button': {
        color: 'white',
      },
      '& > $button': {
        borderColor: 'white',
      },
    },
    '&$invalid': {
      cursor: 'not-allowed',
      background: '#FFB4A9',
      '& > svg, & > $dragTitle, & > $supports, & > $button': {
        color: 'black',
      },
      '& > $button': {
        borderColor: 'black',
      },
    },
  },
  valid: {},
  invalid: {},
  dragTitle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    padding: 0,
    margin: 0,
    wordBreak: 'break-all',
  },
  hidden: {
    display: 'none',
  },
  supports: {
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  button: {},
  smallButton: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    padding: '5px 13px',
    borderColor: '#EEF3F6',
  },
  grabbing: {
    cursor: 'grabbing',
  },
  dragIcon: {
    position: 'absolute',
    right: '10px',
    bottom: '8px',
    color: '#0F5DB5',
    fontSize: '40px',
  },
}
