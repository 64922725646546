import React from 'react'
import styled from 'styled-components'
import './styles.scss'

interface PopupProps {
  children?: React.ReactNode
  height?: string
  width?: string
  open?: any
  closePopup?: (value: boolean) => void
  hideCloseIcon?: boolean
}
const PopupInnerContainer = styled<any>('div')`
  width: ${(props: any) => props.width || '400px'};
  height: ${(props: any) => props.height || '300px'};
`

function Popup(props: PopupProps) {

  if (!props.open) {
    return null
  }
  const { width, height, children, hideCloseIcon = false } = props
  return (
    <div className="mentorship_popup_wrapper">
      <PopupInnerContainer className="mentorship_popup_inner" width={width} height={height}>
        {!hideCloseIcon && <div className="mentorship_close-icon" onClick={() => props.closePopup && props.closePopup(false)}>×</div>}
        <div className="mentorship_popup_inner_content">
          {children}
        </div>
      </PopupInnerContainer>
    </div>
  )
}
export default Popup
