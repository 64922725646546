import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { Field, Form, Formik } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import * as yup from 'yup'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import CustomSwitch from 'civic-champs-shared/formik/components/CustomSwitch'

export interface AddEditDocumentTypePromptProps {
  documentType?: DocumentType
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const useStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '415px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: '8px 0',
  },
  switch: {
    display: 'flex',
    marginLeft: '12px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
})

const formSchema = yup.object({
  name: yup.string().min(2).required(),
  isActive: yup.boolean().default(true),
})

export const AddEditDocumentTypePrompt = ({
  documentType,
  showing,
  close,
  complete,
}: AddEditDocumentTypePromptProps) => {
  const handleSubmit = ({ name, isActive }: { name: string; isActive: boolean }) =>
    complete({
      id: documentType?.id,
      name,
      isActive,
    })
  const classes = useStyles()
  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#document-type-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik
        initialValues={{
          name: documentType?.name || '',
          isActive: documentType ? documentType.isActive : true,
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, isValid, values }) => (
          <Form>
            <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="document-type-prompt">
              {documentType ? 'Edit Document' : 'New Document'}
              <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                <CloseIcon className={classes.dialogCloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <Field
                component={StyledOutlinedTextField}
                id="name"
                name="name"
                label="Enter a Document Name"
                variant="outlined"
                fullWidth
              />
              <div className={classes.switch}>
                <Field
                  component={CustomSwitch}
                  id="isActive"
                  name="isActive"
                  thumbColor="#FFF"
                  trackColor={values.isActive ? '#008954' : '#a2a3a6'}
                  type="checkbox"
                />
                <span>{values.isActive ? 'Active' : 'Inactive'}</span>
              </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <OutlinedButton disabled={isSubmitting} onClick={close}>
                Cancel
              </OutlinedButton>
              <ContainedButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} onClick={submitForm}>
                Save
              </ContainedButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: AddEditDocumentTypePromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddEditDocumentTypePrompt {...props} />
  </ThemeProvider>
)
