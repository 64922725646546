import { useCallback } from 'react'
import { useFetchRefactored } from 'civic-champs-shared/api/hooks'

export const useGetImportUrl = (): ((id: number) => Promise<string>) => {
  const [request] = useFetchRefactored<string>({
    useNewNotification: true,
  })

  const getImportUrl = useCallback(
    async (id: number) =>
      request({
        method: 'get',
        url: `/imports/${id}/url`,
      }),
    [request],
  )

  return getImportUrl
}

export default useGetImportUrl
