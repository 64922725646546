import React, { useCallback } from 'react'

export const useHandleKeyboardEvents = <T>({
  filteredOptions,
  focusedOption,
  onOpenChange,
  focusedOptionRef,
  onValueChange,
  setFocusedOption,
  optionsDivRef,
  dynamicLoadingEnabled,
  optionsCount,
}: {
  filteredOptions: T[]
  focusedOption: T | null
  onOpenChange: (open: boolean) => void
  focusedOptionRef: React.MutableRefObject<HTMLDivElement | null>
  onValueChange: (value: T) => void
  setFocusedOption: React.Dispatch<React.SetStateAction<T | null>>
  optionsDivRef: React.MutableRefObject<HTMLDivElement | null>
  dynamicLoadingEnabled?: boolean
  optionsCount?: number
}) => {
  /**
   * When you navigate list options using keyboard arrows at some point next option will be partially of fully out of viewport.
   * This function checks if selected option is in viewport and if not - scrolls parent container to put option to viewport.
   * It doesn't take any arguments, as selected option has ref, and it works with refs, so it doesn't need any arguments.
   */
  const makeSelectedOptionVisible = useCallback(() => {
    window.requestAnimationFrame(() => {
      if (focusedOptionRef.current === null || optionsDivRef.current === null) return
      const optionRect = focusedOptionRef.current.getBoundingClientRect()
      const containerRect = optionsDivRef.current.getBoundingClientRect()
      const isOutOfView = optionRect.top < containerRect.top || optionRect.bottom > containerRect.bottom
      if (isOutOfView) {
        focusedOptionRef.current.scrollIntoView({ block: 'center' })
      }
    })
  }, [focusedOptionRef, optionsDivRef])

  return useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Escape':
          onOpenChange(false)
          break
        case 'ArrowDown':
          setFocusedOption(value => {
            if (value === null) {
              optionsDivRef.current?.scrollTo(0, 0)
              return filteredOptions[0]
            } else {
              const index = filteredOptions.indexOf(value)
              const isLast = index === filteredOptions.length - 1 || index === -1
              if (isLast) {
                optionsDivRef.current?.scrollTo(0, 0)
                return filteredOptions[0]
              } else {
                makeSelectedOptionVisible()
                return filteredOptions[index + 1]
              }
            }
          })
          break
        case 'ArrowUp':
          setFocusedOption(value => {
            if (value === null) {
              // If we load options dynamically we don't allow bottom to top navigation until all options are loaded
              // We add this later, just for now it's additional time that we don't have
              if (dynamicLoadingEnabled && optionsCount !== filteredOptions.length) {
                optionsDivRef.current?.scrollTo(0, 0)
                return filteredOptions[0]
              } else {
                optionsDivRef.current?.scrollTo(0, optionsDivRef.current?.scrollHeight)
                return filteredOptions[filteredOptions.length - 1]
              }
            } else {
              const index = filteredOptions.indexOf(value)
              const isFirst = index === 0 || index === -1
              if (isFirst) {
                if (dynamicLoadingEnabled && optionsCount !== filteredOptions.length) {
                  return value
                } else {
                  optionsDivRef.current?.scrollTo(0, optionsDivRef.current?.scrollHeight)
                  return filteredOptions[filteredOptions.length - 1]
                }
              } else {
                makeSelectedOptionVisible()
                return filteredOptions[index - 1]
              }
            }
          })
          break
        case 'Enter':
          if (focusedOption !== null) {
            onValueChange(focusedOption)
          }
          break
      }
    },
    [
      dynamicLoadingEnabled,
      filteredOptions,
      focusedOption,
      onOpenChange,
      makeSelectedOptionVisible,
      onValueChange,
      optionsCount,
      optionsDivRef,
      setFocusedOption,
    ],
  )
}
