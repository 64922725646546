import React from 'react'
import { Field, Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { RadioGroup, Select, TextField } from 'formik-material-ui'
import { Close } from '@material-ui/icons'
import { Button, Divider, FormControlLabel, Grid, IconButton, MenuItem, Radio, Typography } from '@material-ui/core'

import { CreateOrUpdateCredentialParam, CredentialContentType, CredentialExpirationOption } from '../models'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import useStyles from '../hooks/useStyles'
import RichTextField from 'civic-champs-shared/formik/components/RichTextField'
import { OrgNamedRef } from 'civic-champs-shared/common/types'
import MaskedField from '../../components/MaskedField'

interface Props {
  credential?: CreateOrUpdateCredentialParam
  handleSave: (values: CreateOrUpdateCredentialParam) => void
  handleCancel: (values: CreateOrUpdateCredentialParam) => void
  organization: OrgNamedRef
}

const defaultValues = {
  title: '',
  description: '',
  contentType: CredentialContentType.Text,
  content: '',
  url: '',
  waiverStatement: '',
  expirationOption: CredentialExpirationOption.Month,
  expirationOptionLength: 1,
  expirationDate: '12/31',
}

const CredentialForm = (props: Props) => {
  const promptClasses = usePromptStyles()
  const timeZone = props.organization.timeZone || 'America/New_York'
  const classes = useStyles()
  const handleSubmit = async (
    values: CreateOrUpdateCredentialParam,
    actions: FormikActions<CreateOrUpdateCredentialParam>,
  ) => {
    const [month, day] = (values.expirationDate as string).split('/')
    const actualDate = `${new Date().getFullYear()}-${month}-${day}`
    await props.handleSave({
      ...values,
      expirationDate: moment.tz(actualDate, timeZone).endOf('day').toISOString(),
    })
    actions.setSubmitting(false)
  }
  const handleCancel = async (values: CreateOrUpdateCredentialParam, setSubmitting: (submitting: boolean) => void) => {
    setSubmitting(true)
    await props.handleCancel(values)
    setSubmitting(false)
  }
  const isCreate = !props.credential || isEmpty(props.credential)
  const initialValues = (isCreate ? defaultValues : props.credential) as CreateOrUpdateCredentialParam

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values: CreateOrUpdateCredentialParam) => {
        const errors: any = {}
        if (!values.title) {
          errors.title = 'Name is required'
        }
        if (values.contentType === CredentialContentType.Text && !values.content) {
          errors.content = 'Content is required'
        }
        if (values.contentType === CredentialContentType.Url && !values.url) {
          errors.url = 'URL is required'
        }
        if (values.expirationOption === CredentialExpirationOption.Date) {
          if (!values.expirationDate) {
            errors.expirationDate = 'Date is required'
          } else if (!/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/.test(values.expirationDate)) {
            errors.expirationDate = 'Date is not valid'
          }
        }
        if (
          (values.expirationOption === CredentialExpirationOption.Month ||
            values.expirationOption === CredentialExpirationOption.Year) &&
          !values.expirationOptionLength
        ) {
          errors.expirationOptionLength = 'Invalid value'
        }
        return errors
      }}
    >
      {({ values, isSubmitting, setSubmitting }) => {
        return (
          <Form>
            <div className={promptClasses.titleContainer} style={{ margin: '20px 0' }}>
              <Typography align="center" variant="h3">
                {!isCreate ? 'Update Waiver' : 'Add a New Waiver'}
                <IconButton onClick={() => handleCancel(values, setSubmitting)} className={promptClasses.closeButton}>
                  <Close />
                </IconButton>
              </Typography>
            </div>
            <div>
              <Grid container spacing={4}>
                <Grid item sm={4}>
                  <Typography align="right" variant="h5">
                    Administrative Details
                  </Typography>
                </Grid>
                <Grid item sm={8} />
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Name of the credential</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="title"
                    placeholder="Name for this credential"
                  />
                </Grid>
                <Grid item sm={4} />
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Waiver Description</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="description"
                    placeholder="Description of the credential"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item sm={4} />
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Expiration</Typography>
                </Grid>
                <Grid item sm={2}>
                  <Field fullWidth variant="outlined" component={Select} name="expirationOption">
                    {/* <MenuItem value={'signup'}>Every signup</MenuItem> */}
                    <MenuItem value={CredentialExpirationOption.Month}>Set number of months</MenuItem>
                    <MenuItem value={CredentialExpirationOption.Year}>Set number of years</MenuItem>
                    <MenuItem value={CredentialExpirationOption.Day}>Set number of days</MenuItem>
                    <MenuItem value={CredentialExpirationOption.Date}>Specific day of the year</MenuItem>
                    <MenuItem value={CredentialExpirationOption.DayOf}>After Event ends</MenuItem>
                    <MenuItem value={CredentialExpirationOption.Updated}>Never unless updated</MenuItem>
                  </Field>
                </Grid>
                <Grid item sm={2}>
                  {[
                    CredentialExpirationOption.Month,
                    CredentialExpirationOption.Year,
                    CredentialExpirationOption.Day,
                  ].includes(values.expirationOption) && (
                    <Field
                      fullWidth
                      variant="outlined"
                      component={TextField}
                      name="expirationOptionLength"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                    />
                  )}
                  {values.expirationOption === 'date' && (
                    <Field
                      fullWidth
                      variant="outlined"
                      component={MaskedField}
                      name="expirationDate"
                      mask="yn/dn"
                      maskChar=" "
                      alwaysShowMask={false}
                      formatChars={{ y: '[0-1]', d: '[0-3]', n: '[0-9]' }}
                      type="text"
                    />
                  )}
                </Grid>
              </Grid>
              <Divider style={{ margin: '40px 0' }} />
              <Grid container spacing={3}>
                <Grid item sm={4}>
                  <Typography variant="h5" align="right">
                    Volunteer Details
                  </Typography>
                </Grid>
                <Grid item sm={8}></Grid>
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Waiver Content</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Field component={RadioGroup} name="contentType" row>
                    <FormControlLabel value="text" control={<Radio />} label="Formatted Text" />
                    <FormControlLabel value="url" control={<Radio />} label="Webpage URL" />
                  </Field>
                </Grid>
                <Grid item sm={4} />
                <Grid item sm={4} />
                <Grid item sm={4}>
                  {values.contentType === CredentialContentType.Text && (
                    <Field
                      placeholder="Waiver content that can be added to this section and formatted with the tools above."
                      name="content"
                      component={RichTextField}
                    />
                  )}
                  {values.contentType === CredentialContentType.Url && (
                    <Field
                      fullWidth
                      variant="outlined"
                      component={TextField}
                      name="url"
                      placeholder="https://www.example.com/mywaiver"
                    />
                  )}
                </Grid>
                <Grid item sm={4} />
                <Grid item sm={4}>
                  <Typography className={classes.formLabel}>Waiver Agreement</Typography>
                </Grid>
                <Grid item sm={4}>
                  <Field
                    component={TextField}
                    name="waiverStatement"
                    placeholder="You agree that you have read and understand the content presented for this waiver."
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </div>
            <div className={`${promptClasses.actionContainer} ${classes.formActions}`}>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                className={promptClasses.actionButton}
                onClick={() => props.handleCancel(values)}
              >
                Cancel
              </Button>
              {/* <Button size='large' variant='contained' color='primary' className={promptClasses.actionButton} disabled={values.contentType === 'url' && !values.url}>Preview</Button> */}
              <Button
                size="large"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                className={promptClasses.actionButton}
              >
                Save
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CredentialForm
