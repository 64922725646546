import { map, pick } from 'lodash'
import { useCallback } from 'react'

import { ChampionDatafeedImportRow, ExternalCustomerDatafeed } from '../types'
import { useErrorNotification, useFetch } from 'civic-champs-shared/api/hooks'

type ImportIdMappingsFunction = (
  datafeed: ExternalCustomerDatafeed,
  processedRows: ChampionDatafeedImportRow[]
) => Promise<void>

type ResponseStatus = { 
  loading?: boolean, 
  called?: boolean, 
  error?: any, 
  result?: any 
} 

const useImportIdMappings = (): [ImportIdMappingsFunction, ResponseStatus] => {  
  //@ts-ignore
  const [request, status] = useFetch()
  const showError = useErrorNotification()

  const importIdMappings = useCallback(
    async (
      datafeed: ExternalCustomerDatafeed,
      processedRows: ChampionDatafeedImportRow[]
    ) => {
      const mappings = map(processedRows, row => pick(row, ['internalId', 'externalId']))
      try {
        //@ts-ignore
        return request({
          method: 'post',
          url: `/external_datafeeds/${datafeed.id}/id_mappings`,
          config: { body: mappings }
        }) 
      } catch(error) {
        showError('ID Mapping Import Failed!' , error)
        throw error
      }
    },
    [showError, request]
  )

  return [importIdMappings, status as ResponseStatus]
}

export default useImportIdMappings