import { makeStyles } from '@material-ui/core/styles'
import { basicFileDialogStyles } from '../utils/basicFileDialogStyles'

export const useAvatarDialogStyles = makeStyles({
  ...(basicFileDialogStyles as any),
  cropImage: {
    height: '300px',
    width: '300px',
    objectFit: 'cover',
  },
  imageUI: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  cropImageContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '300px',
    height: '300px',
  },
  cropImageCover: {
    position: 'absolute',
    left: '25px',
    top: '25px',
    borderRadius: '100%',
    width: '250px',
    height: '250px',
    boxShadow: '0px 0px 0px 300px rgba(255, 255, 255, 0.6)',
    cursor: 'grab',
  },
})

export default useAvatarDialogStyles
