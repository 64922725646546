import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

export const useSendNotification = (): {
  sendNotification: (meetingId: number, notificationType: string, recipientType?: string) => Promise<void>
  status: Status<void>
} => {
  const [request, status] = useFetchRefactored<void>({
    errorMessage: 'Sending Notification Failed',
    successMessage: 'Notification Sent',
  })

  const sendNotification = useCallback(
    (meetingId, notificationType, recipientType) => {
      let url = `/mentorship-meetings/${meetingId}/notify/${notificationType}`
      if (recipientType) url += `/${recipientType}`
      return request({
        method: 'post',
        url,
      })
    },
    [request],
  )

  return {
    sendNotification,
    status,
  }
}

export default useSendNotification
