import { useCallback } from 'react'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { volunteerSummary } from '../../volunteering/volunteers/hooks/utils'

const useRemoveVolunteer = (eagerRemove: (item: any) => () => void) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(async userCredentials => {
    const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(userCredentials)
    const text = onlyOneVolunteer
      ? `Removing Waiver Signature for ${volunteerName}...`
      : `Removing ${numVolunteers} Waiver Signatures...`
    const notification = createNotification(text)
    let successCount = 0;
    const totalCount = userCredentials.length;

    for (let userCredential of userCredentials) {
      const undo = eagerRemove(userCredential);
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/user-credential/${userCredential.id}`
        });
        if (++successCount === totalCount) {
          notification.onSuccess('Waiver Signatures removed!')
        }
      } catch (error) {
        const text = `Could not remove ${userCredential.user.givenName}` +
          ` ${userCredential.user.familyName} Waiver Signature`
        notification.onError(text, error)
        undo();
      }
    }
  }, [createNotification, eagerRemove, request])
}

export default useRemoveVolunteer
