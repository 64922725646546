import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import { useCallback } from 'react';
import AddRequiredGroupMemberPrompt from '../components/AddRequiredGroupMemberPrompt';

const useAddRequiredGroupMemberPrompt = (groupType, onAddMember) => {
    const prompt = usePrompt(props => AddRequiredGroupMemberPrompt({...props, groupType}));

    return useCallback(() => {
        if(prompt.showing) return;

        //TODO this is still awkward and we're still repeating it...
        const unlistens = [
            prompt.onComplete(onAddMember),
            prompt.onHide( () => { unlistens.forEach( unlisten => unlisten() ) })
        ]

        prompt.show();
    }, [onAddMember, prompt])
}

export default useAddRequiredGroupMemberPrompt
