import React, { useMemo } from 'react'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { DEFAULT_FILTERS, DATE_RANGE } from 'core/table/filters'
import { DateTimeCell, CurrencyCell, NullableTextCell } from 'core/table/cells'
import { filter } from 'lodash'
import { Link } from 'react-router-dom'
import { getDonationStatusDisplayNames } from '../interface'

const donationColumns = [
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: DateTimeCell,
    filter: DATE_RANGE,
  },
  {
    id: 'donor',
    Header: 'Donor',
    accessor: donation => {
      const { givenName, familyName } = donation.person || {}
      return filter([givenName, familyName]).join(' ')
    },
  },
  {
    Header: 'Amount',
    accessor: donation => donation.amountInCents / 100,
    Cell: CurrencyCell,
  },
  {
    Header: 'Processing Fee',
    accessor: donation => donation.fees / 100,
    Cell: CurrencyCell,
  },
  {
    id: 'net',
    Header: 'Net',
    accessor: donation => (donation.amountInCents - donation.fees) / 100,
    Cell: CurrencyCell,
  },
  {
    id: 'transaction',
    Cell: ({ cell }) => <Link to={`/donations/${cell.row.original.id}`}>View Transaction</Link>,
  },
  {
    Header: 'Status',
    accessor: donation => getDonationStatusDisplayNames(donation.status),
    Cell: NullableTextCell,
  },
]

export default function useDonationsTable(props) {
  const { donations, dateRange } = props
  return useTable(
    {
      data: donations,
      columns: donationColumns,
      filterTypes: DEFAULT_FILTERS,
      initialState: { filters: useMemo(() => [{ id: 'createdAt', value: dateRange }], [dateRange]) },
    },
    useFilters,
    useSortBy,
    usePagination,
  )
}
