import { useCallback } from 'react'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

export const useRemoveActivities = (eagerRemoveMany: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async activities => {
      const undo = eagerRemoveMany(activities)
      const notification = createNotification('Removing Activitiess')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/activities?ids=${activities.map(({ id }: any) => id).join(',')}`,
        })

        notification.onSuccess('Activities removed!')
      } catch (error) {
        notification.onError(`Could not remove activities`, error)
        undo()
      }
    },
    [createNotification, eagerRemoveMany, request],
  )
}

export default useRemoveActivities
