import React, { useMemo } from 'react'
import { TOP_PADDING } from 'Event/hooks/useEventDrilldownStyles'

const headerSize = 80

export const useExpansionPanelSummaryStyle = ({
  expanded,
  scrollTop,
  summaryRef,
  detailsRef,
  offsetAllowance = TOP_PADDING,
}: {
  expanded: boolean
  scrollTop: number
  summaryRef: React.RefObject<HTMLDivElement>
  detailsRef: React.RefObject<HTMLDivElement>
  offsetAllowance?: number
}) =>
  useMemo(() => {
    if (!expanded || !summaryRef?.current || !detailsRef.current) return
    const { height } = summaryRef.current.getBoundingClientRect()
    const { top, bottom } = detailsRef.current.getBoundingClientRect()
    // additional 1px is added to cover subpixel gap that can appear on scroll
    let offset = headerSize - top + height - offsetAllowance - 1
    const bottomPosition = bottom - height
    if (offset > 0 && bottomPosition > 0) {
      return {
        position: 'relative',
        top: offset,
      }
    }
  }, [expanded, scrollTop]) // eslint-disable-line react-hooks/exhaustive-deps
