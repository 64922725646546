import { useCallback } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'

export const useCreateCampaignEvent = () => {
  const [request] = useFetch()

  return useCallback(
    (campaignId, opportunityId, type) => {
      return request({
        method: 'post',
        url: `/campaigns/${campaignId}/event`,
        config: {
          body: {
            opportunityId,
            type,
          },
        },
      })
    },
    [request],
  )
}
