import React, { FC } from 'react'
import isUndefined from 'lodash/isUndefined'
import { connect } from 'react-redux'
import { setEventField as setExistingEventField } from 'Event/redux/reducers/EditEvent'
import { setEventField as setNewEventField } from 'Event/redux/reducers/CreateEvent'
import { EventFormKeys } from 'Event/interfaces/interfaceCreateEditEvent'
import { useFeatureEnabled } from 'core/feature/hooks'
import EventSurveyEditor from 'Event/components/EventSurveyEditor'
import EventQuestionSetEditor from 'Event/components/EventQuestionSetEditor'

interface EventForm {
  event: {
    questionSets: {
      questionSetId?: number
      surveyId?: number
      name: string
      optional: boolean
      required: boolean
    }[]
  }
}

export interface EventSurveyQuestionSetEditorProps {
  match: {
    url: string
    params: { encodedOccurrence?: string; id?: string }
  }
  createEventForm: EventForm
  editEventForm: EventForm
  setExistingEventField: (key: EventFormKeys, val: any) => any
  setNewEventField: (key: EventFormKeys, val: any) => any
}

export interface EventQuestionnaireEditorProps {
  eventForm: EventForm
  setEventField: (key: EventFormKeys, val: any) => any
  backLink: string
  id?: string
  channel?: string | null
}

function MSTPCreateEditEvent(state: any) {
  return {
    createEventForm: state.CreateEvent,
    editEventForm: state.EditEvent,
  }
}

export function mapDispatchToProps(dispatch: any) {
  return {
    setExistingEventField: (key: EventFormKeys, val: any) => dispatch(setExistingEventField(key, val)),
    setNewEventField: (key: EventFormKeys, val: any) => dispatch(setNewEventField(key, val)),
  }
}

const EventSurveyQuestionSetEditor: FC<EventSurveyQuestionSetEditorProps> = ({
  match: {
    url,
    params: { encodedOccurrence, id },
  },
  createEventForm,
  editEventForm,
  setExistingEventField,
  setNewEventField,
}) => {
  const isNew = isUndefined(id)
  const eventExists = !isUndefined(encodedOccurrence)
  const eventForm = eventExists ? editEventForm : createEventForm
  const setEventField = eventExists ? setExistingEventField : setNewEventField
  const backLink = url.substr(0, isNew ? url.length - 4 : url.indexOf(`/${id}/edit`))
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const channelMatch = window.location.hash.match(/channel=([^&]+)/)
  const channel = channelMatch ? channelMatch[1] : null

  return isQuestionnaireAsSurveyEnabled ? (
    <EventSurveyEditor
      eventForm={eventForm}
      setEventField={setEventField}
      backLink={backLink}
      id={id}
      channel={channel}
    />
  ) : (
    <EventQuestionSetEditor
      eventForm={eventForm}
      setEventField={setEventField}
      backLink={backLink}
      id={id}
      channel={channel}
    />
  )
}

export default connect(MSTPCreateEditEvent, mapDispatchToProps)(EventSurveyQuestionSetEditor)
