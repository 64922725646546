import { useState, useEffect } from 'react';

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export default function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handle = setTimeout( () => setDebouncedValue(value), delay );
            return () => clearTimeout(handle);
        },
        [value, delay] //TODO delay? Not idiomatic hook
    )

    return debouncedValue;
}
