import React, { useCallback, useEffect } from 'react'
import { Button, Grid, Radio, Typography } from '@material-ui/core'
import { AddCircleOutline, MenuBook } from '@material-ui/icons'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import usePersonLocations from '../hooks/usePersonLocations'
import AddLocationPrompt from './AddOrEditLocationPrompt'
import { isEmpty, isEqual } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import Loading from 'components/Loading'
import AddressBookPrompt from './AddressBookPrompt'

const useStyles = makeStyles(theme => ({
  location: {
    border: '1px solid',
    borderColor: '#707070',
    borderRadius: '2px',
    padding: 8,
    margin: 8,
    cursor: 'pointer',
  },
  header: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
}))

export default function TaskLocationField(props) {
  const {
    form: { setFieldValue },
    errors = {},
    recipient,
    field: { name, value },
  } = props
  const classes = useStyles()
  const { personLocations, loading, refresh } = usePersonLocations(recipient && recipient.id)

  const selectLocation = useCallback(
    location => {
      setFieldValue(name, location)
    },
    [setFieldValue, name],
  )

  useEffect(() => {
    if (!isEmpty(personLocations) && !value) {
      selectLocation(personLocations[0].location)
    } else if (!value) {
      selectLocation(undefined)
    }
  }, [recipient, personLocations, selectLocation, value])

  const showAddLocationPrompt = useShowPrompt(AddLocationPrompt)
  const showAddressBookPrompt = useShowPrompt(AddressBookPrompt)

  const handleAddLocationClick = async () => {
    await showAddLocationPrompt({ personId: recipient.id, refreshLocations: refresh })
  }

  const handleAddressBookClick = async () => {
    await showAddressBookPrompt({ person: recipient, refreshParentLocations: refresh })
    refresh()
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.locationHeader}>
        <Typography variant="h4" className={classes.header}>
          Recipient Location
        </Typography>
        {personLocations.length === 0 ? (
          <Button variant="contained" color="primary" onClick={handleAddLocationClick} disabled={!recipient}>
            <AddCircleOutline />
            Add
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={handleAddressBookClick} disabled={!recipient}>
            <MenuBook />
            Open Recipient Address Book
          </Button>
        )}
      </Grid>
      {errors[name] && (
        <p className="MuiFormHelperText-root Mui-error" style={{ color: '#f44336' }}>
          {errors[name]}
        </p>
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          {personLocations.map(pl => (
            <div key={pl.id} className={classes.location} onClick={() => selectLocation(pl.location)}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Radio checked={isEqual(pl.location, value)} />
                </Grid>
                <Grid item>
                  <p>{pl.location.address.line1}</p>
                  <p>
                    {pl.location.address.city} {pl.location.address.state}
                  </p>
                  <p>{pl.location.address.zipCode}</p>
                </Grid>
              </Grid>
            </div>
          ))}
        </>
      )}
    </>
  )
}
