import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useGetCampaign = () => {
  const [request, status] = useFetch()

  const getCampaign = useCallback(
    id => {
      return request({
        method: 'get',
        url: `/campaigns/${id}`,
      })
    },
    [request],
  )

  return [getCampaign, status]
}

export default useGetCampaign
