export const START_KIOSK = 'kiosk.start';
export const START_KIOSK_SUCCEEDED = 'kiosk.start.succeeded';
export const START_KIOSK_FAILED = 'kiosk.start.failed';
export const START_KIOSK_CLEAR_FAILURE = 'kiosk.start.clear-failure';
export const KIOSK_STARTED = 'kiosk.started';

export const STOP_KIOSK = 'kiosk.stop';
export const STOP_KIOSK_SUCCEEDED = 'kiosk.stop.succeeded';
export const STOP_KIOSK_FAILED = 'kiosk.stop.failed';
export const KIOSK_STOPPED = 'kiosk.stopped';

export const startKiosk = (opportunity) => ({ 
    type: START_KIOSK,
    payload: { opportunity } 
});

export const startKioskSucceeded = () => ({ type: START_KIOSK_SUCCEEDED });

export const startKioskFailed = (error) => ({
    type: START_KIOSK_FAILED,
    payload: { error }
});

export const kioskStarted = () => ({ type: KIOSK_STARTED })

export const stopKiosk = (adminCredentials) => ({ 
    type: STOP_KIOSK,
    payload: { adminCredentials }
});

export const stopKioskSucceeded = () => ({ type: STOP_KIOSK_SUCCEEDED });

export const stopKioskFailed = (error) => ({
    type: STOP_KIOSK_FAILED,
    payload: { error }
});

export const clearStopKioskFailure = () => ({ type: START_KIOSK_CLEAR_FAILURE });

export const kioskStopped = () => ({ type: KIOSK_STOPPED })