import React, { useCallback, useEffect, useMemo } from 'react'
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import matchSorter from 'match-sorter'
import PagedTable from 'core/table/components/PagedTable'
import { EmailCell, WithLinkCell, NullableTextCell, PersonNameCell, PhoneNumberCell } from 'core/table/cells'
import Loading from 'components/Loading'
import useTasks from '../hooks/useTasks'
import * as _ from 'lodash'
import { Button } from '@material-ui/core'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ProcessMemberPrompt from './ProcessMemberPrompt'
import REQUIRED_GROUP_TYPES from '../requiredGroupTypes'

export const memberStatuses = {
  applicant: 'applicant',
  member: 'member',
}

const useColumns = ({ refresh }) => {
  const processVolunteerPrompt = useShowPrompt(ProcessMemberPrompt)
  const openProcessVolunteerPrompt = useCallback(
    async props => {
      await processVolunteerPrompt({ ...props, groupName: 'volunteers', groupType: REQUIRED_GROUP_TYPES.volunteer })
    },
    [processVolunteerPrompt],
  )

  return useMemo(() => {
    const MembershipStatusCell = props => {
      const { row, data: allMembers, value: status } = props
      const { original: member } = row
      return (
        <div>
          {status === memberStatuses.applicant && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                openProcessVolunteerPrompt({ member, refresh, allMembers })
              }}
            >
              Process
            </Button>
          )}
        </div>
      )
    }
    return [
      {
        Header: 'Volunteer',
        id: 'name',
        accessor: row => row,
        Cell: props => WithLinkCell(PersonNameCell, id => `/task-persons/${id}`)(props),
      },
      {
        Header: 'Mobile',
        accessor: 'phoneNumber',
        Cell: PhoneNumberCell,
      },
      {
        Header: 'Home',
        accessor: 'homePhoneNumber',
        Cell: PhoneNumberCell,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: EmailCell,
      },
      {
        Header: 'Status',
        accessor: 'membershipStatus',
        Cell: MembershipStatusCell,
      },
      {
        Header: 'Open Tasks',
        accessor: 'openTasks',
        Cell: NullableTextCell,
      },
      {
        Header: 'Completed Tasks',
        accessor: 'completedTasks',
        Cell: NullableTextCell,
      },
      // TODO: add to api
      // {
      //   Header: 'Preferences',
      //   accessor: 'preferences',
      //   Cell: NullableTextCell,
      // },
      // {
      //   Header: 'Last Activity',
      //   accessor: 'lastActivity'
      // },
      // {
      //   Header: 'Tasks',
      //   accessor: 'tasks'
      // },
      // {
      //   Header: 'Rating',
      //   accessor: 'rating'
      // }
    ]
  }, [refresh, openProcessVolunteerPrompt])
}

const useMappedVolunteers = ({ volunteers, tasks, completedTasks }) =>
  useMemo(() => {
    const openTasksByVolunteerId = _.groupBy(tasks, 'volunteer.id')
    const completedTasksByVolunteerId = _.groupBy(completedTasks, 'volunteer.id')
    return _.map(volunteers, volunteer => ({
      ...volunteer,
      openTasks: _.size(openTasksByVolunteerId[volunteer.id]),
      completedTasks: _.size(completedTasksByVolunteerId[volunteer.id]),
    }))
  }, [volunteers, tasks, completedTasks])

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.givenName', 'original.familyName', 'original.email', 'original.phoneNumber', 'original.homePhoneNumber'],
  })

fuzzyMatchFilter.autoRemove = value => !value

export default function VolunteersTab(props) {
  const { query, volunteers, loading: volunteersLoading, refresh } = props
  const { tasks, completedTasks, loading: tasksLoading } = useTasks()
  const loading = volunteersLoading || tasksLoading
  const data = useMappedVolunteers({ volunteers, tasks, completedTasks })
  const table = useTable(
    {
      data,
      columns: useColumns({ refresh }),
      getRowId: row => row.id,
      globalFilter: fuzzyMatchFilter,
      initialState: {
        globalFilter: query,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  )

  useEffect(() => {
    table.setGlobalFilter(query)
  }, [table, query])

  return <div>{loading ? <Loading /> : <PagedTable {...table} />}</div>
}
