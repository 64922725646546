import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentUser } from 'auth/hooks'

export default function useMicroDonationAccessToken() {
  // @ts-ignore
  const [request, { loading, error, result, called }] = useFetch()
  const user = useCurrentUser()
  const getMicroDonationAccessToken = useCallback(() => {
    // @ts-ignore
    request({
      method: 'post',
      url: `/donation/auth/token`,
    })
  }, [request])

  useEffect(getMicroDonationAccessToken, [getMicroDonationAccessToken])

  return {
    result,
    loading,
    error,
    called,
  }
}
