import { Person } from 'people/interface'
import React, { useState } from 'react'
import useChatPromptStyles from 'chats/hooks/useChatPromptStyles'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { Field, Form, Formik } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import MembersSelect from 'chats/components/MembersSelect'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import * as yup from 'yup'
import { ChatPromptProps, Member } from 'chats/interfaces'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

const formSchema = yup.object({
  name: yup.string().required(),
  description: yup.string(),
})

const existingMembers: Member[] = []

const ChatPrompt = (props: ChatPromptProps) => {
  const { showing, close, complete, initialMembers = [], name, description, isNew } = props
  const [members, setMembers] = useState<(Person | PersonSuggestion)[]>(initialMembers)
  const classes = useChatPromptStyles()
  const prefix = isNew ? 'add' : 'edit'

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#${prefix}-chat-prompt` }}
      aria-labelledby={`${prefix}-chat-dialog-title`}
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik
        initialValues={{ name, description }}
        validationSchema={formSchema}
        onSubmit={values => complete({ ...values, members })}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={`${prefix}-chat-prompt`}>
              New Chat
              <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                <CloseIcon className={classes.dialogCloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <>
                <div className={classes.topFields}>
                  <div>
                    <Field
                      fullWidth
                      name="name"
                      label="Name"
                      className={classes.whiteBg}
                      moreHeight={true}
                      newStyles={true}
                      component={StyledOutlinedTextField}
                    />
                  </div>
                  {isNew ? (
                    <div>
                      <MembersSelect members={members} setMembers={setMembers} existingMembers={existingMembers} />
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <Field
                  fullWidth
                  name="description"
                  label="Description"
                  className={classes.whiteBg}
                  multiline
                  rows={3}
                  component={StyledOutlinedTextField}
                />
              </>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <OutlinedButton disabled={isSubmitting} onClick={close}>
                Cancel
              </OutlinedButton>
              <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                {isNew ? 'Create' : 'Save'}
              </ContainedButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: ChatPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <ChatPrompt {...props} />
  </ThemeProvider>
)
