import { isNil, omitBy } from 'lodash'
import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { Volunteer } from 'champion/utils/interface'

export const useFetchVolunteers = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<Volunteer[]>()
  const fetchVolunteers = useCallback(
    async (
      params: {
        reporting?: boolean
        showInactive?: boolean
        startDate?: string
        endDate?: string
        limit?: number
        offset?: number
      } = {},
    ) =>
      request({
        url: `/organizations/${organization.id}/volunteers`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, organization.id],
  )

  return { fetchVolunteers, loading, error }
}
