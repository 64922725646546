import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const emptyArray = []

export const useSetGroupCredentials = (onResultUpdate) => {
  const [request, { result, loading }] = useFetch('Error setting waivers')
  const showSuccess = useSuccessNotification()
  const organization = useCurrentOrg()
  const setGroupCredentials = useCallback(
    async (groupId, credentials) => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully updated waivers')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/organizations/${organization.id}/groups/${groupId}/credentials`,
        config: {
          body: { credentials },
        },
      })
    },
    [request, onResultUpdate, organization, showSuccess],
  )

  return [
    setGroupCredentials,
    {
      result: result || emptyArray,
      loading,
    },
  ]
}
