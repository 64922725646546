import { Grid, makeStyles, Typography } from '@material-ui/core'
import map from 'lodash/map'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import React from 'react'
import ExportContactlessChampionsButton from 'champion/components/ExportContactlessChampionsButton'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import FailedRecipientsList from 'components/dialog/FailedRecipientsList'

const useStyles = makeStyles({
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'left',
  },
  padding: {
    paddingTop: '22px',
  },
})

export const SendNotificationErrorsStep = (props: any) => {
  const classes = useStyles()
  const { rows, complete } = props
  const isSuperAdmin = useHasRole(SUPER_ADMIN)

  return (
    <>
      <Grid item>
        <Typography className={classes.header}>Resend Verification Link</Typography>
      </Grid>
      <FailedRecipientsList
        exportButton={<ExportContactlessChampionsButton data={rows} includeDonations={isSuperAdmin} />}
        errorText="These recipients did not receive the link because they don’t have any contact information on file. Visit their
          profiles and add an email and phone number."
        title="Failed Recipients"
        rows={map(rows, ({ user: { id, familyName, givenName } }: any) => `${givenName} ${familyName}`)}
        className={classes.padding}
      />
      <Grid item container direction="row" justify="flex-end">
        <Grid item>
          <ContainedButton onClick={() => complete()}>Close</ContainedButton>
        </Grid>
      </Grid>
    </>
  )
}
