import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import useSetSortOrder from 'core/table/table-hooks/useSetSortOrder'

export const useReorderLocations = (getLocations: () => void) => {
  const [request] = useFetchRefactored<{ id: number }[]>({
    emptyValue: undefined,
  })

  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()
  const [setSortOrder] = useSetSortOrder()

  return useCallback(
    async ({ rows, sortOption }) => {
      const notification = createNotification('Reordering locations')
      try {
        await setSortOrder('locations', `"${sortOption.field}" ${sortOption.order}`)

        if (sortOption.isCustom) {
          // @ts-ignore
          await request({
            method: 'put',
            url: `/organizations/${currentOrg.id}/locations`,
            config: {
              body: rows.map(({ id }: any, index: number) => ({ id, order: index + 1 })),
            },
          })
        }

        await getLocations()

        notification.onSuccess('Locations reordered!')
      } catch (error) {
        notification.onError(`Could not reorder locations`, error)
      }
    },
    [createNotification, currentOrg.id, getLocations, request, setSortOrder],
  )
}

export default useReorderLocations
