import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useGroupCredentials } from '../hooks/useGroupCredentials'
import { useSetGroupCredentials } from '../hooks/useSetGroupCredentials'
import Loading from 'components/Loading'
import { useSetGroupQuestionSets } from '../hooks/useSetGroupQuestionSets'
import { useFetchGroupQuestionSets } from '../hooks/useFetchGroupQuestionSets'
import { RequirementPicker } from 'question-sets/components/RequirementsPicker'

const GroupRequirementsList = props => {
  const { group } = props
  const [existingCredentials, setExistingCredentials] = useState([])
  const { loading: credentialsLoading } = useGroupCredentials(group.id, setExistingCredentials)
  const [setGroupCredentials] = useSetGroupCredentials(setExistingCredentials)
  const [existingQuestionSets, setExistingQuestionSets] = useState([])
  const [setGroupQuestionSets] = useSetGroupQuestionSets(setExistingQuestionSets)
  const [requirementsList, setRequirementList] = useState([])

  const [fetchGroupQuestionSets] = useFetchGroupQuestionSets(setExistingQuestionSets)

  useEffect(() => {
    fetchGroupQuestionSets(group.id)
  }, [fetchGroupQuestionSets, group])

  useEffect(() => {
    let questionSetList = existingQuestionSets.map(a => {
      return {
        value: {
          ...(a.survey
            ? { surveyId: a.survey.id }
            : {
                questionSetId: a.questionSet.id,
              }),
          name: a.questionSet.name,
          required: a.required,
        },
        type: 'questionSet',
        date: a.createdAt,
      }
    })

    let credentialList = existingCredentials.map(a => {
      if (['waiver', 'identification'].includes(a.type)) {
        return {
          value: a,
          type: a.type,
          date: a.createdAt,
        }
      }

      return null
    })

    const requirementsList = questionSetList.concat(credentialList)
    const sortedRequirements = requirementsList.sort((a, b) => (a.date > b.date ? 1 : -1))

    setRequirementList(sortedRequirements)
  }, [existingCredentials, existingQuestionSets])

  const handleRequirementsSubmit = requirements => {
    const questionSets = requirements
      .filter(x => {
        if (x.type === 'questionSet' || x.tempType === 'questionnaire') {
          return x
        }
      })
      .map(({ value }) => ({
        ...value,
        required: typeof value.required !== 'undefined' ? value.required : !value.optional,
      }))

    const waivers = requirements
      .filter(x => {
        if (x.type === 'waiver' || x.type === 'identification' || x.tempType === 'waiver' || x.tempType === 'id') {
          return x
        }
      })
      .map(x => {
        //TODO value vs id 1 fix 1
        if (x.tempType) {
          return {
            id: x.value.id ? x.value.id : x.value,
            requiresReview: true,
          }
        }
        return {
          id: x.value.id ? x.value.id : x.value,
          requiresReview: true,
        }
      })

    // Functions that filters requirements by type, at this point both objects are ordered and contains the same keys
    const byTypeRequirements = _.groupBy(requirementsList, 'type') // Actual requirements
    const byTypeRequirementsUpdated = _.groupBy(requirements, 'type') // Requirements submitted by user
    const requirementsAreEqual = _.isEqual(requirementsList, requirements) // Comparing the complete objects

    if (!requirementsAreEqual) {
      // Comparing actual questions set with the new questions set submitted
      const questionsSetNotModified = _.isEqual(
        byTypeRequirements['questionSet'],
        byTypeRequirementsUpdated['questionSet'],
      )
      const questionSetsID = questionSets.map(i => i.surveyId || i.questionSetId).sort()
      const existingQuestionSetsID = existingQuestionSets.map(i => i.survey.id || i.questionSet.id).sort()
      // Compare if questions are added or deleted
      const questionsAreEqual = _.isEqual(questionSetsID, existingQuestionSetsID)

      if (!questionsAreEqual || !questionsSetNotModified) {
        // Questions have been modified by user
        setGroupQuestionSets(group.id, questionSets) // Update changes in questions set
      } else {
        setGroupCredentials(group.id, waivers) // Update changes in waivers or credentials
      }
    }
  }

  const loading = credentialsLoading
  if (loading) {
    return <Loading />
  }

  return (
    <RequirementPicker
      loading={loading}
      value={requirementsList}
      onSubmit={handleRequirementsSubmit}
      title={<h3>Requirements:</h3>}
      backLink={`/groups/${group.id}`}
    />
  )
}
export default GroupRequirementsList
