import PageHeader from 'App/components/PageHeader'
import React, { useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'
import { useGetEvent } from 'Event/hooks/useGetEvent'
import Loader from 'Event/components/loader'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { EventsDrilldownInfo } from 'Event/components/EventDrilldownInfo'
import { EventDrilldownRegistrations } from 'Event/components/EventDrilldownRegistrations'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { Summary } from '../components/SummaryTable'
import { RegistrationResponse } from '../scenes/events/detail/volunteers/hooks/RemoveShiftsPrompt'

const EventsDrilldownComponent = ({
  event,
  encodedOccurrence,
  refresh,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  encodedOccurrence: string
  refresh?: () => void
}) => {
  const classes = useEventDrilldownStyles()
  const [scrollTop, setScrollTop] = React.useState(0)
  const handleScroll = useHandleScroll(setScrollTop)
  const [summary, setSummary] = useState<Summary>(
    () =>
      event.timeshifts?.reduce((acc, shift) => {
        for (const role of shift.roles) {
          if (!acc[role.id]) {
            acc[role.id] = {
              title: role.name,
              registered: 0,
              total: 0,
            }
          }
          acc[role.id].total += role.all
          acc[role.id].registered += role.all - role.available
        }
        return acc
      }, {} as Summary) || {},
  )

  const onAdd = useCallback(
    (result: RegistrationResponse) =>
      result.details.forEach(({ role: { id } }) => {
        setSummary(prev => ({
          ...prev,
          [id]: {
            ...prev[id],
            registered: prev[id].registered + 1,
          },
        }))
      }),
    [],
  )

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <EventsDrilldownInfo
        event={event}
        encodedOccurrence={encodedOccurrence}
        scrollTop={scrollTop}
        refresh={refresh}
        summary={summary}
      />
      <EventDrilldownRegistrations event={event} scrollTop={scrollTop} onAdd={onAdd} />
    </div>
  )
}

export const EventsDrilldown = (props: RouteComponentProps<{ encodedOccurrence: string }>) => {
  const [eventsPage] = useEventsPageState()
  const { encodedOccurrence } = props.match.params
  const { event, loading, refresh } = useGetEvent(encodedOccurrence)
  if (loading) {
    return <Loader />
  }
  return (
    <PageHeader
      title={(event as OpportunityResponseWithRecurrenceInfo).name}
      subTitle="Back to Events"
      backLink={eventsPage === EventPages.Calendar ? '/events' : '/list-of-events'}
      noPadding={true}
    >
      <EventsDrilldownComponent
        event={event as OpportunityResponseWithRecurrenceInfo}
        encodedOccurrence={encodedOccurrence}
        refresh={refresh}
      />
    </PageHeader>
  )
}

export default EventsDrilldown
