import { useCallback } from 'react'
import { useSnackbar } from 'notistack'

import useInProgressNotification from './useInProgressNotification'
import useSuccessNotification from './useSuccessNotification'
import useErrorNotification from './useErrorNotification'

export const IN_PROGRESS = 'api-call.in-progress'
export const SUCCESS = 'api-call.success'
export const ERROR = 'api-call.error'

const useCreateApiNotification = ({ isNew = false, isMultiline = false } = {}) => {
  const { closeSnackbar } = useSnackbar()

  const showInProgress = useInProgressNotification()
  const showSuccess = useSuccessNotification(isNew, isMultiline)
  const showError = useErrorNotification()

  return useCallback(
    description => {
      let apiStatus = IN_PROGRESS
      let inProgressKey = showInProgress(description)

      const assertInProgress = () => {
        if (apiStatus !== IN_PROGRESS) {
          throw new Error()
        }
      }

      return {
        onSuccess: msg => {
          assertInProgress()

          apiStatus = SUCCESS
          closeSnackbar(inProgressKey)
          showSuccess(msg)
        },
        onError: (msg, error) => {
          assertInProgress()

          apiStatus = ERROR
          closeSnackbar(inProgressKey)
          showError(msg, error)
        },
      }
    },
    [closeSnackbar, showError, showInProgress, showSuccess],
  )
}

export default useCreateApiNotification
