import { Question, QuestionType } from 'civic-champs-shared/question-sets/types'

export const durationQuestion: Question = {
  questionId: 10000000000,
  questionType: QuestionType.DROPDOWN,
  question: 'How many minutes did you meet for this mentoring session?',
  options: ['5', '10', '15', '30', '60'],
  questionCreatedAt: new Date(),
  isRequired: true,
  response: '',
  completed: false,
  includeOther: false,
  otherAnswer: '',
  disabled: false
}
