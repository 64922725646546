import React, { Fragment, useMemo, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { muiTheme } from 'theme'

import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'
import { AddressField, CustomSwitch, StyledOutlinedTextField } from 'civic-champs-shared/formik/components'

import { AddressType, OrganizationLocationReporting } from '../types'
import { yup } from 'civic-champs-shared/api/utils'
import { Geofencing } from 'civic-champs-shared/core/location/utils'
import { mapEventGeofencingToGeofencing, mapGeofencingToEventGeofencing } from 'utils/event'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { useFetchLocations } from 'locations/hooks'
import { useFeatureEnabled } from '../../core/feature/hooks'
import cn from 'classnames'
import { StyledAutocomplete } from '../../components/StyledAutocomplete'
import { AssociationType, EventGroup } from '../../Event/interfaces/interfaceCreateEditEvent'
import { useEnabledGroups, useOptions } from '../../Event/components/opportunity/GroupPicker/hooks'
import { useGroups } from '../../group/hooks/useGroups'
import { Group } from '../../Event/components/opportunity/GroupPicker/types'
import { Theme as DefaultTheme } from '@material-ui/core/styles/createMuiTheme'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from '../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import moment from 'moment'
import RadioGroupFormField from '../../components/RadioGroupFormField'
import { StyledInput } from '../../civic-champs-shared/formik/components/StyledInput'
import { ArraySchema, ObjectSchema } from 'yup'
import { useDateTimeUtils } from '../../civic-champs-shared/utils/useDateTimeUtils'

export interface AddEditLocationModalProps {
  location?: OrganizationLocationReporting
  showing: boolean
  complete: (value: any) => void
  close: () => void
  forceEnableCheckIns?: true
}

const useStyles = makeStyles<DefaultTheme, { offersEnabled?: boolean }>({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: ({ offersEnabled }) => (offersEnabled ? '700px' : '415px'),
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  content: {
    padding: '8px 0',
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
  subItem: {
    marginTop: ({ offersEnabled }) => (offersEnabled ? 0 : '12px'),
    marginLeft: '40px',
    maxWidth: 'calc(100% - 40px)',
  },
  section: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ offersEnabled }) => (offersEnabled ? '10px' : 0),
    '&:last-child': {
      marginBottom: '14px',
    },
  },
  sectionTitle: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    marginBottom: ({ offersEnabled }) => (offersEnabled ? '-5px' : '5px'),
    '&$noMargin': {
      marginBottom: 0,
    },
  },
  subSection: {
    '& $dateTimePickers, & $subItem': {
      marginLeft: '40px',
      maxWidth: 'calc(100% - 40px)',
    },
  },
  noMargin: {},
  groupsInfo: {
    fontFamily: 'Nunito',
    fontSize: ({ offersEnabled }) => (offersEnabled ? '14px' : '16px'),
    fontWeight: 400,
    lineHeight: ({ offersEnabled }) => (offersEnabled ? '22px' : '24px'),
    letterSpacing: '0.5px',
    textAlign: 'left',
    marginTop: ({ offersEnabled }) => (offersEnabled ? '-6px' : '14px'),
  },
  dateTimePickers: {
    display: 'flex',
    gap: '24px',
    width: '440px',
  },
  dateTimePicker: {
    width: '208px',
  },
  unGap: {
    marginTop: '-10px',
  },
})

interface LocationFormData {
  name: string
  address?: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
    geofencing: Geofencing
    country: string
  }
  enableCheckIns?: boolean
  locationDetails?: string | null
  description?: string
  type?: AddressType
  visibilityGroups?: EventGroup[]
  onboardingGroups?: EventGroup[]
  startDate?: moment.Moment
  endDate?: moment.Moment | null
  startTime?: moment.Moment
  endTime?: moment.Moment | null
  locationIsAddress?: boolean
}

const AddEditLocationDialog = ({
  location,
  showing,
  close,
  complete,
  forceEnableCheckIns,
}: AddEditLocationModalProps) => {
  const { unfakeAsLocalTimeForUIAsString, fakeAsLocalTimeForUI, compileDateTime } = useDateTimeUtils()
  const { fetchLocations } = useFetchLocations()
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const [screen, setScreen] = useState(0)
  const { groups: initialGroups } = useGroups() as { groups: Group[]; loading: boolean }
  const groups = useEnabledGroups(initialGroups)
  const options = useOptions({ groups, associationType: AssociationType.EVENT_PRIVATE_TO_MEMBERS, value: [] })
  const onboardingOptions = useOptions({
    groups,
    associationType: AssociationType.ADD_PARTICIPANTS_TO_GROUP,
    value: [],
  })
  const handleSubmit = ({
    address,
    name,
    description,
    type,
    visibilityGroups,
    onboardingGroups,
    enableCheckIns,
    locationDetails,
    startDate,
    endDate,
    startTime,
    endTime,
    locationIsAddress,
  }: LocationFormData) => {
    const geofencing = address?.geofencing || DEFAULT_GEOFENCING
    complete({
      id: location?.id,
      ...(locationIsAddress ? address : {}),
      name,
      geofencing: mapGeofencingToEventGeofencing(geofencing),
      description,
      type: enableCheckIns ? type : AddressType.Event,
      visibilityGroups,
      onboardingGroups,
      locationDetails: locationDetails || null,
      startsAt:
        startDate && startTime ? unfakeAsLocalTimeForUIAsString(compileDateTime(startDate, startTime)) : undefined,
      endsAt: endDate && endTime ? unfakeAsLocalTimeForUIAsString(compileDateTime(endDate, endTime)) : undefined,
      locationIsAddress,
    })
  }

  const address = useMemo(
    () => ({
      line1: location?.line1 || '',
      line2: location?.line2 || '',
      city: location?.city || '',
      country: location?.county || '',
      state: location?.state || '',
      zip: location?.zipCode || '',
      geofencing: location?.geofencing?.location
        ? mapEventGeofencingToGeofencing(location.geofencing)
        : DEFAULT_GEOFENCING,
    }),
    [location],
  )

  const initialValues = useMemo(() => {
    return {
      name: location?.name || '',
      address,
      locationIsAddress: true,
      ...(offersEnabled
        ? {
            type: location?.visibility || AddressType.Public,
            visibilityGroups: location?.visibilityGroups || [],
            onboardingGroups: location?.onboardingGroups || [],
            description: location?.description || '',
            enableCheckIns: location?.type ? location?.type !== AddressType.Event : false,
            locationDetails: location?.locationDetails || null,
            startDate: location?.startsAt ? fakeAsLocalTimeForUI(location?.startsAt) : moment(),
            startTime: location?.startsAt ? fakeAsLocalTimeForUI(location?.startsAt) : moment(),
            endDate: location?.endsAt ? fakeAsLocalTimeForUI(location?.endsAt) : null,
            endTime: location?.endsAt ? fakeAsLocalTimeForUI(location?.endsAt) : null,
            locationIsAddress: location ? location.locationDetails === null : true,
          }
        : {}),
    }
  }, [location, address, offersEnabled, fakeAsLocalTimeForUI]) as LocationFormData

  const schema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .required('Location Name is Required')
          .test('uniqueName', 'This name has already been used. Please enter a unique name.', value =>
            value === initialValues.name
              ? true
              : fetchLocations({ search: JSON.stringify({ name: value }) }).then(data => !data.length),
          ),

        address: yup
          .mixed()
          .when('locationIsAddress', (locationIsAddress: boolean, schema: ArraySchema<ObjectSchema>) =>
            locationIsAddress
              ? yup
                  .object({
                    line1: yup
                      .string()
                      .min(1, 'An address is required. Please enter an address.')
                      .required('An address is required. Please enter an address.')
                      .typeError('An address is required. Please enter an address.'),
                  })
                  .required('An address is required. Please enter an address.')
                  .typeError('An address is required. Please enter an address.')
                  .test(
                    'unqiueAddress',
                    'This address has already been saved. Please enter a different address.',
                    (value: LocationFormData['address']) => {
                      if (!value) return true
                      const {
                        line1,
                        geofencing: {
                          location: {
                            coordinates: { lat, lng },
                          },
                        },
                      } = value
                      const { address } = initialValues
                      return address &&
                        line1 === address.line1 &&
                        lat === address.geofencing.location.coordinates.lat &&
                        lng === address.geofencing.location.coordinates.lng
                        ? true
                        : fetchLocations({
                            search: JSON.stringify({
                              location: {
                                line1,
                                coordinates: [lng, lat],
                              },
                            }),
                          }).then(data => !data.length)
                    },
                  )
              : schema,
          ),
        locationDetails: yup
          .mixed()
          .when('locationIsAddress', (locationIsAddress: boolean) =>
            !locationIsAddress
              ? yup
                  .string()
                  .min(1, 'An location is required. Please enter an address.')
                  .required('An location is required. Please enter an address.')
                  .typeError('An location is required. Please enter an address.')
              : yup.string().notRequired().nullable(),
          ),
        locationIsAddress: yup.boolean(),
      }),
    [fetchLocations, initialValues],
  )

  const classes = useStyles({ offersEnabled })
  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#location-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit} validateOnChange>
        {({ submitForm, isSubmitting, isValid, values, errors }) => {
          const next = () => {
            if (screen === 0 && values.enableCheckIns) {
              setScreen(1)
            } else {
              submitForm()
            }
          }
          return (
            <Form>
              <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="location-prompt">
                {screen === 1 ? 'Additional Information' : location ? 'Edit Location' : 'Add Location'}
                <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                {
                  [
                    <Fragment key={0}>
                      <Field
                        component={StyledOutlinedTextField}
                        id="name"
                        name="name"
                        label="Location Name"
                        placeholder="Enter a Location Name here…"
                        variant="outlined"
                        fullWidth
                      />
                      {offersEnabled ? (
                        <>
                          <div className={cn(classes.section, classes.subSection)}>
                            <span className={classes.sectionTitle}>Location:</span>
                            <RadioGroupFormField
                              name="locationIsAddress"
                              valueIsBoolean
                              disabled={isSubmitting || !!location?.timesUsedTotal}
                              options={[
                                {
                                  label: 'This Location is tied to a Google Maps address',
                                  value: true,
                                },
                              ]}
                            />
                            {values.locationIsAddress && (
                              <Field
                                className={classes.subItem}
                                fullWidth
                                variant="outlined"
                                labelShrink
                                label="Location Address *"
                                placeholder="Select your Location’s Google Maps address"
                                component={AddressField}
                                name="address"
                                showRadius
                                vertical
                                halfMap
                                hideMap={!values.address?.line1}
                                compactSlider
                                autocompleteDisabled={!!location?.timesUsedTotal}
                              />
                            )}
                            <div className={cn({ [classes.unGap]: !values.locationIsAddress })}>
                              <RadioGroupFormField
                                name="locationIsAddress"
                                valueIsBoolean
                                disabled={isSubmitting || !!location?.timesUsedTotal}
                                options={[
                                  {
                                    label: 'This Location does not have a physical address',
                                    value: false,
                                  },
                                ]}
                              />
                            </div>
                            {!values.locationIsAddress && (
                              <Field
                                component={StyledInput}
                                name="locationDetails"
                                className={classes.subItem}
                                label={'Location*'}
                                placeholder="Enter a location here…"
                                disabled={isSubmitting || !!location?.timesUsedTotal}
                                maxLength={100}
                              />
                            )}
                          </div>

                          <div className={cn(classes.section, classes.noMargin, classes.subSection)}>
                            <span className={classes.sectionTitle}>Visibility:</span>
                            <div className={classes.switch}>
                              <Field
                                component={CustomSwitch}
                                disabled={forceEnableCheckIns}
                                id="enableCheckIns"
                                name="enableCheckIns"
                                thumbColor="#FFF"
                                trackColor={values.enableCheckIns ? '#0F5DB5' : '#8E9192'}
                                type="checkbox"
                                tooltip="Roles set to Inactive will not be available for selection when adding new Events or Activities."
                              />
                              <span>Allow volunteers to check in via Civic Champs mobile app</span>
                            </div>
                            {values.enableCheckIns && (
                              <>
                                <div className={classes.dateTimePickers}>
                                  <div className={classes.dateTimePicker}>
                                    <Field
                                      inputVariant="outlined"
                                      label="Start Date *"
                                      higher
                                      component={StyledKeyboardDatePicker}
                                      name="startDate"
                                    />
                                  </div>

                                  <div className={classes.dateTimePicker}>
                                    <Field
                                      higher
                                      inputVariant="outlined"
                                      label="End Date"
                                      component={StyledKeyboardDatePicker}
                                      name="endDate"
                                      minDate={moment(values.startDate || undefined)
                                        .startOf('day')
                                        .toDate()}
                                      minDateMessage={'The end date cannot occur before the start date.'}
                                      clearable
                                    />
                                  </div>
                                </div>

                                <div className={classes.dateTimePickers}>
                                  <div className={classes.dateTimePicker}>
                                    <Field
                                      inputVariant="outlined"
                                      label="Start Time *"
                                      higher
                                      component={StyledKeyboardTimePicker}
                                      name="startTime"
                                    />
                                  </div>

                                  <div className={classes.dateTimePicker}>
                                    <Field
                                      higher
                                      inputVariant="outlined"
                                      label="End Time"
                                      component={StyledKeyboardTimePicker}
                                      name="endTime"
                                      clearable
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <Field
                          fullWidth
                          variant="outlined"
                          labelShrink
                          label="Address"
                          placeholder="Enter a Location Address"
                          component={AddressField}
                          name="address"
                          vertical
                          showRadius
                          compactSlider
                          autocompleteDisabled={!!location?.timesUsedTotal}
                        />
                      )}
                    </Fragment>,
                    <Fragment key={1}>
                      <div className={cn(classes.section, classes.subSection)}>
                        <Field
                          name="description"
                          label="Location Details"
                          placeholder="Enter any additional information about this location. This is visible to admins only and volunteers will not be able to see this."
                          component={CkEditorStyledField}
                        />
                      </div>
                      <div className={cn(classes.section, classes.subSection)}>
                        <span className={classes.sectionTitle}>Visibility:</span>
                        <RadioGroupFormField
                          name="type"
                          disabled={isSubmitting}
                          options={[
                            {
                              label: 'Allow any volunteer to check into this Location',
                              value: AddressType.Public,
                            },
                          ]}
                        />
                        {values.type === AddressType.Public && (
                          <div className={classes.subItem}>
                            <p className={classes.groupsInfo}>
                              If you would like checking into this Location to confer any Group membership, select the
                              Group(s) below. Volunteers who check in will be sent onboarding questionnaires and waivers
                              needed for Group membership.
                            </p>
                            <Field
                              name="onboardingGroups"
                              placeholder="Select Group (s)"
                              label="Groups"
                              component={StyledAutocomplete}
                              options={onboardingOptions}
                              getOptionLabel={({ name }: EventGroup) => name}
                              disabled={isSubmitting}
                              multiple
                              notched={true}
                            />
                          </div>
                        )}
                        <RadioGroupFormField
                          name="type"
                          disabled={isSubmitting}
                          options={[
                            {
                              label: 'Only allow selected Groups to check into this Location',
                              value: AddressType.Private,
                            },
                          ]}
                        />
                        {values.type === AddressType.Private && (
                          <Field
                            className={classes.subItem}
                            name="visibilityGroups"
                            placeholder="Select Group (s)"
                            label="Groups"
                            component={StyledAutocomplete}
                            options={options}
                            getOptionLabel={({ name, closed, approvedMembersOnly }: EventGroup) =>
                              !closed
                                ? name
                                : approvedMembersOnly
                                ? `${name} - Approved members`
                                : `${name} - Applicants`
                            }
                            disabled={isSubmitting}
                            multiple
                            notched={true}
                          />
                        )}
                      </div>
                    </Fragment>,
                  ][screen]
                }
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton
                  disabled={isSubmitting}
                  onClick={() => {
                    if (screen === 0) {
                      close()
                    } else {
                      setScreen(0)
                    }
                  }}
                >
                  {screen === 0 ? 'Cancel' : 'Back'}
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} onClick={next}>
                  {screen === 0 && values.enableCheckIns ? 'Next' : 'Save'}
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export const AddEditLocationModal = (props: AddEditLocationModalProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddEditLocationDialog {...props} />
  </ThemeProvider>
)

export default AddEditLocationModal
