import { map } from 'lodash'
import React, { useMemo } from 'react'
import useCampaignsSummary from '../hooks/useCampaignsSummary'
import SummaryCards from 'core/table/components/SummaryCards'
import { NumberCell, CurrencyCell } from 'core/table/cells'

const cards = [
    {
        Header: 'Active Campaigns',
        accessor: 'active.count',
        Cell: NumberCell
    },
    {
        Header: 'Total Active Donations',
        accessor: 'active.totalDonations',
        Cell: CurrencyCell
    },
    {
        Header: 'Total Campaigns',
        accessor: 'count',
        Cell: NumberCell
    },
    {
        Header: 'Total Donations',
        accessor: 'totalDonations',
        Cell: CurrencyCell
    },
    {
        Header: 'Average Campaign',
        accessor: 'averageDonations',
        Cell: CurrencyCell
    }
]

export default function CampaignsSummaryCards(props) {
    //TODO this is getting duplicated
    const { rows } = props
    const campaigns = useMemo(
      () => props.campaigns || map(rows, r => r.original), 
      [props.campaigns, rows]
    )

    const summary = useCampaignsSummary(campaigns)

    return <SummaryCards
        cards={cards}
        summary={summary}
    />
}