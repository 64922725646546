import useDeleteChat from './useDeleteChat'

export interface DeleteChatsResult {
  [id: string]: boolean
}
export const useDeleteChats = (): ((chatIds: string[]) => Promise<DeleteChatsResult>) => {
  const [deleteChat] = useDeleteChat()
  return (chatIds: string[]) =>
    new Promise(resolve => {
      const result: DeleteChatsResult = {}
      for (const chatId of chatIds) {
        deleteChat(chatId)
          .then(() => {
            result[chatId] = true
          })
          .catch(() => {
            result[chatId] = false
          })
          .finally(() => {
            if (Object.keys(result).length === chatIds.length) {
              resolve(result)
            }
          })
      }
    }) as Promise<DeleteChatsResult>
}
