import React, { useEffect, useMemo, useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import filter from 'lodash/filter'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import forOwn from 'lodash/forOwn'
import first from 'lodash/first'
import HelpIcon from '@material-ui/icons/Help'
// @ts-ignore
import uuid from 'uuid'
import qs from 'query-string'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import cn from 'classnames'

import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import useSetFilterRowsHistory from 'core/table/table-hooks/useSetFiltersRowHistory'
import useFiltersModalStyles from 'core/table/table-hooks/useFilterModalStyles'

import { FilterModalProps, FilterRow } from 'core/table/interfaces/Filters'
import { getColumnHeaderText } from 'core/table/utils'
import {
  AdvancedPersonAutocomplete,
  AdvancedPersonAutocompleteProps,
} from 'civic-champs-shared/core/AdvancedPersonAutocomplete'

export const getEmptyFilterRow = (): FilterRow => ({
  id: uuid(),
  column: '',
  operator: '',
  value: '',
})

export const useFiltersFromQuery = (
  search: string,
  getOperatorOptions?: (column: string) => any[],
): { filters: any[]; filterRows: any[] } => {
  const query = qs.parse(search, { arrayFormat: 'bracket' })
  const filters = [] as any[]
  const filterRows = [] as FilterRow[]

  forOwn(query, (value: any, key) => {
    const [column, operator] = key.split(':')
    try {
      value = JSON.parse(value)
    } catch (e) {}
    let defaultOperator
    if (operator) {
      value = {
        operator,
        operand: value,
      }
    } else {
      if (!getOperatorOptions) {
        return
      }
      defaultOperator = first(getOperatorOptions(column))
      if (!defaultOperator) return
    }
    filters.push({ id: column, value: value })
    filterRows.push({
      ...getEmptyFilterRow(),
      column,
      operator: operator || defaultOperator?.value,
      value,
    })
  })
  return useMemo(() => ({ filters, filterRows }), [filterRows, filters])
}

const isColumnOperatorUsed = (uiFilterRows: any[], column: string, operator: string) =>
  !!find(uiFilterRows, { column, operator })

const NewFiltersModal = (props: FilterModalProps) => {
  const [filterRows, setFilterRows] = useState<FilterRow[]>(
    props.filterRows?.length ? [...props.filterRows] : [getEmptyFilterRow()],
  )
  const [uiFilterRows, setUiFilterRows] = useState(
    props.filterRows?.length ? [...props.filterRows] : [getEmptyFilterRow()],
  )
  const { history } = props
  const setFilterRowsHistory = useSetFilterRowsHistory(history)

  const onClose = (...args: any) => {
    props.onClose(...args)
    setUiFilterRows([...filterRows])
  }

  const classes = useFiltersModalStyles()

  const handleChange = (rowId: string, value: any, name: string) => {
    setUiFilterRows((state: any[]) =>
      state.map(row =>
        row.id === rowId
          ? {
              ...row,
              [name]: value,
            }
          : row,
      ),
    )
  }

  useEffect(() => {
    if (!isUndefined(history) && !isUndefined(history.location.state) && history.location.state.filterRows) {
      const mappedState = history.location.state.filterRows.map(({ column, value }: FilterRow) => ({ column, value }))
      const mappedFilter = filterRows.map(({ column, value }: FilterRow) => ({ column, value }))
      if (
        JSON.stringify(sortBy(mappedState, ['column, value'])) !==
        JSON.stringify(sortBy(mappedFilter, ['column, value']))
      ) {
        setUiFilterRows(
          history.location.state.filterRows.length ? history.location.state.filterRows : [getEmptyFilterRow()],
        )
        setFilterRows(history.location.state.filterRows)
        // @ts-ignore
        props.table.setAllFilters(history.location.state.filterRows.map(({ column, value }) => ({ id: column, value })))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history && history.location && history.location.state])

  const handleColumnChange = (rowId: string, value: any, name: string) => {
    handleChange(rowId, value, name)
    handleChange(
      rowId,
      find(props.getOperatorOptions(value), option => !isColumnOperatorUsed(uiFilterRows, value, option.value))?.value,
      'operator',
    )
    handleChange(rowId, '', 'value')
  }

  const handleOperatorChange = (rowId: string, value: any) => {
    handleChange(rowId, value, 'operator')
    const rowValue = find(uiFilterRows, { id: rowId })?.value
    if (rowValue?.operator) {
      handleChange(rowId, { ...rowValue, operator: value }, 'value')
    }
  }

  const handleRemoveFilter = (row: any) => {
    // @ts-ignore
    const updatedRows = uiFilterRows.filter(item => item.id !== row.id)
    if (updatedRows.length === 0) updatedRows.push(getEmptyFilterRow())
    setUiFilterRows(updatedRows)
  }

  const applyFilters = () => {
    // @ts-ignore
    const valuableRows = filter(uiFilterRows, ({ value }) => {
      if (isArray(value.operand)) {
        return value.operand.length > 0
      } else if (value.operand) {
        const actualValue = isUndefined(value.operand.value) ? value.operand : value.operand.value
        return typeof actualValue === 'string' ? actualValue.trim() !== '' : actualValue !== null
      }
      return false
    })
    // @ts-ignore
    props.table.setAllFilters(valuableRows.map(({ column, value }) => ({ id: column, value })))

    setFilterRowsHistory(valuableRows)
    props.setFilterRows && props.setFilterRows(valuableRows)
    props.onClose()
    const newUIRows = valuableRows.length === 0 ? [getEmptyFilterRow()] : valuableRows
    // @ts-ignore
    setUiFilterRows([...newUIRows])
    // @ts-ignore
    setFilterRows([...valuableRows])
  }

  const addFilter = () => {
    setUiFilterRows([...uiFilterRows, getEmptyFilterRow()])
  }

  const clearFilters = () => {
    setUiFilterRows([getEmptyFilterRow()])
  }

  return (
    <Box width={650} pb={1} pl={2} pr={2} style={{ backgroundColor: ' #F8FAFF', maxHeight: '100%' }}>
      <p id="filter-title" className={classes.header}>
        Add and Remove Filters{' '}
        <a
          href="https://support.civicchamps.com/how-to-filter-your-volunteer-data-for-reporting"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpIcon className={classes.helpIcon} />
        </a>
      </p>
      {props.subHeader && <p className={classes.subHeader}>{props.subHeader}</p>}
      <Grid container>
        {(uiFilterRows as any[]).map((row, index) => {
          const operatorOptions = props.getOperatorOptions(row.column)
          const selectedColumn = props.columns.find(column => column.id === row.column)
          // @ts-ignore
          const options = selectedColumn?.getFilterAutocomplete?.()
          // @ts-ignore
          const isTags = selectedColumn?.isTags || false
          // @ts-ignore
          const singleSelect = selectedColumn?.singleSelect || false
          let personAutocompleteConfig:
            | Omit<AdvancedPersonAutocompleteProps, 'label' | 'placeholder' | 'value' | 'onChange' | 'variant'>
            | undefined
          // @ts-ignore
          personAutocompleteConfig = selectedColumn?.personAutocompleteConfig

          return (
            <Grid key={index} container className={classes.row} justify="space-between">
              <Grid item>
                <FormControl>
                  <InputLabel className={classes.label} id={`select-label-${index}`}>
                    Filter by
                  </InputLabel>
                  <Select
                    labelId={`select-label-${index}`}
                    id={`option-select-${index}`}
                    className={cn(classes.field, classes.rowField)}
                    classes={{ outlined: classes.outlined }}
                    variant="outlined"
                    name="column"
                    value={row.column}
                    onChange={ev => {
                      // @ts-ignore
                      handleColumnChange(row.id, ev.target.value, ev.target.name)
                    }}
                  >
                    {props.columns
                      // @ts-ignore
                      .filter(column => column && column.id && !column.disableFilters)
                      .map(column => (
                        <MenuItem key={column.id} value={column.id}>
                          {getColumnHeaderText(column)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                {operatorOptions.length ? (
                  <FormControl>
                    <InputLabel className={classes.label} id={`select2-label-${index}`}>
                      Filter type
                    </InputLabel>
                    <Select
                      labelId={`select2-label-${index}`}
                      id={`option-select2-${index}`}
                      className={cn(classes.field, classes.operatorField)}
                      classes={{ outlined: classes.outlined }}
                      variant="outlined"
                      name="operator"
                      value={row.operator}
                      onChange={ev => {
                        // @ts-ignore
                        handleOperatorChange(row.id, ev.target.value)
                      }}
                    >
                      {operatorOptions.map(option => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          disabled={isColumnOperatorUsed(uiFilterRows, row.column, option.value)}
                        >
                          {option.displayValue}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <TextField
                    type="text"
                    className={cn(classes.field, classes.operatorField)}
                    InputProps={{
                      classes: {
                        input: cn(classes.outlined, classes.field),
                      },
                    }}
                    disabled={true}
                    placeholder="First select a filter"
                    value=""
                    variant="outlined"
                    onChange={ev => {
                      handleChange(row.id, { operator: row.operator, operand: ev.target.value }, ev.target.name)
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                {row.operator && options ? (
                  <>
                    <Autocomplete
                      multiple={!singleSelect}
                      id={`${index}-options`}
                      options={options.map((option: any) => option?.title || option)}
                      classes={{
                        inputRoot: classes.inputRoot,
                        ...(isTags ? { listbox: classes.tagsListbox, option: classes.tagsOption } : {}),
                      }}
                      value={row?.value?.operand || []}
                      onChange={(_, value) => {
                        handleChange(row.id, { operator: row.operator, operand: value }, 'value')
                      }}
                      filterSelectedOptions
                      disableClearable
                      getOptionSelected={(option: any, value: any) =>
                        option === value || (option?.value && value?.value && option.value === value.value)
                      }
                      getOptionLabel={(option: any) => option?.label || option}
                      renderInput={params => (
                        <TextField
                          {...params}
                          className={cn(classes.field, classes.valueField)}
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              input: cn(classes.outlined, classes.field),
                            },
                          }}
                          variant="outlined"
                          placeholder={row.value ? '' : 'Value'}
                        />
                      )}
                      renderOption={(option: any) => {
                        return isTags ? (
                          <Chip
                            variant="outlined"
                            style={{ backgroundColor: option.color }}
                            label={option?.label || option}
                            classes={{ root: classes.chip, label: classes.chipLabel, avatar: classes.chipAvatar }}
                          />
                        ) : (
                          option?.label || option
                        )
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option: any) => {
                          const optionData = options.find((item: any) => (item?.title || item) === option)
                          return (
                            <Chip
                              variant="outlined"
                              style={isTags ? { backgroundColor: option.color } : undefined}
                              label={option?.label || option}
                              avatar={optionData?.avatar}
                              classes={{ root: classes.chip, label: classes.chipLabel, avatar: classes.chipAvatar }}
                              {...getTagProps({ index })}
                            />
                          )
                        })
                      }
                    />
                  </>
                ) : row.operator && personAutocompleteConfig ? (
                  <AdvancedPersonAutocomplete
                    {...personAutocompleteConfig}
                    variant={'filter'}
                    label={'value'}
                    value={row?.value?.operand || null}
                    excludeNonGroupMembers={true}
                    onChange={operand => {
                      handleChange(row.id, { operator: row.operator, operand }, 'value')
                    }}
                  />
                ) : (
                  <TextField
                    // @ts-ignore
                    type={selectedColumn && selectedColumn.filter === 'dynamicNumber' ? 'number' : 'text'}
                    className={cn(classes.field, classes.valueField)}
                    InputProps={{
                      classes: {
                        input: cn(classes.outlined, classes.field),
                      },
                    }}
                    disabled={!row.operator}
                    placeholder={!row.operator ? 'First select a filter' : 'Type a value'}
                    name="value"
                    value={row.value && row.value.operand}
                    variant="outlined"
                    onChange={ev => {
                      handleChange(row.id, { operator: row.operator, operand: ev.target.value }, ev.target.name)
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                <CloseIcon
                  style={{ cursor: 'pointer', fontSize: '21px', marginTop: '11px', color: 'rgba(0, 0, 0, 0.54)' }}
                  onClick={() => handleRemoveFilter(row)}
                />
              </Grid>
            </Grid>
          )
        })}
        <Button onClick={addFilter} className={classes.addFilterRowButton} startIcon={<AddIcon />}>
          Add Filter
        </Button>
      </Grid>
      <Divider />
      {props.binaryFilters && (
        <Grid container direction="column">
          {props.binaryFilters?.map(({ name, value, onChange }) => (
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} fontSize="small" />}
                  checkedIcon={<CheckBoxIcon className={classes.checkboxChecked} fontSize="small" />}
                  checked={value}
                  onChange={(e, v) => onChange(v)}
                />
              }
              label={name}
              key={name}
            />
          ))}
        </Grid>
      )}
      <Box display="flex" mt={1} mb={1}>
        <Button onClick={clearFilters} className={classes.actionButton}>
          Clear All Filters
        </Button>
        <Box ml="auto" display="inline-block">
          <Button className={classes.actionButton} onClick={onClose}>
            Cancel
          </Button>

          <Box ml={1} display="inline-block">
            <Button className={classes.actionButton} onClick={applyFilters}>
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NewFiltersModal
