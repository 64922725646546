import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useStripeProduct(id) {
  const [request, { result, loading, called, error }] = useFetch('Error fetching stripe products')
  const getStripeProducts = useCallback(async () => {
    return request({
      method: 'get',
      url: `/stripe-products/${id}`,
    })
  }, [request, id])

  useEffect(() => {
    getStripeProducts()
  }, [id])

  return useMemo(
    () => ({ result, loading: loading || !called, called, error, refresh: getStripeProducts }),
    [result, loading, called, error, getStripeProducts],
  )
}
