import { Field, Form, Formik } from 'formik'
import React from 'react'
import TaskVolunteerField from '../formik/TaskVolunteerField'
import BackLink from 'donation/campaigns/components/BackLink'
import { makeStyles, Button, Grid } from '@material-ui/core'
import { useAssignVolunteerTask, useRemoveVolunteerFromTask, useStartTask } from '../hooks'
import { useHistory } from 'react-router'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import * as _ from 'lodash'
import useTask from '../hooks/useTask'
import Loading from 'components/Loading'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginTop: theme.spacing(2),
  },
  unassignButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.neutral.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    },
  },
  cancelButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}))

export default function UpdateTask(props) {
  const { id: taskId } = props.match.params
  const history = useHistory()
  const classes = useStyles()
  const { task, loading: taskLoading } = useTask(taskId)
  const [assignVolunteer, assignLoading] = useAssignVolunteerTask()
  const [removeVolunteer, removeLoading] = useRemoveVolunteerFromTask()
  const [startTask, startLoading] = useStartTask()
  const confirmPrompt = useConfirm()
  const showError = useErrorNotification()

  const handleUnassignClick = async () => {
    const confirm = await confirmPrompt(
      'This will remove the current volunteer from this task. Are you sure you want to unassign the volunteer?',
      {
        title: 'Unassign Volunteer?',
        confirmText: 'Yes',
        rejectText: 'No',
      },
    )

    if (confirm) {
      try {
        await removeVolunteer(task)
        history.push(`/tasks/${task.id}`)
      } catch (err) {
        showError('There was a problem unassigning the volunteer.', err)
      }
    }
  }

  const handleStartTaskClick = async () => {
    try {
      await startTask(task)
      history.push(`/tasks/${task.id}`)
    } catch (error) {
      showError('There was a problem starting the task', error)
    }
  }

  const handleSubmit = async form => {
    try {
      if (form.volunteer) {
        // todo: should happen in single network request
        await removeVolunteer(task)
        await assignVolunteer(task, {
          startTime: task.startTime,
          endTime: task.endTime,
          volunteerId: form.volunteer.id,
        })
      }

      history.push(`/tasks/${taskId}`)
    } catch (error) {
      showError('There was a problem updating the task', error)
    }
  }

  const initialValues = {
    volunteer: task.volunteer,
  }

  const loading = taskLoading || assignLoading || removeLoading || startLoading
  return (
    <div>
      <BackLink to="/helping-hands" text="Back to Helping Hands Overview" />
      {loading ? (
        <Loading />
      ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values }) => (
            <Form>
              <Field name="volunteer" component={TaskVolunteerField} />
              <Grid container direction="row" justify="center" spacing={2} className={classes.buttonRow}>
                <Grid item>
                  <Button variant="contained" className={classes.unassignButton} onClick={handleUnassignClick}>
                    Unassign
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!values.volunteer || _.isEqual(values.volunteer, task.volunteer)}
                  >
                    Reassign
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={handleStartTaskClick}
                    color="secondary"
                    variant="contained"
                    disabled={!_.isEqual(values.volunteer, task.volunteer)}
                  >
                    Start Task
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
