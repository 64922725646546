import React from 'react'
import ActionMenu from 'components/menu/ActionMenu'
import ExportOpportunitiesActionItem from './ExportOpportunitiesActionItem'
import RequireRole from 'auth/components/RequireRole'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import ActionMenuItem from 'components/menu/ActionMenuItem'
import { ListItemIcon } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

export default function OpportunityActionMenu(props) {
    const { opportunities, selected, onUpdateOpportunity } = props

    const handleStartEdit = () => {
        onUpdateOpportunity(selected)
    }

    return (
        <ActionMenu>
            <RequireRole role={INTERNAL_ADMIN}>
                <ActionMenuItem
                    disabled={!selected}
                    onClick={handleStartEdit}
                >
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    Edit Opportunity
                </ActionMenuItem>
            </RequireRole>
            <ExportOpportunitiesActionItem opportunities={opportunities} />
        </ActionMenu>
    )
}
