import { fork, takeEvery, take, race, delay, select, all, call, put } from 'redux-saga/effects'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import { getEncodedOccurrence } from '../selectors'
import {
  GET_VOLUNTEERS,
  getVolunteers,
  getVolunteersSucceeded,
  getVolunteersFailed,
  KIOSK_STARTED,
  KIOSK_STOPPED,
} from '../actions'
import processRawVolunteers from './utils/processRawVolunteers'

export default function* volunteersSaga() {
  yield fork(watchGetVolunteers)
  yield fork(synchronizeVolunteersFlow)
}

export function* synchronizeVolunteersFlow() {
  while (true) {
    yield take(KIOSK_STARTED)
    yield race([
      take(KIOSK_STOPPED),
      call(function* () {
        while (true) {
          yield put(getVolunteers())
          yield delay(1000 * 60 * 5)
        }
      }),
    ])
  }
}

export function* watchGetVolunteers() {
  yield takeEvery(GET_VOLUNTEERS, onGetVolunteers)
}

export function* onGetVolunteers() {
  try {
    let [allVolunteers, activeVolunteers] = yield all([fetchVolunteers(), fetchActiveVolunteers()])
    let volunteers = processRawVolunteers(allVolunteers, activeVolunteers)

    yield put(getVolunteersSucceeded(volunteers))
  } catch (error) {
    yield put(getVolunteersFailed(error))
  }
}

function* fetchVolunteers() {
  let encodedOccurrence = yield select(getEncodedOccurrence)
  let url = `/opportunity_instances/${encodedOccurrence}/volunteer_roster`
  return yield call(requestWithRetry, { url })
}

function* fetchActiveVolunteers() {
  let encodedOccurrence = yield select(getEncodedOccurrence)
  let url = `/opportunity_instances/${encodedOccurrence}/active_volunteers`
  return yield call(requestWithRetry, { url })
}
