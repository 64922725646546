import React, { useEffect, useMemo } from 'react'
import { useTable, useSortBy, usePagination, useFilters } from 'react-table'
import { DEFAULT_FILTERS, DATE_RANGE } from 'core/table/filters'
import { DateTimeCell, CurrencyCell, HoursCell, CheckMarkCell } from 'core/table/cells'
import { filter, get } from 'lodash'
import { CampaignEventType } from '../interface'

const campaignEventColumns = [
  {
    Header: 'Date',
    accessor: 'occurredAt',
    Cell: DateTimeCell,
    filter: DATE_RANGE,
  },
  {
    id: 'volunteer',
    Header: 'Volunteer',
    accessor: event => {
      const { givenName, familyName } = event.user || {}
      return filter([givenName, familyName]).join(' ')
    },
  },
  {
    Header: 'Hours',
    accessor: 'hoursVolunteered',
    Cell: HoursCell,
  },
  {
    id: 'eventType',
    Header: 'Offer Made',
    accessor: `events.${CampaignEventType.VIEWED}`,
    Cell: CheckMarkCell,
    filter: 'eventType',
  },
  {
    Header: 'Campaign Clicks',
    accessor: `events.${CampaignEventType.CAMPAIGN_CLICKED}`,
    Cell: CheckMarkCell,
  },
  {
    Header: 'Donation Clicks',
    accessor: `events.${CampaignEventType.DONATION_CLICKED}`,
    Cell: CheckMarkCell,
  },
  {
    Header: 'Donations',
    accessor: activity => get(activity, 'events.donations', 0) / 100,
    Cell: ({ cell }) => (cell.value ? CurrencyCell({ cell }) : ''),
  },
]

export default function useCampaignEventsTable(props) {
  const { combinedActivitiesAndEvents, dateRange, campaignEventType } = props
  const table = useTable(
    {
      data: combinedActivitiesAndEvents,
      columns: campaignEventColumns,
      filterTypes: {
        ...DEFAULT_FILTERS,
        eventType: (rows, id, campaignEventType) => {
          return [
            CampaignEventType.VIEWED,
            CampaignEventType.CAMPAIGN_CLICKED,
            CampaignEventType.DONATION_CLICKED,
          ].includes(campaignEventType)
            ? rows.filter(row => row.original.events[campaignEventType])
            : rows
        },
      },
      initialState: {
        filters: useMemo(
          () => [
            { id: 'occurredAt', value: dateRange },
            { id: 'eventType', value: campaignEventType },
          ],
          [dateRange, campaignEventType],
        ),
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  useEffect(() => {
    table.setFilter('eventType', campaignEventType)
  }, [campaignEventType])

  return table
}
