import React from 'react'
import { CellProps } from 'react-table'
import { makeStyles } from '@material-ui/core/styles'

import { Tag, TagColors } from '../types'

const useStyles = makeStyles({
  cell: {
    display: 'flex',
  },
  colorIcon: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    marginRight: '10px',
  },
})

export const ColorWithNameCell = ({ cell }: CellProps<Tag>) => {
  const tag = cell.row.original
  const classes = useStyles()

  const { color, colorName } = tag

  return (
    <div className={classes.cell}>
      <div
        className={classes.colorIcon}
        style={{
          backgroundColor: color,
        }}
      />
      {Object.values(TagColors).includes(color.toLowerCase() as TagColors) ? colorName : 'Discontinued'}
    </div>
  )
}
