import { Button, Grid } from '@material-ui/core'
import { Field } from 'formik'
import * as _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Icon } from '@mdi/react'
import { mdiMinusCircleOutline } from '@mdi/js'
import React from 'react'
import {CREATE_NEW_ID} from "./hooks";


export const DirtyRow = props => {
  const { formikBag, index, getOptionsLabel, handleNewWaiver, arrayHelpers, options, credentialsOnScreen = [] } = props
  const { values, errors, setFieldValue } = formikBag

  const newCredentialAdded = values.requirements.filter(item => item.type === 'newRequirement').map(i => i.value)
  // Array of credentials ids, that won't be displayed on the screen
  const credentialsSetId = credentialsOnScreen.map(i => i?.value.id).concat(newCredentialAdded)
  const unusedOptions = options.filter(i => {
    return !credentialsSetId.includes(i?.credential?.id) // Credential option that has't been used yet
  })

  return (
    <>
      <Grid item xs={8}>
        <Field
          name={`requirements.${index}.value`}
          options={unusedOptions}
          disableClearable
          value={_.get(values, `requirements.${index}.value`)}
          getOptionLabel={getOptionsLabel}
          filterOptions={createFilterOptions({ stringify: getOptionsLabel })}
          component={Autocomplete}
          onChange={async (event, selection) => {
            if (selection === CREATE_NEW_ID) {
              await handleNewWaiver(setFieldValue, index)
            } else {
              setFieldValue(`requirements.${index}.value`, selection.credential.id)
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!_.get(errors, `requirements.${index}.value`)}
              // helperText={_.get(errors, `requirements.${index}.value`)}
              classes={{ root: 'custom-input-component' }}
              variant="outlined"
              placeholder="Select an ID Prompt"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item container xs={4} justify="flex-end" direction="row">
        <Button
          variant="contained"
          style={{ backgroundColor: '#ff6f3e', color: 'white'}}
          onClick={() => arrayHelpers.remove(index)}
        >
          <Icon path={mdiMinusCircleOutline} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
          Remove
        </Button>
      </Grid>
    </>
  )
}
