import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { ColumnState } from 'core/table/interfaces/ColumnState'

export const useSetColumnState = (): [
  (tableName: string, state: ColumnState[]) => Promise<ColumnState[]>,
  Status<ColumnState[]>,
] => {
  const [request, status] = useFetchRefactored<ColumnState[]>({
    emptyValue: undefined,
  })

  const setColumnState = useCallback(
    (tableName, state) =>
      request({
        method: 'put',
        url: `/user/tables/${tableName}/columns`,
        config: {
          body: state,
        },
      }),
    [request],
  )

  return [setColumnState, status]
}

export default useSetColumnState
