import {
    GIVINGORGS_REQUEST,
    GIVINGORGS_SUCCESS,
  } from '../actions/givingOrgs';
  
  export const defaultState = {
    opportunities: [],
    loading: false,
  };
  
  export default function config(state = defaultState, action) {
    switch (action.type) {
      case GIVINGORGS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case GIVINGORGS_SUCCESS:
        return {
          ...state,
          opportunities: action.payload.opportunities,
          loading: false
        };
  
      default:
        return state;
    }
  }
  