import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import AddGroupPrompt from '../components/AddGroupPrompt';
import { useCallback } from 'react';

const useAddGroupPrompt = (onAddGroup) => {
    const prompt = usePrompt(AddGroupPrompt);

    return useCallback(() => {
        if(prompt.showing) return;

        //TODO this is still awkward and we're still repeating it...
        const unlistens = [
            prompt.onComplete(onAddGroup),
            prompt.onHide( () => { unlistens.forEach( unlisten => unlisten() ) })
        ]

        prompt.show();
    }, [onAddGroup, prompt])
}

export default useAddGroupPrompt
