import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { useCallback } from 'react'

export const useDeleteChat = (): [(chatId: string) => Promise<{ status: number }>, Status<{ status: number }>] => {
  const [request, status] = useFetchRefactored<{ status: number }>()
  const org = useCurrentOrg()

  const deleteChat = useCallback(
    (chatId: string) =>
      request({
        method: 'del',
        url: `/organizations/${org.id}/chats/${chatId}`,
      }),

    [request, org],
  )

  return [deleteChat, status]
}

export default useDeleteChat
