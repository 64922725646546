import React from 'react'
import useActiveCampaigns from '../hooks/useActiveCampaigns'
import Loading from '../../../components/Loading'
import { Field, Formik } from 'formik'
import { Button, Grid, MenuItem } from '@material-ui/core'
import { TextField, Select } from 'formik-material-ui'
import { round } from 'lodash'

export const CampaignDemoSelector = props => {
  const { result: campaigns, loading } = useActiveCampaigns()
  if (loading) {
    return <Loading />
  }
  if (campaigns.length === 0) {
    return 'No Active Campaigns'
  }
  const initialValues = {
    campaign: campaigns[0],
    hours: campaigns[0].minHours,
  }
  const handleFormikSubmit = values =>
    props.history.push(`/campaigns/demo/opportunity/${values.campaign.opportunity.id}/hours/${values.hours}`)
  return (
    <>
      <h2>Select your demo campaign and hours volunteered</h2>
      <Formik initialValues={initialValues} onSubmit={handleFormikSubmit} noValidation>
        {({ dirty, values, errors, submitCount, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Field component={Select} id="campaign" name="campaign" label="Campaign" variant="outlined" fullWidth>
                    {campaigns.map(campaign => (
                      <MenuItem key={`${campaign.id}-${campaign.opportunity.id}-`} value={campaign}>
                        <b>
                          Campaign:&nbsp;
                        </b>{campaign.name} -&nbsp;
                        <b>
                          Opportunity:&nbsp;
                        </b>{campaign.opportunity.name} -&nbsp;
                        <b>
                          Min. Hours:&nbsp;
                        </b>{round(campaign.minHours, 1)}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item>
                  <Field
                    component={TextField}
                    type="number"
                    id="hours"
                    inputProps={{ min: 0, step: 0.5 }}
                    name="hours"
                    label="Hours"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={values.hours < values.campaign.minHours}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
