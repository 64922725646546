import { isObject } from 'lodash';
import moment from 'moment';

export const DATE_RANGE_LAST_WEEK    = "6";
export const DATE_RANGE_THIS_WEEK    = "5";
export const DATE_RANGE_NEXT_WEEK    = "4";
export const DATE_RANGE_LAST_MONTH   = "8";
export const DATE_RANGE_THIS_MONTH   = "7";
export const DATE_RANGE_NEXT_MONTH   = "11";
export const DATE_RANGE_LAST_QUARTER = "12";
export const DATE_RANGE_THIS_QUARTER = "13";
export const DATE_RANGE_LAST_YEAR    = "10";
export const DATE_RANGE_THIS_YEAR    = "9";
export const DATE_RANGE_LAST_7_DAYS  = "1";
export const DATE_RANGE_LAST_30_DAYS = "2";
export const DATE_RANGE_LAST_90_DAYS = "3";

export const DateRangePresetOptions = [
    { label: 'Last Week',    value: DATE_RANGE_LAST_WEEK    },
    { label: 'This Week',    value: DATE_RANGE_THIS_WEEK    },
    { label: 'Next Week',    value: DATE_RANGE_NEXT_WEEK    },
    { label: 'Last Month',   value: DATE_RANGE_LAST_MONTH   },
    { label: 'This Month',   value: DATE_RANGE_THIS_MONTH   },
    { label: 'Next Month',   value: DATE_RANGE_NEXT_MONTH   },
    { label: 'Last Quarter', value: DATE_RANGE_LAST_QUARTER },
    { label: 'This Quarter', value: DATE_RANGE_THIS_QUARTER },
    { label: 'Last Year',    value: DATE_RANGE_LAST_YEAR    },
    { label: 'This Year',    value: DATE_RANGE_THIS_YEAR    },
    { label: 'Last 7 Days',  value: DATE_RANGE_LAST_7_DAYS  },
    { label: 'Last 30 Days', value: DATE_RANGE_LAST_30_DAYS },
    { label: 'Last 90 Days', value: DATE_RANGE_LAST_90_DAYS },
  ];

export const getDateRangeFromPreset = (option) => {
    let today = moment();

    switch(option) {
        case DATE_RANGE_LAST_WEEK:    
            return {
                startDate: today.clone().subtract(1, 'week').startOf('week'),
                endDate: today.clone().subtract(1, 'week').endOf('week')
            };

        case DATE_RANGE_THIS_WEEK:   
            return {
                startDate: today.clone().startOf('week'),
                endDate: today.clone().endOf('week')
            };

        case DATE_RANGE_NEXT_WEEK:    
            return {
                startDate: today.clone().add(1, 'week').startOf('week'),
                endDate: today.clone().add(1, 'week').endOf('week')
            };

        case DATE_RANGE_LAST_MONTH:  
            return {
                startDate: today.clone().subtract(1, 'month').startOf('month'),
                endDate: today.clone().subtract(1, 'month').endOf('month')
            };

        case DATE_RANGE_THIS_MONTH:
            return {
                startDate: today.clone().startOf('month'),
                endDate: today.clone().endOf('month')
            };

        case DATE_RANGE_NEXT_MONTH:  
            return {
                startDate: today.clone().add(1, 'month').startOf('month'),
                endDate: today.clone().add(1, 'month').endOf('month')
            };

        case DATE_RANGE_LAST_QUARTER:  
            return {
                startDate: today.clone().subtract(1, 'quarter').startOf('quarter'),
                endDate: today.clone().subtract(1, 'quarter').endOf('quarter')
            };

        case DATE_RANGE_THIS_QUARTER:  
            return {
                startDate: today.clone().startOf('quarter'),
                endDate: today.clone().endOf('quarter')
            };

        case DATE_RANGE_LAST_YEAR:  
            return {
                startDate: today.clone().subtract(1, 'year').startOf('year'),
                endDate: today.clone().subtract(1, 'year').endOf('year')
            };

        case DATE_RANGE_THIS_YEAR:
            default:
            return {
                startDate: today.clone().startOf('year'),
                endDate: today.clone().endOf('year')
            };

        case DATE_RANGE_LAST_7_DAYS:
            return {
                startDate: today.clone().subtract(7, 'days'),
                endDate: today
            };
        
        case DATE_RANGE_LAST_30_DAYS:
            return {
                startDate: today.clone().subtract(30, 'days'),
                endDate: today,
            };
    
        case DATE_RANGE_LAST_90_DAYS:
            return {
                startDate: today.clone().subtract(90, 'days'),
                endDate: today,
            };
    }
};

//TODO write a test!
export const getPresetFromDateRange = (dateRange) => {
    if(! isObject(dateRange) ) return null;

    let { startDate, endDate } = dateRange;

    for(let { value: preset } of DateRangePresetOptions) {
        let presetRange = getDateRangeFromPreset(preset);
        if(
            startDate.isSame(presetRange.startDate, 'day') &&
            endDate.isSame(presetRange.endDate, 'day')
        ) {
            return preset;
        }
    }

    return null;
}