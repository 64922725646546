import { useCallback, useEffect, useState } from 'react'
import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { mapEventGeofencingToGeofencing } from 'utils/event'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'

export const useGetEvent = (
  encodedOccurrence?: string,
  populateRecurrenceInfo = true,
  preload = true,
): { event: OpportunityResponseWithRecurrenceInfo | null; loading: boolean; refresh: () => void } => {
  const [done, setDone] = useState(false)
  const [event, setEvent] = useState<OpportunityResponseWithRecurrenceInfo | null>(null)
  const [requestEvent] = useFetch() as any

  const refresh = useCallback(() => {
    ;(async () => {
      setDone(false)
      const result = await requestEvent({
        url: `/opportunity_instances/${encodedOccurrence}/?populateRecurrenceInfo=${populateRecurrenceInfo}`,
      })
      const { geofencing, ...eventData } = result
      setEvent({
        ...eventData,
        geofencing: mapEventGeofencingToGeofencing(geofencing),
      })
      setDone(true)
    })()
  }, [encodedOccurrence, populateRecurrenceInfo, requestEvent])

  useEffect(() => {
    if (preload) {
      refresh()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    event,
    loading: !done,
    refresh,
  }
}
