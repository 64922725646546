import { useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCredentialTypes } from "./useCredentialTypes";

const useCredentialTypesCollection = () => {
  const [credentialIDTypes, operations] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection } = operations
  const { refetch } = useCredentialTypes((credentialTypes) => {
    syncCollection(credentialTypes)
    setInitiallyLoaded(true)
  })


  return [
    {
      credentialIDTypes,
      initiallyLoaded
    },
    {
      refresh: refetch
    },
  ]
}

export default useCredentialTypesCollection
