import useOpenAccountLinkInNewTab from '../hooks/useOpenAccountLinkInNewTab'
import { Button, CircularProgress } from '@material-ui/core'
import { Icon } from '@mdi/react'
import { mdiOpenInNew } from '@mdi/js'
import React from 'react'

export const EditStripeProfileLink = () => {
  const [openAccountLinkInNewTab, { loading }] = useOpenAccountLinkInNewTab()
  return (
    <Button onClick={openAccountLinkInNewTab} disabled={loading}>
        Edit Stripe Profile
        {loading ? <CircularProgress/> : <Icon path={mdiOpenInNew} size={0.8} style={{ verticalAlign: 'middle', fill: '#5F8FE8' }} />}
    </Button>
  )
}
