import { Button, Typography } from '@material-ui/core'
import { AddCircleOutline } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import AsyncAutocomplete from 'components/AsyncAutocomplete'
import { isNull } from 'lodash'
import React from 'react'
import useTaskVolunteers from '../hooks/useTaskVolunteers'
import { filter } from 'lodash'
import { PersonAutocompleteOption } from 'volunteering/components/PersonAutocompleteOption'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  headerRow: {
    verticalAlign: 'middle',
    display: 'flex',
  },
  personPicker: {
    margin: theme.spacing(2),
  },
}))

export default function TaskVolunteerPicker(props) {
  const { value, onChange } = props
  const classes = useStyles()
  const { volunteers, loading, onOpenAddVolunteerPrompt } = useTaskVolunteers()
  const approvedVolunteers = volunteers.filter(volunteer => volunteer.membershipStatus === 'member')
  const handleChange = (e, value) => (!isNull(value) ? onChange(value) : onChange(undefined))

  return (
    <div>
      <div className={classes.headerRow}>
        <Typography variant="h4" className={classes.header}>
          Volunteer
        </Typography>
        <Button variant="contained" onClick={onOpenAddVolunteerPrompt} color="primary">
          <AddCircleOutline />
          Add
        </Button>
      </div>
      <AsyncAutocomplete
        options={approvedVolunteers}
        getOptionLabel={opt => filter([opt.givenName, opt.familyName]).join(' ')}
        renderOption={opt => <PersonAutocompleteOption option={opt} />}
        onChange={handleChange}
        value={value}
        className={classes.personPicker}
        label="Volunteer"
        loading={loading}
      />
    </div>
  )
}
