import React from 'react'
import { Grid, Typography, ListItemIcon  } from '@material-ui/core'
import GavelIcon from '@material-ui/icons/GavelOutlined'
import EditIcon from '@material-ui/icons/Edit'
import { useCurrentOrg } from 'auth/hooks';
import { useTableSearch } from 'components/table/hooks';
import useOrganizationsCollection from '../hooks/useOrganizationsCollection'
import Loading from 'components/Loading';
import TableToolbar from 'components/table/TableToolbarLight';
import DataTable from 'components/DataTable';
import { FixedPointCell, IntegerCell, DateTimeCell } from 'core/table/cells';
import { createDateValueSort } from 'components/table/sort';
import ActionMenu from 'components/menu/ActionMenu';
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions';
import RequireRole from 'auth/components/RequireRole';
import ActionMenuItem from 'components/menu/ActionMenuItem';
import useManageOrganization from '../hooks/useManageOrganization';
import ExportOrganizationsActionItem from './ExportOrganizationsActionItem';
import useCreateOrUpdateOrganizationPrompt from '../hooks/useCreateOrUpdateOrganizationPrompt'
import _ from 'lodash'
import Search from 'core/table/components/Search'
import { AddButton } from 'civic-champs-shared/core/add-button'

const OrganizationNameCell = ({ cell }) => {
    const currentOrg = useCurrentOrg()
    return currentOrg.id === cell.row.original.id ? (<strong>{cell.value}</strong>) : cell.value
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: OrganizationNameCell
  },
  {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateTimeCell,
    sortType: createDateValueSort('createdAt')
  },
  {
    Header: 'Members',
    accessor: 'totalMembers',
    Cell: IntegerCell
  },
  {
    Header: 'Volunteers',
    accessor: 'totalVolunteers',
    Cell: IntegerCell
  },
  {
    Header: 'Activities',
    accessor: 'totalActivities',
    Cell: IntegerCell
  },
  {
    Header: 'Hours',
    accessor: 'totalHoursVolunteered',
    Cell: FixedPointCell
  }
];

const OrganizationActionMenu = (props) => {
    const { selected, organizations, reload } = props

    const manageOrganization = useManageOrganization()
    const onCreateOrUpdateOrganization = useCreateOrUpdateOrganizationPrompt(reload)
    const handleStartViewing = () => {
        if(!selected) return
        manageOrganization(selected)
    }

    return (
        <ActionMenu>
            <RequireRole role={INTERNAL_ADMIN}>
                <ActionMenuItem
                    disabled={!selected}
                    onClick={handleStartViewing}
                >
                    <ListItemIcon>
                        <GavelIcon />
                    </ListItemIcon>
                    Manage Organization
                </ActionMenuItem>
                <ActionMenuItem
                  disabled={!selected}
                  onClick={ () => onCreateOrUpdateOrganization(_.get(selected, 'id')) }
                >
                  <ListItemIcon>
                      <EditIcon />
                  </ListItemIcon>
                  Edit Organization
                </ActionMenuItem>
            </RequireRole>
            <ExportOrganizationsActionItem organizations={organizations} />
        </ActionMenu>
    )
}

const searchConfig = {
    fields: ['name'],
    processTerm: (term, field) => term.toLowerCase(),
    searchOptions: {
        processTerm: term => term.toLowerCase(),
        combineWith: 'AND',
        prefix: true
    }
}

export default function Organizations(props) {
    const [{ organizations, loading, selected }, { reload, setSelected }] = useOrganizationsCollection()
    const { query, setQuery, results: filteredOrgs } = useTableSearch(organizations, searchConfig)
    const onCreateOrUpdateOrganization = useCreateOrUpdateOrganizationPrompt(reload)

    return (
        <div>
            <Grid container style={{ marginBottom: 20 }}>
                <Grid container xs={12} item justify="space-between" direction="row">
                    <Grid xs={6} container alignItems="center" item>
                        <Typography variant="h4">Organizations</Typography>
                        <Grid item style={{ marginLeft: 10 }}>
                          <AddButton onClick={onCreateOrUpdateOrganization} />
                        </Grid>
                    </Grid>
                <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
                    <Grid item sm={6}>
                        <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
                    </Grid>
                </Grid>
               </Grid>
            </Grid>
            {!loading ? (
                <div>
                    <TableToolbar
                        query={query}
                        onQueryChange={setQuery}
                        actionMenu={(
                            <OrganizationActionMenu
                                selected={selected}
                                organizations={filteredOrgs}
                                reload={reload}
                            />
                        )}
                    />
                    <DataTable
                        columns={columns}
                        data={filteredOrgs}
                        onSelectedChanged={setSelected}
                    />
                </div>
            ): <Loading />}
        </div>
    )
}
