import { uniqueId, get, find, isFunction } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useMeasure } from 'react-use'
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core'

import useTaskVolunteeringRoles from '../hooks/useTaskVolunteeringRoles'

export default function TaskRolePicker(props) {
    const { 
        name,
        label,
        value, 
        onChange,
        disabled,
        required,
        error
    } = props

    const [id] = useState(() => uniqueId('task-role-picker-'))
    const [labelRef, { width: labelWidth }] = useMeasure()

    const { roles, loading } = useTaskVolunteeringRoles()

    const selectedId = get(value, 'id', 0)

    const handleChange = useCallback(e => {
        if( isFunction(onChange) ) {
            const newValue = find(roles, { id: e.target.value })
            onChange(newValue)
        }
    }, [roles, onChange])

    return <div>
        <FormControl
            error={!!error}
            disabled={disabled || loading}
            variant="outlined"
            fullWidth
            required={required}
        >
            <InputLabel 
                ref={labelRef} 
                htmlFor={id}
            >
                {label}
            </InputLabel>
            <Select
                value={selectedId}
                onChange={handleChange}
                input={<OutlinedInput
                    labelWidth={labelWidth}
                    id={id}
                    name={name}
                />}
            >
                {roles.map(role => (
                    <MenuItem value={role.id}>{role.name}</MenuItem>
                ))}
            </Select>
            {error && <FormHelperText error={true}>{error}</FormHelperText>}    
        </FormControl>
    </div>
}