import React, {useState} from 'react'
import { Grid, TextField, Chip, makeStyles } from '@material-ui/core'
import Loading from 'civic-champs-shared/core/Loading'
import { Form, Formik, Field } from 'formik'
import { FormikEffect } from 'civic-champs-shared/question-sets/components/FormikEffect'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { useCurrentOrg } from '../../../../auth/hooks'
import { Group } from './types'
import { yup } from 'civic-champs-shared/api/utils'
import { AssociationType, EventGroup } from '../../../interfaces/interfaceCreateEditEvent'
import {useGetGroupOptionLabel, useOptions, useInitialValues, useEnabledGroups} from './hooks'
import {Autocomplete} from "formik-material-ui-lab";
import LockIcon from "@material-ui/icons/Lock";
import CancelIcon from "@material-ui/icons/Cancel";

const useClasses = makeStyles({
  groupsEdit: {
    marginBottom: '20px',
    marginTop: '20px',
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#ff6f3e',
    },
    '& .MuiOutlinedInput-root.Mui-error fieldset': {
      borderColor: '#ff6f3e',
    },
    '& .MuiOutlinedInput-root.Mui-error input': {
      color: '#ff6f3e',
      '&::placeholder': {
        opacity: 1,
      }
    },
  }
})

const groupPickerSchema = yup.object({
  groups: yup
    .array()
    .of(
      yup.object({
        groupId: yup
          .number()
          .integer()
          .positive(),
        name: yup.string(),
        associationType: yup.string().oneOf(Object.values(AssociationType)),
        approvedMembersOnly: yup.boolean(),
      }),
    )
    // @ts-ignore
    .unique(
      'You cannot select the same group twice',
      (group: EventGroup) => `${group.groupId}-${group.associationType}-${group.approvedMembersOnly}`,
    ),
})

interface Props {
  loading: boolean
  value: EventGroup[]
  associationType: AssociationType
  onChange: (groups: EventGroup[]) => void
  onSubmit?: (val: { groups: EventGroup[] }) => void,
  required?: boolean,
  hasError?: boolean,
  errorMessage?: string
}

export const GroupPicker = (props: Props) => {
  const {
    loading,
    value = [],
    associationType,
    onChange,
    onSubmit = () => {},
    required = false,
    hasError = false,
    errorMessage = 'Required field',
  } = props
  const [initialGroups, setInitialGroups] = useState<Group[]>([])
  const currentOrg = useCurrentOrg()
  const getOptionLabel = useGetGroupOptionLabel(associationType)
  const [, { loading: groupsLoading }] = useGetOnMount<Group[]>({
    errorMessage: 'Error fetching groups',
    onSuccess: setInitialGroups,
    emptyValue: [],
    url: `/organizations/${currentOrg.id}/groups`,
  })
  const groups = useEnabledGroups(initialGroups)
  const options = useOptions({ groups, associationType, value })
  const initialValues = useInitialValues(value, associationType)
  const classes = useClasses()

  if (loading || groupsLoading) {
    return <Loading />
  }

  return (
    <Grid container direction="column">
      <Formik initialValues={initialValues} validationSchema={groupPickerSchema} onSubmit={onSubmit} enableReinitialize>
        {formikBag => {
          return (
            <Form>
              <FormikEffect
                onChange={state => {
                  onChange && onChange(state.values.groups)
                }}
                formik={formikBag}
              />
                <Field
                    name="groups"
                    component={Autocomplete}
                    multiple
                    options={options}
                    getOptionLabel={getOptionLabel}
                    fullWidth
                    renderTags={(value: any[], getTagProps: any) =>
                        value.map((option, index) => {
                            return <Chip color="primary" label={getOptionLabel(option)} {...getTagProps({ index })} disabled={option.lockedTag} deleteIcon={option.lockedTag ? <LockIcon/> : <CancelIcon/>}/>
                        })
                    }
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder={`Select a group${required ? '*' : ''}`}
                            className={classes.groupsEdit}
                            required={required}
                            error={hasError}
                            helperText={hasError && errorMessage}
                        />
                    )}
                />
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
