import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { Grid, Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { ReactComponent as CCLogo } from 'civic-champs-shared/public/assets/icons/ccLogoFillable.svg'
import { ThemeProvider } from '@material-ui/styles'
import { muiTheme } from 'theme'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  title: { cursor: 'move' },
  icon: { color: theme.palette.primary.main, width: '78px', height: '100px' },
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    padding: '8px 0',
    textAlign: 'justify',
    margin: 0,
  },
  content: {
    display: 'flex',
    padding: '0 20px',
    gap: '14px',
    alignItems: 'center',
  },
  boldText: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
  },
  button: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    borderRadius: '100px',
    color: '#FFF',
    padding: '8px 24px',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  cancelButton: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    color: '#74777F',
    borderRadius: '100px',
    padding: '8px 24px',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  actions: {
    padding: '8px 20px 20px',
  },
}))

export interface NotificationPermissionRequestDialogProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

export function NotificationPermissionRequestDialog(props: NotificationPermissionRequestDialogProps) {
  return (
    <ThemeProvider theme={muiTheme}>
      <NotificationPermissionRequestDialogBase {...props} />
    </ThemeProvider>
  )
}

function NotificationPermissionRequestDialogBase(props: NotificationPermissionRequestDialogProps) {
  const { showing, complete, close } = props
  const classes = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      onClose={() => close()}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#notify-draggable-dialog-title` }}
      aria-labelledby="notify-draggable-dialog-title"
      maxWidth="xs"
    >
      <DialogContent classes={{ root: classes.content }}>
        <CCLogo className={classes.icon} />
        <Grid container direction="column" alignItems="flex-start" justify="space-between">
          <Typography classes={{ root: cn(classes.boldText, classes.title) }} id="notify-draggable-dialog-title">
            Civic Champs would like to send you notifications
          </Typography>
          <DialogContentText classes={{ root: classes.text }}>
            Allow notifications to receive instant updates on events, volunteer applications, and more
          </DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.cancelButton} autoFocus onClick={() => complete(false)}>
          Don’t Allow
        </Button>
        <Button className={classes.button} onClick={() => complete(true)} variant="contained" color="primary">
          Allow
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationPermissionRequestDialog
