import React from 'react';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export default function PageTitle(props) {
  const title = props.title

  return (
    <>  
    <Typography variant="h4">
        {title}
    </Typography>
    </>
  );
}