import React from 'react'
import { ExportButton } from 'core/table/components'

const filename = 'locations.csv'
const headers = [
  { label: 'Order', key: 'order' },
  { label: 'Name', key: 'name' },
  { label: 'Address', key: 'address' },
  { label: 'Times Used (Active)', key: 'timesUsedActive' },
  { label: 'Times Used (Total)', key: 'timesUsedTotal' },
]

const formatData = (locations: any[]) =>
  locations.map(({ name, order, timesUsedTotal, timesUsedActive, ...location }) => {
    const { line1, line2, city, county, state, zipCode, countryCode } = location
    const address = [line1, line2, city, county, state, zipCode, countryCode].filter(value => !!value).join(', ')
    return {
      name,
      order,
      timesUsedTotal,
      timesUsedActive,
      address,
    }
  })

export const ExportLocationsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => (
  <ExportButton data={data} disabled={disabled} formatData={formatData} headers={headers} filename={filename} />
)

export default ExportLocationsButton
