import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export const useChangePassword = onResultUpdate => {
  const [request, { loading, error, result }] = useFetch(
    'Error changing password.  Please refresh the page and try again',
  )
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const changePassword = useCallback(
    async (person, password) => {
      const confirmed = await confirm(
        `Are you sure you want to change the user's password for ${person.givenName} ${person.familyName}?`,
        {
          title: `Change Password`,
          confirmText: `Confirm`,
          rejectText: 'Cancel',
          subText: `Clicking the 'Confirm' button below will change the user's password`,
        },
      )
      if (!confirmed) {
        return
      }
      return request({
        onSuccess: result => {
          showSuccess(`Successfully changed password for ${person.givenName} ${person.familyName}`)
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/people/${person.id}/password`,
        config: { body: { password } },
      })
    },
    [request],
  )

  return useMemo(() => [changePassword, { loading, error, person: result }], [result, loading, error])
}
