import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'

const useDeleteOrganizationLongCode = () => {
  const [request, status] = useFetch('Error deleting organization long code')
  const organization = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const editOrganizationLongCode = useCallback( (id) => {
    return request({
      method: 'del',
      url: `/organizations/${organization.id}/long-codes/${id}`,
      onSuccess: () => showSuccess('Successfully deleted organization long code')
    })
  }, [request])

  return [editOrganizationLongCode, status]
}

export default useDeleteOrganizationLongCode
