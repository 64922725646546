import _ from 'lodash'
import activitySources from '../../enum/activitySources'
import { TIMESHEET, LOG_OTHER_HOURS } from '../../enum/entryMethod'
import { ADMIN } from '../../enum/actor'

export const getVolunteerName = activity => {
  const first = _.get(activity, 'user.givenName', '')
  const last = _.get(activity, 'user.familyName', '')
  return first + (first && last ? ' ' : '') + last
}

export const getOpportunityName = activity => _.get(activity, 'opportunity.name')

export const isTimesheetActivity = activity => {
  if (!activity) return false

  const { entryMethod } = activitySources[activity.occurredAtSource]
  return entryMethod === TIMESHEET
}

export const isAdminCreatedActivity = activity => {
  if (!activity) return false

  const { actor, entryMethod } = activitySources[activity.occurredAtSource]

  return actor === ADMIN && entryMethod === LOG_OTHER_HOURS
}

export const isLogOtherHoursActivity = activity => {
  if (!activity) return false

  const { entryMethod } = activitySources[activity.occurredAtSource]

  return entryMethod === LOG_OTHER_HOURS
}
