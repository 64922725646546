import { Button, Grid } from '@material-ui/core'
import { Field } from 'formik'
import { CREATE_NEW_WAIVER } from './hooks'
import * as _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Icon } from '@mdi/react'
import { mdiMinusCircleOutline } from '@mdi/js'
import React from 'react'

export const DirtyRow = props => {
  const { formikBag, index, getOptionsLabel, handleNewWaiver, arrayHelpers, options } = props
  const { values, errors, setFieldValue } = formikBag
  return (
      <>
        <Grid item xs={8}>
          <Field
            name={`credentialIds.${index}`}
            options={options}
            disableClearable
            value={_.get(values, `credentialIds.${index}`)}
            getOptionLabel={getOptionsLabel}
            filterOptions={createFilterOptions({ stringify: getOptionsLabel })}
            component={Autocomplete}
            onChange={async (event, selection) => {
              if (selection === CREATE_NEW_WAIVER) {
                await handleNewWaiver(arrayHelpers, index)
              } else {
                setFieldValue(`credentialIds.${index}`, selection.credential.id)
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                error={!!_.get(errors, `credentialIds.${index}`)}
                helperText={_.get(errors, `credentialIds.${index}`)}
                classes={{ root: 'custom-input-component' }}
                variant="outlined"
                placeholder="Select a waiver"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item container xs={4} justify="flex-end" direction="row">
          <Button
            variant="contained"
            style={{ backgroundColor: '#ff6f3e', color: 'white'}}
            onClick={() => arrayHelpers.remove(index)}
          >
            <Icon path={mdiMinusCircleOutline} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
            Remove
          </Button>
        </Grid>
      </>
  )
}
