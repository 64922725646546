import PageHeader from 'App/components/PageHeader'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import Loader from 'Event/components/loader'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { useFetchOpportunityLocation } from 'locations/hooks/useFetchOpportunityLocation'
import { OrganizationLocation, OrganizationLocationReporting } from 'locations/types'
import { LocationDrilldownActivities } from 'locations/components/LocationDrilldownActivities'
import { LocationDrilldownInfo } from 'locations/components/LocationDrilldownInfo'

const LocationDrilldownComponent = ({
  location,
  loadLocation,
}: {
  location: OrganizationLocationReporting
  loadLocation: () => void
}) => {
  const classes = useEventDrilldownStyles()
  const [scrollTop, setScrollTop] = React.useState(0)
  const handleScroll = useHandleScroll(setScrollTop)

  return (
    <div className={classes.root} onScroll={handleScroll}>
      <LocationDrilldownInfo location={location} scrollTop={scrollTop} loadLocation={loadLocation} />
      <LocationDrilldownActivities locationId={location.id} scrollTop={scrollTop} />
    </div>
  )
}

export const LocationDrilldown = (props: RouteComponentProps<{ locationId: string }>) => {
  const locationId = parseInt(props.match.params.locationId)
  const { fetchLocation } = useFetchOpportunityLocation()
  const [loading, setLoading] = useState(true)
  const [location, setLocation] = useState<OrganizationLocationReporting | null>(null)
  const loadLocation = useCallback(() => {
    fetchLocation(locationId).then(result => {
      setLocation(result)
      setLoading(false)
    })
  }, [locationId]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    loadLocation()
  }, [fetchLocation, loadLocation, locationId])

  if (loading) {
    return <Loader />
  }
  return (
    <PageHeader
      title={(location as OrganizationLocation).name as string}
      subTitle="Back to Locations"
      backLink="/locations"
      noPadding={true}
    >
      <LocationDrilldownComponent location={location as OrganizationLocationReporting} loadLocation={loadLocation} />
    </PageHeader>
  )
}

export default LocationDrilldown
