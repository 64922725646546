import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const empty = []

const useOptedOutPhoneNumbers = () => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching opted out phone numbers')
  const getOptOuts = useCallback(async () => {
    return request({
      method: 'get',
      url: `/opted-out-phone-numbers`,
    })
  }, [request])

  useEffect(() => {
    getOptOuts()
  }, [])

  return useMemo(
    () => ({
      result: ((result && result.phoneNumber) || empty).map(phoneNumber => ({ phoneNumber })),
      loading: loading || !called,
      called,
      error,
      refresh: getOptOuts,
    }),
    [result, loading, called, error, getOptOuts],
  )
}

export default useOptedOutPhoneNumbers
