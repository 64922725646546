import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import moment from 'moment-timezone'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useHistory } from 'react-router'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'

import { TableMenuButton } from 'civic-champs-shared/core/TableMenuButton'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { ContainedButton } from 'civic-champs-shared/core'

import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import { useOfferSignupColumns, useStyles as useColumnStyles } from '../hooks/useOfferSignupColumns'
import { useDateRangeFilter } from 'filtering/hooks'
import Loading from 'components/Loading'

import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import CUSTOM_FILTERS from '../../tracking/activity/utils/filters'

import { ARRAY_OPERATOR_OPTIONS, STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import useOfferSignupCollection, { OfferSignup } from '../hooks/useOfferSignupsCollection'
import ExportOfferSignupsButton from './ExportOfferSignupsButton'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import { useGetAllSearchableContacts, useMessageRecipientsPrompt } from 'messages/hooks'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'firstName':
    case 'lastName':
    case 'email':
    case 'mobile':
    case 'name':
    case 'homePhoneNumber':
      return STRING_OPERATOR_OPTIONS

    case 'groups':
      return ARRAY_OPERATOR_OPTIONS

    default:
      return []
  }
}

interface Props {
  tableName: string
  opportunityId: number
}

export const OfferSignups = React.memo(({ tableName, opportunityId }: Props) => {
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const history = useHistory()
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const skipReset = useRef<boolean>()

  const columnStyles = useColumnStyles()
  const [dateRange] = useDateRangeFilter()
  const { filters } = useFiltersFromQuery(history.location.search, getOperatorOptions)

  const [{ signups, loading }, { addSignUps, cancelSignUps }, eventListeners] = useOfferSignupCollection(opportunityId)
  const getSearchableContacts = useGetAllSearchableContacts()

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const organization = useCurrentOrg()

  // @ts-ignore
  const columns = useOfferSignupColumns() as any
  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
    getOperatorOptions,
  })

  const filteredSignups = useMemo(() => {
    if (!signups) return []
    const { startDate, endDate } = dateRange
    const { timeZone } = organization

    if (startDate === undefined || endDate === undefined) return signups

    const filterStart = moment(startDate).tz(timeZone, true)
    const filterEnd = moment(endDate).tz(timeZone, true)

    return signups.filter((signup: OfferSignup) => {
      return moment(signup.createdAt).isBetween(filterStart, filterEnd, 'day')
    })
  }, [dateRange, organization, signups])

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'contact', 'consolidatedContact', 'homePhoneNumber', 'groups'],
        // @ts-ignore
        globalFilter: '',
        filters,
      },
      globalFilter,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
      data: filteredSignups,
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, selectedRowsCount] = useMemo(
    () => [table.selectedFlatRows.map(({ original }: any) => original), table.selectedFlatRows.length],
    [table.selectedFlatRows],
  )

  const onMessageVolunteers = useCallback(() => {
    const recipientPersonIds = selectedRows.map((row: any) => row.person.id)
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }, [getSearchableContacts, selectedRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const onCancelSignups = useCallback(async () => {
    await cancelSignUps(selectedRows)
    table.toggleAllRowsSelected(false)
  }, [cancelSignUps, selectedRows, table])

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable<OfferSignup>
        history={history}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Sign-ups"
        filterSubHeader="Select signup attributes to filter by:"
        buttons={
          <>
            <TableMenuButton
              startIcon={<MessageOutlinedIcon />}
              onClick={onMessageVolunteers}
              disabled={!selectedRowsCount}
              rightMargin
            >
              Message
            </TableMenuButton>
            <ExportOfferSignupsButton data={selectedRows} disabled={!selectedRowsCount} />
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              disabled={!selectedRowsCount}
              onClick={onCancelSignups}
              rightMargin={isSuperAdmin}
            >
              Cancel
            </TableMenuButton>
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addSignUps}>
            Add Sign-Up
          </ContainedButton>
        }
        getOperatorOptions={getOperatorOptions}
        useGlobalSearch
        useFilters
        useDateRange
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
})
