import * as React from 'react'
import {Location} from 'history'
import FeedbackModule from '../../kit/modules/feedback'
import {RouteComponentProps} from 'react-router'

interface MatchParams {
  uuid: string
}

export interface FeedbackSceneProps extends RouteComponentProps<MatchParams> {
  location: Location
}

export default class FeedbackScene extends React.Component<FeedbackSceneProps, {}> {
  render() {
    return (
      <FeedbackModule {...this.props} />
    )
  }
}
