import _ from 'lodash'
import * as React from 'react'
import { useEffect } from 'react'
import { Field, Form, Formik } from 'formik'
import MultiStepNavButton from 'Event/modules/events/create-edit/navigation-button-area'
import './index.scss'
import { AddressField } from 'civic-champs-shared/formik/components/AddressField'
import { Geofencing } from 'civic-champs-shared/core/location/utils'
import { FormikProps } from 'formik/dist/types'
import { mapEventToAddressField } from 'utils/event'

export interface EventAddressForm {
  address: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
    geofencing: Geofencing
    country: string
    utcOffset: number
  }
}

type MapHandler = (...input: any) => void

const LocationModule = (props: any) => {
  const {
    onPrevClick,
    onNextClick,
    onSaveDraftClick,
    isSendingDraftRequest,
    event,
    editMode = false,
    setEventField,
  } = props
  const { published } = event

  const formValues = mapEventToAddressField(event)
  const formRef: React.Ref<FormikProps<EventAddressForm>> = React.createRef()

  const wrapHandler =
    (handler?: MapHandler, allowInvalid: boolean = false) =>
    async (...input: any) => {
      if (formRef.current === null) return
      formRef.current.setTouched({ address: { line1: true } }, false)
      await formRef.current.validateForm()
      if (Object.keys(formRef.current.errors).length) {
        if (allowInvalid) handler?.(...input)
        return
      }
      const { line1: address, city, state, zip, geofencing } = formRef.current.values.address

      setEventField('address', address)
      setEventField('city', city)
      setEventField('state', state)
      setEventField('zip', zip)
      setEventField('geofencing', geofencing)

      handler?.(...input)
    }

  useEffect(() => {
    return () => {
      wrapHandler()
    }
  })

  return (
    <div className="location-module">
      <h2 className="location-module__title">Location</h2>
      <Formik
        onSubmit={() => {}}
        initialValues={formValues}
        validate={(values: EventAddressForm) => {
          const errors: any = {}
          if (!values.address.line1) {
            _.set(errors, ['address', 'line1'], 'Address is required')
          }
          return errors
        }}
        innerRef={formRef}
      >
        <Form className="location-form-wrap">
          <Field
            component={AddressField}
            name="address"
            showRadius={true}
            fieldTitle="Address"
            placeholder="Street Address, City, State, Zip"
            label="Address *"
          />
        </Form>
      </Formik>

      <MultiStepNavButton
        isShowDraftButton={editMode ? !published : true}
        draftButtonText={editMode ? 'Update Draft' : 'Save For Later'}
        onSaveDraftClick={wrapHandler(onSaveDraftClick)}
        onNextClick={wrapHandler(onNextClick)}
        onPrevClick={wrapHandler(onPrevClick, true)}
        isSendingRequest={isSendingDraftRequest}
      />
    </div>
  )
}

export default LocationModule
