import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikWizardContext } from 'civic-champs-shared/formik/FormikWizard'
import { useFormikContext } from 'formik'
import cn from 'classnames'

import SummaryInfoDonations from './SummaryInfoDonations'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'
import './index.scss'

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    width: '497px',
    height: '81px',
    background: '#FFFFFF',
    border: '1px solid #616882',
    borderRadius: '2px',
    opacity: '1',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0px 3px 6px #00000029',
    marginBottom: '14px',
  },
  buttonActive: {
    background: '#5F8FE8',
  },
  logo: {
    width: '30px',
    height: '18px',
    alignSelf: 'center',
    marginLeft: '18px',
    marginRight: '13px',
  },
  logoAccount: {
    width: '35px',
    height: '35px',
    alignSelf: 'center',
    marginLeft: '18px',
    marginRight: '13px',
  },
  logoHeart: {
    width: '30px',
    height: '28px',
    alignSelf: 'center',
    marginLeft: '18px',
    marginRight: '13px',
  },
  logoHand: {
    width: '30px',
    height: '37px',
    alignSelf: 'center',
    marginLeft: '18px',
    marginRight: '13px',
  },
  textContainer: {
    marginTop: '10px',
  },
  text: {
    alignSelf: 'flex-start',
    textAlign: 'left',
    color: '#212121',
  },
  textActive: {
    color: 'white',
  },
  nextImage: {
    width: '182px',
    height: '49px',
  },
  content:{
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    flexDirection: 'column',
  },
  buttonContainer: {
    marginTop: '50px',
  },
  saveDraft: {
    width: '146px',
    height: '49px',
  },
  saveDraftContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    marginBottom: '62px',
    marginLeft: '90px',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    width: '100%',
},
  summaryContainer: {
    borderRadius: '4px 0 0 0',
    boxShadow: '0 4px 7px rgba(100, 124, 142, 0.18)',
    borderTop: '1px solid #ABB6CC',
    borderLeft: '1px solid #ABB6CC',
    borderBottom: '1px solid #ABB6CC',
    padding: '21px 29px',
    alignSelf: 'flex-end',
    marginRight: '-85px',
    height: '750px',
    width: '360px',
}         
}));

export default function TargetAudience() {
    const { values, setFieldValue } = useFormikContext()
    const [audience, setAudience] = useState(() => values.useRecommended ? 'use_recommended' : (values.targetGroup || 'everyone'))

    const stepInfo = useFormikWizardContext()
    const fullStepData = {...values, ...stepInfo}

    //TODO? write updates to 'audience' when targetGroup or useRecommended fields change?

    const classes = useStyles()

    const AudienceButton = (props) => (
      <button
           className={cn(props.selected ? [classes.button, classes.buttonActive] : classes.button)}
           onClick={props.onClick}>
             <img src={props.selected?props.icon.srcActive:props.icon.src } alt={props.label} className={props.icon.class}/>
             <div className={classes.textContainer}>
                 <div className={cn(props.selected ? [classes.text, classes.textActive]: classes.text)}>{props.label}</div>
                 <p className={cn(props.selected ? [classes.text, classes.textActive]: classes.text)}><i>{props.description}</i></p>
              </div>
       </button>
    )

  const targets = [{
    value: 'everyone',
    label: 'Everyone',
    description: 'Ask everyone as often as possible',
    icon: {
      src: '/assets/icons/account-group.svg',
      srcActive: '/assets/icons/account-group-white.svg',
      class: classes.logo
    }
  }, {
    value: 'never_donated',
    label: 'New Donors',
    description: "Optimize for people who haven't yet donated",
    icon: {
      src: '/assets/icons/account.svg',
      srcActive: '/assets/icons/accountWhite.svg',
      class: classes.logoAccount
    }
  }, {
    value: 'current_donor',
    label: 'Existing Donors',
    description: "Optimize for people who have previously donated",
    icon: {
      src: '/assets/icons/charity.svg',
      srcActive: '/assets/icons/charityWhite.svg',
      class: classes.logoHeart
    }
  }, {
    value: 'use_recommended',
    label: "Recommended by Civic Champs",
    description: 'Optimized using historical user data and success metrics',
    icon: {
      src: '/assets/icons/ccLogo.svg',
      srcActive: '/assets/icons/ccLogoWhite.svg',
      class: classes.logoHand
    }
  }]

  //TODO can we make use_recommended a targetGroup? 

  return (
   <div className={classes.mainContainer}>
    <div className={classes.content}>
      <div>
        {targets.map( ({ value, ...otherProps }) => (
          <AudienceButton
            {...otherProps}
            key={value} 
            selected={audience === value}
            onClick={() => { 
              setAudience(value) 
              if(value === 'use_recommended'){
                setFieldValue('useRecommended', true)
              } else {
                setFieldValue('targetGroup', value)
                setFieldValue('useRecommended', false)
              }
            }}
          />
        ))}
      </div>
    <div className={classes.buttonContainer}>
      <StepNavigation />
    </div>   
    </div> 
    <div className={classes.summaryContainer}>
      <SummaryInfoDonations currentStep={fullStepData}/>
    </div>

    {/* <p>{JSON.stringify(errors)}</p> */}
  </div>)
}

TargetAudience.SCHEMA_FIELDS = ['targetGroup', 'useRecommended']
