import AddCircleIcon from '@material-ui/icons/AddCircle'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    fontSize: '16px',
    color: '#0F5DB5',
    marginRight: '8px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      color: '#0F5DB5',
    },
  },
})

export const InlineAddButton = ({ onClick, text }: { onClick: () => void | Promise<void>; text: string }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <AddCircleIcon className={classes.icon} />
      <span>{text}</span>
    </div>
  )
}
