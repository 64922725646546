import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { useCurrentOrg } from 'auth/hooks'
import { RowsReorderModal } from 'core/table/components/RowsReorderModal'
import useGetSortOrder from 'core/table/table-hooks/useGetSortOrder'

import { useReorderRoles } from './useReorderRoles'

export const useReorderRolesPrompt = (fetchRoles: () => {}) => {
  const showModal = useShowPrompt(RowsReorderModal)
  const reorderRoles = useReorderRoles(fetchRoles)
  const organization = useCurrentOrg()
  const [fetchSortOrder] = useGetSortOrder()

  return useCallback(
    async table => {
      const sortOrder = await fetchSortOrder('roles')

      const reordered = await showModal({
        table,
        title: 'Adjust Role Order',
        description: `Drag roles or choose other sorting options to adjust the order they will appear throughout ${organization.name}`,
        sortOptions: [
          { name: 'Custom', field: 'order', order: 'asc', isCustom: true },
          { name: 'A-Z', field: 'name', order: 'asc' },
          { name: 'Most Frequently Used', field: 'totalActivities', order: 'desc' },
        ],
        sortOrder,
      })

      if (reordered) {
        const { rows, sortOption } = reordered
        await reorderRoles({ rows, sortOption })
      }
    },
    [fetchSortOrder, organization.name, reorderRoles, showModal],
  )
}

export default useReorderRolesPrompt
