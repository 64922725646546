import React, { useCallback } from 'react'
import { Button, MenuItem, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import { StyledKeyboardDatePicker } from 'components/StyledFormikDateTimePickers.old'
import StyledOutlinedTextField from 'civic-champs-shared/formik/legacy-components/StyledOutlinedTextField'
import StyledSelect from 'civic-champs-shared/formik/legacy-components/StyledSelect'
import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer.old'
import useCreateProgram from 'new-mentorship/hooks/useCreateProgram'
import useUpdateProgram from 'new-mentorship/hooks/useUpdateProgram'

const formSchema = yup.object({
  name: yup.string().label('program name').required(),
  description: yup.string().nullable().notRequired(),
  startsAt: yup.date().label('Start Date').required(),
  endsAt: yup.date().notRequired().nullable(),
  // .when('startsAt', (startsAt: any, yup: any) => startsAt && yup.min(startsAt, "Program can't end before start")),
})

export default function FillProgramBasicDataStep(props: any) {
  const classes = useModalStyles()
  const { onSuccess, program, cancel } = props
  const initialValues = {
    name: program?.name || '',
    description: program?.description || '',
    startsAt: program ? moment(program.startsAt) : null,
    endsAt: program?.endsAt ? moment(program.endsAt) : null,
    status: program?.status || '',
  }
  const [createProgram] = useCreateProgram()
  const [updateProgram] = useUpdateProgram()

  const handleSubmit = useCallback(
    event => {
      event.endsAt = event.endsAt === null ? null : moment(event.endsAt).toISOString()
      event.startsAt = moment(event.startsAt).toISOString()
      return (program ? updateProgram(program.id, event) : createProgram(event)).then(onSuccess)
    },
    [createProgram, onSuccess, program, updateProgram],
  )

  const handleFormikSubmit = useCallback(
    (values, { setSubmitting }) => {
      handleSubmit(values).finally(() => setSubmitting(false))
    },
    [handleSubmit],
  )
  return (
    <>
      <Typography>Please provide mentoring program information</Typography>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleFormikSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Field name="name" label="Program Name" fullWidth component={StyledOutlinedTextField} />
              </Grid>
              <Grid item xs={6}>
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  labelId="status-label"
                  fullWidth
                  variant="outlined"
                  component={StyledSelect}
                >
                  <MenuItem value="draft">Draft</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="startsAt"
                  fullWidth
                  label="Start Date"
                  component={StyledKeyboardDatePicker}
                  inputVariant="outlined"
                  format="MM/DD/YYYY"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="endsAt"
                  fullWidth
                  label="End Date"
                  component={StyledKeyboardDatePicker}
                  inputVariant="outlined"
                  format="MM/DD/YYYY"
                  clearable={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  label="Program Description"
                  placeholder="Description"
                  component={StyledOutlinedTextField}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              classes={{ root: classes.buttonsContainer }}
            >
              <Button
                disabled={isSubmitting}
                onClick={cancel}
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                {program ? 'Save' : 'Create New'} Program
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}
