import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'

const STABLE_EMPTY_ARRAY = []

const useCampaignDonations = (campaign) => {
    const [request, { result, loading, error }] = useFetch()
    const getDonations = useCallback( () => {
        return request({
            method: 'get',
            url: `/campaigns/${campaign.id}/donations`
        })
    }, [request, campaign.id])

    useEffect(() => { getDonations() }, [getDonations])

    return {
        refresh: getDonations,
        donations: result || STABLE_EMPTY_ARRAY,
        loading,
        error
    }
}

export default useCampaignDonations
