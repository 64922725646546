import { useCallback } from 'react'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'

export const useSetSortOrder = (): [(tableName: string, sortOrder: string) => Promise<string>, Status<string>] => {
  const [request, status] = useFetchRefactored<string>({
    emptyValue: undefined,
  })
  const currentOrg = useCurrentOrg()

  const setColumnState = useCallback(
    (tableName, sortOrder) =>
      request({
        method: 'put',
        url: `/user/${currentOrg.id}/tables/${tableName}/order`,
        config: {
          body: {
            sortOrder,
          },
        },
      }),
    [currentOrg.id, request],
  )

  return [setColumnState, status]
}

export default useSetSortOrder
