import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { TripRequest } from '../types'

const useFetchTrips = (): [() => Promise<TripRequest[]>, Status<TripRequest[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripRequest[]>({
    errorMessage: 'Error Fetching Trips.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchTrips = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/trip-requests`,
    })
  }, [organization, request])

  return [fetchTrips, status]
}

export default useFetchTrips
