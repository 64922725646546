import { 
    GET_FEATURES_FOR_USER, 
    GET_FEATURES_FOR_USER_SUCCEEDED, 
    GET_FEATURES_FOR_USER_FAILED, 
    FEATURES_INITIALIZED
} from "../actions";

export const  STORE_KEY = 'features';

export const defaultState = {
    initialized: false,  // volatile,
    loading: false,      // volatile
    error: null,         // volatile
    user: null,
    lastUpdatedAt: null,
    settings: { }
}

export default function reducer(state=defaultState, action) {
    let { user, features, error } = action.payload || {};

    switch(action.type) {
        case FEATURES_INITIALIZED:
            return {
                ...state,
                initialized: true
            }

        case GET_FEATURES_FOR_USER:
            return { 
                ...state, 
                loading: true,
                error: null,
                user,
                lastUpdatedAt: null,
                settings: { }
            }

        case GET_FEATURES_FOR_USER_SUCCEEDED:
            return {
                initialized: true,
                loading: false,
                user,
                lastUpdatedAt: new Date(),
                settings: features,
                error: null
            }

        case GET_FEATURES_FOR_USER_FAILED:
            return { 
                initialized: true,
                loading: false,
                error,
                user,
                lastUpdatedAt: new Date(),
                settings: { }
            }

        default:
            return state;
    }
}