export interface PersonPayload {
  givenName?: string
  familyName?: string
  email?: string
  phoneNumber?: string
  homePhoneNumber?: string
  emailVerified?: boolean
  phoneNumberVerified?: boolean
  cognitoSub?: string,
  stripeCustomerId?: string
}

export interface Person extends PersonPayload {
  id: number
  givenName: string
  familyName: string
  createdAt: Date
  deletedAt?: Date
}

export const verifyField = {
  EMAIL: 'emailVerified',
  PHONE_NUMBER: 'phoneNumberVerified'
}
