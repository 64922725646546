import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import * as _ from 'lodash'
import { Form, Formik, FieldArray } from 'formik'
import { Grid, Button } from '@material-ui/core'
import { mdiDownload } from '@mdi/js'
import { Icon } from '@mdi/react'

import { AddButton } from 'civic-champs-shared/core/add-button'
import Loading from 'civic-champs-shared/core/Loading'
import { FormikEffect } from 'civic-champs-shared/question-sets/components/FormikEffect'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useOrganizationCredentials from '../../../credential/hooks/useOrganizationCredentials'
import CreateOrUpdateCredentialPrompt from '../../../credential/components/CreateOrUpdateCredentialPrompt'
import {
  usePickerSchema,
  useSubmit,
  useGetDirtyLookup,
  useGetOptionsLabel,
  useHandleNewWaiver,
  useOptions,
  useOnEdit,
} from './hooks'
import { DirtyRow } from './DirtyRow'
import { CleanRow } from './CleanRow'

interface Props {
  loading: boolean;
  min: number;
  max: number;
  value: number[];
  title: ReactNode;
  onChange: Function;
  onSubmit: Function;
}

export const WaiverPicker = (props: Props) => {
  const { loading, value = [], onSubmit, min = 0, max = 1000, onChange } = props

  const [credentials, setCredentials] = useState([])
  const [credentialsLoading, setCredentialLoading] = useState(true)
  const onSuccess = useCallback(
    res => {
      setCredentials(res)
      setCredentialLoading(false)
    },
    [setCredentials, setCredentialLoading],
  )
  useOrganizationCredentials(onSuccess)
  const credentialById = useMemo(() => _.keyBy(credentials, 'credential.id'), [credentials])
  const pickerSchema = usePickerSchema(min, max)
  const getOptionsLabel = useGetOptionsLabel(credentialById)
  const submit = useSubmit({ onSubmit, pickerSchema })
  const openNewWaiverModal = useShowPrompt(CreateOrUpdateCredentialPrompt)
  const handleNewWaiver = useHandleNewWaiver({ openNewWaiverModal, setCredentials, credentials })
  const options = useOptions(credentials)
  const getDirtyLookup = useGetDirtyLookup(value)
  const onEdit = useOnEdit({ credentials, setCredentials, openNewWaiverModal })

  if (loading || credentialsLoading) {
    return <Loading />
  }

  return (
    <Grid container direction="column">
      <Formik
        initialValues={{ credentialIds: _.clone(value) }}
        onSubmit={submit}
        validationSchema={pickerSchema}
        enableReinitialize
      >
        {formikBag => {
          const { isSubmitting, values, submitForm, isValid } = formikBag
          const dirtyLookup = getDirtyLookup(values.credentialIds)
          const dirty = Object.values(dirtyLookup).some(changed => Boolean(changed))
          return (
            <Form>
              <FormikEffect
                onChange={state  => {
                  try {
                    // ideally, we'd only call onChange if 'isValid', but isValid has a slight delay
                    // where it comes as 'true' right before false and vice versa
                    const castValues = pickerSchema.cast(state.values)
                    onChange &&
                      onChange({
                        ...state,
                        values: {
                          // @ts-ignore
                          credentials: castValues.credentialIds.map((credentialId: number) => credentialById[credentialId]),
                        },
                      })
                  } catch (err) {}
                }}
                formik={formikBag}
              />
              <FieldArray
                name="credentialIds"
                validateOnChange={true}
                render={arrayHelpers => (
                  <>
                    <Grid container item alignItems="center">
                      {props.title}
                        <Grid item style={{ marginLeft: 10 }}>
                          <AddButton
                            disabled={isSubmitting || !isValid}
                            title="add"
                            onClick={() => arrayHelpers.push('')}
                          />
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                      {values.credentialIds.map((credentialId, index) => (
                        <Grid
                          item
                          container
                          direction="row"
                          key={index}
                          spacing={2}
                          justify="flex-start"
                          alignContent="center"
                          alignItems="center"
                          style={{ marginBottom: '10px' }}
                        >
                          {dirtyLookup[index] ? (
                            <DirtyRow
                              formikBag={formikBag}
                              index={index}
                              getOptionsLabel={getOptionsLabel}
                              handleNewWaiver={handleNewWaiver}
                              arrayHelpers={arrayHelpers}
                              options={options}
                            />
                          ) : (
                            <CleanRow
                              onRemove={() => {
                                arrayHelpers.remove(index)
                                if (onSubmit) {
                                  return submitForm()
                                }
                              }}
                              disabled={isSubmitting}
                              onEdit={() => onEdit(credentialId)}
                              credentialId={credentialId}
                              label={getOptionsLabel(credentialId)}
                            />
                          )}
                        </Grid>
                      ))}
                      <Grid container direction="row" style={{ marginTop: '25px' }}>
                        {onSubmit && (
                          <Button
                            disabled={isSubmitting || !isValid || !dirty}
                            type="submit"
                            variant="contained"
                            color="secondary"
                          >
                            <Icon path={mdiDownload} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
