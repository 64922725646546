import { makeStyles } from '@material-ui/core'

export default makeStyles({
  wrapper: {
    width: '100%',
  },
  block: {
    width: '100%',
  },
  header: {
    marginBottom: 40,
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '48px',
    color: '#090909',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  statistics: {
    display: 'flex',
  },
  link: {
    fontFamily: 'Open Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '24px',
    color: '#5C8DE8',
    textDecoration: 'none',
  },
  table: {
    marginTop: 32,
  },
  filterBox: {
    marginBottom: 8,
  },
  dateBox: {
    lineHeight: '36px',
    padding: 4,
    border: 'solid 1px rgba(179, 179, 179)',
    borderRadius: 4,
  },
  rightButtonsGrid: {
    paddingRight: '16px',
  },
})
