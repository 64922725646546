import { ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION } from './actions';

export default function(state=[], action) {
    switch(action.type) {
        case ENQUEUE_NOTIFICATION:
            return [
                ...state,
                action.payload.notification
            ]
        case REMOVE_NOTIFICATION:
            return state.filter( notification => notification.options.key !== action.payload.key) 
            
        default:
            return state;
    }
}