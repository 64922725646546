let canvas: HTMLCanvasElement
export async function getTextWidth(text: string, font: string, letterSpacing: number = 0): Promise<number> {
  // @ts-ignore
  await document.fonts.load(font)
  // re-use canvas object for better performance
  if (!canvas) {
    canvas = document.createElement('canvas')
    document.body.appendChild(canvas)
  }
  canvas.style.letterSpacing = `${letterSpacing}px`
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  context.font = font
  const { width } = context.measureText(text)
  return width
}
