import { getAuthorizationToken } from '../../config-amplify'

type Api = string
type ENV_KEYS =
  | 'AWS_REGION'
  | 'COGNITO_USER_POOL_ID'
  | 'COGNITO_WEB_CLIENT_ID'
  | 'COGNITO_AUTH_FLOW'
  | 'API_ENDPOINT'
  | 'GOOGLE_MAPS_KEY'

//TODO why use fetch instead of the AWS Congito API? Does this circumvent any AWS authentication logic? (such as responding to 401?)
export default class BaseService {
  getEnvVar = (key: ENV_KEYS) => process.env['REACT_APP_' + key]

  requestWithToken: any = async () => {
    const token = await getAuthorizationToken()
    if (!!token) {
      return {
        'Content-Type': 'application/json',
        Authorization: token,
      }
    } else {
      return { 'Content-Type': 'application/json' }
    }
  }

  async getJSON(api: Api) {
    try {
      const config = {
        method: 'get',
        headers: await this.requestWithToken(),
      }
      const request = await fetch(api, config)
      return await request.json()
    } catch (e) {
      console.error('', e)
    }
  }

  async postJSON(api: Api, body: object) {
    try {
      const config = {
        method: 'post',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      }

      const request = await fetch(api, config)
      return await request.json()
    } catch (e) {
      console.error('', e)
    }
  }

  async postCSV(api: Api, body: any) {
    try {
      const request = await fetch(api, {
        method: 'post',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      })
      if (request.body) {
        return request.body
          .getReader()
          .read()
          .finally()
      }
      return
    } catch (e) {
      console.error('', e)
    }
  }

  async putJSON(api: Api, body: object) {
    try {
      const config = {
        method: 'put',
        headers: await this.requestWithToken(),
        body: JSON.stringify(body),
      }
      const request = await fetch(api, config)
      return await request.json()
    } catch (e) {
      console.error('', e)
    }
  }

  async deleteJSON(api: Api) {
    try {
      const request = await fetch(api, {
        method: 'delete',
        headers: await this.requestWithToken(),
      })
      return await request.json()
    } catch (e) {
      console.error('', e)
    }
  }
}
