import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import { verifyField } from '../interface'

export const useForceVerify = onResultUpdate => {
  const [request, { loading, error, result }] = useFetch(
    'Error force-verifying user contact.  Please refresh the page and try again',
  )
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const forceVerify = useCallback(
    async ({ person, email, phoneNumber }) => {
      const confirmed = await confirm(
        `Are you sure you want to verify the ${email ? 'email address' : 'phone number'} for ${person.givenName} ${
          person.familyName
        }?`,
        {
          title: `Force Verify`,
          confirmText: `Force Verify`,
          rejectText: 'Cancel',
          subText: `Clicking the 'Force Verify' button below will verify the user's ${
            email ? 'email address' : 'phone number'
          } and give them full account access`,
        },
      )
      if (!confirmed) {
        return
      }
      return request({
        onSuccess: result => {
          showSuccess(
            `Successfully verified the ${email ? 'email address' : 'phone number'} for ${person.givenName} ${
              person.familyName
            }`,
          )
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/people/${person.id}/verification`,
        config: { body: { email, phoneNumber } },
      })
    },
    [request],
  )

  return useMemo(() => [forceVerify, { loading, error, person: result }], [forceVerify, loading, error, result])
}
