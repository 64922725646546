import React, { useCallback, useMemo } from 'react'
import { FormModal, FormModalScreen } from 'components/FormModal'
import { Form, Formik } from 'formik'
import yup from 'civic-champs-shared/utils/yup'
import _ from 'lodash'
import { Chip } from '@material-ui/core'
import Papa from 'papaparse'
import { Loading, OutlinedButton } from 'civic-champs-shared'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { useActivityImportDialogStyles } from 'tracking/activity/hooks'
import { timezones } from 'civic-champs-shared/helpers/timezones'
import StyledInput from 'civic-champs-shared/core/StyledInput'
import { Organization } from 'new-mentorship/types'
import { useRolesCollection } from 'volunteer-role/hooks'
import { Role } from 'volunteer-role/types'
import { parseAndValidateInput } from 'tracking/activity/utils/validation'
import { CSVLink } from 'react-csv'
import useFileManagementUpload from 'civic-champs-shared/core/file-upload/hooks/useFileManagementUpload'
import useImportActivities from 'tracking/activity/hooks/useImportActivities'
import { ImportMappings, Outcome } from 'utils/import'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const getTimezoneOptionInfo = (option: TimeZone) => {
  return { left: option.name, id: option.value, right: undefined }
}

const getOptionInfo = (option: Organization | Role) => {
  return { left: option.name, id: option.id, right: undefined }
}

interface TimeZone {
  value: string
  name: string
}
export interface Mappings extends ImportMappings {
  email: string | null
  phone: string | null
  hours: string | null
  firstName: string | null
  lastName: string | null
  occurredAt: string | null
  volunteeringRole: string | null
}
export interface FormValues {
  file: File | null
  headers: string[]
  mappings: Mappings
  volunteeringRole: Role | null
  dateFormat: string
  timeZone: TimeZone
}

export const ActivityImportDialog = ({ showing, close, complete }: Props) => {
  const [importActivities] = useImportActivities()
  const [, , upload] = useFileManagementUpload()
  const [{ roles }] = useRolesCollection() as any as [{ roles: Role[] }]
  const roleOptions = useMemo(() => roles.filter(({ name }) => name.trim() !== ''), [roles])
  const org = useCurrentOrg()
  const [invalidCsv, setInvalidCsv] = React.useState<Record<string, any>[] | null>(null)
  const [csvHeaders, setCsvHeaders] = React.useState<string[]>([])
  const classes = useActivityImportDialogStyles()
  const initialValues: FormValues = useMemo(
    () => ({
      file: null,
      headers: [],
      mappings: {
        email: null,
        phone: null,
        hours: null,
        firstName: null,
        lastName: null,
        occurredAt: null,
        volunteeringRole: null,
      },
      volunteeringRole: null,
      dateFormat: 'MM-DD-YYYY',
      timeZone: timezones.find(({ value }) => value === org.timeZone) as TimeZone,
    }),
    [org.timeZone],
  )

  const handle = useCallback(
    async (values: FormValues) => {
      const result = await parseAndValidateInput(values, roleOptions)
      if (result.some(({ outcome }) => outcome !== Outcome.Valid)) {
        setInvalidCsv(result)
        setCsvHeaders(values.headers)
      } else {
        const file = await upload(values.file as File, { isPrivate: true, contentType: 'text/csv' })
        await importActivities({
          orgId: org.id,
          importConfig: {
            file,
            mappings: values.mappings,
            dateFormat: values.dateFormat,
            timeZone: values.timeZone.value,
            volunteeringRoleId: values.volunteeringRole ? values.volunteeringRole.id : undefined,
          },
        })
        complete(true)
      }
    },
    [complete, importActivities, org.id, roleOptions, upload],
  )

  const reset = useCallback(() => {
    setInvalidCsv(null)
    setCsvHeaders([])
  }, [])

  if (!roleOptions.length) return <Loading />

  return (
    <FormModal id="trip-prompt" showing={showing} close={close} title="Import Activities">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          file: yup.mixed().required('File is Required'),
          mappings: yup.object({
            email: yup.string().nullable().notRequired(),
            phone: yup
              .string()
              .nullable()
              .test('phoneOrEmailOrNames', 'Phone or Email or First Last Name is Required', function (value: any) {
                return value || this.parent.email || (this.parent.firstName && this.parent.lastName)
              }),
            firstName: yup.string().nullable().notRequired(),
            lastName: yup.string().nullable().notRequired(),
            hours: yup.string().nullable().required('Hours is Required'),
            occurredAt: yup.string().nullable().required('Check-In Date is Required'),
            volunteeringRole: yup.string().nullable().notRequired(),
          }),
          volunteeringRole: yup
            .object()
            .nullable()
            .test('roleDefinedSomewhere', 'Define or Map Volunteering Role', function (value: any) {
              return value || this.parent.mappings.volunteeringRole
            }),
          organization: yup.object().nullable().notRequired(),
          dateFormat: yup.string().nullable().required('Date Format is Required'),
          timeZone: yup.object().nullable().required('Time Zone Is Required'),
        })}
        onSubmit={handle}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, values, errors, setFieldValue, setFieldError, touched }) => {
          return (
            <Form>
              <FormModalScreen
                onNext={submitForm}
                onBack={invalidCsv ? reset : undefined}
                backText={invalidCsv ? 'Back' : undefined}
                nextText="Import"
                nextDisabled={invalidCsv !== null}
                disabled={isSubmitting}
              >
                {invalidCsv ? (
                  <div className={classes.section}>
                    <div className={classes.sectionTitle}>Invalid CSV data:</div>
                    <CSVLink data={invalidCsv} headers={[...csvHeaders, 'outcome', 'reason']}>
                      Download CSV with validation result
                    </CSVLink>
                  </div>
                ) : (
                  <>
                    <div className={classes.section}>
                      <div className={classes.sectionTitle}>File:</div>
                      {errors.file || ''}
                      <input
                        accept="text/csv"
                        id="file-upload-input"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={e => {
                          const file = _.first(e.target.files) as File
                          Papa.parse<any, File>(file, {
                            header: false,
                            step: (results, parser) => {
                              if (results.errors.length === 0) {
                                setFieldValue('headers', results.data)
                              } else {
                                setFieldError('file', results.errors[0].message)
                              }
                              // Stop parsing, as we need only first line with headers
                              parser.abort()
                            },
                            complete: results => {},
                          })
                          setFieldValue('file', file)
                        }}
                      />
                      {values.file ? (
                        <Chip
                          label={values.file.name}
                          onDelete={() => {
                            setFieldValue('file', null)
                            setFieldValue('headers', [])
                          }}
                        />
                      ) : (
                        <label htmlFor="file-upload-input">
                          <OutlinedButton disabled={false} component="span">
                            Choose File
                          </OutlinedButton>
                        </label>
                      )}
                    </div>
                    {!!values.headers.length && (
                      <div className={classes.section}>
                        <div className={classes.sectionTitle}>Field Mapping:</div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'Email'}
                            placeholder={'Select an Email field'}
                            value={values.mappings.email}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.email', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.email && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.email ? errors.mappings?.email : undefined}
                          />
                          <AdvancedAutocomplete<string>
                            label={'Phone'}
                            placeholder={'Select a Phone field'}
                            value={values.mappings.phone}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.phone', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.phone && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.phone ? errors.mappings?.phone : undefined}
                          />
                        </div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'First Name'}
                            placeholder={'Select a First Name field'}
                            value={values.mappings.firstName}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.firstName', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.firstName && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.firstName ? errors.mappings?.firstName : undefined}
                          />
                          <AdvancedAutocomplete<string>
                            label={'Last Name'}
                            placeholder={'Select a Last Name field'}
                            value={values.mappings.lastName}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.lastName', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.lastName && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.lastName ? errors.mappings?.lastName : undefined}
                          />
                        </div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'Hours'}
                            placeholder={'Select an Hours field'}
                            value={values.mappings.hours}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.hours', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.hours && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.hours ? errors.mappings?.hours : undefined}
                          />
                          <AdvancedAutocomplete<string>
                            label={'Check-In Date'}
                            placeholder={'Select an Check-In Date field'}
                            value={values.mappings.occurredAt}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.occurredAt', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.occurredAt && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.occurredAt ? errors.mappings?.occurredAt : undefined}
                          />
                        </div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'Volunteering Role'}
                            placeholder={'Select a Volunteering Role field'}
                            value={values.mappings.volunteeringRole}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.volunteeringRole', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.volunteeringRole &&
                              Object.values(values.mappings).includes(option)
                            }
                            error={
                              touched.mappings?.volunteeringRole
                                ? (errors.mappings?.volunteeringRole as string)
                                : undefined
                            }
                          />
                          <div style={{ width: '100%' }} />
                        </div>
                      </div>
                    )}
                    <div className={classes.section}>
                      <div className={classes.sectionTitle}>Import Config:</div>
                      <div className={classes.double}>
                        <AdvancedAutocomplete<TimeZone>
                          label={'Time Zone'}
                          placeholder={'Select a Time Zone'}
                          value={values.timeZone}
                          disableInputFilter
                          options={timezones}
                          getOptionInfo={getTimezoneOptionInfo}
                          onChange={value => setFieldValue('timeZone', value)}
                          error={touched.timeZone ? (errors.timeZone as string) : undefined}
                        />
                        <StyledInput
                          value={values.dateFormat}
                          onChange={mileage => setFieldValue('dateFormat', mileage)}
                          label={'Date Format'}
                          placeholder="Enter Date Format"
                          error={touched.dateFormat ? (errors.dateFormat as string) : undefined}
                        />
                      </div>
                      <div className={classes.double}>
                        <AdvancedAutocomplete<Role>
                          label={'Volunteering Role'}
                          placeholder={'Select a Volunteering Role'}
                          value={values.volunteeringRole}
                          options={roleOptions}
                          getOptionInfo={getOptionInfo}
                          onChange={value => setFieldValue('volunteeringRole', value)}
                          error={touched.volunteeringRole ? (errors.volunteeringRole as string) : undefined}
                        />
                      </div>
                    </div>
                  </>
                )}
              </FormModalScreen>
            </Form>
          )
        }}
      </Formik>
    </FormModal>
  )
}
