import { useCallback } from 'react'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

export const useRemoveTag = (eagerRemove: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async tag => {
      const undo = eagerRemove(tag)
      const notification = createNotification('Removing tags')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/tags/${tag.id}`,
        })

        notification.onSuccess('Tag removed!')
      } catch (error) {
        notification.onError(`Could not remove ${tag.name} from tags`, error)
        undo()
      }
    },
    [createNotification, eagerRemove, request],
  )
}

export default useRemoveTag
