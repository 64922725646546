import { MessagingContact } from 'messages/types'
import useFetchPersonSuggestions from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import { useEffect, useState } from 'react'

export const useGetAllSearchableContacts = (): (() => Promise<MessagingContact[]>) => {
  const [contacts, setContacts] = useState<MessagingContact[]>([])
  const [fetch] = useFetchPersonSuggestions()
  useEffect(() => {
    fetch().then(({ suggestions }) => {
      setContacts(
        suggestions.map(({ id, givenName, familyName, contact }) => ({
          id,
          firstName: givenName,
          lastName: familyName,
          ...(contact ? (contact.includes('@') ? { email: contact } : { phoneNumber: contact }) : {}),
        })),
      )
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return () => Promise.resolve(contacts)
}
