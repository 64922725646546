import React from 'react'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog'

export interface Props {
  organization: any
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

export default function MigrateQuestionnairesConfirmationDialog(props: Props) {
  const { showing, complete, close, organization } = props

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={complete}
      title={`Are you sure you want to migrate?`}
      text={`After migration ${organization.name} (${organization.id}) will use questionnaires as surveys with versionning. This can't be reverted.`}
      cancelText="Do not migrate"
      confirmText="Continue with migration"
    />
  )
}
