import yup from 'civic-champs-shared/utils/yup'

//TODO need a good way to report this as a 
// "flat" error or to handle the flat error...
const objectRef = () => yup.object({
    id: yup.number().integer().moreThan(0).required()
})
//TODO using yup.object() for now

export const volunteerTaskSchema = yup.object({
    // requester: yup.object().required('Requester is required'),   
    recipient: yup.object().required('Recipient is required'),
    volunteeringRole: yup.object().required('Volunteering Role is required'),
    description: yup.string().required(), //TODO matches API
    timeslots: yup.array().of(yup.object()).required('Timeslots are required').min(1),
    volunteer: yup.object().nullable(),
    location: yup.object().required('Location is required')
})

export const addVolunteerSchema = yup.object({
    volunteer: yup.object().required('Volunteer is required'),
    timeslot: yup.object().required('Time is required')
})
