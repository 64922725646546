import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import * as _ from 'lodash'

const empty = []


// probably a more efficient way to do this
const useMappedResults = badContacts =>
  useMemo(() => {
    const mappedBadContacts = []
    const badContactsGroupedByEmail = _.groupBy(badContacts, 'email')
    const badContactsGroupedByPhoneNumber = _.groupBy(badContacts, 'phoneNumber')
    Object.entries(badContactsGroupedByEmail).forEach(entry => {
      const [email, badEmailContacts] = entry
      if (email && email !== 'null') {
        mappedBadContacts.push({ ...badEmailContacts[0], count: badEmailContacts.length, contact: email })
      }
    })
    Object.entries(badContactsGroupedByPhoneNumber).forEach(entry => {
      const [phoneNumber, badPhoneContacts] = entry
      if (phoneNumber && phoneNumber !== 'null') {
        mappedBadContacts.push({ ...badPhoneContacts[0], count: badPhoneContacts.length, contact: phoneNumber })
      }
    })
    return mappedBadContacts
  }, [badContacts])

const useBadContactsSummary = () => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching bad contacts summary')
  const organization = useCurrentOrg()
  const getBadContacts = useCallback(async () => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/bad-contacts`,
    })
  }, [request])

  useEffect(() => {
    getBadContacts()
  }, [])

  const mappedResults = useMappedResults(result || empty)

  return useMemo(() => ({ result: mappedResults, loading: loading || !called, called, error, refresh: getBadContacts }), [
    mappedResults,
    loading,
    called,
    error,
    getBadContacts
  ])
}

export default useBadContactsSummary
