import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

const useTestReminder = () => {
  const [request, status] = useFetch('Error testing day of reminders')
  const showSuccess = useSuccessNotification()
  const testReminder = useCallback(({ email, start, end }) => {
    return request({
      method: 'post',
      url: '/test/day-of-reminders',
      onSuccess: () => showSuccess('Successfully tested day of reminders'),
      config: {
        body: {
          email,
          eventDateFilter: { start, end },
        },
      },
    })
  }, [])

  return [testReminder, status]
}

export default useTestReminder
