import _ from 'lodash';
import React, { useEffect, useCallback } from 'react'
import { makeStyles, MenuItem, FormControl, InputLabel, Button, Link } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle';
import { Field, Form } from 'formik'
import { useFormikWizardContext } from 'civic-champs-shared/formik/FormikWizard'
import { TextField, Select } from 'formik-material-ui'
import { DatePicker } from 'formik-material-ui-pickers'
import cn from 'classnames'

import SummaryInfoDonations from './SummaryInfoDonations'
import CurrencyFieldDollar from 'civic-champs-shared/formik/components/CurrencyFieldDollar'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AffiliatedPrompt from './AffiliatedPrompt'
import useGetAffiliatedOpportunities from '../hooks/useGetAffiliatedOpportunties'
import useDeleteAffiliatedOpportunity from '../hooks/useDeleteAffiliatedOpportunity'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'

const useStyles = makeStyles(theme => ({
    buttonContainer: {
      marginBottom: '58px',
      marginTop: '10px',
    },
    content:{
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      flexDirection: 'column'
      },
    titleText: {
      fontSize: '24px',
      fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif'
    },
    subTitle: {
        fontSize: '19px',
        fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif',
        marginTop: '46px',
      },
    companyInput:{
      borderRadius: '2px',
      width: '389px',
      heiht: '44px',
      marginRight: '33px',
      marginTop: '20px',
    },
    row: {
      flexDirection: 'row', 
    },
    rowAffiliate: {
      display: 'flex',
      flexDirection: 'row', 
      justifyContent: 'space-between',
      width: '300px',
      alignItems: 'center',
    },
    formControl:{
      width: '350px',
      heiht: '44px',
      borderRadius: '4px',
      marginTop: '31px',
    },
    label: {
      marginLeft: '20px',
      color: '#CCCCCC',  
    },
    dtPicker: {
      width: '170px',
      borderRadius: '2px',
      marginRight: '16px',
      marginTop: '20px'
    },
    amountRow: {
      display: 'flex',
      flexDirection: 'row', 
      alignItems: 'center',
      marginTop: '41px',
    }, 
    amountInput: {
      width: '93px',
      height: '55px',
      marginLeft: '13px',
      marginRight: '13px',
    },
    minimumAmount: {
      width: '160px',
      height: '44px',
      marginLeft: '10px',
      paddingLeft: '10px',
    },
    minimumRow:{
      display: 'flex',
      flexDirection: 'row', 
      alignItems: 'center',
    },
    affRow: {
      //marginTop: '47px'
    },
    addButton: {
      display: 'flex',
      backgroundColor: '#FFCD00',
      width: '88px',
      height: '30px',
      alignItems: 'center', 
      justifyContent: 'center',
      alignSelf: 'center',
      marginLeft: '7px',
      marginTop: '15px'
    },
    removeText: {
      color: '#FF6F3E',
      textTransform: 'lowercase',
    },
    saveDraft: {
      width: '146px',
      height: '49px',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexGrow: 1,
      width: '100%',
    },
    summaryContainer: {
      borderRadius: '4px 0 0 0',
      boxShadow: '0 4px 7px rgba(100, 124, 142, 0.18)',
      borderTop: '1px solid #ABB6CC',
      borderLeft: '1px solid #ABB6CC',
      borderBottom: '1px solid #ABB6CC',
      padding: '21px 29px',
      alignSelf: 'flex-end',
      marginRight: '-85px',
      height: '750px',
      width: '360px',
    }    
  }));

export default function BasicInfo(props) {
    const {values, errors, campaign } = props
    const classes = useStyles()

    const [affiliatedOpps, setAffiliatedOpps] = React.useState();
    const [getAffiliateOpportunities, ] = useGetAffiliatedOpportunities()
    const [deleteAffiliatedOpportunity, ] = useDeleteAffiliatedOpportunity()

    const stepInfo = useFormikWizardContext()
    const fullStepData = {...values, ...stepInfo}

    const updateAffiliatedOpps = useCallback(async () => {
      const affiliatedOpps = await getAffiliateOpportunities(campaign.id) 
      setAffiliatedOpps(affiliatedOpps)
    }, [campaign.id, getAffiliateOpportunities])

    useEffect(() => { updateAffiliatedOpps() }, [updateAffiliatedOpps])

    const deleteOpportunity = async (opportunityId) => {
      await deleteAffiliatedOpportunity(campaign.id, opportunityId)
      await updateAffiliatedOpps() 
    }

    const showAffiliatePrompt = useShowPrompt(AffiliatedPrompt)

    const editAffilatedOpps = async () => {
      await showAffiliatePrompt({
        campaignId: campaign.id,
        startsAt: values.startsAt,
        endsAt: values.endsAt
      }) 
      //TODO probably should return affiliated opps from modal 
      await updateAffiliatedOpps()
    }

    return (
      <div className={classes.mainContainer}>
      <div className={classes.content}>
        <h2 className={classes.titleText}>Basic Info</h2>  
        <Form>
          <div className={classes.row}>
            <Field 
              component={TextField}
              name="name"
              className={classes.companyInput}
              placeholder="Campaign Name"
              variant="outlined"
            />
            <Field
              component={DatePicker}
              format="MM/DD/YYYY"
              inputVariant="outlined"
              name="startsAt"
              label="Start Date"
              className={classes.dtPicker}
            />
            <Field
              component={DatePicker}
              format="MM/DD/YYYY"
              inputVariant="outlined"
              name="endsAt"
              label="End Date"
              className={classes.dtPicker}
            />
          </div>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.label}>Select Campaign Type</InputLabel>
            <Field
              component={Select}
              variant='outlined'
              name="campaignType"
            >
              <MenuItem value="matching_hours">dollars per hours</MenuItem>
              {/* <MenuItem value="flat_amount">fixed dollar amount</MenuItem> */}
            </Field>
          </FormControl>
          <h4 className={classes.subTitle}>Time matching</h4>
          <div>User will be prompted to donate an amount that increases with the amount of hours volunteered.</div>
          <div className={classes.amountRow}>
            <div>Donate $ </div> 
            <Field 
              component={CurrencyFieldDollar}
              min={100}
              name="timeMatchingAmountInCents"
              className={classes.amountInput}
              placeholder="Amount"
              variant="outlined"
            />
            <div>for every </div> 
            <Field 
              component={TextField}
              name="timeMatchingHour"
              className={classes.amountInput}
              placeholder="Number"
              variant="outlined"
            /> 
            <div>hours volunteered.</div> 
          </div>
          <h4 className={classes.subTitle}>What is the minimum amount you would like donors to contribute?</h4>
          <div className={classes.minimumRow}> 
            <div>$</div>           
            <Field 
              component={CurrencyFieldDollar}
              min={100}
              name="minimumAmountInCents"
              className={classes.minimumAmount}
              placeholder="Amount"
              variant="outlined"
            />
          </div>
        </Form>
            <div className={cn([classes.minimumRow, classes.affRow])}>  
              <h4 className={classes.subTitle}>Affiliated Opportunities</h4>
              <Button
                onClick={editAffilatedOpps}
                className={classes.addButton}
                type="button"
                variant="contained"
                startIcon={<AddCircle />}
              >
                  Add
              </Button>
            </div> 
            {_.map(affiliatedOpps, (opp) => (
              <div 
                key={opp.id}
                className={classes.rowAffiliate}
              >
                {opp.name}
                <Button 
                  className={classes.removeText}
                  type="text"
                  onClick={()=>deleteOpportunity(opp.id)}
                >
                  remove
                </Button>
              </div>  
            ))}
            <div className={classes.buttonContainer}>
              <StepNavigation />
            </div>
      </div>
      <div className={classes.summaryContainer}>
        <SummaryInfoDonations currentStep={fullStepData}/>
      </div>
    </div>)
}

BasicInfo.SCHEMA_FIELDS = [
  'name', 
  'startsAt',
  'endsAt',
  'campaignType',
  'timeMatchingAmountInCents',
  'timeMatchingHour',
  'minimumAmountInCents'
]
