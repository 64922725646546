// TODO: think about what to show when all requirements have been satisfied
// if there are no requirements to begin with, the modal just pops up and then disappears immediately
// which is an awkward experience
import React from 'react'
import { IconButton, makeStyles, Modal } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import QuestionSetCreation from './question-set-creation'
import { useExistingQuestions, useEditQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import { useQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import Loading from 'civic-champs-shared/core/Loading'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    borderRadius: 0,
    color: 'white',
    backgroundColor: '#FF6F3E',
  },
  creationContainer: {
    width: '80%',
  },
}))

export default function QuestionSetCreationModal(props) {
  const { showing, complete, questionSetId, showAnonymousCheckbox = false } = props
  const classes = useStyles()
  const { questionSet, loading: questionSetLoading } = useQuestionSet(questionSetId)
  const { existingQuestions, loading: existingQuestionsLoading } = useExistingQuestions()
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet(newQuestionSet => complete(newQuestionSet))
  const loading = existingQuestionsLoading || editLoading || (questionSetId && questionSetLoading)

  return (
    <Modal open={showing} className={classes.modal}>
      <div className={classes.modalBody}>
        <IconButton className={classes.icon} aria-label="close" onClick={() => complete(false)}>
          <Close />
        </IconButton>
        <div className={classes.creationContainer}>
          {existingQuestionsLoading || (questionSetId && questionSetLoading) ? (
            <Loading />
          ) : (
            <QuestionSetCreation
              questionSet={questionSet}
              loading={loading}
              onSubmit={editQuestionSet}
              existingQuestions={existingQuestions}
              showAnonymousCheckbox={showAnonymousCheckbox}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}
