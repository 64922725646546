export const GIVINGORGS_REQUEST = 'GIVINGORGS_REQUEST';
export const GIVINGORGS_SUCCESS = 'GIVINGORGS_SUCCESS';
export const GIVINGORGS_FAILURE = 'GIVINGORGS_FAILURE';

export default {
  request: () => ({
    type: GIVINGORGS_REQUEST
  }),

  success: payload => ({
    type: GIVINGORGS_SUCCESS,
    payload
  }),
  failure: payload => ({
    type: GIVINGORGS_FAILURE,
    payload
  })
};