import { 
    START_KIOSK, START_KIOSK_SUCCEEDED, START_KIOSK_FAILED, START_KIOSK_CLEAR_FAILURE,
    STOP_KIOSK, STOP_KIOSK_SUCCEEDED, STOP_KIOSK_FAILED,
} from '../actions';
import getErrorMessage from '../../utils/getErrorMessage';
import defaultState from './defaultState';

//TODO use an "enum" instead of strings for status
export default function(state, action) {
    switch(action.type) {
        case START_KIOSK:
            return {
                ...state,
                status: 'starting',
                opportunity: action.payload.opportunity,
            };

        case START_KIOSK_SUCCEEDED:
            return { 
                ...state, 
                status: 'active',
                error: null
            };

        case START_KIOSK_FAILED:
            return { 
                ...state, 
                status: 'failed',
                error: getErrorMessage(action.payload.error)
            };

        case START_KIOSK_CLEAR_FAILURE:
        case STOP_KIOSK_SUCCEEDED:
            return defaultState();
        
        case STOP_KIOSK:
            return {
                ...state,
                status: 'stopping',
                error: null
            };

        case STOP_KIOSK_FAILED:
            return { 
                ...state, 
                status: 'active',
                error: getErrorMessage(action.payload.error)
            };

        default:
            return state;
    }
}