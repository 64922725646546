import * as React from 'react'
import * as _ from 'lodash'
import { Button, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import './sumarry.scss'
import DOMPurify from 'dompurify'

const useStyles = makeStyles(theme => ({
  previewTitle: {
    fontSize: '24px',
    color: '#3D3D3D',
  },
  appearance: {
    fontSize: '15px',
    color: '#3D3D3D',
    marginTop: '9px',
    marginBottom: '14px',
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '303px',
    height: '620px',
    border: '4px solid #000000',
    borderRadius: '28px',
    paddingTop: '22px',
    paddingLeft: '28px',
    paddingRight: '29px',
    paddingBottom: '22px',
    justifyContent: 'space-between',
    wordWrap: 'break-word',
  },
  phoneTitle: {
    fontSize: '28px',
    color: '#3D3D3D',
    marginTop: '22px',
    marginBottom: '9px',
  },
  thankYouText: {
    fontSize: '1.8vh',
    //fontSize: '15px',
    color: '#3D3D3D',
    maxWidth: '290px',
    maxHeight: '400px',
    overflow: 'hidden',
  },
  image: {
    width: '242px',
    height: 'auto',
    marginTop: '9px',
    marginBottom: '16px',
  },
  button: {
    width: '240px',
    height: '44px',
    backgroundColor: '#3D67B5',
    borderRadius: '4px',
    marginTop: '16px',
    marginBottom: '22px',
  },
  buttonTwo: {
    marginTop: 0,
  },
  buttonText: {
    color: 'white',
    fontSize: '12px',
  },
  text: {
    marginTop: '20px',
  },
  subTitle: {
    marginTop: '32px',
  },
}))

const DonatePromptPhone = (props: any) => {
  const { data } = props
  const classes = useStyles()

  //TODO add real image url for Donation prompt

  return (
    <div className={classes.phoneContainer}>
      <div className={classes.phoneTitle}>
        <b>{data != null && data.title != null ? data.title : 'Help Needed'}</b>
      </div>
      <div
        className={classes.thankYouText}
        dangerouslySetInnerHTML={{
          __html:
            data != null && data.firstParagraph != null
              ? DOMPurify.sanitize(data.firstParagraph)
              : 'Challenges and opportunities change-makers but sustainable families society.Strategize, resist, or preliminary thinking natural resources youth justice state of play impact.Challenges and opportunities change-makers but sustainable families society. Strategize, resist, or preliminary thinking natural resources youth justice state of play impact.',
        }}
      ></div>
      <img className={classes.image} src={_.get(data, 'image.url', '/assets/icons/blue-logo.svg')} alt="User_picture" />
      <Button className={cn([classes.button, classes.buttonTwo])} variant="contained">
        <div className={classes.buttonText}>
          <b>Donate ${data != null && data.minimumAmount != null ? data.minimumAmount / 100 : 8}</b>
        </div>
      </Button>
    </div>
  )
}

export default DonatePromptPhone
