import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrentOrg } from '../../../auth/hooks'
import usePost from '../../../api/hooks/usePost'
import { UploadConfig, UploadedFile, UploadedFilePayload, VirusStatus } from '../interface'
import { useErrorNotification, useGet } from '../../../api/hooks'

type Upload = (file: File, metadata: UploadConfig) => Promise<UploadedFile>
type UploadToS3 = (file: File, metadata: UploadConfig) => Promise<string>
interface UploadStatus {
  loading: boolean
  called: boolean
  result: UploadedFile | null
  error?: Error | null
  scanning: boolean
}

export default function useFileManagementUpload(): [Upload, UploadStatus, UploadToS3] {
  const currentOrganization = useCurrentOrg()
  const baseUrl = `/organizations/${currentOrganization.id}/files`
  const [loading, setLoading] = useState<boolean>(false)
  const [postToS3Error, setPostToS3Error] = useState<Error | null>(null)
  const [scanning, setScanning] = useState<boolean>(false)
  const [currentIntervalId, setCurrentIntervalId] = useState<number | null>(null)
  const [scannedFile, setScannedFile] = useState<UploadedFile | null>(null)
  const showError = useErrorNotification()

  const [start, startStatus] = usePost<{ downloadUrl: string; uploadUrl: string }, UploadConfig>({
    errorMessage: 'Error starting file upload',
    configMapper: (body: UploadConfig) => ({
      url: `${baseUrl}/generate_url`,
      config: { body },
    }),
  })

  const [done, doneStatus] = usePost<UploadedFile, UploadedFilePayload>({
    errorMessage: 'Error saving file upload',
    configMapper: (body: UploadedFilePayload) => ({
      url: baseUrl,
      config: { body },
    }),
  })

  const [getFile, getFileStatus] = useGet<UploadedFile>({
    errorMessage: 'Error scanning file for viruses - please refresh the page and re-upload the file',
    configMapper: (id: number) => ({ url: `/files/${id}` }),
  })

  const pollScanningStatus = useCallback(
    async (upload: UploadedFile): Promise<UploadedFile> => {
      setScanning(true)
      let scannedFile: UploadedFile
      try {
        scannedFile = await new Promise<UploadedFile>((resolve, reject) => {
          const intervalId = setInterval(() => {
            setCurrentIntervalId(intervalId)
            try {
              getFile(upload.id).then((file: UploadedFile) => {
                if (file.virusStatus !== VirusStatus.UNSCANNED) {
                  clearInterval(intervalId)
                  resolve(file)
                }
              })
            } catch (err) {
              clearInterval(intervalId)
              reject(err)
            }
          }, 2000)
        })
      } catch (err) {
        throw err
      } finally {
        setScanning(false)
      }
      const { virusStatus } = scannedFile
      if (virusStatus === VirusStatus.ERROR || virusStatus === VirusStatus.INFECTED) {
        const msg =
          virusStatus === VirusStatus.ERROR
            ? 'There was an error scanning your file for viruses.  Please refresh the page and and try again'
            : 'Your file contained a virus.  Please upload a different file'
        showError(msg)
        throw new Error(msg)
      }
      setScanning(false)
      return scannedFile
    },
    [getFile, showError],
  )

  const postToS3 = useCallback(
    async ({ uploadUrl, file }: { uploadUrl: string; file: File }) => {
      try {
        await fetch(uploadUrl, {
          method: 'put',
          body: file,
        })
      } catch (err) {
        // @ts-ignore
        setPostToS3Error(err)
        showError('Error uploading file', err)
        throw err
      }
    },
    [setPostToS3Error, showError],
  )

  const clearData = useCallback(() => {
    setPostToS3Error(null)
    setScanning(false)
    setCurrentIntervalId(null)
    setScannedFile(null)
  }, [])

  const uploadToS3 = useCallback(
    async (file: File, uploadConfig: UploadConfig) => {
      const { isPrivate, ...metadata } = uploadConfig || {}
      clearData()
      // 1. get signedUrls
      const { uploadUrl, downloadUrl } = await start({ contentType: file.type, isPrivate, metadata })
      // 2. upload file to s3
      await postToS3({ uploadUrl, file })
      return downloadUrl
    },
    [clearData, postToS3, start],
  )

  const upload = useCallback(
    async (file: File, metadata: UploadConfig): Promise<UploadedFile> => {
      const { isPrivate = false } = metadata || {}
      setLoading(true)
      try {
        // 1&2. Upload to S3
        const downloadUrl = await uploadToS3(file, metadata)
        // 3. record file to api database
        const upload = await done({ filename: file.name, contentType: file.type, url: downloadUrl, isPrivate })
        // 4. poll until the file is scanned
        const scannedFile = await pollScanningStatus(upload)
        // 5. save the scanned file
        setScannedFile(scannedFile)
        setLoading(false)
        // 6. finally, return the scanned, 'CLEAN' file
        return scannedFile
      } catch (e) {
        throw e
      } finally {
        setLoading(false)
      }
    },
    [uploadToS3, done, pollScanningStatus],
  ) as Upload

  useEffect(() => {
    return () => {
      if (currentIntervalId) {
        clearInterval(currentIntervalId)
      }
    }
  }, [currentIntervalId])

  return useMemo(
    () => [
      upload,
      {
        error: startStatus.error || postToS3Error || doneStatus.error || getFileStatus.error,
        loading,
        called: startStatus.called,
        result: scannedFile,
        scanning,
      },
      uploadToS3,
    ],
    [
      upload,
      startStatus.error,
      startStatus.called,
      postToS3Error,
      doneStatus.error,
      getFileStatus.error,
      loading,
      scannedFile,
      scanning,
      uploadToS3,
    ],
  )
}
