import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useRowSelect,
} from 'react-table'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import Checkbox from '../../../../Event/components/checkbox'

const pageSizeOptions = [10, 25, 50, 100]

const useStyles = makeStyles(theme => ({
  sortAsc: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '10px',
      width: 0,
      height: 0,
      borderStyle: 'solid !important',
      borderWidth: '0 4px 5px 4px !important',
      borderColor: 'transparent transparent #A5B0C0 transparent !important'
    }
  },
  sortDesc: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '10px',
      width: 0,
      height: 0,
      borderStyle: 'solid !important',
      borderWidth: '5px 4px 0 4px !important',
      borderColor: '#A5B0C0 transparent transparent transparent !important'
    }
  },
  selectedRow: {
    background: 'rgb(125, 152, 218)',
  },
  selectedCell: {
    color: 'white',
  },
  header: {
    cursor: 'pointer'
  },
  headerCell: {
    color: '#000000',
    paddingRight: '10px',
    backgroundColor: '#B8CDF5',
    fontWeight: 600,
  },
  cell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    msTextOverflow: 'ellipsis',
    OTextOverflow: 'ellipsis',
    textOverflow: 'ellipsis',
    border: '1px solid rgba(0,0,0,0.05)',
    borderLeft: '1px solid rgba(0,0,0,0.02)',
    borderRight: '1px solid rgba(0,0,0,0.02)',
    '&:first-child': {
      'border-left': '0',
    },
    '&:last-child': {
      'border-right': '0',
    },
  },
}))

const getClampedPageIndex = (totalItemCount: number, itemsPerPage: number, pageIndex: number) => {
  let pageCount = Math.floor((totalItemCount + itemsPerPage - 1) / itemsPerPage)
  let minimumPageIndex = 0
  let maximumPageIndex = Math.max(0, pageCount - 1)

  return Math.min(Math.max(minimumPageIndex, pageIndex), maximumPageIndex)
}

export default function DataTable(props: any) {
  const {
    data,
    filterKey,
    withCheckboxes,
    getSelectedRows = () => {},
    clearSelectedRows = () => {},
    sortable,
    onFetchData = () => {}
  } = props
  const tableRef = useRef(null)

  // Checkboxes
  const handleChange = () => {
    const selected = !selectAll
    changeSelectAll(selected)
    const checkedCopy: any[] = []
    data.forEach(() => {
      checkedCopy.push(selected)
    })
    changeChecked(checkedCopy)
    const selectedItems = checkedCopy
      .map((item, key) => {
        return item && data[key]
      })
      .filter(Boolean)
    getSelectedRows(selectedItems)
  }
  const handleSingleCheckboxChange = (index: number) => {
    const checkedCopy = checked
      checkedCopy[index] = !checked[index]
    if (checkedCopy[index] === false) {
      changeSelectAll(false)
    }
    changeChecked(checkedCopy)
    const selectedItems = checked
      .map((item, key) => {
        return item && data[key]
      })
      .filter(Boolean)
    getSelectedRows(selectedItems)
  }
  const onClearSelectedRows = () => {
    changeSelectAll(false)
    changeChecked([])
  }
  clearSelectedRows(onClearSelectedRows)

  // Manually manage pageIndex to avoid losing the current index when elements are updated/removed
  const [selectAll, changeSelectAll] = useState(false)
  const [checked, changeChecked] = useState<any[]>([])

  const {
      state: { pageIndex, pageSize, sortBy }, 
      gotoPage,
      setPageSize,
      headerGroups,
      getTableProps,
      page,
      prepareRow,
    } = useTable({
      ...props,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  ) as any

  //pagination
  const handleChangePage = useCallback((_, selectedPage) => {
    gotoPage(selectedPage)
  }, [gotoPage])
  const handleChangePageSize = useCallback(event => {
    setPageSize(event.target.value)
    gotoPage(0)
  }, [setPageSize, gotoPage])

  // sorting
  const classes = useStyles()
  const getSortStyle = useCallback(column => {
    if (column.canSort && column.isSorted) {
      return column.isSortedDesc ? classes.sortDesc : classes.sortAsc
    }
    return null
  }, [classes])

  const handleToggleSortBy = useCallback(column => {
    if (!column.canSort) return null

    column.toggleSortBy(column.isSorted ? !column.isSortedDesc : false)
    gotoPage(0)
  }, [gotoPage])

  // Reset the page index if the data filters are changed
  useEffect(() => {
    gotoPage(0)
  }, [gotoPage, filterKey])

  // Keep page index from going out of range when data changes
  useEffect(() => {
    let clampedPageIndex = getClampedPageIndex(data.length, pageSize, pageIndex)
    if (clampedPageIndex !== pageIndex) {
        gotoPage(clampedPageIndex)
    }
  }, [data.length, pageSize, pageIndex, gotoPage])

  // Manually handle pagination
  useEffect(() => {
    onFetchData(pageIndex, pageSize, sortBy)
  }, [onFetchData, pageIndex, pageSize, sortBy])

  return (<>
    <Table ref={tableRef} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            className={classes.header}
          >
            {
              withCheckboxes &&
              <TableCell className={cn(classes.cell, classes.headerCell)}>
                <Checkbox
                  checked={selectAll}
                  value={selectAll}
                  onClick={handleChange}
                />
              </TableCell>
            }
            {headerGroup.headers.map((column: any) => (
                <TableCell
                  {...column.getHeaderProps()}
                  onClick={() => column.sortable && sortable && handleToggleSortBy(column)}
                  className={cn(getSortStyle(column), classes.cell, classes.headerCell)}
                >
                  {column.render('Header')}
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {
          page.map((row: any) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()} className={cn(row.original.isNew ? 'isNew' : '')}>
              {
                withCheckboxes &&
                <TableCell>
                  <Checkbox
                    value={checked[row.index] || false}
                    onClick={() => handleSingleCheckboxChange(row.index)}
                  />
                </TableCell>
              }
              {
                row.cells.map((cell: any) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                ))
              }
            </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
    <TablePagination
      component="div"
      count={data.length}
      page={pageIndex}
      onChangePage={handleChangePage}
      rowsPerPage={pageSize}
      rowsPerPageOptions={pageSizeOptions}
      onChangeRowsPerPage={handleChangePageSize}
    />
  </>)
}
