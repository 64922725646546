import React, { useMemo } from 'react'
import { CellProps } from 'react-table'

import { DATE_RANGE, DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { DateCell, WithLinkCell } from 'core/table/cells'
import { useTableColumnStyles as useStyles } from 'core/table/table-hooks'

import RatingIcon from '../components/NewRatingIcon'
import { PROGRAMS_FILTER, RATING_FILTER } from 'new-mentorship/helpers/filters'
import { availableRatings } from 'new-mentorship/helpers/filters/rating'
import useGetProgramFilterAutocomplete from 'new-mentorship/hooks/useGetProgramFilterAutocomplete'

const getRatingFilterAutocomplete = () => availableRatings

export function useMentorshipMeetingsColumns() {
  const classes = useStyles()

  const getProgramFilterAutocomplete = useGetProgramFilterAutocomplete()

  return useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'startsAt',
        id: 'startsAt',
        disableFilters: true,
        filter: DATE_RANGE,
        Cell: (props: any) =>
          WithLinkCell(
            DateCell,
            (id: number) => `/mentorship/meetings/${id}`,
          )({ ...props, linkProps: { className: classes.link } }),
      },
      {
        Header: 'Mentor',
        accessor: 'mentor',
        id: 'mentor',
        filter: DYNAMIC_TEXT,
      },
      {
        Header: 'Mentee',
        accessor: 'mentee',
        id: 'mentee',
        filter: DYNAMIC_TEXT,
      },
      {
        Header: 'Program',
        accessor: 'program',
        id: 'program',
        filter: PROGRAMS_FILTER,
        getFilterAutocomplete: getProgramFilterAutocomplete,
      },
      {
        id: 'rating',
        Header: 'Rating (Mentor / Mentee)',
        accessor: 'rating',
        filter: RATING_FILTER,
        getFilterAutocomplete: getRatingFilterAutocomplete,
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <div style={{ display: 'flex' }}>
            {<RatingIcon width="18px" positive={value.mentor} />}
            {<RatingIcon width="18px" positive={value.mentee} />}
          </div>
        ),
      },
      {
        Header: 'Meeting Time ',
        accessor: 'duration',
        id: 'duration',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => value + ' min',
      },
    ],
    [classes.link, getProgramFilterAutocomplete],
  )
}

export default useMentorshipMeetingsColumns
