import React from 'react'
import { Form, Formik } from 'formik'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared'
import { FormikProps } from 'formik/dist/types'

const https = 'https://'

const useStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '700px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  content: {
    padding: '8px 0',
    gap: '5px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '12px',
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
  subheader: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  cancel: {
    border: 0,
  },
})

function OrganizationModal(props: {
  showing: boolean
  complete: (...props: any) => Promise<any>
  cancel: () => Promise<any>
  children: React.ReactNode
  title: string
  subtitle: string
  initialValues: Record<string, any>
  validationSchema: any
  name: string
  formHandler?: (props: FormikProps<Record<string, any>>) => void
}) {
  const { showing, complete, cancel, name, title, subtitle, validationSchema, initialValues, formHandler, children } =
    props
  const classes = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#organization-${name}-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={complete}>
        {formik => {
          formHandler?.(formik)
          const { submitForm, isSubmitting } = formik

          return (
            <Form>
              <DialogTitle
                classes={{ root: classes.title }}
                disableTypography={true}
                id={`organization-${name}-prompt`}
              >
                {title}
                <IconButton className={classes.dialogCloseButton} onClick={cancel}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <div className={classes.subheader}>{subtitle}:</div>
                {children}
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton className={classes.cancel} disabled={isSubmitting} onClick={cancel}>
                  Cancel
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                  Save
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default React.memo(OrganizationModal)
