import { Amplify, Auth } from 'aws-amplify'
import decodeJwtToken from 'civic-champs-shared/auth/utils/decodeJwtToken'

const endpointOverride = process.env.REACT_APP_NGROK_API_URL

export const getAuthorizationToken = async () => {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const payload = decodeJwtToken(token)
      if (payload.exp * 1000 > Date.now()) {
        return `scheduler:${token}`
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('firstLogin')
        await Auth.signOut()
      }
    } catch (e) {
      localStorage.removeItem('token')
      localStorage.removeItem('firstLogin')
      await Auth.signOut()
    }
  }

  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTH_FLOW,
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: 'civicChampsApi',
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        custom_header: async () => ({ Authorization: await getAuthorizationToken() }),
      },
    ],
  },
  ...(endpointOverride && {
    clientMetadata: {
      endpointOverride,
    },
  }),
})
