import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import cn from 'classnames'
import { useCurrentOrg } from 'auth/hooks'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useOrganizationSettingsTabs } from 'core/feature/hooks'
import { makeStyles, withStyles } from '@material-ui/core'
import { useUISettings } from 'hooks/useUISettings'

const useStyles = makeStyles(theme => ({
  adminTab: {
    backgroundColor: `${theme.palette.background.green} !important`,
  },
  mainContainer: {},
  innerContainer: {
    border: '1px solid #5C8DE8',
    marginTop: '-1px',
  },
  title: {
    marginBottom: '35px',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function OrganizationSettings() {
  const [value, setValue] = useState(0)
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const organization = useCurrentOrg()
  const tabs = useOrganizationSettingsTabs()

  const uiSettings = useUISettings()

  // calculate the number of tabs
  const numTabs = tabs.length
  const AntTabs = withStyles({
    root: {
      width: 160 * numTabs,
      backgroundColor: 'white',
    },
    indicator: {
      backgroundColor: 'white',
      height: 0,
    },
  })(Tabs)

  const AntTab = withStyles(theme => ({
    root: {
      backgroundColor: '#ADD7F3',
      textTransform: 'none',
      border: '1px solid #5C8DE8',
      width: 72,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        color: 'black',
        opacity: 1,
      },
      '&$selected': {
        borderRight: '1px solid #5C8DE8',
        borderLeft: '1px solid #5C8DE8',
        borderTop: '1px solid #5C8DE8',
        borderBottom: 0,
        color: 'black',
        backgroundColor: 'white',
      },
      '&:focus': {
        color: 'black',
      },
    },
    selected: {},
  }))(props => <Tab disableRipple {...props} />)

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h4" className={classes.title}>
        {organization.name}
      </Typography>
      <AntTabs value={value} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <AntTab
            key={index}
            label={tab.label}
            className={cn({
              [classes.adminTab]: tab.isAdminItem,
              hidden: tab.isAdminItem && !uiSettings.showAdminItems,
            })}
          />
        ))}
      </AntTabs>
      <div className={classes.innerContainer}>
        {tabs.map((tab, index) => {
          const { Component } = tab
          return (
            <TabPanel value={value} index={index} key={index}>
              <Component />
            </TabPanel>
          )
        })}
      </div>
    </div>
  )
}
