import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import { Grid, Tooltip } from '@material-ui/core'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { contactSort, ExpandableCell, NameCell, PhoneEmailContactCell } from 'core/table/cells/modern'
import { CellWithTooltip, DateCell, PhoneNumberCell } from 'core/table/cells'
import { DATE_RANGE, DYNAMIC_TEXT } from 'core/table/filters'
import { WaiversCell } from '../components/WaiversCell'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useUISettings } from 'hooks/useUISettings'
import { Cell, ColumnInstance } from 'react-table'
import { RegistrantResponse } from 'Event/interfaces'
import { useDateRangeFilter } from 'filtering/hooks'
import { useGetRegisteredPeriod } from 'registrant/utils/useGetRegisteredPeriod'
import { createDateValueSort } from 'components/table/sort'
import useOrgDateTimeCell from 'core/table/cells/useOrgDateTimeCell'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  unsignedWaiversColumn: {
    '&>div': {
      width: '100px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  contactColumn: {
    '&>div': {
      width: '70px',
    },
  },
  dateColumn: {
    '&>div': {
      width: '90px',
    },
  },
  emailColumn: {
    '&>div': {
      width: '210px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  eventNameColumn: {
    '&>div': {
      width: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  roleNameColumn: {
    '&>div': {
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  shiftNameColumn: {
    '&>div': {
      width: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  shiftTimeColumn: {
    '&>div': {
      width: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  signupDateColumn: {
    '&>div': {
      width: '140px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '100px',
    },
  },
  groupsColumn: {
    '&>div': {
      width: '310px',
    },
  },
  mobileColumn: {
    '&>div': {
      width: '150px',
    },
  },
  homeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  statusHeaderTooltip: {
    height: '16px',
    marginBottom: '-5px',
  },
})

const STATUS_TOOLTIP =
  'A registration’s status is determined by whether or not the volunteer checks into the event for which they registered. If a volunteer registers for an event, and then checks into a different event or opportunity, they will appear as Absent on this table.'

export function useRegistrantColumns({ forEvent, forProfile = false }: { forEvent?: boolean; forProfile?: boolean }) {
  const classes = useStyles()
  const [, setDateRange] = useDateRangeFilter()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const history = useHistory()
  const getRegisteredPeriod = useGetRegisteredPeriod()

  const navigateToActivity = useCallback(
    (registration: RegistrantResponse) => {
      const {
        role: { name: role },
        occurrence: { name: opportunity },
        user: { givenName, familyName },
      } = registration
      const { startDate, endDate } = getRegisteredPeriod(registration)
      setDateRange({ startDate: startDate.startOf('day'), endDate: endDate.endOf('day') })
      history.push(
        `/activities?${encodeURIComponent(`role:anyOf`)}=${encodeURIComponent(`["${role}"]`)}&${encodeURIComponent(
          `opportunity:=`,
        )}=${encodeURIComponent(opportunity)}&${encodeURIComponent('name:=')}=${encodeURIComponent(
          givenName + ' ' + familyName, //TODO should we use a hidden ID filter, or does that not work?
        )}`,
      )
    },
    [getRegisteredPeriod, history, setDateRange],
  )

  const OrgDateTimeCell = useOrgDateTimeCell()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'user',
            Cell: ({ value: { id, givenName, familyName } }: any) => (
              <Link className={classes.link} to={`/champion/${id}`}>
                {givenName} {familyName}
              </Link>
            ),
            disableFilters: true,
            className: classes.nameColumn,
            sortType: contactSort,
            requires: 'notForProfile',
          },
          {
            id: 'contact',
            Header: (
              <Grid container justify="space-between" style={{ fontSize: '20px', width: '40px' }}>
                <AlternateEmailIcon fontSize="inherit" htmlColor="#001B3F" />
                <PhoneIcon fontSize="inherit" htmlColor="#001B3F" />
              </Grid>
            ),
            accessor: 'user',
            Cell: PhoneEmailContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.contactColumn,
            requires: 'notForProfile',
          },
          {
            id: 'firstName',
            Header: 'First Name',
            accessor: 'user.givenName',
            Cell: NameCell,
            className: classes.firstNameColumn,
            filter: DYNAMIC_TEXT,
            requires: 'notForProfile',
          },
          {
            id: 'lastName',
            Header: 'Last Name',
            accessor: 'user.familyName',
            Cell: NameCell,
            className: classes.lastNameColumn,
            filter: DYNAMIC_TEXT,
            requires: 'notForProfile',
          },
          {
            id: 'eventName',
            Header: 'Event',
            accessor: 'opportunity.name',
            Cell: ({ row }: any) => {
              const { occurrence, organization } = row.original

              return (
                <Link
                  className={classes.link}
                  to={`/events/${encodeOccurrenceFromEvent(occurrence)}/${organization.id}`}
                >
                  {occurrence.name}
                </Link>
              )
            },
            filter: DYNAMIC_TEXT,
            className: classes.eventNameColumn,
            requires: 'showEventName',
          },
          {
            id: 'date',
            Header: 'Event Date',
            accessor: 'date',
            Cell: DateCell,
            className: classes.dateColumn,
            disableFilters: true,
            sortType: createDateValueSort('date'),
            requires: 'forProfile',
          },
          {
            id: 'roleName',
            Header: 'Role',
            accessor: 'role.name',
            Cell: CellWithTooltip,
            filter: DYNAMIC_TEXT,
            className: classes.roleNameColumn,
          },
          {
            id: 'shiftName',
            Header: 'Shift Name',
            accessor: 'timeshift.name',
            filter: DYNAMIC_TEXT,
            className: classes.shiftNameColumn,
          },
          {
            id: 'shiftTime',
            Header: 'Shift Time',
            // @ts-ignore
            accessor: ({ timeshift: { timeStart, timeEnd } }: any) => `${timeStart} - ${timeEnd}`,
            filter: DYNAMIC_TEXT,
            className: classes.shiftTimeColumn,
          },
          {
            id: 'signupDate',
            Header: 'Signup Date',
            accessor: 'createdAt',
            Cell: OrgDateTimeCell,
            filter: DATE_RANGE,
            sortType: createDateValueSort('createdAt'),
            className: classes.signupDateColumn,
          },
          {
            id: 'status',
            Header: (
              <Grid container justify={'flex-start'}>
                <Tooltip title={STATUS_TOOLTIP} arrow>
                  <Grid item>
                    Status
                    <ContactSupportOutlinedIcon className={classes.statusHeaderTooltip} />
                  </Grid>
                </Tooltip>
              </Grid>
            ),
            accessor: 'status',
            Cell: ({ value, row: { original } }: Cell<RegistrantResponse, string>) => {
              if (original.activityId) {
                return (
                  <a className={classes.link} href="#" onClick={() => navigateToActivity(original)}>
                    {value}
                  </a>
                )
              }

              return value
            },
            disableFilters: true,
            className: classes.statusColumn,
          },
          {
            id: 'unsignedWaivers',
            Header: 'Waivers',
            accessor: 'unsignedWaivers',
            Cell: WaiversCell,
            disableFilters: true,
            className: classes.unsignedWaiversColumn,
          },
          {
            id: 'email',
            Header: 'Email',
            accessor: 'user.email',
            filter: DYNAMIC_TEXT,
            className: classes.emailColumn,
          },
          {
            id: 'mobile',
            Header: 'Mobile Phone',
            accessor: 'user.phoneNumber',
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            className: classes.mobileColumn,
          },
          {
            id: 'homePhoneNumber',
            Header: 'Home Phone',
            accessor: 'user.homePhoneNumber',
            className: classes.homeColumn,
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
          },
          {
            id: 'groups',
            Header: 'Groups',
            accessor: ({ groups }: any) => (groups || []).map(({ name }: any) => name).join(', '),
            Cell: ExpandableCell,
            disableFilters: true,
            className: classes.groupsColumn,
          },
        ],
        {
          showAdminItems,
          showEventName: !forEvent,
          forProfile,
          notForProfile: !forProfile,
        },
      ) as any as ColumnInstance<RegistrantResponse>[],
    [
      classes.nameColumn,
      classes.contactColumn,
      classes.firstNameColumn,
      classes.lastNameColumn,
      classes.eventNameColumn,
      classes.dateColumn,
      classes.roleNameColumn,
      classes.shiftNameColumn,
      classes.shiftTimeColumn,
      classes.signupDateColumn,
      classes.statusHeaderTooltip,
      classes.statusColumn,
      classes.unsignedWaiversColumn,
      classes.emailColumn,
      classes.mobileColumn,
      classes.homeColumn,
      classes.groupsColumn,
      classes.link,
      OrgDateTimeCell,
      showAdminItems,
      forEvent,
      forProfile,
      navigateToActivity,
    ],
  )
}

export default useRegistrantColumns
