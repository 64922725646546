import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { PersonRef } from 'civic-champs-shared/question-sets/types'
import format from 'civic-champs-shared/utils/format'
import useDelete from 'civic-champs-shared/api/hooks/useDelete'
import cn from 'classnames'

export interface Tester {
  id: number
  person: PersonRef
  addedBy: PersonRef
  createdAt: Date
  deletedAt?: Date
}

interface Props {
  tester: Tester
  complete: (cleared: boolean) => void
  close: () => void
  cancel: () => void
  refresh: () => Promise<void>
  showing: boolean
}

export const ClearTesterPrompt = (props: Props) => {
  const { tester, close, showing, complete, cancel, refresh } = props
  const [deleteTester, { loading }] = useDelete({
    errorMessage: 'Error removing tester',
    successMessage: 'Successfully removed tester',
    configMapper: t => ({
      url: `/testers/${t.id}`,
      onSuccess: refresh,
    }),
  })
  const classes = usePromptStyles()
  const submit = async () => {
    await deleteTester(tester)
    await refresh()
    complete(true)
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={cn(classes.contentContainer, classes.windowedContentContainer)}>
        <div className={classes.dialog}>
          <Grid container direction="column">
            <Grid>
              <Typography variant="h5">Do you want to remove this tester?</Typography>
            </Grid>
            <Grid>
              <div style={{ marginTop: '10px' }}>
                {tester.person.givenName} {tester.person.familyName} (
                {tester.person.email ? tester.person.email : format.phoneNumber(tester.person.phoneNumber)})
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={cn(classes.actionContainer, classes.windowedActionContainer)}>
        <Button
          variant="text"
          color="primary"
          onClick={cancel}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeCancel}`}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={submit}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeButton}`}
        >
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
