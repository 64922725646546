import React, { FC } from 'react'
import { useFeatureEnabled } from 'core/feature/hooks'
import GroupSurveyEditor from 'group/pages/GroupSurveyEditor'
import GroupQuestionnaireEditor from 'group/pages/GroupQuestionnaireEditor'

export interface GroupSurveyQuestionSetEditorProps {
  match: {
    url: string
    params: { id: string; questionnaireId?: string }
  }
}

export const GroupSurveyQuestionSetEditor: FC<GroupSurveyQuestionSetEditorProps> = (
  props: GroupSurveyQuestionSetEditorProps,
) => {
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  return isQuestionnaireAsSurveyEnabled ? <GroupSurveyEditor {...props} /> : <GroupQuestionnaireEditor {...props} />
}

export default GroupSurveyQuestionSetEditor
