import React from 'react'
import { Button, Grid, Modal, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '350px',
    height: '124px',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    zIndex: '1000',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: '15px',
  },
}))

export const RemoveModal = props => {
  const { showing, submit, cancel } = props
  const classes = useStyles()
  return (
    <Modal open={showing} className={classes.modal}>
      <Grid container direction="column" className={classes.modalBody}>
        <Grid item className={classes.title}>
          <Typography variant="h5">Remove this waiver?</Typography>
        </Grid>
        <Grid item container direction="row" justify="center">
          <Grid item>
            <Button onClick={cancel} variant="text">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={submit} variant="contained" color="primary">
              Remove
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
