import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Auth } from 'aws-amplify'

import Popup from '../../components/popup'
import ChangePassword from './changePassword'
import EditUserPhone from './editUserPhone'
import EditUserEmail from './editUserEmail'
import EditUserName from './editUserName'
import UserPhoneVerification from './userPhoneVerification'
import UserEmailVerification from './userEmailVerification'
import { Loader } from '../../components/loader'
import AccountViewItem from '../../components/account/view-item'
import ButtonComponent from '../../components/button'
import { IUpdateUser, IUserInfo, UserAttributeType } from '../../../redux/actions'

import './index.scss'

interface IProps {
  isLoading: boolean
  changeUserPassword: (currentPass: string, newPass: string) => {}
  updateUser: (updates: IUpdateUser) => {}
  passwordError?: string
  userInfo: IUserInfo
  getCurrentUserInfo: VoidFunction
  verifyChangeUserAttribute: (attr: UserAttributeType, code: string) => {}
  sendUserVerificationCodeByAttribute: (attr: UserAttributeType) => {}
  showNotification: (message: string, status: 'success' | 'error') => {}
}

interface IPopupDisplayStrategy {
  component: React.ReactNode
  width: string
  height: string
}

enum PopupNames {
  CHANGE_USER_PASSWORD = 'changeUserPassword',
  EDIT_USER_NAME = 'editUserName',
  EDIT_USER_PHONE = 'editUserPhone',
  USER_PHONE_VERIFICATION = 'userPhoneVerification',
  EDIT_USER_EMAIL = 'editUserEmail',
  USER_EMAIL_VERIFICATION = 'userEmailVerification',
}

const AccountSettingsModule = (props: IProps) => {
  const {
    isLoading,
    changeUserPassword,
    updateUser,
    passwordError,
    userInfo,
    getCurrentUserInfo,
    verifyChangeUserAttribute,
    sendUserVerificationCodeByAttribute,
    showNotification,
  } = props
  const [activePopup, setActivePopup] = useState('')

  useEffect(() => {
    getCurrentUserInfo()
  }, [getCurrentUserInfo])

  if (isLoading) {
    return <Loader />
  }

  const phoneValue = userInfo.phoneNumber

  const onUpdateUserName = async (updates: IUpdateUser) => {
    try {
      await updateUser(updates)
      await getCurrentUserInfo()
      setActivePopup('')
      showNotification('Name was updated successfully', 'success')
    } catch (exception) {
      showNotification('Updating name failure', 'error')
    }
  }
  const onUpdateUserPhone = async (updates: IUpdateUser) => {
    try {
      await updateUser(updates)
      await getCurrentUserInfo()
      setActivePopup(PopupNames.USER_PHONE_VERIFICATION)
      showNotification(`Verification code was sent to ${updates.phone_number}`, 'success')
    } catch (e) {
      showNotification('Updating phone failure', 'error')
    }
  }
  const onUpdateUserEmail = async (updates: IUpdateUser) => {
    try {
      await updateUser(updates)
      await getCurrentUserInfo()
      setActivePopup(PopupNames.USER_EMAIL_VERIFICATION)
      showNotification(`Verification code was sent to ${updates.email}`, 'success')
    } catch (exception) {
      showNotification('Updating email failure', 'error')
    }
  }
  const verifyUserEmail = async (code: string) => {
    try {
      await verifyChangeUserAttribute('email', code)
      showNotification('Email was verified successfully', 'success')
      setActivePopup('')
      await getCurrentUserInfo()
      setActivePopup('')
      await updateUser({ email: userInfo.email, email_verified: true })
    } catch (exception) {
      showNotification(exception.message, 'error')
    }
  }
  const verifyUserPhone = async (code: string) => {
    try {
      await verifyChangeUserAttribute('phone_number', code)
      showNotification('Phone number was verified successfully', 'success')
      setActivePopup('')
      await getCurrentUserInfo()
      setActivePopup('')
      await updateUser({ phone_number: phoneValue, phone_number_verified: true })
    } catch (exception) {
      showNotification(exception.message, 'error')
    }
  }
  const onChangeUserPassword = async (currentPass: string, newPass: string) => {
    try {
      await changeUserPassword(currentPass, newPass)
      showNotification('Password was changed successfully', 'success')
      setActivePopup('')
    } catch (exception) {
      console.error(exception)
    }
  }
  const onResendEmailVerificationCode = async () => {
    try {
      await sendUserVerificationCodeByAttribute('email')
      showNotification(`Verification code was sent to ${userInfo.email}`, 'success')
    } catch (exception) {
      showNotification(exception.message, 'error')
    }
  }
  const onResendPhoneVerificationCode = async () => {
    try {
      await sendUserVerificationCodeByAttribute('phone_number')
      showNotification(`Verification code was sent to ${phoneValue}`, 'success')
    } catch (exception) {
      showNotification(exception.message, 'error')
    }
  }

  const popupDisplayStrategy: { [key: string]: IPopupDisplayStrategy } = {
    [PopupNames.CHANGE_USER_PASSWORD]: {
      component: (
        <ChangePassword
          changeUserPassword={onChangeUserPassword}
          closePopup={() => setActivePopup('')}
          passwordError={passwordError}
        />
      ),
      width: '470px',
      height: '550px',
    },
    [PopupNames.EDIT_USER_NAME]: {
      component: (
        <EditUserName
          initialValues={{ firstName: userInfo.givenName, lastName: userInfo.familyName }}
          closePopup={() => setActivePopup('')}
          onUpdate={onUpdateUserName}
        />
      ),
      width: '471px',
      height: '456px',
    },
    [PopupNames.EDIT_USER_PHONE]: {
      component: (
        <EditUserPhone
          initialValues={{ phone: phoneValue || '' }}
          closePopup={() => setActivePopup('')}
          onUpdate={onUpdateUserPhone}
        />
      ),
      width: '471px',
      height: '342px',
    },
    [PopupNames.USER_PHONE_VERIFICATION]: {
      component: (
        <UserPhoneVerification
          initialValues={{ code: '' }}
          onCancel={() => setActivePopup('')}
          onSave={verifyUserPhone}
          onResend={debounce(onResendPhoneVerificationCode, 1000)}
        />
      ),
      width: '471px',
      height: '385px',
    },
    [PopupNames.EDIT_USER_EMAIL]: {
      component: (
        <EditUserEmail
          initialValues={{ email: userInfo.email || '' }}
          closePopup={() => setActivePopup('')}
          onUpdate={onUpdateUserEmail}
        />
      ),
      width: '471px',
      height: '342px',
    },
    [PopupNames.USER_EMAIL_VERIFICATION]: {
      component: (
        <UserEmailVerification
          initialValues={{ code: '' }}
          onCancel={() => setActivePopup('')}
          onSave={verifyUserEmail}
          onResend={debounce(onResendEmailVerificationCode, 1000)}
        />
      ),
      width: '471px',
      height: '385px',
    },
  }
  const form = popupDisplayStrategy[activePopup as PopupNames] ? popupDisplayStrategy[activePopup] : null

  return (
    <div className="account_wrapper">
      <div className="account_block">
        <div className="account_block_header">
          <div className="account_block_header_title">Account Setting</div>
        </div>
      </div>

      <div className="account_block_content">
        <AccountViewItem
          title={'Name'}
          value={`${userInfo.givenName} ${userInfo.familyName}`}
          onClickIcon={() => setActivePopup(PopupNames.EDIT_USER_NAME)}
        />

        <AccountViewItem
          title={'Email Address'}
          value={userInfo.email || ''}
          onClickIcon={() => setActivePopup(PopupNames.EDIT_USER_EMAIL)}
        />

        <AccountViewItem
          title={'Phone Number'}
          value={phoneValue || ''}
          onClickIcon={() => setActivePopup(PopupNames.EDIT_USER_PHONE)}
        />

        <AccountViewItem title={'Password'} value={'**************'} />

        <span className="account_change_pass" onClick={() => setActivePopup(PopupNames.CHANGE_USER_PASSWORD)}>
          Change Password
        </span>
      </div>

      <ButtonComponent className="logout_btn" onClick={() => Auth.signOut()}>
        Log Out
      </ButtonComponent>

      {form !== null && (
        <Popup open hideCloseIcon width={form.width} height={form.height}>
          {form.component}
        </Popup>
      )}
    </div>
  )
}

export default AccountSettingsModule
