import { useCallback, useMemo } from 'react'
import * as _ from 'lodash'
import yup from 'civic-champs-shared/utils/yup'

export const CREATE_NEW_QUESTION_SET = 'CREATE NEW QUESTIONNAIRE'
export const CREATE_NEW_SURVEY = 'CREATE NEW SURVEY'

// pretty ugly
export const useHandleNewQuestionSet = ({ openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox }) =>
  useCallback(
    async (arrayHelpers, index, questionSetId, optional = false) => {
      const addedQuestionSet = await openQuestionSetModal({ questionSetId, showAnonymousCheckbox })
      if (addedQuestionSet) {
        const newSet = {
          questionSetId: addedQuestionSet.questionSetId,
          name: addedQuestionSet.name,
          optional,
        }

        setQuestionSets([...questionSets, newSet])
        arrayHelpers.replace(index, newSet)
      }
    },
    [openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox],
  )

export const useHandleNewSurvey = ({ openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox }) =>
  useCallback(
    async (setFieldValue, index, surveyId, optional = false) => {
      const addedSurvey = await openQuestionSetModal({ surveyId, showAnonymousCheckbox })

      if (addedSurvey) {
        const newSet = {
          surveyId: addedSurvey.id,
          name: _.last(_.orderBy(addedSurvey.versions, ['version'])).questionSet.name,
          optional,
          required: !optional,
        }
        setQuestionSets(_.uniqBy([...questionSets, newSet], 'surveyId'))
        setFieldValue(`requirements[${index}].value`, newSet)
      }
    },
    [openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox],
  )

export const usePickerSchema = (min, max, isQuestionnaireAsSurveyEnabled = false) =>
  useMemo(() => {
    const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'
    return yup.object({
      questionSets: yup
        .array()
        .of(
          yup
            .object({
              [key]: yup
                .number()
                .min(1, 'You must select a question set or delete the row')
                .required('You must select a question set or delete the row'),
              optional: yup.boolean().default(false),
            })
            .uniqueProperty(key, 'You cannot select the same question set twice'),
        )
        .min(min, `At least ${min} questionnaires are required`)
        .max(max, `A maximum of ${max} questionnaires can be entered`),
    })
  }, [isQuestionnaireAsSurveyEnabled, min, max])

export const useOptions = ({ questionSets, value, isGlobalUserProfile, isQuestionnaireAsSurveyEnabled }) =>
  useMemo(() => {
    const key = isQuestionnaireAsSurveyEnabled ? 'id' : 'questionSetId'
    const selectedQuestionsByQuestionId = _.keyBy(value, key)
    const mappedQuestions = questionSets.reduce((acc, { id: surveyId, anonymous, questionSetId, name }) => {
      if (!anonymous || selectedQuestionsByQuestionId[key]) {
        acc.push({
          ...(isQuestionnaireAsSurveyEnabled ? { surveyId } : { questionSetId }),
          name,
          optional: false,
        })
      }
      return acc
    }, [])
    return isGlobalUserProfile ? [...mappedQuestions] : [CREATE_NEW_QUESTION_SET, ...mappedQuestions]
  }, [isQuestionnaireAsSurveyEnabled, value, questionSets, isGlobalUserProfile])

export const useSubmit = ({ pickerSchema, onSubmit }) =>
  useCallback(
    values => {
      const castQuestionSets = pickerSchema.cast(values)
      const mappedQuestionSets = castQuestionSets.questionSets.map(questionSet => ({
        ...questionSet,
        required: !questionSet.optional,
      }))
      return onSubmit(mappedQuestionSets)
    },
    [pickerSchema, onSubmit],
  )

export const useGetDirtyLookup = (value, isQuestionnaireAsSurveyEnabled = false) =>
  useCallback(
    questionSets => {
      const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'
      return questionSets.reduce((acc, questionSet, index) => {
        const currentValue = _.get(value, index)
        if (!currentValue) {
          acc[index] = { [key]: true, optional: true }
        } else {
          const idChanged = currentValue[key] !== questionSet[key]
          const optionalChanged = currentValue.optional !== questionSet.optional
          acc[index] = { [key]: idChanged, optional: optionalChanged }
        }
        return acc
      }, {})
    },
    [isQuestionnaireAsSurveyEnabled, value],
  )

export const emptyRow = key => ({ [key]: '', name: '', optional: false })
