import { useState, useMemo } from 'react'
import moment from 'moment'
import { get } from 'lodash'

//TODO pull out into its own file
class DateTimeRange {
  constructor(start, duration) {
    this._start = start
    this._duration = duration
    this._isValid = this._start instanceof moment && moment.isDuration(this._duration)
  }

  get start() {
    return this._start
  }
  get duration() {
    return this._duration
  }
  get end() {
    return this.isValid() ? this._start.clone().add(this._duration) : moment.invalid()
  }

  isValid() {
    return this._isValid
  }
}

const getMoment = (object, path) => {
  let value = get(object, path)
  return value instanceof moment || value == null ? value : moment(value)
}

const getDuration = (object, path) => {
  let value = get(object, path)
  return moment.isDuration(value) || value == null ? value : moment.duration(value)
}

//TODO support both 'duration' and 'end' values in the initial data

export default function useDateTimeRange(initialData) {
  const [range, setRange] = useState(
    () => new DateTimeRange(getMoment(initialData, 'start'), getDuration(initialData, 'duration')),
  )

  const actions = useMemo(
    () => ({
      setStart: start => setRange(({ duration }) => new DateTimeRange(start, duration)),
      setDuration: duration => setRange(({ start }) => new DateTimeRange(start, duration)),
      setEnd: value =>
        setRange(prev => {
          let start = prev.start
          let end = moment(value)

          if (end.isValid()) {
            if (end < start) end = start

            return new DateTimeRange(start, moment.duration(end.diff(start.startOf('minute'))))
          } else {
            return prev
          }
        }),
    }),
    [setRange],
  )

  return [range, actions]
}
