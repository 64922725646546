import { size } from 'lodash'
import React, { useMemo } from 'react'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { Box, Grid } from '@material-ui/core'
import { AddButton } from 'civic-champs-shared/core/add-button'
import Loading from 'civic-champs-shared/core/Loading'
import PageTitle from 'components/PageTitle'
import { DateTimeCell, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'
import PagedTable from 'core/table/components/PagedTable'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useStyles from 'new-mentorship/hooks/useStyles'
import CreateUpdateSurveyPrompt from 'surveys/components/CreateUpdateSurveyPrompt'
import useSurveys from 'new-mentorship/hooks/useFetchSurveys'

const useColumns = () => {
  const classes = useStyles()
  return useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: (row: any) => row.versions[0]?.questionSet?.name || 'Untitled',
        Cell: (props: any) =>
          WithLinkCell(
            NullableTextCell,
            (id: any) => `/mentorship/surveys/${id}`,
          )({ ...props, linkProps: { className: classes.link } }),
      },
      {
        id: 'version',
        Header: 'Version',
        accessor: (row: any) => size(row.versions),
        Cell: NumberCell,
      },
      {
        id: 'lastUpdated',
        Header: 'Last Updated',
        accessor: (row: any) => row.versions[0]?.createdAt,
        Cell: DateTimeCell,
        sortType: 'datetime',
      },
      {
        id: 'editor',
        Header: 'Editor',
        accessor: (row: any) => {
          const editor = row.versions[0]?.questionSet?.createdBy
          return !!editor ? `${editor.givenName} ${editor.familyName}` : null
        },
        Cell: NullableTextCell,
      },
    ],
    [classes.link],
  )
}

export const MentorshipSurveys = () => {
  const { surveys, loading, refresh } = useSurveys()

  const showCreateUpdatePrompt = useShowPrompt(CreateUpdateSurveyPrompt)

  const handleAdd = async () => {
    await showCreateUpdatePrompt()
    refresh()
  }

  const columns = useColumns()

  const table = useTable<any>(
    {
      data: surveys,
      columns,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div className={classes.header}>
          {/* TODO: this should have items in it! */}
          <Grid xs={6} container item alignItems="center" justify="flex-start" direction="row">
            <PageTitle title="Mentorship Surveys" />
          </Grid>
          <Box ml={1.25}>
            <AddButton onClick={handleAdd} />
          </Box>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <section id="table" className={classes.table}>
            {/*
              // @ts-ignore */}
            <PagedTable {...table} />
          </section>
        )}
      </div>
    </div>
  )
}

export default MentorshipSurveys
