import { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { CredentialFull } from 'civic-champs-shared/question-sets/types'
import { useGetOrganizationCredentials } from './useGetOrganizationCredentials'

const useOrganizationCredentials = (onSuccess?: (data: any) => any) => {
  const [getOrganizationCredentials, { result, loading, error, called }] = useGetOrganizationCredentials(onSuccess)
  useEffect(() => {
    getOrganizationCredentials()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    credentials: useMemo(
      () =>
        _.map(result, r => ({
          ...r,
          id: r.credential.id,
        })),
      [result],
    ) as CredentialFull[],
    loading: loading || !called,
    error,
    refresh: getOrganizationCredentials,
  }
}

export default useOrganizationCredentials
