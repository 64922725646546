import React from 'react'
import { ExportButton } from 'core/table/components'
import format from 'civic-champs-shared/utils/format'

const filename = 'admins.csv'
const headers = [
  { label: 'First Name', key: 'person.givenName' },
  { label: 'Last Name', key: 'person.familyName' },
  { label: 'Permission', key: 'role' },
  { label: 'Email', key: 'person.email' },
  { label: 'Mobile Phone', key: 'person.phoneNumber' },
  { label: 'Last Login', key: 'person.lastLoginAt' },
  { label: 'Joined date', key: 'startedAt' },
]

const formatPhones = (phoneProps: string[], obj: any) =>
  phoneProps.reduce((acc: any, phone: string) => ({ ...acc, [phone]: format.phoneNumber(obj?.[phone]) }), {})

const formatData = (admins: any[]) =>
  admins.map(({ person, startedAt, role }) => ({
    person: {
      ...person,
      ...formatPhones(['phoneNumber', 'homePhoneNumber'], person),
      lastLoginAt: format.name(person.lastLoginAt),
    },
    role,
    startedAt: format.datetime(startedAt),
  }))

export const ExportAdminsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => (
  <ExportButton
    type="popover"
    data={data}
    disabled={disabled}
    formatData={formatData}
    headers={headers}
    filename={filename}
  />
)

export default ExportAdminsButton
