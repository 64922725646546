import * as types from '../types'
import { Meeting } from '../../scenes/MentorShipActivities'
import { Match } from '../../kit/modules/mentor-ship/matches'

export interface MatchDetailsItem extends Match {
  activities: Meeting[]
}

export interface MatchState {
  loading: boolean
  match: MatchDetailsItem
  error: any
}

const initialState = {
  loading: false,
  match: null,
  error: null
}

export default function matchesReducer(state = initialState, action: any) {
  switch (action.type) {
    case types.MATCH_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.MATCH_RECEIVE:
      return {
        ...state,
        loading: false,
        error: null,
        match: action.payload
      }
    case types.MATCH_CLEAR:
      return {
        loading: false,
        error: null,
        match: null
      }
    case types.MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default:
      return state
  }
}
