import { Status } from '../../civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../auth/hooks'
import useFetchRefactored from '../../civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { UploadsHistoryItem } from '../interfaces'

interface IResponse {
  data: UploadsHistoryItem[]
  total: number
}

export const useGetUploadedFiles = (reference: string): [() => Promise<IResponse>, Status<IResponse>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<IResponse>()

  const getFiles = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/uploads/${reference}`,
    })
  }, [organization.id, reference, request])

  return [getFiles, status]
}
