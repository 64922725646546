import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { RemoveModal } from '../WaiverPicker/RemoveModal'

const useStyles = makeStyles(theme => ({
  removeText: {
    color: theme.palette.danger.main,
  },
}))

export const CleanRow = props => {
  const { label, onRemove, onEdit, disabled } = props
  const showRemoveModal = useShowPrompt(RemoveModal)
  const classes = useStyles()
  return (
    <>
      <Grid item xs={3}>
        <div>{label}</div>
      </Grid>
      <Grid item xs={1} container justify="center">
        <Button variant="text" color="secondary" onClick={onEdit} disabled={disabled}>
          Edit
        </Button>
      </Grid>
      <Grid item xs={1} container justify="center">
        <Button
          variant="text"
          className={classes.removeText}
          onClick={() => showRemoveModal({ submit: onRemove })}
          disabled={disabled}
        >
          Remove
        </Button>
      </Grid>
    </>
  )
}
