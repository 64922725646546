import React from 'react'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Tooltip } from '@material-ui/core'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import { GridJustification } from '@material-ui/core/Grid/Grid'
import cn from 'classnames'

export interface SummaryCardsProps {
  data: {
    [key: string]: any
  }
  cards: SummaryCard[]
  classes: {
    [key: string]: string
  }
  fixedWidth?: number
  justify?: GridJustification
}

export interface SummaryCard {
  header: string
  accessor: string
  transform?: (input: any) => any
  tooltip?: string
}

function SummaryCards({ data, cards, classes, justify, fixedWidth }: SummaryCardsProps) {
  return (
    <Grid container spacing={2} alignItems="stretch" justify={justify}>
      {cards.map(({ header, accessor, transform, tooltip }, index) => {
        let value
        try {
          value = transform ? transform(data[accessor]) : data[accessor]
        } catch (e) {
          value = data[accessor]
        }
        return (
          <Grid item xs key={index} className={cn({ [classes.nogrow]: justify })}>
            <div className={classes.card} style={fixedWidth ? { minWidth: `${fixedWidth}px` } : {}}>
              <Typography className={classes.header}>
                {header}
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <ContactSupportOutlinedIcon className={classes.tooltip} />
                  </Tooltip>
                )}
              </Typography>

              <Typography className={classes.value}>{value}</Typography>
            </div>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withStyles({
  card: {
    backgroundColor: '#EBF0FF',
    borderRadius: '5px',
    padding: '15px',
    height: '100%',
  },
  header: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#000000',
  },
  value: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '46px',
    color: '#000000',
  },
  tooltip: {
    fontSize: 'inherit',
    marginLeft: '8px',
  },
  nogrow: {
    flexGrow: 'unset',
  },
})(SummaryCards)
