import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'
import { Role, RolePayload } from '../types'

export const useSaveRole = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<Role>()
  const saveRole = useCallback(
    async ({ id, ...role }: RolePayload, options?: { reporting: boolean }) =>
      request({
        method: id ? 'put' : 'post',
        url: `/organizations/${organization.id}/roles${id ? `/${id}` : ''}`,
        config: {
          queryStringParameters: options,
          body: role,
        },
      }),
    [request, organization.id],
  )

  return { saveRole, loading, error }
}
