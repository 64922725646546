import { NOTIFICATION_INITIALIZED, NOTIFICATION_UPDATED } from 'notification/redux/actions'
import { SIGNED_OUT } from 'auth/actions'

const defaultState = {
  initialized: false,
  notifications: [],
}

export interface Event {
  type: string
  payload: any
}

export default function (state = defaultState, event: Event) {
  switch (event.type) {
    case NOTIFICATION_INITIALIZED:
      return {
        ...state,
        initialized: true,
        notifications: event.payload.notifications,
      }

    case NOTIFICATION_UPDATED:
      return {
        ...state,
        notifications: event.payload.notifications,
      }

    case SIGNED_OUT:
      return {
        ...defaultState,
      }

    default:
      return state
  }
}
