import moment from 'moment';
import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Favorite from '@material-ui/icons/Favorite'
import { useCurrentOrg } from 'auth/hooks';

const useStyles = makeStyles(theme => ({
    footer: {
        display: 'flex',
        height: '30px',
        backgroundColor: 'white',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: '30px',
      },
    row: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
      color: 'black',
      alignSelf: 'center',
      alignItems: 'center',
    },
    icon: {
      marginLeft: '2px',
      marginRight: '2px',
    },
    name: {
      fontSize: '12px',
      color: 'black',
    },
    text: {
      fontSize: '12px',
      color: 'black',
    },
  }))

const Footer = ({ history }) => {
    const classes = useStyles()
    const organization = useCurrentOrg()
  
    return (
      <div className={classes.footer}>
          {organization!=null &&<span className={classes.row}>
            <div><b>We</b></div>
            <Favorite className={classes.icon} color={'white'}/>
            <div className={classes.name}><b>{organization.name}</b></div>
          </span>} 
          <div className={classes.text}> </div> 
      </div>
    )
  }

  export default Footer