import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { NotificationBlastReportingWithMessages } from 'messages/types'
import { Status } from 'civic-champs-shared/api/hooks'
import { first } from 'lodash'

export const useGetMessage = (): [
  (uuid: string) => Promise<NotificationBlastReportingWithMessages>,
  Status<NotificationBlastReportingWithMessages>,
] => {
  const [fetch, status] = useFetchRefactored<
    NotificationBlastReportingWithMessages,
    NotificationBlastReportingWithMessages[]
  >({ mapper: infos => first(infos) as NotificationBlastReportingWithMessages })

  const currentOrg = useCurrentOrg()
  const getMessage = useCallback(
    (uuid: string) => {
      return fetch({
        method: 'get',
        url: `/organizations/${currentOrg.id}/notification-blasts`,
        config: {
          queryStringParameters: { uuid, includeMessages: true, includeAttachments: true },
        },
      })
    },
    [currentOrg.id, fetch],
  )

  return [getMessage, status]
}
