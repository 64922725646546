import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

// TODO: wire this in so that users can edit group names
export const useUpdateGroup = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error updating group')
  const showSuccess = useSuccessNotification()
  const organization = useCurrentOrg()
  const updateGroup = useCallback(
    async group => {
      return request({
        onSuccess: result => {
          showSuccess(`Successfully updated group "${result.name}"`)
          onResultUpdate && onResultUpdate(result)
        },
        method: 'put',
        url: `/organizations/${organization.id}/groups/${group.id}`,
        config: {
          body: { ...group },
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request],
  )

  return [
    updateGroup,
    {
      group: result,
      loading,
    },
  ]
}
