import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { MentorshipProgram } from 'new-mentorship/types'

export const useCreateProgram = (): [(body: any) => Promise<MentorshipProgram>, Status<MentorshipProgram>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<MentorshipProgram>({
    errorMessage: 'Program Creation Failed',
    successMessage: 'Program Created Successfully',
  })

  const createProgram = useCallback(
    body => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/mentorship-programs`,
        config: {
          body,
        },
      })
    },
    [organization, request],
  )

  return [createProgram, status]
}

export default useCreateProgram
