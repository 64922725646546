import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import useRemoveDocumentType from './useRemoveDocumentType'

export const useRemoveDocumentTypePrompt = (eagerRemove: Action<DocumentType>) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeDocumentType = useRemoveDocumentType(eagerRemove)

  return useCallback(
    async (documentType: DocumentType) => {
      const text = `Are you sure you want to delete "${documentType.name}"? The action can not be undone.`
      const confirmed = await showPrompt({
        text,
        title: 'Delete Document Type',
        confirmText: 'Delete',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        await removeDocumentType(documentType)
      }
    },
    [showPrompt, removeDocumentType],
  )
}

export default useRemoveDocumentTypePrompt
