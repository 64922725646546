import React, { FC } from 'react'

import { CurrencyCell, NumberCell } from 'core/table/cells'
import SummaryCards from 'core/table/components/SummaryCards'
import useChampionSummary from '../hooks/useChampionSummary'

export const NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR = 33.49
const valuePerHour = `${NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR}`

const cards = [
  {
    Header: 'Champions',
    accessor: 'count',
    Cell: NumberCell,
  },
  {
    Header: 'Hours',
    accessor: 'totalHours',
    Cell: NumberCell,
  },
  {
    Header: 'Donations',
    accessor: 'totalDonations',
    Cell: CurrencyCell,
  },
  {
    Header: 'Estimated Impact',
    accessor: 'estimatedImpact',
    Cell: CurrencyCell,
    toolTipText: `This value was calculated using the national average of $${valuePerHour} / hour for volunteers.`,
  },
]

export interface Props {
  volunteers: any
}

export const ChampionsSummaryCards: FC<Props> = props => {
  const { volunteers } = props
  const summary = useChampionSummary(volunteers, valuePerHour)

  return <SummaryCards cards={cards} summary={summary} />
}
