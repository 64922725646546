import { useCallback } from 'react'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { MergeRolesModal } from '../components/MergeRolesModal'
import { useMergeRoles } from './useMergeRoles'

export const useMergeRolesPrompt = (fetchRoles: () => {}) => {
  const showModal: any = useShowPrompt(MergeRolesModal)
  const mergeRoles = useMergeRoles(fetchRoles)

  return useCallback(
    async roles => {
      const confirm = await showModal({ roles })

      if (confirm) {
        await mergeRoles(roles, confirm.name)
      }
    },
    [mergeRoles, showModal],
  )
}

export default useMergeRolesPrompt
