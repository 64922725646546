import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import useAvatarDialogStyles from 'champion/hooks/useAvatarDialogStyles'
import cn from 'classnames'
import ZoomSlider from 'champion/components/ZoomSlider'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import OpenWithIcon from '@material-ui/icons/OpenWith'
import { AVATAR_DEFAULT_ZOOM as DEFAULT_ZOOM, AVATAR_MAX_ZOOM as MAX_ZOOM } from 'champion/interfaces'
import { readFile } from 'champion/utils/files'

export const AvatarCropUI = ({
  file,
  setFile,
  imageRef,
  dataRef,
}: {
  file: string
  setFile: Dispatch<SetStateAction<string | null>>
  imageRef: React.MutableRefObject<any>
  dataRef: React.MutableRefObject<{ x: number; y: number; zoom: number }>
}) => {
  const classes = useAvatarDialogStyles()
  const inputFileRef = React.useRef<any>()
  const handleClick = useCallback(() => {
    inputFileRef.current?.click()
  }, [])
  const requestRunning = useRef<number | null>(null)
  const movement = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const touches = useRef<{ x: number; y: number; identifier: number }[]>([])
  const [zoom, setZoom] = useState<number>(dataRef.current.zoom)
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: dataRef.current.x,
    y: dataRef.current.y,
  })
  const [maxOffset, setMaxOffset] = useState<number>(MAX_ZOOM - DEFAULT_ZOOM)
  const [grabbing, setGrabbing] = useState<boolean>(false)
  useEffect(() => {
    setMaxOffset(MAX_ZOOM - zoom)
  }, [zoom])
  const normalize = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (x < 0) x = 0
      if (y < 0) y = 0
      if (x > maxOffset) x = maxOffset
      if (y > maxOffset) y = maxOffset
      dataRef.current.x = x
      dataRef.current.y = y
      return { x, y }
    },
    [dataRef, maxOffset],
  )
  const handleZoomChange = useCallback(
    (_: any, newZoom: number | number[]) => {
      setZoom(existingZoom => {
        const offsetDiff = (existingZoom - (newZoom as number)) / 2
        setOffset(({ x, y }) => {
          x += offsetDiff
          y += offsetDiff
          return normalize({ x, y })
        })
        dataRef.current.zoom = newZoom as number
        return newZoom as number
      })
    },
    [dataRef, normalize],
  )
  const handleTouchStart = useCallback((e: TouchEvent<HTMLDivElement>) => {
    setGrabbing(true)
    for (let i = 0; i < e.targetTouches.length; i++) {
      const touch = e.targetTouches[i]
      touches.current.push({ x: touch.clientX, y: touch.clientY, identifier: touch.identifier })
    }
  }, [])
  const handleTouchEnd = useCallback((e: TouchEvent<HTMLDivElement>) => {
    if (e.targetTouches.length === 0) {
      setGrabbing(false)
    }
    const identifiers: number[] = []
    for (let i = 0; i < e.targetTouches.length; i++) {
      const touch = e.targetTouches[i]
      identifiers.push(touch.identifier)
    }
    touches.current = touches.current.filter(t => identifiers.includes(t.identifier))
  }, [])
  const handleMove = useCallback(() => {
    if (requestRunning.current === null) {
      const { x: movementX, y: movementY } = movement.current
      movement.current = { x: 0, y: 0 }
      requestRunning.current = window.requestAnimationFrame(function () {
        setOffset(currentOffset => {
          let x = currentOffset.x + movementX
          let y = currentOffset.y + movementY
          return normalize({ x, y })
        })
        requestRunning.current = null
      })
    }
  }, [normalize])
  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (!grabbing) return
      let x = 0
      let y = 0
      let n = 0
      for (let i = 0; i < e.targetTouches.length; i++) {
        const touch = e.targetTouches[i]
        const existingTouch = touches.current.find(t => t.identifier === touch.identifier)
        if (!existingTouch) continue
        x += touch.clientX - existingTouch.x
        y += touch.clientY - existingTouch.y
        n++
        existingTouch.x = touch.clientX
        existingTouch.y = touch.clientY
      }
      movement.current.x += x / n
      movement.current.y += y / n
      handleMove()
    },
    [grabbing, handleMove],
  )
  const handleMouseMove = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!grabbing) return
      movement.current.x += e.movementX
      movement.current.y += e.movementY
      handleMove()
    },
    [grabbing, handleMove],
  )
  return (
    <div className={classes.imageUI}>
      <div
        className={classes.cropImageContainer}
        onMouseDown={() => setGrabbing(true)}
        onTouchStart={handleTouchStart}
        onMouseMove={handleMouseMove}
        onMouseUp={() => setGrabbing(false)}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <img ref={imageRef} src={file} className={classes.cropImage} crossOrigin="anonymous" />
        <div
          className={cn(classes.cropImageCover, { [classes.grabbing]: grabbing })}
          style={{
            width: `${zoom}px`,
            height: `${zoom}px`,
            top: `${offset.y}px`,
            left: `${offset.x}px`,
          }}
        />
        <OpenWithIcon className={classes.dragIcon} />
      </div>
      <ZoomSlider
        value={zoom}
        onChange={handleZoomChange}
        min={MAX_ZOOM / 2}
        max={MAX_ZOOM}
        step={2}
        aria-labelledby="Zoom"
      />
      <OutlinedButton className={classes.smallButton} onClick={handleClick}>
        Browse Image
      </OutlinedButton>
      <input
        ref={inputFileRef}
        className={classes.hidden}
        onChange={e => readFile((e.target.files as FileList)[0]).then(setFile)}
        type="file"
        accept="image/*"
      />
    </div>
  )
}
