// This will work with any version of Safari across all devices: Mac, iPhone, iPod, iPad.
// more details here: https://stackoverflow.com/posts/31732310/revisions
const IS_SAFARI =
  navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1

// Full explanation:  https://stackoverflow.com/questions/7000190/detect-all-firefox-versions-in-js
const IS_FIREFOX = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

export{
  IS_SAFARI,
  IS_FIREFOX
}
