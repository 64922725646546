import { isFunction } from 'lodash'
import moment from 'moment-timezone'
import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Button, makeStyles } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

//TODO promote this to a component library
//TODO replace existing CSV export functions w/ this button!

export interface CsvHeaderConfig {
  label: string
  key: string
}

export interface CsvConfig {
  filename?: string
  headers?: CsvHeaderConfig[]
  data: object[]
}

export type PrepareCsvFunction = () => CsvConfig | Promise<CsvConfig>

const formatFilename = (filename: string | undefined, separator: string = '_') => {
  if (filename === undefined) return

  const now = moment().format(`YYYYMMDD${separator}hhmmss`)
  const match = filename.match(/^([^.]+)\.(.*)$/)
  return match ? `${match[1]}${separator}${now}.${match[2]}` : filename
}

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
})

export interface ExportCsvButtonProps extends Partial<CsvConfig> {
  addTimestampToFilename?: boolean
  onPrepareCsv?: PrepareCsvFunction
  disabled?: boolean
}

const EMPTY_STATE: CsvConfig = { data: [] }

export const ExportCsvButton: React.FC<ExportCsvButtonProps> = props => {
  const [preparingCsv, setPreparingCsv] = useState(false)
  const [csvConfig, setCsvConfig] = useState<CsvConfig>(EMPTY_STATE)
  const csvLink = useRef<CSVLink & HTMLAnchorElement>(null)
  const classes = useStyles() //TODO can this be done using withStyles?

  const handleClick = async () => {
    let { onPrepareCsv, addTimestampToFilename = true } = props

    if (!isFunction(onPrepareCsv)) {
      onPrepareCsv = () => {
        if (!props.headers || !props.data) {
          throw new Error(`CSV export is missing headers and/or data!`)
        }

        return {
          filename: props.filename || 'export.csv',
          headers: props.headers,
          data: props.data,
        }
      }
    }

    setPreparingCsv(true)
    setCsvConfig(EMPTY_STATE)
    try {
      const csv = await onPrepareCsv()
      setCsvConfig({
        ...csv,
        filename: addTimestampToFilename ? formatFilename(csv.filename) : csv.filename,
      })
      //@ts-ignore
      csvLink.current.link.click()
    } finally {
      setPreparingCsv(false)
    }
  }

  const disabled = props.disabled || preparingCsv

  return (
    <div>
      <Button variant="contained" color="secondary" disabled={disabled} onClick={handleClick}>
        <GetAppIcon style={{ marginRight: 5 }} />
        {props.children}
      </Button>
      <CSVLink className={classes.hidden} {...csvConfig} ref={csvLink} target="_blank" />
    </div>
  )
}
