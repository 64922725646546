import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { InputBase, Paper, Grid, Button, IconButton } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import cn from 'classnames';

import UsersList from '../UsersList';
import useStyles from './useStyles';

function UserSearch({ timesheetMode, query, selected, onChange, onSearch, onSelect, onSubmit }) {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const classes = useStyles();

  useEffect( () => { setFilteredUsers( onSearch(query) ) }, [query, onSearch])

  const handleInputChange = (e) => onChange(e.target.value);

  const handleCheckIn = () => {
    onSubmit(selected);
  };

  return (
    <>
      <Grid Container>
        <Grid item>
          <Paper className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="Search">
              <Search />
            </IconButton>
            <InputBase
              className={classes.input}
              onChange={handleInputChange}
              value={query}
              placeholder="User Name or phone number"
              inputProps={{ 'aria-label': 'User Name or phone number' }}
              autoFocus
            />
            { !_.isEmpty(query) && <IconButton 
              className={classes.iconButton} 
              aria-label="Search" 
              onClick={() => onChange('')}
            >
              <Clear />
            </IconButton>}
          </Paper>
          <Grid container direction="column">
            <UsersList
              users={filteredUsers}
              onSelect={onSelect}
              selected={selected}
              timesheetMode={timesheetMode}
            />
            <Grid item className={classes.checkInWrapper}>
              <Button
                variant="contained"
                color="secondary"
                disabled={!(selected || {}).id}
                className={cn(classes.checkIn, timesheetMode === 'check-in' ? classes.checkInColor : classes.checkOutColor)}
                onClick={handleCheckIn}
              >
                {timesheetMode === 'check-in' ? 'Check in' : 'Check out'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default UserSearch;
