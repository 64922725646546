import { isEmpty } from 'lodash'
import { useLatest } from 'react-use'
import React, { useCallback, useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'

export default function Search(props) {
    const { onQueryChange, debounce=200, ...otherProps } = props

    const [query, setQuery] = useState('')
    const latestOnChange = useLatest(onQueryChange)

    const debouncedOnChange = useAsyncDebounce(value => {
        if(latestOnChange.current) {
            latestOnChange.current(value)
        }
    }, debounce || 0)

    const handleQueryChange = useCallback(event => { 
        const { value } = event.target
        setQuery(value)
        debouncedOnChange(value)
    }, [debouncedOnChange])

    const handleClearQuery = useCallback( () => { 
        setQuery('')
        if(latestOnChange.current) {
            latestOnChange.current('') 
        }
    }, [latestOnChange])

    //TODO this doesn't always work!
    const handleKeyUp = useCallback(event => {
        if(event.keyCode === 27) {
            handleClearQuery()
        }
    }, [handleClearQuery])

    return <OutlinedInput
        {...otherProps}
        value={query}
        onChange={handleQueryChange}
        onKeyUp={handleKeyUp}
        endAdornment={
            <InputAdornment position="end">{
                isEmpty(query)
                ? <SearchIcon />
                : <IconButton onClick={handleClearQuery}>
                    <ClearIcon />
                </IconButton>
            }</InputAdornment>
        }
    />
}