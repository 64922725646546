import { get } from 'lodash'

export const DEV_KEY = 'features'
export const PRODUCT_KEY = 'productFeatures'

export const getUser = state => get(state, `${DEV_KEY}.user`, null)

export const isProductFeaturesInitialized = state => get(state, `${PRODUCT_KEY}.initialized`, false)

export const isFeaturesInitialized = state =>
  get(state, `${DEV_KEY}.initialized`, false) && isProductFeaturesInitialized(state)

export const isFeaturesLoading = state =>
  get(state, `${DEV_KEY}.loading`, false) || get(state, `${PRODUCT_KEY}.loading`, false)

export const isFeaturesAvailable = state => isFeaturesInitialized(state) && !isFeaturesLoading(state)

export const getFeatures = state => {
  if (!isFeaturesAvailable(state)) return {}

  const features = get(state, `${DEV_KEY}.settings`, {})
  const productFeatures = get(state, `${PRODUCT_KEY}.settings`, {})

  return {
    ...features,
    ...productFeatures,
  }
}

export const isFeatureEnabled = (state, name) => !!getFeatures(state)[name]?.enabled
