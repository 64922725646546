import React from 'react'
import { usePubNub } from 'pubnub-react'

import { isFilePayload, getNameInitials, getPredefinedColor, getTime } from '../helpers'
import { ReactComponent as DownloadIcon } from 'civic-champs-shared/core/icon/icons/download.svg'
import { useCurrentProfile } from 'auth/hooks'
import format from 'civic-champs-shared/utils/format'

// This was ported from pubnub react chat components repository:
// https://github.com/pubnub/react-chat-components/blob/master/packages/react/src/message-list/message-item.tsx

export const MessageListItem = props => {
  const { message: envelope, users, channel, editedText } = props

  const profile = useCurrentProfile()
  let { user } = props
  if (!user) {
    const { pubnubId, avatar } = profile.user
    user = {
      id: pubnubId,
      name: format.name(profile.user),
      profileUrl: avatar?.url,
    }
  }

  const pubnub = usePubNub()

  const actions = envelope.actions
  const deleted = !!Object.keys(actions?.deleted || {}).length
  if (deleted) {
    return null
  }

  const time = getTime(envelope.timetoken)
  const uuid = envelope.uuid || envelope.publisher || ''
  const message = isFilePayload(envelope.message) ? envelope.message.message : envelope.message
  const file = isFilePayload(envelope.message) && envelope.message.file
  const reactions = envelope.actions?.reaction

  const addReaction = async (reaction, messageTimetoken) => {
    try {
      await pubnub.addMessageAction({
        channel,
        messageTimetoken: String(messageTimetoken),
        action: {
          type: 'reaction',
          value: reaction,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const removeReaction = async (reaction, messageTimetoken, actionTimetoken) => {
    try {
      await pubnub.removeMessageAction({ channel, messageTimetoken, actionTimetoken })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className="pn-msg__avatar" style={{ backgroundColor: getPredefinedColor(uuid) }}>
        {user?.profileUrl ? <img src={user.profileUrl} alt="User avatar" /> : getNameInitials(user?.name || uuid)}
      </div>

      <div className="pn-msg__main">
        <div className="pn-msg__content">
          <div className="pn-msg__title">
            <span className="pn-msg__author">{user?.name || uuid}</span>
            <span className="pn-msg__time">{time}</span>
          </div>
          {message?.text && <div className="pn-msg__bubble">{editedText || message?.text}</div>}
        </div>

        <div className="pn-msg__extras">
          {file && file.name ? (
            <div className="pn-msg__file">
              {/\.(svg|gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file.name) ? (
                <img alt={file.name} className="pn-msg__image" src={file.url} />
              ) : (
                <div className="pn-msg__bubble">
                  <a className="pn-msg__nonImage" href={file.url} target="_blank" rel="noopener noreferrer" download>
                    {file.name}
                    <DownloadIcon className="pn-msg__downloadIcon" />
                  </a>
                </div>
              )}
            </div>
          ) : null}

          {reactions ? (
            <div className="pn-msg__reactions">
              {Object.entries(reactions)
                .sort(([, a], [, b]) => b.length - a.length)
                .map(([reaction, instances]) => {
                  const instancesLimit = 99
                  const instancesLimited = instances.slice(0, instancesLimit)
                  const instancesOverLimit = instances.length - instancesLimited.length
                  const userReaction = instances?.find(i => i.uuid === pubnub.getUUID())
                  const userNames = instancesLimited.map(i => {
                    const user = users.find(u => u.id === i.uuid)
                    return user ? user.name : i.uuid
                  })
                  const tooltipContent = `
                  ${userNames.join(', ')}
                  ${instancesOverLimit ? `and ${instancesOverLimit} more` : ``}
                `

                  return (
                    <div
                      className={`pn-tooltip pn-msg__reaction ${userReaction ? 'pn-msg__reaction--active' : ''}`}
                      key={reaction}
                      data-tooltip={tooltipContent}
                      onClick={() => {
                        userReaction
                          ? removeReaction(reaction, envelope.timetoken, userReaction.actionTimetoken)
                          : addReaction(reaction, envelope.timetoken)
                      }}
                    >
                      {reaction} {instancesLimited.length}
                      {instancesOverLimit ? '+' : ''}
                    </div>
                  )
                })}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
