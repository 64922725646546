import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { capitalize, uniq } from 'lodash'
import moment from 'moment'
import {
  useExpanded,
  useFilters,
  useFlexLayout,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Box, Button, Grid, Hidden, Popover, Typography } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { Icon } from '@mdi/react'
import { mdiCalendar, mdiCalendarArrowRight, mdiEye, mdiFilterMenu, mdiLaunch, mdiMapMarker } from '@mdi/js'

import { createGroupedDateSort, createGroupedValueSort } from 'components/table/sort'
import { useDateRangeFilter } from 'filtering/hooks'
import { useTableSearch } from 'components/table/hooks'
import useOpportunitiesCollection from '../hooks/useOpportunitiesCollection'
import { FixedPointCell, IntegerCell } from 'core/table/cells'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import OpportunitySummaryCards from './OpportunitySummaryCards'
import Loading from 'components/Loading'
import useCreateOrUpdateOpportunityPrompt from '../hooks/useCreateOrUpdateOpportunityPrompt'
import RequireRole from 'auth/components/RequireRole'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import Search from 'core/table/components/Search'
import { AddButton } from 'civic-champs-shared/core/add-button'
import format from 'civic-champs-shared/utils/format'
import { startKiosk } from 'kiosk/actions'
import PagedTable from '../../../core/table/components/PagedTable'
import { FiltersModal, operatorTypes } from 'core/table/components/FiltersModal'
import DEFAULT_FILTERS, { DYNAMIC_NUMBER, DYNAMIC_TEXT } from '../../../core/table/filters'

const useStyles = makeStyles(() => ({
  cardsWrapper: {
    padding: '3em 0',
  },

  expiredEvent: {
    color: '#FF6F3E',
  },

  root: {
    flexGrow: 1,
  },

  viewDetailsButton: {},
}))

const checkIsKioskModeAvailable = (startsAt, endsAt) => {
  const now = moment()

  return now.diff(moment(startsAt), 'days') < 2 && now.diff(moment(endsAt), 'days') < 2
}

export const getOriginalValue = row => row.original || row.subRows[0].original

const getIsExpanderRow = row => row.subRows.length > 1

const getIsExpired = row => {
  const { endsAt } = getOriginalValue(row)

  return getIsExpanderRow(row)
    ? row.subRows.every(subRow => Date.now() > new Date(subRow.original.endsAt))
    : Date.now() > new Date(endsAt)
}

export const getCellVisibility = visibility => {
  return capitalize(visibility === 'select-groups-only' ? 'groups only' : visibility)
}

const useKioskButtonStyles = makeStyles(() => ({
  button: {
    minWidth: 40,
    backgroundColor: '#BFDF57',

    '&:hover': {
      backgroundColor: '#96AF44',
    },
  },
}))

const LaunchKioskButton = props => {
  const classes = useKioskButtonStyles()
  const dispatch = useDispatch()

  const handleStartKiosk = useCallback(
    ev => {
      ev.stopPropagation()

      if (checkIsKioskModeAvailable(props.event.startsAt, props.event.endsAt)) {
        dispatch(startKiosk(props.event))
      }
    },
    [dispatch, props.event],
  )

  return checkIsKioskModeAvailable(props.event.startsAt, props.event.endsAt) ? (
    <Button className={classes.button} disableElevation variant="contained" onClick={handleStartKiosk}>
      <Icon path={mdiLaunch} size={1} fill="#FFFFFF" />
    </Button>
  ) : null
}

const ViewDetailsButton = props => {
  return (
    <Button
      style={{ minWidth: 40 }}
      color="secondary"
      disableElevation
      variant="contained"
      onClick={ev => {
        ev.stopPropagation()
        props.onClick()
      }}
    >
      <Icon path={mdiEye} size={1} fill="#FFFFFF" />
    </Button>
  )
}

const getSortType = (rowA, rowB, accessor) => createGroupedValueSort(row => getOriginalValue(row)[accessor])(rowA, rowB)

const getDateCellValue = (startsAt, endsAt) => {
  const isSameDate = moment(startsAt).isSame(endsAt, 'day')

  return isSameDate
    ? `${format.date(startsAt)} ${format.time(startsAt)} - ${format.time(endsAt)}`
    : `${format.date(startsAt)} - ${format.date(endsAt)}`
}

const useOpportunityColumns = onCreateOrUpdateOpportunity => {
  const classes = useStyles()

  const ExpiredCell = useMemo(() => {
    return props => <span className={classes.expiredEvent}>{props.children}</span>
  }, [classes.expiredEvent])

  return useMemo(() => {
    return [
      {
        Header: '',
        disableFilters: true,
        disableSortBy: true,
        accessor: 'opportunityId',
        Cell: ({ cell, row }) => {
          const shouldShowExpanderIcon = row.subRows.length > 1 && cell.isGrouped

          return shouldShowExpanderIcon ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ExpandLessIcon htmlColor="#000000" /> : <ExpandMoreIcon htmlColor="#000000" />}
            </span>
          ) : null
        },
        width: 60,
      },

      {
        id: 'type',
        Header: 'Type',
        disableFilters: true,
        disableSortBy: true,
        accessor: 'schedulable',
        width: 60,
        aggregate: 'count',
        Aggregated: ({ row, value }) => {
          const fill = '#5C8DE8FA'
          const { schedulable } = getOriginalValue(row)

          if (value > 1) {
            return <Icon path={mdiCalendarArrowRight} size={1} style={{ fill }} />
          }

          return schedulable ? (
            <Icon path={mdiCalendar} size={1} style={{ fill }} />
          ) : (
            <Icon path={mdiMapMarker} size={1} style={{ fill }} />
          )
        },
      },

      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        filter: 'dynamicText',
        aggregate: 'count',
        Aggregated: ({ row, value }) => {
          const { name } = getOriginalValue(row)

          return value > 1 ? `${name} (${value})` : name
        },
        width: 200,
        sortType: getSortType,
      },

      {
        id: 'dates',
        Header: 'Scheduled Times',
        disableFilters: true,
        accessor: row => ({ startsAt: row.startsAt, endsAt: row.endsAt }),
        aggregate: leafValues => {
          if (leafValues.length === 1) {
            const [{ startsAt, endsAt }] = leafValues

            return getDateCellValue(startsAt, endsAt)
          }

          const startsAt = Math.min(...leafValues.map(({ startsAt }) => Number(new Date(startsAt))))
          const endsAt = Math.max(...leafValues.map(({ endsAt }) => Number(new Date(endsAt))))

          return getDateCellValue(startsAt, endsAt)
        },
        Cell: ({ row, cell }) => {
          const { startsAt, endsAt } = cell.value
          const cellValue = getDateCellValue(startsAt, endsAt)

          return getIsExpired(row) ? <ExpiredCell>{cellValue}</ExpiredCell> : cellValue
        },
        Aggregated: ({ row, value }) => {
          return getIsExpired(row) ? <ExpiredCell>{value}</ExpiredCell> : value
        },
        sortType: createGroupedDateSort(row => getOriginalValue(row)['startsAt']),
        width: 200,
      },

      {
        id: 'location',
        Header: 'Location',
        filter: 'dynamicText',
        accessor: 'address',
        Aggregated: ({ row }) => {
          const { address } = getOriginalValue(row)

          return address
        },
        sortType: getSortType,
        width: 200,
      },

      {
        id: 'visibility',
        filter: 'includesSome',
        Header: 'Visibility',
        accessor: 'visibility',
        Cell: ({ cell }) => {
          return getCellVisibility(cell.value)
        },
        width: 115,
        sortType: getSortType,
        aggregate: leafValues => {
          const visibilityList = uniq(leafValues)

          return visibilityList.length > 1 ? 'Varied' : getCellVisibility(visibilityList[0])
        },
      },

      {
        id: 'hours',
        Header: 'Hours',
        filter: 'dynamicNumber',
        accessor: 'totalVolunteerHours',
        Cell: FixedPointCell,
        sortType: 'basic',
        aggregate: 'sum',
        width: 90,
      },

      {
        id: 'activities',
        Header: 'Activities',
        filter: 'dynamicNumber',
        accessor: 'totalCheckIns',
        Cell: props => {
          return <IntegerCell {...props} />
        },
        aggregate: 'sum',
        width: 90,
      },

      {
        id: 'actions',
        Header: 'Actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => {
          const originalValue = getOriginalValue(row)

          return getIsExpanderRow(row) ? null : (
            <Grid container justify="space-between">
              <Grid item xs={4}>
                <LaunchKioskButton event={originalValue} />
              </Grid>
              <Grid item xs={5}>
                <ViewDetailsButton row={row} onClick={() => onCreateOrUpdateOpportunity(getOriginalValue(row))} />
              </Grid>
            </Grid>
          )
        },
      },
    ]
  }, [onCreateOrUpdateOpportunity])
}

const getOperatorOptions = column => {
  switch (column) {
    case 'name':
    case 'location':
    case 'dates':
      return [
        { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
        { value: operatorTypes.EQUALS, displayValue: 'Equals' },
      ]

    case 'activities':
    case 'hours':
      return [
        { value: operatorTypes.LESS_THAN, displayValue: 'Less than' },
        { value: operatorTypes.LESS_OR_EQUAL, displayValue: 'Less than or equal to' },
        { value: operatorTypes.EQUALS, displayValue: 'Equal to' },
        { value: operatorTypes.GREATER_OR_EQUAL, displayValue: 'Greater than or equal to' },
        { value: operatorTypes.GREATER_THAN, displayValue: 'Greater than' },
      ]

    case 'visibility':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]

    default:
      return []
  }
}

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true,
  },
}

const groupByConfig = ['opportunityId']

function Opportunities() {
  const classes = useStyles()

  const [filtersModalVisible, setFiltersModalVisible] = useState(false)
  const [dateRange, setDateRange] = useDateRangeFilter()
  const [{ opportunities, loading }, { reload, setSelected }] = useOpportunitiesCollection(dateRange)
  const { setQuery, results: filteredOpportunities } = useTableSearch(opportunities, searchConfig)

  const reloadWithFilters = useCallback(() => {
    reload(dateRange)
  }, [dateRange, reload])

  const [filterRows, setFilterRows] = useState([])

  const onCreateOrUpdateOpportunity = useCreateOrUpdateOpportunityPrompt(reloadWithFilters)
  const columns = useOpportunityColumns(onCreateOrUpdateOpportunity)

  const anchorEl = useRef(null)

  const table = useTable(
    {
      columns,
      classes,
      data: filteredOpportunities,
      onSelectedChanged: setSelected,
      groupBy: groupByConfig,
      filterTypes: {
        [DYNAMIC_NUMBER]: DEFAULT_FILTERS[DYNAMIC_NUMBER],
        [DYNAMIC_TEXT]: DEFAULT_FILTERS[DYNAMIC_TEXT],
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
  )

  return (
    <div>
      <Grid container>
        <Grid container xs={12} item alignItems="center" justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Typography gutterBottom variant="h4">
              Opportunities
            </Typography>
            <RequireRole role={INTERNAL_ADMIN}>
              <Grid item style={{ marginLeft: 10 }}>
                <AddButton onClick={onCreateOrUpdateOpportunity} />
              </Grid>
            </RequireRole>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
            </Grid>
            <Grid item style={{ marginLeft: '10px' }}>
              <DateRangeFilter initialValues={dateRange} onFilterApplied={setDateRange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading || (opportunities || []).length ? (
        <>
          <div className={classes.root}>
            <Hidden only="sm">
              <OpportunitySummaryCards opportunities={filteredOpportunities} />
            </Hidden>
          </div>
          <div>
            <Box mb={2}>
              {/*<AddButton*/}
              {/*  disabled*/}
              {/*  onClick={() => {}}*/}
              {/*  icon={*/}
              {/*    <Icon*/}
              {/*      path={mdiBookmarkMultiple}*/}
              {/*      size={1}*/}
              {/*      fill="#000000"*/}
              {/*    />*/}
              {/*  }*/}
              {/*  title="REPORTS"*/}
              {/*/>*/}

              <Box ml={2} display="inline-block" ref={anchorEl}>
                <AddButton
                  onClick={() => setFiltersModalVisible(true)}
                  icon={<Icon path={mdiFilterMenu} size={1} fill="#000000" />}
                  title={
                    <Box>
                      <sup>{table.columns.filter(column => column.filterValue).length || ''}</sup>
                      <Box component="span" pl={1}>
                        FILTERS
                      </Box>
                    </Box>
                  }
                />
              </Box>
            </Box>

            <Popover
              keepMounted
              BackdropProps={{}}
              open={filtersModalVisible}
              anchorEl={anchorEl.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: -20,
              }}
            >
              <FiltersModal
                columns={columns}
                table={table}
                setFilterRows={setFilterRows}
                filterRows={filterRows}
                getOperatorOptions={getOperatorOptions}
                onClose={() => setFiltersModalVisible(false)}
              />
            </Popover>

            <PagedTable {...table} groupBy={groupByConfig} />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default Opportunities
