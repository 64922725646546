import { withStyles } from '@material-ui/core/styles'
import { DatePicker, DateTimePicker, KeyboardDatePicker } from 'formik-material-ui-pickers'

export const StyledDateTimePicker = withStyles({
  root: ({ smaller }: { smaller?: boolean }) => ({
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3d3d3d',
    width: '100%',
    '& label': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',
    },
    '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      lineHeight: '20px',
      minHeight: smaller ? '41px' : '45px',
      '& input.MuiOutlinedInput-input': {
        padding: smaller ? '8.5px 10px' : '14px',
      },
      '& fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid',
      },
    },
  }),
})(DateTimePicker)

export const StyledDatePicker = withStyles({
  root: ({ smaller }: { smaller?: boolean }) => ({
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#3d3d3d',
    width: '100%',
    '& label': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',
    },
    '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      lineHeight: '20px',
      minHeight: smaller ? '41px' : '45px',
      '& input.MuiOutlinedInput-input': {
        padding: smaller ? '8.5px 10px' : '14px',
      },
      '& fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid',
      },
    },
  }),
})(DatePicker)

export const StyledKeyboardDatePicker = withStyles({
  root: ({ smaller, iconColor }: { smaller?: boolean; iconColor?: string }) => ({
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.5px',
    color: '#3d3d3d',
    width: '100%',
    '& label': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',
    },
    '& label.MuiInputLabel-outlined:not(.Mui-focused):not(.MuiFormLabel-filled)': {
      transform: 'translate(14px, 14px) scale(1)',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '0.5px',

      minHeight: smaller ? '41px' : '45px',
      '& input.MuiOutlinedInput-input': {
        padding: smaller ? '8.5px 10px' : '14px',
      },
      '& fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&:hover fieldset': {
        borderColor: 'rgb(175, 188, 213)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
        border: '1px solid',
      },
    },
    ...(iconColor && {
      '& .MuiInputAdornment-root': {
        '& .MuiIconButton-label': {
          color: iconColor,
        },
      },
    }),
  }),
})(KeyboardDatePicker)
