import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import map from 'lodash/map'
import { Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import format from 'civic-champs-shared/utils/format'

import { NumberCell } from 'core/table/cells'
import DEFAULT_FILTERS from 'core/table/filters'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks'
import {
  ARRAY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'

import Loading from 'components/Loading'
import { Tag } from '../types'
import { useMentorshipMatchesColumns as useColumns, useMentorshipMatchesSummary } from '../hooks'

import PageHeader from 'App/components/PageHeader'
import MENTORSHIP_FILTERS from 'new-mentorship/helpers/filters'
import { useMentorshipMatchesCollection } from '../hooks/useMentorshipMatchesCollection'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'tags':
      return ARRAY_OPERATOR_OPTIONS
    case 'meetingCount':
    case 'momentCount':
    case 'averageMeetingDuration':
      return NUMBER_OPERATOR_OPTIONS
    case 'programName':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Includes' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Does not include' },
      ]
    case 'mentorName':
    case 'menteeName':
      return STRING_OPERATOR_OPTIONS
    default:
      return []
  }
}

const cards = [
  {
    header: 'Matches',
    accessor: 'matchesCount',
    Cell: NumberCell,
  },
  {
    header: 'Meetings',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    header: 'Avg. Meeting Duration',
    accessor: 'averageMeetingTime',
    Cell: NumberCell,
  },
  {
    header: 'Positive Experiences',
    accessor: 'positiveFeedbackPercent',
  },
]

const csvHeaders = [
  { label: 'Program', key: 'programName' },
  { label: 'Mentor', key: 'mentorName' },
  { label: 'Mentee', key: 'menteeName' },
  { label: 'Matched Since', key: 'startedAt' },
  { label: '# Meetings', key: 'meetingCount' },
  { label: '# Moments', key: 'momentCount' },
  { label: 'Avg. Meeting Time', key: 'averageMeetingDuration' },
  { label: 'Tags', key: 'tags' },
]

const tableName = 'mentorshipMatches'

const NewMentorshipMatchesComponent = ({ location, history }: RouteComponentProps) => {
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [{ matches, loading }, { addMatch, editMatch }, eventListeners] = useMentorshipMatchesCollection()
  const data = useMemo(
    () =>
      map(matches, match => ({
        ...match,
        mentorName: format.name(match?.mentor),
        menteeName: format.name(match?.mentee),
        programName: match?.program?.name,
      })),
    [matches],
  )
  const skipReset = React.useRef<boolean>()

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const columns = useColumns({ editMatch })

  const table = useTable(
    {
      data,
      columns,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...MENTORSHIP_FILTERS },
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
      },
      autoResetPage: !skipReset.current,
      autoResetSortBy: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const summary = useMentorshipMatchesSummary(table.rows)

  const exportedData = useMemo(
    () =>
      table.selectedFlatRows.map(({ values }: any) => ({
        ...values,
        tags: values.tags.map(({ name }: Tag) => name).join(','),
      })),
    [table.selectedFlatRows],
  )

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Matches"
        filterSubHeader="Select match attributes to filter by:"
        buttons={
          <>
            <ExportButton data={exportedData} headers={csvHeaders} filename={'mentorship-matches.csv'} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addMatch}>
            Add Match
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
        skipReset={skipReset}
        eventListeners={eventListeners}
      />
    </Grid>
  )
}

export const NewMentorshipMatches = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Mentor Matches" subTitle="Create and manage mentor and mentee matches">
      <NewMentorshipMatchesComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipMatches
