import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

interface PausedButtonProps {
  onClick: () => void
  classes: any
  disabled: boolean
  loading: boolean
  paused: boolean
}

export default function SalsaPauseButton(props: PausedButtonProps) {
  const { onClick, classes, disabled, loading, paused } = props
  return (
    <Button onClick={onClick} className={classes.yellowButton} disabled={disabled}>
      {loading && <CircularProgress size={24} color="primary" className={classes.buttonProgress} />}
      {paused ? 'Reconnect' : 'Pause'}
    </Button>
  )
}
