import {
    CURRENT_FILE
  } from '../actions/currentFile';

  export const defaultState = {
    currentFile: null
  };

  export default function config(state = defaultState, action) {
    switch (action.type) {
      case CURRENT_FILE:
        return {
          ...state,
          currentFile: action.payload.currentFile
        };

      default:
        return state;
    }
  }
