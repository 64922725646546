import React, { useCallback, useState } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import useExportStyles from 'export/useExportStyles'
import { NotificationBlastMessage, NotificationBlastMessageStatus } from 'messages/types'
import { Row } from 'react-table'

const filename = 'message-overview.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email/Phone', key: 'contact' },
  { label: 'Status', key: 'status' },
]

const formatData = (messages: Row<NotificationBlastMessage>[]) => {
  return messages.map(({ original: { status, recipientContact, recipient } }) => {
    let textStatus
    switch (status) {
      case NotificationBlastMessageStatus.SENDING:
        textStatus = 'Loading'
        break
      case NotificationBlastMessageStatus.SENT:
        textStatus = 'Sent'
        break
      case NotificationBlastMessageStatus.FAILED:
        textStatus = 'Failure'
        break
    }

    return {
      name: recipient?.givenName + ' ' + recipient?.familyName,
      firstName: recipient?.givenName,
      lastName: recipient?.familyName,
      contact: recipientContact,
      status: textStatus,
    }
  })
}
const ExportMessageOverviewButton = ({
  data,
  disabled,
}: {
  data: Row<NotificationBlastMessage>[]
  disabled?: boolean
}) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin onClick={createFormattedData}>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        onClick={createFormattedData}
        className={classes.fill}
        filename={fileNameWithDate as string}
        headers={headers}
        data={formattedData}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportMessageOverviewButton
