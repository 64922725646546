import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export const useCredentialType = (credentialTypeID, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Credential Type.  Please refresh the page and try again',
  )
  const refresh = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/identification-types/${credentialTypeID}`,
    })
  }, [request])
  useEffect(() => {
    refresh()
  }, [refresh])

  return {
    credential: result,
    loading: loading || !called,
    refresh,
  }
}
