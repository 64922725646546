import { useCallback, useEffect } from 'react'
import { useRemoteCollection, useRemoteCollectionPointer } from 'civic-champs-shared/api/hooks'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import { useFetchQuestionSets } from 'civic-champs-shared/question-sets/hooks'
import DuplicateQuestionSetPrompt from 'civic-champs-shared/question-sets/components/DuplicateQuestionSetPrompt'

const useDuplicateQuestionnairePrompt = eagerAdd => {
  const duplicateQuestionSetPrompt = usePrompt(DuplicateQuestionSetPrompt)

  useEffect(() => {
    const unlisten = duplicateQuestionSetPrompt.onComplete(eagerAdd)
    return unlisten
  }, [duplicateQuestionSetPrompt, eagerAdd])

  return useCallback(questionSet => duplicateQuestionSetPrompt.show({ questionSet }), [duplicateQuestionSetPrompt])
}

export default function useQuestionSetsManagement() {
  const [questionSets, operations, events] = useRemoteCollection()
  const [selected, setSelected] = useRemoteCollectionPointer(events)
  const { syncCollection } = operations
  const [refresh, { loading, called }] = useFetchQuestionSets()

  useEffect(() => {
    refresh({ onResultUpdate: syncCollection })
  }, [refresh, syncCollection])

  const duplicateQuestionSet = useDuplicateQuestionnairePrompt(operations.eagerAdd)

  return [
    {
      questionSets,
      loading: loading || !called,
      selected,
    },
    {
      refresh,
      setSelected,
      duplicateQuestionSet,
    },
  ]
}
