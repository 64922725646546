import React, { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'

export default function useOrganizationStripeId() {
  const [request, { loading, error, result, called }] = useFetch()
  const { id } = useCurrentOrg()
  const getStripeId = useCallback(() => {
    return request({ method: 'get', url: `/organizations/${id}/stripe-customer-ids` })
  }, [request, id])

  useEffect(() => {
    getStripeId()
  }, [getStripeId])

  return useMemo(
    () => ({
      stripeId: result,
      error,
      loading: loading || !called,
      refresh: getStripeId
    }),
    [result, error, loading, called, getStripeId],
  )
}
