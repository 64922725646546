import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'


const useMessagesPreview = (recipientPersonIds) => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching messages preview')
  const organization = useCurrentOrg()
  const getMessagePreview = useCallback(async () => {
    return request({
      method: 'post',
      url: `/organizations/${organization.id}/notification-blast-preview`,
      config: {
        body: { recipientPersonIds }
      }
    })
  }, [request, organization.id, recipientPersonIds])

  useEffect(() => {
    getMessagePreview()
  }, [getMessagePreview])

  return useMemo(
    () => ({ result: result || null, loading: loading || !called, called, error }),
    [result, loading, called, error],
  )
}

export default useMessagesPreview
