import React, { useCallback } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { yup } from 'civic-champs-shared/api/utils'
import { Field, Form, Formik } from 'formik'
import Button from '@material-ui/core/Button'
import isNull from 'lodash/isNull'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import StyledOutlinedTextField from 'civic-champs-shared/formik/legacy-components/StyledOutlinedTextField'
import StyledOutlinedPhoneField from 'civic-champs-shared/formik/components/StyledOutlinedPhoneField'
import { DatePicker } from 'formik-material-ui-pickers'
import SwitchToggle from 'civic-champs-shared/formik/components/SwitchToggle'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: '0',
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
    },
    inputFieldOutlined: {},
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontFamily: '"Josefin Sans", sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '36px',
      lineHeight: '22px',
      color: '#090909',
      textAlign: 'center',
      marginBottom: '23px',
      width: '90%',
    },
    blueButton: {
      color: '#ffffff',
      backgroundColor: '#5C8DE8',
      border: '1px solid transparent',
    },
    datePicker: {
      '& label.Mui-focused': {
        color: '#3d3d3d',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'rgba(0, 0, 0, 0.87)',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
  }),
)
const validationSchema = yup.object({
  givenName: yup.string().trim().required(),
  familyName: yup.string().trim().required(),
  email: yup
    .string()
    .trim()
    .email('Enter a valid email')
    .when('phone', (phone: string, schema: any) =>
      isNull(phone) ? schema.required('Email or mobile is required') : schema,
    )
    .nullable(),
  phone: yup
    .string()
    // @ts-ignore
    .phoneNumber()
    .trim()
    .nullable(),
  home: yup
    .string()
    // @ts-ignore
    .phoneNumber()
    .trim()
    .nullable(),
})

export default function AddUserPrompt(props: any) {
  const {
    open = false,
    handleClose,
    label,
    onCreate,
    initialValues = {
      givenName: '',
      familyName: '',
      email: null,
      phone: null,
      home: null,
      birthday: null,
      sendInvite: true,
    },
  } = props

  const handleFormikSubmit = useCallback(
    (
      { givenName, familyName, email, phone: phoneNumber, home: homePhoneNumber, birthday, sendInvite },
      { setSubmitting },
    ) => {
      const userData = {
        givenName,
        familyName,
        email,
        phoneNumber,
        homePhoneNumber,
        birthday: birthday?.format('YYYY-MM-DD') || '',
        sendInvite,
      }

      onCreate(userData)
      setSubmitting(false)
    },
    [onCreate],
  )
  const classes = useStyles()
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <div className={classes.titleWrapper}>
          <h2 className={classes.title}>{label}</h2>
          <IconButton onClick={handleClose}>
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormikSubmit}>
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Field
                component={StyledOutlinedTextField}
                fullWidth
                id="givenName"
                name="givenName"
                label="First Name"
                required
              />
              <Field
                component={StyledOutlinedTextField}
                fullWidth
                id="familyName"
                name="familyName"
                label="Last Name"
                required
              />
              <Field component={StyledOutlinedTextField} fullWidth name="email" label="email" required />
              <Field component={StyledOutlinedPhoneField} fullWidth name="phone" label="Mobile Phone" />
              <Field component={StyledOutlinedPhoneField} fullWidth name="home" label="Home Phone" />
              <Field
                component={DatePicker}
                autoOk
                clearable
                disableFuture
                variant="inline"
                format="MM/DD/YYYY"
                margin="normal"
                label="Birth Date"
                name="birthday"
                // @ts-ignore
                classes={{ root: classes.datePicker }}
              />
              <Field component={SwitchToggle} name="sendInvite" />
              <Button color="secondary" variant="contained" fullWidth disabled={isSubmitting} onClick={submitForm}>
                Submit
              </Button>
            </Form>
          )}
        </Formik>
        <AddUserPrompt />
      </div>
    </Modal>
  )
}
