import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export default function useOpenAccountLinkInNewTab() {
  const [request, { loading, error }] = useFetch(
    'Error getting the link for editing your Stripe account profile.  Please refresh the page to try again',
  )
  const org = useCurrentOrg()
  const openAccountLinkInNewTab = useCallback(() => {
    return request({
      method: 'get',
      url: `/stripe/organizations/${org.id}/account-link`,
      onSuccess: result => {
        const editLink = document.createElement('a')
        editLink.setAttribute('rel', 'noopener')
        editLink.setAttribute('target', '_blank')
        editLink.setAttribute('href', result.url)
        editLink.click()
      },
    })
  }, [request])

  return [
    openAccountLinkInNewTab,
    {
      loading,
      error,
    },
  ]
}
