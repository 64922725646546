import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useRemoveTrip } from './'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import _ from 'lodash'

const useRemoveTripPrompt = (onRemoved: Function) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const [removeTrip, status] = useRemoveTrip()

  return async (tripId: number) => {
    const confirmed = await showPrompt({
      text: 'Deleting trip requests will also delete all associated trip orders, as well as assigned drivers. This action cannot be undone.',
      title: 'Are you sure you want to delete this Trip Request?',
      confirmText: 'Delete Trip Request',
      cancelText: 'Do Not Delete',
      type: 'warning',
    })
    if (confirmed) {
      await removeTrip(tripId)
      if (_.isFunction(onRemoved)) {
        onRemoved()
      }
    }
  }
}

export default useRemoveTripPrompt
