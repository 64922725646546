export const RUN_QUERY = 'api.run-query';
export const RUN_QUERY_REQUEST = 'api.run-query.request';
export const RUN_QUERY_SUCCEEDED = 'api.run-query.succeeded';
export const RUN_QUERY_FAILED = 'api.run-query.failed';

export const runQuery = (queryConfig) => ({
    type: RUN_QUERY,
    payload: { queryConfig }
});

export const startRequest = (queryConfig) => ({
    type: RUN_QUERY_REQUEST,
    payload: { queryConfig }
});

export const querySucceeded = (queryConfig, result) => ({
    type: RUN_QUERY_SUCCEEDED,
    payload: { queryConfig, result }
});

export const queryFailed = (queryConfig, error) => ({
    type: RUN_QUERY_FAILED,
    payload: { queryConfig, error }
});