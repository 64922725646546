import { uniqueId } from 'lodash'
import React, { useLayoutEffect, useState, MutableRefObject } from 'react'
import { makeStyles, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, Typography } from '@material-ui/core'

import {
  OpportunityVisibility,
  getVisibilityDescription,
  getVisibilityLabel,
} from 'Event/interfaces/index'
import { useFeatureEnabled } from '../../../core/feature/hooks'

//TODO refactor into utility
const useHtmlId = (prefix = '') => useState(() => uniqueId(prefix))[0]

//TODO this seems like it should be done with a ref function instead of a ref!

const useElemWidth = <E extends HTMLElement>(): [MutableRefObject<E | null>, number] => {
  const ref = React.useRef<E | null>(null)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    //@ts-ignore
    setWidth(ref.current.offsetWidth)
  }, [])

  return [ref, width]
}

const useClasses = makeStyles({
  root: {
    width: '100%',
  },
  formControl: {
    width: '50%',
    maxWidth: 220,
  },
  description: {
    margin: '12px 20px 0 12px',
  },
})

const VisibilitySelect = (props: { visibility: OpportunityVisibility; onChange: any; allowPrivate: boolean }) => {
  const id = useHtmlId('opportunity-visibility-')
  const [labelRef, labelWidth] = useElemWidth<HTMLLabelElement>()
  const isGroupEnabled = useFeatureEnabled('Groups')

  const classes = useClasses()

  return (
    <Box className={classes.root}>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel ref={labelRef} htmlFor={id}>
          Visibility
        </InputLabel>
        <Select
          value={props.visibility}
          onChange={e => props.onChange(e.target.value)}
          input={<OutlinedInput labelWidth={labelWidth} id={id} name="visibility" />}
        >
          {Object.values(OpportunityVisibility)
            .filter(value => props.allowPrivate || value !== OpportunityVisibility.PRIVATE)
            .filter(value => isGroupEnabled || value !== OpportunityVisibility.SELECT_GROUPS_ONLY)
            .map(value => (
              <MenuItem key={value} value={value}>
                {getVisibilityLabel(value)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Typography className={classes.description}>{getVisibilityDescription(props.visibility)}</Typography>
    </Box>
  )
}

export default VisibilitySelect
