import React, { useMemo } from 'react'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { DateCell, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { CellProps } from 'react-table'
import { ChatMock } from 'chats/interfaces'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '36px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '191px',
      '&>a>div': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
  applicantsColumn: {
    '&>div': {
      width: '70px',
      paddingRight: '30px',
    },
  },
  membersColumn: {
    '&>div': {
      width: '61px',
      paddingRight: '30px',
    },
  },
  approvalTypeColumn: {
    '&>div': {
      width: '141px',
    },
  },
  createdColumn: {
    '&>div': {
      width: '84px',
      paddingRight: '30px',
    },
  },
}))

const useGroupOverviewColumns = () => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const classes = useStyles()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            Header: 'ID',
            accessor: 'id',
            id: 'id',
            requires: 'isInternalAdmin',
            disableGlobalFilter: true,
            className: classes.selectColumn,
          },
          {
            Header: 'Group Name',
            accessor: 'name',
            id: 'name',
            Cell: (props: CellProps<ChatMock, string>) =>
              WithLinkCell(
                NullableTextCell,
                (id: string) => `/groups/${id}`,
              )({ ...props, linkProps: { className: classes.link } }),
            className: classes.nameColumn,
          },
          {
            Header: 'Applicants',
            accessor: 'pendingMemberCount',
            id: 'pendingMemberCount',
            Cell: NumberCell,
            disableGlobalFilter: true,
            className: classes.applicantsColumn,
          },
          {
            Header: 'Members',
            accessor: 'memberCount',
            id: 'memberCount',
            Cell: NumberCell,
            disableGlobalFilter: true,
            className: classes.membersColumn,
          },
          {
            id: 'approvalType',
            Header: 'Approval Type',
            disableGlobalFilter: true,
            accessor: (group: Group) => (group.closed ? 'Limited' : 'Open'),
            className: classes.approvalTypeColumn,
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            id: 'createdAt',
            Cell: DateCell,
            disableGlobalFilter: true,
            className: classes.createdColumn,
          },
        ] as any[],
        {
          isInternalAdmin,
        },
      ),
    [classes, isInternalAdmin],
  )
}

export default useGroupOverviewColumns
