import { get } from 'lodash'
import React, { Component } from 'react'
import Modal from 'react-modal'
import ParsedRows from './parsed-rows'
import classNames from 'classnames'

import Button from '../../Mentorship/kit/components/button'
import BaseService from '../../Mentorship/services/base.service'
import { Loader } from '../../Mentorship/kit/components/loader'
import ENDPOINT_ROUTES from '../../Mentorship/constants/APIs'
import { MatchFilters } from '../../Mentorship/kit/modules/mentor-ship/matches'
import { withCurrentOrganizationHOC } from 'Event/helpers/withCurrentOrganizationHOC'
import moment from 'moment'
import Loading from '../Loading'

import './index.scss'

interface UploadItem {
  id: number
  total: number
  createdAt: string | Date
}
interface UploadsHistory {
  data: UploadItem[]
  total: number 
}

interface CurrentOrganization {
  id: number,
  name: string
}

enum UploadsHistoryReferences {
  MATCH = 'match',
  VOLUNTEER = 'volunteer'
}

interface IState {
  uploadingError?: object | null
  isLoading?: boolean
  rows?: [] | undefined
  uploads: UploadsHistory | null
}

interface IProps {
  currentOrganization?: CurrentOrganization
  close: () => void
  getMatches: (params: MatchFilters) => void
  reference: UploadsHistoryReferences
  downloadLink: string
  confirmParsedRows: (orgId: number, newRows: any[], sendInvite?: boolean) => Promise<any>
  columns: any[]
}

class UploadCsvPopup extends Component<IProps, IState> {
  fileInput: React.RefObject<HTMLInputElement>
  private request: BaseService
  private friendlyReference: string

  constructor(props: any) {
    super(props)
    this.fileInput = React.createRef()
    this.state = {
      uploadingError: null,
      isLoading: false,
      uploads: null
    }
    this.request = new BaseService()
    this.friendlyReference = props.reference === UploadsHistoryReferences.VOLUNTEER ? 'Volunteers' : 'Matches'
  }

  async componentDidMount() {
    const uploads = await this.request.getJSON(ENDPOINT_ROUTES.Uploads.uploadsList(this.props.currentOrganization!.id, this.props.reference))
    this.setState({ uploads })
  }

  openFileDialog = () => {
    this.fileInput.current && this.fileInput.current.click()
  }

  clearError = () => {
    this.setState({ uploadingError: null })
  }

  uploadFile = async ({target}: React.BaseSyntheticEvent) => {
    this.setState({
      isLoading: true,
      uploadingError: null
    })
    const {0: file} = target.files
    const formData = new FormData()
    formData.append('file', file)

    try {
      const orgId = get(this.props.currentOrganization, 'id') as number;
      const rows = await this.request.postFile(ENDPOINT_ROUTES.Uploads.uploadFile(orgId, this.props.reference), formData)
      this.setState({ rows })
    } catch (err) {
      this.setState({
        uploadingError: err.message
      })
    } finally {
      this.setState({
        isLoading: false
      })
    }

  }

  closePopup = () => {
    this.props.close()
  }

  downloadHistoryItem = async (id: number) => {
    const link = await this.request.getJSON(ENDPOINT_ROUTES.Uploads.downloadHistoryItem(this.props.currentOrganization!.id, id))
    window.open(link.link, '_self')
  }

  render() {
    const modalStyles = {
      content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0 auto',
        padding: '40px 60px 0 0',
        transform : 'translate(-50%, -50%)',
        border: 'none',
        backgroundColor: 'transparent'
      },
      overlay: {
        background: 'rgba(23, 66, 147, 0.8)',
        ...(this.state.rows && {zIndex: 9999})
      }
    }

    return (
      <Modal
        ariaHideApp={false}
        isOpen={true}
        style={modalStyles}
      >
        <div className={classNames('upload-csv-popup__container', this.state.rows && 'upload-csv-popup__container--wide')}>
          <span onClick={this.closePopup} className="upload-csv-popup__close" />
          <div className="upload-csv-popup__header">Add {this.friendlyReference}</div>
          {this.state.isLoading && <Loader />}
          {
            this.state.uploadingError &&
            (
              <>
                <div className="upload-csv-popup__errors">
                  <div className="upload-csv-popup__errors-title">Errors</div>
                  <div className="upload-csv-popup__error">{this.state.uploadingError}</div>
                </div>
                <div className="upload-csv-popup__try-again">
                  <Button onClick={this.clearError} className="upload-csv-popup__try-again-btn">
                    Try Again
                  </Button>
                </div>
              </>
            )
          }
          {
            !this.state.rows && !this.state.uploadingError &&
            (
              <>
                <a
                  href={this.props.downloadLink}
                  className="upload-csv-popup__download-template"
                >
                  Download Template
                </a>
                <div className="upload-csv-popup__upload-csv-btn-wrap">
                  <Button onClick={this.openFileDialog} className="upload-csv-popup__upload-csv-btn">Upload CSV</Button>
                </div>
                <input onChange={this.uploadFile} ref={this.fileInput} className="upload-csv-popup__file" type="file" name="file"/>
                { !this.state.uploads && <div className="upload-csv-popup__uploads-history-loading"><Loading size={30} /></div> }
                {
                  this.state.uploads && this.state.uploads.data?.length > 0 && (
                    <div className="upload-csv-popup__uploads-history">
                      <div className="upload-csv-popup__uploads-history-title">History</div>
                      <ul className={classNames('upload-csv-popup__uploads-history-list', this.state.uploads.data?.length > 3 && 'scrollable')}>
                        {
                          this.state.uploads.data.map(({id, total, createdAt}: UploadItem) => (
                            <li key={id} className="upload-csv-popup__uploads-history-item">
                              <div className="upload-csv-popup__uploads-history-date">{moment(createdAt).format('MM/DD/YYYY')}</div>
                              <div className="upload-csv-popup__uploads-history-matches">{total} {this.friendlyReference}</div>
                              <span onClick={() => this.downloadHistoryItem(id)} className="upload-csv-popup__uploads-history-download" />
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  )
                }
              </>
            )
          }
          {
            this.state.rows &&
            (
              <>
                <ParsedRows
                  getResults={this.props.getMatches}
                  cancel={this.closePopup}
                  rows={this.state.rows}
                  columns={this.props.columns}
                  confirmParsedRows={this.props.confirmParsedRows}
                  friendlyReference={this.friendlyReference}
                />
              </>
            )
          }
        </div>
      </Modal>
    )
  }
}

export default withCurrentOrganizationHOC(UploadCsvPopup)
