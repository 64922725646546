import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { FieldProps, getIn } from 'formik'
import Select from 'react-select'

import { CustomBaseSelectStyles } from '../../core/select/custom-styles'

export interface SelectFieldProps<V = any, FormValues = any> extends FieldProps<V, FormValues> {
  options: any[]
  disabled?: boolean
  className?: string
  placeholder?: string
}

// TODO for now this is just being used in the QuestionItemEditor...
//     so I'm re-using the SelectComponent that was used in question-item
export const SelectField = <V, FormValues>({ field, form, ...props }: SelectFieldProps<V, FormValues>) => {
  const { className, disabled, options, placeholder } = props
  const { value, onBlur, name } = field

  const errorMessage = getIn(form.errors, name)
  const hasError = !!errorMessage

  const classes = classNames('custom-select-component__select', {
    'custom-select-component__select--error': hasError,
    'custom-select-component__select--disabled': disabled,
    [`${className}`]: className,
  })

  const internalValue = useMemo(() => options.find(item => item.value === value), [options, value])
  const handleChange = useCallback(
    item => {
      form.setFieldValue(name, item.value)
    },
    [form, name],
  )

  return (
    <div className="custom-select-component">
      <Select
        hasError={hasError}
        isDisabled={disabled}
        className={classes}
        placeholder={placeholder}
        value={internalValue}
        onChange={handleChange}
        styles={CustomBaseSelectStyles}
        options={options}
        onBlur={onBlur}
      />
      {hasError && <div className="custom-select-component__error-msg">{errorMessage}</div>}
    </div>
  )
}

export default SelectField
