import map from 'lodash/map'
import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Column,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import Grid from '@material-ui/core/Grid'
import { Add as AddIcon, RemoveCircleOutlineOutlined } from '@material-ui/icons'

import Loading from 'civic-champs-shared/core/Loading'
import { ContainedButton, TableMenuButton } from 'civic-champs-shared'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

import PageHeader from 'App/components/PageHeader'
import { Chat } from 'chats/interfaces'
import useChatsColumns, { useStyles as useColumnStyles } from 'chats/hooks/useChatsColumns'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import { ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import { NUMBER_OPERATOR_OPTIONS, STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import ExportChatsButton from './ExportChatsButton'
import { useHasRole } from 'auth/hooks'
import useChatsCollection from '../hooks/useChatsCollection'

export const Chats = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Chats" subTitle="Send messages within the mentoring works app from here">
      <ChatsComponent {...props} />
    </PageHeader>
  )
}

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'name':
      return STRING_OPERATOR_OPTIONS
    case 'membersCount':
      return NUMBER_OPERATOR_OPTIONS
    default:
      return []
  }
}
const tableName = 'chats'
export const ChatsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ chats, loading, initiallyLoaded }, { addChat, deleteChats }, eventListeners] = useChatsCollection()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  const onDelete = useCallback((chat: any) => deleteChats([chat]), [deleteChats])

  const columns = useChatsColumns({ onDelete })
  const columnClasses = useColumnStyles()
  const superAdmin = useHasRole(SUPER_ADMIN)

  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
        // @ts-ignore
        filters,
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: chats,
      columns: columns as Column<Chat>[],
      autoResetPage: !skipReset.current,
      autoResetSortBy: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnClasses.selectColumn),
  )

  if (!initiallyLoaded || loading || columnStateLoading) {
    return <Loading />
  }

  // @ts-ignore
  const selectedRows = table.selectedFlatRows

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Chats"
        filterSubHeader="Select chat attributes to filter by:"
        buttons={
          <>
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlined />}
              onClick={() => deleteChats(map(selectedRows, 'original'))}
              disabled={!selectedRows.length}
              rightMargin
            >
              Remove
            </TableMenuButton>
            {superAdmin && <ExportChatsButton data={selectedRows} disabled={!selectedRows.length} />}
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addChat}>
            New Chat
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export default Chats
