import {
  OpportunityResponse,
  OpportunityResponseWithRecurrenceInfo,
  OpportunityStatus,
} from 'Event/interfaces/interfaceCreateEditEvent'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { useHistory } from 'react-router'
import { useDateStrings } from 'Event/scenes/events/hooks/useDateStrings'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { Edit as EditIcon } from '@material-ui/icons'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { EventVisibility } from 'Event/components/EventVisibility'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import TabletMacIcon from '@material-ui/icons/TabletMac'
import { useDispatch } from 'react-redux'
import { startKiosk } from 'kiosk/actions'
import { RECURRING_TYPE } from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import useCancelEventPrompt from 'Event/modules/events/details/header/useCancelEventPrompt'
import useCancelOpportunities from 'Event/hooks/useCancelOpportunities'
import { CancelType } from 'Event/modules/events/details/header'
import { Summary, SummaryTable } from 'Event/components/SummaryTable'
import { useShowEventAddEditPrompt } from 'Event/hooks/useShowEventAddEditPrompt'
import { linkify } from 'civic-champs-shared/utils/linkify'
import { useGetEventGroups } from 'Event/hooks/useGetEventGroups'
import { OpportunityVisibility } from 'Event/interfaces'
import Loader from 'Event/components/loader'
import { useFeatureEnabled } from '../../core/feature/hooks'
import { encodeOccurrenceFromEvent } from '../helpers/encodeOccurrence'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'
import { createMarkup } from '../../civic-champs-shared/core/utils/helpers'
import { useShowAddEditOfferPrompt } from '../hooks/useShowAddEditOfferPrompt'
import useAddEditLocationPrompt from '../../locations/hooks/useAddEditLocationPrompt'
import { useFetchOpportunityLocation } from '../../locations/hooks/useFetchOpportunityLocation'
import moment from 'moment-timezone'

const SectionHeader = ({ title, onEdit }: { title: string; onEdit: () => void }) => {
  const classes = useEventDrilldownStyles()

  return (
    <div className={classes.sectionHeader}>
      <div className={classes.sectionTitle}>{title}</div>
      <EditIcon className={classes.editIcon} onClick={onEdit} />
    </div>
  )
}

export const EventsDrilldownInfo = ({
  event,
  encodedOccurrence,
  scrollTop,
  refresh,
  summary,
  type = 'Event',
}: {
  event: OpportunityResponseWithRecurrenceInfo
  encodedOccurrence: string
  scrollTop: number
  refresh?: () => void
  summary?: Summary
  type?: string
}) => {
  const classes = useEventDrilldownStyles()
  const [getEventGroups, { loading: eventGroupsLoading, result: groups, called: groupsCalled }] = useGetEventGroups()
  const needsGroups = event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY
  useEffect(() => {
    if (needsGroups) {
      getEventGroups(event.id)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { schedulable } = event
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const isNewEditEnabled = useFeatureEnabled('NewEditEventUICalendarEvent')
  const isEditMultishiftFallbackEnabledEnabled = useFeatureEnabled('NewAddEventUILegacyFallback')
  const history = useHistory()
  const currentOrg = useCurrentOrg()
  const { instanceStartString, timeRange, recurrencePattern, recurrenceStart, recurrenceEnd } = useDateStrings({
    event,
  })
  const onSuccess = useCallback(
    (event: OpportunityResponse) => {
      const url = event.schedulable
        ? `/events/${encodeOccurrenceFromEvent(event)}/${currentOrg.id}`
        : `/opportunities/${encodeOccurrenceFromEvent(event)}`
      if (history.location.pathname === url) {
        refresh?.()
      } else {
        history.push(url)
      }
    },
    [currentOrg.id, history, refresh],
  )

  const showOpportunityPrompt = useShowEventAddEditPrompt(onSuccess)
  const showOfferPrompt = useShowAddEditOfferPrompt(onSuccess)
  const showLocationEditPrompt = useAddEditLocationPrompt()
  const { fetchLocation } = useFetchOpportunityLocation()
  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const handleStartKiosk = useCallback(() => {
    if (event.isOffer) return
    dispatch(startKiosk(event))
  }, [event, dispatch])
  const cancelOpportunities = useCancelOpportunities()
  const handleCancelEvent = useCallback(
    async ({ cancelType }: { cancelType: CancelType }) => {
      await cancelOpportunities([encodedOccurrence], cancelType)
      history.push('/list-of-events')
    },
    [cancelOpportunities, encodedOccurrence, history],
  )

  const onEdit = useCallback(async () => {
    if (event.isOffer) {
      showOfferPrompt({ offer: event, visibilityGroups: groups, encodedOccurrence })
    } else if (
      event.schedulable &&
      (!isNewEditEnabled ||
        (isEditMultishiftFallbackEnabledEnabled && event.timeshifts?.length && event.timeshifts?.length > 1))
    ) {
      history.push(`/events/${encodedOccurrence}/edit-event/basic`)
    } else if (!event.schedulable && offersEnabled) {
      onSuccess((await showLocationEditPrompt(await fetchLocation(event.locationId), true)) as OpportunityResponse)
    } else {
      showOpportunityPrompt(event)
    }
  }, [
    encodedOccurrence,
    event,
    fetchLocation,
    groups,
    history,
    isEditMultishiftFallbackEnabledEnabled,
    isNewEditEnabled,
    offersEnabled,
    onSuccess,
    showLocationEditPrompt,
    showOfferPrompt,
    showOpportunityPrompt,
  ])

  const showCancelEventPrompt = useCancelEventPrompt(handleCancelEvent)
  const onCancel = useCallback(() => showCancelEventPrompt(event), [event, showCancelEventPrompt])

  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  const status = useMemo(() => {
    if (
      event.status === OpportunityStatus.Draft ||
      !currentOrg.limitFutureEventVisibility ||
      moment(event.startsAt)
        .tz(currentOrg.timeZone)
        .startOf('day')
        .subtract(currentOrg.futureEventVisibilityDays, 'days')
        .isBefore(moment())
    ) {
      return event.status
    }
    return 'Hidden'
  }, [
    currentOrg.limitFutureEventVisibility,
    currentOrg.timeZone,
    currentOrg.futureEventVisibilityDays,
    event.startsAt,
    event.status,
  ])

  if (needsGroups && (!groupsCalled || eventGroupsLoading)) {
    return <Loader />
  }

  return (
    <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
      <EventExpansionPanelSummary style={style} ref={summaryRef} name="event-info">
        <h1>Overview</h1>
      </EventExpansionPanelSummary>
      <EventExpansionPanelDetails ref={detailsRef}>
        <div className={classes.buttons}>
          <OutlinedButton startIcon={<TabletMacIcon />} onClick={handleStartKiosk}>
            Open Kiosk
          </OutlinedButton>
          <OutlinedButton startIcon={<HighlightOffIcon />} onClick={onCancel}>
            Cancel {type}
          </OutlinedButton>
        </div>
        <div className={classes.details}>
          <div className={classes.eventInfo}>
            <SectionHeader title={`${type} Information`} onEdit={onEdit} />
            {event.isOffer || (offersEnabled && !schedulable) ? (
              <>
                <div className={classes.sectionRow}>
                  <div>{type} Start:</div>
                  <div>{recurrenceStart}</div>
                </div>
                <div className={classes.sectionRow}>
                  <div>{type} End:</div>
                  <div>{recurrenceEnd}</div>
                </div>
              </>
            ) : (
              <>
                <div className={classes.sectionRow}>
                  <div>{type} Date:</div>
                  <div>{instanceStartString}</div>
                </div>
                <div className={classes.sectionRow}>
                  <div>{type} Time:</div>
                  <div>{timeRange}</div>
                </div>
              </>
            )}
            <div className={classes.sectionRow}>
              <div>Location:</div>
              <div>
                {event.locationIsAddress ? getDisplayAddressFromEvent(event) : linkify(event.locationDetails, 50)}
              </div>
            </div>
            <div className={classes.sectionRow}>
              <div>Visibility:</div>
              <div>
                <EventVisibility event={event} groups={groups} />
              </div>
            </div>
            <div className={classes.sectionRow}>
              <div>Status:</div>
              <div>{status}</div>
            </div>
            {schedulable && (
              <>
                <SectionHeader title="Primary Point of Contact" onEdit={onEdit} />
                <div className={classes.sectionRow}>
                  <div>Name:</div>
                  <div>{event.contact_name}</div>
                </div>
                <div className={classes.sectionRow}>
                  <div>Phone:</div>
                  <div>{event.contact_phone_number}</div>
                </div>
                <div className={classes.sectionRow}>
                  <div>Email:</div>
                  <div>{event.contact_email}</div>
                </div>
                {event.recurrenceInfo.type !== RECURRING_TYPE.NEVER && (
                  <>
                    <SectionHeader title="Sequence Information" onEdit={onEdit} />
                    <div className={classes.sectionRow}>
                      <div>Start Date:</div>
                      <div>{recurrenceStart}</div>
                    </div>
                    <div className={classes.sectionRow}>
                      <div>End Date:</div>
                      <div>{recurrenceEnd}</div>
                    </div>
                    <div className={classes.sectionRow}>
                      <div>Frequency:</div>
                      <div>{recurrencePattern}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className={classes.description}>
            <h3>Description</h3>
            <div dangerouslySetInnerHTML={createMarkup(event.description)} />
            {summary && (
              <>
                <h3>Day-of Event Instructions for Volunteers</h3>
                <div dangerouslySetInnerHTML={createMarkup(event.instructions)} />
                <h3>Signups Summary</h3>
                <div className={classes.signupSummary}>
                  <SummaryTable summary={summary} />
                </div>
              </>
            )}
            {event.isOffer && event.trackSignups && (
              <>
                <h3>Post Signup Instructions</h3>
                <div dangerouslySetInnerHTML={createMarkup(event.instructions)} />
              </>
            )}
          </div>
          <div className={classes.support} />
        </div>
      </EventExpansionPanelDetails>
    </EventExpansionPanel>
  )
}
