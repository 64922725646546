import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'

const useEditOrganizationStripeId = () => {
  const [request, status] = useFetch('Error setting organization stripe Id')
  const organization = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const editOrganizationStripeId = useCallback( (stripeCustomerId, id) => {
    return request({
      method: id ? 'put' : 'post',
      url: id ? `/organizations/${organization.id}/stripe-customer-ids/${id}` : `/organizations/${organization.id}/stripe-customer-ids`,
      config: {
        body: { stripeCustomerId }
      },
      onSuccess: () => showSuccess('Successfully updated organization stripe Id')
    })
  }, [request])

  return [editOrganizationStripeId, status]
}

export default useEditOrganizationStripeId
