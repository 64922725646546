import React, { useCallback, useMemo } from 'react'
import { FormModal, FormModalScreen } from 'components/FormModal'
import { Field, Form, Formik } from 'formik'
import yup from 'civic-champs-shared/utils/yup'
import _ from 'lodash'
import { Chip } from '@material-ui/core'
import Papa from 'papaparse'
import { Loading, OutlinedButton } from 'civic-champs-shared'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { useActivityImportDialogStyles } from 'tracking/activity/hooks'
import { CSVLink } from 'react-csv'
import useFileManagementUpload from 'civic-champs-shared/core/file-upload/hooks/useFileManagementUpload'
import { ImportMappings, Outcome } from 'utils/import'
import { parseAndValidateInput } from 'volunteer-role/utils/validation'
import { useRolesCollection } from 'volunteer-role/hooks'
import { Role } from 'volunteer-role/types'
import useRolesActivities from 'volunteer-role/hooks/useImportRoles'
import { CustomSwitch } from 'civic-champs-shared/formik/components'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

export interface Mappings extends ImportMappings {
  name: string | null
  isActive: string | null
  isDefault: string | null
}
export interface FormValues {
  file: File | null
  headers: string[]
  mappings: Mappings
  isActive: boolean
  isDefault: boolean
}

const initialValues: FormValues = {
  file: null,
  headers: [],
  mappings: {
    name: null,
    isActive: null,
    isDefault: null,
  },
  isActive: true,
  isDefault: false,
}

const validationSchema = yup.object({
  file: yup.mixed().required('File is Required'),
  mappings: yup.object({
    name: yup.string().nullable().required('Name is Required'),
    isActive: yup.string().nullable().notRequired(),
    isDefault: yup.string().nullable().notRequired(),
  }),
  isActive: yup.boolean(),
  isDefault: yup.boolean(),
})

export const RoleImportDialog = ({ showing, close, complete }: Props) => {
  const [importRoles] = useRolesActivities()
  const [, , upload] = useFileManagementUpload()
  const [{ roles, loading }] = useRolesCollection() as any as [{ roles: Role[]; loading: boolean }]
  const existingRoles = useMemo(() => roles.map(({ name }) => name.trim()), [roles])
  const org = useCurrentOrg()
  const [invalidCsv, setInvalidCsv] = React.useState<Record<string, any>[] | null>(null)
  const [csvHeaders, setCsvHeaders] = React.useState<string[]>([])
  const classes = useActivityImportDialogStyles()

  const handle = useCallback(
    async (values: FormValues) => {
      const result = await parseAndValidateInput(values, existingRoles)
      if (result.some(({ outcome }) => outcome !== Outcome.Valid)) {
        setInvalidCsv(result)
        setCsvHeaders(values.headers)
      } else {
        const file = await upload(values.file as File, { isPrivate: true, contentType: 'text/csv' })
        await importRoles({
          orgId: org.id,
          importConfig: {
            file,
            mappings: values.mappings,
            isActive: values.isActive,
            isDefault: values.isDefault,
          },
        })
        complete(true)
      }
    },
    [complete, existingRoles, importRoles, org.id, upload],
  )

  const reset = useCallback(() => {
    setInvalidCsv(null)
    setCsvHeaders([])
  }, [])

  if (loading) return <Loading />

  return (
    <FormModal id="trip-prompt" showing={showing} close={close} title="Import Roles">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handle}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, values, errors, setFieldValue, setFieldError, touched }) => {
          return (
            <Form>
              <FormModalScreen
                onNext={submitForm}
                onBack={invalidCsv ? reset : undefined}
                backText={invalidCsv ? 'Back' : undefined}
                nextText="Import"
                nextDisabled={invalidCsv !== null}
                disabled={isSubmitting}
              >
                {invalidCsv ? (
                  <div className={classes.section}>
                    <div className={classes.sectionTitle}>Invalid CSV data:</div>
                    <CSVLink data={invalidCsv} headers={[...csvHeaders, 'outcome', 'reason']}>
                      Download CSV with validation result
                    </CSVLink>
                  </div>
                ) : (
                  <>
                    <div className={classes.section}>
                      <div className={classes.sectionTitle}>File:</div>
                      {errors.file || ''}
                      <input
                        accept="text/csv"
                        id="file-upload-input"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={e => {
                          const file = _.first(e.target.files) as File
                          Papa.parse<any, File>(file, {
                            header: false,
                            step: (results, parser) => {
                              if (results.errors.length === 0) {
                                setFieldValue('headers', results.data)
                              } else {
                                setFieldError('file', results.errors[0].message)
                              }
                              // Stop parsing, as we need only first line with headers
                              parser.abort()
                            },
                            complete: results => {},
                          })
                          setFieldValue('file', file)
                        }}
                      />
                      {values.file ? (
                        <Chip
                          label={values.file.name}
                          onDelete={() => {
                            setFieldValue('file', null)
                            setFieldValue('headers', [])
                          }}
                        />
                      ) : (
                        <label htmlFor="file-upload-input">
                          <OutlinedButton disabled={false} component="span">
                            Choose File
                          </OutlinedButton>
                        </label>
                      )}
                    </div>
                    {!!values.headers.length && (
                      <div className={classes.section}>
                        <div className={classes.sectionTitle}>Field Mapping:</div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'Name'}
                            placeholder={'Select an Name field'}
                            value={values.mappings.name}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.name', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.name && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.name ? errors.mappings?.name : undefined}
                          />
                          <AdvancedAutocomplete<string>
                            label={'Status / Active'}
                            placeholder={'Select a Status / Active field'}
                            value={values.mappings.isActive}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.isActive', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.isActive && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.isActive ? errors.mappings?.isActive : undefined}
                          />
                        </div>
                        <div className={classes.double}>
                          <AdvancedAutocomplete<string>
                            label={'Role can be used for Opportunities'}
                            placeholder={'Select an "Role can be used for Opportunities" field'}
                            value={values.mappings.isDefault}
                            disableInputFilter
                            options={values.headers}
                            onChange={value => setFieldValue('mappings.isDefault', value)}
                            getOptionDisabled={(option: string) =>
                              option !== values.mappings.isDefault && Object.values(values.mappings).includes(option)
                            }
                            error={touched.mappings?.isDefault ? errors.mappings?.isDefault : undefined}
                          />
                          <div style={{ width: '100%' }} />
                        </div>
                      </div>
                    )}
                    <div className={classes.section}>
                      <div className={classes.sectionTitle}>Default Values:</div>
                      <div className={classes.double}>
                        <div className={classes.switch}>
                          <Field
                            component={CustomSwitch}
                            id="isActive"
                            name="isActive"
                            thumbColor="#FFF"
                            trackColor={values.isActive ? '#008954' : '#a2a3a6'}
                            type="checkbox"
                            tooltip="Roles set to Inactive will not be available for selection when adding new Events or Activities."
                          />
                          <span>{values.isActive ? 'Active' : 'Inactive'}</span>
                        </div>
                        <div className={classes.switch}>
                          <Field
                            component={CustomSwitch}
                            id="isDefault"
                            name="isDefault"
                            thumbColor="#FFF"
                            trackColor={values.isDefault ? '#008954' : '#a2a3a6'}
                            label="Role can be used for Opportunities"
                            tooltip="Roles marked as useable for Opportunities can be selected by volunteers when checking into all Opportunities, or logging activities outside of specific Events."
                          />
                          <span>
                            {values.isDefault
                              ? 'Roles can be used for Opportunities'
                              : "Roles can't be used for Opportunities"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </FormModalScreen>
            </Form>
          )
        }}
      </Formik>
    </FormModal>
  )
}
