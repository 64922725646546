import React from 'react'
import { PopulatedMigrationIssue, MigrationIssueTypes, SupporterOrVolunteer } from '../interfaces'
import { Button, Grid } from '@material-ui/core'
import { filter } from 'lodash'
import format from 'civic-champs-shared/utils/format'

interface SalsaMigrationIssuesModalProps {
  onSelect: (salsaMigrationIssueId: number, selectedId: string | number | null) => void
  migrationIssue: PopulatedMigrationIssue
  currentResolutionSelection: string | number | null
  classes: any
}

const getIdNameEmailPhone = (user: SupporterOrVolunteer) => {
  const { id, givenName = '', familyName = '', email = '', phoneNumber, cellPhone, workPhone, homePhone, homePhoneNumber } = user
  const phoneNumbersArray = filter([phoneNumber, cellPhone, workPhone, homePhone, homePhoneNumber])
  const phoneNumbers = phoneNumbersArray.length
    ? phoneNumbersArray.map(number => format.phoneNumber(number)).join(', ')
    : ''
  return { name: givenName + ' ' + familyName, phoneNumbers, email, id }
}

export default function SalsaMigrationIssuesModal(props: SalsaMigrationIssuesModalProps) {
  const { onSelect, migrationIssue, currentResolutionSelection, classes } = props
  const { salsaMigrationIssueId, issueType, volunteers, supporters } = migrationIssue
  const volunteerOrSupporterIsAlreadySelected = (selectedId: string | number) =>
    currentResolutionSelection === selectedId
  const anotherVolunteerOrSupporterIsAlreadySelected = (selectedId: string | number) => {
    return !!(currentResolutionSelection && currentResolutionSelection !== selectedId)
  }
  const select = (selectedId: string | number) => {
    onSelect(salsaMigrationIssueId, volunteerOrSupporterIsAlreadySelected(selectedId) ? null : selectedId)
  }
  const { individualUser, candidates } =
    issueType === MigrationIssueTypes.MULTIPLE_SUPPORTERS_MATCH_VOLUNTEER
      ? { individualUser: volunteers[0], candidates: supporters }
      : { individualUser: supporters[0], candidates: volunteers }
  const {
    name: individualUserName,
    email: individualUserEmail,
    phoneNumbers: individualUserPhoneNumbers,
  } = getIdNameEmailPhone(individualUser)

  const { from, candidateType } =
    issueType === MigrationIssueTypes.MULTIPLE_SUPPORTERS_MATCH_VOLUNTEER
      ? { from: 'Civic Champs', candidateType: 'supporters' }
      : { from: 'Salsa', candidateType: 'volunteers' }

  return (
    <div style={{ marginBottom: '30px', marginTop: '30px' }}>
      <p style={{ marginBottom: '0', color: '#3D3D3D' }}>From {from}</p>
      <Grid container spacing={4} direction="column">
        <Grid item xs={12}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={3}>
              <Grid container wrap="nowrap" direction="column" spacing={2}>
                <Grid item>
                  <div style={{ wordWrap: 'break-word' }}>
                    <b>Name</b>
                  </div>
                </Grid>
                <Grid item xs>
                  <div style={{ wordWrap: 'break-word' }}>{individualUserName}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container wrap="nowrap" direction="column" spacing={2}>
                <Grid item>
                  <div style={{ wordWrap: 'break-word' }}>
                    <b>Email</b>
                  </div>
                </Grid>
                <Grid item xs>
                  <div style={{ wordWrap: 'break-word' }}>{individualUserEmail}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container wrap="nowrap" direction="column" spacing={2}>
                <Grid item>
                  <div style={{ wordWrap: 'break-word' }}>
                    <b>Phone(s)</b>
                  </div>
                </Grid>
                <Grid item xs>
                  <div style={{ wordWrap: 'break-word' }}>{individualUserPhoneNumbers}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div style={{ marginBottom: '10px', color: '#3D3D3D' }}>
                    Do any of these {candidateType} match the above person
                  </div>
                  {candidates.map((candidate: SupporterOrVolunteer): any => {
                    const { name, email, phoneNumbers, id } = getIdNameEmailPhone(candidate)
                    const isAlreadySelected = volunteerOrSupporterIsAlreadySelected(id)
                    const anotherAlreadySelected = anotherVolunteerOrSupporterIsAlreadySelected(id)
                    const className = isAlreadySelected
                      ? classes.selectedUser
                      : anotherAlreadySelected
                      ? classes.nonSelectedUser
                      : classes.neitherUserSelected
                    return (
                      <Grid container direction="row" spacing={4} key={id} className={className}>
                        <Grid item xs={3}>
                          <Grid container wrap="nowrap" direction="column" spacing={2}>
                            <Grid item>
                              <div style={{ wordWrap: 'break-word' }}>
                                <b>Name</b>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div style={{ wordWrap: 'break-word' }}>{name}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container wrap="nowrap" direction="column" spacing={2}>
                            <Grid item>
                              <div style={{ wordWrap: 'break-word' }}>
                                <b>Email</b>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div style={{ wordWrap: 'break-word' }}>{email}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Grid container wrap="nowrap" direction="column" spacing={2}>
                            <Grid item>
                              <div style={{ wordWrap: 'break-word' }}>
                                <b>Phone(s)</b>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <div style={{ wordWrap: 'break-word' }}>{phoneNumbers}</div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            className={
                              isAlreadySelected
                                ? classes.orangeButton
                                : anotherAlreadySelected
                                ? classes.disabledButton
                                : classes.submitButton
                            }
                            disabled={anotherAlreadySelected}
                            onClick={() => select(id)}
                          >
                            {isAlreadySelected ? 'Unselect' : 'Select'}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
