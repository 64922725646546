import React, { useState } from 'react'
import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import { ChampionSummaryCards } from '../components/ChampionSummaryCards'
import { VolunteerReflections } from '../components/VolunteerReflections'
import { ProfileDetailsCard } from '../components/ProfileDetailsCard'
import BackLink from 'donation/campaigns/components/BackLink'
import useProfile from '../hooks/useProfile'
import useUpdatePerson from '../../people/hooks/useUpdatePerson'
import { useCurrentOrg } from '../../auth/hooks'
import { ProfileWaiverTable } from 'champion/components/ProfileWaiverTable'
import { QuestionAnswerSetsPagedView } from 'civic-champs-shared/question-sets/components/QuestionAnswerSetsPagedView'

const useStyles = makeStyles(() => ({
  cardHeader: {
    background: '#FFCD00',
    color: '#3d3d3d',
    fontWeight: 'bold',
    height: '30px',
    '& .MuiCardHeader-title': {
      fontSize: '14px',
    },
  },
  cardHeaderAvatar: {
    background: '#FFCD00',
    color: '#3d3d3d',
    fontWeight: 'bold',
    '& .MuiCardHeader-title': {
      fontSize: '16px',
    },
  },
  waiversCard: {
    maxHeight: '300px',
    overflow: 'scroll',
  },
  label: {
    fontWeight: 600,
  },
}))

export const ProfileViewOld = props => {
  const [profile, setProfile] = useState(null)
  const id = props.match.params.id
  const currentOrg = useCurrentOrg()
  const { loading, getProfile } = useProfile({
    id,
    onSuccess: setProfile,
    organizationId: currentOrg.id,
  })
  const { updatePerson } = useUpdatePerson(() => getProfile(id))

  const classes = useStyles()

  if (loading) {
    return <Loading />
  }
  if (!profile) {
    return (
      <div>
        <BackLink to={'/people'} text="Back to People" />
        <div>No profile exists with this id</div>
      </div>
    )
  }

  const { user, activities, volunteer, credentials, questionSets } = profile
  const activitiesWithReflection = activities.filter(({ reflection }) => !!reflection)

  const onSubmit = async values => {
    await updatePerson({ ...values, id: user.id })
  }

  return (
    <div>
      <BackLink to={'/champions'} text="Back to Champions" />
      <Grid container spacing={2} direction="row">
        <Grid item xs={4}>
          <ProfileDetailsCard user={user} volunteering={volunteer.volunteering} onSubmit={onSubmit} />
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Card>
                <CardHeader title="Contributions" className={classes.cardHeader} />
                <CardContent>
                  <ChampionSummaryCards activities={activities} donation={volunteer.donation} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title="Overview" className={classes.cardHeader} />
                <CardContent>
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant="body1" className={classes.label}>
                        Reflections from Volunteer:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <VolunteerReflections activities={activitiesWithReflection} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title="Signed Waivers" className={classes.cardHeader} />
                <CardContent className={classes.waiversCard}>
                  <ProfileWaiverTable credentials={credentials} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title="Questions and Answers" className={classes.cardHeader} />
                <CardContent>
                  <QuestionAnswerSetsPagedView prerequisites={{ questionSets }} fullHeight={false} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
