import useGet from 'civic-champs-shared/api/hooks/useGet'
import { useMount } from 'react-use'

export const useOpportunityPersonGroups = opportunityId => {
  const [getOpportunityPersonGroups, status] = useGet({
    configMapper: opportunityId => ({ url: `/opportunity_instances/${opportunityId}/groups` }),
    errorMessage: 'Error fetching associated groups',
    emptyValue: [],
  })
  useMount(() => {
    if (opportunityId) {
      getOpportunityPersonGroups(opportunityId)
    }
  })
  const { loading, called } = status
  return [getOpportunityPersonGroups, { ...status, loading: opportunityId ? loading || !called : false }]
}
