import React from 'react'
import { Grid, Typography } from '@material-ui/core'

interface SalsaNotConnectedTextProps {
  classes: any
  informationMissing: boolean
  canConnect: boolean
}

export default function SalsaNotConnectedText(props: SalsaNotConnectedTextProps) {
  const { informationMissing, canConnect, classes } = props
  return (
    <Grid item className={classes.formLabelWrapper} xs={4}>
      <Typography className={classes.notConnectedLabel}>
        {informationMissing ? (
          <span>Please add the API Key and Group ID</span>
        ) : canConnect ? (
          <span>
            Clicking <b>Connect</b> will enable Civic Champs to upload your volunteers into Salsa and allow you to do
            full syncs from Salsa into Civic Champs using the <b>Sync</b> button below
          </span>
        ) : (
          <span>
            Syncing is currently paused. Click the <b>Reconnect</b> button to restart syncing your Civic Champs
            volunteers into Salsa.
          </span>
        )}
      </Typography>
    </Grid>
  )
}
