import { useCallback, useMemo, useState } from 'react'
import { useErrorNotification, useSuccessNotification } from './index'
import { FetchProps, Request, Status } from './fetchRefactoredSchema'
import * as _ from 'lodash'
import requestWithRetry from '../requestWithRetry'

export default function useFetchRefactored<T, R = T>(props: FetchProps<T, R> = {}): [Request<T>, Status<T>] {
  const {
    errorMessage,
    successMessage,
    emptyValue = null,
    useNewNotification = false,
    mapper = (data: R) => data as any as T,
  } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [called, setCalled] = useState<boolean>(false)
  const [result, setResult] = useState<T | null>(_.cloneDeep(emptyValue))
  const [error, setError] = useState<Error | null>(null)
  const showError = useErrorNotification()
  const showSuccess = useSuccessNotification(useNewNotification)
  const request: Request<T> = useCallback(
    async requestConfig => {
      const { method = 'get', url, config = {}, onSuccess, onError } = requestConfig
      try {
        setCalled(true)
        setLoading(true)
        setError(null)
        const response: R = await requestWithRetry({ method, url, config })
        const result = mapper(response)
        setResult(result)
        if (onSuccess) {
          onSuccess(result)
        }

        const successNotification = successMessage || requestConfig.successMessage
        if (successNotification) {
          showSuccess(successNotification)
        }
        return result
      } catch (error) {
        // @ts-ignore
        setError(error)
        // @ts-ignore
        onError?.(error)

        const errorNotification = errorMessage || requestConfig.errorMessage
        if (errorNotification) {
          showError(errorNotification, error)
        }
        throw error
      } finally {
        setLoading(false)
      }
    },
    [successMessage, showSuccess, errorMessage, showError], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // @ts-ignore
  const status: Status<T> = useMemo(() => ({ loading, called, result, error }), [loading, called, result, error])

  return [request, status]
}
