import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'

//TODO handle errors!
const useGetVolunteeringRoles = () => {
  const [request, { result, loading, called, error }] = useFetch()

  const getVolunteeringRoles = useCallback(
    (orgId, defaultOnly) => {
      return request({
        method: 'get',
        url: `/organizations/${orgId}/roles`,
        config: {
          queryStringParameters: {
            defaultOnly: !!defaultOnly,
          },
        },
      })
    },
    [request],
  )

  return [
    getVolunteeringRoles,
    {
      loading: loading || !called,
      volunteeringRoles: result,
      error,
    },
  ]
}

export default useGetVolunteeringRoles
