import { connect } from 'react-redux';

import StandaloneKiosk from './StandaloneKiosk';
import { opportunityName, isKioskMode, kioskStatus, lastError } from '../selectors'
import { clearStopKioskFailure } from 'kiosk/actions';

const mapStateToProps = state => ({
    opportunityName: opportunityName(state),
    isKioskMode: isKioskMode(state),
    kioskStatus: kioskStatus(state),
    error: lastError(state)
});

const mapDispatchToProps = dispatch => ({
    abortKiosk: () => dispatch( clearStopKioskFailure() )
})

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneKiosk);