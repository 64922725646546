import React from 'react'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'

export default function MaskedField(props) {
  const { mask, maskChar, alwaysShowMask = false, ...rest } = props
  return (
    <InputMask mask={mask} maskChar={maskChar} alwaysShowMask={alwaysShowMask} {...fieldToTextField(rest)}>
      {inputProps => <TextField {...inputProps} />}
    </InputMask>
  )
}
