import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProgramMatch, Tag } from 'new-mentorship/types'
import useStyles from 'new-mentorship/hooks/useStyles'
import { NumberCell } from 'core/table/cells'
import { Row, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import PageTitle from 'components/PageTitle'
import SummaryCards from 'core/table/components/SummaryCards'
import Loading from 'civic-champs-shared/core/Loading'
import Search from 'new-mentorship/helpers/Search'
import reduce from 'lodash/reduce'
import { Box, Grid, Popover } from '@material-ui/core'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import MatchFlowEditor from 'new-mentorship/components/MatchFlowEditor'
import LegacyMatchFlowEditor from 'new-mentorship/legacy-components/MatchFlowEditor'
import useAddMatch from 'new-mentorship/hooks/useAddMatch'
import useTagManagement from 'new-mentorship/hooks/useTagsManagement'
import DEFAULT_FILTERS from 'core/table/filters'
import PagedTable from 'core/table/components/PagedTable'
import DateRangeFilter from 'components/filter/DateRangeFilter'
// @ts-ignore
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useDateRangeFilter } from 'filtering/hooks'
import { Icon } from '@mdi/react'
import { mdiFilterMenu } from '@mdi/js'
import { FiltersModal, operatorTypes, useFiltersFromQuery } from 'core/table/components/FiltersModal'
import { RouteComponentProps } from 'react-router'
import useColumns from 'new-mentorship/hooks/useMatchColumns'
import useFetchMatches from 'new-mentorship/hooks/useFetchMatches'
import map from 'lodash/map'
import useSelectColumn from 'core/table/table-hooks/useSelectColumn'
import { ExportCsvButton } from 'external-datafeed/components/ExportCsvButton'
import { useFeatureEnabled } from 'core/feature/hooks'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'tags':
      return [
        { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
        { value: operatorTypes.EQUALS, displayValue: 'Equals' },
      ]

    case 'meetingCount':
    case 'momentCount':
    case 'averageMeetingDuration':
      return [
        { value: operatorTypes.LESS_THAN, displayValue: 'Less than' },
        { value: operatorTypes.LESS_OR_EQUAL, displayValue: 'Less than or equal to' },
        { value: operatorTypes.EQUALS, displayValue: 'Equal to' },
        { value: operatorTypes.GREATER_OR_EQUAL, displayValue: 'Greater than or equal to' },
        { value: operatorTypes.GREATER_THAN, displayValue: 'Greater than' },
      ]

    case 'programName':
    case 'mentorName':
    case 'menteeName':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]
    default:
      return []
  }
}

const cards = [
  {
    Header: 'Mentorship Meetings ',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    Header: 'Mentorship Moments',
    accessor: 'momentCount',
    Cell: NumberCell,
  },
  {
    Header: 'Average Meeting Time',
    accessor: 'averageMeetingTime',
    Cell: NumberCell,
  },
  {
    Header: 'Positive Experiences',
    accessor: 'positiveFeedbackPercent',
  },
  {
    Header: 'Negative Experiences',
    accessor: 'negativeFeedbackPercent',
  },
]

const useSummary = (rows: Row<ProgramMatch>[]) =>
  useMemo(() => {
    const { meetingCount, momentCount, totalMeetingTime, positiveFeedbackCount, negativeFeedbackCount } = reduce(
      rows,
      (
        data,
        {
          original: { meetingCount, momentCount, averageMeetingDuration, positiveFeedbackCount, negativeFeedbackCount },
        }: Row<ProgramMatch>,
      ) => ({
        meetingCount: data.meetingCount + meetingCount,
        momentCount: data.momentCount + momentCount,
        totalMeetingTime: data.totalMeetingTime + averageMeetingDuration * meetingCount,
        positiveFeedbackCount: data.positiveFeedbackCount + positiveFeedbackCount,
        negativeFeedbackCount: data.negativeFeedbackCount + negativeFeedbackCount,
      }),
      {
        meetingCount: 0,
        momentCount: 0,
        totalMeetingTime: 0,
        positiveFeedbackCount: 0,
        negativeFeedbackCount: 0,
      },
    )
    const feedbackCount = positiveFeedbackCount + negativeFeedbackCount
    return {
      meetingCount,
      momentCount,
      averageMeetingTime: meetingCount ? Math.round(totalMeetingTime / meetingCount / 60) : 0,
      positiveFeedbackPercent:
        (feedbackCount ? Math.round((positiveFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
      negativeFeedbackPercent:
        (feedbackCount ? Math.round((negativeFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
    }
  }, [rows])

const csvHeaders = [
  { label: 'Program', key: 'programName' },
  { label: 'Mentor', key: 'mentorName' },
  { label: 'Mentee', key: 'menteeName' },
  { label: 'Matched Since', key: 'startedAt' },
  { label: '# Meetings', key: 'meetingCount' },
  { label: '# Moments', key: 'momentCount' },
  { label: 'Avg. Meeting Time', key: 'averageMeetingDuration' },
  { label: 'Tags', key: 'tags' },
]

export const MentorshipMatches = ({ location, history }: RouteComponentProps) => {
  const { filters, filterRows: initialFilterRows } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [filtersModalVisible, setFiltersModalVisible] = useState(false)
  const [dateRange, setDateRange] = useDateRangeFilter()
  const anchorEl = useRef(null)
  const newDialogUI = useFeatureEnabled('MentorshipDialogsNewUI')

  const [fetchMatches, { loading, result }] = useFetchMatches()
  useEffect(() => {
    fetchMatches()
  }, [fetchMatches])

  const matches = useMemo(
    () =>
      map(result, match => ({
        ...match,
        mentorName: `${match?.mentor?.givenName} ${match?.mentor?.familyName}`,
        menteeName: `${match?.mentee?.givenName} ${match?.mentee?.familyName}`,
        programName: match?.program?.name,
      })),
    [result],
  )

  const { addMatch } = useAddMatch()
  const { linkTagsToMatch } = useTagManagement()
  const showAddMatch = useShowPrompt(newDialogUI ? MatchFlowEditor : LegacyMatchFlowEditor)

  const classes = useStyles()

  const columns = useColumns({ showAddMatch, fetchMatches })

  const table = useTable(
    {
      data: matches,
      columns,
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      // @ts-ignore
      initialState: { filters },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn,
  )

  useEffect(() => {
    table.setFilter('startedAt', dateRange)
  }, [dateRange, table])

  const summary = useSummary(table.rows)

  const prepareCsv = useCallback(
    () => ({
      filename: 'mentorship-matches.csv',
      headers: csvHeaders,
      data: table.selectedFlatRows.map(({ values }: any) => ({
        ...values,
        tags: values.tags.map(({ name }: Tag) => name).join(','),
      })),
    }),
    [table.selectedFlatRows],
  )

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div className={classes.header}>
          <Grid xs={6} container item alignItems="center" justify="flex-start" direction="row">
            <PageTitle title="Mentorship Matches" />
            <Box ml={1.25}>
              <AddButton
                onClick={() =>
                  showAddMatch({
                    label: 'Add a New Match',
                    submit: ({ tags, ...match }: Partial<ProgramMatch> & { tags: Tag[] }) =>
                      addMatch(match).then(async ({ id: matchId }: ProgramMatch) => {
                        if (tags.length) {
                          await linkTagsToMatch(
                            tags.map(({ id }) => id),
                            matchId,
                          )
                        }
                        return fetchMatches()
                      }),
                  })
                }
              />
            </Box>
          </Grid>
          <Grid xs={6} container item alignItems="center" spacing={0} justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onChange={table.setGlobalFilter} initialValue={table.state.globalFilter} />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Box className={classes.dateBox}>
                <DateRangeFilter
                  initialValues={dateRange}
                  onFilterApplied={setDateRange}
                  anchorDirection={ANCHOR_LEFT}
                  noBorder
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <SummaryCards summary={summary} cards={cards} />
        {loading ? (
          <Loading />
        ) : (
          <section id="table" className={classes.table}>
            <Grid xs={12} container alignItems="center" spacing={0} justify="space-between" direction="row">
              <Grid xs={3} container item alignItems="center" spacing={0} justify="flex-start" direction="row">
                {/*
              // @ts-ignore */}
                <Box ml={2} display="inline-block" className={classes.filterBox} ref={anchorEl}>
                  <AddButton
                    onClick={() => setFiltersModalVisible(true)}
                    icon={<Icon path={mdiFilterMenu} size={1} /*fill="#000000"*/ />}
                    title={
                      <Box>
                        <sup>
                          {table.columns.filter((column: any) => column.filterValue && !column.disableFilters).length ||
                            ''}
                        </sup>
                        <Box component="span" pl={1}>
                          FILTERS
                        </Box>
                      </Box>
                    }
                  />
                </Box>
                <Popover
                  keepMounted
                  BackdropProps={{}}
                  open={filtersModalVisible}
                  anchorEl={anchorEl.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  // @ts-ignore
                  transformOrigin={{
                    vertical: -20,
                  }}
                >
                  <FiltersModal
                    columns={columns}
                    table={table}
                    history={history}
                    filterRows={initialFilterRows}
                    getOperatorOptions={getOperatorOptions}
                    onClose={() => setFiltersModalVisible(false)}
                  />
                </Popover>
              </Grid>
              <Grid
                xs={9}
                container
                item
                alignItems="center"
                spacing={1}
                justify="flex-end"
                direction="row"
                className={classes.rightButtonsGrid}
              >
                <Grid item>
                  <ExportCsvButton disabled={table.selectedFlatRows.length === 0} onPrepareCsv={prepareCsv}>
                    Export CSV
                  </ExportCsvButton>
                </Grid>
              </Grid>
            </Grid>
            <PagedTable {...table} wrapHeader />
          </section>
        )}
      </div>
    </div>
  )
}

export default MentorshipMatches
