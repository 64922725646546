import _ from 'lodash'
import React from 'react'
import { Field, Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { FormControl, Grid, MenuItem, Tooltip, Typography } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import HelpOutlineIcon from '@material-ui/icons/ContactSupportOutlined'

import { AddressField, defaultAddress, SwitchToggle } from 'civic-champs-shared/formik/components'
import { CreateOrUpdateOrganizationParam } from '../models'
import {
  CreateOrUpdateOrganizationGeofencingParam,
  mapDbOrganizationToGeofencing,
  mapGeofencingOrganizationToDb,
} from 'utils/organization'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import { timezones } from '../../../civic-champs-shared/helpers/timezones'

const defaultValues = {
  name: '',
  website: '',
  address: defaultAddress(),
  timeZone: '',
  isTest: false,
  isMentoringCustomer: false,
  sandboxModeEnabled: false,
} as CreateOrUpdateOrganizationGeofencingParam

const OrganizationForm = (props: {
  onSubmit: (values: CreateOrUpdateOrganizationParam) => void
  organization?: CreateOrUpdateOrganizationParam
  justify: any
  isTester: boolean
}) => {
  const { organization, justify, isTester } = props
  const onSubmit = async (
    values: CreateOrUpdateOrganizationGeofencingParam,
    actions: FormikActions<CreateOrUpdateOrganizationGeofencingParam>,
  ) => {
    props.onSubmit(mapGeofencingOrganizationToDb(values))
    actions.setSubmitting(false)
  }
  const initialValues = organization ? mapDbOrganizationToGeofencing(organization) : defaultValues
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values: CreateOrUpdateOrganizationGeofencingParam) => {
        const errors: any = {}
        if (!values.name) {
          errors.name = 'Name is required'
        }
        if (!values.website) {
          errors.website = 'Website is required'
        }
        if (!values.timeZone) {
          errors.timeZone = 'Time zone is required'
        }
        if (!values.address.line1) {
          _.set(errors, ['address', 'line1'], 'Address is required')
        }
        return errors
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <Grid container direction="row" justify={justify}>
            <Grid item sm={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="name"
                    placeholder="Name for this organization"
                    label="Name"
                  />
                </Grid>
                <Grid item>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="website"
                    placeholder="Website for this organization"
                    label="Website"
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth error={!!(errors.timeZone && touched.timeZone)}>
                    <Field
                      fullWidth
                      variant="outlined"
                      component={TextField}
                      select={true}
                      label="Time Zone"
                      name="timeZone"
                      inputProps={{
                        id: 'timeZone',
                      }}
                    >
                      {timezones.map(({ value, name }) => (
                        <MenuItem key={value} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Field component={AddressField} name="address" label="Address" />
                </Grid>
                {isTester && (
                  <Grid item>
                    <Field component={SwitchToggle} name="isTest" label="Test Organization?" />
                  </Grid>
                )}
                <FeatureEnabled code="SandboxMode">
                  <Grid item>
                    <Field component={SwitchToggle} name="sandboxModeEnabled" label="Silent Mode" />
                    <Tooltip
                      title={
                        <Typography style={{ fontSize: '16px' }}>
                          Enabling Silent Mode will prevent administrative actions in Civic Champs from sending any
                          emails or texts. This includes inviting volunteers to groups, registration confirmations,
                          event cancellations, etc.
                        </Typography>
                      }
                    >
                      <HelpOutlineIcon style={{ fontSize: 18, verticalAlign: 'middle', marginLeft: 5 }} />
                    </Tooltip>
                  </Grid>
                </FeatureEnabled>
                <Grid item container justify="flex-end">
                  <ContainedButton type="submit">Save</ContainedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default OrganizationForm
