import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ExportMentorshipSurveysButton from '../components/ExportMentorshipSurveysButton'

import Loading from 'civic-champs-shared/core/Loading'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import useSurveys from '../hooks/useFetchSurveys'
import CreateUpdateSurveyPrompt from 'surveys/components/CreateUpdateSurveyPrompt'
import { useColumns, useStyles } from '../hooks/useMentorshipSurveysColumns'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Redirect } from 'react-router-dom'

import PageHeader from 'App/components/PageHeader'

const tableName = 'surveys'

const NewMentorshipSurveysComponent = ({ location, history, match: { url } }: RouteComponentProps) => {
  const { surveys, loading, refresh } = useSurveys()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columns = useColumns()
  const columnStyles = useStyles()

  const showCreateUpdatePrompt = useShowPrompt(CreateUpdateSurveyPrompt)
  const isNewQuestionnairesEditorEnabled = useFeatureEnabled('NewQuestionnairesEditor')
  const [redirect, setRedirect] = useState<null | string>(null)

  const handleAdd = async () => {
    if (isNewQuestionnairesEditorEnabled) {
      setRedirect(`${url}/new`)
    } else {
      await showCreateUpdatePrompt()
      refresh()
    }
  }

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: surveys,
      columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Surveys"
        buttons={
          <>
            <ExportMentorshipSurveysButton data={table.selectedFlatRows} disabled={!table.selectedFlatRows.length} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={handleAdd}>
            Add Survey
          </ContainedButton>
        }
        useGlobalSearch
      />
    </Grid>
  )
}

export const NewMentorshipSurveys = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Mentorship Surveys" subTitle="Create and manage mentorship surveys">
      <NewMentorshipSurveysComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipSurveys
