import React from 'react'
import { FormHelperText } from '@material-ui/core'

import TaskVolunteerPicker from '../components/TaskVolunteerPicker'

export default function TaskVolunteerField({ field, form, ...props }) {
    const error = form.errors[field.name]
    const handleChange = value => form.setFieldValue(field.name, value)
    return (
        <div>
            <TaskVolunteerPicker
                {...props}
                name={field.name}
                value={field.value}
                onChange={handleChange}
            />
            {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </div>
    )
}