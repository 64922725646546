import { useCallback } from 'react'
import { map } from 'lodash'
import { Admin } from 'admins/team/interface'
import { MessagingContact } from '../types'

export const useGetSearchableContactsFromAdminsArray = (admins: Admin[]): (() => Promise<MessagingContact[]>) => {
  return useCallback(
    () =>
      Promise.resolve(
        map(admins, ({ person: { id, givenName, familyName } }) => ({
          id,
          firstName: givenName as string,
          lastName: familyName as string,
        })),
      ),
    [admins],
  )
}
