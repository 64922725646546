import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export const useDeleteNotification = (): [(id: number) => Promise<void>, Status<void>] => {
  const [request, status] = useFetchRefactored<void>()

  const deleteNotification = useCallback(
    (id: number) => {
      return request({
        method: 'del',
        url: `/notifications/${id}`,
      })
    },
    [request],
  )

  return [deleteNotification, status]
}

export default useDeleteNotification
