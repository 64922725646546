import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { MentorshipProgram } from 'new-mentorship/types'

export const useFetchPrograms = (): [() => Promise<MentorshipProgram[]>, Status<MentorshipProgram[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<MentorshipProgram[]>({
    errorMessage: 'Error Fetching Programs.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchPrograms = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/mentorship-programs`,
      config: {
        queryStringParameters: {
          includeStats: 1,
        },
      },
    })
  }, [organization, request])

  return [fetchPrograms, status]
}

export default useFetchPrograms
