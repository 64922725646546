import { map } from 'lodash'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'

import {
  ConsolidatedContactCell,
  ContactCell,
  contactSort,
  ExpandableCell,
  NameCell,
  PersonLinkCell,
} from 'core/table/cells/modern'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { useGroups } from 'group/hooks/useGroups'
import { useUISettings } from 'hooks/useUISettings'
import { FIRST_LAST_NAME, GROUPS } from '../../tracking/activity/utils/filters'
import { Grid } from '@material-ui/core'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import HomeIcon from '@material-ui/icons/Home'
import cn from 'classnames'
import { DateCell, PhoneNumberCell } from '../../core/table/cells'
import IconComponent from '../../civic-champs-shared/core/icon'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  contactColumn: {
    '&>div': {
      width: '100px',
    },
  },
  contactColumnHeader: {
    fontSize: '20px',
    width: '100px',
    color: '#001B3F',
  },
  contactIcon: {
    fontSize: 'inherit',
    color: '#000',
    cursor: 'default',
  },
  consolidatedContactColumn: {
    '&>div': {
      width: '50px',
    },
  },
  emailColumn: {
    '&>div': {
      width: '210px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  mobileColumn: {
    '&>div': {
      width: '150px',
    },
  },
  homeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  groupsColumn: {
    '&>div': {
      width: '310px',
    },
  },
  activityColumn: {
    '&>div': {
      width: '110px',
    },
  },
})

export const useOfferSignupColumns = () => {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const { groups } = useGroups(undefined, false)

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'person',
            Cell: PersonLinkCell,
            filter: FIRST_LAST_NAME,
            className: classes.nameColumn,
            sortType: contactSort,
          },
          {
            id: 'firstName',
            Header: 'First Name',
            accessor: 'person.givenName',
            Cell: (input: any, _: any) => NameCell(input, _, 'person'),
            filter: DYNAMIC_TEXT,
            className: classes.firstNameColumn,
            sortType: createStringSort('firstName'),
          },
          {
            id: 'lastName',
            Header: 'Last Name',
            accessor: 'person.familyName',
            Cell: (input: any, _: any) => NameCell(input, _, 'person'),
            filter: DYNAMIC_TEXT,
            className: classes.lastNameColumn,
            sortType: createStringSort('lastName'),
          },
          {
            id: 'contact',
            Header: (
              <Grid container justify="space-between" className={classes.contactColumnHeader}>
                <Grid item>
                  <AlternateEmailIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <PhoneIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <HomeIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <span className={cn('material-symbols-outlined', classes.contactIcon)}>emergency</span>
                </Grid>
              </Grid>
            ),
            accessor: 'person',
            Cell: ContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.contactColumn,
          },
          {
            id: 'consolidatedContact',
            Header: (
              <Grid container justify="center">
                <Grid item>
                  <IconComponent name="contact" customColor="#000000" height={18} width={18} />
                </Grid>
              </Grid>
            ),
            accessor: 'person',
            Cell: ConsolidatedContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.consolidatedContactColumn,
          },
          {
            id: 'email',
            Header: 'Email',
            accessor: 'person.email',
            filter: DYNAMIC_TEXT,
            className: classes.emailColumn,
            sortType: createStringSort('email'),
          },
          {
            id: 'mobile',
            Header: 'Mobile Phone',
            accessor: 'person.phoneNumber',
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            className: classes.mobileColumn,
            sortType: createStringSort('mobile'),
          },
          {
            id: 'homePhoneNumber',
            Header: 'Home Phone',
            accessor: 'person.homePhoneNumber',
            className: classes.homeColumn,
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            sortType: createStringSort('homePhoneNumber'),
          },
          {
            id: 'groups',
            Header: 'Groups',
            accessor: 'groups',
            Cell: ExpandableCell,
            filter: GROUPS,
            className: classes.groupsColumn,
            sortType: createStringSort('groups'),
            getFilterAutocomplete: () => map(groups, 'name'),
          },
          {
            id: 'createdAt',
            Header: 'Sign-up Date',
            accessor: 'createdAt',
            Cell: DateCell,
            disableFilters: true,
            className: classes.activityColumn,
          },
        ],
        {
          showAdminItems,
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showAdminItems],
  )
}

export default useOfferSignupColumns
