import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { ProgramMatch } from 'new-mentorship/types'

export const useEditMatch = (): {
  editMatch: (id: number, body: Partial<ProgramMatch>) => Promise<ProgramMatch>
  status: Status<ProgramMatch>
} => {
  const [request, status] = useFetchRefactored<ProgramMatch>({
    errorMessage: 'Match Update Failed',
    successMessage: 'Match Updated Successfully',
  })

  const editMatch = useCallback(
    (id, body) => {
      return request({
        method: 'put',
        url: `/mentorship-matches/${id}`,
        config: {
          body,
        },
      })
    },
    [request],
  )

  return {
    editMatch,
    status,
  }
}

export default useEditMatch
