import { NamedRef, OrgNamedRef, PersonRef } from 'civic-champs-shared/common/types'
import {
  InvitationDisplayStatus,
  InvitationStatus,
  InvitationType,
  SendMethod,
} from 'civic-champs-shared/question-sets/types/invitation'

export enum EmailEventType {
  RENDERING_FAILURE = 'Rendering Failure',
  REJECT = 'Reject',
  BOUNCE = 'Bounce',
  SEND = 'Send',
  COMPLAINT = 'Complaint',
  DELIVERY = 'Delivery',
  OPEN = 'Open',
  CLICK = 'Click',
  DELIVERY_DELAY = 'DeliveryDelay',
}

export enum MessageSourceType {
  Event = 'event',
  Group = 'group',
  Champions = 'champions',
}
export interface Message {
  id: number
  messageId?: string
  from?: string
  to?: string
  subject?: string
  uuid?: string
}

export enum MessageStatus {
  SENT = 'SENT',
  FAILED = 'FAILED',
  SENDING = 'SENDING',
}

export interface MessageInfo {
  id: number
  recipient: PersonRef
  recipientContact: string
  status: MessageStatus
  method: SendMethod
  withheld?: boolean
  reasonWithheld?: string
}

export interface EmailEvent {
  id: number
  createdAt: Date
  organization?: OrgNamedRef
  message?: Message
  sentBy?: PersonRef
  sentTo?: PersonRef
  eventType: EmailEventType
  template: string
  tags?: any
  emailId?: number
}

export interface SmsEvent {
  organization?: OrgNamedRef
  succeeded: boolean
  smsMessageId: string
  providerResponse: string
  smsType: string
  sentBy?: PersonRef
  sentTo?: PersonRef
  message?: Message
}

export interface OpportunityOccurrenceNamedRef {
  id: number
  name: string
  date: string
}

export interface NotificationBlastSummary {
  uuid: string
  createdAt: string | Date
  subject: string
  body?: string
  userEnteredSubject?: string
  emailCount: number
  smsCount: number
  failedEmailEvents: EmailEvent[]
  failedSMSEvents: SmsEvent[]
  emailsStillSending: MessageInfo[]
  smsStillSending: MessageInfo[]
  blockedEmails: MessageInfo[]
  blockedSMSs: MessageInfo[]
  sourceType?: MessageSourceType
  opportunity?: OpportunityOccurrenceNamedRef
  group?: NamedRef
  organization?: OrgNamedRef
  attachments?: {
    questionnaires?: { questionSet: NamedRef; survey?: NamedRef; required: boolean }[]
  }
}

export interface SubjectAndBody {
  subject: string
  body?: string | null
}

export interface NotificationBlast extends SubjectAndBody {
  emailPersonIds: number[]
  smsPersonIds: number[]
  sourceType?: MessageSourceType
  sourceId?: number | string
  groupId?: number
  invitationId?: number
  opportunityId?: number
  dateString?: string
  attachments?: {
    questionnaires?: number[]
  }
}

export interface MessagingContact {
  id: number
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
}

export enum NotificationBlastMessageStatus {
  SENT = 'SENT',
  FAILED = 'FAILED',
  SENDING = 'SENDING',
}

export interface NotificationBlastMessage {
  id: number
  recipient?: PersonRef
  recipientContact: string
  status: NotificationBlastMessageStatus
  method: SendMethod
}

export interface NotificationBlastReportingWithMessages extends NotificationBlastSummary {
  messages: NotificationBlastMessage[]
}

export interface MessageInvitation {
  id: number
  invitationType: InvitationType
  invitationStatus: InvitationStatus
  displayStatus: InvitationDisplayStatus
}

export interface TemplateVersion {
  id: number
  templateId: number
  template: string
  description: string
  version: number
  updatedAt: Date
}

export interface AutomatedMessage {
  createdAt: string | Date
  method: SendMethod
  recipients: PersonRef[]
  recipientContacts: string[]
  sourceType: MessageSourceType
  opportunity?: OpportunityOccurrenceNamedRef
  group?: NamedRef
  registration?: OpportunityOccurrenceNamedRef
  invitation?: MessageInvitation
  failedEmailEvents: any[]
  failedSMSEvents: any[]
  emailsStillSending: MessageInfo[]
  smsStillSending: MessageInfo[]
  templateVersion: TemplateVersion
}
