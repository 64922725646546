import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const useCreateCampaign = () => {
  const [request, status] = useFetch()
  const organization = useCurrentOrg()

  const createCampaign = useCallback(() => {
    return request({
      method: 'post',
      url: `/organizations/${organization.id}/campaigns`,
    })
  }, [organization.id, request])

  return [createCampaign, status]
}

export default useCreateCampaign
