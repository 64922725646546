import { Person } from 'people/interface'
import useTagsManagement from '../../new-mentorship/hooks/useTagsManagement'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import { AddRemoveTagsPrompt } from '../components/AddRemoveTagsPrompt'
import { useCreateApiNotification } from '../../civic-champs-shared/api/hooks'
import { useCallback } from 'react'

export const useAddRemoveTagsPrompt = (
  onTagsChanged: (personIds: number[], addTagIds: number[], removeTagIds: number[]) => Promise<void>,
): ((persons: Person[]) => Promise<void>) => {
  const { addRemoveTagsToPersons } = useTagsManagement()
  const showPrompt = useShowPrompt(AddRemoveTagsPrompt)
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (persons: Person[]) => {
      const { personIds, addTagIds, removeTagIds } = await showPrompt({ persons })
      const notification = createNotification('Updating tags')
      try {
        await addRemoveTagsToPersons(personIds, addTagIds, removeTagIds)
        onTagsChanged(personIds, addTagIds, removeTagIds)
        notification.onSuccess('Tags updated')
      } catch (e) {
        notification.onError('Error updating tags', e)
      }
    },
    [addRemoveTagsToPersons, createNotification, onTagsChanged, showPrompt],
  )
}
