import React from 'react'
import * as moment from 'moment'
import { Grid, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
  },
  date: {
    width: 80,
  },
}))

export const VolunteerReflections = ({ activities }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={1} direction="column">
      {activities.map(({ occurredAt, opportunity, reflection }) => (
        <Grid item>
          <Grid container spacing={1} direction="row">
            <Grid item className={classes.date}>
              <Typography variant="body">{moment(occurredAt).format('l')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body">"{reflection}"</Typography>
            </Grid>
            {opportunity && (
              <>
                <Grid item>
                  <Typography variant="body" className={classes.label}>
                    Event:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body">{opportunity.name}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
