import React, { useMemo, useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { Prerequisites } from '../types'
import Answers from './Answers'
import { Grid, makeStyles } from '@material-ui/core'
import ChevronButton from '../../core/chevron-button'
import Pagination from '../../core/pagination/Pagination'

const useStyle = makeStyles(() => ({
  questionContainer: ({ fullHeight }: { fullHeight: boolean; autoHeight?: boolean }) => ({
    height: fullHeight ? '90%' : 'auto',
  }),
  answersTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      fontFace: 'Nunito',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
  },
  answersContainer: ({ fullHeight, autoHeight }: { fullHeight: boolean; autoHeight?: boolean }) => ({
    height: autoHeight ? 'auto' : fullHeight ? '100%' : '300px',
    width: '100%',
    overflowY: 'auto',
    textAlign: 'left',
  }),
}))

const useCurrentPrevNextAnswerSet = (requirements: Prerequisites, selectedAnswerSetIndex: number) =>
  useMemo(() => {
    const { questionSets } = requirements
    const questionnaireListLength = questionSets.length
    const answerSet = _.get(questionSets, selectedAnswerSetIndex)
    const hasPrevious = selectedAnswerSetIndex > 0
    const hasNext = selectedAnswerSetIndex < questionSets.length - 1

    return { answerSet, hasPrevious, hasNext, questionnaireListLength }
  }, [requirements, selectedAnswerSetIndex])

interface QuestionAnswerSetsPagedViewProps {
  prerequisites: Prerequisites
  fullHeight: boolean
  autoHeight?: boolean
  skipTitle?: boolean
}

export const QuestionAnswerSetsPagedView = (props: QuestionAnswerSetsPagedViewProps) => {
  const { prerequisites, fullHeight, autoHeight, skipTitle = false } = props
  const classes = useStyle({ fullHeight, autoHeight })
  const [selectedAnswerSetIndex, setSelectedAnswerSetIndex] = useState(0)

  const { answerSet, hasPrevious, hasNext, questionnaireListLength } = useCurrentPrevNextAnswerSet(
    prerequisites,
    selectedAnswerSetIndex,
  )

  if (!answerSet) {
    return null
  }

  return (
    <Grid container direction="row" justify="space-between" className={classes.questionContainer}>
      <Grid item className={classes.answersContainer}>
        {answerSet.answerSetCreatedAt ? (
          <>
            <h3 className={classes.answersTitle}>
              <div>
                {!skipTitle && <>{answerSet.name} responses at </>}
                {moment(answerSet.answerSetCreatedAt).format('lll')}
              </div>
              <div>Version: {answerSet.surveyVersion || '1'}</div>
            </h3>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Answers answers={answerSet.questions} showQuestionLabelEvenIfNotCompleted={true} />
              </Grid>
            </Grid>
          </>
        ) : (
          <h3 className={classes.answersTitle}>{answerSet.name} was skipped</h3>
        )}
      </Grid>
      {questionnaireListLength > 1 && (
        <Grid item style={{ width: '100%' }} container justify="flex-end" direction="column">
          <Grid container direction="row">
            <Grid item xs={2}>
              <ChevronButton
                direction="left"
                onClick={() => setSelectedAnswerSetIndex(selectedAnswerSetIndex - 1)}
                disabled={!hasPrevious}
              />
            </Grid>
            <Grid item container justify="center" alignContent="center" xs={8}>
              <Pagination
                // @ts-ignore
                count={questionnaireListLength}
                color="secondary"
                hideNextButton={true}
                hidePrevButton={true}
                page={selectedAnswerSetIndex + 1}
                onChange={(event: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
                  setSelectedAnswerSetIndex(page - 1)
                }
              />
            </Grid>
            <Grid item xs={2}>
              <ChevronButton
                direction="right"
                onClick={() => setSelectedAnswerSetIndex(selectedAnswerSetIndex + 1)}
                disabled={!hasNext}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
