/**
 * This is modified version of useGlobalFilter from react-table
 * Changes:
 *  - logic of filtering subRows is changed
 *  - original logic filtered parent rows first and then additionally subRows inside left parent rows
 *  - new logic filters subRows first and then removes parent rows, that has no subRows left.
 *  - filtering on parent rows columns by data in not performed
 */
import React from 'react'

import { getFilterMethod, getFirstDefined, shouldAutoRemoveFilter } from 'react-table/src/utils'

import { actions, functionalUpdate, useGetLatest, useMountedLayoutEffect } from 'react-table'

import * as filterTypes from 'react-table/src/filterTypes'

// Actions
actions.resetGlobalFilter = 'resetGlobalFilter'
actions.setGlobalFilter = 'setGlobalFilter'

export const useGlobalFilter = hooks => {
  hooks.stateReducers.push(reducer)
  hooks.useInstance.push(useInstance)
}

useGlobalFilter.pluginName = 'useGlobalFilter'

function reducer(state, action, previousState, instance) {
  if (action.type === actions.resetGlobalFilter) {
    return {
      ...state,
      globalFilter: instance.initialState.globalFilter || undefined,
    }
  }

  if (action.type === actions.setGlobalFilter) {
    const { filterValue } = action
    const { userFilterTypes } = instance

    const filterMethod = getFilterMethod(instance.globalFilter, userFilterTypes || {}, filterTypes)

    const newFilter = functionalUpdate(filterValue, state.globalFilter)

    //
    if (shouldAutoRemoveFilter(filterMethod.autoRemove, newFilter)) {
      const { globalFilter, ...stateWithoutGlobalFilter } = state
      return stateWithoutGlobalFilter
    }

    return {
      ...state,
      globalFilter: newFilter,
    }
  }
}

function useInstance(instance) {
  const {
    data,
    rows,
    flatRows,
    rowsById,
    allColumns,
    filterTypes: userFilterTypes,
    globalFilter,
    manualGlobalFilter,
    state: { globalFilter: globalFilterValue },
    dispatch,
    autoResetGlobalFilter = true,
    disableGlobalFilter,
  } = instance

  const setGlobalFilter = React.useCallback(
    filterValue => {
      dispatch({ type: actions.setGlobalFilter, filterValue })
    },
    [dispatch],
  )

  // TODO: Create a filter cache for incremental high speed multi-filtering
  // This gets pretty complicated pretty fast, since you have to maintain a
  // cache for each row group (top-level rows, and each row's recursive subrows)
  // This would make multi-filtering a lot faster though. Too far?

  const [globalFilteredRows, globalFilteredFlatRows, globalFilteredRowsById] = React.useMemo(() => {
    if (manualGlobalFilter || typeof globalFilterValue === 'undefined') {
      return [rows, flatRows, rowsById]
    }

    const filteredFlatRows = []
    const filteredRowsById = {}

    const filterMethod = getFilterMethod(globalFilter, userFilterTypes || {}, filterTypes)

    if (!filterMethod) {
      console.warn(`Could not find a valid 'globalFilter' option.`)
      return rows
    }

    allColumns.forEach(column => {
      const { disableGlobalFilter: columnDisableGlobalFilter } = column

      column.canFilter = getFirstDefined(
        columnDisableGlobalFilter === true ? false : undefined,
        disableGlobalFilter === true ? false : undefined,
        true,
      )
    })

    const filterableColumns = allColumns.filter(c => c.canFilter === true)

    // Filters top level and nested rows
    const filterRows = filteredRows => {
      filteredRows = filteredRows.map(row => ({
        ...row,
        subRows: row.subRows && row.subRows.length ? filterRows(row.subRows) : row.subRows,
      }))

      if (!filteredRows.length) {
        return filteredRows
      }

      // If we're in parent row
      if (filteredRows[0].depth === 0) {
        filteredRows = filteredRows.filter(row => row.subRows.length)
      } else {
        filteredRows = filterMethod(
          filteredRows,
          filterableColumns.map(d => d.id),
          globalFilterValue,
        )
      }

      filteredRows.forEach(row => {
        filteredFlatRows.push(row)
        filteredRowsById[row.id] = row
      })

      return filteredRows
    }

    return [filterRows(rows), filteredFlatRows, filteredRowsById]
  }, [
    manualGlobalFilter,
    globalFilterValue,
    globalFilter,
    userFilterTypes,
    allColumns,
    rows,
    flatRows,
    rowsById,
    disableGlobalFilter,
  ])

  const getAutoResetGlobalFilter = useGetLatest(autoResetGlobalFilter)

  useMountedLayoutEffect(() => {
    if (getAutoResetGlobalFilter()) {
      dispatch({ type: actions.resetGlobalFilter })
    }
  }, [dispatch, manualGlobalFilter ? null : data])

  Object.assign(instance, {
    preGlobalFilteredRows: rows,
    preGlobalFilteredFlatRows: flatRows,
    preGlobalFilteredRowsById: rowsById,
    globalFilteredRows,
    globalFilteredFlatRows,
    globalFilteredRowsById,
    rows: globalFilteredRows,
    flatRows: globalFilteredFlatRows,
    rowsById: globalFilteredRowsById,
    setGlobalFilter,
    disableGlobalFilter,
  })
}
