import { useCallback } from 'react'
import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { EventPayload } from 'Event/helpers/add-edit-helpers'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'

export const useUpdateSchedulableOpportunity = (): [
  (body: EventPayload, encodedOccurrence: string, isDraft?: boolean) => Promise<OpportunityResponseWithRecurrenceInfo>,
  Status<OpportunityResponseWithRecurrenceInfo>,
] => {
  const [request, status] = useFetchRefactored<OpportunityResponseWithRecurrenceInfo>()

  const updateSchedulableOpportunity = useCallback(
    (body: EventPayload, encodedOccurrence: string, isDraft: boolean = false) => {
      return request({
        method: 'put',
        url: `/opportunity_instances/${encodedOccurrence}/${isDraft ? 'draft' : 'publish'}`,
        config: {
          body,
        },
      })
    },
    [request],
  )

  return [updateSchedulableOpportunity, status]
}

export default useUpdateSchedulableOpportunity
