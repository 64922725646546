import React, { useMemo } from 'react'
import { Field } from 'formik'
import { useCurrentOrg } from 'auth/hooks'
import { yup } from 'civic-champs-shared/api/utils'
import { StyledOutlinedTextField } from 'civic-champs-shared/formik/components'
import { StyledAutocomplete } from 'components/StyledAutocomplete'
import { timezones } from 'civic-champs-shared/helpers/timezones'
import OrganizationModal from 'organization/component/OrganizationModal'
import { FormikProps } from 'formik/dist/types'

const https = 'https://'
const validationSchema = yup.object({
  name: yup.string().min(1).required(),
  website: yup.string().url().required(),
})
const formikHandler = ({ values, setFieldValue }: FormikProps<Record<string, any>>) => {
  if (values.website === '' || (https.startsWith(values.website) && values.website !== https)) {
    setFieldValue('website', https)
  }
}

export default function OrganizationDataModal(props: {
  showing: boolean
  complete: (...props: any) => Promise<any>
  cancel: () => Promise<any>
}) {
  const org = useCurrentOrg()
  const initialValues = useMemo(
    () => ({
      name: org.name,
      website: org.website,
      timeZone: timezones.find(({ value }) => value === org.timeZone),
    }),
    [org],
  )

  return (
    <OrganizationModal
      {...props}
      name="data"
      title="Edit Organization Details"
      subtitle="Organization Details"
      initialValues={initialValues}
      validationSchema={validationSchema}
      formHandler={formikHandler}
    >
      <Field
        component={StyledOutlinedTextField}
        id="name"
        name="name"
        label="Organization Name*"
        variant="outlined"
        fullWidth
      />
      <Field
        name="timeZone"
        placeholder="Time Zone"
        label="Time Zone*"
        component={StyledAutocomplete}
        options={timezones}
        getOptionLabel={({ name }: { name: string; value: string }) => name}
        notched={true}
      />
      <Field
        component={StyledOutlinedTextField}
        id="website"
        name="website"
        label="Website*"
        variant="outlined"
        fullWidth
      />
    </OrganizationModal>
  )
}
