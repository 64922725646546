import React, { useState } from 'react'
import { makeStyles, Grid, Button, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'

const useStyles = makeStyles(() => ({
  settingsText: {
    fontSize: '16px',
  },
  editButton: {
    color: '#5C8DE8',
    marginLeft: '10px',
  },
}))

export default function InlineTextEditField(props) {
  const { onSubmit, name, value, label, required, readOnly, multiline, rows } = props
  const [editing, setEditing] = useState(false)
  const classes = useStyles()

  return editing ? (
    <Formik
      initialValues={{ [name]: value }}
      validationSchema={yup.object({ [name]: yup.string().trim()[required ? 'required' : 'notRequired']() })}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2} direction="row" alignItems="center">
              <Grid item>
                <Field
                  component={TextField}
                  id={name}
                  name={name}
                  label={label}
                  multiline={multiline}
                  rows={rows}
                  style={multiline ? { minWidth: '500px' } : {}}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <Button onClick={() => setEditing(false)}>Cancel</Button>
                <Button type="submit" disabled={!isValid || isSubmitting}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Formik>
  ) : (
    <>
      <Typography variant="body1" className={classes.settingsText}>
        {value}
      </Typography>
      {readOnly || (
        <Button variant="text" className={classes.editButton} onClick={() => setEditing(true)}>
          EDIT
        </Button>
      )}
    </>
  )
}
