import { useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useMembers } from './useMembers'
import useRemoveMemberPrompt from './useRemoveMemberPrompt'
import useAddGroupMember from 'group/hooks/useAddGroupMember'
import { AddMemberType } from 'civic-champs-shared/core/add-person/AddPersonFlow'
import useAddMemberPrompt from 'group/hooks/useAddMemberPrompt'

const useMembersCollection = group => {
  const [members, operations] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection } = operations
  const { refresh } = useMembers(group.id, result => {
    syncCollection(result)
    setInitiallyLoaded(true)
  })

  const [addGroupMember] = useAddGroupMember()
  const addMember = ({ id, userData, sendInvite }) => {
    const payload = id
      ? {
          userId: id,
          type: AddMemberType.ExistingUser,
          sendInvite,
        }
      : {
          ...userData,
          type: AddMemberType.NewUser,
          sendInvite,
        }
    return addGroupMember(group.id, payload).then(operations.eagerAdd)
  }
  const addMemberLegacy = useAddMemberPrompt(group.id, operations.eagerAdd)
  const removeMember = useRemoveMemberPrompt(group, operations.eagerRemoveMany)

  // TODO: possibly handle 'loaded' better and definitely integrate useBulkUpdateMembers
  return [
    {
      members,
      initiallyLoaded: initiallyLoaded,
    },
    {
      addMember,
      addMemberLegacy,
      removeMember,
      refresh,
    },
  ]
}

export default useMembersCollection
