import React from 'react'
import PageHeader from 'App/components/PageHeader'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import { Webhooks } from 'integrations/components/Webhooks'
import { LittleGreenLight } from 'integrations/components/LittleGreenLight'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '10px 0 20px',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
})

export const IntegrationsComponent = () => {
  const lglIntegrationEnabled = useFeatureEnabled('LglIntegration')
  const apiKeyEnabled = useFeatureEnabled('ApiKey')
  const superAdmin = useHasRole(SUPER_ADMIN)
  const [scrollTop, setScrollTop] = React.useState(0)
  const handleScroll = useHandleScroll(setScrollTop)
  const classes = useStyles()

  return (
    <div className={classes.root} onScroll={handleScroll}>
      {apiKeyEnabled && <Webhooks scrollTop={scrollTop} />}
      {lglIntegrationEnabled && superAdmin && <LittleGreenLight scrollTop={scrollTop} />}
    </div>
  )
}
export const Integrations = () => {
  return (
    <PageHeader title="Integrations" subTitle="Manage integrations with other services">
      <IntegrationsComponent />
    </PageHeader>
  )
}

export default Integrations
