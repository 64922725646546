import _ from 'lodash';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import cn from 'classnames';

import useStyles from './useStyles';

//TODO move to a utility file
const redact = (string) => _.repeat('*', _.size(string))

const redactEmail = email => {
  if(!email) return email;

  let match = email.match(/^(.)(.*)(.@.*$)/i)
  if(match) {
    let [ , first, toCensor, rest ] = match;
    return first + redact(toCensor) + rest;
  } else {
    match = email.match(/^(.*)(@.*)$/i);
    let [ , address, domain ] = match;
    return redact(address) + domain;
  }
}

const redactPhone = phone =>  {
  if(!phone) return phone;
  return '(###) ###-' + phone.substring(phone.length-4);
}

export default function CheckboxListSecondary({ users, selected, onSelect, timesheetMode }) {
  const classes = useStyles();
  const selectUser = (user) => {
    if (!user.missingRequiredMemberships) {
      onSelect(user)
    }
  }
  return (
    <Grid item className={classes.searchResult}>
      <List dense className={classes.root}>
        {users.map(value => {
          const labelId = `checkbox-list-secondary-label-${value}`;
          const selectedId = _.get(selected, 'id')
          const isSelected = selectedId != null && selectedId === _.get(value, 'id');
          return (
            <Paper
              key={value.id}
              className={cn(
                classes.listItemWrapper,
                isSelected &&
                  (timesheetMode === 'check-in' ? classes.checkedInSelected : classes.checkedOutSelected)
              )}
            >
              <ListItem 
                button 
                onClick={() => selectUser(value)}
                disabled={!!value.missingRequiredMemberships}
                className={cn(classes.listItem, { [classes.disabled]: value.missingRequiredMemberships })}
              >
                <ListItemText id={labelId}>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      {`${value.user.givenName} ${value.user.familyName} `}
                    </Grid>
                    <Grid item xs={4}>
                      {redactEmail(value.user.email)}
                    </Grid>
                    <Grid item xs={4}>
                      {redactPhone(value.user.phoneNumber) || redactPhone(value.user.homePhoneNumber)}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemSecondaryAction>
                  {isSelected && (
                    <Checkbox
                      edge="end"
                      checked={true}
                      inputProps={{ 'aria-labelledby': labelId }}
                      className={cn(timesheetMode === 'check-out' && classes.checkedCheckbox)}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </Paper>
          );
        })}
      </List>
    </Grid>
  );
}
