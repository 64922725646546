import React from 'react'
import './style.scss'
export const Loader = () => {
  return (
    <div className="loader_wrapper">
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
