import _ from 'lodash';
import { connect } from 'react-redux';

import KioskSignUp from './KioskSignUp';
import { checkIn, signUpVolunteer, signUpVolunteerFailed, signUpVolunteerSucceeded } from '../actions'
import { organizationName, getOrganizationId, getEncodedOccurrence } from '../selectors'

const mapStateToProps = state => ({
    organizationName: organizationName(state),
    organizationId: getOrganizationId(state) ,
    submitting: _.get(state, 'kiosk.signUpVolunteer.running', false), // TODO move to selector
    error: _.get(state, 'kiosk.signUpVolunteer.error'),
    encodedOccurrence: getEncodedOccurrence(state),
});

const mapDispatchToProps = dispatch => ({
    signUpVolunteerStarted: () => dispatch( signUpVolunteer() ),
    signUpVolunteerSucceeded: (newVolunteer) => dispatch(signUpVolunteerSucceeded(newVolunteer) ),
    signUpVolunteerFailed: (error) => dispatch(signUpVolunteerFailed(error) ),
    checkIn: (newVolunteer) => dispatch(checkIn(newVolunteer) ),
    setSignUpError: (msg) => dispatch( signUpVolunteerFailed(msg) )
});

export default connect(mapStateToProps, mapDispatchToProps)(KioskSignUp);
