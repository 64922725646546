import React, { useEffect, useRef } from 'react'
import InputComponent from '../../../../../../components/input/index'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { INIT_VALIDATOR } from '../../../../../../helpers/validation'
import { disablePastingNonNumbers } from '../../../../../../helpers/disablePastingNonNumbers'
import { disableEntryNegativeNumbers } from '../../../../../../helpers/disableEntryNegativeNumbers'
import { RoleAutocomplete } from 'Event/components/role-autocomplete/'

import './index.scss'

const defaultErrorMessages = {
  role: '',
  available: '',
}

const RoleListItem = props => {
  const {
    role,
    roles,
    onChangeRoleField,
    onCreateRole,
    onRemoveRole,
    enableDelete,
    isClickedToNextStep,
    onChangeValidField,
    timeshifts,
    disabled = false,
  } = props
  const availableRef = useRef(null)

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  const handleChangeField = type => value => {
    onChangeRoleField({ [type]: value })
  }

  const handleChangeRole = role => {
    const { id, name } = role || {}
    const newRole = { id, name }
    onChangeRoleField(newRole)
    validateRole(newRole)
  }

  const checkRoleField = field => {
    return timeshifts.every(shift => shift.roles.every(role => role[field]))
  }

  const validateRole = role =>
    onChangeErrorMessage('role', role && role.id && role.name ? '' : 'The role field is required')

  useEffect(() => {
    if (isClickedToNextStep !== null && isClickedToNextStep) {
      !role.available && onChangeErrorMessage('available', 'The available field is required')
      !role.id && !role.name && onChangeErrorMessage('role', 'The role field is required')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedToNextStep])

  useEffect(() => {
    const isHaveNotErrors = !errorMessages.role && !errorMessages.available
    onChangeValidField(checkRoleField('name') && checkRoleField('available') && isHaveNotErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages.role, errorMessages.available, timeshifts])

  useEffect(() => {
    ;(!role.name || !role.available) && onChangeValidField(null)
    const availableInput = availableRef.current.getElementsByTagName('input')[0]
    availableInput.addEventListener('keypress', disableEntryNegativeNumbers, false)
    availableInput.addEventListener('paste', disablePastingNonNumbers, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeIconClassNames = classNames('shift-close-icon', {
    'shift-close-icon--hidden': !enableDelete,
  })

  return (
    <div className="shift-role-list__item">
      <span className="shift-role-list__item-indicator" />
      <div className="shift-role-list__item-role">
        <RoleAutocomplete
          label="Role*"
          value={role}
          options={roles}
          onChange={handleChangeRole}
          onCreate={async () => {
            validateRole(null)
            const role = await onCreateRole()
            if (role) {
              handleChangeRole(role)
            }
          }}
          hasError={!!errorMessages.role}
          error={errorMessages.role}
          disabled={disabled}
        />
      </div>
      <div ref={availableRef} className="shift-list-item-row__item-number">
        <InputComponent
          type="number"
          label="# available*"
          value={role.available}
          onChange={handleChangeField('available')}
          onBlur={onValidateField('available', { required: true, minValue: 1 })}
          hasError={!!errorMessages.available}
          errorMessage={errorMessages.available}
        />
      </div>
      <span onClick={onRemoveRole} className={closeIconClassNames} />
    </div>
  )
}

RoleListItem.propTypes = {
  role: PropTypes.object,
  onChangeRoleField: PropTypes.func,
  onRemoveRole: PropTypes.func,
  isClose: PropTypes.bool,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default RoleListItem
