import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'

const useSetVolunteerStatus = (): ((payload: { status: string; personId: number }) => Promise<void>) => {
  const organization = useCurrentOrg()
  const [request] = useFetchRefactored<void>({
    successMessage: 'Status set successfully',
    errorMessage: 'Error setting status',
    useNewNotification: true,
  })

  return useCallback(
    ({ status, personId }: { status: string; personId: number }) => {
      return request({
        method: 'put',
        url: `/organizations/${organization.id}/volunteers/${personId}/status`,
        config: {
          body: { status },
        },
      })
    },
    [organization.id, request],
  )
}

export default useSetVolunteerStatus
