import { filter } from 'lodash'
import { useMemo } from 'react'
import { DateCell, FixedPointCell, CurrencyCell, PhoneNumberCell, ContactCell, ViewCell } from 'core/table/cells'
import format from 'civic-champs-shared/utils/format'
import { createDateValueSort, createContactSort } from 'components/table/sort'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled } from 'core/feature/hooks'
import { contactAccessor } from 'core/table/utils'

export default function useChampionColumns() {
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const isDonationEnabled = useFeatureEnabled('Donations')

  return useMemo(
    () =>
      filter(
        [
          { id: 'firstName', Header: 'First Name', accessor: 'user.givenName' },
          { id: 'lastName', Header: 'Last Name', accessor: 'user.familyName' },
          {
            id: 'email',
            Header: 'Email',
            sortType: createContactSort('email'),
            accessor: contactAccessor({
              valuePath: 'user.email',
              verificationStatusPath: 'user.emailVerified',
              type: 'email',
            }),
            Cell: ContactCell,
          },
          {
            id: 'mobile',
            Header: 'Mobile',
            sortType: createContactSort('mobile'),
            accessor: contactAccessor({
              valuePath: 'user.phoneNumber',
              verificationStatusPath: 'user.phoneNumberVerified',
              type: 'phone',
            }),
            Cell: ContactCell,
          },
          {
            id: 'homePhoneNumber',
            Header: 'Home',
            accessor: 'user.homePhoneNumber',
            Cell: PhoneNumberCell,
          },
          {
            id: 'hours',
            Header: 'Hours',
            accessor: 'volunteering.totalVolunteerHours',
            Cell: FixedPointCell,
            sortType: 'basic',
          },
          {
            id: 'donations',
            Header: 'Donations',
            accessor: (volunteer: { donation: { totalDonations: number } }) =>
              format.fixedPoint(volunteer.donation.totalDonations / 100, 2),
            Cell: CurrencyCell,
            // we can have a more sophisticated approach if we want to have more permissions
            superAdminsOnly: true,
            hideColumn: !isDonationEnabled || !isSuperAdmin,
          },
          {
            id: 'latestActivity',
            Header: 'Latest Activity',
            accessor: 'volunteering.latestActivity',
            Cell: DateCell,
            sortType: createDateValueSort('volunteering.latestActivity'),
          },
          {
            Header: '',
            id: 'view',
            Cell: ViewCell((id: number) => `/champion/${id}`, 'user.id'),
          },
        ],
        column => !column.hideColumn,
      ),
    [isSuperAdmin, isDonationEnabled],
  )
}
