import { useCallback, useEffect, useMemo, useRef } from 'react'

type MessageListener = (this: BroadcastChannel, ev: BroadcastChannelEventMap['message']) => any
export const useBroadcastChannel = (channelName?: string | null) => {
  const channel = useRef<BroadcastChannel | null>(channelName ? new BroadcastChannel(channelName) : null)
  const listener = useRef<MessageListener | null>(null)
  useEffect(() => {
    return () => {
      if (channel.current && listener.current) {
        channel.current.removeEventListener('message', listener.current)
      }
    }
  }, [])

  const addOrReplaceMessageListener = useCallback(
    (messageListener: MessageListener, options?: AddEventListenerOptions) => {
      if (channel.current) {
        if (listener.current) {
          channel.current.removeEventListener('message', listener.current)
        }
        listener.current = messageListener
        channel.current.addEventListener('message', listener.current, options)
      }
    },
    [],
  )

  return useMemo(
    () => ({
      postMessage: channel.current ? channel.current.postMessage.bind(channel.current) : () => {},
      addOrReplaceMessageListener,
    }),
    [addOrReplaceMessageListener],
  )
}
