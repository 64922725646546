import { GetDeleteProps, Refresh, Status } from './fetchRefactoredSchema'
import { useCallback } from 'react'
import useFetchRefactored from './useFetchRefactored'

export default function useGet<T>(props: GetDeleteProps<T>): [Refresh<T>, Status<T>] {
  const { errorMessage, successMessage, emptyValue, configMapper } = props
  const [request, status] = useFetchRefactored({ errorMessage, successMessage, emptyValue })
  const refresh = useCallback(
    async (params: any) => {
      const { url, onSuccess, onError, config } = configMapper(params)
      return request({
        method: 'get',
        url,
        onError,
        onSuccess,
        config,
      })
    },
    [request, configMapper],
  )
  return [refresh, status]
}
