import React from 'react'
import RequestsTab from './RequestsTab'
import BackLink from 'donation/campaigns/components/BackLink'
import { Typography } from '@material-ui/core'
import usePerson from '../../../people/hooks/usePerson'
import Loading from '../../../components/Loading'
import { useCurrentOrg } from '../../../auth/hooks'

export default function TaskPersonRequests(props) {
  const personId = Number(props.match.params.id)
  const currentOrg = useCurrentOrg()
  const { loading, person } = usePerson({ id: personId, organizationId: currentOrg.id })
  if (loading) {
    return <Loading/>
  }

  return (
    <div>
      <BackLink to="/helping-hands" text="Back to Helping Hands Overview" />
      <Typography variant="h5" style={{ marginTop: 25, marginBottom: 25 }}><b>TASK PROFILE:</b> {person.givenName} {person.familyName}</Typography>
      <RequestsTab personId={personId} includeClosedAndCompleted />
    </div>
  )
}
