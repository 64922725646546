import React from 'react'
import cn from 'classnames'
import { Button, Card, Grid, Typography } from '@material-ui/core'
import Icon from '@mdi/react'
import { mdiAccountPlus, mdiAccountRemove } from '@mdi/js'

import { useTaskStyles } from './Task'
import { PersonInfo } from 'volunteering/components/PersonInfo'
import TaskStatus, { isActive } from '../TaskStatus'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import { useHistory } from 'react-router'
import { useRemoveVolunteerFromTask } from '../hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

const AccountRemoveIcon = () => <Icon path={mdiAccountRemove} size={1} color="white" />

const AccountAddIcon = () => <Icon path={mdiAccountPlus} size={1} />

const UnassignButton = props => {
  const { className, ...rest } = props
  const classes = useTaskStyles()
  return (
    <Button
      variant="contained"
      className={cn(classes.cancelButton, className)}
      startIcon={<AccountRemoveIcon />}
      {...rest}
    >
      Unassign
    </Button>
  )
}

const AssignButton = props => (
  <Button variant="contained" color="primary" startIcon={<AccountAddIcon />} {...props}>
    Assign
  </Button>
)

export default function TaskPeople(props) {
  const { task, refreshTask } = props
  const { requester, recipient, volunteer } = task
  const classes = useTaskStyles()
  const confirmPrompt = useConfirm()
  const showError = useErrorNotification()
  const history = useHistory()

  const [removeVolunteer] = useRemoveVolunteerFromTask()

  const onAddVolunteer = () => {
    history.push(`/tasks/${task.id}/add-volunteer`)
  }

  const onRemoveVolunteer = async person => {
    const confirm = await confirmPrompt(`Are you sure you want to remove ${person.givenName}?`, {
      title: 'Are you sure?',
      confirmText: 'Yes',
      rejectText: 'No',
    })

    if (confirm) {
      try {
        await removeVolunteer(task)
        refreshTask()
      } catch (err) {
        showError('There was a problem removing the volunteer.', err)
      }
    }
  }

  return (
    <Grid container direction="row" spacing={4} className={classes.row}>
      <Grid item md={4}>
        <Card className={classes.taskPersonCard}>
          <PersonInfo title="Requester" person={requester} />
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className={classes.taskPersonCard}>
          <PersonInfo title="Recipient" person={recipient} />
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className={classes.taskPersonCard}>
          {!!volunteer ? (
            <PersonInfo
              title="Volunteer"
              person={volunteer}
              actionButton={isActive(task) && <UnassignButton onClick={() => onRemoveVolunteer(volunteer)} />}
            />
          ) : (
            <PersonInfo
              title="Volunteer"
              actionButton={
                <AssignButton onClick={onAddVolunteer} disabled={task.taskStatus !== TaskStatus.Accepted} />
              }
              emptyContent={
                task.taskStatus !== TaskStatus.Accepted && (
                  <p className={classes.warning}>Task must be approved before volunteer is assigned</p>
                )
              }
            />
          )}
        </Card>
      </Grid>
    </Grid>
  )
}
