import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import store, { history, persistor } from './store'
import { Provider } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import MentorshipRoutes from 'Mentorship/Routes'
import './config-amplify'
import './index.scss'
import * as Sentry from '@sentry/react'
import { muiTheme } from 'legacy-theme'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
}

render(
  <ThemeProvider theme={muiTheme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {
          // TODO research and fix this condition with better solution
          /\/mentorship\/feedback\/(\S+)$/gi.test(window.location.href) ? (
            <MentorshipRoutes />
          ) : (
            <Router>
              <App history={history} />
            </Router>
          )
        }
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
