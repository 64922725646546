import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AddEditEventPrompt from 'Event/components/AddEditEventPrompt'
import { OpportunityPayload } from 'Event/interfaces'
import useCreateNonSchedulableOpportunity from 'volunteering/opportunities/hooks/useCreateNonSchedulableOpportunity'
import useUpdateNonSchedulableOpportunity from 'volunteering/opportunities/hooks/useUpdateNonSchedulableOpportunity'
import { OpportunityResponse, OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import useCreateSchedulableOpportunity from 'volunteering/opportunities/hooks/useCreateSchedulableOpportunity'
import { EventPayload } from 'Event/helpers/add-edit-helpers'
import useUpdateSchedulableOpportunity from 'volunteering/opportunities/hooks/useUpdateSchedulableOpportunity'

export const useShowEventAddEditPrompt = (onSuccess?: (result: OpportunityResponse) => void) => {
  const showPrompt = useShowPrompt(AddEditEventPrompt)
  const org = useCurrentOrg()
  const [createOpportunity] = useCreateNonSchedulableOpportunity(org.id) as [
    (opportunity: OpportunityPayload) => Promise<any>,
  ]
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity() as [
    (opportunity: OpportunityPayload, encodedOccurrence: string) => Promise<any>,
  ]
  const [createEvent] = useCreateSchedulableOpportunity()
  const [updateEvent] = useUpdateSchedulableOpportunity()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (event?: OpportunityResponse | OpportunityResponseWithRecurrenceInfo) => {
      const result: OpportunityPayload | EventPayload = await showPrompt({ event })
      const notification = createNotification(event ? `Updating Event` : `Creating Event`)
      let response: OpportunityResponse
      try {
        if (result.isSchedulable) {
          if (event) {
            response = await updateEvent(result, encodeOccurrenceFromEvent(event), result.asDraft)
          } else {
            response = await createEvent(result, result.asDraft)
          }
        } else {
          if (event) {
            response = await updateOpportunity(result, encodeOccurrenceFromEvent(event))
          } else {
            response = await createOpportunity(result)
          }
        }

        notification.onSuccess(event ? `Event Updated` : `Event Created`)
        onSuccess?.(response)
      } catch (e) {
        notification.onError(`Failed to ${event ? 'Update' : 'Create'} Event`, e)
      }
    },
    [createEvent, createNotification, createOpportunity, onSuccess, showPrompt, updateEvent, updateOpportunity],
  )
}
