import React from 'react'
import ActionMenu from 'components/menu/ActionMenu'
import ActionMenuItem from 'components/menu/ActionMenuItem'
import { ListItemIcon } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'

export default function CredentialActionMenu(props: {
  selectedId?: number,
  onCreateOrUpdateCredential: (credentialId?: number) => void,
}) {
  return <ActionMenu>
    <div>
      <ActionMenuItem onClick={() => props.onCreateOrUpdateCredential(props.selectedId)} disabled={!props.selectedId} >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        Edit Credential
      </ActionMenuItem>
    </div>
  </ActionMenu>
}