import { useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'
import { RegistrationResponse } from 'Event/scenes/events/detail/volunteers/hooks/RemoveShiftsPrompt'

export const useFetchEventRegistrations = () => {
  const [fetch] = useFetchRefactored<RegistrationResponse[]>()

  return useCallback(
    (encodedOccurrence: string) =>
      fetch({
        method: 'get',
        url: `/opportunity_instances/${encodedOccurrence}/registrations/`,
      }),
    [fetch],
  )
}
