export enum FORM_STEPS {
  BASIC = 1,
  PARTICIPANTS = 2,
  LOCATION = 3,
  SHIFTS = 4,
  QUESTIONS = 5,
  REVIEW = 6
}

export const EVENT_FORM_STEPS = [
  { step: FORM_STEPS.BASIC, title: 'Basic Info', path: 'basic' },
  { step: FORM_STEPS.PARTICIPANTS, title: 'Participants', path: 'participants' },
  { step: FORM_STEPS.LOCATION, title: 'Location', path: 'location' },
  { step: FORM_STEPS.SHIFTS, title: 'Shifts', path: 'shifts' },
  { step: FORM_STEPS.QUESTIONS, title: 'Onboarding', path: 'questions' },
  { step: FORM_STEPS.REVIEW, title: 'Review', path: 'review' },
]
