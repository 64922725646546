import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import {
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    OutlinedInput
} from '@material-ui/core';

//REVIEW I don't care for `/components/table` much.  Does this make sense in a `/core/ui`?  Where do other components go?
export default function SelectList(props) {
    const {
        id, //REVIEW if no id is specified, let's generate one using lodash.  _.uniqueId('select-list-') maybe?
        name,
        label,
        items,
        value,
        getItemRenderer, //REVIEW let's just call this ItemRenderer  (it doesn't get anything)
        onChange,
        error,
        required,
        disabled,
        disabledMessage
    } = props;

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, [])

    const handleChange = (event) => {
        if (_.isFunction(onChange)) {
            onChange(event.target.value);
        }
    }

    return (
        <FormControl
            error={!!error}
            disabled={disabled}
            variant="outlined"
            required={required}
            fullWidth
        >
            <InputLabel ref={inputLabel} htmlFor={id}>{label}</InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                error={!!error}
                input={<OutlinedInput
                    labelWidth={labelWidth}
                    id={id}
                    name={name}
                />}
            >
                {(items || []).map(item => getItemRenderer(item))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
            {disabledMessage && <FormHelperText>{disabledMessage}</FormHelperText>}
        </FormControl>
    );
}