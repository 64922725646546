import { useState, useEffect } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { useFetchSalsaIntegration } from './useFetchSalsaIntegration'
import { useUpdateSalsaIntegration } from './useUpdateSalsaIntegration'
import { useSyncSalsaIntegration } from './useSyncSalsaIntegration'
import { useResolveSalsaMigrationIssues } from './useResolveSalsaMigrationIssues'
import { useDisconnectSalsaIntegration } from './useDisconnectSalsaIntegration'
import { usePauseSalsaIntegration } from './usePauseSalsaIntegration'
import { DEFAULT_SALSA_INTEGRATION } from '../interfaces'

export const useSalsaIntegrationManagement = () => {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)
  const [combinedSalsaIntegration, setCombinedSalsaIntegration] = useState(DEFAULT_SALSA_INTEGRATION)
  const { id: organizationId } = useCurrentOrg()

  const { reFetch, loading: initialLoading } = useFetchSalsaIntegration(result => {
    setCombinedSalsaIntegration(result || DEFAULT_SALSA_INTEGRATION)
  })
  const { updateSalsaIntegration, loading: updateLoading } = useUpdateSalsaIntegration(setCombinedSalsaIntegration)

  const { syncIntegration, loading: syncLoading } = useSyncSalsaIntegration(setCombinedSalsaIntegration)

  const { resolveSalsaMigrationIssues, loading: resolveLoading } = useResolveSalsaMigrationIssues(
    setCombinedSalsaIntegration,
  )

  const { loading: disconnectLoading, disconnectSalsaIntegration } = useDisconnectSalsaIntegration(() => {
    setCombinedSalsaIntegration(DEFAULT_SALSA_INTEGRATION)
  })

  const { loading: pauseLoading, pauseSalsaIntegration } = usePauseSalsaIntegration(setCombinedSalsaIntegration)

  // we only want initialLoadComplete to be set to true once, otherwise the page will reload every time we poll
  useEffect(() => {
    if (!initialLoading) {
      setInitialLoadComplete(true)
    }
  }, [initialLoading])

  return {
    salsaIntegration: combinedSalsaIntegration,
    organizationId,
    loading: updateLoading || syncLoading || resolveLoading || disconnectLoading || pauseLoading,
    initialLoadComplete,
    disconnectLoading,
    pauseSalsaIntegration,
    reFetch,
    updateSalsaIntegration,
    syncIntegration,
    resolveSalsaMigrationIssues,
    disconnectSalsaIntegration,
  }
}
