import moment from 'moment';

import { rank } from 'civic-champs-shared/auth/utils/permissions';
import { TaskStatusDisplayNames } from '../../volunteering/tasks/TaskStatus'

// Use when the accessor for the column is a string. The data for
// the row should have a date with the given accessor as its property name.
export const createDateValueSort = (accessor) => {
    return (rowA, rowB) => {
        let dateA = moment(rowA.values[accessor]);
        let dateB = moment(rowB.values[accessor]);

        return dateSort(dateA, dateB);
    };
};

// Use when the accessor for the column is a function that returns an object.
// The object returned by the accessor function should have a 'value' property that
// represents the date to sort.
export const createDateObjectSort = (columnName) => {
    return (rowA, rowB) => {
        let dateA = moment(rowA.values[columnName].value);
        let dateB = moment(rowB.values[columnName].value);

        return dateSort(dateA, dateB);
    };
};

export const dateSort = (dateA, dateB) => {
    let sortA = dateA.isValid() ? dateA.valueOf() : 0;
    let sortB = dateB.isValid() ? dateB.valueOf() : 0;

    return sortA - sortB;
};

export const createRoleSort = (accessor) => {
    return (rowA, rowB) => {
        const sortA = rank(rowA.values[accessor])
        const sortB = rank(rowB.values[accessor])

        return sortA - sortB
    }
}

export const createPersonSort = (accessor) => {
    return (rowA, rowB) => {
        const personA = rowA.values[accessor]
        const personB = rowB.values[accessor]

        // make sort null safe
        if (!personA) return 1
        if (!personB) return -1

        const makePersonString = (p) => `${p.givenName} ${p.familyName} ${p.email} ${p.phoneNumber}`
        if (makePersonString(personA) > makePersonString(personB)) return 1
        else return -1
    }
}

export const createTaskStatusSort = (accessor) => {
    return (rowA, rowB) => {
        const statusA = rowA.values[accessor]
        const statusB = rowB.values[accessor]
        if (!statusA) return 1
        if (!statusB) return -1
        return TaskStatusDisplayNames[statusA] > TaskStatusDisplayNames[statusB] ? 1 : -1
    }
}

export const createContactSort = (accessor) => {
    return (rowA, rowB) => {
        const contactA = rowA.values[accessor] && rowA.values[accessor].value
        const contactB = rowB.values[accessor] && rowB.values[accessor].value

        if (!contactA) return 1
        if (!contactB) return -1

        if (contactA === contactB) return 0

        return (
          contactA > contactB
              ? 1
              : -1
        )
    }
}

export const createGroupedValueSort = (accessorFn) => {
    return (rowA, rowB) => {
        const rowAValue = accessorFn(rowA)
        const rowBValue = accessorFn(rowB)

        if (rowAValue === rowBValue) return 0

        return (
          rowAValue > rowBValue
            ? 1
            : -1
        )
    }
}

export const createGroupedDateSort = (accessorFn) => {
    return (rowA, rowB) => {
        const rowAValue = accessorFn(rowA)
        const rowBValue = accessorFn(rowB)

        if (rowAValue === rowBValue) return 0

        return (
          moment(rowAValue) > moment(rowBValue)
            ? 1
            : -1
        )
    }
}