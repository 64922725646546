import React, { FC, useCallback, useEffect, useState } from 'react'
import QuestionSetEditor from 'surveys/components/QuestionSetEditor'
import { useSaveSurvey } from 'surveys/hooks'
import useGetSurvey from 'surveys/hooks/useGetSurvey'
import { Loading } from 'civic-champs-shared'

export interface SurveyDetailsProps {
  match: {
    path: string
    url: string
    params: { surveyId?: string }
  }
}

export const MentorshipSurveyEditor: FC<SurveyDetailsProps> = ({
  match: {
    url,
    params: { surveyId },
  },
}) => {
  const [getSurvey, { loading, result: survey }] = useGetSurvey()
  const backLink = url.substr(0, url.length - (surveyId ? 5 : 4))
  const [redirectLink, setRedirectLink] = useState<string | undefined>(undefined)
  useEffect(() => {
    surveyId && getSurvey(parseInt(surveyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [{ loading: surveySaving }, saveSurvey] = useSaveSurvey()
  const onSubmit = useCallback(
    async payload => {
      const result = await saveSurvey(payload, (surveyId && parseInt(surveyId)) as number | undefined)
      !surveyId && setRedirectLink(`${backLink}/${result.id}`)
      return result
    },
    [backLink, saveSurvey, surveyId, setRedirectLink],
  )

  if (loading) {
    return <Loading />
  }
  return (
    <QuestionSetEditor
      isSubmitting={surveySaving}
      onSubmit={onSubmit}
      questionSet={survey?.versions[0].questionSet}
      backLink={backLink}
      redirectLink={redirectLink}
      backText={surveyId ? `Back to ${survey?.versions[0].questionSet?.name}` : 'Back to Surveys'}
      title={surveyId ? 'Edit Survey' : 'New Survey'}
    />
  )
}

export default MentorshipSurveyEditor
