import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export const useAddWhitelistedPhoneNumber = (onSuccess) => {
  const [request, status] = useFetch(
    'Error adding whitelistedPhoneNumber.  Please refresh the page to try again',
  )
  const showSuccess = useSuccessNotification()
  const whitelistPhoneNumber = useCallback(
    phoneNumber => {
      return request({
        method: 'post',
        url: '/whitelist-dev-phone-number',
        config: {
          body: { phoneNumber },
        },
        onSuccess: (res) => {
          onSuccess && onSuccess(res)
          showSuccess(`Successfully added ${phoneNumber}`)
        },
      })
    },
    [request, onSuccess, showSuccess],
  )

  return useMemo(
    () => [
      whitelistPhoneNumber,
      status,
    ],
    [whitelistPhoneNumber, status],
  )
}
