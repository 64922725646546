import { makeStyles } from '@material-ui/core'

export const useProfileAccordionStyles = makeStyles(theme => ({
  panelRoot: {
    boxShadow: 'none',
    marginBottom: '12px',
    '&::before': {
      display: 'none !important',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(960)]: {
      padding: '8px 0 24px',
    },
  },
  summaryRoot: {
    position: 'relative',
    minHeight: 'auto !important',
    [theme.breakpoints.down(960)]: {
      padding: '0',
    },
  },
  content: {
    margin: 0,
    borderBottom: '1px solid #C4C6CF',
  },
  expanded: {
    margin: '0 0 12px 0 !important',
  },
  expandIcon: {
    position: 'absolute',
    padding: 0,
  },
  header: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
  },
}))
