import React, { useState } from 'react'
import * as moment from 'moment'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { KeyboardDatePicker } from 'formik-material-ui-pickers'
import EditIcon from '@material-ui/icons/Edit'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

import PhoneField from 'civic-champs-shared/formik/components/PhoneField'
import format from 'civic-champs-shared/utils/format'
import { AddressField, defaultAddress } from 'civic-champs-shared/formik/components/AddressField'
import { profilePersonSchema } from '../schema'
import { EmergencyContactField } from './EmergencyContactField'
import { pick } from 'lodash'

const useStyles = makeStyles(() => ({
  avatar: {
    width: 100,
    height: 100,
  },
  cardHeaderAvatar: {
    background: '#FFCD00',
    color: '#3d3d3d',
    fontWeight: 'bold',
    '& .MuiCardHeader-title': {
      fontSize: '16px',
    },
  },
  label: {
    fontWeight: 600,
  },
  editButton: {
    color: 'white',
    backgroundColor: '#FF6F3E',
    fontSize: '12px',
  },
  cancelButton: {
    color: 'white',
    backgroundColor: '#FF6F3E',
    fontSize: '12px',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#5C8DE8',
  },
  link: {
    color: '#5C8DE8',
    fontWeight: 600,
  },
  avatarEdit: {
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: '#707070',
        color: '#707070',
      },
    },
    '& .MuiInputBase-root': {
      background: 'white',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '10px 14px',
    },
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
  verifiedIcon: {
    fontSize: '20px',
    color: '#FF6F3E',
    verticalAlign: 'middle',
  },
}))

export const ProfileDetailsCard = ({ user, volunteering, onSubmit }) => {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)

  const { address: userAddress, emergencyContact, birthDate } = user
  const address = userAddress
    ? {
        ...userAddress,
        geofencing: {
          ...defaultAddress().geofencing,
        },
      }
    : defaultAddress()
  const age = birthDate ? moment().diff(moment(birthDate), 'years') : '13+'

  const { givenName, familyName } = user
  const fullName = `${givenName} ${familyName}`
  const { emergencyContactRelationship } = user

  const initialValues = {
    ...pick(user, [
      'givenName',
      'familyName',
      'email',
      'emailVerified',
      'phoneNumber',
      'phoneNumberVerified',
      'homePhoneNumber',
      'birthDate',
      'createdAt',
    ]),
    address,
    emergencyContact:
      emergencyContact != null
        ? {
            ...pick(emergencyContact, [
              'id',
              'givenName',
              'familyName',
              'phoneNumber',
              'workPhoneNumber',
              'homePhoneNumber',
              'email',
            ]),
            relationship: emergencyContactRelationship,
          }
        : null,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profilePersonSchema}
      onSubmit={async values => {
        await onSubmit(
          profilePersonSchema.cast({
            ...values,
            //birthDate: moment(values.birthDate).utc(true),
            emergencyContactRelationship: values.emergencyContact?.relationship,
          }),
        )
        setEditing(false)
      }}
    >
      {({ dirty, resetForm, isSubmitting }) => (
        <Form>
          <Card>
            <CardHeader
              title={
                <Grid container direction="column">
                  <Grid container justify="flex-end">
                    {editing ? (
                      <Button
                        className={classes.cancelButton}
                        variant="contained"
                        size="small"
                        disabled={isSubmitting}
                        onClick={() => {
                          resetForm()
                          setEditing(false)
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        startIcon={<EditIcon />}
                        className={classes.editButton}
                        variant="contained"
                        size="small"
                        disabled={isSubmitting}
                        onClick={() => setEditing(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </Grid>
                  <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item>
                      <Avatar alt={fullName} src={user.avatar?.url} className={classes.avatar}>
                        {givenName[0]}
                        {familyName[0]}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      {editing ? (
                        <Grid container spacing={1} direction="row">
                          <Grid item xs={6}>
                            <Field
                              variant="outlined"
                              size="small"
                              className={classes.avatarEdit}
                              component={TextField}
                              disabled={isSubmitting}
                              placeholder="First Name"
                              name="givenName"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              variant="outlined"
                              size="small"
                              className={classes.avatarEdit}
                              component={TextField}
                              disabled={isSubmitting}
                              placeholder="Last Name"
                              name="familyName"
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Typography variant="h6">{fullName}</Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} direction="row">
                        <Grid item>
                          <Typography variant="body1">Member Since:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">{format.date(volunteering.startedAt)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} direction="row">
                        <Grid item>
                          <Typography variant="body1">Volunteer Status:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1">{volunteering.approvalStatus}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
              className={classes.cardHeaderAvatar}
            />
            <CardContent>
              <Grid container spacing={1} direction="column">
                {[
                  { key: 'email', label: 'Email' },
                  { key: 'phoneNumber', label: 'Mobile' },
                  { key: 'homePhoneNumber', label: 'Home Phone' },
                  //{ key: 'workPhoneNumber', label: 'Work Phone' }
                ]
                  .filter(({ key }) => editing || user[key])
                  .map(item => ({ ...item, verified: user[`${item.key}Verified`] }))
                  .map(({ key, label, verified }) => (
                    <Grid item key={key}>
                      <Grid container spacing={1} direction="row">
                        <Grid item className={classes.centered} xs={3}>
                          <Typography variant="body1" className={classes.label}>
                            {label}:
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {!editing || verified ? (
                            <>
                              <Link className={classes.link}>
                                {key === 'email' ? user[key] : format.phoneNumber(user[key])}
                              </Link>
                            </>
                          ) : (
                            <Field
                              name={key}
                              fullWidth
                              variant="outlined"
                              size="small"
                              disabled={isSubmitting}
                              component={key === 'email' ? TextField : PhoneField}
                            />
                          )}
                        </Grid>
                        {verified && (
                          <Grid item xs={1}>
                            <Tooltip title={`${label} verified`}>
                              <Box component="span" ml="auto">
                                <VerifiedUserIcon className={classes.verifiedIcon} />
                              </Box>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                <Grid item>
                  {editing ? (
                    <Grid container spacing={1} direction="column">
                      <Grid item xs={3}>
                        <Typography variant="body1" className={classes.label}>
                          Address:
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Field
                          fullWidth
                          variant="outlined"
                          size="small"
                          hideMap
                          component={AddressField}
                          name="address"
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    address?.id && (
                      <Grid container direction="row">
                        <Grid item xs={3}>
                          <Typography variant="body1" className={classes.label}>
                            Address:
                          </Typography>
                        </Grid>
                        <Grid item container xs={8} direction="column">
                          <Grid item>
                            <Typography variant="body1">{address.line1}</Typography>
                          </Grid>
                          {address.line2 && (
                            <Grid item>
                              <Typography variant="body1">{address.line2}</Typography>
                            </Grid>
                          )}
                          <Grid item>
                            <Typography variant="body1">
                              {address.city}, {address.state} {address.zip}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid item>
                  <Grid container spacing={1} direction="row">
                    <Grid item className={classes.centered} xs={3}>
                      <Typography variant="body1" className={classes.label}>
                        Age:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {editing ? (
                        <Field
                          disabled={isSubmitting}
                          component={KeyboardDatePicker}
                          className={classes.datePicker}
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          inputVariant="outlined"
                          name="birthDate"
                          fullWidth
                        />
                      ) : (
                        <Typography variant="body1">{age}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Field
                    disabled={isSubmitting}
                    component={EmergencyContactField}
                    name="emergencyContact"
                    label="Emergency Contact"
                    editing={editing}
                  />
                </Grid>
                {editing && (
                  <Grid container spacing={1} direction="column" alignItems="center">
                    <Grid item>
                      <Button
                        className={classes.saveButton}
                        disabled={!dirty || isSubmitting}
                        variant="contained"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  )
}
