import * as React from 'react'
import './index.scss'

const SuccessfullyComponent = () => {
  return (
    <div className="success_wrapper">
      <div className="success_block">
        <div className="success_header">
          Your feedback was logged successfully!
        </div>
        <div className="success_icon">
          <img src="/assets/mentorship/images/success_icon.svg" alt="success"/>
        </div>
      </div>
    </div>
  )
}

export default SuccessfullyComponent
