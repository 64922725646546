import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DateTimeCell, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'
import DeleteIcon from '@material-ui/icons/Delete'
import { CellProps } from 'react-table'
import { Chat, ChatMock } from 'chats/interfaces'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '36px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '239px',
    },
  },
  membersColumn: {
    '&>div': {
      width: '150px',
    },
  },
  lastActiveColumn: {
    '&>div': {
      width: '200px',
    },
  },
  createdColumn: {
    '&>div': {
      width: '150px',
    },
  },
  deleteColumn: {
    '&>div': {
      width: '150px',
      '& svg': {
        color: theme.palette.error.main,
        fontSize: '20px',
        cursor: 'pointer',
      },
    },
  },
}))

export default function useChatsColumns({ onDelete }: { onDelete: (chat: Chat) => void }) {
  const classes = useStyles()

  return useMemo(
    () => [
      {
        id: 'name',
        Header: 'Chat Name',
        accessor: 'name',
        Cell: (props: CellProps<ChatMock, string>) =>
          WithLinkCell(
            NullableTextCell,
            (id: string) => `/chats/${id}`,
          )({ ...props, linkProps: { className: classes.link } }),
        className: classes.nameColumn,
        filter: DYNAMIC_TEXT,
      },
      {
        id: 'membersCount',
        Header: 'Members',
        accessor: 'membersCount',
        Cell: NumberCell,
        className: classes.membersColumn,
        filter: DYNAMIC_NUMBER,
      },
      {
        id: 'lastActive',
        Header: 'Last Active',
        accessor: 'lastActivityAt',
        Cell: DateTimeCell,
        className: classes.lastActiveColumn,
        disableFilters: true,
      },
      {
        id: 'created',
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: DateTimeCell,
        className: classes.createdColumn,
        disableFilters: true,
      },
      {
        id: 'delete',
        Header: <DeleteIcon style={{ color: 'black' }} />,
        accessor: 'id',
        Cell: ({ row: { original } }: CellProps<Chat, string>) => (
          <>
            <DeleteIcon onClick={() => onDelete(original)} />
          </>
        ),
        className: classes.deleteColumn,
        disableFilters: true,
      },
    ],
    [classes], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
