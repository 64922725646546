import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useUpdateCampaign = () => {
  const [request, status] = useFetch()
  const updateCampaign = useCallback(
    campaign => {
      const { id, organization, createdAt, publishedAt, ...body } = campaign
      return request({
        method: 'put',
        url: `/campaigns/${campaign.id}`,
        config: { body },
      })
    },
    [request],
  )

  return [updateCampaign, status]
}

export default useUpdateCampaign
