import React, { useEffect, useMemo, useState } from 'react'
import { CredentialsDetailsHeader } from '../components/CredentialDetailsHeader'
import Loading from '../../components/Loading'
import useCredentialSigningManagement from '../hooks/useCredentialSigningManagement'
import { useTable, useSortBy, usePagination, useFilters, useRowSelect } from 'react-table'
import { DateCell, NullableTextCell } from 'core/table/cells'
import PagedTable from 'core/table/components/PagedTable'
import { DEFAULT_FILTERS, DATE_RANGE } from 'core/table/filters'
import { useDateRangeFilter } from 'filtering/hooks'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useSelectColumn, { selectionColumnId } from '../../core/table/table-hooks/useSelectColumn'
import ClearIcon from '@material-ui/icons/HighlightOff';
import { withCurrentUserHOC } from '../../Event/helpers/withCurrentUserHOC'
import { useUISettings } from 'hooks/useUISettings'
import { useFeatureEnabled } from '../../core/feature/hooks'
import useRemoveWaiverSignaturePrompt from '../hooks/useRemoveWaiverSignaturePrompt'
import useRemoteCollection from '../../civic-champs-shared/api/hooks/useRemoteCollection'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '60px',
  },
  greenButton: {
    backgroundColor: theme.palette.background.green,
    '&:hover': {
      backgroundColor: theme.palette.accent.yellowGreen.light,
    },
    marginBottom: '20px',
  },
  checkBoxRowHeader: {
    backgroundColor: theme.palette.background.green,
  }
}))

const columns = [
  {
    Header: 'Signature Date',
    accessor: 'createdAt',
    Cell: DateCell,
    filter: DATE_RANGE,
  },
  { Header: 'First Name', accessor: 'user.givenName' },
  { Header: 'Last Name', accessor: 'user.familyName' },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: NullableTextCell,
  },
  {
    Header: 'Expiration',
    accessor: 'expiredAt',
    Cell: DateCell,
  },
]

export const CredentialVolunteers = withCurrentUserHOC((props) => {
  const classes = useStyles()
  const credentialId = props.match.params.id
  const [dateRange, setDateRange] = useDateRangeFilter()
  const [mostRecentPerUser, setMostRecentPerUser] = useState(true)
  const [loading, setLoading] = useState(true)
  const { showAdminItems } = useUISettings()
  const deleteCredentialsSigningEnabled = useFeatureEnabled('DeleteCredentialSigning')
  const [volunteers, operations] = useRemoteCollection()
  useCredentialSigningManagement(credentialId, mostRecentPerUser, operations.syncCollection, setLoading);
  const data = useMemo(() => volunteers, [volunteers])

  const volunteerTable = useTable(
    {
      data,
      columns,
      filterTypes: DEFAULT_FILTERS,
      initialState: { filters: useMemo(() => [{ id: 'createdAt', value: dateRange }], [dateRange]) },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn,
  )

  useEffect(() => {
    volunteerTable.toggleHideColumn(selectionColumnId, !(showAdminItems && deleteCredentialsSigningEnabled))
  }, [volunteerTable, showAdminItems, deleteCredentialsSigningEnabled])

  const removeWaiverSignatures = useRemoveWaiverSignaturePrompt(operations.eagerRemove)

  const handleDeleteSignature = () => {
    const volunteers = volunteerTable.selectedFlatRows
      .map(row => row.original);
    removeWaiverSignatures(volunteers);
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    volunteerTable.setFilter('createdAt', newRange)
  }
  if (loading) {
    return <Loading />
  }
  return (
    <div style={{ height: '90vh' }}>
      <CredentialsDetailsHeader credentialId={credentialId} history={props.history} />
        <div className={classes.row}>
          <FormControlLabel
            control={<Checkbox checked={mostRecentPerUser} onChange={() => setMostRecentPerUser(!mostRecentPerUser)} name="mostRecent" />}
            label="Show Only Most Recent Credential Per User"
          />
          <DateRangeFilter initialValues={dateRange} onFilterApplied={handleDateRangeChange} />
        </div>
        {showAdminItems && deleteCredentialsSigningEnabled && <Grid container item spacing={1} alignItems="center" justify="flex-start">
          <Grid item>
            <Button
              variant="contained"
              className={classes.greenButton}
              disabled={!volunteerTable.selectedFlatRows.length}
              onClick={handleDeleteSignature}
            >
              <ClearIcon style={{ marginRight: 5 }} />
              Delete Signature
            </Button>
          </Grid>
        </Grid>}
        <PagedTable
          style={{backgroundColor: '#ADC6F3'}}
          {...volunteerTable}
          selectHeaderCellClassName={classes.checkBoxRowHeader}
        />
    </div>
  )
})
