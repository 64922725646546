import {get} from 'lodash';
import {useState} from 'react'
import {EMAIL_REGEX} from '../constants/EMAIL_REGEX'
import { validate } from '../../Mentorship/helpers/helpers'

export const validateEmail = email => EMAIL_REGEX.test(String(email).toLowerCase())

export const INIT_VALIDATOR = errorsObject => {
  const [errorMessages, setErrorMessage] = useState(errorsObject)

  const handleChangeErrorMsg = (field, value) => {
    const updatedErrors = { ...errorMessages }
    updatedErrors[field] = value
    setErrorMessage(updatedErrors)
  }

  return {
    errorMessages,
    onChangeErrorMessage: (field, msg) => handleChangeErrorMsg(field, msg),
    onValidateField: (field, params) => value => {
      // in case we receive the event instead of the value itself
      const targetValue = get(value, 'target.value', value)
      const trimedValue = String(targetValue).trim()
      if (params && params.required && !trimedValue) {
        handleChangeErrorMsg(field, `The ${field} field is required`)
      } else if (params && params.phone && trimedValue && !validate.phoneNumber(trimedValue)) {
        handleChangeErrorMsg(field, `Invalid US phone number`)
      } else if (params && params.maxLength && trimedValue.length > params.maxLength) {
        handleChangeErrorMsg(field, `Must be ${params.maxLength} characters or less`)
      } else if (params && params.email && trimedValue && !validateEmail(trimedValue)) {
        handleChangeErrorMsg(field, 'Invalid email address')
      } else if (params && params.minValue && trimedValue < params.minValue) {
        handleChangeErrorMsg(field, `The ${field} field cannot be a less than ${params.minValue}`)
      } else {
        handleChangeErrorMsg(field, '')
      }
    },
  }
}
