import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import PurgeAccountPrompt from '../components/PurgeAccountPrompt'
import { useCallback } from 'react'

export const usePurgeAccountPrompt = (props) => {
  const { personId, onComplete } = props
  const prompt = usePrompt(props => PurgeAccountPrompt({ ...props, personId }))

  return useCallback(() => {
    if (prompt.showing) return;

    const unlistens = [
      prompt.onComplete(onComplete),
      prompt.onHide(() => { unlistens.forEach(unlisten => unlisten()) })
    ]

    prompt.show()
  }, [prompt, onComplete])
}
