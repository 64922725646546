import { useCallback } from 'react'
import { adminsSummary } from './utils'
import { Admin } from '../interface'

import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

const useRemoveAdmins = (eagerRemoveMany: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (admins: Admin[]) => {
      const { numAdmins, onlyOneAdmin, adminName } = adminsSummary(admins)

      const undo = eagerRemoveMany(admins)
      const notification = createNotification(
        onlyOneAdmin ? `Removing ${adminName} from administrators...` : `Removing ${numAdmins} administrators...`,
      )
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/admins`,
          config: {
            queryStringParameters: {
              ids: admins.map(({ id }) => id),
            },
          },
        })

        notification.onSuccess(
          onlyOneAdmin ? `${adminName} has been removed as an administrator` : `Removed ${numAdmins} administrators`,
        )
      } catch (error) {
        notification.onError(
          onlyOneAdmin
            ? `Could not remove ${adminName} from administrators`
            : `Could not remove ${numAdmins} administrators`,
          error,
        )
        undo()
      }
    },
    [createNotification, eagerRemoveMany, request],
  )
}

export default useRemoveAdmins
