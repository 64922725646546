import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import ImportDragContainer from './ImportDragContainer'
import useVolunteerImportDialogStyles from '../hooks/useVolunteerImportDialogStyles'
import HelpIcon from '@material-ui/icons/Help'
import cn from 'classnames'
import { useUploadFile } from '../../import/hooks/useUploadFile'
import { UploadedVolunteer } from '../../import/interfaces'
import { Loader } from '../../civic-champs-shared'
import ParsedRows from '../../components/upload-csv-popup/parsed-rows'
import { useConfirmVolunteers } from '../hooks/useConfirmVolunteers'
import { useGetUploadedFiles } from '../../import/hooks/useGetUploadedFiles'
import format from '../../civic-champs-shared/utils/format'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import capitalize from 'lodash/capitalize'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const columns = [
  {
    Header: 'First Name',
    accessor: 'givenName',
  },
  {
    Header: 'Last Name',
    accessor: 'familyName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Phone',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Home Phone',
    accessor: 'homePhoneNumber',
  },
]

const ImportVolunteersPrompt = (props: Props) => {
  const { showing, close, complete } = props
  const [rows, setRows] = useState<UploadedVolunteer[] | null>(null)
  const [sendInvite, setSendInvite] = useState<boolean>(false)
  const classes = useVolunteerImportDialogStyles()
  const [uploadFile, { loading: fileUploading }] = useUploadFile<UploadedVolunteer>('volunteer')
  const [getUploadedFiles, { loading: filesLoading, result: uploadedFiles }] = useGetUploadedFiles('volunteer')
  const [confirmVolunteers, { loading: volunteersConfirming }] = useConfirmVolunteers()

  useEffect(() => {
    getUploadedFiles()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileSelect = useCallback(
    (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      uploadFile(formData).then(setRows)
    },
    [uploadFile],
  )
  const handleCancel = useCallback(() => {
    if (rows) {
      setRows(null)
    } else {
      close()
    }
  }, [close, rows])

  const confirmParsedRows = useCallback(async () => {
    const newVolunteers = await confirmVolunteers({
      volunteers: rows as UploadedVolunteer[],
      sendInvite,
    })
    complete(newVolunteers)
  }, [complete, confirmVolunteers, rows, sendInvite])

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#import-volunteer-prompt` }}
      aria-labelledby={`avatar-title`}
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, { [classes.wide]: !!rows }) }}
      disableEnforceFocus
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={`import-volunteer-prompt`}>
        Volunteer Upload
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
        <div className={classes.subTitle}>
          Bulk upload new volunteers into your organization from a .csv. First time users are strongly encouraged to
          first consult help documentation.{' '}
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {!rows && (
          <div className={classes.helpBlock}>
            <OutlinedButton
              startIcon={<HelpIcon />}
              className={cn(classes.button, classes.helpButton)}
              onClick={console.log}
            >
              Help
            </OutlinedButton>
            <OutlinedButton className={classes.button} href={process.env.REACT_APP_UPLOAD_TEMPLATE_VOLUNTEERS}>
              Download Template
            </OutlinedButton>
          </div>
        )}
        {fileUploading || volunteersConfirming ? (
          <Loader />
        ) : rows ? (
          <ParsedRows rows={rows} columns={columns} friendlyReference="Volunteers" onSendInviteChange={setSendInvite} />
        ) : (
          <>
            <ImportDragContainer setFile={handleFileSelect} readFromFile={false} />
            {filesLoading ? (
              <Loader />
            ) : (
              <div className={classes.history}>
                <div>History</div>
                {uploadedFiles &&
                  uploadedFiles.data.map(({ type, createdAt, file: { url } }) => (
                    <div className={classes.historyItem}>
                      <div>{format.date(createdAt)}</div>
                      <div>{capitalize(type)}</div>
                      <div>
                        <a href={url} target="_blank">
                          <CloudDownloadIcon style={{ color: '#0F5DB5' }} />
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <OutlinedButton onClick={handleCancel}>{rows ? 'Back' : 'Cancel'}</OutlinedButton>
        <ContainedButton onClick={confirmParsedRows} disabled={rows === null}>
          Import
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default (props: Props) => (
  <ThemeProvider theme={muiTheme}>
    <ImportVolunteersPrompt {...props} />
  </ThemeProvider>
)
