import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { useCurrentOrg } from 'auth/hooks'
import { RowsReorderModal } from 'core/table/components/RowsReorderModal'
import useGetSortOrder from 'core/table/table-hooks/useGetSortOrder'
import useReorderDocumentTypes from 'documents/hooks/useReorderDocumentTypes'
import { SyncAction } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'

export const useReorderDocumentTypesPrompt = (eagerSync: SyncAction<DocumentType>) => {
  const showModal = useShowPrompt(RowsReorderModal)
  const reorderDocumentTypes = useReorderDocumentTypes(eagerSync)
  const organization = useCurrentOrg()
  const [fetchSortOrder] = useGetSortOrder()

  return useCallback(
    async table => {
      const sortOrder = await fetchSortOrder('document_type')

      const reordered = await showModal({
        table,
        title: 'Adjust Document Type Order',
        description: `Drag document types or choose other sorting options to adjust the order they will appear throughout ${organization.name}`,
        sortOptions: [
          { name: 'Custom', field: 'order', order: 'asc', isCustom: true },
          { name: 'A-Z', field: 'name', order: 'asc' },
          { name: 'Most Frequently Used', field: 'document_count', order: 'desc' },
        ],
        sortOrder,
      })

      if (reordered) {
        const { rows, sortOption } = reordered
        await reorderDocumentTypes({ rows, sortOption })
      }
    },
    [fetchSortOrder, organization.name, reorderDocumentTypes, showModal],
  )
}

export default useReorderDocumentTypesPrompt
