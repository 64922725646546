import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Person } from 'people/interface'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

const useFetchDrivers = (): [() => Promise<Person[]>, Status<Person[]>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Person[]>({
    errorMessage: 'Error Fetching Drivers.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchDrivers = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/drivers`,
    })
  }, [organization.id, request])

  return [fetchDrivers, status]
}

export default useFetchDrivers
