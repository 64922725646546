import React from 'react'
import { makeStyles } from '@material-ui/core'
import { DonationStatus, getDonationStatusDisplayNames } from '../interface'

const useStyles = makeStyles(() => ({
  [DonationStatus.PAID]: {
    fontWeight: 'bold',
    color: '#5F8FE8',
    fontSize: '22px',
  },
  [DonationStatus.REFUNDED]: {
    color: 'grey',
    fontSize: '22px',
  },
  [DonationStatus.PENDING_REFUND]: {
    color: 'grey',
    fontSize: '22px',
  },
  [DonationStatus.INITIATED]: {
    color: '#FFCD00',
    fontSize: '22px',
  },
  [DonationStatus.FAILED]: {
    color: '#FF6F3E',
    fontSize: '22px',
  },
}))

export default function DonationStatusDisplay({ donation }) {
  const classes = useStyles()
  return <span className={classes[donation.status]}>{getDonationStatusDisplayNames(donation.status)}</span>
}
