import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Box, Drawer, Typography } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { mdiAccount, mdiAccountSwitch, mdiCar, mdiFace, mdiHandHeart, mdiSettings, mdiWebhook } from '@mdi/js'
import HelpingHandsIcon from 'components/icon/HelpingHandsIcon'
import { INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled, useOrganizationSettingsTabs } from 'core/feature/hooks'
import { useCurrentOrg, useHasRole } from 'auth/hooks'
import NavMenu from 'App/components/NavMenu'
import useSettingsEnabledForSuperAdmin from '../core/feature/hooks/useSettingsEnabledForSuperAdmin'
import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useMediaQuery } from 'react-responsive'
import { Overlay } from 'components/overlay/Overlay'
import TuneIcon from '@material-ui/icons/Tune'
import MessageIcon from '@material-ui/icons/Message'
import EventIcon from '@material-ui/icons/Event'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { muiTheme } from 'theme'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'

import { ReactComponent as OpenIcon } from 'images/open-menu.svg'
import { ReactComponent as CloseIcon } from 'images/close-menu.svg'

const hasChildren = item => !isEmpty(item.items)

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.drawer.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    flexShrink: 0,
    height: '100%',
    '&$closed': {
      width: '50px',
    },
  },
  drawerPaper: {
    width: theme.drawer.width,
    backgroundColor: '#4985DF',
    borderRight: 'none',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    '&$closed': {
      width: '50px',
    },
  },
  closed: {},
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    height: 69,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$closed': {
      justifyContent: 'center',
      paddingLeft: 0,
    },
    '&$silentModeEnabled': {
      backgroundColor: '#F4BF2C',
    },
  },
  silentModeEnabled: {},
  iconBlock: {
    marginRight: 10,
    borderRight: '10px solid transparent',
    display: 'block',
    width: '1px',
    height: '45px',
  },
  iconsSelected: {
    color: 'white',
    borderRight: '10px solid',
    display: 'block',
    width: '1px',
    height: '45px',
  },
  orgNameWrapper: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    height: '69px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '20px 0',
  },
  link: {
    fontSize: '12px',
    marginBottom: '4px',
    marginTop: '4px',
    color: 'white',
  },
  nav: {
    zIndex: 1,
    height: '100%',
  },
  holePuncher: {
    zIndex: 11,
  },
  orgCaption: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  orgName: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  container: {
    height: '100%',
  },
  openClose: {
    marginRight: '12.58px',
    cursor: 'pointer',
    color: '#A8C7FF',
    '&$closed': {
      marginRight: 0,
    },
    '&$silentModeEnabled': {
      color: 'white',
    },
  },
}))

const getItems = ({ organization, classes }) => {
  return [
    {
      label: organization ? organization.name : 'Organization',
      route: ['/organization', '/organizations'],
      requires: 'organizationTab',
      IconComponent: () => <AccountBalanceIcon size="large" />,
      items: [
        {
          label: 'Overview',
          route: '/organization',
        },
        {
          label: 'Search',
          route: '/organizations',
          requires: 'internalAdmin',
          isAdminItem: true,
        },
      ],
    },
    {
      label: 'People',
      route: ['/people', '/invitations', '/merge-accounts', '/all-people', '/testers'],
      iconPath: mdiFace,
      items: [
        { label: 'All People', route: '/all-people', requires: 'internalSuperAdmin' },
        { label: 'Overview', route: '/people' },
        { label: 'Invitations', route: '/invitations' },
        { label: 'Testers', route: '/testers', requires: 'internalSuperAdmin' },
      ],
      requires: 'internalAdmin',
      isAdminItem: true,
    },
    {
      label: 'Champions',
      iconPath: mdiAccount,
      items: [
        { label: 'Overview', route: '/champions' },
        { label: 'Activity Log', route: '/activities' },
        { label: 'Waivers', route: '/credentials' },
        { label: 'Credential IDs', route: '/credential-ids', requires: 'credentialIDs' },
      ],
      route: ['/activities', '/credentials', '/champions', '/credential-ids'],
      aClassName: classes.holePuncher,
    },
    {
      label: 'Messages',
      route: [
        '/messages',
        '/bad-contacts',
        '/white-listed-phone-numbers',
        '/add-sms-credits',
        '/opted-out-phone-numbers',
        '/custom-messages',
      ],
      IconComponent: () => <MessageIcon size="large" />,
      items: [
        { label: 'Custom Messages', route: '/custom-messages', requires: 'bulkMessaging' },
        {
          label: 'Automated Messages',
          route: '/messages',
          requires: { anyOf: ['internalAdmin', 'automatedMessages'] },
        },
        { label: 'Bad Contacts', route: '/bad-contacts', requires: 'internalSuperAdmin', isAdminItem: true },
        { label: 'Opt Outs', route: '/opted-out-phone-numbers', requires: 'internalSuperAdmin', isAdminItem: true },
        {
          label: 'Whitelisted Phones',
          route: '/white-listed-phone-numbers',
          requires: 'internalSuperAdmin',
          isAdminItem: true,
        },
        {
          label: 'Add SMS Credits',
          route: '/add-sms-credits',
          requires: { allOf: ['internalSuperAdmin', 'bulkMessaging'] },
          isAdminItem: true,
        },
        {
          label: 'Chats',
          route: '/chats',
          requires: 'chats',
        },
      ],
      requires: { anyOf: ['internalAdmin', 'bulkMessaging', 'internalSuperAdmin'] },
    },
    {
      label: 'Donations',
      route: ['/donations', '/campaigns'],
      iconPath: mdiHandHeart,
      items: [
        { label: 'Overview', route: '/donations/overview' },
        { label: 'Campaigns', route: '/campaigns' },
      ],
      requires: 'donations',
    },
    {
      label: 'Trip Planner',
      route: '/trip-planner',
      iconPath: mdiCar,
      requires: 'tripPlanner',
    },
    {
      label: 'Groups',
      route: '/groups',
      IconComponent: () => <img alt="groups" src="/assets/icons/Groups.svg" />,
      requires: 'groups',
    },
    {
      label: 'Events',
      IconComponent: () => <EventIcon size="large" />,
      items: [
        { label: 'Calendar', route: '/events' },
        { label: 'Opportunities', route: '/opportunities', requires: 'volunteering', isAdminItem: true },
        { label: 'Registrations', route: '/registrants', requires: 'registrants' },
        { label: 'List of Events', route: '/list-of-events', requires: 'listOfEvents' },
        { label: 'Event Templates', route: '/event-templates', requires: 'opportunityTemplates' },
      ],
      route: ['/events', '/opportunities', '/registrants', '/list-of-events', '/event-templates'],
      requires: { allOf: ['volunteering', 'events'] },
      aClassName: classes.holePuncher,
    },
    {
      label: 'Kiosk',
      route: '/kiosk',
      IconComponent: () => <PhoneIphoneIcon size="large" />,
      requires: 'volunteering',
    },
    {
      label: 'Helping Hands',
      route: '/helping-hands',
      IconComponent: HelpingHandsIcon,
      requires: 'helpingHands',
    },
    {
      label: 'Mentor',
      route: '/mentorship',
      iconPath: mdiAccountSwitch,
      items: [
        { label: 'Meetings', route: '/mentorship/meetings' },
        { label: 'Matches', route: '/mentorship/matches' },
        { label: 'Programs', route: '/mentorship/programs' },
        { label: 'Surveys', route: '/mentorship/surveys', hide: 'questionnaireAsSurveyEnabled' },
      ],
      requires: { allOf: ['mentoring'] },
    },
    {
      label: 'Integrations',
      route: '/integrations',
      iconPath: mdiWebhook,
      requires: { oneOf: ['apiKeyEnabled', 'lglIntegrationEnabled'] },
    },
    {
      label: 'Setup',
      IconComponent: () => <TuneIcon size="large" />,
      requires: 'superAdmin',
      items: [
        {
          label: 'Admins',
          route: '/admins',
        },
        {
          label: 'Questionnaires',
          route: '/questionnaires',
        },
        {
          label: 'Roles',
          route: '/roles',
          requires: 'rolesManagement',
        },
        {
          label: 'Tags',
          route: '/tags',
          requires: 'tagsManagement',
        },
        {
          label: 'Locations',
          route: '/locations',
          requires: 'locationsManagement',
        },
        {
          label: 'Documents',
          route: '/documents',
          requires: { allOf: ['superAdmin', 'documentManagement'] },
        },
      ],
    },

    // show full settings if an internal admin
    {
      label: 'Settings',
      route: '/settings',
      iconPath: mdiSettings,
      items: [
        {
          label: 'Organization',
          requires: 'settingsTabs',
          route: '/settings/organization',
        },
        { label: 'Features', route: '/settings/features', isAdminItem: true },
        {
          label: 'Stripe Products',
          route: '/settings/stripe-products',
          requires: 'internalSuperAdmin',
          isAdminItem: true,
        },
      ],
      requires: { allOf: ['internalAdmin', 'hasChildren'] },
      atBottom: true,
    },

    // show partial list if just a superAdmin and any of the sub-items are enabled
    {
      label: 'Settings',
      route: '/settings',
      iconPath: mdiSettings,
      items: [{ label: 'Organization', requires: 'settingsTabs', route: '/settings/organization' }],
      requires: { allOf: ['settingsEnabledForSuperAdmin', 'hasChildren'] },
      hide: 'internalAdmin',
      atBottom: true,
    },
  ]
}

const OrganizationMenuItem = props => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="caption" className={classes.orgCaption}>
        Organization
      </Typography>
      <Typography variant="body2" className="truncated-multiline">
        <Box lineHeight={1} component="span" className={classes.orgName}>
          {props.name}
        </Box>
      </Typography>
    </Box>
  )
}

const useDrawerItems = () => {
  const internalAdmin = useHasRole(INTERNAL_ADMIN)
  const internalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const superAdmin = useHasRole(SUPER_ADMIN)

  const volunteering = useFeatureEnabled('Volunteering')
  const events = useFeatureEnabled('EventScheduling')
  const mentoring = useFeatureEnabled('Mentorship')
  const mentoringUpdates = useFeatureEnabled('NewMentorship')
  const donations = useFeatureEnabled('Donations')
  const bulkMessaging = useFeatureEnabled('BulkMessaging')
  const volunteerQuestionnaires = useFeatureEnabled('VolunteerProfiles')
  const helpingHands = useFeatureEnabled('HelpingHands')
  const tripPlanner = useFeatureEnabled('TripPlanner')
  const groups = useFeatureEnabled('Groups')
  const credentialIDs = useFeatureEnabled('CredentialIDs')
  const apiKeyEnabled = useFeatureEnabled('ApiKey')
  const registrants = useFeatureEnabled('Registrants')
  const chats = useFeatureEnabled('Chats')
  const rolesManagement = useFeatureEnabled('RolesManagement')
  const tagsManagement = useFeatureEnabled('NewTags')
  const locationsManagement = useFeatureEnabled('LocationsManagement')
  const automatedMessages = useFeatureEnabled('NewAutomatedMessages')
  const questionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const documentManagement = useFeatureEnabled('DocumentManagement')
  const settingsTabs = !isEmpty(useOrganizationSettingsTabs())
  const settingsEnabledForSuperAdmin = useSettingsEnabledForSuperAdmin()
  const listOfEvents = useFeatureEnabled('ListOfEvents')
  const isLglIntegrationEnabled = useFeatureEnabled('LglIntegration')
  const isOrgTabForSuperAdminsEnabled = useFeatureEnabled('OrganizationTabForSuperAdmins')
  const opportunityTemplates = useFeatureEnabled('OpportunityTemplates')
  const lglIntegrationEnabled = isLglIntegrationEnabled && superAdmin
  const organizationTab = internalAdmin || (superAdmin && isOrgTabForSuperAdminsEnabled)
  const organization = useCurrentOrg()
  const classes = useStyles()

  const items = useMemo(
    () => getItems({ organization, classes, isOrgTabForSuperAdminsEnabled }),
    [classes, isOrgTabForSuperAdminsEnabled, organization],
  )

  return useMemo(() => {
    const enabled = {
      internalAdmin,
      internalSuperAdmin,
      superAdmin,
      volunteering,
      events,
      mentoring,
      mentoringUpdates,
      donations,
      bulkMessaging,
      volunteerQuestionnaires,
      helpingHands,
      tripPlanner,
      groups,
      settingsTabs,
      settingsEnabledForSuperAdmin,
      credentialIDs,
      apiKeyEnabled,
      registrants,
      chats,
      rolesManagement,
      listOfEvents,
      tagsManagement,
      hasChildren,
      locationsManagement,
      automatedMessages,
      lglIntegrationEnabled,
      questionnaireAsSurveyEnabled,
      opportunityTemplates,
      documentManagement,
      organizationTab,
    }

    return filterByRequireRules(items, {
      config: enabled,
      recursive: true,
      nestingProperty: 'items',
    })
  }, [
    internalAdmin,
    internalSuperAdmin,
    superAdmin,
    volunteering,
    events,
    mentoring,
    mentoringUpdates,
    donations,
    bulkMessaging,
    volunteerQuestionnaires,
    helpingHands,
    tripPlanner,
    groups,
    settingsTabs,
    settingsEnabledForSuperAdmin,
    credentialIDs,
    apiKeyEnabled,
    registrants,
    chats,
    rolesManagement,
    listOfEvents,
    tagsManagement,
    locationsManagement,
    automatedMessages,
    lglIntegrationEnabled,
    questionnaireAsSurveyEnabled,
    documentManagement,
    organizationTab,
    opportunityTemplates,
    items,
  ])
}

const DrawerContents = ({ history, onChangeRoute, open, onToggle }) => {
  const classes = useStyles() //TODO move styles and component to a new place
  const navItems = useDrawerItems()
  const org = useCurrentOrg()

  return (
    <div className={classes.container}>
      {/* removed until we'll find a better UI solution*/}
      {false && <Overlay />}
      <div
        className={cn(classes.toolbar, 'menu-logo', {
          [classes.closed]: !open,
          [classes.silentModeEnabled]: org?.sandboxModeEnabled,
        })}
      >
        {open && <img src="/assets/icons/white-logo.svg" alt="logo" />}
        {open ? (
          <CloseIcon
            className={cn(classes.openClose, {
              [classes.closed]: !open,
              [classes.silentModeEnabled]: org?.sandboxModeEnabled,
            })}
            onClick={onToggle}
            alt={'close'}
          />
        ) : (
          <OpenIcon
            className={cn(classes.openClose, {
              [classes.closed]: !open,
              [classes.silentModeEnabled]: org?.sandboxModeEnabled,
            })}
            onClick={onToggle}
            alt={'open'}
          />
        )}
      </div>
      <NavMenu items={navItems} history={history} open={open} onChangeRoute={onChangeRoute} />
    </div>
  )
}

const DrawerBar = ({ history }) => {
  const classes = useStyles()
  const isSmall = useBreakPoint(1280)
  const [open, setOpen] = useState(!isSmall)
  useMediaQuery({ minWidth: 1280 }, undefined, isOpen => {
    if (isOpen !== open) {
      setOpen(isOpen)
    }
  })

  return (
    <>
      <nav className={classes.nav}>
        <Drawer
          className={cn(classes.drawer + ' menu-sidebar', { [classes.closed]: !open })}
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: cn(classes.drawerPaper, { [classes.closed]: !open }),
          }}
        >
          <DrawerContents history={history} open={open} onToggle={() => setOpen(v => !v)} />
        </Drawer>
      </nav>
    </>
  )
}

Drawer.propTypes = {
  history: PropTypes.object,
}

export default function WrappedDrawer(props) {
  return (
    <ThemeProvider theme={muiTheme}>
      <DrawerBar {...props} />
    </ThemeProvider>
  )
}
