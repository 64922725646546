import React, { useState, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';

import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useReloadLocalFeatures } from '../hooks';

export const useUpdateEnabled = (featureSwitch) => {
    const [fetch, status] = useFetch();

    const updateEnabled = useCallback(value => {
        const organizationId = featureSwitch.organization.id;
        const featureId = featureSwitch.feature.id;

        return fetch({
          method: 'put',
          url: `/settings/features/${featureId}/switches`,
          config: {
            body: {
              organizationId,
              enabled: !!value,
            },
          },
        })
    }, [fetch, featureSwitch]);

    return [updateEnabled, status];
}

export default function CustomFeatureSwitch(props) {
    const { featureSwitch } = props;

    const [enabled, setEnabled] = useState(featureSwitch.enabled);
    const [updateEnabled, { loading }] = useUpdateEnabled(featureSwitch);

    const reload = useReloadLocalFeatures();

    const toggleEnabled = useCallback(async () => {
        if(loading) return;

        let prev = enabled;
        setEnabled(prev);

        try {
            await updateEnabled(!enabled);
            reload();
        } catch(error) {
            console.error(error);
            setEnabled(prev);
            //TODO flash the background so it shows an error color
            //TODO display the error as a toast
        }
    }, [loading, enabled, updateEnabled, setEnabled, reload]);

    return (
      <FormGroup>
        <FormControlLabel
          control={<Switch disabled={loading} checked={enabled} onChange={toggleEnabled} />}
          label={
            <>
              Enabled
              {loading && <CircularProgress size="sm" />}
            </>
          }
        />
      </FormGroup>
    )
}
