import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { IconButton, makeStyles } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import dayjs from 'utils/dayjs'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTimeZone } from 'civic-champs-shared/auth/hooks'

const useStyles = makeStyles(theme => ({
  dayNavigation: {
    fontSize: '1.2em',
    display: 'flex',
    '& button': {
      color: theme.palette.primary.main,
      padding: 0,
    },
    marginBottom: '20px',
  },
  currentDay: {
    display: 'flex',
    alignItems: 'center',
    width: '116px',
    textAlign: 'center',
    margin: 'auto 10px',
    color: '#191C1D',
  },
  longFormattedDay: {
    margin: 'auto 10px',
  },
  datePicker: {
    width: '26px',
    height: '26px',
    margin: 0,
    color: theme.palette.primary.main,
    '&>div>div': {
      marginBottom: '4px',
    },
    '& button': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > span > svg': {
        fontSize: '20px',
      },
    },
  },
}))

export const DayNavigationBar = ({
  date,
  setDate,
  dateAlias,
}: {
  date: Date
  setDate: Dispatch<SetStateAction<Date>>
  dateAlias?: string
}) => {
  const classes = useStyles()
  const onPrevClick = useCallback(() => {
    setDate(value => dayjs(value).subtract(1, 'day').toDate())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const onNextClick = useCallback(() => {
    setDate(value => dayjs(value).add(1, 'day').toDate())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const timeZone = useTimeZone()

  return (
    <div className={classes.dayNavigation}>
      <IconButton onClick={onPrevClick}>
        <ChevronLeft />
      </IconButton>

      <div className={classes.currentDay}>
        {dayjs(date).format('MM/DD/YYYY')}
        <KeyboardDatePicker
          className={classes.datePicker}
          margin="normal"
          id="date-picker-dialog"
          format="MM/DD/YYYY"
          value={date}
          onChange={setDate as any}
          InputProps={{ disableUnderline: true }}
        />
      </div>

      <IconButton onClick={onNextClick}>
        <ChevronRight />
      </IconButton>

      <div className={classes.longFormattedDay}>
        {dayjs(date).tz(timeZone).format('dddd, MMMM DD')}
        {dateAlias && ` (${dateAlias})`}
      </div>
    </div>
  )
}

export default DayNavigationBar
