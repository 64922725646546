import { useCallback, Dispatch } from 'react'

import getErrorMessage from 'civic-champs-shared/utils/getErrorMessage'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

import { Action, ActionType } from './types'
import { mergePeople } from './api'

const toString = (person: any) => `${person.givenName} ${person.familyName} (id:${person.id})`

export const useDoMerge = (dispatch: Dispatch<Action>) => {
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  return useCallback(
    async (replacingPerson: any, replacedPerson: any): Promise<boolean> => {
      const confirmed = await confirm(
        `Are you sure you want to merge ${toString(replacedPerson)}'s account into ${toString(
          replacingPerson,
        )}'s account?`,
        {
          title: `Merge Accounts`,
          confirmText: `Merge`,
          rejectText: 'Cancel',
          subText: `Clicking the button 'Merge' will permanently delete ${toString(replacedPerson)}'s account.`,
        },
      )
      if (!confirmed) {
        dispatch({ type: ActionType.MergeCanceled }) //TODO is exporting an action creator better architecture/design?
        return false
      }

      try {
        await mergePeople(replacingPerson, replacedPerson) //TODO taking a { keep, merge } object might be clearer/ less error prone
        showSuccess('Successfully merged accounts')
        return true
      } catch (error) {
        dispatch({
          type: ActionType.MergeFailed,
          payload: {
            error: getErrorMessage(error), //TODO this should just be done in the reducer, too much implementation details
          },
        })

        return false
      }
    },
    [],
  )
}
