export enum TaskStatus {
    InReview = 'in-review',
    DetailsRequired = 'details-required',
    NotAccepted = 'not-accepted',
    Accepted = 'accepted',
    Scheduled = 'scheduled',
    Canceled = 'canceled',
    InProgress = 'in-progress',
    ClaimedCompleted = 'claimed-completed',
    Completed = 'completed',
    NotCompleted = 'not-completed',
}

// this is a short-term solution
export const TaskStatusDisplayNames = {
    [TaskStatus.InReview]: 'Waiting for Review',
    [TaskStatus.DetailsRequired]: 'Needs More Details',
    [TaskStatus.NotAccepted]: 'Declined',
    [TaskStatus.Accepted]: 'Approved',
    [TaskStatus.Scheduled]: 'Scheduled',
    [TaskStatus.Canceled]: 'Canceled',
    [TaskStatus.InProgress]: 'In Progress',
    [TaskStatus.ClaimedCompleted]: 'Completed',
    [TaskStatus.Completed]: 'Completed (Admin)',
    [TaskStatus.NotCompleted]: 'Canceled (Admin)',
}

export const inProgressTaskStatuses = [
    TaskStatus.InReview,
    TaskStatus.DetailsRequired,
    TaskStatus.Accepted,
    TaskStatus.Scheduled,
    TaskStatus.InProgress,
    TaskStatus.NotCompleted,
]

export const isActive = (task: any) => task && inProgressTaskStatuses.includes(task.taskStatus)

export default TaskStatus
