const defaultState = () => ({
    status: 'inactive',
    opportunity: null,
    timesheetMode: 'check-in',
    volunteers: [],
    volunteeringRoles: [],
    hasWaivers: false,
    error: null,
    getVolunteers: { running: false, error: null },
    signUpVolunteer: { running: false, error: null },
    checkIn: { running: false, error: null },
    checkOut: { running: false, error: null },
    getWaivers: { running: false, error: null }
});

export default defaultState;