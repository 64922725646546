import {
  Address,
  GoogleAutoCompleteMixedResponse,
  parsePlace,
  PredictionAndPlaceResults,
} from 'civic-champs-shared/formik/components/AddressField'
import _ from 'lodash'
import { Loader } from '@googlemaps/js-api-loader'
import PlaceResult = google.maps.places.PlaceResult

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY as string,
  version: 'weekly',
})

let autocompleteService: google.maps.places.AutocompleteService
let placesService: google.maps.places.PlacesService

export const getAddressSuggestions = async (input: string): Promise<GoogleAutoCompleteMixedResponse[]> => {
  if (!autocompleteService || !placesService) {
    const { AutocompleteService, PlacesService } = await loader.importLibrary('places')
    autocompleteService = autocompleteService || new AutocompleteService()
    placesService = placesService || new PlacesService(document.createElement('div'))
  }
  return new Promise(resolve => {
    let localPredictionAndPlaceResults: PredictionAndPlaceResults = {}
    const callBackHandler: (input: PredictionAndPlaceResults) => void = input => {
      localPredictionAndPlaceResults = {
        ...localPredictionAndPlaceResults,
        ...input,
      }
      if (
        typeof localPredictionAndPlaceResults.predictionResults !== 'undefined' &&
        typeof localPredictionAndPlaceResults.placeResults !== 'undefined'
      ) {
        resolve([
          ...(localPredictionAndPlaceResults.placeResults || []),
          ...(localPredictionAndPlaceResults.predictionResults || []),
        ])
        localPredictionAndPlaceResults = {}
      }
    }
    autocompleteService.getPlacePredictions(
      { input, types: ['address'] },
      (result: google.maps.places.AutocompletePrediction[]) => {
        callBackHandler({ predictionResults: result })
      },
    )
    placesService.findPlaceFromQuery(
      { query: input, fields: ['name', 'place_id'] },
      (results: google.maps.places.PlaceResult[]) => {
        callBackHandler({ placeResults: results })
      },
    )
  })
}

export const getAddressFromSuggestion = async (suggestion: GoogleAutoCompleteMixedResponse): Promise<Address> => {
  if (!placesService) {
    const { PlacesService } = await loader.importLibrary('places')
    placesService = new PlacesService(document.createElement('div'))
  }
  return new Promise((resolve, reject) => {
    if (_.isEmpty(suggestion) || !suggestion.place_id) return reject('No place id')

    placesService.getDetails({ placeId: suggestion.place_id }, (result: PlaceResult) => {
      const { state, zip, city, geofencing, country, countryCode, county } = parsePlace(result)
      const description = 'description' in suggestion ? suggestion.description : result.name
      resolve({
        line1: description,
        line2: '',
        utcOffset: 0,
        city,
        zip,
        state,
        geofencing,
        country,
        county,
        countryCode,
      })
    })
  })
}
