import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useOrganizationRoles = (onResultUpdate, defaultOnly = false) => {
  const [request, { result, loading, called }] = useFetch('Error Fetching Roles. Please refresh the page and try again')
  const currentOrg = useCurrentOrg()
  const refetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/roles`,
      config: {
        queryStringParameters: {
          defaultOnly: !!defaultOnly,
        },
      },
    })
  }, [currentOrg.id, defaultOnly, onResultUpdate, request])

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    roles: result || [],
    loading: loading || !called,
    refetch,
  }
}
