import { useCallback } from 'react'
import { useCreateApiNotification, useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'

export const useRemoveDocumentType = (eagerRemove: Action<DocumentType>) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()

  return useCallback(
    async documentType => {
      const undo = eagerRemove(documentType)
      const notification = createNotification('Removing document type')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/organizations/${currentOrg.id}/document_types/${documentType.id}`,
        })

        notification.onSuccess('Document type removed!')
      } catch (error) {
        notification.onError(`Could not document type ${documentType.name}`, error)
        undo()
      }
    },
    [createNotification, currentOrg.id, eagerRemove, request],
  )
}

export default useRemoveDocumentType
