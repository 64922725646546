import { MessageSourceType, NotificationBlastSummary } from 'messages/types'
import { Link } from 'react-router-dom'
import React from 'react'
import { useMessageOverviewStyles } from 'messages/hooks/useMessageOverviewStyles'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'

export const Recipient = ({ info }: { info: NotificationBlastSummary }) => {
  const classes = useMessageOverviewStyles()
  const org = useCurrentOrg()
  const { sourceType, group, opportunity, organization } = info
  switch (sourceType) {
    case MessageSourceType.Group:
      return (
        <Link className={classes.link} to={`/groups/${group?.id}`}>
          {group?.name}
        </Link>
      )
    case MessageSourceType.Event:
      return (
        <Link
          className={classes.link}
          to={`/events/${encodeOccurrenceFromEvent({
            opportunityId: opportunity?.id,
            startsAt: opportunity?.date,
            organization,
          })}/${org.id}`}
        >
          {opportunity?.name}
        </Link>
      )
    default:
      return <span>Selected Champions</span>
  }
}
