import { makeStyles } from '@material-ui/core'

export const useQuestionItemStyles = makeStyles(theme => ({
  questionRow: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  questionItem: {
    width: '100%',
    flexShrink: 1,
    border: '1px solid #E0E3E3',
    borderRadius: '4px',
    padding: '10px',
    flexDirection: 'column',
    gap: '12px',
    display: 'flex',
  },
  questionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
    },
  },
  questionType: {
    width: '305px',
    '& > div.Mui-focused > fieldset': {
      border: '1px solid #74777F !important',
    },
  },
  numbering: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    width: '30px',
    marginRight: '20px',
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  paper: {
    marginTop: '50px',
    backgroundColor: '#F8FAFF',
    border: '1px solid #0F5DB5',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px 0',
  },
  selectIcon: {
    marginRight: '20px',
    display: 'flex',
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxSpan: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    alignSelf: 'center',
    margin: '2px 0',
  },
  requiredCheckbox: {
    marginRight: '4px',
  },
  answersItem: {
    paddingLeft: '50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  hidden: { display: 'none' },
  answerList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  answerListItem: {
    display: 'flex',
  },
  textField: {
    width: '660px',
    maxWidth: '100%',
  },
  addOption: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}))

export default useQuestionItemStyles
