import isMatch from 'lodash/isMatch'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import React from 'react'
import find from 'lodash/find'

interface rating {
  mentor: boolean | null
  mentee: boolean | null
}

interface mentorRating {
  mentor: boolean | null
}
interface menteeRating {
  mentee: boolean | null
}

export const availableRatings = [
  { title: 'Mentor Approves', value: { mentor: true }, avatar: <ThumbUpIcon style={{ color: '#008954' }} /> },
  { title: 'Mentor Disapproves', value: { mentor: false }, avatar: <ThumbDownIcon style={{ color: '#BA1B1B' }} /> },
  { title: 'Mentee Approves', value: { mentee: true }, avatar: <ThumbUpIcon style={{ color: '#008954' }} /> },
  { title: 'Mentee Disapproves', value: { mentee: false }, avatar: <ThumbDownIcon style={{ color: '#BA1B1B' }} /> },
]

const anyOf = (rowValue: rating, operand: string[]): boolean => {
  const mapped = operand.map(title => find(availableRatings, { title })?.value) as (mentorRating | menteeRating)[]
  return mapped.reduce((acc: boolean, value) => acc || isMatch(rowValue, value), false)
}

const notAnyOf = (rowValue: rating, operand: string[]) => !anyOf(rowValue, operand)

const operatorToMethodMap = {
  anyOf,
  notAnyOf,
}

export const ratingFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}
