import React, { useCallback, useEffect, useState } from 'react'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import format from 'civic-champs-shared/utils/format'
import makeStyles from '@material-ui/styles/makeStyles'
import cn from 'classnames'
import useDeleteNoteDialog from 'note/hooks/useDeleteNoteDialog'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AddEditNoteDialog from 'note/components/AddEditNoteDialog'
import useAddEditNote from 'note/hooks/useAddEditNote'
import { EntityType, Note } from 'note/Note'
import useGetNotes from 'note/hooks/useGetNotes'
import DOMPurify from 'dompurify'

const useStyles = makeStyles({
  container: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    '& > h4': {
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#000000',
      borderBottom: '1px solid #C4C7C7',
      margin: 0,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '10px 0',
  },
  notes: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
  },
  noteTitle: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
    marginBottom: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  noteBody: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: '#000000',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  note: {
    padding: '10px 20px',
    '&:hover': {
      background: '#EBF0FF',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
      borderRadius: '0px 0px 4px 4px',
      '& div.editControls': {
        display: 'flex !important',
        gap: '18px',
      },
    },
  },
  textButton: {
    letterSpacing: '0.1px',
    color: '#0F5DB5',
    cursor: 'pointer',
    margin: 0,
    '&.delete': {
      color: '#BA1B1B',
    },
  },
})

export const ProfileNotes = ({ personId }: { personId: number }) => {
  const [getNotes] = useGetNotes(EntityType.PERSON)
  const [saveNote] = useAddEditNote(EntityType.PERSON)
  const [notes, setNotes] = useState<Note[]>([])
  const classes = useStyles()
  const deleteNote = useDeleteNoteDialog(() => getNotes(personId).then(setNotes))
  const showAddEditNote = useShowPrompt(AddEditNoteDialog)
  useEffect(() => {
    getNotes(personId).then(setNotes)
  }, [getNotes, personId])
  const showAddEditDialog = useCallback(
    async (note?: any) => {
      await saveNote({ ...(await showAddEditNote({ note })), entityId: personId })
      return setNotes(await getNotes(personId))
    },
    [getNotes, personId, saveNote, showAddEditNote],
  )

  return (
    <div className={classes.container}>
      <h4>Notes</h4>
      <div className={classes.buttons}>
        <ContainedButton
          style={{ transform: 'scale(0.8)' }}
          startIcon={<AddCircleIcon style={{ color: '#FFF' }} />}
          // @ts-ignore
          onClick={() => showAddEditDialog()}
        >
          New
        </ContainedButton>
      </div>
      <div className={classes.notes}>
        {notes.map((note, id) => (
          <div key={id} className={classes.note}>
            <div className={classes.noteTitle}>
              <div>
                {format.date(note.createdAt)}
                {note.title && ` - ${note.title}`}
              </div>
              <div style={{ display: 'none' }} className="editControls">
                <p className={classes.textButton} onClick={() => showAddEditDialog(note)}>
                  Edit
                </p>
                <p className={cn(classes.textButton, 'delete')} onClick={() => deleteNote(note)}>
                  Delete
                </p>
              </div>
            </div>
            <div className={classes.noteBody} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.body) }} />
          </div>
        ))}
      </div>
    </div>
  )
}
