import React from 'react'
import { makeStyles } from '@material-ui/core'
import { OpportunityStatus } from 'Event/interfaces/interfaceCreateEditEvent'

const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 'bold',
    color: '#5F8FE8',
    fontSize: '22px',
  },
  inactive: {
    color: 'grey',
    fontSize: '22px',
  },
  draft: {
    color: '#FF6F3E',
    fontSize: '22px',
  },
}))

export default function EventStatus(props) {
  const { title } = props
  const classes = useStyles()
  let className
  switch (title) {
    case OpportunityStatus.Draft:
      className = classes.draft
      break
    case OpportunityStatus.Concluded:
      className = classes.inactive
      break
    default:
      className = classes.active
  }

  return <div className={className}>{title}</div>
}
