import { FormHelperText } from '@material-ui/core'
import React from 'react'

import TaskRequesterPicker from '../components/TaskRequesterPicker'

export default function TaskRequesterField({ field, form, ...props }) {
    const error = form.errors[field.name]
    const handleChange = value => form.setFieldValue(field.name, value)

    return (
        <div>
            <TaskRequesterPicker
                {...props}
                name={field.name}
                value={field.value}
                onChange={handleChange}
            />
            {error && <FormHelperText error={true}>{error}</FormHelperText>}
        </div>
    )
}