import { useMemo } from 'react'

interface DisplayAreaInfo<T> {
  topPlaceholderHeight: number
  bottomPlaceholderHeight: number
  displayFrom: number
  visibleContacts: T[]
}

interface DisplayAreaInfoProps<T> {
  contacts: T[]
  scrollTopPosition: number
  height: number
  rowHeight: number
}

export const useDisplayAreaInfo = <T>({ contacts, scrollTopPosition, height, rowHeight }: DisplayAreaInfoProps<T>) =>
  useMemo<DisplayAreaInfo<T>>(() => {
    const rowsInHeight = height / rowHeight
    const displayFrom = scrollTopPosition <= height ? 0 : Math.floor((scrollTopPosition - height) / rowHeight)
    const rowsAbove = displayFrom > rowsInHeight ? rowsInHeight : displayFrom
    let displayTo = displayFrom + rowsInHeight * 2 + rowsAbove
    if (displayTo > contacts.length) {
      displayTo = contacts.length
    }
    const topPlaceholderHeight = displayFrom * rowHeight
    const bottomPlaceholderHeight = (contacts.length - displayTo) * rowHeight
    const visibleContacts = contacts.slice(displayFrom, displayTo)
    return { visibleContacts, topPlaceholderHeight, bottomPlaceholderHeight, displayFrom }
  }, [contacts, height, rowHeight, scrollTopPosition])
