import * as isNumber from 'is-number'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const iconAssets = {
  frown: '/assets/icons/emoticon-frown.png',
  sad: '/assets/icons/emoticon-sad.png',
  happy: '/assets/icons/emoticon-happy.png',
  excited: '/assets/icons/emoticon-excited.png'
}

export function getSmileySrc(score) {
  if (score > 0.5) return iconAssets.excited
  else if (score >= 0) return iconAssets.happy
  else if (score >= -0.5) return iconAssets.sad
  else return iconAssets.frown
}

//TODO? move (some of) this to parent class to allow re-use of icon
const useStyles = makeStyles({
  root: {
    width: '20px',
    height: '20px',
    marginRight: '29px',
    marginTop: '2px',
    '& span::after': { //TODO is this the right way to do this?
      marginRight: '15px'
    }
  },

})

export default function SmileyRatingIcon({ rating }) {
  const classes = useStyles()

  return isNumber(rating)
    ? (
      <img
        alt="satisfaction-rating"
        src={getSmileySrc(rating)}
        className={classes.root}
      />
    ) : null
}
