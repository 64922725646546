import React from 'react'
import { Moment } from 'moment'
import cn from 'classnames'
import { Box, Divider, makeStyles, Paper, Typography } from '@material-ui/core'

import { getSlots } from './helpers/volunteerSlots'
import EventVolunteersProgress from './EventVolunteersProgress'
import { Opportunity } from '../../interfaces'
import useFormatToOrgTime from './hooks/useFormatToOrgTime'
import { IS_FIREFOX, IS_SAFARI } from 'utils/browser-check'
import { createMarkup } from '../../../civic-champs-shared/core/utils/helpers'

const useStyles = makeStyles(theme => ({
  eventPreviewPrompt: {
    padding: '20px',
    maxHeight: '95vh',
    maxWidth: 300,
    overflowY: 'auto',
  },

  eventPreviewDate: {
    textAlign: 'center',
    marginBottom: '32px',
  },

  eventPreviewHeader: {
    paddingBottom: '8px',
    margin: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },

  eventPreviewName: {
    border: 'none',
    background: 'none',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    fontSize: '1.2rem',
    fontFamily: 'Open Sans',
    padding: 0,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    cursor: 'pointer',
  },

  eventPreviewDescription: {
    margin: '0 16px',
    lineClamp: 3,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& p': {
      margin: 0,
    },
  },

  eventPreviewDescriptionFirefoxSafari: {
    display: 'block',
    maxHeight: '6rem',
    margin: '1rem 0 1rem 0',
  },

  progressBarWrapper: {
    padding: '8px 0',
    position: 'relative',
  },

  eventProgressBarText: {
    textAlign: 'center',
    fontSize: '13px',
    marginBottom: '32px',
  },

  eventPreviewDivider: {
    margin: '16px 0',
    // @ts-ignore
    backgroundColor: theme.palette.neutral.grayDark,
  },

  draftLabel: {
    width: '85px',
    background: '#FFA030',
    color: '#fff',
    borderRadius: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    padding: '7px 29px 8px',
  },

  noEventsLabel: {
    fontSize: 20,
    fontWeight: 300,
    color: '#777777',
  },
}))

interface Props {
  classes?: any
  today?: Moment
  events: Opportunity[]
  onViewDetails: Function
}

export const DayEvents = (props: Props) => {
  const { events, onViewDetails, today } = props
  const classes = useStyles()
  const formatTime = useFormatToOrgTime()

  return (
    <Paper elevation={3} className={cn(classes.eventPreviewPrompt, props.classes.eventPreviewPrompt)}>
      <Typography variant="h5" className={classes.eventPreviewDate}>
        {formatTime(today ? today : events[0].startsAt, 'dddd, MMMM Do')}
      </Typography>
      {!events.length && (
        <Box textAlign="center">
          <Typography className={classes.noEventsLabel}>No Events Today</Typography>
        </Box>
      )}

      <div>
        {events.map((event, index) => {
          const slots = event.slots || getSlots(event)

          return (
            <div key={`${event.occurrenceId}_${index}`}>
              <div className={classes.eventPreviewHeader}>
                <button className={classes.eventPreviewName} onClick={() => onViewDetails(event)}>
                  {event.name}
                </button>
                {!event.published && <span className={classes.draftLabel}>Draft</span>}
              </div>
              <Typography>{`${formatTime(event.startsAt)} - ${formatTime(event.endsAt)}`}</Typography>
              <div
                className={cn(classes.eventPreviewDescription, {
                  [classes.eventPreviewDescriptionFirefoxSafari]: IS_FIREFOX || IS_SAFARI,
                })}
                dangerouslySetInnerHTML={createMarkup(event.description)}
              />
              <Typography className={classes.eventProgressBarText}>Roles</Typography>
              <div className={classes.progressBarWrapper}>
                <EventVolunteersProgress current={slots.total - slots.available} total={slots.total} big />
              </div>
              {index !== events.length - 1 && <Divider className={classes.eventPreviewDivider} />}
            </div>
          )
        })}
      </div>
    </Paper>
  )
}

DayEvents.defaultProps = {
  classes: {},
}
