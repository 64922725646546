import React, { useState } from "react";

import CampaignEditor from "../components/CampaignEditor";

export default function TestEditCampaign() {
    const [campaign, setCampaign] = useState()

    return <CampaignEditor 
            campaign={campaign}
            onCampaignChanged={setCampaign}
        />
}