import { Opportunity } from "Event/interfaces"
import { reduce } from 'lodash'

export const getSlots = (event: Opportunity) => {
  const slots = { available: 0, total: 0 }
  for(let shift of event.timeshifts || []) {
      for(let role of shift.roles || []) {
          slots.available += role.available
          slots.total += role.all
      }
  }

  return slots
}

export const mergeSlots = (events: Opportunity[]) =>
  reduce(
      events,
      (slots, event) => {
          const eventSlots = event.slots || getSlots(event)
          slots.available += eventSlots.available
          slots.total += eventSlots.total

          return slots
      },
      { available: 0, total: 0 }
  )