import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

const STABLE_EMPTY_ARRAY = []

export default function useInvitations() {
  const [request, { loading, error, result, called }] = useFetch(
    'Error fetching invitations.  Please refresh the page to try again',
  )
  const fetchInvitations = useCallback(() => {
    return request({ method: 'get', url: '/invitations' })
  }, [request])

  useEffect(() => {
    fetchInvitations()
  }, [])

  return useMemo(
    () => ([ result || STABLE_EMPTY_ARRAY, { loading: loading || !called, error }]),
    [result, loading, called, error],
  )
}
