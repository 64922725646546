import React, { useMemo } from 'react'
import { useGroups } from 'group/hooks/useGroups'
import { find, isNil } from 'lodash'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import useTripModalStyles from 'volunteering/trips/hooks/useTripModalStyles'
import { TripOrder, TripOrderStatus } from 'volunteering/trips/types'
import moment, { Moment } from 'moment'
import { mapEventGeofencingToGeofencing } from 'utils/event'
import { Address, AddressField } from 'civic-champs-shared/formik/components/AddressField'
import { Field, Form, Formik } from 'formik'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from 'civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import { Loading } from 'civic-champs-shared'
import { AdvancedPersonAutocomplete } from 'civic-champs-shared/core/AdvancedPersonAutocomplete'
import StyledInput from 'civic-champs-shared/core/StyledInput'
import * as yup from 'yup'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import { useFillCounty } from 'volunteering/trips/hooks/useFillCounty'
import { FormModal, FormModalScreen } from 'components/FormModal'

export interface AdjustTripFormValues {
  driver: PersonSuggestion
  pickUpAddress: Address
  dropOffAddress: Address
  pickUpDate: Moment
  pickUpTime: Moment
  dropOffDate: Moment
  dropOffTime: Moment
  mileage: string
}
interface Props {
  showing: boolean
  complete: (value: AdjustTripFormValues) => void
  close: () => void
  trip: TripOrder
}

export const adjustTripPayloadSchema = yup.object({
  driver: yup
    .mixed()
    .required('A driver is required. Please select a driver.')
    .typeError('A driver is required. Please select a driver.'),
  pickUpAddress: yup
    .object({
      line1: yup
        .string()
        .min(1, 'A pickup address is required. Please enter a pickup address.')
        .required('A pickup address is required. Please enter a pickup address.')
        .typeError('A pickup address is required. Please enter a pickup address.'),
    })
    .required('A pickup address is required. Please enter a pickup address.')
    .typeError('A pickup address is required. Please enter a pickup address.'),
  dropOffAddress: yup
    .object({
      line1: yup
        .string()
        .min(1, 'A drop-off address is required. Please enter a drop-off address.')
        .required('A drop-off address is required. Please enter a drop-off address.')
        .typeError('A drop-off address is required. Please enter a drop-off address.'),
    })
    .required('A drop-off address is required. Please enter a drop-off address.')
    .typeError('A drop-off address is required. Please enter a drop-off address.'),
  pickUpDate: yup
    .date()
    .required('A pickup date is required. Please enter a pickup date.')
    .typeError('A pickup date is required. Please enter a pickup date.'),
  pickUpTime: yup
    .date()
    .required('A pickup time is required. Please enter a pickup time.')
    .typeError('A pickup time is required. Please enter a pickup time.'),
  dropOffDate: yup
    .date()
    .test('min-by-date', 'The drop-off date cannot occur before the pickup date.', function (value: any) {
      return value && moment(value).isSameOrAfter(moment(this.parent.pickUpDate), 'day')
    })
    .required('A drop-off date is required. Please enter a drop-off date.')
    .typeError('A drop-off date is required. Please enter a drop-off date.'),
  dropOffTime: yup
    .date()
    .required('A drop-off time is required. Please enter a drop-off time.')
    .typeError('A drop-off time is required. Please enter a drop-off time.'),
  mileage: yup
    .number()
    .min(0, 'A mileage should be positive number')
    .required('A mileage is required. Please enter' + ' mileage.')
    .typeError('A mileage is' + ' required. Please enter mileage.'),
})

export const AdjustTripModal = ({ showing, close, complete, trip }: Props) => {
  const { groups } = useGroups()
  const driverGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Driver } })?.id, [groups])
  const [pickupCounty, pickupCountyLoading] = useFillCounty(trip.pickupLocation.address)
  const [dropOffCounty, dropOffCountyLoading] = useFillCounty(trip.dropOffLocation.address)
  const driverDisabled = useMemo(
    () =>
      ![TripOrderStatus.Unassigned, TripOrderStatus.Assigned, TripOrderStatus.Cancelled].includes(
        trip.tripOrderStatus as TripOrderStatus,
      ),
    [trip.tripOrderStatus],
  )

  const initialValues = useMemo(
    () => ({
      driver: trip?.driver
        ? {
            id: trip.driver.id,
            givenName: trip.driver.givenName,
            familyName: trip.driver.familyName,
            contact: trip.driver.email || trip.driver.phoneNumber || '',
            title: '',
            groupId: driverGroupId,
            pubnubId: '',
          }
        : null,
      pickUpAddress: {
        ...trip.pickupLocation.address,
        zip: trip.dropOffLocation.address.zipCode,
        county: pickupCounty,
        country: '',
        utcOffset: 0,
        geofencing: mapEventGeofencingToGeofencing(trip.pickupLocation.geofencing),
      } as Address,
      dropOffAddress: {
        ...trip.dropOffLocation.address,
        zip: trip.dropOffLocation.address.zipCode,
        county: dropOffCounty,
        country: '',
        utcOffset: 0,
        geofencing: mapEventGeofencingToGeofencing(trip.dropOffLocation.geofencing),
      } as Address,
      pickUpDate: trip.pickedUpAt ? moment(trip.pickedUpAt) : moment(trip.pickupAt),
      pickUpTime: trip.pickedUpAt ? moment(trip.pickedUpAt) : moment(trip.pickupAt),
      dropOffDate: trip.droppedOffAt ? moment(trip.droppedOffAt) : moment(trip.dropOffAt),
      dropOffTime: trip.droppedOffAt ? moment(trip.droppedOffAt) : moment(trip.dropOffAt),
      mileage: (isNil(trip.mileage) ? '' : trip.mileage) as string,
    }),
    [driverGroupId, dropOffCounty, pickupCounty, trip],
  )

  const classes = useTripModalStyles()

  return (
    <FormModal id="adjust-trip-prompt" showing={showing} close={close} title="Adjust Ride Details">
      {pickupCountyLoading || dropOffCountyLoading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues as any}
          validationSchema={adjustTripPayloadSchema}
          onSubmit={complete}
          validateOnChange
          isInitialValid={true}
        >
          {({ submitForm, isSubmitting, values, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <FormModalScreen
                  onBack={close}
                  disabled-={isSubmitting}
                  onNext={submitForm}
                  nextText="Next"
                  backText="Cancel"
                >
                  <Field
                    fullWidth
                    variant="outlined"
                    hideMap
                    labelShrink
                    label="Pickup Address *"
                    placeholder="Enter the address the rider will be picked up"
                    component={AddressField}
                    name="pickUpAddress"
                  />
                  <div className={classes.dateTimePickers}>
                    <div className={classes.dateTimePicker}>
                      <Field
                        inputVariant="outlined"
                        label="Pickup Date *"
                        higher
                        component={StyledKeyboardDatePicker}
                        name="pickUpDate"
                      />
                    </div>

                    <div className={classes.dateTimePicker}>
                      <Field
                        inputVariant="outlined"
                        label="Pickup Time *"
                        higher
                        component={StyledKeyboardTimePicker}
                        name="pickUpTime"
                      />
                    </div>
                  </div>
                  <Field
                    fullWidth
                    variant="outlined"
                    hideMap
                    labelShrink
                    label="Drop-Off Address*"
                    placeholder="Enter the address the rider will be dropped off"
                    component={AddressField}
                    name="dropOffAddress"
                  />
                  <div className={classes.dateTimePickers}>
                    <div className={classes.dateTimePicker}>
                      <Field
                        higher
                        inputVariant="outlined"
                        label="Drop-off Date *"
                        component={StyledKeyboardDatePicker}
                        name="dropOffDate"
                        minDate={values.pickUpDate || new Date()}
                        minDateMessage={'The drop-off date cannot occur before the pickup date.'}
                      />
                    </div>

                    <div className={classes.dateTimePicker}>
                      <Field
                        higher
                        inputVariant="outlined"
                        label="Drop-off Time *"
                        component={StyledKeyboardTimePicker}
                        name="dropOffTime"
                      />
                    </div>
                  </div>
                  <AdvancedPersonAutocomplete
                    label={'Driver *'}
                    placeholder={'Select a person to be the trip’s driver'}
                    groupId={driverGroupId}
                    value={values.driver}
                    excludeNonGroupMembers={true}
                    onChange={driver => setFieldValue('driver', driver)}
                    error={touched.driver ? (errors.driver as string) : undefined}
                    disabled={driverDisabled}
                  />
                  <div className={classes.dateTimePickers}>
                    <div className={classes.dateTimePicker}>
                      <StyledInput
                        value={values.mileage}
                        onChange={mileage => setFieldValue('mileage', mileage)}
                        label={'Miles Driven *'}
                        placeholder="Enter miles driven"
                        error={touched.mileage ? errors.mileage : undefined}
                      />
                    </div>
                  </div>
                </FormModalScreen>
              </Form>
            )
          }}
        </Formik>
      )}
    </FormModal>
  )
}
export default AdjustTripModal
