import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import CreateOrUpdateOpportunityPrompt from '../components/CreateOrUpdateOpportunityPrompt'
import { useCallback } from 'react'

const useCreateOrUpdateOpportunityPrompt = (onCreateOrUpdate) => {
  const prompt = usePrompt(CreateOrUpdateOpportunityPrompt)

  return useCallback((opportunity) => {
    if (prompt.showing) return;

    if (onCreateOrUpdate) {
      const unlistens = [
        prompt.onComplete(onCreateOrUpdate),
        prompt.onHide(() => { unlistens.forEach(unlisten => unlisten()) })
      ]
    }
      
    prompt.show({ opportunity })
  }, [prompt, onCreateOrUpdate])
}

export default useCreateOrUpdateOpportunityPrompt
