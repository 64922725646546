import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import BackLink from '../../donation/campaigns/components/BackLink'
import { useGroup } from '../hooks/useGroup'
import { useFetchGroupQuestionSets } from '../hooks/useFetchGroupQuestionSets'
import { useSetGroupQuestionSets } from '../hooks/useSetGroupQuestionSets'
import { QuestionSetPicker } from 'question-sets/components/QuestionSetPicker'
import { useGroupLink } from '../hooks/useGroupLink'
import { WaiverPicker } from 'question-sets/components/WaiverPicker'
import { useGroupCredentials } from '../hooks/useGroupCredentials'
import { useSetGroupCredentials } from '../hooks/useSetGroupCredentials'

export const GroupsQuestionSets = props => {
  const groupId = props.match.params.id
  const [existingQuestionSets, setExistingQuestionSets] = useState([])
  const [existingCredentials, setExistingCredentials] = useState([])
  const { group, loading: groupLoading } = useGroup(groupId)
  const { loading: credentialsLoading } = useGroupCredentials(groupId, setExistingCredentials)
  const { link, text } = useGroupLink(group) || {}
  const [setGroupQuestionSets] = useSetGroupQuestionSets(setExistingQuestionSets)
  const [setGroupCredentials] = useSetGroupCredentials(setExistingCredentials)
  const [fetchGroupQuestionSets, { loading: groupQuestionSetsLoading, called }] =
    useFetchGroupQuestionSets(setExistingQuestionSets)

  useEffect(() => {
    fetchGroupQuestionSets(groupId)
  }, [fetchGroupQuestionSets, groupId])

  const handleQuestionSetSubmit = async questionSets => {
    await setGroupQuestionSets(groupId, questionSets)
  }

  const handleCredentialSubmit = async credentialIds => {
    await setGroupCredentials(groupId, credentialIds)
  }

  const loading = groupLoading || groupQuestionSetsLoading || !called || credentialsLoading
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <BackLink to={link} text={text} />
      <Grid container direction="column" spacing={3}>
        <Grid container item>
          <QuestionSetPicker
            loading={loading}
            showRequired
            title={<Typography variant="h4">{group.name} Questionnaires</Typography>}
            value={existingQuestionSets.map(groupQuestionSet => ({
              questionSetId: groupQuestionSet.questionSet.id,
              name: groupQuestionSet.questionSet.name,
              optional: !groupQuestionSet.required,
              surveyId: groupQuestionSet.survey?.id,
            }))}
            onSubmit={handleQuestionSetSubmit}
            backLink={`/groups/${groupId}/questionnaires`}
          />
        </Grid>
        <Grid container item>
          <WaiverPicker
            loading={loading}
            title={<Typography variant="h4">{group.name} Waivers</Typography>}
            value={existingCredentials.map(credential => credential.id)}
            onSubmit={handleCredentialSubmit}
          />
        </Grid>
      </Grid>
    </>
  )
}
