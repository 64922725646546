export enum Outcome {
  Skipped = 'skipped',
  Error = 'error',
  Invalid = 'invalid',
  Valid = 'valid',
}

export interface ImportMappings {
  [key: string]: string | null
}

export const mapRow = <T extends ImportMappings>({ row, mappings }: { row: Record<string, any>; mappings: T }) =>
  Object.entries(mappings).reduce((acc, [key, mapping]) => {
    const value = mapping ? row[mapping] : null
    if (value) {
      acc[key as keyof T] = value
    }
    return acc
  }, {} as T)
