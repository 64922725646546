import { useCallback } from 'react'
import { useFetchRefactored } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'

export default function useResendAdminInvitation() {
  const [request] = useFetchRefactored({
    successMessage: 'Invitation Resent Successfully',
    errorMessage: 'Error resending invitation.',
    useNewNotification: true,
  })
  const organization = useCurrentOrg()
  return useCallback(
    (personIds: number) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/resend-admin-invitations`,
        config: {
          body: { personIds },
        },
      })
    },
    [organization.id, request],
  )
}
