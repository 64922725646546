import React from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { AddCircleOutline, Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import Loading from 'components/Loading'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import usePersonLocations from '../hooks/usePersonLocations'
import useRemovePersonLocation from '../hooks/useRemovePersonLocation'
import AddOrEditLocationPrompt from './AddOrEditLocationPrompt'

const useStyles = makeStyles(theme => ({
    dialog: {
        width: '100%',
        height: '100%',
        zIndex: '1298',
        position: 'fixed',
        top: 0,
        background: theme.palette.background.default,
        padding: theme.spacing(4)
    },
    header: {
        display: 'inline-block',
        marginRight: theme.spacing(2)
    },
    headerRow: {
        marginBottom: theme.spacing(4)
    },
    close: {
        position: 'absolute',
        top: '0.65em',
        right: '1em'
    },
    location: {
        border: '1px solid',
        borderColor: '#707070',
        borderRadius: '2px',
        padding: 8,
        margin: 8,
        cursor: 'pointer',
    },
    editButton: {
        marginRight: theme.spacing(2)
    }
  }))

export default function AddressBookPrompt(props) {
    const { close, person, refreshParentLocations } = props
    const classes = useStyles()
    const showAddLocationPrompt = useShowPrompt(AddOrEditLocationPrompt)
    const { personLocations, loading, refresh } = usePersonLocations(person.id)
    const { removePersonLocation, loading: removeLoading } = useRemovePersonLocation()
    const showError = useErrorNotification()

    const handleAddLocationClick = async () => {
        await showAddLocationPrompt({ personId: person.id, refreshLocations: refresh })
    }

    const handleEditLocationClick = async (personLocation) => {
        await showAddLocationPrompt({
          personId: person.id,
          refreshLocations: refresh,
          personLocation,
        })
    }

    const handleRemoveLocation = async (personLocation) => {
        try {
            await removePersonLocation(personLocation)
            refresh()
        } catch (err) {
            showError('There was a problem removing the location', err)
        }
    }

    const handleCloseClick = () => {
        refreshParentLocations()
        close()
    }

    return (
        <div className={classes.dialog}>
            <Grid container direction='row' justify='space-between' className={classes.headerRow}>
                <Grid item alignContent='center' container>
                    <Typography variant='h4' className={classes.header}>{person.givenName} {person.familyName}'s Address Book</Typography>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleAddLocationClick}
                    >
                        <AddCircleOutline />
                        Add
                    </Button>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleCloseClick} className={classes.close}><Close /></IconButton>
                </Grid>
            </Grid>
            <Grid container direction='column'>
            {
                loading || removeLoading ?
                <Loading />
                :
                <>{personLocations.map(pl => (
                    <div key={pl.id} className={classes.location}>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item>
                                <p>{pl.location.address.line1}</p>
                                <p>{pl.location.address.city} {pl.location.address.state}</p>
                                <p>{pl.location.address.zipCode}</p>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() => handleEditLocationClick(pl)}
                                    className={classes.editButton}
                                >
                                    Edit Location
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => handleRemoveLocation(pl)}
                                >
                                    Remove Location
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ))}</>
            }
            </Grid>
        </div>
    )
}
