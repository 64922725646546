/*
  TODO
    1. clean up some of the code - copy-pasted from before, but could be improved
    2. build out the ability to write conditions
    3. build out ability to re-order questions
    4. RE-WRITE THIS WHOLE THING...IT'S COPY-PASTA'D FROM OLD CODE, BUT SHOULD BE RE-WRITTEN WITH FORMIK
 */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import QuestionItem from '../question-item'
import BigPlusButton from 'civic-champs-shared/core/big-plus-button'
import './index.scss'
import { QuestionType } from 'civic-champs-shared/question-sets/types'
import { Button } from '@material-ui/core'
import Loading from 'civic-champs-shared/core/Loading'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { NameModal } from './NameModal'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const QuestionSetCreation = (props) => {
  const {
    questionSet = { questions: [], name: '', anonymous: false },
    onSubmit,
    existingQuestions = [],
    loading,
    showAnonymousCheckbox = false,
  } = props
  const emptyQuestion = {
    id: null,
    questionType: QuestionType.SHORT_TEXT,
    question: '',
    options: [],
    isRequired: false,
  }
  const isExisting = !!questionSet.questionSetId

  const [questionState, setQuestionState] = useState(questionSet.questions)
  const [isClickedToNextButton, setIsClickedToNextButton] = useState(false)
  const [questionStateWithValidField, setQuestionStateWithValidField] = useState([])
  const showNameModal = useShowPrompt(NameModal)

  const handleAddNewQuestion = () => {
    setQuestionState([...questionState, { ...emptyQuestion, id: String(questionState.length) }])
  }

  const handleRemoveQuestion = (idx) => () => {
    const updatedQuestion = [...questionState]
    updatedQuestion.splice(idx, 1)
    setQuestionState(updatedQuestion)
    const updatedQuestionWithValidState = [...questionStateWithValidField]
    updatedQuestionWithValidState.splice(idx, 1)
    setQuestionStateWithValidField(updatedQuestionWithValidState)
  }

  const handleDuplicateQuestion = (index) => () => {
    setQuestionState([...questionState, { ..._.omit(questionState[index], ['questionId']) }])
  }

  const handleChangeField = (idx) => (val, field) => {
    const updatedQuestion = [...questionState]
    if (field) {
      updatedQuestion[idx][field] = val
    } else {
      updatedQuestion[idx] = val
    }
    setQuestionState(updatedQuestion)
  }

  const handleChangeValidField = (idx) => (value) => {
    const updatedQuestions = _.cloneDeep(questionState)
    updatedQuestions[idx]['is_valid'] = value
    setQuestionStateWithValidField(updatedQuestions)
  }

  const isHaveErrorAtQuestions = () => {
    return questionStateWithValidField.some((i) => i.is_valid === false || i.is_valid === null)
  }

  const handleSubmit = () => {
    setIsClickedToNextButton(true)
    const errorFree = !isHaveErrorAtQuestions()
    if (questionState.length && errorFree) {
      showNameModal({
        // it is risky to set an existing question set as anonymous that wasn't previously anonymous, as it might already be used in other places
        showAnonymousCheckbox: showAnonymousCheckbox && (questionSet.anonymous || !questionSet.questionSetId),
        questionSet,
        isExisting,
        onSave: ({ name, anonymous }) => {
          onSubmit({
            ...questionSet,
            questions: questionState,
            name,
            anonymous,
            // if we change the name, then we save it as a new questionSet, so save under a new questionSetId
            questionSetId: name === questionSet.name ? questionSet.questionSetId : undefined,
          })
        },
      })
    }
  }

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return

    setQuestionState((oldSate) => reorder(oldSate, result.source.index, result.destination.index))
  }, [])

  const getItemStyle = useCallback(
    (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      background: isDragging ? '#ADD7F3' : 'white',
      // styles we need to apply on draggables
      ...draggableStyle,
      opacity: isDragging ? 0.5 : 1,
    }),
    [],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <div className="custom-question-module">
      <h2 className="custom-question-module__title">{questionSet.questionSetId ? 'Edit' : 'New'} Questionnaire</h2>
      <div className="custom-question-form">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questionarie">
            {(dropabbleProvided) => (
              <div {...dropabbleProvided.droppableProps} ref={dropabbleProvided.innerRef}>
                {questionState &&
                  questionState.map((question, index) => {
                    const itemId = question.questionId ? String(question.questionId) : question.id

                    return (
                      <Draggable key={itemId} draggableId={itemId} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <QuestionItem
                              questions={questionState}
                              question={question}
                              onChangeField={handleChangeField(index)}
                              onDuplicateQuestion={handleDuplicateQuestion(index)}
                              onRemoveQuestion={handleRemoveQuestion(index)}
                              onChangeValidField={handleChangeValidField(index)}
                              isClickedToNextStep={isClickedToNextButton}
                              existingQuestions={existingQuestions}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                {dropabbleProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="button-row">
          <BigPlusButton
            title="Add New Question"
            width="250px"
            className={!!questionState.length ? 'add-question-button-wrap' : ''}
            onClick={handleAddNewQuestion}
          />
          <Button
            color="secondary"
            variant="contained"
            disabled={!questionState.length || isHaveErrorAtQuestions()}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}

QuestionSetCreation.propTypes = {
  questionSet: PropTypes.object,
  existingQuestions: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
}

export default QuestionSetCreation
