import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useUISettings } from 'hooks/useUISettings'
import { DateTimeCell, NullableTextCell, NumberCell, WithLinkCell } from 'core/table/cells'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  fullNameColumn: {
    '&>div': {
      width: '200px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '150px',
    },
  },
  versionColumn: {
    '&>div': {
      width: '100px',
    },
  },
})

export const useColumns = () => {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: (row: any) => row.id,
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: (row: any) => row.versions[0]?.questionSet?.name || 'Untitled',
            Cell: (props: any) =>
              WithLinkCell(
                NullableTextCell,
                (id: any) => `/mentorship/surveys/${id}`,
              )({ ...props, linkProps: { className: classes.link } }),
          },
          {
            id: 'createdBy',
            Header: 'Created By',
            accessor: (row: any) => row.createdBy,
            Cell: ({ value }: any) => {
              const { id, givenName, familyName } = value || {}
              return id ? (
                <Link className={classes.link} to={`/champion/${id}`}>
                  {givenName} {familyName}
                </Link>
              ) : null
            },
            disableFilters: true,
            className: classes.fullNameColumn,
          },
          {
            id: 'version',
            Header: 'Version',
            accessor: (row: any) => row.versions?.length,
            Cell: NumberCell,
          },
          {
            id: 'lastUpdated',
            Header: 'Last Edited On',
            accessor: (row: any) => row.versions[0]?.createdAt,
            Cell: DateTimeCell,
          },
          {
            id: 'lastEditedBy',
            Header: 'Last Edited By',
            accessor: (row: any) => row.versions[0]?.questionSet?.createdBy,
            Cell: ({ value }: any) => {
              const { id, givenName, familyName } = value || {}
              return id ? (
                <Link className={classes.link} to={`/champion/${id}`}>
                  {givenName} {familyName}
                </Link>
              ) : null
            },
          },
        ],
        {
          showAdminItems,
        },
      ),
    [classes.fullNameColumn, classes.link, showAdminItems],
  )
}
