import React from 'react'
import styled from 'styled-components'
import './index.scss'

const ProgressBar = props => {
  const { total, current } = props

  const currentWidth = ((100 * current) / total).toFixed()

  const StyledProgressLine = styled.span`
    width: ${currentWidth}%;
  `

  return (
    <div className="progress-bar">
      <StyledProgressLine className="progress-bar__progress" />
      <span className="progress-bar__info">
        {current} / {total}
      </span>
    </div>
  )
}

export default ProgressBar
