import { useCallback } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export default function useOpenStripeConnectPage() {
  const [request, { loading, error, called }] = useFetch()
  const org = useCurrentOrg()
  const openStripeConnectPage = useCallback(
    (type) => {
      return request({
        method: 'post',
        url: `/stripe/organization/${org.id}/redirect/${type}`,
        onSuccess: (result) => window.location.replace(result.url),
      })
    },
    [request],
  )

  return [
    openStripeConnectPage,
    {
      loading,
      error,
      called,
    }
  ]
}
