import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { AddEditDocumentTypePrompt } from 'documents/components/AddEditDocumentTypePrompt'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useSaveDocumentType } from 'documents/hooks/useSaveDocumentType'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'

const useAddEditDocumentsPrompt = ({
  eagerAdd,
  eagerReplace,
}: {
  eagerReplace: Action<DocumentType>
  eagerAdd: Action<DocumentType>
}) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(AddEditDocumentTypePrompt)
  const { saveDocumentType } = useSaveDocumentType()
  return useCallback(
    async (existingDocumentType?: DocumentType) => {
      // @ts-ignore
      const formData = await showPrompt({ documentType: existingDocumentType })
      const notification = createNotification('Saving document type')
      try {
        const documentType = await saveDocumentType(formData)
        if (formData.id) {
          eagerReplace(documentType)
        } else {
          eagerAdd(documentType)
        }

        notification.onSuccess('Document type saved')
      } catch (error) {
        notification.onError('Failed to save document type', error)
      }
    },
    [createNotification, eagerAdd, eagerReplace, saveDocumentType, showPrompt],
  )
}

export default useAddEditDocumentsPrompt
