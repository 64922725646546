import { find } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RECURRING_TYPE, RECURRING_TYPE_OPTIONS } from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import SelectComponent from '../select'

const RecurringTypeSelect = props => {
  const { startsAt, value, onChange, editMode } = props

  const options = useMemo(() => RECURRING_TYPE_OPTIONS(startsAt), [startsAt])

  const setInitialOption = useCallback(() => {
    let option = find(options, { value })
    if (option == null) {
      option = find(options, { value: RECURRING_TYPE.NEVER })
    }

    return option
  }, [options, value])

  const [option, setOption] = useState(setInitialOption())

  const handleOnChange = option => {
    setOption(option)
    if (onChange) onChange(option.value)
  }

  useEffect(() => {
    editMode && setOption(setInitialOption(value))
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectComponent
      disabled={!editMode ? !startsAt : true}
      value={option}
      onChange={handleOnChange}
      options={options}
      className="select"
    />
  )
}

export default RecurringTypeSelect
