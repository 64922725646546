export const SET_TIMESHEET_MODE = 'kiosk.set-timesheet-mode';

export const CHECK_IN = 'kiosk.check-in';
export const CHECK_IN_SUCCEEDED = 'kiosk.check-in.succeeded';
export const CHECK_IN_FAILED = 'kiosk.check-in.failed';

export const CHECK_OUT = 'kiosk.check-out';
export const CHECK_OUT_SUCCEEDED = 'kiosk.check-out.succeeded';
export const CHECK_OUT_FAILED = 'kiosk.check-out.failure';

export const GET_VOLUNTEERING_ROLES = 'kiosk.get-volunteering-roles';
export const GET_VOLUNTEERING_ROLES_SUCCEEDED = 'kiosk.get-volunteering-roles.succeeded';
export const GET_VOLUNTEERING_ROLES_FAILED = 'kiosk.get-volunteering-roles.failed';

export const setTimesheetMode = (mode) => {
    if(mode !== 'check-in' && mode !== 'check-out') {
        throw new Error(`Unknown timesheet mode '${mode}', expected 'check-in' or 'check-out'`);
    }

    return {
        type: SET_TIMESHEET_MODE,
        payload: { mode }
    }
}

export const checkIn = (volunteer) => ({
    type: CHECK_IN,
    payload: { volunteer }
});

export const checkInSucceeded = (volunteer) => ({
    type: CHECK_IN_SUCCEEDED,
    payload: { volunteer }
});

export const checkInFailed = (error) => ({
    type: CHECK_IN_FAILED,
    payload: { error }
});

export const checkOut = (volunteer, activityData) => ({
    type: CHECK_OUT,
    payload: { volunteer, activityData }
});

export const checkOutSucceeded = (volunteer) => ({
    type: CHECK_OUT_SUCCEEDED,
    payload: { volunteer }
});

export const checkOutFailed = (error) => ({
    type: CHECK_OUT_FAILED,
    payload: { error }
});

export const getVolunteeringRoles = () => ({ type: GET_VOLUNTEERING_ROLES });

export const getVolunteeringRolesSucceeded = (roles) => ({ 
    type: GET_VOLUNTEERING_ROLES_SUCCEEDED,
    payload: { roles }
});

export const getVolunteeringRolesFailed = (error) => ({ 
    type: GET_VOLUNTEERING_ROLES_FAILED,
    payload: { error }
});
