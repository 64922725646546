import { Person } from 'people/interface'

export interface Member {
  id: number
  pubnubId: string
  avatar?: string
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  memberSince: Date
  role?: string
}

export interface MemberApiResponse {
  id: number
  pubnubId: string
  avatar?: string
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  memberSince: string
}

export interface Chat {
  id: string
  name: string
  description: string
  createdAt: Date
  lastActivityAt: Date
  membersCount?: number
}

export interface ChatPayload {
  id?: string
  name: string
  description: string
}

export interface ChatMembersPayload {
  chatId: string
  uuids: string[]
}

export interface ChatApiResponse {
  id: string
  name: string
  description: string
  createdAt: string
  lastActivityAt: string
  membersCount?: number
}

export interface ChatMock {
  id: string
  name: string
  description: string
  updated: Date
  eTag: string
  custom: {
    createdAt: Date
    organizationId: number
    membersCount: number
    [name: string]: any
  }
}
export interface ChatColumn {
  id: number
  person: Member
}

export interface BasicChatPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

export interface ChatPromptProps extends BasicChatPromptProps {
  initialMembers?: Person[]
  name: string
  description: string
  isNew: boolean
}

export interface EditChatPromptProps extends BasicChatPromptProps {
  name: string
  description: string
}

export interface AddChatPromptProps extends BasicChatPromptProps {
  initialMembers?: Person[]
}

export const ProductionChatTarget = {
  environment: 'production',
  topic: 'com.mentoringworks',
}

