import { Chat, ChatApiResponse, ChatPayload } from 'chats/interfaces'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { useCallback } from 'react'
import { mapChat } from 'chats/helpers'

export const useUpdateChat = (): [(body: ChatPayload) => Promise<Chat>, Status<ChatApiResponse>] => {
  const [request, status] = useFetchRefactored<ChatApiResponse>()
  const org = useCurrentOrg()

  const addChat = useCallback(
    ({ id, ...body }: ChatPayload) =>
      request({
        method: 'put',
        url: `/organizations/${org.id}/chats/${id}`,
        config: {
          body,
        },
      }).then(mapChat),

    [request, org],
  )

  return [addChat, status]
}

export default useUpdateChat
