import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Grid, IconButton, InputAdornment } from '@material-ui/core'
import Loading from 'components/Loading'
import { useCreateApiKey, useDeleteApiKey, useGetApiKeys } from 'api-key/hooks'
import first from 'lodash/first'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import TextField from '@material-ui/core/TextField'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'

export default function IntegrationApiKey() {
  const [getApiKeys, getStatus] = useGetApiKeys()
  const [createApiKey, createStatus] = useCreateApiKey()
  const [deleteApiKey, deleteStatus] = useDeleteApiKey()
  const [value, setValue] = useState<string>('')
  const inputEl = useRef(null)

  const showSuccess = useSuccessNotification(true)

  useEffect(() => {
    const handler = async () => {
      const keys = await getApiKeys()
      const token = first(keys)?.token
      if (token) setValue(token)
    }
    handler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleCopy = useCallback(
    async (input: string) => {
      // @ts-ignore
      inputEl?.current?.select()
      await navigator.clipboard.writeText(input)
      showSuccess('Api Key Copied to Clipboard')
    },
    [inputEl, showSuccess],
  )
  const handleClick = useCallback(async () => {
    if (value) await deleteApiKey(value)
    const { token } = await createApiKey()
    setValue(token)
    await handleCopy(token)
  }, [createApiKey, deleteApiKey, handleCopy, value])

  if (getStatus.loading) {
    return <Loading />
  }
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          variant="outlined"
          id="api-key"
          fullWidth
          label="Api Key"
          type="text"
          value={value}
          onChange={() => null}
          inputProps={{ ref: inputEl }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="copy" onClick={() => handleCopy(value)}>
                  <FileCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Button disabled={createStatus.loading || deleteStatus.loading} onClick={handleClick}>
          {value ? 'Regenerate key' : 'Generate key'}
        </Button>
      </Grid>
    </Grid>
  )
}
