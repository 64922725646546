import React, { useState } from 'react'
import { Grid, Typography, Paper, Button } from '@material-ui/core'
import Loading from 'components/Loading'
import BackLink from 'donation/campaigns/components/BackLink'
import useInvitation from '../hooks/useInvitation'
import useResendInvitation from '../hooks/useResendInvitation'
import useRevokeInvitation from '../hooks/useRevokeInvitation'
import format from 'civic-champs-shared/utils/format'
import { capitalize, get } from 'lodash'

export const InvitationDetail = props => {
  const id = props.match.params.id
  const [invitation, setInvitation] = useState(null)
  const [originalInvitation, { loading: invitationLoading }] = useInvitation(id, setInvitation)
  const [resendInvitation, { loading: resendLoading }] = useResendInvitation(newInvitation =>
    props.history.push(`/invitations/${newInvitation.id}`),
  )
  const [revokeInvitation, { loading: revokeLoading }] = useRevokeInvitation(setInvitation)
  const loading = invitationLoading || resendLoading || revokeLoading

  if (loading) {
    return <Loading />
  }
  return (
    <div>
      <BackLink to={'/invitations'} text="Back to Invitations" />
      <Grid container spacing={4} direction="column">
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Invitation</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Date</strong>
                  <p>{format.date(invitation.person.createdAt)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>First Name</strong>
                  <p>{invitation.person.givenName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Last Name</strong>
                  <p>{invitation.person.familyName}</p>
                </Grid>
                <Grid item sm={3} style={{ overflowWrap: 'break-word' }}>
                  <strong>Contact</strong>
                  <p>
                    {invitation.method === 'email'
                      ? invitation.recipientEmail
                      : format.phoneNumber(invitation.recipientPhoneNumber)}
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Type</strong>
                  <p>{capitalize(invitation.invitationType)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Organization</strong>
                  <p>{get(invitation, 'organization.name', '-')}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Opportunity</strong>
                  <p>{get(invitation, 'opportunity.name', '-')}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Status</strong>
                  <p>{capitalize(invitation.displayStatus || invitation.invitationStatus)}</p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {(invitation.isResendable || invitation.isRevocable) && (
          <Grid container item sm={8} spacing={2}>
            <Grid item sm={12}>
              <Typography variant="h5">Actions</Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper variant="outlined" style={{ padding: 20 }}>
                <Grid container spacing={2}>
                  {invitation.isResendable &&
                  <Grid item sm={3}>
                    <Button onClick={() => resendInvitation(invitation)} disabled={loading}>Resend Invitation</Button>
                  </Grid>
                  }
                  {invitation.isRevocable &&
                  <Grid item sm={3}>
                      <Button onClick={() => revokeInvitation(invitation)} disabled={loading}>Revoke Invitation</Button>
                  </Grid>
                  }
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
