import { get } from 'lodash'
import { call, fork, put, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { Auth, Hub } from 'aws-amplify'

import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import {
  AUTH_PROCESS_ERROR,
  authInitialized,
  authProcessError,
  continueSession,
  SIGNED_OUT,
  signedIn,
  signedOut,
} from './actions'
import decodeJwtToken from 'civic-champs-shared/auth/utils/decodeJwtToken'

export default function* authRootSaga() {
  yield fork(watchForAuthProcessErrors)
  yield fork(watchForAuthEvents)
}

export function* watchForAuthEvents() {
  const channel = listenForAuthEvents()
  yield takeEvery(channel, onAuthEvent)
}

export function* onAuthEvent(authEvent) {
  const user = get(authEvent, 'payload.user')

  if ([SIGNED_OUT, AUTH_PROCESS_ERROR].includes(authEvent.type)) {
    const hasToken = !!localStorage.getItem('token')
    localStorage.removeItem('token')
    localStorage.removeItem('firstLogin')
    if (window.sharedWorker) {
      window.sharedWorker.port.postMessage({
        event: 'stop',
      })
    }
    if (hasToken) {
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
    yield put(authEvent)
  } else {
    const orgId = get(user, 'permissions.managedOrganization.id')

    //TODO this should be a temporary block / fix
    if (!user) {
      yield put(authProcessError('Auth Event without user', authEvent))
    }

    try {
      const organization = orgId ? yield call(requestWithRetry, { url: `/organizations/${orgId}` }) : null
      const profile = yield call(requestWithRetry, { url: `/my-profile` })
      yield put(authInitialized(user, organization, profile))
    } catch (e) {
      yield put(authProcessError(`The organization id:${orgId} could not be fetched!`, e))
    }
  }
}

export function* watchForAuthProcessErrors() {
  yield takeEvery(AUTH_PROCESS_ERROR, onAuthProcessError)
}

export function onAuthProcessError(authErrorEvent) {
  /*
    yield put( enqueueNotification(
        'An error occurred during authentication',
        {
            variant: 'warning',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            persist: false
        }
    ))
    */

  const { message = 'An error occurrend during authentication', data } = authErrorEvent.payload || {}

  if (data instanceof Error) {
    console.error(message, data.stack)
  } else {
    const output = message + !!data ? `: ${JSON.stringify(data, undefined, 4)}` : ''
    console.log(output)
  }
  Auth.signOut()
}

function listenForAuthEvents() {
  return new eventChannel(emit => {
    const listener = data => {
      switch (data.payload.event) {
        case 'signIn':
          emit(signedIn(mapHubEventToUser(data)))
          break

        case 'signOut':
          emit(signedOut())
          break

        default:
      }
    }

    getCurrentAuthenticatedUser()
      .then(user => {
        emit(continueSession(user))
        Hub.listen('auth', listener)
      })
      .catch(e => {
        emit(authProcessError('failed to get cognito user', e))
      })

    return () => Hub.remove('auth', listener)
  })
}

async function getCurrentAuthenticatedUser() {
  try {
    const userInfo = await Auth.currentAuthenticatedUser()
    return mapHubEventToUser({ payload: { data: userInfo } })
  } catch (error) {
    try {
      const token = localStorage.getItem('token')
      const userInfo = decodeJwtToken(token)
      return mapHubEventToUser({ payload: { data: { attributes: userInfo } } })
    } catch (error) {}
    return null
  }
}

function mapHubEventToUser(event) {
  let attrs = event.payload.data.attributes

  let permissions
  try {
    permissions = JSON.parse(attrs['custom:permissions'])
  } catch (error) {
    permissions = {
      role: 'USER',
      managedOrganization: null,
    }
  }

  return {
    cognitoSub: attrs.sub,
    givenName: attrs.given_name,
    familyName: attrs.family_name,
    email: attrs.email,
    phone: attrs.phone || attrs.alt_phone,
    permissions,
    //TODO map verification status
    //emailVerified:
    //phoneVerified:
  }
}
