import React, { useRef, useCallback, useMemo, RefObject } from 'react'
import { map, isEmpty } from 'lodash'
import moment, { Moment } from 'moment';
import cn from 'classnames'
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core'

import { Opportunity } from 'Event/interfaces';
import EventVolunteersProgress from './EventVolunteersProgress'
import { mergeSlots } from './helpers/volunteerSlots'
import useFormatToOrgTime from './hooks/useFormatToOrgTime'
import useCalendarDayStyles from './hooks/useCalendarDayStyles'
import { viewTypes } from './viewTypes'

export interface ShowEventPopupParams  {
    events: Opportunity[],
    anchorRef: RefObject<Element | null>
}

type ShowEventPopupCallback = (params: ShowEventPopupParams) => void;

interface Props {
    date: Moment,
    events: Opportunity[],
    onShowEventPopup?: ShowEventPopupCallback,
    month: number,
    viewType: string,
}

const useStyles = makeStyles(() => ({
    calendarEvent: {
        '&:before': {
            display: 'none !important',
        },
    },
}))

const EventCalendarDay = (props: Props) => {
    const { date, events, onShowEventPopup, month, viewType } = props
    const classes = useCalendarDayStyles()
    const ownClasses = useStyles()
    const isShort = useMediaQuery('(max-height: 750px)')
    const formatTime = useFormatToOrgTime()

    const ref = useRef(null)
    const dayOfMonth = date.date()

    const isWeekView = viewType === viewTypes.WEEK
    const isMonthView = viewType === viewTypes.MONTH

    const numEventsToDisplay = isShort
      ? 1
      : isWeekView
        ? Infinity
        : 3

    const displayEvents = events.slice(0, numEventsToDisplay)
    const isCurrentMonth = date.month() === month
    const isPast = date < moment()
    const isToday = date.isSame(moment(), 'day')

    const { total, available } = mergeSlots(events)

    const hasPopup = useMemo(() => (!isEmpty(events)), [events])

    const handleClick = useCallback(e => {
        if (onShowEventPopup && hasPopup) {
            onShowEventPopup({
                events: events,
                anchorRef: ref
            })
        }
    }, [events, onShowEventPopup, hasPopup])

    const cellClasses = useMemo(() => cn(
        classes.calendarCell,
        {
            [classes.calendarCellWeek]: isWeekView,
            [classes.today]: isToday,
            [classes.pastDay]: isPast,
            [classes.otherMonth]: !isCurrentMonth,
            [classes.pointer]: hasPopup,
        }
    ), [classes.calendarCell, classes.today, classes.pastDay, classes.otherMonth, classes.pointer, classes.calendarCellWeek, isToday, isPast, isCurrentMonth, hasPopup, isWeekView])

    const eventClasses = useMemo(() => cn(
        classes.calendarEvent,
        {
            [classes.singleLine]: (events.length > 2 || isShort) && isMonthView,
            [classes.doubleLine]: events.length === 2 && !isShort,
            [classes.tripleLine]: isWeekView || (events.length === 1 && !isShort),
            [classes.calendarEventWeek]: isWeekView,
            [ownClasses.calendarEvent]: !isWeekView,
        }
    ), [classes.calendarEvent, classes.singleLine, classes.doubleLine, classes.tripleLine, classes.calendarEventWeek, events.length, isShort, isMonthView, isWeekView, ownClasses.calendarEvent])

    return (
        <div
            className={cellClasses}
            onClick={handleClick}
            ref={ref}
        >
            <div className={classes.dayInfo}>
                <span>{dayOfMonth}</span>
            </div>
            {map(displayEvents, (event, index) =>
                <p className={eventClasses} key={`${event.occurrenceId}_${index}`}>
                    <span>{formatTime(event.startsAt)}</span>
                    &nbsp;
                    <Tooltip title={`${formatTime(event.startsAt)} ${event.name}`}>
                        <span>{event.name}</span>
                    </Tooltip>
                </p>
            )}
            {events.length > numEventsToDisplay &&
                <p className={eventClasses}><span>+{events.length - numEventsToDisplay} more</span></p>
            }
            {events.length >= 1 &&
                <EventVolunteersProgress total={total} current={total - available} />
            }
        </div>
    )
}

export default EventCalendarDay
