import { createMuiTheme } from '@material-ui/core/styles'

import { palette } from 'civic-champs-shared/config/palette'

export const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito, sans-serif',
    useNextVariants: true,
  },

  palette: {
    primary: { main: '#0F5DB5' },
    secondary: { main: '#74777F' },

    background: {
      default: '#ffffff',
      gray: '#EEF3F6',
      green: palette.background.green,
      lightBlue: '#D6E3FF',
      blue: '#eff4fd',
      yellow: '#fef0b2',
      warning: '#ffe2d8',
      lightGrey: '#F8FAFF',
    },

    error: {
      main: '#BA1B1B',
    },

    borders: {
      error: palette.border.red,
    },

    success: { main: '#008000', light: '#98FB98' },
    danger: { main: '#ff6f3e', dark: '#b24d2b' },

    accent: {
      yellowGreen: {
        main: palette.background.green,
        dark: palette.background.darkGreen,
        light: palette.background.lightGreen,
      },
      lightBlue: { main: '#add7f3', dark: '#82a4ba' },
    },

    neutral: {
      main: '#999999',
      darkGray: '#3d3d3d',
      black: '#000000',
      white: palette.main.default,
      gray: '#808080',
      grayDark: palette.text.grayDark,
      lightGray: '#cccccc',
    },
  },
  drawer: {
    width: 242,
    icon: {
      halfWidth: 12,
    },
  },
})
