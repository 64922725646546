import React, { useState, useMemo } from 'react';

import { UISettingsContextProvider } from './UISettingsContext'

import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

export const UISettingsProvider = ({ children }) => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const [showAdminItems, setShowAdminItems] = useState(isInternalAdmin)

  const context = useMemo(() => ({ showAdminItems, setShowAdminItems }), [showAdminItems, setShowAdminItems])

  return <UISettingsContextProvider value={context}>{children}</UISettingsContextProvider>
}
