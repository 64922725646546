import React, { useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { Check } from '@material-ui/icons'

import './index.scss'

interface CopyButtonProps {
  color?: 'primary' | 'secondary'
  onClick: () => void
  transitionDuration?: number
}

export const CopyButton = ({
  color = 'secondary',
  onClick,
  transitionDuration = 2000,
}: CopyButtonProps): JSX.Element => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), transitionDuration)
      return () => clearTimeout(timeout)
    }
  }, [copied, transitionDuration])

  return (
    <IconButton
      edge="end"
      onClick={async () => {
        await onClick()
        setCopied(true)
      }}
    >
      {copied ? <Check color={color} /> : <span className="copy-button__icon" />}
    </IconButton>
  )
}

export default CopyButton
