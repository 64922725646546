import React from 'react'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import { Volunteer } from 'champion/utils/interface'
import format from 'civic-champs-shared/utils/format'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { FormControlLabel, FormGroup } from '@material-ui/core'
import { Checkbox } from 'formik-material-ui'
import { Field } from 'formik'

export interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  volunteers: Volunteer[]
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
    '& > span, & > ul > li, $label': {
      color: '#2D3038',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    '& > ul': {
      margin: 0,
      paddingLeft: '20px',
      '&$scrollable': {
        width: '100%',
        border: '1px solid #74777F',
        height: '106px',
        padding: '3px',
        paddingInlineStart: '24px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        wordWrap: 'break-word',
        whiteSpace: 'wrap',
        '& > li': {
          color: '#74777F',
        },
      },
    },
  },
  scrollable: {},
  label: {},
  checkboxes: {
    marginLeft: '25px',
    marginTop: '-2px',
    marginBottom: '-4px',
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
      marginTop: '-9px',
      marginBottom: '-9px',
    },
  },
})

export const RemoveVolunteersConfirmDialog = ({ volunteers, ...props }: Props) => {
  const classes = useStyles()

  return (
    <ConfirmDialog
      {...props}
      cancelText="Cancel"
      confirmText="Remove Volunteers"
      title="Are you sure you want to remove the following volunteers:"
      initialValues={{ groupMemberships: false, questionnaireResponses: false, historicalActivityData: false }}
    >
      <div className={classes.main}>
        <span>You are removing the following accounts as volunteers for your organization:</span>
        <ul className={cn({ [classes.scrollable]: volunteers.length > 5 })}>
          {volunteers.map(volunteer => (
            <li key={volunteer.id}>
              {volunteer.user.givenName} {volunteer.user.familyName} -{' '}
              {volunteer.user.email || format.phoneNumber(volunteer.user.phoneNumber)}
            </li>
          ))}
        </ul>
        <span>
          The selected accounts will be removed from the VOLUNTEERS group, and will no longer be visible in the
          Champions table. You may also choose to delete the following organization information associated with the
          selected accounts:
        </span>
        <FormGroup row classes={{ root: classes.checkboxes }}>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={<Field component={Checkbox} name="groupMemberships" color="primary" />}
            label="Group Memberships"
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            control={<Field component={Checkbox} name="questionnaireResponses" color="primary" />}
            label="Questionnaire Responses"
          />
          <FormControlLabel
            classes={{ label: classes.label }}
            control={<Field component={Checkbox} name="historicalActivityData" color="primary" />}
            label="Historical Activity Data"
          />
        </FormGroup>
        <span>This action cannot be undone.</span>
      </div>
    </ConfirmDialog>
  )
}
