import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { Row } from 'react-table'
import { ExportButton } from 'core/table/components'

const filename = 'groups.csv'
const headers = [
  { label: 'ID', key: 'id' },
  { label: 'Group Name', key: 'name' },
  { label: 'Applicants', key: 'pendingMemberCount' },
  { label: 'Members', key: 'memberCount' },
  { label: 'Approval Type', key: 'approvalType' },
  { label: 'Date Created', key: 'createdAt' },
]

const formatData = (groups: Row<Group>[]) => {
  return groups.map(({ original: group }) => {
    return {
      id: group.id,
      name: group.name,
      pendingMemberCount: group.pendingMemberCount,
      memberCount: group.memberCount,
      approvalType: group.closed ? 'Limited' : 'Open',
      createdAt: format.date(group.createdAt),
    }
  })
}
const ExportGroupsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const filteredHeaders = isInternalAdmin ? headers : headers.filter(header => header.key !== 'id')
  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={filteredHeaders}
      disabled={disabled}
      filename={filename}
    />
  )
}

export default ExportGroupsButton
