import { useCallback } from 'react'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import MessageSummaryPrompt from '../components/MessageSummaryPrompt'
import { NotificationBlastSummary } from '../types'

const useMessageSummaryPrompt = () => {
  const prompt = usePrompt(MessageSummaryPrompt)
  return useCallback(
    (summary: NotificationBlastSummary) => {
      // @ts-ignore
      prompt.show({ summary })
    },
    [prompt],
  )
}

export default useMessageSummaryPrompt
