import { isEmpty } from 'lodash'
import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AddButton } from 'civic-champs-shared/core/add-button'
import { ExternalDatafeedCard } from 'external-datafeed/components/ExternalDatafeedCard'
import useCustomerDatafeedManagement from 'external-datafeed/hooks/useCustomerDatafeedManagement'
import Loading from 'civic-champs-shared/core/Loading'
import BackLink from 'donation/campaigns/components/BackLink'

const ExternalDatafeeds = () => {
  const { datafeeds, loading, creating, addDatafeed } = useCustomerDatafeedManagement()

  if(loading) {
    return <Grid container>
      <Loading />
    </Grid>
  }

  return <div>
    <BackLink to="/champions" text="Back to Champions" />
    <Grid container spacing={4} direction="column">
      <Grid item>
        <Typography variant="h5">External Datafeed</Typography>
      </Grid>
      <Grid item sm={6}>
        <Typography>
          This Datafeed exports all Champions in your Civic Champs account
          and includes their Civic Champs ID. The Import allows you to upload
          a unique ID for the Champion from your own system but the upload MUST
          contain the Civic Champs ID provide in the export. The Import ONLY updates
          the Champion's Customer ID.
        </Typography>
      </Grid>
        <>{datafeeds.map( datafeed => (
          <Grid item key={datafeed.id}>
            <ExternalDatafeedCard 
              datafeed={datafeed}
            />
          </Grid>
        ))}</>
      {isEmpty(datafeeds) && (<Grid item>
        <AddButton 
          disabled={creating}
          onClick={addDatafeed}
          title="Add Datafeed"
        />
      </Grid>)}
    </Grid>
  </div>
}

export default ExternalDatafeeds