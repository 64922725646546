import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

const useSendNotification = (organizationId: number): [(body: any) => Promise<void>, Status<void>] => {
  const [request, status] = useFetchRefactored<void>({
    successMessage: 'Verification Link Sent',
    useNewNotification: true,
  })

  const sendNotification = useCallback(
    body => {
      return request({
        method: 'post',
        url: `/organizations/${organizationId}/people/verification-requests/bulk`,
        config: {
          body,
        },
      })
    },
    [organizationId, request],
  )

  return [sendNotification, status]
}

export default useSendNotification
