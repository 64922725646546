import { get } from 'lodash'

export enum CampaignEventType {
  VIEWED = 'viewed',
  DONATED = 'donated',
  SUBSCRIBED = 'subscribed',
  CAMPAIGN_CLICKED = 'campaign_clicked',
  DONATION_CLICKED = 'donation_clicked',
}

export interface CampaignEvent {
  id?: number
  personId: number
  campaignId: number
  opportunityId: number
  donationId?: number
  donationSubscriptionId?: number
  type?: CampaignEventType
  occurredAt?: Date
}

export enum DonationStatus {
  PAID = 'paid',
  REFUNDED = 'refunded',
  INITIATED = 'initiated',
  FAILED = 'failed',
  PENDING_REFUND = 'pending_refund',
}

export const donationStatusDisplayNames = {
  [DonationStatus.PAID]: 'Paid',
  [DonationStatus.REFUNDED]: 'Refunded',
  [DonationStatus.INITIATED]: 'Pending',
  [DonationStatus.FAILED]: 'Failed',
  [DonationStatus.PENDING_REFUND]: 'Pending Refund',
}

export const getDonationStatusDisplayNames = (status: string): string => {
  return get(donationStatusDisplayNames, status, 'Unknown')
}
