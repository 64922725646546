import React from 'react'
import { IconButton, makeStyles, Modal } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { WaiverFormValues } from '../models'
import { createMarkup } from '../../civic-champs-shared/core/utils/helpers'

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
  },
  modalTitle: {
    fontSize: '34px',
    color: '#3D3D3D',
    fontFamily: 'SemiBold Open Sans',
    marginBottom: '25px',
    alignSelf: 'center',
  },
  text: {
    marginLeft: '100px',
    marginRight: '100px',
    overflow: 'scroll',
    marginBottom: '30px',
  },
}))

export default function WaiverPreviewPrompt(props: {
  showing: boolean
  complete: () => void
  close: () => void
  waiver: WaiverFormValues
}) {
  const { showing, close, waiver } = props
  const classes = useStyles()

  return (
    <Modal open={showing} className={classes.modal}>
      <div className={classes.modalBody}>
        <IconButton className={classes.icon} aria-label="close" component="span" onClick={close}>
          <Close />
        </IconButton>
        <div className={classes.modalTitle}>Waiver Content</div>
        <p className={classes.text} dangerouslySetInnerHTML={createMarkup(waiver.content)} />
        <div className={classes.modalTitle}>Waiver Agreement</div>
        <p className={classes.text} dangerouslySetInnerHTML={createMarkup(waiver.waiverStatement)} />
      </div>
    </Modal>
  )
}
