export interface NotePayload {
  id?: number
  title?: string
  body: string
  entityType: string
  entityId: number
}

export interface Note extends NotePayload {
  id: number
  organizationId: number
  createdAt: Date
  createdById: number
  updatedAt: Date | null
  updatedById: number | null
  deletedAt: Date | null
  deletedById: number | null
}

export enum EntityType {
  PERSON = 'person',
}
