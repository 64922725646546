import { makeStyles } from '@material-ui/core/styles'

const rightAligned = {
  textAlign: 'right',
  paddingRight: '35px',
}

export default makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  riderColumn: {
    '&>div': {
      width: '165px',
    },
  },
  pickupDateColumn: {
    '&>div': {
      width: '108px',
    },
  },
  editColumn: {
    '&>div': {
      width: '40px',
    },
  },
  pickupTimeScheduledColumn: {
    '&>div': {
      width: '179px',
      ...rightAligned,
    },
  },
  dropOffTimeScheduledColumn: {
    '&>div': {
      width: '193px',
      ...rightAligned,
    },
  },
  driverColumn: {
    '&>div': {
      width: '143px',
    },
  },
  milesColumn: {
    '&>div': {
      width: '110px',
      ...rightAligned,
    },
  },
  pickupTimeActualColumn: {
    '&>div': {
      width: '158px',
      ...rightAligned,
    },
  },
  dropOffTimeActualColumn: {
    '&>div': {
      width: '172px',
      ...rightAligned,
    },
  },
  addressColumn: {
    '&>div': {
      width: '200px',
      '&>div': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  dropOffCountyColumn: {
    '&>div': {
      width: '143px',
    },
  },
  reasonColumn: {
    '&>div': {
      width: '125px',
    },
  },
  recoveryColumn: {
    '&>div': {
      width: '111px',
    },
  },
  sudMhColumn: {
    '&>div': {
      width: '137px',
    },
  },
  cjiColumn: {
    '&>div': {
      width: '80px',
    },
  },
  notesColumn: {
    '&>div': {
      width: '299px',
    },
  },
  driver: {
    cursor: 'pointer',
    color: '#0F5DB5',
  },
  statusColumn: {
    '&>div': {
      width: '100px',
    },
  },
}))
