import { useCallback } from 'react'
import useFetchRefactored from '../../civic-champs-shared/api/hooks/useFetchRefactored'
import { Opportunity } from '../interfaces'
import { Status } from '../../civic-champs-shared/api/hooks'

export const useGetOpportunityInstance = (): [
  (encodedOccurrence: string) => Promise<Opportunity>,
  Status<Opportunity>,
] => {
  const [request, status] = useFetchRefactored<Opportunity>()

  const getOpportunityInstance = useCallback(
    (encodedOccurrence: string) => {
      return request({
        method: 'get',
        url: `/opportunity_instances/${encodedOccurrence}`,
      })
    },
    [request],
  )

  return [getOpportunityInstance, status]
}
