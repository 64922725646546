import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useFetchPrograms from 'new-mentorship/hooks/useFetchPrograms'
import Loading from 'civic-champs-shared/core/Loading'
import { NumberCell } from 'core/table/cells'
import { MentorshipProgramWithStatistics } from 'new-mentorship/types'
import SummaryCards from 'core/table/components/SummaryCards'
import {
  CellProps,
  Row,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Link } from 'react-router-dom'
import useStyles from 'new-mentorship/hooks/useStyles'
import PageTitle from 'components/PageTitle'
import { Box, Grid, Popover } from '@material-ui/core'
import { AddButton } from 'civic-champs-shared/core/add-button'
import moment from 'moment'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Search from 'new-mentorship/helpers/Search'
import reduce from 'lodash/reduce'
import PagedTableUntyped from 'core/table/components/PagedTable'
import DEFAULT_FILTERS, { DATE_RANGE, DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { FiltersModal, operatorTypes, useFiltersFromQuery } from 'core/table/components/FiltersModal'
import { Icon } from '@mdi/react'
import { mdiFilterMenu } from '@mdi/js'
import DateRangeFilter from 'components/filter/DateRangeFilter'
// @ts-ignore
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useDateRangeFilter } from 'filtering/hooks'
import qs from 'query-string'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ProgramFlowEditor from 'new-mentorship/components/ProgramFlowEditor'
import LegacyProgramFlowEditor from 'new-mentorship/legacy-components/ProgramFlowEditor'
import { RouteComponentProps } from 'react-router'
import useSelectColumn from 'core/table/table-hooks/useSelectColumn'
import { ExportCsvButton } from 'external-datafeed/components/ExportCsvButton'
import { useFeatureEnabled } from 'core/feature/hooks'

const PagedTable: any = PagedTableUntyped

const csvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Description', key: 'description' },
  { label: 'Start', key: 'startsAt' },
  { label: 'End', key: 'endsAt' },
  { label: '# Matches', key: 'matchCount' },
  { label: 'Avg. Meeting Time', key: 'meetingLengthAverage' },
  { label: '% Positive Rating by Mentors', key: 'mentorFeedbackAverage' },
  { label: '% Positive Rating by Mentees', key: 'menteeFeedbackAverage' },
  { label: 'Status', key: 'status' },
]

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'name':
    case 'description':
      return [
        { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
        { value: operatorTypes.EQUALS, displayValue: 'Equals' },
      ]

    case 'matchCount':
    case 'meetingLengthAverage':
    case 'mentorFeedbackAverage':
    case 'menteeFeedbackAverage':
      return [
        { value: operatorTypes.LESS_THAN, displayValue: 'Less than' },
        { value: operatorTypes.LESS_OR_EQUAL, displayValue: 'Less than or equal to' },
        { value: operatorTypes.EQUALS, displayValue: 'Equal to' },
        { value: operatorTypes.GREATER_OR_EQUAL, displayValue: 'Greater than or equal to' },
        { value: operatorTypes.GREATER_THAN, displayValue: 'Greater than' },
      ]

    case 'status':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]

    default:
      return []
  }
}

const cards = [
  {
    Header: 'Programs',
    accessor: 'programs',
    Cell: NumberCell,
  },
  {
    Header: 'Matches',
    accessor: 'matches',
    Cell: NumberCell,
  },
]

const useColumns = ({ showAddProgram, fetchPrograms }: any) => {
  const classes = useStyles()
  return useMemo(
    () => [
      {
        Header: '',
        id: 'id',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({
          cell: {
            row: { original },
          },
        }: CellProps<any>) => {
          return (
            <IconButton
              color="inherit"
              aria-label="edit program"
              edge="start"
              onClick={() =>
                showAddProgram({
                  program: original,
                  submit: fetchPrograms,
                })
              }
              className={classes.menuButton}
            >
              <EditIcon />
            </IconButton>
          )
        },
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        filter: DYNAMIC_TEXT,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          o.matchCount > 0 ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/matches',
                search: qs.stringify({ programName: [value] }, { arrayFormat: 'bracket' }),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value}</span>
          ),
      },

      {
        Header: 'Description',
        id: 'description',
        accessor: 'description',
        filter: DYNAMIC_TEXT,
      },
      {
        Header: 'Period',
        id: 'startsAt',
        disableFilters: true,
        accessor: 'startsAt',
        filter: DATE_RANGE,
        Cell: ({
          cell: {
            value,
            row: {
              original: { endsAt },
            },
          },
        }: CellProps<any>) => {
          const startDate = moment(value).format('MMMM D, YYYY')
          const endDate = endsAt ? moment(endsAt).format('MMMM D, YYYY') : '...'
          return (
            <span>
              {startDate} - {endDate}
            </span>
          )
        },
      },
      {
        Header: '# Matches',
        id: 'matchCount',
        accessor: 'matchCount',
        filter: DYNAMIC_NUMBER,
      },
      {
        Header: 'Avg. Meeting Time ',
        id: 'meetingLengthAverage',
        accessor: 'meetingLengthAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value / 60)} min` : ''),
      },
      {
        Header: '% Positive Rating by Mentors',
        id: 'mentorFeedbackAverage',
        accessor: 'mentorFeedbackAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value * 100)}%` : ''),
      },
      {
        Header: '% Positive Rating by Mentees',
        id: 'menteeFeedbackAverage',
        accessor: 'menteeFeedbackAverage',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value ? `${Math.round(value * 100)}%` : ''),
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        filter: 'includesSome',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes],
  )
}

const useSummary = (rows: Row<any>[]) =>
  useMemo(() => {
    return {
      programs: rows.length,
      matches: reduce(
        rows,
        (sum: number, { original: { matchCount } }: Row<MentorshipProgramWithStatistics>) => matchCount + sum,
        0,
      ),
    }
  }, [rows])

export const MentorshipPrograms = ({ location, history }: RouteComponentProps) => {
  const [filtersModalVisible, setFiltersModalVisible] = useState(false)
  const { filters, filterRows: initialFilterRows } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [dateRange, setDateRange] = useDateRangeFilter()
  const anchorEl = useRef(null)
  const newDialogUI = useFeatureEnabled('MentorshipDialogsNewUI')

  const [fetchPrograms, { loading, result: programs }] = useFetchPrograms()
  useEffect(() => {
    fetchPrograms()
  }, [fetchPrograms])

  const showAddProgram = useShowPrompt(newDialogUI ? ProgramFlowEditor : LegacyProgramFlowEditor)

  const classes = useStyles()

  const columns = useColumns({ showAddProgram, fetchPrograms })

  const table = useTable(
    {
      data: programs,
      columns,
      // @ts-ignore
      initialState: { filters },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn,
  )

  useEffect(() => {
    table.setFilter('startsAt', dateRange)
  }, [dateRange, table])

  const prepareCsv = useCallback(
    () => ({
      filename: 'mentorship-programs.csv',
      headers: csvHeaders,
      data: table.selectedFlatRows.map(
        ({ original: { meetingLengthAverage, mentorFeedbackAverage, menteeFeedbackAverage, ...row } }: any) => ({
          ...row,
          meetingLengthAverage: meetingLengthAverage ? Math.round(meetingLengthAverage / 60) : '',
          mentorFeedbackAverage: mentorFeedbackAverage ? Math.round(mentorFeedbackAverage * 100) : '',
          menteeFeedbackAverage: menteeFeedbackAverage ? Math.round(menteeFeedbackAverage * 100) : '',
        }),
      ),
    }),
    [table.selectedFlatRows],
  )

  const summary = useSummary(table.rows)

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div className={classes.header}>
          <Grid xs={12} sm={4} container item alignItems="center" justify="flex-start" direction="row">
            <PageTitle title="Programs" />
            <Box ml={1.25}>
              <AddButton
                onClick={() =>
                  showAddProgram({
                    submit: fetchPrograms,
                  })
                }
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={8} container item alignItems="center" spacing={0} justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onChange={table.setGlobalFilter} initialValue={table.state.globalFilter} />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Box className={classes.dateBox}>
                <DateRangeFilter
                  initialValues={dateRange}
                  onFilterApplied={setDateRange}
                  anchorDirection={ANCHOR_LEFT}
                  noBorder
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <SummaryCards summary={summary} cards={cards} />
        {loading ? (
          <Loading />
        ) : (
          <>
            <section id="table" className={classes.table}>
              <Grid xs={12} container alignItems="center" spacing={0} justify="space-between" direction="row">
                <Grid xs={3} container item alignItems="center" spacing={0} justify="flex-start" direction="row">
                  {/*
              // @ts-ignore */}
                  <Box ml={2} display="inline-block" className={classes.filterBox} ref={anchorEl}>
                    <AddButton
                      onClick={() => setFiltersModalVisible(true)}
                      icon={<Icon path={mdiFilterMenu} size={1} /*fill="#000000"*/ />}
                      title={
                        <Box>
                          <sup>
                            {table.columns.filter((column: any) => column.filterValue && !column.disableFilters)
                              .length || ''}
                          </sup>
                          <Box component="span" pl={1}>
                            FILTERS
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                  <Popover
                    keepMounted
                    BackdropProps={{}}
                    open={filtersModalVisible}
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    // @ts-ignore
                    transformOrigin={{
                      vertical: -20,
                    }}
                  >
                    <FiltersModal
                      columns={columns}
                      table={table}
                      filterRows={initialFilterRows}
                      history={history}
                      getOperatorOptions={getOperatorOptions}
                      onClose={() => setFiltersModalVisible(false)}
                    />
                  </Popover>
                </Grid>
                <Grid
                  xs={9}
                  container
                  item
                  alignItems="center"
                  spacing={1}
                  justify="flex-end"
                  direction="row"
                  className={classes.rightButtonsGrid}
                >
                  <Grid item>
                    <ExportCsvButton disabled={table.selectedFlatRows.length === 0} onPrepareCsv={prepareCsv}>
                      Export CSV
                    </ExportCsvButton>
                  </Grid>
                </Grid>
              </Grid>
              <PagedTable {...table} wrapHeader />
            </section>
          </>
        )}
      </div>
    </div>
  )
}
export default MentorshipPrograms
