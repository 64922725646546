import { isEmpty } from 'lodash'
import React, { forwardRef } from 'react'
import { makeStyles, Tooltip, IconButton, Slide } from '@material-ui/core'
import CommentWithEyeIcon from 'components/icon/CommentWithEye'

//import ViewActivityReflectionsDialog from './ViewActivityReflectionsDialog'

const useStyles = makeStyles(theme => ({
  icon: {
    padding: '0 0 0 0.15em',
    marginRight: '-0.75em',
    color: '#5C8DE8',
  },
  tooltip: {
    minWidth: '300px',
    minHeight: '150px',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: theme.shadows[4],
    border: 'solid 1px black'
  },
  container: {
    padding: '0 1em'
  }
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function ActivityReflectionIcon({ reflection }) {
  const classes = useStyles()

  return !isEmpty(reflection)
    ? (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        TransitionComponent={Transition}
        placement='right-start'
        title={
          <div className={classes.container}>
            <h3>Reflection:</h3>
            <p>{reflection}</p>
          </div>
        }
      >
        <IconButton size="small" className={classes.icon}>
          <CommentWithEyeIcon />
        </IconButton>
      </Tooltip>
    ) : null
}
