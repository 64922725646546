import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { ExportButton } from 'core/table/components'

const filename = 'champions.csv'
const headers = [
  { label: 'First Name', key: 'user.givenName' },
  { label: 'Last Name', key: 'user.familyName' },
  { label: 'Email', key: 'user.email' },
  { label: 'Mobile Phone', key: 'user.phoneNumber' },
  { label: 'Home Phone', key: 'user.homePhoneNumber' },
  { label: 'Hours', key: 'hours' },
  { label: 'Donations', key: 'donations' },
  { label: 'Last Activity', key: 'latestActivity' },
  { label: 'Groups', key: 'groups' },
  { label: 'Address', key: 'user.address' },
  { label: 'Emergency Contact First Name', key: 'user.emergencyContact.givenName' },
  { label: 'Emergency Contact Last Name', key: 'user.emergencyContact.familyName' },
  { label: 'Emergency Contact Relationship', key: 'user.emergencyContactRelationship' },
  { label: 'Emergency Contact Mobile Phone', key: 'user.emergencyContact.phoneNumber' },
  { label: 'Emergency Contact Work Phone', key: 'user.emergencyContact.workPhoneNumber' },
  { label: 'Emergency Contact Home Phone', key: 'user.emergencyContact.homePhoneNumber' },
  { label: 'Emergency Contact Email Address', key: 'user.emergencyContact.email' },
]

const formatPhones = (phones: string[], obj: any) =>
  phones.reduce((acc: any, phone: string) => ({ ...acc, [phone]: format.phoneNumber(obj?.[phone]) }), {})

const formatData = (champions: any[]) => {
  return champions.map(({ original: { user, volunteering, groups, donation } }) => {
    return {
      user: {
        ...user,
        ...formatPhones(['phoneNumber', 'homePhoneNumber'], user),
        address: format.address(user.address),
        emergencyContact: {
          ...user.emergencyContact,
          ...formatPhones(['phoneNumber', 'homePhoneNumber', 'workPhoneNumber'], user.emergencyContact),
        },
      },
      hours: format.fixedPoint(volunteering.totalVolunteerHours, 1),
      donations: format.fixedPoint(donation.totalDonations / 100, 2),
      latestActivity: volunteering.latestActivity,
      groups,
    }
  })
}
const ExportChampionsButton = ({
  data,
  disabled,
  includeDonations,
}: {
  data: any[]
  disabled?: boolean
  includeDonations?: boolean | null
}) => {
  const filteredHeaders = includeDonations ? headers : headers.filter(header => header.key !== 'donations')
  const fileNameWithDate = useCurrentDateWithFilename(filename)

  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={filteredHeaders}
      disabled={disabled}
      filename={fileNameWithDate as string}
    />
  )
}

export default ExportChampionsButton
