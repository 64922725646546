import { makeStyles } from '@material-ui/core'

export const useProfileStyles = makeStyles({
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#000000',
    margin: 0,
    padding: 0,
  },
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  subHeader: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
  },
  delete: {
    cursor: 'pointer',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '39px',
    letterSpacing: '0.1px',
    color: '#BA1B1B',
    textAlign: 'right',
  },
  add: {
    cursor: 'pointer',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '39px',
    letterSpacing: '0.1px',
    color: '#3776CF',
    textAlign: 'right',
  },
  icon: {
    color: '#BA1B1B',
    position: 'relative',
    top: '4px',
    marginTop: '8px',
  },
  spoiler: {
    width: '100%',
    height: '0px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    margin: '8px 0 4px',
  },
  verified: {
    position: 'relative',
    left: '8px',
    top: '2px',
  },
  verifiedText: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '41px',
    letterSpacing: '0.5px',
    color: '#000000',
    paddingLeft: '8.5px',
  },
})

export default useProfileStyles
