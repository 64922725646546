import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import CancelDialog from 'civic-champs-shared/core/confirm-dialog/CancelDialog'
import { BulkAction } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { Row } from 'react-table'
import { CancelType } from 'Event/modules/events/details/header'
import useCancelOpportunities from 'Event/hooks/useCancelOpportunities'
import { OpportunityRow } from 'Event/components/DailyEventsTable'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'

const useCancelEventsDialog = ({ eagerRemoveMany }: { eagerRemoveMany: BulkAction<OpportunityResponse> }) => {
  const showPrompt = useShowPrompt(CancelDialog)
  const cancelOpportunities = useCancelOpportunities({
    errorMessage: 'Failed to cancel events',
    successMessage: 'Events cancelled',
  })

  return useCallback(
    async (selectedRows: Row<OpportunityRow>[]) => {
      const confirmed = await showPrompt({
        text: `Are you sure you want to cancel ${selectedRows.length} events?`,
        title: 'Confirm Action',
        confirmText: 'Yes',
        cancelText: 'No',
      })

      if (confirmed) {
        const rollback = eagerRemoveMany(selectedRows.map(({ original: { id } }) => ({ id } as OpportunityResponse)))
        try {
          await cancelOpportunities(
            selectedRows.map(({ original }) => encodeOccurrenceFromEvent(original)),
            CancelType.Single,
          )
        } catch (error) {
          // error notification is shown by `useFetch` inside `useCancelOpportunities`
          rollback()
        }
      }
    },
    [showPrompt, eagerRemoveMany, cancelOpportunities],
  )
}

export default useCancelEventsDialog
