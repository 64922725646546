import { OpportunityResponse } from 'Event/interfaces/interfaceCreateEditEvent'
import { OpportunityRegistrations, OpportunityRow } from 'Event/components/DailyEventsTable'
import { groupBy } from 'lodash'
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'

const emptyRegistrations: OpportunityRegistrations = {
  total: 0,
  confirmed: 0,
}

const mapOpportunityResponseToRow = ({
  id,
  occurrenceId,
  opportunityId,
  startsAt,
  endsAt,
  name,
  timeshifts,
  organization,
}: OpportunityResponse): OpportunityRow => ({
  id,
  occurrenceId,
  opportunityId,
  startsAt: new Date(startsAt),
  endsAt: endsAt ? new Date(endsAt) : undefined,
  name: name,
  organization,
  registrations: (timeshifts || []).reduce((registrations: OpportunityRegistrations, shift) => {
    return shift.roles.reduce(
      (registrations, role) => ({
        total: registrations.total + role.all,
        confirmed: registrations.confirmed + role.all - role.available,
      }),
      registrations,
    )
  }, emptyRegistrations),
})

export const mapDailyEvents = (events: OpportunityResponse[]): OpportunityRow[] => {
  const rows = events.map(mapOpportunityResponseToRow)
  const groupedRows = groupBy(rows, ({ startsAt }) => moment(startsAt).format('YYYY-MM-DD'))
  return sortBy(
    Object.entries(groupedRows).map(([date, rows]) => ({
      id: parseInt(date.replace('-', '')),
      name: moment(date).format('dddd, MMMM Do'),
      startsAt: (minBy(rows, 'startsAt') as OpportunityRow).startsAt,
      endsAt: (maxBy(rows, 'endsAt') as OpportunityRow).endsAt,
      registrations: rows.reduce(
        (registrations, row) => ({
          total: registrations.total + row.registrations.total,
          confirmed: registrations.confirmed + row.registrations.confirmed,
        }),
        emptyRegistrations,
      ),
      subRows: sortBy(rows, 'startsAt'),
    })),
    'startsAt',
  )
}
