import { reduce } from 'lodash'
import React, { useState } from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Field, Formik } from 'formik'
import yup from 'civic-champs-shared/utils/yup'
import Loading from 'components/Loading'
import usePerson from '../hooks/usePerson'
import useUpdatePerson from '../hooks/useUpdatePerson'
import BackLink from '../../donation/campaigns/components/BackLink'
import { useCurrentOrg, useHasRole } from '../../auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

const nameSchema = yup.object({
  givenName: yup
    .string()
    .trim()
    .required('required'),
  familyName: yup
    .string()
    .trim()
    .required('required'),
})

export const PersonNameUpdate = props => {
  const id = props.match.params.id
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const [person, setPerson] = useState(null)
  const currentOrg = useCurrentOrg()
  const { loading: getLoading } = usePerson({
    id,
    onSuccess: setPerson,
    organizationId: isInternalSuperAdmin ? undefined : currentOrg.id,
  })
  const { loading: updateLoading, updatePerson } = useUpdatePerson(setPerson)
  const loading = getLoading || updateLoading

  if (loading) {
    return <Loading />
  }
  const fields = ['familyName', 'givenName']
  const handleFormikSubmit = async values => {
    await updatePerson({ id, ...values })
    props.history.push(`/people/${id}`)
  }
  const initialValues = reduce(
    fields,
    (acc, field) => {
      acc[field] = person[field] || ''
      return acc
    },
    {},
  )
  return (
    <>
      <BackLink to={`/people/${id}`} text="Back to Person" />
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleFormikSubmit(nameSchema.cast(values))}
        validationSchema={nameSchema}
      >
        {({ dirty, values, handleSubmit, isSubmitting, isValid }) => {
          const disabled = !dirty || !isValid || isSubmitting
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Field
                    component={TextField}
                    id="givenName"
                    name="givenName"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Field
                    component={TextField}
                    id="familyName"
                    name="familyName"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button type="submit" disabled={disabled}>
                    {loading || (isSubmitting && <CircularProgress size={24} color="primary" />)}
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
