import React, { useEffect, useMemo } from 'react'
import moment from 'moment'

import EventCalendar from './EventCalendar'
import Loading from 'components/Loading'
import useEventsCollection from './hooks/useEventsCollection'
import { CalendarNavigationBar } from './CalendarNavigationBar'
import { useCalendarPeriod } from './hooks/useCalendarPeriod'
import { viewTypes } from './viewTypes'
import { useEventsPageState } from 'Event/hooks/useEventsPageState'
import { EventPages } from 'Event/redux/actions/EventNavigation'

export const WeekView = () => {
  const [, setEventsPage] = useEventsPageState()
  useEffect(() => {
    setEventsPage(EventPages.Calendar)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { firstOfWeek, setFirstOfWeek, gotoPrevWeek, gotoNextWeek, gotoCurrentWeek } = useCalendarPeriod(viewTypes.WEEK)

  const { date, month, year } = useMemo(() => {
    return {
      date: firstOfWeek.date(),
      month: firstOfWeek.month(),
      year: firstOfWeek.year(),
    }
  }, [firstOfWeek])

  const handleChangeMonth = (newValue: any) => setFirstOfWeek(moment(newValue).startOf('month'))

  const [{ events, loading }] = useEventsCollection({
    startDate: firstOfWeek.clone().startOf('week').subtract(1, 'day').format('MM-DD-YYYY'),
    endDate: firstOfWeek.clone().endOf('week').add(1, 'day').format('MM-DD-YYYY'),
    debounceDelay: 250,
  })

  return (
    <div>
      <CalendarNavigationBar
        date={firstOfWeek}
        onTodayClick={gotoCurrentWeek}
        onNextClick={gotoNextWeek}
        onPrevClick={gotoPrevWeek}
        onMonthChange={handleChangeMonth}
      />

      {loading ? (
        <Loading />
      ) : (
        <EventCalendar
          date={date}
          month={month}
          year={year}
          // @ts-ignore
          events={events}
          viewType={viewTypes.WEEK}
        />
      )}
    </div>
  )
}
