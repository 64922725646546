import SummaryCards from 'civic-champs-shared/core/SummaryCards'
import React from 'react'
import { Activity } from 'champion/utils/interface'
import { NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR } from 'champion/components/ChampionsSummaryCards'
import format from 'civic-champs-shared/utils/format'
import { maxBy, reduce } from 'lodash'
import { ProfileNotes } from 'champion/components/ProfileNotes'

export const ProfileOverview = ({
  activities,
  donations,
  personId,
}: {
  activities: Omit<Activity, 'user' | 'organization'>[]
  donations: number
  personId: number
}) => {
  const cards = [
    {
      header: 'Activities',
      accessor: 'activities',
      transform: format.number,
    },
    {
      header: 'Hours',
      accessor: 'hours',
      transform: format.number,
    },
    {
      header: 'Donations',
      accessor: 'donations',
      transform: format.money,
    },
    {
      header: 'Last Activity',
      accessor: 'lastActivity',
      transform: format.date,
    },
    {
      header: 'Est. Impact',
      accessor: 'hours',
      transform: (v: number) => format.money(NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR * v),
    },
  ]
  const summary = {
    activities: activities.length,
    hours: reduce(activities, (total, { hoursVolunteered }) => total + hoursVolunteered, 0),
    donations: donations,
    lastActivity: maxBy(activities, 'occurredAt')?.occurredAt,
  }
  return (
    <>
      <SummaryCards cards={cards} data={summary} justify="center" fixedWidth={175} />
      <ProfileNotes personId={personId} />
    </>
  )
}
