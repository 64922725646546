import { useFetchRefactored } from '../../civic-champs-shared/api/hooks'
import { RegistrantResponse } from '../interfaces'
import { useCallback } from 'react'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'

export const useFetchRegistrant = (): ((id: number) => Promise<RegistrantResponse>) => {
  const [fetch] = useFetchRefactored<RegistrantResponse>()
  const org = useCurrentOrg()

  return useCallback((id: number) => fetch({ url: `/organizations/${org.id}/registrants/${id}` }), [fetch, org.id])
}
