import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { Member, MemberApiResponse } from 'chats/interfaces'
import map from 'lodash/map'

const mapper = (members: MemberApiResponse[]): Member[] =>
  map(
    members,
    ({ memberSince, ...rest }: MemberApiResponse): Member => ({
      ...rest,
      memberSince: new Date(memberSince),
    }),
  )
export const useGetChatMembers = (): [(chatId: string) => Promise<Member[]>, Status<Member[]>] => {
  const [request, status] = useFetchRefactored<Member[], MemberApiResponse[]>({ mapper })
  const org = useCurrentOrg()

  const fetchChatMembers = useCallback(
    (chatId: string) =>
      request({
        method: 'get',
        url: `/organizations/${org.id}/chats/${chatId}/members`,
      }),
    [request, org],
  )

  return [fetchChatMembers, status]
}

export default useGetChatMembers
