import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import { OfferSignup } from './useOfferSignupsCollection'
import { useCancelOfferSignups } from './useCancelOfferSignups'
import map from 'lodash/map'
import { BulkAction } from '../../civic-champs-shared/api/hooks/useRemoteCollection'

export const useCancelOfferSignupsPrompt = (opportunityId: number, eagerRemoveMany: BulkAction<OfferSignup>) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const cancelOfferSignups = useCancelOfferSignups(opportunityId)

  return useCallback(
    async (offerSignups: OfferSignup[]) => {
      const confirmed = await showPrompt({
        title: 'Are you sure you want to cancel all selected sign-ups?',
        confirmText: 'Cancel Selected Signups',
        cancelText: 'Do Not Cancel',
      })

      if (confirmed) {
        await cancelOfferSignups(map(offerSignups, 'person.id'))
        eagerRemoveMany(offerSignups)
      }
    },
    [cancelOfferSignups, eagerRemoveMany, showPrompt],
  )
}

export default useCancelOfferSignupsPrompt
