import { omitBy, isNil } from 'lodash'
import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'auth/hooks'
import { Tag, TagWithReporting } from '../types'

export const useFetchTags = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<(Tag | TagWithReporting)[]>()
  const fetchTags = useCallback(
    async (params: { reporting?: boolean }) =>
      request({
        url: `/organizations/${organization.id}/tags`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, organization.id],
  )

  return { fetchTags, loading, error }
}
