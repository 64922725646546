import { useCurrentProfile } from 'auth/hooks'
import { useGetPubNubAccessToken } from 'chats/hooks/useGetPubNubAccessToken'
import { useCallback, useEffect, useState } from 'react'
import PubNub, { PubnubConfig } from 'pubnub'

const { REACT_APP_PUBNUB_PUB_KEY, REACT_APP_PUBNUB_SUB_KEY } = process.env

export const usePubnub = (): { pubnub: PubNub; loading: boolean } => {
  const profile = useCurrentProfile()
  const [fetchToken] = useGetPubNubAccessToken()
  const [token, setToken] = useState('')
  const [ttl, setTtl] = useState(0)
  const [pubnub] = useState(
    new PubNub({
      publishKey: REACT_APP_PUBNUB_PUB_KEY,
      subscribeKey: REACT_APP_PUBNUB_SUB_KEY,
      userId: profile.user.pubnubId,
    } as PubnubConfig),
  )

  if (token) {
    pubnub.setToken(token)
  }

  const refreshToken = useCallback(
    () =>
      fetchToken().then((response: { token: string; ttl: number }) => {
        setToken(response.token)
        setTtl(response.ttl)
      }),
    [fetchToken],
  )

  useEffect(() => {
    refreshToken()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timer =
      ttl &&
      setInterval(() => {
        refreshToken()
      }, ttl - 1000)

    return () => clearInterval(timer)
  }, [refreshToken, ttl])

  return { pubnub, loading: !token }
}
