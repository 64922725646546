import { useFeatureEnabled } from '../../core/feature/hooks'
import useFetchPrograms from './useFetchPrograms'
import { useCallback, useEffect, useState } from 'react'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import ProgramFlowEditor from '../components/ProgramFlowEditor'
import LegacyProgramFlowEditor from '../legacy-components/ProgramFlowEditor'
import { useRemoteCollection } from '../../civic-champs-shared/api/hooks'
import { MentorshipProgram } from '../types'
import { useDateRangeFilter } from '../../filtering/hooks'
import filter from 'lodash/filter'
import { useWithinRange } from '../../utils/useWithinRange'
import { CollectionEventListeners } from '../../civic-champs-shared/api/hooks/useRemoteCollection'
import useFetchProgram from './useFetchProgram'

export const useMentorshipProgramsCollection = () => {
  const [programs, operations, events] = useRemoteCollection<MentorshipProgram>()
  const [dateRange] = useDateRangeFilter()
  const [withinRange] = useWithinRange(dateRange)
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection, eagerAdd, eagerReplace } = operations
  const newDialogUI = useFeatureEnabled('MentorshipDialogsNewUI')

  const [fetchPrograms, { loading }] = useFetchPrograms()
  const [fetchProgram] = useFetchProgram()

  useEffect(() => {
    fetchPrograms().then(result => {
      syncCollection(filter(result as MentorshipProgram[], ({ startsAt, endsAt }) => withinRange(startsAt, endsAt)))
      setInitiallyLoaded(true)
    })
  }, [dateRange, fetchPrograms, syncCollection, withinRange])

  const showAddProgram = useShowPrompt(newDialogUI ? ProgramFlowEditor : LegacyProgramFlowEditor)
  const addProgram = useCallback(
    () =>
      showAddProgram({
        submit: eagerAdd,
      }),
    [eagerAdd, showAddProgram],
  )
  const editProgram = useCallback(
    (program: MentorshipProgram) =>
      showAddProgram({
        program,
        submit: (program: MentorshipProgram) => {
          fetchProgram(program.id).then(eagerReplace)
        },
      }),
    [eagerReplace, fetchProgram, showAddProgram],
  )

  return [{ programs, loading, initiallyLoaded }, { addProgram, editProgram }, events] as [
    { programs: MentorshipProgram[]; loading: boolean; initiallyLoaded: boolean },
    {
      addProgram: () => Promise<void>
      editProgram: (program: MentorshipProgram) => Promise<void>
    },
    CollectionEventListeners<MentorshipProgram>,
  ]
}
