import React, { useEffect, useState, useCallback } from 'react'
import { Grid, makeStyles, Typography, Button, IconButton, TextField } from '@material-ui/core'
import { AddCircleOutline, Schedule, DeleteOutline, ArrowForward } from '@material-ui/icons'
import { TripRequestLocationPicker } from 'volunteering/trips/components'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import ReactGoogleMap from 'components/ReactGoogleMap'
import { MIN_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import { defaultAddress } from 'civic-champs-shared/formik/components/AddressField'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  headerRow: {
    verticalAlign: 'middle',
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  locationMap: {
    width: '100%',
    height: '360px',
    marginTop: theme.spacing(2),
  },
  actionButton: {
    marginTop: '10px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:first-child': {
      'margin-left': '0',
    },
    '&:last-child': {
      'margin-right': '0',
    },
  },
  tripTimesColumn: { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
  tripOrderItem: { margin: theme.spacing(1) },
  tripOrderItemPickup: { display: 'flex', alignItems: 'center' },
  deleteTripOrderItemButton: { marginLeft: theme.spacing(1), color: 'red' },
}))

interface TripDetailsProps {
  formData: any
  onNext: Function
  onBack: Function
}

const defaultTripDetails = {
  pickupLocation: {
    ...defaultAddress(),
    displayAddress: '',
  },
  dropOffLocation: {
    ...defaultAddress(),
    displayAddress: '',
  },
  pickupAt: null,
  dropOffAt: null,
  description: '',
  validated: false,
}

export const TripDetails = (props: TripDetailsProps) => {
  const classes = useStyles()
  const { onNext, onBack, formData } = props
  const [validatedRideDetails, setValidatedRideDetails] = useState(false)
  const [tripOrders, setTripOrders] = useState(formData.tripOrders.at(-1).validated ? formData.tripOrders : [])
  const [tripDetails, setTripDetails] = useState(defaultTripDetails)
  const [coordinates, setCoordinates] = useState()
  const addTripOrder = () => {
    setTripOrders([...tripOrders, tripDetails])
    setTripDetails(defaultTripDetails)
  }

  useEffect(() => {
    const validated = Boolean(
      tripDetails.dropOffLocation.displayAddress &&
        tripDetails.pickupLocation.displayAddress &&
        tripDetails.description &&
        tripDetails.pickupAt &&
        tripDetails.dropOffAt,
    )
    if (validated !== tripDetails.validated) {
      setTripDetails({ ...tripDetails, validated })
    }
  }, [setTripDetails, tripDetails])

  useEffect(() => {
    setValidatedRideDetails(tripOrders.length > 0 ? tripOrders.at(-1).validated : tripDetails.validated)
  }, [tripOrders, tripDetails.validated])

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault()
      const { validated } = tripDetails
      const finalTripOrders = validated ? [...tripOrders, tripDetails] : tripOrders
      onNext(tripOrders.length > 0 ? finalTripOrders : [tripDetails])
      setTripDetails(defaultTripDetails)
    },
    [onNext, tripDetails, tripOrders],
  )

  const updateField = useCallback(
    (e: any) => {
      const description = e.target.value
      setTripDetails(currTripDetails => ({ ...currTripDetails, description }))
    },
    [setTripDetails],
  )

  const updateLocation = useCallback(
    (field: string, location: any) => setTripDetails(value => ({ ...value, [field]: location })),
    [setTripDetails],
  )

  return (
    <div>
      <div className={classes.headerRow}>
        <Typography variant="h4" className={classes.header}>
          Trip Details
        </Typography>
      </div>

      <form onSubmit={handleSubmit} noValidate={false}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="row" spacing={6}>
              <Grid item xs={6}>
                <TripRequestLocationPicker
                  setCoordinates={setCoordinates}
                  location={tripDetails.pickupLocation}
                  setLocation={updateLocation}
                  label="Pickup Address"
                  field="pickupLocation"
                />
                <TripRequestLocationPicker
                  setCoordinates={setCoordinates}
                  location={tripDetails.dropOffLocation}
                  setLocation={updateLocation}
                  label="Drop Off Address"
                  field="dropOffLocation"
                />
                <div className={classes.tripTimesColumn}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item container xs={6} direction="column">
                      <Grid item>
                        <Typography variant="h6" className={classes.header}>
                          Pickup Date & Time
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.location}>
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            //@ts-ignore
                            classes={{ root: 'custom-input-component' }}
                            format="MM/DD/YYYY hh:mm a"
                            onChange={(pickupAt: any) => setTripDetails(value => ({ ...value, pickupAt }))}
                            disablePast
                            placeholder="Pickup Date & Time"
                            value={tripDetails.pickupAt}
                            keyboardIcon={<Schedule />}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container xs={6} direction="column">
                      <Grid item>
                        <Typography variant="h6" className={classes.header}>
                          Drop Off Date & Time
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={classes.location}>
                          <KeyboardDateTimePicker
                            inputVariant="outlined"
                            //@ts-ignore
                            classes={{ root: 'custom-input-component' }}
                            format="MM/DD/YYYY hh:mm a"
                            onChange={(dropOffAt: any) => setTripDetails(value => ({ ...value, dropOffAt }))}
                            disablePast
                            placeholder="Drop Off Date & Time"
                            value={tripDetails.dropOffAt}
                            keyboardIcon={<Schedule />}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.locationMap}>
                  <ReactGoogleMap
                    zoom={17}
                    geofenceRadius={MIN_RADIUS_IN_METER}
                    //@ts-ignore
                    coordinates={coordinates}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.header}>
                Description
              </Typography>
            </Grid>
            <TextField
              multiline
              onChange={updateField}
              rows={6}
              fullWidth
              classes={{ root: 'custom-input-component' }}
              variant="outlined"
              label="Description"
              value={tripDetails.description}
              placeholder="Please enter a description for this Trip Order"
            />
            <Button
              variant="contained"
              onClick={addTripOrder}
              color="primary"
              disabled={!tripDetails.validated}
              className={classes.actionButton}
            >
              <AddCircleOutline />
              Add an Order to Trip
            </Button>
            <Grid container direction="column" spacing={2}>
              {tripOrders && (
                <ol>
                  {tripOrders.map((tripOrder: any, i: number) => {
                    return tripOrder.validated ? (
                      <li className={classes.tripOrderItem} key={`trip-order-list-item-${i + 1}`}>
                        <Typography key={`trip-order-locations-${i + 1}`} className={classes.tripOrderItemPickup}>
                          {tripOrder.pickupLocation.displayAddress} <ArrowForward />{' '}
                          {tripOrder.dropOffLocation.displayAddress}
                          <IconButton
                            className={classes.deleteTripOrderItemButton}
                            key={`trip-order-icon-button-${i + 1}`}
                            aria-label="delete"
                            onClick={() => {
                              const newTripOrders = tripOrders.filter((order: any) => {
                                return order !== tripOrder
                              })
                              setTripOrders(newTripOrders)
                            }}
                          >
                            <DeleteOutline key={`trip-order-delete-icon-${i + 1}`} />
                          </IconButton>
                        </Typography>
                        <Typography key={`trip-order-date-${i + 1}`} className={classes.text}>
                          {moment(tripOrder.pickupAt).format('MMMM DD, YYYY @ HH:MM a')}
                        </Typography>
                      </li>
                    ) : null
                  })}
                </ol>
              )}
            </Grid>
            <Grid item>
              <Button onClick={() => onBack()} className={classes.actionButton}>
                Back
              </Button>
              <Button
                type="submit"
                disabled={!validatedRideDetails}
                variant="contained"
                color="secondary"
                className={classes.actionButton}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
