import { useSelector } from 'react-redux';

import { isFeaturesAvailable } from '../selectors';

export default function FeaturesLoadedGate(props) {
    const { children, fallback } = props;

    const featuresAvailable = useSelector(isFeaturesAvailable);

    if(featuresAvailable)
        return children
    else if(fallback)
        return fallback();
    else 
        return null;
}