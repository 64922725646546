import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function OutlinedText({ label, text, labelStyle, textStyle}) {

    return (<>
      <Typography className={labelStyle}>{label}</Typography>
      <Typography className={textStyle}>{text}</Typography>
    </>)
}
