import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Person } from 'people/interface'
import { GetFirstLastId, useRecipientsPlaceholderText } from 'messages/hooks/useRecipientsPlaceholderText'
import {
  HALF_HEIGHT,
  ROW_HEIGHT,
  useMembersSelectStyles,
  useMessageRecipientRowStyles,
} from 'chats/hooks/useMembersSelectStyles'
import cn from 'classnames'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { Fade, Popper } from '@material-ui/core'
import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PersonEmbeddedAutocomplete, { GetContent } from 'messages/components/PersonEmbeddedAutocomplete'
import find from 'lodash/find'
import { Member } from 'chats/interfaces'
import CloseIcon from '@material-ui/icons/Close'
import { useHandleBlur } from 'civic-champs-shared/helpers/useHandleBlur'
import { useHandleScroll } from 'civic-champs-shared/helpers/useHandleScroll'
import useFetchPersonSuggestions, { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'

interface MembersSelectProps {
  members: (Person | PersonSuggestion)[]
  setMembers: Dispatch<SetStateAction<(Person | PersonSuggestion)[]>>
  existingMembers?: Member[]
}

const getFirstLastId: GetFirstLastId<Person | PersonSuggestion> = ({
  id,
  givenName,
  familyName,
}: Person | PersonSuggestion) => ({
  id,
  firstName: givenName,
  lastName: familyName,
})

const getContent: GetContent<PersonSuggestion> = ({ id, givenName, familyName, contact }: PersonSuggestion) => ({
  id,
  left: `${givenName} ${familyName}`,
  right: contact,
})

const MemberRow = ({ member, onDelete }: { member: Person | PersonSuggestion; onDelete: (id: number) => void }) => {
  const classes = useMessageRecipientRowStyles()
  let contact
  if ('email' in member) {
    contact = member.email
  } else if ('contact' in member) {
    contact = member.contact
  }
  return (
    <div className={classes.recipientRow}>
      <div className={classes.recipientContainer}>
        <CheckCircleIcon className={classes.rowIcon} />{' '}
        <div className={classes.recipientNameContainer}>
          <div>
            {member.givenName} {member.familyName}
          </div>
          <div className={classes.email}>{contact}</div>
        </div>
      </div>
      <CloseIcon className={classes.closeIcon} onClick={() => onDelete(member.id)} />
    </div>
  )
}

const MembersSelect = ({ members, setMembers, existingMembers = [] }: MembersSelectProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [contacts, setContacts] = useState<PersonSuggestion[]>([])
  const { text, more } = useRecipientsPlaceholderText<Person | PersonSuggestion>({ contacts: members, getFirstLastId })
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const focusEl = useRef<HTMLDivElement | null>(null)
  const [fetchPersonSuggestions] = useFetchPersonSuggestions()
  useEffect(() => {
    const fetch = async () => {
      const { suggestions } = await fetchPersonSuggestions('')
      setContacts(suggestions)
    }
    fetch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const filteredContacts = useMemo(
    () =>
      contacts.filter(
        (user: PersonSuggestion) =>
          !members.find(member => member.id === user.id) && !existingMembers.find(member => member.id === user.id),
      ),
    [contacts, members, existingMembers],
  )
  const classes = useMembersSelectStyles()
  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts } = useDisplayAreaInfo<
    Person | PersonSuggestion
  >({
    contacts: members,
    scrollTopPosition,
    rowHeight: ROW_HEIGHT,
    height: HALF_HEIGHT,
  })
  const textDiv = useMemo(
    () => (
      <div className={classes.text}>
        {members.length ? (
          <>
            {text}
            {more ? (
              <span>
                {'\u00A0'}+{more} more
              </span>
            ) : null}
          </>
        ) : open ? (
          ''
        ) : (
          'Select Members *'
        )}
      </div>
    ),
    [members.length, classes.text, text, more, open],
  )
  const handleClick = () => {
    setScrollTopPosition(0)
    setOpen(val => !val)
  }

  const handleAdd = (id: number) => {
    const person = find(contacts, { id })
    setMembers(currentMembers => [...currentMembers, person as Person | PersonSuggestion])
  }

  const handleScroll = useHandleScroll(setScrollTopPosition)

  const handleBlur = useHandleBlur(focusEl, () => setOpen(false))

  const handleDelete = useCallback(
    (id: number) => {
      setMembers(currentMembers => currentMembers.filter(member => member.id !== id))
    },
    [setMembers],
  )

  return (
    <>
      <div
        id="member-selector"
        className={cn(classes.input, { [classes.open]: open })}
        tabIndex={-1}
        onClick={handleClick}
        ref={anchorEl}
      >
        {textDiv}
        {open ? (
          <ExpandLessIcon className={classes.expandCollapse} />
        ) : (
          <ExpandMoreIcon className={classes.expandCollapse} />
        )}
      </div>
      <Popper
        className={classes.popper}
        id="message-recipient-selector-popper"
        open={open}
        anchorEl={anchorEl.current}
        transition
        tabIndex={-1}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div tabIndex={-1} className={classes.popperPaper} ref={focusEl} onBlur={handleBlur}>
              <div className={classes.selected}>
                <strong>Selected</strong>
              </div>
              <div className={classes.recipients} onScroll={handleScroll}>
                <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />
                {visibleContacts.map(member => (
                  <MemberRow key={member.id} member={member} onDelete={handleDelete} />
                ))}
                <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
              </div>
              <div style={{ borderTop: '1px rgba(0,0,0,0.1) solid', margin: '5px 9.5px' }} />
              <PersonEmbeddedAutocomplete<PersonSuggestion>
                onAdd={handleAdd}
                selectedContactCount={members.length}
                contacts={filteredContacts}
                getFirstLastId={getFirstLastId}
                getContent={getContent}
              />
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default (props: MembersSelectProps) => (
  <ThemeProvider theme={muiTheme}>
    <MembersSelect {...props} />
  </ThemeProvider>
)
