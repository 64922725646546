import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function usePerson({ id, onSuccess, organizationId }) {
  const [request, { loading, error, result, called }] = useFetch(
    'Error fetching people.  Please refresh the page to try again',
  )
  const getPerson = useCallback(
    id => {
      return request({
        method: 'get',
        url: organizationId ? `/organizations/${organizationId}/people/${id}` : `/people/${id}`,
        onSuccess,
      })
    },
    [request, onSuccess, organizationId],
  )

  useEffect(() => {
    getPerson(id)
  }, [id, getPerson])

  // todo: why called multiple times ?
  return useMemo(
    () => ({
      person: result,
      loading: loading || !called,
      error,
      getPerson,
    }),
    [result, loading, called, error, getPerson],
  )
}
