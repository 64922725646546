import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import AddEditOfferPrompt from '../components/AddEditOfferPrompt'
import useCreateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useCreateNonSchedulableOpportunity'
import { OpportunityPayload } from '../interfaces'
import {
  OpportunityOccurrencePersonGroup,
  OpportunityResponse,
  OpportunityResponseWithRecurrenceInfo,
} from '../interfaces/interfaceCreateEditEvent'
import { useCurrentOrg } from '../../civic-champs-shared/auth/hooks'
import { useCreateApiNotification } from '../../civic-champs-shared/api/hooks'
import useUpdateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useUpdateNonSchedulableOpportunity'

export const useShowAddEditOfferPrompt = (onSuccess?: (result: OpportunityResponse) => void) => {
  const showPrompt = useShowPrompt(AddEditOfferPrompt)
  const org = useCurrentOrg()
  const createNotification = useCreateApiNotification()
  const [createOpportunity] = useCreateNonSchedulableOpportunity(org.id) as [
    (opportunity: OpportunityPayload) => Promise<any>,
  ]
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity() as [
    (opportunity: OpportunityPayload, encodedOccurrence: string) => Promise<any>,
  ]

  return useCallback(
    async ({
      offer,
      visibilityGroups,
      encodedOccurrence,
    }: {
      offer?: OpportunityResponseWithRecurrenceInfo
      visibilityGroups?: OpportunityOccurrencePersonGroup[]
      encodedOccurrence?: string
    } = {}) => {
      const result: OpportunityPayload = await showPrompt({ offer, visibilityGroups })
      const notification = createNotification(`Saving Offer`)
      try {
        const response: OpportunityResponse = await (encodedOccurrence
          ? updateOpportunity(result, encodedOccurrence)
          : createOpportunity(result))
        notification.onSuccess(`Offer Saved`)
        onSuccess?.(response)
      } catch (e) {
        notification.onError(`Failed to Save Offer`, e)
      }
    },
    [createNotification, createOpportunity, onSuccess, showPrompt, updateOpportunity],
  )
}
