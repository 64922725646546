import { IntegrationProvider } from 'integrations/types'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const usePushActivities = (): ((props: {
  provider: IntegrationProvider
  startDate: Date
  endDate: Date
}) => Promise<void>) => {
  const [request] = useFetchRefactored<void>({
    successMessage: 'Activities send to integration sync',
    errorMessage: 'Failed to send activities to integration sync',
    useNewNotification: true,
  })
  const org = useCurrentOrg()

  return useCallback(
    async ({ provider, startDate, endDate }: { provider: IntegrationProvider; startDate: Date; endDate: Date }) => {
      return request({
        method: 'post',
        url: `/organizations/${org.id}/push-activities/${provider}`,
        config: {
          body: {
            start_date: format.dateForUrl(startDate),
            end_date: format.dateForUrl(endDate),
          },
        },
      })
    },
    [org.id, request],
  )
}

export default usePushActivities
