import { useTimeZone } from 'civic-champs-shared/auth/hooks'
import { useMemo } from 'react'
import moment from 'moment-timezone'
import { OrganizationLocation } from 'locations/types'

const formatDate = (companyTimeZone: string, date?: string | null) =>
  date ? moment(date).tz(companyTimeZone).format('dddd, MMMM Do YYYY, hh:mma z') : 'Never'

export const useLocationDateStrings = ({ location }: { location?: OrganizationLocation }) => {
  const companyTimeZone = useTimeZone()
  return useMemo(() => {
    if (!location) return { startsAt: '', endsAt: '' }

    return {
      startsAt: formatDate(companyTimeZone, location.startsAt),
      endsAt: formatDate(companyTimeZone, location.endsAt),
    }
  }, [location, companyTimeZone])
}
