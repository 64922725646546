import * as moment from 'moment'
import yup from 'civic-champs-shared/utils/yup'
import { isNil } from 'lodash'

export const EMAIL_VALIDATION_TEXT = 'Email must be a valid email address'
const PHONE_VALIDATION_TEXT = 'Phone number must be a valid US phone number'

export const profilePersonSchema = yup.object({
  givenName: yup.string().trim().required('Please enter first name'),
  familyName: yup.string().trim().required('Please enter last name'),
  email: yup.string().email(EMAIL_VALIDATION_TEXT).trim().lowercase().nullable(),
  phoneNumber: yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable(),
  homePhoneNumber: yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable(),
  birthDate: yup
    .date()
    .nullable()
    .test(
      'birthDate',
      'Volunteers must be at least 13 years old',
      value => isNil(value) || moment().diff(moment(value), 'years') >= 13,
    ),
  address: yup
    .object()
    .shape({
      id: yup.number().id().nullable(),
      line1: yup.string().trim().nullable(),
      line2: yup.string().trim().nullable(),
      city: yup.string().trim().nullable(),
      country: yup.string().trim().nullable(),
      zip: yup.string().trim().nullable(),
    })
    .nullable(),
  emergencyContactRelationship: yup.string().nullable(),
  emergencyContact: yup
    .object()
    .shape({
      id: yup.number().id().nullable(),
      givenName: yup.string().trim().required("Please enter the emergency contact's first name"),
      familyName: yup.string().trim().required("Please enter the emergency contact's last name"),
      email: yup.string().email(EMAIL_VALIDATION_TEXT).trim().lowercase().nullable(),
      phoneNumber: yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable(),
      homePhoneNumber: yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable(),
      workPhoneNumber: yup.string().phoneNumber(PHONE_VALIDATION_TEXT).nullable(),
    })
    .nullable(),
})
