import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const stableArray = []

export default function useActiveCampaign() {
  const [request, { loading, error, result, called }] = useFetch('Error loading possible campaigns')
  const organization = useCurrentOrg()
  const getActiveCampaigns = useCallback(() => {
    request({
      method: 'get',
      url: `/organizations/${organization.id}/active_campaigns`,
    })
  }, [request])

  useEffect(getActiveCampaigns, [getActiveCampaigns])

  return {
    result: result || stableArray,
    loading: loading || !called,
    error,
  }
}
