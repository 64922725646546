import useStyles from 'new-mentorship/hooks/useStyles'
import useEditMatch from 'new-mentorship/hooks/useEditMatch'
import useTagManagement from 'new-mentorship/hooks/useTagsManagement'
import { useGroups } from 'group/hooks/useGroups'
import React, { useMemo } from 'react'
import find from 'lodash/find'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { CellProps } from 'react-table'
import IconButton from '@material-ui/core/IconButton'
import { ProgramMatch, Tag } from 'new-mentorship/types'
import differenceBy from 'lodash/differenceBy'
import EditIcon from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import { DATE_RANGE, DYNAMIC_NUMBER, DynamicTextArray } from 'core/table/filters'
import moment from 'moment'
import Tags from 'components/tag/Tags'
import { timezones } from 'civic-champs-shared/helpers/timezones'

export default function ({ showAddMatch, fetchMatches }: any) {
  const classes = useStyles()
  const { editMatch } = useEditMatch()
  const { linkTagsToMatch, unlinkTagsFromMatch } = useTagManagement()
  const { groups } = useGroups()
  const { mentorGroupId, menteeGroupId } = useMemo(
    () => ({
      mentorGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentor } })?.id,
      menteeGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentee } })?.id,
    }),
    [groups],
  )

  return useMemo(
    () => [
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({
          cell: {
            row: {
              original: {
                id,
                mentor,
                mentee,
                program,
                startedAt,
                stoppedAt,
                tags,
                meetingsTimezone,
                meetingCount,
                momentCount,
              },
            },
          },
        }: CellProps<any>) => {
          const mentorContact = mentor.email || mentor.phoneNumber
          const menteeContact = mentee.email || mentee.phoneNumber
          return (
            <IconButton
              color="inherit"
              aria-label="edit match"
              edge="start"
              onClick={() => {
                showAddMatch({
                  id,
                  label: 'Edit Match',
                  editable: meetingCount + momentCount === 0,
                  initialValues: {
                    program,
                    mentor: {
                      id: mentor.id,
                      givenName: mentor.givenName,
                      familyName: mentor.familyName,
                      contact: mentorContact,
                      title: `${mentor.givenName} ${mentor.givenName} - ${mentorContact} `,
                      groupId: mentorGroupId,
                    },
                    mentee: {
                      id: mentee.id,
                      givenName: mentee.givenName,
                      familyName: mentee.familyName,
                      contact: menteeContact,
                      title: `${mentee.givenName} ${mentee.givenName} - ${menteeContact} `,
                      groupId: menteeGroupId,
                    },
                    startedAt,
                    stoppedAt,
                    tags,
                    meetingsTimezone: find(timezones, { value: meetingsTimezone }),
                  },
                  submit: ({ tags, ...match }: Partial<ProgramMatch> & { tags: Tag[] }) =>
                    editMatch(id, match).then(async (match: ProgramMatch) => {
                      const addTags = differenceBy(tags, match?.tags || [], 'id')
                      const deleteTags = differenceBy(match?.tags || [], tags, 'id')
                      if (addTags.length) {
                        await linkTagsToMatch(
                          addTags.map(({ id }) => id),
                          match.id,
                        )
                      }
                      if (deleteTags.length) {
                        await unlinkTagsFromMatch(
                          deleteTags.map(({ id }) => id),
                          match.id,
                        )
                      }
                      return fetchMatches()
                    }),
                })
              }}
              className={classes.menuButton}
            >
              <EditIcon />
            </IconButton>
          )
        },
      },
      {
        Header: 'Program',
        accessor: 'programName',
        id: 'programName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ program: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentor',
        accessor: 'mentorName',
        id: 'mentorName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ mentor: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentee',
        accessor: 'menteeName',
        id: 'menteeName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ mentee: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Matched Since',
        accessor: 'startedAt',
        id: 'startedAt',
        disableFilters: true,
        filter: DATE_RANGE,
        Cell: ({ cell: { value } }: CellProps<any>) => moment(value).format('MMMM D, YYYY'),
      },
      {
        Header: '# Meetings',
        accessor: 'meetingCount',
        id: 'meetingCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify(
                  { mentee: [o.menteeName], mentor: [o.mentorName], type: ['meeting'] },
                  { arrayFormat: 'bracket' },
                ),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },
      {
        Header: '# Moments',
        accessor: 'momentCount',
        id: 'momentCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify(
                  { mentee: [o.menteeName], mentor: [o.mentorName], type: ['moment'] },
                  { arrayFormat: 'bracket' },
                ),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },

      {
        Header: 'Avg. Meeting Time',
        accessor: 'averageMeetingDuration',
        id: 'averageMeetingDuration',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value && value + ' min') || '-',
      },

      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags',
        filter: DynamicTextArray('name'),
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Tags tags={value.map(({ name: label, id, color }: Tag) => ({ label, id, color }))} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes, mentorGroupId, menteeGroupId],
  )
}
