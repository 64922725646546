import React from 'react'
import { NotificationBlastSummary } from 'messages/types'
import { useMessageOverviewStyles } from 'messages/hooks/useMessageOverviewStyles'
import { createMarkup } from '../../civic-champs-shared/core/utils/helpers'

export const MessageBody = ({ info: { body } }: { info: NotificationBlastSummary }) => {
  const classes = useMessageOverviewStyles()
  if (typeof body === 'object') {
    return <div className={classes.body}>{body}</div>
  } else {
    return <div className={classes.body} dangerouslySetInnerHTML={createMarkup(body as string)} />
  }
}
