import React, { useCallback, useEffect } from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import { CustomBaseSelectStyles } from './custom-styles'

import './index.scss'

interface Props {
  onChange: (option: any) => void
  options: any[]
  value: any
  disabled?: boolean
  placeholder?: string
  hasError?: boolean
  className?: string
  errorMessage?: string
  isOptionDisabled?: any
  onBlur?: (e?: any) => void
}

const SelectComponent = (props: Props) => {
  const {
    disabled,
    onChange,
    options,
    value,
    placeholder,
    hasError,
    className,
    isOptionDisabled,
    errorMessage = 'Required field',
    onBlur,
  } = props

  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const shouldHide = React.useRef(true)
  const mousePosition = React.useRef<{ x: number; y: number; target: EventTarget | null }>({ x: 0, y: 0, target: null })
  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      mousePosition.current = { x: e.clientX, y: e.clientY, target: e.target }
    }
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const handleBlur = useCallback(
    e => {
      const { x, y, target } = mousePosition.current
      if (target !== null) {
        const { left, top } = (target as HTMLDivElement).getBoundingClientRect()
        // click on scrollbar should not trigger blur
        if (x > left + (target as HTMLDivElement).clientWidth || y > top + (target as HTMLDivElement).clientHeight) {
          shouldHide.current = false
          return
        }
      }
      onBlur?.(e)
    },
    [onBlur],
  )

  const classes = classNames('custom-select-component__select', {
    'custom-select-component__select--error': hasError,
    'custom-select-component__select--disabled': disabled,
    [`${className}`]: className,
  })

  return (
    <div className="custom-select-component">
      <Select
        isOptionDisabled={isOptionDisabled}
        hasError={hasError}
        isDisabled={disabled}
        className={classes}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        styles={CustomBaseSelectStyles}
        options={options}
        onBlur={handleBlur}
        onMenuClose={function (...args) {
          if (shouldHide.current) {
            setMenuIsOpen(false)
          } else {
            shouldHide.current = true
          }
        }}
        onMenuOpen={() => setMenuIsOpen(true)}
        menuIsOpen={menuIsOpen}
      />
      {hasError && <div className="custom-select-component__error-msg">{errorMessage}</div>}
    </div>
  )
}

export default SelectComponent
