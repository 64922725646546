import React from 'react'
import { StatusCodes } from 'http-status-codes'

import MultiStepBar from '../../components/multi-step-bar'
import BaseInfoModule from '../../modules/events/create-edit/basic-info/index'
import ParticipantsModule from '../../modules/events/create-edit/participants'
import LocationModule from '../../modules/events/create-edit/location/index'
import ShiftModule from '../../modules/events/create-edit/shifts/index'
import CustomQuestionModule from '../../modules/events/create-edit/custom-questions/index'
import ReviewModule from '../../modules/events/create-edit/review/index'
import SummaryInfo from '../../modules/events/create-edit/summary-info/index'
import classNames from 'classnames'
import API_ENDPOINTS from '../../constants/API_ENDPOINTS'
import PageNotFound from '../../scenes/page-not-found'
import Loader from '../../components/loader'
import BaseService from '../../services/base.service'
import EditRecurringPopup from '../../modules/events/create-edit/edit-recurring-popup'
import CongratulationsPopup from '../../modules/events/create-edit/congratulations-popup'
import ErrorPopup from '../../modules/events/create-edit/error-popup'
import { EVENT_FORM_STEPS, FORM_STEPS } from '../../constants/EVENT_FORM_STEPS'
import { encodeOccurrenceFromEvent } from '../../helpers/encodeOccurrence'

import './index.scss'
import { OpportunityOccurrenceQuestionSet } from 'civic-champs-shared/question-sets/types'
import {
  AssociationType,
  EventGroup,
  OpportunityOccurrencePersonGroup,
} from '../../interfaces/interfaceCreateEditEvent'
import { mapEventPayload } from '../events/helpers/mapEventPayload'
import { mapEventGeofencingToGeofencing } from 'utils/event'
import isNull from 'lodash/isNull'
import { withCurrentOrganizationHOC } from 'Event/helpers/withCurrentOrganizationHOC'
import moment from 'moment'
import { getInitialEditMode } from '../../helpers/add-edit-helpers'

const base = new BaseService()

export const mapToEventGroup = (eventGroup: OpportunityOccurrencePersonGroup): EventGroup => ({
  groupId: eventGroup.group.id,
  name: eventGroup.group.name,
  associationType: eventGroup.associationType,
  approvedMembersOnly: eventGroup.approvedMembersOnly,
  closed: eventGroup.group.closed,
})

class EditEventScene extends React.Component<any> {
  state = {
    isSendingDraftRequest: false,
    isSendingPublicRequest: false,
    isLoadingEvent: false,
    isShowPopupEdit: false,
    isShowPopupCongrats: false,
    orgId: 1,
    encodedOccurrence: null,
    isShowPopupError: false,
    errorMessage: '',
    clearOnExit: true,
  }

  async componentDidMount() {
    const response = await this.getEvent()
    // We do not want to rewrite event data if we came back from adding new question set
    // and maintained all event data in redux store
    if (
      isNull(response) ||
      (!isNull(response) &&
        (response?.occurrenceId !== this.props.editEventForm.event?.occurrenceId ||
          response?.opportunityId !== this.props.editEventForm.event?.opportunityId))
    ) {
      this.props.setEventDataFromApi({
        ...response,
        editMode: getInitialEditMode(response),
        // keeping only date part to avoid timezone-difference related date shifts
        startsAt: moment.tz(response.startsAt, response.organization.timeZone).format('YYYY-MM-DD'),
      })
    }
    if (response != null) {
      this.setState({ orgId: response.organization.id })
    }

    if (this.checkPath()) {
      const { currentStep } = this.props.editEventForm
      const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep)[0].path
      this.pushCurrentPath(currentPath)
      this.props.changeStep(currentStep)
    }

    if (this.props.match.params.opportunityId) {
      this.setState({
        opportunityId: this.props.match.params.opportunityId,
      })
    }

    if (this.props.match.params.encodedOccurrence) {
      this.setState({
        encodedOccurrence: this.props.match.params.encodedOccurrence,
      })
    }
  }

  componentWillUnmount() {
    if (this.state.clearOnExit) {
      this.props.changeStep(1)
      this.props.clearEventForm()
    }
  }

  render() {
    if (!this.checkPath()) {
      return <PageNotFound />
    }

    if (this.state.isLoadingEvent) {
      return <Loader />
    }
    const { currentStep, event } = this.props.editEventForm
    const { orgId, encodedOccurrence } = this.state

    const handleSaveDraft = event.is_recurring ? this.handleOpenEditModal : this.handleSaveDraft
    const renderSteps: { [key in FORM_STEPS]: React.ReactNode } = {
      [FORM_STEPS.BASIC]: (
        <BaseInfoModule
          onNextClick={this.nextStep}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          encodedOccurrence={encodedOccurrence}
          editMode
          {...this.props}
        />
      ),
      [FORM_STEPS.PARTICIPANTS]: (
        <ParticipantsModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          {...this.props}
        />
      ),
      [FORM_STEPS.LOCATION]: (
        <LocationModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          editMode
          {...this.props}
        />
      ),
      [FORM_STEPS.SHIFTS]: (
        <ShiftModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          editMode
          {...this.props}
        />
      ),
      [FORM_STEPS.QUESTIONS]: (
        <CustomQuestionModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          onBeforeAddEdit={() => this.setState({ clearOnExit: false })}
          {...this.props}
        />
      ),
      [FORM_STEPS.REVIEW]: (
        <ReviewModule
          onPrevClick={this.prevStep}
          onSaveClick={event.is_recurring && event.published ? this.handleOpenEditModal : this.handleUpdatePublish}
          onCancel={this.handleCancelEvent}
          onSaveDraftClick={handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          isSendingPublicRequest={event.is_recurring ? false : this.state.isSendingPublicRequest}
          event={event}
          editMode
          {...this.props}
        />
      ),
    }

    const contentMainClasses = classNames('create-event-module-content__main', {
      'full-width': currentStep === FORM_STEPS.QUESTIONS || currentStep === FORM_STEPS.REVIEW,
    })

    return (
      <div className="main-container">
        <div className="create-event-module">
          <div className="buton-back-container">
            <div
              className="detail_event_block_back_arrow"
              onClick={() => this.props.history.push(`/events/${this.state.encodedOccurrence}/${orgId}`)}
            >
              <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
              Event Details
            </div>
          </div>
          <span className="edit-event-text">Edit Event</span>
          <div className="create-event-module-steps">
            <MultiStepBar
              currentStep={currentStep}
              steps={EVENT_FORM_STEPS}
              changeStep={this.props.changeStep}
              editMode={true}
            />
          </div>
          <div className="create-event-module-content">
            <div className={contentMainClasses}>{renderSteps[currentStep as FORM_STEPS]}</div>
          </div>
          {this.state.isShowPopupEdit && (
            <EditRecurringPopup
              handleClose={this.handleCloseEditModal}
              handleSave={event.published ? this.handleUpdatePublish : this.handleSaveDraft}
              isSendingUpdateRequest={this.state.isSendingPublicRequest}
              {...this.props}
            />
          )}
          {this.state.isShowPopupCongrats && (
            <CongratulationsPopup
              editMode={true}
              isRecurring={event.is_recurring}
              handleClose={this.handleCloseCongratsModal}
            />
          )}
          {this.state.isShowPopupError && (
            <ErrorPopup handleClose={this.handleCloseErrorModal} errorMessage={this.state.errorMessage} />
          )}
        </div>
        {currentStep !== FORM_STEPS.REVIEW && (
          <div className="sidebar">
            <SummaryInfo editMode currentStep={currentStep} event={event} />
          </div>
        )}
      </div>
    )
  }

  handleOpenEditModal = () => this.setState({ isShowPopupEdit: true })
  handleCloseEditModal = () => this.setState({ isShowPopupEdit: false })

  handleCloseErrorModal = () => {
    this.setState({ isShowPopupError: false })
    this.setState({ errorMessage: '' })
  }

  handleCloseCongratsModal = () => {
    this.setState({ isShowPopupCongrats: false })
    this.getEventListing()
  }

  checkPath = () => {
    const locationPath = this.props.history.location.pathname.split('/')
    const locationPathLength = locationPath.length
    const currentPath = locationPath[locationPathLength - 1]
    return EVENT_FORM_STEPS.some(item => item.path === currentPath)
  }

  getEventListing = () => this.props.history.push(`/events/${this.state.encodedOccurrence}/${this.state.orgId}`)

  nextStep = () => {
    const { currentStep } = this.props.editEventForm
    if (currentStep === EVENT_FORM_STEPS.length) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep + 1)[0].path
    this.pushCurrentPath(currentPath)
    this.props.changeStep(currentStep + 1)
  }

  prevStep = () => {
    const { currentStep } = this.props.editEventForm
    if (currentStep === 1) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep - 1)[0].path
    this.pushCurrentPath(currentPath)
    this.props.changeStep(currentStep - 1)
  }

  pushCurrentPath = (currentPath: string) => {
    this.props.history.push(`/events/${this.props.match.params.encodedOccurrence}/edit-event/${currentPath}`)
  }

  handleCancelEvent = () => {
    this.getEventListing()
  }

  handleSaveDraft = async () => {
    this.setState({ isSendingDraftRequest: true })
    try {
      const { event } = this.props.editEventForm
      const endpoint = API_ENDPOINTS.Events.occurrenceRefactorUpdateEvent(
        this.props.match.params.encodedOccurrence,
        'draft',
      )
      const response = await base.putJSON(endpoint, mapEventPayload(event, this.props.currentOrganization.timeZone))

      if (!response.error && response.statusCode !== 500) {
        this.setState({ isSendingDraftRequest: false })
        this.setState({ encodedOccurrence: encodeOccurrenceFromEvent(response) })
        this.getEventListing()
      }
      this.setState({ errorMessage: response.message })
      this.setState({ isShowPopupError: true })
      this.setState({ isSendingDraftRequest: false })
    } catch (e) {
      console.error(e)
    }
  }

  handleUpdatePublish = async () => {
    this.setState({ isSendingPublicRequest: true })
    try {
      const { event } = this.props.editEventForm
      const endpoint = API_ENDPOINTS.Events.occurrenceRefactorUpdateEvent(
        this.props.match.params.encodedOccurrence,
        'publish',
      )
      const response = await base.putJSON(endpoint, mapEventPayload(event, this.props.currentOrganization.timeZone))

      if (!response.error && response.statusCode !== StatusCodes.INTERNAL_SERVER_ERROR) {
        this.setState({ isSendingPublicRequest: false })
        this.setState({ encodedOccurrence: encodeOccurrenceFromEvent(response) })
        event.published ? this.getEventListing() : this.setState({ isShowPopupCongrats: true })
      }

      if (
        [StatusCodes.BAD_REQUEST, StatusCodes.CONFLICT, StatusCodes.INTERNAL_SERVER_ERROR].includes(response.statusCode)
      ) {
        this.handleCloseEditModal()
        this.setState({ isSendingPublicRequest: false })
        this.setState({
          errorMessage:
            response.statusCode === StatusCodes.INTERNAL_SERVER_ERROR
              ? 'Unknown error happened, please contact your system administrator'
              : response.message,
        })
        this.setState({ isShowPopupError: true })
      }

      this.setState({ isSendingPublicRequest: false })
    } catch (e) {
      console.error(e)
    }
  }

  getEvent = async () => {
    this.setState({ isLoadingEvent: true })
    try {
      const { encodedOccurrence } = this.props.match.params
      const { isQuestionnaireAsSurveyEnabled } = this.props
      const event = await base.getJSON(
        API_ENDPOINTS.Events.getOccurrence({ encodedOccurrence, populateRecurrenceInfo: true }),
      )

      const eventQuestionSets = await base.getJSON(
        API_ENDPOINTS.Events.getOccurrenceQuestionSets(encodedOccurrence, isQuestionnaireAsSurveyEnabled),
      )

      const eventGroups = await base.getJSON(API_ENDPOINTS.Events.getOccurrenceGroups(encodedOccurrence))
      if (
        !event.error &&
        event.statusCode !== 500 &&
        !eventQuestionSets.error &&
        eventQuestionSets.statusCode !== 500 &&
        !eventGroups.error &&
        eventGroups.statusCode !== 500
      ) {
        this.setState({ isLoadingEvent: false })
        return {
          ...event,
          geofencing: mapEventGeofencingToGeofencing(event.geofencing),
          questionSets: eventQuestionSets.map((eventQuestionSet: OpportunityOccurrenceQuestionSet) => ({
            ...(isQuestionnaireAsSurveyEnabled
              ? {
                  surveyId: eventQuestionSet.survey?.id,
                }
              : {
                  questionSetId: eventQuestionSet.questionSet.id,
                }),
            name: eventQuestionSet.questionSet.name,
            required: eventQuestionSet.required,
          })),
          visibilityGroups: eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.EVENT_PRIVATE_TO_MEMBERS,
            )
            .map(mapToEventGroup),
          onboardingGroups: eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP,
            )
            .map(mapToEventGroup),
        }
      }
      this.setState({ isLoadingEvent: false })
    } catch (e) {
      console.error(e)
    }
  }
}

export default withCurrentOrganizationHOC(EditEventScene)
