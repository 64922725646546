import React, { useCallback } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useFetch } from 'civic-champs-shared/api/hooks'
import AddUserFlow from 'users/components/AddUserFlow'
import { useCurrentOrg } from 'auth/hooks'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import cn from 'classnames'

export default function AddMemberPrompt(props) {
  const classes = usePromptStyles()
  const { showing, close, cancel, complete } = props

  const organization = useCurrentOrg()

  const [request, { loading: submitting, error, result }] = useFetch()
  const handleSubmit = useCallback(
    event => {
      request({
        method: 'post',
        url: `/organizations/${organization.id}/admins`,
        config: {
          body: event,
        },
      })
    },
    [organization, request],
  )

  const handleDone = () => complete(result)

  return (
    <Dialog fullScreen className={cn({ fullScreen: true })} open={showing} onClose={close} disableBackdropClick={true}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>Add Admin</Typography>
        <IconButton disabled={submitting} onClick={close} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AddUserFlow
          userLabel="Admin"
          onSubmit={handleSubmit}
          submitting={submitting}
          error={error}
          onCancel={cancel}
          onDone={handleDone}
        />
      </DialogContent>
    </Dialog>
  )
}
