import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  Column,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { Grid } from '@material-ui/core'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks/'

import Loading from 'components/Loading'

import PageHeader from 'App/components/PageHeader'
import { useAutomatedMessagesColumns, useGetAutomatedMessages } from '../hooks'
import { NotificationBlastSummary } from '../types'
import { useDateRangeFilter } from 'filtering/hooks'
import { STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'

const csvHeaders = [
  { label: 'Subject', key: 'subject' },
  { label: 'Date', key: 'createdAt' },
  { label: 'Source', key: 'source' },
  { label: 'Source Name', key: 'sourceName' },
  { label: 'Status', key: 'status' },
]

const tableName = 'automatedMessages'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'subject':
      return STRING_OPERATOR_OPTIONS
    default:
      return []
  }
}

const AutomatedMessagesComponent = ({ location, history }: RouteComponentProps) => {
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()
  const [dateRange] = useDateRangeFilter()

  const { result: messages, loading } = useGetAutomatedMessages(dateRange)
  const columns = useAutomatedMessagesColumns()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)

  const table = useTable(
    {
      data: messages,
      columns: columns as Column<NotificationBlastSummary>[],
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  if (loading || columnStateLoading) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows.map((row: any) => row.original)
  const disabled = !selectedRows.length

  return (
    <Grid container>
      <ExtendedPagedTable<NotificationBlastSummary>
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Messages"
        buttons={
          <>
            <ExportButton
              disabled={disabled}
              data={selectedRows}
              headers={csvHeaders}
              filename={'automated-messages.csv'}
            />
          </>
        }
        useGlobalSearch
        useDateRange
      />
    </Grid>
  )
}

export const AutomatedMessages = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Automated Messages" subTitle="View system generated messages sent">
      <AutomatedMessagesComponent {...props} />
    </PageHeader>
  )
}

export default AutomatedMessages
