import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import useAvatarDialogStyles from 'champion/hooks/useAvatarDialogStyles'
import cn from 'classnames'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { ReactComponent as UploadIcon } from 'images/fileUpload.svg'
import { readFile } from 'champion/utils/files'

enum DragState {
  None,
  Valid,
  Invalid,
}

const isValidFile = (e: React.DragEvent<any>): boolean => {
  if (e.dataTransfer.items.length !== 1) return false
  const item = e.dataTransfer.items[0]
  return item.kind === 'file' && item.type.startsWith('image/')
}

const getFile = (e: React.DragEvent<any>): File | null => {
  if (isValidFile(e)) {
    return e.dataTransfer.items[0].getAsFile()
  }
  return null
}

export const AvatarDragContainer = ({ setFile }: { setFile: Dispatch<SetStateAction<string | null>> }) => {
  const classes = useAvatarDialogStyles()
  const [state, setState] = useState(DragState.None)
  const inputFileRef = React.useRef<any>()
  const handleClick = useCallback(() => {
    inputFileRef.current?.click()
  }, [])
  return (
    <div
      className={cn(classes.dragndrop, {
        [classes.valid]: state === DragState.Valid,
        [classes.invalid]: state === DragState.Invalid,
      })}
      onDrop={e => {
        e.preventDefault()
        const file = getFile(e)
        if (!file) return
        readFile(file).then(setFile)
      }}
      onDragOver={e => {
        e.preventDefault()
        if (isValidFile(e)) {
          setState(DragState.Valid)
        } else {
          setState(DragState.Invalid)
        }
      }}
      onDragLeave={e => {
        setState(DragState.None)
      }}
    >
      <UploadIcon />
      <p className={classes.dragTitle}>
        {state === DragState.Invalid ? 'Incorrect File Type' : 'Drop New Image Here or'}
      </p>
      <OutlinedButton className={classes.button} onClick={handleClick}>
        Browse Image
      </OutlinedButton>
      <input
        ref={inputFileRef}
        className={classes.hidden}
        onChange={e => readFile((e.target.files as FileList)[0]).then(setFile)}
        type="file"
        accept="image/*"
      />
      <p className={classes.supports}>Supports JPEG, PNG or GIF</p>
    </div>
  )
}

export default AvatarDragContainer
