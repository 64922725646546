import { get } from 'lodash'
import { fork, takeEvery, put, call } from 'redux-saga/effects'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

import {
  GET_FEATURES_FOR_USER,
  getFeaturesForUser,
  getFeaturesForUserSucceeded,
  getFeaturesForUserFailed,
} from '../actions'
import { AUTH_INITIALIZED, SIGNED_IN } from 'auth/actions'

export default function* developmentFeatureRootSaga() {
  yield fork(watchForAuthInitializedEvent)
  yield fork(watchForSignedInEvent)
  yield fork(watchForGetFeaturesEvents)
}

export function* watchForAuthInitializedEvent() {
  yield takeEvery(AUTH_INITIALIZED, onAuthInitializedEvent)
}

//TODO how does this work for endpoints that aren't protected!?
export function* onAuthInitializedEvent(authEvent) {
  let { user: authorizedUser } = authEvent.payload
  yield put(getFeaturesForUser(authorizedUser))
}

export function* watchForSignedInEvent() {
  yield takeEvery(SIGNED_IN, onSignedInEvent)
}

export function* onSignedInEvent(signInEvent) {
  let { user: authorizedUser } = signInEvent.payload
  yield put(getFeaturesForUser(authorizedUser))
}

export function* watchForGetFeaturesEvents() {
  yield takeEvery(GET_FEATURES_FOR_USER, onGetFeaturesEvent)
}

export function* onGetFeaturesEvent(fetchEvent) {
  const { user } = fetchEvent.payload

  const orgId = get(user, 'permissions.managedOrganization.id')
  const url = `/organizations/${orgId}/features`

  try {
    const features = yield call(requestWithRetry, { url })
    yield put(getFeaturesForUserSucceeded(user, features))
  } catch (error) {
    yield put(getFeaturesForUserFailed(user, error))
  }
}

export function isSameUser(authorizedUser, featuresUser) {
  return authorizedUser != null && featuresUser != null && authorizedUser.cognitoSub === featuresUser.cognitoSub
}
