import { useAsyncFn } from 'react-use'
import { useCurrentOrg } from 'auth/hooks'
import { editQuestionSet } from 'surveys/api'
import { useCreateSurveyVersion, useCreateSurvey } from 'surveys/hooks'

// TODO trying out a thinner call w/ a generic async wrapper
export const useSaveSurvey = () => {
  const currentOrg = useCurrentOrg()
  const [createSurveyVersion] = useCreateSurveyVersion()
  const [createSurvey] = useCreateSurvey()
  return useAsyncFn(
    async (payload, surveyId?: number) => {
      const questionSet = await editQuestionSet(currentOrg.id, payload)

      const { questionSetId } = questionSet
      return surveyId ? createSurveyVersion(surveyId, { questionSetId }) : createSurvey({ questionSetId })
    },
    [currentOrg.id],
  )
}

export default useSaveSurvey
