import React from 'react'

import AccountSettingsModule from '../../redux/containers/UserAccount'

class MyAccountScene extends React.Component<any> {
  render() {
    return (
      <AccountSettingsModule {...this.props}/>
    )
  }
}

export default MyAccountScene
