import { useCallback, useMemo, useState } from 'react'
import { Person } from 'people/interface'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { loadInChunks } from 'utils/loadInChunks'

export const useFetchPeople = () => {
  const [loading, setLoading] = useState(false)
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [people, setPeople] = useState<Person[]>([])
  const [request] = useFetchRefactored()

  const fetchPeople = useCallback(
    (queryStringParameters: { limit: number; offset: number }) => {
      return request({
        url: `/people`,
        config: {
          queryStringParameters,
        },
      }) as Promise<Person[]>
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const countPeople = useCallback(
    () => {
      return request({
        url: `/people-count`,
      }) as Promise<{ count: number }>
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPeople = useCallback(async () => {
    const newPeople: Person[] = await loadInChunks<Person>({
      getCount: countPeople,
      getChunk: fetchPeople,
      limit: 5000,
      setLoadingProgress,
      setLoading,
    })
    setPeople(newPeople)
    setInitiallyLoaded(true)
  }, [countPeople, fetchPeople])

  return useMemo(
    () => ({
      people,
      loading,
      initiallyLoaded,
      loadingProgress,
      getPeople,
    }),
    [loading, loadingProgress, people, initiallyLoaded], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
