import { get } from 'lodash'
import { useState, useEffect } from "react"
import useLazyRef from '../../utils/useLazyRef'

const idOf = value => get(value, 'id')
const isSame = (lhs, rhs) => idOf(lhs) === idOf(rhs) 

const useRemoteCollectionPointer = (collectionEvents, initialState=null) => {
    const [ref, setRef] = useState(initialState)
    const lastIdRef = useLazyRef( () => idOf(initialState) )

    useEffect(() => {
        const onAdd = item => {
            // this means the item was re-added to the collection, 
            // probably from undoing a failed remove
            if( idOf(item) === lastIdRef.current ) setRef(item)
        }

        const onUpdate = item => {
            setRef( prev => isSame(prev, item) ? item : prev ) 
            lastIdRef.current = idOf(item) 
        }

        const onRemove = item => setRef( prev => isSame(prev, item) ? null : prev )

        const onSync = items => setRef( prev => {
            for(let item of items) {
                if(isSame(prev, item) || idOf(item) === lastIdRef.current) 
                    return item
            }

            return null
        })
        
        const unlistens = [
            collectionEvents.onAdd(onAdd),
            collectionEvents.onUpdate(onUpdate),
            collectionEvents.onRemove(onRemove),
            collectionEvents.onSync(onSync)
        ]

        return  () => unlistens.forEach( fn => fn() )
    }, [collectionEvents, setRef, lastIdRef])

    return [ref, setRef]
}

export default useRemoteCollectionPointer
