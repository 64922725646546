import { EventPages, SET_EVENTS_PAGE } from 'Event/redux/actions/EventNavigation'

const defaultState = () => ({
  page: EventPages.List,
})

interface Action {
  type: string
  payload: {
    page: EventPages
  }
}

export default function reducer(state = defaultState(), action: Action) {
  switch (action.type) {
    case SET_EVENTS_PAGE:
      return {
        ...state,
        page: action.payload.page,
      }
    default:
      return state
  }
}
