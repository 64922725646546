import React from 'react'
import { Container, IconButton, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useModalStyles } from 'civic-champs-shared/core/StepModalContainer'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { useSaveSurvey } from 'surveys/hooks'
import ConfirmSurveyChangesDialog from './ConfirmSurveyChangesDialog'
import QuestionSetEditor from 'question-sets/components/QuestionSetEditor'

//TODO we need a useful interface for modal props
export function CreateUpdateSurveyPrompt(props: any) {
  const { showing, cancel, complete, survey } = props
  const showSuccess = useSuccessNotification()
  const showConfirmationDialog = useShowPrompt(ConfirmSurveyChangesDialog)

  const [{ loading }, saveSurvey] = useSaveSurvey()
  const handleSubmit = async (payload: any) => {
    if (survey?.id && payload.questionsEdited) {
      const success = await showConfirmationDialog()
      if (!success) return
    }
    const newSurvey = await saveSurvey(payload, survey?.id)
    complete(newSurvey)
    showSuccess(`Survey "${newSurvey.versions[0].questionSet?.name}" created`)
  }

  const classes = useModalStyles()

  return (
    <Modal open={showing}>
      <Container classes={{ root: classes.container }} maxWidth={false}>
        <Container
          classes={{
            maxWidthMd: classes.maxWidthMd,
            maxWidthLg: classes.maxWidthLg,
          }}
          maxWidth="lg"
        >
          <div className={classes.grid}>
            <IconButton aria-label="close" style={{ position: 'absolute', right: 0, top: 0 }} onClick={cancel}>
              <CloseIcon fontSize="large" />
            </IconButton>
            <QuestionSetEditor
              questionSet={survey?.versions[0].questionSet}
              isNew={!survey?.id}
              onSubmit={handleSubmit}
              isSubmitting={loading}
            />
          </div>
        </Container>
      </Container>
    </Modal>
  )
}

export default CreateUpdateSurveyPrompt
