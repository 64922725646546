import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

import './index.scss'
import VisibilitySelect from '../../../../components/opportunity/VisibilitySelect'
import { GroupPicker } from '../../../../components/opportunity/GroupPicker'
import { OpportunityVisibility } from '../../../../interfaces'
import { AssociationType } from '../../../../interfaces/interfaceCreateEditEvent'

const useClasses = makeStyles({
  visibilityContainer: {
    minHeight: '116px'
  },
  groupsContainer: {
    marginTop: '45px',
    minHeight: '320px'
  },
  groupsTitle: {
    fontSize: '24px',
    lineHeight: '42px',
    fontWeight: 600,
    color: '#000000',
    fontFamily: ['Magallanes-Regular', 'Arial', 'Open Sans', 'sans-serif']
  }
})

export const Visibility = props => {
  const {
    visibility,
    setEventField,
    visibilityGroups,
    allowPrivate = true,
    onChange,
    hasError,
    errorMessage
  } = props

  const classes = useClasses()

  return (
    <div>
      <div className={classes.visibilityContainer}>
        <VisibilitySelect
          allowPrivate={allowPrivate}
          visibility={visibility}
          onChange={val => setEventField('visibility', val)}
        />
      </div>
      <div className={classes.groupsContainer}>
        {visibility === OpportunityVisibility.SELECT_GROUPS_ONLY && (<>
            <h2 className={classes.groupsTitle}>Group Visibility</h2>
            <div>
              <Typography>
                Please add the group(s) for which you would like to limit the participation for this event. Only members
                of these groups will be able to view and join the event.
              </Typography>
              <GroupPicker
                loading={false}
                value={visibilityGroups}
                associationType={AssociationType.EVENT_PRIVATE_TO_MEMBERS}
                onChange={val => {
                  setEventField('visibilityGroups', val)
                  onChange && onChange(val)
                }}
                hasError={hasError}
                errorMessage={errorMessage}
                required
              />
            </div>
        </>)}
      </div>
    </div>
  )
}
