import { isString } from 'lodash'
import { useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { getSurvey } from 'surveys/api'

export const useSurvey = (id: string | number) => {
  const retVal = useAsyncFn(getSurvey, [])
  const [, fetch] = retVal

  useEffect(() => {
    fetch(isString(id) ? parseInt(id) : id)
  }, [fetch, id])

  return retVal
}

export default useSurvey
