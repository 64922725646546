import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'

import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import Loading from 'components/Loading'
import { useRecipientReschedule } from '../hooks'
import TimeSlotPicker from './TimeSlotPicker'

const useStyles = makeStyles(theme => ({
  dialog: {
    zIndex: '1200 !important',
    textAlign: 'center',
  },
  content: {
    width: '400px',
    padding: '8px 20px 20px 20px',
  },
  submit: {
    margin: '16px 8px 0',
  },
  title: {
    padding: '20px 0 16px',
  },
}))

const validate = values => !isEmpty(values.fieldValue)

export default function EditTaskFieldPrompt(props) {
  const classes = useStyles()
  const { task, field, showing, close, complete, initialValue } = props
  const [rescheduleRequest, rescheduleLoading] = useRecipientReschedule()
  const showError = useErrorNotification()

  const handleSubmit = async (values, actions) => {
    try {
      const timeslots = values.fieldValue
      const overdueAt = moment.max(timeslots.map(ts => ts.endTime)).format()

      await rescheduleRequest(task, {
        ...task, //TODO do we really need all these?
        [field]: timeslots,
        overdueAt,
      })
      complete()
    } catch (err) {
      showError('There was a problem updating the task', err)
    }
  }

  const loading = rescheduleLoading

  return (
    <Dialog open={showing} onClose={close} className={classes.dialog}>
      <DialogTitle className={classes.title}>Edit Task</DialogTitle>
      <DialogContent className={classes.content}>
        {loading ? (
          <Loading />
        ) : (
          <Formik initialValues={{ fieldValue: initialValue }} onSubmit={handleSubmit}>
            {({ isSubmitting, values }) => {
              // arrayHelper.insert() does not work with validators
              // https://github.com/formium/formik/issues/1870
              const isValid = validate(values)
              return (
                <Form>
                  <FieldArray
                    name="fieldValue"
                    render={arrayHelpers => <TimeSlotPicker arrayHelpers={arrayHelpers} maxSlots={3} />}
                  />
                  <Button onClick={close} disabled={isSubmitting} className={classes.submit}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={classes.submit}
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Form>
              )
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  )
}
