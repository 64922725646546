import { useCallback, useMemo, useState } from 'react'
import { capitalize } from 'lodash';
import moment from 'moment'

import { useCurrentOrg } from 'auth/hooks'

const getFirstOfThisPeriod = (period: string, orgTimezone: string) => (
  moment()
    .tz(orgTimezone)
    .startOf(period as moment.unitOfTime.StartOf)
)

export const useCalendarPeriod = (period: string) => {
  const org = useCurrentOrg()

  const [firstOfPeriod, setFirstOfPeriod] = useState(getFirstOfThisPeriod(period, org.timeZone))

  const gotoPrevPeriod = useCallback(() => {
    // @ts-ignore
    setFirstOfPeriod(prev => prev.clone().subtract(1, period))
  }, [period])

  const gotoNextPeriod = useCallback(() => {
    // @ts-ignore
    setFirstOfPeriod(prev => prev.clone().add(1, period))
  }, [period])

  const gotoCurrentPeriod = useCallback(() => {
    setFirstOfPeriod(getFirstOfThisPeriod(period, org.timeZone))
  }, [period, org.timeZone])

  const capitalizedPeriod = capitalize(period)

  return useMemo(() => {
    return {
      [`firstOf${capitalizedPeriod}`]: firstOfPeriod,
      [`setFirstOf${capitalizedPeriod}`]: setFirstOfPeriod,
      [`gotoPrev${capitalizedPeriod}`]: gotoPrevPeriod,
      [`gotoNext${capitalizedPeriod}`]: gotoNextPeriod,
      [`gotoCurrent${capitalizedPeriod}`]: gotoCurrentPeriod,
    } as any
  }, [capitalizedPeriod, firstOfPeriod, gotoPrevPeriod, gotoNextPeriod, gotoCurrentPeriod])
}
