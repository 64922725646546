import _ from 'lodash';
import { useRef, useMemo } from 'react';
import MiniSearch from 'minisearch';

const getValue = (valOrFn) => _.isFunction(valOrFn) ? valOrFn() : valOrFn;

const createResultsMapper = documents => {
    const documentMap = _.reduce(documents, (map, doc) => {
        map.set(doc.id, doc);
        return map;
    }, new Map());

    return results => _.map(results, result => documentMap.get(result.id) )
}

export default function useMiniSearch(documents, initialConfigOrConfigFactory, configDeps=[]) {
    const initialConfig = useRef(initialConfigOrConfigFactory);
    const search = useMemo(() => {
        if(documents === null || documents === undefined) {
            return _.constant([]);
        }

        let config = getValue(initialConfig.current);
        let minisearch = new MiniSearch(config);
        let mapResults = createResultsMapper(documents);

        minisearch.addAll(documents);

        return (query, searchOptions) => {
            if( _.isEmpty(query) ) return [...documents];
            
            let results = minisearch.search(query, searchOptions);
            return mapResults(results);
        }

    }, [documents, ...configDeps]) // eslint-disable-line react-hooks/exhaustive-deps

    return search;
}