import { filter, find, orderBy } from 'lodash'
import React, { useMemo, useCallback } from 'react'
import { CircularProgress, Typography, IconButton, Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import useGet from 'civic-champs-shared/api/hooks/useGetLegacy'
import FeatureSettingsCard from './FeatureSettingsCard'
import GloballyDisabledSwitch from './GloballyDisabledSwitch'
import useTestReminderPrompt from '../hooks/useTestReminderPrompt'

const retiredFeatures = [
  'VolunteerSatisfactionRating',
  'AdminAddActivity',
  'AdminEditRole',
  'Credentials',
  'EventPrivateLink',
  'AdminAddMember',
  'EventShiftTracking',
  'Persons',
  'CheckOutIdleVolunteers',
  'SingleSignOn',
  'Invitations',
  'EventsCalendar',
  'DonationCampaigns',
  'Champions',
  'Tasks',
]

const retiredFiler = (features) => filter(features, (feature) => !retiredFeatures.includes(feature.featureKey))

export default function FeatureSettings() {
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const showTestReminderModal = useTestReminderPrompt()

  const { result: features, loading, refetch: reloadFeatures } = useGet('/settings/features')
  const devFeatures = useMemo(() => filter(retiredFiler(features), { type: 'development' }), [features])
  const salsaIntegration = useMemo(() => find(features, { featureKey: 'SalsaIntegration' }), [features])
  const handleRefresh = useCallback(() => {
    if (loading) return
    reloadFeatures()
  }, [reloadFeatures, loading])
  const cards = (devFeatures || []).map((feature) => <FeatureSettingsCard key={feature.id} feature={feature} />)
  // NOTE: if we want to allow this toggle for for other features, we need to refactor this to be more generic
  if (salsaIntegration) {
    cards.push(
      <FeatureSettingsCard key={salsaIntegration.id} feature={salsaIntegration}>
        {isInternalSuperAdmin && <GloballyDisabledSwitch feature={salsaIntegration} />}
      </FeatureSettingsCard>,
    )
  }
  if (isInternalSuperAdmin) {
    cards.push(
      <Button key={'test-reminder-form'} onClick={showTestReminderModal}>
        Test Reminders
      </Button>,
    )
  }
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Feature Settings
        <IconButton onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Typography>
      {loading ? <CircularProgress /> : orderBy(cards, 'props.feature.featureKey')}
    </div>
  )
}
