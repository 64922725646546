import { useCallback, useEffect } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const STABLE_EMPTY_ARRAY = []

const useListCampaigns = () => {
  const [request, { result: campaigns, loading, called, error }] = useFetch()
  const organization = useCurrentOrg()

  const listCampaigns = useCallback(async () => {
    const params = {} //TODO add parameters
    return request({
      url: `/organizations/${organization.id}/campaigns`,
      config: {
        queryStringParameters: params,
      },
    })
  }, [request, organization.id])

  useEffect(() => {
    listCampaigns()
  }, [listCampaigns])

  return {
    campaigns: campaigns || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    error,
    refresh: listCampaigns,
  }
}

export default useListCampaigns
