
import * as React from 'react';
import createSvgIcon from './createSvgIcon';

/**
 * @ignore - internal component.
 */
export default createSvgIcon(
    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />,
    'FirstPage',
);