import { GET_PRODUCT_FEATURES_FOR_ORG, GET_PRODUCT_FEATURES_FOR_ORG_SUCCEEDED, GET_PRODUCT_FEATURES_FOR_ORG_FAILED, UPDATE_PRODUCT_FEATURE } from "../actions";

export const STORE_KEY = 'productFeatures';

//TODO for simplicity right now we'll support a single organization...
export const defaultState = {
    initialized: false, //volatile
    loading: false,     //volatile
    error: null,        //volatile
    organization: null,
    settings: {}
}

export default function reducer(state = defaultState, action) {
    const { organization, features, error } = action.payload || {}
    switch (action.type) {
        case GET_PRODUCT_FEATURES_FOR_ORG:
            return {
                ...state,
                loading: true,
                error: null,
                organization,
                lastUpdatedAt: null,
                settings: {}
            }

        case GET_PRODUCT_FEATURES_FOR_ORG_SUCCEEDED:
            return {
                initialized: true,
                loading: false,
                organization,
                lastUpdatedAt: new Date(),
                settings: features,
                error: null
            }

        case GET_PRODUCT_FEATURES_FOR_ORG_FAILED:
            return {
                initialized: true,
                loading: false,
                error,
                organization,
                lastUpdatedAt: new Date(),
                settings: {}
            }

        case UPDATE_PRODUCT_FEATURE:
            const { feature, enabled } = action.payload
            return {
                ...state,
                settings: {
                    ...state.settings,
                    [feature.key]: {
                        ...feature,
                        type: 'product',
                        enabled: !!enabled
                    }
                }
            }

        default:
            return state;
    }
}
