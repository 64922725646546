import { map, filter } from 'lodash'
import React, { useMemo } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'

import Loading from 'components/Loading'
import PagedTable from 'core/table/components/PagedTable'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import CampaignDonationSummary from './CampaignDonationSummary'
import CampaignEventsSummary from './CampaignEventsSummary'
import useCampaignEventsTable from '../hooks/useCampaignEventsTable'
import useDonationsTable from '../hooks/useDonationsTable'
import useCampaignDonations from '../hooks/useCampaignDonations'
import useCombinedActivitiesAndEvents from '../hooks/useCombinedActivitiesAndEvents'
import { useDateRangeFilter } from 'filtering/hooks'
import { CampaignEventType } from '../interface'
import moment from 'moment'

export default function CampaignDonationTable(props) {
  const { campaign, campaignEventType, showMoreOrLess } = props
  const { donations, loading: donationsLoading } = useCampaignDonations(campaign)
  const [dateRange, setDateRange] = useDateRangeFilter()
  const { combinedActivitiesAndEvents, loading: eventsLoading } = useCombinedActivitiesAndEvents(campaign)
  const eventsTable = useCampaignEventsTable({
    combinedActivitiesAndEvents,
    dateRange,
    campaignEventType,
  })
  const donationsTable = useDonationsTable({
    donations,
    dateRange,
  })
  const filteredDonations = useMemo(() => map(donationsTable.rows, r => r.original), [donationsTable.rows])
  // we cannot just use the rows of the events table, as those get filtered depending on which stage of the conversion metrics is selected
  // instead, we want to have a summary of all events within the selected date range
  const filteredCombinedActivitiesAndEvents = useMemo(
    () =>
      filter(combinedActivitiesAndEvents, activity => {
        const value = moment(activity.occurredAt)
        return value.isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]')
      }),
    [combinedActivitiesAndEvents, dateRange],
  )

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    donationsTable.setFilter('createdAt', newRange)
    eventsTable.setFilter('occurredAt', newRange)
  }

  const loading = !donations || !combinedActivitiesAndEvents || donationsLoading || eventsLoading
  const showEventsTable = campaignEventType && campaignEventType !== CampaignEventType.DONATED

  return (
    <Grid item xs={12} container direction="column" spacing={2}>
      <Grid item>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">Donations</Typography>
          <DateRangeFilter initialValues={dateRange} onFilterApplied={handleDateRangeChange} />
        </Box>
      </Grid>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid item>
            <CampaignDonationSummary
              donations={filteredDonations}
              combinedActivitiesAndEvents={filteredCombinedActivitiesAndEvents}
              showMoreOrLess={showMoreOrLess}
              showMore={!!campaignEventType}
            />
          </Grid>
          {campaignEventType && (
            <Grid item>
              <CampaignEventsSummary
                donations={filteredDonations}
                combinedActivitiesAndEvents={filteredCombinedActivitiesAndEvents}
                campaign={campaign}
                campaignEventType={campaignEventType}
              />
            </Grid>
          )}
          <Grid item>{showEventsTable ? <PagedTable {...eventsTable} /> : <PagedTable {...donationsTable} />}</Grid>
        </>
      )}
    </Grid>
  )
}
