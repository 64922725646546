import React, { useCallback } from 'react'
import { Popper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SettingsIcon from '@material-ui/icons/Settings'
import { useNotificationPopperStyles } from 'notification/hooks'
import cn from 'classnames'
import { NotificationBlock } from 'notification/components/NotificationBlock'
import {
  Notification,
  NotificationSettingsPayload,
  NotificationType,
  SYSTEM_NOTIFICATIONS,
} from 'notification/interfaces'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { NotificationSettingsDialog } from 'notification/components/NotificationSettingsDialog'
import { useCurrentProfile } from 'auth/hooks'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import useSetNotificationSettings from '../hooks/useSetNotificationSettings'

export interface NotificationPopperProps {
  open: boolean
  onClose: () => void
  anchorEl: any
  notifications: Notification[]
  onDeleteAll: () => Promise<void>
  onDelete: (id: number) => Promise<void>
}

export const NotificationPopper = ({
  open,
  onClose,
  anchorEl,
  notifications,
  onDeleteAll,
  onDelete,
}: NotificationPopperProps) => {
  const [setNotificationSettings] = useSetNotificationSettings()
  const showPrompt = useShowPrompt(NotificationSettingsDialog)
  const enableNotifications = useCallback(async () => {
    const payload = {
      enabled: true,
      settings: Object.values(NotificationType).reduce(
        (acc: any, type) => {
          acc[type] = true
          return acc
        },
        {
          [SYSTEM_NOTIFICATIONS]: true,
        },
      ),
    } as NotificationSettingsPayload
    await setNotificationSettings(payload)
    await showPrompt()
  }, [setNotificationSettings, showPrompt])
  const currentProfile = useCurrentProfile()
  const classes = useNotificationPopperStyles()
  return (
    <Popper id="notification-popper" open={open} anchorEl={anchorEl} className={classes.popper}>
      <div className={classes.paper}>
        <div className={classes.header}>
          <h2>Notifications</h2> <CloseIcon className={classes.close} onClick={onClose} />
        </div>
        {currentProfile?.notificationSettings?.enabled ? (
          <>
            <div className={classes.manage}>
              <div className={cn(classes.action, { [classes.inactive]: !notifications.length })} onClick={onDeleteAll}>
                <span>Clear All</span>
              </div>
              <div className={classes.action} onClick={() => showPrompt()}>
                <SettingsIcon /> <span>Manage Notifications</span>
              </div>
            </div>
            <div className={classes.notifications}>
              {notifications.map(notification => (
                <NotificationBlock key={notification.id} notification={notification} onDelete={onDelete} />
              ))}

              <div className={classes.empty}>{notifications.length ? 'End' : 'You’re all caught up!'}</div>
            </div>
          </>
        ) : (
          <div className={classes.disabled}>
            <span>You currently have notifications turned off. Click enable to turn them on!</span>
            <ContainedButton startIcon={<SettingsIcon />} onClick={() => enableNotifications()}>
              Enable
            </ContainedButton>
          </div>
        )}
      </div>
    </Popper>
  )
}
