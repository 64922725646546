import React from 'react'
import { fieldToSwitch, SwitchProps } from 'formik-material-ui'
import { CustomSwitchComponent, StylesProps } from './CustomSwitchComponent'

interface Props extends SwitchProps, StylesProps {
  tooltip?: string
}
export function CustomSwitch({ thumbColor, trackColor, tooltip, ...props }: Props): JSX.Element {
  return (
    <CustomSwitchComponent
      {...fieldToSwitch(props)}
      thumbColor={thumbColor}
      trackColor={trackColor}
      tooltip={tooltip}
    />
  )
}

export default CustomSwitch
