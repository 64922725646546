import React, { useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useFetch } from 'civic-champs-shared/api/hooks'
import AddUserFlow from 'users/components/AddUserFlow';
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';
import cn from 'classnames';

export default function AddGroupMemberPrompt(props) {
    const classes = usePromptStyles();
    const { showing, close, cancel, complete, groupId } = props;

    const [request, { loading: submitting, error, result }] = useFetch()
    const handleSubmit = useCallback( event => {
        request({
            method: 'post',
            url: `/groups/${groupId}/members`,
            config: {
                body: event
            }
        })
    }, [groupId, request])

    const handleDone = () => complete(result)

    return (
      <Dialog
        fullScreen
        className={cn({ fullScreen: true })}
        open={showing}
        onClose={close}
        disableBackdropClick={true}
      >
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title}>Add Member</Typography>
          <IconButton disabled={submitting} onClick={close} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AddUserFlow
            userLabel="Member"
            onSubmit={handleSubmit}
            submitting={submitting}
            error={error}
            onCancel={cancel}
            onDone={handleDone}
          />
        </DialogContent>
      </Dialog>
    )
}
