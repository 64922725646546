import React from 'react'
import { CredentialWithAssociatedEntity } from '../types'
import { Grid, Typography } from '@material-ui/core'
import classNames from 'classnames'
import moment from 'moment'

type WaiverProps = {
  waiver: CredentialWithAssociatedEntity
}

type WaiversProps = {
  waivers: CredentialWithAssociatedEntity[]
  className?: string
}

const WaiverStatus = ({ waiver }: WaiverProps) => {
  let waiverText = `Good`
  let color = 'black'

  if (waiver.credential.expirationDate) {
    const expiration = moment(waiver.credential.expirationDate)
    waiverText = `Good (expires ${expiration.format('MM/DD/YYYY')})`

    if (moment().isAfter(expiration)) {
      waiverText = `Expired (expired ${expiration.format('MM/DD/YYYY')})`
      color = '#ee875b'
    }
  }

  if (waiver.expiredAt) {
    const expiration = moment(waiver.expiredAt)
    waiverText = `Good (expires ${expiration.format('MM/DD/YYYY')})`
    color = 'black'
    if (moment().isAfter(expiration)) {
      waiverText = `Expired (expired ${expiration.format('MM/DD/YYYY')})`
      color = '#ee875b'
    }
  }

  if (!waiver.signedAt) {
    waiverText = 'Needs Reviewed'
    color = '#5c8de8'
  }
  return (
    <Typography variant="body1" style={{ color: color, margin: '10px' }}>
      {waiver.version.title} {waiverText}
    </Typography>
  )
}

const Waivers = ({ waivers, className }: WaiversProps) => {
  const classes = classNames({ [`${className}`]: className })
  return (
    <Grid container direction="column" className={classes}>
      {waivers.map(waiver => (
        <Grid item>
          <WaiverStatus waiver={waiver} />
        </Grid>
      ))}
    </Grid>
  )
}

export default Waivers
