export const SYSTEM_NOTIFICATIONS = 'SYSTEM_NOTIFICATIONS'

export enum NotificationType {
  VOLUNTEER_CREATED = 'VOLUNTEER_CREATED',
  GROUP_APPLICATION = 'GROUP_APPLICATION',
  EVENT_REGISTRATION = 'EVENT_REGISTRATION',
  EVENT_DEREGISTRATION = 'EVENT_DEREGISTRATION',
  IMPORT_FINISHED = 'IMPORT_FINISHED',
  VOLUNTEER_IMPORT_FINISHED = 'VOLUNTEER_IMPORT_FINISHED',
  VOLUNTEER_IMPORT_PROGRESS = 'VOLUNTEER_IMPORT_PROGRESS',
}

export interface Notification {
  id: number
  organizationId: number
  title: string
  body: string
  type: NotificationType
  info: { [key: string]: any } | null
  createdAt: Date
  readAt: Date | null
}

enum SystemNotificationType {
  SYSTEM_NOTIFICATIONS = 'SYSTEM_NOTIFICATIONS',
}
type NotificationTypes = NotificationType | SystemNotificationType

export interface NotificationSettingsPayload {
  enabled: boolean
  settings: {
    [key in NotificationTypes]: boolean
  }
}

export interface NotificationSettings extends NotificationSettingsPayload {
  id: number
  organizationId: number
}

export interface NotificationApiResponse {
  id: number
  organizationId: number
  title: string
  body: string
  type: NotificationType
  info: { [key: string]: any }
  createdAt: string
  readAt: string
}

export interface NotificationBulkPayload {
  read?: number[]
  delete?: number[]
}
