import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import { isUndefined, omitBy } from 'lodash'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useGetOpportunityTemplates = (
  isSchedulable: boolean,
): [(strictOrder?: boolean, isActive?: boolean) => Promise<OpportunityTemplate[]>, Status<OpportunityTemplate[]>] => {
  const org = useCurrentOrg()

  const [request, status] = useFetchRefactored<OpportunityTemplate[]>({
    errorMessage: 'Error Fetching Templates.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchOpportunityTemplates = useCallback(
    (strictOrder = false, isActive?: boolean) => {
      return request({
        method: 'get',
        url: `/organizations/${org.id}/opportunity_templates`,
        config: {
          queryStringParameters: omitBy({ isSchedulable, isActive, strictOrder }, isUndefined),
        },
      })
    },
    [isSchedulable, org.id, request],
  )

  return [fetchOpportunityTemplates, status]
}

export default useGetOpportunityTemplates
