import React from 'react';
import {Chip, makeStyles, Tab, Tabs} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import UserCredentialIDs from "./UserCredentialIDs";
import {AddButton} from "../../civic-champs-shared/core/add-button";
import useUserCredentialIDsCollection from "../hooks/useUserCredentialIDsCollection";
import Loading from 'components/Loading'

const useStyles = makeStyles(theme => ({
  actionMenu: {
    margin: '16px 0',
  },
  tabs: {
    backgroundColor: 'inherit !important',
    border: 'none',
    // get rid of underline
    '& > div > span': {
      display: 'none',
    },
    '& button:first-child': {
      width: '100%',
      maxWidth: `calc(100% - 750px)`,
      alignItems: 'flex-start',
    },
  },
  panel: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderTop: 'none',
    minHeight: 860,
    padding: theme.spacing(2),
  },
  addButton: {
    margin: "0 15px"
  },
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const StyledTab = withStyles({
  root: {
    backgroundColor: '#EEF3FD',
    borderRadius: '4px 4px 0 0',
    border: '1px solid #5C8DE8',
    fontSize: '1.2em',
    textTransform: 'none',
    color: '#3d3d3d',
  },
  wrapper: {
    alignItems: 'flex-start',
  },
  selected: {
    background: 'white',
    borderBottom: 0,
    color: 'inherit !important',
  },
})(Tab)

const StyledChip = withStyles({
  root: {
    backgroundColor: "#ff6f3e",
    color: "white",
    margin: "0 5px",
    fontWeight: 700
  }
})(Chip)

const CredentialIDTabs = (props) => {

  const [currentTab, setCurrentTab] = React.useState(0)
  const handleTabChange = (event, tabIndex) => setCurrentTab(tabIndex)
  const [{userCredentialIds, initiallyLoaded}, {
    addUserCredential,
    refresh
  }] = useUserCredentialIDsCollection(props.credentialType)

  const classes = useStyles()

  if (!initiallyLoaded) {
    return <Loading/>
  }

  return (
    <div>

      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <StyledTab label={
          <div style={{display: "flex", alignItems: "center"}}>
            <span>IDs</span>
            {userCredentialIds.length > 0 && <StyledChip label={userCredentialIds.length}/>}
          </div>
        }/>
      </Tabs>

      <TabPanel className={classes.panel} value={currentTab} index={0}>
        <UserCredentialIDs credentialType={props.credentialType} userCredentialIds={userCredentialIds}
                           query={props.query} refresh={refresh}/>
      </TabPanel>

    </div>
  );
};

export default CredentialIDTabs;
