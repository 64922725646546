import { useCallback, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../auth/hooks'

export const useAddSMSCredits = onSuccess => {
  const [request, status] = useFetch('Error adding SMS Credits.  Please refresh the page to try again')
  const showSuccess = useSuccessNotification()
  const currentOrg = useCurrentOrg()
  const addSMSCredits = useCallback(
    smsCredits => {
      return request({
        method: 'post',
        url: `/organizations/${currentOrg.id}/sms-credits`,
        config: {
          body: { smsCredits },
        },
        onSuccess: res => {
          onSuccess && onSuccess(res)
          showSuccess(`Successfully added ${smsCredits}`)
        },
      })
    },
    [request, onSuccess, showSuccess],
  )

  return useMemo(() => [addSMSCredits, status], [addSMSCredits, status])
}
