import { useCallback } from "react"
import { usePrompt } from "civic-champs-shared/core/modal/hooks"
import WaiverContentPrompt from "../pages/WaiverContentPrompt"

const useWaiverContent = (waiver) => {
        const prompt = usePrompt(WaiverContentPrompt);
        
        return useCallback(() => prompt.show({waiver}), [prompt, waiver]);
} 

export default useWaiverContent
