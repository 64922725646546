import { useAccessToken, useCurrentProfile } from 'auth/hooks'
import { useSharedWorker } from 'App/hooks/useSharedWorker'
import { useCallback, useEffect, useState } from 'react'
import { SYSTEM_NOTIFICATIONS } from '../interfaces'
import useGetNotifications from 'notification/hooks/useGetNotifications'
import useBulkOnNotifications from 'notification/hooks/useBulkOnNotifications'
import useDeleteNotification from 'notification/hooks/useDeleteNotification'
import { useNotifications as useNotificationState, useNotificationsInitialized } from 'notification/redux/hooks'
import { useDispatch } from 'react-redux'
import { notificationsInit, notificationUpdate } from 'notification/redux/actions'
import useSetNotificationSettings from 'notification/hooks/useSetNotificationSettings'
import type { Notification } from 'notification/interfaces'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import NotificationPermissionRequestDialog from 'notification/components/NotificationPermissionRequestDialog'
import { useHistory } from 'react-router-dom'
import useGetImportUrl from 'import/hooks/useGetImportUrl'

export interface NotificationHookData {
  notifications: Notification[]
  unread: boolean
  markAllRead: () => Promise<void>
  deleteAll: () => Promise<void>
  deleteOne: (id: number) => Promise<void>
}
export const useNotifications = (): NotificationHookData => {
  const getUrl = useGetImportUrl()
  const profile = useCurrentProfile()
  const { notificationSettings } = profile
  const notificationsInitialized = useNotificationsInitialized()
  const notifications = useNotificationState()
  const dispatch = useDispatch()
  const [setNotificationSettings] = useSetNotificationSettings()
  const [unread, setUnread] = useState<boolean>(false)
  const [fetchNotifications] = useGetNotifications()
  const [sendBulk] = useBulkOnNotifications()
  const [deleteNotification] = useDeleteNotification()
  const showPermissionRequestDialog = useShowPrompt(NotificationPermissionRequestDialog)
  const token = useAccessToken()
  const worker = useSharedWorker()
  const history = useHistory()
  const requestNotificationPermissions = useCallback(async () => {
    if (
      'Notification' in window &&
      notificationSettings &&
      notificationSettings.enabled &&
      notificationSettings.settings?.[SYSTEM_NOTIFICATIONS] &&
      Notification.permission === 'default'
    ) {
      const allowed = await showPermissionRequestDialog()
      const permission = allowed ? await Notification.requestPermission() : 'denied'
      if (permission === 'denied') {
        await setNotificationSettings({
          ...notificationSettings,
          settings: {
            ...notificationSettings.settings,
            [SYSTEM_NOTIFICATIONS]: false,
          },
        })
      }
    }
  }, [notificationSettings, setNotificationSettings, showPermissionRequestDialog])

  const markAllRead = useCallback(async () => {
    const unreadIds = notifications
      .filter(({ readAt }) => {
        return !readAt
      })
      .map(({ id }) => id)
    if (unreadIds.length) {
      try {
        // Error handling is done in the sendBulk hook
        await sendBulk({ read: unreadIds })
        dispatch(notificationUpdate(notifications.map(notification => ({ ...notification, readAt: new Date() }))))
      } catch (e) {}
    }
  }, [dispatch, notifications, sendBulk])

  const deleteAll = useCallback(async () => {
    try {
      // Error handling is done in the sendBulk hook
      await sendBulk({ delete: notifications.map(({ id }) => id) })
      dispatch(notificationUpdate([]))
    } catch (e) {}
  }, [dispatch, notifications, sendBulk])

  const deleteOne = useCallback(
    async (id: number) => {
      try {
        // Error handling is done in the deleteNotification hook
        await deleteNotification(id)
        dispatch(notificationUpdate(notifications.filter(notification => notification.id !== id)))
      } catch (e) {}
    },
    [deleteNotification, dispatch, notifications],
  )

  useEffect(() => {
    if (!notificationsInitialized) {
      fetchNotifications().then(existingNotifications => dispatch(notificationsInit(existingNotifications)))
      requestNotificationPermissions()
    }
  }, [notificationsInitialized]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setUnread(notifications.some(({ readAt }) => !readAt))
  }, [notifications])

  useEffect(() => {
    if (token && worker && notificationSettings?.enabled) {
      worker.postMessage({
        event: 'connect',
        token,
        url: process.env.REACT_APP_API_ENDPOINT?.replace('http', 'ws'),
        notificationsEnabled: notificationSettings?.settings?.[SYSTEM_NOTIFICATIONS],
      })

      window.addEventListener('beforeunload', () => worker?.postMessage({ event: 'disconnect' }))
      worker.addEventListener('message', async ({ data: { action, data } }) => {
        switch (action) {
          case 'event':
            dispatch(notificationUpdate([data, ...notifications]))
            break
          case 'navigate':
            window.focus()
            if (/^http/.test(data.url)) {
              window.location.href = data.url
            } else {
              history.push(data.url)
            }
            break
          case 'import_result':
            window.location.href = await getUrl(data.importId)
            break
          default:
        }
      })
    } else if (worker && !notificationSettings?.enabled) {
      worker.postMessage({
        event: 'stop',
        token,
        url: process.env.REACT_APP_API_ENDPOINT?.replace('http', 'ws'),
      })
    }
  }, [dispatch, getUrl, history, notificationSettings, notifications, token, worker])

  useEffect(() => {
    if (worker && notificationSettings?.enabled) {
      worker.postMessage({
        event: 'changeNotifications',
        notificationsEnabled: notificationSettings?.settings?.[SYSTEM_NOTIFICATIONS],
      })
    }
  }, [notificationSettings, worker])

  return { notifications, unread, markAllRead, deleteAll, deleteOne }
}
