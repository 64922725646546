import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect } from 'react'

const STABLE_EMPTY_OBJECT = {}
const useTask = taskId => {
  const [request, { result: task, loading }] = useFetch()

  const getTask = useCallback(async () => {
    return request({
      method: 'get',
      url: `/task/${taskId}`,
    })
  }, [taskId, request])

  useEffect(() => {
    getTask()
  }, [getTask])

  return {
    task: task || STABLE_EMPTY_OBJECT,
    loading,
    refresh: getTask,
  }
}

export default useTask
