import React from 'react'
import { Dialog, DialogContent, Typography, IconButton } from '@material-ui/core'
import Loading from 'components/Loading'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { Close } from '@material-ui/icons'
import _ from 'lodash'
import OrganizationForm from './OrganizationForm'
import useCreateOrUpdateOrganization from '../hooks/useCreateOrUpdateOrganization'
import { CreateOrUpdateOrganizationParam } from '../models'
import useOrganization from '../hooks/useOrganization';
import { useMemo } from 'react';
import { useIsTester } from '../../../auth/hooks'

interface Props {
  showing: boolean,
  close: any,
  cancel: any,
  complete: any,
  organizationId?: number,
}

const CreateOrUpdateOrganizationPrompt = (props: Props) => {
  const { showing, close, complete, organizationId } = props
  const { result, loading, called } = useOrganization(props.organizationId)
  const { isTester, loading: testerLoading } = useIsTester()

  const organization = useMemo(() => {
    if (_.isEmpty(result)) return undefined
    const c = _.clone(result)
    // we save coords as [X,Y] in the db
    _.set(c, ['address', 'gps'], {
      lat: _.get(result, ['location', 'coordinates', 1]),
      lng: _.get(result, ['location', 'coordinates', 0]),
    })
    return c as CreateOrUpdateOrganizationParam
  }, [result])

  const promptClasses = usePromptStyles()
  const isUpdate = organizationId!=null? true: false
  const createOrUpdateOrganization = useCreateOrUpdateOrganization()

  const onSubmit = async (values: CreateOrUpdateOrganizationParam) => {
    await createOrUpdateOrganization(values, organizationId)
    complete()
  }

  if (!!props.organizationId && (loading || !called || testerLoading)) {
    return <Loading />
  }

  return <Dialog fullScreen open={showing} >
    <div className={promptClasses.titleContainer} style={{margin: '20px 0'}}>
      <Typography align='center' variant='h3'>{ isUpdate ? 'Edit Organization' : 'Add a New Organization' }
        <IconButton onClick={close} className={promptClasses.closeButton}>
          <Close />
        </IconButton>
      </Typography>
    </div>
    <DialogContent>
      <OrganizationForm
        justify={"center"}
        onSubmit={onSubmit}
        organization={organization}
        isTester={isTester}
      />
    </DialogContent>
  </Dialog>
}

export default CreateOrUpdateOrganizationPrompt
