import { useCallback } from 'react'
import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { EventPayload } from 'Event/helpers/add-edit-helpers'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'

export const useCreateSchedulableOpportunity = (): [
  (body: EventPayload, isDraft?: boolean) => Promise<OpportunityResponseWithRecurrenceInfo>,
  Status<OpportunityResponseWithRecurrenceInfo>,
] => {
  const org = useCurrentOrg()
  const [request, status] = useFetchRefactored<OpportunityResponseWithRecurrenceInfo>()

  const createSchedulableOpportunity = useCallback(
    (body: EventPayload, isDraft: boolean = false) => {
      return request({
        method: 'post',
        url: `/organizations/${org.id}/opportunities/${isDraft ? 'draft' : 'publish'}`,
        config: {
          body,
        },
      })
    },
    [org.id, request],
  )

  return [createSchedulableOpportunity, status]
}

export default useCreateSchedulableOpportunity
