import React, { useMemo } from 'react'

export const GroupType = {
  Volunteer: 'volunteer',
  Member: 'member',
  UserDefined: 'user-defined',
  TaskRequester: 'task-requester',
  TaskRecipient: 'task-recipient',
  TaskVolunteer: 'task-volunteer',
  Mentee: 'mentee',
}

export const useGroupLink = group => {
  return useMemo(() => {
    if (!group) {
      return null
    }
    const { groupType } = group
    const { code } = groupType
    if (code === GroupType.Volunteer) {
      return { link: '/volunteers', text: 'Back to Volunteers' }
    } else if (code === GroupType.Member) {
      return { link: '/team/members', text: 'Back to Admins' }
    } else if ([GroupType.TaskRequester, GroupType.TaskRecipient, GroupType.TaskVolunteer].includes(code)) {
      return { link: '/helping-hands', text: 'Back to Helping Hands' }
    }
    return { link: `/groups/${group.id}`, text: `Back to ${group.name}` }
  }, [group])
}
