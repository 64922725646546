import React from 'react'
import { useEditQuestionSet, useExistingQuestions } from 'civic-champs-shared/question-sets/hooks'
import QuestionSetCreation from '../components/question-set-creation'
import BackLink from '../../donation/campaigns/components/BackLink'

export const QuestionSetNew = props => {
  const { existingQuestions, loading: existingQuestionsLoading } = useExistingQuestions()
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet(updatedQuestionSet =>
    props.history.push(`/questionnaires/${updatedQuestionSet.questionSetId}`),
  )

  const loading = existingQuestionsLoading || editLoading

  return (
    <>
      <BackLink to={'/questionnaires/'} text={'Back to questionnaires'} />
      <QuestionSetCreation loading={loading} onSubmit={editQuestionSet} existingQuestions={existingQuestions} />
    </>
  )
}
