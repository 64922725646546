import React  from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createDateValueSort } from 'components/table/sort';
import { useDateRangeFilter } from 'filtering/hooks';
import { useTableSearch } from 'components/table/hooks';
import useOpportunitiesCollection from '../hooks/useOpportunitiesCollection';
import { FixedPointCell, DateTimeCell, IntegerCell } from 'core/table/cells';
import DateRangeFilter from 'components/filter/DateRangeFilter';
import TableToolbar from 'components/table/TableToolbarLight';
import DataTable from 'components/DataTable';
import OpportunitySummaryCards from './OpportunitySummaryCards';
import OpportunityActionMenu from './OpportunityActionMenu';
import Loading from 'components/Loading';
import useCreateOrUpdateOpportunityPrompt from '../hooks/useCreateOrUpdateOpportunityPrompt'
import RequireRole from 'auth/components/RequireRole'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import Search from 'core/table/components/Search'
import { AddButton } from 'civic-champs-shared/core/add-button'

const useStyles = makeStyles(theme => ({
  cardsWrapper: {
    padding: '3em 0',
  },
  root: {
    flexGrow: 1
  },
}));

const columns = [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Event Start',
    accessor: 'startsAt',
    Cell: DateTimeCell,
    sortType: createDateValueSort('startsAt')
  },
  {
    Header: 'Event End',
    accessor: 'endsAt',
    Cell: DateTimeCell,
    sortType: createDateValueSort('endsAt')
  },
  {
    Header: 'Hours',
    accessor: 'totalVolunteerHours',
    Cell: FixedPointCell,
    sortType: 'basic'
  },
  {
    Header: 'Check-ins',
    accessor: 'totalCheckIns',
    Cell: IntegerCell
  }
];

const searchConfig = {
  fields: ['name'],
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true
  }
}

function Opportunities() {
  const classes = useStyles();

  const [dateRange, setDateRange] = useDateRangeFilter();
  const [{ opportunities, loading, selected }, { reload, setSelected }] = useOpportunitiesCollection(dateRange)
  const { query, setQuery, results: filteredOpportunities } = useTableSearch(opportunities, searchConfig)

  const reloadWithFilters = () => { reload(dateRange) }
  const onCreateOrUpdateOpportunity = useCreateOrUpdateOpportunityPrompt(reloadWithFilters)

  return (
    <div>
      <Grid container>
        <Grid container xs={12} item alignItems='center' justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Typography gutterBottom variant="h4">
              Opportunities
            </Typography>
            <RequireRole role={INTERNAL_ADMIN}>
              <Grid item style={{ marginLeft: 10 }}>
                <AddButton onClick={onCreateOrUpdateOpportunity} />
              </Grid>
            </RequireRole>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
            </Grid>
            <Grid item style={{marginLeft: '10px'}}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={setDateRange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading || (opportunities || []).length ? (
        <>
          <div className={classes.root}>
            <Hidden only="sm">
              <OpportunitySummaryCards opportunities={filteredOpportunities} />
            </Hidden>
          </div>
          <div>
            <TableToolbar
              query={query}
              onQueryChange={setQuery}
              actionMenu={(
                <OpportunityActionMenu
                  opportunities={filteredOpportunities}
                  selected={selected}
                  onUpdateOpportunity={onCreateOrUpdateOpportunity}
                />
              )}
            />
            <DataTable
              columns={columns}
              data={filteredOpportunities}
              onSelectedChanged={setSelected}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Opportunities;
