import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { Field, Form, Formik } from 'formik'
import CustomSwitch from 'civic-champs-shared/formik/components/CustomSwitch'
import { useCurrentProfile } from 'auth/hooks'
import cn from 'classnames'
import type { NotificationSettingsPayload } from 'notification/interfaces'
import { NotificationType, SYSTEM_NOTIFICATIONS } from 'notification/interfaces'
import get from 'lodash/get'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { useNotificationSettingsStyles } from 'notification/hooks'
import useSetNotificationSettings from 'notification/hooks/useSetNotificationSettings'
import { useHasRole } from 'civic-champs-shared/auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

const Switch = ({
  name,
  values,
  bigger,
  disabled,
}: {
  name: string
  values: { [key: string]: any }
  bigger?: boolean
  disabled?: boolean
}) => {
  const classes = useNotificationSettingsStyles()
  const enabled = get(values, name)
  return (
    <div className={cn(classes.switch, { [classes.bigger]: bigger })}>
      <Field
        component={CustomSwitch}
        id={name}
        name={name}
        thumbColor="#FFF"
        trackColor={enabled ? '#0F5DB5' : '#8E9192'}
        type="checkbox"
        disabled={disabled}
      />
      <span>{enabled ? ' On' : ' Off'}</span>
    </div>
  )
}

const SwitchRow = ({
  label,
  name,
  values,
  disabled,
}: {
  label: string
  name: string
  values: { [key: string]: any }
  disabled?: boolean
}) => {
  const classes = useNotificationSettingsStyles()
  return (
    <div className={classes.row}>
      <div className={cn(classes.left)}>{label}</div>
      <Switch name={name} values={values} disabled={disabled} />
    </div>
  )
}

export function NotificationSettingsDialog(props: any) {
  const { showing, close } = props
  const classes = useNotificationSettingsStyles()
  const profile = useCurrentProfile()
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const [setNotificationSettings] = useSetNotificationSettings()

  const handleSubmit = async (values: NotificationSettingsPayload) => {
    await setNotificationSettings(values)
    close()
  }

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      onClose={() => close()}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#notification-settings-dialog-title` }}
      aria-labelledby="notification-settings-dialog-title"
      maxWidth="xs"
    >
      <div className={classes.container}>
        <div className={classes.title} id="notification-settings-dialog-title">
          <h2>Notification Settings</h2>
          <CloseIcon className={classes.close} onClick={() => close()} />
        </div>
        <div className={classes.content}>
          <Formik initialValues={profile.notificationSettings} onSubmit={handleSubmit}>
            {({ submitForm, isSubmitting, values }) => (
              <Form>
                <div className={classes.general}>
                  <span className={classes.bold}>Notifications Enabled</span>
                  <Switch name="enabled" values={values} bigger disabled={isSubmitting} />
                </div>
                <div className={classes.selectors}>
                  <div>
                    <span className={cn(classes.left, classes.header)}>Type</span>
                    <span className={classes.header}>Status</span>
                  </div>
                  <div className={classes.separator} />
                  <SwitchRow
                    label="New Volunteers"
                    name={'settings.' + NotificationType.VOLUNTEER_CREATED}
                    values={values}
                    disabled={isSubmitting}
                  />
                  <SwitchRow
                    label="Group Applications"
                    name={'settings.' + NotificationType.GROUP_APPLICATION}
                    values={values}
                    disabled={isSubmitting}
                  />
                  <SwitchRow
                    label="Event Registrations"
                    name={'settings.' + NotificationType.EVENT_REGISTRATION}
                    values={values}
                    disabled={isSubmitting}
                  />
                  <SwitchRow
                    label="Volunteer Cancelations"
                    name={'settings.' + NotificationType.EVENT_DEREGISTRATION}
                    values={values}
                    disabled={isSubmitting}
                  />
                  {isInternalAdmin && (
                    <SwitchRow
                      label="Volunteer Import Progress"
                      name={'settings.' + NotificationType.VOLUNTEER_IMPORT_PROGRESS}
                      values={values}
                      disabled={isSubmitting}
                    />
                  )}
                  <div className={classes.separator} />
                  <SwitchRow
                    label="System Notifications"
                    name={'settings.' + SYSTEM_NOTIFICATIONS}
                    values={values}
                    disabled={isSubmitting}
                  />
                </div>
                <div className={classes.buttons}>
                  <ContainedButton onClick={submitForm} disabled={isSubmitting}>
                    Save
                  </ContainedButton>
                  <OutlinedButton onClick={close} borderless disabled={isSubmitting}>
                    Cancel
                  </OutlinedButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Dialog>
  )
}
