import React from 'react'
import { PersonInfo } from './PersonInfo'

interface PersonAutocompleteOptionProps {
  option?: any
}

export const PersonAutocompleteOption: React.FC<PersonAutocompleteOptionProps> = props => {
  const { option } = props

  return (
    <div>
      <PersonInfo person={option} inline />
    </div>
  )
}
