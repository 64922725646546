import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OpportunityTemplatePersonGroup } from 'volunteering/opportunities/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useGetOpportunityTemplateGroups = (): [
  (opportunityId: number) => Promise<OpportunityTemplatePersonGroup[]>,
  Status<OpportunityTemplatePersonGroup[]>,
] => {
  const org = useCurrentOrg()
  const [request, status] = useFetchRefactored<OpportunityTemplatePersonGroup[]>({
    errorMessage: 'Error Fetching Template Groups',
    emptyValue: [],
  })

  const fetchOpportunityTemplateGroups = useCallback(
    (opportunityId: number) => {
      return request({
        method: 'get',
        url: `/organizations/${org.id}/opportunity_templates/${opportunityId}/groups`,
      })
    },
    [org.id, request],
  )

  return [fetchOpportunityTemplateGroups, status]
}

export default useGetOpportunityTemplateGroups
