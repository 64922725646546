import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'

export const useMergeAccounts = onResultUpdate => {
  const [request, { loading, error, result }] = useFetch(
    'Error merging user accounts.  Please refresh the page and try again',
  )
  const showSuccess = useSuccessNotification()
  const confirm = useConfirm()

  const mergeAccounts = useCallback(
    async (replacingPerson, replacedPerson) => {
      const confirmed = await confirm(
        `Are you sure you want to merge ${replacedPerson.givenName} ${replacedPerson.familyName} (${
          replacedPerson.email || replacedPerson.phoneNumber
        })'s account into ${replacingPerson.givenName} ${replacingPerson.familyName} (${
          replacingPerson.email
        })'s account?`,
        {
          title: `Merge Accounts`,
          confirmText: `Merge`,
          rejectText: 'Cancel',
          subText: `Clicking the button 'Merge' will permanently delete ${replacedPerson.givenName} ${
            replacedPerson.familyName
          } (${replacedPerson.email || replacedPerson.phoneNumber})'s account.`,
        },
      )
      if (!confirmed) {
        return
      }
      return request({
        onSuccess: result => {
          showSuccess('Successfully merged accounts')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: '/persons/merges',
        config: { body: { replacingPersonId: replacingPerson.id, replacedPersonId: replacedPerson.id } },
      })
    },
    [request],
  )

  return useMemo(() => [mergeAccounts, { loading, error, person: result }], [mergeAccounts, loading, error, result])
}
