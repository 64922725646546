import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCallback } from 'react'
import { IntegrationProvider } from 'integrations/types'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import usePushActivities from 'tracking/activity/hooks/usePushActivities'
import { useDateRangeFilter } from 'filtering/hooks'
import format from 'civic-champs-shared/utils/format'

const usePushActivitiesDialog = ({
  providerName,
  provider,
}: {
  providerName: string
  provider: IntegrationProvider
}) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const pushActivities = usePushActivities()
  const [{ startDate, endDate }] = useDateRangeFilter()

  return useCallback(async () => {
    const confirmed = await showPrompt({
      text: `Do you want to send activities from ${format.date(startDate)} to ${format.date(
        endDate,
      )} to ${providerName}?`,
      title: 'Confirm Action',
      confirmText: 'Yes',
      cancelText: 'No',
    })

    if (confirmed) {
      await pushActivities({ provider, startDate, endDate })
    }
  }, [showPrompt, startDate, endDate, providerName, pushActivities, provider])
}

export default usePushActivitiesDialog
