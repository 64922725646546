import { useCallback } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'

const useDeleteAffiliatedOpportunity = () => {
  const [request, { result, loading, called }] = useFetch()

  const deleteAffiliatedOpportunity = useCallback(
    async (campaignId, opportunityId) => {
      // TODO: replace this with new `/campaigns/${campaignId}/opportunities/${encodedOccurrence}`
      return request({
        method: 'del',
        url: `/campaigns/${campaignId}/opportunities/${opportunityId}`,
      })
    },
    [request],
  )

  return [
    deleteAffiliatedOpportunity,
    {
      opportunity: result,
      loading: loading || !called,
    },
  ]
}

export default useDeleteAffiliatedOpportunity
