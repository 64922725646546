import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
  root: {
    padding: '2px 9px',
    display: 'flex',
    alignItems: 'center',
    margin: '0 5px 25px',
    borderRadius: 25,
  },
  input: {
    marginLeft: 16,
    flex: 1,
  },
  checkIn: {
    width: '100%',
    height: 50,
    borderRadius: 25,
    background: '#5C8DE8',
  },
  subTitle: {
    fontSize: '1.3125rem',
  },
  containerWrapper: {
    //margin: `5% auto`,
    color: '#43425D',
  },
  containerHeader: {
    minHeight: '180px',
    marginTop: '15px',
  },
  bottomMenu: {
    position: 'fixed',
    bottom: 0,
  },
  bottomCheckIn: {
    position: 'fixed',
    bottom: 200,
    background: 'white',
  },
  actionButton: {
    margin: '-30px 60px 0',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#5C8DE8',
  },
  bottom: {
    marginBottom: '40px',
    float: 'right',
  },
  checkInColor: {
    backgroundColor: '#5C8DE8',
  },
  checkOutColor: {
    backgroundColor: '#FF6F3E',
  },
  logo: {
    marginTop: 0,
  },
  diologWrapper: {
    background: '#F2F2F2',
  },
  signUpButton: {
    margin: '20px 60px 0',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: '#5C8DE8',
  },
}))
