export const MATCHES_LIST_REQUEST = 'MATCHES_LIST_REQUEST'
export const MATCHES_LIST_RECEIVE = 'MATCHES_LIST_RECEIVE'
export const MATCHES_LIST_FAILURE = 'MATCHES_LIST_FAILURE'
export const MATCHES_LIST_CLEAR = 'MATCHES_LIST_CLEAR'

export const MATCH_REQUEST = 'MATCH_REQUEST'
export const MATCH_RECEIVE = 'MATCH_RECEIVE'
export const MATCH_FAILURE = 'MATCH_FAILURE'
export const MATCH_CLEAR = 'MATCH_CLEAR'

export const USER_LOADING = 'USER_LOADING'

export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS'
export const CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE'

export const GET_CURRENT_USER_INFO_SUCCESS = 'GET_CURRENT_USER_INFO_SUCCESS'
export const GET_CURRENT_USER_INFO_FAILURE = 'GET_CURRENT_USER_INFO_FAILURE'

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
