import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Grid } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import  CloseIcon  from '@material-ui/icons/Close';
import ChangePassword from './ChangePasswordForm'

export default function ChangePasswordPrompt(props) {
  const { showing, close, complete } = props
  const classes = usePromptStyles()

  return (
    <Dialog
      className='full-screen'
      open={showing}
      onClose={close}
    >
      <DialogTitle className={classes.titleContainer}>
        <Grid container direction='row' justify='space-between'>
          <Grid>
            <IconButton
              onClick={close}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <ChangePassword changeUserPassword={complete} closePopup={close}/>
      </DialogContent>
    </Dialog>
  )
}
