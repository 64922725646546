import React from 'react'
import { ExpansionPanel } from '@material-ui/core'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'

export const EventExpansionPanel = ({
  children,
  onChange,
}: {
  children: React.ReactNode
  onChange?: (event: React.ChangeEvent<{}>, expanded: boolean) => void
}) => {
  const classes = useEventDrilldownStyles()
  return (
    <ExpansionPanel onChange={onChange} classes={{ root: classes.expansionRoot }} square={true} defaultExpanded={true}>
      {children}
    </ExpansionPanel>
  )
}
