import useGetOnMount from '../../../api/hooks/useGetOnMount'
import { useCurrentOrg } from '../../../auth/hooks'
import { UploadedFile } from '../interface'
import { Refresh, Status } from '../../../api/hooks/fetchRefactoredSchema'

interface FilesResponse { files: UploadedFile[] }

export default function useOrgFiles(): [Refresh<FilesResponse>, Status<FilesResponse>] {
  const currentOrg = useCurrentOrg()
  const [refresh, status] = useGetOnMount<FilesResponse>({
    url: `/organizations/${currentOrg.id}/files`,
    errorMessage: 'Error fetching files',
    emptyValue: { files: [] },
  })
  return [refresh, status]
}
