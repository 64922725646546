import { makeStyles } from '@material-ui/styles'

export const useNotificationPopperStyles = makeStyles((theme: any) => ({
  popper: {
    zIndex: 1,
  },
  paper: {
    width: '250px',
    backgroundColor: 'white',
    padding: '10px 10px 5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& h2': {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      margin: 0,
    },
  },
  close: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  manage: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '10px,',
  },
  action: {
    display: 'flex',
    cursor: 'pointer',
    '& > svg': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      marginRight: '6px',
    },
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '8px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: theme.palette.primary.main,
    },
  },
  inactive: {
    opacity: 0,
    cursor: 'default',
  },
  notifications: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '260px',
    overflowY: 'auto',
    borderTop: '1px solid #EEF0FA',
    width: '100%',
    marginTop: '5px',
  },
  empty: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000',
    textAlign: 'center',
    marginTop: '5px',
    justifySelf: 'center',
  },
  disabled: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    flexDirection: 'column',
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
      margin: '10px',
    },
  },
}))
