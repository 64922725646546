import * as _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import Button from '../../../../../components/button'
import StaticGoogleMap from '../../../../components/static-google-map'
import './index.scss'
import format from 'civic-champs-shared/utils/format'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import { useFetchQuestionSets } from 'civic-champs-shared/question-sets/hooks'
import Loading from 'civic-champs-shared/core/Loading'
import { getVisibilityDescription, getVisibilityLabel, OpportunityVisibility } from 'Event/interfaces'

const listVisibilityGroups = groups =>
  _.map(groups, group =>
    !group.closed
      ? group.name
      : group.approvedMembersOnly
      ? `Approved members of ${group.name}`
      : `Applicants of ${group.name}`,
  ).join(', ')

const ReviewModule = props => {
  const {
    onSaveClick,
    onPrevClick,
    onSaveDraftClick,
    onCancel,
    isSendingDraftRequest,
    isSendingPublicRequest,
    isQuestionnaireAsSurveyEnabled,
    event,
    editMode = false,
  } = props

  const {
    published,
    name,
    startsAt,
    endsAt,
    description,
    instructions,
    timeshifts,
    questionSets,
    geofencing,
    requiredCredentials,
    onboardingGroups,
    isTest,
  } = event

  const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'
  const responseKey = isQuestionnaireAsSurveyEnabled ? 'id' : 'questionSetId'

  const questionSetByQuestionSetId = useMemo(() => _.keyBy(questionSets, key), [key, questionSets])

  const [fetchQuestionSets, { loading, result: questionSetResponses }] = useFetchQuestionSets(
    false,
    isQuestionnaireAsSurveyEnabled,
    true,
  )

  useEffect(() => {
    if (!_.isEmpty(questionSets)) {
      const ids = _.map(questionSets, key)
      fetchQuestionSets({ [isQuestionnaireAsSurveyEnabled ? 'surveyIds' : 'questionSetIds']: ids })
    }
  }, [fetchQuestionSets, isQuestionnaireAsSurveyEnabled, key, questionSets])

  const startDate = moment(startsAt).format('MMMM D, YYYY')
  const endDate = moment(endsAt).format('MMMM D, YYYY')

  const descriptionRef = useRef(null)
  const instructionRef = useRef(null)
  useEffect(() => {
    descriptionRef.current.innerHTML = description
    instructionRef.current.innerHTML = instructions
  }, [description, instructions])
  if (loading) {
    return <Loading />
  }

  return (
    <div className="review-module">
      <h2 className="review-module__title">Review</h2>
      <div className="review-form">
        <div className="review-form-top-info">
          <div className="left-form">
            <h2 className="review-form__title">{name}</h2>
            <span className="review-form__date">
              {startDate} {startDate !== endDate ? `- ${endDate}` : ''}
            </span>
            <div className="review-form__description" ref={descriptionRef} />
            <div className="review-form__instructions" ref={instructionRef} />
            {(event.contact_name || event.contact_email || event.contact_phone_number) && (
              <>
                <h2 className="review-form__title">Point of Contact</h2>
                <div className="review-form__contact">
                  {event.contact_name && <span>{event.contact_name}</span>}
                  {event.contact_email && (
                    <span>
                      {' '}
                      -&nbsp;<b>{event.contact_email}</b>
                    </span>
                  )}
                  {event.contact_phone_number && <span> -&nbsp;{format.phoneNumber(event.contact_phone_number)}</span>}
                </div>
              </>
            )}
          </div>
          <div className="right-form">
            <StaticGoogleMap zoom={17} coordinates={geofencing.location.coordinates} />
            <div className="right-form__address">{getDisplayAddressFromEvent(event)}</div>
          </div>
        </div>
        <h2 className="review-form__title">Visibility</h2>
        <div className="review-form__visibility">
          {event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY
            ? listVisibilityGroups(event.visibilityGroups)
            : getVisibilityDescription(event.visibility)}{' '}
          &nbsp;({getVisibilityLabel(event.visibility)})
        </div>
        <h2 className="review-form__title">Shifts & Roles</h2>
        <div className="review-form__shifts">
          <ul className="review-form-shift-list">
            {timeshifts.map((shift, index) => (
              <li key={index} className="review-form-shift-list__item">
                <span className="time">
                  {shift.time_start} - {shift.time_end}
                </span>
                {shift.roles.map((role, index) => (
                  <span key={index} className="role">
                    {role.name} - {role.available}
                  </span>
                ))}
              </li>
            ))}
          </ul>
        </div>
        {isTest && <h2 className="review-form__title">Test Event</h2>}
        {!_.isEmpty(onboardingGroups) && (
          <>
            <h2 className="review-form__title">Group Onboarding</h2>
            <div className="review-form__group_onboarding">
              Volunteers will be onboarded into {_.map(onboardingGroups, 'name').join(', ')}
            </div>
          </>
        )}
        {requiredCredentials && requiredCredentials.length > 0 && (
          <>
            <h2 className="review-form__title">Waivers</h2>
            <Box mb={3}>
              {requiredCredentials.map(r => (
                <div key={r.id}>{r.title}</div>
              ))}
            </Box>
          </>
        )}
        {!_.isEmpty(questionSetResponses) && <h2 className="review-form__title">Questionnaires</h2>}
        {questionSetResponses.map(questionSetResponse => {
          const { questions } = isQuestionnaireAsSurveyEnabled
            ? questionSetResponse.versions[0]?.questionSet
            : questionSetResponse

          return (
            <div key={questionSetResponse[responseKey]}>
              <div className="review-form__questions">
                <h3>
                  {questionSetResponse.name}
                  {questionSetByQuestionSetId[questionSetResponse[responseKey]]?.required ? '*' : ''}
                </h3>
                <div className="review-form-question-list">
                  {(questions || []).map((question, index) => (
                    <div
                      key={`${questionSetResponse[responseKey]}-${index}`}
                      className="review-form-question-list__item"
                    >
                      <span className="question">
                        {question.question}
                        {question.isRequired ? '*' : ''}
                      </span>
                      <span className="type-question">{question.questionType}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="review-form-buttons">
        <Button onClick={onCancel} type="text" color="secondary">
          CANCEL
        </Button>
        <div className="review-form-buttons__right">
          <Button className="review-form-buttons__back-btn" onClick={onPrevClick} type="text" color="secondary">
            BACK
          </Button>
          {editMode && !published && (
            <Button isLoading={isSendingDraftRequest} onClick={onSaveDraftClick} type="outlined" color="secondary">
              Update Draft
            </Button>
          )}
          {!editMode && (
            <Button isLoading={isSendingDraftRequest} onClick={onSaveDraftClick} type="outlined" color="secondary">
              Save For Later
            </Button>
          )}
          <Button isLoading={isSendingPublicRequest} color="secondary" onClick={onSaveClick}>
            {editMode && published ? 'Update' : 'Publish'}
          </Button>
        </div>
      </div>
    </div>
  )
}

ReviewModule.propTypes = {
  onSaveClick: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSendingDraftRequest: PropTypes.bool,
  isSendingPublicRequest: PropTypes.bool,
  event: PropTypes.object,
  editMode: PropTypes.bool,
}

export default ReviewModule
