import { get, filter } from 'lodash';
import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

//TODO if this is/becomes cannonical, move to another component and/or functions
export default function ShortMemberDetails({ member }) {

    const memberName = useMemo( () => {
        let first = get(member, 'person.givenName', '');
        let last = get(member, 'person.familyName', '');

        return `${first} ${last}`.trim();
    }, [member]);
    
    const contact = useMemo( () => {
        const email = get(member, 'person.email');
        const mobile = get(member, 'person.phoneNumber');
        const home = get(member, 'person.homePhoneNumber');
        return filter([email, home, mobile]).join(' / ') || null
    }, [member]);

    return <>
        <Typography variant="h3">{memberName}</Typography>
        <Typography>{contact}</Typography>
    </>
}
