import { withStyles } from '@material-ui/styles'
import { Slider } from '@material-ui/core'

export const ZoomSlider = withStyles({
  root: {
    color: '#0F5DB5',
    height: 6,
    width: '300px',
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: '#0F5DB5',
    marginTop: -12,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
    backgroundColor: '#C4C7C7',
    opacity: 1,
  },
})(Slider)

export default ZoomSlider
