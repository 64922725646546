import React from 'react'
import {FormikHelpers as FormikActions, FormikProps, FormikValues, withFormik} from "formik"
import {object, string} from 'yup'

import ActionBar from "../../../components/action-bar";
import InputComponent from '../../../components/default-input'
import FormWrapper from "../../../components/form-wrapper"
import {getFormikFieldError} from "../../../../../utils/getErrorMessage"

import './index.scss'
import {IUpdateUser} from "../../../../redux/actions";

const editUserNameValidationSchema = object().shape({
  firstName: string()
    .trim()
    .required('Field is required')
    .min(2, 'First name should have at least two characters'),
  lastName: string()
    .trim()
    .min(2, 'Last name should have at least two characters')
    .required('Field is required')
})

interface IFormProps extends FormikProps<FormikValues> {
  onClickCancel?: VoidFunction
}

const EditUserNameForm = (props: IFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
    onClickCancel
  } = props

  return(
    <form onSubmit={handleSubmit}>
      <InputComponent
        className='first_name'
        label="First Name"
        name='firstName'
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('firstName', errors, touched)}
      />
      <InputComponent
        label="Last Name"
        name='lastName'
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('lastName', errors, touched)}
      />
      <ActionBar
        actionLeft={{
          variant: 'text',
          text: 'CANCEL',
          onClick: onClickCancel
        }}
        actionRight={{
          type: 'submit',
          color: 'yellow',
          text: 'SAVE',
          disabled: !isValid || isSubmitting
        }}
      />
    </form>
  )
}

interface IEditUserNameProps {
  closePopup: VoidFunction
  onUpdate: (updates: IUpdateUser) => {}
  initialValues: {
    firstName: string
    lastName: string
  }
}

const EditUserName = (props: IEditUserNameProps) => {
  const {
    closePopup,
    onUpdate,
    initialValues
  } = props

  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>) => {
    const updates = {
      given_name: values.firstName,
      family_name: values.lastName
    }
    await onUpdate(updates)
    actions.setSubmitting(false)
  }

  const Form = withFormik({
    mapPropsToValues: ({initialValues}: any) => initialValues,
    validationSchema: editUserNameValidationSchema,
    handleSubmit: submitFormHandler
  })(EditUserNameForm)

  return (
    <FormWrapper title={'Edit Name'}>
      <Form
        initialValues={initialValues}
        onClickCancel={closePopup}
      />
    </FormWrapper>
  )
}

export default EditUserName
