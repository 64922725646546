import React, { useMemo, useState } from 'react'
import { useGroups } from 'group/hooks/useGroups'
import { find } from 'lodash'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { TripModalMainScreen } from 'volunteering/trips/components/TripModalMainScreen'
import { SecondScreenFormValues, TripModalSecondScreen } from 'volunteering/trips/components/TripModalSecondScreen'
import { TripOrder } from 'volunteering/trips/types'
import moment from 'moment'

import { mapEventGeofencingToGeofencing } from 'utils/event'
import { Address } from 'civic-champs-shared/formik/components/AddressField'
import { FormModal } from 'components/FormModal'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  trip?: TripOrder
}

export const AddEditTripModal = ({ showing, close, complete, trip }: Props) => {
  const [screen, setScreen] = useState(0)
  const { groups } = useGroups()
  const riderGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Rider } })?.id, [groups])
  const driverGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Driver } })?.id, [groups])
  const [formData, setFormData] = useState({
    rider: trip?.rider
      ? {
          id: trip.rider.id,
          givenName: trip.rider.givenName,
          familyName: trip.rider.familyName,
          contact: trip.rider.email || trip.rider.phoneNumber || '',
          title: '',
          groupId: riderGroupId,
          pubnubId: '',
        }
      : null,
    pickUpAddress: trip?.pickupLocation
      ? ({
          ...trip.pickupLocation.address,
          zip: trip.dropOffLocation.address.zipCode,
          country: '',
          utcOffset: 0,
          geofencing: mapEventGeofencingToGeofencing(trip.pickupLocation.geofencing),
        } as Address)
      : null,
    dropOffAddress: trip?.dropOffLocation
      ? ({
          ...trip.dropOffLocation.address,
          zip: trip.dropOffLocation.address.zipCode,
          country: '',
          utcOffset: 0,
          geofencing: mapEventGeofencingToGeofencing(trip.dropOffLocation.geofencing),
        } as Address)
      : null,

    pickUpDate: trip ? moment(trip?.pickupAt) : null,
    pickUpTime: trip ? moment(trip?.pickupAt) : null,
    dropOffDate: trip ? moment(trip?.dropOffAt) : null,
    dropOffTime: trip ? moment(trip?.dropOffAt) : null,
  })
  const [additionalFormData, setAdditionalFormData] = useState<SecondScreenFormValues>({
    driver: trip?.driver
      ? {
          id: trip.driver.id,
          givenName: trip.driver.givenName,
          familyName: trip.driver.familyName,
          contact: trip.driver.email || trip.driver.phoneNumber || '',
          title: '',
          groupId: riderGroupId,
          pubnubId: '',
        }
      : null,
    reason: trip?.reason || null,
    recovery: trip?.recovery || false,
    cji: trip?.cji || false,
    sudMh: trip?.sudMh || 'null',
    description: trip?.description || null,
  })
  const handleFirstSubmit = (values: any) => {
    setFormData(values)
    setScreen(1)
  }

  const handleFinalSubmit = (values: any) => {
    setAdditionalFormData({ id: trip?.id, ...values })
    complete({ ...formData, ...values })
  }

  const handleBack = (values: any) => {
    setAdditionalFormData({ id: trip?.id, ...values })
    setScreen(0)
  }

  return (
    <FormModal id="trip-prompt" showing={showing} close={close} title={(trip ? 'Edit' : 'Add') + ' Trip Request'}>
      {
        [
          <TripModalMainScreen
            close={close}
            onSubmit={handleFirstSubmit}
            riderGroupId={riderGroupId}
            values={formData}
            isNewTrip={!trip}
          />,
          <TripModalSecondScreen
            close={handleBack}
            onSubmit={handleFinalSubmit}
            driverGroupId={driverGroupId}
            values={additionalFormData}
            isInitialValid={!!trip}
            tripOrderStatus={trip?.tripOrderStatus}
          />,
        ][screen]
      }
    </FormModal>
  )
}
export default AddEditTripModal
