import React  from 'react'
import { MenuItem } from '@material-ui/core'

import SelectList from 'components/select/SelectList'
import useVolunteeringRoles from '../hooks/useVolunteeringRoles'

export default function VolunteeringRoleSelector(props) {
  const { value, onChange, required, disabled, query } = props

  const { data: volunteeringRoles, loading } = useVolunteeringRoles(query)

  return (
    <SelectList
      id="volunteer-role"
      name="volunteer-role"
      label="Volunteer Role"
      items={volunteeringRoles}
      value={value}
      getItemRenderer={i => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      )}
      onChange={onChange}
      required={required}
      disabled={disabled || loading}
    />
  )
}
