import { useCallback, useEffect } from 'react'
import { useFetch } from "../../api/hooks";
import { useCurrentOrg } from "../../auth/hooks";

const emptyQuestionSet = { questions: [], name: '', anonymous: false }

export const useQuestionSet = (questionSetId, onResultUpdate) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Question Set.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()
  const refresh = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/question-set/${questionSetId}`,
    })
  }, [currentOrg.id, onResultUpdate, questionSetId, request])
  useEffect(() => {
    if (questionSetId) {
      refresh()
    }
  }, [questionSetId, refresh])

  return {
    questionSet: result || emptyQuestionSet,
    loading: loading || !called,
    refresh,
  }
}
