// TODO: This is all copy pasted from an earlier version of the code.  It needs to be re-written
import React, { useCallback, useEffect, useMemo } from 'react'
import InputComponent from 'civic-champs-shared/core/input'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { INIT_VALIDATOR } from 'civic-champs-shared/core/utils/validation'

import './index.scss'
import useDebounce from 'civic-champs-shared/utils/useDebounce'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const defaultErrorMessages = {
  answer: '',
}

const AnswerListItem = props => {
  const {
    answerValue,
    onChangeAnswer,
    onRemoveAnswer,
    type,
    isClose,
    onChangeValidField,
    isClickedToNextStep,
    questions,
  } = props

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  useEffect(() => {
    if (isClickedToNextStep !== null && isClickedToNextStep) {
      !answerValue && onChangeErrorMessage('answer', 'The answer field is required')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedToNextStep])

  useEffect(() => {
    const checkAllQuestionAnswers = questions.every(question => question.options.every(answer => answer))
    onChangeValidField(checkAllQuestionAnswers && !errorMessages.answer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages.answer, questions])

  useEffect(() => {
    !answerValue && onChangeValidField(null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const answerClassNames = classNames('answer-list-item', {
    'answer-list-item--checkbox': type === 'checkbox',
    'answer-list-item--radio': type === 'radio',
  })

  const onBlur = useCallback(input => onValidateField('answer', { required: true, maxLength: 40 })(input), [
    onValidateField,
  ])

  const value = useMemo(() => answerValue, [answerValue])
  const debouncedValue = useDebounce(value, 250)

  useEffect(() => {
    onBlur(debouncedValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const handleChangeAnswer = value => {
    onChangeAnswer(value)
  }

  return (
    <div className={answerClassNames}>
      <span className="answer-list-item__indicator" />
      <div className="answer-input-wrap">
        <InputComponent
          label="Enter answer here"
          value={value}
          onChange={handleChangeAnswer}
          onBlur={onBlur}
          hasError={!!errorMessages.answer}
          errorMessage={errorMessages.answer}
        />
      </div>
      {isClose && 
        <IconButton
          onClick={onRemoveAnswer}
          className="close-icon" 
        >
          <CloseIcon />
        </IconButton>
      }
    </div>
  )
}

AnswerListItem.propTypes = {
  questions: PropTypes.array.isRequired,
  answerValue: PropTypes.string.isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
  onRemoveAnswer: PropTypes.func.isRequired,
  isClose: PropTypes.bool,
  type: PropTypes.string,
  onChangeValidField: PropTypes.func.isRequired,
  isClickedToNextStep: PropTypes.any.isRequired,
}

export default AnswerListItem
