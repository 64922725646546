import { AUTH_INITIALIZED, CURRENT_ORG_UPDATED, PROFILE_UPDATED, SIGNED_OUT, START_MANAGING_ORG } from './actions'

const defaultState = {
  initialized: false,
  currentUser: null,
  currentOrganization: null,
  currentProfile: null,
}

export default function (state = defaultState, event) {
  switch (event.type) {
    case AUTH_INITIALIZED:
      return {
        ...state,
        initialized: true,
        currentUser: event.payload.user,
        currentOrganization: event.payload.organization,
        currentProfile: event.payload.profile,
      }

    case PROFILE_UPDATED:
      return {
        ...state,
        currentProfile: event.payload.profile,
      }

    case SIGNED_OUT:
      return {
        ...defaultState,
      }

    case START_MANAGING_ORG:
    case CURRENT_ORG_UPDATED:
      return { ...state, currentOrganization: event.payload.organization }

    default:
      return state
  }
}
