import { size, sumBy } from 'lodash'

export default function useChampionSummary(volunteers, valuePerHour) {
  const totalHours = sumBy(volunteers, 'volunteering.totalVolunteerHours') || 0
  const totalDonations = sumBy(volunteers, 'donation.totalDonations') / 100 || 0
  return {
    count: size(volunteers),
    totalHours: Math.round(totalHours * 100) / 100,
    totalDonations: totalDonations,
    estimatedImpact: valuePerHour * totalHours + totalDonations,
  }
}
