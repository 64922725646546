import React, { useMemo } from 'react'
import { Box, Button, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { filter, sumBy } from 'lodash'

import useMessageSummaryPrompt from 'messages/hooks/useMessageSummaryPrompt'
import { styleDateCell, styleTextCell } from 'core/table/utils'

export const useMessagesSummaryCards = (messages, organization) =>
  useMemo(() => {
    return {
      totalMessages: messages.length,
      emailSent: sumBy(messages, 'emailCount') || 0,
      smsSent: sumBy(messages, 'smsCount') || 0,
      smsCredits: (organization && organization.smsCredits) || 0,
    }
  }, [messages, organization])

const getStatusCell = (notificationBlastSummary, classes) => {
  const { failedEmailEvents, failedSMSEvents, emailsStillSending, smsStillSending } = notificationBlastSummary

  if (emailsStillSending.length || smsStillSending.length) {
    return <div className={classes.sendingStatus}>Sending</div>
  }

  const suffix = failedEmailEvents.length !== 1 ? 's' : ''

  const failedEmailEventsString = failedEmailEvents.length ? `${failedEmailEvents.length} Email${suffix} failed` : ''

  const failedSMSEventsString = failedSMSEvents.length ? `${failedSMSEvents.length} SMS failed` : ''

  const failedString = filter([failedEmailEventsString, failedSMSEventsString]).join(' / ')

  if (failedString) {
    return <div className={classes.failedStatus}>{failedString}</div>
  }

  return <div>Messages Sent Successfully</div>
}

export const useCards = history =>
  useMemo(
    () => [
      {
        Header: 'Total Messages',
        accessor: 'totalMessages',
      },
      {
        Header: 'Emails Sent',
        accessor: 'emailSent',
      },
      {
        Header: 'SMS Sent',
        accessor: 'smsSent',
      },
      {
        Header: 'SMS Sends Available',
        accessor: 'smsCredits',
        Footer: () => (
          <div>
            <Button
              component={Link}
              onClick={() => history.push('/buy-sms-credits')}
              style={{ color: '#5F8FE8', textDecoration: 'underline', textTransform: 'none' }}
            >
              Click to buy more credits
            </Button>
          </div>
        ),
      },
    ],
    [history],
  )

const useStyles = makeStyles(theme => ({
  failedStatus: {
    color: theme.palette.danger.main,
    textDecoration: 'underline',
  },
  sendingStatus: {
    color: theme.palette.secondary.main,
  },
  centered: {
    textAlign: 'center',
  },
  viewButton: {
    fontSize: 15,
    textTransform: 'capitalize',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
}))

export const useColumns = () => {
  const showMessageSummary = useMessageSummaryPrompt()
  const classes = useStyles()

  return useMemo(
    () => [
      {
        Header: '',
        id: 'view',
        Cell: ({ cell }) => (
          <div className={classes.centered}>
            <Button
              className={classes.viewButton}
              color="secondary"
              variant="text"
              onClick={() => showMessageSummary(cell.row.original)}
            >
              View
            </Button>
          </div>
        ),
      },
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: styleDateCell(classes.centered),
        filter: 'dateRange',
      },
      {
        Header: 'Subject',
        accessor: 'userEnteredSubject',
        Cell: ({ cell }) => (
          <Box className={classes.wordBreak}>{cell.row.original.userEnteredSubject || cell.row.original.subject}</Box>
        ),
      },
      {
        Header: 'Email',
        accessor: 'emailCount',
        Cell: styleTextCell(classes.centered),
      },
      {
        Header: 'SMS',
        accessor: 'smsCount',
        Cell: styleTextCell(classes.centered),
      },
      {
        Header: 'Status',
        Cell: props => getStatusCell(props.cell.row.original, classes),
      },
    ],
    [classes, showMessageSummary],
  )
}
