import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from '../../../auth/hooks'
import { useSuccessNotification } from 'civic-champs-shared/api/hooks'

const useEditOrganizationLongCode = () => {
  const [request, status] = useFetch('Error setting organization long code')
  const organization = useCurrentOrg()
  const showSuccess = useSuccessNotification()
  const editOrganizationLongCode = useCallback( (body, id) => {
    return request({
      method: id ? 'put' : 'post',
      url: id ? `/organizations/${organization.id}/long-codes/${id}` : `/organizations/${organization.id}/long-codes`,
      config: {
        body
      },
      onSuccess: () => showSuccess('Successfully updated organization long code')
    })
  }, [request])

  return [editOrganizationLongCode, status]
}

export default useEditOrganizationLongCode
