import { useCallback } from "react"
import { usePrompt } from "civic-champs-shared/core/modal/hooks"
import WaiverAgreementPrompt from "../pages/WaiverAgreementPrompt"

const useWaiverAgreementPrompt = (waiver) => {
        const prompt = usePrompt(WaiverAgreementPrompt);
        
        return useCallback(() => prompt.show({waiver}), [prompt, waiver]);
} 

export default useWaiverAgreementPrompt
