import React, { CSSProperties, FC, useCallback } from 'react'
import './index.scss'
import CheckBox from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import cn from 'classnames'

type Props = {
  value: boolean | undefined | null
  onClick: (val: boolean) => void
  label?: string
  style?: CSSProperties
  disabled?: boolean
  spanClass?: string
  className?: string
}

export const Checkbox: FC<Props> = ({ value, label, disabled, onClick, style, spanClass, className }) => {
  const handleClick = useCallback(() => {
    !disabled && onClick(!value)
  }, [onClick, value, disabled])

  return (
    <div className={cn('custom-checkbox-wrap', className)} onClick={handleClick} style={style}>
      {value ? <CheckBox style={{ fill: disabled ? 'grey' : '#5C8DE8' }} /> : <CheckBoxOutlineBlank />}
      {label && <span className={cn(spanClass, 'custom-checkbox__title')}>{label}</span>}
    </div>
  )
}
