import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export const useSyncSalsaIntegration = onResultUpdate => {
  const [request, { loading, result }] = useFetch('Error Syncing Salsa Supporters')
  const showSuccess = useSuccessNotification()
  const syncIntegration = useCallback(
    async salsaIntegration => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully initiated sync of Salsa Supporters')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/salsa/organizations/${salsaIntegration.organizationId}/salsa_sync`,
      })
    },
    [request],
  )

  return {
    syncIntegration,
    salsaIntegration: result,
    loading,
  }
}
