import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { useCallback } from 'react'
import { ChatMembersPayload, Member } from 'chats/interfaces'

export const useAddChatMembers = (): [
  ({ chatId, uuids }: ChatMembersPayload) => Promise<Member[]>,
  Status<Member[]>,
] => {
  const [request, status] = useFetchRefactored<Member[]>()
  const org = useCurrentOrg()

  const addChatMembers = useCallback(
    ({ chatId, uuids }: ChatMembersPayload) =>
      request({
        method: 'patch',
        url: `/organizations/${org.id}/chats/${chatId}/members`,
        config: {
          body: { uuids },
        },
      }),

    [request, org],
  )

  return [addChatMembers, status]
}

export default useAddChatMembers
