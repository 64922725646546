import { useCallback, useMemo } from 'react'
import * as _ from 'lodash'

import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

import { AssociationType, EventGroup } from '../../../interfaces/interfaceCreateEditEvent'
import { Group, GroupTypeCode, donorGroups, helpingHandsGroups, mentorshipGroups, tripPlannerGroups } from './types'
import { useFeatureEnabled } from '../../../../core/feature/hooks'
import { useHasRole } from 'auth/hooks'

// when selecting a visibility group, if the group is closed
// we want to give the option of restricting visibility to group applicants and members OR just full members
export const useGetGroupOptionLabel = (associationType: AssociationType) =>
  useCallback(
    (group: EventGroup) => {
      return associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP || !group.closed
        ? group.name
        : group.approvedMembersOnly
        ? `${group.name} - Approved members`
        : `${group.name} - Applicants`
    },
    [associationType],
  )

export const useOptions = ({
  groups,
  associationType,
  value,
}: {
  groups: Group[]
  associationType: AssociationType
  value: EventGroup[]
}) =>
  useMemo<EventGroup[]>(() => {
    const selectedGroupByGroupId = _.groupBy(value, 'groupId')
    const allOptions = groups.reduce((options: EventGroup[], group: Group) => {
      const baseOption = {
        groupId: group.id,
        name: group.name,
        closed: group.closed,
        approvedMembersOnly: false,
        associationType,
      }
      options.push({
        ...baseOption,
      })
      // when selecting a visibility group, if the group is closed
      // we want to give the option of restricting visibility to group applicants and members OR just full members
      if (!(associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP) && group.closed) {
        options.push({
          ...baseOption,
          approvedMembersOnly: true,
        })
      }
      return options
    }, [])
    return allOptions.filter(option => {
      const selectedGroups = selectedGroupByGroupId[option.groupId]
      return (
        _.isEmpty(selectedGroups) ||
        selectedGroups.every(
          group =>
            group.associationType !== option.associationType ||
            group.approvedMembersOnly !== option.approvedMembersOnly,
        )
      )
    })
  }, [groups, associationType, value])

export const useInitialValues = (value: any[], associationType: AssociationType) =>
  useMemo<{ groups: any }>(() => {
    const groups = _.cloneDeep(value || [])
    if (associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP && !value.some(group => group.lockedTag)) {
      groups.unshift({
        name: 'Volunteers',
        lockedTag: true,
      })
    }

    return { groups }
  }, [value]) // eslint-disable-line

export const useEnabledGroups = (groups: Group[]): Group[] => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const mentorship = useFeatureEnabled('Mentorship')
  const helpingHands = useFeatureEnabled('HelpingHands')
  const donors = useFeatureEnabled('Donations')
  const tripPlanner = useFeatureEnabled('TripPlanner')

  return useMemo(() => {
    const rejectFn = (group: Group) =>
      (!mentorship && mentorshipGroups.includes(group.groupType.code)) ||
      (!helpingHands && helpingHandsGroups.includes(group.groupType.code)) ||
      (!donors && donorGroups.includes(group.groupType.code)) ||
      (!tripPlanner && tripPlannerGroups.includes(group.groupType.code)) ||
      (!isInternalAdmin && group.groupType.code === GroupTypeCode.Admin)

    return _.reject(groups, rejectFn)
  }, [groups]) // eslint-disable-line
}
