import React from 'react'
import { useSurvey } from '../hooks/useSurvey'
import { Loading } from 'civic-champs-shared/core'
import { CreateUpdateSurveyPrompt } from './CreateUpdateSurveyPrompt'

export function SurveyEditModal(props: any) {
  const { surveyId, ...rest } = props
  const [{ loading, value: survey }] = useSurvey(surveyId)

  if (loading) {
    return <Loading />
  }

  return <CreateUpdateSurveyPrompt survey={survey} {...rest} />
}

export default SurveyEditModal
