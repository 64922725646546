export const readFile = async (file: File): Promise<string> => {
  const reader = new FileReader()
  return new Promise(resolve => {
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result as string)
      },
      false,
    )
    reader.readAsDataURL(file)
  })
}
