import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import ReactGoogleMap from 'components/ReactGoogleMap'
import InputComponent from 'Event/components/input'
import { MIN_RADIUS_IN_METER } from 'Event/helpers/toRoundMetersTo5Feet'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import useCreatePersonLocation from '../hooks/useCreatePersonLocation'
import useEditPersonLocation from '../hooks/useEditPersonLocation'
import { getDisplayAddressFromEvent, parseGoogleResponse } from 'civic-champs-shared/core/location/utils'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { mapCoordinatesObjectToArray } from 'utils/event'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '100%',
    height: '100%',
    zIndex: '1299',
    position: 'fixed',
    top: 0,
    background: theme.palette.background.default,
    padding: theme.spacing(4),
  },
  locationMap: {
    width: '100%',
    height: '360px',
  },
  addressRow: {
    marginBottom: '32px',
  },
  buttonRow: {
    marginTop: '32px',
  },
  header: {
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  headerRow: {
    marginBottom: theme.spacing(4),
  },
  close: {
    position: 'absolute',
    top: '0.65em',
    right: '1em',
  },
}))

export default function AddOrEditLocationPrompt(props) {
  const { close, personId, refreshLocations, personLocation } = props
  const { location } = personLocation || {}
  const { address, geofencing = DEFAULT_GEOFENCING } = location || {}
  const { line1, city, state, zipCode, countryCode } = address || {}
  const [addressObj, setAddressObj] = useState({
    address: line1 || '',
    displayAddress: getDisplayAddressFromEvent({
      address: line1 || '',
      city: city || '',
      state: state || '',
      zip: zipCode || '',
    }),
    city: city || '',
    state: state || '',
    zip: zipCode || '',
    contryCode: countryCode || '',
  })
  const [coordinates, setCoordinates] = useState(geofencing?.location?.coordinates || geofencing?.position?.coordinates)
  const classes = useStyles()
  const addressRef = useRef(null)
  const { createPersonLocation, createLoading } = useCreatePersonLocation()
  const { editPersonLocation, editLoading } = useEditPersonLocation()
  const showError = useErrorNotification()

  const handleAddressChange = address => {
    // this gets called as the user types or when they hit the clear button
    // either way, we want to wipe away the old city, state, zip until they have selected a location from the dropdown
    // in which case, handlePlaceSelect will get called
    setAddressObj({
      address,
      displayAddress: address,
      city: '',
      state: '',
      zip: '',
      countryCode: '',
    })
  }

  const handlePlaceSelect = useCallback(
    autocomplete => () => {
      const {
        displayName,
        address: { line1, city, state, zipCode, countryCode },
        coordinates,
      } = parseGoogleResponse(autocomplete)
      if (line1 && (coordinates.lat !== 0 || coordinates.lng !== 0)) {
        setAddressObj({
          address: line1,
          city,
          state,
          zip: zipCode,
          countryCode,
          displayAddress: displayName,
        })
        setCoordinates(coordinates)
      }
    },
    [],
  )

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
      types: ['geocode'],
    })
    autocomplete.setComponentRestrictions({
      country: ['us'],
    })

    const listener = autocomplete.addListener('place_changed', handlePlaceSelect(autocomplete))

    return () => {
      window.google.maps.event.removeListener(listener)
      window.google.maps.event.clearInstanceListeners(autocomplete)
    }
  }, [handlePlaceSelect, addressRef])

  const isEdit = !!personLocation
  const handleSubmit = async () => {
    try {
      const payload = {
        personId,
        updateType: isEdit ? 'fix' : undefined,
        location: {
          id: isEdit ? personLocation.id : undefined,
          geofencing: {
            position: {
              coordinates: mapCoordinatesObjectToArray(coordinates),
              type: 'Point',
            },
          },
          address: {
            line1: addressObj.address,
            city: addressObj.city,
            state: addressObj.state,
            zipCode: addressObj.zip,
            countryCode: addressObj.countryCode,
          },
        },
      }
      if (isEdit) {
        await editPersonLocation(personId, payload)
      } else {
        await createPersonLocation(personId, payload)
      }
      refreshLocations()
      close()
    } catch (err) {
      showError('There was a problem adding the location', err)
    }
  }

  const loading = createLoading || editLoading

  return (
    <div className={classes.dialog}>
      <Grid container direction="row" justify="space-between" className={classes.headerRow}>
        <Grid item>
          <Typography variant="h4" className={classes.header}>
            Add Location
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={close} className={classes.close}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item container xs={6} direction="column">
          <Grid item>
            <div>
              <InputComponent
                inputRef={addressRef}
                type="search"
                value={addressObj.displayAddress}
                onChange={handleAddressChange}
                label="Address*"
                placeholder="Street Address, City, State, Zip"
                disabled={loading}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.locationMap}>
            <ReactGoogleMap zoom={17} geofenceRadius={MIN_RADIUS_IN_METER} coordinates={coordinates} />
          </div>
        </Grid>
      </Grid>

      <Button color="secondary" variant="contained" onClick={handleSubmit} disabled={loading}>
        {isEdit ? 'Update' : 'Add'}
      </Button>
    </div>
  )
}
