import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { PersonRef } from 'civic-champs-shared/question-sets/types'
import format from 'civic-champs-shared/utils/format'
import useDelete from 'civic-champs-shared/api/hooks/useDelete'
import cn from 'classnames'

export interface WhitelistDevPhoneNumber {
  id: number
  phoneNumber: string
  addedBy?: PersonRef
  createdAt: Date | string
  deletedAt: Date | string
}

interface Props {
  whitelistedPhoneNumber: WhitelistDevPhoneNumber
  complete: (cleared: boolean) => void
  close: () => void
  cancel: () => void
  refresh: () => Promise<void>
  showing: boolean
}

export const ClearWhitelistedPhoneNumberPrompt = (props: Props) => {
  const { whitelistedPhoneNumber, close, showing, complete, cancel, refresh } = props
  const [deleteWhitelistedPhoneNumber, { loading }] = useDelete({
    errorMessage: 'Error deleting phone number',
    successMessage: 'Successfully deleted phone number',
    configMapper: whitelistedPhoneNumber => ({
      url: `/whitelist-dev-phone-number/${whitelistedPhoneNumber.id}`,
      onSuccess: refresh,
    }),
  })
  const classes = usePromptStyles()
  const submit = async () => {
    await deleteWhitelistedPhoneNumber(whitelistedPhoneNumber)
    await refresh()
    complete(true)
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={cn(classes.contentContainer, classes.windowedContentContainer)}>
        <div className={classes.dialog}>
          <Grid container direction="column">
            <Grid>
              <Typography variant="h5">Do you want to delete this whitelisted phone number?</Typography>
            </Grid>
            <Grid>
              <div style={{ marginTop: '10px' }}>{format.phoneNumber(whitelistedPhoneNumber.phoneNumber)}</div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={cn(classes.actionContainer, classes.windowedActionContainer)}>
        <Button
          variant="text"
          color="primary"
          onClick={cancel}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeCancel}`}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={submit}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeButton}`}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
