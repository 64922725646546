import * as React from 'react'
import ReactDOM from 'react-dom'
import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import classNames from 'classnames'

import './index.scss'
import { useFormatDates } from '../../helpers/toFormatFullDateToISO'

export interface DatePickerMultiSelectProps {
  onChange: (date: string) => void
  minDate?: string
  isDisabled?: boolean
  highlightedDates?: []
  dates?: []
  isRequired?: boolean
  hasError?: boolean
}

const DatePickerMultiSelect = (props: DatePickerMultiSelectProps) => {
  const { dates, onChange, minDate, isDisabled, highlightedDates, isRequired = false, hasError = false } = props

  const [isReq, setRequired] = useState(isRequired)
  const { timeZone } = useFormatDates()

  const [showDatepicker, handleShowDatepicker] = useState(false)

  const [datepickerRef, setRef] = useState(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  })

  useEffect(() => {
    setRequired(false)
  }, [])

  useEffect(() => {
    if (!isRequired) return setRequired(false)
    if (isRequired && hasError) return setRequired(true)
  }, [isRequired, hasError])

  const handleClickOutside = (event: any) => {
    const timePickerNode = ReactDOM.findDOMNode(datepickerRef)
    const condition = timePickerNode && timePickerNode.parentNode && !timePickerNode.parentNode.contains(event.target)

    if (condition && showDatepicker) {
      handleCloseDatePicker()
    }
  }

  const handleClick = () => {
    handleShowDatepicker(!showDatepicker)
  }

  const handleChangeRef = (ref: any) => setRef(ref)

  const getListOfDates = () => {
    return (
      dates &&
      dates.map((date, index) => {
        const formattedDate = moment(date).tz(timeZone, true).format('MM/DD/YYYY')
        if (index < 8) {
          if (index === dates.length - 1) {
            return <span key={index}>{`${formattedDate}`}</span>
          }
          if (index >= 7) {
            return <span key={index}>{`${formattedDate}...`}</span>
          }
          return <span key={index}>{`${formattedDate}, `}</span>
        }
      })
    )
  }

  const handleCloseDatePicker = () => {
    (isRequired && dates && !dates.length) || (dates && dates.length < 2) ? setRequired(true) : setRequired(false)
    handleShowDatepicker(false)
  }

  const handleChange = (date: Date) => {
    const formattedDate = date && moment(date).format('MM/DD/YYYY')
    onChange(formattedDate)
  }

  const getHighlightedDates = () => {
    const highlightDates: any[] = []
    highlightedDates &&
      highlightedDates.map(item => {
        highlightDates.push(moment(item).toDate())
      })
    return highlightDates
  }

  const renderErrorMessage = () => {
    if (isReq && dates && !dates.length) {
      return 'This field is required'
    }
    if (isReq && dates && dates.length < 2) {
      return 'Please choose at least two events'
    }
  }

  const datePickerClassNames = classNames('custom-datepicker-multi-select', {
    'custom-datepicker-multi-select--error': isReq,
  })

  return (
    <React.Fragment>
      <div className={datePickerClassNames}>
        <div className="custom-datepicker-multi-select__content" ref={handleChangeRef} onClick={handleClick}>
          <span className="dates">{getListOfDates()}</span>
          <span className="counter">{dates && dates.length > 0 && dates.length}</span>
          <span className="calendar-icon" />
        </div>
        {showDatepicker && (
          <div className="custom-datepicker-multi-select__datepicker">
            <DatePicker
              inline
              disabled={isDisabled}
              dateFormat="MM/dd/yyyy"
              minDate={minDate ? new Date(minDate) : moment().toDate()}
              isClearable
              showYearDropdown
              onChange={handleChange}
              highlightDates={getHighlightedDates()}
            />
          </div>
        )}
      </div>
      <span className="custom-datepicker-multi-select-error-msg">{renderErrorMessage()}</span>
    </React.Fragment>
  )
}

export default DatePickerMultiSelect
