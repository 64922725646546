import cn from 'classnames'
import { Box, Button, Grid, makeStyles } from '@material-ui/core'
import React, { ReactNode, useMemo } from 'react'
import { CellProps, Row, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { countBy, get, pickBy } from 'lodash'

import PagedTable from 'core/table/components/PagedTable'
import { EmailCell, NullableTextCell } from 'core/table/cells'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import { BadContact, StepTwoProps } from './schema'

// TODO convert PagedTable into .tsx
const Table: any = PagedTable

const useDisabledBoxStyles = makeStyles((theme) => ({
  disabledCell: {
    // @ts-ignore
    color: theme.palette.neutral.lightGray,
  },
}))

const DisabledBox = (props: { children: ReactNode }) => {
  const classes = useDisabledBoxStyles()

  return (
    <Box className={classes.disabledCell}>
      {props.children}
    </Box>
  )
}

const useContactsColumns = (failuresCountByOwnerId: Record<string, number>) => {
  return useMemo(() => {
    const disabledContactIds = Object.keys(failuresCountByOwnerId).map(Number)

    const DisabledTextCell = ({ cell, row }: CellProps<any>) => (
      disabledContactIds.includes(row.original.currentOwner.id)
        ? <DisabledBox><NullableTextCell cell={cell} /></DisabledBox>
        : <NullableTextCell cell={cell} />
    )

    return [
      {
        id: 'firstName',
        Header: 'First Name',
        accessor: 'currentOwner.givenName',
        Cell: DisabledTextCell,
      },
      {
        id: 'lastName',
        Header: 'Last Name',
        accessor: 'currentOwner.familyName',
        Cell: DisabledTextCell,
      },
      {
        id: 'email',
        Header: 'Email',
        accessor: 'email',

        Cell: ({ row }: { row: any }) => disabledContactIds.includes(row.original.currentOwner.id)
          ? null
          : <EmailCell cell={{ value: row['email'] }} />,
      },
      {
        id: 'phone',
        Header: 'Phone',
        accessor: 'phoneNumber',
        Cell: DisabledTextCell,
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: 'reason',
        Cell: ({ cell, row }: CellProps<any>) => {
          const isDisabled = disabledContactIds.includes(row.original.currentOwner.id)

          return (
            isDisabled
              ? <DisabledBox>{
                `${failuresCountByOwnerId[row.original.currentOwner.id]} failures disabled this contact`
              }</DisabledBox>
              : <NullableTextCell cell={cell} />
          )
        },
      },
    ]
  }, [failuresCountByOwnerId])
}

const useStyles = makeStyles((theme) => ({
    tableHeader: {
      paddingTop: 0,
      paddingBottom: 0,
      // @ts-ignore
      backgroundColor: theme.palette.danger.main,
      // @ts-ignore
      color: theme.palette.neutral.white,

      '& .MuiSvgIcon-root': {
        // @ts-ignore
        color: theme.palette.neutral.white,
      },
    },
    tableCell: {
      paddingTop: 0,
      paddingBottom: '0 !important',
      maxWidth: 250,
      border: '1px solid rgba(224, 224, 224, 1)',
    },
    buttons: {
      alignItems: 'flex-end',
      marginTop: 10,
    },
    marginLeft: {
      marginLeft: 10,
    },
    actionRow: {
      alignItems: 'flex-end',
      marginTop: 30,
    },
    button: {
      borderColor: 'currentColor',
      textTransform: 'initial',
      fontWeight: 'bold',
      minWidth: 100,
    },
    cancelButton: {
      // @ts-ignore
      color: theme.palette.danger.main,
    },
    note: {
      // TODO extend mui theme type
      // @ts-ignore
      color: theme.palette.neutral.grayDark,
    },
  }),
)

export default function StepTwo(props: StepTwoProps) {
  const { onSubmit, cancel, badContacts, back } = props
  const classes = useStyles()

  const failuresCountByOwnerId = useMemo(() => {
    return pickBy(
      countBy(badContacts, 'currentOwner.id'),
      (value: number) => value > 1,
    )
  }, [badContacts])

  const columns = useContactsColumns(failuresCountByOwnerId)

  const table = useTable(
    {
      data: badContacts,
      // @ts-ignore
      columns,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useConditionalSelectColumn((row: Row<BadContact>): boolean => {
      const disabledContactIds = Object.keys(failuresCountByOwnerId).map(Number)

      return !disabledContactIds.includes(get(row, 'original.currentOwner.id'))
    }),
  )

  const submit = () => {
    // we want to submit the ones that were NOT selected
    onSubmit(badContacts.filter((badContact, index) => {
      // @ts-ignore
      return !table.state.selectedRowIds[index]
    }))
  }

  return (
    <Box display="flex" flexDirection="column" className="fullheight">
      <div>
        <h3>The following contacts previously had issues when sending</h3>
        <p className={classes.note}>
          Selecting any of the contacts below will attempt to send the message to them one more time. A second failure
          will disable this contact until it has been updated in the user's profile
        </p>
        <Table
          {...table}
          classes={{
            cell: classes.tableCell,
            headerCell: classes.tableHeader,
          }}
        />
      </div>
      <Grid xs={12} item container direction="row" justify="space-between" className={classes.actionRow}>
        <Grid item xs={6}>
          <Button onClick={back} variant="outlined" color="secondary" className={classes.button}>
            Back
          </Button>
        </Grid>
        <Grid direction="row" item xs={6} container justify="flex-end">
          <Grid>
            <Button
              onClick={cancel}
              variant="outlined"
              className={cn(classes.button, classes.cancelButton)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button
              className={cn(classes.marginLeft, classes.button)}
              onClick={submit}
              variant="contained"
              color="secondary"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
