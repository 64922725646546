import { useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import {useUserCredentialIDs} from "./useUserCredentialIDs";

const useUserCredentialIDsCollection = (props) => {

  const credentialType = props
  const [userCredentialIds, operations] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection } = operations
  const { refetch } = useUserCredentialIDs(credentialType.id, (result) => {
    syncCollection(result)
    setInitiallyLoaded(true)
  })

  return [
    {
      userCredentialIds,
      initiallyLoaded
    },
    {
      refresh: refetch
    },
  ]
}

export default useUserCredentialIDsCollection
