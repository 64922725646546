import React from 'react'
import { ChampionProfile } from 'champion/interfaces'
import { ExpansionPanel, ExpansionPanelDetails, Typography } from '@material-ui/core'
import { ProfileOverview } from 'champion/components/ProfileOverview'
import { ProfileWaivers } from 'champion/components/ProfileWaivers'
import { VolunteerReflections } from 'champion/components/VolunteerReflections'
import { QuestionAnswerSetsGroupedView } from 'civic-champs-shared/question-sets/components/QuestionAnswerSetsGroupedView'
import { ReflectionCalendar } from 'champion/components/ReflectionCalendar'
import { useFeatureEnabled } from 'core/feature/hooks'
import { ProfileRegistrations } from 'champion/components/ProfileRegistrations'
import { useProfileAccordionStyles } from 'champion/hooks/useProfileAccordionStyles'
import { ProfileExtensionPanelSummary } from 'champion/components/ProfileExtensionPanelSummary'
import { useGetActivitiesWithReflections } from 'champion/hooks/useGetActivitiesWithReflections'

export const ProfileAccordion = ({ profile }: { profile: ChampionProfile }) => {
  const classes = useProfileAccordionStyles()
  const volunteerCalendarEnabled = useFeatureEnabled('VolunteerReflectionCalendar')
  const activitiesWithReflection = useGetActivitiesWithReflections(profile.activities)
  return (
    <div>
      <ExpansionPanel
        classes={{ root: classes.panelRoot }}
        defaultExpanded={true}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ProfileExtensionPanelSummary aria-controls={`0-content`} id={`0-header`}>
          <Typography className={classes.header}>Overview</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <ProfileOverview
            personId={profile.user.id}
            activities={profile.activities}
            donations={profile.volunteer.donation.totalDonations}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
        <ProfileExtensionPanelSummary aria-controls={`1-content`} id={`1-header`}>
          <Typography className={classes.header}>Waivers</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <ProfileWaivers credentials={profile.credentials} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
        <ProfileExtensionPanelSummary aria-controls={`2-content`} id={`2-header`}>
          <Typography className={classes.header}>Reflections</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          {volunteerCalendarEnabled ? (
            <ReflectionCalendar activities={activitiesWithReflection} />
          ) : (
            <VolunteerReflections activities={activitiesWithReflection} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
        <ProfileExtensionPanelSummary aria-controls={`3-content`} id={`3-header`}>
          <Typography className={classes.header}>Questionnaires</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <QuestionAnswerSetsGroupedView questionSets={profile.questionSets} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel classes={{ root: classes.panelRoot }} TransitionProps={{ unmountOnExit: true }}>
        <ProfileExtensionPanelSummary aria-controls={`4-content`} id={`4-header`}>
          <Typography className={classes.header}>Registrations</Typography>
        </ProfileExtensionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <ProfileRegistrations personId={profile.user.id} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
