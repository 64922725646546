import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { useFetchRefactored, Status } from 'civic-champs-shared/api/hooks'
import { ActivityPayload } from '../types'
import { Activity } from 'champion/utils/interface'

export const useAddActivity = (): [(activity: ActivityPayload) => Promise<Activity>, Status<Activity>] => {
  const organization = useCurrentOrg()

  const [request, status] = useFetchRefactored<Activity>({
    useNewNotification: true,
  })

  const addActivity = useCallback(
    (activity: ActivityPayload) => {
      const {
        volunteer: { cognitoSub, name },
        ...activityPayload
      } = activity

      return request({
        method: 'post',
        url: `/activities`,
        config: {
          body: { ...activityPayload, cognitoSub, organizationId: organization.id },
        },
        successMessage: `An activity was added for ${name}`,
        errorMessage: `An activity was not added for ${name}`,
      })
    },
    [organization.id, request],
  )

  return [addActivity, status]
}

export default useAddActivity
