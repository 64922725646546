import React from 'react'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch/Switch'

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string
}

interface Props extends SwitchProps {
  classes: Styles
}

export const NavSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 29,
      height: 15,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: '2px',
      '&$checked': {
        transform: 'translateX(13px)',
        '&:before': {
          content: '""',
          display: 'inline-block',
          transform: 'rotate(45deg)',
          height: '6px',
          width: '3px',
          borderBottom: '1px solid black',
          borderRight: '1px solid black',
          position: 'absolute',
          top: '3px',
        },
      },
    },
    thumb: {
      width: 11,
      height: 11,
      color: '#4985DF',
    },
    track: {
      borderRadius: 15 / 2,
      border: 0,
      backgroundColor: '#FFF',
      opacity: '1 !important',
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
      '& $thumb': {
        color: '#fff',
      },
      '& + $track': {
        backgroundColor: '#000000',
      },
    },
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})
