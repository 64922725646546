import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core/'
import {
  Add as AddIcon,
  MessageOutlined as MessageOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
} from '@material-ui/icons'

import Loading from 'components/Loading'
import { ContainedButton, TableMenuButton } from 'civic-champs-shared/core'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExtendedPagedTable } from 'core/table/components'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import { useAdminsCollection, useAdminsColumns, useStyles } from '../hooks'
import PageHeader from 'App/components/PageHeader'
import { useGetSearchableContactsFromAdminsArray, useMessageRecipientsPrompt } from 'messages/hooks'
import { Admin } from '../interface'
import { ExportAdminsButton } from '../components'
import useResendAdminInvitation from '../hooks/useResendAdminInvitation'
import TablePopoverButton from '../../../civic-champs-shared/core/TablePopoverButton'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'

const tableName = 'admins'

export const Admins = (props: RouteComponentProps) => (
  <PageHeader title="Admins" subTitle="Manage your organization's administrator accounts.">
    <AdminsComponent {...props} />
  </PageHeader>
)

export const AdminsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ members, loading }, { addMemberLegacy, removeMembers, changeRoleWithoutPrompt }, eventListeners] =
    useAdminsCollection()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const getSearchableContacts = useGetSearchableContactsFromAdminsArray(members as Admin[])
  const resendInvitation = useResendAdminInvitation()

  const columns = useAdminsColumns(changeRoleWithoutPrompt)
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'joinedDate'],
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      // @ts-ignore
      data: members,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, disabled] = useMemo(() => {
    const selectedRows = table.selectedFlatRows.map((row: any) => row.original)
    return [selectedRows, !selectedRows.length]
  }, [table.selectedFlatRows])

  const handleResendInvitation = useCallback(async () => {
    return resendInvitation(selectedRows.map(({ person: { id } }: Admin) => id))
  }, [resendInvitation, selectedRows])

  const onMessageAdmins = () => {
    showMessageRecipientsPrompt({
      recipientPersonIds: selectedRows.map(({ id }: Admin) => id),
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }

  const onRemoveAdmins = async () => {
    // @ts-ignore
    await removeMembers(selectedRows)
    table.toggleAllRowsSelected(false)
  }

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        // @ts-ignore
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Admins"
        buttons={
          <>
            <TableMenuButton
              startIcon={<MessageOutlinedIcon />}
              onClick={onMessageAdmins}
              disabled={disabled}
              rightMargin
            >
              Message
            </TableMenuButton>
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              onClick={onRemoveAdmins}
              disabled={disabled}
              rightMargin
            >
              Remove
            </TableMenuButton>
          </>
        }
        popover={
          <>
            <TablePopoverButton
              startIcon={<VerifiedIcon style={{ width: '20px', height: '20px' }} />}
              disabled={disabled}
              onClick={handleResendInvitation}
            >
              Resend Verification Link
            </TablePopoverButton>
            <ExportAdminsButton data={selectedRows} disabled={disabled} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addMemberLegacy}>
            Add Admin
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}
