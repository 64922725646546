import React, { useMemo } from 'react'
import { uniqBy } from 'lodash'
import { CampaignEventType } from '../interface'
import ProgressTracker from './ProgressTracker'

export default function CampaignEventsSummary(props) {
  const { combinedActivitiesAndEvents, campaign, campaignEventType } = props
  const eventTypes = [
    CampaignEventType.VIEWED,
    CampaignEventType.CAMPAIGN_CLICKED,
    CampaignEventType.DONATION_CLICKED,
    CampaignEventType.DONATED,
  ]
  const { eventSummary, audienceCount } = useMemo(() => {
    const eventSummary = combinedActivitiesAndEvents.reduce((acc, activity) => {
      const { events } = activity
      eventTypes.forEach(type => {
        if (events[type]) {
          acc[type] = (acc[type] || 0) + events[type]
        }
      })
      return acc
    }, {})
    const audienceCount = uniqBy(combinedActivitiesAndEvents, 'user.id').length
    return { eventSummary, audienceCount }
  }, [combinedActivitiesAndEvents])
  const steps = [
    {
      name: 'Audience',
      count: audienceCount,
      path: `/campaigns/${campaign.id}/audience`,
    },
    {
      name: 'Offers',
      count: eventSummary[CampaignEventType.VIEWED] || 0,
      path: `/campaigns/${campaign.id}/${CampaignEventType.VIEWED}`,
    },
    {
      name: 'Campaign Clicks',
      count: eventSummary[CampaignEventType.CAMPAIGN_CLICKED] || 0,
      path: `/campaigns/${campaign.id}/${CampaignEventType.CAMPAIGN_CLICKED}`,
    },
    {
      name: 'Donation Clicks',
      count: eventSummary[CampaignEventType.DONATION_CLICKED] || 0,
      path: `/campaigns/${campaign.id}/${CampaignEventType.DONATION_CLICKED}`,
    },
    {
      name: 'Donations',
      count: eventSummary[CampaignEventType.DONATED] || 0,
      path: `/campaigns/${campaign.id}/${CampaignEventType.DONATED}`,
    },
  ]
  const stepIndex = campaignEventType ? steps.findIndex(step => step.path.split('/')[3] === campaignEventType) : 0
  return (
    <>
      <h3>Conversions</h3>
      <ProgressTracker steps={steps} stepIndex={stepIndex} />
    </>
  )
}
