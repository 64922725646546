import { useCallback } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'

const useGetAffiliatedOpportunities = () => {
    const [request, { result, loading, called }] = useFetch()

    const getAffiliatedOpportunities = useCallback(async (campaignId) => {
        return request({
            method: 'get',
            url: `/campaigns/${campaignId}/opportunities`
        })
    }, [request])

    return [
        getAffiliatedOpportunities,
        {
            opportunities: result,
            loading: loading || !called
        }
    ]
}

export default useGetAffiliatedOpportunities
