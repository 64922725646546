import * as React from 'react'
import {useCurrentOrg} from '../../auth/hooks'

//For Class Component
//If you need a id of current organization, wrap your class component in this HOC when component is export. For example: "export default withCurrentOrganizationHOC(YourComponent)"
export const withCurrentOrganizationHOC = (Component: any) => {
  return (props: any) => {
    const currentOrg = useCurrentOrg()
    return <Component currentOrganization={currentOrg} {...props}/>
  }
}