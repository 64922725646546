import { useCallback, useEffect } from 'react'

import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useRemoteCollection, useRemoteCollectionPointer } from 'civic-champs-shared/api/hooks'
import { AddMemberType } from 'civic-champs-shared/core/add-person/AddPersonFlow'

import { useCurrentOrg } from 'auth/hooks'

import useExpelAdminPrompt from './useExpelAdminPrompt'
import useChangeRolePrompt from './useChangeRolePrompt'
import useAddAdmin from './useAddAdmin'
import useAddAdminPrompt from './useAddAdminPrompt'
import useChangeRole from './useChangeRole'
import useRemoveAdminsPrompt from './useRemoveAdminsPrompt'

export const useAdminsCollection = () => {
  const [request, { loading, error }] = useFetch()
  const [members, operations, events] = useRemoteCollection()
  const [selected, setSelected] = useRemoteCollectionPointer(events)
  const organization = useCurrentOrg()

  const { syncCollection } = operations
  const getMembers = useCallback(async () => {
    let updatedMembers = await request({
      url: `/organizations/${organization.id}/admins`,
    })

    syncCollection(updatedMembers)
  }, [organization, request, syncCollection])

  useEffect(() => {
    getMembers()
  }, [getMembers])

  const [addAdmin] = useAddAdmin()

  const changeRole = useChangeRolePrompt(operations.eagerReplace)
  const expelMember = useExpelAdminPrompt(operations.eagerRemove)
  const removeMembers = useRemoveAdminsPrompt(operations.eagerRemoveMany)

  const addMember = ({ id, userData, sendInvite } = {}) => {
    const payload = id
      ? {
          userId: id,
          type: AddMemberType.ExistingUser,
          sendInvite,
        }
      : {
          ...userData,
          type: AddMemberType.NewUser,
          sendInvite,
        }
    return addAdmin(payload).then(operations.eagerAdd)
  }
  const addMemberLegacy = useAddAdminPrompt(operations.eagerAdd)
  const changeRoleWithoutPrompt = useChangeRole(operations.eagerReplace)

  return [
    {
      members,
      loading,
      error,
      selected,
    },
    {
      setSelected,
      changeRole,
      changeRoleWithoutPrompt,
      expelMember,
      removeMembers,
      addMember,
      addMemberLegacy,
    },
    events,
  ]
}

export default useAdminsCollection
