import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export default function useStripeAcountStatus() {
  // @ts-ignore
  const [request, { loading, error, result, called }] = useFetch('Error confirming your Stripe connection status.  Please refresh the page to try again')
  const org = useCurrentOrg()
  const getStatus = useCallback(() => {
    // @ts-ignore
    return request({
      method: 'get',
      url: `/organizations/${org.id}/donations/setup_status`,
    })
  }, [request])

  useEffect(() => {
    getStatus()
  }, [getStatus])

  return {
    result,
    loading,
    error,
    called,
  }
}
