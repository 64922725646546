import { NotificationBlastContact } from 'messages/hooks/useMessageOverview'
import { useMessageRecipientRowStyles } from 'messages/hooks/useStyles'
import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone'
import PhoneIcon from '@material-ui/icons/Phone'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import cn from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

interface RecipientRowProps {
  contact: NotificationBlastContact
  emailsOnly: boolean
  onRemove: () => void
}

const isBounced = ({ isEmailBounced, isPhoneBounced }: NotificationBlastContact, emailsOnly: boolean): boolean => {
  if (emailsOnly) return isEmailBounced
  return isEmailBounced || isPhoneBounced
}

const RecipientRowIcon = ({ contact, emailsOnly }: { contact: NotificationBlastContact; emailsOnly: boolean }) => {
  const classes = useMessageRecipientRowStyles()
  if (contact.loading) {
    return <HourglassFullTwoToneIcon className={classes.rowIcon} />
  }
  if (!contact.doNotContact) {
    if (contact.sendToPhone && !emailsOnly) {
      return <PhoneIcon className={classes.rowIcon} />
    } else if (contact.email) {
      return <AlternateEmailIcon className={classes.rowIcon} />
    }
  }
  return <FeedbackOutlinedIcon className={cn(classes.rowIcon, classes.error)} />
}

export const RecipientRow = ({ contact, emailsOnly, onRemove }: RecipientRowProps) => {
  const classes = useMessageRecipientRowStyles()
  return (
    <div className={classes.recipientRow}>
      <div className={classes.recipientContainer}>
        <RecipientRowIcon contact={contact} emailsOnly={emailsOnly} />{' '}
        <div className={classes.recipientNameContainer}>
          <div className={classes.recipientName}>
            {contact.firstName} {contact.lastName}
          </div>
          {contact.loading ? null : <RecipientErrorInfo contact={contact} emailsOnly={emailsOnly} />}
        </div>
      </div>
      <CloseIcon className={classes.closeIcon} onClick={onRemove} />
    </div>
  )
}

const RecipientErrorInfo = ({ contact, emailsOnly }: { contact: NotificationBlastContact; emailsOnly: boolean }) => {
  const classes = useMessageRecipientRowStyles()

  if (contactHasError({ contact, emailsOnly })) {
    return <div className={classes.recipientErrorInfo}>Invalid: {getReason({ contact, emailsOnly })}</div>
  }

  return null
}

const contactHasError = ({
  contact,
  emailsOnly,
}: {
  contact: NotificationBlastContact
  emailsOnly: boolean
}): boolean => {
  if (contact.doNotContact) {
    return true
  }
  if (contact.email) {
    return false
  }
  return !contact.sendToPhone || emailsOnly
}

const getReason = ({ contact, emailsOnly }: { contact: NotificationBlastContact; emailsOnly: boolean }) => {
  if (contact.doNotContact) {
    return 'Do Not Contact'
  }
  if (isBounced(contact, emailsOnly)) {
    return 'Bounced'
  }
  return 'No Contact Information on File'
}
