import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useCurrentOrg } from 'auth/hooks'
import useProfile from 'champion/hooks/useProfile'
import Loading from 'components/Loading'
import ProfileInfoCard from 'champion/components/ProfileInfoCard'
import { ChampionProfile } from 'champion/interfaces'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'
import { ProfileAccordion } from 'champion/pages/ProfileAccordion'
import { ProfileTabs } from './ProfileTabs'
import PageHeader from 'App/components/PageHeader'

const breakPoint = 1024

const useStyles = makeStyles(theme => ({
  back: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#0F5DB5',
    textDecoration: 'none',
    display: 'block',
    clear: 'both',
    marginLeft: '-20px',
    marginBottom: '20px',
    '& > img': {
      position: 'relative',
      top: '3px',
      left: '-2px',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '1100px',
    alignItems: 'start',
    gap: '50px',
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column',
    },
  },
  content: {
    width: 'calc(100% - 427px)',
    [theme.breakpoints.down(960)]: {
      width: '427px',
    },
  },
}))

interface ProfileViewProps {
  match: {
    params: {
      id: number
    }
  }
}

export const ProfileViewComponent = ({
  match: {
    params: { id },
  },
}: ProfileViewProps) => {
  const [profile, setProfile] = useState<ChampionProfile | null>(null)
  const currentOrg = useCurrentOrg()
  const { loading, getProfile } = useProfile({
    id,
    onSuccess: setProfile,
    organizationId: currentOrg.id,
    includeTags: true,
    includeGroups: true,
  })
  const classes = useStyles()
  const isSmall = useBreakPoint(breakPoint)

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.wrapper}>
      <ProfileInfoCard profile={profile as ChampionProfile} getProfile={getProfile} />
      <div className={classes.content}>
        {isSmall ? (
          <ProfileAccordion profile={profile as ChampionProfile} />
        ) : (
          <ProfileTabs profile={profile as ChampionProfile} />
        )}
      </div>
    </div>
  )
}

export const ProfileView = (props: ProfileViewProps) => {
  return (
    <PageHeader title="Profile" subTitle="Back To Champions" backLink="/champions">
      <ProfileViewComponent {...props} />
    </PageHeader>
  )
}
