import React from 'react'

import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import { NamedRef } from 'civic-champs-shared/common/types'
import { IconComponent } from 'civic-champs-shared/core/icon'
import { SurveyQuestionnaire, SurveyQuestionnaireStatus } from 'surveys/types'
import { CellProps } from 'react-table'

export interface QuestionnaireEditCellProps {
  onDuplicate?: (questionSet: NamedRef) => void
  onDelete?: (questionSet: NamedRef) => void
  cell: CellProps<SurveyQuestionnaire>
}

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 10,
  },
  icon: {
    color: '#0F5DB5',
  },
  deleteIcon: {
    color: '#ba1b1b',
    height: '18px',
    width: '18px',
    cursor: 'pointer',
  },
  deleteHeaderIcon: {
    color: '#000000',
    height: '18px',
    width: '18px',
  },
}))

export const QuestionnaireEditHeader = (): JSX.Element => {
  const classes = useStyles()

  return (
    <Grid container justify="space-between" className={classes.cellGrid}>
      <Grid item>
        <IconButton className={classes.iconButton} disabled>
          <IconComponent name="copy" customColor="#000000" height={18} width={18} />
        </IconButton>
      </Grid>

      <Grid item>
        <IconButton className={classes.iconButton} disabled>
          <DeleteIcon className={classes.deleteHeaderIcon} />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export const QuestionnaireEditCell = ({ cell, onDuplicate, onDelete }: QuestionnaireEditCellProps): JSX.Element => {
  const classes = useStyles()
  const questionSet = cell.row.original

  return (
    <Grid container justify="space-between" className={classes.cellGrid}>
      <Grid item>
        <Tooltip title="Duplicate">
          <IconButton
            className={classes.iconButton}
            disabled={!questionSet || !onDuplicate}
            onClick={e => {
              e.stopPropagation()
              if (questionSet && onDuplicate) {
                onDuplicate(questionSet)
              }
            }}
          >
            <IconComponent name="copy" customColor="#0F5DB5" height={18} width={18} />
          </IconButton>
        </Tooltip>
      </Grid>

      {questionSet.status === SurveyQuestionnaireStatus.INACTIVE && !questionSet.inUse && (
        <Grid item>
          <Tooltip title="Delete">
            <IconButton
              className={classes.iconButton}
              disabled={!questionSet || !onDelete}
              onClick={e => {
                e.stopPropagation()
                if (questionSet && onDelete) {
                  onDelete(questionSet)
                }
              }}
            >
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}
