import React from 'react'
import { ExportButton } from 'core/table/components'
import format from 'civic-champs-shared/utils/format'

const filename = 'tags.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Created On', key: 'createdAt' },
  { label: 'Created By', key: 'createdBy' },
  { label: 'Status', key: 'status' },
]

const formatData = (tags: any[]) =>
  tags.map(({ name, createdAt, createdBy, status }) => ({
    name,
    createdAt: format.datetime(createdAt),
    createdBy: format.name(createdBy),
    status,
  }))

export const ExportTagsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => (
  <ExportButton data={data} disabled={disabled} formatData={formatData} headers={headers} filename={filename} />
)

export default ExportTagsButton
