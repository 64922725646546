import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import isUndefined from 'lodash/isUndefined'

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
    width: 150,
    height: 150,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  progressContainer: {
    width: 150,
    height: 150,
    position: 'absolute',
    top: 'calc(50% - 75px)',
    left: 'calc(50% - 75px)',
    fontSize: '50px',
    fontFamily: 'Nunito',
    lineHeight: '150px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}))

export default function Loading(props) {
  const classes = useStyles()
  const progress = props.progress || 0
  const progressTrack = useRef({
    lastProgress: progress,
    lastUpdatedAt: new Date(),
    maxProgress: 0,
  })
  const [onScreenProgress, setOnScreenProgress] = useState(progress)
  useEffect(() => {
    if (props.animateProgress && props.progress) {
      setOnScreenProgress(props.progress)
      const progressDiff = props.progress - progressTrack.current.lastProgress
      const timeDiff = new Date() - progressTrack.current.lastUpdatedAt
      progressTrack.current = {
        lastProgress: props.progress,
        lastUpdatedAt: new Date(),
        maxProgress: Math.min(99, props.progress + progressDiff),
      }
      const interval = setInterval(() => {
        setOnScreenProgress(progress => {
          const newProgress = progress + 1
          if (newProgress > progressTrack.current.maxProgress) {
            clearInterval(interval)
            return progress
          } else {
            return newProgress
          }
        })
      }, Math.round(timeDiff / progressDiff))
      return () => clearInterval(interval)
    }
  }, [props.progress, props.animateProgress]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.progress} size={props.size || 150} />
      {!isUndefined(props.progress) && <div className={classes.progressContainer}>{onScreenProgress}%</div>}
    </div>
  )
}
