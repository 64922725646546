import React, { useState, useCallback } from 'react'
import { FormGroup, FormControlLabel, Switch, CircularProgress } from '@material-ui/core'

import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useReloadLocalFeatures } from '../hooks'

export const useUpdateGloballyDisabled = feature => {
  const [fetch, status] = useFetch('Error toggling Globally Disabled')

  const updateGloballyDisabled = useCallback(
    value => {
      return new Promise((resolve, reject) => {
        fetch({
          method: 'post',
          // TODO: if we want to allow this toggle for for other features, we need to refactor this function to use a more generic endpoint
          // but ensure that the specific actions triggered by this endpoint still happen
          url: '/salsa/global-disable',
          config: {
            body: { disabled: !!value },
          },
          onSuccess: resolve,
          onError: reject,
        })
      })
    },
    [fetch],
  )

  return [updateGloballyDisabled, status]
}

export default function GloballyDisabledSwitch(props) {
  const { feature } = props
  const [disabled, setDisabled] = useState(!!feature.globallyDisabledAt)
  const [updateGloballyDisabled, { loading }] = useUpdateGloballyDisabled(feature)

  const reload = useReloadLocalFeatures()

  const toggleDisabled = useCallback(async () => {
    if (loading) return

    let prev = disabled
    setDisabled(!disabled)

    try {
      await updateGloballyDisabled(!disabled)
      reload()
    } catch (error) {
      // useFetch will have an error snackbar
      setDisabled(prev)
    }
  }, [loading, disabled, updateGloballyDisabled, setDisabled, reload])

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch disabled={loading} checked={disabled} onChange={toggleDisabled} />}
        label={
          <>
            Globally Disabled
            {loading && <CircularProgress size="sm" />}
          </>
        }
      />
    </FormGroup>
  )
}
