import React from 'react'
import { Grid, Box, makeStyles } from '@material-ui/core'
import { useTable } from 'react-table'

export const DefaultCell = (props) => <>{props.value}</>

const useStyles = makeStyles({
    root: {

    },
    cell: {

    },
    label: {
        fontWeight: 'bold',
        color: 'grey',
        marginBottom: '4px'
    }
})

//TODO does it make sense to use the whole react table thing here?
//     could make a "thinner" version that re-uses the format
export default function PropertyGrid(props) {
    const { properties, object, ...remainingProps } = props

    const table = useTable({
        columns: properties,
        data: [object]
    })

    const classes = useStyles()

    return (
        <Grid 
            container 
            className={classes.root} 
            spacing={2}
            {...remainingProps}
        >
            {table.rows.map(row => {
                table.prepareRow(row)
                return row.cells.map( cell => (
                    <Grid 
                        item
                        className={classes.cell}
                        xs={3}
                        {...cell.getCellProps()}
                    >
                        <div className={classes.label}>
                            {cell.column.render('Header')}
                        </div>
                        {cell.render('Cell')}
                    </Grid>
                ))
            })}
        </Grid>
    )
}