import { useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'react-use'

const getIsSmall = (breakPoint: number, width?: number) => (width ? width <= breakPoint : false)

export function useBreakPoint(breakPoint: number, vertical?: boolean) {
  const { width, height } = useWindowSize()
  const [isSmall, setIsSmall] = useState<boolean>(getIsSmall(breakPoint, vertical ? height : width))
  useEffect(() => {
    setIsSmall(getIsSmall(breakPoint, vertical ? height : width))
  }, [breakPoint, height, vertical, width])
  return useMemo(() => isSmall, [isSmall])
}
