import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MentoringFeedbackType, ProgramMeetingType } from 'new-mentorship/types'
import useStyles from 'new-mentorship/hooks/useStyles'
import useColumns from 'new-mentorship/hooks/useMeetingsColumns'
import { NumberCell } from 'core/table/cells'
import { Row, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import moment from 'moment-timezone'
import PageTitle from 'components/PageTitle'
import SummaryCards from 'core/table/components/SummaryCards'
import Loading from 'civic-champs-shared/core/Loading'
import Search from 'new-mentorship/helpers/Search'
import reduce from 'lodash/reduce'
import DEFAULT_FILTERS from 'core/table/filters'
import { Box, Button, Grid, Popover } from '@material-ui/core'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { Icon } from '@mdi/react'
import { mdiFilterMenu } from '@mdi/js'
import { FiltersModal, operatorTypes, useFiltersFromQuery } from 'core/table/components/FiltersModal'
import PagedTable from 'core/table/components/PagedTable'
import { useDateRangeFilter } from 'filtering/hooks'
import DateRangeFilter from 'components/filter/DateRangeFilter'
// @ts-ignore
import { ANCHOR_LEFT } from 'react-dates/constants'
import { RouteComponentProps } from 'react-router'
import map from 'lodash/map'
import useFetchMeetingsGrid from 'new-mentorship/hooks/useFetchMeetingsGrid'
import useSelectColumn from 'core/table/table-hooks/useSelectColumn'
import { ExportCsvButton } from 'external-datafeed/components/ExportCsvButton'
import isBoolean from 'lodash/isBoolean'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import capitalize from 'lodash/capitalize'
import { useCurrentOrg } from 'auth/hooks'
import SendNotificationModal from 'new-mentorship/components/SendNotificationModal'
import { useUISettings } from 'hooks/useUISettings'

const csvHeaders = [
  { label: 'Date', key: 'startsAt' },
  { label: 'Mentor', key: 'mentor' },
  { label: 'Mentee', key: 'mentee' },
  { label: 'Program', key: 'program' },
  { label: 'Type', key: 'type' },
  { label: 'Mentor Rating', key: 'mentorRating' },
  { label: 'Mentee Rating', key: 'menteeRating' },
  { label: 'Meeting Time', key: 'duration' },
]

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'duration':
      return [
        { value: operatorTypes.LESS_THAN, displayValue: 'Less than' },
        { value: operatorTypes.LESS_OR_EQUAL, displayValue: 'Less than or equal to' },
        { value: operatorTypes.EQUALS, displayValue: 'Equal to' },
        { value: operatorTypes.GREATER_OR_EQUAL, displayValue: 'Greater than or equal to' },
        { value: operatorTypes.GREATER_THAN, displayValue: 'Greater than' },
      ]

    case 'mentor':
    case 'mentee':
    case 'program':
    case 'type':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]

    default:
      return []
  }
}

const cards = [
  {
    Header: 'Mentorship Meetings ',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    Header: 'Mentorship Moments',
    accessor: 'momentCount',
    Cell: NumberCell,
  },
  {
    Header: 'Average Meeting Time',
    accessor: 'averageMeetingTime',
    Cell: NumberCell,
  },
  {
    Header: 'Positive Experiences',
    accessor: 'positiveFeedbackPercent',
  },
  {
    Header: 'Negative Experiences',
    accessor: 'negativeFeedbackPercent',
  },
]

const escapeValue = (value: string): string => value.replace(/"/g, '""')

const getValue = (value: string | string[] | number): string | number =>
  isNumber(value) ? value : isArray(value) ? value.map(escapeValue).join(',') : escapeValue(value)

const useSummary = (rows: Row<any>[]) =>
  useMemo(() => {
    const { meetingCount, momentCount, totalMeetingTime, positiveFeedbackCount, negativeFeedbackCount } = reduce(
      rows,
      (acc, { original: { type, duration, mentorRating, menteeRating } }: Row<any>) => ({
        meetingCount: acc.meetingCount + (type === ProgramMeetingType.meeting ? 1 : 0),
        momentCount: acc.momentCount + (type === ProgramMeetingType.moment ? 1 : 0),
        totalMeetingTime: acc.totalMeetingTime + duration,
        positiveFeedbackCount:
          acc.positiveFeedbackCount + (mentorRating === true ? 1 : 0) + (menteeRating === true ? 1 : 0),
        negativeFeedbackCount:
          acc.negativeFeedbackCount + (mentorRating === false ? 1 : 0) + (menteeRating === false ? 1 : 0),
      }),
      {
        meetingCount: 0,
        momentCount: 0,
        totalMeetingTime: 0,
        positiveFeedbackCount: 0,
        negativeFeedbackCount: 0,
      },
    )
    const feedbackCount = positiveFeedbackCount + negativeFeedbackCount
    return {
      meetingCount,
      momentCount,
      averageMeetingTime: meetingCount ? Math.round(totalMeetingTime / meetingCount) : 0,
      positiveFeedbackPercent:
        (feedbackCount ? Math.round((positiveFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
      negativeFeedbackPercent:
        (feedbackCount ? Math.round((negativeFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
    }
  }, [rows])

export const MentorshipMeetings = ({ location, history }: RouteComponentProps) => {
  const { filters, filterRows: initialFilterRows } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [filtersModalVisible, setFiltersModalVisible] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [dateRange, setDateRange] = useDateRangeFilter()
  const anchorEl = useRef(null)
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const [
    fetchMeetings,
    {
      loading,
      result: { meetings: meetingsUnmapped, questionColumns },
    },
  ] = useFetchMeetingsGrid()
  useEffect(() => {
    fetchMeetings()
  }, [fetchMeetings])

  const meetings = useMemo(
    () =>
      map(
        meetingsUnmapped,
        ({ id, startsAt, endsAt, type, mentorName, menteeName, programName, mentorRating, menteeRating, answers }) => ({
          id,
          startsAt,
          mentor: mentorName,
          mentee: menteeName,
          program: programName,
          type,
          duration: Math.round(moment.duration(moment(endsAt).diff(moment(startsAt))).as('minutes')),
          mentorRating,
          menteeRating,
          answers,
        }),
      ),
    [meetingsUnmapped],
  )

  const classes = useStyles()

  const columns: any = useColumns()

  const table = useTable(
    {
      data: meetings,
      columns,
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      // @ts-ignore
      initialState: { filters },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn,
  )

  useEffect(() => {
    table.setFilter('startsAt', dateRange)
  }, [dateRange, table])

  const selectedRow = table.selectedFlatRows[0]?.original

  const fullCsvHeaders = useMemo(() => {
    const basicHeaders = [...csvHeaders]
    for (const type of Object.values(MentoringFeedbackType)) {
      if (questionColumns[type]) {
        basicHeaders.push({ label: `${capitalize(type)} Questions`, key: `${type}Questions` })
        // @ts-ignore
        for (const { id, value } of questionColumns[type]) {
          basicHeaders.push({ label: value, key: `${type}Question${id}` })
        }
      }
    }
    return basicHeaders
  }, [questionColumns])

  const { result: org } = useCurrentOrg()
  const prepareCsv = useCallback(
    () => ({
      filename: 'mentorship-meetings.csv',
      headers: fullCsvHeaders,
      data: table.selectedFlatRows.map(
        ({ original: { mentorRating, menteeRating, startsAt, answers, ...rest } }: any) => {
          const row = {
            ...rest,
            startsAt: moment.tz(startsAt, org?.timeZone).format('MM/DD/YYYY hh:mm:ss a'),
            mentorRating: isBoolean(mentorRating) ? (mentorRating ? 'positive' : 'negative') : '',
            menteeRating: isBoolean(menteeRating) ? (menteeRating ? 'positive' : 'negative') : '',
          }
          for (const type of Object.values(MentoringFeedbackType)) {
            if (questionColumns[type]) {
              // @ts-ignore
              for (const { id } of questionColumns[type]) {
                const value = answers[type]?.[id]?.value || ''
                if (value) row[`${type}Question${id}`] = getValue(value)
              }
            }
          }
          return row
        },
      ),
    }),
    [fullCsvHeaders, org, questionColumns, table.selectedFlatRows],
  )

  const summary = useSummary(table.rows)

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <div className={classes.header}>
          <Grid xs={4} container item alignItems="center" justify="flex-start" direction="row">
            <PageTitle title="Mentor Meetings" />
          </Grid>
          <Grid xs={8} container item alignItems="center" spacing={0} justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onChange={table.setGlobalFilter} initialValue={table.state.globalFilter} />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Box className={classes.dateBox}>
                <DateRangeFilter
                  initialValues={dateRange}
                  onFilterApplied={setDateRange}
                  anchorDirection={ANCHOR_LEFT}
                  noBorder
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <SummaryCards summary={summary} cards={cards} />
        {loading ? (
          <Loading />
        ) : (
          <section id="table" className={classes.table}>
            <Grid xs={12} container alignItems="center" spacing={0} justify="space-between" direction="row">
              <Grid xs={3} container item alignItems="center" spacing={0} justify="flex-start" direction="row">
                {/*
              // @ts-ignore */}
                <Box ml={2} display="inline-block" className={classes.filterBox} ref={anchorEl}>
                  <AddButton
                    onClick={() => setFiltersModalVisible(true)}
                    icon={<Icon path={mdiFilterMenu} size={1} />}
                    title={
                      <Box>
                        <sup>
                          {table.columns.filter((column: any) => column.filterValue && !column.disableFilters).length ||
                            ''}
                        </sup>
                        <Box component="span" pl={1}>
                          FILTERS
                        </Box>
                      </Box>
                    }
                  />
                </Box>
                <Popover
                  keepMounted
                  BackdropProps={{}}
                  open={filtersModalVisible}
                  anchorEl={anchorEl.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  // @ts-ignore
                  transformOrigin={{
                    vertical: -20,
                  }}
                >
                  <FiltersModal
                    columns={columns}
                    table={table}
                    history={history}
                    filterRows={initialFilterRows}
                    getOperatorOptions={getOperatorOptions}
                    onClose={() => setFiltersModalVisible(false)}
                  />
                </Popover>{' '}
              </Grid>
              <Grid
                xs={9}
                container
                item
                alignItems="center"
                spacing={1}
                justify="flex-end"
                direction="row"
                className={classes.rightButtonsGrid}
              >
                {showAdminItems && (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={table.selectedFlatRows.length !== 1}
                      onClick={() => setModalOpen(true)}
                    >
                      Send Notification
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <ExportCsvButton disabled={table.selectedFlatRows.length === 0} onPrepareCsv={prepareCsv}>
                    Export CSV
                  </ExportCsvButton>
                </Grid>
              </Grid>
            </Grid>
            <PagedTable {...table} wrapHeader />
          </section>
        )}
      </div>
      <SendNotificationModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        menteeName={selectedRow?.mentee}
        mentorName={selectedRow?.mentor}
        meetingId={selectedRow?.id}
      />
    </div>
  )
}

export default MentorshipMeetings
