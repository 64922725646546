import * as React from 'react'
import { useEffect } from 'react'
import Popup from '../../../../components/popup/index'
import Button from '../../../../../components/button/index'

import './index.scss'
import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

interface EditRecurringPopupProps {
  handleClose: () => void
  handleSave: () => void
  isSendingUpdateRequest: boolean
}

const defaultValue = 'all'

const EditRecurringPopup = (props: EditRecurringPopupProps | any) => {
  useEffect(() => {
    props.setEventField('editMode', defaultValue)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { handleClose, handleSave, isSendingUpdateRequest } = props
  return (
    <Popup
      handleCloseModal={handleClose}
      shouldCloseOnOverlayClick
      hideCloseButton={false}
      width={583}
      className="edit-popup"
      contentClassName="edit-popup__content"
    >
      <h3 className="edit-popup__title">Edit Event</h3>
      <Box mb={4}>
        <RadioGroup
          onChange={e => {
            props.setEventField('is_recurring', e.target.value === 'all')
            props.setEventField('editMode', e.target.value)
          }}
          defaultValue={defaultValue}
        >
          <FormControlLabel value="all" control={<Radio />} label="All events" />
          <FormControlLabel value="single" control={<Radio />} label="Only this event" />
        </RadioGroup>
      </Box>
      <div className="button-area">
        <Button type="text" className="edit-popup__button" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="edit-popup__button"
          color="secondary"
          onClick={handleSave}
          isLoading={isSendingUpdateRequest}
          customBackgroundColor="#FF0101"
        >
          Ok
        </Button>
      </div>
    </Popup>
  )
}

export default EditRecurringPopup
