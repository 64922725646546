import React from 'react';
import { makeStyles, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import GroupRequirementsList from './GroupRequirementsList'
import { PermissionOptions } from '../../Event/components/opportunity/GroupPicker/types'

const useStyles = makeStyles(() => ({
  settingsContainer: {
    padding: '0 10px',
  },
  settingsLabel: {
    fontSize: '18px',
    fontWeight: 600,
    marginRight: '10px',
    marginTop: '5px',
  },
  previewLabel: {
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: 600,
    marginRight: '10px',
  },
}))

const ApplicationLinkLabel = ({ group }) => (
  <label>
    <span>
      <b>{group.useApplicationLink ? 'Active Application Link' : 'Not accepting application'}</b>:{' '}
    </span>
    {group.useApplicationLink
      ? 'The link for the application is at the top of the page.'
      : 'Anyone going to the link will receive the following message:'}
  </label>
)

const GroupOnboarding = (props) => {
  const { group } = props
  const classes = useStyles()

  const onAppLinkToggle = (useApplicationLink) => props.groupUpdate({ useApplicationLink })

  const canChangeApplyGroup = ![PermissionOptions.PERMANENTLY_CLOSED, PermissionOptions.PERMANENTLY_OPEN].includes(
    group.permissionOptions,
  )

  return (
    <Grid container direction="column" className={classes.settingsContainer}>
      <Grid item xs={12} s={6}>
        {canChangeApplyGroup && (
          <FeatureEnabled code="VolunteerApplication">
            <Grid container direction="row" alignItems="baseline">
              <Grid item>
                <Typography variant="body1" className={classes.settingsLabel}>
                  Application Link:
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      name="appLink"
                      checked={group.useApplicationLink}
                      onChange={(e) => onAppLinkToggle(e.target.checked)}
                    />
                  }
                  label={<ApplicationLinkLabel group={group} />}
                />
                {!group.useApplicationLink && (
                  <Typography variant="body1" className={classes.previewLabel}>
                    We are currently not accepting applications for {group.name} please check back later
                  </Typography>
                )}
              </Grid>
            </Grid>
          </FeatureEnabled>
        )}
      </Grid>
      <Grid item xs={12} s={6}>
        <Grid container direction="column">
          <Grid item>
            <GroupRequirementsList group={group} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupOnboarding;
