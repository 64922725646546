import React from 'react'
import classNames from 'classnames'
import './index.scss'

const Checkbox = (props) => {
  const {
    value,
    label,
    onClick,
    style,
  } = props

  const classes = classNames(
    'custom-checkbox',
    {'custom-checkbox--active': value}
  )

  const handleClick = () => {
    onClick(!value)
  }

  return (
    <div className='custom-checkbox-wrap' onClick={handleClick} style={{...style}}>
      <div className={classes}>
        <span className='custom-checkbox__icon'/>
      </div>
      {label && <span className='custom-checkbox__title'>{label}</span>}
    </div>
  )
}

export default Checkbox
