import { useCallback, useEffect, useState } from 'react'

import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'

import { useCurrentOrg } from 'auth/hooks'
import { useAddActivityPrompt, useEditActivityPrompt, useRemoveActivitiesPrompt } from './index'
import { useFeatureEnabled } from '../../../core/feature/hooks'
import { loadInChunks } from '../../../utils/loadInChunks'

export const useActivitiesCollection = filters => {
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [request, { error }] = useFetch()
  const [activities, operations, events] = useRemoteCollection()
  const isVolunteerTagsEnabled = useFeatureEnabled('ActivityVolunteerTags')
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection, eagerAdd, eagerReplace, eagerRemoveMany } = operations

  const getActivities = useCallback(
    async filters => {
      const { startDate, endDate, opportunityId, locationId, mapOccurrencesApproximately } = filters
      const params = {}
      if (mapOccurrencesApproximately) {
        params.mapOccurrencesApproximately = true
      }

      if (startDate) {
        params.from = format.dateForUrl(startDate)
      }

      if (endDate) {
        params.to = format.dateForUrl(endDate)
      }

      if (isVolunteerTagsEnabled) {
        params.includeVolunteerTags = true
      }

      let updatedActivities = []
      if (opportunityId || locationId) {
        setLoadingProgress(0)
        setLoading(true)
        updatedActivities = await request({
          url: opportunityId
            ? `/opportunities/${opportunityId}/activities`
            : `/organizations/${organization.id}/locations/${locationId}/activities`,
          config: {
            queryStringParameters: params,
          },
        })
        setLoadingProgress(100)
        setLoading(false)
      } else {
        updatedActivities = await loadInChunks({
          getCount: () =>
            request({
              url: `/organizations/${organization.id}/activity-count`,
              config: {
                queryStringParameters: params,
              },
            }),
          getChunk: ({ limit, offset }) =>
            request({
              url: `/organizations/${organization.id}/activities`,
              config: {
                queryStringParameters: { ...params, limit, offset },
              },
            }),
          limit: 1000,
          setLoadingProgress,
          setLoading,
        })
      }

      syncCollection(updatedActivities)
      setInitiallyLoaded(true)
    },
    [isVolunteerTagsEnabled, organization.id, request, syncCollection],
  )
  useEffect(() => {
    getActivities(filters)
  }, [filters, getActivities])

  const addActivity = useAddActivityPrompt(eagerAdd)
  const editActivity = useEditActivityPrompt(eagerReplace)
  const removeActivities = useRemoveActivitiesPrompt(eagerRemoveMany)

  return [
    {
      activities,
      loading,
      loadingProgress,
      error,
      initiallyLoaded,
    },
    {
      addActivity,
      editActivity,
      removeActivities,
    },
    events,
  ]
}

export default useActivitiesCollection
