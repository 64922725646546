import * as React from 'react'
import { InputLabel, Grid } from '@material-ui/core'
import { useState } from 'react'
import './index.scss'
import moment from 'moment'
import TimeInputPolyfill from 'react-time-input-polyfill'
import { debounce, uniqueId } from 'lodash'

const DEBOUNCE_WAIT = 250

export interface Props {
  onChange: (startTime: string, endTime: string) => void
  startTime: string
  endTime: string
  disabled?: boolean
}

// 5 minutes
const STEP = 300
const FORMAT_TO_SAVE = 'LT'
const FORMAT_FOR_PICKER = 'HH:mm'

const datePickerMoment = (time: string) => moment(time, FORMAT_FOR_PICKER)

export const validateTimeSelection = (startTime: any, endTime: any, format = FORMAT_TO_SAVE): string => {
  const toMoment = (time: any) => moment(time, format)
  const startIsAfterEndTime = toMoment(startTime).isSameOrAfter(toMoment(endTime))
  if (startIsAfterEndTime) {
    return 'End time must be after Start time'
  } else if (!startTime || startTime === 'Invalid date') {
    return 'Please enter a valid Start Time'
  } else if (!endTime || endTime === 'Invalid date') {
    return 'Please enter a valid End Time'
  } else {
    return ''
  }
}

export const TimeRangePicker = (props: Props) => {
  const { onChange, startTime, endTime, disabled = false } = props

  const fromSavedTime = (time?: string, isEnd?: boolean) => {
    if (!time) {
      return isEnd ? '10:00' : '09:00'
    }
    return moment(time, FORMAT_TO_SAVE).format(FORMAT_FOR_PICKER)
  }

  const toSavedTime = (time: string) => {
    return datePickerMoment(time).format(FORMAT_TO_SAVE)
  }

  const [startId] = useState<string>( () => uniqueId('range-picker-start-') )
  const [endId] = useState<string>( () => uniqueId('range-picker-end-') )

  const [selectedStartTime, setSelectedStartTime] = useState<string>(fromSavedTime(startTime))
  const [selectedEndTime, setSelectedEndTime] = useState<string>(fromSavedTime(endTime, true))
  const [errorMessage, setErrorMessage] = useState<string>(validateTimeSelection(fromSavedTime(startTime), fromSavedTime(endTime, true), FORMAT_FOR_PICKER))



  const handleTimeChange = (startTime: any, endTime: any) => {
    setSelectedStartTime(startTime)
    setSelectedEndTime(endTime)
    const error = validateTimeSelection(startTime, endTime, FORMAT_FOR_PICKER)
    setErrorMessage(error)
    onChange(toSavedTime(startTime), toSavedTime(endTime))
  }

  const handleChangeStartTime = (newStartTimeEvent: {value: string}) => {
    if (newStartTimeEvent.value === selectedStartTime) {
      // to avoid an infinite loop
      return
    }
    handleTimeChange(newStartTimeEvent.value, selectedEndTime)
  }

  const handleChangeEndTime = (newEndTimeEvent: {value: string}) => {
    if (newEndTimeEvent.value === selectedEndTime) {
      // to avoid an infinite loop
      return
    }
    handleTimeChange(selectedStartTime, newEndTimeEvent.value)
  }

  return (
    <>
      <Grid container item xs={12} spacing={0} style={{ marginRight: '10px' }}>
        <Grid item xs={5}>
          <Grid container direction="row">
            <InputLabel
              htmlFor={startId}
              shrink={true}
              className="time-picker_label"
              style={{
                left: '12px',
                width: '42px',
              }}
              disabled={disabled}
            >
              Start
            </InputLabel>
            <TimeInputPolyfill
              id={startId}
              label="Start"
              variant="outlined"
              className={`time-picker_input ${errorMessage ? 'time-picker_error_border' : ''}`}
              value={selectedStartTime}
              onChange={debounce(handleChangeStartTime, DEBOUNCE_WAIT)}
              step={STEP}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={5}>
          <Grid container direction="row">
            <InputLabel
              htmlFor={endId}
              shrink={true}
              className="time-picker_label"
              style={{
                left: '11px',
                width: '35px',
              }}
              disabled={disabled}
            >
              End
            </InputLabel>
            <TimeInputPolyfill
              id={endId}
              label="End"
              variant="outlined"
              className={`time-picker_input ${errorMessage ? 'time-picker_error_border' : ''}`}
              value={selectedEndTime}
              onChange={debounce(handleChangeEndTime, DEBOUNCE_WAIT)}
              step={STEP}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <p className="time-picker_error">{errorMessage}</p>
        </Grid>
      </Grid>
    </>
  )
}
