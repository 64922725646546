import React, { useMemo } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import useInvitations from '../hooks/useInvitations'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import format from 'civic-champs-shared/utils/format'
import { capitalize } from 'lodash'
import { WithLinkCell, DateCell } from 'core/table/cells'

const columns = [
  {
    id: 'createdAt',
    Header: 'Date',
    accessor: 'createdAt',
    Cell: WithLinkCell(DateCell, id => `/invitations/${id}`),
    filter: 'dateRange',
  },
  { Header: 'ID', accessor: 'id' },
  { Header: 'Person ID', accessor: 'person.id' },
  { Header: 'First Name', accessor: 'person.givenName' },
  { Header: 'Last Name', accessor: 'person.familyName' },
  {
    id: 'contact',
    Header: 'Contact',
    accessor: original =>
      original.method === 'email' ? original.recipientEmail : format.phoneNumber(original.recipientPhoneNumber),
  },
  { id: 'status', Header: 'Status', accessor: original => capitalize(original.displayStatus || original.invitationStatus) },
]

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.person.givenName',
      'original.person.familyName',
      'original.recipientEmail',
      'original.recipientPhoneNumber',
      'original.recipientHomePhoneNumber',
      'original.person.id',
      'original.id',
      'original.invitationStatus',
      'original.displayStatus',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const InvitationsOverview = props => {
  const [invitations, { loading }] = useInvitations()

  const [dateRange, setDateRange] = useDateRangeFilter()

  const table = useTable(
    {
      data: useMemo(() => invitations, [invitations]),
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid item xs={4}>
            <Typography variant="h4">Invitations</Typography>
          </Grid>
          <Grid xs={8} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px'}}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
