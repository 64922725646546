import map from 'lodash/map'

import { Opportunity, OpportunityApiResponse } from 'Event/interfaces'
import { mapEventGeofencingToGeofencing } from 'utils/event'

export const mapOpportunities = (opportunity: OpportunityApiResponse[]): Opportunity[] => {
  return map(opportunity, ({ geofencing, ...opportunity }) => ({
    ...opportunity,
    geofencing: geofencing && mapEventGeofencingToGeofencing(geofencing),
  }))
}
