import React, { useMemo, useState } from 'react'
import { Activity } from 'champion/utils/interface'
import dayjs from 'utils/dayjs'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { DEFAULT_TIME_ZONE } from 'civic-champs-shared/utils/format'
import { useReflectionCalendarData } from 'champion/hooks/useReflectionCalendarData'
import { makeStyles } from '@material-ui/styles'
import { CalendarSelect } from 'civic-champs-shared/core/CalendarSelect'
import { ReflectionCalendarTable } from 'champion/components/ReflectionCalendarTable'
import { ReflectionsList } from 'champion/components/ReflectionsList'

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '20px',
  },
  dates: {
    display: 'flex',
    gap: '20px',
  },
})

export const ReflectionCalendar = ({ activities }: { activities: Activity[] }) => {
  const organization = useCurrentOrg()
  const [today, years, timeZone] = useMemo(() => {
    const timeZone = organization?.timezone || DEFAULT_TIME_ZONE
    const today = dayjs().tz(timeZone)
    const years = new Set<number>([today.year()])
    for (const activity of activities) {
      years.add(dayjs(activity.occurredAt).tz(timeZone).year())
    }
    const sortedYears = Array.from(years)
    sortedYears.sort()
    return [today, sortedYears, timeZone]
  }, [activities, organization])
  const [date, setDate] = useState(today.date(1))
  const [selectedDate, setSelectedDate] = useState(today)

  const data = useReflectionCalendarData(date, activities)

  const selectedActivities = useMemo(
    () => activities.filter(({ occurredAt }) => dayjs(occurredAt).tz(timeZone).isSame(selectedDate, 'day')),
    [activities, selectedDate, timeZone],
  )

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.dates}>
        <CalendarSelect
          onChange={e => setDate(date.month(e.target.value as number))}
          id="month"
          value={date.month()}
          options={months.map(month => ({ value: month, label: today.month(month).format('MMMM') }))}
        />
        <CalendarSelect
          onChange={e => setDate(date.year(e.target.value as number))}
          id="month"
          value={date.year()}
          options={years.map(year => ({ value: year, label: year.toString() }))}
        />
      </div>
      <ReflectionCalendarTable
        data={data}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        today={today}
      />
      <ReflectionsList activities={selectedActivities} />
    </div>
  )
}
