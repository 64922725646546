import {isFunction} from 'lodash'
import React, {useMemo} from 'react'
import {withStyles, Button} from '@material-ui/core'

export const ApprovalButton = withStyles({
    root: {
        background: '#BFDF57',
        color: 'black'
    },
    label: {
        textTransform: 'uppercase'
    }
})((props) => (
    <Button
        variant="contained"
        {...props}
    />
))


const useGroupDetailApprovalButtonCell = (onGotoApprovals, group) => useMemo(() => {
    const ApprovalButtonCell = ({cell}) => {

        const handleClick = (e) => {
            if (isFunction(onGotoApprovals)) onGotoApprovals(group, cell.row.values.status, cell.row.original.id)
            e.preventDefault()
        }

        return cell.row.values.status === 'Applicant' ?
            <ApprovalButton onClick={handleClick}>
                Approve
            </ApprovalButton>
            :
            <Button onClick={handleClick} style={{textDecoration: "underline"}}>
                VIEW
            </Button>

    }

    return ApprovalButtonCell
}, [onGotoApprovals, group])

export default useGroupDetailApprovalButtonCell;
