import { makeStyles } from '@material-ui/styles'

export const useActivityImportDialogStyles = makeStyles({
  sectionTitle: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    '&$noMargin': {
      marginBottom: 0,
    },
  },
  noMargin: {},
  double: {
    display: 'flex',
    gap: '24px',
  },
  section: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      marginBottom: '14px',
    },
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
})
