import React, { useState, useCallback } from 'react';
import { FormGroup, FormControlLabel, Switch, CircularProgress } from '@material-ui/core';

import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useReloadLocalFeatures } from '../hooks';

export const useUpdateEnabledByDefault = feature => {
  const [fetch, status] = useFetch()

  const updateEnabled = useCallback(
    value => {
      //TODO why doesn't fetch also return a promise?
      return new Promise((resolve, reject) => {
        fetch({
          method: 'put',
          url: `/settings/features/${feature.id}`,
          config: {
            body: { enabledByDefault: !!value },
          },
          onSuccess: resolve,
          onError: reject,
        })
      })
    },
    [fetch, feature],
  )

  return [updateEnabled, status]
}

export default function EnabledByDefaultSwitch(props) {
  const { feature } = props

  const [enabled, setEnabled] = useState(feature.enabledByDefault)
  const [updateEnabled, { loading }] = useUpdateEnabledByDefault(feature)

  const reload = useReloadLocalFeatures()

  const toggleEnabled = useCallback(async () => {
    if (loading) return

    let prev = enabled
    setEnabled(!enabled)

    try {
      await updateEnabled(!enabled)
      reload()
    } catch (error) {
      console.error(error)
      setEnabled(prev)

      //TODO flash the background so it shows an error color
      //TODO display the error as a toast
    }
  }, [loading, enabled, updateEnabled, setEnabled, reload])

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch disabled={loading} checked={enabled} onChange={toggleEnabled} />}
        label={
          <>
            Enabled by Default
            {loading && <CircularProgress size="sm" />}
          </>
        }
      />
    </FormGroup>
  )
}
