import React, { useEffect } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Loading from 'components/Loading'
import * as yup from 'yup'
import { Remove, Add } from '@material-ui/icons'
import { TextField } from 'formik-material-ui'
import { IconButton, Button as MuiButton } from '@material-ui/core'
import { Field } from 'formik'
import { useGetTaskRoles, useUpdateTaskRole, useCreateTaskRole, useDeleteTaskRoleAssignment } from 'volunteering/tasks/hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

const schema = yup.object().shape({
  name: yup.string().required('Required')
})

const useStyles = makeStyles((theme) => ({
  field: {
    margin: '8px',
  },
  removeButton: {
    color: theme.palette.danger.main,
    margin: '16px 0'
  },
  role: {
    borderBottom: '1px solid #aaaaaa',
    paddingBottom: '8px',
    marginBottom: '8px'
  }
}))

export default function HelpingHandsRoles(props) {
  const [isAdding, setIsAdding] = React.useState(false)
  const styles = useStyles()

  const [getRoles, { result: roles, loading }] = useGetTaskRoles()
  const [updateRole,] = useUpdateTaskRole()
  const [createRole,] = useCreateTaskRole()
  const [deleteRole, { loading: deleteLoading }] = useDeleteTaskRoleAssignment()
  const errorMsg = useErrorNotification()

  useEffect(() => { getRoles() }, [getRoles])

  const handleSubmit = async (values) => {
    try {
      await createRole(values)
    } catch(err) {
      // handle name already exists
      if (err.response.status === 409) {
        await updateRole(values)
      } else {
        errorMsg('There was a problem adding the role. Please try again.', err.response.data.message)
      }
    }
    setIsAdding(false)
    getRoles()
  }

  const removeRole = async (role) => {
    try {
      await deleteRole(role.id)
    } catch(err) {
      errorMsg('There was a problem removing the role. Please try again', err.response.data.message)
    }
    getRoles()
  }

  return (
    <>
      <Typography variant='h4'>Helping Hands Service Roles</Typography>
      {
        (loading || deleteLoading) ?
        <Loading />
        :
        <Grid container item xs={12} direction='column'>
          {roles && roles.map(role => (
            <Grid container item direction='row' alignItems='center' className={styles.role} key={role.name}>
              <Grid item xs={8} sm={6} md={4}>
                <span>
                  {role.name}
                </span>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  className={styles.removeButton}
                  onClick={() => removeRole(role)}
                  disabled={deleteLoading}
                >
                  <Remove />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {
            isAdding ?
            <Formik
              initialValues={{ name: '' }}
              onSubmit={handleSubmit}
              validationSchema={schema}
            >
              {({ submitForm, isSubmitting }) => {
                return (
                  <Form>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Field
                          className={styles.field}
                          name="name"
                          variant="outlined"
                          component={TextField}
                          placeholder="Role Name"
                          label="Role Name"
                        />
                      </Grid>
                      <Grid item>
                        <MuiButton variant="contained" color="primary" onClick={submitForm} disabled={isSubmitting}>
                          Add new role
                        </MuiButton>
                      </Grid>
                    </Grid>
                    <MuiButton
                      variant="text"
                      color="secondary"
                      onClick={() => setIsAdding(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </MuiButton>
                  </Form>
                )
              }}
            </Formik>
            :
            <Grid item>
              <MuiButton
                variant='contained'
                color='secondary'
                onClick={() => setIsAdding(true)}
              >
                <Add />
                Add role
              </MuiButton>
            </Grid>
          }
        </Grid>
      }
    </>
  )
}
