import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const getScrollableTree = (element: HTMLElement | null) => {
  const scrollableParents = []
  let parent = element
  while (parent) {
    const { overflowY, overflowX } = window.getComputedStyle(parent)
    if (overflowY === 'scroll' || overflowY === 'auto' || overflowX === 'scroll' || overflowX === 'auto') {
      scrollableParents.push(parent)
    }
    parent = parent.parentElement
  }
  return scrollableParents
}

export const useAutocompleteOptionsPositioningStyles = ({
  open,
  inputDiv,
  optionsDiv,
  optionsCount,
  hasAddButton,
}: {
  open: boolean
  inputDiv: HTMLDivElement | null
  optionsDiv: HTMLDivElement | null
  optionsCount: number
  hasAddButton?: boolean
}) => {
  const requestRunning = useRef<number | null>(null)
  const [optionsStyles, setOptionsStyles] = useState<React.CSSProperties>({})

  const handlePositioning = useCallback(() => {
    if (open && inputDiv && requestRunning.current === null) {
      requestRunning.current = window.requestAnimationFrame(function () {
        setOptionsStyles(getOptionsStyles(inputDiv, optionsDiv, hasAddButton))
        requestRunning.current = null
      })
    } else if (!open && requestRunning.current === null) {
      setOptionsStyles({})
    }
  }, [hasAddButton, inputDiv, open, optionsDiv])

  const scrollableTree = useMemo(() => getScrollableTree(inputDiv), [inputDiv])

  useEffect(() => {
    for (const element of scrollableTree) {
      element.addEventListener('scroll', handlePositioning)
    }
    window.addEventListener('resize', handlePositioning)

    return () => {
      for (const element of scrollableTree) {
        element.removeEventListener('scroll', handlePositioning)
      }
      window.removeEventListener('resize', handlePositioning)
    }
  }, [handlePositioning, scrollableTree])

  useEffect(() => {
    handlePositioning()
  }, [handlePositioning, optionsCount])

  return optionsStyles
}

const getOptionsStyles = (input: HTMLDivElement | null, optionsDiv?: HTMLDivElement | null, hasAddButton?: boolean) => {
  if (!input || !optionsDiv) return {}
  const gap = 4
  const addButtonHeight = hasAddButton ? 40 : 0
  let optionDivHeight = Math.min(420, optionsDiv?.scrollHeight ? optionsDiv.scrollHeight + addButtonHeight : 420)
  const { top, left, width, height } = input.getBoundingClientRect()
  const bottom = document.documentElement.clientHeight - top - height
  const isBelow = bottom >= optionDivHeight + gap ? true : bottom > top
  optionDivHeight = Math.min(optionDivHeight, isBelow ? bottom - gap : top - gap)
  return {
    width: `${width}px`,
    left: `${left}px`,
    maxHeight: `${optionDivHeight}px`,
    ...(isBelow ? { top: `${top + height + gap}px` } : { bottom: `${bottom + gap + height}px` }),
  }
}
