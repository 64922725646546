import { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instantiateQueryConfig from '../utils/instantiateQueryConfig';
import { getQueryState } from '../selectors';
import { runQuery } from '../actions';

//TODO don't allow parameters here?  What if they change while a call is running?
export default function useMutation(queryDef) {
    const dispatch = useDispatch();
    const [config, setConfig] = useState(null);

    const selector = useMemo( () => getQueryState(config), [config]);
    const queryState = useSelector(selector);

    const update = useCallback( 
        (params) => { 
            const config = instantiateQueryConfig(queryDef, params);
            setConfig(config);

            dispatch( runQuery(config) ); 
        },
        [dispatch, queryDef]
    );
    
    return [update, queryState];
}