import moment from 'moment-timezone'
import { find, groupBy } from 'lodash'
import isUndefined from 'lodash/isUndefined'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { Row, useColumnOrder, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CheckIcon from '@material-ui/icons/Check'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import AddIcon from '@material-ui/icons/Add'

import { useCurrentOrg } from 'auth/hooks'
import { OutlinedButton } from 'civic-champs-shared/core'
import Loading from 'components/Loading'
import { ExtendedPagedTable } from 'core/table/components'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { MessagingContact } from 'messages/types'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import { TableMenuButton } from 'civic-champs-shared'
import TablePopoverButton from 'civic-champs-shared/core/TablePopoverButton'
import { BulkAction, CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { RegistrantResponse } from 'Event/interfaces'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import useCancelEventRegistrationsDialog from 'Event/hooks/useCancelEventRegistrationsDialog'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import useBulkAddActivities from 'tracking/activity/hooks/useBulkAddActivities'
import { useGetRegisteredPeriod } from 'registrant/utils/useGetRegisteredPeriod'
import { useRegistrantColumns, useStyles } from 'registrant/hooks/useRegistrantColumns'
import ExportRegistrantsButton from 'registrant/pages/ExportRegistrantsButton'
import { useTimeZone } from '../../civic-champs-shared/auth/hooks'
import Popup from '../../Event/components/popup'
import AddVolunteerForm from '../../Event/modules/events/details/add-volunteer-form'
import { useGroups } from '../../group/hooks/useGroups'
import { GroupTypeCode } from '../../Event/components/opportunity/GroupPicker/types'
import { RegistrationResponse } from '../../Event/scenes/events/detail/volunteers/hooks/RemoveShiftsPrompt'
import { useFetchRegistrant } from '../../Event/hooks/useFetchRegistrant'

export const RegistrantsTable = ({
  registrants,
  initiallyLoaded,
  tableName,
  event,
  getSearchableContacts = () => Promise.resolve([]),
  autocompleteSectionTitle,
  onAdd,
  eagerRemoveMany,
  reloadRegistrants,
  forProfile = false,
  eventListeners,
}: {
  registrants: RegistrantResponse[]
  initiallyLoaded: boolean
  tableName: string
  event?: OpportunityResponseWithRecurrenceInfo
  getSearchableContacts?: () => Promise<MessagingContact[]>
  autocompleteSectionTitle?: string
  onAdd?: (response: RegistrationResponse, registrant: RegistrantResponse) => void
  eagerRemoveMany?: BulkAction<RegistrantResponse>
  reloadRegistrants: () => any
  forProfile?: boolean
  eventListeners?: CollectionEventListeners<RegistrantResponse>
}) => {
  const history = useHistory()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const columns = useRegistrantColumns({ forEvent: !isUndefined(event), forProfile })
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const organization = useCurrentOrg()
  const [recordActivities] = useBulkAddActivities()
  const getRegisteredPeriod = useGetRegisteredPeriod()
  const timeZone = useTimeZone()
  const [displayAddVolunteerPopup, setDisplayAddVolunteerPopup] = useState(false)
  const { groups } = useGroups()
  const volunteerGroupId: number = useMemo(
    () => find(groups, { groupType: { code: GroupTypeCode.Volunteer } })?.id,
    [groups],
  )
  const fetchRegistrant = useFetchRegistrant()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const skipReset = React.useRef<boolean>()

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'email', 'mobile', 'homePhoneNumber', 'signupDate', 'groups'],
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: registrants,
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const handleAddVolunteer = useCallback(
    async (response: RegistrationResponse) => {
      setDisplayAddVolunteerPopup(false)
      if (onAdd) {
        const registrant = await fetchRegistrant(response.id)
        onAdd(response, registrant)
      }
    },
    [fetchRegistrant, onAdd],
  )

  const [selectedRows, selectedRowsCount, selectedNotCheckedInRows, selectedNotCheckedInRowsCount] = useMemo(() => {
    const selectedNotCheckedInRows = table.selectedFlatRows.filter((row: any) => !row.original.activityId)
    return [
      table.selectedFlatRows,
      table.selectedFlatRows.length,
      selectedNotCheckedInRows as Row<RegistrantResponse>[],
      selectedNotCheckedInRows.length,
    ]
  }, [table.selectedFlatRows])

  const showCancelEventRegistrationDialog = useCancelEventRegistrationsDialog({
    eagerRemoveMany: eagerRemoveMany as BulkAction<RegistrantResponse>,
    encodedOccurrence: event ? encodeOccurrenceFromEvent(event) : '',
    selectedRows,
  })

  const recordActivitiesForSelectedRows = useCallback(async () => {
    const groupped = groupBy(
      selectedNotCheckedInRows,
      ({ original: { opportunity, role, timeshift } }) => `${opportunity.id}-${role.id}-${timeshift.id}`,
    )
    const payload = Object.values(groupped).map((rows: any) => {
      const firstRow = rows[0].original
      const { startDate, endDate } = getRegisteredPeriod(firstRow)

      return {
        volunteers: rows.map(({ original: { user } }: Row<RegistrantResponse>) => user),
        occurredAt: startDate.tz(timeZone).toISOString(),
        hoursVolunteered: endDate.diff(startDate, 'minutes') / 60,
        opportunityId: firstRow.opportunity.id,
        volunteeringRoleId: firstRow.role.id,
      }
    })
    await recordActivities(payload)
    reloadRegistrants()
  }, [getRegisteredPeriod, recordActivities, reloadRegistrants, selectedNotCheckedInRows, timeZone])

  const [showCancelButton, cancelButtonDisabled] = useMemo(() => {
    if (!event || !eagerRemoveMany) return [false, false]
    return [
      true,
      moment.tz(event.startsAt, event.organization.timeZone as string).isBefore(moment()) || !selectedRows.length,
    ]
  }, [eagerRemoveMany, event, !selectedRows.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const onMessageVolunteers = () => {
    const recipientPersonIds = selectedRows.map((row: any) => row.original.user.id)
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle,
    })
  }

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  return (
    <>
      <ExtendedPagedTable
        history={history}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Registrations"
        eventListeners={eventListeners}
        skipReset={skipReset}
        buttons={
          <>
            {!forProfile && (
              <TableMenuButton
                startIcon={<MessageOutlinedIcon />}
                onClick={onMessageVolunteers}
                disabled={!selectedRowsCount}
                rightMargin
              >
                Message
              </TableMenuButton>
            )}
            {showCancelButton && (
              <TableMenuButton
                startIcon={<RemoveCircleOutlineOutlinedIcon />}
                onClick={showCancelEventRegistrationDialog}
                disabled={cancelButtonDisabled}
                rightMargin
              >
                Cancel
              </TableMenuButton>
            )}
            <ExportRegistrantsButton data={selectedRows} disabled={!selectedRowsCount} organization={organization} />
          </>
        }
        popover={
          <>
            <TablePopoverButton
              startIcon={<CheckIcon />}
              onClick={recordActivitiesForSelectedRows}
              disabled={!selectedNotCheckedInRowsCount}
            >
              Mark as Checked-in
            </TablePopoverButton>
          </>
        }
        useGlobalSearch={!forProfile}
        addButton={
          onAdd ? (
            <OutlinedButton startIcon={<AddIcon />} onClick={() => setDisplayAddVolunteerPopup(true)}>
              Add Volunteer
            </OutlinedButton>
          ) : undefined
        }
      />
      {displayAddVolunteerPopup && (
        <Popup
          handleCloseModal={() => setDisplayAddVolunteerPopup(false)}
          shouldCloseOnOverlayClick={true}
          hideCloseButton={true}
          width={500}
        >
          <AddVolunteerForm
            event={event}
            volunteerGroupId={volunteerGroupId}
            onSave={handleAddVolunteer}
            onClose={() => setDisplayAddVolunteerPopup(false)}
            returnRegistrant
          />
        </Popup>
      )}
    </>
  )
}
