import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core/'
import { Add as AddIcon, DragHandle as DragHandleIcon, MergeType as MergeTypeIcon } from '@material-ui/icons'

import { ContainedButton, TableMenuButton } from 'civic-champs-shared/core'

import DEFAULT_FILTERS from 'core/table/filters'
import ExportRolesButton from '../components/ExportRolesButton'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import Loading from 'components/Loading'
import { useRolesCollection, useRolesColumns, useStyles } from '../hooks'
import PageHeader from 'App/components/PageHeader'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { RoleImportDialog } from 'volunteer-role/components/RoleImportDialog'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'

const tableName = 'roles'

export const RolesComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ roles, initiallyLoaded }, { addRole, editRole, deleteRole, reorderRoles, mergeRoles }, eventListeners] =
    useRolesCollection() as any
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const columns = useRolesColumns({ editRole, deleteRole })
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const showImportDialog = useShowPrompt(RoleImportDialog)

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      data: roles,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows.map((row: any) => row.original)
  const disabled = !selectedRows.length

  const onReorderRoles = async () => {
    await reorderRoles(table)
    table.toggleAllRowsSelected(false)
  }

  const onMergeRoles = async () => {
    await mergeRoles(selectedRows)
    table.toggleAllRowsSelected(false)
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Roles"
        buttons={
          <>
            <TableMenuButton startIcon={<DragHandleIcon />} onClick={onReorderRoles} rightMargin>
              Reorder
            </TableMenuButton>
            <TableMenuButton
              startIcon={<MergeTypeIcon />}
              onClick={onMergeRoles}
              disabled={selectedRows.length <= 1}
              rightMargin
            >
              Merge
            </TableMenuButton>

            <ExportRolesButton data={selectedRows} disabled={disabled} />
            {isInternalSuperAdmin && (
              <TableMenuButton startIcon={<SaveAltOutlinedIcon />} onClick={showImportDialog}>
                Import
              </TableMenuButton>
            )}
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={() => addRole()}>
            Add Role
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const Roles = (props: RouteComponentProps) => (
  <PageHeader title="Roles" subTitle="Create and manage roles for volunteer activities">
    <RolesComponent {...props} />
  </PageHeader>
)
