import React, { FC, useMemo } from 'react'
import { cloneDeep, isFunction, map, uniqueId } from 'lodash'
import { Field, FieldArray, Form, Formik } from 'formik'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd'
import { Button } from '@material-ui/core'
import { TextField } from 'formik-material-ui'

import BigPlusButton from 'civic-champs-shared/core/big-plus-button'
import { QuestionPayload, QuestionSetResponse, QuestionType } from 'civic-champs-shared/question-sets/types'
import { questionSetSchema } from '../types/schema'
import QuestionItemEditor, { QuestionItem, showOptions } from './QuestionItemEditor'
import { useFeatureEnabled } from 'core/feature/hooks'

const getItemId = () => uniqueId('item_')

const createEmptyQuestion = (): QuestionItem => ({
  questionType: QuestionType.SHORT_TEXT,
  question: '',
  options: ['', ''],
  isRequired: true,
  meta: {
    itemId: getItemId(),
  },
})

const cloneQuestion = (question: any) => {
  const newQuestion = cloneDeep(question)
  newQuestion.meta = {
    itemId: getItemId(),
  }
  newQuestion.questionId = undefined

  return newQuestion
}

const prepareQuestionFromItems = (items: QuestionItem[]): QuestionPayload[] => {
  //TODO use "meta" field to manage versioning?
  return map<QuestionItem, QuestionPayload>(items, item => ({
    questionId: item.questionId,
    questionType: item.questionType,
    question: item.question,
    isRequired: item.isRequired,
    options: showOptions(item.questionType) ? item.options : undefined,
  }))
}

const getItemStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot): any => {
  const isDragging = snapshot.isDragging
  const draggableStyle = provided.draggableProps.style

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    background: isDragging ? '#ADD7F3' : 'white',
    // styles we need to apply on draggables
    ...draggableStyle,
    opacity: isDragging ? 0.5 : 1,
  }
}

export interface SurveyModel {
  name: string
  questions: QuestionItem[]
  questionsEdited: boolean
}

export interface QuestionSetEditorProps {
  onSubmit: (payload: any) => Promise<void>
  questionSet?: QuestionSetResponse
  isNew?: boolean
  isSubmitting: boolean
}

const QuestionSetEditor: FC<QuestionSetEditorProps> = (props: QuestionSetEditorProps) => {
  const { onSubmit, questionSet, isNew = true, isSubmitting } = props

  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const validationSchema = questionSetSchema(isQuestionnaireAsSurveyEnabled)

  const initialValues = useMemo<SurveyModel>(
    () => ({
      name: questionSet?.name || '',
      questions: questionSet?.questions.map(question => ({
        ...question,
        meta: {
          itemId: getItemId(),
        },
      })) || [createEmptyQuestion()],
      questionsEdited: false,
    }),
    [questionSet],
  )

  const handleSubmit = async (values: SurveyModel) => {
    if (!isFunction(onSubmit)) return

    onSubmit({
      name: values.name,
      questions: prepareQuestionFromItems(values.questions),
      questionsEdited: values.questionsEdited,
    })
  }

  return (
    <div className="custom-question-module">
      <h2 className="custom-question-module__title">{questionSet ? 'Edit' : 'New'} Survey</h2>
      <div className="custom-question-form">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <Field
                name="name"
                label="Survey Name"
                component={TextField}
                variant="outlined"
                fullWidth
                style={{ marginBottom: '20px' }}
              />
              <hr />
              <FieldArray
                name="questions"
                render={arrayHelpers => {
                  const handleDragEnd = (result: any) => {
                    if (!result.destination) return
                    const from = result.source.index
                    const to = result.destination.index

                    arrayHelpers.move(from, to)
                    setFieldValue('questionsEdited', true)
                  }

                  const handleAddQuestion = () => {
                    arrayHelpers.push(createEmptyQuestion())
                    setFieldValue('questionsEdited', true)
                  }

                  const handleClone = (index: any) => () => {
                    const clone = cloneQuestion(values.questions[index])
                    arrayHelpers.insert(index + 1, clone)
                    setFieldValue('questionsEdited', true)
                  }

                  const handleEdit = (index: any) => () => {
                    const question = values.questions[index]
                    arrayHelpers.replace(index, {
                      ...question,
                      questionId: undefined,
                      meta: {
                        ...question.meta,
                        originalQuestion: question,
                      },
                    })
                    setFieldValue('questionsEdited', true)
                  }

                  const handleRevertEdit = (index: any) => () => {
                    const {
                      meta: { originalQuestion },
                    } = values.questions[index]
                    arrayHelpers.replace(index, {
                      ...originalQuestion,
                    })
                    setFieldValue('questionsEdited', true)
                  }

                  const handleRequired = (index: any) => () => {
                    const question = values.questions[index]
                    arrayHelpers.replace(index, {
                      ...question,
                      isRequired: !question.isRequired,
                    })
                  }

                  //TODO should this have confirmation?  Undo?
                  const handleRemove = (index: any) => () => {
                    arrayHelpers.remove(index)
                    setFieldValue('questionsEdited', true)
                  }

                  return (
                    <>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="questionarie">
                          {droppableProvided => (
                            <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                              {values.questions.map((question: QuestionItem, index) => (
                                <Draggable key={question.meta.itemId} draggableId={question.meta.itemId} index={index}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(provided, snapshot)}
                                    >
                                      <QuestionItemEditor
                                        index={index}
                                        question={question}
                                        onDuplicate={handleClone(index)}
                                        onRemove={handleRemove(index)}
                                        onEdit={handleEdit(index)}
                                        onRevertEdit={handleRevertEdit(index)}
                                        onRequired={handleRequired(index)}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <div className="button-row">
                        <BigPlusButton
                          title="Add New Question"
                          width="250px"
                          className={!!values.questions.length ? 'add-question-button-wrap' : ''}
                          onClick={handleAddQuestion}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          disabled={isSubmitting || !isValid || (!isNew && !values.questionsEdited)}
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  )
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default QuestionSetEditor
