import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Column, Row, useColumnOrder, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import { useGetMessage } from 'messages/hooks/useGetMessage'
import { NotificationBlastMessage, NotificationBlastReportingWithMessages } from 'messages/types'
import Loading from 'components/Loading'
import PageHeader from 'App/components/PageHeader'
import format from 'civic-champs-shared/utils/format'
import { useMessageOverviewStyles } from 'messages/hooks/useMessageOverviewStyles'
import { Recipient } from 'messages/components/Recipient'
import { useConditionalSelectColumn, useGetColumnState } from 'core/table/table-hooks'
import { useMessagesOverviewColumns } from 'messages/hooks/useMessageOverviewColumns'
import { ExtendedPagedTable } from 'core/table/components'
import { TableMenuButton } from 'civic-champs-shared'

import TablePopoverButton from 'civic-champs-shared/core/TablePopoverButton'

import ExportMessageOverviewButton from 'messages/components/ExportMessageOverviewButton'
import { MessageBody } from 'messages/components/MessageBody'
import {
  useGetAllSearchableContacts,
  useGetAutomatedMessage,
  useMessageRecipientsPrompt,
  useResendCustomMessagesPrompt,
} from 'messages/hooks'
import { MessageAttachments } from 'messages/components/MessageAttachments'

interface MessageOverviewComponentProps
  extends RouteComponentProps<{ id: string } | { date: string; templateId: string }> {
  info: NotificationBlastReportingWithMessages
}

const tableName = 'custom-message-overview'

export const MessageOverviewComponent = ({ history, location, info }: MessageOverviewComponentProps) => {
  const classes = useMessageOverviewStyles()
  const { messages, createdAt } = info
  const columns = useMessagesOverviewColumns()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const getSearchableContacts = useGetAllSearchableContacts()
  const showResendMessagesPrompt = useResendCustomMessagesPrompt()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName'],
      },
      data: messages,
      columns: columns as Column<NotificationBlastMessage>[],
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, classes.selectColumn),
  )

  const selectedRows = (table as any).selectedFlatRows as Row<NotificationBlastMessage>[]

  const onMessageVolunteers = useCallback(() => {
    const recipientPersonIds = selectedRows.map(row => row.original.recipient?.id) as number[]
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }, [getSearchableContacts, selectedRows, showMessageRecipientsPrompt])

  const onResend = useCallback(() => {
    showResendMessagesPrompt({
      messages: selectedRows.map(row => row.original),
      body: info.body || '',
      subject: info.subject,
    })
  }, [info.body, info.subject, selectedRows, showResendMessagesPrompt])

  if (columnStateLoading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <div className={classes.header}>
          <div>
            To: <Recipient info={info} />
          </div>
          <div>
            {format.datetime(createdAt)} ({format.timeAgo(createdAt)})
          </div>
        </div>
        <MessageBody info={info} />
        <MessageAttachments info={info} />
      </div>
      <div className={classes.title}>Recipients</div>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        buttons={
          <>
            <TableMenuButton
              startIcon={<MessageOutlinedIcon />}
              onClick={onMessageVolunteers}
              disabled={!selectedRows.length}
              rightMargin
            >
              Message
            </TableMenuButton>
            <ExportMessageOverviewButton data={selectedRows} disabled={!selectedRows.length} />
          </>
        }
        popover={
          <>
            <TablePopoverButton startIcon={<MailOutlineIcon />} disabled={!selectedRows.length} onClick={onResend}>
              Resend
            </TablePopoverButton>
          </>
        }
      />
    </div>
  )
}

export const MessageOverview = (props: RouteComponentProps<{ id: string }>) => {
  const [getMessage, { result: info, loading, called }] = useGetMessage()
  useEffect(() => {
    getMessage(props.match.params.id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!called || loading) {
    return <Loading />
  }

  return (
    <PageHeader title={info.subject} subTitle="Back to Custom Messages" backLink="/custom-messages">
      <MessageOverviewComponent {...props} info={info} />
    </PageHeader>
  )
}

export const AutomatedMessageOverview = (props: RouteComponentProps<{ date: string; templateId: string }>) => {
  const { date, templateId } = props.match.params
  const [getMessage, { result, loading, called }] = useGetAutomatedMessage()

  useEffect(() => {
    getMessage(date, templateId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!called || loading) {
    return <Loading />
  }

  return (
    <PageHeader title={result.subject} subTitle="Back to Automated Messages" backLink="/messages">
      <MessageOverviewComponent {...props} info={result} />
    </PageHeader>
  )
}
