import _ from 'lodash';
import stringify from 'json-stable-stringify';

export default function getQueryKey(queryConfig) {
    if(!queryConfig) return null;

    const { queryKey, request } = queryConfig;

    if(queryKey !== null && queryKey !== undefined) {
        return queryKey;
    } else {
        const { 
            method = 'get', 
            url, 
            config
        } = request;
        const api = `${_.toLower(method || '')} ${url}`;
        return stringify({ api, config });
    }
}