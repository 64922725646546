import _ from 'lodash'
import React, { useState, useEffect, useRef }  from 'react'
import { 
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    OutlinedInput,
    MenuItem
} from '@material-ui/core';

/*
const idEq = (lhs, rhs) => {
    lhsExists = lhs != null;
    rhsExists = rhs != null;
    lhsId = lhsExists ? lhs.id : null;
    rhsId = rhsExists ? rhs.id : null;

    return lhsId == rhsId && lhsExists == rhsExists;
}
*/

export default function VolunteeringRoleSelect(props) {
    const { roles=[], value, onChange, error, disabled } = props;

    const actualValue = _.get(value, 'id');
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);
    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, [])

    const handleChange = (event) => {
        let id = parseInt(event.target.value);
        let selectedRole = !isNaN(id) ? _.find(roles, { id }) : null;
        if( _.isFunction(onChange) ) {
            onChange(selectedRole);
        }
    }

    return (
        <FormControl 
            error={!!error}
            disabled={disabled}
            variant="outlined"
            required
            fullWidth 
        >
            <InputLabel ref={inputLabel} htmlFor="volunteering-role">Activity</InputLabel>
            <Select
                value={actualValue} 
                onChange={handleChange}
                error={!!error}
                input={<OutlinedInput 
                    labelWidth={labelWidth} 
                    id="volunteering-role"
                    name="volunteeringRole" 
                />}
            >
                {roles.map( role => (
                    <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                ))}
            </Select>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}