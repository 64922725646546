import { useCallback } from 'react'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import WaiverPreviewPrompt from '../components/WaiverPreviewPrompt'
import { WaiverFormValues } from '../models'

const useWaiverPreviewPrompt = () => {
  const prompt = usePrompt(WaiverPreviewPrompt)

  return useCallback((waiver: WaiverFormValues) => (prompt as any).show({ waiver }), [prompt])
}

export default useWaiverPreviewPrompt
