import { useCallback, useEffect, useState } from 'react'

import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import useCancelEventPrompt from 'Event/modules/events/details/header/useCancelEventPrompt'
import { getSlots } from '../helpers/volunteerSlots'

const useEventsCollection = ({ startDate = null, endDate = null, debounceDelay = 0 }) => {
  const [request, { loading, error }] = useFetch()
  const [syntheticLoading, setSyntheticLoading] = useState(false)
  const [events, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const { syncCollection, eagerRemove } = operations

  const getEvents = useCallback(async () => {
    let queryStringParameters = { status: 'Publish' }
    if (startDate && endDate) {
      queryStringParameters = { startDate, endDate, status: 'any' }
    }

    let { list: updatedEvents } = await request({
      method: 'get',
      url: `/organizations/${organization.id}/opportunity_instances`,
      config: { queryStringParameters },
    })

    updatedEvents = updatedEvents.map(event => {
      event.slots = getSlots(event)
      return event
    })

    syncCollection(updatedEvents)
    setSyntheticLoading(false)
  }, [startDate, endDate, request, organization.id, syncCollection])

  useEffect(() => {
    setSyntheticLoading(true)
    let timeout = setTimeout(getEvents, debounceDelay)
    return () => clearTimeout(timeout)
  }, [debounceDelay, getEvents])

  const onEventCancelled = useCallback(
    ({ event, cancelType }) => {
      if (!cancelType || cancelType === 'single') {
        eagerRemove(event)
      } else {
        getEvents()
      }
    },
    [eagerRemove, getEvents],
  )

  const cancelEvent = useCancelEventPrompt(onEventCancelled)

  return [
    {
      events,
      loading: loading || syntheticLoading,
      error,
    },
    {
      refresh: getEvents,
      cancelEvent,
      eagerRemoveMany: operations.eagerRemoveMany,
    },
  ]
}

export default useEventsCollection
