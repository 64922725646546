import { noop, uniqueId } from 'lodash'
import React, { FC } from 'react'
import { Button, IconButton, makeStyles, Tooltip, TooltipProps } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import { Field, FieldArray } from 'formik'
import { Checkbox } from 'civic-champs-shared/core/new-checkbox'
import { Question, QuestionType, QuestionTypeLabels } from 'civic-champs-shared/question-sets/types'
import EditIcon from '@material-ui/icons/Edit'
import UndoIcon from '@material-ui/icons/Undo'
import { useFeatureEnabled } from 'core/feature/hooks'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import StyledSelect, {
  MenuItem,
  useStyles as useSelectStyles,
} from 'civic-champs-shared/formik/components/StyledSelect'
import { ReactComponent as CheckboxesIcon } from 'civic-champs-shared/core/icon/icons/checkboxes.svg'
import { ReactComponent as ShortAnswerIcon } from 'civic-champs-shared/core/icon/icons/short-answer.svg'
import { ReactComponent as LongAnswerIcon } from 'civic-champs-shared/core/icon/icons/long-answer.svg'
import { ReactComponent as DropdownIcon } from 'civic-champs-shared/core/icon/icons/dropdown.svg'
import { ReactComponent as MultipleChoiceIcon } from 'civic-champs-shared/core/icon/icons/multiple-choice.svg'
import { ReactComponent as HomeAddressIcon } from 'civic-champs-shared/core/icon/icons/home-address.svg'
import { ReactComponent as NumberIcon } from 'civic-champs-shared/core/icon/icons/number.svg'
import { ReactComponent as EmergencyContactIcon } from 'civic-champs-shared/core/icon/icons/emergency-contact.svg'
import { ReactComponent as CopyIcon } from 'civic-champs-shared/core/icon/icons/copy-new.svg'
import { ReactComponent as DocumentIcon } from 'civic-champs-shared/core/icon/icons/document.svg'
import cn from 'classnames'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getItemStyle } from 'question-sets/components/NewQuestionSetEditor'
import useDocumentTypesCollection from 'documents/hooks/useDocumentTypesCollection'
import Loading from 'components/Loading'
import useQuestionItemStyles from 'question-sets/hooks/useQuestionItemStyles'

//TODO move to ./types!

export interface QuestionItemMetadata {
  itemId: any
  originalQuestion?: QuestionItem
}

export interface QuestionOption {
  value: string
  meta: {
    itemId: string
  }
}

//TODO working around questionId/createdAt being required in question :-/
export interface QuestionItem extends Omit<Question, 'questionId' | 'questionCreatedAt' | 'questionType' | 'options'> {
  questionType?: QuestionType
  questionId?: number
  meta: QuestionItemMetadata
  options: QuestionOption[]
}

export interface QuestionItemEditorProps {
  index: any
  question: QuestionItem
  onDuplicate: () => void
  onRemove: () => void
  onEdit: () => void
  onRevertEdit: () => void
  onRequired: () => void
}

export const getOptionId = () => uniqueId('option_')
export const createEmptyOption = () => ({
  value: '',
  meta: {
    itemId: getOptionId(),
  },
})

const useTooltipStyles = makeStyles({
  tooltip: { color: 'black', backgroundColor: '#fff0b2', fontStyle: 'italic' },
  arrow: { color: '#fff0b2' },
})

const StyledTooltip: FC<TooltipProps> = props => (
  <Tooltip classes={useTooltipStyles()} placement="top-end" arrow={true} {...props} />
)

export const showOptions = (type?: QuestionType): boolean =>
  type ? [QuestionType.MULTIPLE_CHOICE, QuestionType.SINGLE_CHOICE, QuestionType.DROPDOWN].includes(type) : false

export const allowOptions = (type?: QuestionType) => showOptions(type) || type === QuestionType.DOCUMENT

export const QuestionItemEditor: FC<QuestionItemEditorProps> = (props: any) => {
  const { index, question, onDuplicate, onRemove, onEdit, onRevertEdit, onRequired } = props
  const {
    questionId,
    meta: { originalQuestion },
  } = question
  const classes = useQuestionItemStyles()
  const selectClasses = useSelectStyles()

  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const isDocumentManagementEnabled = useFeatureEnabled('DocumentManagement')
  const [{ documentTypes, loading }] = useDocumentTypesCollection()

  const questionTypeOptions = [
    { value: QuestionType.SHORT_TEXT, icon: <ShortAnswerIcon />, label: QuestionTypeLabels[QuestionType.SHORT_TEXT] },
    { value: QuestionType.LONG_TEXT, icon: <LongAnswerIcon />, label: QuestionTypeLabels[QuestionType.LONG_TEXT] },
    {
      value: QuestionType.SINGLE_CHOICE,
      icon: <MultipleChoiceIcon />,
      label: QuestionTypeLabels[QuestionType.SINGLE_CHOICE],
    },
    {
      value: QuestionType.MULTIPLE_CHOICE,
      icon: <CheckboxesIcon />,
      label: QuestionTypeLabels[QuestionType.MULTIPLE_CHOICE],
    },
    { value: QuestionType.DROPDOWN, icon: <DropdownIcon /> },
    { value: QuestionType.NUMBER, icon: <NumberIcon /> },
    ...(isDocumentManagementEnabled ? [{ value: QuestionType.DOCUMENT, icon: <DocumentIcon /> }] : []),
    ...(isQuestionnaireAsSurveyEnabled
      ? [
          { value: QuestionType.HOME_ADDRESS, icon: <HomeAddressIcon /> },
          { value: QuestionType.EMERGENCY_CONTACT, icon: <EmergencyContactIcon /> },
        ]
      : []),
  ]

  return (
    <div className={classes.questionRow}>
      <IconButton onClick={noop} disabled>
        <DragIndicatorIcon />
      </IconButton>
      <div className={classes.questionItem}>
        <div className={classes.questionHeader}>
          <div>
            <span className={classes.numbering}>{index + 1}.</span>
            <Field
              name={`questions[${index}].questionType`}
              variant="outlined"
              fullWidth
              component={StyledSelect}
              flexValueLayout
              shrinkLabel={false}
              label={!question.questionType && 'Select a Question Type'}
              disabled={questionId && !originalQuestion}
              className={classes.questionType}
              MenuProps={{ PopoverClasses: { paper: classes.paper }, classes: { list: classes.list } }}
            >
              {questionTypeOptions.map(({ value, label, icon }, index) => (
                <MenuItem value={value} key={index}>
                  <span className={cn(selectClasses.hidden, classes.selectIcon)}>{icon}</span>
                  {label || value}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div>
            <Checkbox
              className={classes.requiredCheckbox}
              value={question.isRequired}
              onClick={onRequired}
              label="Required"
              disabled={questionId && !originalQuestion}
            />
            <StyledTooltip title="Duplicate question">
              <IconButton className={classes.iconButton} onClick={onDuplicate}>
                <CopyIcon className={classes.primary} />
              </IconButton>
            </StyledTooltip>
            <StyledTooltip title="Delete question">
              <IconButton className={classes.iconButton} onClick={onRemove}>
                <DeleteIcon style={{ color: '#BA1B1B' }} />
              </IconButton>
            </StyledTooltip>
            {questionId && !originalQuestion && (
              <StyledTooltip title="Edit question">
                <IconButton className={classes.iconButton} onClick={onEdit}>
                  <EditIcon className={classes.primary} />
                </IconButton>
              </StyledTooltip>
            )}
            {!!originalQuestion && (
              <StyledTooltip title="Revert Edits">
                <IconButton className={classes.iconButton} onClick={onRevertEdit}>
                  <UndoIcon className={classes.primary} />
                </IconButton>
              </StyledTooltip>
            )}
          </div>
        </div>

        <div className={cn(classes.answersItem, { [classes.hidden]: !question.questionType })}>
          {question.questionType === QuestionType.DOCUMENT &&
            (loading ? (
              <Loading />
            ) : (
              <FieldArray
                name={`questions[${index}].options`}
                render={() => (
                  <Field
                    name={`questions[${index}].options[0].value`}
                    variant="outlined"
                    fullWidth
                    component={StyledSelect}
                    flexValueLayout
                    shrinkLabel={false}
                    disabled={questionId && !originalQuestion}
                    label={!question?.options?.[0]?.value && 'Select a Document Type'}
                    className={classes.questionType}
                    MenuProps={{ PopoverClasses: { paper: classes.paper }, classes: { list: classes.list } }}
                  >
                    {documentTypes.map(({ id, name }) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                )}
              />
            ))}
          <Field
            name={`questions[${index}].question`}
            component={StyledOutlinedTextField}
            variant="outlined"
            marginless={true}
            hideLabelOnShrink={true}
            label={'Write your Question here (e.g. What is your favorite color?)'}
            disabled={questionId && !originalQuestion}
            className={classes.textField}
            fullWidth
          />
          <div className={classes.answerList}>
            {showOptions(question.questionType) && (
              <FieldArray
                name={`questions[${index}].options`}
                render={arrayHelpers => {
                  const handleDragEnd = (result: any) => {
                    if (!result.destination) return
                    const from = result.source.index
                    const to = result.destination.index

                    arrayHelpers.move(from, to)
                  }
                  const handleRemove = (index: any) => () => {
                    arrayHelpers.remove(index)
                  }
                  const handleAddNew = () => {
                    arrayHelpers.push(createEmptyOption())
                  }

                  //TODO should have an internal draggable context?
                  return (
                    <>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId={`questionOptions${index}`}>
                          {droppableProvided => (
                            <div
                              className={classes.itemContainer}
                              {...droppableProvided.droppableProps}
                              ref={droppableProvided.innerRef}
                            >
                              {question.options.map((_answer: QuestionOption, answerIdx: number) => (
                                <Draggable
                                  key={_answer.meta.itemId}
                                  draggableId={_answer.meta.itemId}
                                  index={answerIdx}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={classes.answerListItem}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(provided, snapshot)}
                                    >
                                      <IconButton onClick={noop} disabled>
                                        <DragIndicatorIcon />
                                      </IconButton>
                                      <div>
                                        <Field
                                          disabled={questionId && !originalQuestion}
                                          name={`questions[${index}].options[${answerIdx}].value`}
                                          component={StyledOutlinedTextField}
                                          marginless={true}
                                          hideLabelOnShrink={true}
                                          label={
                                            !_answer.value &&
                                            `Write Option ${answerIdx + 1} here` +
                                              (answerIdx === 0 ? ' (e.g. Blue)' : '')
                                          }
                                          className={classes.textField}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                      {answerIdx >= 2 && (
                                        <IconButton
                                          disabled={questionId && !originalQuestion}
                                          onClick={handleRemove(answerIdx)}
                                          className="close-icon"
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Button
                        disabled={questionId && !originalQuestion}
                        className={classes.addOption}
                        onClick={handleAddNew}
                        startIcon={<AddCircleIcon />}
                      >
                        Add Option
                      </Button>
                    </>
                  )
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuestionItemEditor
