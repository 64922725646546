import {connect} from "react-redux"

import AccountSettingsModule from "../../kit/modules/account"
import {
  updateUser,
  changeUserPassword,
  getCurrentUserInfo,
  verifyChangeUserAttribute,
  sendUserVerificationCodeByAttribute,
  IUpdateUser
} from "../actions"
import {enqueueNotification} from "../../../notifications/actions";
import {getNotificationOptions} from "../../helpers/helpers";

const mapStateToProps = (state: any) => ({
  userInfo: state.User.info,
  isLoading: state.User.isLoading,
  passwordError: state.User.errors.passwordError
})
const mapDispatchToProps = (dispatch: any) => ({
  verifyChangeUserAttribute,
  sendUserVerificationCodeByAttribute,
  changeUserPassword: (oldPass: string, newPass: string) => dispatch(changeUserPassword(oldPass, newPass)),
  getCurrentUserInfo: () => dispatch(getCurrentUserInfo()),
  updateUser: (updates: IUpdateUser) => dispatch(updateUser(updates)),
  showNotification: (message: string, status: 'success' | 'error') => dispatch(enqueueNotification(message, getNotificationOptions(status)))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsModule)
