import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { useHistory } from 'react-router'
import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import format from 'civic-champs-shared/utils/format'

import { CapitalizeCell, DateTimeCell } from 'core/table/cells'
import { ExpandableCell } from 'core/table/cells/modern'

import { MessageStatusCell } from '../components/MessageStatusCell'
import { MessageSourceCell } from './index'
import { AutomatedMessage } from 'messages/types'

const useStyles = makeStyles(theme => ({
  failedStatus: {
    // @ts-ignore
    color: theme.palette.danger.main,
    textDecoration: 'underline',
  },
  sendingStatus: {
    color: theme.palette.secondary.main,
  },
  centered: {
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
  },
  column: {
    '&>div': {
      paddingRight: '5px',
    },
  },
}))

const TypeCell = ({ cell }: CellProps<AutomatedMessage>) => {
  const history = useHistory()
  const classes = useStyles()
  const {
    templateVersion: { templateId, description },
    createdAt,
  } = cell.row.original

  return (
    <Link
      onClick={() => {
        history.push(`/messages/${format.dateForUrl(createdAt)}/${templateId}`)
      }}
      className={classes.link}
    >
      {description.length > 60 ? `${description.substring(0, 60)}...` : description}
    </Link>
  )
}

export const useAutomatedMessagesColumns = () => {
  const classes = useStyles()

  return useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'templateVersion',
        Cell: TypeCell,
      },
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateTimeCell,
        filter: 'dateRange',
        className: classes.column,
      },
      {
        Header: 'Method',
        accessor: 'method',
        Cell: CapitalizeCell,
      },
      {
        id: 'recipients',
        Header: 'Recipient',
        accessor: ({ recipients }: any) =>
          (recipients || []).map((recipient: any) => format.name(recipient)).join(', '),
        Cell: ExpandableCell,
        className: classes.groupsColumn,
        disableFilters: true,
      },
      {
        id: 'recipientContacts',
        Header: 'Recipient Contact',
        accessor: ({ recipientContacts }: any) => (recipientContacts || []).map((contact: any) => contact).join(', '),
        Cell: ExpandableCell,
        className: classes.groupsColumn,
        disableFilters: true,
      },
      {
        id: 'source',
        Header: 'Source Name',
        Cell: MessageSourceCell,
        className: classes.column,
      },
      {
        id: 'status',
        Header: 'Status',
        Cell: MessageStatusCell,
      },
    ],
    [classes],
  )
}
