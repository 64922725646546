import React from 'react'
import { makeStyles } from '@material-ui/styles'
import cn from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles({
  outlined: {
    height: '30px',
    lineHeight: '30px',
    paddingTop: 0,
    paddingBottom: 0,
    background: '#F8FAFF',
    color: '#0F5DB5',
    '& ~ fieldset': {
      borderColor: '#0F5DB5 !important',
      borderWidth: '1px !important',
    },
    '&:hover': {
      background: '#EBF0FF',
    },
    '&:focus': {
      background: '#EBF0FF',
      '& ~ fieldset': {
        borderColor: '#78ACFF !important',
      },
    },
    '&$open': {
      background: '#D6E3FF',
    },
  },
  open: {},
  iconOutlined: {
    color: '#0F5DB5',
  },
  list: {
    borderRadius: '4px',
    border: '1px solid #0F5DB5',
    background: '#F8FAFF',
    padding: 0,
  },
  item: {
    color: '#0F5DB5',
    borderRadius: '4px',
    padding: 0,
    paddingLeft: '5px',
    marginBottom: '5px',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    '&:hover': {
      background: '#EBF0FF',
    },
    '&$itemSelected, &$itemSelected:hover': {
      background: '#D6E3FF',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  itemSelected: {},
})
export const CalendarSelect = ({
  value,
  onChange,
  options,
  id,
}: {
  value: any
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void
  options: { value: any; label: string }[]
  id?: string
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  return (
    <Select
      variant="outlined"
      id={id}
      value={value}
      onChange={onChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      classes={{ outlined: cn(classes.outlined, { [classes.open]: open }), iconOutlined: classes.iconOutlined }}
      MenuProps={{
        classes: {
          list: classes.list,
        },
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={label} classes={{ root: classes.item, selected: classes.itemSelected }} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}
