import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { AddTripFlow } from 'volunteering/trips/components'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import cn from 'classnames'
import { useCreateTripRequest } from 'volunteering/trips/hooks'
import { TripRequest } from '../types'

interface AddTripPromptProps {
  showing: any
  close: Function
  cancel: Function
  complete: Function
}

export const AddTripPrompt: React.FC<AddTripPromptProps> = props => {
  const classes = usePromptStyles()
  const { showing, close, cancel, complete } = props

  const [handleSubmit, status] = useCreateTripRequest()

  const handleDone = async (value: TripRequest) => {
    complete(await handleSubmit(value))
  }

  return (
    <>
      <Dialog fullScreen className={cn({ fullScreen: true })} open={showing} onClose={() => close()}>
        <DialogTitle className={classes.titleContainer}>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row">
              <Grid>
                <Typography className={classes.title}>Add Trip Request</Typography>
              </Grid>
            </Grid>
            <Grid>
              <IconButton onClick={() => close()} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <AddTripFlow onSubmit={handleDone} error={status?.error} close={close} onCancel={cancel} />
        </DialogContent>
      </Dialog>
    </>
  )
}
