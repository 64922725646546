import React, { useCallback } from 'react'
import { FieldProps } from 'formik'
import CKEditor from '../../core/ckeditor'
import _ from 'lodash'
import { TextFieldProps } from '@material-ui/core'

export default function RichTextField(props: FieldProps & TextFieldProps) {
  const {
    form: { setFieldValue, errors },
    field: { name, value },
  } = props

  const onChange = useCallback(v => {
    setFieldValue(name, v)
  }, [setFieldValue, name])

  return (
    <CKEditor onChange={onChange} value={value} error={_.get(errors, name) as string} placeholder={props.placeholder} />
  )
}
