import { firstLastFilter, groupsFilter, tagsFilter } from 'core/table/filters'

export const GROUPS = 'groups'
export const TAGS = 'tags'
export const STATUS = 'status'
export const FIRST_LAST_NAME = 'customFirstLastName'

const prepare = (input: { value: string }[]): string[] => input.map(({ value }) => value)

const anyOf = (rowValue: string, operand: { value: string }[]): boolean => prepare(operand).includes(rowValue)
const notAnyOf = (rowValue: string, operand: { value: string }[]): boolean => !prepare(operand).includes(rowValue)

const operatorToMethodMap = {
  anyOf,
  notAnyOf,
}

export const statusFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

export const CUSTOM_FILTERS = {
  [GROUPS]: groupsFilter,
  [TAGS]: tagsFilter,
  [FIRST_LAST_NAME]: firstLastFilter,
  [STATUS]: statusFilter,
}

export default CUSTOM_FILTERS
