import { useCallback } from 'react'
import { map } from 'lodash'
import { MessagingContact } from '../types'
import { PersonRef } from 'civic-champs-shared/common/types'

export const useGetSearchableContactsFromVolunteersArray = (
  volunteers: { user: PersonRef }[],
): (() => Promise<MessagingContact[]>) => {
  return useCallback(
    () =>
      Promise.resolve(
        map(volunteers, ({ user: { id, givenName, familyName, email, phoneNumber } }) => ({
          id,
          firstName: givenName as string,
          lastName: familyName as string,
          email,
          phoneNumber,
        })),
      ),
    [volunteers],
  )
}
