import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog, { useStyles } from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React, { useCallback } from 'react'
import map from 'lodash/map'
import { Chat } from 'chats/interfaces'
import DialogContentText from '@material-ui/core/DialogContentText'
import { useDeleteChats } from 'chats'
import { useCreateApiNotification } from '../../civic-champs-shared/api/hooks'
import { BulkAction } from '../../civic-champs-shared/api/hooks/useRemoteCollection'

export const useDeleteChatsPrompt = ({
  eagerRemoveMany,
}: {
  eagerRemoveMany: BulkAction<Chat>
}): ((chats: Chat[]) => Promise<void>) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(ConfirmDialog)
  const deleteChats = useDeleteChats()
  const classes = useStyles()

  return useCallback(
    async (chats: Chat[]) => {
      const text =
        chats.length === 1 ? (
          <>
            <strong>{chats[0].name}</strong> chat
          </>
        ) : (
          <>
            <strong>{chats.length}</strong> selected chats
          </>
        )
      const children = (
        <DialogContentText classes={{ root: classes.text }}>
          Are you sure you want to delete {text} for all members? This action cannot be undone.
        </DialogContentText>
      )
      const confirmed = await showPrompt({
        children,
        title: chats.length === 1 ? 'Delete Chat?' : 'Delete Chats?',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        const notification = createNotification('Deleting chat(s)')
        const ids = map(chats, 'id')
        const result = await deleteChats(ids)
        const removedIds = Object.entries(result)
          .filter(([key, value]) => value)
          .map(([key]) => key)
        if (removedIds.length) {
          eagerRemoveMany(removedIds.map(id => ({ id } as Chat)))
          notification.onSuccess(`${Object.values(result).length} chat(s) deleted`)
        } else {
          notification.onError('Failed to delete chat(s)')
        }
      }
    },
    [classes.text, createNotification, deleteChats, eagerRemoveMany, showPrompt],
  )
}

export default useDeleteChatsPrompt
