import React, { useMemo } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useStyles from '../hooks/useStyles'
import { Organization } from 'new-mentorship/types'
import { addressToString } from 'champion/components/ProfileInfoCard'
import { Edit as EditIcon } from '@material-ui/icons'
import { useShowOrganizationEdit } from 'organization/hooks/useShowOrganizationEdit'
import { timezones } from 'civic-champs-shared/helpers/timezones'
import find from 'lodash/find'
import { useShowOrganizationSettingsEdit } from 'organization/hooks/useShowOrganizationSettingsEdit'

export const SectionHeader = ({ title, onEdit }: { title: string; onEdit: () => void }) => {
  const classes = useStyles()

  return (
    <div className={classes.sectionHeader}>
      <div className={classes.sectionTitle}>{title}</div>
      <EditIcon className={classes.editIcon} onClick={onEdit} />
    </div>
  )
}

export const OrganizationOverview = () => {
  const org: Organization = useCurrentOrg()
  const site = useMemo(() => (org.website ? org.website.replace(/^http(s):\/\//, '') : null), [org.website])
  const editDetails = useShowOrganizationEdit()
  const editSettings = useShowOrganizationSettingsEdit()

  const classes = useStyles()
  return (
    <div className={classes.details}>
      <div className={classes.info}>
        <SectionHeader title={`Organization Details`} onEdit={editDetails} />
        <div className={classes.sectionRow}>
          <div>Name:</div>
          <div>{org.name}</div>
        </div>
        <div className={classes.sectionRow}>
          <div>Timezone:</div>
          <div>{find(timezones, { value: org.timeZone })?.name}</div>
        </div>
        {site && (
          <div className={classes.sectionRow}>
            <div>Website:</div>
            <div>
              <a href={'https://' + site} target="_blank" rel="noopener noreferrer">
                {site}
              </a>
            </div>
          </div>
        )}
      </div>
      <hr className={classes.separator} />
      <div className={classes.info}>
        <SectionHeader title={`Organization Settings`} onEdit={editSettings} />
        <div className={classes.sectionRow}>
          <div>Address:</div>
          <div>{addressToString(org.address)}</div>
        </div>
        <div className={classes.sectionRow}>
          <div>Calendar Visibility:</div>
          <div>{org.limitFutureEventVisibility ? `${org.futureEventVisibilityDays} days` : 'All Events'}</div>
        </div>
        <div className={classes.sectionRow}>
          <div>Silent Mode:</div>
          <div>{org.sandboxModeEnabled ? 'Enabled' : 'Disabled'}</div>
        </div>
      </div>
    </div>
  )
}
