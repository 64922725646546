import React, { BaseSyntheticEvent } from 'react'
import './index.scss'
import classNames from 'classnames'

interface Props {
  checked?: boolean
  className?: string
  onChange: (value: any) => void
  id?: string
  label?: string
  onBlur?: (event: BaseSyntheticEvent) => void
}

const CheckboxComponent = (props: Props) => {
  const { className, onChange, id, label, checked, onBlur } = props
  const classes = classNames('checkbox_item', {
    [`${className}`]: className,
  })
  return (
    <div className="checkbox_wrapper">
      <div className={classes}>
        <span className={`checkbox_checkmark ${checked ? 'checkbox_checked' : ''}`} />
        <input id={id} type="checkbox" checked={checked} onChange={onChange} onBlur={onBlur} />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  )
}

export default CheckboxComponent
