import React from 'react'
import useActiveCampaign from '../hooks/useActiveCampaign'
import _ from 'lodash'
import Loading from 'components/Loading'
import useMicroDonationAccessToken from '../hooks/useMicroDonationAccessToken'
import { useCurrentUser } from 'auth/hooks'
import { useCreateCampaignEvent } from '../hooks/useCreateCampaignEvent'
import { CampaignEventType } from '../interface'

export const CampaignDemo = (props: any) => {
  const { opportunityId, hours = 0 } = props.match.params
  const { result: accessToken, loading: gettingAccessToken, called: calledAccessToken } = useMicroDonationAccessToken()
  const { result: campaign, loading: gettingActiveCampaign } = useActiveCampaign(
    opportunityId,
    hours,
  )
  const user = useCurrentUser()
  const createCampaignEvent = useCreateCampaignEvent()
  const createClickedEvent = () => {
    createCampaignEvent(campaign.id, opportunityId, CampaignEventType.CAMPAIGN_CLICKED)
  }

  if (gettingActiveCampaign || gettingAccessToken || !calledAccessToken) {
    return <Loading />
  }

  if (_.isEmpty(campaign)) {
    return <div> no active campaign </div>
  }

  return (
    <div>
      <h2>Thank you!</h2>
      <p>{_.get(campaign, 'thankYouPrompt.firstParagraph', 'thank you 1')}</p>
      <p>{_.get(campaign, 'thankYouPrompt.secondParagraph', 'thank you 2')}</p>
      <a
        href={`${process.env.REACT_APP_DONATIONS_SITE}/nonprofit/${campaign.organizationId}/campaign?cognito_sub=${user.cognitoSub}&campaign_id=${campaign.id}&token=${accessToken.token}&hours=${hours}&opportunity_id=${opportunityId}`}
        onClick={createClickedEvent}
      >
        Tell me more
      </a>
    </div>
  )
}
