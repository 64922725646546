import { useCallback, useEffect } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import useAddRequiredGroupMemberPrompt from './useAddRequiredGroupMemberPrompt'
import REQUIRED_GROUP_TYPES from '../requiredGroupTypes'

const STABLE_EMPTY_ARRAY = [] //TODO this is reel dum

const useTaskRequesters = () => {
  const organization = useCurrentOrg()
  const [request, { result, loading, called }] = useFetch()

  const getTaskRequesters = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/task_requesters`,
    })
  }, [organization.id, request])

  useEffect(() => {
    getTaskRequesters()
  }, [getTaskRequesters])

  const onOpenAddRequesterPrompt = useAddRequiredGroupMemberPrompt(REQUIRED_GROUP_TYPES.requester, getTaskRequesters)

  return {
    requesters: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh: getTaskRequesters,
    onOpenAddRequesterPrompt,
  }
}

export default useTaskRequesters
