import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useCallback } from 'react'

export const useGetTaskRoles = () => {
  const org = useCurrentOrg()
  const [fetch, status] = useFetch()
  const getRoles = useCallback(() => {
    return fetch({
      method: 'get',
      url: `/organizations/${org.id}/task_roles`,
    })
  }, [fetch, org.id])

  return [getRoles, status]
}

export const useCreateTaskRole = () => {
  const org = useCurrentOrg()
  const [fetch, status] = useFetch()
  const createRole = useCallback(
    payload => {
      return fetch({
        method: 'post',
        url: `/organizations/${org.id}/task_roles`,
        config: { body: payload },
      })
    },
    [fetch, org.id],
  )

  return [createRole, status]
}

export const useUpdateTaskRole = () => {
  const [fetch, status] = useFetch('Error updating role. Please try again.')
  const org = useCurrentOrg()
  const updateRole = useCallback(
    payload => {
      return fetch(
        {
          method: 'put',
          url: `/organizations/${org.id}/task_roles`,
          config: { body: payload },
        },
        [payload],
      )
    },
    [fetch, org.id],
  )

  return [updateRole, status]
}

export const useDeleteTaskRoleAssignment = () => {
  const org = useCurrentOrg()
  const [fetch, status] = useFetch('There was a problem removing the role. Please try again')
  const deleteRoleAssignment = useCallback(
    roleId => {
      return fetch({
        method: 'del',
        url: `/organizations/${org.id}/task_roles/${roleId}`,
      })
    },
    [fetch, org.id],
  )

  return [deleteRoleAssignment, status]
}
