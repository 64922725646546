import {useCallback, useEffect, useReducer} from 'react';
import { useSuccessNotification, useErrorNotification } from 'civic-champs-shared/api/hooks'
import {API} from "aws-amplify";
import {filter} from "lodash";

function request(requestConfig) {
    const { method = 'get', url, config } = requestConfig
    return API[method]('civicChampsApi', url, config)
}

const processApplicant = (member, decision, comment) => request({
    method: 'post',
    url: `/groups/${member.groupId}/membership_actions`,
    config: {
        body: {
            type: decision,
            personIds: [member.person.id],
            comment: comment || undefined,
        }
    },
})

const init = ({allMembers, type, chosenMemberID}) => {
    const filteredMembers = filter(allMembers, m => m.status.code === type.toLowerCase())
    return {
        applicants: filteredMembers,
        index: chosenMemberID ? filteredMembers.findIndex(member => member.id === chosenMemberID): 0,
        submitting: false,
        isDirty: false
    }
}

const reducer = (state, action) => {
    const { applicants, index, submitting } = state

    switch(action) {
        case 'prev':
            return submitting ? state : {
                ...state,
                index: index > 0 ? index - 1 : index
            }

        case 'next':
            return submitting ? state : {
                ...state,
                index: index < applicants.length - 1 ? index + 1 : index
            }

        case 'submit':
            return { ...state, submitting: true }

        case 'succeeded':
            return {
                ...state,
                applicants: removeAt(applicants, index),
                index: index + 1 < applicants.length - 1 ? index + 1 : applicants.length - 1,
                submitting: false,
                isDirty: true
            }

        case 'failed':
            return { ...state, submitting: true }

        default:
            throw new Error(`Unknown applicant action '${action}!`)
    }
}

const removeAt = (array, index) => {
    let arr = array || []
    if(index < 0 || index > arr.length - 1) return arr
    arr.splice(index, 1)
    return arr;
}

export const useApplicantManagement = ({ allMembers, close, refresh, type, chosenMemberID }) => {
    const showSuccess = useSuccessNotification()
    const showError = useErrorNotification()

    const [
        { applicants, index, submitting, isDirty },
        dispatch
    ] = useReducer(reducer, {allMembers, type, chosenMemberID}, init)

    const exit = useCallback(() => {
        if(isDirty) refresh()
        close()
    }, [close, refresh, isDirty])

    const current = applicants[index]

    const createProcessFn = (decision) => async (comment) => {
        if(!current) return

        dispatch('submit')
        try {
            await processApplicant(current, decision, comment)
            showSuccess(`Successfully ${decision} membership`)
            dispatch('succeeded')
        } catch(error) {
            showError('Error while processing membership decision', error)
            dispatch('failed')
        }
    }

    useEffect(
        () => { if(applicants.length === 0) exit() },
        [applicants.length, exit]
    )

    return {
        submitting,
        prev: applicants[index - 1],
        current: applicants[index],
        next: applicants[index + 1],
        gotoNext: () => dispatch('next'),
        gotoPrev: () => dispatch('prev'),
        approve: createProcessFn('approved'),
        decline: createProcessFn('declined'),
        exit,
        total: applicants.length,
        currentIndex: index + 1
    }
}
