import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { Field, Form, Formik } from 'formik'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import yup from 'civic-champs-shared/utils/yup'
import { Note } from 'note/Note'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'

const useStyles = makeStyles({
  title: { cursor: 'move', padding: '20px 20px 16px 20px' },
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    padding: '8px 0',
    textAlign: 'justify',
    margin: 0,
  },
  content: {
    display: 'flex',
    padding: '0 20px !important',
    gap: '14px',
    alignItems: 'center',
    overflowY: 'hidden',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#000000',
    margin: 0,
    padding: 0,
  },
  header: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  button: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    borderRadius: '100px',
    color: '#FFF',
    padding: '8px 24px',
    backgroundColor: '#DD3730',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '&:hover': {
      backgroundColor: '#DD3730',
    },
  },
  cancelButton: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    color: '#74777F',
    borderRadius: '100px',
    padding: '8px 24px',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  actions: {
    padding: '8px 20px 20px',
  },
  container: {
    width: '700px',
    maxWidth: '100%',
    background: '#F8FAFF',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
  },
  limit: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'right',
    letterSpacing: '0.1px',
    color: '#8E9192',
    display: 'block',
    width: '100%',
  },
})

export interface AddEditNoteDialogProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  note?: Note
}

export const noteSchema = yup.object({
  // @ts-ignore
  id: yup.number().id().notRequired().nullable(),
  title: yup.string().trim().nullable(),
  body: yup.string().trim().required('Please enter note').max(2000),
})

export function AddEditNoteDialog(props: AddEditNoteDialogProps) {
  const { showing, complete, close, note } = props
  const classes = useStyles()
  const initialValues = useMemo(
    () =>
      note
        ? {
            id: note.id,
            title: note.title || '',
            body: note.body,
          }
        : {
            id: null,
            title: '',
            body: '',
          },
    [note],
  )

  const handleSubmit = useCallback(
    (input: any) => {
      return complete(noteSchema.cast(input))
    },
    [complete],
  )

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      onClose={() => close()}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#add-edit-note-dialog`, className: classes.container }}
      aria-labelledby="add-edit-note-dialog-title"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="add-edit-note-dialog">
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <Typography classes={{ root: classes.header }}>{note ? 'Edit' : 'Add'} Note</Typography>
          </Grid>
          <Grid item>
            <CloseIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={close} />
          </Grid>
        </Grid>
      </DialogTitle>
      <Formik<{ title: string; body: string; id: null | number }>
        initialValues={initialValues}
        validationSchema={noteSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, values }) => {
          return (
            <>
              <Form style={{ width: '100%' }}>
                <DialogContent classes={{ root: classes.content }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography className={classes.label}>Title</Typography>
                      <Field
                        fullWidth
                        name="title"
                        component={StyledOutlinedTextField}
                        marginless
                        smaller
                        maxLength={70}
                      />
                      {values.title.length >= 50 && (
                        <span className={classes.limit} style={values.title.length >= 70 ? { color: '#BA1B1B' } : {}}>
                          {values.title.length}/70
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.label}>Content</Typography>
                      <Field
                        fullWidth
                        name="body"
                        component={CkEditorStyledField}
                        _dangerouslyRemoveDialogTabindex
                        marginless
                        smaller
                        multiline
                        rows={12}
                        maxLength={2000}
                      />
                      {values.body.length >= 1500 && (
                        <span className={classes.limit} style={values.body.length >= 2000 ? { color: '#BA1B1B' } : {}}>
                          {values.body.length}/2000
                        </span>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={classes.actions}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    classes={{ root: classes.buttonsContainer }}
                  >
                    <OutlinedButton disabled={isSubmitting} onClick={close}>
                      Cancel
                    </OutlinedButton>
                    <ContainedButton disabled={isSubmitting} onClick={submitForm}>
                      Save
                    </ContainedButton>
                  </Grid>
                </DialogActions>
              </Form>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddEditNoteDialog
