import { cloneDeep, keys, unset, each, set } from 'lodash';
import { createTransform } from 'redux-persist';

export default function createVolatileStateTransform(volatileFieldDefaults, reducerName) {
    return createTransform(
        (persistingState, key) => removeVolatileFields(persistingState, volatileFieldDefaults),
        (hydratingState, key) => defaultVolatileFields(hydratingState, volatileFieldDefaults),
        { whitelist: [reducerName] }
    )
}

export function removeVolatileFields(state, volatileFieldDefaults) {
    let newState = cloneDeep(state);
    keys(volatileFieldDefaults || {}).forEach( path => { unset(newState, path) })

    return newState;
}

export function defaultVolatileFields(state, volatileFieldsDefaults) {
    let newState = cloneDeep(state);
    each(volatileFieldsDefaults, (defaultValue, path) => { set(newState, path, defaultValue) });

    return newState;
}