import { useCallback, useState, useEffect } from 'react'

import useGetVolunteeringRoles from '../hooks/useGetVolunteeringRoles'
import useGetVolunteeringRolesForOpportunity from '../hooks/useGetVolunteeringRolesForOpportunity'

//composing these results this way is *painful*!
const useVolunteeringRoles = ({ opportunity, organization, defaultOnly }) => {
  const [getVolunteeringRoles, ] = useGetVolunteeringRoles()
  const [getVolunteeringRolesForOpportunity, ] = useGetVolunteeringRolesForOpportunity()

  const getRoles = useCallback((opportunity, organization, defaultOnly) => 
      opportunity 
        ? getVolunteeringRolesForOpportunity(opportunity)
        : getVolunteeringRoles(organization.id, defaultOnly)
  , [getVolunteeringRoles, getVolunteeringRolesForOpportunity])

  const [loading, setLoading] = useState(false) 
  const [data, setData] = useState([])

  useEffect(
    () => { 
      (async () => {
        setLoading(true)
        try {
          let result = await getRoles(opportunity, organization, defaultOnly)
          setData(result)
        } catch(e) { 
          /* TODO: report an error */ 
        } finally {
          setLoading(false)
        }
      })();
    }, [opportunity, organization, defaultOnly, getRoles]
  )

  return { data, loading }
}

export default useVolunteeringRoles;