import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Notification, NotificationApiResponse } from 'notification/interfaces'

const mapper = (notifications: NotificationApiResponse[]): Notification[] =>
  notifications.map(notification => ({
    id: notification.id,
    organizationId: notification.organizationId,
    title: notification.title,
    body: notification.body,
    type: notification.type,
    info: notification.info,
    createdAt: new Date(notification.createdAt),
    readAt: notification.readAt ? new Date(notification.readAt) : null,
  }))

export const useGetNotifications = (): [() => Promise<Notification[]>, Status<Notification[]>] => {
  const [request, status] = useFetchRefactored<Notification[], NotificationApiResponse[]>({ mapper })

  const getNotifications = useCallback(() => {
    return request({
      method: 'get',
      url: `/notifications`,
    })
  }, [request])

  return [getNotifications, status]
}

export default useGetNotifications
