import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { Card, CardContent, CardActions, Typography, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip, ListItemIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import EnabledByDefaultSwitch from './EnabledByDefaultSwitch';
import AddCustomSwitchPrompt from './AddCustomSwitchPrompt';
import CustomFeatureSwitch from './CustomFeatureSwitch';
import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import { useReloadLocalFeatures } from '../hooks';

const useStyles = makeStyles({
    card: {
        marginBottom: '16px'
    },
    list: {
        width: '50%',
        minWidth: '300px'
    },
    switchActions: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'end'
    }
});

const useDeleteSwitch = () => {
    const [fetch, status] = useFetch();

    const deleteSwitch = useCallback( featureSwitch => {
        const featureId = featureSwitch.feature.id
        const organizationId = featureSwitch.organization.id

        return fetch({
            method: 'del',
            url: `/settings/features/${featureId}/switches`,
            config: {
                queryStringParameters: { organizationId }
            }
        })
    }, [fetch])

    return [deleteSwitch, status]
}

export default function FeatureSettingsCard(props) {
    const { feature, children } = props;

    const addSwitchPrompt = usePrompt(AddCustomSwitchPrompt);
    const handleClickAdd = useCallback(() => {
      addSwitchPrompt.show({ feature })
    }, [feature, addSwitchPrompt])

    const reload = useReloadLocalFeatures()
    //TODO should move this out into its own button or something?
    const [deleteSwitch, { loading: deleting }] = useDeleteSwitch()
    const handleClickDelete = useCallback( (featureSwitch) => async () => {
        if(deleting) return

        const { organization } = featureSwitch
        const prompt = 'Are you sure you want to delete the custom switch for ' +
                     `Organization '${organization.name}' (id:${organization.id})?`

        if( window.confirm(prompt) ) {
            await deleteSwitch(featureSwitch)
            reload()
        }
    }, [deleting, deleteSwitch, reload])

    const classes = useStyles();

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">{feature.displayName}</Typography>
          <Typography color="secondary">{feature.featureKey}</Typography>
          {!isEmpty(feature.switches) && (
            <List className={classes.list}>
              {feature.switches.map(featureSwitch => (
                <ListItem key={featureSwitch.id}>
                  <ListItemText primary={featureSwitch.organization.name} />
                  <ListItemSecondaryAction className={classes.switchActions}>
                    <CustomFeatureSwitch featureSwitch={featureSwitch} />
                    <Tooltip title="Delete Switch">
                      <IconButton onClick={handleClickDelete(featureSwitch)} disabled={deleting}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
        <CardActions>
          <EnabledByDefaultSwitch feature={feature} />
          <Button variant="outlined" color="secondary" onClick={handleClickAdd}>
            Custom Switch
            <AddIcon />
          </Button>
          {children}
        </CardActions>
      </Card>
    )
}

