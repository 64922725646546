import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'

const useGetVolunteeringRolesForOpportunity = () => {
  const [request, { result, loading, called, error }] = useFetch()

  const getVolunteeringRoles = useCallback(
    ({ opportunityId, startsAt }) => {
      const encodedId = encodeOccurrenceFromEvent({ opportunityId, startsAt })
      return request({
        method: 'get',
        url: `/public/opportunities/${encodedId}/roles`,
      })
    },
    [request],
  )

  return [
    getVolunteeringRoles,
    {
      volunteeringRoles: result,
      loading: loading || !called,
      error,
    },
  ]
}

export default useGetVolunteeringRolesForOpportunity
