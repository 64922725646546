import React, { useState, useMemo } from 'react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { MenuContextProvider } from './MenuContext';

const defaultAnchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
}

const defaultTransformOrigin = {
    vertical: 'top',
    horizontal: 'center'
}

const useStyles = makeStyles( theme => ({
    menu: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white
            }
        }
    },
    button: {
        backgroundColor: 'white',
        textTransform: 'capitalize',
        fontWeight: 500,
        maxHeight: 32,
        width: 150,
        lineHeight: '20px',
        color: '#707070',
        fontSize: '16px'
    }
}))

export default function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuContext = useMemo( 
        () => ({ closeMenu: () => setAnchorEl(null) }), 
        [setAnchorEl]
    );

    const classes = useStyles();

    // `ref` does not properly forward with material-ui/core/Button
    const handleButtonClick = event => setAnchorEl(event.currentTarget);

    return (
        <MenuContextProvider value={menuContext} >
            <Button
                className={classes.button}
                variant="contained"
                onClick={handleButtonClick}
            >
                Select Action
            </Button>
            <Menu 
                disableAutoFocusItem={true}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={defaultAnchorOrigin}
                transformOrigin={defaultTransformOrigin}
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={menuContext.closeMenu}
                className={classes.menu}
                variant="menu"
            >
                {props.children}
            </Menu>
        </MenuContextProvider>
    );
}