import React, { useCallback } from 'react'
import { TextField } from '@material-ui/core'
import { fieldToTextField } from 'formik-material-ui'

export default function CurrencyField(props) {
    const { field, form, min, max, children, ...other } = props

    const handleChange = useCallback( event => {
         const amount = event.target.value.replace(/[^0-9]/g, '')
        form.setFieldValue(field.name, amount*100, true)
    }, [field.name, form.setFieldValue])

    return (
        <TextField
            {...fieldToTextField(props)}
            {...other}
            value={field.value/100}
            onChange={handleChange}
        >
            {children}
        </TextField>
    )
}
