import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback, useEffect } from 'react'
import { mapCoordinatesArrayToObject } from 'utils/event'

const mapper = locations =>
  locations.map(({ location, ...person }) => {
    return {
      ...person,
      location: {
        ...location,
        geofencing: {
          ...location.geofencing,
          position: {
            ...location.geofencing.position,
            coordinates: mapCoordinatesArrayToObject(location.geofencing.position.coordinates),
          },
        },
      },
    }
  })

const STABLE_EMPTY_ARRAY = []
const usePersonLocations = personId => {
  const [request, { result: locations, loading }] = useFetch(undefined, mapper)

  const getLocations = useCallback(async () => {
    if (!personId) return []
    await request({
      method: 'get',
      url: `/persons/${personId}/locations`,
    })
    return []
  }, [personId, request])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  return {
    personLocations: locations || STABLE_EMPTY_ARRAY,
    loading,
    refresh: getLocations,
  }
}

export default usePersonLocations
