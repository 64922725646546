import React from 'react'
import './index.scss'

interface Props {
  icon: boolean | undefined
}

function RatingIcon(props: Props) {
  return (
    <div className="rating_icon">
      {props.icon === true && <div className="rating_icon_like" />}
      {props.icon === false && <div className="rating_icon_dislike" />}
    </div>
  )
}

export default RatingIcon
