import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DraggablePaper from './DraggablePaper'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  title: { cursor: 'move', padding: '20px 20px 16px 20px' },
  icon: { color: '#DD3730', width: '37px', height: '32px' },
  text: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
    padding: '8px 0',
    textAlign: 'justify',
    margin: 0,
  },
  content: {
    display: 'flex',
    padding: '0 20px',
    gap: '14px',
    alignItems: 'center',
  },
  boldText: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#2D3038',
  },
  button: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    borderRadius: '100px',
    color: '#FFF',
    padding: '8px 24px',
    backgroundColor: '#DD3730',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    '&:hover': {
      backgroundColor: '#DD3730',
    },
  },
  cancelButton: {
    fontFamily: 'Nunito, sans-sertif',
    fontWeight: 700,
    color: '#74777F',
    borderRadius: '100px',
    padding: '8px 24px',
    textTransform: 'none',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  actions: {
    padding: '8px 20px 20px',
  },
})

export interface CancelDialogProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  children?: React.ReactNode
  cancelText: string
  confirmText: string
  text?: string
  id?: string
  title: string
}

export function CancelDialog(props: CancelDialogProps) {
  const {
    showing,
    complete,
    close,
    title,
    cancelText,
    confirmText,
    text,
    children,
    id = 'draggable-dialog-title',
  } = props
  const classes = useStyles()

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      onClose={() => close()}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#${id}` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={id}>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <Typography classes={{ root: classes.boldText }}>{title}</Typography>
          </Grid>
          <Grid item>
            <CloseIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={close} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <ReportProblemOutlinedIcon className={classes.icon} />
        <div>
          {text && <DialogContentText classes={{ root: classes.text }}>{text}</DialogContentText>}
          {children}
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.cancelButton} autoFocus onClick={close}>
          {cancelText}
        </Button>
        <Button className={classes.button} onClick={complete} variant="contained" color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDialog
