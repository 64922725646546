import moment from 'moment-timezone'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { Button, CircularProgress, Dialog, Grid, IconButton, Slide, TextField, Typography } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'

import DurationField from './DurationField'
import useDateTimeRange from 'utils/useDateTimeRange'
import { useCurrentOrg } from 'auth/hooks'
import VolunteerSelector from 'volunteering/volunteers/components/VolunteerSelector'
import OpportunitySelector from 'volunteering/opportunities/components/OpportunitySelector'
import VolunteeringRoleSelector from 'tracking/volunteeringRoles/components/VolunteeringRoleSelector'
import { useAddActivity } from '../hooks/useAddActivity'
import { useDateTimeUtils } from 'civic-champs-shared/utils/useDateTimeUtils'

const useStyles = makeStyles(theme => ({
  labelStyle: {
    fontSize: '1.2em',
    fontWeight: '600',
    display: 'inline',
  },
  textStyle: {
    fontSize: '1.2em',
    display: 'inline',
    paddingLeft: '5px',
  },
  rowContent: {
    padding: '1em 0.25em',
  },
  rowContentCentered: {
    padding: '0.1em 1em',
    alignContent: 'center',
  },
  dtPicker: {},
  submitButton: {
    backgroundColor: '#5C8DE8',
    color: 'white',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const styles = theme => ({
  root: {
    margin: '0',
    padding: '0',
    fontFamily: 'Open Sans',
    borderRadius: '4px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: 'white',
  },
  title: {
    fontSize: '2em',
    fontWeight: '500',
    backgroundColor: '#5C8DE8',
    color: 'white',
    marginTop: '0px',
    width: '100%',
    padding: '0.4em 1em',
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: '10px 30px',
  },
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useActivityForm = () => {
  const { fakeAsLocalTimeForUI } = useDateTimeUtils()
  const [volunteer, setVolunteer] = useState({ cognitoSub: '', name: '' })
  const [opportunityId, setOpportunityId] = useState('')
  const [range, rangeActions] = useDateTimeRange({
    start: fakeAsLocalTimeForUI(moment()),
    duration: moment.duration(1, 'hour'),
  })
  const [volunteeringRoleId, setVolunteeringRoleId] = useState('')
  const [team, setTeam] = useState('')

  const actions = useMemo(
    () => ({
      setVolunteer,
      setOpportunityId,
      setStartedAt: rangeActions.setStart,
      setEndedAt: rangeActions.setEnd,
      setDuration: rangeActions.setDuration,
      setVolunteeringRoleId,
      setTeam,
    }),
    [rangeActions],
  )

  return useMemo(() => {
    const formData = {
      volunteer,
      opportunityId,
      startedAt: range.start,
      endedAt: range.end,
      duration: range.duration,
      volunteeringRoleId,
      team,
    }

    return [formData, actions]
  }, [volunteer, opportunityId, range, volunteeringRoleId, team, actions]) // eslint-disable-line react-hooks/exhaustive-deps
}

export function AddActivityDialog(props) {
  const { showing, close, complete } = props
  const classes = useStyles()
  const organization = useCurrentOrg()

  const [formData, actions] = useActivityForm()
  const [addActivity, { loading: submitting }] = useAddActivity()
  const { unfakeAsLocalTimeForUIAsString, unfakeAsLocalTimeForUI } = useDateTimeUtils()

  const submit = useCallback(async () => {
    let activityData = {
      volunteer: formData.volunteer,
      occurredAt: unfakeAsLocalTimeForUIAsString(formData.startedAt),
      hoursVolunteered: formData.duration.as('hours'),
    }

    if (formData.opportunityId) {
      activityData.opportunityId = formData.opportunityId
    }
    if (formData.volunteeringRoleId) {
      activityData.volunteeringRoleId = formData.volunteeringRoleId
    }
    if (formData.team) {
      activityData.team = formData.team
    }

    const savedActivity = await addActivity(activityData)
    complete ? complete(savedActivity) : close()
  }, [
    formData.volunteer,
    formData.startedAt,
    formData.duration,
    formData.opportunityId,
    formData.volunteeringRoleId,
    formData.team,
    unfakeAsLocalTimeForUIAsString,
    addActivity,
    complete,
    close,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  //TODO this is kind of a hack
  const volunteeringRoleQuery = useMemo(
    () => ({
      organization,
      opportunity: formData.opportunityId
        ? {
            opportunityId: formData.opportunityId,
            startsAt: formData.startsAt,
          }
        : null,
      defaultOnly: !formData.opportunityId,
    }),
    [formData.opportunityId, formData.startsAt, organization],
  )

  const [opportunityStart, opportunityEnd] = useMemo(
    () => [unfakeAsLocalTimeForUI(formData.endedAt), unfakeAsLocalTimeForUI(formData.startedAt)],
    [unfakeAsLocalTimeForUI, formData.endedAt, formData.startedAt],
  )

  return (
    <Dialog
      open={showing}
      onClose={close}
      disableBackdropClick={true}
      TransitionComponent={Transition}
      fullScreen={true}
    >
      <form>
        <DialogTitle>Add Activity</DialogTitle>
        <DialogContent>
          <Grid>
            <Grid item className={classes.rowContent}>
              <VolunteerSelector
                organization={organization}
                value={formData.volunteer.cognitoSub}
                onChange={actions.setVolunteer}
                required={true}
                disabled={submitting}
                reporting={false}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <KeyboardDateTimePicker
                name="startedAt"
                label="Check-In"
                value={formData.startedAt}
                onChange={actions.setStartedAt}
                format="MM/DD/YYYY hh:mm a"
                inputVariant="outlined"
                className={classes.dtPicker}
                disabled={submitting}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <KeyboardDateTimePicker
                name="endedAt"
                label="Check-Out"
                minDate={formData.startedAt}
                value={formData.endedAt}
                onChange={actions.setEndedAt}
                format="MM/DD/YYYY hh:mm a"
                inputVariant="outlined"
                className={classes.dtPicker}
                disabled={submitting}
              />
            </Grid>
            <Grid item className={classes.rowContentCentered}>
              <DurationField value={formData.duration} onChange={actions.setDuration} disabled={submitting} />
            </Grid>
            <Grid item className={classes.rowContent}>
              <OpportunitySelector
                organization={organization}
                value={formData.opportunityId}
                onChange={actions.setOpportunityId}
                required={false}
                disabled={submitting}
                endDate={opportunityStart}
                startDate={opportunityEnd}
                reporting={false}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <VolunteeringRoleSelector
                value={formData.volunteeringRoleId}
                onChange={actions.setVolunteeringRoleId}
                required={false}
                disabled={submitting}
                query={volunteeringRoleQuery}
              />
            </Grid>
            <Grid item className={classes.rowContent}>
              <TextField
                id="team"
                name="team"
                label="Team"
                variant="outlined"
                value={formData.team}
                onChange={e => actions.setTeam(e.target.value)}
                disabled={submitting}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} disabled={submitting}>
            Cancel
          </Button>
          <Button className={classes.submitButton} disabled={submitting} onClick={submit}>
            {submitting && <CircularProgress className={classes.buttonProgress} size={24} color="primary" />}
            Add Activity
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddActivityDialog
