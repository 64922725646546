import React, { useState, useCallback } from 'react'
import { Typography, Stepper, StepLabel, StepContent, Step } from '@material-ui/core'
import useStyles from '../hooks/useMyTripStyles'
import { TripRiderPicker, TripDetails, TripReview } from 'volunteering/trips/components'

export const EXISTING_USER = 'EXISTING_USER'
export const NEW_USER = 'NEW_USER'

const EMPTY_ADDRESS = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  countryCode: '',
  country: '',
}

const EMPTY_POSITION = {
  position: {
    coordinates: [],
    type: 'Point',
  },
}

interface AddTripFlowProps {
  onSubmit: Function
  error: any
  close: Function
  onCancel: Function
  status?: any
}

export const AddTripFlow: React.FC<AddTripFlowProps> = props => {
  const classes = useStyles()
  const { onSubmit } = props
  const [rider, setRider] = useState({})
  const [formData, setFormData] = useState({
    riderId: null,
    tripOrderIndex: 0,
    tripOrders: [
      {
        pickupLocation: {
          address: EMPTY_ADDRESS,
          displayAddress: '',
          geofencing: EMPTY_POSITION,
        },
        dropOffLocation: {
          address: EMPTY_ADDRESS,
          displayAddress: '',
          geofencing: EMPTY_POSITION,
        },
        pickupAt: null,
        description: '',
        validated: 0,
      },
    ],
  })
  const [activeStep, setActiveStep] = useState(0)
  const handleChangeRider = useCallback(
    value => {
      setFormData({ ...formData, riderId: value.id })
      setActiveStep(1)
    },
    [formData],
  )

  const handleAddTripOrders = useCallback(
    (value: any) => {
      setFormData({ ...formData, tripOrders: value })
      setActiveStep(2)
    },
    [formData],
  )

  const handleBack = useCallback(() => {
    setActiveStep(value => value - 1)
  }, [])

  // TODO Setup adding new rider functionality to TripRiderPicker
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
        <StepLabel>
          <Typography className={classes.sectionLabel}>Rider Information</Typography>
        </StepLabel>
        <StepContent>
          <TripRiderPicker onNext={handleChangeRider} setRider={setRider} rider={rider} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <Typography className={classes.sectionLabel}>Trip Request Details</Typography>
        </StepLabel>
        <StepContent>
          <TripDetails onNext={handleAddTripOrders} onBack={handleBack} formData={formData} />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>Review Trip Request Details</StepLabel>
        <StepContent>
          <TripReview rider={rider} onCreate={onSubmit} values={formData} onBack={handleBack} />
        </StepContent>
      </Step>
    </Stepper>
  )
}
