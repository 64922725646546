import React from 'react'
import {number, object} from 'yup'
import {FormikHelpers as FormikActions, FormikProps, FormikValues, withFormik} from "formik"

import {getFormikFieldError} from "../../../../../utils/getErrorMessage";
import InputComponent from "../../../components/default-input";
import FormWrapper from "../../../components/form-wrapper";
import ActionBar from "../../../components/action-bar";

import './index.scss'

const userPhoneVerificationValidationSchema = object().shape({
  code: number()
    .required('Verification code is required')
})

interface IFormProps extends FormikProps<FormikValues>{
  onClickCancel: VoidFunction
  onClickResend: VoidFunction
}

const UserPhoneVerificationForm = (props: IFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    onClickCancel,
    onClickResend
  } = props

  return(
    <form onSubmit={handleSubmit}>
      <InputComponent
        name='code'
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Verification Code"
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('code', errors, touched)}
      />
      <span className='phone_verification_resend_code' onClick={onClickResend}>
        Resend the Code
      </span>
      <ActionBar
        actionLeft={{
          variant: 'text',
          text: 'CANCEL',
          onClick: onClickCancel
        }}
        actionRight={{
          type: 'submit',
          color: 'yellow',
          text: 'SAVE',
          disabled: !isValid || isSubmitting
        }}
      />
    </form>
  )
}

interface IUserPhoneVerificationProps {
  initialValues: {
    code: string
  },
  onCancel: VoidFunction
  onResend: VoidFunction
  onSave: (code: string) => {}
}

const UserPhoneVerification = (props: IUserPhoneVerificationProps) => {
  const {
    initialValues,
    onResend,
    onCancel,
    onSave
  } = props

  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>) => {
    await onSave(values.code)
    actions.setSubmitting(false)
  }

  const Form = withFormik({
    mapPropsToValues: ({initialValues}: any) => initialValues,
    validationSchema: userPhoneVerificationValidationSchema,
    handleSubmit: submitFormHandler
  })(UserPhoneVerificationForm)

  return(
    <FormWrapper title={'Phone Number Verification'}>
      <Form
        initialValues={initialValues}
        onClickCancel={onCancel}
        onClickResend={onResend}
      />
    </FormWrapper>
  )
}

export default UserPhoneVerification
