
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import CreateOrUpdateOrganizationPrompt from '../components/CreateOrUpdateOrganizationPrompt'
import { useEffect, useCallback } from 'react'


const useCreateOrUpdateOrganizationPrompt = (onCreateOrUpdate?: () => void) => {
  const prompt = usePrompt(CreateOrUpdateOrganizationPrompt) as any

  return useCallback((organizationId?: number) => {
    if (prompt.showing) return

    //TODO this is still awkward and we're still repeating it...
    if (onCreateOrUpdate) {
      const unlistens = [
        prompt.onComplete(onCreateOrUpdate),
        prompt.onHide( () => { unlistens.forEach( unlisten => unlisten() ) })
      ]
    }

    prompt.show({ organizationId })
  }, [prompt, onCreateOrUpdate])
}

export default useCreateOrUpdateOrganizationPrompt
