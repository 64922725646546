import React, { useEffect } from 'react'
import useDocumentTypesCollection from 'documents/hooks/useDocumentTypesCollection'
import { RouteComponentProps } from 'react-router'
import PageHeader from 'App/components/PageHeader'
import useDocumentTypeColumns, { useStyles } from 'documents/hooks/useDocumentTypeColumns'
import { useColumnOrder, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import { STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import Loading from 'components/Loading'
import Grid from '@material-ui/core/Grid'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import { TableMenuButton } from 'civic-champs-shared'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import AddIcon from '@material-ui/icons/Add'
import { DragHandle as DragHandleIcon } from '@material-ui/icons'

const tableName = 'document_type'

export const DocumentTypes = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Documents" subTitle="Create and manage document types to be collected on Questionnaires">
      <DocumentTypesComponent {...props} />
    </PageHeader>
  )
}

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'name':
      return STRING_OPERATOR_OPTIONS

    default:
      return []
  }
}

export const DocumentTypesComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [{ documentTypes, initiallyLoaded }, { reorderDocumentTypes, addEditDocumentType }, eventListeners] =
    useDocumentTypesCollection()
  const columns = useDocumentTypeColumns({ addEditDocumentType })
  const columnStyles = useStyles()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
        // @ts-ignore
        filters,
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: documentTypes,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )
  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }
  // @ts-ignore
  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Documents"
        buttons={
          <>
            <TableMenuButton startIcon={<DragHandleIcon />} onClick={() => reorderDocumentTypes(table)} rightMargin>
              Reorder
            </TableMenuButton>
          </>
        }
        addButton={
          <ContainedButton
            startIcon={<AddIcon />}
            // @ts-ignore
            onClick={() => addEditDocumentType()}
          >
            Add Document Type
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export default DocumentTypes
