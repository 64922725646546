export enum WebhookStatus {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Broken = 'broken',
}

export enum WebhookEventType {
  VolunteerCreated = 'volunteer_created',
  VolunteerUpdated = 'volunteer_updated',
  VolunteerDeleted = 'volunteer_deleted',
  VolunteerEventsUpdated = 'volunteer_events_updated',
  OrganizationUpdated = 'organization_updated',
}

export enum WebhookEventStatus {
  Retrying = 'retrying',
  Processed = 'processed',
  Failed = 'failed',
  Canceled = 'canceled',
}

export interface Webhook {
  id: number
  organizationId: number
  eventType: WebhookEventType
  url: string
  secret?: string
  status: WebhookStatus
  createdAt: Date
  deletedAt?: Date
}

export interface WebhookEventResponse {
  id: number
  occurredAt: Date
  webhookEventId: number
  error: string
  responseStatus: string
  newEventStatus: WebhookEventStatus
  responseCode: number | null
  responseBody: string | null
  webhookId: number
  payload: any
}

export enum IntegrationProvider {
  LGL = 'lgl',
}

export interface IntegrationKey extends IntegrationKeyPayload {
  id: number
  createdAt: Date
  enabled: boolean
}

export interface IntegrationKeyPayload {
  id?: number
  provider: IntegrationProvider
  token: string
  organizationId: number
  enabled?: boolean
}
