import { useEffect, useMemo, useState } from 'react'
import useOrganization from 'volunteering/organizations/hooks/useOrganization'
import useSetMentoringCustomer from 'volunteering/organizations/hooks/useSetMentoringCustomer'
import { useCurrentOrg } from 'auth/hooks'

export const useMentoringCustomer = () => {
  const currentOrg = useCurrentOrg()
  const { result: organization, loading } = useOrganization(currentOrg.id)
  const [mentoringCustomer, setMentoringCustomer] = useState(false)
  const setIsMentoringCustomer = useSetMentoringCustomer()
  useEffect(() => {
    if (organization) {
      setMentoringCustomer(organization.isMentoringCustomer)
    }
  }, [organization])

  const handleChange = async (isMentoringCustomer: boolean) => {
    await setIsMentoringCustomer(currentOrg.id, isMentoringCustomer)
    setMentoringCustomer(isMentoringCustomer)
  }

  return useMemo(
    () => ({
      isMentoringCustomer: mentoringCustomer,
      setIsMentoringCustomer: handleChange,
      loading,
    }),
    [handleChange, loading, mentoringCustomer],
  )
}
