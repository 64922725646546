//TODO move this to the /filtering package
import { DATE_RANGE_THIS_YEAR, getDateRangeFromPreset } from 'components/filter/DateRangePresets'
import { SET_DATE_RANGE_FILTER } from './actions'

//TODO track when filter was last updated so we can ignore current settings on hydrate after too long
const defaultState = () => ({
  dateRange: getDateRangeFromPreset(DATE_RANGE_THIS_YEAR),
  custom: {},
})

export default function reducer(state = defaultState(), action) {
  switch (action.type) {
    case SET_DATE_RANGE_FILTER:
      if (action.payload.key) {
        return {
          ...state,
          custom: {
            ...state.custom,
            [action.payload.key]: action.payload.dateRange,
          },
        }
      }
      return {
        ...state,
        dateRange: action.payload.dateRange,
      }
    default:
      return state
  }
}
