import React, { useMemo } from 'react'
import { Grid, Typography, Box, Button, makeStyles } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import { PersonNameCell, PhoneNumberCell, DateCell } from 'core/table/cells'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { AddWhitelistedPhoneNumberPrompt } from '../components/AddWhitelistedPhoneNumberPrompt'
import { ClearWhitelistedPhoneNumberPrompt } from '../components/ClearWhitelistedPhoneNumberPrompt'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'

const useStyles = makeStyles(theme => ({
  addButton: {
    backgroundColor: '#ffcd00',
  },
}))

const useColumns = refresh => {
  const showDeleteWhiteListedPhoneNumber = useShowPrompt(ClearWhitelistedPhoneNumberPrompt)
  return useMemo(
    () => [
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateCell,
        filter: 'dateRange',
      },
      { Header: 'Phone Number', accessor: 'phoneNumber', Cell: PhoneNumberCell },
      {
        Header: 'Added By',
        accessor: 'addedBy',
        Cell: PersonNameCell,
      },
      {
        Header: '',
        id: 'delete',
        Cell: ({ cell }) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => showDeleteWhiteListedPhoneNumber({ whitelistedPhoneNumber: cell.row.original, refresh })}
          >
            DELETE
          </Button>
        ),
      },
    ],
    [showDeleteWhiteListedPhoneNumber, refresh],
  )
}

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.phoneNumber', 'original.addedBy.givenName', 'original.addedBy.familyName'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const WhitelistedPhoneNumbersOverview = () => {
  const [refresh, { loading, result: whitelistedContacts }] = useGetOnMount({
    url: '/whitelist-dev-phone-number',
    errorMessage: 'Error fetching white listed phone numbers',
    emptyValue: [],
  })
  const classes = useStyles()
  const [dateRange, setDateRange] = useDateRangeFilter()
  const addToWhitelist = useShowPrompt(AddWhitelistedPhoneNumberPrompt)
  const table = useTable(
    {
      data: whitelistedContacts,
      columns: useColumns(refresh),
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Grid item>
              <Typography variant="h4">Whitelisted Phone Number</Typography>
            </Grid>
            <Grid item style={{ marginLeft: '10px' }}>
              <Button variant="contained" className={classes.addButton} onClick={() => addToWhitelist({ refresh })}>
                <AddCircleIcon style={{ marginRight: 5 }} />
                Add
              </Button>
            </Grid>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid>
          <p>In Dev and Staging, all text messages will go to (812)-720-9809 unless the number is listed below</p>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
