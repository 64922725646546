import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'

import { createContactSort, createDateValueSort, createRoleSort } from 'components/table/sort'
import MemberRoleSelect from '../components/MemberRoleSelect'

import { ContactCell, DateCell } from 'core/table/cells'
import { contactAccessor } from 'core/table/utils'

import { useUISettings } from 'hooks/useUISettings'
import { useHasRole } from '../../../auth/hooks'
import { INTERNAL_ADMIN } from '../../../civic-champs-shared/auth/utils/permissions'

export const useStyles = makeStyles(() => ({
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  contactColumn: {
    '&>div': {
      width: '100px',
    },
  },
  contactColumnHeader: {
    fontSize: '20px',
    width: '100px',
    color: '#001B3F',
  },
  contactIcon: {
    fontSize: 'inherit',
    color: '#000',
    cursor: 'default',
  },
  consolidatedContactColumn: {
    '&>div': {
      width: '50px',
    },
  },
  emailColumn: {
    '&>div': {
      width: '210px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  mobileColumn: {
    '&>div': {
      width: '150px',
    },
  },
  homeColumn: {
    '&>div': {
      width: '150px',
    },
  },
}))

export function useAdminsColumns(changeRole) {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'person.id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'person',
            Cell: ({ value: { givenName, familyName } }) => `${givenName} ${familyName}`,
            disableFilters: true,
            className: classes.nameColumn,
          },
          { id: 'firstName', Header: 'First Name', accessor: 'person.givenName' },
          { id: 'lastName', Header: 'Last Name', accessor: 'person.familyName' },
          {
            Header: 'Permission',
            accessor: 'role',
            Cell: ({ cell }) => (
              <MemberRoleSelect
                styled
                value={cell.value}
                onChange={newRole => {
                  changeRole(cell.row.original, newRole)
                }}
              />
            ),
            sortType: createRoleSort('role'),
          },
          {
            id: 'email',
            Header: 'Email',
            sortType: createContactSort('email'),
            accessor: contactAccessor({
              valuePath: 'person.email',
              verificationStatusPath: 'person.emailVerified',
              type: 'email',
            }),
            Cell: ContactCell,
          },
          {
            id: 'phone',
            Header: 'Phone',
            sortType: createContactSort('phone'),
            accessor: contactAccessor({
              valuePath: 'person.phoneNumber',
              verificationStatusPath: 'person.phoneNumberVerified',
              type: 'phone',
            }),
            Cell: ContactCell,
          },
          {
            id: 'joinedDate',
            Header: 'Joined Date',
            accessor: 'startedAt',
            Cell: DateCell,
            sortType: createDateValueSort('startedAt'),
          },
          {
            id: 'lastLoginDate',
            Header: 'Last Login',
            accessor: 'person.lastLoginAt',
            Cell: DateCell,
            sortType: createDateValueSort('person.lastLoginAt'),
          },
        ],
        {
          showAdminItems,
          isInternalAdmin,
        },
      ),
    [changeRole, classes.nameColumn, isInternalAdmin, showAdminItems],
  )
}
