import { useCurrentOrg } from '../../auth/hooks'
import { useCallback } from 'react'
import useFetchRefactored from '../../civic-champs-shared/api/hooks/useFetchRefactored'
import { CredentialFull } from '../../civic-champs-shared/question-sets/types'
import { Status } from '../../civic-champs-shared/api/hooks'

export const useGetOrganizationCredentials = (onSuccess?: (data: any) => any) => {
  const currentOrganization = useCurrentOrg()
  const [request, status] = useFetchRefactored<CredentialFull[]>()

  const getOrganizationCredentials = useCallback(async () => {
    // @ts-ignore
    return request({
      url: `/organizations/${currentOrganization.id}/credentials`,
    }).then((data: any) => {
      if (onSuccess) {
        onSuccess(data)
      }
      return data
    })
  }, [request, currentOrganization.id, onSuccess])

  return [getOrganizationCredentials, status] as [() => Promise<CredentialFull[]>, Status<CredentialFull[]>]
}
