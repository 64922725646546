export const useSharedWorker = (): MessagePort | null => {
  // @ts-ignore
  if ('SharedWorker' in window && !window?.sharedWorker) {
    // @ts-ignore
    window.sharedWorker = new SharedWorker('/scripts/eventWorker.js?v=5')
    // @ts-ignore
    window.sharedWorker.port.start()
  }
  // @ts-ignore
  return window?.sharedWorker?.port
}
