import { get } from 'lodash'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import BackLink from 'donation/campaigns/components/BackLink'
import { FormikWizard, Step } from 'civic-champs-shared/formik/FormikWizard'
import TaskRequester from './TaskRequester'
import TaskRecipient from './TaskRecipient'
import TaskDetails from './TaskDetails'
import TaskReview from './TaskReview'
import { volunteerTaskSchema } from '../task.schema'
import useCreateTask from '../hooks/useCreateTask'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import moment from 'moment'

export const usePublishTask = () => {
    const history = useHistory()
    const { createTask } = useCreateTask()
    const showError = useErrorNotification()
    return useCallback( async (task, editor) => {
        const { recipient, requester, volunteer, volunteeringRole, location, timeslots, ...props } = task
        const payload = {
            ...props,
            contactId: get(requester, 'id'),
            requesterId: get(requester, 'id'),
            recipientId: get(recipient, 'id'),
            volunteeringRoleId: get(volunteeringRole, 'id'),
            locationId: get(location, 'id'),
            overdueAt: moment.max(timeslots.map(ts => ts.endTime)).format(),
            timeslots,
        }

        try {
            const task = await createTask(payload)
            history.push(`/tasks/${task.id}`)
        } catch(err) {
            showError('There was a problem creating this task', err)
        }
    }, [createTask, history, showError])
}

const initialValues = {
    description: '',
    recipientIsContact: false,
    recipient: {},
    requester: {},
    timeslots: [],
    volunteeringRole: {}
}

export default function CreateTask() {
    const handlePublish = usePublishTask()
    return (
        <div>
            <BackLink to="/helping-hands" text="Back to Helping Hands Overview" />
            <FormikWizard
                initialValues={initialValues}
                validationSchema={volunteerTaskSchema}
                onPublish={handlePublish}
                publishButtonLabel='Create Task'
            >
                <Step
                    name="Recipient"
                    component={TaskRecipient}
                    schemaFields={TaskRecipient.SCHEMA_FIELDS}
                />
                <Step
                    name="Requester"
                    component={TaskRequester}
                    schemaFields={TaskRequester.SCHEMA_FIELDS}
                />
                <Step
                    name="Task Details"
                    component={TaskDetails}
                    schemaFields={TaskDetails.SCHEMA_FIELDS}
                />
                <Step
                    name="Review"
                    component={TaskReview}
                />
            </FormikWizard>
        </div>
    )
}
