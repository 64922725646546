import { useCallback } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export const useUpdateSalsaIntegration = onResultUpdate => {
  const [request, { result, loading }] = useFetch('Error saving Salsa Integration configuration')
  const showSuccess = useSuccessNotification()
  const updateSalsaIntegration = useCallback(
    async salsaIntegration => {
      const existingIntegration = !!salsaIntegration.id
      const { method, url } = existingIntegration
        ? {
            method: 'put',
            url: `/salsa/organizations/${salsaIntegration.organizationId}/update`,
          }
        : { method: 'post', url: `/salsa/organizations/${salsaIntegration.organizationId}` }
      return request({
        onSuccess: result => {
          showSuccess('Successfully saved Salsa Integration configuration')
          onResultUpdate && onResultUpdate(result)
        },
        method,
        url,
        config: {
          body: { ...salsaIntegration },
        },
      })
    },
    [request],
  )

  return {
    updateSalsaIntegration,
    salsaIntegration: result,
    loading,
  }
}
