import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { GroupMembership } from '../../../Event/modules/events/create-edit/custom-questions/GroupMembership'

const useStyles = makeStyles(theme => ({
  datePickerWrap: {
    width: '128px',
  },
  timePickerRow: {
    margin: '16px 0',
  },
}))

const GroupMembershipStep = props => {
  const { goToPrevStep, onChange, isUpdate, formikProps } = props
  const { values, handleSubmit, isValid, isSubmitting } = formikProps
  const { visibility, onboardingGroups } = values
  const disabled = !isValid || isSubmitting
  const classes = useStyles()
  return (
    <Grid container direction="row">
      <Grid item container direction="row" spacing={2} className={classes.timePickerRow}>
        <GroupMembership visibility={visibility} onChange={onChange} onboardingGroups={onboardingGroups} />
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={goToPrevStep} disabled={isSubmitting}>
            Go Back
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" type="submit" disabled={disabled} onClick={handleSubmit}>
            {isUpdate ? 'Update Opportunity' : 'Create Opportunity'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GroupMembershipStep
