import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'

const useEditPersonLocation = () => {
  const [request, { loading }] = useFetch()

  const editPersonLocation = useCallback(
    (personId, payload) => {
      return request({
        method: 'put',
        url: `/persons/${personId}/locations/${payload.location.id}`,
        config: {
          body: payload,
        },
      })
    },
    [request],
  )

  return { editPersonLocation, loading }
}

export default useEditPersonLocation
