import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useInputStyles = makeStyles({
  root: {
    background: 'white',
    '& label': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    '& label.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '& label.MuiFormLabel-filled': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
})

const useOutlinedStyles = makeStyles({
  root: {
    fontSize: '16px',
    lineHeight: '20px',
    minHeight: '45px',
    padding: '2px 62px 2px 8px',
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
  },
  notchedOutline: {
    borderColor: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid !important',
  },
  input: {
    fontFamily: 'Nunito, sans-serif',
    padding: '14px 16px',
  },
  adornedEnd: {
    '& > div ': {
      right: '20px !important',
    },
  },
})

export const StyledAutocompleteComponent = ({ label, error, helperText, ...props }: any) => {
  const classes = useInputStyles()
  const outlinedClasses = useOutlinedStyles()
  return (
    <Autocomplete
      {...props}
      style={{ width: '100%' }}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          classes={classes}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          InputProps={{ ...params.InputProps, classes: outlinedClasses }}
        />
      )}
    />
  )
}

export default StyledAutocompleteComponent
