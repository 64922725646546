import React, { useMemo } from 'react'

import useFormatToOrgTime from 'Event/scenes/events/hooks/useFormatToOrgTime'

const useOrgDateTimeCell = () => {
  const formatTime = useFormatToOrgTime()
  return useMemo(
    () =>
      ({ cell, className = '' }: { cell: any; className: string }) =>
        <div className={className}>{formatTime(cell.value, 'MM/DD/YYYY hh:mm a')}</div>,
    [formatTime],
  )
}

export default useOrgDateTimeCell
