import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OfferSignup } from './useOfferSignupsCollection'

export const useFetchOfferSignups = (opportunityId: number) => {
  const [request, { loading, error }] = useFetchRefactored<OfferSignup[]>()
  const fetchOfferSignups = useCallback(
    async () =>
      request({
        url: `/opportunities/${opportunityId}/signups`,
      }),
    [request, opportunityId],
  )

  return { fetchOfferSignups, loading, error }
}
