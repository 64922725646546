import React, { useCallback, useMemo, useState } from 'react'
import { useGroups } from 'group/hooks/useGroups'
import { find } from 'lodash'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import useTripModalStyles from 'volunteering/trips/hooks/useTripModalStyles'
import { TripOrder } from 'volunteering/trips/types'
import { AdvancedPersonAutocomplete } from 'civic-champs-shared/core/AdvancedPersonAutocomplete'
import { mapPersonToSuggestion, PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import { getDisplayAddress } from 'civic-champs-shared/core/location/utils'
import format from 'civic-champs-shared/utils/format'
import { FormModal, FormModalScreen } from 'components/FormModal'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  trip: TripOrder
}

export const AssignDriverModal = ({ showing, close, complete, trip }: Props) => {
  const [driver, setDriver] = useState<PersonSuggestion | null>(
    trip?.driver ? mapPersonToSuggestion(trip?.driver) : null,
  )
  const { groups } = useGroups()
  const driverGroupId: number = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Driver } })?.id, [groups])
  const handleSubmit = useCallback(() => {
    complete(driver)
  }, [complete, driver])
  const pickupLocation = getDisplayAddress(trip.pickupLocation.address)
  const dropOffLocation = getDisplayAddress(trip.dropOffLocation.address)

  const classes = useTripModalStyles()
  return (
    <FormModal
      id="trip-driver-prompt"
      showing={showing}
      close={close}
      className={classes.driverDialog}
      title="Assign a Driver"
    >
      <FormModalScreen onBack={close} onNext={handleSubmit} nextText="Assign Driver" backText="Cancel">
        <div className={classes.tripTimeInfo}>
          Pickup Time: {format.time(trip.pickupAt)}
          <br />
          Pickup Location: {pickupLocation}
          <br />
          <br />
          Drop-Off Time: {format.time(trip.dropOffAt)}
          <br />
          Pickup Location: {dropOffLocation}
          <br />
        </div>
        <AdvancedPersonAutocomplete
          label={'Driver *'}
          placeholder={'Select a person to be the trip’s driver'}
          groupId={driverGroupId}
          value={driver}
          excludeNonGroupMembers={true}
          onChange={driver => setDriver(driver as PersonSuggestion)}
        />
      </FormModalScreen>
    </FormModal>
  )
}
export default AssignDriverModal
