import React, { FC, useCallback, useState } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import { useEditQuestionSet, useQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import isUndefined from 'lodash/isUndefined'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { QuestionSetSurveyEditorProps } from 'question-sets/pages/QuestionSetSurveyEditor'

export const QuestionSetEditor: FC<QuestionSetSurveyEditorProps> = ({
  match: {
    url,
    params: { id },
  },
}) => {
  const [redirectLink, setRedirectLink] = useState<string | undefined>(undefined)
  const isNew = isUndefined(id)
  const backLink = url.substr(0, url.length - (isNew ? 4 : 5))
  const questionSetId = isNew ? undefined : parseInt(id as string)
  const { questionSet, loading: fetchLoading } = useQuestionSet(questionSetId)
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet()
  const organization = useCurrentOrg()
  const onSubmit = useCallback(
    payload =>
      editQuestionSet({ ...payload, organization, questionSetId }).then(response => {
        if (isNew) {
          setRedirectLink(`${backLink}/${response.questionSetId}`)
        }
        return response
      }),
    [backLink, editQuestionSet, isNew, organization, questionSetId],
  )

  if (!isNew && fetchLoading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={editLoading}
      onSubmit={onSubmit}
      questionSet={!isNew ? questionSet : undefined}
      backLink={backLink}
      backText={isNew ? 'Back to Questionnaires' : `Back to ${questionSet.name}`}
      title={id ? 'Edit Questionnaire' : 'New Questionnaire'}
      redirectLink={redirectLink}
    />
  )
}

export default QuestionSetEditor
