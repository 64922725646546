import React from 'react'
import { find } from 'linkifyjs'
import filter from 'lodash/filter'

export const linkify = (text: string, maxLength?: number) => {
  const matches = filter(find(text), 'isLink')
  if (!matches.length) return text
  let position = 0
  return (
    <>
      {matches.map(({ href, start, end, value }, index) => {
        const chunk = (
          <>
            {text.substring(position, start)}
            <a style={{ color: '#0F5DB5' }} href={href} target="_blank">
              {value.substring(0, maxLength)}
            </a>
            {index === matches.length - 1 ? text.substring(end) : null}
          </>
        )
        position = end
        return chunk
      })}
    </>
  )
}
