import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import { useCallback, useEffect } from "react";

import AddMemberPrompt from "../components/AddMemberPrompt";

const useAddAdminPrompt = (eagerAdd) => {
    const addMemberPrompt = usePrompt(AddMemberPrompt);

    useEffect(() => {
        const unlisten = addMemberPrompt.onComplete(eagerAdd)
        return unlisten
    }, [addMemberPrompt, eagerAdd])

    return useCallback(() => addMemberPrompt.show(), [addMemberPrompt]);
}

export default useAddAdminPrompt
