import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import { useRemoveActivities } from './useRemoveActivities'

export const useRemoveActivitiesPrompt = (eagerRemoveMany: any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeActivities = useRemoveActivities(eagerRemoveMany)

  return useCallback(
    async activities => {
      const confirmed = await showPrompt({
        text: 'Deleted activities are lost and gone forever.',
        title: 'Are you sure you want to delete all selected activities?',
        confirmText: 'Delete Selected Activities',
        cancelText: 'Do Not Delete',
      })

      if (confirmed) {
        await removeActivities(activities)
      }
    },
    [removeActivities, showPrompt],
  )
}

export default useRemoveActivitiesPrompt
