import React, { useCallback, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { isUndefined } from 'lodash'
import useAvatarUpload from 'champion/hooks/useAvatarUpload'
import useAvatarDialogStyles from 'champion/hooks/useAvatarDialogStyles'
import { AvatarCropUI } from 'champion/components/AvatarCropUI'
import AvatarDragContainer from 'champion/components/AvatarDragContainer'
import { AVATAR_DEFAULT_ZOOM as DEFAULT_ZOOM, AVATAR_MAX_ZOOM as MAX_ZOOM } from 'champion/interfaces'

interface Props {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  personId: number
}

const AvatarPrompt = (props: Props) => {
  const { showing, close, complete, personId } = props
  const uploadAvatar = useAvatarUpload()
  const classes = useAvatarDialogStyles()
  const imageRef = React.useRef<HTMLImageElement>()
  const dataRef = React.useRef<{ x: number; y: number; zoom: number }>({
    x: (MAX_ZOOM - DEFAULT_ZOOM) / 2,
    y: (MAX_ZOOM - DEFAULT_ZOOM) / 2,
    zoom: DEFAULT_ZOOM,
  })
  const [file, setFile] = useState<string | null>(null)

  const handleSubmit = useCallback(async () => {
    const resultingImageSize = 300
    const image = imageRef.current as HTMLImageElement
    const { naturalWidth, naturalHeight } = image
    const { x, y, zoom } = dataRef.current
    let minDimension: number
    let cropX = 0
    let cropY = 0
    if (naturalWidth > naturalHeight) {
      minDimension = naturalHeight
      cropX = (naturalWidth - naturalHeight) / 2
    } else {
      minDimension = naturalWidth
      cropY = (naturalHeight - naturalWidth) / 2
    }
    const scale = minDimension / MAX_ZOOM

    const canvas = document.createElement('canvas')
    canvas.width = resultingImageSize
    canvas.height = resultingImageSize
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    if (!isUndefined(ctx.imageSmoothingQuality)) {
      ctx.imageSmoothingQuality = 'high'
    }

    ctx.drawImage(
      image,
      cropX + x * scale,
      cropY + y * scale,
      zoom * scale,
      zoom * scale,
      0,
      0,
      resultingImageSize,
      resultingImageSize,
    )
    canvas.toBlob(
      async blob => {
        // As safari doesn't support webp export we need to check resulting blob type to see which format was used as a fallback
        const { type } = blob as Blob
        const extension = type.substr(type.indexOf('/') + 1)
        const file = new File([blob as Blob], `user-avatar-${personId}.${extension}`, { type })
        const avatar = await uploadAvatar(file, personId)

        complete(avatar)
      },
      'image/webp',
      0.9,
    )
  }, [complete, personId, uploadAvatar])

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#avatar-prompt` }}
      aria-labelledby={`avatar-title`}
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={`avatar-prompt`}>
        Edit Profile Picture
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {file ? (
          <AvatarCropUI file={file} setFile={setFile} imageRef={imageRef} dataRef={dataRef} />
        ) : (
          <AvatarDragContainer setFile={setFile} />
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <OutlinedButton onClick={close}>Cancel</OutlinedButton>
        <ContainedButton onClick={handleSubmit} disabled={!file}>
          Save
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default (props: Props) => (
  <ThemeProvider theme={muiTheme}>
    <AvatarPrompt {...props} />
  </ThemeProvider>
)
