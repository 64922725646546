import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export const useCancelOfferSignups = (opportunityId: number) => {
  const [request] = useFetchRefactored<void>({
    successMessage: 'Signups cancelled',
    errorMessage: 'Failed to cancel signups',
  })
  return useCallback(
    async userIds =>
      request({
        url: `/opportunities/${opportunityId}/cancel-signups`,
        method: 'post',
        config: { body: { userIds } },
      }),
    [request, opportunityId],
  )
}
