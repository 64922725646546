import useRemoveMember from './useRemoveMember'
import React, {useCallback} from 'react'
import { memberSummary } from './utils'
import CommentPrompt from '../components/CommentPrompt'
import {useConfirm} from "../../civic-champs-shared/core/modal/hooks";

const useRemoveMemberPrompt = (group, eagerRemoveMany) => {
    const comment = useConfirm(CommentPrompt)
    const removeMember = useRemoveMember(eagerRemoveMany)

    return useCallback(
        async members => {
            const { numMembers, onlyOneMember, memberName } = memberSummary(members)
            const text = onlyOneMember
                ? `Are you sure you want to remove ${memberName} from ${group.name}?`
                : `Are you sure you want to remove these ${numMembers} members from ${group.name}`
            const confirmed = await comment(text, {
                type: 'warning',
                title: 'Remove Member',
                confirmText: 'Remove Member',
                rejectText: 'Cancel',
                placeholder: 'Add a note to track the reason for the removal',
                rows: 3,
                fullWidth: true,
                multiline: true,
                variant: 'outlined'
            })

            if (confirmed.choice) {
                await removeMember(group, members, confirmed.comment)
            }
        },
        [comment, removeMember],
    )
}

export default useRemoveMemberPrompt
