import { useCallback, useMemo } from 'react'
import * as _ from 'lodash'
import { CREATE_NEW_QUESTION_SET } from '../QuestionSetPicker/hooks'
import yup from 'civic-champs-shared/utils/yup'

export const CREATE_NEW_ID = 'CREATE NEW ID PROMPT'

export const useOptions = ({
  questionSets,
  questionValueQuestionSets,
  isGlobalUserProfile,
  isQuestionnaireAsSurveyEnabled,
}) => {
  return useMemo(() => {
    const key = isQuestionnaireAsSurveyEnabled ? 'id' : 'questionSetId'
    const selectedQuestionsByKey = _.keyBy(questionValueQuestionSets, key)
    const mappedQuestions = questionSets.reduce((acc, { id: surveyId, anonymous, questionSetId, name }) => {
      if (!anonymous || selectedQuestionsByKey[surveyId || questionSetId]) {
        acc.push({
          ...(isQuestionnaireAsSurveyEnabled ? { surveyId } : { questionSetId }),
          name,
          optional: false,
        })
      }
      return acc
    }, [])

    return isGlobalUserProfile ? [...mappedQuestions] : [CREATE_NEW_QUESTION_SET, ...mappedQuestions]
  }, [questionValueQuestionSets, isQuestionnaireAsSurveyEnabled, questionSets, isGlobalUserProfile])
}

export const useHandleNewQuestionSet = ({
  openQuestionSetModal,
  setQuestionSets,
  questionSets,
  showAnonymousCheckbox,
}) =>
  useCallback(
    async (setFieldValue, index, questionSetId, optional = false) => {
      const addedQuestionSet = await openQuestionSetModal({ questionSetId, showAnonymousCheckbox })

      if (addedQuestionSet) {
        const newSet = {
          questionSetId: addedQuestionSet.questionSetId,
          name: addedQuestionSet.name,
          optional,
          required: !optional,
        }
        setQuestionSets(_.uniqBy([...questionSets, newSet], 'questionSetId'))
        setFieldValue(`requirements[${index}].value`, newSet)
      }
    },
    [openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox],
  )

export const useHandleNewSurvey = ({ openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox }) =>
  useCallback(
    async (setFieldValue, index, surveyId, optional = false) => {
      const addedSurvey = await openQuestionSetModal({ surveyId, showAnonymousCheckbox })

      if (addedSurvey) {
        const newSet = {
          surveyId: addedSurvey.id,
          name: _.last(_.orderBy(addedSurvey.versions, ['version'])).questionSet.name,
          optional,
          required: !optional,
        }
        setQuestionSets(_.uniqBy([...questionSets, newSet], 'surveyId'))
        setFieldValue(`requirements[${index}].value`, newSet)
      }
    },
    [openQuestionSetModal, setQuestionSets, questionSets, showAnonymousCheckbox],
  )

export const useHandleNewWaiver = ({
  openNewWaiverModal,
  setWaiverCredentials,
  waiverCredentials,
  setCredentials,
  credentials,
}) =>
  useCallback(
    async (setFieldValue, index) => {
      const addedWaiver = await openNewWaiverModal()
      if (addedWaiver) {
        setWaiverCredentials([...waiverCredentials, addedWaiver])
        setCredentials([...credentials, addedWaiver])
        setFieldValue(`requirements[${index}].value`, addedWaiver.credential)
      }
    },
    [openNewWaiverModal, setWaiverCredentials, waiverCredentials, setCredentials, credentials],
  )

export const useHandleNewIdentification = ({
  openNewIdentificationModal,
  setIdentificationCredentials,
  identificationCredentials,
  setCredentials,
  credentials,
}) =>
  useCallback(
    async (setFieldValue, index) => {
      const addedWaiver = await openNewIdentificationModal()
      if (addedWaiver) {
        setIdentificationCredentials([...identificationCredentials, addedWaiver])
        setCredentials([...credentials, addedWaiver])
        setFieldValue(`requirements[${index}].value`, addedWaiver.credential)
      }
    },
    [openNewIdentificationModal, setIdentificationCredentials, identificationCredentials, setCredentials, credentials],
  )

export const usePickerSchema = (min, _max, isQuestionnaireAsSurveyEnabled = false) =>
  useMemo(
    () =>
      yup.object({
        requirements: yup
          .array()
          .min(min, `At least ${min} requirements are required`)
          .of(
            yup.lazy(value => {
              if (value.tempType) {
                switch (value.tempType) {
                  case 'id':
                    if (typeof value.value === 'object') {
                      return yup.object().shape({
                        value: yup.object().shape({
                          id: yup.number().required(),
                        }),
                      })
                    } else {
                      return yup.object().shape({
                        value: yup.number().required(),
                      })
                    }

                  case 'questionnaire':
                    return isQuestionnaireAsSurveyEnabled
                      ? yup.object().shape({
                          value: yup.object().shape({
                            surveyId: yup.number().required('Questionnaire must be specified'),
                          }),
                        })
                      : yup.object().shape({
                          value: yup.object().shape({
                            questionSetId: yup.number().required('Questionnaire must be specified'),
                          }),
                        })
                  case 'waiver':
                    if (typeof value.value === 'object') {
                      return yup.object().shape({
                        value: yup.object().shape({
                          id: yup.number().required(),
                        }),
                      })
                    } else {
                      return yup.object().shape({
                        value: yup.number().required(),
                      })
                    }
                  default:
                }
              }
              switch (value.type) {
                case 'identification':
                  return yup.object().shape({
                    value: yup.object().shape({
                      id: yup.number().required(),
                    }),
                  })
                case 'questionSet':
                  return isQuestionnaireAsSurveyEnabled
                    ? yup.object().shape({
                        value: yup.object().shape({
                          surveyId: yup.number().required('Questionnaire must be specified'),
                        }),
                      })
                    : yup.object().shape({
                        value: yup.object().shape({
                          questionSetId: yup.number().required('Questionnaire must be specified'),
                        }),
                      })

                case 'waiver':
                  return yup.object().shape({
                    value: yup.object().shape({
                      id: yup.number().required(),
                    }),
                  })
                default:
                  return yup.object().shape({
                    value: yup.object().shape({
                      id: yup.number().required(),
                    }),
                  })
              }
            }),
          ),
      }),
    [isQuestionnaireAsSurveyEnabled, min],
  )

export const useSubmit = ({ onSubmit, pickerSchema }) =>
  useCallback(
    values => {
      const castedValues = pickerSchema.cast(values)
      return onSubmit(castedValues.requirements)
    },
    [onSubmit, pickerSchema],
  )

export const useGetWaiverDirtyLookup = value =>
  useCallback(
    credentialIds => {
      return credentialIds.reduce((acc, credentialId, index) => {
        const currentValue = _.get(value, index)
        acc[index] = !currentValue || currentValue !== credentialId
        return acc
      }, {})
    },
    [value],
  )

export const useGetRequirementsDirtyLookup = value =>
  useCallback(
    requirements => {
      return requirements.reduce((acc, item, index) => {
        const currentValue = _.get(value, index)
        acc[index] =
          !currentValue ||
          (currentValue.value.id
            ? currentValue.value.id !== item.value.id
            : currentValue.value.questionSetId
            ? currentValue.value.questionSetId !== item.value.questionSetId
            : !_.isEqual(currentValue.value, item.value))
        return acc
      }, {})
    },
    [value],
  )

export const useOnWaiverEdit = ({ waiverCredentials, setWaiverCredentials, openNewWaiverModal }) =>
  useCallback(
    async credentialId => {
      const waiver = await openNewWaiverModal({ credentialId })
      if (waiver) {
        // in case the name changed, swap in the updated waiver
        const index = waiverCredentials.findIndex(x => x.credential.id === waiver.credential.id)
        const credentialsClone = _.cloneDeep(waiverCredentials)
        _.set(credentialsClone, index, waiver)
        setWaiverCredentials(credentialsClone)
      }
    },
    [waiverCredentials, setWaiverCredentials, openNewWaiverModal],
  )

export const useOnIdentificationEdit = ({
  identificationCredentials,
  setIdentificationCredentials,
  openNewIdentificationModal,
}) =>
  useCallback(
    async credentialId => {
      const waiver = await openNewIdentificationModal({ credentialId })
      if (waiver) {
        // in case the name changed, swap in the updated waiver
        const index = identificationCredentials.findIndex(x => x.credential.id === waiver.credential.id)
        const credentialsClone = _.cloneDeep(identificationCredentials)
        _.set(credentialsClone, index, waiver)
        setIdentificationCredentials(credentialsClone)
      }
    },
    [identificationCredentials, setIdentificationCredentials, openNewIdentificationModal],
  )

export const CREATE_NEW_WAIVER = 'CREATE NEW WAIVER'

export const useWaiverOptions = waiverCredentials =>
  useMemo(() => [CREATE_NEW_WAIVER, ...waiverCredentials], [waiverCredentials])
// this will change
export const useIdentificationOptions = credentialIDTypes =>
  useMemo(() => [CREATE_NEW_ID, ...credentialIDTypes], [credentialIDTypes])

export const useGetWaiverOptionsLabel = credentialById =>
  useCallback(
    option => {
      if (option === CREATE_NEW_WAIVER) {
        return CREATE_NEW_WAIVER
      }
      // if the 'option' is passed in by the 'value' prop, it will just be the id
      const optionId = _.get(option, 'credential.id', option.id ? option.id : option)
      return _.get(credentialById, `${optionId}.version.title`, '')
    },
    [credentialById],
  )

export const useGetIdentificationOptionsLabel = credentialById =>
  useCallback(
    option => {
      if (option === CREATE_NEW_ID) {
        return CREATE_NEW_ID
      }
      // if the 'option' is passed in by the 'value' prop, it will just be the id
      const optionId = _.get(option, 'credential.id', option.id ? option.id : option)
      return _.get(credentialById, `${optionId}.version.title`, '')
    },
    [credentialById],
  )
