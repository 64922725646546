import React from 'react'
import find from 'lodash/find'

import { EditCell } from 'core/table/cells'
import { timezones } from 'civic-champs-shared/helpers/timezones'
import { useTagManagement } from '../hooks'
import { CreateMatchForm } from './MatchFlowEditor'

export interface MentorshipEditCellProps {
  editMatch: (props: { id: number; initialValues: CreateMatchForm; editable: boolean }) => Promise<void>
  cell: any
  mentorGroupId: number
  menteeGroupId: number
}

export const MentorshipMatchesEditCell = ({
  editMatch,
  cell,
  menteeGroupId,
  mentorGroupId,
}: MentorshipEditCellProps) => {
  const {
    row: {
      original: {
        id,
        mentor,
        mentee,
        program,
        startedAt,
        stoppedAt,
        tags,
        meetingsTimezone,
        meetingCount,
        momentCount,
      },
    },
  } = cell

  const { linkTagsToMatch, unlinkTagsFromMatch } = useTagManagement()

  const mentorContact = mentor.email || mentor.phoneNumber
  const menteeContact = mentee.email || mentee.phoneNumber
  return (
    <EditCell
      cell={cell}
      onEdit={() =>
        editMatch({
          id,
          editable: meetingCount + momentCount === 0,
          initialValues: {
            program,
            mentor: {
              id: mentor.id,
              givenName: mentor.givenName,
              familyName: mentor.familyName,
              contact: mentorContact,
              title: `${mentor.givenName} ${mentor.givenName} - ${mentorContact} `,
              groupId: mentorGroupId,
            },
            mentee: {
              id: mentee.id,
              givenName: mentee.givenName,
              familyName: mentee.familyName,
              contact: menteeContact,
              title: `${mentee.givenName} ${mentee.givenName} - ${menteeContact} `,
              groupId: menteeGroupId,
            },
            startedAt,
            stoppedAt,
            tags,
            meetingsTimezone: find(timezones, { value: meetingsTimezone }) as { name: string; value: string } | null,
          },
        })
      }
    />
  )
}
