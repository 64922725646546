import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  orderColumn: {
    '&>div': {
      width: '50px',
      marginRight: '10px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
      marginRight: '10px',
    },
  },
  addressColumn: {
    '&>div': {
      width: '300px',
      marginRight: '10px',
    },
  },
  timesUsedColumn: {
    '&>div': {
      width: '150px',
      marginRight: '10px',
    },
  },
  typeColumn: {
    '&>div': {
      width: '150px',
      marginRight: '10px',
    },
  },
  editColumn: {
    '&>div': {
      width: '80px',
    },
  },
  mapIcon: {
    fontSize: '16px',
    color: '#0F5DB5',
  },
})

export default useStyles
