import { Button, makeStyles } from '@material-ui/core'
import AsyncAutocomplete from 'components/AsyncAutocomplete'
import { isNull } from 'lodash'
import React, { useEffect } from 'react'
import { filter } from 'lodash'
import { PersonAutocompleteOption } from '../../components/PersonAutocompleteOption'
import useFetchRiders from '../hooks/useFetchRiders'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  headerRow: {
    verticalAlign: 'middle',
    display: 'flex',
  },
  personPicker: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

interface TripRiderPickerProps {
  rider?: any
  onNext: Function
  setRider: Function
}

export const TripRiderPicker: React.FC<TripRiderPickerProps> = props => {
  const { onNext, rider, setRider } = props
  const classes = useStyles()
  // TODO add onOpenAddTripRiderPrompt functionality
  const [fetchRiders, { result: riders, loading: ridersLoading }] = useFetchRiders()
  const handleChange = (e: any, value: any) => (!isNull(value) ? setRider(value) : setRider({}))

  useEffect(() => {
    fetchRiders()
  }, [fetchRiders])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    onNext(rider)
  }

  // TODO add onOpenAddTripRiderPrompt button
  return (
    <div>
      <form onSubmit={handleSubmit} noValidate={false}>
        <AsyncAutocomplete
          options={riders}
          getOptionLabel={(opt: any) => `${filter([opt.givenName, opt.familyName]).join(' ')}`}
          renderOption={(opt: any) => <PersonAutocompleteOption option={opt} />}
          onChange={handleChange}
          value={rider}
          label="Who needs a ride?"
          loading={ridersLoading}
          className={classes.personPicker}
        />
        <div>
          <Button type="submit" disabled={!rider?.id} variant="contained" color="secondary">
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}
