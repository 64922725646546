import React from 'react'
import keyBy from 'lodash/keyBy'
import { Grid, Paper } from '@material-ui/core'
import { QuestionDetails } from './QuestionDetails'
import { Question } from '../types'

export const ReadOnlyQuestionSetQuestions = ({ questions }: { questions: Question[] }) => {
  const questionByQuestionId = keyBy(questions, 'questionId')
  return (
    <Grid item sm={12}>
      <Paper variant="outlined" style={{ padding: 20 }}>
        {questions.map((question: Question, index: number) => (
          <QuestionDetails
            key={question.questionId}
            question={question}
            index={index}
            questionByQuestionId={questionByQuestionId}
          />
        ))}
      </Paper>
    </Grid>
  )
}
