import { useMemo } from 'react'
import { Activity } from 'champion/utils/interface'
import isNil from 'lodash/isNil'

export const useGetActivitiesWithReflections = (activities: Omit<Activity, 'user' | 'organization'>[]) => {
  return useMemo(
    () => activities.filter(({ reflection, volunteerSatisfaction }) => !!reflection || !isNil(volunteerSatisfaction)),
    [activities],
  ) as Activity[]
}
