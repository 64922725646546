import React from 'react'
import { Field, Form, Formik } from 'formik'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { muiTheme } from 'theme'

import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'
import { StyledOutlinedTextField } from 'civic-champs-shared/formik/components'

import Tag from 'components/tag/Tag'
import { TagColor, TagPayload, tagPayloadSchema } from '../types'
import { TagColorPickerField } from './TagColorPickerField'

export interface AddEditTagModalProps {
  tag?: TagPayload
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const useStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '350px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  content: {
    padding: '8px 0',
  },
  tagPreview: {
    maxWidth: '300px',
  },
  previewPanel: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
  previewLabel: {
    fontFamily: 'Nunito',
    fontWeight: 600,
    padding: '10px 0 10px',
  },
})

const AddEditTagDialog = ({ tag, showing, close, complete }: AddEditTagModalProps) => {
  const handleSubmit = ({ name, color: { htmlColor, colorName } }: { name: string; color: TagColor }) =>
    complete({
      id: tag?.id,
      name,
      color: htmlColor,
      colorName,
    })

  const classes = useStyles()
  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#role-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik
        initialValues={{
          name: tag?.name || '',
          color: tag
            ? { htmlColor: tag.color, colorName: tag.colorName }
            : { htmlColor: 'rgba(0,0,0,0.1)', colorName: '' },
        }}
        validationSchema={tagPayloadSchema}
        onSubmit={handleSubmit}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, isValid, values }) => {
          return (
            <Form>
              <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="tag-prompt">
                {tag ? 'Edit Tag' : 'New Tag'}
                <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Field
                  component={StyledOutlinedTextField}
                  id="name"
                  name="name"
                  label="Enter a Tag Name"
                  variant="outlined"
                  fullWidth
                />
                <Field component={TagColorPickerField} id="color" name="color" fullWidth />
                <div className={classes.previewPanel}>
                  <Typography className={classes.previewLabel}>Preview</Typography>
                  <Tag id={0} color={values.name ? values.color.htmlColor : 'rgba(0,0,0,0)'} label={values.name} />
                </div>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton disabled={isSubmitting} onClick={close}>
                  Cancel
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} onClick={submitForm}>
                  Save
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export const AddEditTagModal = (props: AddEditTagModalProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddEditTagDialog {...props} />
  </ThemeProvider>
)

export default AddEditTagModal
