import React  from 'react'
import './index.scss'
import { NavLink } from 'react-router-dom'

interface LinkDetail {
  to: string
  text: string
  isActive?: (match: any, location: any) => boolean
}

interface Props {
  linkDetails: LinkDetail[]
}

export default function TabSwitcher(props: Props) {
  return (
    <div className='switcher_block'>
      {
        props.linkDetails.map((linkDetail, idx) =>
        <NavLink
          key={idx}
          to={linkDetail.to}
          exact
          isActive={linkDetail.isActive}
          activeClassName='active'
          className="switcher_block_button">
          {linkDetail.text}
        </NavLink>
        )
      }
    </div>
  )
}
