import React, { useMemo } from 'react'
import * as yup from 'yup'

import { FormikWizard, Step } from 'civic-champs-shared/formik/FormikWizard'
import BackLink from './BackLink'
import TargetAudience from './TargetAudience'
import BasicInfo from './BasicInfo'
import ThankYou from './ThankYou'
import DonatePrompt from './DonatePrompt'
import Frequency from './Frequency'
import Review from './Review'

import './index.scss' //TODO :-/
import moment from 'moment'

const REQUIRED_IMAGE_MESSAGE = 'Image is a required field'

const imageSchema = yup.object({
    id: yup.number().required(REQUIRED_IMAGE_MESSAGE),
    filename: yup.string().required(REQUIRED_IMAGE_MESSAGE),
    contentType: yup.string().required(REQUIRED_IMAGE_MESSAGE),
    url: yup.string().url().required(REQUIRED_IMAGE_MESSAGE),
    isPrivate: yup.boolean().default(false),
}).required(REQUIRED_IMAGE_MESSAGE)

const campaignSchema = yup.object().shape({
    name: yup.string().trim().required('required'),
    startsAt: yup.string().required('required'),
    endsAt: yup.string().required('required'), //TODO startsAt before EndsAt
    campaignType: yup.string().oneOf(['matching_hours']).required('required'),
    timeMatchingAmountInCents: yup.number().integer().min(100, 'min $1').required('required'),
    timeMatchingHour: yup.number().min(1, 'min 1 hr').required('required'),
    minimumAmountInCents: yup.number().integer().min(100, 'min $1').required('required'),
    thankYouPrompt: yup.object().shape({
        title: yup.string().required('required').trim(),
        firstParagraph: yup.string().required('required').trim(),
        image: imageSchema,
        secondParagraph: yup.string().required('required').trim(),
        buttonText: yup.string().required('required')
    }).required(),
    donatePrompt: yup.object().shape({
        title: yup.string().required('required').trim(),
        firstParagraph: yup.string().required('required').trim(),
        image: imageSchema
    }).required(),
    targetGroup: yup.string().oneOf(['everyone', 'never_donated', 'current_donor']).required(),
    useRecommended: yup.bool().required(),
    frequency: yup.string().oneOf(['every_shift', 'once_a_week', 'once_a_month', 'once_every_three_month']).required(),
    lifetimeViewsPerUser: yup.number().integer().min(0, 'must be greater than zero').required('required')
})

export default function CampaignEditor(props) {
    const { campaign, onSaveDraft, onPublish } = props

    const additionalProps = useMemo(() => ({ campaign }), [campaign])
    const campaignName = campaign.name === "Untitled Campaign" && moment.duration(moment().diff(moment(campaign.createdAt))).asHours() < 2.5 ?  "New campaign": campaign.name 

    return (
        <div className="create-event-module">
            <BackLink
                to={`/campaigns/${campaign.id}/`}
                text="Back to Campaign Details"
            />
            <span className='new-event-text'>{campaignName}</span>
            <FormikWizard
                initialValues={campaign}
                initialFurthestStep={campaign.furthestEditorStep}
                validationSchema={campaignSchema}
                published={campaign.publishedAt != null}
                onSaveDraft={onSaveDraft}
                onPublish={onPublish}
                additionalProps={additionalProps}
            >
                <Step
                    name="Target Audience"
                    component={TargetAudience}
                    schemaFields={TargetAudience.SCHEMA_FIELDS}
                />
                <Step
                    name="Basic Info"
                    component={BasicInfo}
                    schemaFields={BasicInfo.SCHEMA_FIELDS}
                />
                <Step
                    name="Thank You"
                    component={ThankYou}
                    schemaFields={ThankYou.SCHEMA_FIELDS}
                />
                <Step
                    name="Donate Prompt"
                    component={DonatePrompt}
                    schemaFields={DonatePrompt.SCHEMA_FIELDS}
                />
                <Step
                    name="Frequency"
                    component={Frequency}
                    schemaFields={Frequency.SCHEMA_FIELDS}
                />
                <Step
                    name="Review"
                    component={Review}
                />
            </FormikWizard>
        </div>
    )
}
