import { useCallback, useEffect } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import useAddRequiredGroupMemberPrompt from './useAddRequiredGroupMemberPrompt'
import REQUIRED_GROUP_TYPES from '../requiredGroupTypes'

const STABLE_EMPTY_ARRAY = [] //TODO this is reel dum

const useTaskRecipients = () => {
  const organization = useCurrentOrg()
  const [request, { result, loading, called }] = useFetch()

  const getTaskRecipients = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/task_recipients`,
    })
  }, [organization.id, request])

  useEffect(() => {
    getTaskRecipients()
  }, [getTaskRecipients])

  const onOpenAddRecipientPrompt = useAddRequiredGroupMemberPrompt(REQUIRED_GROUP_TYPES.recipient, getTaskRecipients)

  return {
    recipients: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh: getTaskRecipients,
    onOpenAddRecipientPrompt,
  }
}

export default useTaskRecipients
