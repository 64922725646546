import React from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(() => ({
    root: { 
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '40px',
    },
    stepLink: {
      textDecoration: 'none',  
      display: 'flex',
      width: '186px',
      height: '51px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: "url('/assets/icons/ProgressBarStart.svg')",
      background: 'transparent',
      backgroundRepeat: 'no-repeat',
      border: '0',
     },
     stepLinkActive: {
       backgroundImage: "url('/assets/icons/ProgressBarStartActive.svg')",
       cursor: 'pointer',
     },
    stepLinkPassed: {
       backgroundImage: "url('/assets/icons/ProgressBarStartPassed.svg')",
       cursor: 'pointer',
    },     
    stepLinkMiddle: {
       marginLeft: '-31px', 
       backgroundImage: "url('/assets/icons/ProgressBarMid.svg')",
    },
    disabled: {
      pointerEvents: "none",
      border: '0',
    },
    stepLinkMiddleActive: {
       backgroundImage: "url('/assets/icons/ProgressBarMidActive.svg')",
     },
    stepLinkMiddlePassed: {
       backgroundImage: "url('/assets/icons/ProgressBarMidPassed.svg')",
       cursor: 'pointer',
    },
    stepLinkEnd: {
      textDecoration: 'none',   
      display: 'flex',
      width: '155px',
      alignItems: 'center',
      justifyContent: 'center', 
      backgroundRepeat: 'no-repeat',
      marginLeft: '-30px', 
      backgroundImage: "url('/assets/icons/ProgressBarEnd.svg')",
      background: 'transparent',
      border: '0',
    },
    stepLinkEndActive: {
      backgroundImage: "url('/assets/icons/ProgressBarEndActive.svg')",
    },
    stepLinkEndPassed: {
      backgroundImage: "url('/assets/icons/ProgressBarEndPassed.svg')",
      cursor: 'pointer'
    },    
    stepLabel: { },
    title: {
      color: '#5F8FE8',
    },
    titleActive: {
      color: 'white',
    },
    titleError: {
      color: 'red'
    } 
}))

export default function ProgressTracker(props) {
    const { steps, furthestStep, stepIndex, gotoStep } = props

    const classes = useStyles()

    return (
        <div className={classes.root}>
            {steps.map( (step, index) => {
                const disabled = index > furthestStep 
                const passed = furthestStep >= index
                const active = stepIndex === index

                const handleClick = event => {
                    if(!disabled) {
                        gotoStep(index)
                    }
                }

                const chooseStyle = () => {
                    if(index === 0) {
                        if(active) return  cn([classes.stepLink, classes.stepLinkActive])
                        if(passed) return  cn([classes.stepLink, classes.stepLinkPassed])
                        return classes.stepLink
                    } else if (index!== steps.length-1){
                        if(active) return  cn([classes.stepLink, classes.stepLinkMiddle, classes.stepLinkMiddleActive])
                        if(passed) return  cn([classes.stepLink, classes.stepLinkMiddle, classes.stepLinkMiddlePassed])
                        return cn([classes.stepLink, classes.stepLinkMiddle, classes.disabled])
                    } else {
                        if(active) return  cn([classes.stepLinkEnd, classes.stepLinkEndActive])
                        if(passed) return  cn([classes.stepLinkEnd, classes.stepLinkEndPassed, classes.disabled])
                        return cn([classes.stepLinkEnd, classes.disabled])
                    }
                }

                const chooseTitleStyle = () => {
                  if(active) {
                    return cn([classes.title, classes.titleActive])
                  } else if(!step.isValid && passed) {
                    return cn([classes.title, classes.titleError])
                  } else {
                    return classes.title
                  }
                }

                return (
                    <button
                        key={index}
                        onClick={handleClick}
                        className={chooseStyle()}
                    >
                        <div>
                        <p className={chooseTitleStyle()}>{step.name}</p>
                        </div>
                    </button>
                )
            })}
        </div>
    )
}