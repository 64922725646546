import React from 'react'
import { FieldProps } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

import { TagColors } from '../types'

const useStyles = makeStyles({
  chipWrapper: {
    padding: '10px',
  },
  colorChip: {
    cursor: 'pointer',
    borderRadius: '50%',
    borderWidth: '3px',
    width: '100%',
    aspectRatio: '1 / 1',
  },
  innerChip: {
    borderRadius: '50%',
    width: '100%',
    aspectRatio: '1 / 1',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#f8faff',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '25% 25% 25% 25%',
  },
})

export const TagColorPickerField = ({ field: { name, value }, form: { setFieldValue }, ...props }: FieldProps) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {Object.entries(TagColors).map(([colorName, htmlColor]) => (
        <div key={htmlColor} className={classes.chipWrapper}>
          <div
            className={classes.colorChip}
            role="button"
            style={{
              backgroundColor: htmlColor,
              padding: value.htmlColor === htmlColor ? '5px' : 0,
            }}
            onClick={() => {
              setFieldValue(name, { htmlColor, colorName })
            }}
          >
            <div
              className={classes.innerChip}
              style={{
                backgroundColor: htmlColor,
              }}
            ></div>
          </div>
        </div>
      ))}
    </div>
  )
}
