import React, { useState } from 'react'
import { Typography, Button, IconButton } from '@material-ui/core'
import { DeleteOutline } from '@material-ui/icons'

import AddTimeslotPrompt from './AddTimeslotPrompt'

const STABLE_EMPTY_ARRAY = []

export default function NewTimeslotPicker(props) {
    const { value: slots = STABLE_EMPTY_ARRAY, onChange, maxSlots = 1 } = props

    const remove = index => {
        if(!slots) return

        let copy = [...slots]
        copy.splice(index, 1)

        if(onChange) onChange(copy)
    }
    
    const insert = (index, value) => {
        let copy = slots ? [...slots] : []
        copy.splice(index, 0, value) 

        if(onChange) onChange(copy)
    }

    const [showPrompt, setShowPrompt] = useState(false)

    return (
        <div>
            <Typography variant='h6'>What times are good to deliver this help?</Typography>
            <Typography variant='body1'>
                Please add {
                maxSlots === 1
                ? 'one'
                : `up to ${maxSlots.toString()} `
                }
                timeslots that will give the helpers a window of time to help out.
            </Typography>
            <Typography variant='body1'>
                <b>Times Available</b>
            </Typography>
            {slots.map((slot, i) =>
                <div key={slot.startTime}>
                {slot.startTime.format('MM/DD/YYYY hh:mma')} - {slot.endTime.format('hh:mma')}
                <IconButton aria-label='delete' onClick={() => remove(i)}>
                    <DeleteOutline />
                </IconButton>
                </div>
            )}
            <Button
                onClick={() => { setShowPrompt(true) }}
                variant='contained'
                color='primary'
            >
                Pick time slot(s)
            </Button>
            <AddTimeslotPrompt
                slots={slots}
                showing={showPrompt}
                remove={remove}
                insert={insert}
                maxSlots={maxSlots}
                closeModal={() => { setShowPrompt(false) }}
            />
        </div>
    )
}