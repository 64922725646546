import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { ProgramMatch } from 'new-mentorship/types'

export const useAddMatch = (): {
  addMatch: (body: Partial<ProgramMatch>) => Promise<ProgramMatch>
  status: Status<ProgramMatch>
} => {
  const [request, status] = useFetchRefactored<ProgramMatch>({
    errorMessage: 'Match Creation Failed',
    successMessage: 'Match Created Successfully',
  })

  const addMatch = useCallback(
    ({ programId, ...body }) => {
      return request({
        method: 'post',
        url: `/mentorship-programs/${programId}/mentorship-matches`,
        config: {
          body,
        },
      })
    },
    [request],
  )

  return {
    addMatch,
    status,
  }
}

export default useAddMatch
