import React from 'react'
import styled from 'styled-components'
import TableHeaders from './table-header/index'
import './index.scss'

export interface TableHeader {
  key: string
  title: string | any
  customWidth: string
  sortable?: boolean
  customColor?: string
  className?: string
}

interface TableProps {
  headers: TableHeader[]
  rows: any[]
  style?: string
  background?: string
  rowHoverColor?: string
  color?: string
  className?: string
  hoverColor?: string
  align?: 'left' | 'center' | 'right'
  onRowClick?: (row: any, index: number) => void
  setRows?: (rows: any) => void
}

const Row = styled('div')`
  background: ${(props: any) => props.background || '#fff'};
  :hover {
    background-color: ${(props: any) => props.hoverColor || 'transparent'};
  }
`

const Column = styled<any>('div')`
  justify-content: right;
  color: ${(props: any) => props.color || 'inherit'}
  width: ${(props: any) => props.customWidth};
  ${(props: any) => props.align === 'left' && 'justify-content: left;'}
  ${(props: any) => props.align === 'center' && 'justify-content: center;'}
  cursor: ${(props: any) => (!!props.onRowClick ? 'pointer' : '')};
`

const getLastJSXChildren: any = (obj: any) => {
  if (obj['props']) {
    // if component with title prop used
    if (obj['props'].title) return obj['props'].title
    return getLastJSXChildren(obj['props'])
  } else if (obj['children'] && typeof obj['children'] === 'object') {
    return getLastJSXChildren(obj['children'])
  }
  return obj['children']
}

const toNumber = (val: any) => (isNaN(Number(val)) ? val : Number(val))

const onSort = (item: any, props: any, setSortBy: any, setAsc: any, sortBy: string, asc: boolean) => {
  const sortedRows = props.rows.sort((a: any, b: any) => {
    //if item is jsx obj
    if (typeof a[item] === 'object' && a[item] !== null) {
      if (toNumber(getLastJSXChildren(a[item])) < toNumber(getLastJSXChildren(b[item]))) {
        return -1
      }
      if (toNumber(getLastJSXChildren(a[item])) > toNumber(getLastJSXChildren(b[item]))) {
        return 1
      }
      return 0
    } else {
      if (toNumber(a[item]) < toNumber(b[item])) {
        return -1
      }
      if (toNumber(a[item]) > toNumber(b[item])) {
        return 1
      }
      return 0
    }
  })

  setSortBy(item)

  if (asc) {
    props.setRows(props.rows.reverse())
    return setAsc(!asc)
  }

  props.setRows(sortedRows)
  return setAsc(!asc)
}

export default function Table(props: TableProps) {
  const [sortBy, setSortBy] = React.useState('')
  const [asc, setAsc] = React.useState(false)

  return (
    <div className="sticky-table">
      <TableHeaders
        headers={props.headers}
        onSort={(item: any) => !!props.setRows && onSort(item, props, setSortBy, setAsc, sortBy, asc)}
        asc={asc}
        sortBy={sortBy}
        style={props.style}
      />
      {props.rows &&
        props.rows.map((row, index) => (
          <Row
            key={index}
            className={`sticky-table__row ${props.className || ''} ${row.selected ? 'checked' : ''}`}
            onClick={() => (props.onRowClick ? props.onRowClick(row, index) : null)}
          >
            {props.headers &&
              props.headers.map((header, i) => (
                <Column
                  customWidth={header.customWidth}
                  className={`sticky-table__row__column ${header.className || ''}`}
                  key={i}
                >
                  {row[header.key]}
                </Column>
              ))}
          </Row>
        ))}
    </div>
  )
}
