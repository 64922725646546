import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import useStripeProducts from '../hooks/useStripeProducts'
import { NullableTextCell, WithLinkCell } from '../../table/cells'

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.name'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

const columns = [
  { Header: 'Product Code', accessor: 'productCode', Cell: WithLinkCell(NullableTextCell, id => `/stripe-products/${id}`, 'productCode') },
  { Header: 'Name', accessor: 'name' },
  { Header: 'SMS Credits', accessor: 'smsCredits', cell: NullableTextCell },
  { Header: 'Email Credits', accessor: 'emailCredits', cell: NullableTextCell },
]

export const ProductsOverview = (props) => {
  const { result: products, loading } = useStripeProducts()
  const table = useTable(
    {
      data: useMemo(() => products, [products]),
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} item>
            <Typography variant="h4">Stripe Products</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <p>"Products" that organizations can buy directly through Stripe in order to increase their balance of SMS credits, etc.</p>
          <p>Whenever an organization purchases a product, Stripe sends our backend information about the purchase including the customerId and the productId</p>
          <p>So, in this table, we essentially save information about what each productId represents and in another table, we save who the customer is.</p>
          <p>Without this information, we do not know what the customer is buying and we therefore cannot honor their purchase</p>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
