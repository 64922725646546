import React, { useState } from 'react'
import { Grid, Link, Typography, makeStyles } from '@material-ui/core'
import slugify from 'slugify'

import Loading from 'components/Loading'
import Search from 'core/table/components/Search'
import BackLink from '../../donation/campaigns/components/BackLink'

import { useEditGroup } from '../hooks/useEditGroup'
import { useCurrentOrg } from '../../auth/hooks'
import { useGroup } from '../hooks/useGroup'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import { GroupTypeCode, PermissionOptions } from '../../Event/components/opportunity/GroupPicker/types'
import { OpenGroupPrompt } from '../components/OpenGroupPrompt'
import GroupsDetailTabs from '../components/GroupsDetailTabs'
import CopyButton from 'civic-champs-shared/core/copy-button'
import GroupClosedSwitch from '../components/GroupClosedSwitch'

const useStyles = makeStyles(() => ({
  groupFieldLabels: {
    fontSize: '18px',
    fontWeight: 600,
    marginRight: '10px',
  },
  applicationLink: {
    color: '#5C8DE8',
    fontWeight: 600,
  },
  disabledLink: {
    color: '#A6A6A6',
    fontSize: '14px',
    fontWeight: 600,
  },
  copyButton: {
    marginLeft: '10px',
  },
}))

export const GroupsDetail = (props) => {
  const groupId = props.match.params.id
  const [group, setGroup] = useState(null)
  const [query, setQuery] = useState(undefined)
  const [currentTab, setCurrentTab] = useState(0)

  const { loading } = useGroup(groupId, setGroup)
  const currentOrg = useCurrentOrg()
  const [editGroup, { loading: editingGroup }] = useEditGroup(groupId, setGroup)
  const showOpenGroupPrompt = useShowPrompt(OpenGroupPrompt)
  const classes = useStyles()

  const onToggleClosed = (closed) =>
    closed
      ? editGroup({ closed })
      : showOpenGroupPrompt({ onSubmit: async () => editGroup({ closed: false }), loading, group })

  const handleQueryChange = (query) => {
    setQuery(query || undefined)
  }

  const onTabChange = (index) => setCurrentTab(index)

  if (loading || !group || editingGroup) {
    return <Loading />
  }

  const canChangeApplyGroup = ![PermissionOptions.PERMANENTLY_CLOSED, PermissionOptions.PERMANENTLY_OPEN].includes(
    group.permissionOptions,
  )

  const applicationLink = `${process.env.REACT_APP_ONBOARDING_SITE_ENDPOINT}/apply/${slugify(
    currentOrg.name,
  ).toLowerCase()}/${group.id}`

  return (
    <>
      <BackLink to={'/groups'} text="Back to Groups" />
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row" justify="space-between" wrap="nowrap">
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="h4">{group.name}</Typography>
            </Grid>
            {group.permissionOptions === PermissionOptions.EDITABLE ? (
              <Grid item style={{ marginLeft: '20px' }}>
                <GroupClosedSwitch group={group} onChange={onToggleClosed} />
              </Grid>
            ) : (
              <Grid item container direction="column" justify="center" xs={6} style={{ marginLeft: '20px' }}>
                <GroupClosedSwitch group={group} readOnly />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body1" className={classes.groupFieldLabels}>
              Description:
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{group.description}</Typography>
          </Grid>
        </Grid>
        {canChangeApplyGroup && (
          <FeatureEnabled code="VolunteerApplication">
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography variant="body1" className={classes.groupFieldLabels}>
                  Application Link:
                </Typography>
              </Grid>
              <Grid item>
                {group.useApplicationLink ? (
                  <Link href={applicationLink} target="_blank" className={classes.applicationLink}>
                    {applicationLink} {!group.useApplicationLink && '(not accepting applications)'}
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.disabledLink}>
                    {`${applicationLink} (not accepting applications)`}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {group.useApplicationLink && (
                  <CopyButton onClick={() => navigator.clipboard.writeText(applicationLink)} />
                )}
              </Grid>
            </Grid>
          </FeatureEnabled>
        )}
        {group.groupType.code !== GroupTypeCode.UserDefined && (
          <Grid item>
            <div>This is a protected group and cannot be removed or edited.</div>
          </Grid>
        )}
        <Grid item>
          <GroupsDetailTabs
            currentTab={currentTab}
            group={group}
            query={query}
            onGroupUpdate={editGroup}
            onTabChange={onTabChange}
          />
        </Grid>
      </Grid>
    </>
  )
}
