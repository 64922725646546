import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { NotificationSettings, NotificationSettingsPayload, SYSTEM_NOTIFICATIONS } from 'notification/interfaces'
import { profileUpdated } from 'auth/actions'
import { useCurrentProfile } from 'auth/hooks'
import { useDispatch } from 'react-redux'

export const useSetNotificationSettings = (): [
  (payload: NotificationSettingsPayload) => Promise<NotificationSettings>,
  Status<NotificationSettings>,
] => {
  const profile = useCurrentProfile()
  const dispatch = useDispatch()
  const [request, status] = useFetchRefactored<NotificationSettings>()

  const setNotificationSettings = useCallback(
    async (payload: NotificationSettingsPayload) => {
      if (
        'Notification' in window &&
        payload.settings[SYSTEM_NOTIFICATIONS] &&
        !profile.notificationSettings?.settings?.[SYSTEM_NOTIFICATIONS]
      ) {
        const permission = await Notification.requestPermission()
        if (permission === 'denied') {
          payload.settings[SYSTEM_NOTIFICATIONS] = false
        }
      }
      const notificationSettings = await request({
        method: 'put',
        url: `/notifications/settings`,
        config: {
          body: payload,
        },
      })
      dispatch(profileUpdated({ ...profile, notificationSettings }))
      return notificationSettings
    },
    [dispatch, profile, request],
  )

  return [setNotificationSettings, status]
}

export default useSetNotificationSettings
