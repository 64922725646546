import React from 'react'
import Loading from '../../core/Loading'
import { useQuestionSet } from '../hooks/useQuestionSet'
import { Grid, Typography } from '@material-ui/core'
import { ReadOnlyQuestionSetQuestions } from './ReadOnlyQuestionSetQuestions'

export const ReadOnlyQuestionSet = ({ questionSetId }: { questionSetId: number }) => {
  const { questionSet, loading } = useQuestionSet(questionSetId)
  if (loading) {
    return <Loading />
  }
  const { name, questions } = questionSet
  return (
    <Grid container spacing={4} direction="column">
      <Grid container item sm={8} spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h5">{name || '-'}</Typography>
        </Grid>
        <ReadOnlyQuestionSetQuestions questions={questions} />
      </Grid>
    </Grid>
  )
}
