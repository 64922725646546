import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'

export const useInactiveQuestionSetPrompt = () => {
  const showPrompt = useShowPrompt(ConfirmDialog)

  return useCallback(
    async (complete: () => void) => {
      const confirmed = await showPrompt({
        text:
          'Inactive questionnaires will remain associated with all of their current groups and events, ' +
          ' but will not be selectable to be used in the future',
        title: 'Are you sure you want to change the status of this Questionnaire to inactive?',
        confirmText: 'Inactivate Questionnaire',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        complete()
      }
    },
    [showPrompt],
  )
}

export default useInactiveQuestionSetPrompt
