import { 
    GET_VOLUNTEERS, GET_VOLUNTEERS_SUCCEEDED, GET_VOLUNTEERS_FAILED,
    SIGN_UP_VOLUNTEER, SIGN_UP_VOLUNTEER_SUCCEEDED, SIGN_UP_VOLUNTEER_FAILED
} from '../actions';
import getErrorMessage from '../../utils/getErrorMessage';

export default function(state, action) {
    switch(action.type) {
        case GET_VOLUNTEERS:
            return { 
                ...state, 
                getVolunteers: { running: true, error: null }
            }

        case GET_VOLUNTEERS_SUCCEEDED:
            return { 
                ...state, 
                volunteers: action.payload.volunteers,
                getVolunteers: { running: false, error: null }
            }
        
        case GET_VOLUNTEERS_FAILED:
            return {
                ...state,
                getVolunteers: { 
                    running: false, 
                    error: getErrorMessage(action.payload.error) 
                }
            }

        case SIGN_UP_VOLUNTEER:
            return { 
                ...state, 
                signUpVolunteer: { running: true, error: null }
            }

        case SIGN_UP_VOLUNTEER_SUCCEEDED:
            return { 
                ...state, 
                volunteers: [ action.payload.newVolunteer, ...state.volunteers],
                signUpVolunteer: { running: false, error: null }
            }
        
        case SIGN_UP_VOLUNTEER_FAILED:
            return {
                ...state,
                signUpVolunteer: { 
                    running: false, 
                    error: getErrorMessage(action.payload.error) 
                }
            }
        
        default:
            return state;
    }
}