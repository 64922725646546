import React from 'react'
import { Popper, ClickAwayListener, Box } from '@material-ui/core'

import { ShowEventPopupParams } from './EventCalendarDay'
import { DayEvents } from './DayEvents';
import { useViewEventDetails } from './hooks/useViewEventDetails'

interface Props extends ShowEventPopupParams {
    showing: boolean,
    close: () => void,
}

const EventPreviewPrompt = (props: Props) => {
    const { showing, close, anchorRef, events } = props
    const handleViewDetails = useViewEventDetails()

    return (
        <ClickAwayListener onClickAway={close}>
            <Popper
                open={showing}
                anchorEl={anchorRef.current}
                placement="left-start"
                modifiers={{
                    flip: true,
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent'
                    }
                }}
            >
              <Box width="352">
                <DayEvents
                  events={events}
                  onViewDetails={handleViewDetails}
                />
              </Box>
            </Popper>
        </ClickAwayListener>
    )
}

export default EventPreviewPrompt
