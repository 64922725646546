import { makeStyles } from '@material-ui/styles'

export const useQuestionSetEditorStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    gap: '20px',
  },
  backLink: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textDecoration: 'none',
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% + 21px)',
    marginLeft: '-21px',
    height: '50px',
    alignItems: 'center',
  },
  customQuestionForm: {
    flexGrow: 1,
    background: 'white',
    padding: '21.5px 20px',
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
    '& $addQuestionButtonWrap': {
      marginTop: '20px',
    },
  },
  addQuestionButtonWrap: {},
  title: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '28px',
    color: '#000000',
    margin: 0,
    flexShrink: 0,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  buttons: {
    display: 'flex',
    gap: '5px',
  },
  titleField: {
    width: '700px',
    flexShrink: 1,
  },
  titleInput: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '28px',
  },
  titleInputUnderline: {
    '&:before': {
      borderBottom: '1px solid #C4C6CF !important',
    },
    '&:after': {
      display: 'none',
    },
  },
  addQuestion: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    // @ts-ignore
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  droppable: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}))

export default useQuestionSetEditorStyles
