const anyOf = (rowValue: string, operand: string[]): boolean => operand.includes(rowValue)
const notAnyOf = (rowValue: string, operand: string[]): boolean => !operand.includes(rowValue)

const operatorToMethodMap = {
  anyOf,
  notAnyOf,
}

export const programFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}
