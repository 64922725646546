import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import { DateCell, NullableTextCell, WithLinkCell } from 'core/table/cells'
import { DocumentType } from 'documents/hooks/useDocumentTypesCollection'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import cn from 'classnames'

export const useStyles = makeStyles(theme => ({
  selectColumn: {
    '&>div': {
      width: '30px',
      marginLeft: '6px',
    },
  },
  orderColumn: {
    '&>div': {
      width: '82px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '217px',
    },
  },
  collectedColumn: {
    '&>div': {
      width: '93px',
      marginRight: '48px',
    },
  },
  lastCollectedColumn: {
    '&>div': {
      width: '140px',
      marginRight: '48px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '105px',
      marginRight: '48px',
    },
  },
  editColumn: {
    '&>div': {
      width: '24px',
      marginRight: '6px',
    },
  },
  editIcon: {
    fontSize: '16px',
  },
  editIconActive: {
    color: '#0F5DB5',
    cursor: 'pointer',
  },
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
  },
}))

export default function useDocumentTypeColumns({
  addEditDocumentType,
}: {
  addEditDocumentType: (documentType?: DocumentType) => Promise<void>
}) {
  const classes = useStyles()

  return useMemo(
    () => [
      {
        id: 'order',
        Header: 'Order',
        accessor: 'order',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell: { value } }: CellProps<DocumentType, number>) => value + '.',
        className: classes.orderColumn,
      },
      {
        id: 'name',
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentType, string>) =>
          WithLinkCell(
            NullableTextCell,
            (id: number) => `/documents/${id}`,
          )({ ...props, linkProps: { className: classes.link } }),
        disableSortBy: true,
        filter: DYNAMIC_TEXT,
        className: classes.nameColumn,
      },
      {
        id: 'documentCount',
        Header: 'Collected',
        accessor: 'documentCount',
        disableFilters: true,
        disableSortBy: true,
        className: classes.collectedColumn,
      },
      {
        id: 'lastCreated',
        Header: 'Last Collected On',
        accessor: 'lastCreated',
        Cell: DateCell,
        disableFilters: true,
        disableSortBy: true,
        className: classes.lastCollectedColumn,
      },
      {
        id: 'isActive',
        Header: 'Status',
        accessor: 'isActive',
        Cell: ({ cell: { value } }: CellProps<DocumentType, boolean>) => (value ? 'Active' : 'Inactive'),
        disableFilters: true,
        disableSortBy: true,
        className: classes.statusColumn,
      },
      {
        id: 'edit',
        Header: <EditIcon className={classes.editIcon} />,
        accessor: 'id',
        Cell: ({ row: { original: documentType } }: CellProps<DocumentType, number>) => (
          <EditIcon
            className={cn(classes.editIcon, classes.editIconActive)}
            onClick={() => addEditDocumentType(documentType)}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
        className: classes.editColumn,
      },
    ],
    [
      addEditDocumentType,
      classes.collectedColumn,
      classes.editColumn,
      classes.editIcon,
      classes.editIconActive,
      classes.lastCollectedColumn,
      classes.link,
      classes.nameColumn,
      classes.orderColumn,
      classes.statusColumn,
    ],
  )
}
