import React, { useCallback, useMemo } from 'react'
import moment from 'moment-timezone'
import isBoolean from 'lodash/isBoolean'
import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import capitalize from 'lodash/capitalize'

import { useCurrentOrg } from 'auth/hooks'
import { MentoringFeedbackType, ProgramMeetingQuestionColumns } from '../types'
import { ExportButton } from 'core/table/components'

const csvHeaders = [
  { label: 'Date', key: 'startsAt' },
  { label: 'Mentor', key: 'mentor' },
  { label: 'Mentee', key: 'mentee' },
  { label: 'Program', key: 'program' },
  { label: 'Type', key: 'type' },
  { label: 'Mentor Rating', key: 'mentorRating' },
  { label: 'Mentee Rating', key: 'menteeRating' },
  { label: 'Meeting Time', key: 'duration' },
]

const escapeValue = (value: string): string => value.replace(/"/g, '""')

const getValue = (value: string | string[] | number): string | number =>
  isNumber(value) ? value : isArray(value) ? value.map(escapeValue).join(',') : escapeValue(value)

export const ExportMentorshipMeetingsButton = ({
  data,
  questionColumns,
}: {
  data: any[]
  questionColumns: ProgramMeetingQuestionColumns
}) => {
  const { result: org } = useCurrentOrg()

  const fullCsvHeaders = useMemo(() => {
    const basicHeaders = [...csvHeaders]
    for (const type of Object.values(MentoringFeedbackType)) {
      if (questionColumns[type]) {
        basicHeaders.push({ label: `${capitalize(type)} Questions`, key: `${type}Questions` })
        // @ts-ignore
        for (const { id, value } of questionColumns[type]) {
          basicHeaders.push({ label: value, key: `${type}Question${id}` })
        }
      }
    }
    return basicHeaders
  }, [questionColumns])

  const formatData = useCallback(
    (inputData: any[]) =>
      inputData.map(({ original: { mentorRating, menteeRating, startsAt, answers, ...rest } }: any) => {
        const row = {
          ...rest,
          startsAt: moment.tz(startsAt, org?.timeZone).format('MM/DD/YYYY hh:mm:ss a'),
          mentorRating: isBoolean(mentorRating) ? (mentorRating ? 'positive' : 'negative') : '',
          menteeRating: isBoolean(menteeRating) ? (menteeRating ? 'positive' : 'negative') : '',
        }
        for (const type of Object.values(MentoringFeedbackType)) {
          if (questionColumns[type]) {
            // @ts-ignore
            for (const { id } of questionColumns[type]) {
              const value = answers[type]?.[id]?.value || ''
              if (value) row[`${type}Question${id}`] = getValue(value)
            }
          }
        }
        return row
      }),
    [org, questionColumns],
  )

  return (
    <ExportButton data={data} formatData={formatData} headers={fullCsvHeaders} filename={'mentorship-meetings.csv'} />
  )
}

export default ExportMentorshipMeetingsButton
