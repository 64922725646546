import _, { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import './index.scss'
import ThankYouPrompt from './ThankYouPrompt'
import DonatePromptPhone from './DonatePromptPhone'
import useGetAffiliatedOpportunities from '../hooks/useGetAffiliatedOpportunties'
import { labelFrequency, labelAudienceDetailed, labelViewsPerUser } from '../utils'
import StepNavigation from 'civic-champs-shared/formik/FormikWizard/StepNavigation'

const useStyles = makeStyles(theme => ({
    buttonContainer: {
      marginRight: '262px',
      marginBottom: '38px',
      marginTop: '90px',
    },
    content:{
      display: 'flex',
      flexGrow: 1,
      position: 'relative',
      flexDirection: 'column'
      },
    titleText: {
      fontSize: '24px',
      fontFamily: 'Magallanes-Regular, Arial, Open Sans, sans-serif'
    },
    text: {
      fontSize: '18px',
      marginTop: '15px',
      },
    subTitle: {
      fontSize: '24px',
      color: '#394D69'  
    },
    row: {
      display: 'flex',
      flexDirection: 'row', 
    },
    smallTitle: {
      color: '#212121',
      marginTop: '41px'
    },
    smallTitleTwo: {
        color: '#212121',
        marginTop: '21px'
      },
    item: {
      marginTop: '5px',
    },
    column: {
      marginRight: '63px',
    },
    event: {
      color: '#386FD5',
    },
    buttonStep: {
      width: '150px',
      height: '49px',
      background: '#5F8FE8',
      marginRight: '40px',
    },
    nextText: {
     color: 'white',
     zIndex: 1000,
     fontSize: '15px',
    },
    propmpt: {
     marginRight: '100px',
    },
    promptTitle: {
      marginBottom: '21px',
    }, 
    appearance: {
      marginTop: '27px',
      marginLeft: '290px',
      font: 'Regular Open Sans',
    },
    modalBody: {
      display: 'flex',
      backgroundColor: 'white',
      width: '583px',
      height: '392px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalTitle: {
      marginTop: '52px',
      fontSize: '33px',
    },
    modalSubTitle: {
      marginTop: '72px',
      fontSize: '23px',
    },
    modalButton: {
      width: '512px',
      height: '56px',
      background: '#5F8FE8',
      marginTop: '100px',
    },
    recommended: {
      font: 'Light Open Sans',
      color: '#3D3D3D',
    },
    textSettings: {
      marginTop: '15px',
    },
    saveDraft: {
      display: 'flex',
      alignSelf: 'flex-start',
      width: '146px',
      height: '49px',
    },
}));

export default function Review(props) {
  const { values, campaign } = props
  const classes = useStyles()

  const [getAffiliateOpportunities, { result: affiliatedOpps }] = useGetAffiliatedOpportunities()

  useEffect(
    () => { getAffiliateOpportunities(campaign.id) }, 
    [campaign.id, getAffiliateOpportunities]
  )

  return (
    <div className={classes.content}>
      <h2 className={classes.titleText}>Review</h2>
      <p className={classes.subTitle}><b>{values.name}</b></p> 
      <div className={classes.row}>
        <div className={classes.column}>
          <div><b>Type</b></div> 
          <div className={classes.item}>Time matching</div> 
        </div>
        <div className={classes.column}>
          <div><b>Start Date</b></div> 
          <div className={classes.item}>{moment(values.startsAt).format("MM/DD/YYYY")}</div> 
        </div>
        <div>
            <div><b>End Date</b></div> 
            <div className={classes.item}>{moment(values.endsAt).format("MM/DD/YYYY")}</div> 
        </div>        
      </div>
      { !isEmpty(affiliatedOpps) && <div className={classes.smallTitle}><b>Affiliated Events</b></div>} 
      {_.map(affiliatedOpps, (opp) => (
          <div key={opp.id} className={classes.event}><b>{opp.name}</b></div>
      ))}
      <div className={classes.row}>
        <div className={classes.propmpt}> 
          <div className={cn([classes.smallTitle, classes.promptTitle])}><b>Thank You Prompt</b></div>
          <ThankYouPrompt data={values.thankYouPrompt} />
        </div>
        <div> 
          <div className={cn([classes.smallTitle, classes.promptTitle])}><b>Donate Prompt</b></div>
          <DonatePromptPhone data={values.donatePrompt}/>
        </div>
      </div>
      <div className={classes.appearance}>Appearance may vary</div> 
      <div className={classes.smallTitleTwo}><b>Time matching</b></div>
      <div>${values.timeMatchingAmountInCents / 100} for every {values.timeMatchingHour} hour</div>
      {values.useRecommended 
        ? (<>
            <div className={classes.smallTitleTwo}><b>Target Audience</b></div>
            <div>Recommended by Civic Champs</div>
            <div className={classes.recommended}>Optimized using historical user data and success metrics.</div>
          </>)
        : (<> 
            <div className={classes.smallTitleTwo}><b>Audience Settings</b></div>
            <div className={classes.textSettings}>
              <b>Ask for donations:</b> {labelFrequency(values.frequency)}
            </div>
            <div className={classes.textSettings}>
              <b>Volunteers to ask:</b> {labelAudienceDetailed(values.targetGroup)}
            </div>
            <div className={classes.textSettings}>
              <b>Limits on asking:</b> {labelViewsPerUser(values.lifetimeViewsPerUser)}
            </div>
          </>)
      }
      <div className={classes.buttonContainer}>
        <StepNavigation />
      </div>    
    </div>) 
} 