import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'
import queryString from 'query-string'

export default function useActiveCampaign(opportunityId, hours) {
  const [request, { loading, error, result, called }] = useFetch('Error loading possible campaigns');
  const getActiveCampaigns = useCallback( () => {
    request({
      method: 'get',
      url: `/opportunities/${opportunityId}/active_campaign/?${queryString.stringify({hours})}`,
    })
  }, [request, opportunityId, hours])

  useEffect(getActiveCampaigns, [getActiveCampaigns]);

  return {
    result: result || null,
    loading: loading || !called,
    error,
  }
}
