import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { ExportButton } from 'core/table/components'
import { CredentialFull, CredentialStats } from 'civic-champs-shared/question-sets/types'
import { Row } from 'react-table'
import { getExpirationRate } from 'credential/helpers'

const filename = 'waivers.csv'
const headers = [
  { label: 'Title', key: 'title' },
  { label: 'Events', key: 'events' },
  { label: 'Volunteers', key: 'volunteers' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Last Modified', key: 'lastModified' },
  { label: 'Expiration Rate', key: 'expirationRate' },
]

const formatData = (waivers: Row<CredentialFull>[]) => {
  return waivers.map(({ original: { version, stats, credential } }) => {
    return {
      title: version.title,
      events: (stats as CredentialStats).totalOpportunities,
      volunteers: (stats as CredentialStats).totalVolunteers,
      dateCreated: format.date(credential.createdAt),
      lastModified: format.date(credential.createdAt),
      expirationRate: getExpirationRate(credential),
    }
  })
}
const ExportWaiversButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)

  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={headers}
      disabled={disabled}
      filename={fileNameWithDate as string}
    />
  )
}

export default ExportWaiversButton
