import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog, { useStyles } from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React, { useCallback } from 'react'
import DialogContentText from '@material-ui/core/DialogContentText'
import { NotificationBlastMessage } from 'messages/types'
import useMessageRecipients from 'messages/hooks/useMessageRecipients'
import { SendMethod } from 'civic-champs-shared/question-sets/types/invitation'

export const useResendCustomMessagesPrompt = (): ((props: {
  messages: NotificationBlastMessage[]
  body: string
  subject: string
}) => Promise<void>) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const [messageRecipients] = useMessageRecipients()
  const classes = useStyles()

  return useCallback(
    async ({ messages, body, subject }) => {
      const message = messages[0]
      const text =
        messages.length === 1 ? (
          <>
            The original message will be resent to{' '}
            <strong>
              {message.recipient?.givenName} {message.recipient?.familyName}
            </strong>
            , and may use SMS credits.
          </>
        ) : (
          <>
            The original message will be resent to all <strong>{messages.length}</strong> selected recipients, and may
            use SMS credits.
          </>
        )
      const children = (
        <DialogContentText classes={{ root: classes.text }}>
          {text} Are you sure you want to resend this message?
        </DialogContentText>
      )
      const confirmed = await showPrompt({
        children,
        title: 'Are you sure you want to resend this message?',
        confirmText: 'Resend',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        await messageRecipients({
          subject,
          body,
          emailPersonIds: messages
            .filter(message => message.method === SendMethod.EMAIL)
            .map(message => message.recipient?.id) as number[],
          smsPersonIds: messages
            .filter(message => message.method === SendMethod.PHONE)
            .map(message => message.recipient?.id) as number[],
        })
      }
    },
    [classes.text, messageRecipients, showPrompt],
  )
}

export default useResendCustomMessagesPrompt
