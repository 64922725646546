import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { Close as CloseIcon } from '@material-ui/icons'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared'
import cn from 'classnames'

export const useStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 20px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '24px',
    color: '#000000',
  },
  dialog: {
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: '700px',
  },
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 0 0',
  },
  next: {
    display: 'flex',
    gap: '10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    '&:first-child': {
      padding: '10px 0 8px',
    },
  },
  back: {
    border: 0,
  },
})

interface FormModalProps {
  showing: boolean
  close: () => void
  id: string
  title: string
  className?: string
  children: React.ReactNode
}

export const FormModal = ({ showing, close, id, title, className, children }: FormModalProps) => {
  const classes = useStyles()
  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#${id}` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, className) }}
      disableEnforceFocus
    >
      <>
        <DialogTitle classes={{ root: classes.title }} disableTypography={true} id={id}>
          {title}
          <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
            <CloseIcon className={classes.dialogCloseIcon} />
          </IconButton>
        </DialogTitle>
        {children}
      </>
    </Dialog>
  )
}

export interface FormModalScreenProps {
  children: React.ReactNode
  onBack?: () => void
  onNext: (...args: any[]) => void
  onDraft?: (...args: any[]) => void
  nextText: string
  backText?: string
  draftText?: string
  backDisabled?: boolean
  nextDisabled?: boolean
  nextIsLoading?: boolean
  disabled?: boolean
  className?: string
}

export function FormModalScreen({
  children,
  onBack,
  onNext,
  nextText,
  backText,
  backDisabled,
  nextDisabled,
  nextIsLoading,
  disabled,
  className,
  draftText,
  onDraft,
}: FormModalScreenProps) {
  const classes = useStyles()
  return (
    <>
      <DialogContent classes={{ root: classes.content }}>{children}</DialogContent>
      <DialogActions className={cn(classes.actions, className)}>
        {onBack ? (
          <OutlinedButton className={classes.back} disabled={disabled || backDisabled} onClick={onBack}>
            {backText}
          </OutlinedButton>
        ) : (
          <div />
        )}
        <div className={classes.next}>
          {draftText && (
            <OutlinedButton disabled={disabled || nextDisabled} onClick={onDraft}>
              {draftText}
            </OutlinedButton>
          )}
          <ContainedButton disabled={disabled || nextDisabled} onClick={onNext}>
            {nextText}
          </ContainedButton>
        </div>
      </DialogActions>
    </>
  )
}
