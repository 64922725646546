import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const STABLE_EMPTY_ARRAY = []

export const useGroups = (onResultUpdate, reporting = true) => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Groups.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()
  const refetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/groups`,
      config: {
        queryStringParameters: { reporting },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg.id, reporting, request])

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    groups: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refetch,
  }
}
