import React, { FC, useCallback, useMemo, useState } from 'react'
import { cloneDeep, filter, isFunction, map, uniqueId } from 'lodash'
import { Field, FieldArray, Form, Formik } from 'formik'
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd'
import { TextField } from 'formik-material-ui'
import { QuestionPayload, QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import { questionSetSchema } from '../types/schema'
import QuestionItemEditor, {
  allowOptions,
  createEmptyOption,
  getOptionId,
  QuestionItem,
  QuestionOption,
} from './NewQuestionItemEditor'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Link } from 'react-router-dom'
import { Path } from 'history'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import cn from 'classnames'
import { ReadOnlyQuestionSetQuestions } from 'civic-champs-shared/question-sets/components'
import EditIcon from '@material-ui/icons/Edit'
import useQuestionSetEditorStyles from 'question-sets/hooks/useQuestionSetEditorStyles'
import useSaveQuestionnairePreview from 'question-sets/hooks/useSaveQuestionnairePreview'
import { useHistory } from 'react-router'

const getItemId = () => uniqueId('item_')

const createEmptyQuestion = (): QuestionItem => ({
  question: '',
  options: [createEmptyOption(), createEmptyOption()],
  isRequired: true,
  meta: {
    itemId: getItemId(),
  },
})

const cloneQuestion = (question: any) => {
  const newQuestion = cloneDeep(question)
  newQuestion.meta = {
    itemId: getItemId(),
  }
  newQuestion.questionId = undefined

  return newQuestion
}

const mapOptionsToPayload = (options: QuestionOption[]) =>
  filter(map<QuestionOption, string>(options, ({ value }) => value))

const prepareQuestionFromItems = (items: QuestionItem[]): QuestionPayload[] => {
  //TODO use "meta" field to manage versioning?
  return map<QuestionItem, QuestionPayload>(items, item => ({
    questionId: item.questionId,
    questionType: item.questionType,
    question: item.question,
    isRequired: item.isRequired,
    options: allowOptions(item.questionType) ? mapOptionsToPayload(item.options) : undefined,
  }))
}

export const getItemStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot): any => {
  const isDragging = snapshot.isDragging
  const draggableStyle = provided.draggableProps.style

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    background: isDragging ? '#ADD7F3' : 'white',
    // styles we need to apply on draggables
    ...draggableStyle,
    opacity: isDragging ? 0.5 : 1,
  }
}

export interface SurveyModel {
  name: string
  questions: QuestionItem[]
  questionsEdited: boolean
}

export interface QuestionSetEditorProps {
  onSubmit: (payload: any) => Promise<void>
  questionSet?: QuestionSetResponse
  isNew?: boolean
  isSubmitting: boolean
  backLink: Path
  backText: string
  channel?: string | null
}

const QuestionSetEditor: FC<QuestionSetEditorProps> = (props: QuestionSetEditorProps) => {
  const { onSubmit, questionSet, isNew = true, isSubmitting, backText, backLink } = props
  const [previewEnabled, setPreviewEnabled] = useState(false)
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const validationSchema = questionSetSchema(isQuestionnaireAsSurveyEnabled)
  const savePreview = useSaveQuestionnairePreview()
  const classes = useQuestionSetEditorStyles()
  const previewInNewWindowEnabled = useFeatureEnabled('QuestionnairePreviewInNewWindow')
  const history = useHistory()

  const initialValues = useMemo<SurveyModel>(
    () => ({
      name: questionSet?.name || '',
      questions: questionSet?.questions.map(question => ({
        ...question,
        options: question?.options?.map(option => ({
          value: option,
          meta: {
            itemId: getOptionId(),
          },
        })),
        meta: {
          itemId: getItemId(),
        },
      })) || [createEmptyQuestion()],
      questionsEdited: false,
    }),
    [questionSet],
  )

  const handleSubmit = useCallback(
    async (values: SurveyModel) => {
      // If nothing changed - go back
      if (!isNew && !values.questionsEdited && initialValues.name === values.name) {
        history.push(backLink)
        return
      }
      if (!isFunction(onSubmit)) return

      onSubmit({
        name: values.name,
        questions: prepareQuestionFromItems(values.questions),
        questionsEdited: values.questionsEdited,
      })
    },
    [backLink, history, initialValues.name, isNew, onSubmit],
  )

  const handlePreview = useCallback(
    async (values: SurveyModel) => {
      if (previewInNewWindowEnabled) {
        const payload = {
          name: values.name,
          questions: prepareQuestionFromItems(values.questions),
        }
        const uuid = await savePreview(payload)
        window.open(`${process.env.REACT_APP_ONBOARDING_SITE_ENDPOINT}/questionnaire-preview/${uuid}`)
      } else {
        setPreviewEnabled(true)
      }
    },
    [previewInNewWindowEnabled, savePreview],
  )

  const handleBackClick = useCallback(
    e => {
      if (!props.channel) return
      e.preventDefault()
      window.close()
    },
    [props.channel],
  )

  return (
    <div className={classes.wrapper}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className={classes.topBlock}>
              <Link to={backLink} className={classes.backLink} onClick={handleBackClick}>
                ← {backText}
              </Link>
              <div className={classes.buttons}>
                {previewEnabled ? (
                  <ContainedButton startIcon={<EditIcon />} onClick={() => setPreviewEnabled(false)}>
                    Edit
                  </ContainedButton>
                ) : (
                  <OutlinedButton startIcon={<VisibilityIcon />} onClick={() => handlePreview(values)}>
                    Preview
                  </OutlinedButton>
                )}
                <ContainedButton disabled={isSubmitting} type="submit">
                  Save
                </ContainedButton>
              </div>
            </div>

            <div className={classes.customQuestionForm}>
              <div className={classes.titleWrapper}>
                <p className={classes.title}>Title:</p>
                <Field
                  name="name"
                  component={TextField}
                  classes={{ root: classes.titleField }}
                  InputProps={{ classes: { input: classes.titleInput, underline: classes.titleInputUnderline } }}
                />
              </div>
              {previewEnabled ? (
                <ReadOnlyQuestionSetQuestions
                  questions={
                    map(values.questions, ({ options, ...question }) => ({
                      ...question,
                      options: allowOptions(question.questionType) ? mapOptionsToPayload(options) : undefined,
                    })) || []
                  }
                />
              ) : (
                <FieldArray
                  name="questions"
                  render={arrayHelpers => {
                    const handleDragEnd = (result: any) => {
                      if (!result.destination) return
                      const from = result.source.index
                      const to = result.destination.index

                      arrayHelpers.move(from, to)
                      setFieldValue('questionsEdited', true)
                    }

                    const handleAddQuestion = () => {
                      arrayHelpers.push(createEmptyQuestion())
                      setFieldValue('questionsEdited', true)
                    }

                    const handleClone = (index: any) => () => {
                      const clone = cloneQuestion(values.questions[index])
                      arrayHelpers.insert(index + 1, clone)
                      setFieldValue('questionsEdited', true)
                    }

                    const handleEdit = (index: any) => () => {
                      const question = values.questions[index]
                      arrayHelpers.replace(index, {
                        ...question,
                        questionId: undefined,
                        meta: {
                          ...question.meta,
                          originalQuestion: question,
                        },
                      })
                      setFieldValue('questionsEdited', true)
                    }

                    const handleRevertEdit = (index: any) => () => {
                      const {
                        meta: { originalQuestion },
                      } = values.questions[index]
                      arrayHelpers.replace(index, {
                        ...originalQuestion,
                      })
                      setFieldValue('questionsEdited', true)
                    }

                    const handleRequired = (index: any) => () => {
                      const question = values.questions[index]
                      arrayHelpers.replace(index, {
                        ...question,
                        isRequired: !question.isRequired,
                      })
                    }

                    //TODO should this have confirmation?  Undo?
                    const handleRemove = (index: any) => () => {
                      arrayHelpers.remove(index)
                      setFieldValue('questionsEdited', true)
                    }

                    return (
                      <>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="questionarie">
                            {droppableProvided => (
                              <div
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                                className={classes.droppable}
                              >
                                {values.questions.map((question: QuestionItem, index) => (
                                  <Draggable
                                    key={question.meta.itemId}
                                    draggableId={question.meta.itemId}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(provided, snapshot)}
                                      >
                                        <QuestionItemEditor
                                          index={index}
                                          question={question}
                                          onDuplicate={handleClone(index)}
                                          onRemove={handleRemove(index)}
                                          onEdit={handleEdit(index)}
                                          onRevertEdit={handleRevertEdit(index)}
                                          onRequired={handleRequired(index)}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <div className="button-row">
                          <Button
                            className={cn(classes.addQuestion, {
                              [classes.addQuestionButtonWrap]: !values.questions.length,
                            })}
                            onClick={handleAddQuestion}
                            startIcon={<AddCircleIcon />}
                          >
                            Add Question
                          </Button>
                        </div>
                      </>
                    )
                  }}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default QuestionSetEditor
