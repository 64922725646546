import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'

export const useGetSortOrder = (): [(tableName: string) => Promise<string>, Status<string>] => {
  const [request, status] = useFetchRefactored<string>({
    emptyValue: undefined,
  })

  const currentOrg = useCurrentOrg()

  const fetchSortOrder = useCallback(
    tableName =>
      request({
        method: 'get',
        url: `/user/${currentOrg.id}/tables/${tableName}/order`,
      }),
    [currentOrg.id, request],
  )

  return [fetchSortOrder, status]
}

export default useGetSortOrder
