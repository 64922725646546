import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment-timezone'
import {
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useHistory } from 'react-router'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import SyncIcon from '@material-ui/icons/Sync'

import { TableMenuButton } from 'civic-champs-shared/core/TableMenuButton'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { ContainedButton } from 'civic-champs-shared/core'

import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useActivityLogColumns, { useStyles as useColumnStyles } from '../hooks/useActivityLogColumns'
import { useDateRangeFilter } from 'filtering/hooks'
import Loading from 'components/Loading'
import ExportActivityLogButton from '../components/ExportActivityLogButton'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'

import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import CUSTOM_FILTERS from '../utils/filters'

import {
  ARRAY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'
import { useUISettings } from 'hooks/useUISettings'
import { useActivitiesCollection } from '../hooks/useActivitiesCollection'
import { Activity } from 'champion/utils/interface'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ActivityImportDialog } from 'tracking/activity/components/ActivityImportDialog'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_SUPER_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import usePushActivitiesDialog from 'tracking/activity/hooks/usePushActivitiesDialog'
import { IntegrationKey, IntegrationProvider } from 'integrations/types'
import TablePopoverButton from 'civic-champs-shared/core/TablePopoverButton'
import { makeStyles } from '@material-ui/core/styles'
import { Opportunity } from '../../../Event/interfaces'
import useGetIntegrationKey, { emptyValue } from '../../../integrations/hooks/useGetIntegrationKey'

const useStyles = makeStyles({
  button: {
    width: '210px',
  },
})

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'firstName':
    case 'lastName':
    case 'opportunity':
    case 'name':
      return STRING_OPERATOR_OPTIONS

    case 'hours':
      return NUMBER_OPERATOR_OPTIONS

    case 'role':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'reflections':
      return [{ value: operatorTypes.CONTAINS, displayValue: 'Contains' }]

    case 'satisfaction':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'groups':
    case 'volunteerTags':
      return ARRAY_OPERATOR_OPTIONS

    default:
      return []
  }
}

const exportAllHandler = async () => {
  const blob = await requestWithRetry({
    url: '/activity_export',
    config: {
      responseType: 'blob',
    },
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', 'activity.csv')
  link.click()
}

interface Props {
  tableName: string
  opportunity?: Opportunity
  locationId?: number
  getActivitySummary?: (activities: Row<Activity>[]) => {
    count: number
    averageHours: number
    totalHours: number
  }
  cards?: {
    header: string
    accessor: string
    transform: (value: any) => any
  }[]
}

export const ActivityLog = React.memo(({ tableName, opportunity, getActivitySummary, locationId, cards }: Props) => {
  const history = useHistory()
  // @ts-ignore
  const { showAdminItems } = useUISettings()
  const skipReset = useRef<boolean>()
  const activityImportFeatureEnabled = useFeatureEnabled('ActivityImport')
  const lglIntegrationEnabled = useFeatureEnabled('LglIntegration')
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const showPushActivitiesDialog = usePushActivitiesDialog({
    providerName: 'Little Green Light',
    provider: IntegrationProvider.LGL,
  })
  const activityImportEnabled = activityImportFeatureEnabled && isInternalSuperAdmin && !locationId

  const classes = useStyles()
  const columnStyles = useColumnStyles()
  const [dateRange] = useDateRangeFilter()
  const { filters } = useFiltersFromQuery(history.location.search, getOperatorOptions)
  const activityFilters = useMemo(
    () => ({ ...dateRange, opportunityId: opportunity?.id, locationId, mapOccurrencesApproximately: true }),
    [dateRange, opportunity?.id], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [{ activities, loading, loadingProgress }, { addActivity, editActivity, removeActivities }, eventListeners] =
    useActivitiesCollection(activityFilters)

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const organization = useCurrentOrg()

  const [integrationKey, setIntegrationKey] = useState<IntegrationKey>(emptyValue)
  const fetchIntegrationKey = useGetIntegrationKey()
  useEffect(() => {
    fetchIntegrationKey(IntegrationProvider.LGL).then(setIntegrationKey)
  }, [fetchIntegrationKey])

  // @ts-ignore
  const columns = useActivityLogColumns({ onEdit: editActivity }, opportunity?.id)
  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
    getOperatorOptions,
  })
  const showImportDialog = useShowPrompt(ActivityImportDialog)

  const filteredActivities = useMemo(() => {
    if (!activities) return []
    const { startDate, endDate } = dateRange
    const { timeZone } = organization

    if (startDate === undefined || endDate === undefined) return activities

    const filterStart = moment(startDate).tz(timeZone, true)
    const filterEnd = moment(endDate).tz(timeZone, true)

    return activities.filter((a: any) => {
      const start = moment(a.occurredAt).tz(timeZone)
      const end = start.clone().add(a.hoursVolunteered, 'hours')
      return filterStart.isSameOrBefore(end, 'day') && filterEnd.isSameOrAfter(start, 'day')
    })
  }, [activities, dateRange, organization])

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'reflections', 'groups', 'volunteerTags'],
        // @ts-ignore
        globalFilter: '',
        filters,
      },
      globalFilter,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
      data: filteredActivities,
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, selectedRowsCount] = useMemo(
    () => [table.selectedFlatRows.map(({ original }: any) => original), table.selectedFlatRows.length],
    [table.selectedFlatRows],
  )

  const handleAddActivity = useCallback(() => {
    addActivity?.(opportunity)
  }, [addActivity, opportunity])

  const onRemoveActivity = async () => {
    // @ts-ignore
    await removeActivities(selectedRows)
    table.toggleAllRowsSelected(false)
  }

  if (loading || columnStateLoading) {
    return <Loading progress={loadingProgress} animateProgress />
  }

  return (
    <Grid container>
      <ExtendedPagedTable<Activity>
        getSummary={getActivitySummary}
        cards={cards}
        history={history}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Activities"
        filterSubHeader="Select activity attributes to filter by:"
        buttons={
          <>
            <ExportActivityLogButton data={selectedRows} disabled={!selectedRowsCount} />
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              disabled={!selectedRowsCount}
              onClick={onRemoveActivity}
              rightMargin={showAdminItems || activityImportEnabled}
            >
              Remove
            </TableMenuButton>
            {showAdminItems && (
              <TableMenuButton
                startIcon={<SaveAltOutlinedIcon />}
                onClick={exportAllHandler}
                rightMargin={activityImportEnabled}
              >
                Export all
              </TableMenuButton>
            )}
            {activityImportEnabled && (
              <TableMenuButton startIcon={<SaveAltOutlinedIcon />} onClick={showImportDialog}>
                Import
              </TableMenuButton>
            )}
          </>
        }
        addButton={
          locationId ? undefined : (
            <ContainedButton startIcon={<AddIcon />} onClick={handleAddActivity}>
              Add Activity
            </ContainedButton>
          )
        }
        popover={
          lglIntegrationEnabled && isSuperAdmin && integrationKey.enabled && !!integrationKey.token ? (
            <TablePopoverButton className={classes.button} startIcon={<SyncIcon />} onClick={showPushActivitiesDialog}>
              Sync to Little Green Light
            </TablePopoverButton>
          ) : undefined
        }
        getOperatorOptions={getOperatorOptions}
        useGlobalSearch
        useFilters
        useDateRange
        skipReset={skipReset}
        eventListeners={eventListeners}
      />
    </Grid>
  )
})
