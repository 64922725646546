import screenfull from 'screenfull';
import { eventChannel } from 'redux-saga';
import { fork, takeEvery, call, take, put } from 'redux-saga/effects';
import { REQUEST_SET_FULLSCREEN, updateFullscreenStatus } from './actions';

export function* rootSaga() {
    if(!screenfull.enabled) return;

    yield put( updateFullscreenStatus(screenfull.isFullscreen) );

    yield fork(watchRequestSetFullscreen);
    yield fork(watchOnChange);
}

export function* watchRequestSetFullscreen() {
    yield takeEvery(REQUEST_SET_FULLSCREEN, onRequestSetFullscreen);
}

export function* onRequestSetFullscreen(action) {
    if(!screenfull.enabled) return; //TODO this is probably unnecessary, but won't change right now

    const currentStatus = screenfull.isFullscreen;
    const desiredStatus = action.payload.isFullscreen; 

    if(currentStatus !== desiredStatus) {
        if(desiredStatus) {
            yield call([screenfull, 'request']);
        } else {
            yield call([screenfull, 'exit']);
        }
    }
}

const createOnChangeChannel = () =>
    eventChannel(emit => {
        const listener = () => emit(screenfull.isFullscreen);
        screenfull.on('change', listener);

        return () => screenfull.off('change', listener);
    });

export function* watchOnChange() {
    const onChangeChannel = yield call(createOnChangeChannel);
    while(true) {
        let newValue = yield take(onChangeChannel);
        yield put( updateFullscreenStatus(newValue) );
    }
}