import { useState, useEffect, useMemo } from 'react';
import useDebounce from 'utils/useDebounce'; //TODO make utils/hooks.js!
import useMiniSearch from 'utils/useMiniSearch';

export function useTableSearch(items, searchConfig) {
  const search = useMiniSearch(items, searchConfig);
  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce(query, 250);

  const [results, setResults] = useState(items || []);

  useEffect(() => {
    let newResults = search(debouncedQuery);
    setResults(newResults);
  }, [search, debouncedQuery]);

  return useMemo(() => ({
    query,
    setQuery,
    results
  }), [query, results]);
}