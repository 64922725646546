import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SelectComponent from 'Event/components/select'
import RadioButton from '../../../../components/radio-button'
import Button from 'components/button'
import API_ENDPOINTS from '../../../../constants/API_ENDPOINTS'
import './style.scss'
import BaseService from '../../../../services/base.service'
import { FormData, Role, Shift, ShiftModel, ShiftOption } from 'Event/interfaces'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import IconButton from '@material-ui/core/IconButton'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import first from 'lodash/first'
import isNull from 'lodash/isNull'
import AddUserPrompt from 'people/components/AddUserPrompt'
import { Person } from 'civic-champs-shared/question-sets/types'
import useAddPerson from 'civic-champs-shared/api/hooks/useAddPerson'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import Grid from '@material-ui/core/Grid'
import PersonAutocomplete from 'civic-champs-shared/core/add-person/PersonAutocomplete'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useCreateApiNotification } from '../../../../../civic-champs-shared/api/hooks'

const base = new BaseService()

const AddVolunteerForm = (props: any) => {
  const { onSave, onClose, volunteerGroupId } = props
  const encodedOccurrence = encodeOccurrenceFromEvent(props.event)
  const timeshifts = useMemo(
    () => props.event.timeshifts.filter((ts: any) => ts.available > 0),
    [props.event.timeshifts],
  )
  const createNotification = useCreateApiNotification({ isNew: true, isMultiline: true })

  const [loading, setLoading] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  const [data] = useState<FormData>({
    details: [] as ShiftModel[],
  })

  const [shiftOptions, setShiftOptions] = useState<ShiftOption[]>([
    {
      label: 'Shift *',
      shiftError: '',
      roleError: '',
    },
  ])

  const [shifts, setShifts] = useState<Shift[]>(timeshifts)
  const [dataTimeShifts, setDataTimeShifts] = useState<ShiftModel[]>([new ShiftModel()])

  const [volunteer, setVolunteer] = useState<{
    value: PersonSuggestion | null
    touched: boolean
    error: string
  }>({ value: null, touched: false, error: 'Please select a volunteer.' })

  const [initialCreateValues, setInitialCreateValues] = useState<any>({
    givenName: '',
    familyName: '',
    email: '',
    phone: '',
    home: '',
    birthday: null,
    sendInvite: true,
  })

  const filterShifts = useCallback(() => {
    const shiftIds = dataTimeShifts.map(r => r.timeshift_id)
    const filtered = timeshifts.filter((shift: Shift) => !shiftIds.includes(shift.id))
    setShifts(filtered)
  }, [dataTimeShifts, timeshifts])

  useEffect(() => {
    filterShifts()
  }, [dataTimeShifts, filterShifts])

  const selectShift = (option: ShiftOption, index: number) => {
    const newShifts = [...shiftOptions]
    newShifts[index] = option

    if (option.value) {
      let dataTimeShiftsState = [...dataTimeShifts]
      const object = new ShiftModel()
      object.timeshift_id = option.value.id

      dataTimeShiftsState[index] = object
      setDataTimeShifts(dataTimeShiftsState)
    } else {
      option.shiftError = 'A shift is required. Please select a shift.'
    }
    setShiftOptions(newShifts)
  }

  const onAddShift = () => {
    const newShifts = [...shiftOptions]
    const newShift = { label: 'Shift *', shiftError: '', roleError: '' }
    newShifts.push(newShift)
    setShiftOptions(newShifts)
  }

  const onRemoveShift = (index: number) => {
    const newShifts = [...shiftOptions]
    newShifts.splice(index, 1)
    setShiftOptions(newShifts)

    const dataTimeShiftsState = [...dataTimeShifts]
    dataTimeShiftsState.splice(index, 1)
    setDataTimeShifts(dataTimeShiftsState)
  }

  const handleRoleRadioChange = (id: string, index: number) => {
    const roleId = +id
    let dataTimeShiftsState = [...dataTimeShifts]
    dataTimeShiftsState[index].role_id = +roleId
    setDataTimeShifts(dataTimeShiftsState)
  }

  const mappedShifts = () => {
    return shifts.map((ts: Shift) => {
      return { value: ts, label: `${ts.name}: ${ts.time_start} - ${ts.time_end}` }
    })
  }

  const checkAllShifts = () => {
    const shiftOptionsState = [...shiftOptions]
    dataTimeShifts.forEach((ts: ShiftModel, index: number) => {
      shiftOptionsState[index].shiftError = ts.timeshift_id ? '' : 'A shift is required. Please select a shift.'
      shiftOptionsState[index].roleError = ts.role_id ? '' : 'A role is required. Please select a role'
    })

    return shiftOptionsState
  }

  const onPersonCreate = (input: string) => {
    if (input.trim()) {
      const emailMatch = input.match(/[^\s]+@[^\s]+/)
      const email = first(emailMatch) || ''
      input = input.replace(email, '')
      const phoneMatch = input.match(/^\+?\d+$|^\+?\d+[^\s]|[^\s]\+?\d+[^\s]/)
      const phone = first(phoneMatch) || ''
      const names = input.replace(phone, '').trim().replace(/\s+/, ' ').split(' ')
      setInitialCreateValues((prevValues: any) => ({
        ...prevValues,
        givenName: names.shift(),
        familyName: names.join(' '),
        email,
        phone,
      }))
    }

    setModalOpen(true)
  }

  const onVolunteerChange = (volunteerValue: PersonSuggestion) => {
    setVolunteer(prevState => ({ ...prevState, value: volunteerValue }))
  }

  const setVolunteerTouched = () => setVolunteer(prevState => ({ ...prevState, touched: true }))

  const setErrors = (volunteerEmpty: boolean, shiftsErrorMessages: ShiftOption[]) => {
    volunteerEmpty && setVolunteerTouched()
    setShiftOptions(shiftsErrorMessages)
  }

  const closeModalAndSetData = ([{ person }]: [{ person: Person }]) => {
    const { id, givenName, familyName, email, phoneNumber } = person
    setVolunteer(prevState => {
      const contact = email || phoneNumber
      return {
        ...prevState,
        value: {
          id,
          givenName,
          familyName,
          contact,
          title: `${givenName} ${familyName} - ${contact}`,
          groupId: volunteerGroupId,
        } as PersonSuggestion,
      }
    })
    setModalOpen(false)
  }

  const [addNewPersonToGroup] = useAddPerson({ groupId: volunteerGroupId })

  const handlePersonAdd = (userData: any) => {
    addNewPersonToGroup({
      type: 'NEW_USER',
      ...userData,
    }).then(closeModalAndSetData)
  }

  const onAddPersonPromptClose = () => {
    setModalOpen(false)
    setVolunteer(prevState => ({
      ...prevState,
      value: null,
    }))
  }

  const handleSave = async () => {
    setLoading(true)

    const shiftsOptionsState = checkAllShifts()
    const hasShiftError = shiftsOptionsState.some((option: ShiftOption) => !!option.shiftError || !!option.roleError)
    if (isNull(volunteer.value) || hasShiftError) {
      setErrors(isNull(volunteer.value), shiftsOptionsState)
    } else {
      const notification = createNotification('Registering volunteer')
      try {
        const bodyData = { ...data }
        bodyData.details = dataTimeShifts
        bodyData.person_id = volunteer.value.id

        const savingResponse = await base.postJSON(
          API_ENDPOINTS.EventDetail.registerVolunteer(encodedOccurrence, props.returnRegistrant),
          bodyData,
        )
        if (savingResponse.error) {
          throw new Error(savingResponse.message)
        }
        notification.onSuccess(
          `${savingResponse.user.givenName} ${savingResponse.user.familyName} has been registered for the event.`,
        )
        onSave(savingResponse)
      } catch (e) {
        notification.onError('Error registering Volunteer', e)
      }
    }

    setLoading(false)
  }

  return (
    <>
      <AddUserPrompt
        onCreate={handlePersonAdd}
        open={modalOpen}
        handleClose={onAddPersonPromptClose}
        label="Add new volunteer"
        initialValues={initialCreateValues}
      />
      <article className="add-volunteer-form">
        <div className="title_wrapper">
          <h2 className="title">Add a Volunteer</h2>
          <IconButton onClick={onClose} className="icon-button">
            <Icon path={mdiClose} size={1} />
          </IconButton>
        </div>
        <section className="input-component">
          <PersonAutocomplete
            label={'Search for a Volunteer'}
            addNewPersonText={'+ Add a new volunteer'}
            groupId={volunteerGroupId}
            groupTitle={'Volunteers'}
            onCreate={onPersonCreate}
            onChange={onVolunteerChange}
            required={true}
            hasError={volunteer.touched && isNull(volunteer.value)}
            error={volunteer.error}
            value={volunteer.value}
            onBlur={setVolunteerTouched}
          />
        </section>

        {shiftOptions.map((shiftOption: ShiftOption, optionIndex: number) => {
          return (
            <section key={optionIndex} className="input-component">
              <div className="shift_dropdown_wrapper">
                <SelectComponent
                  value={shiftOption}
                  onChange={(option: ShiftOption) => selectShift(option, optionIndex)}
                  options={mappedShifts()}
                  placeholder={'Shift *'}
                  hasError={!!shiftOption.shiftError}
                  errorMessage={shiftOption.shiftError}
                />
                {optionIndex !== 0 && (
                  <IconButton onClick={() => onRemoveShift(optionIndex)}>
                    <Icon path={mdiClose} size={1} />
                  </IconButton>
                )}
              </div>
              {shiftOption.value && (
                <div className="roles">
                  <div className="roles__title">Roles: *</div>
                  <div className="roles__options">
                    {shiftOption.value.roles.map((role: Role, i: number) => {
                      if (role.available <= 0) {
                        return null
                      }

                      const shiftId = shiftOption.value!.id
                      return (
                        <div className={'roles_options_wrapper'} key={i}>
                          <RadioButton
                            id={i}
                            label={role.name}
                            value={`${role.id}`}
                            checked={!!dataTimeShifts.find(s => shiftId === s.timeshift_id && s.role_id === role.id)}
                            onChange={(roleId: string) => handleRoleRadioChange(roleId, optionIndex)}
                          />
                        </div>
                      )
                    })}
                  </div>
                  {shiftOption.roleError && <div className="error_message">{shiftOption.roleError}</div>}
                </div>
              )}
            </section>
          )
        })}

        {shiftOptions.length < timeshifts.length && (
          <section className="input-component">
            <Button
              disabled={false}
              onClick={onAddShift}
              className="add-shift"
              type="text"
              color="secondary"
              size="small"
            >
              <img src="/assets/icons/add.svg" alt="add" />
              Add another Shift
            </Button>
          </section>
        )}
        <Grid container justify="space-between">
          <OutlinedButton disabled={loading} onClick={onClose}>
            Cancel
          </OutlinedButton>
          <ContainedButton disabled={loading} isLoading={loading} onClick={handleSave}>
            Add
          </ContainedButton>
        </Grid>
      </article>
    </>
  )
}

export default AddVolunteerForm
