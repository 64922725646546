import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useAddEditTagPrompt, useFetchTags, useRemoveTagPrompt } from './index'

export const useTagsCollection = () => {
  const { fetchTags, loading, error } = useFetchTags()
  const [tags, operations, events] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerAdd, eagerReplace, eagerRemove } = operations
  const getTags = useCallback(async () => {
    const updatedTags = await fetchTags({ reporting: true })

    syncCollection(updatedTags)
    setInitiallyLoaded(true)
  }, [fetchTags, syncCollection])

  useEffect(() => {
    getTags()
  }, [getTags])

  const addTag = useAddEditTagPrompt({ eagerAdd })
  const editTag = useAddEditTagPrompt({ eagerReplace })
  const deleteTag = useRemoveTagPrompt(eagerRemove)

  return [
    {
      tags,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addTag,
      editTag,
      deleteTag,
    },
    events,
  ]
}

export default useTagsCollection
