import React from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import ReactGoogleMap from 'components/ReactGoogleMap'
import { get } from 'lodash'
import { useTaskStyles } from './Task'
import cn from 'classnames'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import EditTaskLocationPrompt from './EditTaskLocationPrompt'
import useIsActive from '../hooks/useIsActive'

export default function TaskLocation(props) {
  const { task, refreshTask } = props
  const address = get(task, 'location.address', {})
  const coordinates = get(task, 'location.geofencing.location.coordinates', [])
  const isActive = useIsActive(task)
  const classes = useTaskStyles()
  const showEditLocationPrompt = useShowPrompt(EditTaskLocationPrompt)

  const handleEditLocationClick = async () => {
    await showEditLocationPrompt({ task, initialValue: task.location })
    refreshTask()
  }

  return (
    <Grid container item direction="row" spacing={2} className={cn(classes.locationRow, classes.row)}>
      <Grid item xs={4}>
        <Typography className={classes.header}>
          Location Address
          {isActive && (
            <IconButton onClick={handleEditLocationClick}>
              <Edit />
            </IconButton>
          )}
        </Typography>
        <Typography className={classes.text}>{address.line1}</Typography>
        <Typography className={classes.text}>
          {address.city}, {address.state} {address.zipCode}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <ReactGoogleMap coordinates={coordinates} />
      </Grid>
    </Grid>
  )
}
