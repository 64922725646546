import { useMemo } from 'react'
import _ from 'lodash'
import { Organization } from '../new-mentorship/types'
import { CreateOrUpdateOrganizationParam } from '../volunteering/organizations/models'

export const useGetOrganizationCreateUpdatePayload = (organization: Organization) => {
  return useMemo(() => {
    if (_.isEmpty(organization)) return undefined
    const c = _.clone(organization)
    // we save coords as [X,Y] in the db
    _.set(c, ['address', 'gps'], {
      lat: _.get(organization, ['location', 'coordinates', 1]),
      lng: _.get(organization, ['location', 'coordinates', 0]),
    })
    return c as unknown as CreateOrUpdateOrganizationParam
  }, [organization])
}
