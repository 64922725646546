import React, { useEffect, useMemo, useState } from 'react'
import Loader from 'Event/components/loader'
import { useColumnOrder, usePagination, useSortBy, useTable } from 'react-table'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PagedTable from 'core/table/components/PagedTable'
import { useExtendedTableStyles } from 'core/table/table-hooks/useExtendedTableStyles'
import useGetWebhookEventResponses from 'integrations/hooks/useGetWebhookEventResponses'
import { DateTimeCell } from 'core/table/cells'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useGetColumns = () =>
  useMemo(
    () => [
      {
        id: 'eventId',
        Header: 'Event Id',
        accessor: 'payload.id',
      },
      {
        id: 'createdOn',
        Header: 'Event Date',
        accessor: 'payload.createdOn',
        Cell: DateTimeCell,
      },
      {
        id: 'occurredAt',
        Header: 'Response Date',
        accessor: 'occurredAt',
        Cell: DateTimeCell,
      },
      {
        id: 'responseStatus',
        Header: 'Response Status',
        accessor: 'responseStatus',
      },
      {
        id: 'responseCode',
        Header: 'Response Code',
        accessor: 'responseCode',
      },
      {
        id: 'error',
        Header: 'Error',
        accessor: 'error',
      },
      {
        id: 'newEventStatus',
        Header: 'New Event Status',
        accessor: 'newEventStatus',
      },
      {
        id: 'responseBody',
        Header: 'Response Body',
        accessor: 'responseBody',
        Cell: ({ value }: any) => {
          const [expanded, setExpanded] = useState(false)
          return (
            <ExpansionPanel expanded={expanded}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => setExpanded(prev => !prev)} />}>
                Show
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>{value}</ExpansionPanelDetails>
            </ExpansionPanel>
          )
        },
      },
      {
        id: 'payload',
        Header: 'Payload',
        accessor: 'payload',
        Cell: ({ value }: any) => {
          const [expanded, setExpanded] = useState(false)
          return (
            <ExpansionPanel expanded={expanded}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon onClick={() => setExpanded(prev => !prev)} />}>
                Show
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <pre>{JSON.stringify(value, null, ' ')}</pre>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        },
      },
    ],
    [],
  )

export default function WebhooksResponses(params: any) {
  const {
    match: {
      params: { id },
    },
  } = params
  const [getWebhookEventResponses, { loading, result: data }] = useGetWebhookEventResponses()
  useEffect(() => {
    getWebhookEventResponses(id)
  }, [getWebhookEventResponses, id])
  const classes = useExtendedTableStyles()
  const columns = useGetColumns()

  const table = useTable(
    {
      data,
      // @ts-ignore
      columns,
    },
    useSortBy,
    usePagination,
    useColumnOrder,
  )

  if (loading) {
    return <Loader />
  }

  // @ts-ignore
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography variant="h4">Webhooks Responses</Typography>
      </Grid>
      <Grid container item>
        <Grid item xs={12} className={classes.wrapper}>
          <PagedTable
            {...table}
            // @ts-ignore
            newStyles
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
