import { Button, Checkbox, Popover, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useState } from 'react'
import { filter } from 'lodash'
import { TripOrder, TripOrderEventType } from '../types'
import { Person } from 'people/interface'
import { useMountedState } from 'react-use'

const useStyles = makeStyles({
  button: {
    color: '#0F5DB5',
    textDecoration: 'none',
    fontSize: '12px',
    height: '20px',
    width: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
  driverContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '200px',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
  },
  buttonsContainer: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '200px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '5px 0',
    backgroundColor: 'white',
  },
  driversContainer: {
    margin: '5px 0',
    maxHeight: '200px',
    overflowY: 'scroll',
  },
  popover: {
    position: 'fixed',
  },
})

interface TripDriverPickerProps {
  onDriverAssignment: Function
  tripOrder: TripOrder
  label: string
  drivers: Person[]
  driversLoading: any
}

export const TripDriverPicker: React.FC<TripDriverPickerProps> = props => {
  const { label, onDriverAssignment, tripOrder, drivers, driversLoading } = props
  const assignedDriver = tripOrder.driver
  const [selectedDriver, setSelectedDriver] = useState<Person | null>(assignedDriver || null)
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const classes = useStyles()
  const isMounted = useMountedState()
  const open = Boolean(anchorElement)

  const handleClick = (event: any) => {
    setAnchorElement(event.currentTarget)
  }

  const getEventCode = () => {
    if (!assignedDriver && selectedDriver) {
      return TripOrderEventType.DRIVER_ASSIGNED
    } else if (assignedDriver && !selectedDriver) {
      return TripOrderEventType.DRIVER_UNASSIGNED
    } else if (assignedDriver !== selectedDriver) {
      return TripOrderEventType.DRIVER_REASSIGNED
    } else {
      return false
    }
  }

  const handleDone = async () => {
    setProcessing(true)
    const eventCode = getEventCode()
    if (selectedDriver === assignedDriver || !eventCode) {
      setProcessing(false)
      return handleClose()
    }
    try {
      await onDriverAssignment({
        ...tripOrder,
        eventCode: eventCode,
        payload: {
          driverId: selectedDriver?.id,
        },
      })
    } finally {
      if (isMounted()) {
        setProcessing(false)
      }
    }
  }

  const handleClose = useCallback(() => {
    setAnchorElement(null)
    setSelectedDriver(assignedDriver ? assignedDriver : null)
  }, [assignedDriver])

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.button}
        disabled={driversLoading}
      >
        {label}
      </Button>

      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorElement}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.driversContainer}>
          {drivers && !driversLoading ? (
            drivers?.map(driver => {
              return (
                <div key={`${driver.id}`} className={classes.driverContainer}>
                  <Typography>{`${filter([driver.givenName, driver.familyName]).join(' ')}`}</Typography>
                  <Checkbox
                    color="primary"
                    checked={selectedDriver?.id === driver.id}
                    onChange={() => {
                      setSelectedDriver(selectedDriver?.id === driver.id ? null : driver)
                    }}
                  />
                </div>
              )
            })
          ) : driversLoading ? (
            <CircularProgress />
          ) : (
            <Typography style={{ padding: '0 20px', width: '200px', overflow: 'break-all' }}>
              There are no drivers associtiated with this organization
            </Typography>
          )}
        </div>
        <div className={classes.buttonsContainer}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button disabled={processing} onClick={handleDone} color="primary">
            Submit
          </Button>
        </div>
      </Popover>
    </div>
  )
}
