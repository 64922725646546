import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Grid, Button } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import CloseIcon from '@material-ui/icons/Close'
import { MergeAccountReferenceList } from './MergeAccountReferenceList'
import FormWrapper from '../../Mentorship/kit/components/form-wrapper'
import usePerson from '../hooks/usePerson'
import Loading from 'components/Loading'
import { useReferencesToBeDeleted } from '../hooks/useReferencesToBeDeleted'
import { usePurgeAccount } from '../hooks/usePurgeAccount'

export default function PurgeAccountPrompt(props) {
  const { showing, close, personId, complete } = props
  const classes = usePromptStyles()
  const { person, loading: personLoading } = usePerson({ id: personId })
  const { result: references, loading: referencesLoading } = useReferencesToBeDeleted(person)
  const [purgeAccount, { loading: purgeAccountLoading }] = usePurgeAccount()
  const loading = personLoading || referencesLoading || purgeAccountLoading
  const onPurge = async () => {
    await purgeAccount(person)
    complete()
  }
  return (
    <Dialog className="full-screen" open={showing} onClose={close}>
      <DialogTitle className={classes.titleContainer}>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <IconButton onClick={close} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FormWrapper title={'Purge Account'}>
          {loading && <Loading />}
          {!loading && (
            <>
              <h3>By deleting this person, you will also be deleting the following references:</h3>
              <MergeAccountReferenceList references={references} person={person} />
              <p>
                <b>
                  This action cannot be undone.
                </b>
              </p>
              <Button onClick={close} disabled={loading}>Cancel</Button>
              <Button onClick={onPurge} disabled={loading}>Purge</Button>
            </>
          )}
        </FormWrapper>
      </DialogContent>
    </Dialog>
  )
}
