import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { parseGoogleResponse } from 'civic-champs-shared/core/location/utils'
import { mapCoordinatesObjectToArray } from 'utils/event'
import { defaultAddress } from 'civic-champs-shared/formik/components/AddressField'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  locationPickerContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}))

interface TripRequestLocationPickerProps {
  setCoordinates: Function
  location?: any
  setLocation: Function
  label: string
  field: string
}

export const TripRequestLocationPicker: React.FC<TripRequestLocationPickerProps> = props => {
  const { setCoordinates, location, setLocation, label, field } = props
  const classes: any = useStyles()

  const [addressObj, setAddressObj] = useState(
    location.displayAddress
      ? location
      : {
          address: defaultAddress(),
          displayAddress: '',
        },
  )
  const addressRef = useRef<HTMLInputElement>()

  const handleLocationChange = (address: any) => {
    // this gets called as the user types or when they hit the clear button
    // either way, we want to wipe away the old city, state, zip until they have selected a location from the dropdown
    // in which case, handleLocationSelect will get called
    setAddressObj({
      address: defaultAddress(),
      displayAddress: address,
    })
  }

  const handleLocationSelect = useCallback(
    autocomplete => () => {
      const { displayName, address, coordinates } = parseGoogleResponse(autocomplete)
      if (address.line1 && (coordinates.lat !== 0 || coordinates.lng !== 0)) {
        setAddressObj({
          address,
          displayAddress: displayName,
          geofencing: {
            position: {
              coordinates: mapCoordinatesObjectToArray(coordinates),
              type: 'Point',
            },
            radius: DEFAULT_GEOFENCING.radius,
          },
        })
        setLocation(field, {
          address,
          displayAddress: displayName,
          geofencing: {
            position: {
              coordinates: mapCoordinatesObjectToArray(coordinates),
              type: 'Point',
            },
            radius: DEFAULT_GEOFENCING.radius,
          },
        })
        setCoordinates(coordinates)
      }
    },
    [setLocation, field, setCoordinates],
  )

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current, {
      types: ['geocode'],
    })
    autocomplete.setComponentRestrictions({
      country: ['us'],
    })

    const listener = autocomplete.addListener('place_changed', handleLocationSelect(autocomplete))

    return () => {
      window.google.maps.event.removeListener(listener)
      window.google.maps.event.clearInstanceListeners(autocomplete)
    }
  }, [handleLocationSelect])

  return (
    <>
      <div className={classes.locationPickerContainer}>
        <Grid container direction="column" spacing={2}>
          <Grid item container xs={6} direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.header}>
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <div className={classes.location}>
                <TextField
                  fullWidth
                  inputRef={addressRef}
                  type="search"
                  variant="outlined"
                  color="primary"
                  value={addressObj.displayAddress}
                  onChange={e => handleLocationChange(e.target.value)}
                  label={label}
                  placeholder="Street Address, City, State, Zip"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
