import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
  },
})

export default function BackLink(props) {
  const { to, text } = props
  const classes = useStyles()

  const history = useHistory()

  const linkProps = {
    ...(to
      ? { to }
      : {
          onClick: () => history.goBack(),
        }),
  }

  return (
    <div className="button-back-wrapper">
      <Link {...linkProps} className={classes.root}>
        <div className="detail_event_block_back_arrow">
          <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
          {text}
        </div>
      </Link>
    </div>
  )
}
