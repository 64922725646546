import { IntegrationProvider } from 'integrations/types'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useDeleteIntegrationKey = (): ((provider: IntegrationProvider) => Promise<void>) => {
  const [request] = useFetchRefactored<void>({
    successMessage: 'Integration Deleted',
    errorMessage: 'Failed to Delete Integration',
    useNewNotification: true,
  })
  const org = useCurrentOrg()

  return useCallback(
    (provider: IntegrationProvider) => {
      return request({
        method: 'del',
        url: `/organizations/${org.id}/integration-keys/${provider}`,
      })
    },
    [org.id, request],
  )
}

export default useDeleteIntegrationKey
