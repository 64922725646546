import { map } from 'lodash'
import React, { useMemo } from 'react'
import { useSortBy, usePagination, useTable, Column } from 'react-table'

import PagedTable from 'core/table/components/PagedTable'
import { ChampionDatafeedImportRow } from 'external-datafeed/types'
import { CheckMarkCell } from 'core/table/cells'
import { Typography, makeStyles } from '@material-ui/core'

interface DatafeedParseResultProps {
  data: ChampionDatafeedImportRow[]
}

const ErrorsCell = ({ value }: { value: string[] }) => (<>
  {map(value, (error, index) => (
    <span key={index}>{error}</span>
  ))}
</>) 

const columns: Column<ChampionDatafeedImportRow>[] = [
  { Header: 'ID', accessor: 'internalId' },
  { Header: 'Customer ID', accessor: 'externalId' },
  { Header: 'First Name', accessor: 'firstName' },
  { Header: 'Last Name', accessor: 'lastName' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Mobile', accessor: 'phoneNumber' },
  { Header: 'Home', accessor: 'homePhoneNumber' },
  { Header: 'Update', accessor: 'isNew', Cell: CheckMarkCell },
  { Header: 'Errors', accessor: 'errors', Cell: ErrorsCell } 
]

const useStyles = makeStyles({
  summary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '25px'
  }

})

export const DatafeedParseResult: React.FC<DatafeedParseResultProps> = (props) => {
  const { data=[] } = props 
  const table = useTable<ChampionDatafeedImportRow>(
    {
      data,
      columns
    },
    useSortBy,
    usePagination
  )

  const { errors, skipped, updates } = useMemo(() => {
    let errors = 0, skipped = 0, updates = 0;
    for(let row of data) {
      if(row.isNew) updates++
      else if(row.isError) errors++
      else skipped++
    } 

    return { errors, skipped, updates }
  }, [data])

  const classes = useStyles()
  
  return (
    <div>
      { /* 
        //@ts-ignore */
      (<PagedTable {...table} />)}
      <div className={classes.summary}>
          <Typography>{skipped} Rows Skipped</Typography>
          <Typography>{updates} IDs To Update</Typography>
          <Typography>{errors} Errors</Typography>
      </div>
    </div>
  )
}