import { useMemo } from 'react'

import ProductFeatureSettings from 'core/feature/components/ProductFeatureSettings'
import SalsaSettings from 'salsa/components/SalsaSettings'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import HelpingHandsRoles from 'volunteering/tasks/components/HelpingHandsRoles'
import OrganizationLongCode from '../components/OrganizationLongCode'
import OrganizationStripeId from '../components/OrganizationStripeId'
import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import IntegrationApiKey from 'api-key/IntegrationApiKey'

const useOrganizationSettingsTabs = () => {
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const internalAdmin = useHasRole(INTERNAL_ADMIN)
  const internalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const salsaEnabled = useFeatureEnabled('SalsaIntegration')
  const volunteeringEnabled = useFeatureEnabled('Volunteering')
  const showHHRoles = useFeatureEnabled('HelpingHands')
  const apiKeyEnabled = useFeatureEnabled('ApiKey')

  return useMemo(() => {
    const enabled = {
      internalAdmin,
      internalSuperAdmin,
      salsaEnabled,
      volunteeringEnabled,
      showHHRoles,
      apiKeyEnabled,
    }

    const tabs = isSuperAdmin
      ? [
          {
            label: 'Product Settings',
            requires: 'internalAdmin',
            isAdminItem: true,
            Component: ProductFeatureSettings,
          },
          { label: 'Helping Hands Roles', requires: 'showHHRoles', Component: HelpingHandsRoles },
          { label: 'Salsa Integration', requires: 'salsaEnabled', Component: SalsaSettings },
          {
            label: 'SMS Long Code',
            requires: 'internalSuperAdmin',
            isAdminItem: true,
            Component: OrganizationLongCode,
          },
          {
            label: 'Stripe Customer ID',
            requires: 'internalSuperAdmin',
            isAdminItem: true,
            Component: OrganizationStripeId,
          },
          { label: 'Api Key', requires: 'apiKeyEnabled', Component: IntegrationApiKey },
        ]
      : []

    return filterByRequireRules(tabs, {
      config: enabled,
      recursive: false,
    })
  }, [internalAdmin, internalSuperAdmin, salsaEnabled, volunteeringEnabled, showHHRoles, apiKeyEnabled, isSuperAdmin])
}

export default useOrganizationSettingsTabs
