import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

export const CredentialLabelCell = ({ cell }) => {
  const id = get(cell.row.original, 'id')

  return <Link to={`/credential-ids/${id}`}>{cell.value}</Link>

}


const useCredentialIDColumns = () => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN);

  return useMemo(() => filterByRequireRules([
    {
      Header: 'ID',
      accessor: 'id',
      requires: 'isInternalAdmin'
    }, {
      Header: 'Name',
      accessor: 'label',
      Cell: CredentialLabelCell
    },], {
    isInternalAdmin,
  }), [isInternalAdmin])
}

export default useCredentialIDColumns;