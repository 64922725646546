import { chain, isEmpty, trim, identity, isFunction } from 'lodash';
import React, { useCallback } from 'react'
import Typography from '@material-ui/core/Typography';
import InputMask from 'react-input-mask';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from '../hooks/useMyUserStyles';
import cn from 'classnames';

const filterTrimmedValues = values => chain(values).mapValues(trim).pickBy(identity).value();

export default function CheckExistingUsersStep(props) {
    const classes = useStyles();

    const { userLabel="User", filter, onFilterChanged, onNext } = props;

    const { email='', phone='' } = filter;
    const emailProvided = !isEmpty( trim(filter.email) );
    const phoneProvided = !isEmpty( trim(filter.phone) );
    const anyInput = emailProvided || phoneProvided;

    const handleFilterChange = useCallback(
        (change) => {
            if( !isFunction(onFilterChanged) ) return;
            onFilterChanged(change);
        },
        [onFilterChanged]
    );

    const handleEmailChanged = useCallback( e => handleFilterChange({ email: e.target.value }), [handleFilterChange]);
    const handlePhoneChanged = useCallback( e => {
        const phone = '+' + (e.target.value || '').replace(/[^\d]/g, '')
        handleFilterChange({ phone })
    }, [handleFilterChange]);
    const handlePhoneOnBlur = useCallback( e => {
        let phone = (e.target.value || '').replace(/[^\d]/g, '');
        if (phone.length == 0) {
            handleFilterChange({ phone: '' });
        }
    }, [handleFilterChange]);

    const handleClear = useCallback( () => {handleFilterChange({ email: '', phone: '' }) }, [handleFilterChange])

    const handleSubmit = useCallback(e => {
        e.preventDefault();

        if( !isFunction(onNext) ) return;

        let params = filterTrimmedValues(filter);
        onNext(params);

    }, [onNext, filter]);

    return (<div>
        <Typography>
            Enter {userLabel}&apos;s email and/or phone number
            to check if an account already exists.
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
            <TextField
                name="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={handleEmailChanged}
                fullWidth
                className={(classes.inputFieldOutlined)}
            />
            <InputMask
                name="phone"
                label="Phone"
                variant="outlined"
                mask="+1 (999) 999-9999"
                maskChar=" "
                onBlur={handlePhoneOnBlur}
                alwaysShowMask={false}
                value={phone}
                onChange={handlePhoneChanged}
                fullWidth
            >{
                (inputProps) => <TextField {...inputProps} />
            }</InputMask>
            <div>
                <Button onClick={handleClear} className={classes.actionButton}>Clear</Button>
                <Button
                    type="submit"
                    disabled={!anyInput}
                    variant="contained"
                    color="secondary"
                    endIcon={<SearchIcon/>}
                    className={cn(classes.actionButton)}
                >
                    Search
                </Button>
            </div>
        </form>
    </div>)
}
