import { EventGeofencing } from 'utils/event'
import { EventGroup } from '../Event/interfaces/interfaceCreateEditEvent'

export enum AddressType {
  Event = 'Event',
  Public = 'Public',
  Private = 'Private',
}

export interface OrganizationLocation {
  id: number
  organizationId: number
  locationId: number
  order: number | null
  name: string | null
  line1: string | null
  line2: string | null
  city: string | null
  county: string | null
  state: string | null
  zipCode: string | null
  countryCode: string | null
  geofencing: EventGeofencing
  type: AddressType
  encodedOccurrence?: string | null
  opportunityOccurrenceId?: number | null
  description?: string | null
  startsAt?: string
  endsAt?: string | null
  visibilityGroups?: EventGroup[]
  onboardingGroups?: EventGroup[]
  locationDetails?: string | null
  startDate?: Date | null
  endDate?: Date | null
}

export interface OrganizationLocationReporting extends OrganizationLocation {
  timesUsedActive: number
  timesUsedTotal: number
  locationIsAddress: boolean
  visibility: 'Public' | 'Private'
}

export interface OrganizationLocationPayload {
  id?: number
  name?: string | null
  line1?: string | null
  line2?: string | null
  city?: string | null
  county?: string | null
  state?: string | null
  zipCode?: string | null
  countryCode?: string | null
  geofencing?: EventGeofencing
  locationDetails?: string | null
}
