import React, { useCallback, useRef, useState } from 'react'
import isNull from 'lodash/isNull'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { FieldProps } from 'formik'

import { NamedRef } from 'civic-champs-shared/common/types'
import { usePaperComponentWithOnCreate } from 'civic-champs-shared/core/hooks/usePaperComponentWithOnCreate'
import { useAutocompleteStyles } from 'civic-champs-shared/core/hooks/useAutocompleteStyles'
import { useHandleBlur } from 'civic-champs-shared/helpers/useHandleBlur'

export interface RoleAutocompleteProps {
  label: string
  onCreate?: (inputValue: string) => void
  onBlur?: (...values: any) => void
  onChange: (role: NamedRef) => void
  error?: string
  hasError?: boolean
  required?: boolean
  value?: NamedRef | null
  options: NamedRef[]
  legendWidthFixture?: number
  disabled?: boolean
  addNewRoleText?: string
}

export function RoleAutocomplete(props: RoleAutocompleteProps) {
  const {
    label,
    onCreate,
    onChange,
    onBlur,
    error,
    value,
    options,
    hasError = false,
    required = false,
    legendWidthFixture,
    disabled = false,
    addNewRoleText = `+ Add a new role`,
  } = props

  const [open, setOpen] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const getOptionLabel = useCallback((option: NamedRef) => (option.id > 0 ? option.name : ''), [])

  const filterSuggestions = useCallback(createFilterOptions({ stringify: getOptionLabel }), [
    createFilterOptions,
    getOptionLabel,
  ])

  const [inputValue, setInputValue] = useState('')

  const focusEl = useRef<HTMLDivElement | null>(null)
  const handleCreate = useCallback(() => {
    onCreate && onCreate(inputValue)
  }, [inputValue, onCreate])
  const PaperComponent = usePaperComponentWithOnCreate({
    addNewText: addNewRoleText,
    onCreate: onCreate ? handleCreate : undefined,
    ref: focusEl,
  })

  const handleBlur = useHandleBlur([focusEl], () => {
    setInputFocused(false)
    setOpen(false)
    onBlur && onBlur()
  })
  // @ts-ignore
  const classes = useAutocompleteStyles({ legendWidthFixture })

  return (
    <Autocomplete
      disabled={disabled}
      options={options}
      value={value}
      open={open}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      onInputChange={(_event, newValue) => {
        setInputValue(newValue)
        inputFocused && setOpen(true)
      }}
      PaperComponent={PaperComponent}
      onFocus={() => {
        setInputFocused(true)
        setOpen(true)
      }}
      onBlur={handleBlur}
      filterOptions={filterSuggestions}
      blurOnSelect
      // not a debug code, needed to properly handle add role click
      debug={true}
      classes={{ groupLabel: classes.groupLabel, option: classes.option, listbox: classes.listbox }}
      getOptionLabel={getOptionLabel}
      popupIcon={<KeyboardArrowDownIcon onClick={() => setOpen(v => !v)} />}
      className={classes.autoComplete}
      renderInput={params => {
        return (
          <TextField
            {...params}
            fullWidth
            value={inputValue}
            classes={{
              root: classes.textField,
            }}
            error={hasError}
            helperText={hasError && error}
            required={required}
            label={label}
            variant="outlined"
          />
        )
      }}
    />
  )
}

export default RoleAutocomplete

interface FormikRoleAutocompleteProps
  extends Omit<RoleAutocompleteProps, 'onChange' | 'hasError' | 'error' | 'value' | 'onBlur'>,
    FieldProps {}

export const FormikRoleAutocomplete = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  meta,
  ...props
}: FormikRoleAutocompleteProps) => {
  return (
    <RoleAutocomplete
      {...props}
      onChange={value => {
        setFieldValue(name, value)
      }}
      hasError={touched[name] && (isNull(value) || !!errors[name])}
      error={errors[name] as string}
      value={value}
      onBlur={() => {
        // TODO: figure out better solution to make validation work
        setTimeout(() => setFieldTouched(name, true))
      }}
    />
  )
}
