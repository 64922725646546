import getErrorMessage from 'utils/getErrorMessage';

export const FEATURES_INITIALIZED = 'features.initialized';
export const GET_FEATURES_FOR_USER = 'features.get-feature-set.fetch';
export const GET_FEATURES_FOR_USER_SUCCEEDED = 'features.get-feature-set.success';
export const GET_FEATURES_FOR_USER_FAILED = 'features.get-feature-set.failure';
export const GET_PRODUCT_FEATURES_FOR_ORG = 'features.get-product-feature-set.fetch';
export const GET_PRODUCT_FEATURES_FOR_ORG_SUCCEEDED = 'features.get-product-feature-set.success';
export const GET_PRODUCT_FEATURES_FOR_ORG_FAILED = 'features.get-product-feature-set.failed';
export const UPDATE_PRODUCT_FEATURE = 'features.update-product-switch'

export const featuresInitialized = () => ({ type: FEATURES_INITIALIZED });

export const getFeaturesForUser = (user) => ({
    type: GET_FEATURES_FOR_USER,
    payload: { user }
});

export const getFeaturesForUserSucceeded = (user, features) => ({
    type: GET_FEATURES_FOR_USER_SUCCEEDED,
    payload: {
        user,
        features
    }
});

export const getFeaturesForUserFailed = (user, error) => ({
    type: GET_FEATURES_FOR_USER_FAILED,
    payload: {
        user,
        error: getErrorMessage(error)
    }
});

export const getProductFeaturesForOrg = (organization) => ({
    type: GET_PRODUCT_FEATURES_FOR_ORG,
    payload: { organization }
});

export const getProductFeaturesForOrgSucceeded = (organization, features) => ({
    type: GET_PRODUCT_FEATURES_FOR_ORG_SUCCEEDED,
    payload: {
        organization,
        features
    }
})

export const getProductFeaturesForOrgFailed = (organization, error) => ({
    type: GET_PRODUCT_FEATURES_FOR_ORG_FAILED,
    payload: {
        organization,
        error: getErrorMessage(error)
    }
})

export const updateProductFeature = (organization, feature, enabled) => ({
    type: UPDATE_PRODUCT_FEATURE,
    payload: {
        organization,
        feature,
        enabled
    }
})
