import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { Edit as EditIcon } from '@material-ui/icons'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import TabletMacIcon from '@material-ui/icons/TabletMac'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { linkify } from 'civic-champs-shared/utils/linkify'
import Loader from 'Event/components/loader'
import { createMarkup } from 'civic-champs-shared/core/utils/helpers'
import { OrganizationLocationReporting } from 'locations/types'
import { useLocationDateStrings } from 'locations/hooks/useLocationDateStrings'
import { useGetEvent } from 'Event/hooks/useGetEvent'
import { useGetEventGroups } from 'Event/hooks/useGetEventGroups'
import { OpportunityVisibility } from 'Event/interfaces'
import { EventVisibility } from 'Event/components/EventVisibility'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import { startKiosk } from 'kiosk/actions'
import { useDispatch } from 'react-redux'
import useRemoveLocationPrompt from 'locations/hooks/useRemoveLocationPrompt'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useHistory } from 'react-router'
import useAddEditLocationPrompt from 'locations/hooks/useAddEditLocationPrompt'
import { filter } from 'lodash'

const SectionHeader = ({ title, onEdit }: { title: string; onEdit: () => void }) => {
  const classes = useEventDrilldownStyles()

  return (
    <div className={classes.sectionHeader}>
      <div className={classes.sectionTitle}>{title}</div>
      <EditIcon className={classes.editIcon} onClick={onEdit} />
    </div>
  )
}

export const LocationDrilldownInfo = ({
  location,
  scrollTop,
  loadLocation,
}: {
  location: OrganizationLocationReporting
  scrollTop: number
  loadLocation: () => void
}) => {
  const classes = useEventDrilldownStyles()
  const history = useHistory()

  const navigateToLocations = useCallback(() => {
    history.push('/locations')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const removeLocation = useRemoveLocationPrompt(
    navigateToLocations as unknown as Action<OrganizationLocationReporting>,
    loadLocation as unknown as Action<OrganizationLocationReporting>,
    false,
  )
  const handleRemoveLocation = useCallback(() => {
    removeLocation(location as OrganizationLocationReporting)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  const editLocation = useAddEditLocationPrompt({
    replace: loadLocation as unknown as Action<OrganizationLocationReporting>,
    eager: false,
  })

  const handleEditLocation = useCallback(() => {
    editLocation(location as OrganizationLocationReporting)
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  const { event, loading, refresh: refreshEvent } = useGetEvent(location?.encodedOccurrence || undefined, false, false)
  useEffect(() => {
    if (location?.encodedOccurrence) {
      refreshEvent()
    }
  }, [location, loadLocation, refreshEvent])
  const [getEventGroups, { loading: eventGroupsLoading, result: groups }] = useGetEventGroups()
  const needsGroups = event && event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY
  useEffect(() => {
    if (needsGroups) {
      getEventGroups(event!.id)
    }
  }, [needsGroups]) // eslint-disable-line react-hooks/exhaustive-deps
  const { startsAt, endsAt } = useLocationDateStrings({ location })

  const dispatch = useDispatch()
  const handleStartKiosk = useCallback(() => {
    dispatch(startKiosk(event))
  }, [event, dispatch])

  const [expanded, setExpanded] = useState(true)
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)

  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })

  let address = ''
  if (location) {
    const { line1, city, state, zipCode } = location
    address = filter([line1, city, state, zipCode]).join(', ') || ''
  }

  if (!location || (location.encodedOccurrence && loading) || eventGroupsLoading) {
    return <Loader />
  }

  return (
    <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
      <EventExpansionPanelSummary style={style} ref={summaryRef} name="event-info">
        <h1>Overview</h1>
      </EventExpansionPanelSummary>
      <EventExpansionPanelDetails ref={detailsRef}>
        <div className={classes.buttons}>
          {location.encodedOccurrence && event ? (
            <OutlinedButton startIcon={<TabletMacIcon />} onClick={handleStartKiosk}>
              Open Kiosk
            </OutlinedButton>
          ) : (
            <div />
          )}
          {location.timesUsedTotal === 0 || location.timesUsedActive === 1 ? (
            <OutlinedButton startIcon={<HighlightOffIcon />} onClick={handleRemoveLocation}>
              Delete Location
            </OutlinedButton>
          ) : (
            <div />
          )}
        </div>
        <div className={classes.details}>
          <div className={classes.eventInfo}>
            <SectionHeader title={`Location Information`} onEdit={handleEditLocation} />
            <div className={classes.sectionRow}>
              <div>Start Date:</div>
              <div>{startsAt}</div>
            </div>
            <div className={classes.sectionRow}>
              <div>End Date:</div>
              <div>{endsAt}</div>
            </div>

            <div className={classes.sectionRow}>
              <div>Location:</div>
              <div>{!location.locationDetails ? address : linkify(location.locationDetails, 50)}</div>
            </div>
            <div className={classes.sectionRow}>
              <div>Visibility:</div>
              <div>
                {location.encodedOccurrence ? (
                  <EventVisibility event={event as OpportunityResponseWithRecurrenceInfo} groups={groups} />
                ) : (
                  'Event'
                )}
              </div>
            </div>
          </div>
          <div className={classes.description}>
            <h3>Description</h3>
            <div dangerouslySetInnerHTML={createMarkup(location.description || '')} />
          </div>
          <div className={classes.support} />
        </div>
      </EventExpansionPanelDetails>
    </EventExpansionPanel>
  )
}
