import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { Chat, ChatApiResponse } from 'chats/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { mapChat } from 'chats/helpers'

export const useGetChat = (): [(chatId: string) => Promise<Chat>, Status<Chat>] => {
  const [request, status] = useFetchRefactored<Chat, ChatApiResponse>({
    mapper: mapChat,
  })
  const org = useCurrentOrg()

  const fetchChat = useCallback(
    (chatId: string) =>
      request({
        method: 'get',
        url: `/organizations/${org.id}/chats/${chatId}`,
      }),
    [request, org],
  )

  return [fetchChat, status]
}

export default useGetChat
