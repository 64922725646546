import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'

import { useFetchLocations } from 'locations/hooks/useFetchLocations'
import { OrganizationLocationReporting } from 'locations/types'
import useAddEditLocationPrompt from 'locations/hooks/useAddEditLocationPrompt'
import useRemoveLocationPrompt from 'locations/hooks/useRemoveLocationPrompt'
import useReorderLocationsPrompt from 'locations/hooks/useReorderLocationsPrompt'
import { CollectionEventListeners, EventListener } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useFeatureEnabled } from '../../core/feature/hooks'

export const useLocationsCollection = () => {
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  const { fetchLocations, loading, error } = useFetchLocations(offersEnabled)
  const [locations, operations, events] = useRemoteCollection<OrganizationLocationReporting>()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerAdd, eagerReplace, eagerRemove } = operations
  const getLocations = useCallback(async () => {
    const updatedLocations = (await fetchLocations({ reporting: true })) as OrganizationLocationReporting[]

    syncCollection(updatedLocations)
    setInitiallyLoaded(true)
  }, [fetchLocations, syncCollection])

  useEffect(() => {
    getLocations()
  }, [getLocations])

  const addEditLocation = useAddEditLocationPrompt({ eagerAdd, replace: eagerReplace })
  const removeLocation = useRemoveLocationPrompt(eagerRemove, eagerReplace)
  const reorderLocations = useReorderLocationsPrompt(getLocations)

  return [
    {
      locations,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addEditLocation,
      removeLocation,
      reorderLocations,
    },
    events,
  ] as [
    {
      locations: OrganizationLocationReporting[]
      loading: boolean
      error: any
      initiallyLoaded: boolean
    },
    {
      addEditLocation: (location?: OrganizationLocationReporting) => Promise<void>
      removeLocation: (location: OrganizationLocationReporting) => Promise<void>
      reorderLocations: (table: any) => Promise<void>
      onAddLocation: EventListener<OrganizationLocationReporting>
    },
    CollectionEventListeners<OrganizationLocationReporting>,
  ]
}

export default useLocationsCollection
