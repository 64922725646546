import React, { ReactNode } from 'react'
import classNames from 'classnames'

import Loader from '../../../../Event/components/loader'

import './index.scss'

interface ButtonProps {
  onClick?: (event: any) => void | VoidFunction | Promise<void>
  className?: string
  color?: 'yellow' | 'transparent' | 'blue'
  variant?: 'text'
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  children?: ReactNode
  iconName?: string
  isLoading?: boolean
}

export default function ButtonComponent(props: ButtonProps) {
  const { onClick, className, color, disabled, type, children, iconName, variant, isLoading } = props
  const buttonClasses = classNames('button_component_wrapper', {
    [`${className}`]: className,
    [`button_component_${color}`]: color,
    [`button_component_variant_${variant}`]: variant,
  })
  return (
    <div className={buttonClasses}>
      <button disabled={disabled} type={type} onClick={onClick}>
        {iconName && <img src={`/assets/mentorship/images/${iconName}`} className="button_component_icon" alt="icon" />}
        {isLoading ? <Loader width={15} height={15} /> : children}
      </button>
    </div>
  )
}
