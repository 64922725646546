import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { isNil, omitBy } from 'lodash'
import { OrganizationLocation, OrganizationLocationReporting } from 'locations/types'

export const useFetchLocations = (fetchOpportunityLocations = false) => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<(OrganizationLocation | OrganizationLocationReporting)[]>({
    emptyValue: [],
  })

  const fetchLocations = useCallback(
    async (params: { reporting?: boolean; search?: string }) =>
      request({
        url: fetchOpportunityLocations
          ? `/organizations/${organization.id}/opportunity-locations`
          : `/organizations/${organization.id}/locations`,
        config: {
          queryStringParameters: omitBy(params, isNil),
        },
      }),
    [request, fetchOpportunityLocations, organization.id],
  )

  return { fetchLocations, loading, error }
}
