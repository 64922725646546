import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import cn from 'classnames'

export const OpenGroupPrompt = props => {
  const { onSubmit, loading, close, showing, complete, cancel } = props
  const classes = usePromptStyles()
  const submit = async () => {
    await onSubmit()
    complete(true)
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={cn(classes.contentContainer, classes.windowedContentContainer)}>
        <div className={classes.dialog}>
          <Grid container direction="column">
            <Grid>
              <Typography variant="h6">Are you sure you want to set group to open?</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">Any group 'applicants' will be automatically approved.</Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={cn(classes.actionContainer, classes.windowedActionContainer)}>
        <Button
          variant="text"
          color="primary"
          onClick={cancel}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeCancel}`}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={submit}
          disabled={loading}
          className={`${classes.actionButton} ${classes.orangeButton}`}
        >
          OPEN GROUP
        </Button>
      </DialogActions>
    </Dialog>
  )
}
