import { useCallback } from 'react'

import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { Person } from 'people/interface'
import MultiGroupAddMembersPrompt from 'group/components/MultiGroupAddMembersPrompt'
import { useMultiGroupAddMembers } from './useMultiGroupAddMembers'

const useMultiGroupAddMembersPrompt = (
  onMembersAddedToGroups: (personIds: number[], groupIds: number[]) => void,
): ((persons: Person[]) => Promise<void>) => {
  const showPrompt = useShowPrompt(MultiGroupAddMembersPrompt)
  const { addMembers } = useMultiGroupAddMembers()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async (persons: Person[]) => {
      const { personIds, groupIds } = await showPrompt({ persons })
      const notification = createNotification('Adding to group(s)')
      try {
        await addMembers(personIds, groupIds)
        onMembersAddedToGroups(personIds, groupIds)
        notification.onSuccess('Added to group(s)')
      } catch (e) {
        notification.onError('Error adding to groups', e)
      }
    },
    [addMembers, createNotification, onMembersAddedToGroups, showPrompt],
  )
}

export default useMultiGroupAddMembersPrompt
