import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import { Person } from 'civic-champs-shared/question-sets/types'
import { AddUserPayload } from 'civic-champs-shared/core/add-person/types'

const useAddAdmin = (): [(payload: AddUserPayload) => Promise<Person>, Status<Person>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<Person>({
    successMessage: 'Admin added successfully',
    errorMessage: 'Error adding admin',
  })

  const addAdmin = useCallback(
    (payload: AddUserPayload) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/admins`,
        config: {
          body: payload,
        },
      })
    },
    [organization.id, request],
  )

  return [addAdmin, status]
}

export default useAddAdmin
