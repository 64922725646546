import { useCallback } from 'react'
import { get } from 'lodash'
import moment from 'moment'

import { useCurrentOrg } from 'auth/hooks'

const useFormatToOrgTime = () => {
  const org = useCurrentOrg()
  const tz = get(org, 'timeZone', 'America/New_York')
  return useCallback( (time, format='hh:mm a') => moment.tz(time, tz).format(format), [tz])
}

export default useFormatToOrgTime
