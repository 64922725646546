import { isFunction } from 'lodash'
import React, { useMemo } from 'react'
import { withStyles, Button } from '@material-ui/core'

export const ApprovalButton = withStyles({
  root: {
    background: '#BFDF57',
    color: 'black'
  },
  label: {
    textTransform: 'uppercase'
  }
})((props) => (
  <Button 
    variant="contained"
    {...props}

  />
))

const useGroupOverviewApprovalButtonCell = (onGotoApprovals) => useMemo(() => {
  const ApprovalButtonCell = ({ cell }) => {

    const group = cell.row.original
    const handleClick = (e) => {
      if(isFunction(onGotoApprovals)) onGotoApprovals(group, "applicant")
      e.preventDefault()
    }

    if (!group.pendingMemberCount){
        return null
    }

    return group.pendingMemberCount <= 0 ? null :
      <ApprovalButton onClick={handleClick}>
        Approvals
      </ApprovalButton>
  }

  return ApprovalButtonCell
}, [onGotoApprovals])

export default useGroupOverviewApprovalButtonCell;