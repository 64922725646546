import React, { forwardRef, useState } from 'react'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import ResetPassword from './ResetPassword'
import AdminLogin from './AdminLogIn'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  backButton: {
    color: 'grey',
  },
  submitButton: {
    background: '#FF6F3E',
    color: 'white',
  },
  paper: {
    minHeight: '250px',
    minWidth: '445px',
  },
})

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function LeaveKioskDialog(props) {
  const { open, onSubmit, submitting, onCancel, error } = props
  const classes = useStyles()
  const [forgetPasswordMode, setForgetPasswordMode] = useState(false)

  const cancel = () => {
    // note: by setting forgetPasswordMode to false on cancel, we start everything fresh when the user cancels and re-opens
    // but, if the user accidentally clicks away after he/she has requested a code, the user will end up having to send the code again
    // when he/she re-opens the modal, as the modal will have forgotten that a code was already sent
    // Therefore, we might want to get rid of this logic and simply call onCancel
    setForgetPasswordMode(false)
    onCancel()
  }

  const cancelButton = (
    <Button className={classes.backButton} onClick={cancel} disabled={submitting}>
      Cancel
    </Button>
  )

  return (
    <Dialog
      open={open}
      onClose={cancel}
      disableBackdropClick={submitting}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.paper }}
    >
      {forgetPasswordMode ? (
        <ResetPassword
          classes={classes}
          onSubmit={onSubmit}
          onBackToSignIn={() => setForgetPasswordMode(false)}
          cancelButton={cancelButton}
        />
      ) : (
        <AdminLogin
          classes={classes}
          onSubmit={onSubmit}
          onForgotPassword={setForgetPasswordMode}
          submitting={submitting}
          error={error}
          cancelButton={cancelButton}
        />
      )}
    </Dialog>
  )
}
