import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDateRangeFilter } from 'filtering/selectors'
import { setDateRangeFilter } from 'filtering/actions'

export const useDateRangeFilter = (props = {}) => {
  const { key, defaultDateRange } = props
  const dateRange = useSelector(getDateRangeFilter(key, defaultDateRange))
  const dispatch = useDispatch()
  const setDateRange = useCallback(dateRange => dispatch(setDateRangeFilter(dateRange, key)), [dispatch, key])

  return [dateRange, setDateRange]
}
