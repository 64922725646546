import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { AddOrEditActivityPrompt, EditActivityDialog } from '../components'
import { Activity } from 'champion/utils/interface'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useGetOpportunityInstance } from '../../../Event/hooks/useGetOpportunityInstance'
import { encodeOccurrence } from '../../../Event/helpers/encodeOccurrence'
import { useTimeZone } from '../../../civic-champs-shared/auth/hooks'
import moment from 'moment-timezone'

export const useEditActivityPrompt = (eagerReplace: (activity: Activity) => {}) => {
  const isBulkActivityEditEnabled = useFeatureEnabled('BulkActivityEdit')
  const showPrompt = useShowPrompt(isBulkActivityEditEnabled ? AddOrEditActivityPrompt : EditActivityDialog)
  const timeZone = useTimeZone()
  const [getOpportunity] = useGetOpportunityInstance()

  return useCallback(
    async (activity: Activity) => {
      let opportunity
      if (activity.opportunity?.id && isBulkActivityEditEnabled) {
        try {
          const result = await getOpportunity(
            encodeOccurrence(activity.opportunity.id, moment.tz(activity.occurredAt, timeZone).format('YYYY-MM-DD')),
          )
          opportunity = {
            id: result.opportunityId,
            name: result.name,
            timeshifts: result.timeshifts,
          }
        } catch (err) {
          //TODO check if GET /opportunity_instances/:encoded_id error is 401 Not Found
        }
      }
      const savedActivity = await showPrompt(
        opportunity ? { activity: { ...activity, opportunity }, open: true } : { activity, open: true },
      )
      if (savedActivity) {
        eagerReplace(savedActivity)
      }
    },
    [eagerReplace, getOpportunity, isBulkActivityEditEnabled, showPrompt, timeZone],
  )
}

export default useEditActivityPrompt
