import React from 'react'
import useTask from '../hooks/useTask'
import { CircularProgress, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import useFormatToOrgTime from 'Event/scenes/events/hooks/useFormatToOrgTime'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useTaskTimeslots from '../hooks/useTaskTimeslots'
import moment from 'moment'
import BackLink from 'donation/campaigns/components/BackLink'
import ExpandingText from 'components/ExpandingText'
import { Edit } from '@material-ui/icons'
import useTaskHistory from '../hooks/useTaskHistory'
import TaskHistory from './TaskHistory'
import TaskHeader from './TaskHeader'
import EditTaskDescPrompt from './EditTaskDescPrompt'
import EditTaskTimeslotPrompt from './EditTaskTimeslotPrompt'
import TaskPeople from './TaskPeople'
import TaskLocation from './TaskLocation'
import useIsActive from '../hooks/useIsActive'
import EditVolunteerTimePrompt from './EditVolunteerTimePrompt'
import * as _ from 'lodash'
import { TaskStatus } from '../TaskStatus'

export const useTaskStyles = makeStyles(theme => ({
  cancelButton: {
    color: theme.palette.neutral.white,
    border: '1px solid',
    borderColor: theme.palette.danger.main,
    backgroundColor: theme.palette.danger.main,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.danger.dark,
    },
  },
  warning: {
    color: theme.palette.error.main,
  },
  card: {
    padding: theme.spacing(2),
  },
  taskPersonCard: {
    padding: theme.spacing(2),
    minHeight: '176px'
  },
  row: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    lineHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  title: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  titleRow: {
    display: 'flex',
    alignContent: 'center',
  },
  locationRow: {
    height: '300px',
  },
}))

export default function Task(props) {
  const classes = useTaskStyles()
  const formatDate = useFormatToOrgTime()
  const { id: taskId } = props.match.params

  const { task, loading, refresh: taskRefresh } = useTask(taskId)
  const timeZone = _.get(task, 'organization.timeZone', 'America/New_York')
  const isActive = useIsActive(task)
  const { timeslots, loading: timeslotsLoading, refresh: timeslotsRefresh } = useTaskTimeslots(taskId)
  const { taskHistory, loading: taskHistoryLoading, refresh: historyRefresh } = useTaskHistory(taskId)

  const showEditDescPrompt = useShowPrompt(EditTaskDescPrompt)
  const showEditTimeslotPrompt = useShowPrompt(EditTaskTimeslotPrompt)
  const showEditVolunteerTime = useShowPrompt(EditVolunteerTimePrompt)

  const { description = '', volunteeringRole = {}, overdueAt } = task

  const refresh = () => {
    taskRefresh()
    timeslotsRefresh()
    historyRefresh()
  }

  const handleEditDescClick = async () => {
    await showEditDescPrompt({ task, initialValue: task.description })
    refresh()
  }

  const handleEditTimeslotClick = async () => {
    const formattedSlots = timeslots.map(ts => ({ startTime: moment(ts.startTime), endTime: moment(ts.endTime) }))
    await showEditTimeslotPrompt({
      task,
      initialValue: formattedSlots,
      field: 'timeslots',
    })
    refresh()
  }

  const handleEditVolunteerTime = async () => {
    await showEditVolunteerTime({ task })
    refresh()
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="column">
          <Grid item>
            <BackLink to="/helping-hands" text="Back to Helping Hands Overview" />
          </Grid>

          <TaskHeader task={task} refresh={refresh} />

          <Divider />

          <Grid container item direction="row" spacing={2} className={classes.row}>
            <Grid item container xs={4}>
              <Grid item>
                <Typography className={classes.header}>Service Type</Typography>
                <Typography className={classes.text}>{volunteeringRole.name}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.header}>
                Description
                {isActive && (
                  <IconButton onClick={handleEditDescClick}>
                    <Edit />
                  </IconButton>
                )}
              </Typography>
              <ExpandingText text={description} />
            </Grid>
          </Grid>

          <Divider />

          <Grid container item direction="row" spacing={2} className={classes.row}>
            <Grid item xs={4}>
              <Typography className={classes.header}>Needed by</Typography>
              <Typography className={classes.text}>{formatDate(overdueAt, 'MM/DD/YYYY hh:mm a')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.header}>
                Available Timeslots
                {isActive && (
                  <IconButton onClick={handleEditTimeslotClick}>
                    <Edit />
                  </IconButton>
                )}
              </Typography>
              {timeslotsLoading ? (
                <CircularProgress />
              ) : (
                timeslots.map(ts => (
                  <Typography className={classes.text} key={ts.startTime}>
                    {moment(ts.startTime)
                      .tz(timeZone)
                      .format('MM/DD/YYYY hh:mma')}{' '}
                    -{' '}
                    {moment(ts.endTime)
                      .tz(timeZone)
                      .format('hh:mma')}
                  </Typography>
                ))
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.header}>
                Volunteer Time
                {[TaskStatus.Completed, TaskStatus.ClaimedCompleted].includes(task.taskStatus) && (
                  <IconButton onClick={handleEditVolunteerTime}>
                    <Edit />
                  </IconButton>
                )}
              </Typography>
              {task.occurredAt && task.duration && (
                <Typography className={classes.text}>
                  {moment(task.occurredAt)
                    .tz(timeZone)
                    .format('MM/DD/YYYY hh:mma')}{' '}
                  -{' '}
                  {moment(task.occurredAt)
                    .tz(timeZone)
                    .add(task.duration, 'hours')
                    .format('hh:mma')}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Divider />

          <TaskPeople task={task} refreshTask={refresh} />

          <Divider />

          <TaskLocation task={task} refreshTask={refresh} />

          <Divider />

          <Grid container item direction="column">
            <Typography className={classes.header}>Task History</Typography>
            {taskHistoryLoading ? <Loading /> : <TaskHistory task={task} taskHistory={taskHistory} />}
          </Grid>
        </Grid>
      )}
    </>
  )
}
