import React from 'react'
import useEventDrilldownStyles from 'Event/hooks/useEventDrilldownStyles'
import { ExpansionPanelDetails } from '@material-ui/core'

export const EventExpansionPanelDetails = React.forwardRef(
  ({ children }: { children: React.ReactNode }, ref: React.Ref<HTMLDivElement>) => {
    const classes = useEventDrilldownStyles()
    return (
      <ExpansionPanelDetails ref={ref} classes={{ root: classes.detailsRoot }}>
        {children}
      </ExpansionPanelDetails>
    )
  },
)
