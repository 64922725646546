import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export interface EventQuestionSet {
  id: number
  questionSet: {
    id: number
    name: string
  }
  survey?: {
    id: number
    version: number
  }
  required: boolean
}

export const useGetEventQuestionSet = (): [
  (encodedOccurrence: string) => Promise<EventQuestionSet[]>,
  Status<EventQuestionSet[]>,
] => {
  const [request, status] = useFetchRefactored<EventQuestionSet[]>({ emptyValue: [] })

  const getEventQuestionSet = useCallback(
    (encodedOccurrence: string) => {
      return request({
        method: 'get',
        url: `/opportunity_instances/${encodedOccurrence}/surveys`,
      })
    },
    [request],
  )

  return [getEventQuestionSet, status]
}

export default useGetEventQuestionSet
