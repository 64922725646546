import React from 'react'

import ActionMenu from 'components/menu/ActionMenu'
import ActionMenuItem from 'components/menu/ActionMenuItem'
import ExportMyTeamActionItem from './ExportMyTeamActionItem'
import { ListItemIcon } from '@material-ui/core'
import PersonAddOutlinedIcon  from '@material-ui/icons/PersonAddOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'

export default function MyTeamActionMenu(props) {
    const { members, onAddMember, onChangeRole, onRemoveMember, selectedMember } = props

    const handleEditRole = () => {
        if(!selectedMember) return;
        onChangeRole(selectedMember)
    }

    const handleRemoveMember = () => {
        if(!selectedMember) return;
        onRemoveMember(selectedMember)
    }

    return (
        <ActionMenu>
            <ActionMenuItem onClick={onAddMember}>
                <ListItemIcon>
                    <PersonAddOutlinedIcon />
                </ListItemIcon>
                    Add Admin
            </ActionMenuItem>
            <ActionMenuItem
                disabled={!selectedMember}
                onClick={handleEditRole}
            >
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                Edit Role
            </ActionMenuItem>
            <ActionMenuItem
                disabled={!selectedMember}
                onClick={handleRemoveMember}
            >
                <ListItemIcon>
                    <RemoveIcon />
                </ListItemIcon>
                    Remove Admin
            </ActionMenuItem>
            <ExportMyTeamActionItem members={members} />
        </ActionMenu>
    )
}
