import moment from 'moment'
import React from 'react'
import { 
  makeStyles,
  TextField,
  IconButton,
  Button,
  Modal,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableContainer,
  Table,
  TableBody
} from '@material-ui/core'
import Close from '@material-ui/icons/Close';

import useAffiliateOpportunitySelection from '../hooks/useAffiliateOpportunitySelection';
import { useCurrentOrg } from 'auth/hooks';
import { useTableSearch } from 'components/table/hooks';

const useStyles = makeStyles(theme => ({
modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    color: '#FF6F3E'
  },
  modalTitle: {
    fontSize: '34px',
    color: '#3D3D3D',
    fontFamily: 'SemiBold Open Sans',
    marginBottom: '25px',
    width: '917px',
  },
  modalButton: {
    width: '146px',
    height: '45px',
    background: '#5F8FE8',
    alignItems: 'center', 
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginRight: '30px',
  },
  nextText: {
    color: 'white',
    fontSize: '15px',
  },
  input: {
    width: '917px',
    height: '44px',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems:'center',  
  },
  searchButton: {
    width: '109px',
    height: '44px',
    marginLeft: '16px',
    borderColor: '#5C8DE8',
    marginTop: '5px',  
  },
  searchText: {
    fontSize: '14px',
    color: '#5C8DE8'
  },
  tableHead: {
      width: '917px',
      backgroundColor: '#ADC6F3',
      height: '35px'
  },
  tableContainer: {
    width: '917px',
    marginTop: '53px',
    display: 'flex',
    flexDirection: 'column',
  },
  cancelText: {
    color: '#FF6F3E'
  },
  bottomRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems:'center',
    marginTop: '65px',
    marginBottom: '40px',
    alignSelf: 'flex-end'
  },
}));   

//TODO extract this...
const DATE_FORMAT = 'MMM D, YYYY'
const formatDate = (opportunity, tz='America/Detroit') => {
  const start = moment(opportunity.startsAt).tz(tz).startOf('day')
  const end = moment(opportunity.endsAt).tz(tz).endOf('day')

  return start.isSame(end, 'day') 
    ? start.format(DATE_FORMAT) 
    : `${start.format(DATE_FORMAT)} - ${end.format(DATE_FORMAT)}`
}

const formatStatus = opportunity => !opportunity.scheduled || opportunity.published ? 'Live' : 'Draft'

const headCells = [
    { id: 'dates', numeric: false, disablePadding: false, label: 'Dates' },
    { id: 'opportunityName', numeric: false, disablePadding: false, label: 'Opportunity Name' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
  ];

export default function AffiliatedPrompt(props) {
    const { showing, close, complete, campaignId, startsAt, endsAt, preview } = props

    const organization = useCurrentOrg()

    const [{
      query,
      opportunities,
      selected,
      isAllSelected,
      loading,
      updating
    }, {
      refresh,
      setQuery,
      setSelected,
      setAllSelected,
      save
    }] = useAffiliateOpportunitySelection(campaignId, startsAt, endsAt)

    const classes = useStyles()
    const handleQueryChange = event => setQuery(event.target.value)
    const handleUpdate = async () => {
      if(updating) return
      await save() 
      complete()
    }

    return (<Modal
      open={showing}
      className={classes.modal}
    >
      <div className={classes.modalBody}>
        <IconButton 
          className={classes.icon}
          aria-label="close" 
          component="span" 
          onClick={close} 
         >
            <Close />
        </IconButton>
        <div className={classes.modalTitle}>
        {!preview &&<b>Select Opportunities</b>}
         {preview &&<b>Affiliated Opportunities</b>}
        </div>
        <div className={classes.row}>
          <TextField 
              className={classes.input}
              placeholder={"Search"}  
              variant="outlined"
              value={query}
              onChange={handleQueryChange}
          />
        </div>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell padding="checkbox">
                {!preview &&
                  <Checkbox
                    indeterminate={!isAllSelected && selected.size > 0}
                    checked={isAllSelected}
                    onChange={() => setAllSelected(!isAllSelected)}
                    inputProps={{ 'aria-label': 'select all opportunities' }}
                  />}
                </TableCell>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                  >
                      <b>{headCell.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { opportunities.map((opp, index) => {
                  const isItemSelected = selected.has(opp.opportunityId)
                  const labelId = `enhanced-table-checkbox-${index}`;
                  if(isItemSelected && preview){
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={opp.id}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {formatDate(opp, organization.timeZone)}
                        </TableCell>
                        <TableCell >{opp.name}</TableCell>
                        <TableCell align="right">{formatStatus(opp)}</TableCell>
                      </TableRow>
                    );
                }else if(!preview){
                return (
                  <TableRow
                    hover
                    onClick={() => { setSelected(opp.opportunityId, !isItemSelected) }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={opp.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {formatDate(opp, organization.timeZone)}
                    </TableCell>
                    <TableCell >{opp.name}</TableCell>
                    <TableCell align="right">{formatStatus(opp)}</TableCell>
                  </TableRow>
                );
              }
            })}
            </TableBody>
          </Table>
          {!preview &&
        <div className={classes.bottomRow}>
        <Button
            disabled={updating}
            className={classes.modalButton}
            onClick={handleUpdate}
         >
          <div className={classes.nextText}>
            <b>Update</b>
          </div> 
        </Button>
        <Button
            onClick={close}
       >
           <div className={classes.cancelText}><u>Cancel</u></div> 
       </Button>
       </div>
        }
        </TableContainer>

      </div>
    </Modal>)
}
