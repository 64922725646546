import React from 'react'
import {FormikHelpers as FormikActions, FormikProps, FormikValues, withFormik} from "formik"
import {object, number} from "yup"

import FormWrapper from "../../../components/form-wrapper"
import InputComponent from "../../../components/default-input";
import {getFormikFieldError} from "../../../../../utils/getErrorMessage";
import ActionBar from "../../../components/action-bar";

import './index.scss'

const userEmailVerificationValidationSchema = object().shape({
  code: number()
    .integer('Field value must be integer')
    .required('Verification code is required')
})

interface IFormProps extends FormikProps<FormikValues>{
  onClickCancel: VoidFunction
  onClickResend: VoidFunction
}

const UserEmailVerificationForm = (props: IFormProps) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    onClickCancel,
    onClickResend
  } = props

  return(
    <form onSubmit={handleSubmit}>
      <InputComponent
        name='code'
        value={values.code}
        onChange={handleChange}
        onBlur={handleBlur}
        label="Verification Code"
        disabled={isSubmitting}
        errorMessage={getFormikFieldError('code', errors, touched)}
      />
      <span className='email_verification_resend_code' onClick={onClickResend}>
        Resend the Code
      </span>
      <ActionBar
        actionLeft={{
          variant: 'text',
          text: 'CANCEL',
          onClick: onClickCancel
        }}
        actionRight={{
          type: 'submit',
          color: 'yellow',
          text: 'SAVE',
          disabled: !isValid || isSubmitting
        }}
      />
    </form>
  )
}

interface IUserEmailVerificationProps {
  initialValues: {
    code: string
  },
  onCancel: VoidFunction
  onResend: VoidFunction
  onSave: (code: string) => {}
}

const UserEmailVerification = (props: IUserEmailVerificationProps) => {
  const {
    initialValues,
    onCancel,
    onResend,
    onSave
  } = props

  const submitFormHandler = async (values: FormikValues, actions: FormikActions<any>) => {
    await onSave(values.code)
    actions.setSubmitting(false)
  }

  const Form = withFormik({
    mapPropsToValues: ({initialValues}: any) => initialValues,
    validationSchema: userEmailVerificationValidationSchema,
    handleSubmit: submitFormHandler
  })(UserEmailVerificationForm)

  return(
    <FormWrapper title={'Email Verification'}>
      <Form
        initialValues={initialValues}
        onClickCancel={onCancel}
        onClickResend={onResend}
      />
    </FormWrapper>
  )
}

export default UserEmailVerification
