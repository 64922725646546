import { useHistory } from 'react-router'
import { useCallback } from 'react'
import { LocationState } from 'history'

import { encodeOccurrenceFromEvent } from '../../../helpers/encodeOccurrence'
import { Opportunity } from '../../../interfaces'

export const useViewEventDetails = () => {
  const history = useHistory()

  return useCallback((event: Opportunity, locationState?: LocationState) => {
    const encodedInstance = encodeOccurrenceFromEvent(event)
    const organizationId = event.organization.id

      history.push(`/events/${encodedInstance}/${organizationId}`, locationState)
    }, [history])
}
