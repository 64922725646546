import React from 'react'
import { Chip, makeStyles, Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import GroupMembers from './GroupMembers'
import GroupSettings from './GroupSettings'
import GroupOnboarding from './GroupOnboarding'
import useMembersCollection from '../hooks/useMembersCollection'
import Loading from 'components/Loading'
import AddPersonButton from 'civic-champs-shared/core/add-person/AddPersonButton'
import { useFeatureEnabled } from 'core/feature/hooks'
import { AddButton } from 'civic-champs-shared/core/add-button'

const useStyles = makeStyles(theme => ({
  actionMenu: {
    margin: '16px 0',
  },
  panel: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderTop: 'none',
    minHeight: 860,
    padding: theme.spacing(2),
  },
  addButton: {
    margin: '0 15px',
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  borderClear: {
    width: 'calc(100% + 1px)',
    left: '-0.5px',
  },
}))

const StyledTabs = withStyles({
  root: {
    backgroundColor: 'inherit !important',
    border: 'none',
    // get rid of underline
    '& > div > span': {
      display: 'none',
    },
    '& button:first-child': {
      width: '100%',
      maxWidth: `calc(100% - 750px)`,
      alignItems: 'flex-start',
      overflow: 'visible',
    },
    overflow: 'visible',
  },
  scroller: {
    overflow: 'visible !important',
  },
})(Tabs)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

const StyledTab = withStyles({
  root: {
    backgroundColor: '#EEF3FD',
    borderRadius: '4px 4px 0 0',
    border: '1px solid #5C8DE8',
    fontSize: '1.2em',
    textTransform: 'none',
    color: '#3d3d3d',
  },
  wrapper: {
    alignItems: 'flex-start',
  },
  selected: {
    background: 'white',
    borderBottom: 0,
    color: 'inherit !important',
  },
})(Tab)

const StyledChip = withStyles({
  root: {
    backgroundColor: '#ff6f3e',
    color: 'white',
    margin: '0 5px',
    fontWeight: 700,
  },
})(Chip)

const filterApprovals = members => {
  return members.filter(member => member.status.code === 'applicant')
}

const GroupsDetailTabs = props => {
  const singleAddPersonUIEnabled = useFeatureEnabled('SingleAddPersonUI')
  const [currentTab, setCurrentTab] = React.useState(props.currentTab || 0)
  const handleTabChange = (event, tabIndex) => {
    setCurrentTab(tabIndex)
    props.onTabChange && props.onTabChange(tabIndex)
  }
  const [{ members, initiallyLoaded }, { addMember, addMemberLegacy, removeMember, refresh }] = useMembersCollection(
    props.group,
  )

  const classes = useStyles()
  const filteredApprovals = filterApprovals(members)

  if (!initiallyLoaded) {
    return <Loading />
  }

  return (
    <div>
      <StyledTabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <StyledTab
          label={
            <div className={classes.tabLabel}>
              <span>Members</span>
              {filteredApprovals.length > 0 && <StyledChip label={filteredApprovals.length} />}
              {currentTab === 0 &&
                (singleAddPersonUIEnabled ? (
                  <AddPersonButton
                    classes={{ button: 'tab-button-black', borderClear: classes.borderClear }}
                    groupId={props.group.id}
                    groupTitle="Existing Members"
                    autocompleteLabel="Select User"
                    otherTitle="Existing Users"
                    personName="Group Member"
                    disableGroupPersons={true}
                    legendWidthFixture={90}
                    handlePersonCreate={addMember}
                  />
                ) : (
                  <AddButton
                    className={`tab-button-black ${classes.addButton}`}
                    onClick={addMemberLegacy}
                    title="ADD"
                  />
                ))}
            </div>
          }
        />
        <StyledTab
          label={
            <div className={classes.tabLabel}>
              <span>Onboarding</span>
            </div>
          }
        />
        <StyledTab
          label={
            <div className={classes.tabLabel}>
              <span>Settings</span>
            </div>
          }
        />
      </StyledTabs>

      <TabPanel className={classes.panel} value={currentTab} index={0}>
        <GroupMembers
          group={props.group}
          members={members}
          query={props.query}
          refresh={refresh}
          removeMember={removeMember}
        />
      </TabPanel>

      <TabPanel className={classes.panel} value={currentTab} index={1}>
        <GroupOnboarding group={props.group} groupUpdate={props.onGroupUpdate} />
      </TabPanel>

      <TabPanel className={classes.panel} value={currentTab} index={2}>
        <GroupSettings group={props.group} groupUpdate={props.onGroupUpdate} />
      </TabPanel>
    </div>
  )
}

export default GroupsDetailTabs
