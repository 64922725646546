import { get } from 'lodash'
import { Row } from 'react-table'

const compareBools = (lhs: boolean, rhs: boolean) => (lhs == rhs ? 0 : !rhs ? 1 : -1)

export function booleanSortType<RowT extends object>(config: { invert: boolean } = { invert: false }) {
  const sign = get(config, 'invert', false) ? -1 : 1
  return (lhs: Row<RowT>, rhs: Row<RowT>, columnId: string) =>
    sign * compareBools(lhs.values[columnId], rhs.values[columnId])
}
