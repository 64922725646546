import { useCallback, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useFetchSalsaIntegration = onResultUpdate => {
  const [request, { result, loading, called }] = useFetch(
    'Error Fetching Salsa Integration.  Please refresh the page and try again',
  )
  const { id: organizationId } = useCurrentOrg()

  const reFetch = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/salsa/organizations/${organizationId}`,
    })
  }, [request])
  useEffect(() => {
    reFetch()
  }, [reFetch])

  return {
    salsaIntegration: result,
    loading: loading || !called,
    reFetch,
  }
}
