import React, { useState } from 'react'
import {useCredentialType} from "../hooks/useCredentialType";
import { Grid, Typography } from '@material-ui/core'
import Loading from 'components/Loading'
import Search from 'core/table/components/Search'
import BackLink from '../../donation/campaigns/components/BackLink'
import CredentialIDTabs from "../components/CredentialIDTabs";

export const CredentialIDDetails = props => {
  const credentialTypeID = props.match.params.id
  const [credentialType, setCredentialType] = useState(null)
  const [query, setQuery] = useState(undefined)
  const { loading } = useCredentialType(credentialTypeID, setCredentialType)

  const handleQueryChange = query => {
    setQuery(query || undefined)
  }

  if (loading || !credentialType) {
    return <Loading />
  }

  return (
    <>
      <BackLink to={'/credential-ids'} text="Back to IDs" />
      <Grid container direction="column" spacing={3}>
        <Grid item container direction="row" justify="space-between" wrap="nowrap">
          <Grid item container direction="row">
            <Grid item>
              <Typography variant="h4">{credentialType.label}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
          </Grid>
        </Grid>
        <Grid item>
          <CredentialIDTabs credentialType={credentialType} query={query}/>
        </Grid>
      </Grid>
    </>
  )
}
