import { BulkAction } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { Row } from 'types/react-table'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import CancelDialog from 'civic-champs-shared/core/confirm-dialog/CancelDialog'
import { useCallback } from 'react'
import { useCancelEventRegistrations } from 'Event/hooks/useCancelEventRegistrations'
import { RegistrantResponse } from 'Event/interfaces'

const useCancelEventRegistrationsDialog = ({
  eagerRemoveMany,
  selectedRows,
  encodedOccurrence,
}: {
  eagerRemoveMany: BulkAction<RegistrantResponse>
  selectedRows: Row<RegistrantResponse>[]
  encodedOccurrence: string
}) => {
  const showPrompt = useShowPrompt(CancelDialog)
  const cancelRegistrations = useCancelEventRegistrations(encodedOccurrence)

  return useCallback(async () => {
    const confirmed = await showPrompt({
      text: `Are you sure you want to cancel ${selectedRows.length} registrations for this event?`,
      title: 'Confirm Action',
      confirmText: 'Yes',
      cancelText: 'No',
    })

    if (confirmed) {
      const selectedIds = selectedRows.map(({ original }) => original.id)
      const rollback = eagerRemoveMany(selectedIds.map(id => ({ id } as RegistrantResponse)))
      try {
        await cancelRegistrations(selectedIds)
      } catch (error) {
        // error notification is shown by `useFetchRefactored` inside `useCancelEventRegistrations`
        rollback()
      }
    }
  }, [showPrompt, selectedRows, eagerRemoveMany, cancelRegistrations])
}

export default useCancelEventRegistrationsDialog
