import { trim, toLower } from 'lodash'

export default function parseUsername(username) {
    username = toLower(trim(username))
    if( /^[-()\d\s]+$/.test(username) ) {
        let phone = username.replace(/[^\d]+/g, '');
        if( phone.length === 10) {
            username = '+1' + phone;
        } else if(phone.length >= 11) {
            username = '+' + phone;
        }
    }
    return username;
}
