import usePost from './usePost'

export default function useAddPerson({
  groupId,
  successMessage = 'Successfully added user',
  errorMessage = 'Error creating user',
}: {
  groupId: number
  successMessage?: string
  errorMessage?: string
}) {
  return usePost<any, any>({
    successMessage,
    errorMessage,
    configMapper: (body: any) => ({
      url: `/groups/${groupId}/members`,
      config: { body },
    }),
  })
}
