import React, { useState } from 'react'
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import HelpingHandsTabs from './HelpingHandsTabs'
import HelpingHandsCards from './HelpingHandsCards'

const useStyles = makeStyles(theme => ({
  headerRow: {
    marginBottom: theme.spacing(3)
  }
}))

export default function HelpingHands(props) {
  const [query, setQuery] = useState('')
  const classes = useStyles()

  return (
    <Grid container direction='column'>
      <Grid container item direction='row' justify='space-between' className={classes.headerRow}>
        <Grid item>
          <Typography variant='h4'>
            Helping Hands
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            variant='outlined'
            placeholder='Search'
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
        </Grid>
      </Grid>


      <HelpingHandsCards />
      <HelpingHandsTabs query={query} />

    </Grid>
  )
}