import { useCallback } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useConnectStripeAccount(state: string, code: string) {
  // @ts-ignore
  const [request, { loading, error, result, called }] = useFetch('Error connecting to Stripe.  Please try again')
  const connectStripeAccount = useCallback(() => {
    // @ts-ignore
    return request({
      method: 'post',
      url: '/stripe/connect',
      config: {
        body: {
          state,
          code,
        },
      },
    })
  }, [request])

  return {
    connectStripeAccount,
    succeeded: result && result.success,
    loading: loading || !called,
    error,
  }
}

