import intersection from 'lodash/intersection'
import difference from 'lodash/difference'
import { Tag } from '../../../tags/types'
import map from 'lodash/map'

interface Operand {
  label: string
  value: number
  color: string
}

const anyOf = (rowValue: Tag[], operand: Operand[]): boolean =>
  !!intersection(map(rowValue, 'id'), map(operand, 'value')).length
const allOf = (rowValue: Tag[], operand: Operand[]): boolean =>
  !difference(map(operand, 'value'), map(rowValue, 'id')).length
const notAnyOf = (rowValue: Tag[], operand: Operand[]): boolean =>
  !intersection(map(operand, 'value'), map(rowValue, 'id')).length
const notAllOf = (rowValue: Tag[], operand: Operand[]): boolean =>
  intersection(map(operand, 'value'), map(rowValue, 'id')).length !== operand.length

const operatorToMethodMap = {
  anyOf,
  allOf,
  notAllOf,
  notAnyOf,
}

export const tagsFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}
