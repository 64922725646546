
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HelpingHandsIcon(props) {
    return (
      <SvgIcon {...props}>
        <path id="Path_1" data-name="Path 1" d="M6.773,8.393,6.759,8.4l-.012.013A25,25,0,0,0,36.6,47.653l.01-.005.009-.006a1.613,1.613,0,0,0,.738-1.174h.011V29.532h0v-.941a12.009,12.009,0,0,0-2.193-7.2c-1.9-2.534-5.007-4.087-9.367-4.087a12.8,12.8,0,0,0-5.108.959V6.9A19.1,19.1,0,0,1,42.893,32.157l-.017.045v0l-.009.022-.008.02v.006l-.019.051v.006a1.583,1.583,0,0,0-.052.179h-.041v6.452h.027a1.613,1.613,0,0,0,2.815.748l.009-.01.008-.011a25.01,25.01,0,0,0-30-37.339l-.02.008-.019.011a1.612,1.612,0,0,0-.774,1.378V35.984a2.957,2.957,0,0,0,5.914,0V28.591a6.9,6.9,0,0,1,.852-3.662,3.208,3.208,0,0,1,1.286-1.144,6.535,6.535,0,0,1,2.97-.571c2.843,0,4.043.933,4.639,1.726a6.16,6.16,0,0,1,1.006,3.65V42.436h0V42.7a1.075,1.075,0,0,1-.62.974A19.091,19.091,0,0,1,9.189,14.805L9.2,14.8l.005-.01a1.6,1.6,0,0,0,.193-.577h.014V9.64A1.613,1.613,0,0,0,6.773,8.393ZM37.1,46.279c0,.027,0,.054,0,.081,0-.011,0-.021,0-.032S37.1,46.3,37.1,46.279Zm6.652-6.434a1.344,1.344,0,0,1-.738-1.173h0A1.344,1.344,0,0,0,43.749,39.845Zm-34.61-25.8q0-.027,0-.054Q9.14,14.022,9.139,14.048Z" transform="translate(0 -0.5)" fill="#fff" fillRule="evenodd"/>
      </SvgIcon>
    );
  }