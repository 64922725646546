import Papa from 'papaparse'
import map from 'lodash/map'
import { FormValues, Mappings } from '../components/RoleImportDialog'
import { mapRow, Outcome } from 'utils/import'
import { isUndefined } from 'lodash'

export const parseAndValidateInput = (values: FormValues, existingRoles: string[]): Promise<Record<string, any>[]> => {
  const rows: Record<string, any>[] = []
  return new Promise(resolve => {
    Papa.parse<any, File>(values.file as File, {
      header: true,
      skipEmptyLines: true,
      step: results => {
        if (results.errors.length === 0) {
          rows.push(
            mapAndValidateImportRow({
              row: results.data,
              mappings: values.mappings,
              existingRoles,
            }),
          )
        } else {
          rows.push({
            ...results.data,
            outcome: Outcome.Invalid,
            reason: map(results.errors, 'message').join('; '),
          })
        }
      },
      complete: () => resolve(rows),
    })
  })
}

const getBoolFromString = (input: string, trueValues: string[] = [], falseValues: string[] = []): boolean => {
  const value = input.trim().toLocaleLowerCase()
  if (['1', 'yes', 'true', ...trueValues].includes(value)) return true
  if (['', '0', 'no', 'false', ...falseValues].includes(value)) return false
  throw new Error('Invalid boolean value')
}

export const mapAndValidateImportRow = ({
  row,
  mappings,
  existingRoles,
}: {
  row: Record<string, any>
  mappings: Mappings
  existingRoles: string[]
}): Record<string, any> => {
  const mappedRow = mapRow<Mappings>({ row, mappings })

  if (!mappedRow.name) {
    return {
      ...row,
      outcome: Outcome.Skipped,
      reason: 'No name defined',
    }
  }

  if (existingRoles.includes(mappedRow.name.trim())) {
    return {
      ...row,
      outcome: Outcome.Skipped,
      reason: 'Role already exists',
    }
  }

  if (!isUndefined(mappedRow.isActive)) {
    try {
      getBoolFromString(mappedRow.isActive as string, ['active'], ['inactive'])
    } catch (e) {
      return {
        ...row,
        outcome: Outcome.Invalid,
        reason: 'Invalid status value',
      }
    }
  }

  if (!isUndefined(mappedRow.isDefault)) {
    try {
      getBoolFromString(mappedRow.isDefault as string)
    } catch (e) {
      return {
        ...row,
        outcome: Outcome.Invalid,
        reason: 'Invalid "Role can be used for Opportunities" value',
      }
    }
  }

  return {
    ...row,
    outcome: Outcome.Valid,
  }
}
