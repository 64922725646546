import useSetVolunteerStatus from 'champion/hooks/useSetVolunteerStatus'
import React, { useCallback, useMemo, useState } from 'react'

export const useVolunteerStatus = (initialStatus: string, personId: number) => {
  const setVolunteerStatus = useSetVolunteerStatus()
  const [status, setStatus] = useState<string>(initialStatus as string)
  const updateStatus = useCallback(
    async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const currentStatus = status
      const newStatus = event.target.value as string
      setStatus(newStatus)
      try {
        await setVolunteerStatus({ personId, status: newStatus })
      } catch (e) {
        setStatus(currentStatus)
      }
    },
    [personId, setVolunteerStatus, status],
  )

  return useMemo(() => ({ status, updateStatus }), [status, updateStatus])
}
