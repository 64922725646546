import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Typography } from '@material-ui/core'
import { Select } from 'formik-material-ui'
import { Field, Form, Formik } from 'formik'
import useSendNotification from 'new-mentorship/hooks/useSendNotification'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '12px',
    },
    formControl: {
      marginBottom: '16px',
    },
    label: {
      backgroundColor: 'white',
      padding: '0 8px',
    },
    header: {
      paddingBottom: '16px',
    },
  }),
)

const enum NotificationType {
  Feedback,
  Upcoming,
}

export default function SendNotificationModal({
  open,
  handleClose,
  mentorName,
  menteeName,
  meetingId,
}: {
  open: boolean
  handleClose: () => void
  mentorName: string
  menteeName: string
  meetingId: number
}) {
  const { sendNotification } = useSendNotification()
  const classes = useStyles()

  const handleSubmit = ({ type, period, recipient }: { type: NotificationType; period: string; recipient: string }) => {
    return sendNotification(meetingId, period, type === NotificationType.Feedback ? recipient : undefined).then(() =>
      handleClose(),
    )
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.paper}>
        <Typography variant="h5" className={classes.header}>
          Send Notification
        </Typography>

        <Formik
          initialValues={{ type: NotificationType.Feedback, period: 'instant', recipient: 'mentor' }}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel id="type-label" className={classes.label}>
                      Type
                    </InputLabel>
                    <Field labelId="type-label" name="type" label="Type" component={Select} id="type-select">
                      <MenuItem value={NotificationType.Feedback}>Feedback</MenuItem>
                      <MenuItem value={NotificationType.Upcoming}>Upcoming</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <InputLabel id="period-label" className={classes.label}>
                      Period
                    </InputLabel>
                    <Field labelId="period-label" name="period" label="Period" component={Select} id="period-select">
                      <MenuItem value="instant">
                        {values.type === NotificationType.Feedback ? '15 min' : '1 hour'}
                      </MenuItem>
                      <MenuItem value="daily">24 h</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                {values.type === NotificationType.Feedback && (
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth className={classes.formControl}>
                      <InputLabel id="recipient-label" className={classes.label}>
                        Recipient
                      </InputLabel>
                      <Field
                        labelId="recipient-label"
                        name="recipient"
                        label="Period"
                        component={Select}
                        id="recipient-select"
                      >
                        <MenuItem value="mentor">{mentorName}</MenuItem>
                        <MenuItem value="mentee">{menteeName}</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} container justify="flex-end">
                  <Button disabled={isSubmitting} type="submit" variant="contained" color="primary">
                    Send Notification
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
