import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instantiateQueryConfig from '../utils/instantiateQueryConfig';
import { getQueryState } from '../selectors';
import { runQuery } from '../actions';

export default function useLazyQuery(queryDef) {
    const dispatch = useDispatch();

    const [called, setCalled] = useState(false);
    const [config, setConfig] = useState(null);

    const fetch = useCallback( 
        (params) => { 
            let newConfig = instantiateQueryConfig(queryDef, params);

            setConfig(newConfig)
            dispatch( runQuery(newConfig) );
            setCalled(true); 
        }, 
        [queryDef, dispatch]
    ); 

    const selector = useMemo(() => getQueryState(config), [config]);
    const queryState = useSelector(selector);
    
    return useMemo(
        () => [
            fetch,
            { 
                ...queryState, 
                called
            }
        ],
        [queryState, called, fetch]
    );
}