import React from 'react'
import format from 'civic-champs-shared/utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { ExportButton } from 'core/table/components'
import { TripOrder } from 'volunteering/trips/types'
import { Row } from 'types/react-table'
import capitalize from 'lodash/capitalize'
import { getSudMhText } from 'volunteering/trips/hooks/useTripPlannerColumns'

const filename = 'trips.csv'
const headers = [
  { label: 'Rider', key: 'rider' },
  { label: 'Pickup Date', key: 'pickupDate' },
  { label: 'Pickup Time (Scheduled)', key: 'pickupAt' },
  { label: 'Drop-Off Time (Scheduled)', key: 'dropOffAt' },
  { label: 'Driver', key: 'driver' },
  { label: 'Miles Driven', key: 'mileage' },
  { label: 'Pickup Time (Actual)', key: 'pickedUpAt' },
  { label: 'Drop-Off Time (Actual)', key: 'droppedOffAt' },
  { label: 'Pickup Address', key: 'pickupLocation' },
  { label: 'Drop-Off Address', key: 'dropOffLocation' },
  { label: 'Drop-Off County', key: 'dropOffCounty' },
  { label: 'Reason', key: 'reason' },
  { label: 'Recovery', key: 'recovery' },
  { label: 'SUD / MH', key: 'sudMh' },
  { label: 'CJI', key: 'cji' },
  { label: 'Notes', key: 'description' },
]

interface FormattedData {
  rider: string | null
  pickupDate: string | null
  pickupAt: string | null
  dropOffAt: string | null
  driver: string | null
  mileage: number | null
  pickedUpAt: string | null
  droppedOffAt: string | null
  pickupLocation: string | null
  dropOffLocation: string | null
  dropOffCounty: string | null
  reason: string | null
  recovery: string | null
  sudMh: string | null
  cji: string | null
  description: string | null
}

const formatData = (trips: Row<TripOrder>[]): Partial<FormattedData>[] => {
  return trips.map(
    ({
      original: {
        tripRequest,
        pickupAt,
        dropOffAt,
        driver,
        pickedUpAt,
        droppedOffAt,
        pickupLocation,
        dropOffLocation,
        reason,
        recovery,
        sudMh,
        cji,
        description,
        mileage,
      },
    }) => {
      return {
        rider: format.name(tripRequest?.rider),
        pickupDate: format.date(pickupAt),
        pickupAt: format.time(pickupAt),
        dropOffAt: format.time(dropOffAt),
        driver: format.name(driver),
        mileage,
        pickedUpAt: pickedUpAt ? format.time(pickedUpAt) : '',
        droppedOffAt: droppedOffAt ? format.time(droppedOffAt) : '',
        pickupLocation: format.address(pickupLocation.address),
        dropOffLocation: format.address(dropOffLocation.address),
        dropOffCounty: dropOffLocation?.address.county || '',
        reason: reason ? capitalize(reason) : '',
        recovery: recovery ? 'Yes' : 'No',
        sudMh: getSudMhText(sudMh),
        cji: cji ? 'Yes' : 'No',
        description: description
          .replace(/[\r\n]/g, '')
          .replace(/<br\/?>/gi, '\n')
          .replace(/(<([^>]+)>)/g, ''),
      }
    },
  )
}
const ExportTripsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)

  return (
    <ExportButton
      data={data}
      formatData={formatData}
      headers={headers}
      disabled={disabled}
      filename={fileNameWithDate as string}
    />
  )
}

export default ExportTripsButton
