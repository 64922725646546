import React from 'react'

import { Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'

export interface EditCellProps {
  onEdit?: (row: any) => void
  onDelete?: (row: any) => void
  deletePlaceHolder?: boolean
  editPlaceHolder?: boolean
  cell?: any
}

export interface EditHeaderProps {
  hideEdit?: boolean
  hideDelete?: boolean
}

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 10,
  },
  iconHeader: {
    color: '#000',
  },
  icon: {
    color: '#0F5DB5',
  },
  deleteIcon: {
    color: '#ba1b1b',
    height: '18px',
    width: '18px',
    cursor: 'pointer',
  },
  deleteHeaderIcon: {
    color: '#000000',
    height: '18px',
    width: '18px',
  },
}))

export const EditHeader = ({ hideEdit = false, hideDelete = false }: EditHeaderProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Grid container justify={hideEdit || hideDelete ? 'center' : 'space-between'}>
      {!hideEdit && (
        <IconButton disabled className={classes.iconButton}>
          <EditIcon className={classes.iconHeader} fontSize="small" />
        </IconButton>
      )}
      {!hideDelete && (
        <IconButton disabled className={classes.iconButton}>
          <DeleteIcon className={classes.deleteHeaderIcon} />
        </IconButton>
      )}
    </Grid>
  )
}

export const EditCell = ({
  cell,
  onEdit,
  onDelete,
  deletePlaceHolder,
  editPlaceHolder,
}: EditCellProps): JSX.Element => {
  const classes = useStyles()
  const row = cell.row.original

  return (
    <Grid
      container
      justify={(deletePlaceHolder || onDelete) && (editPlaceHolder || onEdit) ? 'space-between' : 'center'}
    >
      {(editPlaceHolder || onEdit) && (
        <Grid item>
          {onEdit && (
            <Tooltip title="Edit">
              <IconButton
                className={classes.iconButton}
                disabled={!row}
                onClick={e => {
                  e.stopPropagation()
                  if (row) {
                    onEdit(row)
                  }
                }}
              >
                <EditIcon className={classes.icon} fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}
      {(deletePlaceHolder || onDelete) && (
        <Grid item>
          {onDelete && (
            <Tooltip title="Delete">
              <IconButton
                className={classes.iconButton}
                disabled={!row}
                onClick={e => {
                  e.stopPropagation()
                  if (row) {
                    onDelete(row)
                  }
                }}
              >
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}
    </Grid>
  )
}
