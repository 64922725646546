import React, { useCallback, useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { find, get, isNil } from 'lodash'
import classnames from 'classnames'
import InputComponent from 'Event/components/input'
import DatePickerCustom from 'Event/components/date-picker'
import DatePickerMultiSelect from 'Event/components/date-picker-multi-select'
import CKEditor from 'civic-champs-shared/core/ckeditor'
import PropTypes from 'prop-types'
import MultiStepNavButton from '../navigation-button-area'
import {
  DEFAULT_RECURRING_TYPE,
  RECURRING_TYPE,
  RECURRING_TYPE_OPTIONS,
} from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { INIT_VALIDATOR } from 'Event/helpers/validation'
import { getTextInHTMLTags } from 'Event/helpers/getTextInHTMLTags'
import { toSortDate } from 'Event/helpers/toSortDate'
import moment from 'moment'
import { useFormatDates } from 'Event/helpers/toFormatFullDateToISO'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import './index.scss'
import RecurringTypeSelect from 'Event/components/recurring-type-select'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import { useCurrentOrg, useIsTester } from 'auth/hooks'
import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { useDateStrings } from 'Event/scenes/events/hooks/useDateStrings'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'

const defaultErrorMessages = {
  name: '',
  startsAt: '',
  endsAt: '',
  description: '',
  instructions: '',
  contact_name: '',
  contact_email: '',
  contact_phone_number: '',
}

const BaseInfoModule = props => {
  const { setEventField, onNextClick, onSaveDraftClick, isSendingDraftRequest, event, editMode = false } = props
  const {
    published,
    name,
    startsAt,
    endsAt: instanceEndsAt,
    recurring_type,
    description,
    instructions,
    contact_name,
    contact_email,
    contact_phone_number,
    isTest,
    dates,
    is_recurring,
    recurrenceInfo,
  } = event
  const { isTester } = useIsTester()
  const confirmModal = useConfirm()

  const { timeZone } = useFormatDates()
  const endsAt = get(recurrenceInfo, 'endDate', instanceEndsAt)

  const { recurrenceString } = useDateStrings({ event })
  const getInitialRecurringTypeForEditing = useCallback(
    value => {
      const recurringTypeOptions = RECURRING_TYPE_OPTIONS(startsAt)
      let option = find(recurringTypeOptions, { value })
      if (option == null) {
        option = find(recurringTypeOptions, { value: RECURRING_TYPE.NEVER })
      }

      return option.value
    },
    [startsAt],
  )

  const initialRecurringType = isNil(recurring_type) ? RECURRING_TYPE.NEVER : recurring_type

  const [recurringType, setRecurringType] = useState(() =>
    editMode ? getInitialRecurringTypeForEditing(recurring_type) : initialRecurringType,
  )

  const [isMultiSelectDateError, setMultiSelectDateError] = useState(false)

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  const org = useCurrentOrg()

  useMount(() => {
    async function fetchData() {
      try {
        if (!editMode) {
          let useDefault = true
          if (org?.id) {
            const orgInfo = await requestWithRetry({ url: `/organizations/${org.id}` })
            if (orgInfo?.location) {
              const {
                location: {
                  type,
                  coordinates: [lng, lat],
                },
              } = orgInfo
              const geofencingInitial = {
                location: {
                  type,
                  coordinates: {
                    lat,
                    lng,
                  },
                },
                radius: DEFAULT_GEOFENCING.radius,
              }
              setEventField('geofencing', geofencingInitial)
              useDefault = false
            }
          }
          if (useDefault) {
            setEventField('geofencing', DEFAULT_GEOFENCING)
          }
        }
      } catch (error) {
        console.error('error fetching geofencing data', error)
      }
    }
    fetchData()
  })

  useEffect(() => {
    //When getting data(recurring_type) from the redux store
    editMode && setRecurringType(getInitialRecurringTypeForEditing(recurring_type))
  }, [editMode, getInitialRecurringTypeForEditing, recurring_type])

  const handleChangeField = type => val => {
    if (type === 'startsAt' || type === 'endsAt') {
      val = val.split('/')
      const date = `${val[2]}-${val[0]}-${val[1]}`
      setEventField(type, date)
      if (type === 'startsAt' && (editMode || recurringType === RECURRING_TYPE.NEVER)) {
        setEventField('endsAt', date)
      }
    } else {
      // not ideal, but for some reason, onChange={e => handleChangeField('contact_phone_number', e.target.value)}
      // won't fire within InputMask
      const targetValue = get(val, 'target.value', val)
      setEventField(type, targetValue)
    }
  }

  const setInitialCustomDates = option => {
    const isCustomEvents = option === RECURRING_TYPE.CUSTOM_EVENTS
    if (isCustomEvents && !dates.length) {
      return [moment(startsAt).tz(timeZone, true).format('MM/DD/YYYY')]
    } else {
      return []
    }
  }

  const handleChangeRecurringSelect = option => {
    const isRecurring = option !== RECURRING_TYPE.NEVER
    const isNeverCurrentRecurringType = recurringType === RECURRING_TYPE.NEVER
    const isCustomCurrentRecurringType = recurringType === RECURRING_TYPE.CUSTOM_EVENTS
    const checkCurrentRecurringType = isNeverCurrentRecurringType || isCustomCurrentRecurringType

    !editMode && checkCurrentRecurringType && setEventField('endsAt', null)
    setEventField('dates', setInitialCustomDates(option))
    setEventField('recurring_type', option)
    setEventField('is_recurring', isRecurring)

    setRecurringType(option)
  }

  const isDisableEndDate = () => {
    const condition = recurringType === RECURRING_TYPE.NEVER || recurringType === RECURRING_TYPE.CUSTOM_EVENTS
    if (condition) return true
    return !startsAt
  }

  const isRequiredEndDate = () => {
    const condition = recurringType === RECURRING_TYPE.NEVER || recurringType === RECURRING_TYPE.CUSTOM_EVENTS
    return !condition
  }

  const handleChangeMultiSelectDates = date => {
    const updatedDates = dates.map(date => moment(date).tz(timeZone, true).format('MM/DD/YYYY'))
    const index = updatedDates.indexOf(date)
    if (index > -1) {
      updatedDates.splice(index, 1)
    } else {
      updatedDates.push(date)
    }
    const sortedDates = updatedDates.sort(toSortDate)
    setEventField('dates', sortedDates)
    setEventField('endsAt', moment(sortedDates[sortedDates.length - 1], 'MM/DD/YYYY').format('YYYY-MM-DD'))
  }

  const handleNextClick = () => {
    const isRecurringType = recurringType !== RECURRING_TYPE.NEVER
    const isCustomEventsType = recurringType === RECURRING_TYPE.CUSTOM_EVENTS

    if (!name) {
      onChangeErrorMessage('name', `The name field is required`)
    } else if (!startsAt) {
      onChangeErrorMessage('startsAt', `This field is required`)
    } else if (isRecurringType && !isCustomEventsType && !endsAt) {
      onChangeErrorMessage('endsAt', `This field is required`)
    } else if (!editMode && isCustomEventsType && dates.length < 2) {
      setMultiSelectDateError(true)
    } else if (!getTextInHTMLTags(description)) {
      onChangeErrorMessage('description', `The description field is required`)
    } else {
      onNextClick()
    }
  }

  const handleSaveDraft = () => {
    if (!name) {
      onChangeErrorMessage('name', `The name field is required`)
    } else if (!startsAt) {
      onChangeErrorMessage('startsAt', `This field is required`)
    } else {
      onSaveDraftClick()
    }
  }

  const handleChangeDateWithConfirmModal = async value => {
    if (is_recurring) {
      const confirmed = await confirmModal(
        `Changing the date will remove this event from the recurring sequence.  Would you like to continue?`,
        {
          title: 'Warning',
          confirmText: 'Confirm',
          rejectText: 'Discard',
        },
      )
      if (confirmed) {
        handleChangeField('startsAt')(value)
        handleChangeField('is_recurring')(false)
        setRecurringType(DEFAULT_RECURRING_TYPE.value)
      }
    } else {
      handleChangeField('startsAt')(value)
    }
  }

  const topRowClassNames = classnames('base-info-form-row', 'top-row', {
    'top-row--non-recurring': recurringType === RECURRING_TYPE.NEVER,
  })
  return (
    <div className="base-info-module">
      <h2 className="base-info-module__title">Basic Info</h2>
      <div className="base-info-form">
        <div className={topRowClassNames}>
          <div className="base-info-form-row__item">
            <InputComponent
              value={name}
              onChange={handleChangeField('name')}
              label="Event Name*"
              onBlur={onValidateField('name', { required: true, maxLength: 255 })}
              hasError={!!errorMessages.name}
              errorMessage={errorMessages.name}
            />
          </div>
          <div className="base-info-form-row__item datepicker-item">
            <DatePickerCustom
              label="Start Date*"
              value={startsAt}
              onChange={!editMode ? handleChangeField('startsAt') : handleChangeDateWithConfirmModal}
              onBlur={onValidateField('startsAt', { required: true })}
              isRequired
              hasError={!!errorMessages.startsAt}
              placeholder="Start Date*"
            />
          </div>
          {!editMode && (
            <div className="base-info-form-row__item">
              <p className="base-info-form-row__item_title">This event repeats:</p>
              <RecurringTypeSelect
                editMode={editMode}
                startsAt={startsAt}
                value={recurringType}
                onChange={handleChangeRecurringSelect}
              />
            </div>
          )}
          {recurringType !== RECURRING_TYPE.NEVER && !editMode && (
            <div className="base-info-form-row__item datepicker-item">
              <DatePickerCustom
                label="End Date*"
                isDisabled={!editMode ? isDisableEndDate() : true}
                minDate={startsAt}
                value={endsAt}
                onBlur={onValidateField('endsAt', { required: true })}
                onChange={handleChangeField('endsAt')}
                isRequired={isRequiredEndDate()}
                hasError={!!errorMessages.endsAt}
                placeholder="End date*"
              />
            </div>
          )}
        </div>

        {!editMode && recurringType === RECURRING_TYPE.CUSTOM_EVENTS && (
          <div className="base-info-form-row multi-date-row">
            <DatePickerMultiSelect
              minDate={startsAt}
              dates={dates}
              onChange={handleChangeMultiSelectDates}
              highlightedDates={dates}
              isRequired={recurringType === RECURRING_TYPE.CUSTOM_EVENTS}
              hasError={isMultiSelectDateError}
            />
          </div>
        )}
        {editMode && (
          <div className="base-info-form-row multi-date-row">
            <i>{recurrenceString}</i>
          </div>
        )}
        <div className="base-info-form-row description-row">
          <CKEditor
            placeholder="Description*"
            value={description}
            onChange={handleChangeField('description')}
            onBlur={onValidateField('description', { required: true, maxLength: 10000 })}
            error={errorMessages.description}
          />
        </div>
        <div className="base-info-form-row instructions-row">
          <CKEditor
            placeholder="Day-of-event Instructions for Volunteers (Optional)"
            value={instructions}
            onChange={handleChangeField('instructions')}
            onBlur={onValidateField('instructions', { maxLength: 10000 })}
            error={errorMessages.instructions}
          />
        </div>
        <h3 className="base-info-form__title">Point Of Contact (Optional)</h3>
        <div className="base-info-form-row optional-row">
          <div className="base-info-form-row__item">
            <InputComponent
              value={contact_name}
              onChange={handleChangeField('contact_name')}
              label="Name"
              onBlur={onValidateField('contact_name', { maxLength: 120 })}
              hasError={!!errorMessages.contact_name}
              errorMessage={errorMessages.contact_name}
            />
          </div>
          <div className="base-info-form-row__item">
            <InputComponent
              type="email"
              autoCapitalize={'none'}
              value={contact_email}
              onChange={handleChangeField('contact_email')}
              label="Email Address"
              onBlur={onValidateField('contact_email', { email: true, maxLength: 320 })}
              hasError={!!errorMessages.contact_email}
              errorMessage={errorMessages.contact_email}
            />
          </div>
        </div>
        <div className="base-info-form-row optional-row flex-end">
          <div className="base-info-form-row__item">
            <InputMask
              type="phoneNumber"
              label="Phone Number"
              variant="outlined"
              mask="+1 (999) 999-9999"
              maskChar=" "
              classes={{ root: 'custom-input-component' }}
              onBlur={onValidateField('contact_phone_number', { phone: true })}
              alwaysShowMask={false}
              value={contact_phone_number}
              onChange={handleChangeField('contact_phone_number')}
              error={!!errorMessages.contact_phone_number}
              helperText={!!errorMessages.contact_phone_number && errorMessages.contact_phone_number}
              fullWidth
            >
              {inputProps => <TextField {...inputProps} />}
            </InputMask>
          </div>
        </div>
        <div>
          {isTester && (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch name="isTest" checked={isTest} onChange={() => handleChangeField('isTest')(!isTest)} />
                }
                label="Test Event?"
              />
            </Grid>
          )}
        </div>
      </div>
      <MultiStepNavButton
        isShowDraftButton={editMode ? !published : true}
        draftButtonText={editMode ? 'Update Draft' : 'Save For Later'}
        onSaveDraftClick={handleSaveDraft}
        onNextClick={handleNextClick}
        isSendingRequest={isSendingDraftRequest}
      />
    </div>
  )
}

BaseInfoModule.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  isSendingDraftRequest: PropTypes.bool,
  event: PropTypes.object,
  editMode: PropTypes.bool,
  encodedOccurrence: PropTypes.string,
}

export default BaseInfoModule
